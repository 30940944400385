import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SeverityTranslator } from '../../shared/translators/severity.translator';
import { StatisticsAggregationData } from '../../../statistics/shared/models/statistics-logs.response';

@Component({
  selector: 'sh-logs-filter',
  templateUrl: 'logs-filter.component.html',
  styleUrls: ['logs-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsFilterComponent implements OnInit, OnDestroy {
  get aggregationData(): StatisticsAggregationData {
    return this._aggregationData;
  }

  @Input() set aggregationData(val: StatisticsAggregationData) {
    this._aggregationData = val;
    if (this.aggregationData) {
      this.aggregationData[
        'severity'
      ] = this.severityTranslator.logsSeverityOptions.map((x) => {
        return {
          id: x.id,
          name: x.name,
          count: this.aggregationData['severity'][x.id]
            ? this.aggregationData['severity'][x.id].count
            : 0,
        };
      });
    }
  }

  @Input() get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(val) {
    this._isLoading = val;
    this.isLoadingChange.emit(this._isLoading);
  }

  @Output() public isLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public stringFilter: string;

  @Input() public metadataForm: FormGroup;

  @Input() public columnsFilter: any;

  @Output() public onFilterChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  private _aggregationData: StatisticsAggregationData;

  private _isLoading: boolean;
  private severityTranslator = SeverityTranslator;

  constructor() {}

  public ngOnInit() {}

  public ngOnDestroy() {}

  public dynamicFieldChange(event) {
    this.onFilterChange.emit([
      this.metadataForm,
      event.fieldName,
      event.event[1],
    ]);
  }

  public get dynamicFiltersFormGroup(): FormGroup {
    return this.metadataForm.controls['dynamicFilters'] as FormGroup;
  }

  public change(event, fieldName) {
    this.onFilterChange.emit([this.metadataForm, fieldName, event[1]]);
  }

  public isFilterVisible(colId) {
    return (
      this.columnsFilter.findIndex(
        (col) => col.colId === 'coralogix.metadata.' + colId,
      ) !== -1
    );
  }
}
