import { IGridFilter } from './IGridFilter';
import { IFilterParams, IAfterGuiAttachedParams, IDoesFilterPassParams } from 'ag-grid';
import { ElementRef, Renderer2 } from '@angular/core';
export class GridTextFilter implements IGridFilter {
  public filterText: string;
  public lastFilterText: string;
  public hidePopupCallback: any;
  public textElm: ElementRef;
  private params: IFilterParams;
  constructor(private renderer: Renderer2) { }

  public agInit(params: IFilterParams) {
    this.params = params;
  }

  public afterGuiAttached(params: IAfterGuiAttachedParams, textElm: ElementRef) {
    this.textElm = textElm;
    this.hidePopupCallback = params.hidePopup;
    this.textElm.nativeElement.focus();
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return false;
  }

  /*
   * called before the data source call getRows
   **/
  public getModel() {
    return this.filterText;
  }

  public setModel(model: string) {
    this.filterText = model;
  }

  /*
   * show filter icon if there is a text filter
   **/
  public isFilterActive(): boolean {
    return this.filterText && this.filterText.length > 0;
  }

  public applyFilter() {
    // apply only if text changed
    if (this.lastFilterText !== this.filterText) {
      this.lastFilterText = this.filterText;
      // force dataSource to call getRows, and apply filter
      this.params.filterChangedCallback();
    }
    // hide the filter popup
    if (this.hidePopupCallback) {
      this.hidePopupCallback();
    }
  }
}
