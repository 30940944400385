export class JsonParameterRequestData {
  public templateId: string;
  public key: string;
  public title?: string;

  constructor(templateId: string, key: string, title?: string) {
    this.templateId = templateId;
    this.key = key;
    this.title = title;
  }
}
