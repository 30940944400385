import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { FormControl } from '@angular/forms';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';
import { ShDialogService } from '@shared/services/dialogService';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { LogsColumnsService } from '@app/logs-new/shared/services/logs-columns.service';
import { ArchiveQueryDownloadTsvParams, ArchiveQueryPreviewGridParams } from '@app/roi/archive-queries/models/archive-query.model';
import { DownloadResultsFormComponent } from '@app/roi/archive-queries/components/download-results-form/download-results-form.component';

@Component({
  selector: 'sh-logs-preview',
  templateUrl: './logs-preview-grid.component.html',
  styleUrls: ['./logs-preview-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsPreviewGridComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public formControl: FormControl = new FormControl('');
  public logs: object[] = [];
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public hasLogsForPreview$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public unsubscribe$: Subject<void> = new Subject<void>();
  public logs$: BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);
  public validationErr$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchQuery: string = '';
  public validationError: string = '';
  public columnDefs: object[] = [];
  public columnDefs$: BehaviorSubject<object[]> = new BehaviorSubject([]);
  private unusedFields: string[] = [
    'ipaddress',
    'category',
    'class',
    'computername',
    'classname',
    'methodname',
    'threadid',
    'team_id',
    'hr',
    'dt',
  ];

  constructor(
    private archiveQueriesService: ArchiveQueriesService,
    private dialogService: ShDialogService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ICoralogixDialogData & ArchiveQueryPreviewGridParams,
    public dialogRef: MatDialogRef<LogsPreviewGridComponent>,
  ) {}

  public ngOnInit(): void {
    this.getFilteredLogs();
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getTsvs(): void {
    const data: ICoralogixDialogData & ArchiveQueryDownloadTsvParams = {
      title: 'Archive preview logs',
      message: '',
      yesButtonText: 'CONFIRM',
      noButtonText: 'CLOSE',
      id: this.data.id
    };
    const dialogRef = this.dialog.open(DownloadResultsFormComponent, { data });
  }

  private getFilteredLogs(): void {
    this.archiveQueriesService.getUnschematedLogs(this.data.id).subscribe(
      (res) => {
        const nonEmptyResult = res.logs?.length > 0;
        this.logs = res.logs;
        this.logs$.next(this.logs.slice(0, 1000));
        this.hasLogsForPreview$.next(nonEmptyResult);
        if (nonEmptyResult) {
          const cols = [];
          res.columns?.forEach((key) => {
            if (!this.unusedFields.includes(key)) {
              cols.push({ field: key });
            }
          });
          const columnsService = new LogsColumnsService({ colsDefs: cols });
          this.columnDefs$ = new BehaviorSubject(columnsService.getAllColumnsDefinitions(false));
        }
        this.isLoading$.next(false);
      },
      (err) => {
        this.logs = [];
        this.isLoading$.next(false);
        this.hasLogsForPreview$.next(false);
        if (err.status === 417) {
          this.validationErr$.next(err.error);
        } else {
          this.dialogService.showSnackBar('Failed to get logs.', null, 5000);
        }
      },
    );
  }
}
