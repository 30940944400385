import { type } from '../../shared/services/utils';
import { Action } from '@ngrx/store';
import { DynamicFilter } from '../../shared/models/dynamic-filters';
export namespace FiltersActions {
  export const ActionTypes = {
    GET_DYNAMIC_FILTERS: type('[Filter] Get Dynamic Filters'),
    GET_DYNAMIC_FILTERS_COMPLETED: type(
      '[Filter] Get Dynamic Filters Completed',
    ),
    GET_DYNAMIC_FILTERS_FAILED: type('[Filter] Get Dynamic Filters Failed'),
    REMOVE_DYNAMIC_FILTERS: type('[filter] remove all dynamic filters'),
    GET_MANAGE_BUTTON_OVERLAPPING_STATE: type(
      '[filter] get isOverlap manage columns button value',
    ),
    SAVE_MANAGE_BUTTON_OVERLAPPING_STATE: type(
      '[filter] save isOverlap manage columns button value',
    ),
  };

  export class GetDynamicFilters implements Action {
    public type = ActionTypes.GET_DYNAMIC_FILTERS;

    constructor(public payload: any) {}
  }
  export class RemoveDynamicFilters implements Action {
    public type = ActionTypes.REMOVE_DYNAMIC_FILTERS;

    constructor(public payload: any) {}
  }

  export class GetDynamicFiltersCompleted implements Action {
    public type = ActionTypes.GET_DYNAMIC_FILTERS_COMPLETED;

    constructor(public payload: DynamicFilter[]) {}
  }

  export class GetDynamicFiltersFailed implements Action {
    public type = ActionTypes.GET_DYNAMIC_FILTERS_FAILED;

    constructor(public payload: any) {}
  }

  export class GetIsOverlapManageButton implements Action {
    public type = ActionTypes.GET_MANAGE_BUTTON_OVERLAPPING_STATE;

    constructor(public payload: any) {}
  }

  export class SaveIsOverlapManageButton implements Action {
    public type = ActionTypes.SAVE_MANAGE_BUTTON_OVERLAPPING_STATE;

    constructor(public payload: any) {}
  }

  export type Actions =
    | GetDynamicFilters
    | GetDynamicFiltersCompleted
    | GetDynamicFiltersFailed
    | RemoveDynamicFilters
    | GetIsOverlapManageButton
    | SaveIsOverlapManageButton;
}
