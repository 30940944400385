import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

import { Constants } from '@app/constants';
import { QueryDefRequest } from '@app/logs/shared/models/query-def.request';
import { LoggregationEntity } from '../interfaces/loggregation.interface';
import { LoggregationParameterRequest, PlaceholderDataRequest } from '../interfaces/loggregation-parameter-request';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { ParameterType } from '../models/parameter-types.options';
import { QueryParams } from '@app/logs/shared/models/query-params.model';
import { Metadata } from '@app/logs/shared/models/query-metadata';

@Injectable()
export class LoggregationService {
  public loggregationSubject: Subject<Array<LoggregationEntity>> = new Subject<Array<LoggregationEntity>>();
  private loggregationUrl: string = Constants.GetUrl() + Constants.loggregationUrl;
  constructor(private http: HttpClient, private logService: LogsService, private ae: AnalyticEventService) {}

  public GetLoggregation(queryModel: LogQueryModel) {
    this.ae.event({ eventName: 'GetLoggregation' });

    this.getLoggregationResult(queryModel)
      .first()
      .subscribe((res) => this.loggregationSubject.next(res), (error) => this.handleError(error));
  }

  public getLoggregationResult(queryModel: LogQueryModel): Observable<Array<LoggregationEntity>> {
    const query = _.cloneDeep<LogQueryModel>(queryModel);
    const request = new QueryDefRequest(query);

    return this.getDataObs$(query, request)
      .map((req) => JSON.stringify(req))
      .switchMap((resJson) => this.http.post(this.loggregationUrl, resJson))
      .map((res: any) => {
        return res.map(v => new LoggregationEntity(v));
      });
  }

  public getTemplateAggs(templateId: string, paramId: string, path: string, queryModel: LogQueryModel) {
    const request = new PlaceholderDataRequest();
    request.startDate = queryModel.startDate;
    request.endDate = queryModel.endDate;
    request.queryParams = _.cloneDeep(queryModel.queryParams);
    request.queryParams.query.templateIds = [templateId];
    request.type = queryModel.type;
    // TODO Remove this 'if' after updating statistics granular usage
    if (path === 'numeric' || path === 'categorical') {
      request.queryParams.aggregationInterval = this.getInterval(queryModel.startDate, queryModel.endDate);
    } else {
      request.queryParams.aggregationInterval = 60000;
    }
    request.paramId = paramId;
    return this.http
      .post(`${Constants.placeholderUrl}/${path}`, JSON.stringify(request))
      .map(this.extractDataParameter);
  }

  public getParameterData(templateId, paramId, paramType, queryModel) {
    if (paramType === ParameterType.free) {
      const curuntFlow$ = this.getTemplateAggs(templateId, paramId, paramType, queryModel);
      const noramlFlow$ = this.getParameterDetails(templateId, paramId, 'placeholderGlobalStats', queryModel);
      return Observable.zip(curuntFlow$, noramlFlow$);
    } else {
      return this.getTemplateAggs(templateId, paramId, paramType, queryModel);
    }
  }

  public getParameterDataByRequest(request: LoggregationParameterRequest) {
    let currentFlow$;
    let noramlFlow$;
    const queryModel = new LogQueryModel();
    queryModel.startDate = request.queryDef.startDate;
    queryModel.endDate = request.queryDef.endDate;
    queryModel.queryParams = new QueryParams();
    queryModel.queryParams.metadata = request.queryParams ? request.queryParams.metadata : new Metadata();
    queryModel.queryParams.metadata.threadId = '';
    const paramType = 'categorical';
    if (!_.isUndefined(request.paramId) && !_.isNull(request.paramId)) {
      currentFlow$ = this.getTemplateAggs(request.templateId, request.paramId.toString(), paramType, queryModel);
      noramlFlow$ = this.getParameterDetailsByRequest(request, 'placeholderGlobalStats');
    } else {
      delete request.paramId;
      currentFlow$ = this.getTemplateAggs(request.templateId, null, 'occurrences', queryModel);
      noramlFlow$ = this.getParameterDetailsByRequest(request, 'templateGlobalAggStats');
    }

    return Observable.zip(currentFlow$, noramlFlow$);
  }

  public getOccurrencesData(templateId, queryModel) {
    const currentFlow$ = this.getTemplateAggs(templateId, null, 'occurrences', queryModel);
    const noramlFlow$ = this.getOccurrencesDetails(templateId, 'templateGlobalAggStats', queryModel);
    return Observable.zip(currentFlow$, noramlFlow$);
  }

  public getOccurrencesDetails(templateId, queryType, queryModel): any {
    const request = new LoggregationParameterRequest();
    request.queryDef.startDate = queryModel.startDate;
    request.queryDef.endDate = queryModel.endDate;
    request.templateId = templateId;
    request.queryDef.queryType = queryType;
    return this.http.post(this.loggregationUrl + '/parameter', JSON.stringify(request)).map(this.extractDataParameter);
  }

  public getOccurrencesDetailBytemplateIds(templateId: string[], queryType, queryModel): Observable<Array<any>> {
    const request = new LoggregationParameterRequest();
    request.queryDef.startDate = queryModel.startDate;
    request.queryDef.endDate = queryModel.endDate;
    request.queryDef.queryType = queryType;
    const result: any = [];
    templateId.forEach((id) => {
      request.templateId = id;
      result.push(
        this.http
          .post(this.loggregationUrl + '/parameter', JSON.stringify(request))
          .timeout(20000)
          .map((m) => this.extractOccurrencesDetailBytemplateIds(m, id))
          .catch((err) => {
            return Observable.of(null);
          }),
      );
    });
    return Observable.zip(...result);
  }

  private getDataObs$(query: LogQueryModel, request: QueryDefRequest) {
    if (query.type === QueryTypes.TEMPLATE) {
      return this.logService
        .getLogs(query)
        .first()
        .map((res) => {
          request.queryDef.queryParams.templateIds = res.templateIds;
          return request;
        });
    } else {
      return Observable.of(request);
    }
  }

  private getInterval(startDate: number, endDate: number) {
    const diff = (endDate - startDate) / 1000;
    if (diff < 3600 * 12) {
      return 60 * 1000;
    }
    return 3600 * 1000;
  }

  private getParameterDetails(templateId, paramId, queryType, queryModel): any {
    const request = new LoggregationParameterRequest();
    request.queryDef.startDate = queryModel.startDate;
    request.queryDef.endDate = queryModel.endDate;
    request.templateId = templateId;
    request.paramId = paramId;
    request.queryDef.queryType = queryType;

    return this.http.post(this.loggregationUrl + '/parameter', JSON.stringify(request)).map(this.extractDataParameter);
  }

  private getParameterDetailsByRequest(request: LoggregationParameterRequest, queryType): any {
    request.queryDef.queryType = queryType;
    return this.http.post(this.loggregationUrl + '/parameter', JSON.stringify(request)).map(this.extractDataParameter);
  }

  private extractDataParameter(res: any): any {
    return res;
  }

  private extractOccurrencesDetailBytemplateIds(res: any, templateId: string): any {
    const data: any = res;
    return { data, templateId };
  }

  private handleError(error: any) {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
