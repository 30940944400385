import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { FormControl } from '@angular/forms';
import { ArchiveQueryDownloadTsvParams, ArchiveQueryPreviewGridParams, IFile } from '@app/roi/archive-queries/models/archive-query.model';
import { take } from 'rxjs/operators';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { ArchiveQueryActions } from '@app/roi/archive-queries/state/actions/archive-query.actions';
import { BehaviorSubject } from 'rxjs';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';
import { ShDialogService } from '@shared/services/dialogService';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';

@Component({
  selector: 'sh-download-results-form',
  templateUrl: './download-results-form.component.html',
  styleUrls: ['./download-results-form.component.scss']
})
export class DownloadResultsFormComponent implements OnInit {
  public formControl: FormControl = new FormControl('');
  public hasFilesToDownload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public files$: BehaviorSubject<IFile[]> = new BehaviorSubject<IFile[]>([]);

  constructor(
    private archiveQueriesService: ArchiveQueriesService,
    private dialogService: ShDialogService,
    public dialogRef: MatDialogRef<DownloadResultsFormComponent>,
    private store: Store<State>,
    @Inject(MAT_DIALOG_DATA) public data: ICoralogixDialogData & ArchiveQueryDownloadTsvParams,
   ) {}

    public ngOnInit(): void {
      this.archiveQueriesService.getArchiveQueryResults(this.data.id).pipe(take(1)).subscribe(
        (res) => this.displayDownload(res),
        () => this.dialogService.showCoralogixMessage(
          `Failed to get results for query`, null, dialogServiceIconClasses.failed)
      );
    }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public downloadClicked(): void {
    this.files$.value.forEach(file => this.store.dispatch(new ArchiveQueryActions.DownloadResultsAction(file)));
    this.dialogRef.close();
  }

  private displayDownload(results: IFile[]): void {
    this.isLoading$.next(false);
    this.hasFilesToDownload$.next(!!(results && results.length));
    this.files$.next(results);
  }
}
