import { TimestampCellComponent } from '@shared/controls/grid-cells';
import { TeamRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/cell-renderers/team-renderer/team-renderer.component';
import { IColumnsDefinitions, IColumnsState } from '@app/logs-new/shared/models/logs-columns.model';
import { ColDef } from 'ag-grid-community';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { SideMenuHeaderRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/side-menu-header-renderer/side-menu-header-renderer.component';

export const getLogsColumnsDefinitions = (): IColumnsDefinitions => ({
  index: {
    headerName: '#',
    field: 'index',
    width: 75,
  },
  'coralogix.timestamp': {
    headerName: 'Timestamp',
    field: 'timestamp',
    cellRendererFramework: TimestampCellComponent,
    width: 200,
  },
  'coralogix.metadata.severity': {
    headerName: 'Severity',
    field: 'metadata.severity',
    cellRenderer: 'severityRenderer',
    minWidth: 120,
    resizable: false,
    valueGetter: params => params.data.metadata.severity,
  },
  'coralogix.metadata.companyId': {
    headerName: 'Team',
    field: 'metadata.companyId',
    cellRendererFramework: TeamRendererComponent,
    cellRendererParams: {
      path: 'data.metadata.companyId',
    },
    width: 105,
    valueGetter: params => params.data,
  },
  'coralogix.text': {
    headerName: 'Text',
    field: 'text',
    width: 800,
  },
  'coralogix.metadata.applicationName': {
    headerName: 'Application',
    field: 'metadata.applicationName',
    width: 200,
  },
  ratio: {
    headerName: 'Ratio',
    field: 'ratio',
    maxWidth: 90,
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => parseFloat(valueA) - parseFloat(valueB),
  },
  occurrences: {
    headerName: 'Occurrences',
    field: 'occurrences',
    cellRenderer: 'templatesOccurrencesRenderer',
    valueGetter: params => params.data.count,
    maxWidth: 135,
  },
  'coralogix.metadata.subsystemName': {
    headerName: 'Subsystem',
    field: 'metadata.subsystemName',
    width: 200,
  },
  'coralogix.metadata.category': {
    headerName: 'Category',
    field: 'metadata.category',
    width: 200,
  },
  'coralogix.metadata.computerName': {
    headerName: 'Computer',
    field: 'metadata.computerName',
    width: 200,
  },
  'coralogix.metadata.IPAddress': {
    headerName: 'Ip address',
    field: 'metadata.IPAddress',
    width: 200,
  },
  'coralogix.metadata.className': {
    headerName: 'Class',
    field: 'metadata.className',
    width: 200,
  },
  'coralogix.metadata.methodName': {
    headerName: 'Method',
    field: 'metadata.methodName',
    width: 200,
  },
  'coralogix.metadata.threadId': {
    headerName: 'ThreadId',
    field: 'metadata.threadId',
    width: 200,
  },
});

export const getLogsColumnsState = (): IColumnsState => ({
  index: {
    colId: 'index',
    hide: false,
    aggFunc: null,
    width: 75,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
  'coralogix.timestamp': {
    colId: 'timestamp',
    hide: false,
    aggFunc: null,
    width: 200,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
  ratio: {
    colId: 'ratio',
    hide: false,
    aggFunc: null,
    width: 200,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
  occurrences: {
    colId: 'occurrences',
    hide: false,
    aggFunc: null,
    width: 200,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
  'coralogix.metadata.severity': {
    colId: 'metadata.severity',
    hide: false,
    aggFunc: null,
    width: 120,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
  'coralogix.metadata.companyId': {
    colId: 'metadata.companyId',
    hide: false,
    aggFunc: null,
    width: 200,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
  'coralogix.text': {
    colId: 'text',
    hide: false,
    aggFunc: null,
    width: 800,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
  'coralogix.metadata.applicationName': {
    colId: 'metadata.applicationName',
    hide: false,
    aggFunc: null,
    width: 200,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
  'coralogix.metadata.subsystemName': {
    colId: 'metadata.subsystemName',
    hide: false,
    aggFunc: null,
    width: 200,
    pivotIndex: null,
    pinned: null,
    rowGroupIndex: null,
    flex: 0,
  },
});

export const getSidebarCol = (): { colDef: ColDef; colState: ColumnState } => {
  const width = 0;
  return {
    colDef: {
      headerName: 'sideMenu',
      headerComponentFramework: SideMenuHeaderRendererComponent,
      colId: 'sideMenu',
      resizable: false,
      width,
      maxWidth: width,
      cellRenderer: 'sideMenuRenderer',
    },
    colState: {
      colId: 'sideMenu',
      hide: false,
      aggFunc: null,
      width,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
      flex: 0,
    },
  };
};
