<div class="container" (click)="sortColumn()">
  <div class="name" data-test="sh-custom-grid-header-name" [matTooltip]="tooltipText" matTooltipClass="coralogix-tooltip">
    {{ params.displayName }}
  </div>
  <div
    class="sorting-indication"
    [class.sorting-indication-asc]="params.column.getSort() === 'asc'"
    [class.sorting-indication-desc]="params.column.getSort() === 'desc'"
    [class.sorting-indication-active]="activeSortModel$ ? (activeSortModel$ | async)?.field === gridColId : (isSorted$ | async)"
  ></div>
  <mat-icon
    class="clear-icon"
    data-test="sh-custom-grid-header-delete"
    (click)="removeColumn(); $event.stopPropagation()"
    *ngIf="params.context.parent.showDeleteColumnIcon"
  >
    clear
  </mat-icon>
</div>
