import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsEditorComponent } from './metrics-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/shared/material/material.module';
import { MetricsEditorDetailsModule } from '../metrics-editor-details/metrics-editor-details.module';
import { MetricsEditorAggDefModule } from '../metrics-editor-agg-def/metrics-editor-agg-def.module';
import { FieldExtractionSelectorModule } from '../field-extraction-selector/field-extraction-selector.module';
import { PermutationsSettingsComponent } from '../permutations-settings/permutations-settings.component';

@NgModule({
  declarations: [MetricsEditorComponent, PermutationsSettingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MetricsEditorDetailsModule,
    MetricsEditorAggDefModule,
    FieldExtractionSelectorModule,
  ],
  exports: [MetricsEditorComponent],
})
export class MetricsEditorModule {}
