import { GenericMetadataType, Metadata } from '@app/logs/shared/models/query-metadata';

export class GenericMetadataRequest {
  public queryDef: MetadataFilter;

  constructor(field: string, filters: GenericMetadataType) {
    this.queryDef = new MetadataFilter(field, filters);
    this.queryDef.queryParams.query = { field };
    this.queryDef.queryParams.filter = filters;
  }
}

export class MetadataFilter {
  public queryParams: MetadataQueryParams;
  public type: string = 'metadataAutocomplete';

  constructor(field: string, filters: GenericMetadataType) {
    this.queryParams = new MetadataQueryParams(field, filters);
  }
}

export class MetadataQueryParams {
  public query: {
    field: string;
  };
  public filter: GenericMetadataType;
  constructor(field: string, filters: GenericMetadataType) {
    this.query = { field };
    this.filter = filters;
  }
}
