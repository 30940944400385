import { jsonObject, jsonMember } from 'typedjson';
import * as _ from 'lodash';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class AlertESInfoModel {
  @jsonMember(TypedJsonAny.OPTIONS)
  public hits: any = {};
  @jsonMember
  public fromTimestamp: number;
  @jsonMember
  public toTimestamp: number;

  public hasHits(): boolean {
    if (Object.keys(this.hits).every(field => !_.isNumber(this.hits[field]) && _.isEmpty(this.hits[field]))) {
      return false;
    }
    const multiLevelGroupBy = Array.isArray(this.hits);
    if (multiLevelGroupBy && this.hits.every(field => field.hitCount <= 0)) {
      return false;
    }
    return true;
  }

  public isNestedGroupBy(): boolean {
    return Array.isArray(this.hits) && this.hits.length > 0;
  }

  public getGroupByFields(): string[] {
    if (!this.isNestedGroupBy()) {
      return Object.keys(this.hits);
    }

    const staticNames = ['nestedAggs', 'hitCount'];
    return this.getGroupByFieldsRec(this.hits, staticNames, []);
  }

  public getGroupByValues(): string[][] {
    const groupByFields = this.getGroupByFields();

    if (!this.isNestedGroupBy()) {
      // Since this isn't nested, we only have 1 field
      const groupByFieldName = groupByFields[0];
      return Object.keys(this.hits[groupByFieldName]).map(val => [val]);
    }

    const nestedGroupValues = [];
    this.hits.forEach(agg => {
      this.populateNestedValueListRec(agg, [], groupByFields, nestedGroupValues);
    });

    return nestedGroupValues;
  }

  private getGroupByFieldsRec(currentLevel: any, staticNames: string[], groupByFields: string[]): string[] {
    if (_.isNil(currentLevel)) {
      return groupByFields;
    }

    // Here currentLevel[0] is used because ALL of the entries on a level have the SAME groupByField name
    const currentLevelGroupByField = Object.keys(currentLevel[0]).find(name => !staticNames.includes(name));

    groupByFields.push(currentLevelGroupByField);

    return this.getGroupByFieldsRec(currentLevel[0].nestedAggs, staticNames, groupByFields);
  }

  private populateNestedValueListRec(currentLevel: any, currentValueList: string[], groupByFields: string[], valueList: string[][]): void {
    // groupByFields[0] always contains the current groupByField level (since we slice it when we make the recursive call)
    currentValueList = currentValueList.concat(currentLevel[groupByFields[0]]);

    if (_.isNil(currentLevel.nestedAggs)) {
      valueList.push(currentValueList);
      return;
    }
    currentLevel.nestedAggs.forEach(agg => {
      this.populateNestedValueListRec(agg, currentValueList, groupByFields.slice(1), valueList);
    });
  }
}
