<div class="container">
  <div *ngIf='(isLoading$ | async)' class="loader-container">
    <div class="ui active inline loader"></div>
  </div>
  <div class="no-files" *ngIf='!(hasFilesToDownload$ | async) && !(isLoading$ | async)'>
    No logs to preview
  </div>
  <div class="content" *ngIf="(hasFilesToDownload$ | async)">
    <div class="title">The following files will be downloaded:</div>
    <div class="files-list">
      <div *ngFor="let file of (files$ | async)" class="file-item">
        <div class="file-details">
          <span>{{file.fileName}}</span>&nbsp;
          <span class="file-size">({{ file.size | dataSize }})</span>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button class="coralogix button dialog-cancel-button" (click)="onNoClick()">Cancel</button>
    <button class="coralogix button dialog-delete-button" [hidden]="!(hasFilesToDownload$ | async) || (isLoading$ | async)" (click)="downloadClicked()">Download</button>
  </div>
</div>
