export const severityMap: { [key: string]: string } = {
  1: 'Debug',
  2: 'Verbose',
  3: 'Info',
  4: 'Warning',
  5: 'Error',
  6: 'Critical',
};

export const severityColors = ['#b5b5b5', '#68ee9a', '#32bdf4', '#ffc400', '#f04879', '#d423bb'];
export const severityColorMap = {
  Debug: '#b5b5b5',
  Verbose: '#68ee9a',
  Info: '#32bdf4',
  Warning: '#ffc400',
  Error: '#f04879',
  Critical: '#d423bb'
}
