<div>
  <div class="empty-list-header">
    <div class="header-content">
      <div class="icon-wrapper">
        <div class="icon-circle">
          <img src="assets/roi/reindex-empty.png" alt="" />
        </div>
      </div>
      <h3>You do not have any archive queries to show.</h3>
      <p>
        <span>Query your AWS S3 archive.</span>
      </p>
      <div>
        <cgx-button
          label="ARCHIVE QUERY"
          size="lg"
          [srcIconStartStyle]="{ width: 24, height: 24 }"
          srcIconStart="assets/roi/icon-reindex.svg"
          width="216px"
          [disabled]="btnDisabled"
          (press)="openEditor.emit()"
        ></cgx-button>
      </div>
    </div>
  </div>
</div>
