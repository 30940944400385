import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersFormatPipe } from './numbers-format.pipe';

@NgModule({
  declarations: [NumbersFormatPipe],
  imports: [
    CommonModule
  ],
  exports: [NumbersFormatPipe]
})
export class NumbersFormatPipeModule { }
