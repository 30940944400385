import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Hide, Show } from '@app/ngxs-store/loading-bar/loading-bar.action';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { LoadingBarState } from '@app/ngxs-store/loading-bar/loading-bar.state';

@Component({
  selector: 'sh-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  @Select(LoadingBarState.loading) public loading$: Observable<boolean>;

  public destroyed$: Subject<void> = new Subject();
  constructor(private router: Router) { }

  public ngOnInit(): void {
    this.navigationListener();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public navigationListener(): void {
    this.router.events.pipe(
      filter((event) => {
        return (
          event instanceof NavigationStart ||
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        );
      }),
      takeUntil(this.destroyed$)
    ).subscribe((event: NavigationStart | NavigationEnd | NavigationCancel | NavigationError) => {

      if (event instanceof NavigationStart) {
        this.show(event.url);
      } else {
        this.hide();
      }
    });
  }

  @Dispatch() public show = (path: string) => new Show(path);
  @Dispatch() public hide = () => new Hide();
}
