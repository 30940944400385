import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { insightsFilter } from '../../shared/models/insight-filter-model';
import { InsightSeverity } from '../../shared/models/enums';
import * as _ from 'lodash';
import { UserSettingsProvider } from '../../../user/shared/userSettingsProvider';
import { Subscriber, Subscription } from 'rxjs';

@Component({
  selector: 'sh-insight-filter',
  templateUrl: './insight-filter.component.html',
  styleUrls: ['insight-filter.component.scss'],
})
export class InsightFilterComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() set filterType(value: string) {
    if (value && value.split('-').length > 1) {
      const sevValue = value.split('-')[1];

      if (sevValue == 'info') {
        this.filters.severities = [InsightSeverity.Info];
      } else if (sevValue == 'warning') {
        this.filters.severities = [InsightSeverity.Warning];
      } else if (sevValue == 'critical') {
        this.filters.severities = [InsightSeverity.Critical];
      }
    }
    if (value && value.split('-')[0] == 'ALERTS') {
      this.filters.showAnomalies = false;
      this.filters.showAlerts = true;
      this.emitFilterChanged();
    } else if (value && value.split('-')[0] == 'ANOMALIES') {
      this.filters.showAnomalies = true;
      this.filters.showAlerts = false;
      this.emitFilterChanged();
    }
  }
  public filters: insightsFilter;
  @Output() public filterChanged: EventEmitter<
    insightsFilter
  > = new EventEmitter<insightsFilter>();
  public severity = InsightSeverity;
  public userSettingsChangedSubscription: Subscription;

  constructor(private userSettingsProvider: UserSettingsProvider) {
    this.filters = new insightsFilter();
    this.filters.severities = [
      InsightSeverity.Info,
      InsightSeverity.Warning,
      InsightSeverity.Critical,
    ];
    this.filters.showAlerts = true;
    this.filters.showAnomalies = true;
    this.filters.showActiveInsights = true;
    this.filters.showMutedInsights = false;

    if (this.userSettingsProvider.userSettings) {
      this.filters.queryMetadata = this.userSettingsProvider.userSettings.queryMetadata;
    }
  }

  public clearFilters() {
    this.filters.severities = [
      InsightSeverity.Info,
      InsightSeverity.Warning,
      InsightSeverity.Critical,
    ];
    this.filters.showAlerts = true;
    this.filters.showAnomalies = true;
    this.filters.showActiveInsights = true;
    this.filters.showMutedInsights = false;

    if (this.userSettingsProvider.userSettings) {
      this.filters.queryMetadata = this.userSettingsProvider.userSettings.queryMetadata;
    }
    this.emitFilterChanged();
  }

  public ngOnInit() {
    this.userSettingsChangedSubscription = this.userSettingsProvider.userSettingsChanged.subscribe(
      (settings) => {
        this.filters.queryMetadata = settings.queryMetadata;
        this.emitFilterChanged();
      },
    );
  }

  public ngOnDestroy() {
    this.userSettingsChangedSubscription.unsubscribe();
  }

  public ngAfterViewInit() {
    this.emitFilterChanged();
  }

  public isSeveritySelected(severity: InsightSeverity) {
    return this.filters.severities.indexOf(severity) !== -1;
  }

  public onShowAnomaliesClick() {
    this.filters.showAnomalies = !this.filters.showAnomalies;
    this.emitFilterChanged();
  }

  public onShowAlertsClick() {
    this.filters.showAlerts = !this.filters.showAlerts;
    this.emitFilterChanged();
  }

  public onShowActiveInsightsClick() {
    this.filters.showActiveInsights = !this.filters.showActiveInsights;
    this.emitFilterChanged();
  }

  public onShowMutedInsightsClick() {
    this.filters.showMutedInsights = !this.filters.showMutedInsights;
    this.emitFilterChanged();
  }

  public onSeverityClick(severity: InsightSeverity) {
    const severityIndex = this.filters.severities.indexOf(severity);
    severityIndex === -1
      ? this.filters.severities.push(severity)
      : this.filters.severities.splice(severityIndex, 1);
    this.emitFilterChanged();
  }

  /*
   * emit new filter object to keep pipe pure
   */
  public emitFilterChanged() {
    const newFilter = _.cloneDeep(this.filters);
    this.filterChanged.emit(newFilter);
  }
}
