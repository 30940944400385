import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogTitleComponent } from './dialog-title.component';

@NgModule({
  declarations: [DialogTitleComponent],
  imports: [
    CommonModule,
  ],
  exports: [DialogTitleComponent]
})
export class DialogTitleModule { }
