import { createAction, props } from '@ngrx/store';
import { IPredefinedTimeQuery } from '@app/logs-new/shared/models/predefined-time-queries.model';
import { Metadata } from '@app/logs-new/shared/models/query-metadata';
import { ILogQueries, ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { LogsSortingType } from '@app/logs-new/shared/models/sort.model';
import { ILog } from '@app/logs-new/shared/models/logs-data.model';
import { IAddSelectedQueryFieldEvent, LogFilterType } from '@app/logs-new/shared/models/logs-filter.model';
import { ISelectedLog } from '@app/logs-new/shared/models/query-params.model';
import { ISearchQueryEvent } from '@app/logs-new/shared/models/search.model';
import { ILogsGraphOptions } from '@app/logs-new/shared/models/graph/logs-graph.model';
import { IAutoRefreshItem } from '@app/logs-new/shared/models/time/automatic-refresh.model';

export const setPredefinedTimeQueryAction = createAction(
  '[Logs Queries V2] Set Predefined Query',
  props<{ payload: IPredefinedTimeQuery; correlationId: string | number }>(),
);

export const setPredefinedTimeQueryFailAction = createAction(
  '[Logs Queries V2] Set Predefined Query Failed',
  props<{ error: string; correlationId?: string | number }>(),
);

export const setPredefinedTimeQuerySuccessAction = createAction(
  '[Logs Queries V2] Set Predefined Query Success',
  props<{ payload: IPredefinedTimeQuery; correlationId?: string | number }>(),
);

export const setQueryRangeSuccessAction = createAction('[Logs Queries V2] Set Query Range Success', props<{ payload: number }>());

export const getQueryRangeAction = createAction('[Logs Queries V2] Get Query Range');

export const sortQueryAction = createAction('[Logs Queries V2] Sort Query', props<{ payload: { sort: LogsSortingType; field: string } }>());

export const getLogsQueryAction = createAction('[Logs Queries V2] Get Log Query', props<{ payload: string; firstTime?: boolean }>());

export const setLogsQueryAction = createAction(
  '[Logs Queries V2] Set Log Query',
  props<{ payload: ILogQuery; correlationId?: string | number }>(),
);

export const getLogsQueryFailAction = createAction('[Logs Queries V2] Get Queries Fail', props<{ error: string }>());

export const refreshQueryAction = createAction(
  '[Logs Queries V2] Refresh query',
  props<{ payload: IAutoRefreshItem; showRefreshQueryBar?: boolean }>(),
);

export const viewSurroundingLogsAction = createAction(
  '[Logs Queries V2] View Surrounding Logs',
  props<{
    log: ILog;
    seconds: number;
    storeLogInQuery?: boolean;
  }>(),
);

export const viewSurroundingArchiveLogsAction = createAction(
  '[Logs Queries V2] View Surrounding Archive Logs',
  props<{
    log: ILog;
    seconds: number;
    storeLogInQuery?: boolean;
  }>(),
);

export const resetSurroundingLogsAction = createAction('[Logs Queries V2] reset Surrounding Archive Logs');

export const refreshQuerySuccessAction = createAction(
  '[Logs Queries V2] Refresh Query Success',
  props<{ payload: IAutoRefreshItem; showRefreshQueryBar?: boolean }>(),
);

export const updateLogsQueryFieldsAction = createAction(
  '[Logs Queries V2] Update Query Fields',
  props<{ payload: { [key: string]: boolean }; correlationId?: string | number; isLocal?: boolean }>(),
);

export const updateLogsQueryExternalFieldsAction = createAction(
  '[Logs Queries V2] Update Query External Fields',
  props<{ payload: { [key: string]: { [key: string]: boolean } } }>(),
);

export const updateLogsQueryFieldsSuccessAction = createAction(
  '[Logs Queries V2] Update Query Metadata Success',
  props<{
    payload: { metadata: Partial<Metadata>; jsonObject: { [key: string]: any[] } };
    correlationId?: string | number;
  }>(),
);

export const updateLogsQueryFieldsFailAction = createAction(
  '[Logs Queries V2] Update Query Metadata Fail',
  props<{ error: string; correlationId?: string | number }>(),
);

export const createNewQueryAction = createAction('[Logs Queries V2] Create Query', (resetQueryIndex = true, persistQueryId = false) => ({
  resetQueryIndex,
  persistQueryId,
}));

export const addToSearchQueryAction = createAction('[Logs Queries V2] Add To Search Query', props<ISearchQueryEvent>());

export const setSearchQueryAction = createAction('[Logs Queries V2] Set Search Query', props<{ payload: string; localOnly?: boolean }>());

export const resetSearchQueryAction = createAction('[Logs Queries V2] Reset Search Query');

export const setRecentQueriesAction = createAction('[Logs Queries V2] Set Recent Queries', props<{ payload: string[] }>());

export const performQueryAction = createAction(
  '[Logs Queries V2] Perform Queries',
  props<{ payload: ILogQueries; queryId?: string; persistQueryId?: boolean; correlationId?: string }>(),
);

export const resetQueryAction = createAction('[Logs Queries V2] Reset Query');

export const addSelectedQueryField = createAction(
  '[Logs Queries V2] Add Selected Query Field',
  props<{ payload: IAddSelectedQueryFieldEvent; correlationId?: string }>(),
);

export const performQueryFailAction = createAction(
  '[Logs Queries V2] Perform Queries Fail',
  props<{ error: string; correlationId?: string }>(),
);

export const addQueryField = createAction(
  '[Logs Queries V2] Add Query Field',
  props<{ payload: { colField: string; filterType: LogFilterType; isLocal?: boolean } }>(),
);

export const deleteQueryField = createAction(
  '[Logs Queries V2] Delete Query Field',
  props<{ payload: { colField: string; filterType: LogFilterType; isLocal?: boolean } }>(),
);

export const resetQueryFields = createAction('[Logs Queries V2] Reset Query Fields');

export const logsPageChangeQuery = createAction(
  '[Logs Queries V2] Get Logs Page Query',
  props<{ payload: { pageIndex: number; pageSize: number; persistQueryId?: boolean } }>(),
);

export const setShowRefreshQueryBar = createAction('[Logs Queries V2] Set Show Refresh Query Bar', props<{ payload: boolean }>());

export const toggleLogSelection = createAction('[Logs Queries V2] Toggle Log Selection', props<{ payload: ISelectedLog }>());

export const updateLogSelection = createAction('[Logs Queries V2] Update Log Selection', props<{ payload: ILog[] }>());

export const focusLogAction = createAction('[Logs Queries V2] Focus On Log', props<{ payload: ISelectedLog }>());

export const clearSelectedLogsAction = createAction('[Logs Queries V2] Clear Selected Logs', props<{ payload: ISelectedLog }>());

export const setGraphOptionsAction = createAction('[Logs Queries V2] Set Graph Options', props<{ payload: ILogsGraphOptions }>());
