import { Injectable } from '@angular/core';
import {
  ChartModel,
  ChartSeriesModel,
} from '../../../shared/controls/charts/models/chart.model';
import { StatisticsQueryDefinition } from '../models/statistics-query-definition';
import { StatisticsResult } from '../models/statistics-logs.response';

@Injectable()
export class StatisticsChartService {
  /**
   * param: statisticsResult - The statistics result from server (data, stats, type...)
   * param: queryDefinition - The statistics query definition (queryModel, type, category)
   * return: chart model
   */
  public getChartModel(
    statisticsResult: StatisticsResult,
    queryDefinition: StatisticsQueryDefinition,
  ): ChartModel {
    const chartModel = new ChartModel();
    chartModel.series = new Array<ChartSeriesModel>();
    if (statisticsResult) {
      chartModel.series = new Array<ChartSeriesModel>();
      chartModel.xType = 'category';
      chartModel.yAxisEnable = true;
      chartModel.inverted = true;
      chartModel.stacking = null;
      chartModel.sharedTooltip = false;
      const arraysCategoriesLength: number = Object.keys(
        statisticsResult.data.xAxis.values,
      ).length;
      const arraysSeriesLength: number = Object.keys(
        statisticsResult.data.yAxis.values,
      ).length;
      const xCategories = [];
      // Parse categories to Datetime
      const series = new ChartSeriesModel();
      series.name = 'json-parameter';
      series.color = 'rgba(93, 173, 226, 1)';
      series.data = [];
      for (let i = 0; i < arraysCategoriesLength; i++) {
        if (statisticsResult.data.yAxis.values[i]) {
          xCategories[i] = statisticsResult.data.xAxis.values[i];
          series.data.push([
            xCategories[i],
            statisticsResult.data.yAxis.values[i],
          ]);
        }
      }
      series.type = 'column';
      chartModel.series.push(series);
      chartModel.categories = xCategories;
    }
    chartModel.enableCopyClipboard = true;
    return chartModel;
  }
}
