import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { JsonFormatterComponent } from '@app/logs-new/shared/features/logs-new-grid/components/grid-json-formatter/grid-json-formatter.component';

@Component({
  selector: 'sh-logs-formatter-renderer',
  templateUrl: 'logs-formatter-renderer.component.html',
  styleUrls: ['logs-formatter-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsFormatterRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  @ViewChild('jsonFormatterRenderer') public jsonFormatterRenderer: JsonFormatterComponent;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public refresh(params: any): boolean {
    this.jsonFormatterRenderer.refresh();
    return true;
  }
}
