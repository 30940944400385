<form *ngIf="!samlInfo?.has_saml" class="sso-form" [formGroup]="loginSsoForm">
  <h3 class="auth-sub-title">
    Enter your team name at Coralogix
  </h3>
  <sh-team-name-control [formGroup]="loginSsoForm" controlName="teamUrl"></sh-team-name-control>
  <div class="action-buttons" fxLayout="column" fxLayoutAlign="space-between center">
    <button [disabled]="isLoading" (click)="submit(loginSsoForm.value)" class="sh-login-btn-full action-buttons__button">
      <span *ngIf="!isLoading">Continue</span>
      <div *ngIf="isLoading" class="ui active tiny inline loader sh-loader"></div>
    </button>
    <div class="action-buttons__or-separator">
      Or
    </div>
    <a [routerLink]="['/login/user']" class="sh-login-link-btn action-buttons__button" data-test="login-with-credentials">
      Login with credentials
    </a>
  </div>
</form>

<div *ngIf="samlInfo?.has_saml" class="saml-login-container">
  <div class="saml-login-container__info">
    This team requires you to sign in with your
    {{ samlInfo?.idp_info ? samlInfo.idp_info.label : 'identity provider' }}
    account
  </div>

  <div class="action-buttons" fxLayout="column" fxLayoutAlign="space-between center">
    <button (click)="continueToIdp()" class="sh-login-btn-full action-buttons__button saml-login-container__redirect-btn">
      <i class="key icon"></i>
      <span *ngIf="samlInfo?.idp_info">Sign in with {{ samlInfo?.idp_info.label }}</span>
      <span *ngIf="!samlInfo.idp_info">
        Sign in to the identity provider
      </span>
    </button>
    <div class="action-buttons__or-separator">
      Or
    </div>
    <a [routerLink]="['/login/user']" class="sh-login-link-btn action-buttons__button" data-test="login-with-credentials">
      Login with credentials
    </a>
  </div>
</div>
