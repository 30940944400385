import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Entity } from '@shared/models/entity.model';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { TimeZoneType } from '@shared/models/timezone-types';
import * as moment from 'moment';

@Component({
    selector: 'sh-alerts-schedule',
    templateUrl: './alerts-schedule.component.html',
    styleUrls: ['./alerts-schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class AlertsScheduleComponent implements OnInit {
    @Input()
    public isEdit: boolean;
    @Input()
    public form: FormGroup;
    @Input()
    public weekDayOptions: Entity[];
    public timeZoneSubHeader: string = '';
    public limitTriggering: boolean;
    public isOpened: boolean;

    public disabled: boolean;

    constructor(private store: Store<State>) {
      this.store.select(getTimezoneViewPreference).take(1).subscribe(tmz => {
        this.timeZoneSubHeader = tmz === TimeZoneType.local ? '(Local Time)' : '(UTC)';
      });
    }

    public ngOnInit(): void {
        this.limitTriggering = this.form.get('limitTriggering').value;
        if (this.isEdit) {
            this.isOpened = true;
            this.disabled = true;
        }
    }

    public afterExpand(): void {
        this.disabled = true;
    }

    public onExpand(): void {
        if (!this.isEdit) {
            this.form.get('limitTriggering').patchValue(true);
            this.limitTriggering = true;
        }
    }

    public get showNextDay(): boolean {
        if (!this.form) {
            return false;
        }
        const from = this.form.value.activityStarts;
        const to = this.form.value.activityEnds;
        return moment(from, 'HH:mm:ss').toDate() > moment(to, 'HH:mm:ss').toDate();
    }
}
