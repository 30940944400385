import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unescapeForwardSlashes',
})
export class UnescapeForwardSlashesPipe implements PipeTransform {
  public transform(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }
    return value.replace(/\\\//g, '/');
  }
}
