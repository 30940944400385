import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */

const typeCache: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
  if (typeCache[label as string]) {
    throw new Error(`Action type "${label}" is not unique"`);
  }

  typeCache[label as string] = true;

  return label as T;
}

export function markFormAsDirty(formGroup: FormGroup): void {
  Object.keys(formGroup.controls).forEach(key => {
    formGroup.controls[key].markAsDirty();
  });
}

export const escapeHTML = (str: string) =>
  str.replace(
    /[&<>'"]/g,
    tag =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;',
      }[tag] || tag),
  );

export function byValue<V>(obj: { [key: number]: V }, compare?: (x: V, y: V) => boolean): (v: V) => string {
  return (v: V) => {
    return Object.keys(obj)
      .filter(k => (compare ? compare(v, obj[k]) : v === obj[k]))
      .pop();
  };
}

/* Escape JSON special chars */
export const escapeString = (val: string): string => {
  if (typeof val !== 'string') {
    return val;
  }
  return val
    .replace(/[\\]/g, '\\\\')
    .replace(/[\/]/g, '\\/')
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\n]/g, '\\n')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t')
    .replace(/[\"]/g, '\\"')
    .replace(/\\'/g, "\\'");
};

export const turnQueryParamsObjToStr = (params: Params) => {
  return Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&');
};

export const sanitizeNumber = (num: number): number => {
  return isFinite(num) ? num : 0;
};
