import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ChartModel, ChartSeriesModel, PlotlineModel } from '@shared/controls/charts/models/chart.model';
import { StatisticService } from '../../../shared/services/statistics-service';
import { Observable } from 'rxjs/Observable';
import { StatisticsQueryDefinition, StatisticsCategory } from '../../../shared/models/statistics-query-definition';
import * as moment from 'moment';
import { SeveritiesHelper } from '@shared/services/severities.helper';
import { formatsHelper } from '@shared/services/formatsHelper';
import { StatisticsResult, XAxis, YAxis, Data } from '../../../shared/models/statistics-logs.response';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import * as _ from 'lodash';
import { InsightsService } from '@app/insights/shared/services/insights-service';
import { Insight } from '@app/insights/shared/models/insight.model';
import { InsightsQueryModel } from '@app/insights/shared/models/insights-query.model';
import { InsightsHelper } from '@app/insights/shared/helpers/insightsHelper';
import { InsightsMetadataFilterPipe } from '@app/insights/shared/pipes/insights-metadata-filter';
import { insightsFilter } from '@app/insights/shared/models/insight-filter-model';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { DeploymentService } from '@app/deployment/shared/services/deployment.service';
import { BuildResult } from '@app/deployment/models/build';
import { BasicChartComponent } from '@shared/controls/charts/basic-chart/basic-chart.component';
import { WidgetBaseComponent } from '@shared/components/widget-base-component';
@Component({
  selector: 'sh-statistics-chart',
  templateUrl: './statistics-chart.component.html',
  styleUrls: ['./statistics-chart.component.scss'],
})
export class StatisticChartComponent extends WidgetBaseComponent implements AfterViewInit {
  @Input() set redraw(value: boolean) {
    if (this.model) {
      const chartModel = _.cloneDeep<ChartModel>(this.model);
      this.model = chartModel;
    }
  }

  @Input() set queryDefinition(value: StatisticsQueryDefinition) {
    if (value) {
      this._statisticsQueryDefinition = value;
      if (
        value.category === (StatisticsCategory.LOGS || StatisticsCategory.LOGSAGGRERGATIOM) ||
        value.category === StatisticsCategory.SEVERITY
      ) {
        this.isLoading = true;
        Observable.zip(
          value.getBuilds ? this.deploymentService.getBuilds(value.logQeuryModel) : Observable.of([]),
          this.statisticService.getStats(value),
        )
          .first()
          .subscribe(
            ([builds, stats]: [BuildResult, StatisticsResult]) => {
              this.model = this.parseData(stats, value, builds);
              this.isLoading = false;
            },
            () => {
              this.isLoading = false;
            },
          );
      } else {
        this.isLoading = true;
        const queryModel = new InsightsQueryModel();
        if (value.logQeuryModel) {
          queryModel.startDate = value.logQeuryModel.startDate;
          queryModel.endDate = value.logQeuryModel.endDate;
        }

        this.insightsService
          .getInsights(queryModel)
          .first()
          .subscribe(
            (res) => {
              const insights: Array<Insight> = res.insights as Array<Insight>;
              const result: StatisticsResult = new StatisticsResult();

              result.data = new Data();
              result.data.xAxis = new XAxis();
              result.data.xAxis.type = 'integer';
              result.data.xAxis.values = [3, 2, 1];
              result.data.yAxis = new YAxis();
              result.data.yAxis.type = 'complex';
              result.data.yAxis.values = {};
              const date =
                moment()
                  .subtract(24, 'h')
                  .unix() * 1000;

              if (value.category === StatisticsCategory.ANOMALIES) {
                const anomalies = insights.filter(
                  (i) => InsightsHelper.isAnomaly(i) && i.isActive && i.timestamp > date,
                );
                const filter = new insightsFilter();
                this.getMetaDataFilter(filter, value);
                const filtered = new InsightsMetadataFilterPipe().transform(anomalies, filter);
                const groupedInsightsDict = _.groupBy(filtered, this.groupStats.bind(this));

                for (let i = 3; i > 0; i--) {
                  if (groupedInsightsDict[i]) {
                    result.data.yAxis.values[i] = groupedInsightsDict[i].length;
                  }
                }
              } else {
                const alerts = insights.filter((i) => !InsightsHelper.isAnomaly(i) && i.timestamp > date);
                const filter = new insightsFilter();
                this.getMetaDataFilter(filter, value);

                const filtered = new InsightsMetadataFilterPipe().transform(alerts, filter);
                const groupedInsightsDict = _.groupBy(filtered, this.groupStats.bind(this));
                for (let i = 3; i > 0; i--) {
                  if (groupedInsightsDict[i]) {
                    result.data.yAxis.values[i] = groupedInsightsDict[i].length;
                  }
                }
              }
              this.model = this.parseData(result, value, null);
            },
            () => {
              this.isLoading = false;
            },
            () => (this.isLoading = false),
          );
      }
    }
  }

  @Input() public zoomableChart: boolean = true;
  @Input() public clickable: boolean = false;
  @Input() public useAnimation: boolean = false;
  @Input() public infoBarClass: string;
  @Input() public color: string = 'white';
  @Input() public isCompact: boolean = false;
  @Input() public chartInnerSize: string = '65%';
  @Input() public fontSize: string = '18px';

  @Input() public disableQueryByUserSettings: boolean = false;
  @Input() public height: number;
  @Input() public alignToolTipRight: boolean = false;

  public _statisticsQueryDefinition: StatisticsQueryDefinition;

  @ViewChild('shBasicChart', { static: true }) public shBasicChart: BasicChartComponent;

  @Output() public chartTimeRangeSelected: EventEmitter<LogQueryModel> = new EventEmitter<LogQueryModel>();
  @Output() public chartClicked: EventEmitter<[LogQueryModel, StatisticsQueryDefinition]> = new EventEmitter<
    [LogQueryModel, StatisticsQueryDefinition]
  >();
  @Output() public basicChartInit: EventEmitter<BasicChartComponent> = new EventEmitter<BasicChartComponent>();

  constructor(
    private statisticService: StatisticService,
    private insightsService: InsightsService,
    private userSettingsProvider: UserSettingsProvider,
    private deploymentService: DeploymentService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.basicChartInit.emit(this.shBasicChart);
  }

  public onChartTimeRange(logQueryModel: LogQueryModel): void {
    this.chartTimeRangeSelected.emit(logQueryModel);
  }

  public onChartClicked(logQueryModel: LogQueryModel): void {
    this.chartClicked.emit([logQueryModel, this._statisticsQueryDefinition]);
  }

  public groupStats(insight: Insight): number {
    return insight.severity;
  }

  private getMetaDataFilter(filter: insightsFilter, value: StatisticsQueryDefinition): void {
    if (this.disableQueryByUserSettings) {
      filter.queryMetadata = value.logQeuryModel.queryParams.metadata;
    } else {
      filter.queryMetadata = this.userSettingsProvider.userSettings
        ? this.userSettingsProvider.userSettings.queryMetadata
        : null;
    }
  }

  private parseData(
    statisticsResult: StatisticsResult,
    queryDefinition: StatisticsQueryDefinition,
    build: BuildResult,
  ): ChartModel {
    const model = new ChartModel();
    model.fontSize = this.fontSize;
    model.titleY = 20;
    const arraysCategoriesLength: number = Object.keys(statisticsResult.data.xAxis.values).length;
    const arraysSeriesLength: number = Object.keys(statisticsResult.data.yAxis.values).length;
    const xCategories = [];
    const series: Array<ChartSeriesModel> = [];
    const colors = [];
    if (statisticsResult.data.xAxis.type === 'timestamp') {
      if (queryDefinition.seriesType === 'pie') {
        for (let i = 0; i < arraysCategoriesLength; i++) {
          xCategories[i] = SeveritiesHelper.getLogSeverityById(i + 7);
        }

        const serData = [];
        let sum = 0;

        for (let j = 0; j < arraysCategoriesLength; j++) {
          const value = _.sum(statisticsResult.data.yAxis.values[j]);
          if (value) {
            colors.push(SeveritiesHelper.getSeverityColor(j + 1));
            serData.push([SeveritiesHelper.getLogSeverityById(j + 1), value]);
            sum += value;
          }
        }
        const seriesModel = new ChartSeriesModel();
        seriesModel.data = serData;
        seriesModel.innerSize = this.chartInnerSize;
        seriesModel.type = queryDefinition.seriesType;
        if (serData.length > 0) {
          series.push(seriesModel);
          model.title = formatsHelper.numberKM(sum);
        } else {
          model.title = queryDefinition.getNoDataLabel();
        }
      } else {
        model.scale = this.getInterVal(statisticsResult.data.additionalData.scale);
        for (let i = 0; i < arraysCategoriesLength; i++) {
          xCategories[i] = this.ParseDateFromUnix(statisticsResult.data.xAxis.values[i]);
        }
        for (let j = 0; j < arraysSeriesLength; j++) {
          const xParsedValues = [];

          const severity = queryDefinition.logQeuryModel.queryParams.metadata.severity.find((s) => Number(s) === 6 - j);

          if (
            (queryDefinition.category === StatisticsCategory.LOGS ||
              queryDefinition.category === StatisticsCategory.SEVERITY) &&
            !severity &&
            queryDefinition.logQeuryModel.queryParams.metadata.severity.length > 0
          ) {
            continue;
          }

          for (let i = 0; i < arraysCategoriesLength; i++) {
            if (model.yType === 'logarithmic') {
              const value =
                statisticsResult.data.yAxis.values[j][i] === 0 ? 0.0001 : statisticsResult.data.yAxis.values[j][i];
              xParsedValues[i] = [xCategories[i], value];
            } else {
              xParsedValues[i] = [xCategories[i], statisticsResult.data.yAxis.values[j][i]];
            }
          }

          const ser = new ChartSeriesModel();
          ser.data = xParsedValues;
          ser.innerSize = this.chartInnerSize;
          if (
            queryDefinition.category === StatisticsCategory.LOGS ||
            queryDefinition.category === StatisticsCategory.SEVERITY
          ) {
            ser.name = SeveritiesHelper.getLogSeverityById(j + 1);
            colors.push(SeveritiesHelper.getSeverityColor(j + 1));
          } else {
            ser.name = SeveritiesHelper.getEventSeverityById(j);
            colors.push(SeveritiesHelper.getEventSeverityColor(j));
          }

          ser.type = queryDefinition.seriesType;

          series.push(ser);
        }
      }
    } else {
      for (let i = 0; i < arraysCategoriesLength; i++) {
        xCategories[i] = SeveritiesHelper.getLogSeverityById(statisticsResult.data.xAxis.values[i]);
      }

      const serData = [];
      let sum = 0;
      for (let j = 1; j < arraysCategoriesLength + 1; j++) {
        if (statisticsResult.data.yAxis.values[j]) {
          if (
            queryDefinition.category === StatisticsCategory.LOGS ||
            queryDefinition.category === StatisticsCategory.SEVERITY
          ) {
            colors.push(SeveritiesHelper.getSeverityColor(j + 1));
            serData.push([SeveritiesHelper.getLogSeverityById(j + 1), statisticsResult.data.yAxis.values[j]]);
          } else {
            colors.push(SeveritiesHelper.getEventSeverityColor(j));
            serData.push([SeveritiesHelper.getEventSeverityById(j), statisticsResult.data.yAxis.values[j]]);
          }

          sum += statisticsResult.data.yAxis.values[j];
        }
      }
      const seriesModel = new ChartSeriesModel();
      seriesModel.innerSize = this.chartInnerSize;
      seriesModel.data = serData;
      seriesModel.type = queryDefinition.seriesType;
      if (serData.length > 0) {
        series.push(seriesModel);
        model.title = formatsHelper.numberKM(sum);
      } else {
        model.title = queryDefinition.getNoDataLabel();
      }
    }

    model.series = series;
    model.categories = xCategories;
    model.xType = statisticsResult.data.xAxis.type;
    model.colors = colors;
    model.zoomable = this.zoomableChart;
    model.logQueryModel = queryDefinition.logQeuryModel;
    model.backgroundColor = 'transparent';
    model.fontColor = this.color;
    model.useAnimation = this.useAnimation;
    model.clickable = this.clickable;
    model.seriesType = queryDefinition.seriesType;
    model.tickLength = 0;
    model.alignToolTipRight = this.alignToolTipRight;
    if (this.height) {
      model.height = this.height;
    }
    if (this.infoBarClass) {
      model.infoBarClass = this.infoBarClass;
    }
    if (this.isCompact) {
      this.compactChart(model);
    }

    if (build && build.data && build.data.length > 0) {
      build.data.forEach((b) => {
        const plot = new PlotlineModel();
        plot.value =
          (b.tag_timestamp - statisticsResult.data.xAxis.values[0]) / statisticsResult.data.additionalData.scale;
        if (plot.value > xCategories.length - 1) {
          plot.value = xCategories.length - 1;
        }

        plot.color = 'transparent';
        plot.width = 1;
        plot.data = build;
        plot.id = b.id;
        plot.label = {
          align: 'left',
          rotation: undefined,
          style: undefined,
          text:
            '<img id="' + b.id + '" link="#/tags/tag/' + b.id + '" src="' + b.avatar + ' " class="plotline-label"/>',
          textAlign: undefined,
          useHTML: true,
          verticalAlign: 'top',
          x: undefined,
          y: undefined,
        };
        model.xPlotlines.push(plot);
      });
    }

    return model;
  }

  private ParseDateFromUnix(unixDate: number): Date {
    return moment.unix(unixDate / 1000).toDate();
  }

  private getInterVal(value: number): string {
    switch (value) {
      case 1000:
        return 's';
      case 60000:
        return 'm';
      case 3600000:
        return 'h';
    }
    return 'h';
  }

  private compactChart(model: ChartModel): void {
    model.useLabels = false;
    model.minorTickLength = 0;
    model.tickLength = 0;
    model.minorGridLineWidth = 0;
    model.lineWidth = 0;
    model.tooltipEnabled = false;
    model.backgroundColor = 'transparent';
    model.infoBarClass = 'chart-infoBar dark ';
    model.tooltipDateFormat = 'h:mm:ss';
    model.zoomType = null;
  }
}
