<div class="quick-query-container">
  <h2>
    Query shortcuts
  </h2>
  <div
    *ngFor="let quickQuery of predefineQueries.Queries"
    class="quick-query-value-label"
  >
    <span
      class="predefine-label"
      (click)="selectedValueChange.emit(quickQuery)"
    >
      {{ quickQuery.caption }}
    </span>
  </div>
</div>
