import { FieldExtraction } from './metric.model';
import { IConditionalSelectGroup } from '@app/shared/components/conditional-select/conditional-select.model';

export class MetricEditorControls {
  public metricName: string;
  public description: string;
  public applicationName: IConditionalSelectGroup[];
  public subsystemName: IConditionalSelectGroup[];
  public logSeverity: number[];
  public text: string;
  public groupBy: FieldExtraction[];
  public metricFields: FieldExtraction[];
  public permutationsLimit: number;

  constructor(init?: Partial<MetricEditorControls>) {
    Object.assign(this, init);
  }
}
