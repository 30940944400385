import { Metadata } from '@app/logs/shared/models/query-metadata';

export class SaveAlertFromQueryConfig {
     public text: string;
     public jsonObject?: any;
     public metadata?: Metadata;
     public queryType?: string;

     constructor(init: SaveAlertFromQueryConfig) {
          Object.assign(this, init);
     }
}
