<sh-coralogix-sidebar
  #sidebarComponent
  (close)="closeEditor()"
  [templateRef]="editor"
>
</sh-coralogix-sidebar>
<ng-template #editor>
  <div fxLayout="column" class="scrollable panel-container">

    <sh-page-sub-header class='header-section' [height]="'84px'" [title]="groupName">
      <mat-slide-toggle labelPosition="after"
                        class="coralogix rules-panel-activation-slider"
                        [formControl]="activeControl">
        <span class="slider-label-after">ACTIVE</span>
        <span class="slider-label-before">INACTIVE</span>
      </mat-slide-toggle>
      <button (click)="saveParsingTheme()"
              [disabled]="parsingThemeForm.invalid"
              class="button coralogix header-section__button {{isCreateMode? 'full-action-button__secondary' :'full-action-button__primary' }}">
        <span class="header-section__button-text">{{saveButtonText}}</span>
      </button>
    </sh-page-sub-header>

    <div class="panel-container__content" fxLayout="row" fxFlex="auto">
      <sh-main-pt-editor-content class="info-container" fxLayout="column" fxFlex="auto"
                                 [parsingThemeForm]="parsingThemeForm"
                                 (deleteParsingTheme)="handleDeleteAction()"
                                 [isCreateMode]="isCreateMode">
    </sh-main-pt-editor-content>
    </div>
  </div>
</ng-template>
