import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

const DEFAULT_TOOLTIP_POSITION: string = 'bottom right';

@Component({
  selector: 'sh-json-action-buttons',
  templateUrl: './json-action-buttons.component.html',
  styleUrls: ['json-action-buttons.component.scss'],
})
export class JsonActionButtonsComponent implements OnInit {
  @Input() public isCopyToClipboardEnabled: boolean = true;
  @Input() public isFiltersEnabled: boolean = false;
  @Input() public isAddWidgetEnabled: boolean = false;
  @Input() public tooltipPosition: string = DEFAULT_TOOLTIP_POSITION;
  @Output() public onFilterByValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCopyToClipbboard: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() public onShowWidget: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  public ngOnInit() {}
}
