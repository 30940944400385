<div class="grid-size-noTimeline">
  <sh-grid
    style="height: 100%; width: 100%;"
    [searchProvider]="insightsProvider.searchProvider"
    [showGridFilters]="false"
    [logsQueryData]="queryData"
    [(isOverlayVisible)]="isOverlayVisible"
    (gridReadyEvent)="onGridReady($event)"
    (dataLoadedEvent)="updateQueryLogCount($event)"
    (gridRowSelectedEvent)="onSelectedRow($event)"
    (textInfoClicked)="onGridButtonClick($event)"
  ></sh-grid>
</div>
