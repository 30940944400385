import { Routes } from '@angular/router';

import { InsightsComponent } from './insights.component';
import { InsightsLoggregationComponent } from './components/insights-loggregation/insights-loggregation.component';
import { AnomalyLoggregationComponent } from './components/anomaly-loggregation/anomaly-loggregation.component';
import { InsightsLogsComponent } from './components/insights-logs/insights-logs.component';
import { HerokuGurd } from '../shared/guards/heroku-payment.gurd';
import { AdvancedAlertComponent } from './components/advanced-alert/advanced-alert.component';
import { SimpleAlertComponent } from './components/simple-alert/simple-alert.component';
import { GroupByAlertComponent } from './components/group-by-alert/group-by-alert.component';
import { AuthGuard } from '@app/auth/shared/guards/auth.guard';
import { PaymentGuard } from '@app/shared/guards/payment.guard';

export const InsightsRoutes: Routes = [
  {
    path: 'insights',
    component: InsightsComponent,
    data: {
      title: 'Insights',
    },
    canActivate: [PaymentGuard, HerokuGurd, AuthGuard],
    children: [
      {
        path: '',
        component: InsightsLogsComponent,
      },

      {
        path: 'anomaly',
        data: {
          title: 'Insights',
        },
        component: AnomalyLoggregationComponent,
      },
      {
        path: 'loggregation',
        data: {
          title: 'Insights',
        },
        component: InsightsLoggregationComponent,
      },
      {
        path: 'groupbyalert',
        data: {
          title: 'Insights',
        },
        component: GroupByAlertComponent,
      },
      {
        path: 'advancedalert',
        component: AdvancedAlertComponent,
      },
      {
        path: 'newvalue',
        data: {
          title: 'Insights',
        },
        component: SimpleAlertComponent,
      },
      {
        path: 'alert',
        component: SimpleAlertComponent,
      },
      {
        path: 'ratioalert',
        data: {
          title: 'Insights',
        },
        component: GroupByAlertComponent,
      },
      {
        path: 'unique-count-alert',
        loadChildren: () => import('./components/cardinality-alert/cardinality-alert.module').then(m => m.CardinalityAlertModule),
      },
      {
        path: 'metricalert',
        loadChildren: () => import('./components/metric-alert/metric-alert.module').then(m => m.MetricAlertModule),
      },
    ],
  },
];
