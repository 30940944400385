import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getApplicationNames, getSubsystemNames, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'sh-metrics-editor-agg-def',
  templateUrl: './metrics-editor-agg-def.component.html',
  styleUrls: ['./metrics-editor-agg-def.component.scss']
})
export class MetricsEditorAggDefComponent {

  @Input()
  public form: FormGroup;
  @Input()
  public logsSeverityOptions: {id: number, name: string}[];
  @Input()
  public validationError: string;
  public applicationOptions$: Observable<string[]>;
  public subsystemOptions$: Observable<string[]>;

  constructor(private store: Store<State>) {
    this.applicationOptions$ = store.select(getApplicationNames);
    this.subsystemOptions$ = store.select(getSubsystemNames);
  }

  public get isFormValid(): boolean {
    return this.form.valid;
  }
}
