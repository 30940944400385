export class QueryTypes {
  public static FREE: string = 'freeText';
  public static RECORD: string = 'template';
  public static METADATA: string = 'metadataAutocomplete';
  public static LOGGREGATION: string = 'loggregation';
  public static EVENT: string = 'eventOffset';
  public static TEMPLATE_IDS: string = 'templateIds';
  public static TEMPLATE: string = 'template';
  public static NEWLOGS: string = 'newLogsQuery';
}

export enum EQueryTypesOptions {
  FREE = 'freeText',
  RECORD = 'template',
  METADATA = 'metadataAutocomplete',
  LOGGREGATION = 'loggregation',
  EVENT = 'eventOffset',
  TEMPLATE_IDS = 'templateIds',
  TEMPLATE = 'template',
  NEWLOGS = 'newLogsQuery'
}
