export interface IHashmapFuncOptions {
  array: any[];
  key?: string;
  value?: any;
  byItem?: boolean;
  lowerCaseKey?: boolean;
}

/* A function which parses the string and returns it as an object if the string is valid JSON
 OR returns the string if it's not a valid JSON */
export function checkAndParseJSON(str: string): string | object {
  try {
    if (typeof str !== 'string') {
      return str;
    }
    if (isNumber(str)) {
      return str;
    }
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export function isValidJSONString(str: string): boolean {
  try {
    if (typeof str !== 'string') {
      return false;
    }
    if (isNumber(str)) {
      return false;
    }
    return !!JSON.parse(str);
  } catch {
    return false;
  }
}

/* Execute a cb function after all synchronous code has been executed (usually used
 * to run code after Change Detection tick */
export function nextTick(executeFn: () => any): void {
  setTimeout(() => {
    return executeFn();
  }, 0);
}

export function isNumber(val: any): boolean {
  return typeof Number(val) === 'number' && isFinite(Number(val));
}

export function copyToClipboard(str: string): Promise<void> {
  return window.navigator.clipboard.writeText(str);
}

export function log10(n: number): number {
  return Math.log(n) / Math.log(10);
}

const createHashmap = ({ array, key, byItem, value, lowerCaseKey }: IHashmapFuncOptions) => {
  if (!Array.isArray(array)) {
    return {};
  }
  return array.reduce((acc, item) => {
    let mapKey = item?.[key] || item;
    if (typeof mapKey === 'string' && lowerCaseKey) {
      mapKey = mapKey.toLowerCase();
    }
    acc[mapKey] = byItem ? item : value;
    return acc;
  }, {});
};

export function createBoolHashMap({ array, key, lowerCaseKey }: IHashmapFuncOptions): { [key: string]: boolean } {
  return createHashmap({ array, key, value: true, lowerCaseKey });
}

export function createItemHashMap({ array, key }: IHashmapFuncOptions): { [key: string]: any } {
  return createHashmap({ array, key, byItem: true });
}

export function filterArrByQuery(arr: any[], query: string, field?: string): any[] {
  if (!query) {
    return arr;
  }

  function filterByQuery(filterField: string): boolean {
    return filterField?.toLowerCase().includes(query?.toLowerCase());
  }

  return arr.filter(item => filterByQuery(item?.[field] || item));
}

export const getPaginationToAndFrom = (pageIndex: number, pageSize: number): { from: number; to: number } => {
  const from = pageIndex * pageSize;
  const to = from + pageSize;
  return { from, to };
};
