import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from '@shared/directives/click-outside.module';
import { JsonFormatterMenuComponent } from '@app/logs-new/shared/features/new-json-formatter/json-formatter-menu/json-formatter-menu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { ShowTimeModule } from '@app/logs-new/shared/features/new-json-formatter/json-formatter-menu/show-time/show-time.module';
import { AllowAbilityModule } from '@app/logs-new/shared/directives/allow-ability.module';
import { CgxInputFieldModule, CgxTooltipModule } from '@coralogix/design';
import { CoralogixSidebarModule } from '@app/shared/popups/coralogix-sidebar/coralogix-sidebar.module';
import { MatDividerModule } from '@angular/material/divider';
import { PluginsContainerModule } from '@app/logs-new/components/plugins-container/plugins-container.module';
import { JsonFormatterClickOutsideDirective } from '@app/logs-new/shared/features/new-json-formatter/json-formatter-menu/json-formatter-click-outside.directive';

@NgModule({
  declarations: [JsonFormatterMenuComponent, JsonFormatterClickOutsideDirective],
  imports: [
    CommonModule,
    ClickOutsideModule,
    AngularSvgIconModule,
    MatTooltipModule,
    MatIconModule,
    ShowTimeModule,
    AllowAbilityModule,
    CgxTooltipModule,
    CgxInputFieldModule,
    CoralogixSidebarModule,
    MatDividerModule,
    PluginsContainerModule,
  ],
  exports: [JsonFormatterMenuComponent],
})
export class JsonFormatterMenuModule {}
