import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { ModalDirective } from '../../../directives/modal.directive';

@Component({
  selector: 'sh-yes-no-modal',
  templateUrl: './yes-no-modal.component.html',
  styleUrls: ['./yes-no-modal.component.scss'],
})
export class YesNoModalComponent implements OnInit {
  @Input() public message: string;
  @ViewChild(ModalDirective, { static: true })
  public modalDirectiveElm: ModalDirective;
  @Output() public onAnswer: EventEmitter<any> = new EventEmitter<any>();
  private showModalParameter: any;
  constructor() {}

  public ngOnInit() {}
  public showModal(value?) {
    this.showModalParameter = value;
    this.modalDirectiveElm.showModal();
  }
  public yes() {
    this.onAnswer.emit(this.showModalParameter);
    this.modalDirectiveElm.hideModal();
    this.showModalParameter = null;
  }

  public no() {
    this.modalDirectiveElm.hideModal();
    this.showModalParameter = null;
  }
}
