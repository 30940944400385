import { Pipe, PipeTransform } from '@angular/core';
import { formatsHelper } from '../services/formatsHelper';

@Pipe({
  name: 'numbersWithKFormat',
})
export class NumbersWithKFormatPipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    if (value) {
      if (args) {
        return formatsHelper.numberKM(value, args);
      } else {
        return formatsHelper.numberKM(value);
      }
    }

    return value;
  }
}
