import { createAction, props } from '@ngrx/store';
import { IGroup } from '@app/settings/account/components/groups/models/models';

// get all groups
export const getGroupsAction = createAction('[Groups] Get - Groups');
export const getGroupsCompleteAction = createAction('[Groups] Get - Groups completed', props<{ payload: {groups: IGroup[]} }>());
export const getGroupsFailedAction = createAction('[Groups] Get - Groups failed');

// update group scope
export const updateGroupDetailsAction = createAction('[Groups] Post - Edit group scope',
  props<{payload: {group: IGroup}}>());
export const updateGroupDetailsCompleteAction = createAction('[Groups] Post - Edit group scope complete',
  props<{payload: {group: IGroup}}>());
export const updateGroupDetailsFailedAction = createAction('[Groups] Post - Edit group scope failed', props<{ payload: {errMsg: string} }>());

// create group
export const createGroupAction = createAction('[Groups] Post - Create group', props<{ payload: {group: IGroup} }>());
export const createGroupActionCompleteAction = createAction('[Groups] Post - Create group completed', props<{ payload: {group: IGroup} }>());
export const createGroupActionFailedAction = createAction('[Groups] Post - Create group failed', props<{ payload: {errMsg: string} }>());

// delete group
export const deleteGroupAction = createAction('[Groups] Delete - Delete group', props<{ payload: {companyId: number, groupId: number} }>());
export const deleteGroupActionCompleteAction = createAction('[Groups] Delete - Delete group completed', props<{payload: {groupId: number}}>());
export const deleteGroupActionFailedAction = createAction('[Groups] Delete - Delete group failed');

// selected groups
export const updateSelectedGroupsAction = createAction('[Groups] Selected Groups', props<{ payload: string[] }>());
