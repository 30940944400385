import { Action } from '@ngrx/store';
import { type } from '../../../../shared/services/utils';
import { Log } from '../../../../logs/shared/models/log.entity';
import { Grok, GrokMatch } from '../../models/grok.model';

export namespace GroksActions {
  export const ActionTypes = {
    DETECT_GROKS: type('[Grok] detect groks'),
    DETECT_GROKS_COMPLETED: type('[Grok] detect groks completed'),
    DETECT_GROKS_FAILED: type('[Grok] detect groks failed'),
    ADD_GROKS_TO_TEAM: type('[Grok] add groks to team'),
    ADD_GROKS_TO_TEAM_COMPLETED: type('[Grok] add groks to team completed'),
    ADD_GROKS_TO_TEAM_FAILED: type('[Grok] add groks to team failed'),
  };

  export class DetectGroks implements Action {
    public type = ActionTypes.DETECT_GROKS;

    constructor(public payload: Log[]) {}
  }

  export class DetectGroksCompleted implements Action {
    public type = ActionTypes.DETECT_GROKS_COMPLETED;

    constructor(public payload: GrokMatch[]) {}
  }

  export class DetectGroksFailed implements Action {
    public type = ActionTypes.DETECT_GROKS_FAILED;

    constructor(public payload: any) {}
  }

  export class AddGroksToTeam implements Action {
    public type = ActionTypes.ADD_GROKS_TO_TEAM;

    constructor(public payload: Grok[]) {}
  }

  export class AddGroksToTeamCompleted implements Action {
    public type = ActionTypes.ADD_GROKS_TO_TEAM_COMPLETED;

    constructor(public payload: any) {}
  }

  export class AddGroksToTeamFailed implements Action {
    public type = ActionTypes.ADD_GROKS_TO_TEAM_FAILED;

    constructor(public payload: any) {}
  }
  export type Actions =
    | DetectGroks
    | DetectGroksCompleted
    | DetectGroksFailed
    | AddGroksToTeam
    | AddGroksToTeamCompleted
    | AddGroksToTeamFailed;
}
