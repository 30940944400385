import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/observable/of';
import { State } from '@app/app.reducers';
import { map, switchMap, tap } from 'rxjs/operators';
import * as alertActions from '@app/alerts/shared/actions/alerts.actions';
import { AlertsService } from '@app/alerts/shared/services/alerts-service';
import { AlertModel } from '@app/alerts/shared/models/alert-model';
import { InsightsService } from '@app/insights/shared/services/insights-service';
import { sortArrayByPropName, SortOrders } from '@shared/helpers/sorting.helper';
import { InsightsQueryModel } from '@app/insights/shared/models/insights-query.model';
import * as moment from 'moment';
import { Constants } from '@app/constants';
import { InsightsQueryResult } from '@app/insights/shared/models/insight-query.response';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { CreateNUpdateAlertModel } from '@app/alerts/shared/models/create-n-update-alert-model';
import { EditAlertResult, NewAlertResult } from '@app/alerts/shared/models/new-alert.response';
import { IPartialError } from '@app/alerts/shared/models/actions.models';
import { AlertsServiceHelper } from '@app/alerts/shared/services/alerts-service-helper';
import { updateAlertGraphLoadingAction } from '@app/alerts/shared/actions/alerts.actions';

@Injectable()
export class AlertsEffects {
  @Effect()
  public getAllAlerts$: Observable<Action> = this.actions$.pipe(
    ofType(alertActions.getAlertsAction),
    switchMap(() => {
      this.store.dispatch(alertActions.updateAlertTableLoadingAction({ payload: true }));
      return this.alertsService
        .getAlerts()
        .pipe(
          map(data => sortArrayByPropName(data, ['createDate'], [SortOrders.desc])),
          switchMap((data: AlertModel[]) => {
            return of(alertActions.getAlertsCompletedAction({ payload: data }));
          }),
        )
        .catch(err => {
          this.store.dispatch(alertActions.updateAlertTableLoadingAction({ payload: false }));
          return of(alertActions.getAlertsFailedAction(err));
        });
    }),
  );

  @Effect()
  public getAllAlertsInsights$: Observable<Action> = this.actions$.pipe(
    ofType(alertActions.getAlertsInsightsAction),
    switchMap(({ payload }) => {
      this.store.dispatch(updateAlertGraphLoadingAction({ payload: true }));
      const insightsQueryModel: InsightsQueryModel = new InsightsQueryModel();
      const MAX_ALERTS_PAGE_SIZE = 5000;
      insightsQueryModel.startDate =
        moment()
          .subtract(payload.seconds, 'seconds')
          .unix() * 1000;
      return this.insightsService
        .getInsights(insightsQueryModel, MAX_ALERTS_PAGE_SIZE)
        .pipe(
          switchMap((data: InsightsQueryResult) => {
            return of(alertActions.getAlertsInsightsCompletedAction({ payload: data.insights }));
          }),
        )
        .catch(err => {
          this.store.dispatch(alertActions.updateAlertTableLoadingAction({ payload: false }));
          return of(alertActions.getAlertsInsightsFailedAction(err));
        });
    }),
  );

  // updateAlertActions

  @Effect()
  public updateAlert$: Observable<Action> = this.actions$.pipe(
    ofType(alertActions.updateAlertAction),
    switchMap((action: { payload: CreateNUpdateAlertModel }) => {
      this.alertServiceHelper.setEditorLoaderState(true);
      return this.alertsService
        .editAlert(action.payload)
        .map((data: EditAlertResult) => alertActions.updateAlertCompletedAction(action))
        .catch((err: IPartialError) => of(alertActions.updateAlertFailedAction({ payload: err })));
    }),
  );

  @Effect({ dispatch: false })
  public updateAlertCompletedAction$: Observable<any> = this.actions$.pipe(
    ofType(alertActions.updateAlertCompletedAction),
    tap(data => this.alertServiceHelper.dispatchUpdateAlertsInitialData()),
    tap((action: { payload: CreateNUpdateAlertModel }) => {
      const routingLink = this.alertServiceHelper.getAlertsCreateOrUpdateRoutingLink(action.payload.id as string);
      this.alertServiceHelper.closeAlertPanelAndShowMessage('Alert was successfully updated', null, routingLink);
    }),
  );

  @Effect()
  public updateAlertFailed$: Observable<any> = this.actions$.pipe(
    ofType(alertActions.updateAlertFailedAction),
    switchMap((action: { payload: IPartialError }) => {
      return of(this.alertServiceHelper.handleAlertInvalidQueryAction(action.payload));
    }),
  );

  // createAlertActions

  @Effect()
  public createAlert$: Observable<Action> = this.actions$.pipe(
    ofType(alertActions.createAlertAction),
    switchMap((action: { payload: CreateNUpdateAlertModel }) => {
      this.alertServiceHelper.setEditorLoaderState(true);
      return this.alertsService
        .createNewAlert(action.payload)
        .pipe(map((data: NewAlertResult) => alertActions.createAlertCompletedAction({ payload: { ...action.payload, id: data.id[0] } })))
        .catch((err: IPartialError) => of(alertActions.createAlertFailedAction({ payload: err })));
    }),
  );

  @Effect({ dispatch: false })
  public createAlertCompletedAction: Observable<any> = this.actions$.pipe(
    ofType(alertActions.createAlertCompletedAction),
    tap(data => this.alertServiceHelper.dispatchUpdateAlertsInitialData()),
    tap((action: { payload: CreateNUpdateAlertModel }) => {
      const routingLink = this.alertServiceHelper.getAlertsCreateOrUpdateRoutingLink(action.payload.id as string);
      this.alertServiceHelper.closeAlertPanelAndShowMessage('Alert was successfully created', null, routingLink);
    }),
  );

  @Effect()
  public createAlertFailed$: Observable<any> = this.actions$.pipe(
    ofType(alertActions.createAlertFailedAction),
    switchMap((action: { payload: IPartialError }) => {
      return of(this.alertServiceHelper.handleAlertInvalidQueryAction(action.payload));
    }),
  );

  // deleteAlertActions

  @Effect()
  public deleteAlertAction$: Observable<Action> = this.actions$.pipe(
    ofType(alertActions.deleteAlertAction),
    switchMap((action: { payload: string }) => {
      this.alertServiceHelper.setEditorLoaderState(true);
      return this.alertsService
        .deleteAlert(action.payload)
        .map(data =>
          alertActions.deleteAlertCompletedAction({
            payload: action.payload,
          }),
        )
        .catch(err => of(alertActions.deleteAlertFailedAction()));
    }),
  );

  @Effect({ dispatch: false })
  public deleteAlertCompletedAction$: Observable<any> = this.actions$.pipe(
    ofType(alertActions.deleteAlertCompletedAction),
    tap(() => this.alertServiceHelper.dispatchUpdateAlertsInitialData()),
    tap(() => this.alertServiceHelper.closeAlertPanelAndShowMessage('Alert was successfully deleted')),
  );

  @Effect({ dispatch: false })
  public deleteAlertFailedAction$: Observable<any> = this.actions$.pipe(
    ofType(alertActions.deleteAlertFailedAction),
    tap(() => this.alertServiceHelper.closeAlertPanelAndShowMessage('Failed to delete alert', dialogServiceIconClasses.failed)),
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertsService: AlertsService,
    private insightsService: InsightsService,
    private alertServiceHelper: AlertsServiceHelper,
  ) {}
}
