<div class="hit-count-view">
  <div class="hit-count-section">
    <div class="hit-count-section-content">
      <mat-card>
        <mat-card-header>
          <p class="hit-count-section-header" *ngIf="!isRelative">
            Values greater than alert's threshold for field:
            <span class="strong">'{{ hitCountField }}'</span>
          </p>
          <p class="hit-count-section-header" *ngIf="isRelative">
            Ratio Alert
          </p>
        </mat-card-header>
        <div *ngIf="isMultiLevelGroupBy" class="broken-down-sublabel">
          Group by fields: {{ multilevelGroupByFields }}
        </div>
        <button class="ui icon button filter-active" [disabled]="!isFiltered" (click)="clearFilter()">
          Clear
        </button>
        <div *ngIf="isRelative">
          <div class="relative-chart">
            <sh-basic-chart [model]="relativeCalcChartModel"></sh-basic-chart>
          </div>
          <div class="relative-chart">
            <sh-basic-chart (chartClicked)="onChartClicked($event)" [model]="chartModel"></sh-basic-chart>
          </div>
        </div>
        <div *ngIf="!isRelative" class="no-overflow">
          <sh-basic-chart (chartClicked)="onChartClicked($event)" [model]="chartModel"></sh-basic-chart>
        </div>
        <div *ngIf="isMultiLevelGroupBy" class="field-breakdown">
          <div class="section-title-text">Field breakdown</div>
          <div class="field-breakdown-table-bg grey-border">
            <table mat-table [dataSource]="dataSource" matSort class="field-breakdown-table grey-border" matSortActive="hitCount" matSortDirection="desc">
              <ng-container *ngFor="let disCol of fieldBreakdownColumns; let colIndex = index" matColumnDef="{{disCol}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{disCol}}</th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="colIndex === 0 && element.isOverThreshold"
                       [ngClass]="{'over-threshold':true,'severity-info':severity === 1,'severity-warning':severity === 2,'severity-critical':severity === 3}"
                  ></div>
                  <div class="field-breakdown-td">{{element.aggregations[colIndex]}}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="hitCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Count</th>
                <td mat-cell *matCellDef="let element">
                  <div class="field-breakdown-td">{{element.hitCount}}</div>
                </td>
              </ng-container>
              <tbody>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
