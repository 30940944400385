<div>
  <div *ngIf="isCurrentAlertSnoozeLoading(snoozeLoading$ | async)">
    <sh-widget-loader></sh-widget-loader>
  </div>
  <div *ngIf="alert.isLatest && !isAnomaly(alert)" fxLayout="column">

    <div
      class="snooze-switch"
      [matTooltip]="isSnoozed(snoozeData$ | async) ? 'Snoozed by ' + getSnoozingUser(snoozeData$ | async) + '&#13; Ends in ' + snoozeEndFormat(snoozeData$ | async) : 'Snooze alert'"
      matTooltipClass="coralogix-tooltip multiline-tooltip"
      (click)="snoozeToggleClick();$event.stopPropagation();"
    >
    <input type="checkbox" #snoozeCheckbox [checked]="isSnoozed(snoozeData$ | async)"/>
    <label
      for="snoozeCheckbox"
      [matMenuTriggerFor]="snoozeMenu"
      #snoozeMenuTrigger="matMenuTrigger"
      class="checkbox-label"
    >
      <span></span>
    </label>

  </div>
  </div>
  <mat-menu class="sh-snooze-menu" #snoozeMenu="matMenu">
    <div *ngFor="let item of predefinedQuickTimes">
      <button mat-menu-item (click)="snoozeAlertClick(item.seconds)">
        <span>{{item.caption}}</span>
      </button>
    </div>
  </mat-menu>
</div>
