import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { combineLatest, Observable } from 'rxjs';
import { CompanyRequest } from '@app/auth/shared/models/activateUser.model';
import { getArchivePermissionsError, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs/Rx';
import { ArchiveQueryFormat } from '@app/roi/archive-queries/models/archive-query.model';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArchiveLogsService {
  @Select(CompanyState.companyInfo) private companyInfo$: Observable<CompanyRequest>;

  private readonly hasPermissionsError$: Observable<boolean>;

  constructor(private store: Store<State>,) {
    this.hasPermissionsError$ = this.store.select(getArchivePermissionsError);
  }

  public getArchivePermissions(): Observable<boolean> {
    return combineLatest([this.companyInfo$, this.hasPermissionsError$]).pipe(
    ).map(([companySettings, hasErr]) => this.isArchiveQueryDisabled(companySettings, hasErr))
  }

  private isArchiveQueryDisabled(companySettings: CompanyRequest, hasPermissionErr: boolean): boolean {
    const archiveQueryFormat = companySettings?.archiving_provider?.archive_format || null;
    return (archiveQueryFormat < ArchiveQueryFormat.newFormat) || hasPermissionErr;
  }
}
