import { Component, Input, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'sh-toggle-button',
  template: `
    <div (click)="toggleState()">
      <ng-content select="[checked]" *ngIf="isChecked"></ng-content>
      <ng-content select="[un-checked]" *ngIf="!isChecked"></ng-content>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleButtonComponentProvider(),
      multi: true,
    },
  ],
})
export class ToggleButtonComponent implements ControlValueAccessor {
  @Input() public isChecked: boolean;

  constructor(private renderer: Renderer2) {}

  public toggleState() {
    this.isChecked = !this.isChecked;
    this.propagateChange(this.isChecked);
  }

  public writeValue(value: boolean) {
    this.isChecked = value;
  }

  public propagateChange = (_: any) => {};

  public registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {}
}

export function ToggleButtonComponentProvider(): any {
  return forwardRef(() => ToggleButtonComponent);
}
