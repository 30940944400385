<form [formGroup]="form">
  <mat-expansion-panel
    (expandedChange)="onExpand()"
    (afterExpand)="afterExpand()"
    [disabled]="disabled"
    [(expanded)]="isOpened"
    class="coralogix"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Schedule
        <span class="time-zone-sub">{{ timeZoneSubHeader }}</span>
      </mat-panel-title>
      <mat-slide-toggle
        data-test="alerts-limit-triggering-toggle"
        class="coralogix slider"
        (change)="limitTriggering = $event.checked"
        labelPosition="before"
        formControlName="limitTriggering"
      >
        <span class="slider-label">Limit Triggering</span>
      </mat-slide-toggle>
    </mat-expansion-panel-header>

    <div class="content">
      <p [class.form-hidden]="!limitTriggering" class="mat-expansion-panel-subheader">
        Limit the triggering of this alert to specific days and times
      </p>
      <div class="inputs-container" [class.form-hidden]="!limitTriggering">
        <div class="coralogix days-group input-group">
          <label class="input-label">Active On (days)</label>
          <sh-alerts-day-picker [weekDayOptions]="weekDayOptions" formControlName="daysOfWeek"></sh-alerts-day-picker>
        </div>
        <div class="coralogix input-group">
          <label class="input-label">From</label>
          <div class="time-inputs">
            <sh-coralogix-time-input
              formControlName="activityStarts"
              (minutesKeyUp)="secondTimeInput.onPreviousInputLastSectionKeyUp()"
            ></sh-coralogix-time-input>
            <label class="divider">to</label>
            <div class="to-wrapper">
              <sh-coralogix-time-input #secondTimeInput formControlName="activityEnds"></sh-coralogix-time-input>
              <span *ngIf="showNextDay">the next day</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</form>
