<mat-card class="coralogix" *ngIf="history && history.length > 0 && initialized">
  <p class="mat-card-header">
    History
    <span class="time-zone-sub">{{ timeZoneSubHeader }}</span>
  </p>
  <p class="history-item coralogix" *ngFor="let historyItem of history | slice: 0:lastItemIndex">
    <span class="modified">MODIFIED</span>
    <span *ngIf="getUserName(historyItem.userId).length > 0">by</span>
    <span class="user-name">{{ getUserName(historyItem.userId) }}</span>
    on
    <span class="date">{{ historyItem.dateModified | dateFormatByUserSettings: null:null:null:momentUniqDateFormat:dateType }}</span>
  </p>
  <p class="history-item coralogix">
    <span class="modified">{{ lastHistoryItem.dateModified === createDate ? 'CREATED' : 'MODIFIED' }}</span>
    <span class="alert-by" *ngIf="getUserName(lastHistoryItem.userId).length > 0">by</span>
    <span class="user-name">{{ getUserName(lastHistoryItem.userId) }}</span>
    on
    <span class="date">{{ lastHistoryItem.dateModified | dateFormatByUserSettings: null:null:null:momentUniqDateFormat:dateType }}</span>
  </p>
</mat-card>
