import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sh-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogComponent {
  @Input() public defaultBaseDialog = true;

  @Input() public hideCloseBtn = false;

  @Input() public width: string = '';

  @Input() public height: string = '';

  @Output() public close: EventEmitter<void> = new EventEmitter();

  public onClose(): void {
    this.close.emit();
  }
}
