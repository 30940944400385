import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { StatisticsResultTypes } from '@shared/components/statistics-tile/statistics-tile.component';

export enum TagSummaryTypes {
  highSeverityRatio = 'highSeverityRatio',
  newlyIntroducedErrors = 'newlyIntroducedErrors',
  errorVolume = 'errorVolume',
  triggeredAnomalies = 'triggeredAnomalies',
  triggeredAlerts = 'triggeredAlerts'
}

@jsonObject
export class BuildSummary {
  @jsonMember
  public type: TagSummaryTypes;
  @jsonMember
  public compareResult: number;
  @jsonMember
  public tagResult: number;
  @jsonMember
  public linkCacheId?: string;
}

@jsonObject
export class BuildSummaryResponse {
  @jsonArrayMember(BuildSummary)
  public data: BuildSummary[];
}

export interface ITagSummaryResponse {
  data: ITagSummary[];
}

export interface ITagSummary {
  type: TagSummaryTypes;
  compareResult: number;
  tagResult: number;
  linkCacheId?: string;
}

export interface ITagSummaryRequest extends ITagWithCompareBaseRequest {
}

export interface ITagWithCompareBaseRequest {
  tag: ITagRequest;
  compareTag: ITagRequest;
}

export interface ITimeRange {
  startTime: number;
  endTime: number;
  step?: number;
}

export interface ITagRequest extends ITimeRange {
  applicationName: string[];
  subsystemName: string[];
}

export interface ITagNCompareTimeRanges {
  tag: ITimeRange;
  compareTag: ITimeRange;
}

export interface IStatisticsViewData extends ITagSummary {
  title: string;
  insightSentence: string;
  redirectLink: string;
  isNegativeGrowth: boolean;
  growthPercentage: number;
  dataType: StatisticsResultTypes;
}

export interface ISummaryBaseMap {
  [summaryType: string]: Partial<IStatisticsViewData>;
}
