import { Injectable } from '@angular/core';
import { TypedJSON } from 'typedjson';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../constants';
import { Grok, GrokMatch, GroksResponse } from '../models/grok.model';
import { Log } from '../../../logs/shared/models/log.entity';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DetectGroksDialogComponent } from '../../components/detect-groks-dialog/detect-groks-dialog.component';
import {
  getDetectedGroks,
  getSearchingGroks,
  State,
} from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { GroksActions } from '../state/groks/groks.actions';

@Injectable()
export class GroksService {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private store: Store<State>,
  ) {}

  public showGrokLoadingDialog() {
    const detectedGroks$ = this.store.select(getDetectedGroks);
    const searchingGroks$ = this.store.select(getSearchingGroks);
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = {
      detectedGroks$,
      searchingGroks$,
    };
    const dialogRef = this.dialog.open(DetectGroksDialogComponent, config);
    dialogRef
      .afterClosed()
      .first()
      .subscribe((result) => {
        if (result && result.length > 0) {
          this.store.dispatch(new GroksActions.AddGroksToTeam(result));
        }
      });
  }

  public addGroks(groks: Grok[]): Observable<any> {
    const body = {
      groks,
    };
    return this.http.post(`${Constants.groksUrl}`, body);
  }

  public detectGroks(logs: Log[]): Observable<GrokMatch[]> {
    const body = {
      logs,
    };
    return (
      this.http
        .post(`${Constants.groksUrl}/detect`, body)
        // .map(res => TypedJSON.parse(res, GroksResponse))
        // .map((groksResponse:GroksResponse) => groksResponse.groksMatches);
        .map((res) => this.extractGroksResponse(res))
    );
  }

  public extractGroksResponse(res): GrokMatch[] {
    const groksRes: GroksResponse = new TypedJSON(GroksResponse).parse(res);
    return groksRes.groksMatches;
  }
}
