import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryableOperator } from '@shared/services/retryable-operator';

const retryCount = 2;
const retryWaitMilliSeconds = 1000;
const cloudFlareTimeoutErrors = [0,524];


@Injectable()
export class ArchiveRetryInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // this interceptor is only for requests for the archive-logs routes to handle a case where
    // client receives status 524, due to cloudflare cdn timeout
    const isArchiveQueryUrl = this.isArchiveQueryUrl(request.url);
    return isArchiveQueryUrl ? next.handle(request).pipe(
      retryableOperator(retryWaitMilliSeconds, retryCount, cloudFlareTimeoutErrors)
    ) : next.handle(request);
  };

  isArchiveQueryUrl(url: string): boolean {
    return url.includes('archive-logs');
  }
  isCloudFlareErr(statusCode: number): boolean {
    return cloudFlareTimeoutErrors.findIndex(errCode => errCode === statusCode) > -1;
  }
}
