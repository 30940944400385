import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowTimeComponent } from './show-time.component';
import { DateFormatByUserSettingsModule } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.module';
import { FromDateModule } from '@shared/pipes/from-date.module';

@NgModule({
  declarations: [ShowTimeComponent],
  imports: [CommonModule, DateFormatByUserSettingsModule, FromDateModule],
})
export class ShowTimeModule {}
