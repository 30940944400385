import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InviteService } from '@app/user/shared/srvices/invite.service';
import { InviteRequestStatusModel } from '@app/user/shared/models/invite-request-status.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sh-login-status',
  templateUrl: './login-status.component.html',
  styleUrls: ['./login-status.component.scss'],
})
export class LoginStatusComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public inviteRequestStatus: InviteRequestStatusModel;
  private subs: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inviteService: InviteService,
  ) {}

  public ngOnInit(): void {
    // Regex for a valid UUID
    const regex: RegExp = new RegExp(
      /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    );
    const inviteHash: string = this.route.snapshot.params.reqId;

    if (!inviteHash || !regex.test(inviteHash)) {
      this.router.navigate(['/login']).finally();
      return;
    }

    const sub = this.inviteService
      .getInviteRequestStatus(inviteHash)
      .do(() => (this.isLoading = true))
      .subscribe(
        (status) => {
          this.isLoading = false;
          this.inviteRequestStatus = status;
        },
        (err) => {
          this.router.navigate(['/login']).finally();
          return;
        },
      );
    this.subs.push(sub);
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
