<div
  style="text-align: center; "
  *ngIf="commonErrorResult && commonErrorResult.length == 0 && !isLoading"
>
  <span style="position: relative;color: #666666">No Rows To Show</span>
</div>
<div *ngFor="let commonError of commonErrorResult; let last = last">
  <a
    [href]="commonError.link"
    target="tab"
    class="loggregation-item-container {{ theme }}"
    [class.loggregation-last-item]="last"
  >
    <div class="loggregation-item-info">
      <div class="ui equal width grid column">
        <div class="row large">
          <div
            (click)="onItemCliecked(commonError)"
            class="column loggregation-item-column"
          >
            <span
              [ngClass]="severityColor(commonError.loggregationEntity.metadata.severity.toLowerCase())"
            >
              {{ commonError.loggregationEntity.metadata.severity }}
            </span>
          </div>
        </div>
        <div class="row large">
          <div
            (click)="onItemCliecked(commonError)"
            class="column loggregation-item-column"
          >
            <div class="loggregation-count-item {{ theme }}">
              {{ commonError.loggregationEntity.count | numbersFormat }}
            </div>
            <div
              class="loggregation-ration-item anomolus"
              *ngIf="commonError.loggregationEntity.countToNormalRation"
              matTooltip="{{
                commonError.loggregationEntity.countToNormalRation
                  | normalRatio: 100
              }}% above normal"
            >
              {{
              commonError.loggregationEntity.countToNormalRation
                | normalRatio: 100
              }}%
            </div>
            <div
              class="loggregation-ration-item"
              *ngIf="!commonError.loggregationEntity.countToNormalRation"
              matTooltip="{{
                commonError.loggregationEntity.ratio
              }} of error logs"
            >
              {{ commonError.loggregationEntity.ratio }}
            </div>
          </div>
          <div class="column loggregation-item-column">
            <sh-statistics-chart
              [height]="40"
              [isCompact]="true"
              [clickable]="false"
              (chartClicked)="onChartClicked($event)"
              [infoBarClass]="'chart-infoBar dark '"
              [queryDefinition]="commonError.statisticsQueryDefinition"
            ></sh-statistics-chart>
          </div>
        </div>
      </div>
    </div>
    <div
      class="loggregation-item-content"
      (click)="onItemCliecked(commonError)"
    >
      <div
        class="loggregation-item-text"
        innerHTML="{{ commonError.loggregationEntity | loggregationPipe }}"
      ></div>
      <div class="loggregation-metadata-row">
        <span class="loggregation-item-metadata {{ theme }}">
          {{ commonError.loggregationEntity.metadata.applicationName }}
        </span>
        <span class="loggregation-item-metadata {{ theme }}">
          {{ commonError.loggregationEntity.metadata.subsystemName }}
        </span>
      </div>
    </div>
  </a>
</div>
<sh-widget-loader *ngIf="enableLoading" [hidden]="!isLoading" [addInvertedClass]="addInvertedClassToLoader"></sh-widget-loader>
<!--<div class="loggregation-count-item" *ngIf="commonErrorResult?.length==0">-->
<!--No results-->
<!--</div>-->
