import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TypedJSON } from 'typedjson';
import { Observable } from 'rxjs';
import { StatisticsCategory, StatisticsQueryDefinition, StatisticsTypes } from '../models/statistics-query-definition';
import { QueryDefRequest } from '@app/logs/shared/models/query-def.request';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { StatisticsAggregationResult, StatisticsResult } from '../models/statistics-logs.response';
import { DynamicFilter } from '@shared/models/dynamic-filters';
import { Constants } from '@app/constants';
import cloneDeep from 'lodash-es/cloneDeep';

@Injectable()
export class StatisticService {
  public ALERTS: string = Constants.GetUrl() + '/api/v1/statistics/alerts';
  public ANOMALIES: string = Constants.GetUrl() + '/api/v1/statistics/anomalies';
  public LOGS: string = Constants.GetUrl() + '/api/v1/statistics/logs';
  public AGGS: string = Constants.GetUrl() + '/api/v1/statistics/logquery_aggs';

  constructor(private http: HttpClient) {}

  public getStats(queryDefinition: StatisticsQueryDefinition): Observable<StatisticsResult> {
    if (queryDefinition.logQeuryModel) {
      const queryDefRequest: QueryDefRequest = new QueryDefRequest(queryDefinition.logQeuryModel);
      const clonedQuery = cloneDeep(queryDefRequest);
      if (clonedQuery.queryDef['graphs']) delete clonedQuery.queryDef['graphs'];
      return this.http.post(this.getUrl(queryDefinition.category), JSON.stringify(clonedQuery)).map(this.extractData);
    }

    let params: HttpParams = new HttpParams();
    params = params.set('type', this.getType(queryDefinition.type));

    if (queryDefinition.amount) {
      params = params.set('amount', queryDefinition.amount);
    }

    return this.http.get(this.getUrl(queryDefinition.category), { params }).map(this.extractData);
  }

  public getAggregationStats(queryDefinition: LogQueryModel) {
    const queryDefRequest: QueryDefRequest = new QueryDefRequest(queryDefinition);
    return this.http.post(this.AGGS, JSON.stringify(queryDefRequest)).map(this.extractAggregationData);
  }

  public getDynamicFieldAggregation(queryModel: LogQueryModel) {
    const queryDefRequest: QueryDefRequest = new QueryDefRequest(queryModel);
    return this.http.post(this.AGGS, JSON.stringify(queryDefRequest)).map(this.extractDynamicFieldAggregation);
  }

  public getPatternDetailsByInsight(id: string) {
    const request = {
      queryDef: {
        eventId: id,
        beforeInterval: 5 * 60 * 1000,
        afterInterval: 4 * 60 * 1000,
      },
    };

    return this.http
      .post(Constants.GetUrl() + '/api/v1/statistics/pattern', request)
      .timeout(300000)
      .map(
        (res: any) => res,
        err => this.handleError(err),
      );
  }

  public getStatistics(url: string, params: HttpParams): Observable<StatisticsResult> {
    return this.http.get(url, { params }).map(this.extractData);
  }

  public getLogStatsByDates(startDate, endDate): Observable<StatisticsResult> {
    const params: HttpParams = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('type', 'daily');
    return this.getStatistics(this.LOGS, params);
  }

  private extractDynamicFieldAggregation(res) {
    const resultData = res.data;
    const dynamicFilters: DynamicFilter[] = [];
    Object.keys(resultData).forEach(key => {
      const values = resultData[key];
      const filter = new DynamicFilter();
      filter.values = values;
      filter.filterName = key.replace('_coralogix_', '.');
      dynamicFilters.push(filter);
    });
    // console.log(dynamicFilters);
    return dynamicFilters;
  }

  private extractAggregationData(res): StatisticsAggregationResult {
    // TODO: get rid of toLowerCase
    return new TypedJSON(StatisticsAggregationResult).parse(JSON.stringify(res).toLowerCase());
  }

  private extractData(res): StatisticsResult {
    const result: StatisticsResult = res;
    return result;
  }

  private handleError(error: any) {
    const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

  private getUrl(category: StatisticsCategory): string {
    switch (category) {
      case StatisticsCategory.ALERTS:
        return Constants.GetUrl() + '/api/v1/statistics/alerts';
      case StatisticsCategory.ANOMALIES:
        return Constants.GetUrl() + '/api/v1/statistics/anomalies';
      case StatisticsCategory.SEVERITY:
        return Constants.GetUrl() + '/api/v1/statistics/severity';
      case StatisticsCategory.LOGS:
        return Constants.GetUrl() + '/api/v1/statistics/logs';
    }
    return null;
  }

  private getType(type: StatisticsTypes) {
    switch (type) {
      case StatisticsTypes.Hours:
        return 'hours';
      case StatisticsTypes.Daily:
        return 'daily';
      case StatisticsTypes.Total:
        return 'total';
    }
  }
}
