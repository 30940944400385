import { Injectable } from '@angular/core';
import { PayAsYouGoStatus } from '@app/settings/subscriptions/pay-as-you-go/pay-as-you-go-status.enum';
import { PayAsYouGoService } from '@app/services/pay-as-you-go/pay-as-you-go.service';
import { PlanInformationStatus } from '@app/services/pay-as-you-go/responses';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';
import { ShDialogService } from '@app/shared/services/dialogService';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { GetData, SuccessfullyConfigured, Toggle } from './pay-as-you-go.action';

interface IPayAsYouGoModel {
  configured: PayAsYouGoStatus;
  email: string;
  enabled: boolean;
  loading: boolean;
  clientSecret: string;
  stripeCustomerId: string;
  totalUsage: number;
  unitPrice: number;
  dailyUsage: number;
}

const initialState = (): IPayAsYouGoModel => {
  return {
    configured: PayAsYouGoStatus.NOT_SET,
    email: null,
    enabled: false,
    loading: false,
    clientSecret: null,
    stripeCustomerId: null,
    totalUsage: 0,
    unitPrice: 0,
    dailyUsage: 0,
  };
};

@State<IPayAsYouGoModel>({
  name: 'payAsYouGo',
  defaults: initialState(),
})
@Injectable()
export class PayAYouGoState {
  constructor(private dialogService: ShDialogService, private payAsYouGoService: PayAsYouGoService) {}

  @Selector()
  public static configured(state: IPayAsYouGoModel): PayAsYouGoStatus {
    return state?.configured;
  }

  @Selector()
  public static email(state: IPayAsYouGoModel): string {
    return state?.email;
  }
  @Selector()
  public static enabled(state: IPayAsYouGoModel): boolean {
    return state?.enabled;
  }

  @Selector()
  public static loading(state: IPayAsYouGoModel): boolean {
    return state?.loading;
  }

  @Selector()
  public static totalUsage(state: IPayAsYouGoModel): number {
    return state?.totalUsage;
  }

  @Selector()
  public static dailyUsage(state: IPayAsYouGoModel): number {
    return state?.dailyUsage;
  }

  @Selector()
  public static unitPrice(state: IPayAsYouGoModel): number {
    return state?.unitPrice;
  }

  @Selector()
  public static clientSecret(state: IPayAsYouGoModel): string {
    return state?.clientSecret;
  }

  @Selector([PayAYouGoState.configured, PayAYouGoState.enabled])
  public static showActivateButton(configured: PayAsYouGoStatus, enabled: boolean): boolean {
    return (
      (configured === PayAsYouGoStatus.SUBSCRIPTION_COMPLETED && !enabled) ||
      [PayAsYouGoStatus.EMAIL_VALIDATION, PayAsYouGoStatus.SUBSCRIPTION_INCOMPLETED, PayAsYouGoStatus.NOT_CONFIGURED].includes(configured)
    );
  }

  @Selector([PayAYouGoState.configured])
  public static showPayAsyouGo(configured: PayAsYouGoStatus): boolean {
    return [
      PayAsYouGoStatus.EMAIL_VALIDATION,
      PayAsYouGoStatus.NOT_CONFIGURED,
      PayAsYouGoStatus.SUBSCRIPTION_COMPLETED,
      PayAsYouGoStatus.SUBSCRIPTION_INCOMPLETED,
    ].includes(configured);
  }

  @Action(GetData)
  public GetData(ctx: StateContext<IPayAsYouGoModel>): Observable<IPayAsYouGoModel> {
    const planInformation$ = this.payAsYouGoService.planInformation();
    const stripeCustomerData$ = this.payAsYouGoService.stripeCustomerData();
    ctx.patchState({ loading: true });
    return forkJoin([planInformation$, stripeCustomerData$]).pipe(
      map(([planInformation, stripeCustomerData]) => {
        const stripeCustomerId = stripeCustomerData?.customerId;

        if (planInformation) {
          const { unitPrice, totalUsage, status, clientSecret, dailyUsage } = planInformation;
          const enabled = planInformation.enabled;
          const mapper = {
            [PlanInformationStatus.PAYMENT_REQUIRED]: PayAsYouGoStatus.SUBSCRIPTION_INCOMPLETED,
            [PlanInformationStatus.COMPLETED]: PayAsYouGoStatus.SUBSCRIPTION_COMPLETED,
            [PlanInformationStatus.NOT_CONFIGURED]: stripeCustomerId ? PayAsYouGoStatus.NOT_CONFIGURED : PayAsYouGoStatus.EMAIL_VALIDATION,
            [PlanInformationStatus.MANUAL_CONFIGURATION_REQUIRED]: PayAsYouGoStatus.MANUAL_CONFIGURATION_REQUIRED,
          };
          const configured = mapper[status];

          return ctx.setState({
            ...ctx.getState(),
            email: stripeCustomerData?.email,
            enabled,
            stripeCustomerId,
            configured,
            unitPrice: unitPrice / 100,
            totalUsage,
            clientSecret,
            dailyUsage,
          });
        } else {
          return ctx.patchState({ configured: PayAsYouGoStatus.NO_PLAN, stripeCustomerId });
        }
      }),
      catchError(err => {
        this.dialogService.showCoralogixMessage(`Failed request`, null, dialogServiceIconClasses.failed);
        return of(ctx.getState());
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(Toggle)
  public Toggle(ctx: StateContext<IPayAsYouGoModel>): Observable<IPayAsYouGoModel> {
    const { enabled, configured, stripeCustomerId } = ctx.getState();
    if (configured === PayAsYouGoStatus.NOT_CONFIGURED) {
      return this.payAsYouGoService.registerPlan({ customerId: stripeCustomerId }).pipe(
        map(res => {
          return ctx.setState({
            ...ctx.getState(),
            enabled: res.enabled,
            configured: PayAsYouGoStatus.SUBSCRIPTION_COMPLETED,
            totalUsage: res.totalUsage,
            unitPrice: res.unitPrice / 100,
          });
        }),
      );
    } else {
      ctx.patchState({ enabled: !enabled });
      return this.payAsYouGoService.togglePlan({ enabled: !enabled }).pipe(
        map(() => {
          return ctx.getState();
        }),
        catchError(err => {
          const action = !enabled ? 'ON' : 'OFF';
          this.dialogService.showCoralogixMessage(`Failed to turn ${action}`, null, dialogServiceIconClasses.failed);
          return of(ctx.patchState({ enabled }));
        }),
      );
    }
  }

  @Action(SuccessfullyConfigured)
  public SuccessfullyConfigured(ctx: StateContext<IPayAsYouGoModel>, { email }: SuccessfullyConfigured): IPayAsYouGoModel {
    return ctx.setState({ ...ctx.getState(), enabled: true, email });
  }
}
