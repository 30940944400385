import { Injectable, EventEmitter } from '@angular/core';
import { LogQueryModel } from '../models/logsquery.model';
import { HttpClient } from '@angular/common/http';
import { QueryDefRequest } from '../models/query-def.request';
import { LogqueryResult } from '../models/logquery.response';
import { LogQueryData } from '../models/logquery.data';
import { TypedJSON } from 'typedjson';
import { UDIDHelper } from '@shared/services/udid.helper';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { Constants } from '@app/constants';
import { LogqueryStats } from '../models/Logquery-stats.response';
import { Observable } from 'rxjs';

@Injectable()
export class LogsService {
  public newQueryRequest: EventEmitter<LogQueryModel> = new EventEmitter<LogQueryModel>();
  public onBeforeAfter: EventEmitter<LogQueryData> = new EventEmitter<LogQueryData>();
  public onNewQueryId: EventEmitter<string> = new EventEmitter<string>();
  private log_query_url: string = Constants.GetUrl() + Constants.queryUrl;
  private queryStatsUrl: string = Constants.GetUrl() + Constants.queryStatsUrl;
  private saved_log_query_url: string =
    Constants.GetUrl() + Constants.savedQueryUrl;
  constructor(
    private http: HttpClient,
    private udidHelper: UDIDHelper,
    private ae: AnalyticEventService,
  ) {}

  public getLogs(queryModel: LogQueryModel): Observable<LogqueryResult> {
    const queryDefRequest: QueryDefRequest = new QueryDefRequest(queryModel);
    this.ae.event({ eventName: 'getLogs' });

    return this.http
      .post(this.log_query_url, JSON.stringify(queryDefRequest))
      .map(this.extractData);
  }

  public getQueryStats(queryModel: LogqueryStats): Observable<LogqueryStats> {
    return this.http
      .post(this.queryStatsUrl, JSON.stringify(queryModel))
      .map((res: LogqueryStats) => res);
  }

  public saveLogsQuery(queryModel: LogQueryModel): Observable<LogQueryModel> {
    const queryDefRequest: QueryDefRequest = new QueryDefRequest(queryModel);
    return this.http
      .post(this.saved_log_query_url, JSON.stringify(queryDefRequest))
      .map(this.extractQueryModelData);
  }

  public getNewQueryId(): string {
    return this.udidHelper.generateShortUDID();
  }

  private extractData(res: LogqueryResult): LogqueryResult {
    const logqueryRes: LogqueryResult = new TypedJSON(LogqueryResult).parse(
      res,
    );
    return logqueryRes;
  }
  private extractQueryModelData(res: LogQueryModel): LogQueryModel {
    const result: LogQueryModel = res;
    return result;
  }
  private handleError(error: any): Observable<any> {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

}
