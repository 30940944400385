import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KibanaComponent } from './kibana/kibana.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [KibanaComponent],
  declarations: [KibanaComponent],
})
export class KibanaModule {}
