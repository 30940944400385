import { RouterModule, Routes } from '@angular/router';
import { RoiMainLayoutComponent } from '@app/roi/containers/roi-main-layout/roi-main-layout.component';
import { ReindexContainerComponent } from '@app/roi/reindex/containers/reindex-container/reindex-container.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from '@app/auth/shared/guards/auth.guard';
import { AdminGuard } from '@app/shared/guards/admin.guard';
import { MetricesSettingsComponent } from '@app/settings/metrices-settings/metrices-settings.component';
import { ArchivingProvidersLayoutComponent } from '@app/settings/archiving-providers/containers/archiving-providers-layout/archiving-providers-layout.component';
import { ReadOnlyGuard } from '@app/shared/guards/read-only.guard';
import { ArchiveQueriesContainerComponent } from '@app/roi/archive-queries/containers/archive-queries-container/archive-queries-container.component';
import { ArchiveQueryGuard } from '@shared/guards/archive-query.guard';
import { PaymentGuard } from '@app/shared/guards/payment.guard';

export const ROIRoutes: Routes = [
  {
    path: 'tco',
    component: RoiMainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'metrics',
        pathMatch: 'full',
        canActivate: [AuthGuard, AdminGuard],
      },
      {
        path: 'metrics',
        data: {
          title: 'Logs2Metrics',
        },
        loadChildren: () => import('../settings/metrices-settings/metrices-settings.module').then(m => m.MetricesSettingsModule),
        canActivate: [PaymentGuard, AdminGuard],
      },
      {
        path: 'metrics/:metricId',
        component: MetricesSettingsComponent,
        canActivate: [PaymentGuard, AdminGuard],
      },
      {
        path: 'reindex',
        component: ReindexContainerComponent,
        canActivate: [AuthGuard, AdminGuard],
      },
      {
        path: 'quota',
        data: {
          title: 'TCO',
        },
        // component: QuotaManagementComponent,
        loadChildren: () => import('../settings/quota-management/quota-management.module').then(m => m.QuotaManagementModule),
        canActivate: [PaymentGuard, AdminGuard],
      },
      {
        path: 'archivingProviders',
        data: {
          title: 'Setup Archive',
        },
        component: ArchivingProvidersLayoutComponent,
        canActivate: [PaymentGuard, ReadOnlyGuard],
      },
      {
        path: 'archiveQueries',
        data: {
          title: 'Archive Queries',
        },
        component: ArchiveQueriesContainerComponent,
        canActivate: [ArchiveQueryGuard, ReadOnlyGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROIRoutes)],
  exports: [RouterModule],
})
export class RoiRoutingModule {}
