import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';

export enum ETagsRoutes {
  tag = 'tag',
  compare = 'compare',
  edit = 'edit',
  new = 'new',
  allSubsystems = 'all-subsystems'
}

export const TagsBaseUrl: string = '/tags';
export const coralogixMenuImg: string = 'vertical-menu.svg';
export const DefaultCompareEnd = 'noCompare';
export const newTagFromWelcome = `${TagsBaseUrl}/${ETagsRoutes.new}`;
export const MaxAllowedRequestHours = 24;
export const MaxAllowedRequestMinutes = MaxAllowedRequestHours * 60;
export const MaxAllowedRequestSeconds = MaxAllowedRequestMinutes * 60;
export const CloseDialogData: ICoralogixDialogData = {
  title: 'Unsaved changes will be lost',
  message:
    'Closing this Tag dialog will delete your unsaved changes. ' +
    'Are you sure you want to close and discard the unsaved changes?',
  yesButtonText: 'DISCARD',
  noButtonText: 'CONTINUE EDITING',
};

export function getTagRedirectUrl(selectedTagId: number, compareId: string | number): string {
  if (!!selectedTagId && !!compareId) {
    return `${TagsBaseUrl}/${ETagsRoutes.tag}/${selectedTagId}/${ETagsRoutes.compare}/${compareId}/${ETagsRoutes.allSubsystems}/false`;
  } else {
    return TagsBaseUrl;
  }
}

export const MaxDaysBackTagCanBeCreated = 7;
