import * as moment from 'moment';
export enum ArchiveQueryFormat {
  oldFormat = 1,
  newFormat = 2,
}
export enum EArchiveQueryState {
  pending= 'Started',
  active= 'Finished',
  expired= 'Expired',
  failed= 'Failed'
}

export enum EReindexState {
  possible= '',
  pending= 'Ingesting',
  finished= 'Finished',
  failed= 'Failed'
}

export interface IArchiveQueryReindex {
  id: string;
  progressPercent: number;
  status: EReindexState;
  createDate: string;
}

export interface IArchiveQuery {
  id: string;
  name: string;
  description: string;
  applicationName: string[];
  subsystem: string[];
  severity: number[];
  text: string;
  startTime: string;
  endTime: string;
  creationTime: string;
  expirationTime: string;
  status: EArchiveQueryState;
  reindexes: IArchiveQueryReindex[];
  log: ILog[];
  rowCount: number;
  querySyntax: string;
}

export interface ILog {
  date: string;
  action: EArchiveQueryState;
  resource: string;
  by: string;
  cause: string;
}

export interface IFile {
  fileName: string;
  size: number;
  link: string;
}

export interface IArchiveQueriesResponse {
  archiveQueries: IArchiveQuery[];
  queriesCount: number;
}

export interface IArchiveQueryPreviewResponse {
  from: number;
  id: string;
  logs: object[];
  logs_count: number;
  total_count: number;
  columns: string[];
}

export interface IArchiveQueryReindexEstimateResponse {
  estimation: number;
}

export interface ICachedQueryResponse {
  queryId: string;
}

export class ArchiveQueryRequest {
  public name: string = '';
  public description: string;
  public applicationName: string[];
  public subsystem: string[];
  public severity: number[];
  public startTime: moment.Moment;
  public endTime: moment.Moment;
  public text: string = '';
  public querySyntax: string;

  constructor(init?: Partial<ArchiveQueryRequest>) {
    Object.assign(this, init);
  }
}
export interface IQueryUiState {
  showMore: boolean;
}

export class ArchiveQueryPreviewParams {
  public id: string;
  public filterType: string = 'lucene';
  public filter: string = '';

  constructor(init?: Partial<ArchiveQueryPreviewParams>) {
    Object.assign(this, init);
  }
}

export class ArchiveQueryPreviewGridParams {
  public id: string;

  constructor(init?: Partial<ArchiveQueryPreviewParams>) {
    Object.assign(this, init);
  }
}

export class ArchiveQueryDownloadTsvParams {
  public id: string;

  constructor(init?: Partial<ArchiveQueryDownloadTsvParams>) {
    Object.assign(this, init);
  }
}

export interface IUiState {
  [id: string]: IQueryUiState;
}

export interface IReindex {
  applicationName: string;
  archiveQueryId: string;
  companyId: string;
  createBy: string;
  createDate: string;
  description: string;
  endTime: string;
  id: string;
  name: string;
  progressPercent: number;
  severity: number;
  startTime: string;
  status: string;
  subsystem: string;
  text: string;
}

export interface IArchiveQueryBucketPermission {
  hasPermission: boolean;
  msg: string;
  err: string;
}

export const MAXIMUM_ITEMS = 10;

