import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { CoralogixSidebarComponent } from '@shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CloseDialogData, ETagsRoutes, MaxDaysBackTagCanBeCreated } from '@app/deployment/shared/consts/tags.consts';
import { Build } from '@app/deployment/models/build';
import { ManualEventFormProviderService } from '@app/deployment/shared/services/manual-event-form-provider.service';
import { TimeZoneType } from '@shared/models/timezone-types';
import { EditorModes } from '@app/deployment/shared/consts/tag-panel.enums';
import { AuthService } from '@app/auth/shared/services/auth.service';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { TagsActions } from '@app/deployment/shared/actions/tag.actions';
import { Router } from '@angular/router';
import { DatepickerOptions } from '@shared/controls/sh-ng-datepicker/ng-datepicker.component';
import { addDays } from 'date-fns';
import { getUpdatedDateOptions } from '@app/deployment/shared/helpers/helper-functions';

@Component({
  selector: 'sh-tag-panel-container',
  templateUrl: './tag-panel-container.component.html',
  styleUrls: ['./tag-panel-container.component.scss']
})
export class TagPanelContainerComponent implements OnDestroy, OnInit {

  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('sidebarComponent', { static: true })
  public sidebar: CoralogixSidebarComponent;
  public isCreateMode: boolean;
  public saveButtonText: string = 'Create tag';
  public mode: EditorModes;
  public timeZoneSubHeader: string;
  public tagForm: FormGroup;
  public isLocalTimeZone: boolean = true;
  public dateOptions: DatepickerOptions = getUpdatedDateOptions(MaxDaysBackTagCanBeCreated);
  public minFormDate: Date;
  protected destroy$: Subject<void> = new Subject<void>();
  private initialTagNameValue: string;
  private privateKey: string;
  private companyId: number;
  private compareId: number | string;
  constructor(
    private dialog: MatDialog,
    private location: Location,
    private store: Store<State>,
    private authService: AuthService,
    private ae: AnalyticEventService,
    private router: Router,
    private formProviderService: ManualEventFormProviderService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public closeEditor(): void {
    if (this.tagForm?.pristine) {
      this.hideSidebarAndReplaceUrl();
      return;
    }

    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data: CloseDialogData });

    dialogRef.afterClosed().pipe(take(1)).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.hideSidebarAndReplaceUrl();
      }
    });
  }

  public ngOnInit(): void {
    this.store.select(getTimezoneViewPreference).first().subscribe((tmz) => {
      this.isLocalTimeZone = tmz === TimeZoneType.local;
      this.formProviderService.generateNewFormWithDefaultValues(this.isLocalTimeZone, this.minDateActualValueFromOptions);
      this.timeZoneSubHeader = this.isLocalTimeZone ? 'Local Time' : 'UTC';
    });
    this.authService
      .getCompany()
      .first()
      .subscribe((data) => {
        this.companyId = data.id;
        this.privateKey = data.privateKey;
        this.setDefaultCompanyInfo();
      });
  }

  public openEditor(mode: EditorModes, initialValues?: Build, compareId?: number | string): void {
    this.handleInitialRouteReplacementByMode(mode, initialValues?.id);
    this.dateOptions = getUpdatedDateOptions(MaxDaysBackTagCanBeCreated);
    this.minFormDate = this.minDateActualValueFromOptions;
    this.formProviderService.resetForm(this.isLocalTimeZone, this.minFormDate);
    this.isCreateMode = mode === EditorModes.create;
    this.compareId = compareId;
    this.mode = mode;
    if (initialValues) {
      this.formProviderService.updateForm(initialValues, this.isLocalTimeZone, this.minFormDate);
    }
    this.setDefaultCompanyInfo();
    this.setInitialTexts(initialValues?.text_value, this.isCreateMode);
    this.tagForm = this.formProviderService.manualEventForm;
    this.sidebar.showSidebar();
  }

  public get tagName(): string {
    const value = this.tagForm?.get('text_value')?.value;
    return value ? value : this.initialTagNameValue;
  }

  public hideSidebarAndReplaceUrl(): void {
    this.sidebar.hideSidebar();
    let newRoute = this.location.path().replace(`/${ETagsRoutes.new}`, '');
    if (this.mode === EditorModes.edit) {
      const replaceEditIndex = newRoute.indexOf('/edit');
      newRoute = newRoute.slice(0, replaceEditIndex);
    }
    this.ngOnDestroy();
    this.location.replaceState(newRoute);
  }

  public saveTag(): void {
    if (this.formProviderService.manualEventForm.valid) {
      this.formProviderService.setTimeStamp(this.isLocalTimeZone);
      if (this.isCreateMode) {
        this.store.dispatch(
          new TagsActions.AddTagAction({
            tagData: this.formProviderService.manualEventForm.value,
            compareTagId: this.compareId
          }));
        this.ae.event({ eventName: 'Tags - Add tag' });
      } else {
        this.store.dispatch(new TagsActions.EditTagAction(this.formProviderService.manualEventForm.value));
      }
      this.hideSidebarAndReplaceUrl();
    }
  }

  private get minDateActualValueFromOptions(): Date {
    const minDateViewValue = this.dateOptions.minDate;
    return addDays(minDateViewValue, 1);
  }

  private handleInitialRouteReplacementByMode(mode: EditorModes, tagId?: number): void {
    if (mode === EditorModes.edit && tagId && !this.router.url.includes(ETagsRoutes.edit)) {
        this.location.go(`${this.router.url}/${ETagsRoutes.edit}/${tagId}`);
    } else if (mode === EditorModes.create && !this.router.url.includes(ETagsRoutes.new)) {
      this.location.go(`${this.router.url}/${ETagsRoutes.new}`);
    }
  }

  private setDefaultCompanyInfo(): void {
    const controls = this.formProviderService?.manualEventForm?.controls;
    controls?.company_id?.setValue(this.companyId);
    controls?.private_key?.setValue(this.privateKey);
  }

  private setInitialTexts(originalName: string, isCreateMode: boolean): void {
    this.saveButtonText = isCreateMode ? 'Create Tag' : 'Save changes';
    this.initialTagNameValue = isCreateMode ? 'New Tag' : originalName;
  }
}
