import { animate, style, transition } from '@angular/animations';

export const inOutAnimation = [transition(
  ':enter',
  [
    style({ opacity: 0 }),
    animate('.5s ease-out',
      style({ opacity: 1 })),
  ],
),
  transition(
    ':leave',
    [
      style({ opacity: 1 }),
      animate('.3s ease-in',
        style({ opacity: 0 })),
    ],
  )];
