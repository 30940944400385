import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sh-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputDialogComponent {

  public message: string;
  public title: string;
  public action: string;
  public placeholder: string;
  public actionText: string = '';
  public showError$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.message = data.message;
    this.title = data.title;
    this.action = data.action || 'OK';
    this.placeholder = data.placeholder || '';
  }

  public sendText(): void {
    if (!this.actionText) {
      this.showError$.next(true);
      setTimeout(() => {
        this.showError$.next(false);
      }, 1800);
    } else {
      this.dialogRef.close(this.actionText);
    }
  }
}
