<ag-grid-angular
  class="grid ag-theme-alpine cgx-grid"
  [class.grid-with-pagination]="usePagination"
  [rowData]="rowsData$ | async"
  (gridReady)="onGridReady($event)"
  [getRowHeight]="getRowHeight"
  [columnDefs]="colsDefs$ | async"
  (columnResized)="onColumnResize($event)"
  [frameworkComponents]="frameworkComponents"
  [enableCellTextSelection]="true"
  [defaultColDef]="defaultColDef"
  [suppressContextMenu]="true"
  [suppressLoadingOverlay]="true"
  [suppressNoRowsOverlay]="suppressNoRowsOverlay"
  (columnMoved)="emitColumnState()"
  (newColumnsLoaded)="resetRows()"
  (rowDataChanged)="onRowDataChanged()"
  [gridOptions]="gridOptions"
  [context]="gridContext"
  [rowSelection]="'multiple'"
  (rowClicked)="onRowClicked($event)"
  [navigateToNextCell]="navigateToNextCell.bind(this)"
  [suppressRowTransform]="true"
  [rowClassRules]="rowClassRules"
  (rowSelected)="onRowSelected($event)"
></ag-grid-angular>

<div class="grid-pagination" *ngIf="usePagination">
  <mat-paginator
    data-test="logs-grid-paginator"
    [length]="rowsCount$ | async"
    [pageSize]="paginationItemsPerPage"
    [showFirstLastButtons]="true"
    (page)="onPaginationChanged($event)"
  ></mat-paginator>
  <ng-container *shAllowAbility="'highlightLogs'">
    <sh-select-log
      *ngIf="(activeTab$ | async) === logsContent.logs && (selectedLogs$ | async) as selectedLogs"
      [selectedLogs$]="selectedLogs"
      (setFocusedLog)="setFocusedRow($event)"
      (clearSelectedLogs)="clearSelectedRows()"
    ></sh-select-log>
  </ng-container>
</div>

<sh-logs-new-info-panel
  *ngIf="showInfoPanel$ | async"
  [logQuery$]="logsQuery$"
  [activeTab$]="activeTab$"
  (clearInfoPanelEvent)="setInfoPanel(false)"
></sh-logs-new-info-panel>
