import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import * as _ from 'lodash';
import { StatisticsCategory, StatisticsQueryDefinition } from '@app/statistics/shared/models/statistics-query-definition';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { SeveritiesHelper } from '@shared/services/severities.helper';
import { LoggregationPipe } from '@shared/pipes/loggregation.pipe';
import { Router } from '@angular/router';
import { GlobalParamsProvider } from '@shared/services/globalParams.provider';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { ChartModel } from '@shared/controls/charts/models/chart.model';
import { WidgetBaseComponent } from '@shared/components/widget-base-component';
import { LoggregationService } from '@app/loggregation/shared/services/loggregation.service';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';
import { TemplateType } from '@app/loggregation/shared/interfaces/templateType.enum';
import { EFeatureViewMode } from '@app/deployment/shared/models/reducer.models';

const generalTemplateTypes = [1, 2, 3, 4];

@Component({
  selector: 'sh-errors-widget',
  templateUrl: './errors-widget.component.html',
  styleUrls: ['./errors-widget.component.scss'],
})
export class ErrorsWidgetComponent extends WidgetBaseComponent {
  @Input() set queryModel(queryModel: LogQueryModel) {
    this.viewState.emit(EFeatureViewMode.loading);
    this.isLoading = true;
    this.commonErrorResult = [];

    if (!queryModel) {
      return;
    }

    this.commonErrorResult = [];
    this.loggregationService
      .getLoggregationResult(queryModel)
      .first()
      .subscribe(
        res => {
          this.commonErrorResult = this.getTopLoggregationResult(res)
            .filter(l => !generalTemplateTypes.includes(l.templateType)) // Ignore general templates
            .slice(0, this.maxResult)
            .map(l => {
              const commonError = new CommonErrorResult();
              commonError.statisticsQueryDefinition = new StatisticsQueryDefinition();
              commonError.loggregationEntity = l;
              commonError.statisticsQueryDefinition.logQeuryModel = _.cloneDeep<LogQueryModel>(queryModel);
              commonError.statisticsQueryDefinition.logQeuryModel.cacheQueryId = this.logsService.getNewQueryId();
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.query.text = `coralogix.templateId:"${l.id}"`;
              commonError.statisticsQueryDefinition.category = StatisticsCategory.LOGS;
              commonError.statisticsQueryDefinition.logQeuryModel.type = QueryTypes.TEMPLATE;
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.metadata = new Metadata();
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.applicationName.push(l.metadata.applicationName);
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.subsystemName.push(l.metadata.subsystemName);
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.severity.push(
                SeveritiesHelper.getLogSeverityByName(l.metadata.severity),
              );
              commonError.statisticsQueryDefinition.seriesType = 'column';
              if (!commonError.link) {
                commonError.link = '/#/query-new/logs?id=' + commonError.statisticsQueryDefinition.logQeuryModel.cacheQueryId;
                this.logsService
                  .saveLogsQuery(commonError.statisticsQueryDefinition.logQeuryModel)
                  .first()
                  .subscribe();
              }
              return commonError;
            });
          if (this.commonErrorResult.length === 0) {
            this.viewState.emit(EFeatureViewMode.noResults);
          } else {
            this.viewState.emit(EFeatureViewMode.completed);
          }
          this.isLoading = false;
        },
        err => {
          this.isLoading = false;
          this.viewState.emit(EFeatureViewMode.serverError);
        },
      );
  }
  public commonErrorResult: CommonErrorResult[] = [];

  @Input() public maxResult: number = 3;
  @Input() public theme: string = '';
  @Output() public viewState: EventEmitter<EFeatureViewMode> = new EventEmitter<EFeatureViewMode>();
  /**
   * query model for loggregation query
   */

  private loggregationPipe: LoggregationPipe = new LoggregationPipe();

  constructor(
    private loggregationService: LoggregationService,
    private router: Router,
    private logsService: LogsService,
    private globalParams: GlobalParamsProvider,
  ) {
    super();
    this.loggregationPipe.clickableParam = '$2';
  }

  /**
   * get top most result ordered by count desc.
   * loggregationEntities: array of loggreagation
   * limit: number of results
   */
  public getTopLoggregationResult(loggregationEntities: Array<LoggregationEntity>): Array<LoggregationEntity> {
    return _.orderBy(
      loggregationEntities,
      (entity: LoggregationEntity): number => {
        return entity.count;
      },
      'desc',
    );
  }

  public onItemClicked(link: string): void {
    window.open(link, '_blank');
  }

  public onChartClicked(e: any[]): void {
    if (e[0]) {
      this.navToLogs(e[0]);
    }
  }

  public navToLogs(queryModel: LogQueryModel): void {
    this.globalParams.chartClickedLogQueryModel = queryModel;
    this.router.navigate(['/query-new/logs']);
  }
}

class CommonErrorResult {
  public loggregationEntity: LoggregationEntity;
  public statisticsQueryDefinition: StatisticsQueryDefinition;
  public chartModel: ChartModel;
  public link: string;
}
