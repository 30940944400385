import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
declare let Boomerang: any;
@Component({
  selector: 'sh-heroku-sso',
  templateUrl: './heroku-sso.component.html',
  styleUrls: ['./heroku-sso.component.scss'],
})
export class HerokuSsoComponent implements OnInit {
  private userName: string;
  private tempToken: string;
  private teamId: string;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    const { token: tempToken, userName, team_id: teamId } = route.snapshot.params;
    this.tempToken = tempToken;
    this.userName = userName;
    this.teamId = teamId;
    if (!this.userName) {
      const { userName } = route.snapshot.queryParams;
      this.userName = userName ? decodeURIComponent(userName) : null;
    }
  }

  public ngOnInit(): void {
    // Boomerang.init({app: 'herokuApp', addon: 'Coralogix'});
    this.authService.getPermanentTokenFromTemp({ token: this.tempToken, userName: this.userName })
    .first()
      .subscribe(
        (res) => {
          this.authService.storeUser(res.token || res, this.userName, 'heroku');
          if (this.teamId) {
            this.authService.selectTeamId(this.teamId);
          }
          this.router.navigate(['/dashboard']);
        },
        () => {
          this.router.navigate(['/dashboard']);
        },
      );
  }
}
