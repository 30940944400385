import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'sh-dialog-actions',
  templateUrl: './dialog-actions.component.html',
  styleUrls: ['./dialog-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogActionsComponent {

}
