import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoralogixSidebarComponent } from './coralogix-sidebar.component';

@NgModule({
  declarations: [CoralogixSidebarComponent],
  imports: [
    CommonModule,
  ],
  exports: [CoralogixSidebarComponent],
  entryComponents: [CoralogixSidebarComponent]
})
export class CoralogixSidebarModule { }
