export enum AlertsInsightsTime {
  Last1Hour,
  Last3Hours,
  Last6Hours,
  Last12Hours,
  Last24Hours,
}

export interface IInsightTime {
  caption: string;
  seconds: number;
  value: AlertsInsightsTime;
}

export class AlertsInsight {
  public readonly insightsTime: IInsightTime[];

  constructor() {
    this.insightsTime = [
      {
        caption: 'Last 1 hour',
        seconds: 3600,
        value: AlertsInsightsTime.Last1Hour,
      },
      {
        caption: 'Last 3 hours',
        seconds: 10800,
        value: AlertsInsightsTime.Last3Hours,
      },
      {
        caption: 'Last 6 hours',
        seconds: 21600,
        value: AlertsInsightsTime.Last6Hours,
      },
      {
        caption: 'Last 12 hours',
        seconds: 21600,
        value: AlertsInsightsTime.Last12Hours,
      },
      {
        caption: 'Last 24 hours',
        seconds: 86400,
        value: AlertsInsightsTime.Last24Hours,
      },
    ];
  }
}

export const defaultInsightTime = new AlertsInsight().insightsTime[AlertsInsightsTime.Last12Hours];
