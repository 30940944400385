import { RulesValidateActions } from '../actions/rules-validate.actions';

export namespace RulesValidateReducer {
  export interface State {
    previewText: string;
    validationResults: any;
  }

  const initalState: State = {
    previewText: null,
    validationResults: {},
  };

  export function reducer(
    state: State = initalState,
    action: RulesValidateActions.Actions,
  ): State {
    switch (action.type) {
      case RulesValidateActions.ActionTypes.CHANGE_SELECTED_PREVIEW_LOG:
        return Object.assign({}, state, { previewText: action.payload });
      case RulesValidateActions.ActionTypes.VALIDATE_PREVIEW_TEXT:
        return state;
      case RulesValidateActions.ActionTypes.VALIDATE_PREVIEW_TEXT_COMPLETED:
        return Object.assign({}, state, { validationResults: action.payload });
      case RulesValidateActions.ActionTypes.VALIDATE_PREVIEW_TEXT_FAILED:
        return Object.assign({}, state, { validationResults: '{}' });
      default:
        return state;
    }
  }
}
