<div class="info-container tag-content" fxLayout="column" fxFlex="auto" fxLayoutGap="40px">
  <section class="content-wrapper" *ngIf="tagForm" fxLayout="column" fxLayoutGap="30px">
    <mat-card class="coralogix" fxLayout="column" fxLayoutGap="30px">
      <div class="tag-panel-card-header">Details</div>
      <form fxLayout="column" fxLayoutGap="25px">
        <sh-basic-input
          [formControl]="$any(tagForm.controls?.text_value)"
          label="Tag Description"
          [isRequired]="true"
          placeholder="Enter a description"
          errorMsg="Tag Description is required"
        ></sh-basic-input>
      </form>
    </mat-card>
    <mat-card class="coralogix" fxLayout="column">
      <div class="tag-panel-card-header">Tag Content</div>
      <div class="tag-panel-card-subtitle">Chose application/subsystem and timeframe for the tag</div>
      <form fxLayout="column" class="tag-content__time-settings" [formGroup]="tsGroup">
        <div fxLayout="row">
          <sh-coralogix-ng-date-picker
            fxFlex="50%"
            (acceptTime)="acceptTime($event)"
            [initialValue]="tsGroup.controls?.build_timestamp_date?.value"
            [dateOptions]="dateOptions"
            [disableOpenEvent]="isTimeDisabled"
            [hasError]="!tsGroup.valid"
          ></sh-coralogix-ng-date-picker>
          <div fxFlex="50%" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="end center">
              <img class="tag-content__clock-img" src="{{sharedAssetsPath}}icon-clock.svg"/>
              <span class="tag-content__time-zone-sub">{{timeZoneSubHeader}}</span>
            </div>
            <div class="coralogix input-group relative-input tag-content__time">
              <sh-coralogix-time-input
                [hasValidationError]="!tsGroup.valid"
                [disableCtrl]="isTimeDisabled"
                [showSeconds]="true"
                formControlName="build_timestamp_time">
              </sh-coralogix-time-input>
            </div>
          </div>
        </div>
        <mat-hint class="tag-content__error {{isTimeDisabled ? 'tag-content__error--disabled': ''}}"
                  *ngIf="!tsGroup.valid || isTimeDisabled">
          Date cannot be greater than current date or more than a week old
        </mat-hint>
      </form>
      <form [formGroup]="tagForm" fxLayout="column" fxLayoutGap="24px">
        <div class="coralogix input-group">
          <sh-coralogix-select
            [selectOptions]="applicationOptions$ | async"
            [multiple]="true"
            [searchable]="true"
            [disableSelectAll]="true"
            [placeholder]="'select applications'"
            formControlName="application_name">
            <label select-label>
              <span class="text">Applications</span>
            </label>
          </sh-coralogix-select>
        </div>
        <div class="coralogix input-group">
          <sh-coralogix-select
            [selectOptions]="subsystemOptions$ | async"
            [multiple]="true"
            [searchable]="true"
            [disableSelectAll]="true"
            [placeholder]="'select subsystems'"
            formControlName="subsystem_name">
            <label select-label>
              <span class="text">Subsystems</span>
            </label>
          </sh-coralogix-select>
        </div>
      </form>
    </mat-card>
  </section>
</div>
