<p class="mat-card-header conditions-header">
  Conditions
</p>
<p class="mat-card-subheader" *ngIf="isNewValueAlert">
  Select a key to track. Note, this key needs to have less than 50K unique values in the defined timeframe
</p>
<form [formGroup]="formGroup">
  <div *ngIf="isRatioAlert">
    <div style="display: inline-flex; align-items: center;">
      <div class="relative-label">Alert if: {{ relativeCalcStr }} equals</div>
      <div class="coralogix input-group relative-input">
        <sh-coralogix-select
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [selectOptions]="conditionsOperatorOptions"
          formControlName="conditionOperator"
        ></sh-coralogix-select>
      </div>
      <div class="relative-label">than</div>
      <div class="coralogix input-group relative-input" style="min-width: 50px; width: 100px">
        <sh-coralogix-number-input
          [itemSingleName]="''"
          [itemPluralName]="''"
          [minValue]="null"
          [showValue]="hasConditions"
          formControlName="conditionThreshold"
        ></sh-coralogix-number-input>
      </div>
      <div class="relative-label">in</div>
      <div class="coralogix input-group relative-input">
        <sh-coralogix-select
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [selectOptions]="conditionTimeframeOptions"
          [hideValue]="!hasConditions"
          formControlName="conditionTimeframe"
        ></sh-coralogix-select>
      </div>
    </div>
  </div>
  <div *ngIf="isRelativeTimeAlert">
    <div class="relative-timeframe-container">
      <div class="relative-label">Alert if ratio is</div>
      <div class="coralogix input-group relative-input">
        <sh-coralogix-select
          bindLabel="name"
          bindValue="id"
          [selectOptions]="conditionsOperatorOptions"
          formControlName="conditionOperator"
        ></sh-coralogix-select>
      </div>
      <div class="relative-label">than</div>
      <div class="coralogix input-group relative-input relative-timeframe-condition-threshold">
        <sh-coralogix-number-input
          itemSingleName=""
          itemPluralName=""
          [minValue]="0"
          [showValue]="hasConditions"
          formControlName="conditionThreshold"
        ></sh-coralogix-number-input>
      </div>
      <div class="relative-label">compared to</div>
      <div class="coralogix input-group relative-timeframe-input">
        <sh-coralogix-select
          bindLabel="name"
          bindValue="id"
          [selectOptions]="relativeTimeframeOptions"
          (ngModelChange)="onRelativeTimeframeOptionsChange()"
          formControlName="relativeTimeframeOptionId"
        ></sh-coralogix-select>
      </div>
    </div>
  </div>
  <div *ngIf="isCardinalityAlert">
    <div class="top-inputs-container">
      <div class="coralogix input-group sh-mb-30" [class.coralogix-input-error]="hasError('cardinalityFields', 'required')">
        <sh-coralogix-select [selectOptions]="optionsRequired" [searchable]="true" [clearable]="true" formControlName="cardinalityFields">
          <label class="label-with-tooltip" select-label>
            <span class="text">Unique Count By Key</span>
          </label>
        </sh-coralogix-select>
        <span *ngIf="hasError('cardinalityFields', 'required')" class="error">
          Unique count by key is required
        </span>
      </div>
    </div>
    <div class="top-inputs-container">
      <div class="coralogix input-group">
        <label class="input-label">Max Unique Values</label>
        <sh-coralogix-number-input
          [minValue]="1"
          [maxValue]="1000"
          [showValue]="hasConditions"
          formControlName="conditionThreshold"
        ></sh-coralogix-number-input>
        <mat-error class="form-field-error">{{ conditionThresholdError }}</mat-error>
      </div>
      <div class="coralogix input-group">
        <sh-coralogix-select
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [selectOptions]="cardinalityTimeframeOptions"
          formControlName="conditionTimeframe"
        >
          <label select-label>
            <span class="text">In Timeframe</span>
          </label>
        </sh-coralogix-select>
      </div>
    </div>
    <div class="group-fields-checkbox-container">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <cgx-checkbox formControlName="isCardinalityGroupBy" label="Group Unique Count per Specific Key"></cgx-checkbox>
        <i
          class="fa fa-question-circle-o text-cgx-text-primary"
          position="right"
          [horizontal]="8"
          horizontalDirection="left"
          cgxTooltipClass="cgx-alerts-tooltip"
          [cgxTooltip]="groupUniqueCountTooltipText"
        ></i>
      </div>
    </div>
    <div class="group-fields-container">
      <div class="top-inputs-container">
        <div class="coralogix input-group sh-mb-30">
          <sh-coralogix-select [selectOptions]="options" [searchable]="true" [clearable]="true" [formControl]="firstGroupByField">
            <label class="label-with-tooltip" select-label>
              <span class="text">Group By Key</span>
            </label>
          </sh-coralogix-select>
          <span class="error em-height">
            {{ groupByFieldsError }}
          </span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="group-fields-title text-cgx-label">Max Unique Values for Group By Key</div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <div class="fields-label">Unique Values</div>
            <input
              formControlName="cardinalityGroupByFields"
              class="sh-group-field-input"
              type="number"
              min="1"
              max="1000"
              [value]="cardinalityGroupByFieldsControl.value"
            />
          </div>
        </div>
      </div>
      <mat-slider
        style="width: 100%;"
        formControlName="cardinalityGroupByFields"
        class="example-margin"
        min="1"
        max="1000"
        step="1"
        thumbLabel
        [displayWith]="formatLabel"
        [value]="cardinalityGroupByFieldsControl.value"
      ></mat-slider>
      <div class="slider-edge-values">
        <div>1</div>
        <div>1000</div>
      </div>
      <div class="total-values-row">Max Unique Count Values Should Not Exceed {{ cardinalityValues }}</div>
      <div *ngIf="!isCardinalityGroupByControl.value" class="overlay-group-fields-container bg-cgx-menu-bg"></div>
    </div>
  </div>
  <div *ngIf="isNewValueAlert">
    <div class="top-inputs-container">
      <div class="coralogix input-group" [class.coralogix-input-error]="firstGroupByFieldError('required')">
        <sh-coralogix-select [selectOptions]="options" [searchable]="true" [clearable]="true" [formControl]="firstGroupByField">
          <label class="label-with-tooltip" select-label>
            <span class="text">Key to track</span>
          </label>
        </sh-coralogix-select>
        <span *ngIf="firstGroupByFieldError('required')" class="error">
          Key to track is required
        </span>
      </div>
      <div class="coralogix input-group">
        <sh-coralogix-select
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [selectOptions]="conditionTimeframeOptions"
          [hideValue]="hideIsMoreThenDynamicAlertValue"
          formControlName="conditionTimeframe"
        >
          <label select-label>
            <span class="text">Notify on new value in the last:</span>
          </label>
        </sh-coralogix-select>
      </div>
    </div>
  </div>

  <div *ngIf="isMetricAlert">
    <ng-container *ngIf="!isPromQLSyntax">
      <div class="top-inputs-container" fxLayout="row">
        <div class="coralogix input-group" fxFlex="68">
          <sh-metric-field-picker
            [fields]="conditionMetricFieldOptions"
            formControlName="conditionMetricField"
            [class.coralogix-input-error]="hasError('conditionMetricField', 'required')"
          ></sh-metric-field-picker>
          <span *ngIf="hasError('conditionMetricField', 'required')" class="error">
            Metric Field is required
          </span>
        </div>
        <div class="coralogix input-group"></div>
      </div>
      <div class="top-inputs-container" fxLayout="row" fxLayoutGap="20px">
        <div class="coralogix input-group" fxFlex="33">
          <sh-coralogix-select
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [selectOptions]="conditionMetricArithmeticOptions"
            [hideSelected]="false"
            formControlName="conditionMetricArithmeticOperator"
          >
            <label select-label>
              <span class="text">Arithmetic</span>
            </label>
          </sh-coralogix-select>
        </div>
        <div class="coralogix input-group" *ngIf="isPercentileSelected()" fxFlex="32">
          <label class="input-label">
            Percentile
          </label>
          <sh-coralogix-number-input
            [itemSingleName]="''"
            [itemPluralName]="''"
            [minValue]="0"
            [maxValue]="100"
            formControlName="conditionMetricArithmeticModifier"
            [class.coralogix-input-error]="hasError('conditionMetricArithmeticModifier', 'required')"
          ></sh-coralogix-number-input>
          <span *ngIf="hasError('conditionMetricArithmeticModifier', 'required')" class="error">
            Percentile value is required
          </span>
        </div>
        <div class="coralogix input-group"></div>
      </div>
    </ng-container>

    <div class="top-inputs-container" fxLayout="row" fxLayoutGap="20px">
      <div class="coralogix input-group" fxFlex="33">
        <sh-coralogix-select
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [selectOptions]="conditionsOperatorOptions"
          [hideSelected]="false"
          (ngModelChange)="onMetricConditionOperatorChanged()"
          formControlName="conditionOperator"
        >
          <label select-label>
            <span class="text">Is</span>
          </label>
        </sh-coralogix-select>
      </div>
      <div class="coralogix input-group" fxFlex="32">
        <label class="input-label">Value</label>
        <sh-coralogix-number-input
          [itemSingleName]="''"
          [itemPluralName]="''"
          formControlName="conditionMetricValueThreshold"
        ></sh-coralogix-number-input>
      </div>
    </div>

    <div class="top-inputs-container" fxLayout="row">
      <div *ngIf="!isSumOrCountSelected()" class="coralogix input-group" fxFlex="15">
        <label class="input-label">For over</label>
        <sh-coralogix-number-input
          [itemSingleName]="''"
          [itemPluralName]="''"
          formControlName="conditionSampleThreshold"
          [step]="10"
        ></sh-coralogix-number-input>
      </div>
      <div *ngIf="!isSumOrCountSelected()" class="metric-threshold-label">%</div>
      <div class="coralogix input-group" fxFlex="37">
        <sh-coralogix-select
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [selectOptions]="conditionTimeframeOptions"
          [hideValue]="false"
          [hideSelected]="false"
          formControlName="conditionTimeframe"
        >
          <label select-label>
            <span class="text">{{ isSumOrCountSelected() ? 'In' : 'Of' }} the last</span>
          </label>
        </sh-coralogix-select>
      </div>
      <div class="coralogix input-group"></div>
    </div>
    <div class="top-inputs-container" fxLayout="row" fxLayoutGap="20px">
      <div class="coralogix input-group  enforce-null-container" fxFlex="45">
        <cgx-checkbox
          class="enforce-null-checkbox"
          formControlName="conditionSwapNullValue"
          label="Replace missing values with 0"
        ></cgx-checkbox>
      </div>
      <div class="coralogix input-group"></div>
    </div>
    <div class="top-inputs-container" fxLayout="row" *ngIf="!isSwapNullValueChecked()">
      <div class="data-threshold-label">At least</div>
      <div class="coralogix input-group" fxFlex="15">
        <sh-coralogix-number-input
          [itemSingleName]="''"
          [itemPluralName]="''"
          formControlName="conditionNonNullPercentage"
          [step]="10"
        ></sh-coralogix-number-input>
      </div>
      <div class="data-threshold-label">% of the timeframe needs to have values for this alert to trigger</div>
    </div>

    <div
      class="coralogix input-group"
      *ngIf="showMultiLevelGroupBy && !isPromQLSyntax"
      [class.coralogix-input-error]="hasError('groupByFields', 'required')"
    >
      <sh-alerts-group-by
        [options]="options"
        [formGroup]="formGroup"
        [metricAlertGroupByLimit]="metricAlertGroupByLimit$ | async"
        class="group-by"
      ></sh-alerts-group-by>
    </div>
    <div *ngIf="showGroupBy && !isPromQLSyntax" class="top-inputs-container">
      <div class="coralogix input-group sh-mb-30">
        <sh-coralogix-select [selectOptions]="options" [searchable]="true" [clearable]="true" [formControl]="firstGroupByField">
          <label class="label-with-tooltip" select-label>
            <span class="text">Group by key</span>
            <div>
              <i
                class="fa fa-question-circle-o"
                cgxTooltipClass="cgx-alerts-tooltip"
                position="right"
                [horizontal]="8"
                horizontalDirection="left"
                cgxTooltip="An alert will trigger whenever any of the aggregated values appear more than the threshold within the specified timeframe"
              ></i>
            </div>
          </label>
        </sh-coralogix-select>
      </div>
    </div>

    <div class="coralogix input-group">
      <label class="input-label label-with-tooltip">
        <span class="text">Notify When Resolved</span>
        <div>
          <i
            class="fa fa-question-circle-o"
            cgxTooltipClass="cgx-alerts-tooltip"
            position="right"
            [horizontal]="8"
            horizontalDirection="left"
            cgxTooltip="Sends a “Resolve” notification when the alert criteria you defined is no longer met"
          ></i>
        </div>
      </label>
      <mat-slide-toggle class="coralogix" formControlName="notifyOnResolved"></mat-slide-toggle>
    </div>
  </div>

  <div *ngIf="!isRatioAlert && !isNewValueAlert && !isCardinalityAlert && !isRelativeTimeAlert && !isMetricAlert">
    <div class="top-inputs-container">
      <div class="coralogix input-group">
        <sh-coralogix-select
          data-test="alerts-alert-when-select"
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [selectOptions]="conditionsOperatorOptions"
          formControlName="conditionOperator"
        >
          <label select-label>
            <span class="text">Alert when</span>
          </label>
        </sh-coralogix-select>
      </div>
      <div class="coralogix input-group">
        <label *ngIf="!isMoreThanDynamicAlert" class="input-label">Occurrences</label>
        <label *ngIf="isMoreThanDynamicAlert" class="input-label label-with-tooltip">
          <span class="text">Minimum threshold</span>
          <div>
            <i
              class="fa fa-question-circle-o"
              cgxTooltip="An alert will trigger when the number of matches is higher than normal, and above the minimum threshold"
              position="above"
              cgxTooltipClass="cgx-alerts-tooltip"
              [top]="-4"
            ></i>
          </div>
        </label>

        <sh-coralogix-number-input
          [itemSingleName]="'time'"
          [itemPluralName]="'times'"
          [minValue]="0"
          [showValue]="hasConditions"
          formControlName="conditionThreshold"
        ></sh-coralogix-number-input>
      </div>
      <div class="coralogix input-group">
        <sh-coralogix-select
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [selectOptions]="conditionTimeframeOptions"
          [hideValue]="hideTimeWindowValue"
          formControlName="conditionTimeframe"
        >
          <label select-label>
            <span class="text">Time window</span>
          </label>
        </sh-coralogix-select>
      </div>
    </div>
    <div class="coralogix input-group" *ngIf="showMultiLevelGroupBy" [class.coralogix-input-error]="hasError('groupByFields', 'required')">
      <sh-alerts-group-by [options]="options" [formGroup]="formGroup" class="group-by"></sh-alerts-group-by>
    </div>
    <div *ngIf="showGroupBy" class="top-inputs-container">
      <div class="coralogix input-group sh-mb-30">
        <sh-coralogix-select [selectOptions]="options" [searchable]="true" [clearable]="true" [formControl]="firstGroupByField">
          <label class="label-with-tooltip" select-label>
            <span class="text">Group by key</span>
          </label>
        </sh-coralogix-select>
      </div>
    </div>
    <div class="coralogix input-group" *ngIf="hasConditions">
      <div *ngIf="isMoreThanDynamicAlert" class="beta-container">
        <div class="title">
          BETA
        </div>
        <div class="content">
          Coralogix Dynamic Alerts enable you to detect abnormal behavior automatically - without having to set fixed threshold values.
        </div>
      </div>
      <ng-container *ngIf="!isMoreThanDynamicAlert">
        <label class="input-label label-with-tooltip">
          <span class="text">Notify When Resolved</span>
          <div>
            <i
              class="fa fa-question-circle-o"
              cgxTooltipClass="cgx-alerts-tooltip"
              position="right"
              [horizontal]="8"
              horizontalDirection="left"
              cgxTooltip="Sends a “Resolve” notification when the alert criteria you defined is no longer met"
            ></i>
          </div>
        </label>
        <mat-slide-toggle class="coralogix" formControlName="notifyOnResolved"></mat-slide-toggle>
      </ng-container>
    </div>
  </div>
  <p class="mat-card-subheader relative-time-alert-notification" *ngIf="isRelativeTimeAlert">
    {{ relativeTimeSilenced }}
  </p>

  <div class="coralogix input-group alerts-notify-container" *ngIf="!isNewValueAlert && !isCardinalityAlert">
    <label class="input-label label-with-tooltip">
      <span class="text">Notify Every</span>
      <i
        class="fa fa-question-circle-o"
        position="right"
        [horizontal]="8"
        horizontalDirection="left"
        cgxTooltipClass="cgx-alerts-tooltip"
        cgxTooltip="The suppress time for the alerts after the first alert is notified"
      ></i>
    </label>

    <div class="coralogix time-picker">
      <sh-coralogix-select
        data-test="alerts-notify-every-hours-select"
        [selectOptions]="timePicker.hours"
        formControlName="notifyEveryHours"
        placeholder="Hours"
        (ngModelChange)="onHoursChange()"
        class="select-picker"
      >
        <label select-label>
          <span class="text">Hours</span>
        </label>
      </sh-coralogix-select>

      <sh-coralogix-select
        data-test="alerts-notify-every-minutes-select"
        [selectOptions]="timePicker.minutes"
        formControlName="notifyEveryMinutes"
        placeholder="Minutes"
        [class.disabled]="notifyEveryMinutesDisabled"
        class="select-picker"
      >
        <label select-label>
          <span class="text">Minutes</span>
        </label>
      </sh-coralogix-select>
    </div>

    <div class="error-bar" *ngIf="formGroup.hasError('notifyEveryLessThanError')">
      Notify every can not be smaller than the time window when set to "less than"
    </div>
  </div>
</form>
