<div
    class="base-dialog"
    [ngStyle]="{
    width: width || null,
    height: height || null
}"
>
    <div
        *ngIf="!hideCloseBtn"
        class="base-dialog-close-wrapper"
    >
        <button
            class="btn-close cgx-btn-icon"
            (click)="onClose()"
        >
          <svg-icon style="display: block; height: 16px" src="assets/icons/xs-16x16/close.svg"></svg-icon>
        </button>
    </div>

    <ng-content></ng-content>
</div>
