import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';

@Component({
    selector: 'sh-logs-sidebar',
    templateUrl: './logs-sidebar.component.html',
    styleUrls: [ './logs-sidebar.component.scss' ],
})
export class LogsSidebarComponent implements OnInit {
    @Input()
    public isSideBarShown: boolean;
    @Output()
    public dragEnd: EventEmitter<number> = new EventEmitter<number>();
    @Output()
    public dragging: EventEmitter<number> = new EventEmitter<number>();
    @Output()
    public filterChanged: EventEmitter<LogQueryModel> = new EventEmitter<LogQueryModel>();
    @Output()
    public initialized: EventEmitter<number> = new EventEmitter<number>();

    public sideBarWidth: number;
    public translateWidth: number;
    public isSideBarCollapsed: boolean;
    public isResizing: boolean;
    public isHovered: boolean;
    private readonly _initialSideBarWidth: number = 250;
    private readonly _minSideBarWidth: number = 200;
    private readonly _maxSideBarWidth: number = 450;
    private readonly _collapsedSideBarWidth: number = 20;

    public ngOnInit(): void {
        this.sideBarWidth = this._initialSideBarWidth;
        this.initialized.emit(this.sideBarWidth);
    }

    public get sideBarRightMargin(): number {
        if (this.isSideBarCollapsed) {
           return this._collapsedSideBarWidth;
        }
        return 0;
    }

    public onResize(clientX: number): void {
        if (clientX > this._maxSideBarWidth || clientX < this._minSideBarWidth) {
            return;
        }
        this.isResizing = true;
        this.sideBarWidth = clientX;
        this.isSideBarCollapsed = false;
        this.dragging.emit(this.sideBarWidth);
    }

    public onDragEnd(): void {
        this.isResizing = false;
        this.dragEnd.emit(this.sideBarWidth);
    }

    public toggleSideBar(): void {
        this.isSideBarCollapsed ? this._expandSideBar() : this._collapseSideBar();
    }

    private _expandSideBar(): void {
        this.translateWidth = 0;
        this.isSideBarCollapsed = false;
        this.dragEnd.emit(this.sideBarWidth);
    }

    private _collapseSideBar(): void {
        this.translateWidth = - (this.sideBarWidth - this._collapsedSideBarWidth);
        this.isSideBarCollapsed = true;
        this.dragEnd.emit(this._collapsedSideBarWidth);
    }
}
