import { CustomGridHeaderComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/custom-grid-header/custom-grid-header.component';
import { AgGridColumn } from 'ag-grid-angular';
import { TeamRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/cell-renderers/team-renderer/team-renderer.component';
import { SeverityRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/severity-renderer/severity-renderer.component';
import { LogsFormatterRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/logs-formatter-renderer/logs-formatter-renderer.component';
import { TemplatesFormatterRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/templates-formatter-renderer/templates-formatter-renderer.component';
import { TemplatesOccurrencesRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/templates-occurrences-renderer/templates-occurrences-renderer.component';
import { SideMenuRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/side-menu-renderer/side-menu-renderer.component';

export const defaultColDef: Partial<AgGridColumn> = {
  sortable: true,
  resizable: true,
  filter: true,
};

export const defaultFrameworkComponents: { [key: string]: any } = {
  agColumnHeader: CustomGridHeaderComponent,
  severityRenderer: SeverityRendererComponent,
  jsonFormatterRenderer: LogsFormatterRendererComponent,
  templatesFormatterRenderer: TemplatesFormatterRendererComponent,
  templatesOccurrencesRenderer: TemplatesOccurrencesRendererComponent,
  sideMenuRenderer: SideMenuRendererComponent,
  teamRenderer: TeamRendererComponent,
};
