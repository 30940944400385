import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakLinePipe',
})
export class BreakLinePipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    if (value) {
      return value.replace(/(?:\\n\\r|\\r|\\n|\r\n|\r|\n$)\b/g, '<br />');
    }

    return value;
  }
}
