import * as moment from 'moment';

export class GridCellHelper {

  public static timestampCellRenderer(params: any) {
    if (params.value) {
      return moment(params.value).format('DD/MM/YYYY HH:mm:ss.ms a');
    }
    return null;
  }

  public static severityCellRenderer(params: any) {
    if (params.value) {
      const element = document.createElement('div');
      element.className = params.value.toLowerCase();
      element.appendChild(document.createTextNode(params.value));
      return element;
    }
    return null;
  }

  public static getElementsByAttribute(oElm, strTagName, strAttributeName, strAttributeValue) {
    const arrElements = (strTagName == '*' && oElm.all) ? oElm.all : oElm.getElementsByClassName(strTagName);
    const arrReturnElements = new Array();
    const oAttributeValue = (typeof strAttributeValue != 'undefined') ? new RegExp('(^|\\s)' + strAttributeValue + '(\\s|$)', 'i') : null;
    let oCurrent;
    let oAttribute;
    for (let i = 0; i < arrElements.length; i++) {
      oCurrent = arrElements[i];
      oAttribute = oCurrent.getAttribute && oCurrent.getAttribute(strAttributeName);
      if (typeof oAttribute == 'string' && oAttribute.length > 0) {
        if (typeof strAttributeValue == 'undefined' || (oAttributeValue && oAttributeValue.test(oAttribute))) {
          arrReturnElements.push(oCurrent);
        }
      }
    }
    return arrReturnElements;
  }
}
