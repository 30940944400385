import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AgRendererComponent } from 'ag-grid-ng2/main';
import { LoggregationParamsService } from '../grid/services/loggregation-params.service';
declare let $;
@Component({
  moduleId: 'loggregation-text-grid-cell',
  selector: 'sh-loggregation-text-grid-cell',
  templateUrl: 'loggregation-text-grid-cell.component.html',
  styleUrls: ['loggregation-text-grid-cell.component.scss'],
})
export class LoggregationTextGridCellComponent implements AgRendererComponent {
  @ViewChild('loggregationContainer', { static: true })
  public container: ElementRef;
  public paramsValue: any;
  private params: any;
  private clickableParam: string =
    '<div class="ui lable loggregation loggregation-$3-style {CoralogixPHStatsEnabled}"' +
    ' parameterId="$1" parameterType="$3" id="parameter"> *$2</div>';
  constructor(
    private _sanitizer: DomSanitizer,
    private loggregationParamsService: LoggregationParamsService,
  ) {}
  public refresh(params: any): boolean {
    return false;
  }
  public agInit(params: any): void {
    this.params = params;
    this.paramsValue = this._sanitizer.bypassSecurityTrustHtml(
      this.loggregationParamsService.buildLoggregationHTMLParams(
        params.value,
        this.clickableParam,
        false,
        null,
        params.data.placeholdersEnabled
      ),
    );
  }

  public mouseOver(e: any): void {
    if (e.srcElement) {
      if (e.srcElement.getAttribute('id') === 'parameter') {
        const disabled = this.elementDisabled(e.srcElement);
        const hideTimeout = disabled ? 50 : 0;
        const popupSettings = {
          inline: false,
          position: 'top center',
          html: null,
          content: null,
          hoverable: false,
          exclusive: true,
          delay: {
            show: 0,
            hide: hideTimeout
          }
        };
        if (disabled) {
          popupSettings.html = 'Parameter breakdowns are only available for High Priority logs<br>' +
                               '<a target="_blank" href="https://coralogix.com/tutorials/optimize-log-management-costs/">Learn More</a>';
          popupSettings.hoverable = true;
        } else {
          popupSettings.content = '*Default value for this variable';
        }
        const element = $(e.srcElement);

        element.popup(popupSettings);
        element.popup('show');
      }
    }
  }

  public OnMouseOut(e: any): void {}

  public onCellClicked(e: any): void {
    if (e.target.attributes.getNamedItem('parameterId') && ! this.elementDisabled(e.srcElement)) {
      this.params.context.showModal(
        this.params.node.data.id,
        e.target.attributes.getNamedItem('parameterId').value,
      );
    }
  }

  private elementDisabled(e: HTMLElement): boolean {
    return e.classList.contains('disabled');
  }
}
