import { ILegendData } from '@app/statistics/components/chart-legend/chart-legend.component';

export const compareLineChartLegend: ILegendData[] = [
  {
    name: 'Current tag',
    color: 'var(--cgx-primary)'
  },
  {
    name: 'Comparison Tag',
    color: '#8d9db9'
  }
];
