import { jsonMember, jsonObject } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class LogMetadata {
  @jsonMember
  public category: string;
  @jsonMember
  public className: string;
  @jsonMember
  public methodName: string;
  @jsonMember
  public applicationName: string;
  @jsonMember
  public subsystemName: string;
  @jsonMember
  public computerName: string;
  @jsonMember
  public severity: string;
  @jsonMember
  public IPAddress: string;
  @jsonMember
  public sdkId: string;
  @jsonMember(TypedJsonAny.OPTIONS)
  public threadId: any;
  @jsonMember
  public companyId: number;
}
