import { Injectable } from '@angular/core';
import { ShDialogService } from '@app/shared/services/dialogService';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { DeploySecurityService, UpdateNamesAndRegion, UpdatePrivateKey, UpdateSecurityOnionRegion } from './cloud-security.action';

export interface IProccessStateModel {
    deploySecurityService: boolean;
    appName: string;
    subsystemName: string;
    securityOnionRegion: string;
    privateKey: string;
}

const initialState = (): IProccessStateModel => {
    return {
        deploySecurityService: false,
        appName: null,
        subsystemName: null,
        securityOnionRegion: 'us-east-1',
        privateKey: null
    };
};

@State<IProccessStateModel>({
    name: 'cloudSecurity',
    defaults: initialState()
})
@Injectable()
export class CloudSecurityState {

    constructor(
        public store: Store
    ) {
    }

    @Selector()
    public static deploySecurityService(state: IProccessStateModel): boolean {
        return state.deploySecurityService;
    }

    @Selector()
    public static appName(state: IProccessStateModel): string {
        return state.appName;
    }

    @Selector()
    public static subsystemName(state: IProccessStateModel): string {
        return state.subsystemName;
    }

    @Selector()
    public static securityOnionRegion(state: IProccessStateModel): string {
        return state.securityOnionRegion;
    }

    @Selector()
    public static privateKey(state: IProccessStateModel): string {
        return state.privateKey;
    }

    @Selector([CloudSecurityState.appName])
    public static isAppNameEmpty(appName: string): boolean {
        return !appName;
    }

    @Selector([CloudSecurityState.subsystemName])
    public static isSubsystemNameEmpty(subsystemName: string): boolean {
        return !subsystemName;
    }

    @Action(DeploySecurityService)
    public DeploySecurityService(ctx: StateContext<IProccessStateModel>): IProccessStateModel {
        return ctx.patchState({ deploySecurityService: true });
    }

    @Action(UpdatePrivateKey)
    public UpdatePrivateKey(ctx: StateContext<IProccessStateModel>, { privateKey }: UpdatePrivateKey): IProccessStateModel {
        return ctx.patchState({ privateKey });
    }

    @Action(UpdateSecurityOnionRegion)
    public UpdateSecurityOnionRegion(
        ctx: StateContext<IProccessStateModel>,
        { securityOnionRegion }: UpdateSecurityOnionRegion
    ): IProccessStateModel {
        return ctx.patchState({ securityOnionRegion });
    }

    @Action(UpdateNamesAndRegion)
    public UpdateNamesAndRegion(
        ctx: StateContext<IProccessStateModel>,
        { securityOnionRegion, appName, subsystemName }: UpdateNamesAndRegion
    ): IProccessStateModel {
        return ctx.patchState({ securityOnionRegion, appName, subsystemName });
    }

}
