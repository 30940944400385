<form [formGroup]="changePasswordForm">
  <div class="mat-input-container-wrapper" [ngClass]="additionalClassName">
    <mat-form-field class="multiline-hint-abs-under">
      <input
        matInput
        [type]="showPassword ? 'text' : 'password'"
        autocomplete="new-password"
        formControlName="password"
        [placeholder]="passwordLabel"
      />
      <button
        *ngIf="showPasswordButton"
        type="button"
        mat-icon-button
        matSuffix
        (click)="showPassword = !showPassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="showPassword"
      >
        <mat-icon class="login-password-visibility-icon">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint *ngIf="changePasswordForm.controls['password'].dirty && !changePasswordForm.controls['password'].valid" align="start">
        <span *ngIf="passwordError.length === 1">{{ passwordError[0] }}</span>
        <ul *ngIf="passwordError.length > 1">
          <li *ngFor="let errMsg of passwordError">
            {{ errMsg }}
          </li>
        </ul>
      </mat-hint>
    </mat-form-field>
  </div>
  <div *ngIf="repeatPassword" class="mat-input-container-wrapper" [ngClass]="additionalClassName">
    <mat-form-field class="multiline-hint-abs-under">
      <input
        matInput
        [type]="showRepeatPassword ? 'text' : 'password'"
        formControlName="repeatPassword"
        [placeholder]="repeatPasswordLabel"
        autocomplete="new-password"
      />
      <button
        *ngIf="showPasswordButton"
        type="button"
        mat-icon-button
        matSuffix
        (click)="showRepeatPassword = !showRepeatPassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="showRepeatPassword"
      >
        <mat-icon class="login-password-visibility-icon">{{ showRepeatPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint
        align="start"
        *ngIf="
          changePasswordForm.controls['repeatPassword'].dirty &&
          !changePasswordForm.controls['repeatPassword'].valid &&
          changePasswordForm.controls['repeatPassword'].value.length > 0
        "
      >
        No match
      </mat-hint>
      <mat-hint
        align="start"
        *ngIf="
          changePasswordForm.controls['repeatPassword'].dirty &&
          !changePasswordForm.controls['repeatPassword'].valid &&
          changePasswordForm.controls['repeatPassword'].value.length == 0
        "
      >
        Retype new password
      </mat-hint>
    </mat-form-field>
  </div>
</form>
