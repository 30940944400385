import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../app.reducers';
import { TeamActions } from '../user/state/teams/teams.actions';
import { CompanyPlanStatus } from '../user/shared/models/company-plan-status.model';
import { CompanyRequest } from '@app/auth/shared/models/activateUser.model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetCompany, GetCompanyPlanStatus } from '@app/ngxs-store/company/company.action';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { Select } from '@ngxs/store';
import { GetTeammate } from '@app/ngxs-store/user/user.action';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Teammate } from '@app/shared/models/teammate.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {ThemeService} from "@app/logs-new/shared/services/theme.service";
import {LogsQueryService} from "@app/logs-new/shared/services/logs-query.service";
import {UserSettingsProvider} from "@app/user/shared/userSettingsProvider";

@Component({
  selector: 'sh-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @SelectSnapshot(UserState.user) public user: Teammate;

  @Select(CompanyState.companyInfo) public companyInfo$: Observable<CompanyRequest>;

  @Select(CompanyState.planStatus) public planStatus$: Observable<CompanyPlanStatus>;

  constructor(private store: Store<State>, private themeService: ThemeService, private userSettingsProvider: UserSettingsProvider) {
    this.store.dispatch(new TeamActions.GetTeams());
    this.store.dispatch(new TeamActions.GetSelectedTeam());
    this.getTeammate();
  }

  ngOnInit(): void {
    this.getCompany();
    this.GetCompanyPlanStatus();
    this.setDefaultTheme();
  }

  setDefaultTheme(): void {
    this.themeService.theme = this.userSettingsProvider.userSettings.logsTheme || 'light';
  }

  isPlanLimitBarVisible(planStatus: CompanyPlanStatus): boolean {
    return planStatus && ['block', 'warn'].includes(planStatus.status);
  }

  @Dispatch() public getCompany = () => new GetCompany();

  @Dispatch() public GetCompanyPlanStatus = () => new GetCompanyPlanStatus();

  @Dispatch() public getTeammate = () => new GetTeammate();
}
