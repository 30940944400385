import {differenceInSeconds} from 'date-fns';
import {Point} from 'highcharts';
import {BasicChartComponent} from '@shared/controls/charts/basic-chart/basic-chart.component';
import {EScaleOptions, graphPointValue, timestampVal} from '@app/statistics/shared/models/line-chart.models';
import {Compiler, ComponentRef, Injector, Type} from '@angular/core';
import {DynamicLogsTooltipModule} from '@shared/controls/charts/dynamic-tooltip/dynamic-logs-tooltip/dynamic-logs-tooltip.module';
import {DynamicLogsTooltipComponent} from '@shared/controls/charts/dynamic-tooltip/dynamic-logs-tooltip/dynamic-logs-tooltip.component';

export const MaxMinutesForMinutesView = 180;
export const MaxMinutesForSecondsView = 3;

export class ComponentFactoryClass<M, C> {
  constructor(private injector: Injector, private compiler: Compiler) {
  }

  public createComponent = (module: Type<M>, component: Type<C>): ComponentRef<C> => {
    const compiledModule = this.compiler.compileModuleAndAllComponentsSync(
      module
    );
    const factory = compiledModule.ngModuleFactory
      .create(this.injector)
      .componentFactoryResolver.resolveComponentFactory(component);
    return factory.create(this.injector);
  }
}

export function getDynamicTooltipComponentInstance(injector: Injector, compiler: Compiler): ComponentRef<DynamicLogsTooltipComponent> {
  return new ComponentFactoryClass<DynamicLogsTooltipModule,
    DynamicLogsTooltipComponent>(injector, compiler).createComponent(
    DynamicLogsTooltipModule,
    DynamicLogsTooltipComponent
  );
}

export function getTimeUnitScale(data: [timestampVal, graphPointValue][]): EScaleOptions {
  const firstObj = data[0];
  const lastObj = data[data.length - 1];
  if (firstObj?.length && lastObj?.length) {
    const firstValue = firstObj[0];
    const lastValue = lastObj[0];
    const overallDiffInSeconds = Math.abs(differenceInSeconds(firstValue, lastValue));
    return graphScaleByDiff(overallDiffInSeconds);
  }
  return EScaleOptions.minutes;
}

export function getTimeUnitWidgetScale(data: [timestampVal]): EScaleOptions {
  const firstValue = data[0];
  const lastValue = data[data.length - 1];
  if (!!firstValue && !!lastValue) {
    const overallDiffInSeconds = Math.abs(differenceInSeconds(firstValue, lastValue));
    return graphScaleByDiff(overallDiffInSeconds);
  }
  return EScaleOptions.minutes;
}

export function graphScaleByDiff(overallDiffInSeconds: number): EScaleOptions {
  if (overallDiffInSeconds < MaxMinutesForSecondsView * 60) {
    return EScaleOptions.seconds;
  } else if (overallDiffInSeconds < MaxMinutesForMinutesView * 60) {
    return EScaleOptions.minutes;
  } else {
    return EScaleOptions.hours;
  }
}

function getStepLengthByScale(scale: EScaleOptions, stepInSeconds: number): number {
  const oneMinuteInSeconds = 60;
  const oneHourInMinutes = 60;
  switch (scale) {
    case EScaleOptions.seconds:
      return stepInSeconds;
    case EScaleOptions.minutes:
      return stepInSeconds / oneMinuteInSeconds;
    case EScaleOptions.hours:
      return stepInSeconds / oneMinuteInSeconds / oneHourInMinutes;
  }
}

export function getXTimeCategories(values: [number, number][], scale: EScaleOptions): number[] {

  if (Array.isArray(values) && values.length && !!values[1]) {
    const stepDiffInSeconds = Math.abs(differenceInSeconds(values[0][0], values[1][0]));
    const stepLength = getStepLengthByScale(scale, stepDiffInSeconds);
    const overallDiff = stepLength * values.length;
    const isOverallDiffBuggerThanSteps = overallDiff > values.length;
    return [...values].reduce((prev, curr, index) => {
      const diffVal = index * stepLength;
      const fixedVal = isOverallDiffBuggerThanSteps ? Math.round(diffVal) : diffVal.toFixed(1);
      return [...prev, fixedVal + scale];
    }, []);
  }
  return null;
}

export interface IChartTooltipPointData {
  x: number;
  y: number;
  points: Point[];
}

export function getWhiteLineTooltipHtml(currChartData: IChartTooltipPointData,
                                        componentScope: BasicChartComponent): string {

  const items = [...currChartData.points].reduce((prev, curr, index) => {
    const seriesName = curr?.series?.name || `Series ${index}`;
    const pointGap = currChartData.points[0] ? currChartData.points[0].y - currChartData.points[1].y : 0;
    const showGap = index === 0;

    return [...prev, getLineGraphTooltipHtmlItem(seriesName,
      curr.y, curr.color as string, pointGap, showGap)];
  }, []);

  const {chartTooltipDataTitle, scale, tooltipButtonText} = componentScope._chartModel;
  return `<div class="line-tooltip">
    <div class="line-tooltip__titles">
    <div class="line-tooltip__time-from-start" >${getTimeGap(currChartData.x)}</div>
    <div class="line-tooltip__amount-title">${chartTooltipDataTitle}</div>
    </div>
    <div class="line-tooltip__data">
    ${items.join('')}
    </div>
    <button href="#/insights?filter=ALERTS" target="_blank" class="chart-tooltip-button line-tooltip__action-btn">
    ${tooltipButtonText}
    </button>
    <div class="line-tooltip__pointer"></div>
   </div>`;
}

export function getTimeGap(passedTime: number): string {
  return `${passedTime} since tag started`;
}

export function getLineGraphTooltipHtmlItem(name: string, data: number, color: string, gap: number, showGap: boolean): string {
  return `<div class="tooltip-data-item">
    <div class="tooltip-data-item__rectangle" style="background-color: ${color}"></div>
    <div class="tooltip-data-item__title">${name}</div>
    <div class="tooltip-data-item__data">${data}
    <span class="${!showGap ? 'hidden' : ''} tooltip-data-item__data${gap > 0 ? '-positive-growth' : '-negative-growth'}">
(${gap > 0 ? '+' : gap === 0 ? '' : '-'}${Math.abs(gap)})</span>
    </div>
    </div>`;
}
