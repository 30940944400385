import { Action } from '@ngrx/store';
import { type } from '../../../../shared/services/utils';

export namespace LogsGridColumnsActions {
  export const ActionTypes = {
    SET_LOGS_GRID_COLUMNS: type('[LogsGridColumns] Set Logs Grid Columns'),
  };

  export class SetLogsGridColumns implements Action {
    public type = ActionTypes.SET_LOGS_GRID_COLUMNS;

    constructor(public payload?: any) {}
  }

  export type Actions = SetLogsGridColumns;
}
