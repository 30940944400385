import { Injectable } from '@angular/core';
import { ShDialogService } from '@app/shared/services/dialogService';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { GetCompany, GetCompanyPlanDetails, GetCompanyPlanQueryRange, GetCompanyPlanStatus, SetCompany } from './company.action';
import { CompanyPlanDetails } from '@app/user/shared/models/company-plan-details.model';
import { CompanyRequest, CompanySettings } from '@app/auth/shared/models/activateUser.model';
import { CompanyPlanStatus } from '@app/user/shared/models/company-plan-status.model';
import { CompanyQueryRange } from '@app/user/shared/models/company-query-range.model';
import { AuthService } from '@app/auth/shared/services/auth.service';
import { CompanyService } from '@app/user/shared/srvices/company.service';
import { Constants } from '@app/constants';

interface ICompanyModel {
  companyInfo: CompanyRequest;
  planStatus: CompanyPlanStatus;
  planDetails: CompanyPlanDetails;
  queryRange: CompanyQueryRange;
  isLoadingPlanDetails: boolean;
}

const initialState = (): ICompanyModel => {
  return {
    companyInfo: null,
    planStatus: null,
    planDetails: null,
    queryRange: {
      queryRange: 8, // default range is 8 days
    },
    isLoadingPlanDetails: false,
  };
};

@State<ICompanyModel>({
  name: 'company',
  defaults: initialState(),
})
@Injectable()
export class CompanyState {
  constructor(private dialogService: ShDialogService, private authService: AuthService, private companyService: CompanyService) {}

  @Selector()
  public static companyInfo(state: ICompanyModel): CompanyRequest {
    return state?.companyInfo;
  }

  @Selector()
  public static logs2MetricsPermutationLimit(state: ICompanyModel): number {
    const permutationsLimit = state?.companyInfo?.settings?.logs2metrics_permutations_limit;
    return permutationsLimit ?? Constants.DEFAULT_GLOBAL_PERMUTATIONS_LIMIT;
  }

  @Selector()
  public static maxInsightsDisplayed(state: ICompanyModel): number {
    return state?.companyInfo?.settings?.max_insights_displayed;
  }

  @Selector()
  public static isLoadingPlanDetails(state: ICompanyModel): boolean {
    return state?.isLoadingPlanDetails;
  }

  @Selector()
  public static planDetails(state: ICompanyModel): CompanyPlanDetails {
    return state?.planDetails;
  }

  @Selector()
  public static planStatus(state: ICompanyModel): CompanyPlanStatus {
    return state?.planStatus;
  }

  @Selector()
  public static queryRange(state: ICompanyModel): CompanyQueryRange {
    return state?.queryRange;
  }

  @Selector([CompanyState.companyInfo])
  public static companySettings(companyInfo: CompanyRequest): CompanySettings {
    return companyInfo?.settings;
  }

  @Selector([CompanyState.companySettings])
  public static companyLabelLimit(companySettings: CompanySettings): number {
    return companySettings?.logs2metrics_label_limit;
  }

  @Selector([CompanyState.companySettings])
  public static metricAlertGroupByLimit(companySettings: CompanySettings): number {
    const groupByLimit = companySettings?.metric_alert_group_by_limit;
    return groupByLimit ?? Constants.DEFAULT_METRIC_ALERT_GROUP_BY_LIMIT;
  }

  @Selector([CompanyState.companySettings])
  public static cloud_security_enabled(companySettings: CompanySettings): boolean {
    return companySettings?.cloud_security_enabled;
  }

  @Selector([CompanyState.planDetails])
  public static hasPlan(planDetails: CompanyPlanDetails): boolean {
    return !!planDetails;
  }

  @Selector([CompanyState.planDetails])
  public static dailySize(planDetails: CompanyPlanDetails): number {
    return planDetails?.dailySize;
  }

  @Selector([CompanyState.planDetails])
  public static size(planDetails: CompanyPlanDetails): number {
    return planDetails?.size;
  }

  @Selector([CompanyState.planDetails])
  public static retention(planDetails: CompanyPlanDetails): number {
    return planDetails?.retention;
  }

  @Selector([CompanyState.queryRange])
  public static queryRangeNumber(queryRange: CompanyQueryRange): number {
    return queryRange?.queryRange;
  }

  @Action(GetCompany)
  public GetCompany(ctx: StateContext<ICompanyModel>): any {
    ctx.patchState({ isLoadingPlanDetails: true });
    return this.authService.getCompany().pipe(
      map(companyInfo => {
        ctx.patchState({ companyInfo });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`Error: error fetching the Company`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ isLoadingPlanDetails: false });
      }),
    );
  }

  @Action(GetCompanyPlanDetails)
  public GetCompanyPlanDetails(ctx: StateContext<ICompanyModel>): any {
    ctx.patchState({ isLoadingPlanDetails: true });
    return this.companyService.getCompanyPlanDetails().pipe(
      map(planDetails => {
        ctx.patchState({ planDetails });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`Company's plan details not found or has not configured yet`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ isLoadingPlanDetails: false });
      }),
    );
  }

  @Action(GetCompanyPlanStatus)
  public GetCompanyPlanStatus(ctx: StateContext<ICompanyModel>): any {
    return this.companyService.getCompanyPlanStatus().pipe(
      map(planStatus => {
        ctx.patchState({ planStatus });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`Error: error fetching Company's plan status`, null, 5000);
        return of(null);
      }),
    );
  }

  @Action(GetCompanyPlanQueryRange)
  public GetCompanyPlanQueryRange(ctx: StateContext<ICompanyModel>): any {
    return this.companyService.getCompanyQueryRange().pipe(
      map(queryRange => {
        ctx.patchState({ queryRange });
      }),
    );
  }

  @Action(SetCompany)
  public SetCompany(ctx: StateContext<ICompanyModel>, { companyInfo }: SetCompany): any {
    return ctx.patchState({ companyInfo });
  }
}
