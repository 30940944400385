import { LogQueryModel } from '../models/logsquery.model';
import { QueryTypes } from '../models/query-types.options';
import { SeverityTranslator } from './severity.translator';
import { Alert } from '../../../insights/shared/models/alert.model';
import * as moment from 'moment';
import Moment = moment.Moment;
import { Metadata } from '../models/query-metadata';
import { AlertModel } from '../../../alerts/shared/models/alert-model';
import { StringHelper } from '../../../shared/services/string.helper';
import { QueryParams, TEXT_TYPES } from '../models/query-params.model';
import { InsightTypeId } from '../../../insights/shared/models/enums';
import * as _ from 'lodash';
import { UserAlertTypes } from '@app/alerts/shared/models/create-n-update-alert-model';
import { ConditionsOptions } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { InsightsHelper } from '@app/insights/shared/helpers/insightsHelper';
import { MutliLevelEsInfoHit } from '@app/logs/shared/models/multilevel-esinfo-hit';

export class LogqueryModelTranslator {

  public static createQueryModelFromQueryString(params: any): LogQueryModel {
    const logQueryModel: LogQueryModel = new LogQueryModel();

    if (params['queryType'] === 'templateIds') {
      logQueryModel.type = QueryTypes.TEMPLATE_IDS;
    }
    if (params['templateIds']) {
      logQueryModel.queryParams.templateIds = params['templateIds'].split(',');
    }
    if (params['severities']) {
      const severities: number[] = params['severities'].split(',').map((x) => SeverityTranslator.translateSeverity(x));
      logQueryModel.queryParams.metadata.severity = severities;
    }
    if (params['endTime']) {
      logQueryModel.endDate = Number(params['endTime']);
    }
    if (params['startTime']) {
      logQueryModel.startDate = Number(params['startTime']);
    } else {
      logQueryModel.startDate = logQueryModel.endDate - 15 * 60000;
    }
    if (params['query']) {
      logQueryModel.queryParams.query.text = params['query'];
    }
    return logQueryModel;
  }

  private static setQueryParamsForSingleGroupBy(queryParams: QueryParams, hits: object): void {
    for (const field of Object.keys(hits)) {
      if (field.startsWith('coralogix.metadata')) {
        const metadataField = field.split('.')[2];
        if (metadataField && _.has(queryParams.metadata, metadataField)) {
          queryParams.metadata[metadataField] = Object.keys(hits[field]);
          continue;
        }
      }
      queryParams.jsonObject[field] = Object.keys(hits[field]);
    }
  }

  private static getMultiLevelGroupByFields(hits: MutliLevelEsInfoHit[]): string[] {
    const getGroupByAggregationKey = (hit: MutliLevelEsInfoHit) =>
      Object.keys(hit).filter(key => key !== 'hitCount' && key !== 'nestedAggs')[0];

    const traverseHits = (acc, hit) => {
      const res = [...acc, getGroupByAggregationKey(hit)];
      return hit.nestedAggs ?
        traverseHits(res, hit.nestedAggs[0])
        : res;
    };
    return traverseHits([], hits[0]);
  }

  private static setQueryParamsForMultiLevelGroupBy(queryParams: QueryParams, hits: MutliLevelEsInfoHit[]): void {
    const groupByFields = this.getMultiLevelGroupByFields(hits);

    let aggregationLayer: MutliLevelEsInfoHit[] = hits;
    for (const field of groupByFields) {
      const aggregationValues = aggregationLayer.map(hit => hit[field]);
      if (field.startsWith('coralogix.metadata')) {
        const metadataField = field.split('.')[2];
        if (metadataField && _.has(queryParams.metadata, metadataField)) {
          queryParams.metadata[metadataField] = aggregationValues;
        } else {
          queryParams.jsonObject[field] = aggregationValues;
        }
      } else {
        queryParams.jsonObject[field] = aggregationValues;
      }
      // @ts-ignore
      aggregationLayer = hits.flatMap(hit => hit.nestedAggs);
    }
  }

  public static modelFromAlert(alert: Alert, alertDef?: AlertModel): LogQueryModel {
    const model = LogqueryModelTranslator.getModelFromAlertEvent(alert);

    if (alertDef) {
      model.queryParams.metadata = LogqueryModelTranslator.getMetadataFormAlertDefinition(alertDef);

      if (alert.typeId === InsightTypeId.AlertGroupByFields) {
        model.startDate = alert.esInfo.fromTimestamp;
        model.endDate = alert.esInfo.toTimestamp;

        model.queryParams.jsonObject = {};

        if (InsightsHelper.isMultiLevelGroupBy(alert)) {
          this.setQueryParamsForMultiLevelGroupBy(model.queryParams, alert.esInfo.hits);
        } else {
          this.setQueryParamsForSingleGroupBy(model.queryParams, alert.esInfo.hits);
        }
      }

      if (alert.typeId === InsightTypeId.AlertRelative && alertDef.relativeTimeframe) {
        model.startDate = moment(alert.esInfo.fromTimestamp).subtract(alertDef.relativeTimeframe, 'minutes').valueOf();
        model.endDate = moment(alert.esInfo.toTimestamp).subtract(alertDef.relativeTimeframe, 'minutes').valueOf();
      }

      if (alertDef.text) {
        const isRegex = StringHelper.isRegex(alertDef.text);
        model.queryParams.query.text = alertDef.text;
        model.queryParams.query.type = isRegex ? TEXT_TYPES.REGEX : TEXT_TYPES.EXACT;
      } else {
        model.queryParams.query.text = alertDef.text;
      }
    }

    return model;
  }

  private static getAlertStartDate(alertTime: any, alert: Alert): Date {
    return alertTime
      .subtract(alert.conditions ? alert.conditions.conditionTimeframe : alert.conditionTimeframe, 'minutes')
      .toDate();
  }
  private static getNewValueAlertStartDate(alertTime: any, alert: Alert): Date {
    const timeFrame = alert.conditions ? alert.conditions.conditionTimeframe : alert.conditionTimeframe;
    const dayTimeFrame = 1440;
    return alertTime
      .subtract(timeFrame > dayTimeFrame ? dayTimeFrame : timeFrame, 'minutes')
      .toDate();
  }
  // get logquerymodek according the alert instance
  private static getModelFromAlertEvent(alert: Alert): LogQueryModel {
    const alertTime = alert.subTypeId === UserAlertTypes.Cardinality ?
      moment.unix(alert.timestamp / 1000) :
      moment.unix(alert.properties.logTimestamp / 1000);

    const endDate: Date = alertTime.toDate();
    const startDate = (alert.typeId === InsightTypeId.Alert && alert.conditionOperator === ConditionsOptions.newValue)
      ? LogqueryModelTranslator.getNewValueAlertStartDate(alertTime, alert)
      : LogqueryModelTranslator.getAlertStartDate(alertTime, alert);

    return new LogQueryModel(startDate, endDate);
  }

  public static getMetadataFormAlertDefinition(alertDef: AlertModel): Metadata {
    const metadata: Metadata = new Metadata();
    metadata.applicationName = alertDef.applicationName;
    metadata.computerName = alertDef.computerName;
    metadata.IPAddress = alertDef.IPAddress;
    metadata.subsystemName = alertDef.subsystemName;
    metadata.category = alertDef.category;
    metadata.className = alertDef.className;
    metadata.methodName = alertDef.methodName;
    metadata.severity = alertDef.logSeverity;

    return metadata;
  }
}
