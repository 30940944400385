<div
  [popup-directive]="'click'"
  [popup]="popup"
  [hideAfterClick]="true"
  class="user-menu"
  popup-position="bottom right"
>
  <i class="fa fa-bars" aria-hidden="true"></i>
</div>

<div #popup class="ui flowing popup top left transition hidden popup-content">
  <div class="menu-list">
    <div class="sh-list-item" [routerLink]="['/dashboard']" routerLinkActive="active" tooltip="Dashboard" flow="down">
      <i class="fa fa-th" aria-hidden="true"></i>
      <span>Dashboard</span>
    </div>

    <div class="sh-list-item" [routerLink]="['/query-new/logs']" routerLinkActive="active" tooltip="Logs" flow="down">
      <i class="fa fa-database" aria-hidden="true"></i>
      <span>Logs</span>
    </div>

    <div class="sh-list-item" [routerLink]="['/insights']" routerLinkActive="active" tooltip="Insights" flow="down">
      <i class="fa fa-bullseye" aria-hidden="true"></i>
      <span>Insights</span>
    </div>

    <div
      class="sh-list-item"
      *ngIf="isUserHasAlertPremittions"
      [routerLink]="['/alerts']"
      routerLinkActive="active"
      tooltip="Alerts"
      flow="down"
    >
      <i class="fa fa-flag" aria-hidden="true"></i>
      <span>Alerts</span>
    </div>

    <div class="sh-list-item" [routerLink]="['/livetail']" routerLinkActive="active" tooltip="LiveTail" flow="down">
      <i class="fa fa-play" aria-hidden="true"></i>
      <span>LiveTail</span>
    </div>

    <div
      class="sh-list-item"
      *ngIf="isUserHasAlertPremittions"
      [routerLink]="['/tags']"
      routerLinkActive="active"
      tooltip="Tags"
      flow="down"
    >
      <i class="fa fa-tag" aria-hidden="true"></i>
      <span>Tags</span>
    </div>

    <div
      class="sh-list-item"
      [routerLink]="['/kibana']"
      routerLinkActive="active"
      tooltip="Kibana"
      flow="down"
      style="margin-right: 10px;"
    >
      <i class="fa">
        <svg
          class="svg-navbar-icon"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 19.24 25"
        >
          <defs>
            <style>
              .cls-1,
              .cls-2,
              .cls-3 {
                fill: #182232;
              }
              .cls-1 {
                opacity: 0.7;
              }
              .cls-2 {
                opacity: 0.5;
              }
            </style>
          </defs>
          <path
            class="cls-1"
            d="M12.41,11.69L22.12,0H2.88V9.37H3.24A19.09,19.09,0,0,1,12.41,11.69Z"
            transform="translate(-2.88)"
          />
          <polygon class="cls-1" points="0 23.17 0 23.17 9.53 11.69 0 23.17" />
          <path class="cls-1" d="M14.9,13.32l0.27,0.21Z" transform="translate(-2.88)" />
          <path class="cls-1" d="M14.16,12.77l0.15,0.11Z" transform="translate(-2.88)" />
          <path
            class="cls-1"
            d="M15.55,13.84L6.3,25H22.1A19.16,19.16,0,0,0,15.55,13.84Z"
            transform="translate(-2.88)"
          />
          <path
            class="cls-2"
            d="M14.07,12.71c-0.67-.48-1.66-1-1.66-1a19.21,19.21,0,0,1,1.75,1.08Z"
            transform="translate(-2.88)"
          />
          <path class="cls-2" d="M15.55,13.84l-0.39-.31,0.38,0.32h0Z" transform="translate(-2.88)" />
          <path class="cls-2" d="M14.31,12.88l0.59,0.43Z" transform="translate(-2.88)" />
          <path
            class="cls-2"
            d="M15.16,13.52l-0.27-.21-0.59-.43-0.15-.11a19.21,19.21,0,0,0-1.75-1.08L2.88,23.17V25H6.3l9.25-11.16Z"
            transform="translate(-2.88)"
          />
          <path
            class="cls-3"
            d="M3.24,9.36H2.88V23.17l9.53-11.48A19.09,19.09,0,0,0,3.24,9.36Z"
            transform="translate(-2.88)"
          />
        </svg>
      </i>
      <span>Kibana</span>
    </div>
  </div>
</div>
