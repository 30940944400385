import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MetadataService } from '../../shared/services/metadata.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { LogsService } from '../../shared/services/logs-service';
import { LogsQuerFormProvider } from '../../logs-query/logs-query-form.provider';
import { Metadata } from '../../shared/models/query-metadata';
import { FormControl, FormGroup } from '@angular/forms';
import { MetadataProvider } from '../../logs-metadata-query/metadata.provider';
import { StatisticService } from '@app/statistics/shared/services/statistics-service';
import {
  StatisticsAggregationData,
  StatisticsAggregationResult,
} from '@app/statistics/shared/models/statistics-logs.response';
import { SeverityTranslator } from '../../shared/translators/severity.translator';
import { LogsViewStateProvider } from '../../shared/services/logsViewState.provider';
import {
  getLogsFilterGridColumnsLoadingStatus,
  getLogsFilterGridColumnsVisibility,
  getLogsGridColDefs,
  getLogsGridSavedViews,
  getNonSelectedLogsFilterGridColumns,
  getSelectedLogsFilterGridColumns,
  getManageButtonOverlappingState,
  State,
} from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { LogColumn } from '@shared/models/log-column';
import { FiltersActions } from '../filters.actions';
import { LogQueryModel } from '../../shared/models/logsquery.model';
import * as _ from 'lodash';
import { LogsFilterGridColumnsActions } from '../../shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { LogsGridColumnDef } from '../../logs-grid/logs-grid-col-def';
import { LogsView } from '../../shared/models/logs-view.model';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { CompanyPlanStatus } from '@app/user/shared/models/company-plan-status.model';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sh-logs-group-by-panel',
  templateUrl: 'logs-group-by-panel.component.html',
  styleUrls: ['logs-group-by-panel.component.scss'],
})
export class LogsGroupByPanelComponent implements OnDestroy {
  @Select(CompanyState.planStatus) public planStatus$: Observable<CompanyPlanStatus>;

  @Input() public isOpen: boolean = true;

  @Output() public filterChanged: EventEmitter<LogQueryModel> = new EventEmitter<LogQueryModel>();

  public metadataForm: FormGroup;

  public isLoading: boolean = false;

  public isFull: boolean;

  public isOverlappedManageButton: boolean;

  public aggregationData: StatisticsAggregationData;

  public showLogsFilterGrid$: Observable<boolean>;

  public discoverColumns: boolean;

  public isCompanyPlanStatusBarOpened: boolean;

  public logsFilterGridColumnsIsLoading$: Observable<any>;

  public selectedLogsFilterGridColumns$: Observable<any>;

  public availableLogsFilterGridColumns$: Observable<any>;

  private metadataProvider: MetadataProvider;

  private querySubscription: Subscription;
  // private allMetaData: Metadata;
  private filterChangeSubscription: Subscription;

  private updateFilterChangeStream: Subject<any> = new Subject();

  private jsonFilters$Subscription: Subscription;

  private jsonLogColumns: LogColumn[];

  private logsGridSavedViewsSubscription: Subscription;

  private overlapManageButtonSubscription: Subscription;

  private nonBranchMetaFilters: string[] = ['computerName', 'IPAddress', 'threadId'];

  constructor(
    private logService: LogsService,
    private metadataService: MetadataService,
    private logsFormsProvider: LogsQuerFormProvider,
    private logsViewStateProvider: LogsViewStateProvider,
    private statisticService: StatisticService,
    private store: Store<State>,
    private ae: AnalyticEventService,
  ) {
    this.metadataProvider = new MetadataProvider(metadataService);

    this.metadataForm = new FormGroup({
      category: new FormControl([]),
      className: new FormControl([]),
      methodName: new FormControl([]),
      applicationName: new FormControl([]),
      subsystemName: new FormControl([]),
      computerName: new FormControl([]),
      severity: new FormControl([]),
      IPAddress: new FormControl([]),
      threadId: new FormControl([]),
      dynamicFilters: new FormGroup({}),
    });

    this.subscribeToEvents();
    this.subscribeToManageButtonOverlappingState();

    this.availableLogsFilterGridColumns$ = this.store.select(getNonSelectedLogsFilterGridColumns);
    this.selectedLogsFilterGridColumns$ = this.store.select(getSelectedLogsFilterGridColumns);
    this.showLogsFilterGrid$ = this.store.select(getLogsFilterGridColumnsVisibility);
    this.logsFilterGridColumnsIsLoading$ = this.store.select(getLogsFilterGridColumnsLoadingStatus);
  }

  public subscribeToEvents(): void {
    this.filterChangeSubscription = this.updateFilterChangeStream.debounceTime(1500).subscribe((eventArr) => {
      this.updateFilters(eventArr);
    });

    this.jsonFilters$Subscription = this.store.select(getLogsGridColDefs).subscribe((colDefs) => {
      this.jsonLogColumns = colDefs
        .filter((colDef) => colDef.field.indexOf('textObject') === 0)
        .map((c) => {
          const logCol = new LogColumn();
          logCol.fieldName = c.headerName;
          logCol.headerName = c.headerName;
          return logCol;
        });

      this.getDynamicFilters(this.jsonLogColumns);
    });

    this.subscribeToLogQuery();

    this.store
      .select(getNonSelectedLogsFilterGridColumns)

      .filter((columns) => columns)
      .first()
      .subscribe(() => {
        this.logsGridSavedViewsSubscription = this.store
          .select(getLogsGridSavedViews)
          .distinctUntilChanged((prev: any, curr: any) => prev.selectedViewId === curr.selectedViewId)
          .map((views) => {
            return views.savedViews.find((v: LogsView) => v.id === views.selectedViewId);
          })
          .subscribe((selectedView: LogsView) => {
            if (selectedView) {
              const mappedCols = selectedView.logsGridColDefs.map((c) => {
                const colId = _.findKey(LogsGridColumnDef.defaultColDefs, ['field', c.field]);
                if (colId) {
                  return colId;
                }

                return c.headerName;
              });

              this.store.dispatch(new LogsFilterGridColumnsActions.SetSelectedLogsFilterGridColumns(mappedCols));
            } else {
              this.store.dispatch(new LogsFilterGridColumnsActions.SelectLogsFilterGridDefualtColumns());
            }
          });
      });

    this.planStatus$
      .pipe(
        filter((plan) => plan && plan.status !== 'inplan'),
        take(1),
      )
      .subscribe(() => {
        this.isCompanyPlanStatusBarOpened = true;
      });
  }

  public subscribeToLogQuery(): void {
    this.querySubscription = Observable.merge(
      this.logService.onBeforeAfter.map((m) => m.logQueryModel),
      this.logService.newQueryRequest,
    )
      .delay(500)
      .do(() => (this.isOpen = true))
      .do(() => (this.isLoading = true))
      .do((model: LogQueryModel) => {
        return this.addMissingJsonFilters(
          this.metadataForm.controls['dynamicFilters'] as FormGroup,
          model.queryParams.jsonObject,
        );
      })
      .do(() => this.getDynamicFilters(this.jsonLogColumns))
      .do((model: LogQueryModel) => {
        // Form value may not be registered, So it's more safe to set them on the next tick
        setTimeout(() => {
          this.metadataForm.patchValue(model.queryParams.metadata);
          this.metadataForm.controls['dynamicFilters'].patchValue(model.queryParams.jsonObject);
        }, 0);
      })
      .switchMap((model: LogQueryModel) => {
        const getAggregationStats$ = this.statisticService.getAggregationStats(model).catch((err) => {
          const statAggsRes = new StatisticsAggregationResult();
          statAggsRes.data = new StatisticsAggregationData();
          console.log(statAggsRes);
          return Observable.of(statAggsRes);
        });

        return Observable.zip(getAggregationStats$, this.metadataProvider.updateAllMetadata(new Metadata()));
      })
      .map(([aggregationResult, metadata]) => this.combineMetaDataAggregation(metadata, aggregationResult))
      .subscribe(
        (aggregationData) => this.onMetedataResults(aggregationData),
        (err) => {
          console.error('query subscription error:', err);
          this.onMetadataCompleted();
        },
      );
  }

  public subscribeToManageButtonOverlappingState(): void {
    this.overlapManageButtonSubscription = this.store
      .select(getManageButtonOverlappingState)
      .subscribe((isOverlappedManageButton: boolean) => {
        this.isOverlappedManageButton = !!isOverlappedManageButton;
      });
  }

  public addMissingJsonFilters(jsonFiltersForm: FormGroup, jsonObject: any): void {
    if (jsonObject) {
      const jsonFilters: LogColumn[] = [];
      for (const item in jsonObject) {
        if (!jsonFiltersForm.controls[item]) {
          const newJsonFilter: LogColumn = new LogColumn();
          newJsonFilter.fieldName = item;
          newJsonFilter.headerName = item.replace('_coralogix_', '.');
          jsonFilters.push(newJsonFilter);
        }
      }
    }
  }

  public getDynamicFilters(columns: LogColumn[]): void {
    if (columns && columns.length > 0) {
      const queryModel = _.cloneDeep(this.logsFormsProvider.getModel());
      columns.forEach((column) => {
        queryModel.queryParams.jsonAggFields.push(column.fieldName);
      });
      this.store.dispatch(new FiltersActions.GetDynamicFilters(queryModel));
    } else {
      this.store.dispatch(new FiltersActions.RemoveDynamicFilters({}));
    }
  }

  public onMetedataResults(aggregationData: StatisticsAggregationData): void {
    this.aggregationData = aggregationData;
    this.onMetadataCompleted();
  }

  public onMetadataCompleted(): void {
    this.isLoading = false;
  }

  public ngOnDestroy(): void {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.filterChangeSubscription) {
      this.filterChangeSubscription.unsubscribe();
    }
    if (this.jsonFilters$Subscription) {
      this.jsonFilters$Subscription.unsubscribe();
    }
    if (this.logsGridSavedViewsSubscription) {
      this.logsGridSavedViewsSubscription.unsubscribe();
    }
    if (this.overlapManageButtonSubscription) {
      this.overlapManageButtonSubscription.unsubscribe();
    }
  }

  public updateFilters(eventArr: any[]): void {
    this.isLoading = true;
    const event: FormGroup = eventArr[0];
    const fieldName: string = eventArr[1];
    const checked: boolean = eventArr[2];

    const metadata: Metadata = new Metadata(event.value);
    const model = this.logsFormsProvider.getModel();
    model.queryParams.metadata = metadata;
    model.queryParams.jsonObject = event.value.dynamicFilters;
    this.logsViewStateProvider.onFilterChange.emit(model.queryParams);
    this.logsFormsProvider.updateFormByModel(model);
    this.filterChanged.emit(model);
  }

  public onChange(eventArr: any[]): void {
    this.updateFilterChangeStream.next(eventArr);
  }

  public onLogColumnAdded(colId: string): void {
    this.store.dispatch(new LogsFilterGridColumnsActions.SelectLogsFilterGridColumns([colId]));
  }

  public onLogColumnRemoved(colId: string): void {
    this.store.dispatch(new LogsFilterGridColumnsActions.DeselectLogsFilterGridColumns([colId]));
  }

  public closePanel(): void {
    this.isOpen = false;
  }

  public triggerAnalytics(): void {
    if (this.discoverColumns) {
      this.ae.event({ eventName: 'Logs - ManageColumns' });
    }
  }

  private combineMetaDataAggregation(
    metaData: Metadata,
    aggregationData: StatisticsAggregationResult,
  ): StatisticsAggregationData {
    const ret: StatisticsAggregationData = new StatisticsAggregationData();
    for (const fieldName in metaData) {
      if (this.nonBranchMetaFilters.indexOf(fieldName) !== -1) {
        if (aggregationData.data[fieldName.toLowerCase()]) {
          ret[fieldName.toLowerCase()] = aggregationData.data[fieldName.toLowerCase()];
        }
      } else {
        if (metaData[fieldName]) {
          for (let categoryIndex = 0; categoryIndex < metaData[fieldName].length; categoryIndex++) {
            const categoryName = metaData[fieldName][categoryIndex];
            if (fieldName !== 'severity') {
              if (aggregationData.data[fieldName.toLowerCase()]) {
                if (aggregationData.data[fieldName.toLowerCase()][categoryName.toLowerCase()]) {
                  ret[fieldName.toLowerCase()][categoryName] =
                    aggregationData.data[fieldName.toLowerCase()][categoryName.toLowerCase()];
                } else {
                  ret[fieldName.toLowerCase()][categoryName] = 0;
                }
              } else {
                ret[fieldName.toLowerCase()][categoryName] = 0;
              }
            }
          }
          if (
            aggregationData.data[fieldName.toLowerCase()] &&
            !_.isUndefined(ret[fieldName.toLowerCase()]) &&
            fieldName !== 'severity'
          ) {
            const missingAggregatedKeys = Object.keys(aggregationData.data[fieldName.toLowerCase()]).filter((key) =>
              _.isUndefined(ret[fieldName.toLowerCase()][key.toLowerCase()]),
            );
            missingAggregatedKeys.forEach((key) => {
              ret[fieldName.toLowerCase()][key.toLowerCase()] = aggregationData.data[fieldName.toLowerCase()][key];
            });
          }

          ret.severity = [];

          SeverityTranslator.logsSeverityOptions.map((x) => {
            ret.severity[x.id] = {
              id: x.id,
              name: x.name,
              count:
                aggregationData.data && aggregationData.data.severity && aggregationData.data.severity[x.id]
                  ? aggregationData.data.severity[x.id]
                  : 0,
            };
          });
        }
      }
    }
    return ret;
  }
}
