import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'sh-reindex-permissions-error',
  templateUrl: './reindex-permissions-error.component.html',
  styleUrls: ['./reindex-permissions-error.component.scss']
})
export class ReindexPermissionsErrorComponent implements OnInit {
  @Input()
  public permissionMsg: string;
  @Input()
  public btnText: string;
  @Output()
  public messageBtnAction: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public ngOnInit(): void { }

  public onBtnClick(): void {
    this.messageBtnAction.emit();
  }

}
