import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LogsView } from '../shared/models/logs-view.model';
import { NavigationEnd, Router } from '@angular/router';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { UserSettingsService } from '@app/user/shared/user-settings.service';
import { QUERY_TYPE, QueryHistoryModel } from '../shared/models/query-history.model';
import { Log } from '@app/logs/shared/models/log.entity';
import { SaveAlertFromQueryConfig } from '@app/logs/logs-save-menu/models/logs-save-menu-config.model';
import { IContentTemplate, ViewModes } from '@app/logs/shared/models/query-panel-view.models';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetCompanyPlanQueryRange } from '@app/ngxs-store/company/company.action';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export type viewOptions = 'openView' | 'queryDetailsView' | 'editView' | 'saveView';

@Component({
  selector: 'sh-query-panel',
  templateUrl: './query-panel.component.html',
  styleUrls: ['./query-panel.component.scss'],
})
export class QueryPanelComponent implements OnInit, OnDestroy {
  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  @Input() public queryResultCount: string;

  @Input() public newQueryForm: FormGroup;

  @Input() public logsCount: number;

  @Input() public templateCount: number;

  @Input() public lastExecutedQuery: QueryHistoryModel;

  @Input() public lastExecutedQueryId: string;

  @Input() public selectedView: LogsView;

  @Input() public selectedLog: Log;

  @Output() public quickQueryChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() public relativeQuery: EventEmitter<number> = new EventEmitter<number>();

  @Output() public absoluteQuery: EventEmitter<any> = new EventEmitter<any>();

  @Output() public tagQuery: EventEmitter<any> = new EventEmitter<any>();

  @Output() public clear: EventEmitter<any> = new EventEmitter<any>();

  @Output() public clearSelectedView: EventEmitter<any> = new EventEmitter<any>();

  @Output() public detectGroks: EventEmitter<any> = new EventEmitter<any>();

  @Output() public saveAlert: EventEmitter<SaveAlertFromQueryConfig> = new EventEmitter<SaveAlertFromQueryConfig>();

  public contentTemplates: IContentTemplate[] = [
    {
      key: 'queryDetailsView',
      isOpen: false,
    },
    {
      key: 'openView',
      isOpen: false,
    },
    {
      key: 'saveView',
      isOpen: false,
    },
    {
      key: 'editView',
      isOpen: false,
    },
  ];

  public viewModes: any = ViewModes;

  public mode: ViewModes = this.viewModes.quick;

  public queryTagType: number = QUERY_TYPE.TAG;

  public isLogsTabActive: boolean;

  public destroyed$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    public userSettingsProvider: UserSettingsProvider,
    private userSettingsService: UserSettingsService,
    private ae: AnalyticEventService,
  ) {
    this.isLogsTabActive = this.router.url.includes('/query/logs');
    this.getCompanyPlanQueryRange();
  }

  public ngOnInit(): void {
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      takeUntil(this.destroyed$)
    ).subscribe((navEvent: NavigationEnd) => {
      this.isLogsTabActive = navEvent.url.includes('/query/logs');
    });

    this.clear.subscribe(() => {
      this.closeAllTemplates();
    });
  }

  public updateQueryRange(): void {
    if (this.getCurrentTemplate()) {
      this.getCompanyPlanQueryRange();
    }
  }

  public createAlert(): void {
    const form = this.newQueryForm.value;
    const config = new SaveAlertFromQueryConfig({
      metadata: form.metadata,
      text: form.text,
      jsonObject: form.jsonObject,
      queryType: form.isTemplateSearch ? 'template' : 'freeText',
    });
    this.ae.event({ eventName: 'Logs - CreateAlert' });
    this.saveAlert.emit(config);
  }

  public switchTemplate(key: viewOptions): void {
    for (let i = 0; i < this.contentTemplates.length; i++) {
      if (this.contentTemplates[i].key === key) {
        this.contentTemplates[i].isOpen = !this.contentTemplates[i].isOpen;
      } else {
        this.contentTemplates[i].isOpen = false;
      }
    }
    if (key === 'openView') {
      this.ae.event({ eventName: 'Logs - OpenViews' });
    }
    if (key === 'saveView') {
      this.ae.event({ eventName: 'Logs - SaveViews' });
    }
  }

  public closeAllTemplates(): void {
    for (let i = 0; i < this.contentTemplates.length; i++) {
      this.contentTemplates[i].isOpen = false;
    }
  }

  public getCurrentTemplate(): string {
    const currentTemplate = this.contentTemplates.filter((t) => t.isOpen);

    if (currentTemplate.length === 0) {
      return null;
    }

    return currentTemplate[0].key;
  }

  public toggleSetSelectedViewAsDefault(selectedView: { id }): void {
    const logsDefaultGridViewId = this.userSettingsProvider.userSettings.logsDefaultGridViewId;
    this.userSettingsProvider.userSettings.logsDefaultGridViewId =
      logsDefaultGridViewId !== selectedView.id ? selectedView.id : -1;
    this.userSettingsService
      .updateUserSettings(this.userSettingsProvider.userSettings)
      .first()
      .subscribe();
  }

  public onClearSelectedViewClicked(): void {
    this.clearSelectedView.emit();
  }

  public ngOnDestroy(): void {
    if (this.clear) {
      this.clear.unsubscribe();
    }

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public changeMode(mode: ViewModes): void {
    this.mode = mode;
  }

  @Dispatch() public getCompanyPlanQueryRange = () => new GetCompanyPlanQueryRange();
}
