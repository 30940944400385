import { Action } from '@ngrx/store';
import { type } from '@shared/services/utils';
import { AssignOrUnAssignEnum, Teammate } from '../../shared/models/teammate';

// tslint:disable-next-line:no-namespace
export namespace TeamUsersActions {
  export const LOAD = type('[Team Users] Load');
  export const LOAD_SUCCESS = type('[Team Users] Load Success');
  export const LOAD_FAIL = type('[Team Users] Load Fail');
  export const DELETE = type('[Team Users] Delete');
  export const DELETE_SUCCESS = type('[Team Users] Delete SUCCESS');
  export const DELETE_FAIL = type('[Team Users] Delete FAIL');
  export const CHANGE_ROLE = type('[Team Users] Change Role');
  export const CHANGE_ROLE_SUCCESS = type('[Team Users] Change Role SUCCESS');
  export const CHANGE_ROLE_FAIL = type('[Team Users] Change Role FAIL');
  export const UPDATE_GROUP = type('[Team Users] Update Group');
  export const UPDATE_GROUP_SUCCESS = type('[Team Users] Update Group SUCCESS');
  export const UPDATE_GROUP_FAIL = type('[Team Users] Update Group FAIL');
  export const BULK_UPDATE_GROUP = type('[Team Users] BULK Update Group');
  export const BULK_UPDATE_GROUP_SUCCESS = type('[Team Users] BULK Update Group SUCCESS');
  export const BULK_UPDATE_GROUP_FAIL = type('[Team Users] BULK Update Group FAIL');
  export const UPDATE_LOADING = type('[Team Users] Update Loading');

  /**
   * Load Team Users Actions
   */
  export class LoadAction implements Action {
    public readonly type = LOAD;
  }

  export class LoadSuccessAction implements Action {
    public readonly type = LOAD_SUCCESS;

    constructor(public payload: Teammate[]) {
    }
  }

  export class LoadFailAction implements Action {
    public readonly type = LOAD_FAIL;

    constructor(public payload: any) {
    }
  }

  export class DeleteAction implements Action {
    public readonly type = DELETE;

    constructor(public payload: string) {
    }
  }

  export class DeleteSuccessAction implements Action {
    public readonly type = DELETE_SUCCESS;

    constructor(public payload: string) {
    }
  }

  export class DeleteFailAction implements Action {
    public readonly type = DELETE_FAIL;

    constructor(public payload: [string, any]) {
    }
  }

  export class ChangeRoleAction implements Action {
    public readonly type = CHANGE_ROLE;

    constructor(public payload: [string, number]) {
    }
  }

  export class ChangeRoleSuccessAction implements Action {
    public readonly type = CHANGE_ROLE_SUCCESS;

    constructor(public payload: [string, number]) {
    }
  }

  export class ChangeRoleFailAction implements Action {
    public readonly type = CHANGE_ROLE_FAIL;

    constructor(public payload: [any, any]) {
    }
  }

  export class UpdateGroupAction implements Action {
    public readonly type = UPDATE_GROUP;

    constructor(public payload: { uid: string; companyId: string | number; groupId: number, groupName: string; type: AssignOrUnAssignEnum }) {
    }
  }

  export class UpdateGroupSuccessAction implements Action {
    public readonly type = UPDATE_GROUP_SUCCESS;

    constructor(public payload: { uid: string; groupId: number, groupName: string, type: AssignOrUnAssignEnum }) {
    }
  }

  export class UpdateGroupFailAction implements Action {
    public readonly type = UPDATE_GROUP_FAIL;

    constructor(public payload: [any, any]) {
    }
  }

  export class BulkUpdateGroupAction implements Action {
    public readonly type = BULK_UPDATE_GROUP;

    constructor(public payload: { uid: string; companyId: number; groupsIds: string[]; updatedMember: {index: number, data: Teammate}; type: AssignOrUnAssignEnum }) {
    }
  }

  export class BulkUpdateGroupSuccessAction implements Action {
    public readonly type = BULK_UPDATE_GROUP_SUCCESS;

    constructor(public payload: { uid: string; companyId: number; groupsIds: string[]; updatedMember: {index: number, data: Teammate}; type: AssignOrUnAssignEnum }) {
    }
  }

  export class BulkUpdateGroupFailAction implements Action {
    public readonly type = BULK_UPDATE_GROUP_FAIL;

    constructor(public payload: [any, any]) {
    }
  }

  export class UpdateLoadingAction implements Action {
    public readonly type = UPDATE_LOADING;

    constructor(public payload: { uid: string, showLoadingSpinner: boolean }) {
    }
  }

  export type Actions =
    | LoadAction
    | LoadSuccessAction
    | LoadFailAction
    | DeleteAction
    | DeleteSuccessAction
    | DeleteFailAction
    | ChangeRoleAction
    | ChangeRoleSuccessAction
    | ChangeRoleFailAction
    | UpdateGroupAction
    | UpdateGroupSuccessAction
    | UpdateGroupFailAction
    | BulkUpdateGroupAction
    | BulkUpdateGroupFailAction
    | BulkUpdateGroupSuccessAction
    | UpdateLoadingAction;
}
