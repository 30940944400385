<div class="metrics-table">
  <table mat-table [dataSource]="source" matSort matSortStart="desc">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        NAME
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="name-label">{{ element.name }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="createDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        CREATED
      </th>
      <td mat-cell *matCellDef="let element" class="baseTableText">
        {{ getFormattedDate(element.createDate) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updateDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        LAST MODIFIED
      </th>
      <td mat-cell *matCellDef="let element" class="baseTableText">
        {{ getFormattedDate(element.updateDate) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="permutationAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        PERMUTATION AMOUNT
      </th>
      <td mat-cell *matCellDef="let element" class="baseTableText">
        {{ element.permutationsLimit | number: '1.0':'en-US' }}
        <img
          *ngIf="element.hasExceededLimit"
          src="assets/icons/warning-icon.svg"
          alt="warning"
          class="warning-icon"
          matTooltipClass="coralogix-tooltip"
          matTooltip="Your metric reached out to its permutation limit. Your metric might be missing some data. To fix it, please increase the permutation limit."
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="more">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element" class="baseTableText">
        <mat-icon class="more-icon" [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
          more_vert
        </mat-icon>
        <mat-menu #menu="matMenu">
          <div mat-menu-item class="menu-item" (click)="editMetric.emit(element)">
            <img src="assets/icons/edit.svg" class="icon" alt="action" />
            <div class="caption">Edit</div>
          </div>
          <div mat-menu-item class="menu-item" (click)="onDeleteMetric(element)">
            <img src="assets/icons/delete.svg" class="icon" alt="action" />
            <div class="caption">Delete</div>
          </div>
        </mat-menu>
      </td>
    </ng-container>
    <tbody>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="editMetric.emit(row)"></tr>
    </tbody>
  </table>
</div>
