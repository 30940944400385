<div class="permissions-error-container">
  <div class="permissions-error-content">
    <div class="permissions-error-message">
      <div class="permissions-error-icon">
        <img src="assets/roi/icon-warning.svg" />
      </div>
      <div class="error-text">
        {{ permissionMsg }}
      </div>
    </div>
    <cgx-button [label]="btnText" textNoWrap size="md" color="error" width="180px" (press)="onBtnClick()"></cgx-button>
  </div>
</div>
