import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/shared/services/auth.service';
import { Constants } from '../../constants';
import { AnalyticEventService } from '../../user/shared/AnalyticEventService';

@Component({
  selector: 'sh-kibana',
  templateUrl: './kibana.component.html',
  styleUrls: ['./kibana.component.scss'],
})
export class KibanaComponent implements OnInit {
  public kibanaUrl: string = location.origin + '/kibana';
  constructor(
    private authService: AuthService,
    public ea: AnalyticEventService,
  ) {}
  public ngOnInit() {
    document.cookie =
      'coralogix_token=' +
      localStorage.getItem('token') +
      ';expires=Fri, 31 Dec 9999 23:59:59 GMT;';
    document.cookie =
      'coralogix_t_id=' +
      localStorage.getItem('t_id') +
      ';expires=Fri, 31 Dec 9999 23:59:59 GMT;';
    this.ea.event({ eventName: 'openKibana' });
  }
}
