<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  (click)="$event.stopPropagation()"
  class="tags-menu-button">
  <img class="tags-menu-button__icon" src="../../../../../assets/shared/{{menuImg}}">
</button>
<mat-menu #menu="matMenu" class="tags-menu" [overlapTrigger]="true" xPosition="before" yPosition="below">
  <button mat-menu-item class="action-button" (click)="editAction.emit()">
    <span class="action-button__icon"><i class="fa fa-pencil"></i></span>
    <span class="action-button__text">Edit</span>
  </button>
  <button mat-menu-item class="action-button" (click)="deleteAction.emit()">
    <span class="action-button__icon"><i class="fa fa-trash-o"></i></span>
    <span class="action-button__text">Delete</span>
  </button>
</mat-menu>
