import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnoozeButtonComponent } from './snooze-button/snooze-button.component';
import { MaterialModule } from '@app/shared/material/material.module';
import { FormControlsModule } from '@app/shared/form-controls/form-controls.module';
import { WidgetLoaderModule } from '@app/shared/components/widget-loader/widget-loader.module';

@NgModule({
  declarations: [SnoozeButtonComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormControlsModule,
    WidgetLoaderModule
  ],
  exports: [
    SnoozeButtonComponent
  ]
})
export class SnoozeButtonModule { }
