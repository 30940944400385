import { ArchivingProvidersActions } from './archiving-providers.action';
import { ArchivingProviderModel } from '../../shared/models/archivingProviderModel';
export namespace ArchivingProvidersReducer {
  export interface TestingResultState {
    testingMessage: string;
    testingSuccess: boolean;
    id: number;
  }
  export interface State {
    loading: boolean;
    saving: boolean;
    testing: boolean;
    testingResults: TestingResultState;
    archivingProvider: ArchivingProviderModel;
  }
  const initialState: State = {
    loading: true,
    saving: false,
    testing: false,
    testingResults: {
      testingMessage: '',
      testingSuccess: false,
      id: 0,
    },
    archivingProvider: null,
  };

  export function reducer(
    state: State = initialState,
    action: ArchivingProvidersActions.Actions,
  ): State {
    switch (action.type) {
      case ArchivingProvidersActions.ActionTypes.GET_ARCHIVING_PROVIDERS:
        return Object.assign({}, state, { loading: true, testingResults: {} });
      case ArchivingProvidersActions.ActionTypes
        .GET_ARCHIVING_PROVIDERS_COMPLETED:
        return Object.assign({}, state, {
          loading: false,
          archivingProvider: action.payload[0],
        });
      case ArchivingProvidersActions.ActionTypes.SAVE_ARCHIVING_PROVIDERS:
        return Object.assign({}, state, {
          saving: true,
          // testingResults: {}
        });
      case ArchivingProvidersActions.ActionTypes
        .SAVE_ARCHIVING_PROVIDERS_COMPLETED:
        return Object.assign({}, state, {
          saving: false,
          archivingProvider: action.payload,
        });
      case ArchivingProvidersActions.ActionTypes
        .SAVE_ARCHIVING_PROVIDERS_FAILED:
        return Object.assign({}, state, {
          saving: false,
          archivingProvider: action.payload,
        });

      case ArchivingProvidersActions.ActionTypes.TEST_ARCHIVING_PROVIDER:
        return Object.assign({}, state, {
          testing: true,
          testingResults: {},
        });

      case ArchivingProvidersActions.ActionTypes
        .TEST_ARCHIVING_PROVIDER_COMPLETED:
        return Object.assign({}, state, {
          testing: false,
          testingResults: {
            testingMessage: action.payload.message,
            testingSuccess: action.payload.success,
          },
        });
      case ArchivingProvidersActions.ActionTypes.TEST_ARCHIVING_PROVIDER_FAILED:
        return Object.assign({}, state, {
          testing: false,
        });
      default:
        return state;
    }
  }
}
