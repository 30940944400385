import { Injectable, EventEmitter } from '@angular/core';
import { Insight } from '../models/insight.model';
import { Anomaly } from '../models/anomaly.model';
import { QueryViewState } from '../../../logs/shared/services/logsViewState.provider';
import { SearchProvider } from '../../../shared/controls/search/search.provider';
import { AnalyticEventService } from '../../../user/shared/AnalyticEventService';
import { Alert } from '@app/insights/shared/models/alert.model';
@Injectable()
export class InsightsProvider {
  set currentInsight(value: Insight) {
    if (value) {
      if (value.name) {
        this.ea.event({
          eventName: 'openAlert',
          metadata: { name: value.name },
        });
      } else {
        const name = 'Flow Anomaly #' + (value as Anomaly).shortId;
        this.ea.event({ eventName: 'openAnomaly', metadata: { name } });
      }
    }
    this.insight = value;
  }

  get currentInsight() {
    return this.insight;
  }

  get isAnomaly(): boolean {
    return this.currentInsight instanceof Anomaly;
  }

  get currentAnomaly(): Anomaly {
    if (this.isAnomaly) {
      return this.currentInsight as Anomaly;
    }
    return undefined;
  }

  get isAlert(): boolean {
    return this.currentInsight instanceof Alert;
  }

  get currentAlert(): Alert {
    if (this.isAlert) {
      return this.currentInsight as Alert;
    }
    return undefined;
  }

  public currentInsightChanged: EventEmitter<Insight> = new EventEmitter<Insight>();
  public globalState: QueryViewState = new QueryViewState();
  public searchProvider: SearchProvider = new SearchProvider();
  public textInfoClicked: EventEmitter<string> = new EventEmitter<string>();
  public insightsLogsState: any;
  private insight: Insight;

  constructor(private ea: AnalyticEventService) {}
}
