import { Pipe, PipeTransform } from '@angular/core';
import { enumsTranslator } from '@app/shared/config/enums-translator';

@Pipe({
  name: 'enumTranslator'
})
export class EnumTranslatorPipe implements PipeTransform {

  public transform(value: any, type: string): any {
    if (!enumsTranslator[type]) {
      return value;
    }

    return enumsTranslator[type][value];
  }

}
