import { Component, OnDestroy, OnInit } from '@angular/core';
import { getSelectedTagAlerts, getSelectedTagAlertsViewState, getSelectedTagDelta, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { EFeatureViewMode, IAlertsData } from '@app/deployment/shared/models/reducer.models';
import { SeveritiesHelper } from '@shared/services/severities.helper';
import { filter, takeUntil } from 'rxjs/operators';
import { AlertHelper } from '@app/alerts/shared/services/alert-helper';
import { AlertInfo, LineGraphResult, TotalSeverity } from '@app/deployment/shared/models/alerts-summary.models';
import { ILegendData } from '@app/statistics/components/chart-legend/chart-legend.component';
import { ISeveritySummaryItem } from '@app/statistics/components/summary-severity-list/summary-severity-list.component';
import { EGraphTooltipStyles } from '@shared/controls/charts/models/chart.model';
import { getTimeUnitScale, getXTimeCategories } from '@app/statistics/shared/helpers/line-chart-tags-helper';
import { ILineChartConfig } from '@app/statistics/shared/models/line-chart.models';
import { Observable, Subject } from 'rxjs';
import { ITotalSeverity, lineChartLegend } from '@app/deployment/models/alerts-summary';

@Component({
  selector: 'sh-alerts-summary',
  templateUrl: './alerts-summary.component.html',
  styleUrls: ['./alerts-summary.component.scss']
})
export class AlertsSummaryComponent implements OnInit, OnDestroy {
  public graphInfo: ILineChartConfig;
  public completedViewState: EFeatureViewMode = EFeatureViewMode.completed;
  public totalSeverity: ITotalSeverity[];
  public listSummary: ISeveritySummaryItem[];
  public lineGraphLegend: ILegendData[] = lineChartLegend;
  public severityGraphLegend: ILegendData[] = lineChartLegend;
  public viewState$: Observable<EFeatureViewMode> = this.store.select(getSelectedTagAlertsViewState);
  public delta$: Observable<string> = null;
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(private store: Store<State>, private alertHelper: AlertHelper) { }

  public ngOnInit(): void {
    this.store.select(getSelectedTagAlerts).pipe(
        filter(data => !!data && data.hasOwnProperty('volumeCompareGraph')),
        takeUntil(this.destroyed$))
      .subscribe((data: IAlertsData) => {
        this.listSummary = this.getListSummary(data.alertsListInfo);
        this.totalSeverity = this.getMappedSeverity(data.severityInfo);
        this.severityGraphLegend = this.getSeverityLegend(data.severityInfo);
        this.graphInfo = this.getMappedLineGraph(data.volumeCompareGraph);
    });
    this.delta$ = this.store.select(getSelectedTagDelta);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private getMappedLineGraph(graphResult: LineGraphResult): ILineChartConfig {
    const scale = getTimeUnitScale(graphResult.tag);
    return {
      values: {
        seriesValues: [graphResult.tag, graphResult.compareTag]
      },
      config: {
        yTitle: 'ALERT VOLUME',
        xTitle: 'TIME ELAPSED SINCE TAG',
        scale,
        height: 250,
        seriesNames: lineChartLegend.map(item => item.name),
        chartTooltipDataTitle: 'TRIGGERED ALERTS',
        tooltipButtonText: 'VIEW ALERTS',
        xCategories: getXTimeCategories(graphResult.tag, scale),
        tooltipStyleType: EGraphTooltipStyles.whiteWithLinkButton,
        yCategories: [],
        tooltipRightOffsetInPx: 60
      }
    };
  }

  private getListSummary(listInfo: AlertInfo[]): ISeveritySummaryItem[] {
    return !!listInfo ? listInfo.map((item) => {
      const severity = this.alertHelper.GetAlertSeverityCaption(item.severity);
      const color = SeveritiesHelper.getEventSeverityColorByName(severity);
      return {
        ...item,
        severity,
        color
      };
    }) : [];
  }

  private getSeverityLegend(totalSeverity: TotalSeverity[]): ILegendData[] {
    return totalSeverity.map(item => {
      const severity = this.alertHelper.GetAlertSeverityCaption(item.severity);
      const color = SeveritiesHelper.getEventSeverityColorByName(severity);
      const legendItem: ILegendData = {
        name: severity,
        color
      };
      return legendItem;
    });
  }

  private getMappedSeverity(totalSeverity: TotalSeverity[]): ITotalSeverity[] {
    return !!totalSeverity ? totalSeverity.map((item) => {
      const severity = this.alertHelper.GetAlertSeverityCaption(item.severity);
      const color = SeveritiesHelper.getEventSeverityColorByName(severity);
      return {
        ...item,
        color,
        severity,
        y: item.size,
        name: severity
      };
    }) : [];
  }
}
