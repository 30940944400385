import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

@jsonObject
export class Metadata {
  @jsonMember
  public applicationName: string;
  @jsonMember
  public category: string;
  @jsonMember
  public className: string;
  @jsonMember
  public methodName: string;
  @jsonMember
  public severity: string;
  @jsonMember
  public subsystemName: string;
}

@jsonObject
export class LoggregationEntity {
  @jsonMember
  public count: number;
  @jsonMember
  public id: string;
  @jsonMember
  public isHighlighted: boolean;
  @jsonMember
  public metadata: Metadata;
  @jsonMember
  public templateType: number;
  @jsonMember
  public text: string;
  @jsonMember
  public timestamp: number;
  @jsonMember
  public index: number;
  @jsonMember
  public ratio: string;
  public logExample: any;
  public countToNormalRation: number;
  public normalCount: number;

  @jsonMember
  public placeholdersEnabled: boolean = true;

  constructor(init?: Partial<LoggregationEntity>) {
    Object.assign(this, init);
  }
}

@jsonObject
export class LoggregationRes {
  @jsonArrayMember(LoggregationEntity)
  public data: LoggregationEntity[];
}
