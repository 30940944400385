import { Injectable } from '@angular/core';
import { Constants } from '../../../constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { LogsView } from '../models/logs-view.model';
import * as _ from 'lodash';
import { LogsViewMapperService } from './logs-view-mapper.service';

@Injectable()
export class SavedViewsService {
  private url: string = Constants.savedViewsUrl;

  constructor(
    private http: HttpClient,
    private logsViewMapperService: LogsViewMapperService,
  ) {}

  public loadViews(): any {
    return this.http
      .get(this.url)
      .map((res) => this.extractData(res))
      .catch((error) => this.handleError(error));
  }

  public saveView(view: LogsView): any {
    return this.http
      .post(this.url, this.packData(view))
      .map((res) => this.extractData(res))
      .catch((error) => this.handleError(error));
  }

  public deleteView(viewId: number): Observable<number> {
    const params: any = {
      view_id: viewId,
    };
    return this.http
      .delete(this.url, { params })
      .mapTo(viewId)
      .catch((error) => this.handleError(error));
  }

  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';

    if (error.status === 409) {
      errMsg = 'View name already exists in this company';
    }

    return Observable.throw(errMsg);
  }

  private extractData(res) {
    const jsonRes = res;

    if (Array.isArray(jsonRes)) {
      const views: LogsView[] = jsonRes.map((logsViewServer) => {
        logsViewServer['column_definitions'] = JSON.parse(
          logsViewServer['column_definitions'],
        );
        logsViewServer['columns_state'] = JSON.parse(
          logsViewServer['columns_state'],
        );
        logsViewServer['query_model'] = JSON.parse(
          logsViewServer['query_model'],
        );
        return this.logsViewMapperService.mapResponseToModel(logsViewServer);
      });

      return views;
    }
    return this.logsViewMapperService.mapResponseToModel(jsonRes);
  }

  private packData(logsView: LogsView) {
    const queryHistoryModel = _.cloneDeep(logsView.queryHistoryModel);
    if (
      logsView.queryHistoryModel &&
      logsView.queryHistoryModel.queryModel.cacheQueryId
    ) {
      delete queryHistoryModel.queryModel.cacheQueryId;
    }
    return {
      id: logsView.id,
      name: logsView.name,
      is_public: logsView.isPublic,
      column_definitions: logsView.logsGridColDefs,
      columns_state: logsView.logsGridColState,
      query_model: queryHistoryModel,
      is_mine: logsView.isMine,
      is_editable: logsView.isEditable,
    };
  }
}
