import { State } from '@app/app.reducers';
import { createSelector } from '@ngrx/store';
import { ArchiveQueryReducer } from '@app/roi/archive-queries/state/reducers/archive-query.reducer';

export const selectFeature = (state: State) => state.archiveQuery;

export const loadingArchiveQueries = (state: State) => state.archiveQuery.loading;
export const secondLoading = (state: State) => state.archiveQuery.secondLoader;
export const getArchiveQueriesUiState = (state: State) => state.archiveQuery.archiveQueriesUIState;
export const getIsArchiveQueryEditorOpen = (state: State) => state.archiveQueryEditor;
export const getCurrentPage = (state: State) => state.archiveQuery.pageIndex;
export const getFilter = (state: State) => state.archiveQuery.filter;

export const getArchiveQueries = createSelector(
  selectFeature,
  state => state.archiveQueries
);

export const getTotalQueries = createSelector(
  selectFeature,
  state => state.totalQueries
);

