import { createAction, props } from '@ngrx/store';
import { TimeZoneType } from '@shared/models/timezone-types';
import { UserSettings } from '@app/user/shared/user.settings';
import { DateFormatType } from '@shared/models/date-format-types';

export const SetUserTimezoneView = createAction(
  '[User Settings] set user timezone option',
  props<{ payload: { value: TimeZoneType, prevValue: TimeZoneType } }>(),
);

export const SetUserTimezoneViewFailed = createAction(
  '[User Settings] set user timezone option Failed',
  props<{ payload: TimeZoneType }>(),
);

export const SetUserTimezoneViewCompleted = createAction(
  '[User Settings] set user timezone option completed'
);

export const SetUserDateFormatView = createAction(
  '[User Settings] set date format option',
  props<{ payload: { value: DateFormatType, prevValue: DateFormatType } }>(),
);

export const SetUserDateFormatViewFailed = createAction(
  '[User Settings] set date format option failed',
  props<{ payload: DateFormatType }>(),
);

export const SetUserDateFormatViewCompleted = createAction(
  '[User Settings] set user date format completed'
);

export const SetUserSettingsFromResolver = createAction(
  '[User Settings] update user settings from resolver',
  props<{ payload: UserSettings }>(),
);
