import { Component, OnInit } from '@angular/core';
import { getMetrics, loadingMetrics, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { MetricEditorActions } from '@app/settings/metrices-settings/state/actions/metric-editor.actions';
import { MetadataActions } from '@shared/state/actions/metadata.actions';
import { MetricsActions } from '@app/settings/metrices-settings/state/actions/metrics.actions';
import { Observable } from 'rxjs';
import { Metric } from '@app/settings/metrices-settings/models/metric.model';
import { ActivatedRoute } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sh-metrices-settings',
  templateUrl: './metrices-settings.component.html',
  styleUrls: ['./metrices-settings.component.scss'],
})
export class MetricesSettingsComponent implements OnInit {

  public getMetrics$: Observable<Metric[]>;
  public isLoading$: Observable<boolean>;

  constructor(private store: Store<State>, private route: ActivatedRoute) {
    this.store.dispatch(new MetadataActions.GetApplicationNameAction(null));
    this.store.dispatch(new MetadataActions.GetSubsystemNameAction(null));
    this.store.dispatch(new MetricsActions.GetMetricsAction(null));
    this.getMetrics$ = this.store.select(getMetrics);
    this.isLoading$ = this.store.select(loadingMetrics);
  }

  public ngOnInit(): void {
    this.handleRouteParams();
  }

  public openEditor(): void {
    this.store.dispatch(new MetricEditorActions.ToggleEditorAction(true));
  }

  private handleRouteParams(): void {
    this.route.params
      .pipe(filter(routeParams => !!routeParams.metricId))
      .subscribe((routeParams) => {
        const metricId = routeParams.metricId;
        if (metricId === 'new') {
          this.openEditor();
        } else {
          this.openEditorWithMetric(metricId);
        }
      });
  }

  private openEditorWithMetric(metricId: string): void {
    this.getMetrics$.pipe(
      filter(metrics => !!metrics.length),
      take(1),
    ).subscribe(metrics => {
      const selectedMetric = metrics.filter(m => m.id === metricId)[0];
      this.store.dispatch(new MetricEditorActions.EditMetricAction(selectedMetric));
    });
  }

}
