<div
  class="group-by-panel"
  [class.with-bar]="showLogsFilterGrid$ | async"
  [class.company-plan-status-bar]="isCompanyPlanStatusBarOpened"
  [class.opened]="isOpen"
  [class.full]="isFull"
>
  <div class="groupby-tree-container">
    <div class="top-bar" *ngIf="showLogsFilterGrid$ | async">
      <button
        class="ui toggle button"
        style="width: 100%;"
        [ngClass]="{ 'active': !discoverColumns }"
        [matTooltip]="
          !discoverColumns
            ? 'Manage your grid columns and discover new fields'
            : 'Back to filters'
        "
        [matTooltipPosition]="'right'"
        (click)="discoverColumns = !discoverColumns; triggerAnalytics()"
      >
        {{ isOverlappedManageButton ? '' : 'Manage Columns' }}
        <i class="fa fa-columns" style="padding-left: 9px"></i>
      </button>
    </div>
    <sh-logs-filter
      *ngIf="!discoverColumns || (showLogsFilterGrid$ | async) === false"
      [(isLoading)]="isLoading"
      [columnsFilter]="selectedLogsFilterGridColumns$ | async"
      [aggregationData]="aggregationData"
      (onFilterChange)="onChange($event)"
      [metadataForm]="metadataForm"
    ></sh-logs-filter>

    <sh-logs-filter-grid-columns
      *ngIf="discoverColumns && (showLogsFilterGrid$ | async)"
      [selectedColumns]="selectedLogsFilterGridColumns$ | async"
      [availableColumns]="availableLogsFilterGridColumns$ | async"
      [isLoading]="logsFilterGridColumnsIsLoading$ | async"
      (onColumnAdded)="onLogColumnAdded($event)"
      (onColumnRemoved)="onLogColumnRemoved($event)"
    ></sh-logs-filter-grid-columns>
  </div>
</div>
