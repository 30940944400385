<div class="insights container">
  <div class="ui grid">
    <div class="row">
      <div class="ui four wide column">
        <div class="timeline content">
          <sh-insights-timeline
            [autoSelectFirst]="autoSelectFirst"
            [currentInsight]="this.insightsProvider.currentInsight"
            [filterType]="currentFilterType"
            [insights]="insights"
            (onInsightClicked)="onInsightClicked($event)"
            (onUpdatedAnomalies)="updateAnomalies($event)"
          ></sh-insights-timeline>
        </div>
      </div>

      <div fxLayout="column" class="ui twelve wide column">
        <div fxFlex="none" class="ui secondary pointing menu tabs">
          <a
            class="item"
            *ngIf="!isMetricAlert"
            [routerLink]="['/insights']"
            [queryParams]="{ id: currentViewedInsightId }"
            [shActiveRouter]="['/insights']"
            [activeRouteClass]="'tab-active'"
          >
            Logs
          </a>
          <a
            class="item"
            *ngIf="!isMetricAlert"
            [routerLink]="['/insights/loggregation']"
            [queryParams]="{ id: currentViewedInsightId }"
            [shActiveRouter]="['/insights/loggregation']"
            [activeRouteClass]="'tab-active'"
          >
            Loggregation
          </a>
          <a
            class="item"
            *ngIf="this.insightsProvider.isAnomaly"
            [routerLink]="['/insights/anomaly']"
            [queryParams]="{ id: currentViewedInsightId }"
            [shActiveRouter]="['/insights/anomaly']"
            [activeRouteClass]="'tab-active'"
          >
            {{ getInsightTitle(this.insightsProvider.currentInsight) }}
          </a>
          <a
            class="item"
            *ngIf="isGroupByFieldsAlert(this.insightsProvider.currentInsight)"
            [queryParams]="{ id: currentViewedInsightId }"
            [routerLink]="['/insights/groupbyalert']"
            [shActiveRouter]="['/insights/groupbyalert']"
            [activeRouteClass]="'tab-active'"
          >
            Alert logs
          </a>
          <a
            class="item"
            *ngIf="isRelativeAlert(this.insightsProvider.currentInsight)"
            [queryParams]="{ id: currentViewedInsightId }"
            [routerLink]="['/insights/ratioalert']"
            [shActiveRouter]="['/insights/ratioalert']"
            [activeRouteClass]="'tab-active'"
          >
            Alert logs
          </a>
          <a
            class="item"
            *ngIf="isAdvancedAlert(this.insightsProvider.currentInsight)"
            [queryParams]="{ id: currentViewedInsightId }"
            [routerLink]="['/insights/advancedalert']"
            [shActiveRouter]="['/insights/advancedalert']"
            [activeRouteClass]="'tab-active'"
          >
            Alert logs
          </a>
          <a
            class="item"
            *ngIf="isNewValueAlert(this.insightsProvider.currentInsight)"
            [queryParams]="{ id: currentViewedInsightId }"
            [routerLink]="['/insights/newvalue']"
            [shActiveRouter]="['/insights/newvalue']"
            [activeRouteClass]="'tab-active'"
          >
            Alert logs
          </a>
          <a
            class="item"
            *ngIf="isSimpleAlert(this.insightsProvider.currentInsight)"
            [queryParams]="{ id: currentViewedInsightId }"
            [routerLink]="['/insights/alert']"
            [shActiveRouter]="['/insights/alert']"
            [activeRouteClass]="'tab-active'"
          >
            Alert logs
          </a>
          <a
            class="item"
            *ngIf="isCardinalityAlert"
            [queryParams]="{ id: currentViewedInsightId }"
            [routerLink]="['/insights/unique-count-alert']"
            [shActiveRouter]="['/insights/unique-count-alert']"
            [activeRouteClass]="'tab-active'"
          >
            Unique count
          </a>
          <a
            class="item"
            *ngIf="isMetricAlert"
            [queryParams]="{ id: currentViewedInsightId }"
            [routerLink]="['/insights/metricalert']"
            [shActiveRouter]="['/insights/metricalert']"
            [activeRouteClass]="'tab-active'"
          >
            Alert
          </a>
          <div class="right menu" [class.pushed]="infoPanel.isOpen">
            <sh-logs-before-after
              [selectedLog]="insightsProvider.globalState.selectedLog"
              *ngIf="insightsProvider.globalState.showFind"
              (onBeforeAfter)="onBeforeAfter($event)"
            ></sh-logs-before-after>
            <button
              class="ui icon button"
              (click)="chartVisibility()"
              [disabled]="!insightsProvider.currentInsight"
              data-tooltip="toggle timeline"
              data-position="bottom center"
            >
              <i
                class="area chart icon"
                [class.inverted]="!insightsProvider.globalState.showTimeline"
                [class.custom-outline]="insightsProvider.globalState.showTimeline"
              ></i>
            </button>
            <sh-logs-info-panel
              (logActionPerformedEvent)="navigateToLogsQuery()"
              [selectedLog]="insightsProvider.globalState.selectedLog"
              [searchProvider]="insightsProvider.searchProvider"
              [highlightEnabled]="insightsProvider.globalState.showFind"
              #infoPanel
            ></sh-logs-info-panel>
            <sh-logs-grid-find
              [searchProvider]="insightsProvider.searchProvider"
              *ngIf="insightsProvider.globalState.showFind"
            ></sh-logs-grid-find>
            <a class="item">
              {{ insightsProvider.globalState.queryResultCount }}
            </a>
          </div>
        </div>
        <div fxLayout="column" class="inner-content">
          <sh-insight-info-bar *ngIf="!isMetricAlert" [insight]="insightsProvider.currentInsight"></sh-insight-info-bar>
          <div class="inner-content__wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
