<div class="rules-main-container" fxLayout="column">
  <sh-page-sub-header class="sub-page-header" [title]="'Parsing Rules'">
    <button
      (click)="openParsingThemePanel(parsingThemeEditorMode.create)"
      class="button coralogix sub-page-header__button full-action-button__primary new-rule-group-button"
    >
      <svg-icon [src]="addRuleBtnIconUrl" class="icon-parsing-rules"></svg-icon>
      <span class="sub-page-header__button-text">new rule group</span>
    </button>
  </sh-page-sub-header>

  <div class="rules-content-wrapper">
    <sh-rules-limit-notification *ngIf="rulesCloseToLimit" [rulesLeft]="rulesLeft"></sh-rules-limit-notification>

    <div class="header-section" fxLayout="column">
      <sh-info-notification
        [title]="'Need help managing your log data?'"
        subTitle="Schedule your free integration session with one of our experts."
        [sideIconUrl]="ruleNotificationIconUrl"
        buttonText="SCHEDULE HELP SESSION"
        class="header-section__notification"
        (actionButtonClicked)="onNotificationButtonPress()"
        (closeClicked)="isNotificationHidden = true"
        *ngIf="!isNotificationHidden && !isStandAlone"
      ></sh-info-notification>
      <div fxLayout="row wrap" class="header-section__card-list" fxFlexAlign="center" fxLayoutAlign="center center">
        <div class="data-card" fxLayout="column" fxLayoutAlign="start center" *ngFor="let rule of rulesTypeList">
          <svg-icon class="rule-title-icon" [src]="rule.logoUrl"></svg-icon>
          <div class="data-card__content" fxFlexAlign="center">
            <div class="data-card__title">{{ rule.title | uppercase }}</div>
            <div class="data-card__description">{{ rule.description }}</div>
          </div>
          <cgx-button
            [label]="rule.buttonText | uppercase"
            size="lg"
            width="170px"
            [stroked]="true"
            (press)="openParsingThemePanel(parsingThemeEditorMode.create, rule.type)"
          ></cgx-button>
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="viewMode$ | async">
      <sh-parsing-theme-list-container *ngSwitchCase="viewModeOptions.list"></sh-parsing-theme-list-container>
      <sh-rule-welcome *ngSwitchCase="viewModeOptions.welcome"></sh-rule-welcome>
      <div *ngSwitchDefault class="ui active inline loader"></div>
    </ng-container>
    <sh-parsing-theme-editor-panel #parsingThemePanel></sh-parsing-theme-editor-panel>
  </div>
</div>
