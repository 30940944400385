import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { LogsGridService } from '@app/logs-new/shared/services/logs-grid.service';
import { RowNode } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ResizeEvent } from 'angular-resizable-element';
import { LogsContent } from '@app/logs-new/shared/models/logs-content.model';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { copyToClipboard } from '@shared/utils/utils';
import { ShDialogService } from '@shared/services/dialogService';
import { filterFalsy } from '@app/logs-new/shared/operators/filter-falsy.operator';
import { UnescapeForwardSlashesPipe } from '@shared/pipes/unescape-forward-slashes/unescape-forwarwd-slashes.pipe';

enum InfoPanelState {
  Right,
  Bottom,
}

enum InfoPanelModes {
  JSON,
  TABLE,
  RAW,
}

@Component({
  selector: 'sh-logs-new-info-panel',
  templateUrl: './logs-info-panel.component.html',
  styleUrls: ['./logs-info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsInfoPanelComponent implements AfterViewInit, OnDestroy {
  static MIN_DIMENSIONS_PX: number = 400;
  static MAX_DIMENSIONS_PX: number = 1000;
  @Output() clearInfoPanelEvent = new EventEmitter();
  InfoPanelState = InfoPanelState;
  InfoPanelModes = InfoPanelModes;
  infoPanelMode = InfoPanelModes.JSON;
  infoPanelPosition = InfoPanelState.Right;
  selectedRow$ = new BehaviorSubject<RowNode>(null);
  @ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
  @ViewChild('content', { read: TemplateRef }) contentRef: TemplateRef<any>;
  infoPanelStyle: { [selector: string]: string } = {
    width: `${LogsInfoPanelComponent.MIN_DIMENSIONS_PX}px`,
  };
  isFullScreen$ = new BehaviorSubject(false);
  @Input() activeTab$: Observable<LogsContent>;
  @Input() logQuery$: Observable<ILogQuery>;
  LogsContent = LogsContent;
  private unsubscribe$ = new Subject<void>();

  constructor(public logsGridService: LogsGridService, private cdr: ChangeDetectorRef, private dialogService: ShDialogService) {}

  ngAfterViewInit(): void {
    this.logsGridService.selectedRow$.pipe(filterFalsy()).subscribe(selectedRow => {
      this.outletRef.clear();
      this.outletRef.createEmbeddedView(this.contentRef);
      this.selectedRow$.next(selectedRow);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleResizing(event: ResizeEvent): void {
    this.infoPanelStyle = {
      ...this.infoPanelStyle,
      width: `${event.rectangle.width}px`,
    };
  }

  toggleInfoPanel(): void {
    this.isFullScreen$.next(!this.isFullScreen$.getValue());
  }

  copyLogToClipboard(): void {
    this.selectedRow$.pipe(take(1)).subscribe(async selectedRow => {
      const prop = this.infoPanelMode === InfoPanelModes.RAW ? 'rawText' : 'text';
      let log = selectedRow.data[prop];
      if (this.infoPanelMode === InfoPanelModes.RAW) {
        const unescapeForwardSlashesPipe = new UnescapeForwardSlashesPipe();
        log = unescapeForwardSlashesPipe.transform(log);
      }
      await copyToClipboard(log);
      this.dialogService.showCoralogixMessage('Log copied to clipboard successfully');
    });
  }

  validateResize(event: ResizeEvent): boolean {
    const { MIN_DIMENSIONS_PX, MAX_DIMENSIONS_PX } = LogsInfoPanelComponent;
    const width = event.rectangle.width;
    return width > MIN_DIMENSIONS_PX && width < MAX_DIMENSIONS_PX;
  }
}
