<sh-livetail-terminal-input
  class="livetail-terminal-input"
  [isConnected]="isConnected"
  [isPrettified]='isPrettified'
  (clearEvent)="onClear()"
  (stopEvent)="onStop()"
  (grepInputEvent)="onGrepInput($event)"
  (prettifyEvent)='isPrettified = !isPrettified'
  (toggleFiltersPanel)="toggleFiltersPanelStatus()"
></sh-livetail-terminal-input>
<sh-livetail-filters-panel
  class="livetail-filters-panel"
  [ngClass]="{'visible': isFiltersPanelVisible}"
  (filtersChange)="assignKeysToFilter($event)"
  [keysToFilter]="keysToFilter">
</sh-livetail-filters-panel>
<!--<div style="width: 100%;height: 1px;opacity: 0.5"></div>-->
<sh-livetail-terminal-container
  [keysToFilter]="keysToFilter"
  [searchQuery]='textInput'
  [livetailLogs]="livetailLogs"
  [isPrettified]='isPrettified'
  [scrollItem]="scrollToBottomEvent"
></sh-livetail-terminal-container>
<!--<sh-logs-info-panel #infoPanel [selectedLog]="selectedLog"></sh-logs-info-panel>-->

