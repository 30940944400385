import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { Anomaly } from '../../shared/models/anomaly.model';
import { ModalDirective } from '../../../shared/directives/modal.directive';
import { FormGroup } from '@angular/forms';
import { AnomalyTuningFormProvider } from './anomaly-tuning-form-provider';
import { AnomalyUpdateParams } from '../../shared/models/Anomaly-update-params';
import { InsightsService } from '../../shared/services/insights-service';
import { UpdatedAnomaliesModel } from '../../shared/models/updated-anomalies-model';

@Component({
  selector: 'sh-anomaly-tuning',
  templateUrl: './anomaly-tuning.component.html',
  styleUrls: ['anomaly-tuning.component.scss'],
})
export class AnomalyTuningComponent implements OnInit {
  public anomaly: Anomaly;
  @Output() public onAnomalyUpdated: EventEmitter<
    UpdatedAnomaliesModel
  > = new EventEmitter<UpdatedAnomaliesModel>();
  @ViewChild(ModalDirective, { static: true })
  public modalDirectiveElm: ModalDirective;
  public inline: 'default' | 'inline';

  get insightSeverityOptions() {
    return this.formProvider.insightSeverityOptions;
  }

  get newFormGroup(): FormGroup {
    return this.formProvider.newForm;
  }

  constructor(
    private formProvider: AnomalyTuningFormProvider,
    private insightService: InsightsService,
  ) {}

  public ngOnInit() {}

  public openAnomalyTuning(anomaly: Anomaly) {
    this.anomaly = anomaly;
    const updateParams: AnomalyUpdateParams = new AnomalyUpdateParams();
    updateParams.isActive = anomaly.isActive;
    updateParams.severity = anomaly.severity;
    updateParams.name = anomaly.name || 'Flow Anomaly #' + anomaly.shortId;
    this.formProvider.updateFormByModel(updateParams);

    if (this.modalDirectiveElm) {
      this.modalDirectiveElm.showModal();
    }
  }

  public onSubmitChanges() {
    this.submitChanges(this.formProvider.getModel());
  }

  public submitChanges(updateParams: AnomalyUpdateParams) {
    if (this.modalDirectiveElm) {
      this.modalDirectiveElm.hideModal();
    }
    this.insightService
      .updateAnomaly(this.anomaly, updateParams)
      .subscribe(() =>
        this.onAnomalyUpdated.emit(
          new UpdatedAnomaliesModel(this.anomaly.alertId, updateParams),
        ),
      );
  }

  public updateAnomaly(anomaly: Anomaly, updateParams: AnomalyUpdateParams) {
    anomaly.name = updateParams.name;
    anomaly.severity = updateParams.severity;
    anomaly.isActive = updateParams.isActive;
  }
  public cancelChanges() {
    if (this.modalDirectiveElm) {
      this.modalDirectiveElm.hideModal();
    }
  }
}
