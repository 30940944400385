import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { TeamUsersService } from '../../shared/srvices/team-users.service';
import { TeamUsersActions } from './team-users.actions';
import { concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { of } from 'rxjs/observable/of';
import { ShDialogService } from '@shared/services/dialogService';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';

@Injectable()
export class TeamUsersEffects {
  @Effect() public get$: any = this.actions$.pipe(
    ofType(TeamUsersActions.LOAD),
    switchMap(() =>
      this.teamUsersService
        .getTeammates()
        .map((teammates) => {
          return new TeamUsersActions.LoadSuccessAction(teammates);
        })
        .catch((err) => Observable.of(new TeamUsersActions.LoadFailAction(err))),
    ),
  );

  @Effect() public delete$: any = this.actions$.pipe(
    ofType(TeamUsersActions.DELETE),
    switchMap((action: any) => {
      return this.teamUsersService
        .deleteTeammate(action.payload)
        .map((deleted_item_id) => new TeamUsersActions.DeleteSuccessAction(action.payload))
        .catch((err) => Observable.of(new TeamUsersActions.DeleteFailAction([action.payload, err])));
    }),
  );

  @Effect() public changeRole$: any = this.actions$.pipe(
    ofType(TeamUsersActions.CHANGE_ROLE),
    switchMap((action: any) => {
      return this.teamUsersService
        .changeRole(action.payload)
        .map(() => new TeamUsersActions.ChangeRoleSuccessAction(action.payload))
        .catch((err) => Observable.of(new TeamUsersActions.ChangeRoleFailAction([action.payload, err])));
    }),
  );

  @Effect() public updateGroup$: any = this.actions$.pipe(
    ofType(TeamUsersActions.UPDATE_GROUP),
    mergeMap((action: any) => {
      this.store.dispatch(
        new TeamUsersActions.UpdateLoadingAction({ uid: action.payload.uid, showLoadingSpinner: true }),
      );
      return this.teamUsersService
        .updateTeammateGroup(action.payload.companyId, action.payload.uid, action.payload.type, {
          groupId: action.payload.groupId,
        })
        .map(() => new TeamUsersActions.UpdateGroupSuccessAction(action.payload))
        .catch((err) => {
          this.dialogService.showCoralogixMessage('Error occurred', null, dialogServiceIconClasses.failed);
          return of(new TeamUsersActions.UpdateGroupFailAction([action.payload, err]));
        })
        .finally(() => {
          return this.store.dispatch(
            new TeamUsersActions.UpdateLoadingAction({ uid: action.payload.uid, showLoadingSpinner: false }),
          );
        });
    }),
  );

  @Effect() public bulkUpdateGroup$: any = this.actions$.pipe(
    ofType(TeamUsersActions.BULK_UPDATE_GROUP),
    concatMap((action: any) => {
      const { companyId, uid, type, groupsIds } = action.payload;
      return this.teamUsersService
        .bulkUpdateTeammateGroups(companyId, uid, type, { groupsIds })
        .map(() => new TeamUsersActions.BulkUpdateGroupSuccessAction(action.payload))
        .catch((err) => {
          this.dialogService.showCoralogixMessage('Error occurred', null, dialogServiceIconClasses.failed);
          return of(new TeamUsersActions.UpdateGroupFailAction([action.payload, err]));
        });
    }),
  );

  constructor(
    private teamUsersService: TeamUsersService,
    private actions$: Actions,
    private store: Store<State>,
    private dialogService: ShDialogService,
  ) {}
}
