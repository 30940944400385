<div class="overlay" [hidden]="!isGridOverlayVisible">
  <div class="ui active inverted dimmer">
    <div class="ui loader"></div>
  </div>
</div>
<sh-grid-paging
  #pagingComponent
  [currentPage]="currentPage"
  [totalPages]="totalPages"
  [paginationControll]="paginationControll"
  style="bottom: 35px"
></sh-grid-paging>
<ag-grid-ng2
  style="height: 100%;width: 100%"
  *ngIf="gridOptions"
  #agGrid
  class="ag-material"
  [gridOptions]="gridOptions"
></ag-grid-ng2>
<div class="ui modal sh-modal" #modal>
  <div class="header title">
    <i class="icon remove" (click)="hideModal()"></i>
    {{ chartType }}
    <div *ngIf="(isReadOnly$ | async) === false" class="floating-btn-tl" (click)="addWidget(parameterQueryModel)">
      <i class="pin icon"></i>
    </div>
  </div>
  <div style="position: relative">
    <div class="overlay" [(hidden)]="!isChartOverlayVisible">
      <div class="ui active inverted dimmer">
        <div class="ui loader"></div>
      </div>
    </div>
    <sh-loggregation-free-chart [data]="parameterData"></sh-loggregation-free-chart>
  </div>
</div>
