import { ElementRef, AfterViewInit, OnDestroy, EventEmitter, Input, Output, Directive, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
declare let $: any;

@Directive({
  selector: '[modal-directive]',
})
export class ModalDirective implements AfterViewInit, OnDestroy {
  @Input() set ClassName(classesNames: string) {
    if (!classesNames) {
      classesNames = 'ui modal';
    }
    classesNames.split(' ').forEach((x) => {
      this.renderer.addClass(this.el.nativeElement, x);
    });
  }
  @Input() public inverted: boolean;
  @Input() public closeable: boolean = false;
  @Output() public modalClosed: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  public resolve: Subject<boolean> = new Subject<boolean>();

  private promise: Promise<boolean>;

  constructor(private el: ElementRef, private renderer: Renderer2) {}
  public showModal() {
    if (this.el) {
      $(this.el.nativeElement).modal('show');
    }
    return this.resolve.asObservable();
  }

  public hideModal() {
    if (this.el) {
      $(this.el.nativeElement).modal('hide');
    }
  }

  public ngAfterViewInit() {
    if (this.el) {
      $(this.el.nativeElement).modal({
        inverted: this.inverted,
        closable: this.closeable,
        useCSS: false,
        onDeny: () => {
          this.resolve.next(false);
        },
        onApprove: () => {
          this.resolve.next(true);
        },
      });
    }
  }

  public ngOnDestroy() {
    if (this.el) {
      $(this.el.nativeElement).modal('hide');
    }
  }
}
