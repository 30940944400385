import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { graphPointValue, timestampVal } from '@app/statistics/shared/models/line-chart.models';

@jsonObject
export class TotalSeverity {
  @jsonMember
  public size: number;
  @jsonMember
  public severity: number;
}

@jsonObject
export class AlertInfo {
  @jsonMember
  public size: number;
  @jsonMember
  public name: string;
  @jsonMember
  public severity: number;
}

@jsonObject
export class LineGraphResult {
  @jsonArrayMember(Number, { dimensions: 2 })
  public tag: [timestampVal, graphPointValue][];
  @jsonArrayMember(Number, { dimensions: 2 })
  public compareTag: [timestampVal, graphPointValue][];
}

@jsonObject
export class TagsAlertSummaryResponse {
  @jsonArrayMember(TotalSeverity)
  public severityInfo: TotalSeverity[];
  @jsonArrayMember(AlertInfo)
  public alertsListInfo: AlertInfo[];
  @jsonMember
  public volumeCompareGraph: LineGraphResult;
}

export interface ITimeRange {
  startTime: number;
  endTime: number;
}

export interface ITagRequest extends ITimeRange {
  applicationName: string[];
  subsystemName: string[];
}

export interface IRequestWithVolumeData extends ITagRequest {
  step?: number;
}

export interface ITagAlertSummaryRequest {
  tag: IRequestWithVolumeData;
  compareTag: IRequestWithVolumeData;
}
