import { Build } from '@app/deployment/models/build';
import { PredefinedQuickQueryType } from '@app/logs-new/shared/models/time/quick-times.model';

export enum TimeQueryType {
  Quick = 'quick',
  Relative = 'relative',
  Tag = 'tag',
  Custom = 'custom',
}

export interface IPredefinedQuickQuery {
  caption: string;
  seconds?: number;
  value?: PredefinedQuickQueryType;
}

export interface IPredefinedRelativeQuery {
  fromCaption: string;
  fromDate: Date;
  toCaption: string;
  toDate: Date;
}

export interface IPredefinedCustomQuery {
  fromDate: Date;
  toDate: Date;
}

export type IPredefinedQueryData = IPredefinedCustomQuery | IPredefinedQuickQuery | IPredefinedRelativeQuery | Build;

export interface IPredefinedTimeQuery {
  type: TimeQueryType;
  data: IPredefinedQueryData;
}

export class PredefinedCustomTimeQuery {
  public data: IPredefinedCustomQuery;
  public type: TimeQueryType.Custom = TimeQueryType.Custom;

  constructor(fromDate: number, toDate: number) {
    this.data = {
      fromDate: new Date(fromDate),
      toDate: new Date(toDate),
    };
  }
}

export class PredefinedQuickQuery {
  public data: IPredefinedQuickQuery;
  public type: TimeQueryType.Quick = TimeQueryType.Quick;

  constructor(queryTime: IPredefinedQuickQuery) {
    this.data = queryTime;
  }
}
