<div class="content" *ngIf="!isOptionsFilter">
  <input #textText type="text" [(ngModel)]="gridTextFilter.filterText" placeholder="Text" (keyup.enter)="applyFilter()">
  <div class="button-wraper">
    <div class="ui button primary" (click)="applyFilter()">Apply</div>
  </div>
</div>

<div *ngIf="isOptionsFilter">
  <div style="width: 100%;padding: 10px">
    <button class="ui icon button header-button" style="width: 20px;padding: 0px;"  *ngIf="false"
            [attr.data-tooltip]="'group by: '+ colDef.headerName"
            data-position="bottom left" (click)="applyGroupBy()">
      <i class="folder outline icon">
      </i>
    </button>
    <input #optionsText type="text" [(ngModel)]="filter" placeholder="Find filter">
  </div>

  <div class="list-wraper">
    <div class="ui inverted dimmer" [class.active]="gridOptionsFilter.isLoading">
      <div class="ui text loader"></div>
    </div>
    <div class="checkbox-list" #list>
      <div *ngFor="let option of gridOptionsFilter.filterOptions | filterPipe:filter:'name'" class="ui checkbox filter">
        <input type="checkbox" [checked]="option.isSelected"
               (change)="gridOptionsFilter.onChange($event.target.checked,option)"
               [attr.id]="option.name">
        <label [attr.for]="option.name" style="padding-left: 34px;">{{option.name}}</label>
      </div>
      <div class="massage" *ngIf="!gridOptionsFilter.isLoading && !gridOptionsFilter.hasFilters">No matching filters
      </div>
    </div>
  </div>
  <div style="width: 100%;padding: 10px">
    <div class="ui button primary" (click)="applyFilter()">Apply</div>
  </div>
</div>
