<button
  *ngIf="isAddWidgetEnabled"
  class="ui icon button"
  (click)="onShowWidget.emit()"
  data-tooltip="show graph"
  [attr.data-position]="tooltipPosition"
>
  <i class="bar chart icon"></i>
</button>
<button
  *ngIf="isCopyToClipboardEnabled"
  class="ui icon button"
  (click)="onCopyToClipbboard.emit()"
  data-tooltip="Copy to clipboard"
  [attr.data-position]="tooltipPosition"
>
  <i class="clipboard icon"></i>
</button>
<button
  *ngIf="isFiltersEnabled"
  (click)="onFilterByValue.emit()"
  class="ui icon button"
  data-tooltip="Filter by"
  [attr.data-position]="tooltipPosition"
>
  <i class="search plus icon"></i>
</button>
