<div *ngIf="totalPages > 1"  class="pagingControl">
  <div class="pagingButtonsContainer">

    <div class="leftButtons">
      <button class="circular ui icon button floating-btn"
              [disabled]="paginationControll?.btFirst.disabled"
              (click)="pageClicked(0)">
        <i class="angle double left icon"></i>
      </button>
      <button class="circular ui icon button floating-btn"
              [disabled]="paginationControll?.btPrevious.disabled"
              (click)="NextPrevClicked(paginationControll?.btPrevious)">
        <i class="angle left icon"></i>
      </button>
    </div>

    <div class="pagesList">
      <div  *ngFor="let page of totalPages | numberToArrayPipe">
        <button [class.page-selected-btn]="currentPage == page"
                attr.id="pageButton{{page}}"
                (click)="pageClicked(page)"
                class="circular ui icon button page-btn">
          {{page + 1}}
        </button>
      </div>
  </div>

    <div class="rightButtons">
      <button class="circular ui icon button floating-btn"
              [disabled]="paginationControll?.btNext.disabled"
              (click)="NextPrevClicked(paginationControll?.btNext)">
        <i class="angle right icon"></i>
      </button>
      <button class="circular ui icon button floating-btn"
              [disabled]="paginationControll?.btLast.disabled"
              (click)="pageClicked(totalPages-1)">
        <i class="angle double right icon"></i>
      </button>
    </div>

  </div>
</div>
