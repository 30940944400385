import { Component, Input } from '@angular/core';
import { Insight } from '../../shared/models/insight.model';
import { Alert } from '../../shared/models/alert.model';
import { Anomaly } from '../../shared/models/anomaly.model';
import { AlertHelper } from '@app/alerts/shared/services/alert-helper';
import * as moment from 'moment';
import { AnomalySubTypeId, InsightSeverity } from '../../shared/models/enums';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { TimeZoneBaseClass } from '@shared/base-classes/time-zone-base';

@Component({
  selector: 'sh-insight-info-bar',
  templateUrl: './insight-info-bar.component.html',
  styleUrls: ['./insight-info-bar.component.scss'],
})
export class InsightInfoBarComponent extends TimeZoneBaseClass {
  @Input() set insight(insight: Insight) {
    this._insight = insight;
    this.updateCaption(insight);
  }
  public caption: string;
  public captionPrefix: string;

  private _insight: Insight;

  constructor(private alertHelper: AlertHelper, private store: Store<State>) {
    super(store);
  }

  public capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public getSeverityCaption(): string {
    if (this._insight) {
      const severityCaption = InsightSeverity[
        this._insight.severity
      ].toLowerCase();
      if (
        this._insight instanceof Anomaly ||
        (this._insight instanceof Alert &&
          !(this._insight as Alert).hasConditions)
      ) {
        return this.capitalizeFirstLetter(severityCaption);
      }
      return severityCaption;
    }
    return '';
  }

  public getSeverity(): string {
    if (this._insight) {
      return InsightSeverity[this._insight.severity].toLowerCase();
    }
    return '';
  }

  private updateCaption(insight: Insight): void {
    this.caption = '';
    this.captionPrefix = '';

    if (insight instanceof Alert) {
      if ((insight as Alert).hasConditions) {
        this.captionPrefix = 'Advanced ';
        this.caption =
          ' alert "' +
          (insight as Alert).name +
          '" matched ' +
          this.alertHelper.GetConditionsOperatoCaption(
            (insight as Alert).conditions.conditionOperator,
          ) +
          ' than ' +
          (insight as Alert).conditions.conditionThreshold +
          ' times in ' +
          this.alertHelper.GetConditionTimeFrameCaption(
            (insight as Alert).conditions.conditionTimeframe,
          );
      } else {
        this.caption = 'Alert "' + (insight as Alert).name + '"';
      }
    } else if (insight instanceof Anomaly) {
      // Flow anomaly detected - anomalous arrival ratio per minute (8:24 AM - 8:25 AM) for the following log entries
      if (insight.subTypeId === AnomalySubTypeId.FlowAnomaly) {
        const endTime = moment((insight as Anomaly).timestamp)
          .utc(this.isLocalDate)
          .add({ minutes: 1 })
          .format('LT');
        this.caption =
          'Flow anomaly detected - anomalous arrival ratio per minute (' +
          moment((insight as Anomaly).timestamp).utc(this.isLocalDate).format('LT') +
          ' - ' +
          endTime +
          ') for the following log entries';
      } else {
        const endTime = moment((insight as Anomaly).timestamp)
          .utc(this.isLocalDate)
          .subtract({ hours: 1 })
          .format('LT');
        this.caption =
          'Spike anomaly detected - anomalous arrival ratio per hour (' +
          endTime +
          ' - ' +
          moment((insight as Anomaly).timestamp).utc(this.isLocalDate).format('LT') +
          ') for the following log entries';
      }
    }
  }
}
