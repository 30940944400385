<div class="container">
  <div class="query-header">
    <label class="label-with-tooltip" select-label>
      <span class="text">{{ queryTitle }}</span>
      <div *ngIf="hasTitleIcon">
        <div *ngIf="useStandardTooltip">
          <i class="fa fa-question-circle-o" shTooltip tooltip></i>
        </div>
        <div *ngIf="!useStandardTooltip">
          <i class="fa fa-question-circle-o" shTooltip [tooltip]="tooltipContent"></i>
        </div>
      </div>
    </label>
    <a *ngIf="showRegexLegend && !isPromQLQuery" class="regex-link" (click)="openCheatSheet()">
      RegEx Cheat Sheet
    </a>
    <a *ngIf="isPromQLQuery" class="regex-link" href="https://prometheus.io/docs/prometheus/latest/querying/basics/" target="_blank">
      PromQL Documentation
    </a>
    <a *ngIf="showPrestoGuide" class="coralogix presto-guide search-query-guide-link" (click)="openPrestoGuide()">
      Presto Functions
    </a>
    <a *ngIf="showPrestoGuide" class="coralogix search-query-guide-link" (click)="openPrestoCheatSheet()">
      Presto Cheat Sheet
    </a>
  </div>
  <textarea
    *ngIf="!isPromQLQuery"
    shAutoresize
    [(ngModel)]="value"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [placeholder]="placeholder"
    [disabled]="!isEditable"
  ></textarea>
  <sh-promql-editor [(ngModel)]="value" *ngIf="isPromQLQuery" [placeholder]="placeholderText"></sh-promql-editor>
  <div class="description" *ngIf="description">
    {{ description }}
  </div>
</div>
