import { PipeTransform, Pipe } from '@angular/core';

const colors = [
  [
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#E91E63',
    '#9E9E9E',
  ],
  [
    '#7C4DFF',
    '#8C9EFF',
    '#82B1FF',
    '#80D8FF',
    '#84FFFF',
    '#A7FFEB',
    '#B9F6CA',
    '#CCFF90',
    '#F4FF81',
    '#FFFF8D',
    '#FFE57F',
    '#FFD180',
    '#EC407A',
    '#BDBDBD',
  ],
  [
    '#7C4DFF',
    '#8C9EFF',
    '#82B1FF',
    '#80D8FF',
    '#84FFFF',
    '#A7CFEB',
    '#B9F6CA',
    '#CCFA00',
    '#4CAF50',
    '#AFDB8D',
    '#FFE57F',
    '#FFD180',
    '#EC407A',
    '#BDBDBD',
  ],
];

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  public transform(text: string, search: any): string {
    if (!search || !text) {
      return text || '';
    }
    let result = text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    for (let i = 0; i < search.length; i++) {
      const pattern = search[i].replace(
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        '\\$&',
      );
      // pattern = pattern.split(' ').filter((t) => {
      //   return t.length > 0;
      // }).join('|');

      result = result.replace(
        new RegExp(pattern, 'gi'),
        (match) => `<span class="grep-highlight">${match}</span>`,
      );
    }

    return search ? result : text;
  }
}

@Pipe({ name: 'stringTocolor' })
export class StringToColorPipe implements PipeTransform {
  public transform(str: string, schemeNUmber: number): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // var c = (hash & 0x00FFFFFF)
    //   .toString(16)
    //   .toUpperCase();

    return colors[schemeNUmber][Math.abs(hash) % 13];
    // return '#' + "00000".substring(0, 6 - c.length) + c;
  }
}
