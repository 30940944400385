import { jsonObject, jsonMember } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class User {
  @jsonMember
  public fullName: string;
  @jsonMember
  public companyId: number;
  @jsonMember(TypedJsonAny.OPTIONS)
  public password: any;
  @jsonMember
  public id: number;
  @jsonMember
  public username: string;
  @jsonMember
  public firstName: string;
  @jsonMember
  public lastName: string;
  @jsonMember
  public isActive: boolean;
  @jsonMember
  public created_at: Date;
  @jsonMember
  public updated_at: Date;
  @jsonMember
  public company_id: number;
  @jsonMember
  public companyName: string;
}
