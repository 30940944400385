import { Injectable } from '@angular/core';
import { LogQueryModel } from '../../logs/shared/models/logsquery.model';
import { Insight } from '../../insights/shared/models/insight.model';
import { AlertModel } from '../../alerts/shared/models/alert-model';

@Injectable()
export class GlobalParamsProvider {
  public chartClickedLogQueryModel: LogQueryModel;
  public preConfiguredNewAlertRequest: AlertModel;
}
