<mat-table
  *ngIf="tableDataSource?.data?.length; else disableDrag ? noResults : loader"
  [dataSource]="tableDataSource"
  class="rules-draggable-table"
  cdkDropList
  #table
  [cdkDropListData]="tableDataSource"
  (cdkDropListDropped)="onOrderChange($event)"
>
  <ng-container matColumnDef="parsing-themes-draggable-table-anchor">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="!disableDrag" class="anchor-image"></div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="parsing-themes-draggable-table-name-n-rules">
    <mat-header-cell *matHeaderCellDef class="table-header">NAME / RULES</mat-header-cell>
    <mat-cell *matCellDef="let element" fxLayout="column" class="name-n-rules" fxLayoutAlign="center start" fxLayoutGap="5px">
      <div class="name-n-rules__name" *ngIf="element.name">{{ element.name }}</div>
      <div class="name-n-rules__rules" *ngIf="element.rulesGroups?.length && element.rulesGroups[0]?.rules?.length">
        <ng-container>
          <span>{{ element.rulesGroups | removeduplicates | uppercase }}</span>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="parsing-themes-draggable-table-applications">
    <mat-header-cell *matHeaderCellDef class="table-header">{{ metadataFields[0]?.toUpperCase() || 'APPLICATIONS' }}</mat-header-cell>
    <mat-cell *matCellDef="let element" class="long-text-cell">
      {{
        element.ruleMatchers | extractPropFromMatcherPipe: ruleMatcherOptions.applications:'All ' + (metadataFields[0] || 'Applications')
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="parsing-themes-draggable-table-subsystems">
    <mat-header-cell *matHeaderCellDef class="table-header">{{ metadataFields[1]?.toUpperCase() || 'SUBSYSTEMS' }}</mat-header-cell>
    <mat-cell *matCellDef="let element" class="long-text-cell">
      {{ element.ruleMatchers | extractPropFromMatcherPipe: ruleMatcherOptions.subsystems:'All ' + (metadataFields[1] || 'Subsystems') }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="parsing-themes-draggable-table-modified-creator">
    <mat-header-cell *matHeaderCellDef class="table-header">MODIFIED</mat-header-cell>
    <mat-cell *matCellDef="let element" fxLayout="column" class="two-lines-cell" fxLayoutAlign="center start" fxLayoutGap="5px">
      <div class="two-lines-cell__line1 date-col" *ngIf="element.updatedAt">
        {{ element.updatedAt | allBrowsersDate | date: 'longDate' }}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="parsing-themes-draggable-table-action">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element" class="action-column">
      <mat-slide-toggle
        labelPosition="before"
        #matSlideToggle
        class="coralogix"
        [checked]="element.enabled"
        (click)="$event.stopPropagation()"
        (toggleChange)="groupEnableChanged.emit([element, !matSlideToggle.checked])"
      ></mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    class="rules-draggable-table__group-row"
    *matRowDef="let row; columns: displayedColumns"
    cdkDrag
    [cdkDragData]="row"
    [cdkDragDisabled]="disableDrag"
    (drag)="emitEventIfDragDisabled()"
    (click)="emitSelectedTeamEvent(row)"
  ></mat-row>
</mat-table>
<ng-template #noResults>
  <div class="no-results">No results found</div>
</ng-template>

<ng-template #loader>
  <!--  <div class="ui active inline loader"></div>-->
</ng-template>
