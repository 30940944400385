import { ActionReducer, combineReducers, compose, createSelector } from '@ngrx/store';
import { environment } from '@environments/environment';
import { EnrichmentsReducer } from './settings/shared/state/enrichment/enrichment.reducer';
import { RulesValidateReducer } from './rules/reducers/rules-validate.reducer';
import { FiltersReducer } from './logs/filters/filters.reducers';
import { TeamsReducer } from './user/state/teams/teams.reducer';
import { TeamUsersReducer } from './user/state/teams/team-users.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { SettingsReducer } from './settings/reducer/settings.reducer';
import { ArchivingProvidersReducer } from './settings/reducer/archiving-providers/archiving-providers.reducer';
import { LogReducer } from './logs/shared/state/log.reducer';
import { GroksReducer } from './groks/shared/state/groks/groks.reducer';
import { LogsGridColumnsReducer } from './logs/shared/state/logs-grid-columns/logs-grid-columns.reducer';
import { LogsFilterGridColumnsReducer } from './logs/shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.reducer';
import { LogsViewsReducer } from './logs/shared/state/logs-views/logs-views.reducer';
import { IntegrationsReducer } from './settings/shared/state/integrations/integrations.reducer';
import { LogsQueryReducer } from './logs/shared/state/logs-query/logs-query.reducer';
import { ExportReducer } from './logs/shared/state/export/export.reducer';
import { MetricEditorReducer } from '@app/settings/metrices-settings/state/reducers/metric-editor.reducer';
import { MetadataReducer } from '@shared/state/reducers/metadata.reducer';
import { MetricsReducer } from '@app/settings/metrices-settings/state/reducers/metrics.reducer';

import { ReindexEditorReducer } from '@app/roi/reindex/state/reducers/reindex-editor.reducer';
import { ReindexReducer } from '@app/roi/reindex/state/reducers/reindex.reducer';
import * as ParsingThemesReducer from '@app/rules/reducers/parsing-themes.reducer';
import * as AlertsReducer from '@app/alerts/shared/reducers/alerts.reducer';
import * as UserSettingsReducer from '@app/user/state/user-settings/user-settings.reducer';
import * as Groups from '@app/settings/account/components/groups/store/groups.reducer';
import * as Roles from '@app/settings/account/components/roles/store/roles.reducer';
import { Teammate } from '@app/user/shared/models/teammate';
import { ILogsModuleState } from '@app/logs-new/store';
import { ArchiveQueryReducer } from '@app/roi/archive-queries/state/reducers/archive-query.reducer';
import { ArchiveQueryEditorReducer } from '@app/roi/archive-queries/state/reducers/archive-query-editor.reducer';
import { Build } from '@app/deployment/models/build';
import { ITagRequest } from '@app/deployment/shared/models/summary.models';
import { getArrayFromStringList, getTagEndTimeByTagsList } from '@app/deployment/shared/helpers/helper-functions';
import { TagsReducer } from '@app/deployment/shared/reducer/tags.reducer';
import * as GenericMetadataReducer from '@shared/state/reducers/generic-metadata.reducer';
import { intersection } from 'lodash';
import { Team } from './user/shared/models/team';
import { IntegrationModel } from './settings-common/shared/models/newIntegrationRequest';

/* tslint:disable-next-line:interface-name */
export interface State {
  groups: Groups.IGroupState;
  roles: Roles.IRolesState;
  parsingThemes: ParsingThemesReducer.IParsingThemesReducerState;
  rulesValidate: RulesValidateReducer.State;
  filters: FiltersReducer.State;
  tags: TagsReducer.State;
  archivingProviders: ArchivingProvidersReducer.State;
  teams: TeamsReducer.State;
  teamUsers: TeamUsersReducer.State;
  router: RouterReducerState;
  settings: SettingsReducer.State;
  log: LogReducer.State;
  groks: GroksReducer.State;
  logsGridColumns: LogsGridColumnsReducer.State;
  logsFilterGridColumns: LogsFilterGridColumnsReducer.State;
  logsGridSavedViews: LogsViewsReducer.State;
  integrations: IntegrationsReducer.State;
  logsQuery: LogsQueryReducer.State;
  enrichments: EnrichmentsReducer.IState;
  export: ExportReducer.State;
  metricEditor: MetricEditorReducer.State;
  metadata: MetadataReducer.State;
  metrics: MetricsReducer.State;
  reindexEditor: ReindexEditorReducer.State;
  reindex: ReindexReducer.State;
  alerts: AlertsReducer.IAlertsReducerState;
  userSettings: UserSettingsReducer.IUserSettingsReducerState;
  logsModule: ILogsModuleState;
  archiveQuery: ArchiveQueryReducer.State;
  archiveQueryEditor: ArchiveQueryEditorReducer.State;
  genericMetaData: GenericMetadataReducer.IGenericMetadataState;
}

export const reducers: any = {
  roles: Roles.reducer,
  groups: Groups.reducer,
  parsingThemes: ParsingThemesReducer.reducer,
  archivingProviders: ArchivingProvidersReducer.reducer,
  filters: FiltersReducer.reducer,
  rulesValidate: RulesValidateReducer.reducer,
  tags: TagsReducer.reducer,
  teams: TeamsReducer.reducer,
  teamUsers: TeamUsersReducer.reducer,
  router: routerReducer,
  settings: SettingsReducer.reducer,
  log: LogReducer.reducer,
  groks: GroksReducer.reducer,
  logsGridColumns: LogsGridColumnsReducer.reducer,
  logsFilterGridColumns: LogsFilterGridColumnsReducer.reducer,
  logsGridSavedViews: LogsViewsReducer.reducer,
  integrations: IntegrationsReducer.reducer,
  logsQuery: LogsQueryReducer.reducer,
  enrichments: EnrichmentsReducer.reducer,
  export: ExportReducer.reducer,
  metricEditor: MetricEditorReducer.reducer,
  metadata: MetadataReducer.reducer,
  metrics: MetricsReducer.reducer,
  reindexEditor: ReindexEditorReducer.reducer,
  reindex: ReindexReducer.reducer,
  alerts: AlertsReducer.reducer,
  userSettings: UserSettingsReducer.reducer,
  archiveQuery: ArchiveQueryReducer.reducer,
  archiveQueryEditor: ArchiveQueryEditorReducer.reducer,
  genericMetaData: GenericMetadataReducer.reducer,
};

// Generate a reducer to set the root state
function stateSetter(actionReducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action: any) => {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return actionReducer(state, action);
  };
}

const DEV_REDUCERS = [stateSetter];

const developmentReducer = compose(...DEV_REDUCERS, combineReducers)(reducers);
const productionReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any): any {
  if (environment.production) {
    return productionReducer(state, action);
  } else {
    return developmentReducer(state, action);
  }
}

/**
 * rules states - parsing themes
 */
export const getParsingThemes = (state: State) => state.parsingThemes.parsingThemes;
export const getParsingThemesFilter = (state: State) => state.parsingThemes.parsingThemesFilter;
export const getPTRulesLeft = (state: State) => state.parsingThemes.rulesLeft;
export const getMainParsingThemeViewState = (state: State) => state.parsingThemes.mainViewState;
export const getExtendedUniqueFields = (state: State) => state.parsingThemes.extendedUniqueFields;

/**
 * filters states
 */
/**
 * manage button overlapping states
 */
export const getManageButtonOverlappingState = (state: State) => state.filters.isOverlappedManageButton;
export const getDynamicFilters = (state: State) => state.filters.data;
/**
 * team states
 */
export const getTeamsState = (state: State) => state.teams;
export const getTeams = createSelector(getTeamsState, (state: TeamsReducer.State) => state.teams);
export const getSelectedTeamId = createSelector(getTeamsState, (state: TeamsReducer.State) => state.selectedTeamId);
export const getSelectedTeam = createSelector(getTeams, getSelectedTeamId, (teams: Team[], selectedTeamId: number) =>
  teams.find(t => t.id === selectedTeamId),
);
export const getTeamsExceptFromSelected = createSelector(getTeams, getSelectedTeamId, (teams: Team[], id: number) =>
  teams.filter(team => team.id !== id),
);
export const getCreateTeamError = (state: State) => state.teams.teamCreateError;
export const getIsCreatingteamUrl = (state: State) => state.teams.creatingTeamUrl;

export const getTeammates = (state: State) => state.teamUsers.teammates;

export const getFilteredTeammates = (
  selectedGroups: string[],
  searchQuery: string,
  startIndex: number,
  nextIndex: number,
  pageSize: number,
) => {
  function filterByQuery(teammate: Teammate, query: string): boolean {
    return teammate.username.toLowerCase().includes(query.toLowerCase());
  }

  return (state: State): { data: Teammate[]; filteredLength: number } => {
    const allSelected = selectedGroups?.length === Object.values(state.groups.allGroups)?.length;
    const sortedTeammates = state.teamUsers.teammates.sort((a, b) => Number(b.created_at) - Number(a.created_at));
    if (selectedGroups?.length === 0) {
      return { data: [], filteredLength: 0 };
    }
    if (allSelected) {
      const filteredByQuery = searchQuery ? sortedTeammates.filter(t => filterByQuery(t, searchQuery)) : sortedTeammates;
      return {
        data: filteredByQuery.length > pageSize ? filteredByQuery.slice(startIndex, nextIndex) : filteredByQuery,
        filteredLength: filteredByQuery.length,
      };
    }
    const filteredTeammates = sortedTeammates.filter(teammate => {
      const groupNames = Object.values(teammate.groupIds);
      return (
        selectedGroups?.some(selectedGroupName => groupNames.some(n => n === selectedGroupName)) && filterByQuery(teammate, searchQuery)
      );
    });
    return {
      data: filteredTeammates.length > pageSize ? filteredTeammates.slice(startIndex, nextIndex) : filteredTeammates,
      filteredLength: filteredTeammates.length,
    };
  };
};

export const getGroupMembers = (groupId: number) => (state: State) => {
  return state.teamUsers.teammates.filter(member => groupId in member.groupIds);
};
export const getFilteredGroupMembers = (groupId: number, searchQuery: string) => (state: State) => {
  return state.teamUsers.teammates
    .filter(member => member.username.includes(searchQuery))
    .sort((a, b) => {
      return groupId in a.groupIds ? -1 : 1;
    });
};
export const getMemberLoadingStatus = (state: State) => state.teamUsers.loadingStatus;

/**
 * Tags states
 */
export const getTags = (state: State) => state.tags.tags;
export const getSelectedTag = (state: State) => state.tags.selectedTagView.selectedTag;
export const getSelectedShowAllSubsystems = (state: State) => state.tags.selectedTagView.selectedShowAllSubsystems;
export const getSelectedTagForCompare = (state: State) => state.tags.selectedTagView.selectTagForCompare;
export const getTagsLoading = (state: State) => state.tags.loading;
export const getDeletedTagId = (state: State) => state.tags.deletedTagId;
export const getSelectedTagLogRow = (state: State) => state.tags.selectedTagView.selectedLog;
export const getTagNComparePayloadBase = (state: State) => state.tags.selectedTagView.compareNSelectedPayloadBase;
export const getTagSummary = (state: State) => state.tags.selectedTagView.summaryArray;

export const getRouter = (state: State) => state.router;
export const getSummaryViewState = (state: State) => state.tags.selectedTagView.summaryViewMode;
export const getSelectedTagAlerts = (state: State) => state.tags.selectedTagView.alerts;
export const getSelectedTagAlertsViewState = (state: State) => state.tags.selectedTagView.alertsViewMode;
export const getSelectedTagDelta = (state: State) => state.tags.selectedTagView.graphsDelta;
export const getTagErrorVolume = (state: State) => state.tags.selectedTagView.errorVolume;
export const getTagErrorVolumeViewState = (state: State) => state.tags.selectedTagView.errorVolumeViewMode;
export const getSelectedTagQueryBase = () =>
  createSelector(
    getTags,
    getSelectedTag,
    (tags: Build[], tag: Build): ITagRequest => {
      if (!tag || !tags) {
        return null;
      }
      return {
        startTime: tag.tag_timestamp,
        endTime: getTagEndTimeByTagsList(tag, tags),
        applicationName: getArrayFromStringList(tag.application_name),
        subsystemName: getArrayFromStringList(tag.subsystem_name),
      };
    },
  );
export const getTagsByAppAndSubSystem = (appNames: string[], subSysNames: string[]) =>
  createSelector(getTags, tags => {
    if (!appNames?.length && !subSysNames?.length) {
      return tags;
    }
    return tags?.filter(tag => {
      function isTagPresent(splitName: string, appOrSub: string[]): boolean {
        const tagSplit = tag[splitName]?.length ? tag[splitName].split(',') : [];
        return tagSplit.some(t => appOrSub.includes(t));
      }
      const existsOnAppNames = appNames.length ? isTagPresent('application_name', appNames) : true;
      const existOnSubSysNames = subSysNames.length ? isTagPresent('subsystem_name', subSysNames) : true;
      return existsOnAppNames && existOnSubSysNames;
    });
  });

/**
 * Settings states
 */
export const getEmailFilters = (state: State) => state.settings.emailFilters;
export const getEmailFiltersLoading = (state: State) => state.settings.loading;
export const getEmailFiltersId = (state: State) => state.settings.email_filter_id;

/**
 * Archiving providers
 */
export const getArchivingProvider = (state: State) => state.archivingProviders.archivingProvider;
export const getArchivingProvidersLoading = (state: State) => state.archivingProviders.loading;
export const getArchivingProvidersSaving = (state: State) => state.archivingProviders.saving;
export const getArchivingProvidersTestingResult = (state: State) => state.archivingProviders.testingResults;
export const getArchivingProvidersTesting = (state: State) => state.archivingProviders.testing;

/**
 * logs
 */
export const getQueryLogCount = (state: State) => state.log.logCount;
export const getQueryTemplateCount = (state: State) => state.log.templateCount;
export const queryTemplate = (state: State) => state.logsQuery.log;
export const getLogPanelQuery = (state: State) => state.log.logsPanelQuery;
/**
 * Groks
 */
export const getDetectedGroks = (state: State) => state.groks.detectedGroks;
export const getSearchingGroks = (state: State) => state.groks.searchingGroks;

/**
 * Logs grid columns
 */
export const getLogsGridColumns = (state: State) => state.logsGridColumns;
export const getLogsGridColDefs = (state: State) => state.logsGridColumns.colDefs;

/**
 * Logs grid columns filter
 */
export const getSelectedLogsFilterGridColumns = (state: State) => state.logsFilterGridColumns.filter.selected;
export const getNonSelectedLogsFilterGridColumns = (state: State) => state.logsFilterGridColumns.filter.available;
export const getNonAllLogsFilterGridColumns = (state: State) => state.logsFilterGridColumns;
export const getLogsFilterGridColumnsVisibility = (state: State) => state.logsFilterGridColumns.isVisible;
export const getLogsFilterGridColumnsLoadingStatus = (state: State) => state.logsFilterGridColumns.isLoading;

/**
 * Logs grid saved views
 */
export const getLogsGridSavedViews = (state: State) => state.logsGridSavedViews;

/**
 * Integrations
 */
export const getIntegrations = (state: State) => state.integrations.integrations;
export const getIntegrationsTypes = (state: State) => state.integrations.integrationTypes;
export const getIntegrationsLoadingStatus = (state: State) => state.integrations.loading;
export const getDigestIntegrations = (state: State) => state.integrations.digestIntegrations;
export const getSlackIntegrations = createSelector(getIntegrations, (integrations: IntegrationModel[]) =>
  integrations?.filter(int => int.integration_type_id === 0),
);

/*
 * Enrichments
 * */

export const getEnrichments = (state: State) => state.enrichments.enrichments;
export const getCustomEnrichments = (state: State) => state.enrichments.customEnrichments;
export const getCustomEnrichmentLoading = (state: State) => state.enrichments.customEnrichmentsEditorLoading;
export const getCustomEnrichmentDeleted = (state: State) => state.enrichments.customEnrichmentDeleted;

/*
 * Exports
 * */

export const getLogPages = (state: State) => state.export.logPages;
export const getDateRange = (state: State) => state.export.dateRange;

/**
 * Metric settings
 */
export const getIsMetricEditorOpen = (state: State) => state.metricEditor.isOpen;
export const getIsMetricEditorInEditMode = (state: State) => state.metricEditor.editMode;
export const getMetricEditorEditedMetric = (state: State) => state.metricEditor.editedMetric;
export const getMetricEditorStatus = (state: State) => ({
  error: state.metrics.error,
  isLoading: state.metrics.loading,
  errorStatus: state.metrics.errorStatus,
});

/**
 * metadata
 */
export const getApplicationNames = (state: State) => state.metadata.applicationName;
export const getSubsystemNames = (state: State) => state.metadata.subsystemName;

/**
 * metrics
 */
export const getMetrics = (state: State) => state.metrics.metrics;
export const loadingMetrics = (state: State) => state.metrics.loading;
export const getPermutationInUse = (state: State) =>
  state.metrics.metrics?.reduce((acc, { permutationsLimit }) => acc + permutationsLimit, 0);

/**
 * Reindex editor
 */
export const getIsReindexEditorOpen = (state: State) => state.reindexEditor.isOpen;
export const getIsS3PermissionsEditorOpen = (state: State) => state.reindexEditor.isS3PermissionOpen;

/**
 * Reindex
 */
export const getReindexes = (state: State) => state.reindex.reindexes;
export const loadingReindexes = (state: State) => state.reindex.loading;
export const getReindexesPermissions = (state: State) => state.reindex.permissions;
export const getReindexesPermissionsError = (state: State) => state.reindex.permErr;
export const creationFailedError = (state: State) => state.reindex.creationError;

/**
 * Alerts
 */
export const getAlerts = (state: State) => state.alerts.alertsList;
export const getAlertsInsights = (state: State) => state.alerts.alertsInsights;
export const getAlertsTableLoading = (state: State) => state.alerts.alertsListLoading;
export const getAlertsGraphLoading = (state: State) => state.alerts.alertsGraphLoading;
export const alertsEditorLoading = (state: State) => state.alerts.editor.loading;
export const openAlertInvalidQuery = (state: State) => state.alerts.editor.invalidQuery;

/**
 * User Settings
 */
export const getTimezoneViewPreference = (state: State) => state.userSettings.timeZoneViewType;
export const getDateFormatViewPreference = (state: State) => state.userSettings.dateFormatType;

/**
 * Company Groups
 */
export const getGroups = (state: State) => Object.values(state.groups.allGroups);
export const getFilteredGroups = (searchQuery: string) => (state: State) => {
  const groups = Object.values(state.groups.allGroups);
  return groups.filter(group => group.name.toLowerCase().includes(searchQuery.toLowerCase()));
};
export const getGroupsNamesMap = (state: State) => state.groups.groupNames;
export const getGroupNames = (state: State) => Object.values(state.groups.allGroups).map(group => group.name);
export const getAllGroups = (state: State) => state.groups.allGroups;
export const getGroupLoadingState = (state: State) => state.groups.isLoading;

/**
 * Archive Queries
 */
export const loadingArchiveQueries = (state: State) => state.archiveQuery.loading;
export const secondLoading = (state: State) => state.archiveQuery.secondLoader;
export const getArchiveQueries = (state: State) => state.archiveQuery.archiveQueries;
export const getTotalQueries = (state: State) => state.archiveQuery.totalQueries || 0;
export const getArchiveQueriesUiState = (state: State) => state.archiveQuery.archiveQueriesUIState;
export const getIsArchiveQueryEditorOpen = (state: State) => state.archiveQueryEditor.isOpen;
export const getArchivePermissionsError = (state: State) => state?.archiveQuery?.permissionsError;
