import { copyToClipboard, log10 } from '@shared/utils/utils';

function getBasicChart(seriesData: number[]): object {
  return {
    chart: {
      type: 'bar',
      renderTo: this.chartEl.nativeElement,
      height: 500,
      backgroundColor: 'var(--menu-bg)',
      style: {
        fontFamily: 'Nunito Sans',
        fontSize: '13px',
      },
      scrollablePlotArea: {
        minHeight: 25 * this.chartData.length,
        opacity: 1,
      },
      marginRight: 60,
    },
    series: [
      {
        data: seriesData,
        maxPointWidth: 14,
      },
    ],
    tooltip: {
      animation: false,
      useHTML: true,
      shared: true,
      outside: false,
      formatter(): string {
        return getTooltip(this.x, this.y);
      },
      backgroundColor: 'var(--tooltip-background)',
      borderColor: 'transparent',
      borderRadius: 8,
    },
    colors: ['var(--graph-series-color)'],
    title: { text: null },
    xAxis: {
      categories: this.chartData.map(data => this.getFieldLabel(data[this.fieldLabel])) as string[],
      tickLength: 0,
      labels: {
        useHTML: true,
        formatter(): string {
          return `
              <div class='labels-container' data-test='field-visualization-labels-container'>
                <div class='menu-icon-container'>
                  <img src='../../../../../assets/icons/more_vert.svg' class='icon' alt='icon' />
                </div>
                <div class='label' data-test='field-visualization-label' title='${this.value}'>
                  ${this.value}
                </div>
              </div>`;
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          fontFamily: 'Nunito Sans',
          color: 'var(--graph-text-color)',
        },
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderColor: 'transparent',
        dataLabels: {
          enabled: true,
          overflow: 'none',
          crop: false,
          allowOverlap: true,
          color: 'var(--graph-text-color)',
          borderWidth: '0',
          style: {
            fontWeight: 600,
            fontSize: '13px',
          },
        },
      },
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: e => {
              const label = e?.point?.category;
              copyToClipboard(label);
              this.dialogService.showCoralogixMessage(`"${label}" copied to clipboard`);
            },
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
  };
}

enum ChartTypes {
  Normal,
  Logarithmic,
}

function getYAxisMax(type: ChartTypes.Logarithmic | ChartTypes.Normal): any {
  const firstItem = this.chartData[0];
  if (!firstItem) {
    return null;
  }
  const maxField = firstItem[this.fieldAmount] as number;
  const buffer = 3;
  const bufferMultiplier = Math.round(String(maxField).length * buffer);
  const bufferedMaxField = maxField * Number(`1.${bufferMultiplier}`);
  const bufferedLogarithmicField = log10(maxField) * 1.1;
  return type === ChartTypes.Logarithmic ? bufferedLogarithmicField : bufferedMaxField;
}

function getTooltip(field: string, amount: number): string {
  return `<div class='chart-tooltip'>
                  <div class='chart-title'>Field <b>${field}</b></div>
                  <div class='chart-title'>Amount <b>${amount}</b></div>
          </div>`.trim();
}

export default {
  getNormalChart(): any {
    const seriesData = this.chartData.map(data => data[this.fieldAmount]);
    const chart = getBasicChart.call(this, seriesData);
    chart['yAxis'].max = getYAxisMax.call(this, ChartTypes.Normal);
    return chart;
  },

  getLogarithmicChart(): any {
    const seriesData = this.chartData.map(data => log10(data[this.fieldAmount] as number));
    const chart = getBasicChart.call(this, seriesData);
    chart['yAxis'].max = getYAxisMax.call(this, ChartTypes.Logarithmic);
    chart['plotOptions'].bar.dataLabels.formatter = function(): any {
      return Math.round(Math.pow(10, this.y));
    };
    chart['yAxis'].labels.formatter = function(): number {
      return Math.round(Math.pow(10, this.value));
    };
    chart.tooltip.formatter = function(): string {
      return getTooltip(this.x, Math.round(Math.pow(10, this.y)));
    };
    return chart;
  },
};
