import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Build } from '@app/deployment/models/build';
import * as _ from 'lodash';
import { MomentTimeFormatTypes } from '@shared/helpers/moment.helper';

@Component({
  selector: 'sh-tag-query-item',
  templateUrl: './tag-query-item.component.html',
  styleUrls: ['./tag-query-item.component.scss'],
})
export class TagQueryItemComponent {
  public _tagData: Build;
  public dateAgo: string;
  public refId: string;
  public standardHoursFormat: MomentTimeFormatTypes = MomentTimeFormatTypes.standard;

  @Input() set tagData(build: Build) {
    const delimiterIndex = build.text_value.lastIndexOf('\\');
    const newBuild = _.cloneDeep(build);

    if (delimiterIndex !== -1) {
      newBuild.text_value = build.text_value.substr(0, delimiterIndex);
      this.refId = build.text_value.substr(
        delimiterIndex + 1,
        build.text_value.length - delimiterIndex,
      );
    }

    this._tagData = newBuild;
    this.dateAgo = moment(build.tag_timestamp).fromNow();
  }

  @Output() public tagSelected: EventEmitter<Build> = new EventEmitter<Build>();
}
