import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  SnackBarStatusModel,
  StatusesEnum,
} from '../../../../settings-common/shared/models/snack-bar-status';

@Component({
  selector: 'snack-bar-status',
  templateUrl: './snack-bar-status.component.html',
  styleUrls: ['./snack-bar-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackBarStatusComponent {
  public statusEnumValue: StatusesEnum;
  public message: string;
  public className: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarStatusModel) {
    this.statusEnumValue = data.status;
    this.message = data.message;
    this.className = data.className;
  }
}
