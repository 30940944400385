import { Team } from '../../shared/models/team';
import { TeamActions } from './teams.actions';
import * as _ from 'lodash';

export namespace TeamsReducer {
  export interface State {
    teams: Team[];
    loadingTeams: boolean;
    selectedTeamId: number;
    teamCreateError: string;
    creatingTeamUrl: boolean;
  }

  const initalState: State = {
    teams: [],
    loadingTeams: false,
    selectedTeamId: -1,
    teamCreateError: '',
    creatingTeamUrl: false,
  };

  export function reducer(
    state: State = initalState,
    action: TeamActions.Actions,
  ): State {
    switch (action.type) {
      case TeamActions.ActionTypes.LOGIN_WITH_TEAM_NAME:
      case TeamActions.ActionTypes.GET_TEAMS:
        return Object.assign({}, state, { loadingTeams: true });
      case TeamActions.ActionTypes.GET_TEAMS_COMPLETED:
        return Object.assign({}, state, {
          teams: action.payload,
          loadingTeams: false,
        });
      case TeamActions.ActionTypes.LOGIN_WITH_TEAM_NAME_GET_TEAMS_COMPLETED:
        return Object.assign({}, state, {
          teams: action.payload.teams,
          loadingTeams: false,
        });
      case TeamActions.ActionTypes.GET_TEAMS_FAILED:
        console.log('get Teams Failed', action.payload);
        return Object.assign({}, state, { loadingTeams: false });
      case TeamActions.ActionTypes.SELECT_TEAM:
        return Object.assign({}, state, { selectedTeamId: action.payload });
      case TeamActions.ActionTypes.GET_SELECTED_TEAM_COMPLETED:
        return Object.assign({}, state, { selectedTeamId: action.payload });
      case TeamActions.ActionTypes.CREATE_TEAM:
        return Object.assign({}, state, {
          teamCreateError: '',
          creatingTeamUrl: true,
        });
      case TeamActions.ActionTypes.CREATE_FIRST_TEAM:
        return Object.assign({}, state, {
          teamCreateError: '',
          creatingTeamUrl: true,
        });
      case TeamActions.ActionTypes.CREATE_TEAM_COMPLETED:
        return Object.assign({}, state, {
          teams: [...state.teams, action.payload],
          creatingTeamUrl: false,
        });
      case TeamActions.ActionTypes.CREATE_TEAM_FAILED:
        const teamErr =
          action.payload.status === 409
            ? 'Team already exist, please choose a different name'
            : 'Unable to create team';
        return Object.assign({}, state, {
          teamCreateError: teamErr,
          creatingTeamUrl: false,
        });
      case TeamActions.ActionTypes.SET_LOGIN_WITH_TEAM_NAME:
        return Object.assign({}, state, {
          loginWithTeamName: action.payload,
        });
      case TeamActions.ActionTypes.CHANGE_TEAM_URL_COMPLETED:
        const team = state.teams.find((x) => x.id === action.payload.team.id);
        const teamIndex = state.teams.map((t) => t.id).indexOf(team.id);
        const newTeam = _.clone<Team>(team);
        newTeam.name = action.payload.newURL;
        newTeam.team_url = action.payload.newURL;

        return Object.assign({}, state, {
          teams: [
            ...state.teams.slice(0, teamIndex),
            newTeam,
            ...state.teams.slice(teamIndex + 1),
          ],
        });
      default:
        return state;
    }
  }
}
