import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class Metadata {
  @jsonMember
  public applicationName: string;
  @jsonMember
  public category: string;
  @jsonMember
  public className: string;
  @jsonMember
  public methodName: string;
  @jsonMember
  public severity: string;
  @jsonMember
  public subsystemName: string;

  get severityName(): string {
    if (this.severity === '1') {
      return 'DEBUG';
    } else if (this.severity === '2') {
      return 'VERBOSE';
    } else if (this.severity === '3') {
      return 'INFO';
    } else if (this.severity === '4') {
      return 'WARNING';
    } else if (this.severity === '5') {
      return 'ERROR';
    } else if (this.severity === '6') {
      return 'CRITICAL';
    }
    return 'INFO';
  }
}

@jsonObject
export class AnomalyDetails {
  @jsonMember
  public count: number;
  @jsonMember
  public id: string;
  @jsonMember
  public isAnomalous: boolean;
  @jsonMember
  public metadata: Metadata;
  @jsonMember
  public text: string;
  @jsonMember
  public scale: number;
  @jsonMember
  public normalRatio: number;
  @jsonMember
  public anomalyRatio: number;
  @jsonMember(TypedJsonAny.OPTIONS)
  public logExample: any;
}

@jsonObject
export class AnomalyProperties {
  @jsonArrayMember(AnomalyDetails)
  public details: AnomalyDetails[];
  @jsonMember(TypedJsonAny.OPTIONS)
  public patterns1Text: any;
}
