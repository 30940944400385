import { MetadataActions } from '@shared/state/actions/metadata.actions';

export namespace MetadataReducer {

  export interface State {
    applicationName: string[];
    subsystemName: string[];
  }

  const initialState: State = {
    applicationName: [],
    subsystemName: []
  };

  export function reducer(state: MetadataReducer.State = initialState, action: MetadataActions.Actions): MetadataReducer.State {
    switch (action.type) {
      case MetadataActions.ActionTypes.GET_APPLICATION_NAME_COMPLETED:
        return { ...state, ...{ applicationName: action.payload } };
      case MetadataActions.ActionTypes.GET_SUBSYSTEM_NAME_COMPLETED:
        return { ...state, ...{ subsystemName: action.payload } };
      default:
        return state;
    }
  }
}
