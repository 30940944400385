import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';
import { ReindexService } from '@app/roi/reindex/services/reindex.service';
import { ReindexActions } from '@app/roi/reindex/state/actions/reindex.actions';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class ReindexEffects {
  @Effect()
  public getReindexesStatus$: Observable<Action> = this.actions$.pipe(
    ofType(ReindexActions.ActionTypes.GET_REINDEXES_STATUS),
    switchMap((action: any) => {
      return this.reindexService.getReindexesStatus(action.payload)
        .map((res) => new ReindexActions.GetReindexesStatusCompletedAction(res))
        .catch((err) => of(new ReindexActions.GetReindexesStatusFailedAction(err)));
    }),
  );

  @Effect()
  public getReindexes$: Observable<Action> = this.actions$.pipe(
    ofType(ReindexActions.ActionTypes.GET_REINDEXES),
    switchMap((action: any) => {
      return this.reindexService
        .getReindexes()
        .map((res) => new ReindexActions.GetReindexesCompletedAction(res))
        .catch((err) => of(new ReindexActions.GetReindexesFailedAction(err)));
    }),
  );

  @Effect()
  public createReindex$: Observable<Action> = this.actions$.pipe(
    ofType(ReindexActions.ActionTypes.CREATE_REINDEX),
    switchMap((action: any) => {
      return this.reindexService.createReindex(action.payload)
        .map((res) => new ReindexActions.CreateReindexCompletedAction(res))
        .catch( (err) => of(new ReindexActions.CreateReindexFailedAction(err)));
    })
  );

  @Effect()
  public hasBucketPermissions$: Observable<Action> = this.actions$.pipe(
    ofType(ReindexActions.ActionTypes.GET_BUCKET_PERMISSIONS),
    switchMap((action: any) => {
      return this.reindexService.hasBucketPermissions()
        .map((res) => new ReindexActions.GetBucketPermissionsCompletedAction(res))
        .catch((err) => of(new ReindexActions.GetBucketPermissionsFailedAction(err)));
    })
  );

  @Effect()
  public retryReindex$: Observable<Action> = this.actions$.pipe(
    ofType(ReindexActions.ActionTypes.RETRY_REINDEX),
    switchMap((action: any) => {
      return this.reindexService.retryReindex(action.payload)
        .map((res) => new ReindexActions.RetryReindexCompeleted(res))
        .catch((err) => of(new ReindexActions.RetryReindexFailed((err))));
    })
  );

  @Effect()
  public createCachedQuery: Observable<Action> = this.actions$.pipe(
    ofType(ReindexActions.ActionTypes.CREATE_CACHED_QUERY),
    switchMap((action: any) => {
      return this.reindexService.createCachedQuery(action.payload)
        .map((res) => new ReindexActions.CreateCachedQueryCompleted(res))
        .catch((err) => of(new ReindexActions.CreateCachedQueryFailed((err))));
    })
  );

  @Effect({ dispatch: false })
  public navigateToLogs$ = this.actions$.pipe(
    ofType(ReindexActions.ActionTypes.CREATE_CACHED_QUERY_COMPLETED),
    tap((action: any) => {
        const navigationExtras: NavigationExtras = {
          queryParams: { id: action.payload.queryId },
        };
        this.router.navigate(['/query-new/logs'], navigationExtras);
    })
  );
  constructor(private actions$: Actions, private reindexService: ReindexService, private router: Router) {}
}
