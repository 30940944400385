import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ERuleServerTypes } from '@app/rules/shared/models/rule.model';

@Component({
  selector: 'sh-small-rule-card',
  templateUrl: './small-rule-card.component.html',
  styleUrls: ['./small-rule-card.component.scss']
})
export class SmallRuleCardComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public buttonText: string;
  @Input() public type: ERuleServerTypes;
  @Output() public buttonClick: EventEmitter<ERuleServerTypes> = new EventEmitter<ERuleServerTypes>();
}
