import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
declare const $;
@Component({
    selector: 'sh-coralogix-tooltip',
    templateUrl: './coralogix-tooltip.component.html',
    styleUrls: ['./coralogix-tooltip.component.scss'],
})
export class CoralogixTooltipComponent implements AfterViewInit {
    @ViewChild('icon', { static: true })
    public icon: ElementRef;

    @Input()
    public position: string = 'right center';

    public ngAfterViewInit(): void {
        $(this.icon.nativeElement).popup({
            popup: '.special.popup',
            position: this.position,
            hoverable: true,
        });
    }

}
