<div class="input-container" [ngClass]="{'has-error' : hasValidationError, 'disable-input': disabled }">
    <div class="input-wrapper">
        <input  #timeInput
                class="time-input"
                type="number"
                [min]="minValue"
                [max]="maxValue"
                [ngModel]="formattedValue"
                (ngModelChange)="onModelChange($event)"
                (blur)="onBlur()"
                (click)="$event.target.select()"
                (focus)="$event.target.select()"
                (keyup)="onKeyUp($event)"
                [disabled]="disabled"
        >
    </div>
    <div class="switcher-container">
        <div class="switch-btn" shLongPress (onLongPressing)="increaseValue()" (click)="increaseValue()">
            <i class="fa fa-caret-up"></i>
        </div>
        <div class="switch-btn" shLongPress (click)="decreaseValue()" (onLongPressing)="decreaseValue()">
            <i class="fa fa-caret-down"></i>
        </div>
    </div>
</div>

