import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardinalityAlertComponent } from './cardinaly-alert/cardinality-alert.component';
import { SharedModule } from '@app/shared/shared.module';
import { CardinalityRoutingModule } from './cardinaly-alert/cardinality-alert.routes';
import { AlertViewLayoutModule } from '../alert-view-layout/alert-view-layout.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ChartModule } from 'angular-highcharts';

@NgModule({
  declarations: [CardinalityAlertComponent],
  imports: [
    CommonModule,
    CardinalityRoutingModule,
    SharedModule,
    AlertViewLayoutModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ScrollingModule,
    ClipboardModule,
    ChartModule
  ],

  exports: [CardinalityAlertComponent]
})
export class CardinalityAlertModule { }
