import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsEditorDetailsComponent } from './metrics-editor-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/shared/material/material.module';

@NgModule({
  declarations: [MetricsEditorDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [MetricsEditorDetailsComponent]
})
export class MetricsEditorDetailsModule { }
