import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class Teammate {
  @jsonMember
  public created_at: Date;
  @jsonMember
  public id: string;
  @jsonMember
  public isActive: boolean;
  @jsonMember
  public username: string;
  @jsonMember
  public firstName: string;
  @jsonMember
  public lastName: string;
  @jsonMember
  public user_account_id: number;
  @jsonMember
  public role: number = 2;
  @jsonMember
  public groupIds: {[groupId: string]: string};
  @jsonMember
  public companyId: number;
}

export enum AssignOrUnAssignEnum {
  assign = 'assign',
  unassign = 'unassign'
}
