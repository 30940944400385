import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Constants } from '@app/constants';
import { HttpClient } from '@angular/common/http';
import { IGroup } from '@app/settings/account/components/groups/models/models';

@Injectable({
  providedIn: 'root'
})
export class GroupsProxyService {

  constructor(private http: HttpClient) { }

  public fetchAllGroups(companyId: number): Observable<IGroup[]> {
    const url = Constants.getCompanyGroupsUrl(companyId);
    return this.http
      .get(url)
      .map((groups: IGroup[]) => groups);
  }

  public upsertGroup(group: IGroup): Observable<any> {
    const url = `${Constants.getCompanyGroupsUrl(group.companyId)}/${group.id}/group`;
    return this.http.put(url, {group}).map((arg: any) => arg);
  }

  public createGroup(group: IGroup): Observable<{groupId: number}> {
    const url = Constants.getCompanyGroupsUrl(group.companyId);
    return this.http.post(url, {groupName: group.name, description: group.description, roleId: group.roles?.[0]?.id, scope: group.scope}).map((res:{groupId: number}) => res);
  }

  public deleteGroup(companyId: number, groupId: number): Observable<{result: string}>{
    const url = `${Constants.getCompanyGroupsUrl(companyId)}/${groupId}`;
    return this.http.delete(url).map((res: {result: string}) => res);
  }
}
