import { Component, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-ng2';
import { IFilterParams, IAfterGuiAttachedParams, IDoesFilterPassParams, SetFilterParameters } from 'ag-grid';
import { IGridFilter } from './IGridFilter';
import { GridOptionsFilter } from './GridOptionsFilter';
import { MetadataService } from '../../../../logs/shared/services/metadata.service';
import { GridTextFilter } from './GridTextFilter';

@Component({
  selector: 'sh-master-filter',
  templateUrl: './master-filter.component.html',
  styleUrls: ['master-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterFilterComponent implements AgFilterComponent {
  public selectedFilter: IGridFilter;
  public isOptionsFilter: boolean;
  public gridOptionsFilter: GridOptionsFilter;
  public gridTextFilter: GridTextFilter;
  @ViewChild('optionsText') public optionsTextElm: ElementRef;
  @ViewChild('textText') public textTextElm: ElementRef;

  public colDef: any;
  public filter: string;

  private enableGrouping: boolean;

  constructor(private metadataService: MetadataService, private renderer: Renderer2, private changeDetectionRef: ChangeDetectorRef) {
    this.gridOptionsFilter = new GridOptionsFilter(this.metadataService, this.renderer, this.changeDetectionRef);
    this.gridTextFilter = new GridTextFilter(this.renderer);
  }

  public agInit(params: IFilterParams) {
    const filterParams: SetFilterParameters = params.colDef.filterParams;
    if (filterParams && filterParams.values) {
      this.isOptionsFilter = true;
      this.selectedFilter = this.gridOptionsFilter;

    } else {
      this.isOptionsFilter = false;
      this.selectedFilter = this.gridTextFilter;
    }
    this.selectedFilter.agInit(params);
    this.colDef = params.colDef;
    this.enableGrouping = this.colDef.enableGrouping && params.context.isGroupingSupported;
  }

  public afterGuiAttached(params: IAfterGuiAttachedParams) {
    if (this.isOptionsFilter) {
      this.selectedFilter.afterGuiAttached(params, this.optionsTextElm);
    } else {
      this.selectedFilter.afterGuiAttached(params, this.textTextElm);
    }
    this.changeDetectionRef.markForCheck();
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.selectedFilter.doesFilterPass(params);
  }

  public getModel() {
    return this.selectedFilter.getModel();
  }

  public setModel(model) {
    this.selectedFilter.setModel(model);
  }

  public isFilterActive(): boolean {
    return this.selectedFilter.isFilterActive();
  }

  public applyGroupBy() {
    const gridOptionsFilter: any = this.gridOptionsFilter;
    gridOptionsFilter.params.context.onHeaderClicked({ field: this.colDef.field, header: this.colDef.headerName });
    this.gridOptionsFilter.hidePopupCallback();
  }

  public applyFilter() {
    this.selectedFilter.applyFilter();
  }
}
