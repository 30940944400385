import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Widget, widgetsShowInOptions } from '@app/widgets/shared/models/widget';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs/Observable';
import { getApplicationNames, getSubsystemNames, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';

@Component({
  selector: 'sh-widget-add',
  templateUrl: 'widget-add.component.html',
  styleUrls: ['widget-add.component.scss'],
})
export class WidgetAddComponent {
  get inEditMode(): boolean {
    return !!this.widget && true;
  }
  public applicationOptions$: Observable<string[]>;
  public subsystemOptions$: Observable<string[]>;
  public newWidgetForm: FormGroup;
  public widget: Widget;
  public widgetsShowInOpt: typeof widgetsShowInOptions = widgetsShowInOptions;
  @Input() public url: string;

  @Output() public pinWidgetToDashboard: EventEmitter<
    Widget
  > = new EventEmitter<Widget>();
  @Output() public widgetEdited: EventEmitter<Widget> = new EventEmitter<
    Widget
  >();
  public title: string;
  public timeSelectionOptions: any[] = [
    { id: 23, name: 'Last 24 hours' },
    { id: 11, name: 'Last 12 hours' },
    { id: 5, name: 'Last 6 hours' },
    { id: 1, name: 'Last hour' },
  ];
  public jsonQueryModel: LogQueryModel;
  public dashboard: boolean = true;
  public tags: boolean = true;
  private queryModel: string;

  constructor(
    public dialogRef: MatDialogRef<WidgetAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>
  ) {
    this.newWidgetForm = new FormGroup({
      dashboard: new FormControl(this.dashboard),
      tags: new FormControl(this.tags),
      isShared: new FormControl(true),
      title: new FormControl('', Validators.required),
      description: new FormControl(''),
      timeSelection: new FormControl([this.timeSelectionOptions[0]]),
      applications: new FormControl(''),
      subsystems: new FormControl(''),
      showIn: new FormControl(widgetsShowInOptions.dashboard)
    });

    if (data) {
      this.showModal(data.query, data.widget);
      this.url = data.url ? data.url : null;
    }
    this.applicationOptions$ = this.store.select(getApplicationNames);
    this.subsystemOptions$ = this.store.select(getSubsystemNames);
  }

  public showModal(query: any, widget?: Widget): void {
    this.jsonQueryModel = Object.assign({}, query);
    this.widget = widget;

    if (this.inEditMode) {
      this.title = 'Edit Widget';
      const timeSelection = this.timeSelectionOptions.find(
        (item) => item.id === this.widget.amount,
      );
      this.jsonQueryModel = Object.assign({}, JSON.parse(this.widget.query));
      const { applicationName, subsystemName } = this.jsonQueryModel.queryParams.metadata;
      const isTags = this.widget.group.includes(widgetsShowInOptions.tagsPage);
      this.newWidgetForm = new FormGroup({
        title: new FormControl(this.widget.title, Validators.required),
        description: new FormControl(this.widget.description),
        timeSelection: new FormControl([timeSelection]),
        applications: new FormControl(applicationName),
        subsystems: new FormControl(subsystemName),
        showIn: new FormControl(isTags ? widgetsShowInOptions.tagsPage : widgetsShowInOptions.dashboard)
      });

    } else {
      this.title = 'Add Widget';
      this.queryModel = JSON.stringify(query);
    }
  }

  public onSubmitQuery(): void {
    const model: Widget = this.widget ? this.updadeModel() : this.getModel();
    this.widget
      ? this.widgetEdited.emit(model)
      : this.pinWidgetToDashboard.emit(model);
    this.dialogRef.close(model);
  }

  private getModel(): Widget {
    const widget: Widget = new Widget();
    widget.query = JSON.stringify(this.jsonQueryModel);
    widget.url = this.widget ? widget.url : this.url;
    widget.title = this.newWidgetForm.value.title;
    widget.description = this.newWidgetForm.value.description;
    widget.amount = this.newWidgetForm.value.timeSelection[0]
      ? this.newWidgetForm.value.timeSelection[0].id
      : this.newWidgetForm.value.timeSelection.id;
    widget.isShared = this.newWidgetForm.value.isShared;
    widget.timeInterval = this.getInterval(widget.amount);

    widget.group = this.newWidgetForm.value.showIn;
    return widget;
  }

  private updadeModel(): Widget {
    this.widget.title = this.newWidgetForm.value.title;
    this.widget.description = this.newWidgetForm.value.description;
    this.widget.amount = this.newWidgetForm.value.timeSelection[0]
      ? this.newWidgetForm.value.timeSelection[0].id
      : this.newWidgetForm.value.timeSelection.id;
    this.widget.timeInterval = this.getInterval(this.widget.amount);
    this.widget.query = JSON.stringify(this.jsonQueryModel);
    this.widget.group = this.newWidgetForm.value.showIn;
    return this.widget;
  }
  private getInterval(value: number): number {
    if (value <= 11) {
      return 60 * 1000;
    }

    return 3600 * 1000;
  }
}
