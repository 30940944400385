import { LogqueryResult } from './logquery.response';
import { LogQueryModel } from './logsquery.model';
import { LogsService } from '../services/logs-service';

export class LogQueryData {
  public logQueryResult: LogqueryResult;
  public logQueryModel: LogQueryModel;
  public logsService: LogsService;
  public startFromIndex: number;
  public highlightedLogs: Map<string, string> = new Map<string, string>();

  constructor(querymodel: LogQueryModel, queryRresult: LogqueryResult, logsService: LogsService) {
    this.logQueryModel = querymodel;
    this.logQueryResult = queryRresult;
    this.logsService = logsService;
  }
}

export type viewType = 'logs' | 'loggregation' | 'archive-logs';
