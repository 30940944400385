import { MetricsActions } from '@app/settings/metrices-settings/state/actions/metrics.actions';
import { Metric } from '@app/settings/metrices-settings/models/metric.model';
import { ERROR_STATUSES } from '@app/settings/metrices-settings/models/metric.constants';
import * as _ from 'lodash';

export namespace MetricsReducer {

  export interface State {
    metrics: Metric[];
    loading: boolean;
    error: string;
    errorStatus: number;
  }

  const initialState: State = {
    metrics: [],
    loading: false,
    error: null,
    errorStatus: -1
  };

  export function reducer(state: MetricsReducer.State = initialState, action: MetricsActions.Actions): MetricsReducer.State {
    switch (action.type) {
      case MetricsActions.ActionTypes.GET_METRICS:
        return { ...state, ...{ loading: true, error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.GET_METRICS_COMPLETED:
        return { ...state, ...{ metrics: action.payload }, ...{ loading: false, error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.CREATE_METRICS:
        return { ...state, ...{ loading: true, error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.CREATE_METRICS_COMPLETED:
        return { ...state, ...{ metrics: [...state.metrics, action.payload] }, ...{ loading: false, error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.DELETE_METRICS:
        return { ...state, ...{ loading: true , error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.DELETE_METRICS_COMPLETED:
        const deletedMetricIdx = state.metrics.findIndex(m => m.id === action.payload.metricId);
        if (deletedMetricIdx > -1) {
          return { ...state, ...{ loading: false , error: null, errorStatus: -1 },
            ...{ metrics: [...state.metrics.slice(0, deletedMetricIdx), ...state.metrics.slice(deletedMetricIdx + 1, state.metrics.length)] }};
        }
        return { ...state, ...{ loading: false , error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.EDIT_METRICS:
        return { ...state, ...{ loading: true , error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.EDIT_METRICS_COMPLETED:
        const editedMetricIdx = state.metrics.findIndex(m => m.id === action.payload.id);
        if (editedMetricIdx > -1) {
          return { ...state, ...{ loading: false, error: null, errorStatus: -1 },
            ...{ metrics: [...state.metrics.slice(0, editedMetricIdx), action.payload, ...state.metrics.slice(editedMetricIdx + 1, state.metrics.length)] }};
        }
        return { ...state, ...{ loading: false, error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.CREATE_METRICS_FAILED:
      case MetricsActions.ActionTypes.EDIT_METRICS_FAILED:
        const error: string = _.get(action, 'payload.error', null);
        const errorStatus: number = _.get(action, 'payload.status', 500);
        if (errorStatus === ERROR_STATUSES.LUCENE_VALIDATOR_CODE ||
          errorStatus === ERROR_STATUSES.DB_CONFLICT_ERROR_CODE) {
          return { ...state, ...{ loading: false, error, errorStatus } };
        }
        return { ...state, ...{ loading: false, error: null, errorStatus: -1 } };
      case MetricsActions.ActionTypes.DELETE_METRICS_FAILED:
        return { ...state, ...{ loading: false, error: null, errorStatus: -1 } };
      default:
        return state;
    }
  }
}
