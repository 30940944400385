<div class="ui modal" modal-directive>
  <div class="header">
    Edit anomaly
    <i class="icon remove" (click)="cancelChanges()"></i>
  </div>
  <div class="modal-content">
    <form
      class="ui form"
      [formGroup]="newFormGroup"
      (submit)="onSubmitChanges()"
    >
      <div class="field">
        <label>Name</label>
        <input type="text" placeholder="Anomaly name" formControlName="name" />
      </div>
      <div class="field">
        <label>Severity</label>
        <sh-select
          placeholder="Insight Severity"
          [mode]="inline"
          class="inline"
          formControlName="severity"
          [options]="insightSeverityOptions"
          [isMultiselect]="false"
          [showClear]="false"
        ></sh-select>
      </div>
      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" formControlName="enabled" />
          <label>Active</label>
        </div>
      </div>
      <button
        class="ui inverted blue button"
        type="submit"
        [disabled]="!newFormGroup.valid"
      >
        Submit
      </button>
      <button
        class="ui inverted pink button"
        type="button"
        (click)="cancelChanges()"
      >
        Cancel
      </button>
    </form>
  </div>
</div>
