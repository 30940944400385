<div class="grid-size-noTimeline">
  <sh-loggregation-grid
    [queryModel]="queryModel"
    [highlightedTemplates]="highlightedTemplates"
    [data]="data"
    (selectedItemChanged)="onSelectedItemChanged($event)"
    (textInfoClicked)="onGridButtonClick($event)"
    (onItemsCountChanged)="onItemsCountChanged($event)"
  ></sh-loggregation-grid>
</div>
