import { ILogsField } from '@app/logs-new/shared/models/logs-columns.model';
import { LogsFieldType } from '@app/logs-new/shared/models/graph/logs-graph.model';

export const externalLogFields: ILogsField[] = [
  {
    colId: 'coralogix.metadata.companyId',
    headerName: 'Team',
    type: LogsFieldType.Log,
  },
];
