<div class="permissions-info-container">
    <img src="../../../../../assets/roi/icon-info.svg" class="infoImg">
    <div>
      <span class="permissions-info-headline">Setup S3 Access Permissions</span>
      <ol>
        <li>Edit your AWS S3 Bucket Permissions and under 'Access for other AWS accounts'</li>
        <li>Click the 'Add Account' button</li>
        <li style="margin-bottom: 4px;">Insert the Coralogix AWS Account Id:</li>
        <div class="permissions-info-copy">
          <input type="text" value="fa35ef450b07d311b09810445df9c1c4a316118d1899e4cd3db935414e4ba62d" disabled="disabled">
          <button mat-raised-button type="button" class="copy-btn" (click)="copyCanonicalId()">COPY</button >
        </div>
        <li>Check the 'List objects' check box - to give Coralogix read permissions</li>
        <li>Check the 'Write objects' check box  - to give Coralogix write permissions</li>
        <li>Check the 'Read bucket permissions' check box</li>
        <li>Click on the 'Block public access' button and then on the 'Edit' button</li>
      </ol>

      <a href="https://coralogix.com/tutorials/archive-s3-bucket-forever/" target="_blank">Learn More</a>
    </div>
</div>
