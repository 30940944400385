import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ArchivingProviderModel } from '@app/settings/shared/models/archivingProviderModel';
import { ArchivingProvidersReducer } from '@app/settings/reducer/archiving-providers/archiving-providers.reducer';
import TestingResultState = ArchivingProvidersReducer.TestingResultState;

@Component({
  selector: 'sh-reindex-editor-archive',
  templateUrl: './reindex-editor-archive.component.html',
  styleUrls: ['./reindex-editor-archive.component.scss']
})
export class ReindexEditorArchiveComponent {
  @Input()
  public form: FormGroup;
  @Input()
  public regionOptions: {id: string, name: string}[];
  @Input() public testing: boolean;
  @Input() public testingResult: TestingResultState;
  @Output()
  public testArchivingProvider: EventEmitter<ArchivingProviderModel> = new EventEmitter<ArchivingProviderModel>();

  public hasError(controlName: string, errorCode: string): boolean {
    const control = this.form?.get(controlName);
    return control?.hasError(errorCode) && control?.touched;
  }

  public verifyBucketSettings(): void {
    this.testArchivingProvider.emit({
      id: 0,
      provider: 'S3',
      region: this.form.get('region').value,
      name: this.form.get('bucket').value,
      private_key: null,
      active: true});
  }

  public get isTestingResultMessage(): string {
    return this.testingResult?.testingMessage;
  }

  public get testSuccess(): boolean {
    return this.testingResult?.testingSuccess;
  }

  public get verifyImage(): string {
    if (this.testingResult?.testingSuccess) {
      return '../../../../../assets/roi/icon-connection-success.svg';
    }
    return '../../../../../assets/roi/icon-connection-error.svg';
  }

  public get testingResultMessage(): string {
    if (this.testingResult?.testingSuccess) {
      return 'Successfully connected to your S3 bucket';
    }
    return 'Failed to connect to your S3 bucket. Please check your configuration and try again';
  }

  public get formValid(): boolean {
    return this.form.valid;
  }
}
