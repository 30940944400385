import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { AnalyticEventService } from '../../../user/shared/AnalyticEventService';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetTeammate } from '@app/ngxs-store/user/user.action';
import { Observable, Subject } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Teammate } from '@app/shared/models/teammate.model';

@Component({
  selector: 'sh-team-sso',
  templateUrl: './team-sso.component.html',
  styleUrls: ['./team-sso.component.scss'],
})
export class TeamSsoComponent implements OnInit, OnDestroy {
  @Select(UserState.user) public user$: Observable<Teammate>;

  public isNewTeam: boolean = false;

  public tempToken: string;

  public teamId: number;

  public userName: string;

  public destroyed$: Subject<void> = new Subject();

  constructor(route: ActivatedRoute, private router: Router, private authService: AuthService, private ea: AnalyticEventService) {
    const { token: tempToken, team_id: teamId, is_new: isNewTeam, user_name: userName } = route.snapshot.params;
    this.tempToken = tempToken;
    this.teamId = teamId;
    this.isNewTeam = isNewTeam === 'true' ? true : false;
    this.userName = userName;
  }

  public ngOnInit(): void {
    const teamUrl = window.location.hostname.split('.')[0];
    this.authService
      .getPermanentTokenFromTemp({ token: this.tempToken, teamUrl: teamUrl, userName: this.userName })
      .pipe(
        switchMap(token => {
          this.authService.storeUser(token, '', 'coralogix');
          this.authService.selectTeamId(this.teamId.toString());
          this.getTeammate();
          return this.user$;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(user => {
        if (user) {
          this.authService.setUserName(user.username);
          const subRoute = this.isNewTeam ? '/settings/send-your-logs' : '/dashboard';

          if (this.isNewTeam) {
            this.ea.dataLayerReport({
              event: 'signup',
              step: '4',
            });
          }
          setTimeout(() => {
            this.router.navigate([subRoute]);
          }, 0);
        }
      });
    // setTimeout(()=>{
    //   this.router.navigate(['/dashboard']);
    // },0);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @Dispatch() public getTeammate = () => new GetTeammate();
}
