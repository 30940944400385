import {
  CapabilityTypes,
  IRegistry,
  IUrlsPostFix,
  urlsPostfixes,
  Capabilities, ResetFields,
} from '@app/logs-new/shared/abilities-state-registry/capabilities-model';

export class TemplatesRegistry implements IRegistry {
  fieldsToReset: ResetFields = new ResetFields(false, false, false);
  urlsPostFix: Partial<IUrlsPostFix> = {
    ...urlsPostfixes
  };
  private capabilities: Capabilities = new Capabilities(
    false,
    true,
    true,
    false,
    true,
    true,
    true,
    false,
    true,
    false,
    true,
    false,
    false,
    true,
    true,
    true,
    true,
    false,
    true);

  isEnabled(cap: CapabilityTypes): boolean {
    return this.capabilities[cap];
  }
}
