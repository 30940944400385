import { Injectable } from '@angular/core';

@Injectable()
export class AlertSearchQueryParserService {
  public parseAlert(jsonObject: any, text: string): string {
    const isObjectEmpty = Object.entries(jsonObject).length === 0;
    if (!text && isObjectEmpty) {
      return '';
    }
    let isFirstItem = true;
    let query = '';
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        let value = jsonObject[key];
        value = Array.isArray(value) && value.length > 0 ? this.parseArray(value) : null;
        if (value) {
          const queryParam = `${key}:${value}`;
          const and = isFirstItem ? '' : ' AND ';
          query = query.concat(and, queryParam);
          isFirstItem = false;
        }
      }
    }
    if (text && query === '') {
      query = `${query}${text.trim()}`;
      return query;
    }

    if (text) {
      query = `(${query}) AND (${text.trim()})`;
    }

    return query;
  }

  private parseArray(array: any[]): string {
    let isFirstItem = true;
    let queryString = '';
    if (array.length > 1) {
      array.forEach((item) => {
        if (typeof item === 'string') {
          item = `"${item}"`;
        }
        const or = isFirstItem ? '(' : ' OR ';
        queryString = queryString.concat(or, item);
        isFirstItem = false;
      });
      return queryString + ')';
    }
    if (typeof array[0] === 'string') {
      return `"${array[0]}"`;
    }
    return array[0];
  }
}
