<h1 class="s3-title">S3 Bucket Settings</h1>
<mat-card class="white-theme">
  <mat-card-content>
    <div *ngIf="!loading">
      <form [formGroup]="archivingForm" (ngSubmit)="submit()">
        <div style="display: flex">
          <div class="input-container">
            <cgx-checkbox
              label="Activate Archiving Storage"
              labelPosition="after"
              size="md"
              formControlName="active"
              (change)="change($event)"
            ></cgx-checkbox>
          </div>
        </div>
        <div style="display: flex">
          <div class="input-container">
            <mat-form-field class="mat-block">
              <mat-label>Region</mat-label>
              <mat-select placeholder="Region" formControlName="region">
                <div *ngFor="let region of regionOptions">
                  <mat-option value="{{ region.id }}">
                    {{ region.name }}
                  </mat-option>
                </div>
              </mat-select>
              <mat-error style="width: 100%" *ngIf="getIsFieldInvalid('region')">
                Region is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field class="mat-block in-row" style="width: 100%;">
              <input matInput placeholder="Bucket name" formControlName="name" />
              <mat-error style="width: 100%" *ngIf="getIsFieldInvalid('name')">
                Name is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>

  <div *ngIf="!loading" style="display: flex;min-height: 70px;max-height: 70px;flex-direction: column">
    <div class="input-container test-container">
      <div *ngIf="testing" style="display: -webkit-inline-box">
        <div class="testing-caption">
          Testing
        </div>
        <div class="dimmer-container" style="height: 25px; width:50px;margin-left: 10px">
          <div class="ui active inverted dimmer widget-overlay">
            <div class="ui loader"></div>
          </div>
        </div>
      </div>
      <span *ngIf="testingResult && testingResult.testingMessage" class="testing-caption">
        <span>
          Testing Results:
        </span>
        <span *ngIf="testingResult && testingResult.testingSuccess" class="testing-caption success">
          {{ testingResult?.testingMessage }}
        </span>
        <span *ngIf="testingResult && !testingResult.testingSuccess" class="testing-caption">
          (AWS)
        </span>
        <span *ngIf="testingResult && !testingResult.testingSuccess" class="testing-caption failed">
          {{ testingResult?.testingMessage }}
        </span>
      </span>
    </div>
    <div style="flex: 1;">
      <mat-card-actions style="display: flex; margin-top: -3px;">
        <cgx-button
          style="margin-inline-end: 8px"
          label="Save"
          size="sm"
          width=""
          [disabled]="!archivingForm.valid || saving"
          (press)="submit()"
        ></cgx-button>
        <cgx-button
          label="Validate Settings"
          size="sm"
          [flat]="true"
          [disabled]="!archivingForm.valid || saving || testing"
          (press)="test()"
        ></cgx-button>
      </mat-card-actions>
    </div>
  </div>
  <div *ngIf="loading" class="container" style="min-height: 195px;">
    <sh-preview-placeholder></sh-preview-placeholder>
  </div>
</mat-card>
