import { type } from '../../../../shared/services/utils';
import { Action } from '@ngrx/store';
import { Enrichment } from '../../../enrichments-settings/enrichment.model';
import {
  ChangeEnrichmentResponse,
  CustomEnrichmentFormResult,
  CustomEnrichmentsResponse,
} from '@app/settings/enrichments-settings/custom-enrichment.model';

/* tslint:disable-next-line:no-namespace */
export namespace EnrichmentsActions {
  export const ActionTypes = {
    GET_ENRICHMENTS: type('[Enrichments] Get Enrichments'),
    GET_ENRICHMENTS_COMPLETED: type('[Enrichments] Get Completed'),
    GET_ENRICHMENTS_FAILED: type('[Enrichments] Get FAILED'),
    DELETE_ENRICHMENTS: type('[Enrichments] Delete Enrichments'),
    DELETE_ENRICHMENTS_COMPLETED: type('[Enrichments] Delete Completed'),
    DELETE_ENRICHMENTS_FAILED: type('[Enrichments] Delete FAILED'),
    CREATE_ENRICHMENTS: type('[Enrichments] Create Enrichments'),
    CREATE_ENRICHMENTS_COMPLETED: type('[Enrichments] Create Completed'),
    CREATE_ENRICHMENTS_FAILED: type('[Enrichments] Create FAILED'),
    GET_CUSTOM_ENRICHMENTS: type('[Enrichments] Get Custom Enrichments'),
    GET_CUSTOM_ENRICHMENTS_COMPLETED: type('[Enrichments] Get Custom Enrichments Completed'),
    GET_CUSTOM_ENRICHMENTS_FAILED: type('[Enrichments] Get Custom Enrichments FAILED'),
    CREATE_CUSTOM_ENRICHMENT: type('[Enrichments] create custom enrichment'),
    CREATE_CUSTOM_ENRICHMENT_COMPLETED: type('[Enrichments] create custom enrichment Completed'),
    CREATE_CUSTOM_ENRICHMENT_FAILED: type('[Enrichments] create custom enrichment FAILED'),
    UPDATE_CUSTOM_ENRICHMENT: type('[Enrichments] update custom enrichment'),
    UPDATE_CUSTOM_ENRICHMENT_COMPLETED: type('[Enrichments] update custom enrichment Completed'),
    UPDATE_CUSTOM_ENRICHMENT_FAILED: type('[Enrichments] update custom enrichment FAILED'),
    DELETE_CUSTOM_ENRICHMENT: type('[Enrichments] delete custom enrichment'),
    DELETE_CUSTOM_ENRICHMENT_COMPLETED: type('[Enrichments] delete custom enrichment Completed'),
    DELETE_CUSTOM_ENRICHMENT_FAILED: type('[Enrichments] delete custom enrichment FAILED'),
    CUSTOM_ENRICHMENTS_LOADING: type('[Enrichments] custom enrichment loading'),
  };

  export class GetEnrichmentsAction implements Action {
    public type: string = ActionTypes.GET_ENRICHMENTS;

    constructor(public payload?: any) {
    }
  }

  export class GetEnrichmentsCompletedAction implements Action {
    public type: string = ActionTypes.GET_ENRICHMENTS_COMPLETED;

    constructor(public payload: Enrichment[]) {
    }
  }

  export class GetEnrichmentsFailedAction implements Action {
    public type: string = ActionTypes.GET_ENRICHMENTS_FAILED;

    constructor(public payload: any) {
    }
  }

  export class DeleteEnrichmentsAction implements Action {
    public type: string = ActionTypes.DELETE_ENRICHMENTS;

    constructor(public payload: any) {
    }
  }

  export class DeleteEnrichmentsCompletedAction implements Action {
    public type: string = ActionTypes.DELETE_ENRICHMENTS_COMPLETED;

    constructor(public payload: Enrichment[]) {
    }
  }

  export class DeleteEnrichmentsFailedAction implements Action {
    public type: string = ActionTypes.DELETE_ENRICHMENTS_FAILED;

    constructor(public payload: any) {
    }
  }

  export class CreateEnrichmentsAction implements Action {
    public type: string = ActionTypes.CREATE_ENRICHMENTS;

    constructor(public payload: any) {
    }
  }

  export class CreateEnrichmentsCompletedAction implements Action {
    public type: string = ActionTypes.CREATE_ENRICHMENTS_COMPLETED;

    constructor(public payload: Enrichment[]) {
    }
  }

  export class CreateEnrichmentsFailedAction implements Action {
    public type: string = ActionTypes.CREATE_ENRICHMENTS_FAILED;

    constructor(public payload: any) {
    }
  }

  export class GetCustomEnrichmentsAction implements Action {
    public type: string = ActionTypes.GET_CUSTOM_ENRICHMENTS;

    constructor(public payload?: any) {
    }
  }

  export class GetCustomEnrichmentsCompletedAction implements Action {
    public type: string = ActionTypes.GET_CUSTOM_ENRICHMENTS_COMPLETED;

    constructor(public payload: CustomEnrichmentsResponse) {
    }
  }

  export class GetCustomEnrichmentsFailedAction implements Action {
    public type: string = ActionTypes.GET_CUSTOM_ENRICHMENTS_FAILED;

    constructor(public payload: any) {
    }
  }

  export class CreateCustomEnrichmentAction implements Action {
    public type: string = ActionTypes.CREATE_CUSTOM_ENRICHMENT;

    constructor(public payload?: CustomEnrichmentFormResult) {
    }
  }

  export class CreateCustomEnrichmentCompletedAction implements Action {
    public type: string = ActionTypes.CREATE_CUSTOM_ENRICHMENT_COMPLETED;

    constructor(public payload: ChangeEnrichmentResponse) {
    }
  }

  export class CreateCustomEnrichmentFailedAction implements Action {
    public type: string = ActionTypes.CREATE_CUSTOM_ENRICHMENT_FAILED;

    constructor(public payload: any) {
    }
  }

  export class UpdateCustomEnrichmentAction implements Action {
    public type: string = ActionTypes.UPDATE_CUSTOM_ENRICHMENT;

    constructor(public payload?: any) {
    }
  }

  export class UpdateCustomEnrichmentCompletedAction implements Action {
    public type: string = ActionTypes.UPDATE_CUSTOM_ENRICHMENT_COMPLETED;

    constructor(public payload: ChangeEnrichmentResponse) {
    }
  }

  export class UpdateCustomEnrichmentFailedAction implements Action {
    public type: string = ActionTypes.UPDATE_CUSTOM_ENRICHMENT_FAILED;

    constructor(public payload: any) {
    }
  }

  export class DeleteCustomEnrichmentAction implements Action {
    public type: string = ActionTypes.DELETE_CUSTOM_ENRICHMENT;

    constructor(public payload?: number) {
    }
  }

  export class DeleteCustomEnrichmentCompletedAction implements Action {
    public type: string = ActionTypes.DELETE_CUSTOM_ENRICHMENT_COMPLETED;

    constructor(public payload: ChangeEnrichmentResponse) {
    }
  }

  export class DeleteCustomEnrichmentFailedAction implements Action {
    public type: string = ActionTypes.DELETE_CUSTOM_ENRICHMENT_FAILED;

    constructor(public payload: any) {
    }
  }

  export class CustomEnrichmentsLoadingAction implements Action {
    public type: string = ActionTypes.CUSTOM_ENRICHMENTS_LOADING;

    constructor(public payload: boolean) {
    }
  }

  export type Actions =
    | GetEnrichmentsAction
    | GetEnrichmentsCompletedAction
    | GetEnrichmentsFailedAction
    | DeleteEnrichmentsAction
    | DeleteEnrichmentsCompletedAction
    | DeleteEnrichmentsFailedAction
    | CreateEnrichmentsAction
    | CreateEnrichmentsCompletedAction
    | CreateEnrichmentsFailedAction
    | GetCustomEnrichmentsAction
    | GetCustomEnrichmentsCompletedAction
    | GetCustomEnrichmentsFailedAction
    | CreateCustomEnrichmentAction
    | CreateCustomEnrichmentCompletedAction
    | CreateCustomEnrichmentFailedAction
    | UpdateCustomEnrichmentAction
    | UpdateCustomEnrichmentCompletedAction
    | UpdateCustomEnrichmentFailedAction
    | DeleteCustomEnrichmentAction
    | DeleteCustomEnrichmentCompletedAction
    | DeleteCustomEnrichmentFailedAction
    | CustomEnrichmentsLoadingAction;

}
