<div #Container></div>
<div class="ui modal sh-modal" #modal>
  <div class="header title">
    <i class="icon remove" (click)="hideModal()"></i>
    {{ chartType }}
    <div class="floating-btn-tl" (click)="addWidget(parameterQueryModel)">
      <i class="pin icon"></i>
    </div>
  </div>
  <div style="position: relative">
    <div class="overlay" [(hidden)]="!isChartOverlayVisible">
      <div class="ui active inverted dimmer">
        <div class="ui loader" style=""></div>
      </div>
    </div>
    <sh-loggregation-free-chart
      [data]="parameterData"
    ></sh-loggregation-free-chart>
  </div>
</div>
