<mat-card class="coralogix card">
  <p class="permutation-card-subheader">Advance Permutation Settings</p>
  <p class="permutation-card-subheader">
    Choose the amount of permutations for this metric. Up to
    {{ logs2MetricsPermutationLimit$ | async | number: '1.0':'en-US' }} permutations per account.
  </p>
  <p class="permutation-card-subheader" *ngIf="isInEditMode">
    * Updates will come into effect at the end of each day (00:00 UTC)
  </p>

  <div class="permutation-settings coralogix input-group">
    <input
      [formControl]="permutationsLimitControl"
      class="form-control"
      type="number"
      min="0"
      [max]="permutationsLeft$ | async"
    />

    <span class="subtitle">Permutations amount&nbsp;</span>
    <span class="permutation-left">
      / {{ permutationsLeft$ | async | number: '1.0':'en-US' }} Permutation units left
    </span>
  </div>

  <div class="err-message" *ngIf="permutationsLimitControl.hasError('lessThanOne')">
    Permutation amount needs to be bigger than 0 when labels are set
  </div>
  <div class="err-message" *ngIf="permutationsLimitControl.hasError('permutationAmountExceeded')">
    Permutation amount exceeded
  </div>
</mat-card>
