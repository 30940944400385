import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AlertsEditorSelectOptions } from '@app/alerts/alerts-editor/models/alerts-select-options.model';
import { AlertEditorComponentNames, AlertTypeViewsDefs } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { AlertSyntaxType, UserAlertViewTypes } from '@app/alerts/alerts-editor/models/alert-editor-view.models';

@Component({
  selector: 'sh-alerts-query-container',
  templateUrl: './alerts-query-container.component.html',
  styleUrls: ['./alerts-query-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsQueryContainerComponent {
  @Input() isFormInvalid: boolean;
  @Input() invalidErrMsg: string;
  @Input() relativeAlertsArray: FormArray;
  @Input() form: FormGroup;
  @Input() queryGroup: FormGroup;
  @Input() selectOptions: AlertsEditorSelectOptions;
  @Input() isFromQuery: boolean;
  @Input() relativeAlertSelected: boolean;
  @Input() metricAlertSelected: boolean;
  @Input() relativeCalcStr: string;
  @Input() alertType: UserAlertViewTypes;
  @Input() conditionsGroup: FormGroup;
  componentTypes: typeof AlertEditorComponentNames = AlertEditorComponentNames;
  alertTypeViewsDefs: typeof AlertTypeViewsDefs = AlertTypeViewsDefs;

  get isPromQLSyntax(): boolean {
    return this.queryGroup.get('alertSyntaxType').value === AlertSyntaxType.PromQL;
  }

  get selectedAlertSyntaxType(): FormControl {
    return this.queryGroup.get('alertSyntaxType') as FormControl;
  }

  onTabChange(selectedIndex: number): void {
    this.selectedAlertSyntaxType.setValue(selectedIndex);
    this.queryGroup.get('alertText').setValue('');
  }
}
