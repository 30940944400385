import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterPipe', pure: true })
export class FilterPipe implements PipeTransform {
  public transform(value: any[], args1: any, args2: any = null): any {
    if (!value) {
      return null;
    }

    const field = args2 && args2.length > 1 ? args2 : null;
    const filter = args1 && args1.length > 0 ? args1.toLocaleLowerCase() : null;

    if (field) {
      return value.filter((item) => {
        if (field instanceof Array) {
          let ret = false;
          for (const entry of field) {
            ret = !filter || item[entry].toLowerCase().indexOf(filter) !== -1;
            if (ret) {
              return true;
            }
          }
          return false;
        } else if (item && item[field]) {
          return !filter || item[field].toLowerCase().indexOf(filter) !== -1;
        } else if (item && item.length > 0) {
          return !filter || item.toLowerCase().indexOf(filter) !== -1;
        } else {
          return true;
        }
      });
    }

    return value.filter((item) => {
      return !filter || item.toLowerCase().indexOf(filter) !== -1;
    });
  }
}
