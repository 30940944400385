import { IJsonElements, ILatestClickedElement, LogsContextMenuType } from '@shared/modules/json-formatter/json-formatter.model';
import urlRegex from 'url-regex';
import { isValidJSONString } from '@shared/utils/utils';
import { isValidNumericalDateString } from '@app/logs-new/shared/utils/time.utils';
import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';
import isObject from 'lodash-es/isObject';

export const JSON_FORMATTER_VALUE_CLASS = jsonFormatterCssClass('value');
export const JSON_FORMATTER_HIGHLIGHTED_CLASS = jsonFormatterCssClass('highlighted');
export const JSON_FORMATTER_KEY_CLASS = jsonFormatterCssClass('key');
export const JSON_FORMATTER_CONTAINER_CLASS = jsonFormatterCssClass('container');
export const JSON_FORMATTER_TEMPLATE_CLASS = jsonFormatterCssClass('template');
export const JSON_FORMATTER_ACTIVE_CLASS = jsonFormatterCssClass('active');
export const JSON_FORMATTER_OBJECT_CLASS = jsonFormatterCssClass('object');
export const JSON_FORMATTER_ARRAY_CLASS = jsonFormatterCssClass('array');

export type jsonElementClass =
  | 'key'
  | 'value'
  | 'compact'
  | 'container'
  | 'row'
  | 'value-row'
  | 'object'
  | 'array'
  | 'curly-braces'
  | 'brackets'
  | 'active'
  | 'template'
  | 'highlighted'
  | 'with-menu'
  | 'colons';

/* A recursive function which gets the elements keys until reaching the container element, effectively
 * providing the full path of the first element */
const getParentsKeys = (element: HTMLElement, path: string = '') => {
  const isArrayEl = element?.classList.contains(JSON_FORMATTER_ARRAY_CLASS);
  const isObjEl = element?.classList.contains(JSON_FORMATTER_OBJECT_CLASS);
  if (isArrayEl || isObjEl) {
    const contentRow = getContentRow(element);
    const keyEl = contentRow.children[0];
    const isKey = keyEl.classList.contains(JSON_FORMATTER_KEY_CLASS);
    if (isKey) {
      const keyInnerText = contentRow.children[0]['innerText'].replace(/"/g, '') + '.';
      path = keyInnerText.concat(path);
    }
  }
  const isLastKey = element?.classList.contains('logs-json-formatter-container');
  if (isLastKey || !element) {
    return path;
  }
  return getParentsKeys(element?.parentElement, path);
};

/* Returns full JSON path, provide colId if you want to provide a key that's not included
 * in the formatted JSON. For example, if you have a grid and a "text" column that contains
 * an object, the json formatted is not aware of "text" and you'll need to provide it in order
 * to get the full path "text.x.y" */
export function getFullJSONPath(element: HTMLElement, colId?: string): string {
  const hasTextPrefixRegex = /^text$|^text\.|textObject\.?/g;
  colId = colId ? colId.replace(hasTextPrefixRegex, '') : null;
  const isKey = element.classList.contains(JSON_FORMATTER_KEY_CLASS);
  let key = '';
  if (isKey) {
    key = element.innerText;
  } else {
    const contentRow = getContentRow(element);
    const potentialKeyEl = contentRow.children[0];
    if (potentialKeyEl?.classList?.contains(JSON_FORMATTER_KEY_CLASS)) {
      key = potentialKeyEl['innerText'];
    }
  }
  const path =
    isArrEl(element) || isSingleValueEl(element)
      ? getParentsKeys(element).slice(0, -1) /* remove dot */
      : `${getParentsKeys(element)}${key}`;

  if (colId && path) {
    return `${colId}.${path}`;
  }

  return path || colId;
}

export function getTooltipElement(): Element {
  return document.querySelector('.cgx-tooltip');
}

export function removeTooltipElement(): void {
  const tooltipElement = getTooltipElement();
  if (tooltipElement) {
    tooltipElement.remove();
  }
}

export function getJsonFormattedElementType(element: ILatestClickedElement, selection: string): LogsContextMenuType {
  if (selection) return 'selection';

  element = getElement<ILatestClickedElement>(element);
  const isValue = element['classList'].contains(JSON_FORMATTER_VALUE_CLASS);
  return isValue ? 'value' : 'key';
}

const getArrElKey = el => el.parentElement.parentElement.parentElement.children[0];

export const isArrEl = el => {
  const contentRow = getContentRow(el);
  return contentRow.children.length === 1 && !contentRow.classList.contains(JSON_FORMATTER_CONTAINER_CLASS);
};

export const isSingleValueEl = el => {
  const contentRow = getContentRow(el);
  return contentRow.classList.contains(JSON_FORMATTER_CONTAINER_CLASS);
};

/* This function accepts and element and adds relevant data to it:
 * 1. rawField - The element passed, unescaped value
 * 2. selectedField - The element passed, parsed
 * 3. keyField - the key of current element (can be the same as selectedField)
 * 4. valueField - the value of current element (can be the same as selectedField)
 * Sometimes a user selects part of an element, this is why this function accepts
 * a selectionVal optional argument, in this case the rawField and selectedField will
 * be the selection value, while the key field and value field will remain unaffected.
 *  */
export function setJsonFormattedElementData(element: ILatestClickedElement, params: any, selectionVal?: string): ILatestClickedElement {
  const latestClickedElement = getElement<ILatestClickedElement>(element);
  setElementData(latestClickedElement, params, selectionVal);
  setElementKeyValueData(latestClickedElement);
  return latestClickedElement;
}

export function jsonFormatterCssClass(className: jsonElementClass): string {
  return `logs-json-formatter-${className}`;
}

/* An element must be either a key or a value, but sometimes the element is highlighted
 * and therefore it's inside html <mark>. This functions check if it's highlighted, if so
 * it returns its parent, if not the element itself. This way we are making sure to always return
 * either a key element or a value element */
function getElement<T>(element: T): T {
  const isHighlightedPart = element['classList'].contains(JSON_FORMATTER_HIGHLIGHTED_CLASS);
  if (isHighlightedPart) {
    return element['parentElement'];
  }
  return element;
}

function setElementData(element: ILatestClickedElement, params: any, selectionVal: string): void {
  const textValue = selectionVal || element.innerText;
  element.rawField = textValue;
  element.selectedField = textValue.replace(/"/g, '');
  element.isSingleValue = isSingleValueEl(element);
  element.isValidJSON = isValidJSONString(element.rawField);
  element.isURL = urlRegex({ exact: true }).test(element.selectedField);
  element.isDate = isValidNumericalDateString(element.selectedField);
  element.isPrimitiveValue = isPrimitiveValue(element, params);
  element.isKey = element.classList.contains(JSON_FORMATTER_KEY_CLASS);
}

function setElementKeyValueData(element: ILatestClickedElement): void {
  const getElInnerText = el => el.innerText.replace(/"/g, '');
  const contentRowChildren = getContentRow(element).children;
  if (element.isSingleValue) {
    element.valueField = getElInnerText(element);
  } else if (isArrEl(element)) {
    element.keyField = getElInnerText(getArrElKey(element));
    element.valueField = getElInnerText(contentRowChildren[0]);
  } else {
    element.keyField = getElInnerText(contentRowChildren[IJsonElements.key]);
    element.valueField = getElInnerText(contentRowChildren[IJsonElements.value]);
  }
}

function getContentRow(el: ILatestClickedElement | HTMLElement): HTMLElement {
  const isValue = el.classList.contains(JSON_FORMATTER_VALUE_CLASS);
  return isValue ? el.parentElement.parentElement : el.parentElement;
}

function isPrimitiveValue(element: ILatestClickedElement, params: any): boolean {
  const colId = params?.column?.colId;
  const log = params?.data?.textObject;
  const path = getFullJSONPath(element, colId);
  const value = get(log, path);
  return Object(value) !== value;
}

export function getValueFromObjectByDotPath(jsonObj: object, dotSeperatedStr: string): any {
  if (!dotSeperatedStr) {
    return jsonObj;
  }
  if (!isObject(jsonObj)) {
    return null;
  }
  const pathsArr = getPossiblePaths(dotSeperatedStr);

  for (const path of pathsArr) {
    const value = jsonObj[path];
    if (!isNil(value)) {
      const remainingPath = dotSeperatedStr.substring(path.length).slice(1, dotSeperatedStr.length - 1);
      return getValueFromObjectByDotPath(value, remainingPath);
    }
  }

  function getPossiblePaths(path: string): string[] {
    const pathSplit = path.split('.');
    const splitLength = pathSplit.length;
    const paths = [];

    for (let i = 0; i < splitLength; i++) {
      const item = pathSplit.join('.');
      paths.push(item);
      pathSplit.pop();
    }
    return paths;
  }
}
