import { Action } from '@ngrx/store';
import { type } from '../../../shared/services/utils';
import { Team } from '../../shared/models/team';

export namespace TeamActions {
  export const ActionTypes = {
    GET_TEAMS: type('[Team] Get Teams'),
    GET_TEAMS_COMPLETED: type('[Team] Get Teams Completed'),
    GET_TEAMS_FAILED: type('[Team] Get Teams Failed'),
    GET_SELECTED_TEAM: type('[Team] Get Selected Team'),
    GET_SELECTED_TEAM_COMPLETED: type('[team] get Selected Team Completed'),
    SELECT_TEAM: type('[Team] Select Team'),
    CREATE_TEAM: type('[Team] Create New Team'),
    CREATE_FIRST_TEAM: type('[Team] Create New First Team'),
    CREATE_TEAM_COMPLETED: type('[Team] Create New Team Completed'),
    CREATE_TEAM_FAILED: type('[Team] Create New Team Failed'),
    SELECT_TEAM_COMPLETED: type('[team] Select Team Completed'),
    CHANGE_TEAM_URL: type('[team] Change Team URL'),
    CHANGE_TEAM_URL_COMPLETED: type('[team] Change Team URL Completed'),
    CHANGE_TEAM_URL_FAILED: type('[team] Change Team URL Failed'),

    LOGIN_TO_TEAM: type('[team] Login To team'),
    LOGIN_WITH_TEAM_NAME: type('[Team] Login With Team Name'),
    LOGIN_WITH_TEAM_NAME_GET_TEAMS_COMPLETED: type(
      '[Team] Login With Team Name get teams Completed',
    ),
    LOGIN_WITH_TEAM_NAME_FAILED: type('[Team] Login With Team Name Failed'),
    SET_LOGIN_WITH_TEAM_NAME: type('[Team] Set Login Team Name'),
  };

  export class CreateTeam implements Action {
    public type = ActionTypes.CREATE_TEAM;

    constructor(public payload: string) {}
  }
  export class CreateFirstTeam implements Action {
    public type = ActionTypes.CREATE_FIRST_TEAM;

    constructor(public payload: string) {}
  }
  export class CreateTeamCompleted implements Action {
    public type = ActionTypes.CREATE_TEAM_COMPLETED;

    constructor(public payload: Team) {}
  }
  export class CreateTeamFailed implements Action {
    public type = ActionTypes.CREATE_TEAM_FAILED;

    constructor(public payload: any) {}
  }
  export class GetTeams implements Action {
    public type = ActionTypes.GET_TEAMS;

    constructor(public payload?: any) {}
  }
  export class GetTeamsCompleted implements Action {
    public type = ActionTypes.GET_TEAMS_COMPLETED;
    constructor(public payload: Team[]) {}
  }
  export class GetTeamsFailed implements Action {
    public type = ActionTypes.GET_TEAMS_FAILED;

    constructor(public payload: any) {}
  }
  export class GetSelectedTeam implements Action {
    public type = ActionTypes.GET_SELECTED_TEAM;

    constructor(public payload?: any) {}
  }
  export class GetSelectedTeamCompleted implements Action {
    public type = ActionTypes.GET_SELECTED_TEAM_COMPLETED;

    constructor(public payload: any) {}
  }
  export class SelectTeam implements Action {
    public type = ActionTypes.SELECT_TEAM;

    constructor(public payload: { teamId: number; isNew: boolean }) {}
  }
  export class SelectTeamCompleted implements Action {
    public type = ActionTypes.SELECT_TEAM_COMPLETED;

    constructor(public payload: any) {}
  }
  export class ChangeTeamURL implements Action {
    public type = ActionTypes.CHANGE_TEAM_URL;

    constructor(public payload: { team: Team; newURL: string }) {}
  }
  export class ChangeTeamURLFailed implements Action {
    public type = ActionTypes.CHANGE_TEAM_URL_FAILED;

    constructor(public payload: any) {}
  }
  export class ChangeTeamURLCompleted implements Action {
    public type = ActionTypes.CHANGE_TEAM_URL_COMPLETED;

    constructor(public payload: { team: Team; newURL: string }) {}
  }

  export class LoginToTeam implements Action {
    public type = ActionTypes.LOGIN_TO_TEAM;

    constructor(public payload: null) {}
  }

  export class LoginWithTeamName implements Action {
    public type = ActionTypes.LOGIN_WITH_TEAM_NAME;

    constructor(public payload: string) {}
  }

  export class LoginWithTeamNameGetTeamsCompleted implements Action {
    public type = ActionTypes.LOGIN_WITH_TEAM_NAME_GET_TEAMS_COMPLETED;

    constructor(public payload: { teamName: string; teams: Team[] }) {}
  }

  export class LoginWithTeamNameFailed implements Action {
    public type = ActionTypes.LOGIN_WITH_TEAM_NAME_FAILED;

    constructor(public payload: any) {}
  }

  export class SetLoginWithTeamName implements Action {
    public type = ActionTypes.SET_LOGIN_WITH_TEAM_NAME;

    constructor(public payload: string) {}
  }

  export type Actions =
    | GetTeams
    | GetTeamsCompleted
    | GetTeamsFailed
    | GetSelectedTeam
    | SelectTeam
    | CreateTeam
    | CreateTeamCompleted
    | CreateTeamFailed
    | SelectTeamCompleted
    | GetSelectedTeamCompleted
    | ChangeTeamURL
    | ChangeTeamURLFailed
    | ChangeTeamURLCompleted
    | LoginToTeam
    | LoginWithTeamName
    | LoginWithTeamNameGetTeamsCompleted
    | LoginWithTeamNameFailed
    | SetLoginWithTeamName
    | CreateFirstTeam;
}
