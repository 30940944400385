import { Pipe, PipeTransform } from '@angular/core';
import { formatsHelper } from '../services/formatsHelper';
import { TemplateType } from '../../loggregation/shared/interfaces/templateType.enum';
import { LoggregationlHelper } from '../../loggregation/shared/services/loggregation.helper';
import { LoggregationEntity } from '../../loggregation/shared/interfaces/loggregation.interface';

declare let $: any;
@Pipe({
  name: 'loggregationPipe',
})
export class LoggregationPipe implements PipeTransform {
  public clickableParam: string =
    '<span class="parameter-$3-style {CoralogixPHStatsEnabled}" parameterId="$1" parameterType="$3" id="parameter">*$2</span>';
  public clickableJsonParam: string =
    '<span class="parameter-$3-style {CoralogixPHStatsEnabled}" parameterId="$2" parameterType="$3" id="parameter"> *VALUE</span>';
  public transform(value: LoggregationEntity, args?: any): any {
    if (!value) {
      return '';
    }
    let logText = value.text;
    const logExample = value.logExample;
    if (value.templateType === TemplateType.jsonTemplate) {
      logText = LoggregationlHelper.buildLoggregationParametersHTML(
        logText,
        this.clickableJsonParam,
        true,
        logExample,
        value.placeholdersEnabled,
      );
    }
    const replaced = LoggregationlHelper.buildLoggregationParametersHTML(
      logText,
      this.clickableParam,
      false,
      null,
      value.placeholdersEnabled,
    );
    const test = document.createElement('div');
    test.innerHTML = replaced;
    const spans = test.getElementsByTagName('span');
    for (let i = 0; i < spans.length; i++) {
      spans[i].textContent = formatsHelper.stringSub(spans[i].textContent, 50);
    }
    return test.innerHTML;
  }
}
