import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeleteMetricRequest, Metric } from '@app/settings/metrices-settings/models/metric.model';
import { format, parseISO } from 'date-fns';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { MetricsActions } from '@app/settings/metrices-settings/state/actions/metrics.actions';

@Component({
  selector: 'sh-metrics-table',
  templateUrl: './metrics-table.component.html',
  styleUrls: ['./metrics-table.component.scss'],
})
export class MetricsTableComponent {
  @Output()
  public editMetric: EventEmitter<Metric> = new EventEmitter<Metric>();
  public deleteMetric: EventEmitter<Metric> = new EventEmitter<Metric>();
  @Input('metrics')
  set dataSource(metrics: Metric[]) {
    this._dataSource = new MatTableDataSource<Metric>(metrics);
  }
  public displayedColumns: string[] = ['name', 'createDate', 'updateDate', 'permutationAmount', 'more'];
  private _dataSource: MatTableDataSource<Metric>;

  constructor(private store: Store<State>) {}

  get source(): MatTableDataSource<Metric> {
    return this._dataSource;
  }

  public getFormattedDate(dateString: string): string {
    return format(parseISO(dateString), 'MMM dd, yyyy');
  }

  public onDeleteMetric(metric: Metric): void {
    this.store.dispatch(new MetricsActions.DeleteMetricAction(new DeleteMetricRequest(metric.id)));
  }
}
