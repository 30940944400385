<div class='container'>
  <mat-icon class='close-icon' (click)='dialogRef.close(false)'>close</mat-icon>
  <div class='content'>
    <h2 mat-dialog-title class='title'>{{ title }}</h2>
    <span class='subtitle'>{{ message }}</span>
  </div>
  <div class="input-container">
    <input type="text" placeholder="{{placeholder}}" id="actionText" name="actionText" [(ngModel)]="actionText" maxlength="40">
  </div>
  <div class="error-label" [class.visible]="(showError$ | async)">Value can not be empty</div>
  <div class='actions'>
    <div class='btn cancel-btn' (click)='dialogRef.close(false);'>Cancel</div>
    <div class='btn delete-btn' (click)='sendText()'>
      {{ action }}
    </div>
  </div>
</div>
