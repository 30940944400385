export enum ViewModes {
  quick = 'quick',
  relative = 'relative',
  absolute = 'absolute',
  tag = 'tag'
}

export interface IContentTemplate {
  key: string;
  isOpen: boolean;
}
