import { SamlConfigurationsModel } from '@app/settings/shared/models/samlConfigurationsModel';

export class GetSumlConfigurations {
  public static readonly type: string = '[SAML] Get SAML configurations';
}

export class SaveSumlConfigurations {
  public static readonly type: string = '[SAML] Save SAML configurations';
  constructor(public configurations: SamlConfigurationsModel) { }
}

export class UploadIdpMetadataFile {
  public static readonly type: string = '[SAML] Upload Idp Metadata File';
  constructor(public file: File) { }
}

export class ToggleSamlMultipleTeams {
  public static readonly type: string = '[SAML] Toggle SAML Multiple Teams';
}

export class SaveSamlToken {
  public static readonly type: string = '[SAML] Save SAML Token';
}

export class RemoveSamlToken {
  public static readonly type: string = '[SAML] Remove SAML Token';
}

export class FetchDefaultGroups {
  public static readonly type: string = '[SAML] Fetch Default Groups';
}

export class UpdateDefaultGroups {
  public static readonly type: string = '[SAML] Update Default Groups';
  constructor(public groups: {[key: string]: number}) { }
}
