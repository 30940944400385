import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';

@Component({
  selector: 'sh-reindex-empty',
  templateUrl: './reindex-empty.component.html',
  styleUrls: ['./reindex-empty.component.scss']
})
export class ReindexEmptyComponent implements OnInit {
  @Input()
  public btnDisabled: boolean;
  @Output()
  public openEditor: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
