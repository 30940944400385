import { Metadata } from '@app/logs/shared/models/query-metadata';
import { LivetailStatsEntity, LivetailStatsCount } from '../models/livetailStatsEntity';
import { SeveritiesHelper } from '@shared/services/severities.helper';
import { ChartModel, ChartSeriesModel } from '@shared/controls/charts/models/chart.model';
const severityMap = {
  5: 'critical',
  4: 'error',
  3: 'warning',
  2: 'info',
  1: 'verbose',
  0: 'debug',
};
export class LivetailStatisticsManager {
  public livetailTailStats: Array<LivetailStatsEntity> = new Array<LivetailStatsEntity>();
  public bulks = [];

  constructor(metadata: Metadata) {
    this.bulks.push({});
    this.init(metadata);
  }

  public updateStats(data): void {
    this.livetailTailStats.forEach((entity) => {
      entity.livetailStatsCount = new LivetailStatsCount(0);
    });
    if (this.bulks.length > 20) {
      this.bulks.shift();
    }

    const stats = {
      critical: 0,
      error: 0,
      warning: 0,
      info: 0,
      verbose: 0,
      debug: 0,
    };

    for (let j = 0; j < data.results.length; j++) {
      for (let i = 0; i < data.results[j].severity.length; i++) {
        stats[severityMap[i.toString()]] += data.results[j].severity[i];
      }
    }
    this.livetailTailStats.forEach((item) => {
      item.total += parseInt(stats[item.severity.toLowerCase()]);
      item.livetailStatsCount = new LivetailStatsCount(
        parseInt(stats[item.severity.toLowerCase()]),
      );
    });
  }

  public init(metadata: Metadata) {
    const mData = metadata ? metadata : new Metadata();
    if (mData.severity.length == 0) {
      mData.severity = [1, 2, 3, 4, 5, 6];
    }

    for (let i = 0; i < mData.severity.length; i++) {
      const result = new LivetailStatsEntity();
      result.total = 0;
      result.severity = SeveritiesHelper.getLogSeverityById(mData.severity[i]);
      result.chartModel = new ChartModel();
      result.chartModel.series = new Array<ChartSeriesModel>();
      const ser = new ChartSeriesModel();
      ser.data = [];
      ser.name = result.severity;
      ser.type = 'line';
      ser.color = SeveritiesHelper.getSeverityColor(mData.severity[i]);
      result.chartModel.xType = 'timestamp';
      result.chartModel.series.push(ser);
      result.chartModel.height = 40;
      result.chartModel.useLabels = false;
      result.chartModel.minorTickLength = 0;
      result.chartModel.tickLength = 0;
      result.chartModel.minorGridLineWidth = 0;
      result.chartModel.lineWidth = 0;
      result.chartModel.tooltipEnabled = false;
      result.chartModel.backgroundColor = 'transparent';
      result.chartModel.infoBarClass = 'chart-infoBar dark ';
      result.chartModel.tooltipDateFormat = 'h:mm:ss';
      result.chartModel.zoomType = null;
      result.chartModel.hoverable = true;
      result.chartModel.clickable = true;
      result.chartModel.isRealtime = true;
      this.livetailTailStats.push(result);
    }
  }
}
