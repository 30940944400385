import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { formFiltersBase } from '../../shared/models/formFiltersBase';
import { MetadataService } from '../../logs/shared/services/metadata.service';
import { UserSettingsProvider } from '../shared/userSettingsProvider';
import { FormControl, FormGroup } from '@angular/forms';
import { UserSettingsService } from '../shared/user-settings.service';
import * as _ from 'lodash';
import { Metadata } from '../../logs/shared/models/query-metadata';
import { Subscription } from 'rxjs';
import { FilterMetadataPipe } from '../../shared/pipes/filter-metadata/filter-metadata.pipe';
import { UserSettingsDefaultOptionsEnum } from './contants/user-settings-default-options.enum';

@Component({
  selector: 'sh-user-settings-filters',
  templateUrl: 'user-settings-filters.component.html',
  styleUrls: ['user-settings-filters.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [FilterMetadataPipe],
})
export class UserSettingsFiltersComponent extends formFiltersBase implements OnInit, OnDestroy {
  private get filteredApps(): string[] {
    return this.getFilteredValue(this.applicationNameOptions, this.filterApplicationNameOptions);
  }

  private get filteredSubsystems(): string[] {
    return this.getFilteredValue(this.subsystemNameOptions, this.filterSubsystemNameOptions);
  }

  @Input()
  isSmallScreen: boolean;
  applicationName: string[] = [UserSettingsDefaultOptionsEnum.AllApplications];
  applicationNameOptions: string[];
  filterApplicationNameOptions: string;
  subsystemName: string[] = [UserSettingsDefaultOptionsEnum.AllSubsystems];
  subsystemNameOptions: string[];
  filterSubsystemNameOptions: string;
  clearUserSettings: Subscription;

  constructor(
    metadataService: MetadataService,
    private userSettingsProvider: UserSettingsProvider,
    private userSettingsService: UserSettingsService,
    private filterMetadata: FilterMetadataPipe,
  ) {
    super(metadataService);
  }

  ngOnInit(): void {
    this.applicationNameOptions = [];
    this.subsystemNameOptions = [];
    this.filtersForm = this.createMetadataFrom();
    this.getAllRelatedMetadata(null);

    this.options['applicationMetadata$'].first().subscribe(res => {
      this.applicationNameOptions = res;
      setTimeout(() => {
        if (this.userSettingsProvider.userSettings && this.userSettingsProvider.userSettings.queryMetadata.applicationName.length > 0) {
          this.applicationName = this.userSettingsProvider.userSettings.queryMetadata.applicationName;
        }
      }, 200);
    });

    this.options['subsystemMetadata$'].first().subscribe(res => {
      this.subsystemNameOptions = res;
      setTimeout(() => {
        if (this.userSettingsProvider.userSettings && this.userSettingsProvider.userSettings.queryMetadata.subsystemName.length > 0) {
          this.subsystemName = this.userSettingsProvider.userSettings.queryMetadata.subsystemName;
        }
      }, 200);
    });

    this.clearUserSettings = this.userSettingsProvider.clearUserSettings.subscribe(() => {
      this.applicationName = [UserSettingsDefaultOptionsEnum.AllApplications];
      this.subsystemName = [UserSettingsDefaultOptionsEnum.AllSubsystems];
      this.changeSelectedApplication(null);
      this.changeSelectedSubsystem(null);
    });
  }

  isAppMatchedWithFilter(val: string): boolean {
    return this.filteredApps.includes(val);
  }

  isSubsystemMatchedWithFilter(val: string): boolean {
    return this.filteredSubsystems.includes(val);
  }

  apply(): void {
    if (this.userSettingsProvider.userSettings) {
      this.userSettingsProvider.userSettings.queryMetadata = this.filtersForm.value;
      this.userSettingsProvider.userSettingsChanged.emit(this.userSettingsProvider.userSettings);
    }
  }

  changeSelectedSubsystem(value: string[]): void {
    const prevSelection = _.cloneDeep(this.userSettingsProvider.userSettings.queryMetadata.subsystemName);
    const currentSelection = _.cloneDeep(value);
    this.userSettingsProvider.userSettings.queryMetadata.subsystemName = [];

    // Case deselect all, choose All subsystems
    if (currentSelection.length === 0) {
      this.subsystemName = [UserSettingsDefaultOptionsEnum.AllSubsystems];
      if (prevSelection.length === 0) {
        return;
      }
    } else {
      // Case choosing a subsystems and "All subsystems" was selected, remove the selection from "All subsystems"
      if (prevSelection.length === 0) {
        if (currentSelection.length > 1) {
          currentSelection.splice(0, 1);
          this.subsystemName = currentSelection;
          this.userSettingsProvider.userSettings.queryMetadata.subsystemName = this.subsystemName;
        }
      } else {
        // Case choosing "All subsystems", But one or more subsystems was selected
        if (currentSelection.includes(UserSettingsDefaultOptionsEnum.AllSubsystems)) {
          this.subsystemName = [UserSettingsDefaultOptionsEnum.AllSubsystems];
        } else {
          this.userSettingsProvider.userSettings.queryMetadata.subsystemName = this.subsystemName;
        }
      }
    }

    this.userSettingsProvider.userSettingsChanged.emit(this.userSettingsProvider.userSettings);
    this.userSettingsService
      .updateUserSettings(this.userSettingsProvider.userSettings)
      .first()
      .subscribe();
  }

  changeSelectedApplication(value: string[]): void {
    const prevSelection = _.cloneDeep(this.userSettingsProvider.userSettings.queryMetadata.applicationName);
    const currentSelection = _.cloneDeep(value);
    this.userSettingsProvider.userSettings.queryMetadata.applicationName = [];

    // Case deselect all, choose All application
    if (currentSelection.length === 0) {
      this.applicationName = [UserSettingsDefaultOptionsEnum.AllApplications];
      if (prevSelection.length === 0) {
        return;
      }
    } else {
      // Case choosing a subsystems and "All application" was selected, remove the selection from "All application"
      if (prevSelection.length === 0) {
        if (currentSelection.length > 1) {
          currentSelection.splice(0, 1);
          this.applicationName = currentSelection;
          this.userSettingsProvider.userSettings.queryMetadata.applicationName = this.applicationName;
        }
      } else {
        // Case choosing "All application", But one or more applications was selected
        if (currentSelection.includes(UserSettingsDefaultOptionsEnum.AllApplications)) {
          this.applicationName = [UserSettingsDefaultOptionsEnum.AllApplications];
        } else {
          this.userSettingsProvider.userSettings.queryMetadata.applicationName = this.applicationName;
        }
      }
    }

    // Update subsystems list
    let filter = null;
    if (this.applicationName[0] !== UserSettingsDefaultOptionsEnum.AllApplications) {
      filter = new Metadata();
      filter.applicationName = this.applicationName;
    }
    this.getAllRelatedMetadata(filter);
    this.options['subsystemMetadata$'].first().subscribe(res => {
      this.subsystemNameOptions = res;
      this.subsystemName = [UserSettingsDefaultOptionsEnum.AllSubsystems];
      this.userSettingsProvider.userSettings.queryMetadata.subsystemName = [];
    });

    this.userSettingsProvider.userSettingsChanged.emit(this.userSettingsProvider.userSettings);
    this.userSettingsService
      .updateUserSettings(this.userSettingsProvider.userSettings)
      .first()
      .subscribe();
  }

  filterApplicationChanged(filterString: string): void {
    this.filterApplicationNameOptions = filterString;
  }

  filterSubsystemsChanged(filterString: string): void {
    this.filterSubsystemNameOptions = filterString;
  }

  ngOnDestroy(): void {
    if (this.clearUserSettings) {
      this.clearUserSettings.unsubscribe();
    }
  }

  private getFilteredValue(options: string[], filter: string): string[] {
    return filter ? this.filterMetadata.transform(options, filter) : options;
  }

  private createMetadataFrom(): FormGroup {
    return new FormGroup({
      category: new FormControl([]),
      className: new FormControl([]),
      methodName: new FormControl([]),
      applicationName: new FormControl(
        this.userSettingsProvider.userSettings ? this.userSettingsProvider.userSettings.queryMetadata.applicationName : [],
      ),
      subsystemName: new FormControl(
        this.userSettingsProvider.userSettings ? this.userSettingsProvider.userSettings.queryMetadata.subsystemName : [],
      ),
      computerName: new FormControl([]),
      severity: new FormControl([]),
      IPAddress: new FormControl([]),
      sdkId: new FormControl([]),
      threadId: new FormControl(''),
    });
  }
}
