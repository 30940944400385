import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[shMatClickOutside]',
})
export class MaterialClickOutsideDirective {
  @Output()
  public shMatClickOutside: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  /*
  Angular Material has overlay on default with dropdown s and popups, therefore clickOutside will
  trigger unwillingly. This is the current solution of solving it, if facing problems with the future
  we can implement the same solution but with a custom class.
 */

  private excludedClasses: { [key: string]: boolean } = {
    'cdk-overlay-backdrop': true,
    'mat-select-panel-wrap': true,
    'mat-option-text': true,
    'mat-dialog-container': true,
  };

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (
      !clickedInside &&
      this.elementTakesSpace(targetElement) &&
      !this.isExcluded(targetElement) &&
      !this.isExcluded(targetElement?.offsetParent)
    ) {
      this.shMatClickOutside.emit(event);
    }
  }

  private isExcluded(targetElement: Element): boolean {
    if (!targetElement?.classList) return false;
    for (const c of targetElement.classList as any) {
      if (this.excludedClasses[c] === true) {
        return true;
      }
    }
    return false;
  }

  private elementTakesSpace(targetElement: HTMLElement): number {
    return targetElement.clientHeight && targetElement.clientHeight;
  }
}
