import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { checkAndParseJSON } from '@shared/utils/utils';
import { JsonFormatterService } from '@shared/modules/json-formatter/json-formatter.service';

interface IDialogData {
  fieldName: string;
  stringToPrettify: string;
  fullJSONPath: string;
}

@Component({
  selector: 'sh-pretty-json-popup',
  templateUrl: './pretty-json-dialog.component.html',
  styleUrls: ['./pretty-json-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrettyJsonDialogComponent implements AfterViewInit {
  @ViewChild('formattedJson') public formattedJson: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public dialogRef: MatDialogRef<PrettyJsonDialogComponent>,
    private renderer: Renderer2,
  ) {}

  public ngAfterViewInit(): void {
    const data = checkAndParseJSON(this.data.stringToPrettify);
    const formatterService = new JsonFormatterService(data);
    const formattedJSON = formatterService.render();
    this.renderer.appendChild(this.formattedJson.nativeElement, formattedJSON);
  }
}
