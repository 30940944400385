import { ExportActions } from './export.actions';

export namespace ExportReducer {
  export interface State {
    logPages: number[];
    dateRange: Map<string, Date>;
  }

  const initalState: State = {
    logPages: [],
    dateRange: new Map([['min', null], ['max', null]]),
  };

  export function reducer(
    state: State = initalState,
    action: ExportActions.Actions,
  ): State {
    switch (action.type) {
      case ExportActions.ActionTypes.UPDATE_LOG_PAGES:
        return Object.assign({}, state, { logPages: action.payload });
      case ExportActions.ActionTypes.UPDATE_DATE_RANGE:
        return Object.assign({}, state, { dateRange: action.payload });
      default:
        return state;
    }
  }
}
