import {
  Directive,
  Input,
  OnDestroy,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot,
  UrlSegment,
} from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Location } from '@angular/common';

@Directive({
  selector: '[shActiveRouter]',
})
export class ActiveRouterDirective implements OnDestroy, AfterViewInit {
  public _className: string;
  public _routingUrl: string[];
  public routingSubscription: Subscription;

  @Input('activeRouteClass') set className(value: string) {
    this._className = value;
  }

  @Input('shActiveRouter') set routingUrl(value: string[]) {
    this._routingUrl = value;
  }

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
  ) {}

  public ngAfterViewInit() {
    this.registerToRoutingChanged();
  }

  public registerToRoutingChanged() {
    this.routingSubscription = this.route.url.subscribe((urlSegments) =>
      this.isRouterActive(this.route),
    );
  }

  public isRouterActive(activeRoute: ActivatedRoute) {
    if (activeRoute && this._className && this._routingUrl) {
      let currentRoute = this.location.path();
      const queryParamsLocation = currentRoute.indexOf('?');
      if (queryParamsLocation !== -1) {
        currentRoute = currentRoute.substr(0, queryParamsLocation);
      }
      const isActive: boolean = this._routingUrl[0] == currentRoute;
      if (isActive) {
        if (this.el.nativeElement.className.indexOf(this._className) == -1) {
          this.el.nativeElement.className += ' ' + this._className;
        }
      } else {
        this.el.nativeElement.className = _.replace(
          this.el.nativeElement.className,
          this._className,
          '',
        );
      }
    }
  }

  public ngOnDestroy() {
    if (this.routingSubscription) {
      this.routingSubscription.unsubscribe();
    }
  }
}
