<div class="input-container" [class.disabled]="disabled">
    <div class="inner-label">
        <div class="input-wrapper">
            <input
                    type="number"
                    [min]="minValue"
                    [max]="maxValue"
                    [disabled]="disabled"
                    [(ngModel)]="value"
                    (ngModelChange)="onModelChange($event)"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    *ngIf="showValue"
                    [step]="step"
            >
        </div>
        <div class="text" *ngIf="showText && showValue" [ngStyle]="{'left.px': inputWidth}">{{labelText}}</div>
    </div>
    <div class="switcher-container">
        <button class="switch-btn" shLongPress [disabled]="disabled" (onLongPressing)="increaseValue()" (click)="increaseValue()">
            <i class="fa fa-chevron-up"></i>
        </button>
        <button class="switch-btn" shLongPress [disabled]="disabled" (onLongPressing)="decreaseValue()" (click)="decreaseValue()">
            <i class="fa fa-chevron-down"></i>
        </button>
    </div>
</div>
