<div class="multiple-select-group">
  <div class="select-group-header">
    <div class="select-text">{{ label }}</div>
    <div class="all-checkbox">
      <cgx-checkbox label="All" labelPosition="before" [(ngModel)]="allSelected" (ngModelChange)="onSelectAllChange($event)"></cgx-checkbox>
    </div>
  </div>

  <div class="select-group" *ngFor="let group of $any(allGroups.controls); index as i" [class.disabled]="isDisabled$ | async">
    <div class="select-group-container">
      <div class="cgx-conditional-select-container">
        <ng-select
          readonly
          class="cgx-conditional-select"
          placeholder="Select"
          [searchable]="false"
          [formControl]="group.controls['compareType']"
          [clearable]="false"
          (change)="onCompareTypeChange(group)"
        >
          <ng-option *ngFor="let compareType of compareTypes" [value]="compareType">
            {{ compareType | enumTranslator: 'CompareType' }}
          </ng-option>
        </ng-select>

        <ng-container *ngIf="group.controls['compareType'].value === CompareType.equals; else inputTemplate">
          <ng-select
            data-test="conditional-select-input-select"
            class="cgx-conditional-checkbox"
            placeholder="Type..."
            [items]="options$ | async"
            [searchable]="true"
            [virtualScroll]="true"
            [clearable]="false"
            [selectableGroup]="true"
            [selectableGroupAsModel]="true"
            [closeOnSelect]="false"
            [multiple]="true"
            [formControl]="group.controls['values']"
          >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="cgx-select-selected-item">
                <span class="cgx-select-selected-item-caption">{{ item }}</span>
                <img
                  alt="clear"
                  src="../../../../assets/icons/x-pillbox.svg"
                  class="cgx-select-selected-clear-icon"
                  (click)="clear(item)"
                />
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-item$="item$">
              <cgx-checkbox [label]="item" [ngModel]="item$.selected" (click)="$event.preventDefault()"></cgx-checkbox>
            </ng-template>
          </ng-select>
        </ng-container>

        <ng-template #inputTemplate>
          <div class="input-container">
            <input
              data-test="conditional-select-input"
              type="text"
              placeholder="Type..."
              [value]="getGroupValue(group)"
              (input)="onInputChange(group, $event.target.value)"
            />
          </div>
        </ng-template>
      </div>

      <div class="delete-icon-container" [matMenuTriggerFor]="menu">
        <mat-icon class="menu-icon">more_vert</mat-icon>
      </div>
    </div>

    <mat-menu #menu="matMenu" class="delete-group-menu">
      <div (click)="deleteGroup(i)" class="delete-group-item">
        <img alt="icon" src="assets/icons/delete.svg" class="menu-delete-icon" />
        <div class="caption">Delete</div>
      </div>
    </mat-menu>

    <div class="or-container" *ngIf="i < allGroups.value.length - 1">
      <div class="or-line"></div>
      <div class="or-caption">
        OR
      </div>
      <div class="or-line"></div>
    </div>
  </div>

  <div class="add-more-btn" (click)="addControl()" data-test="conditional-select-add-btn">
    <mat-icon class="add-icon">add</mat-icon>
    <div class="caption">{{ btnLabel }}</div>
  </div>
</div>
