<div class="container">
  <sh-new-json-formatter
    *ngIf="value"
    [data]="value"
    [colId]="params.column.getColId()"
    [params]="params"
  ></sh-new-json-formatter>

  <div class="expansion-container" *ngIf="hasMoreContent$ | async">
    <div class="show-more-container" *ngIf="(isRowExpanded$ | async) === false"></div>
    <div class="expand-btn" (click)="setRowHeight()">
      {{ (isRowExpanded$ | async) ? 'MINIMIZE' : 'EXPAND' }}
    </div>
  </div>
</div>
