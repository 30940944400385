import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class Team {
  @jsonMember
  public created_at: string;
  @jsonMember
  public id: number;
  @jsonMember
  public isActive: boolean;
  @jsonMember
  public name: string;
  @jsonMember
  public privateKey: string;
  @jsonMember
  public updated_at: string;
  @jsonMember
  public team_url: string;
}
