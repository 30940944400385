import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@app/app.reducers';
import { severityColorMap, severityColors, severityMap } from '@app/logs-new/shared/constants/severity-map.constant';
import { ILogMetadata } from '@app/logs-new/shared/models/log-metadata.model';
import {
  ILogsCountStats,
  ILogsSeries,
  ILogsStats,
  IOccurrencesData,
  IStatisticsResult,
} from '@app/logs-new/shared/models/logs-stats.model';
import { ILogQueries, ILogQuery, LogQueryModel } from '@app/logs-new/shared/models/logsquery.model';
import { selectLogQuery } from '@app/logs-new/store/logs-queries/logs-queries.selectors';
import { ConfigurationService } from '@app/services/configuration.service';
import { Store } from '@ngrx/store';
import { ShDialogService } from '@shared/services/dialogService';
import { default as cloneDeep, default as _cloneDeep } from 'lodash-es/cloneDeep';
import { forkJoin, Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ContextualRegistryService } from '@app/logs-new/shared/services/contextual-registry.service';

@Injectable({
  providedIn: 'root',
})
export class LogsStatsService {
  constructor(
    private http: HttpClient,
    private store: Store<State>,
    private dialogService: ShDialogService,
    private configService: ConfigurationService,
    private contextualCaps: ContextualRegistryService,
  ) {}

  public getCountStats(query: ILogQuery): Observable<ILogsCountStats> {
    const statsUrl = this.contextualCaps.getCapabilitiesURLS()?.queryStatePostfix;
    const queryStatsUrl = this.configService.getConfigURL(statsUrl);
    return this.http.post<ILogsCountStats>(queryStatsUrl, query);
  }

  public getLogsStats(query: ILogQuery): Observable<ILogsStats> {
    const logsStatsUrl = this.contextualCaps.getCapabilitiesURLS()?.logsStatsPostfix;
    const statsURL = this.configService.getConfigURL(logsStatsUrl);
    const clonedQuery = cloneDeep(query);

    const hasAggFunc = clonedQuery?.graphs?.[0]?.aggBy?.field;

    if (hasAggFunc) {
      const freshQuery = new LogQueryModel();
      clonedQuery.queryParams.metadata = freshQuery.queryParams.metadata;
      clonedQuery.queryParams.jsonObject = freshQuery.queryParams.jsonObject;
    }

    return this.http
      .post<IStatisticsResult>(statsURL, {
        queryDef: clonedQuery,
      })
      .pipe(
        map((res: IStatisticsResult) => {
          const aggField = clonedQuery.graphs?.[0]?.aggField;
          const isGroupBySeverity = aggField === 'coralogix.metadata.severity';
          const graph = res?.results?.graphs?.[0];
          const graphType = clonedQuery?.graphs?.[0]?.type;

          return {
            series: graph?.series.reduce((acc: ILogsSeries[], series) => {
              const name = isGroupBySeverity ? severityMap[Number(series.name)] || series.name : series.name;
              const graphSeries = { data: series.data, name, type: 'line' } as ILogsSeries;
              if (isGroupBySeverity) graphSeries.color = severityColors[Number(series.name) - 1] || severityColorMap[series.name];
              acc.push(graphSeries);
              return acc;
            }, []),
            res,
            isGroupBySeverity,
            graphType,
            graphCategories: graph?.graphs_categories,
            valuesBreakdown: graph?.timestamp_values_maps,
            query: clonedQuery,
            aggBy: clonedQuery?.graphs?.[0]?.aggBy,
          };
        }),
      );
  }

  public getTemplateJsonStats(query: ILogQuery): Observable<number[][]> {
    const statsURL = this.configService.getConfigURL('/api/v1/statistics/logs');
    return this.http
      .post<IStatisticsResult>(statsURL, {
        queryDef: query,
      })
      .pipe(map(extractTemplateStatsData));
  }

  public getTemplateCategoricalStats(query: ILogQuery): Observable<number[][]> {
    const categoricalURL = this.configService.getConfigURL('/api/v1/placeholder/categorical');
    const clonedQuery = _cloneDeep(query);
    clonedQuery.queryParams.metadata.computerName = clonedQuery.queryParams.metadata.computerName || [];
    clonedQuery.queryParams.metadata.IPAddress = clonedQuery.queryParams.metadata.IPAddress || [];
    clonedQuery.queryParams.metadata.threadId = clonedQuery.queryParams.metadata.threadId || '';
    return this.http.post<IStatisticsResult>(categoricalURL, clonedQuery).pipe(map(extractTemplateStatsData));
  }

  public getAllAggregationStats({ metadataQuery, filtersQuery }: ILogQueries): Observable<ILogMetadata> {
    const metadataQueryReq = this.getAggregationStats(metadataQuery);
    const filtersQueryReq = this.getAggregationStats(filtersQuery);

    return forkJoin([metadataQueryReq, filtersQueryReq]).pipe(
      map(([metadataFilters, additionalFilters]) => {
        /* Add missing metadata fields when not exists */
        if (!metadataFilters?.severity) {
          metadataFilters.severity = {};
        }
        for (let i = 1; i < 7; i++) {
          metadataFilters.severity[i] = metadataFilters.severity[i] || 0;
        }
        return {
          ...additionalFilters,
          ...metadataFilters,
        };
      }),
    );
  }

  public getAggregationStats(query: ILogQuery): Observable<ILogMetadata> {
    const logsAggsurl = this.contextualCaps.getCapabilitiesURLS()?.logsAggsPostfix;
    const logqueryAggsUrl = this.configService.getConfigURL(logsAggsurl);
    return this.http
      .post<{ data: ILogMetadata; status: number } & ILogMetadata>(logqueryAggsUrl, {
        queryDef: query,
      })
      .pipe(map(res => res?.data || res));
  }

  public getKeyAggregationStats(key: string, returnAsArr: boolean = false): Observable<{ [key: string]: number }> {
    return this.store.select(selectLogQuery).pipe(
      switchMap(logsQuery => {
        const query = cloneDeep(logsQuery);
        query.queryParams.jsonAggFields = [key];
        return this.getAggregationStats(query).pipe(
          map(fields => fields[key]),
          map(fieldMap => (returnAsArr ? Object.entries(fieldMap) : fieldMap)),
        );
      }),
      catchError(err => {
        this.dialogService.showErrorMessage('Could not get graph stats for field', null);
        return of(err);
      }),
    );
  }

  public getOccurrencesStats(query: ILogQuery, templateId: string): Observable<IOccurrencesData> {
    const clonedQuery = cloneDeep(query);
    clonedQuery.queryParams.query.templateIds = [templateId];
    const occurrencesUrl = this.configService.getConfigURL('/api/v1/placeholder/occurrences');
    const occurrencesReq = this.http.post<IStatisticsResult>(occurrencesUrl, clonedQuery);
    const parameterUrl = this.configService.getConfigURL('/api/v1/loggregation/parameter');
    const parameterReq = this.http.post<IStatisticsResult>(parameterUrl, {
      queryDef: {
        granularity: 'hour',
        queryType: 'templateGlobalAggStats',
        startDate: clonedQuery.startDate,
        endDate: clonedQuery.endDate,
      },
      templateId,
    });

    return forkJoin([occurrencesReq, parameterReq]).pipe(
      map(([occurrences, parameters]: any) => {
        const xAxisValues = occurrences?.data?.xAxis?.values || [];
        const getVal = (data, i) => data.data?.yAxis?.values[i]?.count ?? data?.data?.yAxis?.values[i];
        return {
          categories: occurrences?.data?.xAxis?.values,
          normalAmount: xAxisValues.map((date, index) => [date, getVal(parameters, index), 0]),
          currentQuery: xAxisValues.map((date, index) => [date, getVal(occurrences, index)]),
        };
      }),
    );
  }
}

function extractTemplateStatsData(res: IStatisticsResult): number[][] {
  const yAxisValues = res?.data?.yAxis?.values || [];
  const xAxisValues = res?.data?.xAxis?.values || [];
  return xAxisValues.map((xAxisVal, index) => [xAxisVal, yAxisValues[index]]);
}
