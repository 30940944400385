<sh-grid style="height: 100%; width: 100%;"
         (gridRowSelectedEvent)="publishGridRowSelected($event)"
 [logsQueryData]="queryData"
 [isGroupingSupported]="true"
 [state]="restoredState"
 [searchProvider]="searchProvider"
 [highlightedLogs]="highlightedLogs"
 [(isOverlayVisible)]="isGridOverlayVisible"
         (beforeApiDestroyEvent)="beforeApiDestroy($event)"
         (filterChangedEvent)="gridFilterChanged($event)"
 (textInfoClicked)="onGridButtonClick($event)"
         (gridReadyEvent)="onGridReady($event)"
         (dataLoadedEvent)="gridDataLoaded($event)"
 (logManagerUpdated)="logManagerUpdated($event)"
 (saveStateBeforeDestroy)="saveGridState($event)">
</sh-grid>
