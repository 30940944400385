<div *ngIf="newWidgetForm">
    <mat-dialog-content>
      <h2 mat-dialog-title>{{ title }}</h2>
      <form fxLayout="column" [formGroup]="newWidgetForm" (submit)="onSubmitQuery()">
        <sh-basic-input
          [formControl]="$any(newWidgetForm.controls?.title)"
          label="Name"
          [isRequired]="true"
          placeholder="Enter a name"
          errorMsg="Name is required"
        ></sh-basic-input>

        <br>

        <sh-basic-input
          [formControl]="$any(newWidgetForm.controls?.description)"
          label="Description"
          placeholder="Enter a description"
          errorMsg="Description is required"
        ></sh-basic-input>

        <br>

        <div class="controls-container">
          <sh-metadata-selector [query]="jsonQueryModel"></sh-metadata-selector>
        </div>
        <br>
        <div class="controls-container">
          <div class="ui  grid">
            <div class="control-title show-in-title">Add to</div>
          </div>
          <div class="row">
            <div class="column" style="padding-left: 0">
              <mat-radio-group type="radio" formControlName="showIn">
                <mat-radio-button [value]="widgetsShowInOpt.tagsPage" style="padding-right: 35px;">Tag Reports</mat-radio-button>
                <mat-radio-button [value]="widgetsShowInOpt.dashboard" style="padding-right: 35px;">Main Dashboard</mat-radio-button>
                <mat-form-field
                  class="show-in"
                  [ngClass]="{'is-visible' : newWidgetForm.value?.showIn === widgetsShowInOpt.dashboard }" style="width: 200px" >
                  <mat-select formControlName="timeSelection">
                    <mat-option *ngFor="let time of timeSelectionOptions" [value]="time">
                      {{ time.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-radio-group>
            </div>
          </div>
          <div class="ui grid" *ngIf="!inEditMode">
            <div class="control-title">
              Make available for:
            </div>
            <div class="row">
              <div class="column" style="padding-left: 0">
                <mat-radio-group type="radio" formControlName="isShared">
                  <mat-radio-button [value]="true" style="padding-right: 35px;">
                    Everyone
                  </mat-radio-button>
                  <mat-radio-button [value]="false">Just me</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>

      </form>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button mat-dialog-close class="coralogix button dialog-cancel-button">Cancel</button>
      <button
        mat-button
        [disabled]="!newWidgetForm.valid"
        (click)="onSubmitQuery()"
        class="button coralogix {{inEditMode? 'full-action-button__primary' : 'full-action-button__secondary'}}">
        <span class="header-section__button-text">{{inEditMode ? 'SAVE' : 'CREATE'}}</span>
      </button>
    </mat-dialog-actions>
</div>
