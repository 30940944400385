import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CoralogixSidebarComponent } from '@shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ArchiveQueryActions } from '@app/roi/archive-queries/state/actions/archive-query.actions';
import { ArchiveQueryEditorActions } from '@app/roi/archive-queries/state/actions/archive-query-editor.actions';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';
import { ArchiveQueryEditorForm } from '@app/roi/archive-queries/models/archive-query-editor-form';
import { MetadataActions } from '@shared/state/actions/metadata.actions';
import { ArchiveQueryRequest } from '@app/roi/archive-queries/models/archive-query.model';
import { ShDialogService } from '@shared/services/dialogService';
import { takeUntil } from 'rxjs/operators';
import { getIsArchiveQueryEditorOpen } from '@app/roi/archive-queries/state/selectors/archive-query-selector';
import { ArchiveQueryEditorControls } from '@app/roi/archive-queries/models/archive-query-editor-controls';
import { Constants } from '@app/constants';

@Component({
  selector: 'sh-archive-query-form-container',
  templateUrl: './archive-query-form-container.component.html',
  styleUrls: ['./archive-query-form-container.component.scss'],
})
export class ArchiveQueryFormContainerComponent implements OnInit, OnDestroy {
  @ViewChild('sidebarComponent', { static: true })
  public sidebar: CoralogixSidebarComponent;
  @Output() public formSubmitted: EventEmitter<void> = new EventEmitter<void>();
  public form: FormGroup;

  public logsSeverityOptions: { id: number; name: string }[] = [
    { id: 1, name: 'Debug' },
    { id: 2, name: 'Verbose' },
    { id: 3, name: 'Info' },
    { id: 4, name: 'Warning' },
    { id: 5, name: 'Error' },
    { id: 6, name: 'Critical' },
  ];
  public isPrePopulated: boolean = false;
  public isEditable: boolean = false;
  public validationTextError: string = null;
  public archiveQueryId: string = null;
  private editorSubscription: Subscription;
  private editorCloseSubscription: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<State>,
    private archiveQueryEditorForm: ArchiveQueryEditorForm,
    private archiveQueriesService: ArchiveQueriesService,
    private dialogService: ShDialogService,

  ) {
    this.store.dispatch(new MetadataActions.GetApplicationNameAction(null));
    this.store.dispatch(new MetadataActions.GetSubsystemNameAction(null));
  }

  public submit(archiveQueryReq: ArchiveQueryRequest): void {
    this.store.dispatch(new ArchiveQueryActions.ActivateLoadingAction());
    this.archiveQueriesService
      .createArchiveQuery(archiveQueryReq)
      .subscribe(
        (res) => {
          this.store.dispatch(new ArchiveQueryActions.CreateQueryCompletedAction(res));
          this.store.dispatch(new ArchiveQueryActions.ClearLoadingAction());
          this.store.dispatch(new ArchiveQueryEditorActions.ToggleEditorAction({
            openEditor: false,
            prePopulatedQuery: null,
            isEditable: false
          }));
          this.validationTextError = null;
          this.formSubmitted.emit();
          this.isPrePopulated = false;
          this.isEditable = false;
        },
        err => {
          this.store.dispatch(new ArchiveQueryActions.ClearLoadingAction());
          if (err.status >= 400 &&  err.status < 500) {
            this.validationTextError = err.error.message;
          } else {
            this.dialogService.showSnackBar('Error creating archive query. Please try again.', null, 5000);
          }
        }
      );
  }

  public ngOnInit(): void {
    this.initializeQueryForm();
  }

  public initializeQueryForm(): void {
    this.editorSubscription = this.store.select(getIsArchiveQueryEditorOpen)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.isOpen) {
          let formQueryObject: ArchiveQueryEditorControls = null;
          if (state.prePopulatedQuery) {
            this.archiveQueryId = state.prePopulatedQuery.id;
            // presto queries are on UTC
            const isUtcTimestamp = state.prePopulatedQuery.querySyntax === Constants.PRESTO;
            formQueryObject = {
              name: state.prePopulatedQuery.name,
              description: state.prePopulatedQuery.description,
              applicationName: state.prePopulatedQuery.applicationName,
              subsystem: state.prePopulatedQuery.subsystem,
              severities: state.prePopulatedQuery.severity,
              textSql: state.prePopulatedQuery.text,
              textLucene: state.prePopulatedQuery.text,
              from: ArchiveQueryFormContainerComponent.getDate(state.prePopulatedQuery.startTime, isUtcTimestamp),
              to: ArchiveQueryFormContainerComponent.getDate(state.prePopulatedQuery.endTime, isUtcTimestamp),
              startTime: state.prePopulatedQuery.startTime,
              endTime: state.prePopulatedQuery.endTime,
              querySyntax: state.prePopulatedQuery.querySyntax
            };
            this.isPrePopulated = true;
          }
          if (state.isEditable === true) {
            this.isEditable = true;
          }
          this.form = this.archiveQueryEditorForm.buildForm(formQueryObject || this.archiveQueryEditorForm.defaultValues);
          this.sidebar.showSidebar();
        } else {
          this.sidebar.hideSidebar();
        }
      });
    this.sidebar.close.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.isPrePopulated = false;
      this.isEditable = false;
      this.validationTextError = null;
      this.store.dispatch(new ArchiveQueryEditorActions.ToggleEditorAction({
        openEditor: false,
        prePopulatedQuery: null,
        isEditable: false
      }));
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.isPrePopulated = false;
    this.isEditable = false;
    this.store.dispatch(new ArchiveQueryEditorActions.ToggleEditorAction({
      openEditor: false,
      prePopulatedQuery: null,
      isEditable: false
    }));
  }

  private static getDate(date: string, isUtc: boolean): Date {
    return isUtc ? new Date(date.slice(0, -4)) : new Date(date);
  }
}
