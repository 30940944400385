<div class="top-bar" [class.top-bar-error]="hasReachedPermutationsLimit$ | async">
  <span *ngIf="(hasReachedPermutationsLimit$ | async) === false; else reachedLimitTemplate">
    You have additional {{ permutationsLeft$ | async | number: '1.0':'en-US' }} permutations available
  </span>
  <ng-template #reachedLimitTemplate>
    <span *ngIf="hasReachedPermutationsLimit$ | async">You've reached your permutations limit</span>
  </ng-template>
  <mat-icon
    class="help-icon"
    [matTooltip]="
      'Up to&nbsp;' +
      (logs2MetricsPermutationLimit$ | async | number: '1.0':'en-US') +
      '&nbsp;permutations per account, permutations amount can be managed by editing metrics.'
    "
    matTooltipClass="coralogix-tooltip"
  >
    help_outline
  </mat-icon>
</div>

<div class="metrics-content-wrapper">
  <div class="metrics-table-container">
    <div class="metrics-table-wrapper">
      <sh-metrics-table [metrics]="metrics" (editMetric)="editMetric($event)"></sh-metrics-table>
    </div>
    <div class="permutations-in-use">
      <svg-icon svgClass="warning-icon" src="assets/icons/warning-icon.svg"></svg-icon>
      <div class="title">
        <span>Permutations in use&nbsp;</span>
        <span class="available-permutations">{{ permutationsInUse$ | async | number: '1.0':'en-US' }}</span>
        <span>/{{ logs2MetricsPermutationLimit$ | async | number: '1.0':'en-US' }}</span>
      </div>
    </div>
    <sh-logs-2-matrics-description></sh-logs-2-matrics-description>
  </div>
</div>
