import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import io from 'socket.io-client';
import { AuthService } from '../../../auth/shared/services/auth.service';
import { LivetailEntity } from '../models/livetailEntity';
import { Constants } from '@app/constants';
import { Subject } from 'rxjs';
import { ShDialogService } from '@shared/services/dialogService';

@Injectable()
export class LivetailStatsService {
  get isSocketConnected(): boolean {
    return this.socket && !this.socket.disconnected && this.socket.connected && window.navigator.onLine;
  }

  get IsRegistered(): boolean {
    return this.isRegistered;
  }
  public isRegistered: boolean = false;
  public liveTailDisconnected$ = new Subject<void>();

  public onDisconnected: EventEmitter<any> = new EventEmitter();

  private socket = io(Constants.livaTailUrl, {
    query: 'token=' + this.authService.getToken() + '&t_id=' + this.authService.getSelectedTeamId() + '&stats=true',
  });

  constructor(private authService: AuthService, private dialogService: ShDialogService) {}

  public subscribeLivetail(metaData): void {
    if (this.socket && this.socket.disconnected) {
      this.socket.connect();
    }
    this.socket.emit('livetail:subscribe', metaData);
    this.isRegistered = true;
  }

  public unsubscribeLivetail(): void {
    this.isRegistered = false;
    this.socket.emit('livetail:unsubscribe');
  }

  public cleanLivetail(): void {
    if (this.socket) {
      this.socket.removeAllListeners();
    }
  }

  public startLivetail(): Observable<Array<LivetailEntity>> {
    const observable: Observable<any> = new Observable((observer) => {
      this.socket.on('livetail:results', (data) => {
        observer.next(this.extractData(data));
      });
      return () => {
        this.onDisconnected.emit();
        this.unsubscribeLivetail();
      };
    });

    this.socket.on('reconnect', () => {
      if (this.socket && this.socket.disconnected) {
        this.socket.connect();
      }
    });

    this.socket.on('invalidParametersErr', () => {
      this.dialogService.showErrorMessage('Error, invalid filter parameters for applications and subsystems');
      this.liveTailDisconnected$.next();
      this.liveTailDisconnected$.complete();
    });

    return observable;
  }

  private extractData(res) {
    return res;
  }
}
