import { Observable } from 'rxjs';
import { MetadataService } from '../../logs/shared/services/metadata.service';
import { Metadata } from '../../logs/shared/models/query-metadata';
import { Input, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Directive()
export class formFiltersBase {
  public options: {
    applicationMetadata$?: Observable<string[]>;
    subsystemMetadata$?: Observable<string[]>;
    computerNameMetadata$?: Observable<string[]>;
    ipAddressMetadata$?: Observable<string[]>;
  } = {};
  @Input() public filtersForm: FormGroup;
  constructor(private metadataService: MetadataService) {}

  /////// get metadata function ///////
  // will be called for every change(onHide,onClear)
  public getAllRelatedMetadata(filter: Metadata) {
    this.setMetadataObservable('applicationMetadata$', 'applicationName', filter);
    this.setMetadataObservable('subsystemMetadata$', 'subsystemName', filter);
  }
  public reset() {
    this.filtersForm.reset({
      category: [],
      className: [],
      methodName: [],
      applicationName: [],
      subsystemName: [],
      computerName: [],
      severity: [],
      IPAddress: [],
      sdkId: [],
      threadId: '',
    });
  }

  private setMetadataObservable(optionName, fieldName: string, filter: Metadata) {
    if (filter) {
      const filterMetadata = _.cloneDeep<Metadata>(filter);
      filterMetadata[fieldName] = [];
      filter = filterMetadata;
    }

    this.options[optionName] = this.getMetaData(fieldName, filter);
  }
  private getMetaData(field: string, filter: Metadata): Observable<string[]> {
    return this.metadataService.getMetaData(field, filter).catch((err) => {
      console.log(err);
      return Observable.from([[]]);
    });
  }
}
