import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TypedJSON } from 'typedjson';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { Constants } from '@app/constants';
import { Alert } from '../models/alert.model';
import { Anomaly } from '../models/anomaly.model';
import { Insight } from '../models/insight.model';
import { AnomalySubTypeId, InsightTypeId } from '../models/enums';
import { AnomalyUpdateModel } from '../models/anomaly-update-model';
import { AnomalyUpdateParams } from '../models/Anomaly-update-params';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { Log } from '@app/logs/shared/models/log.entity';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { InsightsQueryModel } from '@app/insights/shared/models/insights-query.model';
import { InsightsQueryResult } from '@app/insights/shared/models/insight-query.response';
import { QueryDefRequest } from '@app/insights/shared/models/query-def.request';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { LogqueryResult } from '@app/logs/shared/models/logquery.response';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@app/auth/shared/services/auth.service';

@Injectable()
export class InsightsService {
  constructor(
    private http: HttpClient,
    private logsService: LogsService,
    private htmlRowMeasure: HtmlRowMeasure,
    private authService: AuthService,
  ) {}

  /*
   * get all insights by insight query model (time frame, page size and isActive)
   * */
  public getInsights(queryModel: InsightsQueryModel, pageSize?: number): Observable<InsightsQueryResult> {
    if (pageSize) {
      const queryDefRequest: QueryDefRequest = new QueryDefRequest(queryModel);
      queryDefRequest.queryDef.pageSize = pageSize;
      return this.http.post(Constants.insightsUrl, JSON.stringify(queryDefRequest)).map(this.extractData);
    }
    return this.authService.getCompany().pipe(
      switchMap(company => {
        const maxInsightsDisplayed = company?.settings?.max_insights_displayed ?? 500;
        const queryDefRequest: QueryDefRequest = new QueryDefRequest(queryModel);
        queryDefRequest.queryDef.pageSize = maxInsightsDisplayed;
        return this.http.post(Constants.insightsUrl, JSON.stringify(queryDefRequest)).map(this.extractData);
      }),
    );
  }
  /*
   * get insights by id
   * */
  public getInsight(insightId: string): Observable<Insight> {
    return this.http.get(Constants.insightsUrl + '/' + insightId).map(this.extractInsightData);
  }
  /*
   * Update anomaly parameters (isActive, name and severity)
   * */
  public updateAnomaly(anomaly: Anomaly, params: AnomalyUpdateParams): any {
    const updateModel: AnomalyUpdateModel = new AnomalyUpdateModel(anomaly, params);
    return this.http.post(Constants.anomalyUrl + '/' + anomaly.alertId, JSON.stringify(updateModel));
  }

  public getPageContaingIndex(
    index: number,
    query: LogQueryModel,
    currentLog: Log,
  ): Observable<{ logsRes: LogqueryResult; exactIndex: number }> {
    query.pageIndex = Math.floor(index / query.pageSize);

    return this.logsService
      .getLogs(query)
      .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
      .map(logsRes => {
        // console.log(logsRes)
        return {
          logsRes,
          exactIndex: this.getExactIndex(logsRes.logs, currentLog.logId),
        };
      });
  }

  public getLogIndexInQuery(currentLog: Log, query: LogQueryModel): Observable<{ total: number; log: Log }> {
    const queryModel = _.cloneDeep<LogQueryModel>(query);
    queryModel.pageSize = 0;
    queryModel.endDate = currentLog.timestamp + 1;
    return this.logsService
      .getLogs(queryModel)
      .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
      .map(logRes => {
        return { total: logRes.total, log: currentLog };
      });
  }

  public getInsightName(insight: Insight): string {
    if (insight.name) {
      return insight.name;
    }
    if (insight.subTypeId === AnomalySubTypeId.VolumeAnomaly) {
      return 'Error Spike Anomaly';
    }
    return 'Flow Anomaly #' + (insight as Anomaly).shortId;
  }
  /*
   * map function to map insights result to alerts and anomalies client objects
   * */
  private extractData(res: any[]): InsightsQueryResult {
    const insights: Array<Insight> = res.map(insight => {
      if (
        insight.eventTypeId === InsightTypeId.Alert ||
        insight.eventTypeId === InsightTypeId.AlertGroupByFields ||
        insight.eventTypeId === InsightTypeId.AlertRelative ||
        insight.eventTypeId === InsightTypeId.MetricAlert
      ) {
        return TypedJSON.parse(insight, Alert);
      } else {
        return TypedJSON.parse(insight, Anomaly);
      }
    });
    const insightQueryRes: InsightsQueryResult = new InsightsQueryResult();
    insightQueryRes.insights = _.sortBy(insights, insight => {
      return insight.timestamp;
    }).reverse();
    return insightQueryRes;
  }
  /*
   * map function to map insights result to alerts and anomalies client objects
   * */
  private extractInsightData(res: any): Insight {
    const result: any = res;
    if (result) {
      if (result.eventTypeId === InsightTypeId.Alert || result.eventTypeId === InsightTypeId.AlertGroupByFields) {
        return TypedJSON.parse(result, Alert) as Insight;
      } else {
        return TypedJSON.parse(result, Anomaly) as Insight;
      }
    }
    return null;
  }

  private getExactIndex(logs: Log[], id: string): number {
    const exactLog: Log = logs.find(log => log.logId === id);

    if (!exactLog) {
      console.warn('insight first logs was not found. log.id: ' + id);
    }

    return exactLog ? exactLog.index : 0;
  }
}
