import { Pipe, PipeTransform } from '@angular/core';
import { RuleMatcher } from '@app/rules/shared/models/rule.model';

@Pipe({
  name: 'extractPropFromMatcherPipe',
})
export class ExtractPropFromMatcherPipe implements PipeTransform {
  transform(value: RuleMatcher[], propToExtract: string, defaultValue: string): any {
    if (!Array.isArray(value)) {
      return defaultValue;
    }

    try {
      const currentMatcher = value.filter(matcher => matcher.field === propToExtract);
      if (!!currentMatcher?.length) {
        return currentMatcher.reduce((prev, curr) => (prev ? `${prev}, ${curr.constraint}` : curr.constraint), '');
      } else {
        return defaultValue;
      }
    } catch (e) {
      return defaultValue;
    }
  }
}
