<div class="test" style="height: 100%">
  <div #topPanelWrapper>
    <!--Menu Bar"-->
    <sh-query-panel
      [queryResultCount]="viewState.queryResultCount"
      [newQueryForm]="newQueryForm"
      [logsCount]="logsCount$ | async"
      [templateCount]="templatesCount$ | async"
      [lastExecutedQuery]="lastExecutedQuery"
      [lastExecutedQueryId]="lastExecutedQueryId"
      [selectedView]="selectedView$ | async"
      [selectedLog]="selectedLog"
      (absoluteQuery)="absoluteQuery()"
      (relativeQuery)="relativeQuery($event)"
      (tagQuery)="tagQuery($event)"
      (clear)="clearForm()"
      (clearSelectedView)="clearSelectedView()"
      (quickQueryChanged)="quickQueryChanged($event)"
      (detectGroks)="detectGroks()"
      (saveAlert)="onSaveAlert($event)"
    ></sh-query-panel>
    <sh-logs-menu
      class="logs-menu"
      [lastExecutedQueryId]="lastExecutedQueryId"
      [queryFrom]="newQueryForm"
      [showFilterPanel]="isSideBarOpen"
      [showTimeline]="viewState.showTimeline"
      [logsSearchProvider]="viewState.logsSearchProvider"
      [selectedLog]="selectedLog"
      [showFind]="viewState.showFind"
      [addWidgetEnable]="
        viewState.showTimeline && logsViewStateProvider.lastExcecutedQuery?.queryModel.type != 'templateIds'
      "
      (executeQuery)="queryWithLastTime()"
      (beforeAfter)="onBeforeAfter($event)"
      (exportLogs)="exportLogs()"
      (addWidget)="addWidget(formProvider.getModel())"
      (showWidget)="showWidget($event)"
      (toggleFilters)="isSideBarOpen = !isSideBarOpen"
      (toggleVisibility)="chartVisibility()"
    ></sh-logs-menu>
  </div>

  <div
    class="page-content-wrapper"
    shRelativeHeightCalcReducer
    [elementsToReduceHeightOfFromCurrent]="elementsToReduceFromHeight"
  >
    <sh-logs-sidebar
      class="side-bar"
      [isSideBarShown]="isSideBarOpen"
      (filterChanged)="filterChanged($event)"
      (dragging)="onSideBarDrag($event)"
      (dragEnd)="onSideBarDragEnd($event)"
      (initialized)="onSideBarInitialized($event)"
    ></sh-logs-sidebar>

    <div class="logs-viewport" [ngStyle]="{ 'width.px': logsViewPortWidth }">
      <!--Left Filters"-->
      <!--Graph"-->
      <div [hidden]="!viewState.showTimeline" class="chart-container" style="width: 100%;">
        <div style="background-color: white !important;width: 100%;">
          <sh-statistics-chart
            [zoomableChart]="false"
            [height]="80"
            [clickable]="false"
            [redraw]="isSideBarOpen"
            [queryDefinition]="statisticsQueryDefinition"
            (chartTimeRangeSelected)="onChartTimeRangeSelected($event)"
            (basicChartInit)="onBasicChartInit($event)"
            [addInvertedClassToLoader]="true"
            [enableLoading]="true"
          ></sh-statistics-chart>
        </div>
      </div>

      <div
        class="main-content"
        [class.grid-size]="viewState.showTimeline"
        [class.grid-size-noTimeline]="!viewState.showTimeline"
        [class.company-plan-status-bar]="isCompanyPlanStatusBarOpened"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <sh-statistics-chart-modal></sh-statistics-chart-modal>
  <sh-logs-info-panel
    [searchProvider]="viewState.logsSearchProvider"
    [selectedLog]="selectedLog"
    [isFiltersEnabled]="true"
    [highlightEnabled]="viewState.showFind"
    (showJsonWidgetEvent)="showWidget($event)"
    (filterChanged)="filterChanged($event)"
    (saveAlert)="onSaveAlert($event)"
    [isLogNavigationRequired]="false"
    #infoPanel
  ></sh-logs-info-panel>
</div>
