import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LivetailService } from '../shared/services/livetail.service';
import { Subscription, timer } from 'rxjs';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { LivetailEntity } from '../shared/models/livetailEntity';
import * as _ from 'lodash';
import { isNullOrUndefined } from 'util';
import { LogsInfoPanelComponent } from '@app/logs/logs-info-panel/logs-info-panel.component';
import { UserSettingsService } from '@app/user/shared/user-settings.service';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { State } from '@app/app.reducers';
import { LogsFilterGridColumnsActions } from '@app/logs/shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sh-livetail-terminal',
  templateUrl: './livetail-terminal.component.html',
  styleUrls: ['./livetail-terminal.component.scss'],
})
export class LivetailTerminalComponent implements OnDestroy, OnInit {
  public livetailLogs: Array<LivetailEntity> = new Array<LivetailEntity>();
  public keysToFilter: string[] = [];
  public livetailLogsbuffer: Array<LivetailEntity> = new Array<LivetailEntity>();
  public connection: Subscription;
  public grepInterval: any;
  public isFiltersPanelVisible: boolean = false;
  public textInput: string = '';
  public selectedLog: any;
  public isLivetailStarted: boolean = false;
  public isConnected: boolean = false;
  public metadata: Metadata = new Metadata();
  @ViewChild('infoPanel', { static: true })
  public infoPanel: LogsInfoPanelComponent;
  public scrollToBottomEvent: EventEmitter<any> = new EventEmitter();
  public isPrettified = false;
  public liveTailTimer$ = timer(0, 5000);

  constructor(
    private livetailService: LivetailService,
    private userSettingsProvider: UserSettingsProvider,
    private userSettingsService: UserSettingsService,
    private store: Store<State>,
  ) {}

  public ngOnInit(): void {
    this.initializeKeysToFilter();
    this.store.dispatch(new LogsFilterGridColumnsActions.LogsFilterGridTemplateColumnsInit());
  }

  public ngOnDestroy(): void {
    this.onStop();
    this.livetailService.liveTailDisconnected$.next();
    this.livetailService.liveTailDisconnected$.complete();
  }

  public scrollToBottom(): void {
    this.scrollToBottomEvent.emit();
  }

  public assignKeysToFilter(keysToFilter: string[], isComponentInitialized: boolean = true): void {
    this.keysToFilter = keysToFilter;
    if (isComponentInitialized) {
      this.userSettingsProvider.userSettings.livetailFilteredKeys = keysToFilter;
      this.userSettingsProvider.userSettingsChanged.emit(this.userSettingsProvider.userSettings);
      this.userSettingsService
        .updateUserSettings(this.userSettingsProvider.userSettings)
        .first()
        .subscribe();
    }
  }

  public toggleFiltersPanelStatus(): void {
    this.isFiltersPanelVisible = !this.isFiltersPanelVisible;
  }

  public grepHighlights(input: LivetailEntity[], grep: any): any {
    if (!grep || isNullOrUndefined(grep) || grep.phrase === '' || isNullOrUndefined(grep.phrase)) {
      return input;
    }
    let caseIn = '';
    if (grep.options && grep.options.includes('-F')) {
      grep.phrase = grep.phrase.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
    if (grep.options && grep.options.includes('-i')) {
      caseIn = 'i';
    }
    if (grep.phrase.startsWith('"') && grep.phrase.endsWith('"')) {
      grep.phrase = grep.phrase.substring(1, grep.phrase.length);
      grep.phrase = grep.phrase.substring(0, grep.phrase.length - 1);
    }
    const regex = RegExp(grep.phrase, caseIn + 'g');
    const trimedLogs = [];
    const result = input.filter((livetilEntity: LivetailEntity) => {
      let matched = null;
      const matchedText = livetilEntity.text ? livetilEntity.text.match(regex) : null;
      const matchedSubsystem = livetilEntity.subsystemName ? livetilEntity.subsystemName.match(regex) : null;
      const matchedApplication = livetilEntity.applicationName ? livetilEntity.applicationName.match(regex) : null;
      const matchedSeverity = livetilEntity.severity ? livetilEntity.severity.match(regex) : null;
      const matchedComputerName = livetilEntity.computerName ? livetilEntity.computerName.match(regex) : null;
      if (matchedText || matchedSubsystem || matchedApplication || matchedSeverity || matchedComputerName) {
        matched = [];
      }

      if (matchedText) {
        matched.push(...matchedText);
      }
      if (matchedSubsystem) {
        matched.push(...matchedSubsystem);
      }
      if (matchedApplication) {
        matched.push(...matchedApplication);
      }
      if (matchedSeverity) {
        matched.push(...matchedSeverity);
      }
      if (matchedComputerName) {
        matched.push(...matchedComputerName);
      }

      let options = grep.options;
      if (!options) {
        options = '-i';
      }
      if (matchedText && options.includes('-o')) {
        _.uniq(matched).forEach((textMatch) => {
          const livetaillog = { text: '', highlights: [] };
          livetaillog.text = textMatch.toString();
          trimedLogs.push(livetaillog);
          livetaillog.highlights.push(..._.uniq(matched));
        });
      }
      if (matched && !options.includes('-v')) {
        if (!livetilEntity.highlights) {
          livetilEntity.highlights = [];
        }

        livetilEntity.highlights.push(..._.uniq(matched));
        return livetilEntity;
      }
      if (!matched && options.includes('-v')) {
        return livetilEntity;
      }
      return livetilEntity;
    });

    return trimedLogs.length > 0 ? trimedLogs : result;
  }

  public onGrepInput(value: string): void {
    this.textInput = value;
    this.onStop();
    this.isLivetailStarted = true;
    this.isConnected = true;
    this.subscribeLivetail(value);
  }

  public parseGrep(value: any): any {
    const splitbyGrep = RegExp('\\s*\\|\\s*grep\\s*');
    const matchPhraseAndOptions = RegExp('((?:\\s*\\-(?:[a-zA-Z]+(?:\\s[0-9]+)?)\\s*)*)(.*)');
    const splitPipe = value.split(splitbyGrep);
    const greps = [];
    splitPipe.forEach((item) => {
      if (item) {
        const match = item.match(matchPhraseAndOptions, 'g');
        if (match) {
          let options;
          let phrase;
          if (match[1]) {
            // grep option -i -v etc..
            options = match[1].split(' ').filter((option) => option.startsWith('-'));
          }
          if (match[2]) {
            // grep the actual phrase
            phrase = match[2];
          }

          const grep = {
            phrase,
            options,
          };
          greps.push(grep);
        }
      }
    });

    return greps;
  }

  public onStop(): void {
    this.isLivetailStarted = false;
    this.isConnected = false;
    if (this.connection) {
      this.connection.unsubscribe();
    }
    if (this.grepInterval) {
      clearInterval(this.grepInterval);
    }

    this.livetailService.liveTailDisconnected$.next();

    this.livetailService.cleanLivetail();
    this.livetailLogsbuffer = [];
  }

  public subscribeLivetail(value: any): any {
    this.livetailService.subscribeLivetail({ ...this.userSettingsProvider.userSettings.queryMetadata, grep: value });

    this.connection = this.livetailService.startLivetail().subscribe(
      (res: LivetailEntity[]) => {
        let toBuffer: LivetailEntity[] = res
          .map((livetailEntity: LivetailEntity) => {
            livetailEntity.text = this.livetailService.getTextObjectAsJson(livetailEntity, this.keysToFilter);
            return livetailEntity;
          })
          .filter((livetailEntity: LivetailEntity) => !!livetailEntity.text);
        this.parseGrep(value).forEach((grep) => {
          toBuffer = this.grepHighlights(toBuffer, grep);
        });
        this.livetailLogsbuffer.push(...toBuffer);
        return true;
      },
      (err) => console.log(err),
    );

    this.grepInterval = setInterval(() => {
      if (this.livetailLogsbuffer.length <= 0) {
        return;
      }

      const factor = Math.ceil(this.livetailLogsbuffer.length * 0.05);
      if (this.livetailLogs.length > 1000) {
        this.livetailLogs.splice(0, factor);
      }
      if (this.livetailLogsbuffer.length > 0) {
        const logs = this.livetailLogsbuffer.splice(0, factor);
        if (logs.length > 0) {
          this.livetailLogs.push(...logs);
          this.scrollToBottom();
        }
      }
    }, 1);

    this.liveTailTimer$.pipe(takeUntil(this.livetailService.liveTailDisconnected$)).subscribe(() => {
      this.isConnected = this.livetailService && this.livetailService.isSocketConnected && this.isLivetailStarted;
      this.isLivetailStarted = this.isConnected;
      if (this.isLivetailStarted) {
        this.livetailService.subscribeLivetail({
          ...this.userSettingsProvider.userSettings.queryMetadata,
          grep: value,
        });
      } else {
        this.livetailService.unsubscribeLivetail();
      }
    });
  }
  public onclick(item: any): void {
    this.selectedLog = item;
    this.infoPanel.toggleOpen();
  }
  public onClear(): void {
    this.livetailLogs = [];
  }

  private initializeKeysToFilter(): void {
    const filteredKeys: string[] = this.userSettingsProvider.userSettings.livetailFilteredKeys;
    this.isFiltersPanelVisible = !!filteredKeys.length;
    this.assignKeysToFilter(filteredKeys, false);
  }
}
