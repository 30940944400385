import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Constants } from '../../../constants';
import {
  ChangeEnrichmentResponse,
  CustomEnrichmentsResponse,
  UploadUrlResponse,
} from '@app/settings/enrichments-settings/custom-enrichment.model';
import { InterceptorSkipHeader } from '@shared/services/token-interceptor.service';

@Injectable()
export class CustomEnrichmentsService {
  constructor(private http: HttpClient) {
  }

  public getEnrichments(): Observable<CustomEnrichmentsResponse> {
    return this.extractCustomEnrichments(this.http.get(Constants.customEnrichmentsUrl));
  }

  public getUploadUrl(fileName: string): Observable<UploadUrlResponse> {
    return this.extractUploadUrlResponse(
      this.http.post(`${Constants.customEnrichmentsUrl}/getUploadUrl`, { fileName: fileName }));
  }

  public uploadToS3(preSignedUrl: string, contentType: string, file: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': contentType }).set(InterceptorSkipHeader, '');
    return this.http.put(preSignedUrl, file, {
      headers: headers,
    }).map((res) => res);
  }

  public addCustomEnrichment(name: string, description: string, s3Key: string): Observable<ChangeEnrichmentResponse> {
    return this.extractCustomEnrichmentResponse(this.http.post(Constants.customEnrichmentsUrl, {
      name: name,
      description: description,
      s3Key: s3Key,
    }));
  }

  public updateCustomEnrichment(enrichmentId: number, name: string,
                                description: string, s3Key: string): Observable<ChangeEnrichmentResponse> {
    return this.extractCustomEnrichmentResponse(this.http.put(Constants.customEnrichmentsUrl, {
      enrichmentId: enrichmentId,
      name: name,
      description: description,
      s3Key: s3Key,
    }));
  }

  public deleteCustomEnrichment(enrichmentId: number): Observable<ChangeEnrichmentResponse> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { enrichmentId: enrichmentId },
    };
    return this.extractCustomEnrichmentResponse(
      this.http.delete(Constants.customEnrichmentsUrl, httpOptions),
    );
  }

  private extractCustomEnrichments(response: any): Observable<CustomEnrichmentsResponse> {
    return response.map((res) => res);
  }

  private extractCustomEnrichmentResponse(response: any): Observable<ChangeEnrichmentResponse> {
    return response.map((res) => res);
  }

  private extractUploadUrlResponse(response: any): Observable<UploadUrlResponse> {
    return response.map((res) => res);
  }
}
