import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class AlertConditions {
  @jsonMember
  public conditionOperator: number;
  @jsonMember
  public conditionThreshold: number;
  @jsonMember
  public conditionTimeframe: number;
}
