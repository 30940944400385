import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogBodyComponent } from './dialog-body.component';

@NgModule({
  declarations: [DialogBodyComponent],
  imports: [
    CommonModule
  ],
  exports: [DialogBodyComponent]
})
export class DialogBodyModule { }
