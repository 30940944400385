<div class="line-tooltip">
  <div class="line-tooltip__titles">
    <div class="line-tooltip__time-from-start">{{timeGap}}</div>
    <div class="line-tooltip__amount-title">{{chartTooltipDataTitle}}</div>
  </div>
  <div class="line-tooltip__data">
    <div class="tooltip-data-item" *ngFor="let item of series; let last = last">
      <div class="tooltip-data-item__rectangle" [ngStyle]="{'background-color': item.color}"></div>
      <div class="tooltip-data-item__title">{{item?.series?.name}}</div>
      <div class="tooltip-data-item__data">{{item.y}}
        <span *ngIf="!last" class="tooltip-data-item__data{{pointGap > 0 ? '-positive-growth' : '-negative-growth'}}">
          ({{pointGap > 0 ? '+' : pointGap === 0 ? '' : ''}}{{pointGap}})
        </span>
      </div>
    </div>
  </div>
  <button [attr.datafld]="dataIndex" class="chart-tooltip-button line-tooltip__action-btn">
    {{tooltipButtonText}}
  </button>
  <div class="line-tooltip__pointer"></div>
</div>
