<div class="field-visualization">
  <div class="header">
    <div class="title">FIELD VISUALIZATION</div>
    <div class="field-title">{{ title }}</div>
    <div class="pin-graph" (click)="pinGraph()">
      <svg-icon src="assets/icons/pin-graph.svg" class="icon" svgClass="cgx-icon-primary"></svg-icon>
      <div class="pin-graph-title">PIN GRAPH</div>
    </div>
  </div>

  <mat-divider class="divider"></mat-divider>

  <div class="content-container">
    <div class="chart-container" #chart [hidden]="!(hasData$ | async) && chartData?.length"></div>

    <ng-container *ngIf="(hasData$ | async) && !chartData?.length">
      <div class="no-data-text">No data is available</div>
    </ng-container>

    <div class="no-data" *ngIf="!(hasData$ | async)">
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <div class="logarithmic-toggle-container" *ngIf="(hasData$ | async) && chartData?.length">
      <mat-slide-toggle class="logarithmic-toggle" [checked]="logarithmicView" (change)="logarithmicViewToggle()">
        <span>LOGARITHMIC SCALE</span>
      </mat-slide-toggle>
    </div>
  </div>
</div>

<div
  class="label-dropdown-menu"
  (clickOutside)="closeLabelsMenu()"
  *ngIf="showLabelMenu$ | async"
  [ngStyle]="labelsMenuStyle"
>
  <div class="item" (click)="addFieldToQuery()" data-test="field-visualization-add-to-query">
    <svg-icon src="assets/icons/icon-search.svg" class="icon" svgClass="cgx-icon-secondary icon"></svg-icon>
    <div class="caption">Add to Query</div>
  </div>
  <mat-divider></mat-divider>
  <div
    class="item"
    (click)="addFieldAsFilter()"
    data-test="field-visualization-add-as-filter"
    [class.disabled]="!fullJSONPath"
  >
    <svg-icon src="assets/icons/icon-filter.svg" class="icon" svgClass="cgx-icon-secondary icon"></svg-icon>
    <div class="caption">Add as Filter</div>
  </div>
</div>
