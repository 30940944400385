import { Injectable } from '@angular/core';
import { Constants } from '../../../constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ArchivingProviderModel } from '../../../settings/shared/models/archivingProviderModel';

@Injectable()
export class ArchivingProvidersService {
  private archivingProviders$: Observable<any>;

  private archivingProviderUrl: string =
    Constants.GetUrl() + Constants.archivingProvider;

  constructor(private http: HttpClient) {}

  public getArchivingProviders(): Observable<ArchivingProviderModel[]> {
    if (this.archivingProviders$) {
      return this.archivingProviders$;
    } else {
      const obs = (this.archivingProviders$ = this.http
        .get(this.archivingProviderUrl)
        .map((res) => this.extractData(res))
        .map((res) => {
          this.archivingProviders$ = null;
          return res;
        })
        .catch((error) => this.handleError(error)));
      return obs;
    }
  }

  public saveArchivingProviders(model: ArchivingProviderModel): Observable<ArchivingProviderModel> {
    const observable = this.http
      .post(this.archivingProviderUrl + '/edit', model)
      .map(this.extractArchivingProviderData);

    return observable;
  }

  public testArchivingProvider(model: ArchivingProviderModel): Observable<any> {
    const observable = this.http
      .post(this.archivingProviderUrl + '/test', model)
      .map((res: any) => {
        if (res && res.message) {
          res.message = res.message.replace(/([A-Z])/g, ' $1').trim();
        }
        return res;
      });
    return observable;
  }

  public extractArchivingProviderData(res: any): ArchivingProviderModel {
    return res ? res as ArchivingProviderModel : new ArchivingProviderModel();
  }

  public extractData(res: any): ArchivingProviderModel[] {
    if (!Array.isArray(res) || res.length === 0) {
      return [];
    }

    return res as ArchivingProviderModel[];
  }

  private handleError(error: any): Observable<any> {
    const errMsg = error.message
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';

    // tslint:disable-next-line:no-console
    console.log(errMsg); // log to console instead

    return Observable.throw(errMsg);
  }
}
