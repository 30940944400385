import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { QueryParams } from './query-params.model';
import { QueryTypes } from './query-types.options';
import { SortModel } from './sort.model';
import * as _ from 'lodash';
import { Constants } from '../../../constants';

@jsonObject
export class LogQueryModel {
  @jsonMember
  public name: string;
  @jsonMember
  public type: string = QueryTypes.FREE; // QueryTypes
  @jsonMember
  public startDate: number;
  @jsonMember
  public endDate: number;
  @jsonMember
  public pageSize: number = Constants.LOGS_QUERY_PAGE_SIZE;
  @jsonMember
  public pageIndex: number = 0;
  @jsonMember
  public queryParams: QueryParams = new QueryParams();
  @jsonMember
  public isNew: boolean;
  @jsonMember
  public cacheQueryId: string;
  @jsonMember
  public eventId: string;
  @jsonMember
  public tagId: number;
  @jsonMember
  public selectedViewId: number;
  @jsonArrayMember(SortModel)
  public sortModel: SortModel[] = [];

  constructor(startDate?: Date, endDate?: Date) {
    if (startDate) {
      startDate.setMilliseconds(0);
      this.startDate = new Date(startDate).getTime();
    } else {
      this.startDate = new Date().getTime();
    }

    if (endDate) {
      endDate.setMilliseconds(0);
      this.endDate = new Date(endDate).getTime();
    } else {
      this.endDate = new Date().getTime();
    }

    this.sortModel.push(new SortModel());
  }

  public isSortModelEqual(sortModel: any): boolean {
    const tmpSortModel: SortModel[] = sortModel.map((model) => new SortModel(model.colId, model.sort));
    const defaultSortModel = tmpSortModel.filter((model) => model.hasDefultSorting());
    if (defaultSortModel.length) {
      return _.isEqual(tmpSortModel, this.sortModel);
    }
    return _.isEqual(tmpSortModel, this.sortModel.slice(0, this.sortModel.length - 1));
  }

  public setSortModel(sortModel: any): void {
    const newSortModel: SortModel[] = [];
    let hasTimestampSorting = false;
    for (const item of sortModel) {
      const model: SortModel = new SortModel(item.colId, item.sort);
      if (model.hasDefultSorting()) {
        hasTimestampSorting = true;
      }
      newSortModel.push(model);
    }
    if (!hasTimestampSorting) {
      // add default sorting model, timestamp asc
      newSortModel.push(new SortModel());
    }
    this.sortModel = newSortModel;
  }

  public isDefaultSortValue(): boolean {
    return this.sortModel.length === 1 && this.sortModel[0].isDefaultValue();
  }

  public returnToDefaultSortValue(): void {
    const defaultSortModel: SortModel[] = [];
    // create default sorting model, timestamp asc
    const timestampSortModel: SortModel = new SortModel();
    defaultSortModel.push(timestampSortModel);
    this.sortModel = defaultSortModel;
  }
}
