import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { formFiltersBase } from '../../models/formFiltersBase';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { MetadataService } from '@app/logs/shared/services/metadata.service';

const ALL_APPLICATIONS = 'All applications';
const ALL_SUBSYSTEMS = 'All subsystems';

@Component({
  selector: 'sh-metadata-selector',
  templateUrl: './metadata-selector.component.html',
  styleUrls: ['./metadata-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetadataSelectorComponent extends formFiltersBase implements OnInit, OnDestroy {
  @Input() set query(value: any) {
    this.queryModel = value;
    if (this.queryModel && Object.keys(this.queryModel).length > 0) {
      this.applicationName =
        this.queryModel.queryParams.metadata.applicationName.length > 0
          ? this.queryModel.queryParams.metadata.applicationName
          : [ALL_APPLICATIONS];
      this.subsystemName =
        this.queryModel.queryParams.metadata.subsystemName.length > 0
          ? this.queryModel.queryParams.metadata.subsystemName
          : [ALL_SUBSYSTEMS];
    }
  }
  get query(): any {
    return this.queryModel;
  }

  public get applicationNameInputText(): string {
    return this.applicationName.length > 1 ? this.applicationName.length + ' Applications' : this.applicationName[0];
  }
  public applicationName: string[] = [ALL_APPLICATIONS];
  public applicationNameOptions: any;
  public subsystemName: string[] = [ALL_SUBSYSTEMS];
  public subsystemNameOptions: any;
  public clearUserSettings: Subscription;
  public queryModel: any;
  @Input()
  public disabled: boolean;

  @Input()
  public optionItemsClass: string = '';

  @Output()
  public onSelectionChanged: EventEmitter<void> = new EventEmitter<void>();

  private subs: Subscription[] = [];
  constructor(metadataService: MetadataService) {
    super(metadataService);
  }

  public ngOnInit() {
    this.applicationNameOptions = [];
    this.subsystemNameOptions = [];
    this.filtersForm = this.createMetadataFrom();
    this.getAllRelatedMetadata(null);
    this.options['applicationMetadata$'].first().subscribe(res => {
      this.applicationNameOptions = res;

      setTimeout(() => {
        if (this.queryModel && Object.keys(this.queryModel).length > 0) {
          this.applicationName =
            this.queryModel.queryParams.metadata.applicationName?.length > 0
              ? this.queryModel.queryParams.metadata.applicationName
              : [ALL_APPLICATIONS];
        }
      }, 200);
    });
    this.options['subsystemMetadata$'].first().subscribe(res => {
      this.subsystemNameOptions = res;

      setTimeout(() => {
        if (this.queryModel && Object.keys(this.queryModel).length > 0) {
          this.subsystemName =
            this.queryModel.queryParams.metadata.subsystemName.length > 0
              ? this.queryModel.queryParams.metadata.subsystemName
              : [ALL_SUBSYSTEMS];
        }
      }, 200);
    });
    const filterAppSub = this.filtersForm.get('applicationName').valueChanges.subscribe(value => {
      this.changeSelectedApplication(value);
    });
    const filterSubsystemSub = this.filtersForm.get('subsystemName').valueChanges.subscribe(value => {
      this.changeSelectedSubsystem(value);
    });
    this.subs.push(filterAppSub, filterSubsystemSub);
  }

  public changeSelectedSubsystem(e) {
    const currSubVal = this.filtersForm.get('subsystemName').value;
    const prevSelection = _.cloneDeep(this.queryModel.queryParams.metadata.subsystemName);
    const currentSelection = _.cloneDeep(currSubVal);
    this.queryModel.queryParams.metadata.subsystemName = [];

    // Case deselect all, choose All subsystems
    if (currentSelection.length === 0) {
      this.subsystemName = [ALL_SUBSYSTEMS];
      if (prevSelection.length === 0) {
        this.onSelectionChanged.emit();
        return;
      }
    } else {
      // Case choosing a subsystems and "All subsystems" was selected, remove the selection from "All subsystems"
      if (prevSelection.length === 0) {
        if (currentSelection.length > 0) {
          this.subsystemName = currentSelection;
          this.queryModel.queryParams.metadata.subsystemName = currSubVal;
        }
      } else {
        // Case choosing "All subsystems", But one or more subsystems was selected
        if (currentSelection[0] === ALL_SUBSYSTEMS) {
          this.subsystemName = [ALL_SUBSYSTEMS];
        } else {
          this.queryModel.queryParams.metadata.subsystemName = currSubVal;
        }
      }
    }

    this.onSelectionChanged.emit();
  }

  public changeSelectedApplication(e) {
    const currAppVal = this.filtersForm.get('applicationName').value;
    const prevSelection = _.cloneDeep(this.queryModel.queryParams.metadata.applicationName);
    const currentSelection = _.cloneDeep(currAppVal);
    this.queryModel.queryParams.metadata.applicationName = [];

    // Case deselect all, choose All application
    if (currentSelection.length === 0) {
      this.applicationName = [ALL_APPLICATIONS];
      if (prevSelection.length === 0) {
        this.onSelectionChanged.emit();
        return;
      }
    } else {
      // Case choosing a subsystems and "All application" was selected, remove the selection from "All application"
      if (prevSelection.length === 0) {
        if (currentSelection.length > 0) {
          this.applicationName = currentSelection;
          this.queryModel.queryParams.metadata.applicationName = currAppVal;
        }
      } else {
        // Case choosing "All application", But one or more applications was selected
        if (currentSelection[0] === ALL_APPLICATIONS) {
          this.applicationName = [ALL_APPLICATIONS];
        } else {
          this.queryModel.queryParams.metadata.applicationName = currAppVal;
        }
      }
    }

    // Update subsystems list
    let filter = null;
    if (this.applicationName[0] !== ALL_APPLICATIONS) {
      filter = new Metadata();
      filter.applicationName = currAppVal;
    }
    this.getAllRelatedMetadata(filter);
    this.options['subsystemMetadata$'].first().subscribe(res => {
      this.subsystemNameOptions = res;
      this.subsystemName = [ALL_SUBSYSTEMS];
      this.queryModel.queryParams.metadata.subsystemName = [];
      this.onSelectionChanged.emit();
    });
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.clearUserSettings) {
      this.clearUserSettings.unsubscribe();
    }
  }

  private createMetadataFrom() {
    return new FormGroup({
      category: new FormControl([]),
      className: new FormControl([]),
      methodName: new FormControl([]),
      applicationName: new FormControl(this.queryModel ? this.queryModel.queryParams.metadata.applicationName : []),
      subsystemName: new FormControl(this.queryModel ? this.queryModel.queryParams.metadata.subsystemName : []),
      computerName: new FormControl([]),
      severity: new FormControl([]),
      IPAddress: new FormControl([]),
      sdkId: new FormControl([]),
      threadId: new FormControl(''),
    });
  }
}
