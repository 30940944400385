import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getLogsGridColumns, State } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { LogsViewsActions } from '../../shared/state/logs-views/logs-views.actions';
import SaveLogsViewAction = LogsViewsActions.SaveLogsViewAction;
import { LogsView } from '../../shared/models/logs-view.model';
import { UserSettingsProvider } from '../../../user/shared/userSettingsProvider';
import { LogsQuerFormProvider } from '../../logs-query/logs-query-form.provider';
import * as _ from 'lodash';
import { LogsViewStateProvider } from '../../shared/services/logsViewState.provider';

@Component({
  selector: 'sh-logs-save-view',
  templateUrl: './logs-save-view.component.html',
  styleUrls: ['./logs-save-view.component.scss'],
})
export class LogsSaveViewComponent implements OnInit {
  @Output() public onSave: EventEmitter<any> = new EventEmitter<any>();
  @Input() public editView: LogsView = null;

  public saveViewForm: FormGroup;

  constructor(
    private store: Store<State>,
    public userSettingsProvider: UserSettingsProvider,
    private formProvider: LogsQuerFormProvider,
    private logsViewStateProvider: LogsViewStateProvider,
  ) {
    this.saveViewForm = new FormGroup({
      viewName: new FormControl('', Validators.required),
      visibility: new FormControl(''),
      includeQueryParams: new FormControl(''),
      isDefault: new FormControl(''),
    });
  }

  public ngOnInit(): void {
    if (this.editView) {
      this.saveViewForm.get('viewName').setValue(this.editView.name);
      this.saveViewForm
        .get('visibility')
        .setValue(this.editView.isPublic ? 'public' : 'private');
      this.saveViewForm
        .get('isDefault')
        .setValue(
          this.userSettingsProvider.userSettings.logsDefaultGridViewId ===
            this.editView.id,
        );
      this.saveViewForm
        .get('includeQueryParams')
        .setValue(this.editView.queryHistoryModel ? true : false);
    } else {
      this.saveViewForm.get('visibility').setValue('private');
    }
  }

  public save(): void {
    if (this.saveViewForm.valid) {
      this.store
        .select(getLogsGridColumns)
        .first()
        .subscribe((gridColumns) => {
          let logView = new LogsView();

          if (this.editView) {
            logView = _.cloneDeep(this.editView);
          }

          logView.name = this.saveViewForm.get('viewName').value;
          logView.isPublic =
            this.saveViewForm.get('visibility').value === 'public';
          logView.logsGridColDefs = gridColumns.colDefs;
          logView.logsGridColState = gridColumns.colState;
          logView.isDefault = this.saveViewForm.get('isDefault').value;

          if (this.saveViewForm.get('includeQueryParams').value) {
            logView.queryHistoryModel = _.cloneDeep(
              this.logsViewStateProvider.lastExcecutedQuery,
            );
            logView.queryHistoryModel.queryModel = _.cloneDeep(
              this.formProvider.getModel(),
            );
          } else {
            logView.queryHistoryModel = null;
          }

          this.store.dispatch(new SaveLogsViewAction(logView));
          this.onSave.emit();
        });
    }
  }
}
