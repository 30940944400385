import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Insight} from '../../shared/models/insight.model';
import {InsightGroup} from '../../shared/models/insight-group';
import {AggregationType} from '../../shared/models/enums';
import {insightsFilter} from '../../shared/models/insight-filter-model';
import {UpdatedAnomaliesModel} from '../../shared/models/updated-anomalies-model';
import {Anomaly} from '../../shared/models/anomaly.model';
import {InsightsFilterPipe} from '../../shared/pipes/insights-filter.pipe';

@Component({
  selector: 'sh-insights-timeline',
  templateUrl: './insights-timeline.component.html',
  styleUrls: ['insights-timeline.component.scss'],
  animations: [
    trigger('childsViewState', [
      state('visible', style({transform: 'translateX(0)'})),
      transition('void => *', [style({transform: 'translateX(100%)'}), animate('300ms ease-out')]),
      transition('* => void', [animate('300ms ease-in', style({transform: 'translateX(100%)'}))]),
    ]),
    trigger('rootViewState', [
      state('collapsed', style({transform: 'translateX(0)'})),
      transition('void => *', [style({transform: 'translateX(-30%)'}), animate(300)]),
      transition('* => void', [animate(300, style({transform: 'translateX(-30%)'}))]),
    ]),
  ],
})
export class InsightsTimelineComponent {
  @Input() set insights(value: Insight[]) {
    this._insights = value;
    if (this.autoSelectFirst && this.insights && this.insights.length) {
      const filteredInsights = new InsightsFilterPipe().transform(this._insights, this.filters);
      if (filteredInsights && filteredInsights.length) {
        this.onInsightClicked.emit(filteredInsights[0]);
      }
    }
  }

  get insights(): Insight[] {
    return this._insights;
  }

  public childViewState: string = 'collapsed';
  public filterText: string;
  public rootViewState: string = 'visible';
  public selectedItemsViewPort: any[];
  public childFilterText: string;
  public viewPortItems: any[];

  @Input() public currentInsight: Insight;
  @Input() public autoSelectFirst: boolean = false;

  public selectedInsightGroup: InsightGroup;
  public showInsightOccurences: boolean;
  @Output() public onInsightClicked: EventEmitter<Insight> = new EventEmitter<Insight>();
  @Output() public onUpdatedAnomalies: EventEmitter<UpdatedAnomaliesModel> = new EventEmitter<UpdatedAnomaliesModel>();
  public aggregationType = AggregationType;
  public insightsAggregationType: number = AggregationType.Daily;
  public filters: insightsFilter;

  @Input() public filterType: string;
  @Input() public isLoading = false;

  private _insights: Insight[];

  constructor() {
  }

  public openInsightOccurrences(insightGroup: InsightGroup) {
    this.selectedInsightGroup = insightGroup;
    this.showInsightOccurences = true;
    this.childViewState = 'visible';
    this.rootViewState = 'collapsed';
  }

  public closeInsightOccurrences() {
    this.showInsightOccurences = false;
    this.selectedInsightGroup = null;
  }

  public emitInsight(insight: Insight) {
    this.onInsightClicked.emit(insight);
  }

  public showGroupedInsights() {
    this.showInsightOccurences = false;
  }

  public onFilterChanged(filter: insightsFilter) {
    // TODO: NG4 remove do check
    setTimeout(() => {
      this.filters = filter;
    }, 0);
  }

  public updateAnomalies(updateParamsModel: UpdatedAnomaliesModel) {
    this.onUpdatedAnomalies.emit(updateParamsModel);
  }

  public getInsightGroupTitle(insightGroup: InsightGroup): string {
    if (insightGroup) {
      if (insightGroup.insights[0].name) {
        return insightGroup.insights[0].name;
      }
      const shortId = (insightGroup.insights[0] as Anomaly).shortId;
      return 'Anomaly #' + shortId;
    }
    return 'Insights';
  }

  public clearFilters() {
    this.filterText = '';
  }

  public isInsightGroupSelected(group: InsightGroup, id: string): boolean {
    if (id && group) {
      const insight = group.insights.filter(i => i.id === id);
      return insight.length !== 0;
    }
    return false;
  }
}
