<h1 class="tutorial-title">How to configure your bucket</h1>
<mat-card class="white-theme">
  <mat-card-content class="s3-card-content">
    <p>
      1. Go to the
      <strong>TCO</strong>
      tab and click on
      <strong>Archive</strong>
      . Add the Region code and Bucket name and
      <strong>Save</strong>
      .
    </p>
    <img src="../../../../../assets/aws-tutorials/s3-tutorial/s3.a.png" />
    <p>
      2. Go to Service -> Storage -> S3 and select your desired S3 Bucket from the bucket list.
    </p>
    <img src="../../../../../assets/aws-tutorials/s3-tutorial/s3.b.png" style="max-width: 300px" />
    <p>
      3. Navigate to the
      <strong>Permissions</strong>
      tab.
    </p>
    <img src="../../../../../assets/aws-tutorials/s3-tutorial/s3.c.png" />
    <p>
      4. In
      <strong>Block public access (bucket settings)</strong>
      click
      <strong>‘Edit’</strong>
      . Uncheck
      <strong>Block all public access</strong>
      and then check all bottom three options, leaving only
      <strong>Block public access to buckets and objects granted through new access control lists (ACLs)</strong>
      unchecked. Click
      <strong>Save changes</strong>
      .
    </p>
    <img src="../../../../../assets/aws-tutorials/s3-tutorial/s3.d.png" />
    <p>
      5. Still Under
      <strong>Permissions</strong>
      scroll down to
      <strong>Access control list (ACL)</strong>
      and click
      <strong>Edit</strong>
      .
    </p>
    <img src="../../../../../assets/aws-tutorials/s3-tutorial/s3.e.png" />
    <p>
      6. Click on
      <strong>Add grantee</strong>
      and Insert Coralogix canonical id:
      <strong>fa35ef450b07d311b09810445df9c1c4a316118d1899e4cd3db935414e4ba62d</strong>
      . Check the
      <strong>Write Objects</strong>
      checkbox to give Coralogix write objects permissions and click
      <strong>Save changes</strong>
      .
    </p>
    <img src="../../../../../assets/aws-tutorials/s3-tutorial/s3.f.png" />
    <p>
      7. If you are also wishing to use Coralogix
      <a href="https://coralogix.com/tutorials/reindex/" target="_blank">Reindex</a>
      feature or Coralogix
      <a href="https://coralogix.com/tutorials/archive-query/" target="_blank">Archive Query</a>
      feature, later on, you will need to check the
      <strong>List Objects</strong>
      and
      <strong>Read Bucket ACL</strong>
      options. Click
      <strong>Save changes</strong>
      .
    </p>
    <img src="../../../../../assets/aws-tutorials/s3-tutorial/s3.g.png" />
    <p>
      8. After we already added the bucket details in Coralogix (see step 1), all we need to do next is to validate our archive. Click on
      <strong>Validate Settings</strong>
      to make sure you have Succedded setting your archive.
    </p>
    <p>
      Now you are all set!
    </p>
  </mat-card-content>
</mat-card>
