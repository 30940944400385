import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'sh-livetail-terminal-input',
  templateUrl: './livetail-terminal-input.component.html',
  styleUrls: ['./livetail-terminal-input.component.scss'],
})
export class LivetailTerminalInputComponent implements OnInit, OnDestroy {
  @Input() public isConnected: boolean = false;
  @Input() public isPrettified: boolean;
  @Output() public grepInputEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() public stopEvent: EventEmitter<any> = new EventEmitter();
  @Output() public clearEvent: EventEmitter<any> = new EventEmitter();
  @Output() public prettifyEvent: EventEmitter<void> = new EventEmitter();
  @Output() public toggleFiltersPanel: EventEmitter<void> = new EventEmitter();
  public keyupFunc: () => void = this.keyup.bind(this);

  public ngOnInit(): void {
    document.addEventListener('keyup', this.keyupFunc);
  }

  public ngOnDestroy(): void {
    document.removeEventListener('keyup', this.keyupFunc);
  }

  public onGrepInput(value: string): void {
    this.grepInputEvent.emit(value);
  }

  public onStop(): void {
    this.stopEvent.emit();
  }

  public onPrettify(): void {
    this.prettifyEvent.emit();
  }

  public onClear(): void {
    this.clearEvent.emit();
  }

  public onFiltersPanelButtonClicked(): void {
    this.toggleFiltersPanel.emit();
  }

  public keyup(e: KeyboardEvent): void {
    const charCode = String.fromCharCode(e.which).toLowerCase();

    if (e.ctrlKey && charCode === 'c') {
      this.onStop();
    }
  }
}
