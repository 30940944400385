import { GrokDefinitionsActions } from './grokDefinitions.actions';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { Action } from '@ngrx/store';
import { of } from 'rxjs/observable/of';
import { GrokDefsService } from '../../services/grokDefs.service';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class GrokDefinitionsEffects {
  @Effect()
  public getGrokDefs: Observable<Action> = this.actions$.pipe(
    ofType(GrokDefinitionsActions.ActionTypes.GET_GROK_DEFS),
    switchMap((action) => {
      return this.grokDefsService
        .getGrokDefs()
        .map((res) => new GrokDefinitionsActions.GetGrokDefsCompleted(res))
        .catch((err) => of(new GrokDefinitionsActions.GetGrokDefsFailed(err)));
    }),
  );
  constructor(
    private actions$: Actions,
    private grokDefsService: GrokDefsService,
  ) {}
}
