<div class="header">
  <div class="title">
    <div class="healine-container">
      <div>
        Archive Queries
      </div>
      <div class="beta-container">
        <label class="beta-label">BETA</label>
      </div>
    </div>
    <div class="search-container">
      <mat-form-field class="search-query-field" appearance="outline" floatLabel="always">
        <input
          type="text"
          placeholder="Search by name, description, username or query id"
          aria-label="Search by name, description, username or query id"
          name="search"
          matInput
          class="search-text"
          (keyup.enter)="dispatchSearchTerm()"
          [formControl]="archiveQueriesSearchTermController"
        />
        <button class="coralogix button search-button" (click)="dispatchSearchTerm()" mat-button matSuffix aria-label="search">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div fxFlex="20" class="new-query-btn">
    <cgx-button
      label="ARCHIVE QUERY"
      size="md"
      [srcIconStartStyle]="{ width: 16, height: 16 }"
      srcIconStart="assets/roi/icon-reindex.svg"
      [disabled]="(isArchiveQueriesLoading$ | async) || (isSecondLoading$ | async)"
      (press)="openEditor()"
    ></cgx-button>
  </div>
</div>

<!-- Loading -->
<div>
  <div class="overlay" [hidden]="!(isArchiveQueriesLoading$ | async) && !(isSecondLoading$ | async)">
    <div class="ui active inverted dimmer overlay">
      <div class="ui text loader">Loading...</div>
    </div>
  </div>
</div>

<sh-reindex-permissions-error
  *ngIf="!(isArchiveQueriesLoading$ | async) && (archivingProviders$ | async)"
  (messageBtnAction)="openBucketEditor()"
  permissionMsg="The Archive query feature requires an Amazon S3 archive bucket to be defined"
  btnText="CONFIGURE S3"
></sh-reindex-permissions-error>

<sh-reindex-permissions-error
  *ngIf="!(isArchiveQueriesLoading$ | async) && (permissionsError$ | async)"
  (messageBtnAction)="openBucketEditor()"
  permissionMsg="The Archive query feature requires read, write and list permissions for your AWS S3 archive bucket"
  btnText="CONFIGURE S3"
></sh-reindex-permissions-error>

<sh-archive-queries-empty
  class="empty-view"
  [btnDisabled]="isArchiveQueriesLoading$ | async"
  *ngIf="(archiveQueries$ | async)?.length <= 0 && !(isArchiveQueriesLoading$ | async); else elseBlock"
  (openEditor)="openEditor()"
></sh-archive-queries-empty>
<ng-template #elseBlock>
  <div class="table-container">
    <sh-archive-queries-table-container
      class="table-container-content"
      [archiveQueries]="archiveQueries$ | async"
      [uiState]="uiState$ | async"
    ></sh-archive-queries-table-container>
  </div>
</ng-template>
<mat-paginator class="paginator" [length]="total$ | async" [pageSize]="10" (page)="getPageQueries($event)"></mat-paginator>
<sh-archive-query-form-container (formSubmitted)="submitHandler()"></sh-archive-query-form-container>
<sh-reindex-s3-form-container></sh-reindex-s3-form-container>
