import { Component, Input, OnInit } from '@angular/core';
import { VolumeAnomalyWidgetResult } from '../../../shared/models/volume-anomaly-query.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'sh-volume-anomaly-widgets',
  templateUrl: './volume-anomaly-widgets.component.html',
  styleUrls: ['./volume-anomaly-widgets.component.scss'],
})
export class VolumeAnomalyWidgetsComponent implements OnInit {
  @Input() public anomalyWidgetData: Observable<VolumeAnomalyWidgetResult>;

  constructor() {}

  public ngOnInit() {}
}
