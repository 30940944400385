<sh-basic-chart
  (ChartTimeRangeSelected)="onChartTimeRange($event)"
  #shBasicChart
  (chartClicked)="onChartClicked($event)"
  [model]="model"
  [hidden]="isLoading"
></sh-basic-chart>
<sh-widget-loader *ngIf="enableLoading" [hidden]="!isLoading"
                  [addInvertedClass]="addInvertedClassToLoader"
></sh-widget-loader>
