import { ConfigurationService } from '@app/services/configuration.service';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { ShDialogService } from '@app/shared/services/dialogService';
import { TypedJSON } from 'typedjson';
import { UserSettings } from './user.settings';

@Injectable()
export class UserSettingsService {
  constructor(private http: HttpClient, private dialogService: ShDialogService, private configService: ConfigurationService) {}

  private userExternalTokensEndpoint: string = '/api/v1/user/external-tokens';
  private userSettingsEndpoint: string = '/api/v1/user/settings';
  private userExternalTokensUrl = this.configService.getConfigHttpURL(this.userExternalTokensEndpoint);
  private userSettingsUrl = this.configService.getConfigHttpURL(this.userSettingsEndpoint);

  getUserExternalApiKeys(): Observable<any> {
    return this.http
      .get(this.userExternalTokensUrl)
      .map(res => res)
      .catch(() => {
        return null;
      });
  }

  updateUserExternalApiKey(id: string, data: any = {}): Observable<any> {
    return this.http
      .put(`${this.userExternalTokensUrl}/${id}`, data)
      .map(res => res)
      .catch(() => {
        this.dialogService.showCoralogixMessage("Couldn't generate a new key", null, dialogServiceIconClasses.failed);
        return null;
      });
  }

  createExternalIntegration(name: string, sourceSystem: string, config: any = null, token: string = null): Observable<any> {
    return this.http
      .post(`${this.userExternalTokensUrl}`, { name, sourceSystem, config, token })
      .map(res => res)
      .catch(() => {
        this.dialogService.showCoralogixMessage("Couldn't create a new integration", null, dialogServiceIconClasses.failed);
        return null;
      });
  }

  deleteUserExternalApiKey(id: string): Observable<any> {
    return this.http.delete(`${this.userExternalTokensUrl}/${id}`, { responseType: 'text' }).catch(() => {
      this.dialogService.showCoralogixMessage("Couldn't delete key", null, dialogServiceIconClasses.failed);
      return null;
    });
  }

  getUserSettings(): Observable<UserSettings> {
    return this.http
      .get(this.userSettingsUrl)
      .map(this.extractUserSettings)
      .catch(err => {
        console.log(err);
        return of(new UserSettings());
      });
  }

  updateUserSettings(userSettings: UserSettings): Observable<any> {
    if (userSettings) {
      const metadata = new Metadata();
      metadata.applicationName = userSettings.queryMetadata.applicationName;
      metadata.subsystemName = userSettings.queryMetadata.subsystemName;
      userSettings.queryMetadata = metadata;
    }
    return this.http.post(this.userSettingsUrl, JSON.stringify(userSettings));
  }

  generateNewApiKey(): Observable<UserSettings> {
    return this.http
      .post(this.userSettingsUrl + '/api_key', null)
      .map(this.extractUserSettings)
      .catch(err => {
        console.log(err);
        return of(new UserSettings());
      });
  }

  generateNewTeamsApiKey(): Observable<UserSettings> {
    return this.http
      .post(this.userSettingsUrl + '/teams_api_key', null)
      .map(this.extractUserSettings)
      .catch(err => {
        console.log(err);
        return of(new UserSettings());
      });
  }

  generateNewESApiKey(): Observable<UserSettings> {
    return this.http
      .post(this.userSettingsUrl + '/es_api_key', null)
      .map(this.extractUserSettings)
      .catch(err => {
        console.log(err);
        return of(new UserSettings());
      });
  }

  extractUserSettings(res: any): UserSettings {
    try {
      return res ? TypedJSON.parse(res, UserSettings) : new UserSettings();
    } catch (e) {
      return new UserSettings();
    }
  }
}
