import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SearchProvider } from '../../shared/controls/search/search.provider';
import { Log } from '../shared/models/log.entity';
import { LoggregationEntity } from '../../loggregation/shared/interfaces/loggregation.interface';
import { JsonParameterRequestData } from '../../shared/models/jsonParameterRequestData';
import { FormGroup } from '@angular/forms';
import { RecentQueriesService } from '../shared/services/recent-queries.service';
import { LogsQuerFormProvider } from '../logs-query/logs-query-form.provider';
import { Observable } from 'rxjs/Observable';
import { map, startWith } from 'rxjs/operators';
import { SearchTerm } from '../shared/models/search-term';

declare const $;

@Component({
  selector: 'sh-logs-menu',
  templateUrl: './logs-menu.component.html',
  styleUrls: ['./logs-menu.component.scss'],
})
export class LogsMenuComponent implements OnInit, AfterViewInit {
  @Input() public queryFrom: FormGroup;
  @Input() public lastExecutedQueryId: string;
  @Input() public showFilterPanel: boolean;
  @Input() public showFind: boolean;
  @Input() public logsSearchProvider: SearchProvider;
  @Input() public selectedLog: Log | LoggregationEntity;
  @Input() public showTimeline: boolean;
  @Input() public addWidgetEnable: boolean;
  @Output() public executeQuery: EventEmitter<any> = new EventEmitter<any>();
  @Output() public beforeAfter: EventEmitter<any> = new EventEmitter<any>();
  @Output() public toggleVisibility: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() public exportLogs: EventEmitter<any> = new EventEmitter<any>();
  @Output() public detectGroks: EventEmitter<any> = new EventEmitter<any>();
  @Output() public addWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() public toggleFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() public showWidget: EventEmitter<
    JsonParameterRequestData
  > = new EventEmitter<JsonParameterRequestData>();

  @ViewChild('query', { static: true }) public query: ElementRef;
  @ViewChild('queryicon', { static: true }) public queryIconElm: ElementRef;
  public filteredOptions: Observable<SearchTerm[]>;
  public filteredFieldsOptions: Observable<SearchTerm[]>;
  private isAutocpmpleteSelectionChanged: boolean;
  constructor(
    private formProvider: LogsQuerFormProvider,
    private recentQueriesService: RecentQueriesService,
  ) {}

  public ngAfterViewInit(): void {
    $(this.queryIconElm.nativeElement).popup({
      popup: '.special.popup',
      position: 'bottom left',
      lastResort: 'bottom left',
      inline: true,
      hoverable: true,
    });
  }

  public ngOnInit(): void {
    const filterPipe = this.queryFrom.controls['text'].valueChanges.pipe(
      startWith(''),
      map((value) => {
          const prefix = (value as SearchTerm).prefix !== '' ? (value as SearchTerm).prefix + ' ' : '';
          return typeof value === 'string'
            ? value
            : prefix +
            (value as SearchTerm).suggestedHead +
            (value as SearchTerm).term +
            (value as SearchTerm).suggestedTail +
            (value as SearchTerm).possfix;
        }
      ));
    this.filteredOptions = filterPipe
      .map((value) =>
        this.recentQueriesService
          .getRecents(value.toString())
          .map((option) => new SearchTerm(value.toString(), option, true, '', ''))
      );

    this.filteredFieldsOptions = filterPipe
      .map((value) => {
        const phrase = value.split(/(AND|OR|NOT)\s+/);
        const lastWord = phrase.pop();
        let searchPrefix = '';
        if (lastWord.length === 0) {
          searchPrefix = value;
        } else {
          searchPrefix = value.slice(0, -1 * lastWord.length);
        }
        return this.recentQueriesService
          .getJsonFieldsOptions(lastWord)
          .map((option) => new SearchTerm(lastWord, option, false, searchPrefix, ': '));
      });
  }

  public executeSearch(): void {
    if (this.isAutocpmpleteSelectionChanged === true) {
      this.isAutocpmpleteSelectionChanged = false;
    } else {
      const query = this.query.nativeElement.value;
      this.setAndUpdateQueryText(query);
    }
  }

  public onSelectionChanged(): void {
    this.isAutocpmpleteSelectionChanged = true;
  }

  public submitSuggested(suggested: SearchTerm): void {
    if (suggested.searchOnClick === true) {
      this.setAndUpdateQueryText(suggested.toString());
    }
  }

  public setAndUpdateQueryText(query: string): void {
    this.formProvider.setQueryFormTextValue(query);
    this.recentQueriesService.update(query);
    this.executeQuery.emit();
  }
}
