<div class="container">
  <img
    src="assets/icons/clear-icon.svg"
    class="clear-icon"
    data-test="dialog-clear-icon"
    (click)="dialogRef.close()"
    alt="icon"
  />

  <div class="title">Showing time for: "{{ data.strDate }}"</div>

  <div class="view-as-json-container">
    <div class="pretty-json-container">
      <div>
        <b>Date:</b>
        {{ date | dateFormatByUserSettings }} ({{ timezone$ | async }})
      </div>
      <div>
        <b>UTC Date:</b>
        {{ UTCDate }}
      </div>
      <br />
      <div>
        {{ date | fromDate }}
      </div>
    </div>
  </div>

  <div class="bottom-actions">
    <div class="btn cancel-btn" (click)="dialogRef.close()">CLOSE</div>
  </div>
</div>
