<div class="stats container">
  <div class="ui equal width grid column">
    <div class="row large">
      <!-- card -->
      <div *ngFor="let stats of manager?.livetailTailStats" class="column">
        <div class="stats-card">
          <div class="stats-card-header">
            <div align="center" class="stats-chart">
              <sh-basic-chart
                [realtimeData]="stats.livetailStatsCount"
                (chartClicked)="chartClicked($event)"
                [model]="stats.chartModel"
              ></sh-basic-chart>
            </div>
            <div align="center" class="stats-right-container">
              <div class="stats-name">
                {{ stats.severity }}
              </div>
              <div class="stats-value">
                {{ stats.total | numbersFormat }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
