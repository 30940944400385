<div #scrollMe class="liveTail-Container" [class.terminal-styling]="isTerminalMode">
  <div class='grep-item' *ngFor='let item of livetailLogs'>
    <code *ngIf='item?.timestamp' style='color: #3bdaeb'>
      <code>[</code>
      <code>{{ item?.timestamp | dateFormatByUserSettings : ampmBigLettersOption }}</code>
      <code>]</code>
    </code>
    <code *ngIf='item?.severity' class='{{ item?.severity.toLowerCase() }}'>
      <code>[</code>
      <code>{{ item?.severity }}</code>
      <code>]</code>
    </code>
    <code
      *ngIf='item?.applicationName'
      [style.color]='item?.applicationName | stringTocolor: 0'
    >
      <code>[</code>
      <code
        [innerHTML]='item?.applicationName | highlight: item?.highlights'
      ></code>
      <code>]</code>
    </code>
    <code
      *ngIf='item?.subsystemName'
      [style.color]='item?.subsystemName | stringTocolor: isTerminalMode ? 1 : 2'
    >
      <code>[</code>
      <code
        [innerHTML]='item?.subsystemName | highlight: item?.highlights'
      ></code>
      <code>]</code>
    </code>
    <code
      *ngIf='item?.computerName'
      [style.color]='item?.computerName | stringTocolor: 0'
    >
      <code>[</code>
      <code
        [innerHTML]='item?.computerName | highlight: item?.highlights'
      ></code>
      <code>]</code>
      <code>:</code>
    </code>
    <code [innerHTML]="item.text | highlight: item?.highlights" *ngIf="!isPrettified"></code>
    <pretty-object [data]="item.text" theme="dark" [searchQuery]="searchQuery" *ngIf="isPrettified"></pretty-object>
  </div>
</div>
