import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[shRelativeHeightCalcReducer]'
})
export class HeightCalculatorDirective {

  private _elementsToReduceHeightOfFromCurrent: HTMLDivElement[] = [];
  private prevHeightToReduceValue: number = 0;

  @Input() set elementsToReduceHeightOfFromCurrent(values: HTMLDivElement[]) {
    if (values) {
      this._elementsToReduceHeightOfFromCurrent = values;
      const currentHeightToReduce = this.getHeightToReduce();
      if (this.checkIfShouldCallHeightChange(currentHeightToReduce)) {
        this.handleHeightChange(currentHeightToReduce);
      }
    }
  }

  constructor(private el: ElementRef) {}

  private handleHeightChange(currentHeightToReduce: number): void {
    this.el.nativeElement.style.height = `calc(100% - ${currentHeightToReduce}px)`;
    this.prevHeightToReduceValue = currentHeightToReduce;
  }

  private getHeightToReduce(): number {
    let heightToReduce = 0;
    this._elementsToReduceHeightOfFromCurrent.forEach((divEl: HTMLDivElement) => {
      heightToReduce += divEl.getBoundingClientRect().height;
    });
    return heightToReduce;
  }

  private checkIfShouldCallHeightChange(currentHeightToReduce: number): boolean {
    return currentHeightToReduce !== this.prevHeightToReduceValue;
  }
}
