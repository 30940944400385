import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';

import { IEegisterAndSubscribeStep1, IPlanInformationResponse, IStripeCustomerDataResponse, ITogglePlan } from './responses';

@Injectable({
  providedIn: 'root',
})
export class PayAsYouGoService {
  constructor(private http: HttpClient, private cs: ConfigurationService) {}

  public planInformation(): Observable<IPlanInformationResponse> {
    const url = this.cs.getConfigURL('/api/v1/payment/overage');
    return this.http.get<IPlanInformationResponse>(url).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 403) {
          return of(null);
        }
        throw err;
      }),
    );
  }
  public stripeCustomerData(): Observable<{ customerId: string; email: string }> {
    const url = this.cs.getConfigURL('/api/v1/payment/customer-data');
    return this.http.get<IStripeCustomerDataResponse>(url).pipe(
      map(res => ({ customerId: res.customerId, email: res.email })),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of(null);
        }
        return throwError(err);
      }),
    );
  }

  public registerPlan(body: { customerId: string }): Observable<IPlanInformationResponse> {
    const url = this.cs.getConfigURL('/api/v1/payment/overage/stripe');
    return this.http.post<IPlanInformationResponse>(url, body);
  }

  public togglePlan(body: { enabled: boolean }): Observable<boolean> {
    const url = this.cs.getConfigURL('/api/v1/payment/overage/toggle');
    return this.http.put<ITogglePlan>(url, body).pipe(
      map(res => {
        return res.enabled;
      }),
    );
  }

  public registerAndSubscribeStep1(body: { email: string }): Observable<IEegisterAndSubscribeStep1> {
    const url = this.cs.getConfigURL('/api/v1/payment/overage/non-stripe');
    return this.http.post<IEegisterAndSubscribeStep1>(url, body);
  }

  public subscriptionCompleted(): Observable<object> {
    const url = this.cs.getConfigURL('/api/v1/payment/overage/subscription-completed');
    return this.http.post<object>(url, null);
  }
}
