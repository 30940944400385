import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMetadata',
})
export class FilterMetadataPipe implements PipeTransform {
  transform(metadata: string[], filterString: string): any {
    if (!Array.isArray(metadata) || !filterString) {
      return metadata;
    }

    if (Array.isArray(filterString)) {
      return metadata.filter(item => !filterString.includes(item.toLowerCase()));
    }

    return metadata.filter(item => item.toLowerCase().includes(filterString.toLowerCase()));
  }
}
