import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import * as Sentry from '@sentry/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { SharedModule } from '@shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { Router, RouterModule } from '@angular/router';
import { LogsModule } from './logs/logs.module';
import { ScrollHelperService } from '@shared/services/scroll-helper/scroll-helper.service';
import { UDIDHelper } from '@shared/services/udid.helper';
import { StatisticsModule } from './statistics/statistics.module';
import { AlertsModule } from './alerts/alerts.module';
import { LivetailModule } from './livetail/livetail.module';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettingsCommonModule } from './settings-common/settings-common.module';
import { UserModule } from './user/user.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './app.reducers';
import { EffectsModule } from '@ngrx/effects';
import { FiltersEffects } from './logs/filters/filters.effects';
import { DeploymentModule } from './deployment/deployment.module';
import { KibanaModule } from './kibana/kibana.module';
import { TagsEffects } from './deployment/shared/tags.effects';
import { TeamsEffects } from './user/state/teams/teams.effects';
import { TeamUsersEffects } from './user/state/teams/team-users.effects';
import { TeamNavigationEffects } from './user/state/teams/team-navigation.effects';
import { SettingsEffects } from './settings/reducer/settings.effects';
import { ArchivingProvidersEffects } from './settings/reducer/archiving-providers/archiving-providers.effects';
import { environment } from '@environments/environment';
import { GroksModule } from './groks/groks.module';
import { GroksEffects } from './groks/shared/state/groks/groks.effects';
import { LogEffects } from './logs/shared/state/log.effects';
import { MetadataEffects } from '@shared/state/effects/metadata.effects';
import { GrokDefinitionsEffects } from './groks/shared/state/grokDefinitions/grokDefinitions.effects';
import { LogsFilterGridColumnsEffects } from './logs/shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.effects';
import { LogsViewsEffects } from './logs/shared/state/logs-views/logs-views.effects';
import { IntegrationsEffects } from './settings/shared/state/integrations/integrations.effects';
import { EnrichmentEffects } from './settings/shared/state/enrichment/enrichment.effects';
import { SecurityOnionEffects } from './settings/shared/state/security-onion/security-onion.effects';
import { TokenInterceptorService } from '@shared/services/token-interceptor.service';
import { LoggregationModule } from '@app/loggregation/loggregation.module';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { HmrModule, stateSetter } from './hmr.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MetricsEffects } from '@app/settings/metrices-settings/state/effects/metrics.effects';
import { RoiModule } from '@app/roi/roi.module';
import { ReindexEffects } from '@app/roi/reindex/state/effects/reindex.effects';
import { ClipboardService } from '@shared/services/clipboard-service';
import { ParsingThemesEffects } from '@app/rules/parsing-themes.effects';
import { AlertsEffects } from '@app/alerts/shared/effects/alerts.effects';
import { UserSettingsEffects } from '@app/user/state/user-settings/user-settings.effects';
import { GroupsEffects } from '@app/settings/account/components/groups/store/groups.effects';
import { RolesEffects } from '@app/settings/account/components/roles/store/roles.effects';
import { ArchiveQueriesEffects } from '@app/roi/archive-queries/state/effects/archive-queries.effects';
import { GenericMetadataEffects } from '@shared/state/effects/generic-metadata.effects';
import { NgxsStoreModule } from './ngxs-store/ngxs-store.module';
import { ConfigurationService } from '@app/services/configuration.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MainLoaderModule } from './main-loader/main-loader.module';
import { ArchiveRetryInterceptor } from '@shared/services/archive-retry.interceptor';

declare global {
  interface Window {
    analytics: any;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    SharedModule,
    LayoutModule,
    AuthModule,
    StatisticsModule,
    LogsModule,
    NoopAnimationsModule,
    LoggregationModule,
    DashboardModule,
    AlertsModule,
    LivetailModule,
    SettingsCommonModule,
    UserModule,
    FlexLayoutModule,
    RoiModule,
    EffectsModule.forRoot([
      ParsingThemesEffects,
      TagsEffects,
      FiltersEffects,
      TeamsEffects,
      TeamUsersEffects,
      TeamNavigationEffects,
      SettingsEffects,
      ArchivingProvidersEffects,
      LogEffects,
      MetadataEffects,
      MetricsEffects,
      GroksEffects,
      GrokDefinitionsEffects,
      LogsFilterGridColumnsEffects,
      LogsViewsEffects,
      IntegrationsEffects,
      EnrichmentEffects,
      SecurityOnionEffects,
      ReindexEffects,
      AlertsEffects,
      UserSettingsEffects,
      GroupsEffects,
      RolesEffects,
      ArchiveQueriesEffects,
      GenericMetadataEffects,
    ]),
    AngularSvgIconModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers: [stateSetter] }),
    DeploymentModule,
    KibanaModule,
    GroksModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument({
      name: 'NGRX store',
      maxAge: 100,
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    NgxsStoreModule,
    OverlayModule,
    MainLoaderModule,
  ],
  providers: [
    ClipboardService,
    UDIDHelper,
    ParsingThemesEffects,
    TagsEffects,
    ScrollHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ArchiveRetryInterceptor,
      multi: true,
    },
    ConfigurationService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ConfigurationService, Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule extends HmrModule {}

export function initApp(appConfigService: ConfigurationService): () => Promise<boolean> {
  return () => appConfigService.load();
}
