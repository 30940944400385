import { FormControl, Validators } from '@angular/forms';
import { Nunjucks } from '@shared/models/nunjucks';

export class NunjucksValidator extends Validators {
  static nunjucksExpression(control: FormControl): any {
    try {
      Nunjucks.renderString(control.value, {});
      return null;
    } catch (err) {
      if (err.message.indexOf('unexpected token') !== -1) {
        const columnNumber = Number(err.message.substring(err.message.indexOf('Column') + 7, err.message.indexOf(']')));
        const startErrorMsg = err.message.substring(err.message.indexOf('unexpected token: '), err.message.length);
        return { nunjucksColumn: `${startErrorMsg} at column ${columnNumber}` };
      } else if (err.message.indexOf('expected variable end') !== -1) {
        if (err.message.indexOf('Column') !== -1) {
          const columnNumber = Number(err.message.substring(err.message.indexOf('Column') + 7, err.message.indexOf(']')));
          return { nunjucksExpectedVariableEnd: `expected variable end at column ${columnNumber}` };
        }
        return { nunjucksExpectedVariableEnd: 'expected variable end' };
      } else if (err.message.indexOf('expected expression, got end of file') !== -1) {
        return { nunjucksEndOfFile: 'expected expression, got end of file' };
      } else {
        return { nunjucksUnknown: err };
      }
    }
  }
}
