import { TimeMagnitude } from '@app/insights/shared/models/time.model';

import { RelativeTimeSuffix, TimeInSeconds } from '@app/logs-new/shared/models/router.model';

export const addZero = date => date.toString().padStart(2, '0');

export function getStringHoursFromDate(date: Date): string {
  return `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
}

export function getDateMinusSeconds(seconds: number, date: Date = new Date()): Date {
  return new Date(date.getTime() - seconds * 1000);
}

export function getDatePlusSeconds(seconds: number, date: Date = new Date()): Date {
  return new Date(date.getTime() + seconds * 1000);
}

export function getStartOfTodayDate(): Date {
  return new Date(new Date().setHours(0, 0, 0, 0));
}

export function validateDateRangeInSeconds(dateOneSec: number, dateTwoSec: number, maxSecRange: number, minSecRange: number = 0): boolean {
  const diff = Math.abs(dateTwoSec - dateOneSec);
  return diff <= maxSecRange && diff >= minSecRange;
}

export function getFullDateFromDateAndHmsString(date: Date, hms: string): Date {
  const newDate = new Date(date.getTime());
  const [timeHours, timeMinutes, timeSecondsAndMillis] = hms.split(':');
  const [timeSeconds, timeMillis] = timeSecondsAndMillis ? timeSecondsAndMillis.split('.') : [0, 0];
  newDate.setHours(Number(timeHours || 0), Number(timeMinutes || 0), Number(timeSeconds || 0), Number(timeMillis || 0));
  return newDate;
}

export const getStrDateWithoutDot = (strDate: string) => strDate.split('.').join('');

export function isValidNumericalDateString(
  strDate: string,
  allowedMagnitudes: TimeMagnitude[] = [
    TimeMagnitude.Seconds,
    TimeMagnitude.MilliSeconds,
    TimeMagnitude.MicroSeconds,
    TimeMagnitude.NanoSeconds,
  ],
): boolean {
  const fullStrDate = getStrDateWithoutDot(strDate);
  return isFinite(Number(strDate)) && allowedMagnitudes.includes(fullStrDate.length);
}

export function isValidJSDate(dateObject: any): boolean {
  return new Date(dateObject).toString() !== 'Invalid Date';
}

export function getDateFromStrDate(dateStr: string): Date {
  let dateNum: number;
  switch (dateStr.length) {
    case TimeMagnitude.Seconds:
      dateNum = Number(dateStr) * 1000;
      break;
    case TimeMagnitude.MicroSeconds:
      dateNum = Number(dateStr) / 1000;
      break;
    case TimeMagnitude.NanoSeconds:
      dateNum = Number(dateStr) / 1000 / 1000;
      break;
    default:
      dateNum = Number(dateStr);
  }
  return new Date(dateNum);
}

/* returns UTC Date with support of micro seconds and nano seconds after decimal point */
export function getFullUTCDateFromStrDate(strDate: string): string {
  const dateLength = strDate.length;
  const UTCDate = getDateFromStrDate(strDate)
    .toISOString()
    .slice(0, -1);

  let valueAfterThirdDecimalPoint = ''; // value after milliseconds
  if (dateLength === TimeMagnitude.MicroSeconds) {
    valueAfterThirdDecimalPoint = strDate.substr(dateLength - 3);
  } else if (dateLength === TimeMagnitude.NanoSeconds) {
    valueAfterThirdDecimalPoint = strDate.substr(dateLength - 6);
  }

  return `${UTCDate + valueAfterThirdDecimalPoint}Z`;
}

export const isValidDate = (date: Date) => date instanceof Date && date?.getTime();

export const areValidDates = (...dates: Date[]) => dates.every(date => isValidDate(date));

export function getNumOfSecondsFromTime(time: number, suffix: RelativeTimeSuffix): number | null {
  switch (suffix) {
    case RelativeTimeSuffix.Seconds:
      return time;
    case RelativeTimeSuffix.Minutes:
      return time * TimeInSeconds.Minute;
    case RelativeTimeSuffix.Hours:
      return time * TimeInSeconds.Hour;
    case RelativeTimeSuffix.Days:
      return time * TimeInSeconds.Day;
  }
  return null;
}

export function getTimeStrFromSeconds(seconds: number): string | void {
  if (seconds === TimeInSeconds.Second) return 1 + RelativeTimeSuffix.Seconds;
  if (seconds === TimeInSeconds.Minute) return 1 + RelativeTimeSuffix.Minutes;
  if (seconds === TimeInSeconds.Day) return 1 + RelativeTimeSuffix.Days;
  if (seconds === TimeInSeconds.Hour) return 1 + RelativeTimeSuffix.Hours;
  if (seconds > TimeInSeconds.Day) return seconds / TimeInSeconds.Day + RelativeTimeSuffix.Days;
  if (seconds > TimeInSeconds.Hour) return seconds / TimeInSeconds.Hour + RelativeTimeSuffix.Hours;
  if (seconds > TimeInSeconds.Minute) return seconds / TimeInSeconds.Minute + RelativeTimeSuffix.Minutes;
  if (seconds > TimeInSeconds.Second) return seconds + RelativeTimeSuffix.Seconds;
}
