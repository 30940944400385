<div class="action-menu">
  <div class="action-menu-left ui secondary pointing menu tabs bar">
    <a
      [ngClass]="{ 'item': true, 'animated pulsing': selectedView }"
      [routerLink]="['/query/logs']"
      [queryParams]="lastExecutedQueryId ? { id: lastExecutedQueryId } : null"
      [shActiveRouter]="['/query/logs']"
      [activeRouteClass]="'tab-active'"
    >
      <i
        *ngIf="isLogsTabActive && selectedView && !selectedView.isEditable"
        class="star outline"
        [ngClass]="{
          'yellow': this.userSettingsProvider.userSettings.logsDefaultGridViewId === selectedView.id,
          'icon': true
        }"
        [matTooltip]="
          this.userSettingsProvider.userSettings.logsDefaultGridViewId !== selectedView.id
            ? 'Set as default view'
            : 'Unset default view'
        "
        [matTooltipPosition]="'right'"
        (click)="toggleSetSelectedViewAsDefault(selectedView)"
      ></i>
      <i
        *ngIf="isLogsTabActive && selectedView && selectedView.isEditable"
        (click)="switchTemplate('editView')"
        [matTooltip]="'Edit view'"
        class="ui pencil alternate icon edit-item-btn"
      ></i>
      {{ selectedView ? selectedView.name : 'Logs' }}
      <span class="count" *ngIf="logsCount">({{ logsCount | numbersWithKFormat: 10000000 }})</span>
      <i
        *ngIf="
          isLogsTabActive &&
          selectedView &&
          this.userSettingsProvider.userSettings.logsDefaultGridViewId !== selectedView.id
        "
        class="ui remove icon item-remove-btn"
        [matTooltip]="'Clear selected view'"
        (click)="onClearSelectedViewClicked()"
      ></i>
    </a>
    <a
      (click)="closeAllTemplates()"
      class="item"
      [routerLink]="['/query/loggregation']"
      [queryParams]="lastExecutedQueryId ? { id: lastExecutedQueryId } : null"
      [shActiveRouter]="['/query/loggregation']"
      [activeRouteClass]="'tab-active'"
    >
      Loggregation
      <span class="count" *ngIf="templateCount">({{ templateCount | numbersWithKFormat: 10000000 }})</span>
    </a>
    <!--<div class="item">{{queryResultCount}}</div>-->
  </div>
  <div class="right-menu">
    <button class="save-btn" mat-button (click)="createAlert()">
      <i class="fa fa-flag" aria-hidden="true"></i>
      Create Alert
    </button>
    <button *ngIf="isLogsTabActive && (isReadOnly$ | async) === false" class="save-btn" mat-button (click)="switchTemplate('saveView')">
      <i class="fa fa-save" aria-hidden="true"></i>
      Save View
    </button>
    <button
      *ngIf="isLogsTabActive"
      mat-button
      [matTooltip]="'Open an existing view'"
      (click)="switchTemplate('openView')"
    >
      <i class="fa fa-folder-open" aria-hidden="true"></i>
      Open
    </button>
    <!--button mat-button *ngIf="!isReadOnly"
            (click)="detectGroks.emit()">
      <i class="fa fa-search" aria-hidden="true"></i>
      Groks
    </button-->
    <button mat-button (click)="clear.emit()">
      <i class="fa fa-eraser" aria-hidden="true"></i>
      Clear
    </button>
    <button mat-button (click)="switchTemplate('queryDetailsView'); updateQueryRange()">
      <span *ngIf="lastExecutedQuery?.queryType !== queryTagType">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
        {{ lastExecutedQuery?.caption }}
      </span>
      <span
        *ngIf="lastExecutedQuery?.queryType === queryTagType"
        [matTooltip]="
        (lastExecutedQuery.queryModel.startDate | dateFormatByUserSettings : null) + ' - ' +
        (lastExecutedQuery.queryModel.endDate | dateFormatByUserSettings : null)"
        [matTooltipPosition]="'left'"
      >
        <i class="fa fa-tag" aria-hidden="true"></i>
        {{ lastExecutedQuery?.caption }}
      </span>
    </button>
  </div>
</div>
<div *ngIf="getCurrentTemplate()">
  <div *ngIf="getCurrentTemplate()" class="content-wrap" [ngSwitch]="getCurrentTemplate()">
    <div class="left query-details-buttons" *ngSwitchCase="'queryDetailsView'">
      <button mat-button (click)="changeMode(viewModes.quick)" [class.selected]="mode === viewModes.quick">
        Quick
      </button>
      <button mat-button (click)="changeMode(viewModes.relative)" [class.selected]="mode === viewModes.relative">
        relative
      </button>
      <button mat-button (click)="changeMode(viewModes.absolute)" [class.selected]="mode === viewModes.absolute">
        Absolute
      </button>
      <button mat-button (click)="changeMode(viewModes.tag)" [class.selected]="mode === viewModes.tag">
        By Tag
      </button>
    </div>
    <div class="center query-details-desktop" *ngSwitchCase="'queryDetailsView'">
      <sh-quick-query-predefined
        *ngIf="mode === viewModes.quick"
        (selectedValueChange)="quickQueryChanged.emit($event); closeAllTemplates()"
      ></sh-quick-query-predefined>
      <sh-relative-query
        *ngIf="mode === viewModes.relative"
        (valueSelected)="relativeQuery.emit($event); closeAllTemplates()"
      ></sh-relative-query>
      <sh-absolute-query
        *ngIf="mode === viewModes.absolute"
        (absoluteQuery)="absoluteQuery.emit($event); closeAllTemplates()"
        [newQueryForm]="newQueryForm"
      ></sh-absolute-query>
      <sh-tag-query
        *ngIf="mode === viewModes.tag"
        (tagQuery)="tagQuery.emit($event); closeAllTemplates()"
        [newQueryForm]="newQueryForm"
      ></sh-tag-query>
    </div>

    <div class="query-details-mobile" *ngSwitchCase="'queryDetailsView'">
      <sh-relative-query (valueSelected)="relativeQuery.emit($event); closeAllTemplates()"></sh-relative-query>
    </div>

    <div *ngSwitchCase="'saveView'">
      <sh-logs-save-view (onSave)="closeAllTemplates()"></sh-logs-save-view>
    </div>
    <div style="width: 100%" *ngSwitchCase="'openView'">
      <sh-logs-open-view (onOpen)="closeAllTemplates()"></sh-logs-open-view>
    </div>
    <div *ngSwitchCase="'editView'">
      <sh-logs-save-view (onSave)="closeAllTemplates()" [editView]="selectedView"></sh-logs-save-view>
    </div>
  </div>
</div>
