import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLoaderComponent } from './main-loader.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [MainLoaderComponent],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [MainLoaderComponent],
})
export class MainLoaderModule {}
