import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { CompanyRequest } from '@app/auth/shared/models/activateUser.model';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'sh-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  @Select(UserState.isAdmin) public isAdmin$: Observable<boolean>;

  @Input() public companyDetails: CompanyRequest;

  public showUserSettingsFilters: boolean = true;

  private routingEventSubscription: Subscription;

  constructor(private router: Router) {}

  public get isMobile(): boolean {
    return window.screen.width < 900;
  }

  public ngOnInit(): void {
    if (this.router.url.startsWith('/query') || this.router.url.startsWith('/settings')) {
      this.showUserSettingsFilters = false;
    }

    this.routingEventSubscription = this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe((val: NavigationEnd) => {
        if (val.url.startsWith('/query') || val.url.startsWith('/settings')) {
          this.showUserSettingsFilters = false;
        } else {
          this.showUserSettingsFilters = true;
        }
      });
  }
  public ngOnDestroy(): void {
    if (this.routingEventSubscription) {
      this.routingEventSubscription.unsubscribe();
    }
  }
}
