import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { AlertConditions } from './alert-conditions.model';
import { AlertProperties } from './alert-properties.model';
import { AlertESInfoModel } from './alert-es-info.model';

@jsonObject
export class RelativeAlert {
  @jsonArrayMember(String)
  public applicationName: string[];
  @jsonArrayMember(Number)
  public logSeverity: number[];
  @jsonMember
  public alias: string;
  @jsonMember
  public parentAlias: string;
  @jsonArrayMember(String)
  public subsystemName: string[];
  @jsonMember
  public text: string;
  @jsonMember
  public alertText: string;
  @jsonMember
  public order: number;
  @jsonMember
  public operator: string = '/';
  @jsonMember
  public relativeTimeframe: number = 0;
}
