import { Component, Input } from '@angular/core';
import { RegexTableRow } from '@shared/tutorials/regex-cheat-sheet/models/regex-table-row.models';
@Component({
  selector: 'sh-regex-cheat-sheet-table-section',
  templateUrl: './regex-cheat-sheet-table-section.component.html',
  styleUrls: [ './regex-cheat-sheet-table-section.component.scss' ],
})
export class RegexCheatSheetTableSectionComponent {
  @Input()
  public hideHeader: boolean;
  @Input()
  public dataSource: RegexTableRow[];
  @Input()
  public symbolColWidth: number;
  public displayedColumns: string[];

  constructor(
  ) {
    const tableCols = new RegexTableRow();
    this.displayedColumns = Object.keys(tableCols);
  }
}
