import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class CustomFiltersProvider {
  public customFilters: CustomFilter[] = [];

  public getCustomFilters(name: string): string[] {
    const customFilter = this.customFilters.find((f) => f.name == name);
    if (customFilter) {
      _.pull(this.customFilters, customFilter);
      return customFilter.filters;
    }
    return null;
  }

  public saveCustomFilters(name: string, filters: string[]) {
    const customFilter = new CustomFilter(name, filters);
    this.customFilters.push(customFilter);
  }
}

export class CustomFilter {
  public name: string;
  public filters: string[] = [];

  constructor(name: string, filters: string[]) {
    this.name = name;
    this.filters = filters;
  }
}
