import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';

import { DateValidator } from '@shared/validators/dates-delta.validator';
import { LogQueryModel } from '../shared/models/logsquery.model';
import { DateHelper } from '@shared/services/date.helper';
import { QueryTypes } from '../shared/models/query-types.options';
import { TEXT_TYPES } from '../shared/models/query-params.model';
import { Log } from '../shared/models/log.entity';
import { SeverityTranslator } from '../shared/translators/severity.translator';
import { getLogsGridColDefs, State } from '@app/app.reducers';
import { LogColumn } from '@shared/models/log-column';
import { StringHelper } from '@shared/services/string.helper';
import { SortModel } from '../shared/models/sort.model';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';
import { IQueryDateFormValues } from '@app/logs/shared/models/forms/query-form-provider.models';

@Injectable()
export class LogsQuerFormProvider {
  get defaultValues(): any {
    return {
      dates: {
        from: moment()
          .subtract({ minutes: 15 })
          .toDate(),
        fromTime: moment()
          .subtract({ minutes: 15 })
          .format('HH:mm:ss'),
        to: new Date(),
        toTime: moment().format('HH:mm:ss'),
      },
      metadata: {
        category: [],
        className: [],
        methodName: [],
        applicationName: [],
        subsystemName: [],
        computerName: [],
        severity: [],
        IPAddress: [],
        threadId: '',
      },
      severity: [1, 2, 3, 4, 5, 6],
      text: '',
      isTemplateSearch: false,
      jsonObject: {},
      tagId: -1,
      selectedViewId: -1,
    };
  }
  public newQueryForm: FormGroup;
  private model: LogQueryModel = new LogQueryModel();
  private hasDynamicFilters: boolean = false;
  constructor(private store: Store<State>) {
    this.generateNewForm(this.defaultValues);

    this.store.select(getLogsGridColDefs).subscribe((colDefs) => {
      this.hasDynamicFilters = false;
      const dynamicFiltersFormGroup: FormGroup = new FormGroup({});
      if (colDefs && colDefs.length > 0) {
        this.hasDynamicFilters = true;
        colDefs.forEach((colDef) => {
          if (colDef.field.indexOf('textObject') === 0) {
            let filterArray = [];
            if (Object.keys(this.newQueryForm.controls['jsonObject'].value).includes(colDef.headerName)) {
              filterArray = this.newQueryForm.controls['jsonObject'].value[colDef.headerName];
            }
            dynamicFiltersFormGroup.addControl(colDef.headerName, new FormControl(filterArray));
          }
        });
      }

      this.newQueryForm.removeControl('jsonObject');
      this.newQueryForm.addControl('jsonObject', dynamicFiltersFormGroup);
    });
  }

  public setQueryType(val: string): void {
    this.model.type = val;
  }

  public setCachedQueryId(val: string): void {
    this.model.cacheQueryId = val;
  }

  public getModel(update: boolean = true): LogQueryModel {
    if (update) {
      this.updateModelByForm(this.model);
    }
    this.model.pageIndex = 0;
    return this.model;
  }

  public updateFormByModel(model: LogQueryModel): void {
    const datesModel: FormGroup = this.newQueryForm.controls[
      'dates'
    ] as FormGroup;

    datesModel.controls['from'].setValue(moment(model.startDate).toDate());
    datesModel.controls['to'].setValue(new Date(model.endDate));
    datesModel.controls['fromTime'].setValue(
      moment(model.startDate).format('HH:mm:ss'),
    );
    datesModel.controls['toTime'].setValue(
      moment(model.endDate).format('HH:mm:ss'),
    );

    this.newQueryForm.controls['severity'].setValue(
      model.queryParams.metadata.severity,
    );
    this.newQueryForm.controls['text'].setValue(model.queryParams.query.text);
    model.queryParams.metadata.threadId =
      model.queryParams.metadata.threadId || '';

    const obj = Object.assign({}, model.queryParams.metadata, { sdkId: null });
    delete obj.sdkId;
    this.addMissingJsonFilters(
      this.newQueryForm.controls['jsonObject'] as FormGroup,
      model.queryParams.jsonObject,
    );
    if (this.hasDynamicFilters) {
      const jsonObject = _.cloneDeep(model.queryParams.jsonObject);
      this.updateJsonFiltersValues(this.newQueryForm.controls['jsonObject'] as FormGroup, jsonObject);
    }
    delete obj.freeText;
    delete obj.priorities;
    this.newQueryForm.controls['metadata'].setValue(obj);
    this.newQueryForm.controls['isTemplateSearch'].setValue(
      model.type === QueryTypes.TEMPLATE ||
        model.type === QueryTypes.TEMPLATE_IDS,
    );
    this.newQueryForm.controls['tagId'].setValue(model.tagId);
    this.newQueryForm.controls['selectedViewId'].setValue(model.selectedViewId);
    this.newQueryForm.controls['sortModel'].setValue(model.sortModel);
  }

  public addMissingJsonFilters(jsonFiltersForm: FormGroup, jsonObject: any): void {
    if (jsonObject) {
      const jsonFilters: LogColumn[] = [];
      for (const item in jsonObject) {
        if (!jsonFiltersForm.controls[item]) {
          const newJsonFilter: LogColumn = new LogColumn();
          newJsonFilter.fieldName = item;
          newJsonFilter.headerName = item.replace('_coralogix_', '.');
          jsonFilters.push(newJsonFilter);
        }
      }
    }
  }

  public updateJsonFiltersValues(jsonFiltersForm: FormGroup, jsonObject: any): void {
    if (jsonObject) {
      this.newQueryForm.controls['jsonObject'].patchValue(jsonObject);

      // Case the dynamic form group not registered all controls yet
      if (!_.isEqual(this.newQueryForm.value.jsonObject, jsonObject)) {
        setTimeout(() => {
          this.newQueryForm.controls['jsonObject'].patchValue(jsonObject);
        }, 0);
      }
    }
  }

  public updateModelByForm(queryModel: LogQueryModel): LogQueryModel {
    const dates = this.getDateTime();
    queryModel.startDate = moment(dates.fromDate).unix() * 1000;
    queryModel.endDate = moment(dates.toDate).unix() * 1000;
    queryModel.queryParams.query.text = this.newQueryForm.value.text.toString();
    queryModel.queryParams.query.type = StringHelper.isRegex(
      this.newQueryForm.value.text.toString(),
    )
      ? TEXT_TYPES.REGEX
      : TEXT_TYPES.EXACT;
    queryModel.type = this.newQueryForm.value.isTemplateSearch
      ? QueryTypes.TEMPLATE
      : QueryTypes.FREE;
    queryModel.queryParams.metadata = this.newQueryForm.controls[
      'metadata'
    ].value;
    queryModel.queryParams.metadata.severity = this.newQueryForm.value.severity;
    queryModel.queryParams.jsonObject = this.newQueryForm.value.jsonObject
      ? this.initalizeDynamicFiltersFormGroup(
          this.newQueryForm.value.jsonObject,
        )
      : {};
    queryModel.tagId = this.newQueryForm.value.tagId;
    queryModel.selectedViewId = this.newQueryForm.value.selectedViewId;
    queryModel.sortModel = this.newQueryForm.value.sortModel
      ? this.newQueryForm.value.sortModel
      : [new SortModel()];
    return queryModel;
  }

  public initalizeDynamicFiltersFormGroup(value: any): any {
    const result = _.cloneDeep(value);
    Object.keys(result).forEach((field) => {
      if (!result[field]) {
        result[field] = [];
      }
    });
    return result;
  }

  public setText(text: string): void {
    this.setQueryFormTextValue(text);
    this.newQueryForm.controls['isTemplateSearch'].setValue(false);
  }

  public setQueryFormTextValue(text: string): void {
    this.newQueryForm.controls['text'].setValue(text);
  }

  public setTemplate(log: Log | LoggregationEntity): void {
    // we also need to refine the query to use severity
    const severity = SeverityTranslator.translateSeverity(
      log.metadata.severity.toString(),
    );
    const severities = [severity];
    this.newQueryForm.controls['severity'].setValue(severities);

    if (log instanceof Log) {
      delete log.metadata.companyId;
    }
    // we want to keep thr Application name and subsystem name.
    // so we will remove the rest
    const obj = Object.assign({}, log.metadata, {
      sdkId: [],
      category: [],
      methodName: [],
      className: [],
      computerName: [],
      IPAddress: [],
      threadId: '',
      processName: [],
      applicationName: [log.metadata.applicationName],
      subsystemName: [log.metadata.subsystemName],
    });

    delete obj.sdkId;
    delete obj.processName;

    this.newQueryForm.controls['metadata'].setValue(obj);

    this.newQueryForm.controls['text'].setValue(log.text);
    this.newQueryForm.controls['isTemplateSearch'].setValue(true);
  }

  public resetForm(): void {
    this.newQueryForm.reset(this.defaultValues);
  }

  public getDateTime(): { fromDate: Date; toDate: Date } {
    const fromDate = DateHelper.mergeDateAndTime(
      (this.newQueryForm.controls['dates'] as FormGroup).controls['from'].value,
      (this.newQueryForm.controls['dates'] as FormGroup).controls['fromTime']
        .value,
    );
    const toDate = DateHelper.mergeDateAndTime(
      (this.newQueryForm.controls['dates'] as FormGroup).controls['to'].value,
      (this.newQueryForm.controls['dates'] as FormGroup).controls['toTime']
        .value,
    );
    return {
      fromDate,
      toDate,
    };
  }

  public getDateForm(dates: IQueryDateFormValues): FormGroup {
    return new FormGroup(
      {
        from: new FormControl(dates.from, Validators.required),
        to: new FormControl(
          { value: dates.to, disabled: false },
          Validators.required,
        ),
        fromTime: new FormControl(dates.fromTime),
        toTime: new FormControl({
          value: dates.toTime,
          disabled: false,
        }),
      },
      DateValidator.checkDelta('from', 'fromTime', 'to', 'toTime'),
    );
  }

  private generateNewForm(values: any): void {
    this.newQueryForm = new FormGroup({
      dates: this.getDateForm(values.dates),
      severity: new FormControl(values.severity),
      text: new FormControl(values.text),
      isTemplateSearch: new FormControl(values.isTemplateSearch),
      metadata: new FormGroup({
        category: new FormControl([]),
        className: new FormControl([]),
        methodName: new FormControl([]),
        applicationName: new FormControl([]),
        subsystemName: new FormControl([]),
        computerName: new FormControl([]),
        severity: new FormControl([]),
        IPAddress: new FormControl([]),
        // sdkId:new FormControl([]),
        threadId: new FormControl(values.metadata.threadId)
      }),
      jsonObject: new FormGroup({}),
      tagId: new FormControl(values.tagId),
      selectedViewId: new FormControl(values.selectedViewId),
      sortModel: new FormControl(values.sortModel),
    });
  }
}
