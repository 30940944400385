import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { State, getReindexesPermissions, getReindexesPermissionsError, getReindexes, getArchivingProvider, getArchivingProvidersLoading, loadingReindexes} from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs';
import { ReindexEditorActions } from '@app/roi/reindex/state/actions/reindex-editor.actions';
import { ReindexActions } from '@app/roi/reindex/state/actions/reindex.actions';
import { Reindex, ReindexBucketPermission } from '../../models/reindex.model';
import { ModalDirective } from '../../../../shared/directives/modal.directive';
import { ArchivingProvidersActions } from '@app/settings/reducer/archiving-providers/archiving-providers.action';

@Component({
  selector: 'sh-reindex-container',
  templateUrl: './reindex-container.component.html',
  styleUrls: ['./reindex-container.component.scss']
})
export class ReindexContainerComponent implements OnInit, OnDestroy {
  @ViewChild(ModalDirective, { static: true })
  public modalDirectiveElm: ModalDirective;
  public reindexes$: Observable<Reindex[]>;
  public permissions: ReindexBucketPermission;
  public displayPermissionErrorMsg: boolean = false;
  public displayArchiveMissingMsg: boolean = false;
  public isReindexesLoading: boolean;
  public isArchiveProvidersLoading: boolean;
  public reindexPermCheckError: boolean;

  private getReindexPermissions$: Subscription;
  private archivingProviders$: Subscription;
  private isReindexesLoading$: Subscription;
  private archiveProvidersLoading$: Subscription;
  private reindexPermErr$: Subscription;

  constructor(private store: Store<State>) {
    this.reindexPermErr$ = this.store.select(getReindexesPermissionsError).subscribe(permErr => this.reindexPermCheckError = permErr);
    this.isReindexesLoading$ = this.store.select(loadingReindexes).subscribe(reindexesLoading => this.isReindexesLoading = reindexesLoading);
    this.archiveProvidersLoading$ = this.store.select(getArchivingProvidersLoading).subscribe(archivesLoading => this.isArchiveProvidersLoading = archivesLoading);
    this.archivingProviders$ = this.store.select(getArchivingProvider).subscribe(archive => this.displayArchiveMissingMsg = !archive ? true : false);
    this.store.dispatch(new ReindexActions.GetBucketPermissionsAction());
    this.store.dispatch(new ArchivingProvidersActions.GetArchivingProvidersAction());
  }

  public ngOnInit(): void {
    this.getReindexPermissions$ = this.store.select(getReindexesPermissions).subscribe( perm => {
      if (perm) {
        const { msg, hasPermission } = perm;
        this.displayPermissionErrorMsg = (!hasPermission && msg?.includes('read')) ? true : false;
      }
    });
    this.reindexes$ = this.store.select(getReindexes);
    this.store.dispatch(new ReindexActions.GetReindexesAction(null));
  }

  public openEditor(): void {
    this.store.dispatch(new ReindexEditorActions.ToggleEditorAction(true));
  }

  public openBucketEditor(): void {
    this.store.dispatch(new ReindexEditorActions.TogglePermissionsEditorAction(true));
  }

  public openS3PermissionModal(): void {
    this.modalDirectiveElm.showModal();
  }

  public closeModal(): void {
    this.modalDirectiveElm.hideModal();
  }

  public get isLoading(): boolean {
    return this.isReindexesLoading || this.isArchiveProvidersLoading;
  }

  public get btnDisabled(): boolean {
    return this.isLoading || this.reindexPermCheckError|| this.displayPermissionErrorMsg || this.displayArchiveMissingMsg;
  }

  public ngOnDestroy() {
    this.getReindexPermissions$.unsubscribe();
    this.archivingProviders$.unsubscribe();
    this.archiveProvidersLoading$.unsubscribe();
    this.isReindexesLoading$.unsubscribe();
    this.reindexPermErr$.unsubscribe();
  }
}
