<div cdkDropListGroup class="group-of-groups">
  <div *ngFor="let group of ruleGroupsForm?.controls; let groupIndex = index; let lastGroup = last" class="group-of-groups__group-item">
    <div
      class="group-of-groups__rule-group-box"
      cdkDropList
      (cdkDropListDropped)="handleDropAction($event)"
      [cdkDropListData]="(group['controls']?.rules)['controls']"
    >
      <ng-container
        *ngFor="let rule of (group['controls']?.rules)['controls']; let ruleIndex = index; let lastRule = last; let firstRule = first"
      >
        <div class="rules-slider-gap" *ngIf="dragInProgress && firstRule"></div>
        <div class="rules-order" *ngIf="!dragInProgress">{{ ruleIndex + 1 }}</div>
        <mat-expansion-panel
          cdkDrag
          (cdkDragStarted)="setDragInProgress(true)"
          (cdkDragEnded)="setDragInProgress(false)"
          #ruleExpandPanel
          [cdkDragData]="rule"
          class="draggable-rule-container"
        >
          <div *cdkDragPlaceholder class="placeholder-rule"></div>
          <mat-expansion-panel-header class="draggable-rule-header-container" cdkDragHandle>
            <mat-panel-title>
              <div class="anchor-image draggable-rule-header-container__anchor"></div>
              <span class="rule-header-container-type">{{ rulesServerTypesToViewTypes[rule?.controls?.type?.value] }}</span>
              <div fxLayout="column" fxFlex="84">
                <div class="rule-header-container-name">{{ rule?.controls?.name?.value }}</div>
                <div class="rule-header-container-description">{{ rule?.controls?.description?.value }}</div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="draggable-rule-container__content" fxLayout="row">
            <div class="rule-form-container" fxFlex="100" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="26px">
              <sh-dynamic-rule-form
                [ruleForm]="rule"
                [extendedFieldOptions]="extendedFieldOptions$ | async"
                [extendedUniqueFields]="extendedUniqueFields$ | async"
                [ruleConfig]="ruleFormConfigs[rule.value.type]"
                [type]="rule.value.type"
              ></sh-dynamic-rule-form>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-slide-toggle
                  fxFlexAlign="end"
                  labelPosition="after"
                  class="coralogix rules-panel-activation-slider "
                  [formControl]="(rule?.controls)['enabled']"
                >
                  <span class="slider-label-after">ACTIVE</span>
                  <span class="slider-label-before">INACTIVE</span>
                </mat-slide-toggle>
                <cgx-button
                  label="DELETE RULE"
                  size="sm"
                  width="120px"
                  color="error"
                  textNoWrap
                  [flat]="true"
                  (press)="deleteRule(groupIndex, ruleIndex)"
                ></cgx-button>
              </div>
            </div>
            <div class="sample-container" fxLayout="column">
              <div class="coralogix input-group log-sample">
                <label class="input-label log-sample__label">
                  <span class="text">Sample log</span>
                </label>
                <div class="rule-description log-sample__description">
                  Insert a sample log to see how it will be affected by this rule.
                </div>
                <textarea
                  shAutoresize
                  type="text"
                  [minHeight]="'199px'"
                  class="noresize-textarea regex-marking form-control autoresizable consolas-font log-sample__text-area"
                  [formControl]="rule.controls['logExample']"
                ></textarea>
              </div>
              <sh-sample-log-preview
                *ngIf="!!rule.value['logExampleRes']"
                [samplePreviewData]="rule.value['logExampleRes']"
                [sampleForBlockRule]="rule.value.type === eServerRuleTypes.block"
                [sampleForAllowRule]="rule.value['blockMatching'] === eServerRuleTypes.allow"
              ></sh-sample-log-preview>
            </div>
          </div>
        </mat-expansion-panel>
        <ng-container
          [formGroup]="rulesSlidersArray"
          *ngIf="!dragInProgress && !lastRule && (rulesSlidersArray?.controls)['rule' + groupIndex + ruleIndex] as ruleSliderControl"
        >
          <sh-inner-labels-slider
            fxFlexAlign="center"
            class="sliders rules-slider"
            #ruleSlider
            [formControl]="$any(ruleSliderControl)"
            (click)="
              updateSlider(ruleIndex, sliderTypes.ruleSlider, $event);
              splitGroupToTwoGroups((ruleGroupsForm?.controls)[groupIndex], groupIndex, ruleIndex)
            "
          ></sh-inner-labels-slider>
        </ng-container>
        <div class="rules-slider-gap" *ngIf="dragInProgress"></div>
      </ng-container>
    </div>
    <ng-container
      [formGroup]="groupsSlidersArray"
      *ngIf="!lastGroup && (groupsSlidersArray?.controls)['group' + groupIndex] as groupSliderControl"
    >
      <sh-inner-labels-slider
        class="sliders group-slider"
        [formControl]="$any(groupSliderControl)"
        (click)="
          updateSlider(groupIndex, sliderTypes.groupSlider, $event);
          mergeTwoGroups((ruleGroupsForm?.controls)[groupIndex], (ruleGroupsForm?.controls)[groupIndex + 1], groupIndex)
        "
      ></sh-inner-labels-slider>
    </ng-container>
  </div>
  <sh-inner-labels-slider
    class="sliders add-rule-slider"
    (click)="updateAddRuleSlider($event)"
    [formControl]="addRuleToLastGroupSlider"
  ></sh-inner-labels-slider>
  <sh-drop-down-button-menu
    [buttonText]="'add rule'"
    [menuOptions]="ruleTypeOptions"
    (buttonPressed)="addRule($any($event))"
  ></sh-drop-down-button-menu>
</div>
