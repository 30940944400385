import { Injectable } from '@angular/core';
import { getSelectedTag, getSelectedTagForCompare, getTags, State } from '@app/app.reducers';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ITagWithCompareBaseRequest, ITagNCompareTimeRanges, ITagRequest, ITimeRange } from '@app/deployment/shared/models/summary.models';
import { addSeconds, differenceInSeconds, subSeconds } from 'date-fns';
import { Store } from '@ngrx/store';
import { Build } from '@app/deployment/models/build';
import { MaxAllowedRequestSeconds } from '@app/deployment/shared/consts/tags.consts';
import { intersection, isNull } from 'lodash-es';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TagsCompareService {
  constructor(private store: Store<State>, private userSettingsProvider: UserSettingsProvider, private router: Router) {}

  public getComparePayload(): Observable<ITagWithCompareBaseRequest> {
    const selected$ = this.store.select(getSelectedTag);
    const compare$ = this.store.select(getSelectedTagForCompare);
    const tagsList$ = this.store.select(getTags);
    return combineLatest([selected$, compare$, tagsList$]).pipe(
      filter(([selected, compareTag, tags]) => !isNull(selected) && !!tags?.length),
      filter(([selected, compareTag, tags]) => {
        if (tags[tags.length - 1].id !== selected.id) {
          return !isNull(compareTag);
        }
        return true;
      }),
      map(([selected, compareTag, tags]) => {
        const selectedStart = selected.tag_timestamp;
        const compareStart = compareTag?.tag_timestamp;

        const globalApplications = this.userSettingsProvider.userSettings.queryMetadata.applicationName;
        const globalSubsystems = this.userSettingsProvider.userSettings.queryMetadata.subsystemName;

        const applicationNameLists = [selected.getApplicationNameList(), compareTag.getApplicationNameList(), globalApplications].filter(
          list => !!list.length,
        );
        const applicationNames = intersection(...applicationNameLists);

        const subsystemNameLists = [selected.getSubsystemNameList(), compareTag.getSubsystemNameList(), globalSubsystems].filter(
          list => !!list.length,
        );

        const isAllSubsystems = this.router.url.includes('all-subsystems/true');
        const subsystemNames = isAllSubsystems ? [] : intersection(...subsystemNameLists);

        const { selectedTagEndTime, compareTagEndTime } = this.getSelectedAndComparedEndTimes(selected, compareTag, tags, isAllSubsystems);
        const selectedOverallDeltaInSeconds = Math.abs(differenceInSeconds(selectedStart, selectedTagEndTime));
        if (compareStart > selectedStart || !compareTag) {
          // if the selected tag is the last tag or there is no compare tag
          let endTime = selectedTagEndTime;
          let noCompareStart = 0;
          if (selectedOverallDeltaInSeconds > MaxAllowedRequestSeconds) {
            endTime = addSeconds(selectedStart, MaxAllowedRequestSeconds).valueOf();
            noCompareStart = subSeconds(selectedStart, MaxAllowedRequestSeconds).valueOf();
          } else {
            noCompareStart = subSeconds(selectedStart, selectedOverallDeltaInSeconds).valueOf();
          }

          const timeRangesForLastTag = this.getTimeRangePayload(selectedStart, endTime, noCompareStart, selectedStart);
          return {
            tag: this.getBasicTagRequestData(applicationNames, subsystemNames, timeRangesForLastTag?.tag),
            compareTag: this.getBasicTagRequestData(applicationNames, subsystemNames, timeRangesForLastTag?.compareTag),
          };
        }
        const compareOverallDeltaInSeconds = Math.abs(differenceInSeconds(compareStart, compareTagEndTime));

        const finalEndTimes: ISelectedNCompareEnd = this.getTagsEndTimesWithMaxTimeLimitation(
          selectedStart,
          compareStart,
          selectedOverallDeltaInSeconds,
          compareOverallDeltaInSeconds,
        );
        const timeRanges: ITagNCompareTimeRanges = this.getTimeRangePayload(
          selected.tag_timestamp,
          finalEndTimes.selectedTagEndTime,
          compareTag.tag_timestamp,
          finalEndTimes.compareTagEndTime,
        );

        return {
          tag: this.getBasicTagRequestData(applicationNames, subsystemNames, timeRanges?.tag),
          compareTag: this.getBasicTagRequestData(applicationNames, subsystemNames, timeRanges?.compareTag),
        };
      }),
    );
  }

  private getTimeRangePayload(tagStart: number, tagEnd: number, compareStart: number, compareEnd: number): ITagNCompareTimeRanges {
    return {
      tag: {
        startTime: tagStart,
        endTime: tagEnd,
      },
      compareTag: {
        startTime: compareStart,
        endTime: compareEnd,
      },
    };
  }

  private getSelectedAndComparedEndTimes(
    selected: Build,
    compareTag: Build,
    tags: Build[],
    isSubsystemsFiltered: boolean,
  ): ISelectedNCompareEnd {
    let selectedTagEndTime = 0;
    const selectedApplications = this.userSettingsProvider.userSettings.queryMetadata.applicationName;
    const selectedSubsystems = this.userSettingsProvider.userSettings.queryMetadata.subsystemName;
    const selectedComparedTagList = selected.getComparableTagList(tags, selectedApplications, selectedSubsystems, isSubsystemsFiltered);
    const [selectedOlderTagList, selectedNewerTagList] = selected.olderNewerTagLists(selectedComparedTagList);
    if (!selectedNewerTagList.length) {
      // is selected tag is the last one created
      selectedTagEndTime = new Date().getTime();
    } else {
      selectedTagEndTime = selectedNewerTagList[selectedNewerTagList.length - 1].tag_timestamp;
    }

    const compareTagComparedTagList = compareTag.getComparableTagList(tags, selectedApplications, selectedSubsystems, isSubsystemsFiltered);
    const [compareTagOlderTagList, compareTagNewerTagList] = compareTag.olderNewerTagLists(compareTagComparedTagList);

    const compareTagEndTime = compareTagNewerTagList[compareTagNewerTagList.length - 1].tag_timestamp;
    return {
      selectedTagEndTime,
      compareTagEndTime,
    };
  }

  private getTagsEndTimesWithMaxTimeLimitation(
    selectedStart: number,
    compareStart: number,
    selectedOverallInSeconds: number,
    compareOverallInSeconds: number,
  ): ISelectedNCompareEnd {
    const minDurationInSeconds = Math.min(selectedOverallInSeconds, compareOverallInSeconds, MaxAllowedRequestSeconds);
    const selectedTagEndTime = addSeconds(selectedStart, minDurationInSeconds).getTime();
    const compareTagEndTime = addSeconds(compareStart, minDurationInSeconds).getTime();
    return {
      selectedTagEndTime,
      compareTagEndTime,
    };
  }

  private getBasicTagRequestData(applicationName: string[], subsystemName: string[], tagTimes: ITimeRange): ITagRequest {
    return {
      ...tagTimes,
      applicationName,
      subsystemName,
    };
  }
}

interface ISelectedNCompareEnd {
  selectedTagEndTime: number;
  compareTagEndTime: number;
}
