import { Pipe, PipeTransform } from '@angular/core';

export interface IRegexIndex {
  jsonProps: RegExp;
  isBoolean: RegExp;
  isNull: RegExp;
  hasParentheses: RegExp;
  hasColon: RegExp;
}

type PrettyJsonClasses = 'key' | 'string' | 'boolean' | 'null' | 'number';

@Pipe({
  name: 'prettyjson',
})
export class PrettyJsonPipe implements PipeTransform {
  private regex: IRegexIndex = {
    jsonProps: /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    isBoolean: /true|false/,
    isNull: /null/,
    hasParentheses: /^"/,
    hasColon: /:$/,
  };
  private highlightedKeys: { [key: string]: string } = null;
  private query: string = '';

  public transform(val: any, highlightedKeys?: { [key: string]: string }, query?: string): string {
    this.highlightedKeys = highlightedKeys;
    this.query = query;
    return this.syntaxHighlight(val);
  }

  private syntaxHighlight(json: any): string {
    const { regex } = this;
    if (typeof json !== 'string') {
      json = JSON.stringify(json, undefined, 2);
    }
    json = PrettyJsonPipe.cleanJSONString(json);
    return json.replace(regex.jsonProps, this.formatStringMatch.bind(this));
  }

  private formatStringMatch(match: string): string {
    const className = this.setMatchClass(match);

    if (this.query) {
      match = this.highlightSearchQuery(match);
    }

    if (className === 'key') {
      let key = match.replace(':', '');
      if (this.highlightedKeys) {
        key = PrettyJsonPipe.handleHighlightedKey(key, this.highlightedKeys);
      }
      return `<span class="${className}">${key}</span>:`;
    }

    const matchText = match
      .replace(/(?:\\n|\n)(?:\\[tnr]|[\t\n\r])*/g, '<br />')
      .replace(/&lt;em&gt;/g, '<em style="background: #ffff00; font-style: normal">')
      .replace(/&lt;\/em&gt;/g, '</em>');
    return `<span class="${className}">${matchText}</span>`;
  }

  private highlightSearchQuery(match: string): string {
    return match.replace(new RegExp(this.query, 'gi'), '<mark>$&</mark>');
  }

  private static cleanJSONString(json: string): string {
    return json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  }

  private static handleHighlightedKey(key: string, highlightedKeys: { [key: string]: string }): any {
    const keyName = key.replace(/"/g, '');
    return highlightedKeys[keyName] ? `"${highlightedKeys[keyName]}"` : key;
  }

  private setMatchClass(match: string): string {
    const { regex } = this;
    let className: PrettyJsonClasses;
    const hasParentheses = regex.hasParentheses.test(match);
    const hasColon = regex.hasColon.test(match);
    const isBoolean = regex.isBoolean.test(match);
    const isNull = regex.isNull.test(match);

    if (hasParentheses && hasColon) {
      className = 'key';
    } else if (hasParentheses) {
      className = 'string';
    } else if (isBoolean) {
      className = 'boolean';
    } else if (isNull) {
      className = 'null';
    } else {
      className = 'number';
    }
    return className;
  }
}
