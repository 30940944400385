import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sh-info-notification',
  templateUrl: './info-notification.component.html',
  styleUrls: ['./info-notification.component.scss'],
})
export class InfoNotificationComponent {
  public startExitAnimation: boolean = false;
  @Input() public sideIconUrl: string = 'support-help';
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public buttonText: string;
  @Output() public actionButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() private closeClicked: EventEmitter<any> = new EventEmitter<any>();

  public emitCloseAction(): void {
    this.startExitAnimation = true;
    setTimeout(() => this.closeClicked.emit(), 500);
  }
}
