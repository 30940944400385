import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../../auth/shared/services/auth.service';

@Injectable()
export class TokenResolver implements Resolve<string> {
  constructor(private authService: AuthService) {}

  public resolve(route: ActivatedRouteSnapshot) {
    const token = route.params.token;
    this.authService.storeUser(token, '', 'coralogix');
    return '';
  }
}
