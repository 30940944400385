import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sh-coralogix-time-input-section',
  templateUrl: 'coralogix-time-input-section.component.html',
  styleUrls: ['coralogix-time-input-section.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CoralogixTimeInputSectionComponent,
    multi: true,
  }],
})
export class CoralogixTimeInputSectionComponent implements ControlValueAccessor {
  @Input() public hasValidationError: boolean;
  @Input() public maxValue: number;
  @Input() public minValue: number = 0;
  @Input() public disabled: boolean;
  @Output() public twoDigitsInput: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('timeInput', { static: true })
  public timeInput: ElementRef;
  public options: number[];
  public formattedValue: string;

  private onChangeFn: (val: any) => any;
  private onTouchedFn: (val?: any) => any;
  private valueBeforeFormatting: number;
  private readonly maxValueLength: number = 2;

  public getFormattedValue(val: number): string {
    if (parseInt(val.toString(), 10) < 10) {
      return '0' + val.toString();
    }
    return Number(val).toString();
  }

  public increaseValue(): void {
    let value = parseInt(this.formattedValue, 10);
    if (value === this.maxValue) {
      value = 0;
      this.onModelChange(value);
      return;
    }
    value++;
    this.formattedValue = this.getFormattedValue(value);
    this.onModelChange(value);
  }

  public decreaseValue(): void {
    let value = parseInt(this.formattedValue, 10);
    if (value === this.minValue) {
      value = this.maxValue;
      this.onModelChange(value);
      return;
    }
    value--;
    this.formattedValue = this.getFormattedValue(value);
    this.onModelChange(value);
  }

  public registerOnChange(fn: (val: any) => any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: (val: any) => any): void {
    this.onTouchedFn = fn;
  }

  public onModelChange(value: number): void {
    if (value === this.valueBeforeFormatting) {
      this.formattedValue = this.getFormattedValue(value);
    }
    let updatedValue = value;
    if (updatedValue > this.maxValue) {
      updatedValue = this.maxValue;
    }
    if (updatedValue < this.minValue || updatedValue === null) {
      updatedValue = this.minValue;
    }
    this.valueBeforeFormatting = updatedValue;
    if (this.onChangeFn) {
      this.onChangeFn(this.getFormattedValue(this.valueBeforeFormatting));
    }
    this.onTouchedFn();
  }

  public writeValue(value: string): void {
    if (!value) {
      this.formattedValue = this.getFormattedValue(this.minValue);
    }
    this.formattedValue = this.getFormattedValue(parseInt(value, 10));
  }

  public onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      return;
    }
    const maxValueFirstDigit = parseInt(this.maxValue.toString()[0], 10);
    if ((this.valueBeforeFormatting && this.valueBeforeFormatting.toString().length === this.maxValueLength)
      || maxValueFirstDigit < this.valueBeforeFormatting) {
      this.timeInput.nativeElement.blur();
      this.twoDigitsInput.emit();
    }
  }

  public makeInputFocused(): void {
    if (this.timeInput) {
      this.timeInput.nativeElement.focus();
    }
  }

  public onBlur(): void {
    if (!this.valueBeforeFormatting && this.valueBeforeFormatting !== 0) {
      return;
    }
    this.formattedValue = this.getFormattedValue(this.valueBeforeFormatting);
  }
}
