import { Condition } from '@app/tco/enums/condition.enum';
import { Priority } from '@app/tco/enums/priority.enum';
import { Severity } from '@app/tco/enums/severity.enum';
import { PolicyCriteria } from '@app/tco/models/policy-criteria.model';

export class Destroy {
    public static readonly type: string = '[QUOTA POLICY] Destroy';
}

export class Loading {
    public static readonly type: string = '[QUOTA POLICY] Loading';
    constructor(public bool: boolean) { }
}

export class GetQuotaPolicies {
    public static readonly type: string = '[QUOTA POLICY] Get Quota Policies';
}

export class AddNewPolicyMode {
    public static readonly type: string = '[QUOTA POLICY] Add New Policy Mode';
}

export class EditPolicy {
    public static readonly type: string = '[QUOTA POLICY] Edit Policy';
    constructor(public policy: PolicyCriteria) { }
}

export class CancelEditPolicy {
    public static readonly type: string = '[QUOTA POLICY] Cancel Edit Policy';
}

export class SaveEditPolicy {
    public static readonly type: string = '[QUOTA POLICY] Save Edit Policy';
    constructor(public policy: PolicyCriteria) { }
}

export class CancelNewPolicyMode {
    public static readonly type: string = '[QUOTA POLICY] Cancel New Policy Mode';
}

export class CreatePolicyCriteria {
    public static readonly type: string = '[QUOTA POLICY] Create Policy Criteria';
    constructor(
        public name: string,
        public applicationCondition: Condition,
        public applicationString: string,
        public subsystemCondition: Condition,
        public subsystemString: string,
        public severities: Severity[],
        public priority: Priority
    ) { }
}

export class SetPriority {
    public static readonly type: string = '[QUOTA POLICY] Set Priority';
    constructor(public priority: Priority, public policyId: string) { }
}

export class DeletePolicy {
    public static readonly type: string = '[QUOTA POLICY] Delete Policy';
    constructor(public id: string) { }
}

export class ChangePolicyOrder {
    public static readonly type: string = '[QUOTA POLICY] Change Policy Order';
    constructor(public previousIndex: number, public currentIndex: number) { }
}

export class SetLoadingPolicyId {
    public static readonly type: string = '[QUOTA POLICY] Set Loading Policy Id';
    constructor(public policyId: string) { }
}
