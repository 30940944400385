import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { LogsFilterGridColumnsActions } from './logs-filter-grid-columns.actions';
import { LogsFilterGridColumnsService } from '../../../services/logs-filter-grid-columns.service';
import { of } from 'rxjs/observable/of';
import { switchMap, map } from 'rxjs/operators';

@Injectable()
export class LogsFilterGridColumnsEffects {
  @Effect()
  public getAvailableColumns: Observable<Action> = this.actions$.pipe(
    ofType(
      LogsFilterGridColumnsActions.ActionTypes.LOGS_FILTER_GRID_COLUMNS_INIT,
    ),
    switchMap(() => {
      return this.logsFilterGridColumnsService
        .loadCompanyIndexFields()
        .map(
          (filterData) => {
              return new LogsFilterGridColumnsActions.LogsFilterGridColumnsInitCompleted(
              filterData,
            );
          }
        )
        .catch((err) =>
          of(
            new LogsFilterGridColumnsActions.LogsFilterGridColumnsFailure(err),
          ),
        );
    }),
  );

  @Effect()
  public getAvailableTemplateColumns: Observable<Action> = this.actions$.pipe(
    ofType(
      LogsFilterGridColumnsActions.ActionTypes.LOGS_FILTER_GRID_TEMPLATE_COLUMNS_INIT,
    ),
    switchMap(() => {
      return this.logsFilterGridColumnsService
        .loadCompanyIndexFieldsFromRedis()
        .map(
          (filterData) =>
            new LogsFilterGridColumnsActions.LogsFilterGridColumnsInitCompleted(
              filterData,
            ),
        )
        .catch((err) =>
          of(
            new LogsFilterGridColumnsActions.LogsFilterGridColumnsFailure(err),
          ),
        );
    }),
  );

  @Effect()
  public selectColumns: Observable<Action> = this.actions$.pipe(
    ofType(LogsFilterGridColumnsActions.ActionTypes.SELECT_COLUMNS),
    map(
      (action: any) =>
        new LogsFilterGridColumnsActions.LogsFilterGridColumnsSelected(
          action.payload,
        ),
    ),
  );

  @Effect()
  public removeColumns: Observable<Action> = this.actions$.pipe(
    ofType(LogsFilterGridColumnsActions.ActionTypes.DESELECT_COLUMNS),
    map(
      (action: any) =>
        new LogsFilterGridColumnsActions.LogsFilterGridColumnsDeselected(
          action.payload,
        ),
    ),
  );
  constructor(
    private actions$: Actions,
    private logsFilterGridColumnsService: LogsFilterGridColumnsService,
  ) {}
}
