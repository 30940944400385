import { Estimation } from './../models/reindex.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Reindex,
  ReindexRequest,
  ReindexResponse,
  ReindexBucketPermission,
  RetryResponse,
  CreateCachedQueryResponse,
} from '@app/roi/reindex/models/reindex.model';
import { Observable } from 'rxjs';
import { Constants } from '@app/constants';
import { TypedJSON } from 'typedjson';

@Injectable()
export class ReindexService {
  constructor(private http: HttpClient) {}

  public createReindex(reindex: ReindexRequest): Observable<Reindex> {
    return this.http.put(Constants.reindexUrl, { reindex }).map((res) => TypedJSON.parse(res, Reindex));
  }

  public getReindexes(): Observable<Reindex[]> {
    return this.http.get(Constants.reindexUrl).map(this.extractReindexes);
  }

  public getReindexesStatus(ids: string[]): Observable<Reindex[]> {
    return this.http.post(Constants.reindexUrl + '/status', { ids }).map(this.extractReindexes);
  }

  public hasBucketPermissions(): Observable<ReindexBucketPermission> {
    return this.http
      .get(Constants.GetUrl() + Constants.archivingProvider + '/permissions')
      .map((res) => TypedJSON.parse(res, ReindexBucketPermission));
  }

  public retryReindex(reindex: Reindex): Observable<RetryResponse> {
    return this.http
      .post(Constants.reindexUrl + '/' + reindex.id, {})
      .map((res) => TypedJSON.parse(res, Reindex))
      .map((newReindex) => new RetryResponse(reindex.id, newReindex));
  }

  public createCachedQuery(reindexId: string): Observable<CreateCachedQueryResponse> {
    return this.http
      .post(Constants.reindexUrl + '/query/' + reindexId, {})
      .map((res) => TypedJSON.parse(res, CreateCachedQueryResponse));
  }

  public extractReindexes(res: any): Reindex[] {
    const parsedRes = TypedJSON.parse(res, ReindexResponse);
    return parsedRes.reindexes;
  }

  public getReindexSizeEstimation(reindex: ReindexRequest): Observable<any> {
    return this.http
      .post(Constants.reindexUrl + '/estimation', { reindex })
      .map((res) => TypedJSON.parse(res, Estimation));
  }
}
