import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { getMetadataFieldCompleted } from '@shared/state/actions/generic-metadata.actions';

export interface IGenericMetadataState {
  [key: string]: string[];
}
const genericMetadataReducer: ActionReducer<IGenericMetadataState, Action> = createReducer(
  {},
  on(getMetadataFieldCompleted, (state, { payload }) => ({
    ...state,
    [payload.filedName]: payload.metadata
  }))
);
export function reducer(state: IGenericMetadataState = {}, action: Action): IGenericMetadataState {
  return genericMetadataReducer(state, action);
}
