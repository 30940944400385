import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Constants } from '@app/constants';
import { Metadata } from '@app/logs-new/shared/models/query-metadata';
import { selectLogQuery, selectLogsQueries, selectSearchQuery } from '@app/logs-new/store/logs-queries/logs-queries.selectors';
import { createBoolHashMap, filterArrByQuery } from '@shared/utils/utils';
import { State } from '@app/app.reducers';
import { ILogsModuleState } from '@app/logs-new/store';
import { removeMetadataPrefix } from '@app/logs-new/shared/utils/logs-columns.utils';
import { getQueryLastWord } from '@app/logs-new/shared/utils/query-search.utils';
import { selectCurrentTeamId, selectTeamsFilters, selectTeamsMap } from '@app/user/state/teams/team.selectors';
import { QueryExternalFilters } from '@app/logs/shared/models/query-external-filters';
import { externalLogFields } from '@app/logs-new/shared/assets/data/log-fields';
import { ILogsField } from '@app/logs-new/shared/models/logs-columns.model';
import { LogsFieldType } from '@app/logs-new/shared/models/graph/logs-graph.model';

export const selectLogsData = createSelector(createFeatureSelector<State, ILogsModuleState>('logsModule'), state => state?.logsData);

export const selectGridExpandedMode = createSelector(selectLogsData, state => state.expandedMode);

export const selectAllFields = createSelector(selectLogsData, state => state.allFields);

export const selectGraphTags = createSelector(selectLogsData, state => state.tags);

export const selectShowSidebar = createSelector(selectLogsData, state => state.showSidebar);

export const selectShowGraph = createSelector(selectLogsData, state => state.showGraph);

export const selectLoadingFilters = createSelector(selectLogsData, state => state.loadingFilters);

export const selectLoadingLogs = createSelector(selectLogsData, state => state.loadingLogs);

export const selectLoadingTemplates = createSelector(selectLogsData, state => state.loadingTemplates);

export const selectLogsCount = createSelector(selectLogsData, state => state.count);

export const selectGridLogsCount = createSelector(selectLogsData, state =>
  state.count?.logsCount > Constants.MAX_GRID_LOGS ? Constants.MAX_GRID_LOGS : state.count?.logsCount,
);

export const selectActiveTab = createSelector(selectLogsData, state => state?.activeTab);

export const selectLoadingStats = createSelector(selectLogsData, state => state.loadingStats);

export const selectShowInfoPanel = createSelector(selectLogsData, state => state.showInfoPanel);

export const selectMetadataFields = createSelector(selectAllFields, fields => {
  return fields?.metadata || [];
});

export const selectLogFields = createSelector(selectAllFields, fields => {
  return fields?.logs || [];
});

export const selectMetricFields = createSelector(selectAllFields, fields => {
  return fields?.metricFields || [];
});

export const selectPrometheusMetricFields = createSelector(selectAllFields, fields => {
  return fields?.promMetricFields || [];
});

export const selectAllLogsFields = createSelector(selectLogFields, selectMetadataFields, (metadataFields, logFields) => {
  return [...metadataFields, ...logFields, ...externalLogFields].sort(sortFieldsByName);
});

export const selectAllFieldsArr = createSelector(
  selectAllLogsFields,
  selectMetricFields,
  selectPrometheusMetricFields,
  (logFields, metricFields, promMetricFields) => {
    return [...logFields, ...metricFields, ...promMetricFields].sort(sortFieldsByName);
  },
);

export const selectAllNumericFieldsArr = createSelector(
  selectMetricFields,
  selectPrometheusMetricFields,
  (metricFields, promMetricFields) => {
    return [...metricFields, ...promMetricFields].sort(sortFieldsByName);
  },
);

export const selectMetricFieldsExists = createSelector(
  selectMetricFields,
  selectPrometheusMetricFields,
  (metricFields, promMetricFields) => {
    const length = metricFields.length + promMetricFields.length;
    return length > 0;
  },
);

export const selectGraphGroupByFields = createSelector(selectLogsData, selectAllLogsFields, (state, logFields) => {
  return {
    logFields,
    metricsLabelsMap: state?.allFields?.metricLabelsMap,
    metricLabels: state?.allFields?.metricLabels,
  };
});

export const selectSelectedFiltersArr = createSelector(selectLogsQueries, state => [
  ...Object.keys(state.logQuery.queryParams.metadata),
  ...Object.keys(state.logQuery.queryParams.jsonObject),
]);

export const selectSelectedExternalFiltersArr = createSelector(selectLogsQueries, state => [
  ...Object.keys(state.logQuery?.queryParams?.externalFilters || {}),
]);

export const selectAvailableMetadataFields = createSelector(selectMetadataFields, selectSelectedFiltersArr, (metadata, selectedFilters) =>
  metadata.filter(field => !selectedFilters.includes(removeMetadataPrefix(field.colId))),
);

export const selectFilteredMetadataFields = createSelector(selectAvailableMetadataFields, (metadataColumns, searchQuery: string) =>
  filterArrByQuery(metadataColumns, searchQuery, 'headerName'),
);

export const selectFilteredLogFields = createSelector(
  selectAllFields,
  selectSelectedFiltersArr,
  (logFields, selectedFilters, searchQuery: string) => {
    if (!logFields?.logs) return [];
    const filteredLogFields = filterArrByQuery(logFields.logs, searchQuery, 'headerName');
    return filteredLogFields.filter(field => !selectedFilters.includes(field.colId));
  },
);

export const selectAllFilteredFields = createSelector(selectAllLogsFields, (allFields, searchQuery: string = '') =>
  filterArrByQuery(allFields, searchQuery, 'headerName'),
);

export const selectFilteredSlicedSortedLogColumns = createSelector(
  selectAllLogsFields,
  selectSearchQuery,
  (allFields, searchQuery: string) => {
    return filterArrByQuery(allFields, getQueryLastWord(searchQuery), 'headerName')
      .slice(0, Constants.SEARCH_VISIBLE_QUERIES_AMOUNT)
      .map(item => item.headerName);
  },
);

export const selectAllLogsFilters = createSelector(selectLogsData, state => state.logsFilters);

export const selectSelectedFilterFields = createSelector(selectLogQuery, logQuery => {
  return {
    ...createFilterSelectedMap(logQuery.queryParams.jsonObject),
    ...createFilterSelectedMap(logQuery.queryParams.metadata),
  };
});

export const selectSelectedExternalFilterFields = createSelector(selectLogQuery, logQuery => {
  return {
    ...createFilterSelectedMap(logQuery?.queryParams?.externalFilters || {}),
  };
});

export const selectLogsStats = createSelector(selectLogsData, state => state.logsStats);

export const selectGridLoading = createSelector(selectLogsData, state => state.gridLoading);

export const selectExternalFilters = createSelector(selectTeamsFilters, teams => {
  return { teams };
});

export const selectFilterIndicatorsMap = createSelector(selectCurrentTeamId, currentTeamId => {
  return { teams: currentTeamId };
});

export const selectAllExternalFields = createSelector(selectExternalFilters, externalFilters => {
  return Object.keys(externalFilters).map(externalFilter => ({ colId: externalFilter, headerName: externalFilter }));
});

export const selectCustomMaps = createSelector(selectTeamsMap, teams => {
  return { teams };
});

export const selectExternalFilteredFields = createSelector(
  selectAllExternalFields,
  selectSelectedExternalFiltersArr,
  (externalFilters, selectedFilters, searchQuery: string) => {
    const filteredLogFields = filterArrByQuery(externalFilters, searchQuery, 'headerName');
    return filteredLogFields.filter(field => !selectedFilters.includes(field.colId));
  },
);

export const selectLogsCapability = createSelector(selectLogsData, state => state.logsCapability);

function createFilterSelectedMap(
  queryFields: Metadata | QueryExternalFilters | { [key: string]: number[] | string[] },
): { [key: string]: boolean } {
  return Object.entries(queryFields).reduce((acc, [key, values]) => {
    acc[key] = createBoolHashMap({ array: values, lowerCaseKey: true });
    return acc;
  }, {});
}

function sortFieldsByName(a: ILogsField, b: ILogsField): number {
  return a.headerName.localeCompare(b.headerName);
}
