import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { PaymentPlan } from '@app/shared/models/payment-plan';

@Injectable()
export class PaymentProvider {
  public formGroup: FormGroup;
  public plans: Array<PaymentPlan> = [];
  public selectedPlan: PaymentPlan;
  public subscribedPlan: PaymentPlan;
  public companyId: number;

  public initialized: boolean;

  public getPlanByVolumeAndRetation(volume: string, retention: string): PaymentPlan {
    const plan = this.plans.filter(p => p.id === volume + '_' + retention + '_' + 'plan') as PaymentPlan[];
    if (plan.length > 0) {
      return plan[0];
    }
    return null;
  }

  public setDefaultPlan(): void {
    const customePlan = this.getCustomePlan();
    if (customePlan) {
      this.selectedPlan = customePlan;
    } else {
      this.selectedPlan = this.getPlanByVolumeAndRetation(this.formGroup.value.volume, this.formGroup.value.retention);
    }
  }

  public getCustomePlan(): PaymentPlan {
    let customePlan = null;
    this.plans.forEach(plan => {
      const splited = plan.id.split('_');
      if (splited.length === 4 && splited[3] === this.companyId.toString()) {
        customePlan = plan;
      }
    });
    return customePlan;
  }

  public isUserPaying(): boolean {
    const customPlan = this.getCustomePlan();
    const userHasCustomerPlans = !!customPlan;
    const userHasSubscribedPlan = !!this.subscribedPlan;

    if (userHasCustomerPlans && !userHasSubscribedPlan) {
      return false;
    }
    return true;
  }
}
