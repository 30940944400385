import { Injectable } from '@angular/core';
import { Entity } from '@shared/models/entity.model';
import { LogSeveritiesEnumValues } from '@app/logs/shared/models/log-severities.enum';

@Injectable({
    providedIn: 'root',
})
export class LogsSeverityOptionsFactoryService {

    public create(): Entity[] {
        return [
            new Entity({ name: 'Debug', id: 1 }),
            new Entity({ name: 'Verbose', id: 2 }),
            new Entity({ name: 'Info', id: 3 }),
            new Entity({ name: 'Warning', id: 4 }),
            new Entity({ name: 'Error', id: 5 }),
            new Entity({ name: 'Critical', id: 6 }),
        ];
    }
  public createWithEnumValues(): Entity[] {
    return [
      new Entity({ name: 'Debug', id: LogSeveritiesEnumValues.DEBUG }),
      new Entity({ name: 'Verbose', id: LogSeveritiesEnumValues.VERBOSE }),
      new Entity({ name: 'Info', id: LogSeveritiesEnumValues.INFO }),
      new Entity({ name: 'Warning', id: LogSeveritiesEnumValues.WARNING }),
      new Entity({ name: 'Error', id: LogSeveritiesEnumValues.ERROR }),
      new Entity({ name: 'Critical', id: LogSeveritiesEnumValues.CRITICAL }),
    ];
  }
}
