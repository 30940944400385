import { Component, Input } from '@angular/core';
import { ChartModel, ChartSeriesModel, PlotlineModel } from '@shared/controls/charts/models/chart.model';
import { Observable } from 'rxjs';
import { VolumeAnomalyGraphResult } from '../../../shared/models/volume-anomaly-query.model';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sh-volume-anomaly-graph',
  templateUrl: './volume-anomaly-graph.component.html',
  styleUrls: ['./volume-anomaly-graph.component.scss'],
})
export class VolumeAnomalyGraphComponent {
  @Input() set anomalyGraphData(value: Observable<[VolumeAnomalyGraphResult, number]>) {
    value.pipe(take(1)).subscribe(([graphResult, anomalyTimestamp]) => {
      this.model = this.createChart(graphResult, anomalyTimestamp);
    });
  }

  public model: ChartModel;

  public createChart(value: VolumeAnomalyGraphResult, anomalyTimestamp: number): ChartModel {
    const rangeSeries = new ChartSeriesModel();
    rangeSeries.color = '#d4e4ff';
    rangeSeries.name = 'expected';
    rangeSeries.type = 'areasplinerange';
    rangeSeries.data = value.range.map(([timestamp, max, min]) => [moment.unix(timestamp / 1000).toDate(), max, min]);

    const currentSeries = new ChartSeriesModel();
    currentSeries.color = '#216def';
    currentSeries.data = value.currentValue.map(([timestamp, val]) => [moment.unix(timestamp / 1000).toDate(), val]);
    currentSeries.name = 'actual';
    currentSeries.type = 'spline';

    const chartModel = new ChartModel();
    chartModel.height = 100;
    chartModel.xPlotlines = new Array<PlotlineModel>();
    chartModel.xType = 'timestamp';
    chartModel.series = [rangeSeries, currentSeries];
    chartModel.scale = 'm';
    chartModel.backgroundColor = 'transparent';
    chartModel.fontColor = 'white';
    chartModel.categories = value.range.map(([timestamp, max, min]) => moment.unix(timestamp / 1000).toDate());
    chartModel.stacking = null;
    chartModel.sharedTooltip = true;
    chartModel.lineWidth = 0;

    const anomalyTime = value.currentValue.find(([timestamp, val]) => timestamp >= anomalyTimestamp);
    if (anomalyTime) {
      const anomalySeries = new ChartSeriesModel();
      anomalySeries.color = '#ff5edd';
      anomalySeries.data = value.currentValue.map(([timestamp, val]) => {
        if (timestamp === anomalyTime[0]) {
          return [moment.unix(timestamp / 1000).toDate(), val];
        }
        return [moment.unix(timestamp / 1000).toDate(), null];
      });
      anomalySeries.marker = {
        enabled: true,
        radius: 5,
      };
      anomalySeries.name = 'anomaly';
      anomalySeries.type = 'spline';
      chartModel.series.push(anomalySeries);
    }
    return chartModel;
  }
}
