import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { LoggregationlHelper } from '../../shared/services/loggregation.helper';
import { LoggregationService } from '../../shared/services/loggregation.service';
import {
  StatisticsCategory,
  StatisticsQueryDefinition,
} from '../../../statistics/shared/models/statistics-query-definition';
import { LogQueryModel } from '../../../logs/shared/models/logsquery.model';
import { LoggregationParameterRequest } from '../../shared/interfaces/loggregation-parameter-request';
import * as _ from 'lodash';
import { StatisticService } from '../../../statistics/shared/services/statistics-service';
import { Widget } from '../../../widgets/shared/models/widget';
import { WidgetsService } from '../../../widgets/shared/services/widgets.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WidgetAddComponent } from '../../../shared/controls/widgets/widget-add/widget-add.component';
declare let $;
@Component({
  selector: 'sh-loggregation-item',
  templateUrl: './loggregation-item.component.html',
  styleUrls: ['./loggregation-item.component.scss'],
})
export class LoggregationItemComponent implements OnInit {
  @ViewChild('Container', { static: true }) public containerEl: ElementRef;
  @ViewChild('modal', { static: true }) public modal: ElementRef;
  public parameterQueryModel: any;
  public parameterData: any;
  public chartType: any;
  public isChartOverlayVisible: boolean;
  @Input() set text(item: any) {
    this.containerEl.nativeElement.appendChild(
      LoggregationlHelper.loggregationRenderer(
        item,
        this,
        'loggregation-Cell-Container',
      ),
    );
  }
  @Input() public queryModel: LogQueryModel;
  constructor(
    private loggregationService: LoggregationService,
    private widgetService: WidgetsService,
    public dialog: MatDialog,
    private statisticsService: StatisticService,
  ) {}
  public ngOnInit() {}
  public onCellClicked(e, params) {
    const paramId = e.target.attributes.getNamedItem('parameterId')
      ? e.target.attributes.getNamedItem('parameterId').value
      : null;
    const paramType = e.target.attributes.getNamedItem('parameterType')
      ? e.target.attributes.getNamedItem('parameterType').value
      : null;
    if (e.target.attributes.getNamedItem('parameterType')) {
      this.showModal(params.id, paramId, paramType);
    }
  }
  public OnMouseout(e) {}
  public OnMouseover(e) {}

  public showModal(templateId, parameterId, parameterType) {
    $(this.modal.nativeElement).modal('show');

    if (parameterType == 'json') {
      this.getJsonParameterData(parameterId, parameterType);
    } else {
      const request = new LoggregationParameterRequest();
      request.templateId = templateId;
      request.paramId = parameterId ? parseInt(parameterId) : null;
      this.parameterQueryModel = request;
      if (parameterId) {
        this.loggregationService
          .getParameterData(
            templateId,
            parameterId,
            parameterType,
            this.queryModel,
          )
          .subscribe(
            (res) => {
              this.parameterData = res;
              this.chartType = this.getTypeTitle(parameterType) + ' Parameter';
              this.isChartOverlayVisible = false;
            }, // this will set the chart child component with data;
            (error) => console.log(error),
          );
      } else {
        this.loggregationService
          .getOccurrencesData(templateId, this.queryModel)
          .subscribe(
            (res) => {
              this.parameterData = res;
              this.chartType = 'Occurrences';
              this.isChartOverlayVisible = false;
            },
            (error) => console.log(error),
          );
      }
    }
  }

  public getJsonParameterData(paramId, parameterType) {
    const paramQueryModel: LogQueryModel = _.cloneDeep(this.queryModel);
    paramQueryModel.queryParams.aggField = paramId;
    const statQueryDef: StatisticsQueryDefinition = new StatisticsQueryDefinition();
    statQueryDef.logQeuryModel = paramQueryModel;
    statQueryDef.category = StatisticsCategory.LOGS;
    statQueryDef.seriesType = 'column';
    this.statisticsService
      .getStats(statQueryDef)
      .first()
      .subscribe(
        (res) => {
          this.parameterData = [res];
          this.chartType = this.getTypeTitle(parameterType) + ' Parameter';
          this.isChartOverlayVisible = false;
        },
        (error) => console.log(error),
      );
  }

  public hideModal() {
    this.parameterData = null;
    this.chartType = 'Loading...';
    this.isChartOverlayVisible = true;
    $(this.modal.nativeElement).modal('hide');
  }
  public getTypeTitle(paramType) {
    switch (paramType) {
      case 'free':
        return 'Free';
      case 'numeric':
        return 'Numeric';
      case 'categorical':
        return 'Categorical';
      case 'json':
        return 'Json';
    }
    return '';
  }
  public pinToDashboard(widget: Widget) {
    this.widgetService
      .addWidgets(widget)
      .first()
      .subscribe((widget) => console.log(widget));
  }
  public addWidget(query: any) {
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = { query, url: 'loggregation/parameter' };
    config.panelClass = 'add-edit-widget-container';
    const dialogRef = this.dialog.open(WidgetAddComponent, config);
    dialogRef
      .afterClosed()
      .first()
      .subscribe((widget) => {
        this.pinToDashboard(widget);
      });
  }
}
