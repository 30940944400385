<sh-coralogix-sidebar
  #sidebarComponent
  (close)="closeAlertsEditor()"
  [templateRef]="editor"
>
</sh-coralogix-sidebar>
<ng-template #editor>
  <sh-alerts-editor
    (save)="onSave($event)"
    (delete)="onDelete($event)"
    [mode]="mode"
    [alert]="alert"
    [isLoading]="isLoading$ | async"
    [form]="form"
    [selectOptions]="selectOptions"
    [invalidErrMsg]="isAlertQuerySyntaxInvalid$ | async"
  >
  </sh-alerts-editor>
</ng-template>
