import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sh-loading-groks',
  templateUrl: './loading-groks.component.html',
  styleUrls: ['./loading-groks.component.scss'],
})
export class LoadingGroksComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
