import { intersection, partition } from 'lodash';
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

@jsonObject
export class TagType {
  @jsonMember
  public id: number;
  @jsonMember
  public type: string;
}

@jsonObject
export class TagExtraInfo {
  @jsonMember
  public id: number;
  @jsonMember
  public json: string;
}

@jsonObject
export class Build {
  @jsonMember
  public id: number;
  @jsonMember
  public company_id: number;
  @jsonMember
  public text_key: string;
  @jsonMember
  public text_value: string;
  @jsonMember
  public tag_status: string;
  @jsonMember
  public avatar: string;
  @jsonMember
  public link: string;
  @jsonMember
  public application_name: string;
  @jsonMember
  public subsystem_name: string;

  @jsonMember
  public tag_timestamp: number;
  @jsonMember
  public created_at: Date;
  @jsonMember
  public updated_at: Date;
  @jsonMember
  public json: string;
  @jsonMember
  public tag_type: TagType;

  @jsonMember
  public tags_extra_info: TagExtraInfo;

  public type(): string {
    return this.tag_type.type;
  }

  public getComparableTagList(
    tagList: Build[],
    globalApplicationNames: string[],
    globalSubsystemNames: string[],
    ignoreSubsystems: boolean,
  ): Build[] {
    const subsystemNames = this.getSubsystemNameList();
    const applicationNames = this.getApplicationNameList();

    let comparableTagList = tagList
      .filter(tag => tag.id !== this.id)
      .filter(tag => {
        const currentApplicationNames = tag.application_name ? tag.application_name.split(',') : [];
        const currentSubsystemNames = tag.subsystem_name ? tag.subsystem_name.split(',') : [];
        const bothSubsystemListsNotEmpty = !!subsystemNames.length && !!currentSubsystemNames.length;
        const sharedSubsystemNames = intersection(subsystemNames, currentSubsystemNames);

        if (!!applicationNames.length && !!currentApplicationNames.length) {
          const sharedApplicationNames = intersection(applicationNames, currentApplicationNames);
          if (!sharedApplicationNames.length) {
            return false;
          }
          if (bothSubsystemListsNotEmpty) {
            return ignoreSubsystems || !!sharedSubsystemNames.length;
          }
          return true;
        }
        if (bothSubsystemListsNotEmpty) {
          return ignoreSubsystems || !!sharedSubsystemNames.length;
        }
        return true;
      });

    if (globalApplicationNames.length) {
      comparableTagList = comparableTagList.filter(tag => {
        return !tag.application_name ? true : intersection(globalApplicationNames, tag.getApplicationNameList()).length;
      });
    }

    if (globalSubsystemNames.length) {
      comparableTagList = comparableTagList.filter(tag => {
        return !tag.subsystem_name ? true : intersection(globalSubsystemNames, tag.getSubsystemNameList()).length;
      });
    }

    return comparableTagList;
  }

  public olderNewerTagLists(tagList: Build[]): [Build[], Build[]] {
    return partition(tagList, tag => this.tag_timestamp > tag.tag_timestamp);
  }

  public getApplicationNameList(): string[] {
    return !this.application_name ? [] : this.application_name.split(',');
  }

  public getSubsystemNameList(): string[] {
    return !this.subsystem_name ? [] : this.subsystem_name.split(',');
  }
}

@jsonObject
export class BuildResult {
  @jsonArrayMember(Build)
  public data: Build[];
}
