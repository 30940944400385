import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrettyObjectFormatterDirective } from './pretty-object-formatter.directive';

@NgModule({
  declarations: [PrettyObjectFormatterDirective],
  imports: [CommonModule],
  exports: [PrettyObjectFormatterDirective],
})
export class PrettyObjectFormatterModule {}
