import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/shared/guards/auth.guard';
import { AlertsComponent } from './alerts.component';
import { HerokuGurd } from '../shared/guards/heroku-payment.gurd';
import { ReadOnlyGuard } from '@shared/guards/read-only.guard';
import { PaymentGuard } from '@app/shared/guards/payment.guard';

export const AlertsRoutes: Routes = [
  {
    path: 'alerts',
    component: AlertsComponent,
    data: {
      title: 'Alerts',
    },
    canActivate: [PaymentGuard, HerokuGurd, AuthGuard, ReadOnlyGuard],
  },
  {
    path: 'alerts/new',
    component: AlertsComponent,
    canActivate: [PaymentGuard, HerokuGurd, AuthGuard, ReadOnlyGuard],
  },
  {
    path: 'alerts/:id',
    component: AlertsComponent,
    canActivate: [PaymentGuard, HerokuGurd, AuthGuard, ReadOnlyGuard],
  },
];
