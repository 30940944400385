import { UserAlertViewTypes } from '@app/alerts/alerts-editor/models/alert-editor-view.models';
import { IRadioButtonGroupItem } from '@app/alerts/alerts-editor/components/radio-btn-group/radio-btn-group.model';

export const AlertTypeNameToValue = {
  standard: UserAlertViewTypes.UserTextAlert,
  ratio: UserAlertViewTypes.RatioAlert,
  newValue: UserAlertViewTypes.NewValueAlert,
  cardinality: UserAlertViewTypes.Cardinality,
  relativeTime: UserAlertViewTypes.RelativeTime,
  metric: UserAlertViewTypes.MetricAlert,
};

export enum AlertEditorComponentNames {
  details,
  types,
  query,
  queryArray,
  conditions,
  recipients,
  schedule,
  notificationContent,
  verifyAlert,
  history,
}

export const TwelveHoursInMinutes = 720;

export enum ConditionsOptions {
  notifyImmediately = 0,
  less = 1,
  more = 3,
  moreThanUsual = 4,
  newValue = 5,
}

export enum ArithmeticOptions {
  average = 0,
  min = 1,
  max = 2,
  sum = 3,
  count = 4,
  percentile = 5,
}

export const AlertTypeKey: string = 'userAlertTypeId';

export const AlertTypesOptions: IRadioButtonGroupItem[] = [
  {
    name: 'STANDARD',
    value: UserAlertViewTypes.UserTextAlert,
    description: 'Alert based on number of log occurrences',
  },
  {
    name: 'RATIO',
    value: UserAlertViewTypes.RatioAlert,
    description: 'Alert based on the ratio between queries',
  },
  {
    name: 'NEW VALUE',
    value: UserAlertViewTypes.NewValueAlert,
    description: 'Alert on a never before seen log value',
  },
  {
    name: 'UNIQUE COUNT',
    value: UserAlertViewTypes.Cardinality,
    description: 'Alert based on unique value count per key',
  },
  {
    name: 'TIME RELATIVE',
    value: UserAlertViewTypes.RelativeTime,
    description: 'Alert based on ratio between timeframes',
  },
  {
    name: 'METRIC',
    value: UserAlertViewTypes.MetricAlert,
    description: 'Alert based on arithmetic operators for metrics',
    badge: 'BETA',
  },
];

export const AlertTypeViewsDefs: { [alertComponentName: string]: AlertEditorComponentNames[] } = {
  [AlertTypeNameToValue.standard]: [
    AlertEditorComponentNames.details,
    AlertEditorComponentNames.types,
    AlertEditorComponentNames.query,
    AlertEditorComponentNames.conditions,
    AlertEditorComponentNames.recipients,
    AlertEditorComponentNames.schedule,
    AlertEditorComponentNames.notificationContent,
    AlertEditorComponentNames.verifyAlert,
  ],
  [AlertTypeNameToValue.ratio]: [
    AlertEditorComponentNames.details,
    AlertEditorComponentNames.types,
    AlertEditorComponentNames.query,
    AlertEditorComponentNames.queryArray,
    AlertEditorComponentNames.conditions,
    AlertEditorComponentNames.recipients,
    AlertEditorComponentNames.schedule,
    AlertEditorComponentNames.notificationContent,
  ],
  [AlertTypeNameToValue.newValue]: [
    AlertEditorComponentNames.details,
    AlertEditorComponentNames.types,
    AlertEditorComponentNames.query,
    AlertEditorComponentNames.conditions,
    AlertEditorComponentNames.recipients,
    AlertEditorComponentNames.schedule,
    AlertEditorComponentNames.notificationContent,
  ],
  [AlertTypeNameToValue.cardinality]: [
    AlertEditorComponentNames.details,
    AlertEditorComponentNames.types,
    AlertEditorComponentNames.query,
    AlertEditorComponentNames.conditions,
    AlertEditorComponentNames.recipients,
    AlertEditorComponentNames.schedule,
    AlertEditorComponentNames.notificationContent,
  ],
  [AlertTypeNameToValue.relativeTime]: [
    AlertEditorComponentNames.details,
    AlertEditorComponentNames.types,
    AlertEditorComponentNames.query,
    AlertEditorComponentNames.conditions,
    AlertEditorComponentNames.recipients,
    AlertEditorComponentNames.schedule,
    AlertEditorComponentNames.notificationContent,
  ],
  [AlertTypeNameToValue.metric]: [
    AlertEditorComponentNames.details,
    AlertEditorComponentNames.types,
    AlertEditorComponentNames.query,
    AlertEditorComponentNames.conditions,
    AlertEditorComponentNames.recipients,
    AlertEditorComponentNames.schedule,
  ],
};

export const PrometheusMetricsMatcherId: string = 'coralogix.metrics.prometheus';
