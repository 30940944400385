import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { TooltipComponent } from '@shared/controls/tooltip/tooltip.component';

@Directive({ selector: '[shTooltip]' })
export class TooltipDirective implements OnInit {
  @Input('tooltip') public text: string = '';
  @Input('position') public position: string = '';

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    const positionStrategy = this.getPosition();

    this.overlayRef = this.overlay.create({ positionStrategy });
  }
  @HostListener('mouseenter')
  public show(): void {
    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
    tooltipRef.instance.text = this.text;
  }

  @HostListener('mouseout')
  public hide(): void {
    this.overlayRef.detach();
  }

  private getPosition(): FlexibleConnectedPositionStrategy {
    if (this.position === 'top') {
      return this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetY: -10,
        },
      ]);
    }
    return this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: 10,
      },
    ]);

  }
}
