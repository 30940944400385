<div class="navbar">
  <a [routerLink]="['/dashboard']" class="logo-sm">
    <img src="assets/logo-white.svg" />
  </a>

  <div class="right-section">
    <div *ngIf="showUserSettingsFilters">
      <sh-user-settings-filters [isSmallScreen]="isSmallScreen"></sh-user-settings-filters>
    </div>
    <div class="avatar-initials-wrapper" #userMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu">
      <sh-avatar-initials size="sm" [name]="username$ | async"></sh-avatar-initials>
    </div>
    <div class="menu-button nav-item">
      <sh-mobile-app-menu [isUserHasAlertPremittions]="isUserHasAlertPermissions"></sh-mobile-app-menu>
    </div>
  </div>
</div>

<mat-menu #userMenu>
  <sh-user-menu (click)="$event.stopPropagation()" (close)="userMenuTrigger.closeMenu()"></sh-user-menu>
</mat-menu>
