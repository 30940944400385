import { type } from '@shared/services/utils';
import { Action } from '@ngrx/store';
import { IArchiveQuery } from '@app/roi/archive-queries/models/archive-query.model';

export namespace ArchiveQueryEditorActions {
  export const ActionTypes = {
    TOGGLE_EDITOR: type('[ArchiveQueryEditor] Toggle'),
  };

  export class ToggleEditorAction implements Action {
    public type = ActionTypes.TOGGLE_EDITOR;

    constructor(public payload: {openEditor: boolean, prePopulatedQuery: IArchiveQuery, isEditable: boolean}) {}
  }

  export type Actions =
    ToggleEditorAction;
}
