import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[shJsonFormatterClickOutside]',
})
export class JsonFormatterClickOutsideDirective {
  @Output()
  public shJsonFormatterClickOutside: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const hasNoSelection = !window.getSelection().toString();
    /*
      Without the condition of the no selection the closeOutside is triggered immediately after the first
      render of the menu, closing it right away. In order to avoid this, we check that no selection exists.
      Problem may be related to how 'pointerup' works, we use this event to listen for selection end.
     */
    if (!clickedInside && hasNoSelection) {
      this.shJsonFormatterClickOutside.emit(event);
    }
  }
}
