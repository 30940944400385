import { Injectable } from '@angular/core';

const APP_ID = 'xf85kx3u';

export interface IAnalyticEvent {
  eventName: string;
  metadata?: any;
}
// add more from the docs if needed
export enum SupportedIntercomePropTypes {
  showNewMessage = 'showNewMessage'
}

@Injectable()
export class AnalyticEventService {
  public dataLayerReport(value: any): void {
    try {
      (window as any).dataLayer.push(value);
    } catch (err) {
      console.log(err);
    }
  }

  public update(data: any): void {
    try {
      if (data.path) {
        const teamId = localStorage.getItem('t_id');
        window.analytics.page(data.path, { companyId: teamId });
      }
      window.Intercom('update', data);
    } catch (err) {
      console.log(err);
    }
  }

  public event(data: IAnalyticEvent): void {
    // data ? JSON.parse(data):{}
    // console.log(data);
    try {
      window.Intercom('trackEvent', data.eventName, data.metadata);
      const teamId = localStorage.getItem('t_id');
      window.analytics.track(data.eventName, Object.assign({}, data.metadata, { companyId: teamId }));
    } catch (err) {
      console.log(err);
    }
  }

  public showIntercome(): void {
    window.Intercom('show');
  }

  public showIntercomeWithProp(property: SupportedIntercomePropTypes): void {
    try {
      window.Intercom(property);
    } catch (err) {
      console.log(err);
    }
  }
}
