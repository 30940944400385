import { ConfigurationService } from '@app/services/configuration.service';
import { EmailFilterDefinition, EmailFilterModel } from '../../../settings/shared/models/emailFilterModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { TypedJSON } from 'typedjson';

@Injectable()
export class EmailFiltersService {
  private email$: Observable<any>;

  constructor(private http: HttpClient, private configService: ConfigurationService) {}

  private emailFiltersEndpoint: string = '/api/v1/emailFilters';
  private emailFiltersUrl = this.configService.getConfigHttpURL(this.emailFiltersEndpoint);

  public deleteFilter(filterId: number): Observable<number> {
    return this.http.delete(this.emailFiltersUrl + '/' + filterId).mapTo(filterId);
  }

  public addEmailFilter(filter: any): Observable<EmailFilterDefinition> {
    console.log(filter);
    const observable = this.http.post(this.emailFiltersUrl, filter).map((res: any) => {
      const emailFilterDef = new TypedJSON(EmailFilterDefinition).parse(res);
      return emailFilterDef;
    });
    return observable;
  }

  public editEmailFilter(filter: any): Observable<any> {
    const observable = this.http.post(this.emailFiltersUrl + '/edit', filter).map((res: any) => {
      const t = new TypedJSON(EmailFilterDefinition).parse(res);
      return t;
    });
    return observable;
  }

  public getEmailFilters(): Observable<EmailFilterModel> {
    // console.log(this.emailFiltersUrl);
    if (this.email$) {
      return this.email$;
    } else {
      const obs = (this.email$ = this.http
        .get(this.emailFiltersUrl)
        .map(res => this.extractData(res))
        .map(res => {
          this.email$ = null;
          return res;
        })
        .catch(error => this.handleError(error)));
      return obs;
    }
  }

  public extractData(res: any): EmailFilterModel {
    const model: EmailFilterModel = new TypedJSON(EmailFilterModel).parse(res);
    return model;
  }

  // tslint:disable-next-line:no-empty
  public submit(): void {}

  private handleError(error: any): Observable<never> {
    const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
