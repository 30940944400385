import { Component, Input, OnChanges } from '@angular/core';
import { Insight } from '@app/insights/shared/models/insight.model';
import { AlertModel } from '../shared/models/alert-model';
import { ChartModel } from '@shared/controls/charts/models/chart.model';
import { Alert } from '@app/insights/shared/models/alert.model';
import { AlertHelper } from '../shared/services/alert-helper';
import { SeveritiesHelper } from '@shared/services/severities.helper';
import { FormControl } from '@angular/forms';
import { AlertsInsight } from '../alerts-table/alerts-insights.model';

interface ITotalSeverity {
  size: number;
  y: number;
  name: string;
  severity: string;
  color: string;
}
@Component({
  selector: 'sh-alerts-summery-card',
  templateUrl: './alerts-summary-card.component.html',
  styleUrls: ['./alerts-summary-card.component.scss'],
})
export class AlertsSummaryCardComponent implements OnChanges {
  @Input() set alerts(alerts: AlertModel[]) {
    this._alerts = alerts;
  }
  @Input() public alertsInsightsTime: FormControl;

  @Input() public set insights(insights: Insight[]) {
    this._insights = insights;
  }
  @Input() public isLoading: boolean;
  public chartModel: ChartModel;
  public insightsTime = new AlertsInsight().insightsTime;
  public severityChartModel: ChartModel;
  public aggregationSummary: any;

  public _alerts: AlertModel[];
  public _insights: Insight[];

  public totalSeverity: ITotalSeverity[];

  public alertsInitialized: boolean = false;
  public insightsInitialized: boolean = false;

  constructor(private alertHelper: AlertHelper) {
    this.aggregationSummary = [];
  }

  public ngOnChanges(): void {
    if (this._alerts && this._insights) {
      this.updateChart();
      this.filterInsights();
    }
  }

  public get insights(): Insight[] {
    return this._insights;
  }

  private updateChart(): void {
    const dataByAlertType = [];
    const dataByAlertSeverity = [];

    if (this._alerts && this._alerts.length > 0 && this._insights && this._insights.length > 0) {
      // Getting all the alerts that are of type alerts and not insights.

      const alerts = this._insights.filter((t) => t instanceof Alert);

      this._alerts.forEach((currentAlert) => {
        const alertsCount = alerts.filter((x) => x.alertId === currentAlert.id).length;
        const severity = this.alertHelper.GetAlertSeverityCaption(currentAlert.alertSeverity);
        const alertName = currentAlert.name;
        const color = SeveritiesHelper.getEventSeverityColorByName(severity);
        if (alertsCount > 0) {
          dataByAlertType.push({
            size: alertsCount,
            severity,
            name: alertName,
            color,
          });
        }
      });
      this.aggregationSummary = dataByAlertType.sort((a, b) => {
        return b.size - a.size;
      });
      this.totalSeverity = Object.values(
        dataByAlertType.reduce((acc, item) => {
          if (acc[item.severity]) {
            acc[item.severity] = {
              size: acc[item.severity].size + item.size,
              y: acc[item.severity].size + item.size,
              name: item.severity,
              severity: item.severity,
              color: item.color,
            };
          } else {
            acc[item.severity] = item;
          }
          return acc;
        }, {}),
      );
      // al the alerts we received are of insights and are not based on user alerts
      if (dataByAlertType.length === 0) {
        // since there is no actual relevant data we  un-initialize
        this._alerts = [];
        this._insights = [];
        return;
      }

      SeveritiesHelper.severityOptions.forEach((currentSeverity) => {
        const severityCount = alerts.filter((x) => x.severity === currentSeverity.id).length;
        const severity = currentSeverity.name;
        const color = SeveritiesHelper.getAlertSeverityColor(currentSeverity.id);
        //        console.log(currentSeverity.id + ' ' + severityCount + ' ' + severity) + ' ' + color;
        if (severityCount > 0) {
          dataByAlertSeverity.push({ size: severityCount, severity, color });
        }
      });
      this.aggregationSummary = dataByAlertType.sort((a, b) => {
        return b.size - a.size;
      });
    }
  }

  private filterInsights(): void {
    const alertIds = this._alerts.map((alert) => alert.id);
    this._insights = this._insights.filter((t) => alertIds.includes(t.alertId));
  }
}
