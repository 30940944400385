import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  EArchiveQueryState,
  EReindexState,
  IArchiveQuery,
  ArchiveQueryPreviewParams,
  IQueryUiState,
  ArchiveQueryPreviewGridParams,
} from '@app/roi/archive-queries/models/archive-query.model';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { ArchiveQueryActions } from '@app/roi/archive-queries/state/actions/archive-query.actions';
import { ArchiveQueryEditorActions } from '@app/roi/archive-queries/state/actions/archive-query-editor.actions';
import { Constants } from '@app/constants';

@Component({
  selector: 'sh-archive-query-table-item',
  templateUrl: './archive-query-table-item.component.html',
  styleUrls: ['./archive-query-table-item.component.scss'],
})
export class ArchiveQueryTableItemComponent implements OnInit {
  @Input()
  public queryUiState: IQueryUiState;
  @Input()
  public archiveQuery: IArchiveQuery;

  public ArchiveQueryStateEnum: typeof EArchiveQueryState = EArchiveQueryState;
  public ReindexStateEnum: typeof EReindexState = EReindexState;
  public imgSrc: string = '../../../../../assets/roi/icon-reindex-ingesting.svg';
  public warningImg: string = '../../../../../assets/roi/icon-warning.svg';
  public maxQueryResults: number = 1000000;

  @Output()
  public onGetTsvs: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public onGetFailCause: EventEmitter<IArchiveQuery> = new EventEmitter<IArchiveQuery>();

  @Output()
  public onReindex: EventEmitter<IArchiveQuery> = new EventEmitter<IArchiveQuery>();

  @Output()
  public showLogsPreview: EventEmitter<ArchiveQueryPreviewParams> = new EventEmitter<ArchiveQueryPreviewParams>();

  @Output()
  public showLogsGrid: EventEmitter<ArchiveQueryPreviewGridParams> = new EventEmitter<ArchiveQueryPreviewGridParams>();

  @Output()
  public showLogsScreen: EventEmitter<IArchiveQuery> = new EventEmitter<IArchiveQuery>();

  public reindexState: EReindexState;
  public showProgression: boolean;
  public getProgress: number;
  public reindexStats: string;
  public queryArchiveStatus: string;
  public rowCountMessage: string;
  public queryType: string;

  constructor(private store: Store<State>) {}

  public ngOnInit(): void {
    this.reindexState = this.getReindexState();
    this.showProgression = this.isProgressActive();
    this.getProgress = this.getProgressPercentage();
    this.reindexStats = this.getArchiveQueryReindexStatusText();
    this.queryArchiveStatus = this.getArchiveQueryStatusText();
    this.rowCountMessage = this.getRowCountMessage();
    this.queryType = this.archiveQuery.querySyntax === Constants.PRESTO ? Constants.SQL : Constants.LUCENE;
  }

  public getReindexState(): EReindexState {
    return this.archiveQuery.reindexes.length > 0 ? this.archiveQuery.reindexes[0].status : EReindexState.possible;
  }

  public isProgressActive(): boolean {
    return this.archiveQuery.reindexes.length > 0 && this.archiveQuery.reindexes[0].status === EReindexState.pending;
  }

  public getProgressPercentage(): number {
    return this.archiveQuery.reindexes.length > 0 ? this.archiveQuery.reindexes[0].progressPercent : 0;
  }

  public getArchiveQueryStatusText(): string {
    switch (this.archiveQuery.status) {
      case EArchiveQueryState.active:
        return 'Download TSV';
      case EArchiveQueryState.pending:
        return 'Querying...';
      case EArchiveQueryState.failed:
        return 'Failed';
      case EArchiveQueryState.expired:
        return 'Expired';
    }
  }

  public getArchiveQueryReindexStatusText(): string {
    const state = this.getReindexState();
    switch (state) {
      case EReindexState.possible:
        return 'Reindex';
      case EReindexState.pending:
        return 'Reindexing...';
      case EReindexState.failed:
        return 'Failed';
      case EReindexState.finished:
        return 'View Logs';
    }
  }

  public onArchiveClicked(): void {
    if (this.archiveQuery.status === EArchiveQueryState.failed) {
      this.onGetFailCause.emit(this.archiveQuery);
    } else {
      this.onGetTsvs.emit(this.archiveQuery.id);
    }
  }

  public onReindexClicked(): void {
    if (this.archiveQuery.reindexes.length > 0 && this.archiveQuery.reindexes[0].status === EReindexState.finished) {
      this.showLogsScreen.emit(this.archiveQuery);
    } else {
      this.onReindex.emit(this.archiveQuery);
    }
  }

  public isArchiveButtonDisabled(): boolean {
    return !(this.archiveQuery.status === EArchiveQueryState.active || this.archiveQuery.status === EArchiveQueryState.failed);
  }

  public isReindexButtonHidden(): boolean {
    return !(this.archiveQuery.status === EArchiveQueryState.active) || this.archiveQuery.querySyntax === Constants.PRESTO;
  }

  public getActionMessage(state: EArchiveQueryState): string {
    switch (state) {
      case EArchiveQueryState.pending:
        return 'started';
      case EArchiveQueryState.active:
        return 'completed';
      case EArchiveQueryState.failed:
        return 'failed';
      case EArchiveQueryState.expired:
        return 'expired';
    }
  }

  public getRowCountMessage(): string {
    const rowCount = this.archiveQuery.rowCount;

    if (rowCount === null || rowCount === undefined) {
      return '';
    } else if (rowCount === 1) {
      return `${rowCount} result`;
    } else if (rowCount === 0) {
      return 'No results';
    } else {
      return `${rowCount} results`;
    }
  }

  public previewClicked(): void {
    if (this.archiveQuery.querySyntax === Constants.PRESTO) {
      this.showLogsGrid.emit(
        new ArchiveQueryPreviewGridParams({
          id: this.archiveQuery.id,
        }),
      );
    } else {
      this.showLogsPreview.emit(
        new ArchiveQueryPreviewParams({
          id: this.archiveQuery.id,
        }),
      );
    }
  }

  public collapseEventsLogView(id: string, showMore: boolean): void {
    this.store.dispatch(new ArchiveQueryActions.SetArchiveQueryUiState({ queryId: id, showMore }));
  }

  public expandEventsLogView(id: string, showMore: boolean): void {
    this.store.dispatch(new ArchiveQueryActions.SetArchiveQueryUiState({ queryId: id, showMore }));
  }

  public openEditor(): void {
    this.store.dispatch(
      new ArchiveQueryEditorActions.ToggleEditorAction({
        openEditor: true,
        prePopulatedQuery: this.archiveQuery,
        isEditable: false,
      }),
    );
  }

  public onCloneClicked(): void {
    this.store.dispatch(
      new ArchiveQueryEditorActions.ToggleEditorAction({
        openEditor: true,
        prePopulatedQuery: this.archiveQuery,
        isEditable: true,
      }),
    );
  }

  // @ts-ignore
  setArchiveButtonColor(): string {
    if (
      this.archiveQuery.status === this.ArchiveQueryStateEnum.pending ||
      this.archiveQuery.status === this.ArchiveQueryStateEnum.expired
    ) {
      return 'text-secondary';
    }
    if (this.archiveQuery.status === this.ArchiveQueryStateEnum.active) {
      return 'primary';
    }
    if (this.archiveQuery.status === this.ArchiveQueryStateEnum.failed) {
      return 'error';
    }
  }

  // @ts-ignore
  setReindexButtonColor(): string {
    if ((this.archiveQuery?.reindexes)[0]?.status === this.ReindexStateEnum.pending) {
      return 'text-secondary';
    }
    if (this.archiveQuery?.reindexes?.length < 1 || (this.archiveQuery?.reindexes)[0]?.status === this.ReindexStateEnum.finished) {
      return 'primary';
    }
    if ((this.archiveQuery?.reindexes)[0]?.status === this.ReindexStateEnum.failed) {
      return 'error';
    }
  }

  setReindexButtonLabel(): string {
    switch ((this.archiveQuery?.reindexes)[0]?.status) {
      case this.ReindexStateEnum.pending:
        return 'Reindexing...';
      case this.ReindexStateEnum.possible:
        return 'Reindex';
      case this.ReindexStateEnum.finished:
        return 'View Logs';
      case this.ReindexStateEnum.failed:
        return 'Failed';
      default:
        return 'Reindex';
    }
  }
}
