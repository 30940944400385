<div class="no-rows" *ngIf="suspectedTemplates && suspectedTemplates.length == 0 && !isLoading">
  <span class="no-rows__text">No Rows To Show</span>
</div>
<div fxLayout="column">
  <div class="template-item" fxLayout="column"
       *ngFor="let suspectedTemplate of suspectedTemplates; let suspectedIndex = index; let last = last">
    <div class="template-item__main" fxLayout="row">
      <div class="template-item__summary {{ theme }}"
           [class.loggregation-last-item]="last">
        <div class="template-item__info">
          <div class="template-item__severity text-cgx-{{
                suspectedTemplate.loggregationEntity.metadata.severity.toLowerCase()
              }}">
            {{ suspectedTemplate.loggregationEntity.metadata.severity }}
          </div>
          <div>
            <div class="template-item__ratio-item {{ theme }} ">
              {{suspectedTemplate.loggregationEntity.countToNormalRation | normalRatio: 100}}x
            </div>
            <div class="template-item__ratio-item-sentence">ABOVE NORMAL</div>
            <div class="template-item__ratio-item-errors" fxLayout="row" fxLayoutAlign="space-between center">
              <span fxFlex="80">Expected Errors</span>
              <span fxFlex="20" class="template-item__ratio-item-error-values">
                {{ratioData[suspectedIndex].expectedValues}}
              </span>
            </div>
            <div class="template-item__ratio-item-errors" fxLayout="row" fxLayoutAlign="space-between center">
              <span fxFlex="80">Actual Errors</span>
              <span fxFlex="20" class="template-item__ratio-item-error-values">
                {{ratioData[suspectedIndex].currentValue}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="template-item__content" fxFlex="80">
        <div class="template-item__content-text"
             innerHTML="{{ suspectedTemplate.loggregationEntity | loggregationPipe }}"
        ></div>
        <div class="template-item__metadata-row">
        <span class="template-item__metadata {{ theme }}">
          {{ suspectedTemplate.loggregationEntity.metadata.applicationName }}
        </span>
          <span class="template-item__metadata {{ theme }}">
          {{ suspectedTemplate.loggregationEntity.metadata.subsystemName }}
        </span>
        </div>
      </div>
      <cgx-button
        style="align-self: center;"
        label="VIEW LOGS"
        size="md"
        textNoWrap
        [outline]="true"
        (press)="onItemClicked(suspectedTemplate.link)"
      ></cgx-button>
    </div>
    <div class="separator"></div>
  </div>
</div>
<sh-widget-loader *ngIf="enableLoading" [hidden]="!isLoading" [addInvertedClass]="addInvertedClassToLoader"></sh-widget-loader>

