import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { AlertModel } from '@app/alerts/shared/models/alert-model';
import { AlertsDetailsFeedComponent } from '@app/alerts/alerts-details-feed/alerts-details-feed.component';
import { AlertsEditorContainerComponent } from '@app/alerts/alerts-editor/containers/alerts-editor-container.component';
import { AlertsEditorMode } from '@app/alerts/alerts-editor/models/alerts-editor-mode.enum';
import { AlertsBaseUrl } from '@app/alerts/shared/models/alerts.consts';
import { Location } from '@angular/common';

@Component({
  selector: 'sh-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
  @ViewChild('alertsEditorModal', { static: true })
  public alertsEditorModal: AlertsEditorContainerComponent;

  @ViewChild('alertsDetailsFeedComponent', { static: true })
  public alertsDetailsFeedComponent: AlertsDetailsFeedComponent;

  @Output()
  public openNewAlert: EventEmitter<void> = new EventEmitter<void>();
  constructor(private location: Location) {
  }

  public openAlertsEditor(isEdit: boolean, alert?: AlertModel): void {
    isEdit
      ? this.alertsEditorModal.openAlertsEditor(AlertsEditorMode.edit, alert)
      : this.alertsEditorModal.openAlertsEditor(AlertsEditorMode.create, alert);
  }

  public onAlertChangeClose(): void {
    this.location.replaceState(AlertsBaseUrl);
  }
}
