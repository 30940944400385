import { Component, OnDestroy } from '@angular/core';
import { TagsActions } from '../../shared/actions/tag.actions';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import {
  getDeletedTagId,
  getSelectedShowAllSubsystems,
  getSelectedTag,
  getSelectedTagLogRow,
  getSummaryViewState,
  getTags,
  State,
} from '@app/app.reducers';
import { Build } from '../../models/build';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EFeatureViewMode } from '@app/deployment/shared/models/reducer.models';

@Component({
  selector: 'sh-event-info-container',
  templateUrl: './event-info-container.component.html',
  styleUrls: ['./event-info-container.component.scss'],
})
export class EventInfoContainerComponent implements OnDestroy {
  public isNew: boolean;
  public build: Build;
  public deletedTagId$: Observable<number>;
  public filterBySubsystem$: Observable<boolean>;

  public getSelectedTagLogRow$: Observable<any>;
  public tags$: Observable<Build[]>;
  public tag$: Observable<Build>;
  public tag$tags$: Observable<any>;
  public viewState$: Observable<EFeatureViewMode> = this.store.select(getSummaryViewState);
  public exportLoading: boolean = false;
  public completedViewState: EFeatureViewMode = EFeatureViewMode.completed;
  protected destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store<State>, private route: ActivatedRoute) {
    this.subscribeToRouteChange();

    this.tag$ = this.store.select(getSelectedTag).map(tag => {
      return this.onSelectedTagChanged(tag);
    });

    this.deletedTagId$ = this.store.select(getDeletedTagId);

    this.filterBySubsystem$ = this.store.select(getSelectedShowAllSubsystems);
    this.getSelectedTagLogRow$ = this.store.select(getSelectedTagLogRow);
    this.tags$ = this.store.select(getTags);
    this.tag$tags$ = Observable.combineLatest(this.tag$, this.tags$);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public subscribeToRouteChange(): void {
    const [selectedTag$, newTag$] = this.route.params.map(params => params['tagId']).partition(tagId => tagId);

    combineLatest([selectedTag$, this.store.select(getTags)])
      .pipe(takeUntil(this.destroy$))
      .filter(([id, builds]) => !!id && !!builds)
      .map(([id, builds]) => builds.find(build => build.id === Number(id)))
      .filter(tag => !!tag)
      .subscribe(tag => this.store.dispatch(new TagsActions.SelectTagsAction(tag)));
  }

  public exportToPDF(): void {
    this.exportLoading = true;

    let tagName = '';
    this.store
      .select(getSelectedTag)
      .pipe(take(1))
      .subscribe(selectedTag => {
        tagName = selectedTag.text_value;
      });
    const content: HTMLElement = document.getElementById('tags-export-to-pdf');

    const ignoreElementList = content.querySelectorAll('[data-html2canvas-ignore]');
    const heightOfIgnoreElement = Array.from(ignoreElementList).reduce((acc, element) => {
      acc += element.clientHeight;
      return acc;
    }, 0);

    const width = content.clientWidth;
    const height = content.clientHeight - heightOfIgnoreElement;
    html2canvas(content, {
      scale: 1,
      width: width + 500,
      height: height + 400,
      x: -200,
      y: -100,
      windowWidth: width + 500,
      windowHeight: height + 400,
      ignoreElements: element => {
        return element.classList.contains('data-html2canvas-ignore');
      },
    })
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const ppi = this.getDocumentPPI();

        const doc = new jsPDF({
          unit: 'in',
          format: [(width + 500) / ppi, (height + 100) / ppi],
        });

        doc.addImage(imgData, 'JPEG', 0, 0, (width + 500) / ppi, (height + 400) / ppi, null, 'NONE');
        doc.save(`${tagName}.pdf`);
      })
      .finally(() => {
        this.exportLoading = false;
      });
  }

  private getDocumentPPI(): number {
    const elem = document.createElement('div');
    elem.style.width = '1in';
    document.body.appendChild(elem);
    const ppi = elem.offsetWidth;
    document.body.removeChild(elem);
    return ppi;
  }

  private onSelectedTagChanged(build: Build): Build {
    if (build) {
      this.isNew = false;
      build = Object.assign({}, build, {
        build_timestamp_date: moment(build.tag_timestamp).toDate(),
        build_timestamp_time: moment(build.tag_timestamp).format('HH:mm:ss'),
      });
    }
    return build;
  }
}
