import * as _ from 'lodash';

export class formatsHelper {
  public static numberWithCommas(input) {
    if (!_.isUndefined(input)) {
      return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return null;
  }
  public static stringSub(input, len) {
    if (!input) {
      return input;
    }

    if (input.length > len) {
      let result = input.substring(0, len);
      result += '...';
      return result;
    }

    return input;
  }

  public static numberKM(num, min_precision = 0) {
    if (num < min_precision) {
      return this.numberWithCommas(num);
    }

    if (num > 999999999) {
      const divider = num / 1000000000;
      if (parseFloat(divider.toFixed(1)) % 1 === 0) {
        return this.numberWithCommas(divider.toFixed(0)) + 'B';
      } else {
        return this.numberWithCommas(divider.toFixed(1)) + 'B';
      }
    }

    if (num > 999999) {
      const divider = num / 1000000;
      if (parseFloat(divider.toFixed(1)) % 1 === 0) {
        return this.numberWithCommas(divider.toFixed(0)) + 'M';
      } else {
        return this.numberWithCommas(divider.toFixed(1)) + 'M';
      }
    }

    if (num > 9999) {
      const divider = num / 1000;
      if (parseFloat(divider.toFixed(1)) % 1 === 0) {
        return this.numberWithCommas(divider.toFixed(0)) + 'K';
      } else {
        return this.numberWithCommas(divider.toFixed(1)) + 'K';
      }
    }
    return this.numberWithCommas(num);
  }

  public static getDateFormatByScale(scale: string) {
    switch (scale) {
      case 'd':
        return 'MMM Do';
      case 'h':
        return 'h:a';
      case 'm':
        return 'h:mm:a';
      case 's':
        return 'h:mm:ss';
    }
    return 'h:a';
  }

  public static getScale(value: number) {
    switch (value) {
      case 1000:
        return 's';
      case 60000:
        return 'm';
      case 3600000:
        return 'h';
    }
    return 'h';
  }

  public static getPathFromUrl(url) {
    return url.split('?')[0];
  }

  public static escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }
}
