import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ERuleServerTypes, IParsingTheme, IToggleParsingThemeAction } from '@app/rules/shared/models/rule.model';
import { ERuleTypesNames, RulesTypes } from '@app/rules/shared/models/rule-types-view.models';
import { Store } from '@ngrx/store';
import {
  getApplicationNames,
  getParsingThemes,
  getParsingThemesFilter,
  getPTRulesLeft,
  getSubsystemNames,
  State,
} from '@app/app.reducers';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { ShDialogService } from '@shared/services/dialogService';
import { OrderNumbersList } from '@app/rules/shared/models/ordered-list-view-models';
import * as parsingThemesActions from '@app/rules/actions/parsing-themes.actions';
import { AllValues, getPTListEmptyFilters, IParsingThemeFilter } from '@app/rules/shared/models/parsing-themes-filters';
import { RulesBaseThemeUrl } from '@app/rules/shared/models/rules-consts';
import { Location } from '@angular/common';
import { ParsingThemeFilterService } from '@app/rules/shared/services/parsing-theme-filter.service';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { CoralogixMultipleSelectionMenuComponent } from '@shared/form-controls/coralogix-multiple-selection-menu/coralogix-multiple-selection-menu.component';
import { Constants } from '@app/constants';
import { EConfigEnum } from '@app/global-config/globalConfig';
import { getGenericMetadataField } from '@shared/state/selectors/metadata.selectors';

@Component({
  selector: 'sh-parsing-theme-list-container',
  templateUrl: './parsing-theme-list-container.component.html',
  styleUrls: ['./parsing-theme-list-container.component.scss']
})
export class ParsingThemeListContainerComponent implements OnInit, OnDestroy {
  public filteredParsingThemes$: Observable<IParsingTheme[]>;
  public rulesLeft: number;
  public rulesCloseToLimit: boolean = false;
  public applicationOptions$: Observable<string[]>;
  public subsystemOptions$: Observable<string[]>;
  public searchForm: FormGroup;
  public ruleTypeOptions: {name: ERuleTypesNames | string, value: ERuleServerTypes | string}[];
  public subs: Subscription[] = [];
  public querySearchControl: FormControl;
  public orderList: OrderNumbersList;
  public isParsingThemeListFiltered: boolean;
  public metadataField1: string = Constants.metadataFields()[0];
  public metadataField2: string = Constants.metadataFields()[1];
  @ViewChild('multipleSelectionApps') public multipleSelectionApps: CoralogixMultipleSelectionMenuComponent;
  @ViewChild('multipleSelectionSubsystems') public multipleSelectionSubsystems: CoralogixMultipleSelectionMenuComponent;
  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private router: Router,
    private dialogsService: ShDialogService,
    private ptFilterService: ParsingThemeFilterService,
    private location: Location
  ) {}

  public ngOnInit(): void {
    this.setInitialData();
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public clearFilters(): void {
    this.store.dispatch(parsingThemesActions.setParsingThemeFiltersAction({ payload: getPTListEmptyFilters() }));
    if (this.multipleSelectionApps) {
      this.multipleSelectionApps.unselectAllOptions();
    }
    if (this.multipleSelectionSubsystems) {
      this.multipleSelectionSubsystems.unselectAllOptions();
    }
  }

  public groupSelected(group: IParsingTheme): void {
    const route = `${RulesBaseThemeUrl}/${group.id}`;
    this.router.navigateByUrl(route, { replaceUrl: true }).finally(() => {
      this.location.replaceState(route);
    });
  }

  public changeGroupEnabled([group, enable]: [IParsingTheme, boolean]): void {
    const payload: IToggleParsingThemeAction = {
      parsingThemeId: group.id,
      toggleState: enable
    };

    this.store.dispatch(parsingThemesActions.toggleParsingThemeAction({ payload }));
  }

  public onOrderGroupsChange(orderedGroups: IParsingTheme[]): void {
    this.store.dispatch(parsingThemesActions.updateParsingThemesOrderAction({ payload: orderedGroups }));
  }

  public showDragDisabledMessage(): void {
    this.dialogsService.showCoralogixMessage('Clear filters to drag item', null,
      dialogServiceIconClasses.failed);
  }

  private setInitialData(): void {
    this.setInitialFiltersData();
    const rulesSub = this.store.select(getPTRulesLeft).subscribe((rulesLeft) => {
      this.rulesLeft = rulesLeft;
      this.rulesCloseToLimit = rulesLeft <= 5;
    });

    const orderNFilteredIndicatorSub = combineLatest(
      [this.store.select(getParsingThemes), this.filteredParsingThemes$])
      .pipe(filter(data => !!data[0]?.length))
      .subscribe(([fullGroupList, filteredGroupList]) => {
        this.orderList = new OrderNumbersList(fullGroupList, filteredGroupList);
        this.isParsingThemeListFiltered = fullGroupList.length > filteredGroupList.length;
      });
    this.subs.push(orderNFilteredIndicatorSub, rulesSub);
  }

  private setInitialFiltersData(): void {
    const filtersStoreSelection$ = this.store.select(getParsingThemesFilter);
    this.filteredParsingThemes$ = combineLatest([this.store.select(getParsingThemes), filtersStoreSelection$])
      .switchMap(([parsingThemeList, parsingThemesFilter]) => {
        return of(this.ptFilterService.getFilteredData(parsingThemesFilter, parsingThemeList));
      });
    const filterDataFormSettingsSub = filtersStoreSelection$.subscribe((filterData) => {
      this.createOrUpdateFiltersForm(filterData);
    });
    this.applicationOptions$ = this.store.select(getGenericMetadataField(this.metadataField1 || 'applicationName'));
    this.subsystemOptions$ = this.store.select(getGenericMetadataField(this.metadataField2 || 'subsystemName'));
    this.ruleTypeOptions = RulesTypes.map(rule => ( { name: rule.title, value: rule.type }));
    this.ruleTypeOptions.unshift( { name: 'All Types', value: AllValues } );
    this.subs.push(filterDataFormSettingsSub);
  }

  private setFiltersFormSubscriptions(): void {
    const dropDownsSub = this.searchForm.valueChanges
      .distinctUntilChanged().subscribe((valueChanges: IParsingThemeFilter) => {
      const payload: IParsingThemeFilter = {
        ...valueChanges,
        searchTerm: this.querySearchControl.value
      };
      this.store.dispatch(parsingThemesActions.setParsingThemeFiltersAction({ payload }));
    });
    const querySearchSub = this.querySearchControl.valueChanges.pipe(debounceTime(1000))
      .subscribe((valueChanges: string) => {
        const payload: IParsingThemeFilter = {
          ...this.searchForm.getRawValue(),
          searchTerm: valueChanges
        };
      this.store.dispatch(parsingThemesActions.setParsingThemeFiltersAction({ payload }));
    });
    this.subs.push(dropDownsSub, querySearchSub);
  }

  private createOrUpdateFiltersForm(filterData: IParsingThemeFilter): void {
    let firstLoad = false;
     if (!!this.querySearchControl) {
       this.querySearchControl.setValue(filterData.searchTerm, { emitEvent : false });
     } else {
      this.querySearchControl = new FormControl(filterData.searchTerm);
      firstLoad = true;
    }
    if (!!this.searchForm) {
       this.searchForm.patchValue(filterData, { emitEvent : false });
    } else {
      this.searchForm = new FormGroup({
        applications: new FormControl(filterData.applications),
        subsystems : new FormControl(filterData.subsystems),
        ruleTypes: new FormControl(filterData.ruleTypes)
      });
    }
    if (firstLoad) {
      this.setFiltersFormSubscriptions();
    }
  }
}
