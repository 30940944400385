<div class="livetail-filters-panel-container">
  <i class="fa fa-list-alt list-icon" [ngClass]="{ 'contain-filters': !!keysToFilter.length }" #filtersIcon></i>
  <div class="ui special fluid popup">
    {{ TOOLTIP_TEXT }}
  </div>
  <div *ngFor="let filter of keysToFilter; let index = index" class="filter">
    <p class="filter-text">
      {{ filter }}
    </p>
    <div class="remove-button" (click)="removeFilter(index)">
      &times;
    </div>
  </div>
  <input
    #input
    placeholder="{{ keysToFilter?.length ? '' : PLACEHOLDER }}"
    type="text"
    class="input"
    [(ngModel)]="searchQuery"
    [ngClass]="{ 'no-filters': !keysToFilter?.length }"
    (input)="onSearch()"
    (keydown.backspace)="removeLastFilter()"
    (keydown.enter)="addFilter($event)"
    (keydown.space)="addFilter($event, true)"
    [matAutocomplete]="auto"
    [disabled]="disabled"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="addFilter($event.option.value)"
    class="livetail-queries-box recent-queries-box"
  >
    <cdk-virtual-scroll-viewport itemSize="50" style="height: 350px" *ngIf="searchQuery">
      <div class="header">KEY SUGGESTIONS</div>
      <mat-option
        *cdkVirtualFor="let option of allFields$ | async"
        [value]="option"
        class="recent-queries-option autocomplete-item"
      >
        <div class="suggested-text" [innerHTML]="option | searchHighlight: searchQuery"></div>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
</div>
