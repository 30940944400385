<div>
  <div class="overlay" [hidden]="(isLoading$ | async) === false">
    <div
      class="ui active inverted dimmer overlay"
      [class.active]="isLoading$ | async">
      <div class="ui text loader">Loading tags</div>
    </div>
  </div>
</div>

<sh-empty-state-container
  [subTitle]="(tagsList$ | async)?.length ? '' : 'You do not have any tags'"
  [mainText]="'Tags are the best way for you to understand your version status at a glance, integrate with your deployment pipeline and get your latest builds status. Whether it’s new exceptions, higher error ratios, or new broken flows - we got you covered.'"
  [buttonText]="'new tag'"
  [buttonIconUrl]="addTagBtnIconUrl"
  [circleImagePath]="'../../../../../assets/deployment-icons/tag-empty.png'"
  [listConfig]="listConfig"
  (mainButtonPressed)="openNewTagPanel()"
>
</sh-empty-state-container>
