import { Component, OnInit, Input } from '@angular/core';
import { ChartModel, ChartSeriesModel, PlotlineModel } from '@shared/controls/charts/models/chart.model';
import { LoggregationService } from '../../shared/services/loggregation.service';
import * as moment from 'moment';
import { Anomaly } from '@app/insights/shared/models/anomaly.model';
import * as _ from 'lodash';
import { Insight } from '@app/insights/shared/models/insight.model';
import { StatisticService } from '@app/statistics/shared/services/statistics-service';
import { InsightsProvider } from '@app/insights/shared/services/insights.provider';
import { WidgetBaseComponent } from '@shared/components/widget-base-component';
const selectedColor = 'rgba(50, 189, 244, 1)';
const rangeColor = 'rgba(72, 201, 176, 0.8)';
const anomalyColor = 'rgba(212, 35, 187, 0.8)';
const templateColor = 'rgba(181, 181, 181, 0.1)';
@Component({
  selector: 'sh-templates-charts',
  templateUrl: './templates-charts.component.html',
  styleUrls: ['./templates-charts.component.scss'],
})
export class TemplatesChartsComponent extends WidgetBaseComponent implements OnInit {
  @Input() set anomaly(insight: Insight) {
    this._insight = insight;
    if (insight && this._isAnomalyGraphSupported) {
      this.isLoading = true;
      this.model = null;
      this.lastResults = null;
      this.statisticsService.getPatternDetailsByInsight(insight.id).subscribe(
        res => {
          const chartModel = new ChartModel();
          chartModel.enableCopyClipboard = this.enableCopyClipboard;

          chartModel.height = 100;
          chartModel.xPlotlines = new Array<PlotlineModel>();
          chartModel.xType = 'timestamp';
          chartModel.series = new Array<ChartSeriesModel>();
          chartModel.scale = 'm';
          chartModel.backgroundColor = 'transparent';
          chartModel.fontColor = 'white';
          const highlightedAnomaly = (insight as Anomaly).properties.details.find(a => a.isAnomalous);

          const rangeSeries = new ChartSeriesModel();
          rangeSeries.color = rangeColor;
          rangeSeries.data = [];
          rangeSeries.name = 'normal';
          rangeSeries.type = 'areasplinerange';
          rangeSeries.marker = {
            enabled: true,
            radius: 2,
          };
          const anomalySeries = new ChartSeriesModel();
          anomalySeries.color = anomalyColor;
          anomalySeries.data = [];
          anomalySeries.name = 'anomalySeries';
          anomalySeries.type = 'spline';
          anomalySeries.marker = {
            enabled: true,
            radius: 4,
          };
          const templates = res.templates;
          this.lastResults = res.templates;
          for (let i = 0; i < templates.length; i++) {
            const series = new ChartSeriesModel();
            series.data = [];
            series.type = 'spline';

            series.color = highlightedAnomaly && highlightedAnomaly.id === templates[i].template_id ? selectedColor : templateColor;
            series.name = highlightedAnomaly && highlightedAnomaly.id === templates[i].template_id ? 'occurrences' : 'template';

            for (let j = 0; j < templates[i].x_axis.values.length; j++) {
              const date = moment.unix(templates[i].x_axis.values[j] / 1000).toDate();
              const count = templates[i].y_axis.values[1][j];
              const min = templates[i].y_axis.values[0][j] < 0 ? 0 : templates[i].y_axis.values[0][j];
              const max = templates[i].y_axis.values[2][j] < 0 ? 0 : templates[i].y_axis.values[2][j];
              const templateId = templates[i].template_id;

              series.data.push([date, count, templateId]);
              if (highlightedAnomaly && highlightedAnomaly.id === templateId) {
                if (insight.timestamp === Number(templates[i].x_axis.values[j]) && (count > max || count < min)) {
                  anomalySeries.data.push([date, count, templateId]);
                } else {
                  anomalySeries.data.push([date, null, templateId]);
                }
                rangeSeries.data.push([date, min, max, templateId]);
              }
            }
            chartModel.series.push(series);
          }

          if (chartModel.series.length > 0) {
            for (let j = 0; j < chartModel.series[0].data.length; j++) {
              chartModel.categories.push(chartModel.series[0].data[j][0]);
              const categoryTime = moment(chartModel.series[0].data[j][0]).unix() * 1000;
              if (categoryTime === insight.timestamp) {
                chartModel.highlightedXlables.set(j, anomalyColor);
              }
            }
          }

          chartModel.series.push(rangeSeries);
          chartModel.series.push(anomalySeries);
          chartModel.stacking = null;
          chartModel.sharedTooltip = true;
          chartModel.lineWidth = 0;
          // chartModel.infoBarClass = 'chart-infoBar dark ';
          this.model = chartModel;
          this.isLoading = false;
        },
        () => {
          const chartModel = new ChartModel();
          chartModel.title = 'Currently there is no graph for this anomaly';
          chartModel.infoBarClass = 'chart-infoBar dark ';
          chartModel.series = new Array<ChartSeriesModel>();
          chartModel.backgroundColor = 'transparent';
          chartModel.fontColor = 'black';
          chartModel.height = 100;
          this.model = chartModel;
          this.isLoading = false;
        },
      );
    }
  }

  @Input() set selectedTemplate(value: any) {
    if (value && this.model && this.lastResults) {
      this.isLoading = true;
      const chartModel = _.cloneDeep<ChartModel>(this.model);
      const rangeSeries = chartModel.series.find(s => s.name === 'normal');
      const anomalyMarker = chartModel.series.find(s => s.name === 'anomalySeries');
      const rangeSeriesRes = this.lastResults.find(s => s.template_id === value.id);

      if (rangeSeries && rangeSeriesRes && anomalyMarker && this._insight) {
        for (let i = 0; i < rangeSeries.data.length; i++) {
          const min = rangeSeriesRes.y_axis.values[0][i] < 0 ? 0 : rangeSeriesRes.y_axis.values[0][i];
          const max = rangeSeriesRes.y_axis.values[2][i] < 0 ? 0 : rangeSeriesRes.y_axis.values[2][i];
          const count = rangeSeriesRes.y_axis.values[1][i];
          rangeSeries.data[i][1] = min;
          rangeSeries.data[i][2] = max;
          rangeSeries.data[i][3] = value.id;
          if ((count > max || count < min) && this._insight.timestamp === Number(rangeSeriesRes.x_axis.values[i])) {
            anomalyMarker.data[i] = count;
          } else {
            anomalyMarker.data[i] = null;
          }
        }
      }
      chartModel.series.forEach(s => {
        if (s.name !== 'anomalySeries' && s.name !== 'normal') {
          s.color = value.id === s.data[0][2] ? selectedColor : templateColor;
          s.name = value.id === s.data[0][2] ? 'occurrences' : 'template';
        }
      });
      this.model = chartModel;
      this.isLoading = false;
    }
  }

  @Input() set loggregationQuery(value: any) {
    this.isLoading = true;
    this.loggregationService
      .getParameterDataByRequest(value)
      .first()
      .subscribe(res => {
        const modelQuery = this.parseData(res as { data; type }[]);
        modelQuery.enableCopyClipboard = this.enableCopyClipboard;
        this.model = modelQuery;
        this.isLoading = false;
      });
  }
  @Input() public enableCopyClipboard: boolean = false;

  public lastResults: any[] = [];
  public _insight: Insight;
  public _isAnomalyGraphSupported: boolean = true;

  constructor(
    private loggregationService: LoggregationService,
    private statisticsService: StatisticService,
    private insightsProvider: InsightsProvider,
  ) {
    super();
  }

  public getChartType(data: { type }): string {
    if (!data) {
      return 'spline';
    }

    switch (data.type) {
      case 'numericPHRTStats':
        return 'spline';
      case 'numericPHGlobalStats':
        return 'spline';
      case 'categoricalPHRTStats':
        return 'bar';
      case 'templateRTAggStats':
        return 'areaspline';
      case 'categoricalPHGlobalStats':
        return 'bar';
      case 'templateGlobalAggStats':
        return 'areaspline';
    }

    return 'spline';
  }

  public ngOnInit(): void {
    this.insightsProvider.globalState.showTimeline = true;
  }

  private parseData(res: { data; type }[]): ChartModel {
    if (!res) {
      return null;
    }
    const chartmodel = new ChartModel();
    chartmodel.series = new Array<ChartSeriesModel>();
    const arraysCategoriesLength: number = Object.keys(res[0].data.xAxis.values).length;
    const arraysSeriesLength: number = Object.keys(res[0].data.yAxis.values).length;
    const xCategories = [];

    // Parse categories to Datetime
    for (let i = 0; i < arraysCategoriesLength; i++) {
      if (res[0].data.xAxis.type === 'timestamp') {
        xCategories[i] = this.parseDateFromUnix(res[0].data.xAxis.values[i]);
      } else {
        xCategories[i] = res[0].data.xAxis.values[i];
      }
    }

    const currentQuery = new ChartSeriesModel();
    currentQuery.name = 'Current Query';
    currentQuery.data = [];
    currentQuery.color = 'rgba(93, 173, 226, 1)';
    currentQuery.type = this.getChartType(res[0]);
    currentQuery.innerSize = null;
    // Parse series - series structure is Array[datetime,value]
    if (res[0].data.xAxis.type === 'timestamp') {
      for (let j = 0; j < arraysSeriesLength; j++) {
        let value = [xCategories[j], res[0].data.yAxis.values[j].count];

        if (res[0].data.yAxis.values[j].avg >= 0) {
          value = [xCategories[j], res[0].data.yAxis.values[j].avg];
        }

        currentQuery.data[j] = value;
      }
    } else {
      for (let j = 0; j < arraysSeriesLength; j++) {
        const value = [xCategories[j], res[0].data.yAxis.values[j]];
        currentQuery.data[j] = value;
      }
    }

    chartmodel.series.push(currentQuery);
    if (res[1].data.yAxis.values.length > 0) {
      const normalBehavior = new ChartSeriesModel();
      normalBehavior.name = 'Normal Behavior';
      normalBehavior.data = [];
      normalBehavior.color = 'rgba(72,201,176,0.5)';
      normalBehavior.type = this.getChartType(res[0]);
      normalBehavior.innerSize = null;
      if (res[1].data.xAxis.type === 'timestamp') {
        for (let j = 0; j < arraysSeriesLength; j++) {
          let value = [xCategories[j], res[1].data?.yAxis?.values?.[j]?.count];

          if (res[0].data.yAxis.values[j].avg >= 0) {
            value = [xCategories[j], res[1].data.yAxis.values[j].avg];
          }

          normalBehavior.data[j] = value;
        }
      } else {
        for (let j = 0; j < arraysSeriesLength; j++) {
          const value = [xCategories[j], res[1].data?.yAxis?.values?.[j]?.count];
          normalBehavior.data[j] = value;
        }
      }
      chartmodel.series.push(normalBehavior);
    }

    chartmodel.scale = 'm';
    chartmodel.categories = xCategories;
    chartmodel.backgroundColor = 'transparent';
    chartmodel.fontColor = 'white';
    chartmodel.yAxisEnable = true;
    chartmodel.showLegend = false;
    chartmodel.tooltipValueSuffix = res[0].type.includes('numeric') ? ' Avg.' : '';
    chartmodel.xType = res[0].data.xAxis.type === 'timestamp' ? res[0].data.xAxis.type : 'category';
    chartmodel.inverted = res[0].data.xAxis.type === 'timestamp' ? false : true;
    chartmodel.stacking = null;

    chartmodel.infoBarClass = 'chart-infoBar dark ';

    return chartmodel;
  }

  private parseDateFromUnix(unixDate: number): Date {
    return moment.unix(unixDate / 1000).toDate();
  }
}
