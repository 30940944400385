export enum LoginModesImages {
  team = 'illustration-team-green.svg',
  coralogixFlow = 'illustration-login-green.png',
  resetPassword = 'illustration-forgot-password-green.svg',
  pendingEmail = 'illustration-waiting-approval-green.svg'
}

enum LoginModesImagesClasses {
  team = 'team-image',
  coralogixFlow = 'credentials-image',
  resetPassword = 'reset-password',
  pendingEmail = 'illustrations-pending'
}

export enum LoginModes {
  team = 'team',
  signIn = 'signin',
  signInWithTeam = 'teamSignin',
  teams = 'teams',
  createTeam = 'createTeam',
  forgot = 'forgot',
  resetPassword = 'reset',
  status = 'status',
  newTeamMember = 'newTeamMember',
}

export enum LoginRoutes {
  team = '/login',
  signIn = '/login/user',
  signInWithTeam = '/login/user',
  teams = '/login/teams',
  createTeam = '/login/createTeam',
  forgot = '/login/forgot',
  resetPassword = '/login/reset',
  status = '/login/status',
  newTeamMember = 'sso/reset',
}

export interface IAuthState {
  title: string;
  url: string;
  sideImageName: LoginModesImages;
  sideImageClass: LoginModesImagesClasses;
  hideSignUpInHeaderModes: boolean;
  titleClass?: string;
}

export const modesViewStates: { [key: string]: IAuthState } = {
  [LoginModes.team] : {
    title: 'Sign in to your team',
    url: LoginRoutes.team,
    sideImageName: LoginModesImages.team,
    sideImageClass: LoginModesImagesClasses.team,
    hideSignUpInHeaderModes: false
  },
  [LoginModes.signIn] : {
    title: 'Sign in',
    url: LoginRoutes.signIn,
    sideImageName: LoginModesImages.coralogixFlow,
    sideImageClass: LoginModesImagesClasses.coralogixFlow,
    hideSignUpInHeaderModes: false,
    titleClass: 'with-credentials-title'
  },
  [LoginModes.signInWithTeam] : {
    title: '',
    url: LoginRoutes.signInWithTeam,
    sideImageName: LoginModesImages.team,
    sideImageClass: LoginModesImagesClasses.team,
    hideSignUpInHeaderModes: false
  },
  [LoginModes.teams]: {
    title: 'Choose Team',
    url: LoginRoutes.teams,
    sideImageName: LoginModesImages.team,
    sideImageClass: LoginModesImagesClasses.team,
    hideSignUpInHeaderModes: true
  },
  [LoginModes.createTeam] : {
    title: 'Create Team',
    url: LoginRoutes.createTeam,
    sideImageName: LoginModesImages.team,
    sideImageClass: LoginModesImagesClasses.team,
    hideSignUpInHeaderModes: true
  },
  [LoginModes.forgot] : {
    title: 'Forgot your password?',
    url: LoginRoutes.forgot,
    sideImageName: LoginModesImages.resetPassword,
    sideImageClass: LoginModesImagesClasses.resetPassword,
    hideSignUpInHeaderModes: false
  },
  [LoginModes.resetPassword] : {
    title: 'Reset Password',
    url: LoginRoutes.resetPassword,
    sideImageName: LoginModesImages.resetPassword,
    sideImageClass: LoginModesImagesClasses.resetPassword,
    hideSignUpInHeaderModes: false
  },
[LoginModes.status]: {
  title: '',
  url: LoginRoutes.status,
  sideImageName: LoginModesImages.pendingEmail,
  sideImageClass: LoginModesImagesClasses.pendingEmail,
  hideSignUpInHeaderModes: false
},
  [LoginModes.newTeamMember]: {
    title: '',
    url: LoginRoutes.newTeamMember,
    sideImageName: LoginModesImages.team,
    sideImageClass: LoginModesImagesClasses.team,
    hideSignUpInHeaderModes: false,
  },
};
