import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import {ReindexS3PermissionsEditorControls} from "@app/roi/reindex/models/reindex-editor-controls";

@Injectable()
export class ReindexS3BucketForm {

  public get defaultValues(): ReindexS3PermissionsEditorControls {
    return new ReindexS3PermissionsEditorControls( {
      bucket: '',
      region: '',
    });
  }
  constructor(private fb: FormBuilder) {}

  public buildForm(value: ReindexS3PermissionsEditorControls): FormGroup {
    return this.fb.group({
      bucket: [value.bucket, Validators.required],
      region: [value.region, Validators.required],
    });
  }
}
