import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { SecurityOnionService } from '../../../shared/services/security-onion.service';
import { Observable } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { SetMappingSecurityOnionCompletedAction, SetMappingSecurityOnionFailedAction, ActionTypes } from './security-onion.actions';
import { of } from 'rxjs/observable/of';
import { State } from '../../../../app.reducers';

@Injectable()
export class SecurityOnionEffects {
  @Effect()
  public set$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.SET_SECURITY_ONION_MAPPINGS),
    switchMap((action: any) => {
      return this.securityOnionService
        .setMappingSecurityOnion(action.payload)
        .map((securityOnions) => new SetMappingSecurityOnionCompletedAction(securityOnions))
        .catch((err) => of(new SetMappingSecurityOnionFailedAction(err)));
    }),
  );
  constructor(
    private actions$: Actions,
    private securityOnionService: SecurityOnionService,
    private store: Store<State>,
  ) {}
}
