import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { InsightsProvider } from '../../shared/services/insights.provider';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { InsightsHelper } from '../../shared/helpers/insightsHelper';
import { formatsHelper } from '@shared/services/formatsHelper';
import { Anomaly } from '../../shared/models/anomaly.model';
import { Insight } from '../../shared/models/insight.model';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { LogQueryData } from '@app/logs/shared/models/logquery.data';
import { AnomalySubTypeId } from '../../shared/models/enums';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { LogActions } from '@app/logs/shared/state/log.actions';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { TimeZoneBaseClass } from '@shared/base-classes/time-zone-base';

@Component({
  selector: 'sh-anomaly-loggregation',
  templateUrl: './anomaly-loggregation.component.html',
  styleUrls: ['./anomaly-loggregation.component.scss'],
})
export class AnomalyLoggregationComponent extends TimeZoneBaseClass implements OnInit, OnDestroy {
  public queryModel: LogQueryModel;
  public anomolusQueryModel: LogQueryModel;
  public topErrorQueryModel: LogQueryModel;
  public newErrorQueryModel: LogQueryModel;
  public queryData: LogQueryData;
  public newLogsCount: number;
  public anomolusCount: number;
  public topErrorCount: number;
  public anomalyViewReady: boolean = false;
  public topErrorsViewReady: boolean = false;
  public theme: string = 'light';
  public onNewQueryRequestSubscription: Subscription;

  public anomalyDetailItem: any;
  public math: any;
  public anamolyTemplateCount: number;
  public totalTemplateCount: number;
  public timeStamp: string;
  public isGridOverlayVisible: boolean = true;
  private currentInsightsChangedSubscirption: Subscription;
  private noDebounceCurrentInsightsChangedSubscirption: Subscription;

  constructor(
    public insightsProvider: InsightsProvider,
    private logsService: LogsService,
    private htmlRowMeasure: HtmlRowMeasure,
    private store: Store<State>,
  ) {
    super(store);
    this.math = Math;
  }

  public ngOnInit(): void {
    // TODO NG4 do Check remove
    setTimeout(() => {
      this.insightsProvider.globalState.showFind = false;
    }, 0);

    this.init();

    this.noDebounceCurrentInsightsChangedSubscirption = this.insightsProvider.currentInsightChanged
      .map(test => {
        this.timeStamp = '';
        this.totalTemplateCount = 0;
        this.anamolyTemplateCount = 0;
        this.newLogsCount = undefined;
        this.isGridOverlayVisible = true;
        return test;
      })
      .subscribe(insight => {
        this.initCaption(insight);
      });

    this.currentInsightsChangedSubscirption = this.insightsProvider.currentInsightChanged
      .map(test => {
        this.queryModel = null;
        this.topErrorQueryModel = null;
        this.anomolusQueryModel = null;
        return test;
      })
      .debounceTime(3000)
      .subscribe(insight => {
        if (insight) {
          this.init();
        }
      });
    this.onNewQueryRequestSubscription = this.logsService.newQueryRequest.subscribe(queryModel => {
      this.logsService
        .getLogs(queryModel)
        .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
        .subscribe(res => (this.queryData = new LogQueryData(queryModel, res, this.logsService)));
    });
  }

  public ngOnDestroy(): void {
    this.insightsProvider.globalState.queryResultCount = null;
    this.insightsProvider.globalState.selectedLog = null;
    if (this.currentInsightsChangedSubscirption) {
      this.currentInsightsChangedSubscirption.unsubscribe();
    }
    if (this.onNewQueryRequestSubscription) {
      this.onNewQueryRequestSubscription.unsubscribe();
    }

    if (this.noDebounceCurrentInsightsChangedSubscirption) {
      this.noDebounceCurrentInsightsChangedSubscirption.unsubscribe();
    }
  }

  public onSelectedItemChanged(e: any): any {
    this.insightsProvider.globalState.selectedLog = e;
  }

  public onItemsCountChanged(e: string): void {
    this.insightsProvider.globalState.queryResultCount = e ? formatsHelper.numberWithCommas(e) + ' Templates' : null;
  }

  public onGridButtonClick(event: string): void {
    this.insightsProvider.textInfoClicked.emit(event);
  }

  public getInsightProperties(insight: Insight): any {
    return insight;
  }

  public getNewErrors(insight: Insight): void {
    if (!insight) {
      return;
    }
    this.newLogsCount = 0;
    this.newErrorQueryModel = InsightsHelper.getLogQueryTopErrorModelByInsight(insight);
    this.newErrorQueryModel.type = QueryTypes.NEWLOGS;
    if (this.isVolumeAnomaly(insight)) {
      this.newErrorQueryModel.queryParams.metadata.severity = [5, 6];
    } else {
      this.newErrorQueryModel.queryParams.metadata.severity = [];
    }

    this.logsService
      .getLogs(this.newErrorQueryModel)
      .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
      .first()
      .subscribe(res => {
        this.queryData = new LogQueryData(this.newErrorQueryModel, res, this.logsService);
        if (res) {
          this.newLogsCount = res.total;
          this.store.dispatch(new LogActions.SetLogPanelQuery(this.newErrorQueryModel));
        }
      });
  }

  public onGridRowSelected(e: any): void {
    this.insightsProvider.globalState.selectedLog = e;
  }

  public init(): void {
    this.anomalyViewReady = false;
    this.queryModel = InsightsHelper.getLogQueryModelByInsight(this.insightsProvider.currentInsight);
    this.topErrorQueryModel = InsightsHelper.getLogQueryTopErrorModelByInsight(this.insightsProvider.currentInsight);
    this.anomolusQueryModel = InsightsHelper.getLogQueryTopErrorModelByInsight(this.insightsProvider.currentInsight);
    this.getNewErrors(this.insightsProvider.currentInsight);
    this.initCaption(this.insightsProvider.currentInsight);
  }

  public initCaption(insight: Insight): void {
    const anomaly: Anomaly = insight as Anomaly;
    if (anomaly) {
      this.timeStamp =
        moment(insight.timestamp)
          .utc(this.isLocalDate)
          .format('LT') +
        ' - ' +
        moment(insight.timestamp)
          .utc(this.isLocalDate)
          .add(1, 'm')
          .format('LT');
      if (anomaly.properties && anomaly.properties.details) {
        this.anamolyTemplateCount = anomaly.properties.details.filter(x => x.isAnomalous).length;
        this.totalTemplateCount = anomaly.properties.details.length;
      }
    }
  }

  public isFlowAnomaly(insight: Insight): boolean {
    return insight.subTypeId === AnomalySubTypeId.FlowAnomaly;
  }

  public isVolumeAnomaly(insight: Insight): boolean {
    return insight.subTypeId === AnomalySubTypeId.VolumeAnomaly;
  }

  public OnIsAnomalyTemplatesReady(isAnomalyReady: boolean): void {
    this.anomalyViewReady = isAnomalyReady;
  }

  public OnIsTopErrorsTemplatesReady(e: boolean): void {
    this.topErrorsViewReady = e;
  }

  private getAnomalyTemplateIds(anomaly: Anomaly): string[] {
    if (anomaly) {
      const templateIds = [];
      anomaly.properties.details.forEach(detail => templateIds.push(detail.id));
      return templateIds;
    } else {
      return null;
    }
  }
}
