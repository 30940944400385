import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'sh-archiving-providers-s3-tutorial',
  templateUrl: './archiving-providers-s3-tutorial.component.html',
  styleUrls: ['./archiving-providers-s3-tutorial.component.scss'],
})
export class ArchivingProvidersS3TutorialComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
