import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import HC_more from 'highcharts/highcharts-more';
import {ChartModel, ChartSeriesModel, PlotlineModel} from '@shared/controls/charts/models/chart.model';
import {ILegendData} from '@app/statistics/components/chart-legend/chart-legend.component';
import {
  graphPointValue,
  IInitialConfig,
  ILineChartConfig,
  ILineGraph,
  maxValue,
  minValue,
  RangeSeriesColors,
  seriesColors,
  timestampVal,
} from '@app/statistics/shared/models/line-chart.models';
import _ from 'lodash';

HC_more(Highcharts);
export type tooltipIndex = number;

@Component({
  selector: 'sh-summary-line-chart',
  templateUrl: './summary-line-chart.component.html',
  styleUrls: ['./summary-line-chart.component.scss'],
})
export class SummaryLineChartComponent implements OnDestroy {
  @Input() public legend: ILegendData[];
  public chart: Chart;
  public model: ChartModel;
  @Output() public handleTooltipPress: EventEmitter<tooltipIndex> = new EventEmitter<tooltipIndex>();

  @Input() set chartData(data: ILineChartConfig) {
    if (data?.values?.seriesValues?.length && !this.model) {
      this.model = this.createChart(data.values, data.config);
      this.elRef?.nativeElement?.addEventListener('click', event => {
        if (_.isFunction(event.target?.className?.includes) && event.target?.className?.includes('line-tooltip__action-btn')) {
          const dataIndex = Number(event.target?.getAttribute('datafld'));
          if (!Number.isNaN(dataIndex)) {
            this.handleTooltipPress.emit(dataIndex);
          }
        }
      });
    }
  }

  constructor(private elRef: ElementRef) {
  }

  public ngOnDestroy(): void {
    this.elRef.nativeElement.removeAllListeners();
  }

  public createChart(values: ILineGraph, config: IInitialConfig): ChartModel {
    const {xTitle, yTitle, scale, chartTooltipDataTitle, height, tooltipButtonText, xCategories, yCategories, tooltipStyleType} = config;
    const chartModel = new ChartModel({
      height,
      xPlotlines: new Array<PlotlineModel>(),
      series: [],
      scale,
      backgroundColor: 'transparent',
      fontColor: 'var(--cgx-text-secondary)',
      stacking: null,
      sharedTooltip: false,
      tooltipEnabled: true,
      lineWidth: 0,
      yAxisEnable: true,
      xTitle,
      yTitle,
      tickIntervalY: null,
      gridLineWidthX: 1,
      gridLineWidthY: 1,
      xType: '',
      xCategories,
      yCategories,
      chartTooltipDataTitle,
      tooltipButtonText,
      type: 'columnrange',
      tooltipStyleType,
    });

    if (values.rangeValues?.length) {
      chartModel.series.push(...this.getSeriesRangesArray(values.rangeValues));
    }

    chartModel.series.push(...this.getSeriesArray(values.seriesValues, config.seriesNames));
    return chartModel;
  }

  private getSeriesArray(seriesValues: [timestampVal, graphPointValue][][], names: string[] = []): ChartSeriesModel[] {
    const seriesArr = [];
    seriesValues.forEach((series, index) => {
      const currentSeries = new ChartSeriesModel();
      currentSeries.color = seriesColors[index];
      currentSeries.data = series.map(([timestamp, val]) => [moment.unix(timestamp / 1000).toDate(), val]);
      if (Array.isArray(names) && names[index]) {
        currentSeries.name = names[index];
      } else {
        currentSeries.name = `Series ${index.toString()}`;
      }

      currentSeries.type = 'spline';

      seriesArr.push(currentSeries);
    });
    return seriesArr;
  }

  private getSeriesRangesArray(seriesValues: [timestampVal, minValue, maxValue][][]): ChartSeriesModel[] {
    const seriesArr = [];
    seriesValues.forEach((series, index) => {
      const currentSeries = new ChartSeriesModel();
      currentSeries.color = RangeSeriesColors[index];
      currentSeries.name = 'expected';
      currentSeries.type = 'areasplinerange';
      currentSeries.data = series.map(([timestamp, max, min]) => [moment.unix(timestamp / 1000).toDate(), max, min]);
      seriesArr.push(currentSeries);
    });
    return seriesArr;
  }
}
