import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sh-small-widget',
  templateUrl: './small-widget.component.html',
  styleUrls: ['small-widget.component.scss'],
})
export class SmallWidgetComponent implements OnInit {
  @Input() public header: string;
  @Input() public value: string;

  constructor() {}

  public ngOnInit() {}
}
