import { Role } from '../enums/role.enum';
import { ITeammate } from '../interfaces/teammate.interface';
import { ITeammateSerialized } from '../serialized/teammate.serialized';

export class Teammate implements ITeammate {
    public id: string;
    public username: string;
    public userAccountId: number;
    public isActive: boolean;
    public role: Role;
    public createdAt: string;
    public firstName: string;
    public lastName: string;

    constructor(obj: ITeammate) {
        this.id = obj.id;
        this.username = obj.username;
        this.userAccountId = obj.userAccountId;
        this.isActive = obj.isActive;
        this.role = obj.role;
        this.createdAt = obj.createdAt;
        this.firstName = obj.firstName;
        this.lastName = obj.lastName;
    }

    public static deserializer(obj: ITeammateSerialized): Teammate {
        let teammate: Teammate = null;
        if (obj) {
            teammate = new Teammate({
                id: obj.id,
                username: obj.username,
                userAccountId: obj.userAccountId,
                isActive: obj.isActive,
                role: obj.role,
                createdAt: obj.created_at,
                firstName: obj.firstName,
                lastName: obj.lastName
            });
        }
        return teammate;
    }
}
