import { EFontFamilyOptions } from '@shared/controls/basic-input/basic-input.component';
import { IRuleFieldOptionValue } from '@app/rules/shared/models/parsing-theme-editor.models';
import { FormGroup } from '@angular/forms';

export interface IDynamicRuleCol {
  label: string;
  controlName: string;
  isOptional: boolean;
  tooltip: string;
  type: DynamicRuleControlType;
  placeholder?: string;
  fontFamily?: EFontFamilyOptions;
  valueChangeSubscription?: (form: FormGroup, value: string) => void;
}

export interface IDynamicRadioButtonCol extends IDynamicRuleCol {
  options: IRadioButton[];
}

export interface IDynamicTextareaCol extends IDynamicRuleCol {
  inputClass: string;
  numberOfInputRows: number;
}

export interface IDynamicSelectCol extends IDynamicRuleCol {
  options?: IRuleFieldOptionValue[];
}

export interface IDynamicMultiSelectCol extends IDynamicRuleCol {
  options?: IRuleFieldOptionValue[];
  removePrefix?: string;
}

export interface IRadioButton {
  label: string;
  isDefault: boolean;
  value: string;
}

export interface IDynamicRuleLayout {
  description: string;
  learnMoreLink: string;
  rowConfig: (IDynamicRuleCol | IDynamicRadioButtonCol | IDynamicTextareaCol | IDynamicSelectCol)[][];
}

export enum DynamicRuleControlType {
  text,
  select,
  textarea,
  checkbox,
  radioButtons,
  multiSelect
}
