import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { Action } from '@ngrx/store';
import * as rolesActions from '@app/settings/account/components/roles/store/roles.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RolesService } from '@app/settings/account/components/roles/services/roles.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

import { CompanyState } from '@app/ngxs-store/company/company.state';
import { CompanyRequest } from '@app/auth/shared/models/activateUser.model';

@Injectable()
export class RolesEffects {
  @SelectSnapshot(CompanyState.companyInfo) public companyInfo: CompanyRequest;

  @Effect()
  public getAllRoles$: Observable<Action> = this.actions$.pipe(
    ofType(rolesActions.getRolesAction),
    switchMap(() => {
      if (this.companyInfo) {
        return this.rolesService.getAllRoles()
          .pipe(
            map((roles) => rolesActions.getRolesCompleteAction({ payload: roles })),
            catchError(() => of(rolesActions.getRolesFailedAction()))
          );
      }
      return of(rolesActions.getRolesCompleteAction({ payload: [] }));
    }),
  );

  constructor(
    private actions$: Actions,
    private rolesService: RolesService,
  ) {
  }
}
