<div class="example-item" fxLayout="row">
  <span class="rule-icon">{{ruleNumber}}</span>
  <div fxFlex="100">
    <h3 class="example-item__title">
      <span class="bold-title">{{sample.boldTitle}}:</span>
      {{sample.title}}
    </h3>
    <p class="example-item__description">{{sample.description}}</p>

    <div class="example-item__sample" *ngFor="let codeSample of sample.codeSamples">
      <div class="example-item__sample-title" *ngIf="codeSample.title">{{codeSample.title}}</div>
      <ng-container [ngSwitch]="codeSample.type">
        <pre *ngSwitchCase="ruleSampleType.text"
                 class="example-item__sample-code example-item__sample-log"
            >{{codeSample.data}}</pre>
        <pre *ngSwitchCase="ruleSampleType.regex"
             class="example-item__sample-code example-item__sample-log regex-marking"
        >{{codeSample.data}}</pre>
        <pre *ngSwitchCase="ruleSampleType.json"
             class="example-item__sample-code json-marking"
             [innerHTML]="codeSample.data | prettyjson"></pre>
        <div *ngSwitchCase="ruleSampleType.controls" fxLayout="row" fxLayoutGap="10px" fxFlex="100">
          <div fxLayout="column" fxFlex *ngFor="let control of codeSample.controlsData">
            <div class="example-item__sample-title">{{control.title}}</div>
            <ng-container [ngSwitch]="control.controlType">
              <div *ngSwitchCase="ruleControlType.dropdown"
                   class="example-item__control-example">
                <span>{{control.controlText}}</span>
                <i class="dropdown icon"></i>
              </div>
              <div *ngSwitchCase="ruleControlType.text"
                   class="example-item__control-example">
                <span>{{control.controlText}}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

  </div>
</div>
