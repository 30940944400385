<div class="rule-list-container" fxLayout="column" cdkScrollable>
  <div
    [formGroup]="searchForm"
    fxLayoutGap="10px"
    class="rule-list-container__controls-container"
    fxLayoutAlign="center center"
    fxLayout="row"
  >
    <div class="coralogix input-group relative" fxFlex="38">
      <label class="filters-label">
        <span>Search</span>
      </label>
      <input type="text" placeholder="" class="form-control rules-search-input" [formControl]="querySearchControl" />
      <mat-icon aria-hidden="false" class="search-icon" aria-label="Example home icon" matSuffix>
        <i class="fa fa-search" aria-hidden="true"></i>
      </mat-icon>
    </div>
    <div fxFlex="20" class="coralogix input-group">
      <sh-coralogix-multiple-selection-menu
        #multipleSelectionApps
        class="filter-select applications-subsystems-select"
        [formControlName]="'applications'"
        label="{{ metadataField1 || 'applications' }}"
        [options]="applicationOptions$ | async"
      ></sh-coralogix-multiple-selection-menu>
    </div>
    <div fxFlex="20" class="coralogix input-group">
      <sh-coralogix-multiple-selection-menu
        #multipleSelectionSubsystems
        class="filter-select applications-subsystems-select"
        [formControlName]="'subsystems'"
        label="{{ metadataField2 || 'subsystems' }}"
        [options]="subsystemOptions$ | async"
      ></sh-coralogix-multiple-selection-menu>
    </div>
    <div fxFlex="15" class="coralogix input-group">
      <sh-coralogix-select
        [selectOptions]="ruleTypeOptions"
        [multiple]="false"
        [searchable]="false"
        [bindLabel]="'name'"
        [bindValue]="'value'"
        [placeholder]="'All Types'"
        [disableSelectAll]="true"
        formControlName="ruleTypes"
      >
        <label select-label>
          <span class="text">Rule Types</span>
        </label>
      </sh-coralogix-select>
    </div>
    <div fxFlex="7">
      <cgx-button label="Clear" size="md" [stroked]="true" (press)="clearFilters()"></cgx-button>
    </div>
  </div>
  <div fxLayout="row">
    <div fxLayoutGap="111px" class="order-list" fxLayout="column" fxLayoutAlign="center center">
      <div
        class="order-list__number"
        [ngClass]="{
          'is-first': ruleGroup.isFirst,
          'is-last': ruleGroup.isLast,
          'dashed-before': ruleGroup.hasGapBefore,
          'dashed-after': ruleGroup.hasGapAfter,
          'two-digit-number': ruleGroup.order > 9 && ruleGroup.order < 99,
          'three-digit-number': ruleGroup.order > 99
        }"
        *ngFor="let ruleGroup of orderList?.filteredList; let i = index"
      >
        {{ ruleGroup.order }}
      </div>
    </div>
    <sh-parsing-themes-draggable-table
      fxFlex="auto"
      [dataSource]="filteredParsingThemes$ | async"
      (groupEnableChanged)="changeGroupEnabled($event)"
      (ruleGroupSelected)="groupSelected($event)"
      (orderGroupsChange)="onOrderGroupsChange($event)"
      [disableDrag]="isParsingThemeListFiltered"
      (dragDisableDragAction)="showDragDisabledMessage()"
    ></sh-parsing-themes-draggable-table>
  </div>
</div>
