import { Condition } from '@app/tco/enums/condition.enum';
import { PolicyStatus } from '@app/tco/enums/policy-status.enum';
import { Priority } from '@app/tco/enums/priority.enum';
import { Severity } from '@app/tco/enums/severity.enum';
import { CompareType } from '@shared/components/conditional-select/conditional-select.model';
import { Role } from '../enums/role.enum';
import { PluginTypes } from '@shared/enums/plugin-types.enum';

export interface IEnumsTranslator {
  Severity: {
    [Severity.DEBUG]: string;
    [Severity.VERBOSE]: string;
    [Severity.INFO]: string;
    [Severity.WARNING]: string;
    [Severity.ERROR]: string;
    [Severity.CRITICAL]: string;
  };
  Condition: {
    [Condition.Includes]: string;
    [Condition.Is]: string;
    [Condition.IsNot]: string;
    [Condition.StartsWith]: string;
  };
  Priority: {
    [Priority.Block]: string;
    [Priority.High]: string;
    [Priority.Low]: string;
    [Priority.Medium]: string;
  };
  PolicyStatus: {
    [PolicyStatus.NoPolicy]: string;
    [PolicyStatus.Policy]: string;
  };
  CompareType: {
    [CompareType.contains]: string;
    [CompareType.startsWith]: string;
    [CompareType.equals]: string;
    [CompareType.endsWith]: string;
  };
  Role: {
    [Role.Admin]: string;
    [Role.User]: string;
    [Role.ReadOnly]: string;
    [Role.DataAnalyst]: string;
    [Role.InterfaceUser]: string;
  };
  PluginTypes: {
    [PluginTypes.Private]: string;
    [PluginTypes.Shared]: string;
  };
}

export const enumsTranslator: IEnumsTranslator = {
  Severity: {
    [Severity.DEBUG]: 'debug',
    [Severity.VERBOSE]: 'verbose',
    [Severity.INFO]: 'info',
    [Severity.WARNING]: 'warning',
    [Severity.ERROR]: 'error',
    [Severity.CRITICAL]: 'critical',
  },
  Condition: {
    [Condition.Is]: 'is',
    [Condition.IsNot]: 'is not',
    [Condition.StartsWith]: 'starts with',
    [Condition.Includes]: 'includes',
  },
  Priority: {
    [Priority.Block]: 'block',
    [Priority.Low]: 'low',
    [Priority.Medium]: 'medium',
    [Priority.High]: 'high',
  },
  PolicyStatus: {
    [PolicyStatus.Policy]: 'policy',
    [PolicyStatus.NoPolicy]: 'no policy',
  },
  CompareType: {
    [CompareType.equals]: 'Is',
    [CompareType.contains]: 'Includes',
    [CompareType.startsWith]: 'Starts With',
    [CompareType.endsWith]: 'Ends With',
  },
  Role: {
    [Role.Admin]: 'admin',
    [Role.User]: 'user',
    [Role.ReadOnly]: 'read only',
    [Role.DataAnalyst]: 'data analyst',
    [Role.InterfaceUser]: 'interface user',
  },
  PluginTypes: {
    [PluginTypes.Private]: 'private',
    [PluginTypes.Shared]: 'shared',
  },
};
