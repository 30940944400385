import { IQueryParams, QueryParams } from './query-params.model';
import { QueryTypes } from './query-types.options';
import { ISortModel, SortModel } from './sort.model';
import { Constants } from '@app/constants';
import { ILogsGraph } from './graph/logs-graph.model';
import { LogsGraphHistogram } from './graph/histogram-graph.model';
import { ISelectedSurroundLog } from '@app/logs-new/shared/models/surrounding-logs.model';
import { selectedSurroundLogInitialData } from '@app/logs-new/shared/constants/logs-screen.constants';

export interface ILogQueries {
  metadataQuery: ILogQuery;
  filtersQuery: ILogQuery;
}

export type AggIntervalMilliSecondNum = number;

export interface ILogQuery {
  name: string;
  type: string;
  startDate: number;
  endDate: number;
  pageSize: number;
  pageIndex: number;
  queryParams: IQueryParams;
  graphs: ILogsGraph[];
  isNew: boolean;
  cacheQueryId: string;
  eventId: string;
  tagId: number;
  selectedViewId: number;
  sortModel: ISortModel[];
  selectedSurroundLog: ISelectedSurroundLog;
}

const _15MinutesInMS = 900000;

export class LogQueryModel implements ILogQuery {
  name: string;
  type: string = QueryTypes.FREE;
  startDate: number;
  endDate: number;
  pageSize: number = Constants.LOGS_QUERY_PAGE_SIZE;
  pageIndex: number;
  queryParams: QueryParams = new QueryParams();
  isNew: boolean;
  cacheQueryId: string;
  eventId: string;
  tagId: number;
  selectedViewId: number;
  sortModel: ISortModel[] = [];
  graphs: ILogsGraph[] = [new LogsGraphHistogram()];
  selectedSurroundLog: ISelectedSurroundLog;

  constructor(
    fromDate?: Date,
    toDate?: Date,
    tagId?: number,
    viewId?: number,
    pageIndex?: number,
    selectedSurroundedLog?: ISelectedSurroundLog,
  ) {
    this.endDate = toDate?.getTime() || new Date().getTime();
    this.startDate = fromDate?.getTime() || new Date().getTime() - _15MinutesInMS;
    this.sortModel.push(new SortModel());
    this.tagId = tagId || -1;
    this.selectedViewId = viewId || -1;
    this.pageIndex = pageIndex || 0;
    this.selectedSurroundLog = selectedSurroundedLog || selectedSurroundLogInitialData;
  }
}
