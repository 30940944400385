import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonFormatterComponent } from './json-formatter.component';

@NgModule({
  declarations: [JsonFormatterComponent],
  imports: [CommonModule],
  exports: [JsonFormatterComponent],
})
export class JsonFormatterModule {}
