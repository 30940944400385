import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPluginSerialized } from '@app/logs-new/components/plugins-container/model/plugin.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { Plugin } from '@app/logs-new/components/plugins-container/model/plugin.model.ts';
import { PluginTypes } from '@shared/enums/plugin-types.enum';

export interface IUpdatePluginsSettingsBody {
  privateSettings: { [id: string]: { order: number } };
  sharedSettings: { [id: string]: { order: number } };
}

@Injectable({
  providedIn: 'root',
})
export class LogMenuPluginsService {
  constructor(private http: HttpClient, private cs: ConfigurationService) {}

  public getPlugins(): Observable<{ privatePluginList: Plugin[]; sharedPluginList: Plugin[] }> {
    const url = this.cs.getConfigURL('/api/v1/log-menu-plugins');
    return this.http
      .get<{
        privatePlugins: IPluginSerialized[];
        sharedPlugins: IPluginSerialized[];
      }>(url)
      .pipe(
        map(({ privatePlugins, sharedPlugins }) => {
          return {
            privatePluginList: privatePlugins.map(pluginSerializedItem => Plugin.deserializer(pluginSerializedItem)),
            sharedPluginList: sharedPlugins.map(pluginSerializedItem => Plugin.deserializer(pluginSerializedItem)),
          };
        }),
      );
  }

  public getFields(): Observable<{ metadataFields: string[]; fields: string[] }> {
    const url = this.cs.getConfigURL('/api/v1/company/seperatedtemplateIndexFields');
    return this.http.get<{ metadataFields: string[]; fields: string[] }>(url);
  }

  public createPlugins(body: IPluginSerialized): Observable<Plugin> {
    const url = this.cs.getConfigURL('/api/v1/log-menu-plugins');
    return this.http.post<IPluginSerialized>(url, body).pipe(map(pluginSerialized => Plugin.deserializer(pluginSerialized)));
  }

  public updatePlugins(body: IPluginSerialized): Observable<Plugin> {
    const url = this.cs.getConfigURL('/api/v1/log-menu-plugins');
    return this.http.put<IPluginSerialized>(url, body).pipe(map(pluginSerialized => Plugin.deserializer(pluginSerialized)));
  }

  public deletePlugins(id: string): Observable<string> {
    const url = this.cs.getConfigURL(`/api/v1/log-menu-plugins/${id}`);
    return this.http.delete(url, { responseType: 'text' });
  }

  public updatePluginsSettings(body: IUpdatePluginsSettingsBody): Observable<string[]> {
    const url = this.cs.getConfigURL(`/api/v1/log-menu-plugins/order`);
    return this.http.put<string[]>(url, body);
  }
}
