import { Injectable } from '@angular/core';
import { FieldVisualizationDialogComponent } from '@app/logs-new/shared/features/field-visualization/field-visualization-dialog/field-visualization-dialog.component';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { State } from '@app/app.reducers';
import {
  FieldVisualizationEvent,
  IFieldVisualizationDialogData,
  IFieldVisualizationRes,
} from '@app/logs-new/shared/models/field-visualization.model';
import { addSelectedQueryField, addToSearchQueryAction } from '@app/logs-new/store/logs-queries/logs-queries.actions';
import { ISearchQueryEvent } from '@app/logs-new/shared/models/search.model';
import { IAddSelectedQueryFieldEvent } from '@app/logs-new/shared/models/logs-filter.model';
import { selectLogQuery } from '@app/logs-new/store/logs-queries/logs-queries.selectors';
import { selectActiveTab } from '@app/logs-new/store/logs-data/logs-data.selectors';
import { filterFalsy } from '@app/logs-new/shared/operators/filter-falsy.operator';
import { severityMap } from '@app/logs-new/shared/constants/severity-map.constant';
import { Constants } from '@app/constants';

@Injectable({ providedIn: 'root' })
export class LogsDialogService {
  constructor(private dialog: MatDialog, private store: Store<State>) {}

  openFieldVisualizationDialog(dialogData: Partial<IFieldVisualizationDialogData>): void {
    const data = {
      ...dialogData,
      logsQuery$: this.store.select(selectLogQuery),
      activeTab$: this.store.select(selectActiveTab),
    };
    if (data?.jsonPath === Constants.SEVERITY_FIELD) {
      data.customMap = severityMap;
    }
    const dialogRef = this.dialog.open(FieldVisualizationDialogComponent, {
      panelClass: 'field-visualization',
      data,
    });

    dialogRef
      .afterClosed()
      .pipe(filterFalsy())
      .subscribe(({ payload, type }: IFieldVisualizationRes) => {
        switch (type) {
          case FieldVisualizationEvent.AddToSearchQuery:
            this.store.dispatch(addToSearchQueryAction(payload as ISearchQueryEvent));
            break;
          case FieldVisualizationEvent.AddSelectedQueryField:
            this.store.dispatch(addSelectedQueryField({ payload: payload as IAddSelectedQueryFieldEvent }));
        }
      });
  }
}
