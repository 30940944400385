
<div class="icon" #icon>
    <i class="fa fa-question-circle-o"></i>
</div>
<div class="ui special popup flowing">
    <div class="content">
        <ng-content>
        </ng-content>
    </div>
</div>

