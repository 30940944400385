import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ArchivingProviderModel {
  @jsonMember
  public id: number;
  @jsonMember
  public provider: string;
  @jsonMember
  public region: string;
  @jsonMember
  public name: string;
  @jsonMember
  public private_key: string;
  @jsonMember
  public active: boolean;
}
