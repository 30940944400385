<div class="preview-container" fxLayout="column" fxLayoutGap="14px">
  <h5 class="preview-container__title">Results</h5>
  <mat-expansion-panel #matExpansionPanel *ngIf="samplePreviewData" class="coralogix-mat-expansion-arrow-indicator preview-container__expansion-container"
                       [disabled]="(!samplePreviewData.response && !samplePreviewData.result) || !samplePreviewData.match"
                       [ngClass]="{disabled : (!samplePreviewData.response && !samplePreviewData.result) || !samplePreviewData.match }"
                       fxLayout="column">
    <mat-expansion-panel-header class="expansion-header">
     <span fxLayout="row" class="expansion-header__content" fxLayoutAlign="end center">
       <span *ngIf="(sampleForBlockRule || sampleForAllowRule) else regularMatch"
             class="expansion-header__status
             expansion-header__status--{{sampleForBlockRule && samplePreviewData.match && !sampleForAllowRule ||
              sampleForAllowRule && !samplePreviewData.match? 'blocked' : 'not-blocked'}}">{{
         (sampleForBlockRule && samplePreviewData.match && !sampleForAllowRule) ||
         (sampleForAllowRule && !samplePreviewData.match) ?
         'Blocked' : 'Not Blocked'
         }}
       </span>
       <ng-template #regularMatch>
         <span class="expansion-header__status">{{samplePreviewData.match ? 'Matched': 'Not Matched'}}</span>
       </ng-template>
     </span>
    </mat-expansion-panel-header>
    <pre class="expansion-content" [innerHTML]="samplePreviewData.result ?
    (samplePreviewData.result | json) : samplePreviewData.response ? samplePreviewData.response: ''"
         *ngIf="(!!samplePreviewData.response || !!samplePreviewData.result) && samplePreviewData.match">
    </pre>
  </mat-expansion-panel>
</div>


