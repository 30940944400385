import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../constants';
import { SecurityOnionMappingModel } from '@app/settings/shared/models/securityOnionMappingModel';

@Injectable()
export class SecurityOnionService {
  constructor(private http: HttpClient) {
  }

  public setMappingSecurityOnion(securityOnionMappingModel: SecurityOnionMappingModel): Observable<any> {
    return this.http.post(
      Constants.cloudSecuritySetMapping,
      {
        overwriteKibana: securityOnionMappingModel.overwriteKibana,
        overwriteAlerts: securityOnionMappingModel.overwriteAlerts,
      },
    ).map((r) => r);
  }
}
