import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  moduleId: 'logs-severity',
  selector: 'sh-logs-severity',
  templateUrl: 'logs-severity.component.html',
  styleUrls: ['logs-severity.component.scss'],
})
export class LogsSeverityComponent {
  @Input() public control: FormControl;

  public severities: {name: string, id: number}[] = [
    { name: 'Debug', id: 1 },
    { name: 'Verbose', id: 2 },
    { name: 'Info', id: 3 },
    { name: 'Warning', id: 4 },
    { name: 'Error', id: 5 },
    { name: 'Critical', id: 6 },
  ];

  public onClick(severity: { id: number; name: string }): void {
    const values: number[] = this.control.value;
    values.indexOf(severity.id) === -1 ? values.push(severity.id) : values.splice(values.indexOf(severity.id), 1);
    this.control.updateValueAndValidity();
    this.control.markAsDirty();
  }

  public isSelected(severity: { id: number; name: string }): boolean {
    const values: number[] = this.control.value;
    return values.indexOf(severity.id) !== -1;
  }
}
