import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { RuleFieldOptions } from '@app/rules/shared/models/parsing-theme-editor-consts';
import { IRuleFieldOptionValue } from '@app/rules/shared/models/parsing-theme-editor.models';
import { FormGroup, Validators } from '@angular/forms';
import {
  DynamicRuleControlType,
  IDynamicMultiSelectCol, IDynamicRuleCol,
  IDynamicRuleLayout,
  IDynamicSelectCol,
} from '@app/rules/shared/models/dynamic-rule-config.models';

@Component({
  selector: 'sh-dynamic-rule-form',
  templateUrl: './dynamic-rule-form.component.html',
  styleUrls: ['./dynamic-rule-form.component.scss'],
})
export class DynamicRuleFormComponent implements OnDestroy {
  @Input()
  public set ruleConfig(config: IDynamicRuleLayout) {
    this._ruleConfig = config;
    this.addValidationsByConfig();
    this.addValueChangeSubscriptions();
    if (this._extendedFieldOptions) {
      this.setFieldOptions();
    }
  }

  public get ruleConfig(): IDynamicRuleLayout {
    return this._ruleConfig;
  }

  @Input()
  public set extendedFieldOptions(data: IRuleFieldOptionValue[]) {
    if (data) {
      this._extendedFieldOptions = data;
      this.setFieldOptions();
    }
  }

  public get extendedFieldOptions(): IRuleFieldOptionValue[] {
    return this._extendedFieldOptions;
  }

  @Input()
  public extendedUniqueFields: string[];

  @Input()
  public type: string;

  @Input() public ruleForm: FormGroup;
  public fieldOptions: typeof RuleFieldOptions = RuleFieldOptions;
  public controlTypes: typeof DynamicRuleControlType = DynamicRuleControlType;
  public fieldsSelectOptionMap: { [key: string]: IRuleFieldOptionValue[] } = {};
  public fieldsMultiSelectOptionMap: { [key: string]: string[] } = {};

  private valueOnChangeSubscriptions: Subscription[] = [];
  private _ruleConfig: IDynamicRuleLayout;
  private _extendedFieldOptions: IRuleFieldOptionValue[];

  public ngOnDestroy(): void {
    this.valueOnChangeSubscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public getOptions(controlName: string): string[] {
    return this.type === 'removefields' ? this.extendedUniqueFields : this.fieldsMultiSelectOptionMap[controlName];
  }

  private addValidationsByConfig(): void {
    this.ruleConfig?.rowConfig?.forEach(row => {
      row?.forEach(control => {
        if (!control.isOptional) {
          this.ruleForm.get(control.controlName)?.setValidators([Validators.required]);
        }
      });
    });
  }

  private addValueChangeSubscriptions(): void {
    this.ruleConfig?.rowConfig?.forEach(row => {
      row?.forEach(control => {
        if (control.valueChangeSubscription) {
          this.valueOnChangeSubscriptions.push(
            this.ruleForm.get(control.controlName)?.valueChanges.subscribe(value => {
              control.valueChangeSubscription(this.ruleForm, value);
            }),
          );
        }
      });
    });
  }

  private setFieldOptions(): void {
    const ruleType = this.ruleForm?.value?.type;
    this.fieldOptions = this.extendedFieldOptions?.filter(option => {
      if (option.name.includes('.')) {
        return !option.rulesToSkip.includes(ruleType);
      } else {
        return !option.rulesToSkip.find(type => type === ruleType);
      }
    });
    this.setFieldsMap();
  }

  private setFieldsMap(): void {
    this.ruleConfig?.rowConfig.forEach(row => {
      row.forEach(col => {
        if ([DynamicRuleControlType.select, DynamicRuleControlType.multiSelect].includes(col.type)) {
          let colOptions: IRuleFieldOptionValue[];
          let removePrefix: string;
          switch (col.type) {
            case DynamicRuleControlType.select:
              colOptions = (col as IDynamicSelectCol).options;
              break;
            case DynamicRuleControlType.multiSelect:
              colOptions = (col as IDynamicMultiSelectCol).options;
              removePrefix = (col as IDynamicMultiSelectCol).removePrefix;
              break;
          }
          let thisSelectFieldMap = colOptions || [...this.fieldOptions];
          const formVal = this.ruleForm?.get(col.controlName)?.value;
          thisSelectFieldMap = this.removeOptionPrefix(thisSelectFieldMap, removePrefix);
          thisSelectFieldMap = this.addCurrentValuesToOptions(formVal, thisSelectFieldMap);
          this.setControllerOptions(thisSelectFieldMap, col);
        }
      });
    });
  }

  private setControllerOptions(thisSelectFieldMap: IRuleFieldOptionValue[], col: IDynamicRuleCol): void {
    switch (col.type) {
      case DynamicRuleControlType.select:
        this.fieldsSelectOptionMap[col.controlName] = thisSelectFieldMap;
        break;
      case DynamicRuleControlType.multiSelect:
        this.fieldsMultiSelectOptionMap[col.controlName] = thisSelectFieldMap.map(opt => opt.name);
        break;
    }
  }

  private addCurrentValuesToOptions(formVal: string | string[], thisSelectFieldMap: IRuleFieldOptionValue[]): IRuleFieldOptionValue[] {
    const selectedValues = Array.isArray(formVal) ? formVal : [formVal];
    selectedValues.forEach(selectedValue => {
      if (!thisSelectFieldMap?.find(opt => opt.value === selectedValue) && !!selectedValue) {
        const formOption: IRuleFieldOptionValue = {
          name: selectedValue,
          value: selectedValue,
          rulesToSkip: [],
        };
        thisSelectFieldMap.push(formOption);
      }
    });
    return thisSelectFieldMap;
  }

  private removeOptionPrefix(thisSelectFieldMap: IRuleFieldOptionValue[],
                                       removePrefix: string): IRuleFieldOptionValue[] {
    return thisSelectFieldMap
      .map(opt => {
        opt.name = opt.name.toLowerCase().startsWith(removePrefix) ? opt.name.slice(removePrefix?.length) : opt.name;
        return opt;
      });
  }
}
