import { createAction, props } from '@ngrx/store';
import { GenericMetadataType } from '@app/logs/shared/models/query-metadata';

export const getMetadataField = createAction(
  '[Generic metadata] Get - Metadata field',
  props<{payload: {fieldName: string, filters?: GenericMetadataType}}>());

export const getMetadataFieldCompleted = createAction(
  '[Generic metadata] Get - Metadata field completed',
  props<{payload: {metadata: string[], filedName: string}}>());

export const getMetadataFieldFailed = createAction(
  '[Generic metadata] Get - Metadata field failed');
