import { ERuleServerTypes } from '@app/rules/shared/models/rule.model';
import { ERuleTypesNames, IRuleData } from '@app/rules/shared/models/rule-types-view.models';
import { IRuleFieldOptionValue, ITimestampStandard, ITimestampStandardFormat } from '@app/rules/shared/models/parsing-theme-editor.models';
import { Constants } from '@app/constants';

export const RulesTypesCardsData: Partial<IRuleData>[] = [
  {
    title: ERuleTypesNames.parse,
    description: 'Convert unstructured text to a JSON record',
    buttonText: ERuleTypesNames.parse,
    type: ERuleServerTypes.parse,
  },
  {
    title: ERuleTypesNames.extract,
    description: 'Extract values from logs onto Coralogix metadata  or create a new JSON field',
    buttonText: ERuleTypesNames.extract,
    type: ERuleServerTypes.extract,
  },
  {
    title: ERuleTypesNames.extractJson,
    description: 'Extract values from JSON onto Coralogix metadata like category, severity, etc',
    buttonText: ERuleTypesNames.extractJson,
    type: ERuleServerTypes.jsonExtract,
  },
  {
    title: ERuleTypesNames.replace,
    description: 'Replace any pre defined field with a new value',
    buttonText: ERuleTypesNames.replace,
    type: ERuleServerTypes.replace,
  },
  {
    title: ERuleTypesNames.block,
    description: 'Prevent ingestion of logs based on a matching or non-matching regex',
    buttonText: ERuleTypesNames.block,
    type: ERuleServerTypes.block,
  },
  {
    title: ERuleTypesNames.timestampExtract,
    description: 'Replace log timestamp with JSON\'s field value',
    buttonText: ERuleTypesNames.timestampExtract,
    type: ERuleServerTypes.timestampExtract,
  },
  {
    title: ERuleTypesNames.removeFields,
    description: 'Remove Fields allows to select fields that will not be indexed',
    buttonText: ERuleTypesNames.removeFields,
    type: ERuleServerTypes.removeFields,
  }
];

// using those types => ERuleServerTypes
export const RuleFieldOptions: IRuleFieldOptionValue[] = Constants.getRulesOptionFields();

export const TimestampFormatStandards: ITimestampStandard[] = [
  {
    name: '1989 C (strftime)', value: 'strftime', rulesToSkip: [],
    _metadata: ({ enabled: true, format: '%Y-%m-%dT%H:%M:%S.%f%z', isDefault: true } as ITimestampStandardFormat)
  },
  {
    name: 'Java SimpleDateFormat', value: 'javasdf', rulesToSkip: [],
    _metadata: ({ enabled: true, format: 'yyyy-MM-ddTHH:mm:ss.999999999XXX' } as ITimestampStandardFormat)
  },
  {
    name: 'Go time layouts', value: 'golang', rulesToSkip: [],
    _metadata: ({ enabled: true, format: '2006-01-02T15:04:05.999999999Z07:00' } as ITimestampStandardFormat)
  },
  {
    name: 'Unix timestamp', value: 'secondsts', rulesToSkip: [],
    _metadata: ({ enabled: false, format: '' } as ITimestampStandardFormat)
  },
  {
    name: 'Unix milli-timestamp', value: 'millits', rulesToSkip: [],
    _metadata: ({ enabled: false, format: '' } as ITimestampStandardFormat)
  },
  {
    name: 'Unix micro-timestamp', value: 'microts', rulesToSkip: [],
    _metadata: ({ enabled: false, format: '' } as ITimestampStandardFormat)
  },
  {
    name: 'Unix nano-timestamp', value: 'nanots', rulesToSkip: [],
    _metadata: ({ enabled: false, format: '' } as ITimestampStandardFormat)
  },
];

export const DefaultTimestandard = TimestampFormatStandards.find(s => !!s._metadata?.isDefault);
