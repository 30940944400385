import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';

HC_more(Highcharts);

@Component({
  selector: 'sh-alerts-severity-chart',
  templateUrl: './alerts-severity-chart.component.html',
  styleUrls: ['./alerts-severity-chart.component.scss'],
})
export class AlertsSeverityChartComponent implements OnInit {
  public chart: Chart;

  public totalCount: number;

  @Input() public description = 'alerts in past 24 hours';

  @Input()
  public set totalSeverity(data: any) {
    this.totalCount = data.length ? data.reduce((acc, item) => acc + item.size, 0) : 0;
    this.chartModel.series = [
      {
        type: 'pie',
        showInLegend: false,
        innerSize: '80%',
        data:
          data.length &&
          data.map(item => ({
            ...item,
            y: item.size,
            name: item.severity,
            dataLabelsEnabled: false,
          })),
      },
    ];
    this.chart = new Chart(this.chartModel);
  }

  public chartModel: Options = {
    chart: {
      type: 'pie',
      width: 200,
      height: 200,
      backgroundColor: 'transparent',
    },
    tooltip: {
      outside: true,
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">●</span> {point.name}: <b>{point.y}</b><br/>',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        borderColor: 'transparent',
      },
    },
    title: {
      text: '',
    },
  };

  public ngOnInit(): void {
    this.chart = new Chart(this.chartModel);
  }
}
