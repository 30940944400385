import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'sh-archive-queries-empty',
  templateUrl: './archive-queries-empty.component.html',
  styleUrls: ['./archive-queries-empty.component.scss']
})
export class ArchiveQueriesEmptyComponent {
  @Input()
  public btnDisabled: boolean;
  @Output()
  public openEditor: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

}
