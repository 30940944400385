<div *ngFor="let item of listSummary | slice: 0:listLength">
  <div fxLayout="row" class="list-item-wrapper">
    <div fxFlex="70" class="alert-name" [cgxTooltip]="item.name" position="above">
      {{ item.name }}
    </div>
    <div fxFlex="30">
      <div class="severity-count-wrapper" [style.background]="item.color">
        {{ item.size }}
      </div>
    </div>
  </div>
</div>
