export class FilterOption {
  public name: string;
  public isSelected: boolean;
  public value: any;

  constructor(name: string, isSelected: boolean, value: any) {
    this.name = name;
    this.value = value;
    this.isSelected = isSelected;
  }
}
