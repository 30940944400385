import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoiMainLayoutComponent } from './containers/roi-main-layout/roi-main-layout.component';
import { ReindexContainerComponent } from './reindex/containers/reindex-container/reindex-container.component';
import { ReindexEmptyComponent } from './reindex/components/reindex-empty/reindex-empty.component';
import { ReindexFormContainerComponent } from './reindex/containers/reindex-form-container/reindex-form-container.component';
import { ReindexTableContainerComponent } from './reindex/containers/reindex-table-container/reindex-table-container.component';
import { ReindexTableItemComponent } from './reindex/components/reindex-table-item/reindex-table-item.component';
import { RouterModule } from '@angular/router';
import { RoiRoutingModule } from '@app/roi/roi.routes';
import { ReindexFormComponent } from './reindex/components/reindex-form/reindex-form.component';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlsModule } from '@shared/form-controls/form-controls.module';
import { ReindexEditorDetailsComponent } from './reindex/components/reindex-editor-details/reindex-editor-details.component';
import { ReindexEditorFiltersComponent } from './reindex/components/reindex-editor-filters/reindex-editor-filters.component';
import { ReindexEditorForm } from '@app/roi/reindex/models/reindex-editor-form';
import { ReindexS3BucketForm } from '@app/roi/reindex/models/reindex-s3-bucket-form';
import { ReindexS3FormContainerComponent } from './reindex/containers/reindex-s3-form-container/reindex-s3-form-container.component';
import { ReindexS3FormComponent } from './reindex/components/reindex-s3-form/reindex-s3-form.component';
import { ReindexEditorArchiveComponent } from './reindex/components/reindex-editor-archive/reindex-editor-archive.component';
import { ReindexAccessPermisionsInfoComponent } from './reindex/components/reindex-access-permisions-info/reindex-access-permisions-info.component';
import { MetricEditorForm } from '@app/settings/metrices-settings/models/metric-editor-form';
import { ArchivingProvidersLayoutComponent } from '@app/settings/archiving-providers/containers/archiving-providers-layout/archiving-providers-layout.component';
import { ArchivingFormProviderService } from '@app/settings/shared/services/archiving-form-provider';
import { ArchivingProvidersItemDetailsComponent } from '@app/settings/archiving-providers/controls/archiving-providers-item-details/archiving-providers-item-details.component';
import { ArchivingProvidersS3TutorialComponent } from '@app/settings/archiving-providers/controls/archiving-providers-s3-tutorial/archiving-providers-s3-tutorial.component';
import { ArchivingProvidersListComponent } from '@app/settings/archiving-providers/controls/archiving-providers-list/archiving-providers-list.component';
import { MetricConstants } from '@app/settings/metrices-settings/models/metric.constants';
import { ArchiveQueriesContainerComponent } from '@app/roi/archive-queries/containers/archive-queries-container/archive-queries-container.component';
import { ArchiveQueriesEmptyComponent } from '@app/roi/archive-queries/components/archive-queries-empty/archive-queries-empty.component';
import { ArchiveQueryTableItemComponent } from '@app/roi/archive-queries/components/archive-query-table-item/archive-query-table-item.component';
import { ArchiveQueriesTableContainerComponent } from '@app/roi/archive-queries/containers/archive-queries-table-container/archive-queries-table-container.component';
import { ArchiveQueryFormContainerComponent } from '@app/roi/archive-queries/containers/archive-query-form-container/archive-query-form-container.component';
import { ArchiveQueryFormComponent } from '@app/roi/archive-queries/components/archive-query-form/archive-query-form.component';
import { ArchiveQueryEditorDetailsComponent } from '@app/roi/archive-queries/components/archive-query-editor-details/archive-query-editor-details.component';
import { ArchiveQueryEditorFiltersComponent } from '@app/roi/archive-queries/components/archive-query-editor-filters/archive-query-editor-filters.component';
import { ArchiveQueryEditorForm } from '@app/roi/archive-queries/models/archive-query-editor-form';
import { LogsPreviewGridComponent } from '@app/roi/archive-queries/components/logs-preview-grid/logs-preview-grid.component';
import { DownloadResultsFormComponent } from '@app/roi/archive-queries/components/download-results-form/download-results-form.component';
import { ArchiveQueryLogsPreviewComponent } from '@app/roi/archive-queries/components/logs-preview/archive-query-logs-preview.component';
import { LivetailTerminalContainerModule } from '@app/livetail/livetail-terminal-container/livetail-terminal-container.module';
import { ReindexPermissionErrorModule } from '@app/roi/reindex/components/reindex-permissions-error/reindex-permission-error.module';
import { EmptyMetricsModule } from '@app/settings/metrices-settings/components/empty-metrics/empty-metrics.module';
import { MetricsEditorContainerModule } from '@app/settings/metrices-settings/metrics-editor-container/metrics-editor-container.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LogsNewGridModule } from '@app/logs-new/shared/features/logs-new-grid/logs-new-grid.module';
import {CgxButtonModule, CgxCheckboxModule} from '@coralogix/design';

@NgModule({
  declarations: [
    ArchivingProvidersLayoutComponent,
    ArchivingProvidersItemDetailsComponent,
    ArchivingProvidersListComponent,
    ArchivingProvidersS3TutorialComponent,
    RoiMainLayoutComponent,
    ReindexContainerComponent,
    ReindexEmptyComponent,
    ReindexFormContainerComponent,
    ReindexTableContainerComponent,
    ReindexTableItemComponent,
    ReindexFormComponent,
    ReindexEditorDetailsComponent,
    ReindexEditorFiltersComponent,
    ReindexS3FormContainerComponent,
    ReindexS3FormComponent,
    ReindexEditorArchiveComponent,
    ReindexAccessPermisionsInfoComponent,
    ArchiveQueriesContainerComponent,
    ArchiveQueriesEmptyComponent,
    ArchiveQueryTableItemComponent,
    ArchiveQueriesTableContainerComponent,
    ArchiveQueryFormContainerComponent,
    ArchiveQueryFormComponent,
    ArchiveQueryEditorDetailsComponent,
    ArchiveQueryEditorFiltersComponent,
    DownloadResultsFormComponent,
    ArchiveQueryLogsPreviewComponent,
    LogsPreviewGridComponent,
  ],
  imports: [
    RoiRoutingModule,
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    FormControlsModule,
    ReactiveFormsModule,
    LivetailTerminalContainerModule,
    ReindexPermissionErrorModule,
    EmptyMetricsModule,
    MetricsEditorContainerModule,
    ClipboardModule,
    LogsNewGridModule,
    CgxButtonModule,
    CgxCheckboxModule,
  ],
  providers: [
    ArchivingFormProviderService,
    ReindexEditorForm,
    ReindexS3BucketForm,
    MetricEditorForm,
    MetricConstants,
    ArchiveQueryEditorForm,
  ],
})
export class RoiModule {}
