import { LogsService } from '../shared/services/logs-service';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
} from '@angular/animations';

import { LogQueryModel } from '../shared/models/logsquery.model';
import * as moment from 'moment';
import { Metadata } from '../shared/models/query-metadata';
import { LogsQuerFormProvider } from './logs-query-form.provider';
declare var $: any;

@Component({
  moduleId: 'logs-query',
  selector: 'sh-logs-query',
  templateUrl: 'logs-query.component.html',
  styleUrls: ['logs-query.component.scss'],
  animations: [
    // style({opacity: 1,height: '*'}))
    trigger('visibilityChanged', [
      state('inValid', style({ opacity: 1, height: '*' })),
      state('valid', style({ opacity: 0, height: '0' })),
      transition('inValid => valid', [
        animate(
          '160ms ease-in',
          keyframes([
            style({ opacity: 1, height: '*', offset: 0 }),
            style({ opacity: 0, height: '*', offset: 0.3 }),
            style({ opacity: 0, height: 0, offset: 1.0 }),
          ]),
        ),
      ]),
      transition('valid => inValid', [
        animate(
          '210ms ease-in',
          keyframes([
            style({ opacity: 0, height: 0, offset: 0 }),
            style({ opacity: 0.1, height: '26px', offset: 0.3 }),
            style({ opacity: 0.3, height: '43px', offset: 0.5 }),
            style({ opacity: 1, height: '52px', offset: 1.0 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class LogsQueryComponent implements AfterViewInit {
  @ViewChild('queryicon', { static: true }) public queryIconElm: ElementRef;

  public metadata: Metadata = new Metadata();
  public now: boolean;

  get newQueryForm(): FormGroup {
    return this.formProvider.newQueryForm;
  }

  constructor(
    private logService: LogsService,
    private formProvider: LogsQuerFormProvider,
  ) {}

  public ngAfterViewInit() {
    $(this.queryIconElm.nativeElement).popup({
      popup: '.special.popup',
      hoverable: true,
    });
  }

  public get severityFormControl(): FormControl {
    return this.newQueryForm.controls['severity'] as FormControl;
  }

  public toggleEndTimeNow() {
    const datesModel: FormGroup = this.newQueryForm.controls[
      'dates'
    ] as FormGroup;
    datesModel.controls['to'].setValue(new Date());
    datesModel.controls['toTime'].setValue(
      moment()
        .subtract(15, 'seconds')
        .format('HH:mm:ss'),
    );

    this.now = !this.now;
    if (this.now) {
      datesModel.controls['to'].disable();
      datesModel.controls['toTime'].disable();
    } else {
      datesModel.controls['to'].enable();
      datesModel.controls['toTime'].enable();
    }
  }

  public onSubmitQuery() {
    this.submitLogQueryModel(this.formProvider.getModel());
  }

  public submitLogQueryModel(queryModel: LogQueryModel, cache: boolean = true) {
    queryModel.cacheQueryId = this.logService.getNewQueryId();
    this.logService.onNewQueryId.emit(queryModel.cacheQueryId);
    this.logService.newQueryRequest.emit(queryModel);
  }
  public resetQuery() {
    this.formProvider.resetForm();
    this.now = false;
  }
}
