import { Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { HerokuGurd } from '../shared/guards/heroku-payment.gurd';
import { AuthGuard } from '@app/auth/shared/guards/auth.guard';
import { PaymentGuard } from '@app/shared/guards/payment.guard';

export const DashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      title: 'Dashboard',
    },
    canActivate: [AuthGuard, PaymentGuard, HerokuGurd],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
