import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {  Store } from '@ngrx/store';
import {
  getArchivePermissionsError,
  getArchivingProvider,
  State,
} from '@app/app.reducers';
import { ArchiveQueryEditorActions } from '@app/roi/archive-queries/state/actions/archive-query-editor.actions';
import { Observable, interval, Subject, Subscription } from 'rxjs';
import { IArchiveQuery, IUiState, MAXIMUM_ITEMS } from '@app/roi/archive-queries/models/archive-query.model';
import { ArchiveQueryActions } from '@app/roi/archive-queries/state/actions/archive-query.actions';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {
  getArchiveQueries,
  getArchiveQueriesUiState, getCurrentPage,
  getTotalQueries, loadingArchiveQueries, secondLoading, getFilter
} from '@app/roi/archive-queries/state/selectors/archive-query-selector';
import { ReindexEditorActions } from '@app/roi/reindex/state/actions/reindex-editor.actions';
import { ArchivingProvidersActions } from '@app/settings/reducer/archiving-providers/archiving-providers.action';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sh-archive-queries-container',
  templateUrl: './archive-queries-container.component.html',
  styleUrls: ['./archive-queries-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ArchiveQueriesContainerComponent implements OnInit, AfterViewInit , OnDestroy {
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public isArchiveQueriesLoading$: Observable<boolean>;
  public isSecondLoading$: Observable<boolean>;
  public archiveQueries$: Observable<IArchiveQuery[]>;
  public uiState$: Observable<IUiState>;
  public currentPage: number;
  public filter: string;
  public searchTerm: string = '';
  public total$: Observable<number>;
  public unsubscribe$: Subject<void> = new Subject<void>();
  public archivingProviders$: Observable<boolean>;
  public permissionsError$: Observable<boolean>;
  public archiveQueriesSearchTermController: FormControl = new FormControl();
  private interval: Subscription;

  constructor(private store: Store<State>, private router: ActivatedRoute) {
    this.store.dispatch(new ArchivingProvidersActions.GetArchivingProvidersAction());
  }

  public ngOnInit(): void {
    this.store.select(getCurrentPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(p => this.currentPage = p);
    this.store.select(getFilter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(f => this.filter = f);
    this.store.dispatch(new ArchiveQueryActions.GetArchiveQueriesAction({
      loading: true,
      pageIndex: this.currentPage,
      pageSize: MAXIMUM_ITEMS,
      filter: this.filter
    }));
    this.store.dispatch(new ArchiveQueryActions.GetBucketPermissionsAction());
    this.archiveQueries$ = this.store.select(getArchiveQueries);
    this.total$ = this.store.select(getTotalQueries);
    this.uiState$ = this.store.select(getArchiveQueriesUiState);
    this.isArchiveQueriesLoading$ = this.store.select(loadingArchiveQueries);
    this.isSecondLoading$ = this.store.select(secondLoading);
    this.archivingProviders$ = this.store.select(getArchivingProvider).pipe(map( (archive) => !archive));
    this.permissionsError$ = this.store.select(getArchivePermissionsError);
    this.interval = interval(5000).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (document.hasFocus()) {
        this.store.dispatch(new ArchiveQueryActions.GetArchiveQueriesAction({
          loading: false,
          pageIndex: this.currentPage,
          pageSize: MAXIMUM_ITEMS,
          filter: this.filter}));
      }
    });
    this.archiveQueriesSearchTermController.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged()
    ).subscribe((searchTerm: string | number) => {
      this.searchTerm = searchTerm !== null && searchTerm !== undefined ? searchTerm.toString().toLowerCase() : '';
    });
  }

  public ngAfterViewInit(): void {
    const intl = this.paginator._intl;
    intl.nextPageLabel = '';
    intl.previousPageLabel = '';
    this.router.queryParams.pipe(take(1)).subscribe((params) => {
      if (params?.id) {
        this.archiveQueriesSearchTermController.patchValue(params?.id);
        this.dispatchSearchTerm();
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(new ArchiveQueryActions.ClearLoadingAction());
  }

  public openEditor(): void {
    this.store.dispatch(new ArchiveQueryEditorActions.ToggleEditorAction({ openEditor: true, prePopulatedQuery: null, isEditable: true }));
  }

  public getPageQueries(event: PageEvent): void {
    const pageIndex = event.pageIndex + 1;
    const pageSize = event.pageSize;
    this.store.dispatch(new ArchiveQueryActions.SetPagingAction({ pageIndex: pageIndex, pageSize: pageSize }));
    this.store.dispatch(new ArchiveQueryActions.GetArchiveQueriesAction({
      loading: true,
      pageIndex: pageIndex,
      pageSize: pageSize,
      filter: this.filter
    }));
  }

  public dispatchSearchTerm(): void {
    this.store.dispatch(new ArchiveQueryActions.SetSearchTermAction({ term: this.searchTerm }));
    this.store.dispatch(new ArchiveQueryActions.GetArchiveQueriesAction({
      loading: true,
      pageIndex: 1,
      pageSize: MAXIMUM_ITEMS,
      filter: this.filter
    }));
    this.paginator.firstPage();
  }

  public submitHandler(): void {
    this.store.dispatch(new ArchiveQueryActions.ActivateLoadingAction());
    this.store.dispatch(new ArchiveQueryActions.SetPagingAction({ pageIndex: 1, pageSize: MAXIMUM_ITEMS }));
    this.store.dispatch(new ArchiveQueryActions.SetSearchTermAction({ term: '' }));
    this.store.dispatch(new ArchiveQueryActions.GetArchiveQueriesAction({
      loading: false,
      pageIndex: 1,
      pageSize: MAXIMUM_ITEMS,
      filter: ''
    }));
    this.store.dispatch(new ArchiveQueryEditorActions.ToggleEditorAction({
      openEditor: false,
      prePopulatedQuery: null,
      isEditable: false
    }));
    this.archiveQueriesSearchTermController.patchValue('');
    this.paginator.firstPage();
    this.store.dispatch(new ArchiveQueryActions.ClearLoadingAction());
  }

  public openBucketEditor(): void {
    this.store.dispatch(new ReindexEditorActions.TogglePermissionsEditorAction(true));
  }
}
