import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoralogixSelectComponent } from './coralogix-select.component';
import { MaterialModule } from '@app/shared/material/material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CgxCheckboxModule } from '@coralogix/design';

@NgModule({
  declarations: [CoralogixSelectComponent],
  imports: [CommonModule, MaterialModule, NgSelectModule, FormsModule, CgxCheckboxModule],
  exports: [CoralogixSelectComponent],
})
export class CoralogixSelectModule {}
