import { Build } from '../../models/build';
import * as _ from 'lodash';
import { TagsActions } from '../actions/tag.actions';
import { Constants } from '@app/constants';
import { EFeatureViewMode, ISelectedTagView } from '@app/deployment/shared/models/reducer.models';
import { graphPointValue, timestampVal } from '@app/statistics/shared/models/line-chart.models';
import { differenceInMinutes } from 'date-fns';
import { MaxMinutesForMinutesView } from '@app/statistics/shared/helpers/line-chart-tags-helper';

// tslint:disable-next-line:no-namespace
export namespace TagsReducer {
  // tslint:disable-next-line:interface-name
  export interface State {
    tags: Build[];
    isNewTag: boolean;
    loading: boolean;
    saving: boolean;
    tagLoading: boolean;
    deletedTagId: number;
    selectedTagView: ISelectedTagView;
  }
  const initialState: State = {
    tags: null,
    isNewTag: false,
    loading: true,
    saving: false,
    tagLoading: false,
    deletedTagId: -1,
    selectedTagView: {
      selectedShowAllSubsystems: false,
      selectedTag: null,
      selectTagForCompare: null,
      compareNSelectedPayloadBase: null,
      summaryViewMode: EFeatureViewMode.initialState,
      summaryArray: [],
      errorVolume: null,
      errorVolumeViewMode: EFeatureViewMode.initialState,
      alerts: null,
      alertsViewMode: EFeatureViewMode.initialState,
      graphsDelta: null,
      selectedLog: null,
    },
  };

  export function reducer(state: State = initialState, action: TagsActions.Actions): State {
    switch (action.type) {
      case TagsActions.ActionTypes.SELECT_TAG:
        return {
          ...state,
          deletedTagId: -1,
          isNewTag: false,
          selectedTagView: {
            ...state.selectedTagView,
            selectedTag: action.payload,
            selectedShowAllSubsystems: false,
            selectedLog: null,
          },
        };
      case TagsActions.ActionTypes.SELECT_NEW_TAG:
        const initialCompare = getInitialTagCompare(action.payload, state.tags);
        return {
          ...state,
          deletedTagId: -1,
          isNewTag: true,
          selectedTagView: {
            ...state.selectedTagView,
            selectedTag: action.payload,
            selectTagForCompare: initialCompare,
            selectedShowAllSubsystems: false,
            selectedLog: null,
          },
        };
      case TagsActions.ActionTypes.ADD_TAG:
        return {
          ...state,
          saving: true,
          deletedTagId: -1,
          selectedTagView: {
            ...state.selectedTagView,
            selectedLog: null,
          },
        };
      case TagsActions.ActionTypes.GET_TAGS:
        return {
          ...state,
          loading: true,
          selectedTagView: {
            ...state.selectedTagView,
            selectedLog: null,
          },
        };
      case TagsActions.ActionTypes.DELETE_TAG_COMPLETED:
        const tagIndex = state.tags.map(t => t.id).indexOf(action.payload);
        const selectedTag = state.selectedTagView.selectedTag;
        return {
          ...state,
          deletedTagId: action.payload,
          tags: [...state.tags.slice(0, tagIndex), ...state.tags.slice(tagIndex + 1)],
          selectedTagView: {
            ...state.selectedTagView,
            selectedTag: selectedTag,
            selectedLog: null,
          },
        };
      case TagsActions.ActionTypes.GET_TAGS_COMPLETED:
        return {
          ...state,
          loading: false,
          deletedTagId: -1,
          tags: [...action.payload],
          selectedTagView: {
            ...state.selectedTagView,
            selectedLog: null,
          },
        };
      case TagsActions.ActionTypes.EDIT_TAG_COMPLETED:
        const editIndex = state.tags.map(t => t.id).indexOf(action.payload.id);
        return {
          ...state,
          tags: [...state.tags.slice(0, editIndex), action.payload, ...state.tags.slice(editIndex + 1)],
        };
      case TagsActions.ActionTypes.ADD_TAG_COMPLETED:
        const currTags = _.cloneDeep([...state.tags]);
        const tagData: Build = action.payload.tagData;
        const newTags = [
          ..._.sortBy([tagData, ...currTags], (build: Build) => {
            return build.tag_timestamp;
          }).reverse(),
        ];
        const _initialCompareTag = getInitialTagCompare(tagData, newTags);
        if (state.tags.length >= Constants.TAGS_MAX_SIZE) {
          currTags.splice(-1, 1);
        }
        return {
          ...state,
          loading: false,
          isNewTag: true,
          saving: false,
          tags: newTags,
          selectedTagView: {
            ...state.selectedTagView,
            selectedTag: tagData,
            selectTagForCompare: _initialCompareTag,
            selectedShowAllSubsystems: false,
          },
        };
      case TagsActions.ActionTypes.SELECT_TAG_TO_COMPARE:
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            selectTagForCompare: action.payload,
            selectedLog: null,
          },
        };
      case TagsActions.ActionTypes.CHANGE_TAG_FILTER_BY_SUBSYSTEMS:
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            selectedLog: null,
            selectedShowAllSubsystems: action.payload,
          },
        };
      case TagsActions.ActionTypes.GET_TAG_SUMMARY: {
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            summaryViewMode: EFeatureViewMode.loading,
            summaryArray: null,
          },
        };
      }
      case TagsActions.ActionTypes.GET_TAG_SUMMARY_COMPLETED:
        const summaryViewMode = !!action?.payload?.length ? EFeatureViewMode.completed : EFeatureViewMode.noResults;
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            summaryViewMode,
            summaryArray: action.payload,
          },
        };
      case TagsActions.ActionTypes.GET_TAG_SUMMARY_FAILED: {
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            summaryViewMode: EFeatureViewMode.serverError,
          },
        };
      }
      case TagsActions.ActionTypes.SET_TAG_N_COMPARE_TAG_PAYLOAD_BASE_COMPLETE:
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            compareNSelectedPayloadBase: action.payload,
          },
        };
      case TagsActions.ActionTypes.GET_TAG_ALERTS:
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            alertsViewMode: EFeatureViewMode.loading,
            alerts: null,
            graphsDelta: null,
          },
        };
      case TagsActions.ActionTypes.GET_TAG_ALERTS_COMPLETED:
        const length = action?.payload?.severityInfo?.length;
        const alertsViewMode = length ? EFeatureViewMode.completed : EFeatureViewMode.noResults;
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            alerts: action.payload,
            graphsDelta: getDelta(action.payload.volumeCompareGraph.tag),
            alertsViewMode,
          },
        };
      case TagsActions.ActionTypes.GET_TAG_ALERTS_FAILED:
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            alertsViewMode: EFeatureViewMode.serverError,
          },
        };
      case TagsActions.ActionTypes.GET_TAG_ERROR_VOLUME:
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            errorVolumeViewMode: EFeatureViewMode.loading,
            errorVolume: null,
            graphsDelta: null,
          },
        };
      case TagsActions.ActionTypes.GET_TAG_ERROR_VOLUME_COMPLETED:
        const errorLength = action?.payload?.tag?.length;
        const errorVolumeViewMode = !!errorLength && errorLength > 1 ? EFeatureViewMode.completed : EFeatureViewMode.noResults;
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            errorVolume: action.payload,
            graphsDelta: getDelta(action.payload.tag),
            errorVolumeViewMode,
          },
        };
      case TagsActions.ActionTypes.GET_TAG_ERROR_VOLUME_FAILED:
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            errorVolumeViewMode: EFeatureViewMode.serverError,
          },
        };
      case TagsActions.ActionTypes.SELECT_TAG_LOG_ROW:
        return {
          ...state,
          selectedTagView: {
            ...state.selectedTagView,
            selectedLog: action.payload,
          },
        };
      default:
        return state;
    }
  }
  function getInitialTagCompare(tag: Build, list: Build[]): Build {
    const compareTagInd = list?.findIndex(_tag => tag?.id === _tag.id);
    const hadCompareIndex = list && compareTagInd > -1 && list[compareTagInd + 1];
    return hadCompareIndex ? list[compareTagInd + 1] : null;
  }

  function getDelta(graphArray: [timestampVal, graphPointValue][]): string {
    if (Array.isArray(graphArray) && !!graphArray[0]) {
      const differenceInMin = Math.abs(differenceInMinutes(graphArray[0][0], graphArray[graphArray.length - 1][0]));
      if (differenceInMin <= MaxMinutesForMinutesView) {
        return `${Math.round(differenceInMin)} Minutes`;
      }
      return `${Math.round(differenceInMin / 60)} Hours`;
    }
    return '';
  }
}
