import {
  CapabilityTypes,
  IRegistry,
  IUrlsPostFix, urlsPostfixes, Capabilities, ResetFields,
} from '@app/logs-new/shared/abilities-state-registry/capabilities-model';

export class AQRegistry implements IRegistry {
  fieldsToReset: ResetFields = new ResetFields(true, true, true);

  urlsPostFix: Partial<IUrlsPostFix> = {
    ...urlsPostfixes,
    queryUrl: '/api/v1/archive-logs/logs',
    logsAggsPostfix : '/api/v1/archive-logs/terms',
    logsStatsPostfix: '/api/v1/archive-logs/histogram',
    queryStatePostfix: '/api/v1/archive-logs/stats'
  };
  private capabilities: Capabilities = new Capabilities(
    false,
    false,
    false,
    true,
    false,
    true,
    false,
    true,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false);

  isEnabled(cap: CapabilityTypes): boolean {
    return this.capabilities[cap];
  }
}
