import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsEditorContainerComponent } from './metrics-editor-container.component';
import { CoralogixSidebarModule } from '@app/shared/popups/coralogix-sidebar/coralogix-sidebar.module';
import { MetricsEditorModule } from '../components/metrics-editor/metrics-editor.module';

@NgModule({
  declarations: [MetricsEditorContainerComponent],
  imports: [
    CommonModule,
    CoralogixSidebarModule,
    MetricsEditorModule
  ],
  exports: [MetricsEditorContainerComponent]
})
export class MetricsEditorContainerModule { }
