import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Build } from '../../models/build';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { WidgetsService } from '@app/widgets/shared/services/widgets.service';
import { StatisticsCategory, StatisticsQueryDefinition, StatisticsTypes } from '@app/statistics/shared/models/statistics-query-definition';
import { LogQueryData } from '@app/logs/shared/models/logquery.data';
import { GlobalParamsProvider } from '@shared/services/globalParams.provider';
import { NavigationExtras, Router } from '@angular/router';
import { StatisticService } from '@app/statistics/shared/services/statistics-service';
import { Observable } from 'rxjs';
import { statisticsTrend } from '../../shared/models/statisticsTrend';
import * as _ from 'lodash';
import { Widget, widgetsShowInOptions } from '@app/widgets/shared/models/widget';
import { JsonStatisticsWidget, StatisticsWidget } from '@app/widgets/shared/models/statisticsWidget';
import { TemplateWidget } from '@app/loggregation/shared/interfaces/templateWidget';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WidgetAddComponent } from '@shared/controls/widgets/widget-add/widget-add.component';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { coralogixMenuImg, TagsBaseUrl } from '@app/deployment/shared/consts/tags.consts';
import { EFeatureViewMode } from '@app/deployment/shared/models/reducer.models';
import differenceInHours from 'date-fns/differenceInHours';

@Component({
  selector: 'sh-event-info-viewer',
  templateUrl: './event-info-viewer.component.html',
  styleUrls: ['./event-info-viewer.component.scss'],
})
export class EventInfoViewerComponent implements OnDestroy {
  @Input() public viewState: EFeatureViewMode;

  @Input() set isSelectAllSubsystems(value: boolean) {
    this._isSelectAllSubsystems = value;
    this.resetModel();
  }

  @Input() set all(value: [Build, Build[]]) {
    const [build, builds] = value;
    if (!value) {
      return;
    }
    if (value.length > 0 && !!builds?.length) {
      this._builds = builds;
      if (value.length > 0 && build) {
        this._build = build;
        this.resetModel();
      }
    }
  }

  public completedViewState: EFeatureViewMode = EFeatureViewMode.completed;
  public alertsWidget: any;
  public anomalyWidget: any;
  public _build: Build;
  public customChartWidgets: any[];
  public statisticsTrendIsLoading: boolean = true;
  public statisticsTrend: statisticsTrend;

  @Input() public deletedId: number;

  public _builds: Build[];

  public endDate: number;
  public prvEnd: number;
  public prvStart: number;
  public prevTagId: number;

  public logQueryModel: LogQueryModel;
  public queryData: LogQueryData;
  public highlightedLogs: Map<string, string> = new Map<string, string>();

  public newLogsCount: number;
  public menuImg: string = coralogixMenuImg;
  private updateQueryModel: Subject<any> = new Subject();
  private onUpdateQueryModelObserver: Subscription;
  private _isSelectAllSubsystems: boolean;

  constructor(
    private widgetService: WidgetsService,
    private htmlRowMeasure: HtmlRowMeasure,
    private router: Router,
    public dialog: MatDialog,
    private statisticService: StatisticService,
    private globalParams: GlobalParamsProvider,
  ) {
    this.statisticsTrend = new statisticsTrend();

    this.onUpdateQueryModelObserver = this.updateQueryModel.debounceTime(1000).subscribe(model => {
      this.logQueryModel = model;
      const widget = this.widgetService.buildWidgetWithQuery(
        moment.unix(this.logQueryModel.startDate / 1000).toDate(),
        moment.unix(this.logQueryModel.endDate / 1000).toDate(),
        'Alerts 24 hours',
        StatisticsCategory.ALERTS,
        'pie',
      );
      widget.statisticsQueryDefinition.type = StatisticsTypes.Total;
      widget.statisticsQueryDefinition.logQeuryModel = model;
      this.alertsWidget = widget;
      this.widgetService
        .getWidgets()
        .take(1)
        .subscribe((res: any) => {
          this.customChartWidgets = res
            .filter(item => this.filterWidget(item))
            .map(item => this.changeWidgetDates(item, this.logQueryModel.startDate, this.logQueryModel.endDate));
          if (this.isLessThan3Hours) {
            this.customChartWidgets.forEach(chartWidget => {
              chartWidget.statisticsQueryDefinition.logQeuryModel.queryParams.aggregationInterval = 60000; // 1 minute
            });
          }
        });
      const anomalyWidget = this.widgetService.buildWidgetWithQuery(
        moment.unix(this.logQueryModel.startDate / 1000).toDate(),
        moment.unix(this.logQueryModel.endDate / 1000).toDate(),
        'Anomalies 24 hours',
        StatisticsCategory.ANOMALIES,
        'pie',
      );
      anomalyWidget.statisticsQueryDefinition.type = StatisticsTypes.Total;
      anomalyWidget.statisticsQueryDefinition.logQeuryModel = model;
      this.anomalyWidget = anomalyWidget;
    });
  }

  get build(): Build {
    return this._build;
  }

  get isLessThan3Hours(): boolean {
    const diff = differenceInHours(this.prvEnd, this.prvStart);
    return diff <= 3;
  }

  public ngOnDestroy(): void {
    if (!!this.onUpdateQueryModelObserver) {
      this.onUpdateQueryModelObserver.unsubscribe();
    }
  }

  public redirectToMainTagsRoute(): void {
    this.router.navigate([TagsBaseUrl]).finally();
  }

  public getPrevTag(): Build {
    let prvTag: Build;
    if (this._build) {
      if (this._builds) {
        const buildF = this._builds
          .filter(x => x.application_name === this.build.application_name)
          .filter(x => x.subsystem_name === this.build.subsystem_name);
        const tagIndex = buildF.map(t => t.id).indexOf(this._build.id);
        if (tagIndex !== -1 && tagIndex < buildF.length - 1) {
          const prevTagIndex = tagIndex + 1;
          prvTag = buildF[prevTagIndex];
        }
      }
    }
    return prvTag;
  }

  public getBuildEndTime(tag: Build): number {
    let newEnd: number;
    if (tag) {
      newEnd = tag.tag_timestamp + 86400000 < Date.now() ? tag.tag_timestamp + 86400000 : Date.now();
      if (this._builds) {
        const buildF = this._builds
          .filter(x => x.application_name === this.build.application_name)
          .filter(x => x.subsystem_name === this.build.subsystem_name);

        const tagIndex = buildF.map(t => t.id).indexOf(tag.id);
        if (tagIndex !== -1 && tagIndex > 0) {
          const nextTagIndex = tagIndex - 1;
          const nextTag = buildF[nextTagIndex];
          newEnd = nextTag.tag_timestamp;
        }
      }
    }

    if (newEnd) {
      if (newEnd > this._build.tag_timestamp + 86400000) {
        newEnd = this._build.tag_timestamp + 86400000;
      }
    }
    return newEnd;
  }

  public onChartClicked(e: [LogQueryModel, StatisticsQueryDefinition]): void {
    const logQuery: LogQueryModel = _.cloneDeep(e[0]);
    const statisticDef: StatisticsQueryDefinition = _.cloneDeep(e[1]);

    let filterSev = '';
    if (logQuery && logQuery.queryParams && logQuery.queryParams.metadata && logQuery.queryParams.metadata.severity) {
      const sev = logQuery.queryParams.metadata.severity[0];
      if (sev === 3) {
        filterSev = 'info';
      } else if (sev === 4) {
        filterSev = 'warning';
      } else if (sev === 6) {
        filterSev = 'critical';
      }
    }

    if (statisticDef) {
      switch (statisticDef.category) {
        case StatisticsCategory.SEVERITY:
        case StatisticsCategory.LOGS: {
          if (logQuery) {
            this.globalParams.chartClickedLogQueryModel = logQuery;
          }

          this.router.navigate(['/query-new/logs']);
          break;
        }
        case StatisticsCategory.ALERTS: {
          const navigationExtras: NavigationExtras = {
            queryParams: { filter: 'ALERTS', severity: filterSev, id: '' },
          };
          this.router.navigate(['/insights'], navigationExtras);
          break;
        }
        case StatisticsCategory.ANOMALIES: {
          const navigationExtras: NavigationExtras = {
            queryParams: { filter: 'ANOMALIES', severity: filterSev, id: '' },
          };
          this.router.navigate(['/insights'], navigationExtras);
          break;
        }
      }
    }
  }
  public filterWidget(widget: Widget): boolean {
    let result = false;
    if (widget.group !== widgetsShowInOptions.tagsPage) {
      return false;
    }

    const query = JSON.parse(widget.query);
    const diffApplication = this.logQueryModel.queryParams.metadata.applicationName.filter(x =>
      query.queryParams.metadata.applicationName.includes(x),
    );
    const diffSubsystem = this.logQueryModel.queryParams.metadata.subsystemName.filter(x =>
      query.queryParams.metadata.subsystemName.includes(x),
    );
    result =
      (this.logQueryModel.queryParams.metadata.applicationName.length === 0 ||
        query.queryParams.metadata.applicationName.length === 0 ||
        diffApplication.length > 0) &&
      (this.logQueryModel.queryParams.metadata.subsystemName.length === 0 ||
        query.queryParams.metadata.subsystemName.length === 0 ||
        diffSubsystem.length > 0);

    return result;
  }

  public onDeleteWidget(widget: Widget): void {
    this.widgetService
      .deleteWidget(widget.id)
      .first()
      .subscribe(() => {
        const index = this.customChartWidgets.indexOf(widget);
        this.customChartWidgets.splice(index, 1);
      });
  }

  public editWidget(widget: Widget): void {
    if (!widget || !widget.id) {
      return;
    }
    this.widgetService
      .updateWidget(widget)
      .first()
      .switchMap(() => this.widgetService.getWidget(widget.id))
      .first()
      .subscribe((result: any) => {
        if (result.length > 0) {
          const index = this.customChartWidgets.indexOf(widget);
          if (this.filterWidget(widget)) {
            this.customChartWidgets.splice(index, 1, this.changeWidgetDates(result[0], this.prvStart, this.prvEnd));
          } else {
            this.customChartWidgets.splice(index, 1);
          }
        }
      });
  }

  public changeWidgetDates(widget: Widget, startDate: number, endDate: number): Widget {
    const newWidget = _.cloneDeep(widget);
    if (newWidget instanceof StatisticsWidget) {
      (newWidget as StatisticsWidget).statisticsQueryDefinition.logQeuryModel.startDate = startDate;
      (newWidget as StatisticsWidget).statisticsQueryDefinition.logQeuryModel.endDate = endDate;
      (newWidget as StatisticsWidget).statisticsQueryDefinition.logQeuryModel.queryParams.aggregationInterval = this.getInterval(
        this.prvStart,
        this.prvEnd,
      );
    }
    if (newWidget instanceof JsonStatisticsWidget) {
      (newWidget as JsonStatisticsWidget).statisticsQueryDefinition.logQeuryModel.startDate = startDate;
      (newWidget as JsonStatisticsWidget).statisticsQueryDefinition.logQeuryModel.endDate = endDate;
      (newWidget as JsonStatisticsWidget).statisticsQueryDefinition.logQeuryModel.queryParams.aggregationInterval = this.getInterval(
        this.prvStart,
        this.prvEnd,
      );
    }
    if (newWidget instanceof TemplateWidget) {
      (newWidget as TemplateWidget).queryRequest.queryDef.startDate = startDate;
      (newWidget as TemplateWidget).queryRequest.queryDef.startDate = endDate;
    }
    return newWidget;
  }

  public showWidget(widget: Widget): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = { widget };
    config.panelClass = 'add-edit-widget-container';
    const dialogRef = this.dialog.open(WidgetAddComponent, config);
    dialogRef
      .afterClosed()
      .first()
      .subscribe(_widget => {
        this.editWidget(_widget);
      });
  }

  private resetModel(): void {
    const pevTag = this.getPrevTag();
    const currentBuildEndTime = this.getBuildEndTime(this._build);
    this.endDate = currentBuildEndTime;

    const pevTagEndTime = this.getBuildEndTime(pevTag);
    let prevTagStartTime;
    this.prvEnd = pevTagEndTime;
    if (pevTag) {
      this.prevTagId = pevTag.id;
      prevTagStartTime = pevTag.tag_timestamp;
      this.prvStart = prevTagStartTime;
    }

    this.resetQueryModel(currentBuildEndTime, pevTagEndTime, prevTagStartTime);
  }

  private resetQueryModel(currentBuildEndTime: number, pevTagEndTime: number, prevTagStartTime: number): void {
    if (!this._build) {
      return;
    }
    const model: LogQueryModel = new LogQueryModel();

    if (!currentBuildEndTime) {
      currentBuildEndTime = this._build.tag_timestamp + 86400000 < Date.now() ? this._build.tag_timestamp + 86400000 : Date.now();
    }

    model.endDate = currentBuildEndTime;
    model.startDate = this._build.tag_timestamp;
    if (this._build.application_name) {
      model.queryParams.metadata.applicationName = this._build.application_name.split(',');
    }
    if (!this._isSelectAllSubsystems && this._build.subsystem_name) {
      model.queryParams.metadata.subsystemName = this._build.subsystem_name.split(',').filter(item => item !== '');
    }

    model.queryParams.metadata.severity = [4, 5, 6];
    model.queryParams.aggregationInterval = 3600 * 1000;

    this.logQueryModel = null;
    this.alertsWidget = null;
    this.anomalyWidget = null;
    this.statisticsTrendIsLoading = true;
    this.getStatisticsTrend(model, pevTagEndTime, prevTagStartTime);
    this.updateQueryModel.next(model);
  }

  private getStatisticsTrend(model: LogQueryModel, pevTagEndTime: number, prevTagStartTime: number): void {
    const stats = new StatisticsQueryDefinition();
    stats.category = StatisticsCategory.LOGS;
    stats.seriesType = 'pie';
    stats.logQeuryModel = new LogQueryModel();
    stats.logQeuryModel.startDate = moment.unix(model.startDate).unix();
    stats.logQeuryModel.endDate = moment.unix(model.endDate).unix();
    stats.logQeuryModel.queryParams.metadata.applicationName = model.queryParams.metadata.applicationName;
    stats.logQeuryModel.queryParams.metadata.subsystemName = model.queryParams.metadata.subsystemName;

    const statsDayBefore = _.cloneDeep(stats);

    statsDayBefore.logQeuryModel.startDate = moment
      .unix(model.startDate)
      .subtract(1, 'day')
      .unix();
    statsDayBefore.logQeuryModel.endDate = moment
      .unix(model.endDate)
      .subtract(1, 'day')
      .unix();

    if (pevTagEndTime && prevTagStartTime) {
      if (prevTagStartTime + 86400000 >= model.startDate) {
        statsDayBefore.logQeuryModel.startDate = prevTagStartTime;
        statsDayBefore.logQeuryModel.endDate = pevTagEndTime;
      }
    }

    this.prvStart = statsDayBefore.logQeuryModel.startDate;
    this.prvEnd = statsDayBefore.logQeuryModel.endDate;

    Observable.zip(this.statisticService.getStats(statsDayBefore), this.statisticService.getStats(stats))
      .first()
      .finally(() => {
        this.statisticsTrendIsLoading = false;
        // console.log(this.statisticsTrendIsLoading);
      })
      .map(([statBeforeBuild, statAfterBuild]) => [statBeforeBuild.data.yAxis.values, statAfterBuild.data.yAxis.values])
      .first()
      .subscribe(([statB, statA]) => {
        // console.log(statB, statA);
        this.statisticsTrend = new statisticsTrend();

        // Before Tag Stats
        this.statisticsTrend.statisticsBeforeTag.totalLogs =
          _.sum(statB[0]) + _.sum(statB[1]) + _.sum(statB[2]) + _.sum(statB[3]) + _.sum(statB[4]) + _.sum(statB[5]);
        this.statisticsTrend.statisticsBeforeTag.totalHighSeverityLogs = _.sum(statB[0]) + _.sum(statB[1]) + _.sum(statB[2]);
        this.statisticsTrend.statisticsBeforeTag.totalCriticalSeverityLogs = _.sum(statB[0]);
        this.statisticsTrend.statisticsBeforeTag.totalErrorSeverityLogs = _.sum(statB[1]);
        this.statisticsTrend.statisticsBeforeTag.totalWarningSeverityLogs = _.sum(statB[2]);

        this.statisticsTrend.statisticsAfterTag.totalLogs =
          _.sum(statA[0]) + _.sum(statA[1]) + _.sum(statA[2]) + _.sum(statA[3]) + _.sum(statA[4]) + _.sum(statA[5]);
        this.statisticsTrend.statisticsAfterTag.totalHighSeverityLogs = _.sum(statA[0]) + _.sum(statA[1]) + _.sum(statA[2]);
        this.statisticsTrend.statisticsAfterTag.totalCriticalSeverityLogs = _.sum(statA[0]);
        this.statisticsTrend.statisticsAfterTag.totalErrorSeverityLogs = _.sum(statA[1]);
        this.statisticsTrend.statisticsAfterTag.totalWarningSeverityLogs = _.sum(statA[2]);

        // console.log(this.statisticsTrend);

        // let data = [
        //   {
        //     color: 'red',
        //     size: this.statisticsTrend.percentB * 100,
        //     name: 'before',
        //   },
        //   {
        //     color: 'gray',
        //     size: (1 - this.statisticsTrend.percentB) * 100,
        //     name: 'none',
        //   }
        //
        // ];
      });
  }
  private getInterval(startDate: number, endDate: number): number {
    const diff = (endDate - startDate) / 1000;
    if (diff < 3600 * 12) {
      return 60 * 1000;
    }
    return 3600 * 1000;
  }
}
