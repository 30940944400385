<div class="container">
  <label class="container__switch" for="checkbox">
    <input type="checkbox" id="checkbox" class="container__input"
           [formControl]="formControl"
           [placeholder]="placeholder"
    />
    <div class="slider round">
      <span class="slider__slide-handler">{{!formControl.value? selectedLabel : notSelectedLabel}}</span>
      <span class="slider__void-space">{{formControl.value? selectedLabel : notSelectedLabel}}</span>
    </div>
  </label>
</div>
