<div class="main-coralogix-select-container">
  <div class="select-label">
    <ng-content class="text" select="[select-label]"></ng-content>
    <cgx-checkbox
      *ngIf="multiple && !disableSelectAll"
      labelPosition="after"
      label="All"
      size="md"
      (change)="toggleSelectAll($event.checked)"
      [checked]="isAllSelected"
    ></cgx-checkbox>
  </div>

  <div class="select-container" [class.icon-before]="hasIconBefore">
    <div class="custom-icon-before" *ngIf="hasIconBefore">
      <ng-content select="[custom-icon-before]"></ng-content>
    </div>
    <ng-select
      [ngStyle]="{ 'background-color': backgroundColor || null }"
      class="coralogix-select-container"
      [class.disabled]="isAllSelected || disabled"
      [disabled]="disabled"
      [dropdownPosition]="dropDownPosition"
      [appendTo]="appendTo"
      [multiple]="multiple"
      [searchable]="searchable"
      [items]="options"
      [bindLabel]="bindLabel"
      [bindValue]="bindValue"
      [(ngModel)]="value"
      (ngModelChange)="onModelChange(value)"
      [placeholder]="placeholder"
      [clearable]="clearable"
      (close)="onClose()"
      [virtualScroll]="shouldLoadVirtually"
      [hideSelected]="hideSelected"
      [addTag]="tagMode"
      [maxSelectedItems]="maximumSelection"
      (focus)="onFocus($event)"
      [groupBy]="groupBy"
    >
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <ng-container *ngIf="!isAllSelected && !hideValue">
          <div [class.custom-icon]="hasColoredIcon" class="ng-value" *ngFor="let item of items">
            <span *ngIf="hasColoredIcon" [ngClass]="item[bindLabel]?.toLowerCase()" class="custom-colored-icon"></span>

            <span class="ng-value-label">{{ bindLabel ? item[bindLabel] : item }}</span>

            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
              ×
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="isAllSelected">
          <div class="all-selected-label">{{ allSelectedLabel }}</div>
        </ng-container>
      </ng-template>

      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div class="ng-value" *ngIf="!hideValue">
          <span *ngIf="hasColoredIcon" [ngClass]="item[bindLabel]?.toLowerCase()" class="custom-colored-icon"></span>

          <span class="ng-value-label">item</span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        <div
          ellipsisDetector
          (isEllipsis)="showOptionTooltip = $event"
          [matTooltipDisabled]="!showOptionTooltip"
          [matTooltip]="bindLabel ? item[bindLabel] : item"
          matTooltipPosition="left"
        >
          <span *ngIf="hasColoredIcon" [ngClass]="item[bindLabel]?.toLowerCase()" class="custom-colored-icon"></span>
          {{ bindLabel ? item[bindLabel] : item }}
          <i
            class="fa fa-question-circle-o"
            [matTooltip]="optionsTooltip[item[bindValue]]"
            matTooltipPosition="right"
            *ngIf="optionsTooltip"
          ></i>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
