import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CoralogixSidebarComponent } from '@shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { Store } from '@ngrx/store';
import { getIsReindexEditorOpen, creationFailedError, State } from '@app/app.reducers';
import { ShDialogService } from '@shared/services/dialogService';
import { Subscription } from 'rxjs';
import { ReindexEditorActions } from '@app/roi/reindex/state/actions/reindex-editor.actions';
import { MetadataActions } from '@shared/state/actions/metadata.actions';
import { FormGroup } from '@angular/forms';
import { ReindexEditorForm } from '@app/roi/reindex/models/reindex-editor-form';
import { ReindexRequest, Estimation } from '@app/roi/reindex/models/reindex.model';
import { ReindexActions } from '@app/roi/reindex/state/actions/reindex.actions';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReindexService } from '../../services/reindex.service';

@Component({
  selector: 'sh-reindex-form-container',
  templateUrl: './reindex-form-container.component.html',
  styleUrls: ['./reindex-form-container.component.scss'],
})
export class ReindexFormContainerComponent implements OnInit, OnDestroy {
  @ViewChild('sidebarComponent', { static: true })
  public sidebar: CoralogixSidebarComponent;
  public form: FormGroup;

  public logsSeverityOptions: { id: number; name: string }[] = [
    { id: 1, name: 'Debug' },
    { id: 2, name: 'Verbose' },
    { id: 3, name: 'Info' },
    { id: 4, name: 'Warning' },
    { id: 5, name: 'Error' },
    { id: 6, name: 'Critical' },
  ];
  private editorSubscription: Subscription;
  private editorCloseSubscription: Subscription;
  private creationFailedSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private reindexEditorForm: ReindexEditorForm,
    private reindexService: ReindexService,
    private dialogService: ShDialogService,
    private dialog: MatDialog,
  ) {
    this.store.dispatch(new MetadataActions.GetApplicationNameAction(null));
    this.store.dispatch(new MetadataActions.GetSubsystemNameAction(null));
  }

  public submit(reindexReq: ReindexRequest): void {
    this.store.dispatch(new ReindexActions.ActivateLoadingAction());
    this.reindexService
      .getReindexSizeEstimation(reindexReq)
      .first()
      .subscribe(
        (res: Estimation) => this.estimationModalDisplay(res, reindexReq),
        (err) => {
          this.store.dispatch(new ReindexActions.ClearLoadingAction());
          if (err.error) {
            if (err.error.includes('exceeded ingesting reindex limit')) {
              this.dialogService.showSnackBar(
                'You currently have a Reindex process in progress. Please wait for it to complete before submitting a new Reindex',
                null,
                5000,
              );
            } else {
              this.dialogService.showSnackBar(err.error, null, 5000);
            }
          } else {
            this.dialogService.showSnackBar('Error getting size estimation. Please try again.', null, 5000);
          }
        },
      );

    this.store.dispatch(new ReindexEditorActions.ToggleEditorAction(false));
  }

  public ngOnInit(): void {
    this.editorSubscription = this.store.select(getIsReindexEditorOpen).subscribe((toggle) => {
      if (toggle === true) {
        this.form = this.reindexEditorForm.buildForm(this.reindexEditorForm.defaultValues);
        this.sidebar.showSidebar();
      } else {
        this.sidebar.hideSidebar();
      }
    });
    this.editorCloseSubscription = this.sidebar.close.subscribe(() => {
      this.store.dispatch(new ReindexEditorActions.ToggleEditorAction(false));
    });

    this.creationFailedSubscription = this.store.select(creationFailedError).subscribe((err) => {
      if (err !== '') {
        this.dialogService.showSnackBar(`Failed to reindex data due to: ${err}`, null, 5000);
      }
    });
  }

  public ngOnDestroy(): void {
    this.editorSubscription.unsubscribe();
    this.editorCloseSubscription.unsubscribe();
    this.store.dispatch(new ReindexEditorActions.ToggleEditorAction(false));
    this.creationFailedSubscription.unsubscribe();
  }

  private estimationModalDisplay(res: Estimation, reindexReq: ReindexRequest): void {
    const { estimation } = res;
    if (!res || estimation === null || estimation === undefined) {
      this.dialogService.showSnackBar('Error estimating the reindex size. Please try again.', null, 5000);
    } else {
      const sizeEstimation = estimation === 0 ? '~0' : estimation;
      this.openEstimationDialog(sizeEstimation, reindexReq);
    }

    this.store.dispatch(new ReindexActions.ClearLoadingAction());
  }

  private openEstimationDialog(sizeEstimation: any, reindexReq: ReindexRequest): void {
    const data: ICoralogixDialogData = {
      title: 'This will affect your daily quota',
      message:
        `This reindexing action will decrease your daily quota by an estimated ${sizeEstimation}MB. Click “confirm” ` +
        `to reindex your data.`,
      yesButtonText: 'CONFIRM',
      noButtonText: 'CANCEL',
    };
    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(new ReindexActions.CreateReindexAction(reindexReq));
      }
    });
  }
}
