import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlAutocompleteComponent } from './url-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchHighlightModule } from '@app/shared/pipes/search-highlight/search-highlight.module';

@NgModule({
  declarations: [UrlAutocompleteComponent],
  imports: [CommonModule, MatAutocompleteModule, ReactiveFormsModule, ScrollingModule, SearchHighlightModule],
  exports: [UrlAutocompleteComponent],
})
export class UrlAutocompleteModule {}
