import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';

@Injectable()
export class ZipService {
  public zipFiles(strData: string, fileName: string, fileType: string) {
    const zip = new JSZip();

    zip.file(`${fileName}.${fileType}`, strData);

    return zip
      .generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
      })
      .then(content => {
        // see FileSaver.js
        FileSaver.saveAs(content, `${fileName}.${fileType}.zip`);
        return Observable.from([]);
      });
  }
}
