<div class="anomaly-view" *ngIf="insightsProvider.currentInsight && isFlowAnomaly(insightsProvider.currentInsight)">
  <div class="flow-anomaly-title-container">
    <span style="font-size: large">A flow anomaly was detected between</span>
    <span style="font-size: large;font-weight: 500; white-space: pre;">{{ timeStamp }}.</span>
    <span style="font-size: large">The following</span>
    <span style="font-size: large;font-weight: 500">
      {{ totalTemplateCount }}
    </span>
    <span style="font-size: large">
      log templates usually arrive together at a certain ratio per minute, this ratio was broken by
    </span>
    <span style="font-size: large;font-weight: 500">
      {{ anamolyTemplateCount }}
    </span>
    <span style="font-size: large">of the templates.</span>
  </div>
  <mat-card>
    <div [hidden]="!chart._isAnomalyGraphSupported">
      <div class="templatechart-container" [hidden]="!insightsProvider.globalState.showTimeline">
        <sh-templates-charts
          [anomaly]="this.insightsProvider.currentInsight"
          #chart
          [selectedTemplate]="this.insightsProvider.globalState.selectedLog"
        ></sh-templates-charts>
      </div>
    </div>
    <sh-flow-anomaly [Anomaly]="insightsProvider.currentAnomaly"></sh-flow-anomaly>
  </mat-card>
</div>
<div class="anomaly-view" *ngIf="insightsProvider.currentInsight && isVolumeAnomaly(insightsProvider.currentInsight)">
  <mat-card>
    <sh-volume-anomaly [Anomaly]="insightsProvider.currentAnomaly"></sh-volume-anomaly>
  </mat-card>
</div>
<div class="top-errors-view">
  <div class="flow-anomaly-title-container">
    <span class="flow-anomaly-tilte">
      Suspected Errors
    </span>

    <i
      class="fa fa-info-circle info-icon"
      aria-hidden="true"
      matTooltipPosition="above"
      matTooltip="The following high severity logs (warning, error & critical logs) arrived in a higher ratio than normal during the time of the flow breakage, we suspect that they might be related to it."
    ></i>
  </div>

  <mat-card>
    <div>
      <div class="ui active inverted dimmer" *ngIf="!anomalyViewReady">
        <div class="ui loader"></div>
      </div>
      <sh-suspected-template
        [theme]="theme"
        *ngIf="anomolusQueryModel"
        (isReady)="OnIsAnomalyTemplatesReady($event)"
        [maxResult]="5"
        [queryModel]="anomolusQueryModel"
      ></sh-suspected-template>
    </div>
  </mat-card>
</div>
<div class="top-errors-view">
  <div class="flow-anomaly-title-container">
    <span class="flow-anomaly-tilte">Top Error Logs</span>
    <i
      class="fa fa-info-circle info-icon"
      aria-hidden="true"
      matTooltipPosition="above"
      matTooltip="Most frequent errors (warning, error & critical logs) which arrived during the anomaly timeframe."
    ></i>
  </div>

  <mat-card>
    <sh-common-errors-widget
      [theme]="theme"
      (isReady)="OnIsTopErrorsTemplatesReady($event)"
      *ngIf="topErrorQueryModel"
      [maxResult]="5"
      [queryModel]="topErrorQueryModel"
    ></sh-common-errors-widget>
  </mat-card>
</div>
<div class="new-logs-view">
  <div class="flow-anomaly-title-container">
    <span
      *ngIf="insightsProvider.currentInsight && isVolumeAnomaly(insightsProvider.currentInsight); else volumeAnomaly"
      class="flow-anomaly-tilte"
    >
      {{ isGridOverlayVisible ? '' : (newLogsCount | numbersFormat) }} Newly introduced Errors
    </span>
    <ng-template #volumeAnomaly>
      <span class="flow-anomaly-tilte">{{ isGridOverlayVisible ? '' : (newLogsCount | numbersFormat) }} Newly introduced Logs</span>
    </ng-template>
    <i
      class="fa fa-info-circle info-icon"
      aria-hidden="true"
      matTooltipPosition="above"
      matTooltip="The following log entries arrived for the first time during the flow breakage time and might be related to it."
    ></i>
  </div>
  <mat-card>
    <sh-grid
      style="width: 100%;height: 550px"
      [isOverlayVisible]="false"
      [logsQueryData]="queryData"
      (gridRowSelectedEvent)="onGridRowSelected($event)"
      (textInfoClicked)="onGridButtonClick($event)"
      [showGridFilters]="true"
    ></sh-grid>
  </mat-card>
</div>
