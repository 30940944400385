<div>
  <form class="selector-wrapper" style="width: 500px;" [formGroup]="filtersForm">
    <div class="coralogix input-group">
      <sh-coralogix-select
        [selectOptions]="applicationNameOptions"
        [multiple]="true"
        [searchable]="true"
        [placeholder]="'All applications'"
        [disableSelectAll]="true"
        formControlName="applicationName">
        <label select-label>
          <span class="text">Applications</span>
        </label>
      </sh-coralogix-select>
    </div>

    <div class="coralogix input-group">
      <sh-coralogix-select
        [selectOptions]="subsystemNameOptions"
        [multiple]="true"
        [searchable]="true"
        [placeholder]="'All subsystems'"
        [disableSelectAll]="true"
        formControlName="subsystemName"
      >
        <label select-label>
          <span class="text">Subsystems</span>
        </label>
      </sh-coralogix-select>
    </div>
  </form>
</div>
