import { Injectable } from '@angular/core';
import { Constants } from '../../../constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HerokuService {
  private url: string = Constants.GetUrl() + Constants.HerokuUrl;

  constructor(private http: HttpClient) {}

  public isHerokuBlock(): Observable<any> {
    return this.http
      .get(this.url + '/check-plan')
      .map((res: any) => res)
      .catch(this.handleError);
  }

  public requiredPlan(): Observable<any> {
    return this.http
      .get(this.url + '/required-plan')
      .map((res: any) => res)
      .catch(this.handleError);
  }

  private handleError(error: any) {
    console.log(error);
    return Observable.throw(error);
  }
}
