import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class PaymentPlan {
  @jsonMember
  public amount: number;
  @jsonMember
  public created: number;
  @jsonMember
  public currency: string;
  @jsonMember
  public id: string;
  @jsonMember
  public interval: string;
  @jsonMember
  public interval_count: number;
  @jsonMember
  public livemode: boolean;
  @jsonMember(TypedJsonAny.OPTIONS)
  public metadata: any;
  @jsonMember
  public name: string;
  @jsonMember
  public trial_period_days: number;
  public getVolume(): number {
    if (this.id) {
      const splitedId = this.id.split('_');
      const isDaily = this.id.includes('enterprise');
      return isDaily ? (parseInt(splitedId[0], 10) * 30) : parseInt(splitedId[0], 10);
    }
    return null;
  }
  public getRetantion(): number {
    if (this.id) {
      const splitedId = this.id.split('_');
      return parseInt(splitedId[1], 10);
    }
    return null;
  }
}

@jsonObject
export class PaymentPlanResponse {
  @jsonArrayMember(PaymentPlan)
  public data: PaymentPlan[] = [];
  @jsonMember
  public has_more: boolean;
}
