import { environment } from '@environments/environment';
import { default as globalConfig } from '../assets/configuration.json';
import { ConfigMemberType, EConfigEnum, IConfig } from '@app/global-config/globalConfig';
import { IRuleFieldOptionValue } from '@app/rules/shared/models/parsing-theme-editor.models';

export class Constants {
  public static mode: string = environment.RUN_MODE || 'PROD';

  public static getConfigurationProp<T extends unknown>(prop: ConfigMemberType): T {
    const config = localStorage.getItem('config');
    try {
      const configuration: IConfig = config ? JSON.parse(config) : globalConfig;
      return (configuration[prop] || '') as T;
    } catch (e) {
      return (globalConfig[prop] || '') as T;
    }
  }

  public static GetBillFlowEmbedKey(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.billFlowEmbedKey);
  }

  public static GetRecaptchaV3SiteKey(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.recaptchaV3SiteKey);
  }

  public static GetRecaptchaV2SiteKey(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.recaptchaV2SiteKey);
  }

  public static GetUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.url);
  }

  public static GetUrlVersionPrefix(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.apiVersionUrl);
  }

  public static GetRulesApiPrefix(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.rulesApiPrefix);
  }

  static get signupUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.signup);
  }

  public static GetApiUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.apiUrl);
  }

  public static GetSamlApiUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.samlApiUrl);
  }

  public static KibanaUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.kibanaUrl);
  }

  static get livaTailUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.livaTailUrl);
  }

  static get signupRedirectURL(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.signupRedirectURL);
  }
  static get getStripeKey(): string {
    return Constants.getConfigurationProp(EConfigEnum.stripeKey);
  }

  static get getAuthGuardRedirectUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.authGuardRedirectUrl);
  }

  static get getDefaultHost(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.defaultHost);
  }

  static get getTeamRedirectUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.teamRedirectUrl);
  }

  static get getTeamSamlRedirectUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.teamSamlRedirectUrl);
  }

  static get getAlertRedirectUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.alertRedirectUrl);
  }

  static get getUserLoginRedirectUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.userLoginRedirectUrl);
  }

  static get getIconsUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.iconsUrl);
  }

  static get getUrlEnding(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.urlEnding);
  }

  static get getAppUrlEnding(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.appUrlEnding);
  }

  static get proxyLoginRedirectUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.proxyLoginRedirect);
  }

  public static metadataFields(): string[] {
    return Constants.getConfigurationProp<string[]>(EConfigEnum.metadataFields) || [];
  }

  public static getIndexFieldsUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.indexFieldsUrl);
  }

  public static getExtendedTemplateIndexFieldsUrl(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.extendedTemplateIndexFields) || Constants.getIndexFieldsUrl();
  }

  public static getMetadataFields(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.metadataFieldsUrl);
  }

  public static getTextFieldName(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.textFieldName);
  }

  public static getRulesOptionFields(): IRuleFieldOptionValue[] {
    return Constants.getConfigurationProp<IRuleFieldOptionValue[]>(EConfigEnum.rulesFieldOptions) || [];
  }

  public static getDefaultMetaFieldOptionValue(): string {
    return Constants.getConfigurationProp<string>(EConfigEnum.defaultMetaFieldOptionValue);
  }

  public static getCompanyUrl: string = Constants.GetUrl() + '/api/v1/company';
  public static paymentPlansUrl: string = Constants.GetUrl() + '/api/v1/payment/plans';
  public static paymentUrl: string = Constants.GetUrl() + '/api/v1/payment';
  public static paymentCustomerUrl: string = Constants.GetUrl() + '/api/v1/payment/customer';
  public static queryUrl: string = '/api/v1/logquery';
  public static queryValidationUrl: string = '/api/v1/logquery/validate';
  public static queryStatsUrl: string = '/api/v1/logquery/stats';
  public static savedQueryUrl: string = '/api/v1/logquery/saved';
  public static alertUrl: string = '/api/v1/alert';
  public static cloudSecuritySetMapping: string = Constants.GetUrl() + '/api/v1/cloud-security/mapping';
  public static cloudSecurityInstallUrl: string = '/api/v1/cloud-security/install';
  public static cloudSecurityGetStatusUrl: string = '/api/v1/cloud-security/get-status';
  public static cloudSecurityGetInstallationIdUrl: string = '/api/v1/cloud-security/get-installation-id-by-company';
  public static cloudSecurityGetDeploymentPackageUrl: string = '/api/v1/cloud-security/get-deployment-package-by-company';
  public static cloudSecurityRemoveInstallationInfoUrl: string = '/api/v1/cloud-security/remove-installation-info';
  public static cloudSecurityGetInstallationsHistoryUrl: string = '/api/v1/cloud-security/get-installation-alertHistory';
  public static cloudSecurityGetInstanceTypeToBeUsedUrl: string = '/api/v1/cloud-security/get-instance-type';
  public static cloudSecurityValicateAwsCredsUrl: string = '/api/v1//cloud-security/validate-aws-creds';
  public static changePasswordUrl: string = '/api/v1/user/changepassword';
  public static changeUserPasswordUrl: string = '/api/v1/user/changeuserpassword';
  public static validatePasswordUrl: string = '/api/v1/user/validatepassword';
  public static updateNewTeammateMemberDetailsUrl: string = '/api/v1/user/update-new-teammate-details';
  public static archivingProvider: string = '/api/v1/archiveproviders';
  public static integrationsUrl: string = '/api/v1/integrations';
  public static integrationTypesUrl: string = '/api/v1/integration_types';

  public static dailyUsageUrl: string = '/api/v1/metering/dailyusage';
  public static monthlyUsageUrl: string = '/api/v1/metering/monthlyusage';
  public static loggregationUrl: string = '/api/v1/loggregation';
  public static loggregationPaginatedUrl: string = '/api/v1/loggregation-paginated';
  public static placeholderUrl: string = Constants.GetUrl() + '/api/v1/placeholder';
  public static statisticsUrl: string = Constants.GetUrl() + '/api/v1/statistics';
  public static companyNewMetricsMetricFieldsUrl: string = Constants.GetUrl() + '/api/v1/company/newMetrics/metricFields';
  public static companyNewMetricsMetricLabelsUrl: string = Constants.GetUrl() + '/api/v1/company/newMetrics/metricLabels';
  /**
   * Insights
   */
  public static insightsUrl: string = Constants.GetUrl() + '/api/v1/event';
  public static anomalyUrl: string = Constants.GetUrl() + '/api/v1/anomaly';
  public static logsparserUrl: string = Constants.GetUrl() + '/api/v1/logsparser';
  public static rulesUrl: string = Constants.logsparserUrl + '/rules';
  public static parsingThemesUrl: string = Constants.GetUrl() + '/api/v1/parsing-themes';
  public static ruleSetCrudUrl: string =
    Constants.GetUrl() + Constants.GetUrlVersionPrefix() + Constants.GetRulesApiPrefix() + '/crud/rule-set';
  public static ruleSetUrl: string = Constants.GetUrl() + Constants.GetUrlVersionPrefix() + Constants.GetRulesApiPrefix() + '/rule-set';
  public static ruleSetTestUrl: string =
    Constants.GetUrl() + Constants.GetUrlVersionPrefix() + Constants.GetRulesApiPrefix() + '/rule_tester';
  public static groksUrl: string = Constants.logsparserUrl + '/groks';
  public static grokDefsUrl: string = Constants.logsparserUrl + '/grokdefs';
  public static inviteUrl: string = Constants.GetUrl() + '/api/v1/invite';
  public static inviteRequestUrl: string = Constants.GetUrl() + '/api/v1/invite/request';
  public static userUrl: string = Constants.GetUrl() + '/api/v1/user';
  public static teamUrl: string = Constants.GetUrl() + '/api/v1/user/team';
  public static groupRbacUrl: string = Constants.GetUrl() + '/api/v1/rbac';
  public static savedViewsUrl: string = Constants.GetUrl() + '/api/v1/user/savedViews';
  public static teammates: string = Constants.teamUrl + '/teammates';
  public static companyPlanDetailsUrl: string = Constants.GetUrl() + '/api/v1/company/plan';
  public static companyLogoUrl: string = Constants.GetUrl() + '/api/v1/company/logo';
  public static companyUrl: string = Constants.GetUrl() + '/api/v1/company/plan/status';
  public static companySnoozeUrl: string = Constants.GetUrl() + '/api/v1/company/plan/snooze';
  public static companyQueryRangeUrl: string = Constants.GetUrl() + '/api/v1/company/plan/queryRange';
  public static companyIndexFieldsUrl: string = Constants.GetUrl() + '/api/v1/company/indexFields';
  public static companyRedisTemplateIndexFieldsUrl: string = Constants.getIndexFieldsUrl();
  public static companyRedisExtendedTemplateIndexFieldsUrl: string = Constants.getExtendedTemplateIndexFieldsUrl();
  public static companySamlConfigurationUrl: string = Constants.GetUrl() + '/api/v1/company/saml';
  public static enrichmentsUrl: string = Constants.GetUrl() + '/api/v1/enrichments';
  public static customEnrichmentsUrl: string = Constants.GetUrl() + '/api/v1/custom-enrichments';
  public static volumeAnomlayWidget: string = Constants.GetUrl() + '/api/v1/volume-anomaly/widgets';
  public static volumeAnomlayGraph: string = Constants.GetUrl() + '/api/v1/volume-anomaly/graph';
  public static blockedUsage: string = Constants.GetUrl() + '/api/v1/usage-metrics/blocked';
  public static quotaMgmtUrl: string = Constants.GetUrl() + '/api/v1/quota';
  public static metricsUrl: string = Constants.GetUrl() + '/api/v1/es-metric';
  public static getMetricsUrl: string = Constants.metricsUrl + '/all';
  public static reindexUrl: string = Constants.GetUrl() + '/api/v1/reindexes';
  public static getCompanyGroupsUrl = (companyId: number) => `${Constants.GetUrl()}/api/v1/companies/${companyId}/rbac/groups`;
  public static archiveQueriesUrl: string = Constants.GetUrl() + '/api/v1/archive-queries';
  public static metricAlertGraph: string = Constants.GetUrl() + '/api/v1/metric-alert/query';
  public static promqlMetricAlertGraph: string = Constants.GetUrl() + '/api/v1/metric-alert/query/promql';
  /**
   * SAML Api
   */
  public static samlSpMetadataUrl: string = Constants.GetSamlApiUrl() + '/saml/metadata.xml';
  public static samlSpAssersionUrl: string = Constants.GetSamlApiUrl() + '/saml/assertion';
  public static samlSsoLoginUrl: string = Constants.GetSamlApiUrl() + '/saml/ssoLogin';

  public static HerokuUrl: string = '/api/v1/heroku';

  public static DEFAULT_INSIGHTS_PAGE_SIZE: number = 500;
  // last event time frame
  public static INSIGHTS_TIME_FRAME: number = 1000 * 60 * 60 * 24 * 14;
  public static ALERTS_EVENTS_TIME_FRAMS: number = 1000 * 3600 * 24;
  public static QUERY_PAGE_SIZE: number = 200;
  public static LOGS_QUERY_PAGE_SIZE: number = 100;
  public static MAX_GRID_LOGS = 15_000;
  public static MARKED_LOG_COLOR: string = '#22354c';
  public static MARKED_LOGS_MAX_SIZE: number = 200;
  public static TAGS_MAX_SIZE: number = 300;

  /**
   * Quota Mgmt
   */
  public static QUERY_USAGE_DAYS: number = 7;
  public static REFRESH_USAGE_QUERY_DURATION_MIN: number = 5;
  public static QUOTA_USAGE_INFO_PAGE_SIZE: number = 10;
  public static GIGA_BYTE: number = Math.pow(1024, 3);
  public static MEDIUM_PRIORITY_SAVED_PERCENTAGE: number = 60;
  public static LOW_PRIORITY_SAVED_PERCENTAGE: number = 85;

  /**
   * Recent Queries
   */
  public static LOCAL_STORAGE_KEY: string = 'recent_queries';
  public static LS_KEY_RECENT_QUERIES: string = 'recent_queries';
  public static SEARCH_VISIBLE_QUERIES_AMOUNT: number = 5;
  public static CACHE_MAX_SIZE: number = 30;

  /**
   * Coralogix Domains
   */
  public static CORALOGIX_IRELAND: string = 'coralogix.com';
  public static CORALOGIX_INDIA: string = 'coralogix.in';
  public static CORALOGIX_US: string = 'coralogix.us';

  /**
   * API Endpoints
   */
  public static API_ENDPOINT_HOSTNAME_IRELAND: string = 'api.' + Constants.CORALOGIX_IRELAND;
  public static API_ENDPOINT_HOSTNAME_MUMBAI: string = 'api.app.' + Constants.CORALOGIX_INDIA;
  public static API_ENDPOINT_HOSTNAME_US: string = 'api.' + Constants.CORALOGIX_US;
  /**
   * Metrics
   */
  public static DEFAULT_METRIC_PERMUTATIONS_LIMIT = 30_000;
  public static DEFAULT_GLOBAL_PERMUTATIONS_LIMIT = 1_000_000;

  /**
   * Alerts
   */
  public static DEFAULT_METRIC_ALERT_GROUP_BY_LIMIT = 2;
  public static DEFAULT_ALERT_GROUP_BY_LIMIT = 2;

  /**
   * Archive Query
   */
  public static PRESTO = 'Presto';
  public static LUCENE = 'Lucene';
  public static SQL = 'SQL';

  /*
    Fields
   */
  public static SEVERITY_FIELD = 'coralogix.metadata.severity';

  /*
    Sentry
  */
  public static SENTRY_DSN = 'https://d172f4c743e84a3b9ce8631b2c76d868@o949668.ingest.sentry.io/5930461';
}
