<form [formGroup]="detailsForm" fxLayout="column" fxLayoutGap="25px">
  <sh-basic-input
    [formControl]="$any(detailsForm.controls?.name)"
    label="Rule Group Name"
    [isRequired]="true"
    placeholder="Enter a name"
    errorMsg="Group name is required"
  ></sh-basic-input>
  <sh-basic-input
    [formControl]="$any(detailsForm.controls?.description)"
    label="Rule Group Description"
    [isRequired]="false"
    placeholder="Enter a description"
  ></sh-basic-input>
</form>

