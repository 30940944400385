import { Component, Input } from '@angular/core';
import { LivetailStatisticsManager } from '../shared/services/livetail.statistics.manager';
import { Router } from '@angular/router';
import { ChartClickParams } from '@shared/controls/charts/models/chart.model';
import { LivetailProvider } from '../shared/services/livetail.provider';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';

@Component({
  selector: 'sh-livetail-stats',
  templateUrl: './livetail-stats.component.html',
  styleUrls: ['./livetail-stats.component.scss'],
})
export class LivetailStatsComponent {
  @Input() public clickableChart: boolean = true;
  @Input() public manager: LivetailStatisticsManager;

  constructor(
    private router: Router,
    private htmlRowMeasure: HtmlRowMeasure,
    private livetailProvider: LivetailProvider,
  ) {}

  public chartClicked(e: ChartClickParams): void {
    if (!this.clickableChart) {
      return;
    }

    if (e) {
      const bulk = this.manager.bulks[e.index];
      if (bulk) {
        const specificBulk = bulk[e.name.toUpperCase()];

        if (specificBulk) {
          this.htmlRowMeasure.prepareRowsHeights(specificBulk ? specificBulk : []).first().subscribe(res => {
            this.livetailProvider.livetailGridState.data = res;
            this.livetailProvider.livetailGridState.itemsCount = this.livetailProvider.livetailGridState.data.length;
            this.livetailProvider.livetailGridState.selectedIndex = 0;
            this.livetailProvider.livetailGridState.selectedItem = null;
            this.livetailProvider.showBanner = false;
            this.router.navigate(['/livetail']);
          });
        }
      }
    }
  }
}
