import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { TypedJSON } from 'typedjson';
import { ActivateUserModel, ActivateUserModelRequest, CompanyRequest } from '../models/activateUser.model';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { Team } from '@app/user/shared/models/team';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getSelectedTeamId, getTeams, State } from '@app/app.reducers';
import { Subscription } from 'rxjs/Subscription';
import { User } from '../models/user.model';
import { LoginResult } from '../models/loginResult.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorTypes, IRedirectData, RefreshTokenResponse } from '@app/auth/shared/models/auth.models';
import { throwError } from 'rxjs';
import { ConfigurationService } from '@app/services/configuration.service';
import { of } from 'rxjs/observable/of';
import { EConfigEnum } from '@app/global-config/globalConfig';

export interface IForgotPasswordRequest {
  email: string;
  token: string;
  version: string;
}
interface ISpecialError {
  [key: string]: { key: string; errHandler: () => void };
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  get userName(): string {
    return localStorage.getItem('userName');
  }
  SPECIAL_ERRORS: ISpecialError = {
    [ErrorTypes.TokenNotFound]: { key: ErrorTypes.TokenNotFound as string, errHandler: () => this.forceLogout() },
    [ErrorTypes.Unauthorized]: { key: ErrorTypes.Unauthorized as string, errHandler: () => this.forceLogout() },
  };
  redirectTo: string;
  teams$Subscription: Subscription;
  teams: Team[];
  selectedTeamId$: Observable<number>;
  private useType: string;
  private token: string;
  private loggedInUser: User;
  private teamId: number;
  constructor(
    private http: HttpClient,
    private ae: AnalyticEventService,
    private router: Router,
    private store: Store<State>,
    private configService: ConfigurationService,
    private route: ActivatedRoute,
  ) {
    this.teams$Subscription = this.store.select(getTeams).subscribe(teams => (this.teams = teams));
    this.selectedTeamId$ = this.store.select(getSelectedTeamId);
    this.selectedTeamId$.subscribe(teamId => {
      this.teamId = teamId;
    });
  }
  getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('token');
    }
    return this.token;
  }

  getBearerToken(): string {
    return localStorage.getItem('t_id')
      ? `Bearer ${localStorage.getItem('token')}/${localStorage.getItem('t_id')}`
      : `Bearer ${localStorage.getItem('token')}`;
  }

  getSelectedTeamId(): number {
    return parseInt(localStorage.getItem('t_id'), 10);
  }

  selectTeamId(team_id: string): void {
    localStorage.setItem('t_id', team_id);
    document.cookie = 'coralogix_token=' + this.getToken() + ';expires=Fri, 31 Dec 9999 23:59:59 GMT;';
    document.cookie = 'coralogix_t_id=' + team_id + ';expires=Fri, 31 Dec 9999 23:59:59 GMT;';
  }

  selectTeam(team: Team): void {
    this.selectTeamId(team.id.toString());
  }

  getTempTokenNRedirectData(teamId: number, isNewTeam: boolean): Observable<IRedirectData> {
    if (this.teams) {
      const team = this.teams.find(t => t.id === teamId);
      if (team) {
        const isNewTeamRoute = isNewTeam ? 'true' : 'false';
        const userName = localStorage.getItem('userName') || '';
        this.ae.dataLayerReport({ event: 'login' });
        return this.getTempToken(team.team_url, team.id.toString(), userName.toString())
          .catch(this.handleError)
          .pipe(
            map(
              token =>
                ({
                  token,
                  teamId,
                  userName,
                  isNewTeamRoute,
                  teamUrl: team.team_url,
                } as IRedirectData),
            ),
          );
      } else {
        return this.handleError('No Team');
      }
    } else {
      return throwError('No Teams');
    }
  }

  getSamlSsoLoginUrl(): any {
    const samlSsoLoginUrl = this.configService.getConfigValue<string>(EConfigEnum.samlApiUrl, '') + '/saml/ssoLogin';
    return this.http.get(samlSsoLoginUrl, { responseType: 'text' }).catch(err => {
      setTimeout(() => {
        window.location.href = window.location.origin + '/#/login/user';
      }, 10);
      return this.handleError(err);
    });
  }

  handleSpecialErr(key: ErrorTypes): void {
    this.SPECIAL_ERRORS[key].errHandler();
  }

  getUserType(): any {
    if (!this.useType) {
      this.useType = localStorage.getItem('userType');
    }

    return this.useType;
  }

  signupNewUser(user: { firstName: string; lastName: string; email: string; password: string; aws_token: string }): Observable<any> {
    const signup_url = this.configService.getConfigURL('/api/v1/user/signup');
    return this.http
      .post(signup_url, JSON.stringify(user))
      .do(() => this.ae.event({ eventName: 'signupNewUser' }))
      .catch(this.handleError);
  }

  validateUserCode(validationData: { userData: object; code: string }): Observable<any> {
    const validationUrl = this.configService.getConfigURL('/api/v1/user/validate');
    return this.http
      .put(validationUrl, JSON.stringify(validationData))
      .do(() => this.ae.event({ eventName: 'userActivated' }))
      .map(res => this.extractData(res))
      .map(loginRes => this.onUserLogedIn(loginRes))
      .catch(this.handleError);
  }

  activateUser(activateUserModel: ActivateUserModelRequest): Observable<any> {
    const userUrl = this.configService.getConfigURL('/api/v1/user');
    const observable = this.http
      .post(userUrl, JSON.stringify(activateUserModel))
      .do(() => this.ae.event({ eventName: 'userActivated' }))
      .catch(this.handleError);

    return observable;
  }

  getCompany(): Observable<CompanyRequest> {
    const urlCompany = this.configService.getConfigURL('/api/v1/company');
    const obs = this.http
      .get(urlCompany)
      .map(this.extractCompany)
      .catch(error => this.handleError(error));
    return obs;
  }

  enforceUserPermission(resourceId: number, actionId: number): Observable<{ hasPermission: boolean }> {
    const url = this.configService.getConfigURL(`/api/v1/user/enforce?resourceId=${resourceId}&actionId=${actionId}`);
    return this.http.get<boolean>(url).pipe(
      map(res => ({ hasPermission: res })),
      catchError(() => {
        return of({ hasPermission: false });
      }),
    );
  }

  getUserTeams(): Observable<CompanyRequest[]> {
    const userUrl = this.configService.getConfigURL('/api/v1/user');
    return this.http.get(userUrl + '/teams').map((res: any) => {
      return res;
    });
  }

  extractCompany(res: any): CompanyRequest {
    return res;
  }

  isKeyValid(key: string): Observable<ActivateUserModel> {
    const signup_url = this.configService.getConfigURL('/api/v1/user/signup');
    const obs = this.http
      .get(signup_url + '/' + key)
      .map(this.extractIsKeyValid)
      .map(res => {
        return res;
      })
      .catch(error => this.handleError(error));
    return obs;
  }

  forgotPassword(body: IForgotPasswordRequest): Observable<any> {
    const headers = this.createHeadersWithTesttoken();
    const url = this.configService.getConfigURL('/api/v1/user/forgotpassword');
    return this.http.post(url, body, { headers, responseType: 'text' }).catch(this.handleError);
  }

  resetPassword(body: { password: string }, key: string): Observable<User> {
    const url = this.configService.getConfigURL(`/api/v1/user/resetpassword/${key}`);
    return this.http.post(url, body).pipe(
      tap(() => {
        this.ae.event({ eventName: 'userLoggedin' });
      }),
      map(res => this.extractData(res)),
      map(loginRes => this.onUserLogedIn(loginRes)),
      catchError(this.handleError),
    );
  }

  login(credentials: { username: string; password: string; token?: string; version?: string }): Observable<User> {
    const headers = this.createHeadersWithTesttoken();
    const url = this.configService.getConfigURL('/api/v1/user/login');
    return this.http.post(url, credentials, { headers }).pipe(
      tap(() => {
        this.ae.event({ eventName: 'userLoggedin' });
      }),
      map(res => this.extractData(res)),
      map(loginRes => this.onUserLogedIn(loginRes)),
      catchError(this.handleError),
    );
  }

  forceLogout(): void {
    const getUserLoginRedirectUrl = this.configService.getConfigValue(EConfigEnum.userLoginRedirectUrl, '');
    this.logout()
      .take(1)
      .finally(() => {
        const userRedirectUrl = getUserLoginRedirectUrl.replace('%s', window.location.host.split('.')[0]);
        this.reloadApp(this.teamId, userRedirectUrl);
      })
      .subscribe();
  }

  deleteUserToken(): Observable<any> {
    const logout_url = this.configService.getConfigURL('/api/v1/user/logout');
    return this.http.delete(logout_url, { responseType: 'text' }).catch(this.handleError);
  }

  isLoggedIn(): boolean {
    return this.getToken() != null;
  }
  logout(): Observable<any> {
    this.clearUserData();
    return this.deleteUserToken().pipe(
      switchMap(res => {
        this.clearUserLocalStorageData();
        return res;
      }),
    );
  }

  storeUser(token: string, userName: string, userType?: string): void {
    this.token = token;
    this.useType = userType || 'coralogix';
    localStorage.setItem('token', token);
    localStorage.setItem('userName', userName);

    localStorage.setItem('userType', this.useType);
    this.ae.update({
      email: userName,
      userType: this.useType,
    });
  }

  setUserName(userName: string): void {
    localStorage.setItem('userName', userName);
  }

  reloadApp(teamId: number, redirectURL: string = this.configService.getConfigValue(EConfigEnum.authGuardRedirectUrl, '')): void {
    if (teamId) {
      location.href = redirectURL;
    } else {
      location.reload();
    }
  }

  getPermanentTokenFromTemp(body: object): Observable<any> {
    const tempTokenUrl = this.configService.getConfigURL('/api/v1/user/auth');
    return this.http
      .post(tempTokenUrl, body)
      .map((res: RefreshTokenResponse) => res.token)
      .catch(error => {
        const teamUrl = Object(body)['teamUrl'];
        if (teamUrl) {
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 10);
        }
        return this.handleError(error);
      });
  }

  getTempToken(teamUrl: string, id: string, userName: string): Observable<any> {
    const teamTempTokenUrl = this.configService.getConfigURL('/api/v1/user/team/switch');
    const body = {
      teamUrl,
      id,
      userName,
    };
    return this.http
      .post(teamTempTokenUrl, body)
      .map((res: RefreshTokenResponse) => res.token)
      .catch(this.handleError);
  }

  private clearUserLocalStorageData(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('t_id');
    localStorage.removeItem('userName');
    localStorage.removeItem('userType');
  }

  private clearUserData(): void {
    this.token = null;
    this.useType = null;
    this.teams = [];
    document.cookie = 'coralogix_token' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = 'coralogix_t_id' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }

  private extractIsKeyValid(res: any): ActivateUserModel {
    return TypedJSON.parse(res, ActivateUserModel);
  }

  private onUserLogedIn(loginRes: LoginResult): User {
    this.loggedInUser = loginRes.user;
    this.storeUser(loginRes.token, loginRes.user.username);
    // save token
    return this.loggedInUser;
  }

  private extractData(res: any): LoginResult {
    return TypedJSON.parse(res, LoginResult);
  }

  private handleError(error: any): Observable<never> {
    const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return throwError(error);
  }

  private createHeadersWithTesttoken(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders();
    const { testtoken } = this.route.snapshot.queryParams;
    if (testtoken) {
      return headers.append('testtoken', testtoken);
    }
    return headers;
  }
}
