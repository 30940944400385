import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsEditorAggDefComponent } from './metrics-editor-agg-def.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlsModule } from '@app/shared/form-controls/form-controls.module';
import { ConditionalSelectModule } from '@app/shared/components/conditional-select/conditional-select.module';

@NgModule({
  declarations: [MetricsEditorAggDefComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormControlsModule, ConditionalSelectModule],
  exports: [MetricsEditorAggDefComponent],
})
export class MetricsEditorAggDefModule {}
