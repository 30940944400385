<div class="selected-tag-box summary-tiles">
  <div class="selected-tag-box__title selected-tag-box__title-with-sub">Summary</div>
  <div class="selected-tag-box__sub-title">Overall stats compared to the corresponding timeframe in the comparison tag</div>
  <div fxLayout="row wrap" class="summary-tiles__list" *ngIf="(viewState$ | async) === completedViewState">
    <ng-container *ngFor="let summary of summaryViewByType$ | async; let last = last">
      <sh-statistics-tile fxFlex="1 1 0px" class="summary-tiles__tile" [statistic]="summary" (redirectByType)="redirectByType($event)">
      </sh-statistics-tile>
    </ng-container>
  </div>
  <sh-selected-tag-feature-layout [viewState]="viewState$ | async">
  </sh-selected-tag-feature-layout>
</div>
