<div class="user-menu">
  <div class="user-menu-signed-details">
    <div style="display: flex;">
      <div>
        <sh-avatar-initials [name]="username$ | async"></sh-avatar-initials>
      </div>
      <div class="user-menu-signed-text">
        <div class="user-menu-label">SIGNED IN AS</div>
        <div class="user-email" [cgxTooltip]="username$ | async">{{ username$ | async }}</div>
      </div>
    </div>
    <cgx-button
      flat
      textNoWrap
      [label]="themeService.theme === 'dark' ? 'LIGHT MODE' : 'DARK MODE'"
      [srcIconStart]="themeService.theme === 'dark' ? 'assets/icons/sun.svg' : 'assets/icons/moon.svg'"
      (press)="toggleThemeMode()"
    ></cgx-button>
  </div>
  <div class="seperator"></div>
  <div class="user-menu-team-details">
    <div class="user-menu-team-details-header">
      <span class="user-menu-label">Switch to</span>
      <cgx-button flat srcIconStart="assets/icons/add.svg" label="Create New Team" size="sm" (press)="createNewTeam()"></cgx-button>
    </div>
    <div class="search-wrapper">
      <cgx-input-field [ngModel]="search$.value" (ngModelChange)="searchChanged($event)"></cgx-input-field>
    </div>
    <div class="user-menu-label teams-label">TEAMS</div>
    <div>
      <sh-team-item [name]="(selectedTeam$ | async)?.name" [isSigned]="true"></sh-team-item>
    </div>
    <div *ngIf="(teams$ | async | filterPipe: search:'name').length" class="seperator"></div>

    <div class="team-list" *ngIf="(teams$ | async | filterPipe: search:'name').length <= 4">
      <div
        *ngFor="let team of teams$ | async | filterPipe: search:'name'; trackBy: trackByFn"
        class="team-item-wrapper"
        (click)="switchTeam(team)"
      >
        <sh-team-item [name]="team.name" [searchTerm]="search"></sh-team-item>
        <div class="team-item-overlay"></div>
      </div>
    </div>

    <cdk-virtual-scroll-viewport
      *ngIf="(teams$ | async | filterPipe: search:'name').length > 4"
      cdkScrollable
      minBufferPx="800"
      maxBufferPx="1200"
      [itemSize]="50"
      class="team-list-virtual-scroll"
    >
      <div
        *cdkVirtualFor="let team of teams$ | async | filterPipe: search:'name'; trackBy: trackByFn"
        class="team-item-wrapper"
        (click)="switchTeam(team)"
      >
        <sh-team-item [name]="team.name" [searchTerm]="search"></sh-team-item>
        <div class="team-item-overlay"></div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
  <div class="seperator"></div>
  <div class="user-menu-actions" [class.user-menu-actions-read-only]="isReadOnly$ | async">
    <cgx-button
      *ngIf="(isReadOnly$ | async) === false"
      flat
      size="sm"
      srcIconStart="assets/icons/sm-24x24/settings.svg"
      label="Settings"
      color="text-secondary"
      (press)="navToSettings()"
    ></cgx-button>
    <cgx-button
      flat
      size="sm"
      srcIconStart="assets/icons/sm-24x24/logout.svg"
      label="Log Out"
      color="text-secondary"
      (press)="logout()"
    ></cgx-button>
  </div>
</div>
