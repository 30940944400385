import { Component, Input, OnInit } from '@angular/core';
import { AlertHistory } from '@app/alerts/shared/models/alert-history.model';
import { TeamUsersService } from '@app/user/shared/srvices/team-users.service';
import { Teammate } from '@app/user/shared/models/teammate';
import { PromiseHelper } from '@shared/helpers/promise.helper';
import { DateInputType, MomentHistoryDateTime } from '@shared/helpers/moment.helper';
import { Store } from '@ngrx/store';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { TimeZoneType } from '@shared/models/timezone-types';

@Component({
  selector: 'sh-alert-history',
  templateUrl: './alert-history.component.html',
  styleUrls: ['./alert-history.component.scss'],
})
export class AlertHistoryComponent implements OnInit {
  @Input()
  set alertHistory(val: AlertHistory[]) {
    if (val?.length) {
      this.history = [...val].reverse();
      this.lastItemIndex = this.history.length - 1;
    }
  }
  @Input()
  public createDate: number;
  @Input()
  public lastModified: number;
  public initialized: boolean;
  public history: AlertHistory[];
  public lastItemIndex: number;
  public momentUniqDateFormat: string = MomentHistoryDateTime;
  public dateType: DateInputType = DateInputType.epoch;
  public timeZoneSubHeader: string = '';
  private users: Teammate[];
  private currentUser: Teammate;

  constructor(private teamUsersService: TeamUsersService, private store: Store<State>) {
    this.store.select(getTimezoneViewPreference).take(1).subscribe(tmz => {
      this.timeZoneSubHeader = tmz === TimeZoneType.local ? '(Local Time)' : '(UTC)';
    });
  }

  public async ngOnInit(): Promise<void> {
      PromiseHelper.allSettled([this.getTeammatesList(), this.getCurrentUser()]).then((values) => {
       this.users = values[0].status === 'fulfilled' ? values[0].value : [];
       this.currentUser = values[1].status === 'fulfilled' ? values[1].value : null;
       this.initialized = true;
      });
  }

  public get lastHistoryItem(): AlertHistory {
    return this.history[this.lastItemIndex];
  }

  public getUserName(id: string): string {
    if (this.currentUser && this.currentUser.id === id) {
      if (this.currentUser && this.currentUser.firstName.length) {
        return `${this.currentUser.firstName}  ${this.currentUser.lastName}`;
      } else if (this.currentUser && !this.currentUser.firstName.length) {
        return this.currentUser.username;
      }
    }

    if (this.users && this.users.length > 0) {
      const userObj = this.users.find((user) => {
        return user.id === id;
      });

      if (userObj && userObj.firstName.length) {
        return `${userObj.firstName}  ${userObj.lastName}`;
      } else if (userObj && !userObj.firstName.length) {
        return userObj.username;
      }
    }
    return '';
  }

  private async getTeammatesList(): Promise<Teammate[]> {
    return this.teamUsersService.getTeammates().toPromise();
  }

  private async getCurrentUser(): Promise<Teammate> {
    return this.teamUsersService.me().toPromise();
  }
}
