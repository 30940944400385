import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'sh-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogTitleComponent {
}
