import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { GroksService } from '../../services/groks.service';
import { Observable } from 'rxjs/Observable';
import { Action } from '@ngrx/store';
import { GroksActions } from './groks.actions';
import { of } from 'rxjs/observable/of';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class GroksEffects {
  @Effect()
  public detectGroks: Observable<Action> = this.actions$.pipe(
    ofType(GroksActions.ActionTypes.DETECT_GROKS),
    switchMap((action: any) => {
      this.groksService.showGrokLoadingDialog();
      return this.groksService
        .detectGroks(action.payload)
        .map(
          (groksMatches) => new GroksActions.DetectGroksCompleted(groksMatches),
        )
        .catch((err) => of(new GroksActions.DetectGroksFailed(err)));
    }),
  );

  @Effect()
  public addGroks: Observable<Action> = this.actions$.pipe(
    ofType(GroksActions.ActionTypes.ADD_GROKS_TO_TEAM),
    switchMap((action: any) => {
      return this.groksService
        .addGroks(action.payload)
        .map((res) => new GroksActions.AddGroksToTeamCompleted(res))
        .catch((err) => of(new GroksActions.AddGroksToTeamFailed(err)));
    }),
  );
  constructor(private actions$: Actions, private groksService: GroksService) {}
}
