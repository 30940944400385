import { FiltersActions } from './filters.actions';
import { DynamicFilter } from '../../shared/models/dynamic-filters';
export namespace FiltersReducer {
  export interface State {
    data: DynamicFilter[];
    isOverlappedManageButton?: boolean;
  }

  const initialState: State = {
    data: [],
  };

  export function reducer(
    state: State = initialState,
    action: FiltersActions.Actions,
  ): State {
    switch (action.type) {
      case FiltersActions.ActionTypes.GET_DYNAMIC_FILTERS:
        return Object.assign({}, state);
      case FiltersActions.ActionTypes.GET_DYNAMIC_FILTERS_COMPLETED:
        return Object.assign({}, state, { data: action.payload });
      case FiltersActions.ActionTypes.REMOVE_DYNAMIC_FILTERS:
        return Object.assign({}, state, { data: [] });
      case FiltersActions.ActionTypes.GET_MANAGE_BUTTON_OVERLAPPING_STATE:
        return Object.assign({}, state);
      case FiltersActions.ActionTypes.SAVE_MANAGE_BUTTON_OVERLAPPING_STATE:
        return Object.assign({}, state, {
          isOverlappedManageButton: action.payload,
        });
      default:
        return state;
    }
  }
}
