export class PromiseHelper {

  public static allSettled(promises: any[]): Promise<any> {
    const wrappedPromises = promises.map(p => Promise.resolve(p)
      .then(
        val => ({ status: 'fulfilled', value: val }),
        err => ({ status: 'rejected', reason: err })));
    return Promise.all(wrappedPromises);
  }
}
