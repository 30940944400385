import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';
import { ArchiveQueryActions } from '@app/roi/archive-queries/state/actions/archive-query.actions';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { ShDialogService } from '@shared/services/dialogService';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class ArchiveQueriesEffects {

  @Effect()
  public getArchiveQueries$: Observable<Action> = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.GET_ARCHIVE_QUERIES),
    switchMap((action: any) => {
      return this.archiveQueriesService
        .getArchiveQueries(action.payload?.pageIndex, action.payload?.pageSize, action.payload?.filter)
        .map((res) => new ArchiveQueryActions.GetArchiveQueriesCompletedAction(res))
        .catch((err) => of(new ArchiveQueryActions.GetArchiveQueriesFailedAction(err)));
    }),
  );

  @Effect()
  public createReindex$: Observable<Action> = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.CREATE_REINDEX),
    switchMap((action: any) => {
      return this.archiveQueriesService.createArchiveQueryReindex(action?.payload?.id)
        .map((res) => new ArchiveQueryActions.CreateReindexCompletedAction(res))
        .catch( (err) => of(new ArchiveQueryActions.CreateReindexFailedAction(err)));
    })
  );

  @Effect()
  public downloadResults$: Observable<Action> = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.DOWNLOAD_RESULTS),
    switchMap((action: any) => {
      return this.archiveQueriesService.downloadFiles(action.payload)
        .map(() => new ArchiveQueryActions.DownloadResultsCompletedAction())
        .catch( (err) => of(new ArchiveQueryActions.DownloadResultsFailedAction(err)));
    })
  );

  @Effect({ dispatch: false })
  public failedFetchingArchiveQueries$: Observable<any> = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.GET_ARCHIVE_QUERIES_FAILED),
    tap(() => this.dialogService.showCoralogixMessage('Failed to load archive queries', null, dialogServiceIconClasses.failed))
  );

  @Effect({ dispatch: false })
  public failedCreateReindex$: Observable<any> = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.CREATE_REINDEX_FAILED),
    tap(() => this.dialogService.showCoralogixMessage('Failed to create reindex', null, dialogServiceIconClasses.failed))
  );

  @Effect({ dispatch: false })
  public failedDownloadResults$: Observable<any> = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.DOWNLOAD_RESULTS_FAILED),
    tap(() => this.dialogService.showCoralogixMessage('Failed to download results', null, dialogServiceIconClasses.failed))
  );

  @Effect()
  public createCachedQuery: Observable<Action> = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.CREATE_CACHED_QUERY),
    switchMap((action: any) => {
      return this.archiveQueriesService.createCachedQuery(action.payload)
        .map((res) => new ArchiveQueryActions.CreateCachedQueryCompletedAction(res))
        .catch((err) => of(new ArchiveQueryActions.CreateCachedQueryFailedAction((err))));
    })
  );

  @Effect({ dispatch: false })
  public navigateToLogs$ = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.CREATE_CACHED_QUERY_COMPLETED),
    tap((action: any) => {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: action.payload.queryId },
      };
      this.router.navigate(['/query-new/logs'], navigationExtras);
    })
  );

  @Effect()
  public hasBucketPermissions$: Observable<Action> = this.actions$.pipe(
    ofType(ArchiveQueryActions.ActionTypes.GET_BUCKET_PERMISSIONS),
    switchMap((action: any) => {
      return this.archiveQueriesService.hasBucketPermissions()
        .map((res) => new ArchiveQueryActions.GetBucketPermissionsCompletedAction(res))
        .catch((err) => of(new ArchiveQueryActions.GetBucketPermissionsFailedAction(err)));
    })
  );


  constructor(
    private actions$: Actions,
    private archiveQueriesService: ArchiveQueriesService,
    private dialogService: ShDialogService,
    private router: Router
  ) {}}
