import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Logs2MatricsDescriptionComponent } from './logs-2-matrics-description.component';

@NgModule({
  declarations: [Logs2MatricsDescriptionComponent],
  imports: [
    CommonModule
  ],
  exports: [Logs2MatricsDescriptionComponent]
})
export class Logs2MatricsDescriptionModule { }
