<div class="data-card">
  <div class="data-card__content">
    <div class="data-card__title">{{ title | uppercase }}</div>
    <div class="data-card__description">{{ description }}</div>
  </div>
  <cgx-button
    [label]="buttonText"
    size="sm"
    textNoWrap
    width="170px"
    [stroked]="true"
    (press)="buttonClick.emit(type)"
  ></cgx-button>

</div>
