<div class="selected-tag-box">
  <div class="selected-tag-box__title">{{ logsCount$ | async | numbersFormat }} Newly introduced Logs</div>
  <div class="grid-container">
    <sh-logs-new-grid
      [showJsonFormatterMenu]="false"
      [colsDefs$]="columnDefs$"
      [rowsData$]="rowsData$"
      [withInfoPanel]="true"
    ></sh-logs-new-grid>
  </div>
</div>
