import { jsonObject, jsonMember } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class Insight {
  @jsonMember
  public alertId: string;
  @jsonMember
  public applicationName: string;
  @jsonMember
  public companyId: string;
  @jsonMember({ name: 'eventSeverity', constructor: Number })
  public severity: number;
  @jsonMember({ name: 'eventSubTypeId', constructor: Number })
  public subTypeId: number;
  @jsonMember({ name: 'eventTimestamp', constructor: Number })
  public timestamp: number;
  @jsonMember({ name: 'eventTypeId', constructor: Number })
  public typeId: number;
  @jsonMember({ name: 'logTimestamp', constructor: Number })
  public logTimestamp: number;
  @jsonMember
  public expiration: number;
  @jsonMember
  public id: string;
  @jsonMember
  public isActive: boolean;
  @jsonMember
  public name: string;
  @jsonMember
  public subsystemName: string;
  @jsonMember(TypedJsonAny.OPTIONS)
  public esInfo: any;
  public isLatest: boolean = false;
}
