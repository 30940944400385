import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Metric } from '@app/settings/metrices-settings/models/metric.model';
import { getPermutationInUse, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { MetricEditorActions } from '@app/settings/metrices-settings/state/actions/metric-editor.actions';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetricsStoreService } from '@app/settings/shared/services/metrics-store.service';

@Component({
  selector: 'sh-metrics-table-container',
  templateUrl: './metrics-table-container.component.html',
  styleUrls: ['./metrics-table-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricsTableContainerComponent {
  @Select(CompanyState.logs2MetricsPermutationLimit) public logs2MetricsPermutationLimit$: Observable<number>;
  @Input() public metrics: Metric[];
  public permutationsInUse$ = this.store.select(getPermutationInUse);
  public permutationsLeft$ = this.metricsStoreService.permutationsLeft$;
  public hasReachedPermutationsLimit$ = this.permutationsLeft$.pipe(map(permutationsLeft => permutationsLeft === 0));

  constructor(private store: Store<State>, private metricsStoreService: MetricsStoreService) {}

  public editMetric(metric: Metric): void {
    this.store.dispatch(new MetricEditorActions.EditMetricAction(metric));
  }
}
