import { GridOptions } from 'ag-grid';
import { FilterOption } from '../grid-filters/grid-options-filter/filter-option';
import { MasterFilterComponent } from '../grid-filters/master-filter/master-filter.component';
import { TimestampCellComponent } from '@shared/controls/grid-cells';

export class GridRowStyle {
  public static setRowStyle(gridOptions: GridOptions): { [key: string]: string } | any {
    gridOptions.getRowStyle = (params) => {
      if (!params || !params.context.highlightedLogs || !params.data) {
        return null;
      }

      if (params.data.isHighlighted) {
        return { 'background-color': '#afe6f7' };
      }

      const logColor = params && params.context.highlightedLogs ?
      params.context.highlightedLogs.get(params.data.logId) || params.context.highlightedLogs.get(params.data.templateId) :
        null;

      if (logColor) {
        return {
          'background-color': '#afe6f7'
        };
      }

      return null;
    };
  }
}

export class GridColumnDef {
  public static getSeverityFilterOptions(): FilterOption[] {
    const filterOptions: FilterOption[] = [];
    filterOptions.push(new FilterOption('debug', false, 1));
    filterOptions.push(new FilterOption('verbose', false, 2));
    filterOptions.push(new FilterOption('info', false, 3));
    filterOptions.push(new FilterOption('warning', false, 4));
    filterOptions.push(new FilterOption('error', false, 5));
    filterOptions.push(new FilterOption('critical', false, 6));
    return filterOptions;
  }

  public static createColumnDefs(showFilters: boolean): any {
    if (showFilters) {
      return GridColumnDef.filterColumns;
    }
    return GridColumnDef.noFiltersColumns;
  }

  public static noFiltersColumns: any[] = [
  {
    headerName: '#',
    field: 'index',
    width: 50,
    suppressFilter: true,
    suppressSorting: true
  },
  {
    headerName: 'Timestamp',
    field: 'timestamp',
    cellRendererFramework: TimestampCellComponent,
    width: 200,
    suppressFilter: true
  },
  {
    headerName: 'Severity',
    field: 'metadata.severity',
    cellRenderer: (params: { value }) => {
      if (params.value) {
        const element = document.createElement('div');
        element.className = params.value.toLowerCase();
        element.appendChild(document.createTextNode(params.value));
        return element;
      }
      return null;
    },
    // headerCellTemplate: ((params)=> {
    //   return params.context.groupedColumnHeaderTemplate('metadata.severity',params)
    // }).bind(this),
    width: 105,
    suppressFilter: true
  },
  {
    headerName: 'Text',
    field: 'text',
    width: 600,
    cellStyle: { 'text-align': 'left' },
    cellRenderer: (params: { data, context }) => {
      let showMore;
      if (params.data.showMore) {
        // *********ShowMore*****//
        showMore = document.createElement('div');
        showMore.className = 'showMore-container';
        showMore.addEventListener('click', () => {
          params.context.onShowMore(params);
        });
        const showMoreLabel = document.createElement('span');
        showMoreLabel.className = 'showMore-label';
        showMoreLabel.innerText = 'SHOW MORE';
        showMore.appendChild(showMoreLabel);
      }

      const eButton = document.createElement('div');
      eButton.className = 'action-icon-container';
      const eIcon = document.createElement('i');
      eIcon.classList.add('fa');
      eIcon.classList.add('fa-ellipsis-h');
      eIcon.style.cssText = 'font-size:14px; margin:auto;top: 50%;';
      eButton.addEventListener('click', () => {
        params.context.textInfoClicked.emit();
      });
      eButton.appendChild(eIcon);

      const divElement = document.createElement('div');

      const text = params.context.highlightedText;
      if (text && params.data.viewObject) {
        const toSearch = new RegExp('(' + text + ')', 'gmi');
        // Create mark for each matched text and highlight it.
        divElement.innerHTML = params.data.viewObject.replace(toSearch, '<mark>$1</mark>');
        divElement.appendChild(eButton);
        return divElement;

      }
      divElement.innerHTML = params.data.viewObject;
      divElement.appendChild(eButton);
      if (showMore) {
        divElement.appendChild(showMore);
      }

      return divElement;
    },
    filterFramework: MasterFilterComponent,
    suppressSorting: true
  },

  {
    headerName: 'Application',
    field: 'metadata.applicationName',
    width: 100,
    suppressFilter: true
  },
  {
    headerName: 'Subsystem',
    field: 'metadata.subsystemName',
    width: 100,
    suppressFilter: true
  },
  {
    headerName: 'Category',
    field: 'metadata.category',
    width: 100,
    suppressFilter: true
  },
  {
    headerName: 'Computer',
    field: 'metadata.computerName',
    width: 100,
    suppressFilter: true
  },
  {
    headerName: 'Ip address',
    field: 'metadata.IPAddress',
    width: 100,
    suppressFilter: true
  },
  {
    headerName: 'Class',
    field: 'metadata.className',
    width: 100,
    suppressFilter: true
  },
  {
    headerName: 'Method',
    field: 'metadata.methodName',
    width: 100,
    suppressFilter: true
  },
  {
    headerName: 'ThreadId',
    field: 'metadata.threadId',
    width: 100,
    filterFramework: MasterFilterComponent
  }
];

  public static filterColumns: any[] = [
    {
      headerName: '#',
      field: 'index',
      width: 50,
      suppressFilter: true,
      suppressSorting: true
    },
    {
      headerName: 'Timestamp',
      field: 'timestamp',
      cellRendererFramework: TimestampCellComponent,
      width: 200,
      suppressFilter: true
    },
    {
      headerName: 'Severity',
      field: 'metadata.severity',
      cellRenderer: (params: { value }) => {
        if (params.value) {
          const element = document.createElement('div');
          element.className = params.value.toLowerCase();
          element.appendChild(document.createTextNode(params.value));
          return element;
        }
        return null;
      },
      // headerCellTemplate: ((params)=> {
      //   return params.context.groupedColumnHeaderTemplate('metadata.severity',params)
      // }).bind(this),
      width: 105,
      filterParams: { values: { filterOptions: GridColumnDef.getSeverityFilterOptions() }, newRowsAction: 'keep' },
      filterFramework: MasterFilterComponent
    },
    {
      headerName: 'Text',
      field: 'text',
      width: 600,
      cellStyle: { 'text-align': 'left' },
      cellRenderer: (params: { data, context }) => {
        let showMore;
        if (params.data.showMore) {
          // *********ShowMore*****//
          showMore = document.createElement('div');
          showMore.className = 'showMore-container';
          showMore.addEventListener('click', () => {
            params.context.onShowMore(params);
          });
          const showMoreLabel = document.createElement('span');
          showMoreLabel.className = 'showMore-label';
          showMoreLabel.innerText = 'SHOW MORE';
          showMore.appendChild(showMoreLabel);
        }

        const eButton = document.createElement('div');
        eButton.className = 'action-icon-container';
        const eIcon = document.createElement('i');
        eIcon.classList.add('fa');
        eIcon.classList.add('fa-ellipsis-h');
        eIcon.style.cssText = 'font-size:14px; margin:auto;top: 50%;';
        eButton.addEventListener('click', () => {
          params.context.textInfoClicked.emit();
        });
        eButton.appendChild(eIcon);

        const divElement = document.createElement('div');

        const text = params.context.highlightedText;
        if (text && params.data.viewObject) {
          const toSearch = new RegExp('(' + text + ')', 'gmi');
          // Create mark for each matched text and highlight it.
          divElement.innerHTML = params.data.viewObject.replace(toSearch, '<mark>$1</mark>');
          divElement.appendChild(eButton);
          return divElement;
        }
        divElement.innerHTML = params.data.viewObject;
        divElement.appendChild(eButton);
        if (showMore) {
          divElement.appendChild(showMore);
        }

        return divElement;
      },
      filterFramework: MasterFilterComponent,
      suppressSorting: true
    },

    {
      headerName: 'Application',
      field: 'metadata.applicationName',
      width: 100,
      filterParams: { values: { field: 'applicationName' }, newRowsAction: 'keep' },
      filterFramework: MasterFilterComponent
    },
    {
      headerName: 'Subsystem',
      field: 'metadata.subsystemName',
      width: 100,
      filterParams: { values: { field: 'subsystemName' }, newRowsAction: 'keep' },
      filterFramework: MasterFilterComponent
    },
    {
      headerName: 'Category',
      field: 'metadata.category',
      width: 100,
      filterParams: { values: { field: 'category' }, newRowsAction: 'keep' },
      filterFramework: MasterFilterComponent
    },
    {
      headerName: 'Computer',
      field: 'metadata.computerName',
      width: 100,
      filterFramework: MasterFilterComponent
    },
    {
      headerName: 'Ip address',
      field: 'metadata.IPAddress',
      width: 100,
      filterFramework: MasterFilterComponent
    },
    {
      headerName: 'Class',
      field: 'metadata.className',
      width: 100,
      filterParams: { values: { field: 'className' }, newRowsAction: 'keep' },
      filterFramework: MasterFilterComponent
    },
    {
      headerName: 'Method',
      field: 'metadata.methodName',
      width: 100,
      filterParams: { values: { field: 'methodName' }, newRowsAction: 'keep' },
      filterFramework: MasterFilterComponent
    },
    {
      headerName: 'ThreadId',
      field: 'metadata.threadId',
      width: 100,
      filterFramework: MasterFilterComponent
    }
  ];
}
