import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sh-select',
  templateUrl: './virtual-select.component.html',
  styleUrls: ['./virtual-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VirtualSelectComponent),
      multi: true,
    },
  ],
})
export class VirtualSelectComponent implements ControlValueAccessor {
  @Input() public placeholder: string = 'Type to filter';
  @Input() public isMultiselect: boolean = false;
  @Input() public mode: 'default' | 'inline' = 'default';
  @Input() public showClear: boolean = true;
  @Input() public disabled: boolean;
  @Input() set options(val: any[]) {
    this._options = val;
    this.updateRows(val);
  }
  get options(): any[] {
    return this._options;
  }

  @Output() public onHide: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public onShow: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public onClear: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild('inputFilter')
  public inputFilter: ElementRef;

  public rows: any[] = [];
  public isOpen: boolean;
  public filter: string;
  public filteredData: any[] = [];

  private _options: any[] = [];
  private _selectedValues: any[] = [];

  constructor(private element: ElementRef) {}

  @HostListener('window:mouseup', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (this.isOpen && !this.element.nativeElement.contains(event.target)) {
      this.hide();
    }
  }

  public updateRows(val: any[] = []): void {
    this.rows = val;
  }

  public get selectedValues(): any[] {
    return this._selectedValues;
  }

  public set selectedValues(val: any[]) {
    if (!val) {
      val = [];
    }
    if (!Array.isArray(val)) {
      val = [val];
    }
    this._selectedValues = val;
  }

  public updateFilter(filter: string): void {
    const lowercaseFilter = filter.toLocaleLowerCase();
    this.filteredData =
      this._options?.filter(item => !lowercaseFilter || (item.name || item).toLowerCase().indexOf(lowercaseFilter) !== -1) || [];
    this.updateRows(this.filteredData);
  }

  public clearFilter(): void {
    if (this.filter === '') {
      return;
    }
    this.filter = '';
    this.updateFilter(this.filter);
  }

  public toggleSelected(item: any): void {
    if (!item) {
      return;
    }
    this.clearFilter();

    if (this.isMultiselect) {
      this.selectMultiple(item);
    } else {
      this.selectSingle(item);
    }

    this.propagateChange(this._selectedValues);
  }

  public selectSingle(item: any): void {
    this._selectedValues.splice(0, this.rows.length);
    this._selectedValues.push(item);
    this.hide();
  }

  public selectMultiple(item: any): void {
    if (this.selectedValues.indexOf(item) === -1) {
      this.selectedValues.push(item);
    } else {
      this.selectedValues.splice(this.selectedValues.indexOf(item), 1);
    }
  }

  public focusFilter(): void {
    setTimeout(() => {
      this.inputFilter.nativeElement.focus();
    }, 0);
  }

  public show(): void {
    if (this.isOpen || this.disabled) {
      return;
    }

    this.isOpen = true;
    this.focusFilter();
    this.onShow.emit();
  }

  public hide(): void {
    this.isOpen = false;
    this.clearFilter();
    this.onHide.emit();
  }

  public clear(): void {
    if (this.disabled) {
      return;
    }

    this.selectedValues = [];
    this.propagateChange(this._selectedValues);
    this.onClear.emit();
  }

  //////// ControlValueAccessor imp //////////

  public writeValue(value: any): void {
    this.selectedValues = value;
  }

  public propagateChange = (_: any) => {
    /* do nothing */
  };

  public registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    /* do nothing */
  }
}
