import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Grok, GrokMatch } from '../../shared/models/grok.model';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'sh-detect-groks-dialog',
  templateUrl: './detect-groks-dialog.component.html',
  styleUrls: ['./detect-groks-dialog.component.scss'],
})
export class DetectGroksDialogComponent implements OnInit, OnDestroy {
  public detectedGroksSubscripion: Subscription;
  public searchingGroksSubscription: Subscription;
  public loading: boolean = true;
  public detectedGroks: Grok[];
  public selectedGroks: Grok[] = [];

  constructor(
    public dialogRef: MatDialogRef<DetectGroksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public ngOnInit() {
    this.detectedGroksSubscripion = this.data.detectedGroks$.subscribe(
      (res) => {
        if (res) {
          this.detectedGroks = this.extractGroks(res);
        }
      },
    );

    this.searchingGroksSubscription = this.data.searchingGroks$.subscribe(
      (res) => (this.loading = res),
    );
  }

  public isChecked(grok: Grok): boolean {
    return this.selectedGroks.includes(grok);
  }

  public toggle(grok: Grok, isChecked: boolean) {
    if (isChecked) {
      this.selectedGroks.push(grok);
    } else {
      const index = this.selectedGroks.indexOf(grok);
      this.selectedGroks.splice(index, 1);
    }
  }

  public extractGroks(grokMatches: GrokMatch[]): Grok[] {
    const groks: Grok[] = [];
    grokMatches.forEach((match) => {
      groks.push(match.grok);
    });
    return groks;
  }
  public ngOnDestroy() {
    this.detectedGroksSubscripion.unsubscribe();
  }
}
