import { NgModule } from '@angular/core';
import { CoralogixTooltipComponent } from '@shared/popups/tooltip/coralogix-tooltip.component';
import { CommonModule } from '@angular/common';
import { RegexTooltipContentComponent } from '@shared/popups/tooltip/regex-tooltip-content/regex-tooltip-content.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CoralogixSnackbarComponent } from '@shared/popups/coralogix-snackbar/coralogix-snackbar.component';
import { CoralogixSidebarModule } from './coralogix-sidebar/coralogix-sidebar.module';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatSidenavModule,
        CoralogixSidebarModule
    ],
    declarations: [
        CoralogixTooltipComponent,
        RegexTooltipContentComponent,
        CoralogixSnackbarComponent,
    ],

    exports: [
        CoralogixTooltipComponent,
        RegexTooltipContentComponent,
        CoralogixSnackbarComponent,
        CoralogixSidebarModule
    ],
    entryComponents: [
        CoralogixSnackbarComponent,
    ],
})
export class PopupsModule {}
