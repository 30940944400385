import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class MappingStats {
  @jsonMember
  public mappingLimit: number;
  @jsonMember
  public mappingCount: number;
  @jsonMember
  public dynamic: boolean;
  @jsonMember
  public mappingErrorCount: number;
}
