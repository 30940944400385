import {
  IntegrationModel,
  IntegrationTypeModel,
} from '../../../../settings-common/shared/models/newIntegrationRequest';
import { IntegrationsActions } from './integrations.actions';
import * as _ from 'lodash';
import { IDigestIntegrations } from '../../services/settings-service';

export namespace IntegrationsReducer {
  export interface State {
    integrations: IntegrationModel[];
    integrationTypes: IntegrationTypeModel[];
    digestIntegrations: IDigestIntegrations[];
    loading: boolean;
  }

  const initalState: State = {
    integrations: null,
    integrationTypes: null,
    digestIntegrations: null,
    loading: false,
  };

  export function reducer(
    state: State = initalState,
    action: IntegrationsActions.Actions,
  ): State {
    switch (action.type) {
      case IntegrationsActions.ActionTypes.GET_DIGEST_INTEGRATIONS_COMPLETED: {
        return { ...state, digestIntegrations: action.payload, loading: false };
      }
      case IntegrationsActions.ActionTypes.SAVE_DIGEST_INTEGRATION_COMPLETED: {
        const digestIntegrations = _.cloneDeep(state).digestIntegrations;
        const exists = digestIntegrations.find(digestInt => digestInt.integrationId === action.payload[0].integrationId);
        if (exists) {
          const newDigestIntegrations = digestIntegrations.filter(di => di.integrationId !== exists.integrationId)
          newDigestIntegrations.push(...action.payload);
          return { ...state, digestIntegrations: newDigestIntegrations, loading: false };
        }
        digestIntegrations.push(...action.payload);
        return { ...state, digestIntegrations: digestIntegrations, loading: false };
      }
      case IntegrationsActions.ActionTypes.GET_INTEGRATIONS_COMPLETED: {
        return { ...state, integrations: action.payload, loading: false };
      }
      case IntegrationsActions.ActionTypes.GET_INTEGRATION_TYPES_COMPLETED: {
        return { ...state, integrationTypes: action.payload, loading: false };
      }
      case IntegrationsActions.ActionTypes.ADD_NEW_INTEGRATION: {
        const integrations = _.cloneDeep(state).integrations;

        integrations.unshift(action.payload);

        return { ...state, integrations, loading: false };
      }
      case IntegrationsActions.ActionTypes.REMOVE_NEW_INTEGRATION: {
        const integrations = _.cloneDeep(state).integrations;
        const targetIndex = integrations.findIndex((int) => !int.id);

        if (targetIndex !== -1) {
          integrations.splice(targetIndex, 1);
        }

        return { ...state, integrations, loading: false };
      }
      case IntegrationsActions.ActionTypes.SAVE_INTEGRATION_COMPLETED: {
        const integrations = _.cloneDeep(state).integrations;

        const targetIndex = integrations.findIndex(
          (int) => int.id === action.payload.integration.id,
        );
        if (targetIndex !== -1) {
          integrations[targetIndex] = action.payload.integration;
          return { ...state, integrations, loading: false };
        }

        const newIntegrationIndex = integrations.findIndex((int) => !int.id);
        if (newIntegrationIndex !== -1) {
          integrations[newIntegrationIndex] = action.payload.integration;
          return { ...state, integrations, loading: false };
        }

        return { ...state, integrations, loading: false };
      }
      case IntegrationsActions.ActionTypes.DELETE_INTEGRATION_COMPLETED: {
        const integrations = _.cloneDeep(state).integrations;

        const targetIndex = integrations.findIndex(
          (int) => int.id === action.payload,
        );
        if (targetIndex !== -1) {
          integrations.splice(targetIndex, 1);
        }

        return { ...state, integrations, loading: false };
      }
      case IntegrationsActions.ActionTypes.INTEGRATIONS_FAILURE_COMPLETED: {
        return { ...state, loading: false };
      }

      case IntegrationsActions.ActionTypes.INTEGRATIONS_CONFIG_TEST_FAILED:
      case IntegrationsActions.ActionTypes.INTEGRATIONS_CONFIG_TEST_COMPLETED: {
        return { ...state, loading: false };
      }

      case IntegrationsActions.ActionTypes.GET_DIGEST_INTEGRATIONS:
      case IntegrationsActions.ActionTypes.SAVE_DIGEST_INTEGRATION:
      case IntegrationsActions.ActionTypes.INTEGRATIONS_CONFIG_TEST:
      case IntegrationsActions.ActionTypes.GET_INTEGRATIONS:
      case IntegrationsActions.ActionTypes.GET_INTEGRATION_TYPES:
      case IntegrationsActions.ActionTypes.DELETE_INTEGRATION:
      case IntegrationsActions.ActionTypes.SAVE_INTEGRATION: {
        return { ...state, loading: true };
      }
      default: {
        return state;
      }
    }
  }
}
