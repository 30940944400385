<div class="data-flow-routes">
  <div class="data-flow-category" style="margin-inline-end: 20px;">
    <div class="data-flow-category-header">INTEGRATIONS</div>

    <a
      *ngIf="isReadOnly === false"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/webhooks'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/webhooks.svg"></svg-icon>
      Webhooks
    </a>
    <a
      *ngIf="getPermissionAccessApi$ | async"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/integration/apikey'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/api-key.svg"></svg-icon>
      API Keys
    </a>
    <a
      *ngIf="isAdmin"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/integration/dataintegration'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/contextual-data.svg"></svg-icon>
      Contextual Data
    </a>
    <a
      *ngIf="isAdmin"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/extensions'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/groups.svg"></svg-icon>
      Extensions
    </a>
  </div>

  <div class="data-flow-category">
    <div class="data-flow-category-header">STREAMA ENGINE</div>

    <a *ngIf="isAdmin" class="data-flow-link" routerLinkActive="data-flow-link-active" [routerLink]="'/rules'" (click)="closeMenuTooltip()">
      <svg-icon src="assets/icons/sm-24x24/parsing.svg"></svg-icon>
      Parsing Rules
    </a>
    <a
      *ngIf="isAdmin"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/enrichments'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/data-enrichment.svg"></svg-icon>
      Data Enrichment
    </a>
    <a
      *ngIf="isAdmin"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/tco/quota'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/quota.svg"></svg-icon>
      TCO Quota Optimizer
    </a>
    <a
      *ngIf="isAdmin"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/tco/metrics'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/log2metrics.svg"></svg-icon>
      Logs2Metrics
    </a>
    <a
      *ngIf="isArchiveVersionValid"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/tco/archiveQueries'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/archive-query.svg"></svg-icon>
      Reindex Logs
    </a>
    <a
      *ngIf="isReadOnly === false"
      class="data-flow-link"
      routerLinkActive="data-flow-link-active"
      [routerLink]="'/tco/archivingProviders'"
      (click)="closeMenuTooltip()"
    >
      <svg-icon src="assets/icons/sm-24x24/reindex-archive.svg"></svg-icon>
      Setup Archive
    </a>
  </div>
</div>
