import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ArchiveQueryRequest } from '@app/roi/archive-queries/models/archive-query.model';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ShDialogService } from '@shared/services/dialogService';
import moment from 'moment';
import { Constants } from '@app/constants';

interface IArchiveQueryEditorSplittedControl {
  details: {
    name: string;
    description?: string;
  };
  filters: {
    applicationName: string[];
    subsystemName: string[];
    logSeverity: number[];
    textSql: string;
    textLucene: string;
    from: Date;
    to: Date;
    startTime: string;
    endTime: string;
    querySyntax: string;
  };
}

@Component({
  selector: 'sh-archive-query-form',
  templateUrl: './archive-query-form.component.html',
  styleUrls: ['./archive-query-form.component.scss'],
})
export class ArchiveQueryFormComponent implements OnInit {
  public get detailsGroup(): FormGroup {
    return this.form?.get('details') as FormGroup;
  }

  public get filterGroup(): FormGroup {
    return this.form?.get('filters') as FormGroup;
  }

  public get isFormInvalid(): boolean {
    return this.form?.invalid || !this.isTimeRangeValid;
  }

  @Input()
  public form: FormGroup;
  @Input()
  public logsSeverityOptions: { id: number; name: string }[];
  @Input()
  public isPrePopulated: boolean;
  @Input()
  public validationError: string;
  @Input()
  public isEditable: boolean;
  @Input()
  public queryId: string;

  @Output()
  public submitEvent: EventEmitter<ArchiveQueryRequest> = new EventEmitter<ArchiveQueryRequest>();

  public isTimeRangeValid: boolean = false;

  constructor(
    private archiveQueriesService: ArchiveQueriesService,
    private clipboard: Clipboard,
    private dialogService: ShDialogService) {}

  public ngOnInit(): void {
    if (this.isEditable && this.isPrePopulated) {
      const queryName = this.form.get('details').get('name').value;
      this.isTimeRangeValid = true;
      this.form.get('details').get('name').setValue('CLONE - ' + queryName);
    } else if (this.isEditable) {
      const dateString = new Date().toLocaleString();
      this.form.get('details').get('name').setValue(`Query - ${dateString}`);
    }
  }

  public emitErr(val: boolean): void {
    this.isTimeRangeValid = val;
  }

  public submit(value: IArchiveQueryEditorSplittedControl): void {
    const { filters, details } = value;
    const { name, description } = details;
    const { applicationName, subsystemName, logSeverity, from, to, startTime, endTime, textLucene, textSql, querySyntax } = filters;
    const isUtcTimestamp = querySyntax === Constants.PRESTO;
    const { newStartTime, newEndTime } = this.archiveQueriesService.setEndAndStartTime(from, to, startTime, endTime, isUtcTimestamp);
    const text = querySyntax === Constants.PRESTO ? textSql : textLucene;
    const archiveQueryRequest = new ArchiveQueryRequest({
      name: name,
      description: description,
      querySyntax: querySyntax || 'Lucene',
      applicationName: applicationName || [],
      subsystem: subsystemName || [],
      severity: logSeverity || [],
      text: text || '',
      startTime: newStartTime,
      endTime: newEndTime,
    });
    this.submitEvent.emit(archiveQueryRequest);
  }

  public copyUrl(): void {
    const cleanUrl = window.location.href.split('?')[0];
    const url = `${cleanUrl}?id=${this.queryId}`;
    this.clipboard.copy(url);
    this.dialogService.showCoralogixMessage('Copied archive query link');
  }

  public onCloneClick(value: IArchiveQueryEditorSplittedControl): void {
      this.isEditable = true;
      this.isTimeRangeValid = true;
      this.form.get('details').get('name').setValue('CLONE - ' + value.details.name);
  }
}
