<div class="message-container">
  <ng-container *ngIf="!isLoading && inviteRequestStatus?.status === 'pending'">
    <div class="auth-title">Waiting for your admin's approval</div>
    <p class="auth-sub-title message-container__message" >
      A request to join team <span class="message-container__message--bold">{{ inviteRequestStatus?.team_url }}</span> was sent to your team admin(s). Once an admin approves you, you’ll receive an email invitation from Coralogix to complete the signup process.
    </p>
  </ng-container>
  <ng-container *ngIf="!isLoading && inviteRequestStatus?.status === 'accepted'">
    <div class="auth-title">Your account has been approved</div>
    <p class="auth-sub-title message-container__message">
      Check your email({{ inviteRequestStatus?.email }}) to get your invite and
      join Coralogix.
    </p>
  </ng-container>
  <p *ngIf="isLoading">
    Loading...
  </p>
</div>
