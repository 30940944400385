import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeMagnitude } from '@app/insights/shared/models/time.model';
import {
  getFullUTCDateFromStrDate,
  getDateFromStrDate,
  getStrDateWithoutDot,
} from '@app/logs-new/shared/utils/time.utils';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';

interface IDialogData {
  strDate: string;
}

@Component({
  selector: 'sh-show-time-popup',
  templateUrl: './show-time.component.html',
  styleUrls: ['./show-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowTimeComponent {
  date: Date;
  UTCDate: string = '';
  timezone$ = this.store.select(getTimezoneViewPreference);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public dialogRef: MatDialogRef<ShowTimeComponent>,
    private store: Store<State>,
  ) {
    this.setDateValues();
  }

  private setDateValues(): void {
    const fullStrDate = getStrDateWithoutDot(this.data.strDate);
    this.date = getDateFromStrDate(fullStrDate);
    this.UTCDate = `${getFullUTCDateFromStrDate(fullStrDate).slice(0, -1)} UTC`;
  }
}
