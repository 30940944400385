<form [formGroup]="ruleForm" class="dynamic-rule-form" fxLayout="column" fxLayoutGap="25px">
  <div class="rule-description">
    {{ ruleConfig.description }}
    <a class="rule-external-link" target="_blank" rel="noopener noreferrer" [href]="ruleConfig.learnMoreLink">Learn More</a>
  </div>
  <div fxLayout="row" fxLayoutGap="11px" *ngFor="let row of ruleConfig.rowConfig">
    <ng-container *ngFor="let col of row" [ngSwitch]="col.type">
      <sh-basic-input
        *ngSwitchCase="controlTypes.text"
        [formControl]="$any(ruleForm.controls[col.controlName])"
        [label]="col.label"
        [isRequired]="!col.isOptional"
        [fontFamilyOptions]="col.fontFamily"
        [tooltip]="col.tooltip"
      ></sh-basic-input>
      <div class="coralogix input-group" *ngSwitchCase="controlTypes.select">
        <sh-coralogix-select
          *ngIf="fieldOptions"
          [formControlName]="col.controlName"
          [selectOptions]="fieldsSelectOptionMap[col.controlName]"
          [bindLabel]="'name'"
          [bindValue]="'value'"
          [hasColoredIcon]="false"
          [searchable]="true"
          [multiple]="false"
          [appendTo]="'.group-of-groups'"
          [dropDownPosition]="'bottom'"
          [disableSelectAll]="true"
        >
          <label class="label-with-tooltip" select-label>
            {{ col.label }}
            <span class="input-astrix" *ngIf="!col.isOptional">*</span>
            <i *ngIf="col.tooltip" class="fa fa-question-circle-o" shTooltip [tooltip]="col.tooltip"></i>
          </label>
        </sh-coralogix-select>
      </div>
      <div
        class="coralogix input-group textarea-control"
        [class.coralogix-input-error]="!ruleForm.controls[col.controlName].pristine && !ruleForm.controls[col.controlName].valid"
        *ngSwitchCase="controlTypes.textarea"
      >
        <label class="input-label">
          <span class="text">{{ col.label }}</span>
          <span class="input-astrix" *ngIf="!col.isOptional">*</span>
        </label>
        <textarea
          shAutoresize
          type="text"
          [placeholder]="col.placeholder ? col.placeholder : ''"
          class="noresize-textarea regex-marking form-control autoresizable textarea-control-input {{ col.inputClass }}"
          [formControlName]="col.controlName"
          [minHeight]="$any(37 * col.numberOfInputRows)"
        ></textarea>
        <span *ngIf="!ruleForm.controls[col.controlName].pristine && !ruleForm.controls[col.controlName].valid" class="error">
          This field is required
        </span>
      </div>
      <div class="field-selector-section-spacing" *ngSwitchCase="controlTypes.checkbox">
        <div class="sh-row-fields field-spacing">
          <mat-checkbox type="checkbox" class="coralogix mat-checkbox" [formControlName]="col.controlName" disableRipple="true">
            {{ col.label }}
            <i *ngIf="col.tooltip" class="fa fa-question-circle-o" shTooltip [tooltip]="col.tooltip"></i>
          </mat-checkbox>
        </div>
      </div>
      <div class="coralogix input-group rule-radio-control" *ngSwitchCase="controlTypes.radioButtons">
        <label class="input-label">{{ col.label }}</label>
        <mat-radio-group
          class="coralogix-radio-group--primary dynamic-rule-radio-group"
          fxLayout="column"
          fxLayoutGap="11px"
          [formControlName]="col.controlName"
        >
          <mat-radio-button class="rule-radio-control__rule-radio-btn" *ngFor="let option of col.options" [value]="option.value">
            {{ option.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="coralogix input-group rule-radio-control" *ngSwitchCase="controlTypes.multiSelect">
        <label class="label-with-tooltip">
          {{ col.label }}
          <span class="input-astrix" *ngIf="!col.isOptional">*</span>
          <i *ngIf="col.tooltip" class="fa fa-question-circle-o" shTooltip [tooltip]="col.tooltip"></i>
        </label>
        <sh-coralogix-select
          *ngIf="getOptions(col.controlName)"
          [formControlName]="col.controlName"
          [selectOptions]="getOptions(col.controlName)"
          [hasColoredIcon]="true"
          [searchable]="true"
          [multiple]="true"
          [disableSelectAll]="true"
          [appendTo]="'.group-of-groups'"
          [dropDownPosition]="'bottom'"
        ></sh-coralogix-select>
      </div>
    </ng-container>
  </div>
</form>
