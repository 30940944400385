import { Routes } from '@angular/router';

import { LogsQueryResultComponent } from './logs-query-result/logs-query-result.component';
import { LogsLoggregationComponent } from './logs-loggregation/logs-loggregation.component';
import { HerokuGurd } from '../shared/guards/heroku-payment.gurd';
import { AuthGuard } from '@app/auth/shared/guards/auth.guard';
import { LogsContainerComponent } from '@app/logs/containers/logs-container.component';
import { PaymentGuard } from '@app/shared/guards/payment.guard';

export const LogsRoutes: Routes = [
  {
    path: 'query',
    component: LogsContainerComponent,
    canActivate: [PaymentGuard, HerokuGurd],
    children: [
      {
        path: 'loggregation',
        component: LogsLoggregationComponent,
      },
      {
        path: 'loggregation/:id',
        component: LogsLoggregationComponent,
      },
      {
        path: 'logs/:id',
        component: LogsQueryResultComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: 'logs/:queryType',
        component: LogsQueryResultComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: 'logs',
        component: LogsQueryResultComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: '',
        canActivate: [AuthGuard],
        redirectTo: 'logs',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'logquery/results/:queryType',
    canActivate: [AuthGuard],
    redirectTo: 'query/logs/:queryType',
  },
  {
    path: 'logquery',
    canActivate: [AuthGuard],
    redirectTo: 'query/logs',
    pathMatch: 'full',
  },
  {
    path: 'logquery/results',
    canActivate: [AuthGuard],
    redirectTo: 'query/logs',
    pathMatch: 'full',
  },
];
