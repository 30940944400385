import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'logsAvailableColumnsFilter' })
export class LogsAvailableColumnsFilterPipe implements PipeTransform {
  public transform(columns: any[], term: string): any[] {
    if (columns && columns.length > 0 && term && term.length > 0) {
      return columns.filter(
        (col) =>
          col.colId.toLowerCase().includes(term.toLowerCase()) ||
          col.headerName.toLowerCase().includes(term.toLowerCase()),
      );
    }

    return columns;
  }
}
