<div>
  <div class="from-container" style="display: inline-block">
    <p>From: {{ fromTimeStamp | dateFormatByUserSettings: null : standardHoursFormat }}</p>
    <input
      type="number"
      name="quantity"
      min="1"
      max="1000"
      [(ngModel)]="last"
      (ngModelChange)="fromTimeStamp = Date.now() - last * scalar"
    />
    <select
      [(ngModel)]="scalar"
      (ngModelChange)="fromTimeStamp = Date.now() - last * scalar"
      style="margin-left: 10px"
    >
      <option [attr.value]="1000">Seconds ago</option>
      <option [attr.value]="60 * 1000">Minutes ago</option>
      <option [attr.value]="60 * 60 * 1000">Hours ago</option>
      <option [attr.value]="24 * 60 * 60 * 1000">Days ago</option>
    </select>
  </div>
  <div
    class="to-container"
    style="display: inline-block;margin-left: 30px;margin-right: 30px"
  >
    <p>To Now</p>
    <input type="text" value="Now" disabled />
  </div>
  <button
    [disabled]="
      last * scalar >
      1000 * 60 * 60 * 24 * (queryRangeNumber$ | async)
    "
    (click)="valueSelected.emit(fromTimeStamp)"
    mat-raised-button
    color="primary"
  >
    Get Logs
  </button>
</div>
<div
  *ngIf="
    last * scalar >
    1000 * 60 * 60 * 24 * (queryRangeNumber$ | async)
  "
  class="errMsg"
>
  Query range must be under {{ (queryRangeNumber$ | async) }} days
</div>
