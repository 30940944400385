<div class="multiple-selection-menu-container" #selectionMenu>
  <label class="label">{{ label | titlecase }}</label>
  <div #menuTrigger class="selection-menu-trigger" (click)="toggleMenu()">
    <div class="text-container">
      <div class="text"> {{ selectedValuesText }} </div>
    </div>
    <div class="arrow-container">
      <div class="arrow"></div>
    </div>
  </div>
  <div #menu class="menu" *ngIf="isMenuOpen" [ngClass]="{'width-limited':widthLimited,'position-absolute':positionAbsolute}">
    <div class="menu-top-part">
      <div class="search-input-container">
        <input class="menu-search-input" placeholder="Search" (input)="filterMenuOptions($event)"/>
        <i class="fa fa-search search-icon"></i>
      </div>
      <div class="global-selection-buttons-container">
        <div class="global-selection-button" (click)="selectAllOptions()">SELECT ALL</div>
        <div class="global-selection-button" (click)="unselectAllOptions()">UNSELECT ALL</div>
      </div>
    </div>
    <div class="virtual-scroll-wrapper" [ngStyle]="{'height.px': virtualScrollHeight}">
      <cdk-virtual-scroll-viewport class="virtual-scroll-viewport" itemSize="{{ itemSize }}">
        <div class="option" [ngClass]="{'selected': optionsStatusDict[option],'width-limited':widthLimited}" *cdkVirtualFor="let option of filteredOptions" (click)="selectOption(option)">
          <mat-checkbox disableRipple [checked]="optionsStatusDict[option]" (click)="$event.preventDefault()"></mat-checkbox>
          <span class="option-text">
          {{ option }}
        </span>
          <span class="option-only-button" (click)="selectOnlyOneOption($event, option)">ONLY</span>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
