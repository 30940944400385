import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LivetailEntity } from '@app/livetail/shared/models/livetailEntity';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { FormControl } from '@angular/forms';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';
import { ShDialogService } from '@shared/services/dialogService';
import { BehaviorSubject, Subject } from 'rxjs';
import {distinctUntilChanged, take, takeUntil} from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {ArchiveQueryDownloadTsvParams, ArchiveQueryPreviewParams} from '@app/roi/archive-queries/models/archive-query.model';
import {ArchiveQueryActions} from "@app/roi/archive-queries/state/actions/archive-query.actions";
import {dialogServiceIconClasses} from "@shared/models/dialog-service.models";
import {Store} from "@ngrx/store";
import {State} from "@app/app.reducers";
import {DownloadResultsFormComponent} from "@app/roi/archive-queries/components/download-results-form/download-results-form.component";

@Component({
  selector: 'sh-logs-preview',
  templateUrl: './archive-query-logs-preview.component.html',
  styleUrls: ['./archive-query-logs-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveQueryLogsPreviewComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public formControl: FormControl = new FormControl('');
  public logs: LivetailEntity[] = [];
  public isLoading: boolean = true;
  public hasLogsForPreview: boolean = true;
  public unsubscribe$: Subject<void> = new Subject<void>();
  public logs$: BehaviorSubject<LivetailEntity[]> = new BehaviorSubject<LivetailEntity[]>([]);
  public validationErr$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public filteredLogs$: BehaviorSubject<LivetailEntity[]> = new BehaviorSubject<LivetailEntity[]>([]);
  public searchQuery: string = '';
  public validationError: string = '';

  constructor(
    private archiveQueriesService: ArchiveQueriesService,
    private dialogService: ShDialogService,
    @Inject(MAT_DIALOG_DATA) public data: ICoralogixDialogData & ArchiveQueryPreviewParams,
    public dialogRef: MatDialogRef<ArchiveQueryLogsPreviewComponent>,
    private dialog: MatDialog,
    private store: Store<State>) {
  }

  public ngOnInit(): void {
    this.getFilteredLogs();
    this.formControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged()
    ).subscribe((val: string | number) => {
        this.searchQuery = val !== null && val !== undefined ? val.toString() : '';
      });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public filterLogs(): void {
    this.isLoading = true;
    this.validationErr$.next('');
    this.filterBySearchTerm(this.searchQuery);
    this.paginator.firstPage();
  }

  public getPageQueries(event: PageEvent): void {
    const first = event.pageSize * event.pageIndex;
    const last = event.pageSize + first;
    const filteredLogs = this.filteredLogs$.getValue().slice(first, last);
    this.logs$.next(filteredLogs);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getTsvs(): void {
    const data: ICoralogixDialogData & ArchiveQueryDownloadTsvParams = {
      title: 'Archive preview logs',
      message: '',
      yesButtonText: 'CONFIRM',
      noButtonText: 'CLOSE',
      id: this.data.id
    };
    const dialogRef = this.dialog.open(DownloadResultsFormComponent, { data });

  }

  private getFilteredLogs(): void {
    this.archiveQueriesService.getStoredLogs(this.data.id, this.data.filterType, this.data.filter)
      .subscribe((res) => {
        this.logs = res;
        this.logs$.next(this.logs.slice(0, 100));
        this.filteredLogs$.next(this.logs);
        this.isLoading = false;
        this.hasLogsForPreview = !!(res && res.length);
      }, err => {
        this.logs = [];
        this.isLoading = false;
          this.hasLogsForPreview = false;
          if (err.status === 417) {
            this.validationErr$.next(err.error);
          } else {
            this.dialogService.showSnackBar('Failed to get logs.', null, 5000);
          }
        });
  }

  private filterBySearchTerm(term: string): void {
    this.data.filter = term;
    this.getFilteredLogs();
  }
}
