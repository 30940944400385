import { LogQueryModel } from '../../../../logs/shared/models/logsquery.model';
import { EScaleOptions } from '@app/statistics/shared/models/line-chart.models';

export class ChartModel {
  maxXAxis: number = null;
  maxYAxis: number = null;
  xType: string = '';
  categories: any = [];
  enableCopyClipboard: boolean = false;
  series: Array<ChartSeriesModel> = new Array<ChartSeriesModel>();
  seriesType: string = '';
  title: string = '';
  colors: any = [];
  zoomable: boolean = true;
  zoomType: string = 'x';
  hoverable: boolean = true;
  logQueryModel: LogQueryModel;
  backgroundColor: string = '#ffffff';
  fontColor: string = 'var(--cgx-text-primary)';
  fontSize: string = '18px';
  scale: EScaleOptions | string = null;
  tooltipDateFormat: string = 'MMM Do, h:mm:ss a';
  tooltipEnabled: boolean = true;
  labelHighlightColor: string = '';
  height: number = 170;
  width: number = null;
  inverted: boolean = false;
  tooltipValueSuffix: string = '';
  useAnimation: boolean = false;
  useLabels: boolean = true;
  hideAxis: boolean = false;
  yAxisEnable: boolean = false;
  center: any[] = ['50%', '55%'];
  infoBarClass: string = 'chart-infoBar '; // leave space after
  titleY: number = 20;
  useDefaultFormater: boolean = true;
  yPlotlineNormalValue: number = null;
  yPlotlineAnomalyValue: number = null;
  stacking: string = 'normal';
  xPlotlines: Array<PlotlineModel> = new Array<PlotlineModel>();
  yPlotlines: Array<PlotlineModel> = new Array<PlotlineModel>();
  sharedTooltip: boolean = true;
  clickable: boolean = false;
  showLegend: boolean = false;
  showTitle: boolean = true;
  alignToolTipRight: boolean = false;
  yType: string = null;
  highlightedXlables: Map<number | string, string> = new Map<number | string, string>();
  minorTickLength: number = 2;
  tickLength: number = 0;
  lineWidth: number = 1;
  enableDataLabel: boolean = false;
  dataLabelsInside: boolean = false;
  minorGridLineWidth: number = 1;
  isRealtime: boolean = false;
  activeBuildTooltip: boolean = false;
  generateLogQuery: boolean = true;
  yAxisAllowDecimals: boolean = true;
  xTitle: string = null;
  yTitle: string = null;
  tickIntervalX: number = null;
  tickIntervalY: number = null;
  gridLineWidthX: number = 0;
  gridLineWidthY: number = 0;
  tooltipStyleType: EGraphTooltipStyles = null;
  chartTooltipDataTitle: string = '';
  tooltipButtonText: string = '';
  tooltipRightOffsetInPx: number = null;
  xCategories: (string | number)[] = [];
  yCategories: (string | number)[] = [];
  type: string = '';
  plotBorderWidth?: number;
  yPlotBands: PlotBand[] = [];

  constructor(data?: Partial<ChartModel>) {
    if (!!data) {
      Object.keys(data).forEach(key => {
        if (this.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      });
    }
  }

  xMinRange(): number {
    return this.zoomable ? 0 : this.categories.length - 1;
  }

  getCursor(): string {
    return this.clickable ? 'pointer' : null;
  }

  displayZoomButton(): string {
    return this.zoomable ? 'true' : 'none';
  }
}

export enum EGraphTooltipStyles {
  whiteWithLinkButton = 'whiteWithLinkButton',
  whiteWithLinkButtonLogs = 'whiteWithLinkButtonLogs',
}

export class ChartSeriesModel {
  name: string;
  type: string;
  data: any;
  innerSize: string = '65%';
  color: string;
  marker: ChartMarkerModel;
}

export class PlotlineModel {
  id: number = 0;
  color: string = '#FF0000';
  width: number = 0;
  value: number;
  label: any;
  data: any;
  dashStyle: string = 'Solid';
  zIndex: number;
}

export class ChartClickParams {
  index: number | string;
  name: string;
  chartId: string = '';
}

export class ChartMarkerModel {
  enabled: boolean;
  radius: number;
  symbol?: string;
}

export class PlotBand {
  from: number;
  to: number;
  color: string;
}
