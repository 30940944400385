import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Entity } from '@shared/models/entity.model';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { UserAlertViewTypes } from '@app/alerts/alerts-editor/models/alert-editor-view.models';
import {
  ArithmeticOptions,
  ConditionsOptions,
  TwelveHoursInMinutes,
  PrometheusMetricsMatcherId,
} from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { Metric } from '@app/settings/metrices-settings/models/metric.model';
import { IAlertRelativeTimeOption } from '@app/alerts/alerts-editor/models/alert-relative-time-option';
import moment from 'moment';
import { dropWhile, isNil, round, some, isEmpty } from 'lodash';
import { debounceTime, distinctUntilChanged, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'sh-alerts-conditions',
  templateUrl: './alerts-conditions.component.html',
  styleUrls: ['./alerts-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsConditionsComponent implements OnInit, OnChanges, OnDestroy {
  @Select(CompanyState.metricAlertGroupByLimit) public metricAlertGroupByLimit$: Observable<number>;

  @Input() public conditionTimeframeOptions: Entity[];

  @Input() public relativeTimeframeOptions: Entity[];

  @Input() public relativeTimeframeOptionIdMapping: { [key: number]: IAlertRelativeTimeOption };

  @Input() public relativeTimeframeSilenceDisplay: { [key: number]: string };

  @Input() public cardinalityTimeframeOptions: Entity[];

  @Input() public relativeCalcStr: string;

  @Input() public conditionMetricArithmeticOptions: Entity[];

  public notifyEveryMinutesDisabled: boolean = false;

  public options: string[];

  public optionsRequired: string[];

  public formGroup: FormGroup;

  private _isPromQLSyntax: boolean;

  groupUniqueCountTooltipText = `The total amount of permutations for the
  unique count by key and group by key should
  not exceed 10k for the alert timeframe`;
  @Input()
  set form(form: FormGroup) {
    this.formGroup = form;
    this.subscribeOnConditionChanges();
  }

  @Input() public set isPromQLSyntax(isPromQLSyntax: boolean) {
    const conditionMetricField = this.formGroup?.get('conditionMetricField');
    this._isPromQLSyntax = isPromQLSyntax;
    if (!isPromQLSyntax && this.isMetricAlert) {
      conditionMetricField?.setValidators([this.getMetricFieldValidatorFunc(), Validators.required]);
    } else {
      conditionMetricField?.clearValidators();
    }
    conditionMetricField?.updateValueAndValidity();
  }
  public get isPromQLSyntax(): boolean {
    return this._isPromQLSyntax;
  }

  @Input() public set alertType(type: UserAlertViewTypes) {
    if (!!type) {
      this._alertType = type;
      const conditionOperator = this.formGroup?.get('conditionOperator');
      if (this.isNewValueAlert) {
        conditionOperator?.setValue(ConditionsOptions.newValue);
      } else {
        conditionOperator?.setValue(ConditionsOptions.more);
      }
      this.updateTempConditionsOperatorOptions();
      if (this.isMetricAlert) {
        this.formGroup?.get('arithmeticOperators')?.setValue(ArithmeticOptions.average);
        this.formGroup?.get('conditionMetricField')?.updateValueAndValidity();
        this.initMetricAlertListeners();
      } else {
        const conditionMetricField = this.formGroup?.get('conditionMetricField');
        const conditionMetricArithmeticModifier = this.formGroup?.get('conditionMetricArithmeticModifier');

        conditionMetricField?.setValidators(null);
        conditionMetricArithmeticModifier?.setValidators(null);

        conditionMetricField?.updateValueAndValidity();
        conditionMetricArithmeticModifier?.updateValueAndValidity();
      }
      this.formGroup?.updateValueAndValidity();
    }
    this.updateTempConditionsOperatorOptions();
  }

  public get alertType(): UserAlertViewTypes {
    return this._alertType;
  }

  @Input()
  set conditionsOperatorOptions(e: Entity[]) {
    this._conditionsOperatorOptions = e;
    this.updateTempConditionsOperatorOptions();
  }

  get conditionsOperatorOptions(): Entity[] {
    return this._tempConditionsOperatorOptions;
  }

  @Input()
  set groupByOptions(options: string[]) {
    if (options?.length && !this.isMetricAlert) {
      const groupByOptions = this.getGroupByOptions(options);
      this.setNoneOption(groupByOptions);
    }
  }

  @Input()
  public set metrics(val: Metric[]) {
    if (this.isMetricAlert) {
      this.metricDefs = val;
      const metricFieldsToId = new Map<string, string>();
      val.forEach(metric => {
        metric.metricFields?.forEach(field => metricFieldsToId.set(field.name, metric.id));
      });
      this.logs2MetricsMetricFieldOptions = Array.from(metricFieldsToId.entries()).sort((a, b) => a[0].localeCompare(b[0]));
      this.conditionMetricFieldOptions = this.logs2MetricsMetricFieldOptions.concat(this.prometheusMetricFieldOptions);
      this.initMetricAlertListeners();
    }
  }

  @Input()
  public set newMetricsMetricFields(val: string[]) {
    if (this.isMetricAlert) {
      this.prometheusMetricFieldOptions = val.map(field => [field, PrometheusMetricsMatcherId]);
      this.conditionMetricFieldOptions = this.logs2MetricsMetricFieldOptions.concat(this.prometheusMetricFieldOptions);
    }
  }

  public get isNewValueAlert(): boolean {
    return this.alertType === UserAlertViewTypes.NewValueAlert;
  }

  public get isTextAlert(): boolean {
    return this.alertType === UserAlertViewTypes.UserTextAlert;
  }

  public get isCardinalityAlert(): boolean {
    return this._alertType === UserAlertViewTypes.Cardinality;
  }

  public get isRelativeTimeAlert(): boolean {
    return this.alertType === UserAlertViewTypes.RelativeTime;
  }

  public get isRatioAlert(): boolean {
    return this.alertType === UserAlertViewTypes.RatioAlert;
  }

  public get isMetricAlert(): boolean {
    return this.alertType === UserAlertViewTypes.MetricAlert;
  }

  public get showGroupBy(): boolean {
    return (
      !this.showMultiLevelGroupBy &&
      [ConditionsOptions.more, ConditionsOptions.moreThanUsual, ConditionsOptions.newValue].includes(
        this.formGroup.value.conditionOperator,
      ) &&
      !this.isRatioAlert &&
      !this.isRelativeTimeAlert
    );
  }

  public get showMultiLevelGroupBy(): boolean {
    return this.isMetricAlert || (this.formGroup.value.conditionOperator === ConditionsOptions.more && this.isTextAlert);
  }

  public get hasConditions(): boolean {
    return ![ConditionsOptions.newValue, ConditionsOptions.notifyImmediately].includes(this.formGroup.value.conditionOperator);
  }

  public get hideIsMoreThenDynamicAlertValue(): boolean {
    return (
      this.formGroup.value.conditionOperator === ConditionsOptions.notifyImmediately ||
      this.formGroup.value.conditionOperator === ConditionsOptions.moreThanUsual
    );
  }

  public get hideTimeWindowValue(): boolean {
    return this.formGroup.value.conditionOperator === ConditionsOptions.notifyImmediately;
  }

  public get isMoreThanDynamicAlert(): boolean {
    return this.formGroup.value.conditionOperator === ConditionsOptions.moreThanUsual;
  }

  public get relativeTimeSilenced(): string {
    const relativeTimeframe = this.formGroup.get('relativeTimeframe').value;
    const silencedFor = this.relativeTimeframeSilenceDisplay[relativeTimeframe];
    const silencedUntil = moment()
      .add(relativeTimeframe, 'minutes')
      .format(this.RELATIVE_TIME_SILENCE_TIME_FORMAT);
    return `This alert will be silenced for ${silencedFor} until ${silencedUntil}`;
  }

  public get isCardinalityGroupByControl(): FormGroup {
    return this.formGroup.get('isCardinalityGroupBy') as FormGroup;
  }

  public get cardinalityGroupByFieldsControl(): FormGroup {
    return this.formGroup.get('cardinalityGroupByFields') as FormGroup;
  }

  public get cardinalityValues(): number {
    const num = 10000 / this.cardinalityGroupByFieldsControl.value;
    return Math.floor(num);
  }

  public get conditionThresholdError(): string {
    return this.formGroup?.parent?.errors?.conditionThreshold;
  }

  public get groupByFieldsError(): string {
    return this.formGroup?.parent?.errors?.groupByFields;
  }

  @Input()
  public newMetricsMetricLabels: string[] = [];

  public timePicker: {
    hours: number[] | boolean;
    minutes: number[] | boolean;
  };

  public conditionMetricFieldOptions: [string, string][] = [];

  private logs2MetricsMetricFieldOptions: [string, string][] = [];
  private prometheusMetricFieldOptions: [string, string][] = [];
  private metricDefs: Metric[] = [];
  private RELATIVE_TIME_SILENCE_TIME_FORMAT: string = 'YYYY-MM-DD HH:mm';
  private _alertType: UserAlertViewTypes = UserAlertViewTypes.UserTextAlert;
  private _tempConditionsOperatorOptions: Entity[] = [];
  private _conditionsOperatorOptions: Entity[] = [];
  private refreshRelativeTime: any;
  private destroyed$ = new Subject<void>();
  constructor(private ref: ChangeDetectorRef) {}
  public conditionMetricFieldGroupBy = (option: [string, string]) =>
    option[1] === PrometheusMetricsMatcherId ? 'Prometheus Metrics' : 'L2M Metrics';

  public ngOnInit(): void {
    this.timePicker = {
      hours: this.createTimeArray(37),
      minutes: this.createTimeArray(60),
    };
    // to update time for silenced notification for relative time alerts
    this.refreshRelativeTime = setInterval(() => {
      this.ref.markForCheck();
    }, 30000);

    this.onConditionTypeChange();
    this.onTimeWindowChange();
    this.cardinalityGroupByFieldsChangeListener();
    if (this.isMetricAlert) {
      this.initMetricAlertListeners();
    }
  }

  public ngOnChanges(): void {
    if (!!this.formGroup && !isNil(this.alertType)) {
      if (this.isRelativeTimeAlert) {
        // to update condition timeframe and notification
        this.onRelativeTimeframeOptionsChange();
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    clearInterval(this.refreshRelativeTime);
  }

  public setStep(value: number, minValue: number, maxValue: number): Observable<number> {
    const step = 10;
    if (value < minValue) {
      return Observable.of(minValue);
    }

    if (value > maxValue) {
      return Observable.of(maxValue);
    }

    return Observable.of(step * Math.round(value / step));
  }

  public createTimeArray = (numOfItems: number, divideBy: number = 1, startFrom: number = 0): number[] | false => {
    if (divideBy === 0) {
      return false;
    }
    const arr = [];
    for (let i = startFrom; i < numOfItems / divideBy; i++) {
      arr.push(i * divideBy);
    }
    return arr;
  };

  public onRelativeTimeframeOptionsChange(): void {
    const relativeTimeframeOptionId: number = this.formGroup.get('relativeTimeframeOptionId').value;
    const { conditionTimeframe, relativeTimeframe } = this.relativeTimeframeOptionIdMapping[relativeTimeframeOptionId];
    this.formGroup.get('conditionTimeframe').setValue(conditionTimeframe);
    this.formGroup.get('relativeTimeframe').setValue(relativeTimeframe);
    this.updateTimeWindow();
  }

  public onTimeWindowChange(): void {
    const timeWindow = this.formGroup.get('conditionTimeframe');
    timeWindow.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.updateTimeWindow();
    });
  }

  public onConditionTypeChange(): void {
    const conditionOperator = this.formGroup.get('conditionOperator');
    conditionOperator.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      switch (value) {
        case ConditionsOptions.more:
        case ConditionsOptions.notifyImmediately:
          this.formGroup.get('notifyEveryMinutes').setValue(1);
          this.formGroup.get('notifyEveryHours').setValue(0);
          break;
        case ConditionsOptions.less:
          const timeWindow = this.formGroup.get('conditionTimeframe').value;
          this.formGroup.get('notifyEveryMinutes').setValue(timeWindow);
          break;
        case ConditionsOptions.moreThanUsual:
          const moreThanUsualConditionTimeFrame = 5;
          this.formGroup.get('conditionTimeframe').setValue(moreThanUsualConditionTimeFrame);
          break;
      }
    });
  }

  public hasError(controlName: string, errorCode: string): boolean {
    const control = this.formGroup.get(controlName);
    return control.hasError(errorCode) && control.touched;
  }

  // used in New value, Unique count and Metric alert
  public get firstGroupByField(): FormControl {
    return (this.formGroup.get('groupByFields') as FormArray).controls[0] as FormControl;
  }

  public firstGroupByFieldError(errorCode: string): boolean {
    const groupByFieldsControl = this.formGroup.get('groupByFields') as FormArray;
    const control = groupByFieldsControl && groupByFieldsControl.controls[0];
    return control && control.hasError(errorCode) && control.touched;
  }

  public onHoursChange(): void {
    const notifyEveryHours = this.formGroup.get('notifyEveryHours').value;
    if (notifyEveryHours === 36) {
      this.formGroup.get('notifyEveryMinutes').setValue(0);
      this.notifyEveryMinutesDisabled = true;
    } else {
      this.notifyEveryMinutesDisabled = false;
    }
  }

  public formatLabel(value: number): string {
    return value > 1 ? `${value} fields` : `${value} field`;
  }

  public isPercentileSelected(): boolean {
    return this.formGroup.get('conditionMetricArithmeticOperator').value === ArithmeticOptions.percentile;
  }

  public isSumOrCountSelected(): boolean {
    const arithmeticValue = this.formGroup.get('conditionMetricArithmeticOperator').value;

    return arithmeticValue === ArithmeticOptions.sum || arithmeticValue === ArithmeticOptions.count;
  }

  public isSwapNullValueChecked(): boolean {
    return this.formGroup.get('conditionSwapNullValue').value;
  }

  public onMetricConditionOperatorChanged(): void {
    if (this.formGroup?.get('conditionOperator')?.value === ConditionsOptions.less) {
      this.formGroup?.get('conditionSwapNullValue').setValue(true);
    } else {
      this.formGroup?.get('conditionSwapNullValue').setValue(false);
    }
  }

  private updateTimeWindow(): void {
    const timeWindow = this.formGroup.get('conditionTimeframe').value;
    const conditionOperator = this.formGroup.get('conditionOperator').value;
    if (conditionOperator === ConditionsOptions.less) {
      if (timeWindow < 60) {
        this.formGroup.get('notifyEveryMinutes').setValue(timeWindow);
        this.formGroup.get('notifyEveryHours').setValue(0);
      } else {
        this.formGroup.get('notifyEveryHours').setValue(timeWindow / 60);
        this.formGroup.get('notifyEveryMinutes').setValue(0);
      }
    }
  }

  private updateTempConditionsOperatorOptions(): void {
    if (this.isRatioAlert || this.isRelativeTimeAlert) {
      this._tempConditionsOperatorOptions = this._conditionsOperatorOptions.filter(
        o =>
          ![
            ConditionsOptions.notifyImmediately,
            ConditionsOptions.moreThanUsual,
            ConditionsOptions.newValue,
            ConditionsOptions.notifyImmediately.toString(),
            ConditionsOptions.moreThanUsual.toString(),
            ConditionsOptions.newValue.toString(),
          ].includes(o.id),
      );
    } else if (this.isMetricAlert) {
      this._tempConditionsOperatorOptions = this._conditionsOperatorOptions.filter(o =>
        [ConditionsOptions.more, ConditionsOptions.less, ConditionsOptions.more.toString(), ConditionsOptions.less.toString()].includes(
          o.id,
        ),
      );
    } else {
      this._tempConditionsOperatorOptions = this._conditionsOperatorOptions;
    }
    if (
      this.formGroup &&
      this.conditionsOperatorOptions &&
      !this.isNewValueAlert &&
      !this.conditionsOperatorOptions.map(v => v.id).includes(this.formGroup.controls.conditionOperator.value)
    ) {
      this.formGroup.controls.conditionOperator.setValue(ConditionsOptions.more);
    }
  }

  private primaryGroupByRequired: ValidatorFn = (_: AbstractControl): ValidationErrors | null => {
    const groupByFields: string[] = this.formGroup.get('groupByFields').value;
    const notNone = (field: string) => field && field !== 'None';
    return some(dropWhile(groupByFields, notNone), notNone) ? { primaryGroupByRequired: true } : null;
  };

  private subscribeOnConditionChanges(): void {
    const groupByCtrl = this.formGroup.get('groupByFields') as FormArray;
    const thresholdCtrl = this.formGroup.get('conditionThreshold');
    const timeFrameCtrl = this.formGroup.get('conditionTimeframe');
    const tenMinutes = 10;

    this.formGroup.setValidators(this.primaryGroupByRequired);

    this.formGroup
      .get('conditionOperator')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if (val === ConditionsOptions.notifyImmediately) {
          thresholdCtrl.disable();
          timeFrameCtrl.disable();
          groupByCtrl.setValidators(null);
        } else if (val === ConditionsOptions.moreThanUsual) {
          thresholdCtrl.enable();
          timeFrameCtrl.disable();
          timeFrameCtrl.setValue(tenMinutes);
          groupByCtrl.setValidators(null);
        } else if (val === ConditionsOptions.newValue) {
          thresholdCtrl.setValue(1);
          thresholdCtrl.disable();
          timeFrameCtrl.enable();
          timeFrameCtrl.setValue(TwelveHoursInMinutes);
          groupByCtrl.controls[0].setValidators([this.getGroupValidatorFunc(), Validators.required]);
        } else {
          thresholdCtrl.enable();
          timeFrameCtrl.enable();
          timeFrameCtrl.setValue(tenMinutes);
          groupByCtrl.setValidators(null);
        }
        timeFrameCtrl.updateValueAndValidity();
        groupByCtrl.updateValueAndValidity();
        thresholdCtrl.updateValueAndValidity();
      });
  }

  private getGroupValidatorFunc(): (control: FormControl) => { required: boolean } {
    return control => {
      return control.value && control.value.length && control.value !== 'None' ? null : { required: true };
    };
  }

  private getMetricFieldValidatorFunc(): (control: FormControl) => { required: boolean } {
    return control => {
      return !isNil(control.value) && control.value.length && !isEmpty(control.value[1]) ? null : { required: true };
    };
  }

  private getArithmeticModifierValidatorFunc(): (control: FormControl) => { required: boolean } {
    return control => {
      return !isNil(control.value) ? null : { required: true };
    };
  }

  private setNoneOption(options: string[]): void {
    options.unshift('None');
    this.options = options;
    this.optionsRequired = options.slice(1);
  }

  private cardinalityGroupByFieldsChangeListener(): void {
    this.cardinalityGroupByFieldsControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(currentValue => {
      if (!this.isCardinalityGroupByControl.value) {
        this.isCardinalityGroupByControl.patchValue(true as any);
      }
      const prevValue = this.formGroup.value.cardinalityGroupByFields;
      if (prevValue === currentValue) {
        return;
      }
      if (currentValue < 1 || currentValue > 1000) {
        return this.cardinalityGroupByFieldsControl.patchValue(prevValue);
      }
      if (!Number.isInteger(currentValue)) {
        return this.cardinalityGroupByFieldsControl.patchValue(round(currentValue) as any);
      }
    });
  }

  private initMetricAlertListeners(): void {
    const conditionMetricField = this.formGroup?.get('conditionMetricField');
    if (!this.isPromQLSyntax) {
      conditionMetricField?.setValidators([this.getMetricFieldValidatorFunc(), Validators.required]);
      conditionMetricField?.updateValueAndValidity();
    }
    this.updateGroupByLabels(conditionMetricField?.value?.[1]);

    this.formGroup
      ?.get('conditionMetricField')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(([fieldName, matcherId]) => {
        if (matcherId === PrometheusMetricsMatcherId) {
          this.setNoneOption(this.newMetricsMetricLabels.map(l => l));
        } else {
          this.updateGroupByLabels(matcherId);
        }
      });

    this.formGroup
      ?.get('conditionSampleThreshold')
      .valueChanges.pipe(
        takeUntil(this.destroyed$),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(value => this.setStep(value, 0, 90)),
      )
      .subscribe(value => {
        const control = this.formGroup?.get('conditionSampleThreshold');
        control.patchValue(value);
      });

    this.formGroup
      ?.get('conditionNonNullPercentage')
      .valueChanges.pipe(
        takeUntil(this.destroyed$),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(value => this.setStep(value, 0, 100)),
      )
      .subscribe(value => {
        const control = this.formGroup?.get('conditionNonNullPercentage');
        control.patchValue(value);
      });

    this.formGroup
      ?.get('conditionMetricArithmeticOperator')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(_ => {
        if (this.isPercentileSelected()) {
          const conditionMetricArithmeticModifier = this.formGroup.get('conditionMetricArithmeticModifier');
          conditionMetricArithmeticModifier.setValidators([this.getArithmeticModifierValidatorFunc(), Validators.required]);
          conditionMetricArithmeticModifier.markAsTouched();
          conditionMetricArithmeticModifier.updateValueAndValidity();
        }
      });
  }

  private updateGroupByLabels(matcherId: any): void {
    if (matcherId && this.metricDefs.length > 0) {
      const selectedMetric = this.metricDefs.find(m => m.id === matcherId);
      const labelNames = selectedMetric?.groupBy?.map(label => label.name);
      labelNames?.length > 0 ? this.setNoneOption(labelNames) : (this.options = []);
    }
  }

  private getGroupByOptions(options: string[]): string[] {
    if (this.isNewValueAlert) {
      const newValueExcludedFields = ['coralogix.text'];
      return options.filter(option => !newValueExcludedFields.includes(option));
    }
    return options;
  }
}
