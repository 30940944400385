
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/shared/services/auth.service';
import { GetUserPermission } from '@app/ngxs-store/permissions/permissions.action';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';

interface IPermissionsModel {
  [key: number]: boolean;
  isLoading: boolean;
}

const initialState = (): IPermissionsModel => {
  return {
    isLoading: false
  };
};

@State<IPermissionsModel>({
  name: 'permissions',
  defaults: initialState(),
})
@Injectable()
export class PermissionsState {
  constructor(private authService: AuthService) {}

  @Selector()
  public static isLoading(state: IPermissionsModel): boolean {
    return state?.isLoading;
  }

  public static getPermission(resourceId: number): (state: IPermissionsModel) => boolean {
    return createSelector([PermissionsState], (state: IPermissionsModel) => {
      return state[resourceId];
    });
  }

  @Action(GetUserPermission)
  public GetUserPermission(ctx: StateContext<IPermissionsModel>, { resourceId, actionId }: GetUserPermission): any {
    ctx.patchState({ isLoading: true } );
    return this.authService.enforceUserPermission(resourceId, actionId).pipe(
      map((res) => {
        ctx.patchState({ [resourceId] : res.hasPermission } );
      }),
      catchError((err) => {
        ctx.patchState({ [resourceId] : false } );
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ isLoading: false } );
      })
    );
  }
}
