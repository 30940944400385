<div class="main-container">
    <p class="header">Operators</p>
    <div class="operators-container">
        <p
                class="operator"
                *ngFor="let enum of tableSections | keyvalue:sortingCallback"
                (click)="onSectionSelect(enum.key.toString())"
        >
            {{ enum.value}}
        </p>
    </div>
    <cgx-button
      label="FULL REGEX TUTORIAL"
      href="https://help.coralogix.com/en/articles/3224219-rules-cheat-sheet"
      size="sm"
      width="170px"
      textNoWrap
      newTab
    ></cgx-button>
</div>

