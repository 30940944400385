import { Injectable } from '@angular/core';
import { ShDialogService } from './dialogService';

@Injectable()
export class ClipboardService {
  constructor(private dialogService: ShDialogService) {}

  public copyToClipboard(value: string, notify: boolean = false) {
    const container = document.createElement('div');
    container.setAttribute(
      'style',
      [
        ,
        'position: fixed;',
        'left: 0px;',
        'top: 0px;',
        'width: 0px;',
        'height: 0px;',
        'opacity: 0;',
      ].join(''),
    );
    document.body.appendChild(container);
    const textNode = document.createTextNode(value);
    container.appendChild(textNode);
    const range = document.createRange();
    range.selectNode(container);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    document.body.removeChild(container);

    if (notify) {
      this.dialogService.showSnackBar('"' + value + '"' + ' Copy to clipboard');
    }
  }
}
