import { EventEmitter } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ColDef, IGetRowsParams } from 'ag-grid';
import * as _ from 'lodash';

import { Log } from '../models/log.entity';
import { LogqueryResult } from '../models/logquery.response';
import { LogQueryData } from '../models/logquery.data';
import { LogQueryModel } from '../models/logsquery.model';
import { LogsService } from './logs-service';
import { State } from '@app/app.reducers';
import { QueryParams } from '../models/query-params.model';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { Metadata } from '../models/query-metadata';
import { ExportActions } from '../state/export/export.actions';
import { GridComponent } from '@app/shared/controls/grid';
import { MasterFilterComponent } from '@app/shared/controls/grid-filters/master-filter/master-filter.component';
import { FilterOption } from '@app/shared/controls/grid-filters/grid-options-filter/filter-option';
import { switchMap, take } from 'rxjs/operators';

export class LogManager {
  public logPages: Map<number, Log[]> = new Map<number, Log[]>();
  public currentPage: number;
  public totalQueryLogCount: number = 0;
  public logQueryModel: LogQueryModel;
  public startLoadingData: Subject<any> = new Subject<any>();
  public dataLoaded: Subject<any> = new Subject<any>();
  public onFilterChanged: EventEmitter<LogQueryModel> = new EventEmitter<LogQueryModel>();
  public startFromIndex: number;
  public lastRequestedIndex: number;
  public highlightedLogs: Map<string, string> = new Map<string, string>();
  private filterNames: { prefix: string, names: string[] } = {
    prefix: 'metadata',
    names: [
      'threadId',
      'severity',
      'subsystemName',
      'applicationName',
      'category',
      'className',
      'computerName',
      'IPAddress',
      'methodName'
    ]
  };

  constructor(
    private logsService: LogsService,
    private htmlRowMeasure: HtmlRowMeasure,
    private store: Store<State>) {
  }

  public setQueryResult(logQueryData: LogQueryData): void {
    this.logsService = logQueryData.logsService;
    this.totalQueryLogCount = logQueryData.logQueryResult.total;
    this.logQueryModel = logQueryData.logQueryModel;
    // set first result inside the cache
    this.logPages.clear();
    this.logPages.set(this.logQueryModel.pageIndex, logQueryData.logQueryResult.logs);
    this.store.dispatch(new ExportActions.UpdateLogPages(Array.from(this.logPages.keys())));
    this.store.dispatch(new ExportActions.UpdateDateRange(this.getDateRange()));
    this.currentPage = this.logQueryModel.pageIndex;
  }

  public getRows(params: IGetRowsParams): void {
    if (!isNaN(params.startRow) && !isNaN(params.endRow)) {

      // publish start loading event
      this.startLoadingData.next({});
      this.lastRequestedIndex = this.getPageIndex(params);
      let queryModified: boolean = false;
      // params.startRow = 5000;
      // params.endRow = 10000;
      // if grid has a new sort model, run new query
      if (params.sortModel.length && !this.logQueryModel.isSortModelEqual(params.sortModel)) {
        this.logQueryModel.setSortModel(params.sortModel);
        queryModified = true;
      }
      // if sort model returned back to default, run a new query
      // with default sortModel
      /*else if (!params.sortModel.length && !this.logQueryModel.isDefaultSortValue()) {
        this.logQueryModel.returnToDefaultSortValue();
        queryModified = true;
      }*/
      if (params.filterModel) {
        let colDefs = null;
        if (params.context &&
          params.context.gridOptions &&
          params.context.gridOptions.columnDefs) {
          colDefs = params.context.gridOptions.columnDefs;
        }

        this.filterNames.names.forEach(currFilter => {
          const currParam = this.logQueryModel.queryParams.metadata[currFilter];

          if (currParam?.length > 0) {
            const filterOptions =
              this.getFilterOptions(params, currFilter);

            if (filterOptions) {
              this.logQueryModel.queryParams.metadata[currFilter] =
                currParam.filter(name =>
                  filterOptions.find(subSystem =>
                    subSystem.value === name));
            }
          }
        });

        queryModified = this.updateQueryFilterIfRequired(this.logQueryModel, params.filterModel, colDefs) || queryModified;
      }
      if (queryModified) {
        this.onFilterChanged.emit(this.logQueryModel);
        this.getNewQuery(params);
      } else {
        // try get logs from cache, if not take from server
        if (!this.getLogsFromCache(params)) {
          this.getLogsFromService(params, false);
        }
      }
    }
  }

  public updateFilters(params: QueryParams): void {
    this.logQueryModel.queryParams = params;
  }

  public updateQueryFilterIfRequired(queryModel: LogQueryModel, filters: any, colDefs: ColDef[] = null): boolean {
    let filterModified = false;

    if (!colDefs || colDefs.find(col => col.field === 'text')) {
      const textFilter = filters['text'];
      if (_.isUndefined(textFilter)) {
        if (queryModel.queryParams.query.text !== '') {
          queryModel.queryParams.query.text = '';
          filterModified = true;
        }
      } else if (textFilter !== queryModel.queryParams.query.text) {
        queryModel.queryParams.query.text = textFilter;
        filterModified = true;
      }
    }

    filterModified = this.filterNames.names.reduce((accumulator, currName) => this.updateQueryMetadataField(
      queryModel.queryParams.metadata, filters[`${this.filterNames.prefix}.${currName}`], currName) ||
      accumulator,
      filterModified);

    filterModified = this.updateQueryJsonObjectField(queryModel.queryParams, filters) || filterModified;

    return filterModified;
  }

  public updateQueryJsonObjectField(queryParams: QueryParams, filters: any): boolean {
    if (filters) {
      const filterKeys = _.keys(filters).filter(f => f.indexOf('textObject.') >= 0);
      if (filterKeys && filterKeys.length > 0) {
        const jsonFilters = {};
        for (const key in filters) {
          if (key.startsWith('textObject.')) {
            jsonFilters[key.replace('textObject.', '')] = filters[key];
          }
        }
        if (!_.isEqual(queryParams.jsonObject, jsonFilters)) {
          queryParams.jsonObject = jsonFilters;
          return true;
        }
      }
    }
    return false;
  }

  public updateQueryMetadataField(metadata: Metadata, filter: any, fieldName: string): boolean {
    if (_.isArray(filter) && _.isArray(metadata[fieldName])) {
      filter.sort();
      metadata[fieldName].sort();
    }
    if (!_.isEqual(metadata[fieldName], filter)) {
      if (filter && ((_.isArray(filter) && filter.length) || !_.isArray(filter))) {
        metadata[fieldName] = filter;
        return true;
      }
    }
    if (!_.isArray(metadata[fieldName]) && (metadata[fieldName] !== filter) && (metadata[fieldName] || filter)) {
      metadata[fieldName] = filter;
      return true;

    }

    return false;
  }

  /**
   * get new query from server
   */
  public getNewQuery(params: IGetRowsParams): void {
    // clean cache
    this.logPages = new Map<number, Log[]>();
    this.store.dispatch(new ExportActions.UpdateLogPages([]));
    this.store.dispatch(new ExportActions.UpdateDateRange(
      new Map<string, Date>().set('min', null).set('max', null)
    ));
    // this.getLogsFromService(params, true);
    this.logQueryModel.cacheQueryId = this.logsService.getNewQueryId();
    this.logsService.onNewQueryId.emit(this.logQueryModel.cacheQueryId);
    this.logsService.newQueryRequest.emit(this.logQueryModel);
  }

  public getPageIndex(params: IGetRowsParams): number {
    return Math.floor(params.startRow / this.logQueryModel.pageSize);
  }

  /**
   * get logs from server
   * params: grid request params: firstRow, lastRow
   */
  private getLogsFromService(params: IGetRowsParams, publishResult: boolean): void {
    const pageIndex = this.getPageIndex(params);
    this.logQueryModel.pageIndex = pageIndex;

    this.logsService.getLogs(this.logQueryModel).pipe(
      take(1),
      switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes)))
      .subscribe(
        logsRes => this.processQueryResult(logsRes, params),
        error => console.log(error));
  }

  /**
   * process query result returned from server
   */
  private processQueryResult(logRes: LogqueryResult, params: IGetRowsParams): void {
    this.totalQueryLogCount = logRes.total || logRes.logs.length;
    this.appendLogs(logRes, params);
    if (!this.getLogsFromCache(params)) {
      params.failCallback();
      console.log('failed to get data from cache');
    }
  }

  /**
   * append new logs to cache
   */
  private appendLogs(logRes: LogqueryResult, params: IGetRowsParams): void {
    const pageIndex = this.getPageIndex(params);
    this.logPages.set(pageIndex, logRes.logs);
    this.currentPage = pageIndex;
    this.store.dispatch(new ExportActions.UpdateLogPages(Array.from(this.logPages.keys())));
    this.store.dispatch(new ExportActions.UpdateDateRange(this.getDateRange()));
  }

  /**
   * try get logs from local cache.
   * return: true if success
   */
  private getLogsFromCache(params: IGetRowsParams): boolean {
    // calculate page index
    const pageIndex = this.getPageIndex(params);

    // get page from Map
    const page: any[] = this.logPages.get(pageIndex);
    if (page) {
      // calculate first and last row inside the cached page
      const pageStartRowIndex = params.startRow % this.logQueryModel.pageSize;
      let pageEndRowIndex = params.endRow % this.logQueryModel.pageSize;

      if (!pageEndRowIndex) {
        pageEndRowIndex = page.length;
      }
      const result = page.slice(pageStartRowIndex, pageEndRowIndex);
      if (result) {
        // call grid success callback with the result, and the total logs count (for the virtual scroller size)
        params.successCallback(result, this.totalQueryLogCount);
        if (pageIndex === this.lastRequestedIndex) {
          this.dataLoaded.next({});
        }
      }
      return true;
    }
    return false;
  }

  private getDateRange(): Map<string, Date> {
    const flatArray = [];
    this.logPages.forEach((logs, page) => {
      flatArray.push(...logs.map(l => l.timestamp));
    });
    const m: Map<string, Date> = new Map<string, Date>();
    m.set('min', new Date(Math.min.apply(null, flatArray)));
    m.set('max', new Date(Math.max.apply(null, flatArray)));
    return m;
  }

  private getFilterOptions(params: IGetRowsParams, currFilter: string): FilterOption[] {
    const filterInstance =
      (params?.context as GridComponent)?.
        gridOptions.api?.getFilterInstance(`${this.filterNames.prefix}.${currFilter}`);

    if (_.isObject(filterInstance) && 'getFrameworkComponentInstance' in filterInstance) {
      return (filterInstance.getFrameworkComponentInstance() as MasterFilterComponent)?.
        gridOptionsFilter.filterOptions;
    } else {
      return null;
    }
  }
}
