import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Scope } from '@sentry/angular';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Observable } from 'rxjs';
import { Teammate } from '@shared/models/teammate.model';
import { filterFalsy } from '@app/logs-new/shared/operators/filter-falsy.operator';

interface ITag {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private scope: Scope;
  @Select(UserState.user) private user$: Observable<Teammate>;

  init(): void {
    this.initScope();
    this.onUserChange();
  }

  setUser(user: Teammate): void {
    this.scope.setUser(user);
  }

  setTags(tags: ITag[]): void {
    tags.forEach(({ key, value }) => {
      this.setTag(key, value);
    });
  }

  setTag(key: string, value: string): void {
    this.scope.setTag(key, value);
  }

  setExtra(key: string, value: string): void {
    this.scope.setExtra(key, value);
  }

  private initScope(): void {
    Sentry.configureScope(scope => {
      this.scope = scope;
    });
  }

  private onUserChange(): void {
    this.user$.pipe(filterFalsy()).subscribe(user => {
      this.setUser(user);
    });
  }
}
