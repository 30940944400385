<div
  class="main-loader"
  data-test="coralogix-loading-spinner"
>
  <div class="logo-container">
    <svg
      id="eTq6GibHLa41"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 144 144"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      width="144"
      height="144"
    >
      <style>
        #eTq6GibHLa44_ts {
          animation: eTq6GibHLa44_ts__ts 2000ms linear infinite normal forwards
        }

        @keyframes eTq6GibHLa44_ts__ts {
          0% {
            transform: translate(72px, 72px) scale(1, 1);
            animation-timing-function: cubic-bezier(0.42, 0, 1, 1)
          }

          50% {
            transform: translate(72px, 72px) scale(0.5, 0.5)
          }

          75% {
            transform: translate(72px, 72px) scale(0.75, 0.75);
            animation-timing-function: cubic-bezier(0, 0, 0.58, 1)
          }

          100% {
            transform: translate(72px, 72px) scale(1, 1)
          }
        }

        #eTq6GibHLa45_ts {
          animation: eTq6GibHLa45_ts__ts 2000ms linear infinite normal forwards
        }

        @keyframes eTq6GibHLa45_ts__ts {
          0% {
            transform: translate(72.5px, 72.5px) scale(1, 1);
            animation-timing-function: cubic-bezier(0.42, 0, 1, 1)
          }

          25% {
            transform: translate(72.5px, 72.5px) scale(0.5, 0.5)
          }

          50% {
            transform: translate(72.5px, 72.5px) scale(0.5, 0.5);
            animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
          }

          100% {
            transform: translate(72.5px, 72.5px) scale(1, 1)
          }
        }
      </style>
      <g id="eTq6GibHLa42">
        <g id="eTq6GibHLa43">
          <g
            id="eTq6GibHLa44_ts"
            transform="translate(72,72) scale(1,1)"
          >
            <path
              id="eTq6GibHLa44"
              d="M72.011401,144C42.888662,144.004185,16.631402,126.463739,5.484678,99.558637C-5.662045,72.653535,0.497222,41.682995,21.090108,21.090108C41.682995,0.497222,72.653535,-5.662045,99.558637,5.484678C126.463739,16.631402,144.004185,42.888662,144,72.011401C143.948685,111.748331,111.748331,143.948685,72.011401,144Z"
              transform="translate(-72,-72)"
              fill="rgb(174,255,220)"
              stroke="none"
              stroke-width="1"
            />
          </g>
          <g
            id="eTq6GibHLa45_ts"
            transform="translate(72.5,72.5) scale(1,1)"
          >
            <circle
              id="eTq6GibHLa45"
              r="57.5"
              transform="translate(0,0)"
              fill="rgb(60,196,143)"
              stroke="none"
              stroke-width="1"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</div>