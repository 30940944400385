<div class="tags-header" fxLayout="column">
  <!--  <div fxLayout="row" *ngIf="selectedTag" (click)="goBack.emit()" class="tags-header__back">-->
  <!--    <i class="fa fa-chevron-left" aria-hidden="true"></i> Back-->
  <!--  </div>-->
  <div class="coralogix input-group relative">
    <input type="text" placeholder="Search" class="form-control" [formControl]="searchControl" />
    <mat-icon aria-hidden="false" class="search-icon" aria-label="Example home icon" matSuffix>
      <i class="fa fa-search" aria-hidden="true"></i>
    </mat-icon>
  </div>
</div>
<div class="tags-list">
  <div *ngIf="!loading">
    <sh-tags-list-item
      [class.selected]="selectedTag?.id == tag.id"
      *ngFor="
        let tag of tagsList | filterPipe: searchControl.value:['text_key', 'application_name', 'subsystem_name', 'text_value'];
        let index = index
      "
      [tagData]="tag"
      (deleteTag)="deleteTag.emit($event)"
      (editTag)="editTag.emit($event)"
      (tagSelected)="tagPressed($event)"
      [isSelected]="selectedTag?.id == tag.id"
    ></sh-tags-list-item>
  </div>
  <div *ngIf="loading">
    <sh-preview-placeholder></sh-preview-placeholder>
    <sh-preview-placeholder></sh-preview-placeholder>
  </div>
</div>
