import { jsonObject, jsonMember } from 'typedjson';
import { LogMetadata } from './log-metadata.model';
import { TypedJsonAny } from '@shared/models/typedjson/any';
import { MeasureOject } from '@shared/services/htmlRowMeasure.service';

@jsonObject
export class Log implements MeasureOject {
  public static s_id: number = 0;
  @jsonMember
  public branchId: string;
  @jsonMember
  public logId: string;
  @jsonMember
  public metadata: LogMetadata;
  @jsonMember
  public templateId: string;
  @jsonMember
  public text: string;
  @jsonMember
  public timestamp: number;
  @jsonMember
  public index: number;
  @jsonMember
  public isHighlighted: boolean;
  @jsonMember
  public id: number;
  @jsonMember
  public jsonUuid: string;
  @jsonMember
  public viewObject: string;
  @jsonMember
  public showMore: boolean;
  @jsonMember(TypedJsonAny.OPTIONS)
  public highlight: { [key: string]: any[]; };
  private _textObject: object;
  private isParsed: boolean;

  constructor() {
    this.id = Log.s_id++;
  }

  get isJson(): boolean {
    return this.jsonUuid ? true : false;
  }

  get textObject(): object {
    try {
      if (!this.isParsed) {
        this._textObject = null;
        this._textObject = JSON.parse(this.text);
        return this._textObject;
      }
      return this._textObject;
    } catch (e) {
      return null;
    }
    finally {
      this.isParsed = true;
    }
  }

  set textObject(value: object) {
    this._textObject = value;
    this.isParsed = true;
  }
}
