import { FormGroup } from '@angular/forms';
import { Plugin } from '@app/logs-new/components/plugins-container/model/plugin.model.ts';
import { PluginTypes } from '@shared/enums/plugin-types.enum';
export class GetPlugins {
  public static readonly type: string = '[Plugins] Get Plugins';
}

export class GetLogFields {
  public static readonly type: string = '[Plugins] Get Log Fields';
}

export class UpdatePlugin {
  public static readonly type: string = '[Plugins] Update Plugins';
  constructor(public plugin: Plugin) {}
}

export class CreatePlugin {
  public static readonly type: string = '[Plugins] Create Plugins';
  constructor(public plugin: Plugin) {}
}

export class DeletePlugin {
  public static readonly type: string = '[Plugins] Delete Plugin';
  constructor(public id: string) {}
}

export class UpdatePluginsOrder {
  public static readonly type: string = '[Plugins] Update Plugins Order';
  constructor(public type: PluginTypes, public ids: string[]) {}
}
