import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { ChartModel } from '../models/chart.model';
import { formatsHelper } from '@shared/services/formatsHelper';

@Injectable()
export class ChartFormatterService {

  public labelFormatter(context: any, data: ChartModel, isLocalTime: boolean): string {
    const highlighted = data.highlightedXlables.get(context.value);

    if (data.xType !== 'timestamp') {
      let labelValue = context.value;

      if (context.value.length > 20) {
        labelValue = formatsHelper.stringSub(context.value, 10);
      }

      if (highlighted) {
        return (
          '<span style="font-weight: 800; fill:' +
          highlighted +
          ';">' +
          labelValue +
          '</span>'
        );
      } else {
        return context.value;
      }
    }

    const dateFormat = formatsHelper.getDateFormatByScale(data.scale);
      if (highlighted) {
        const value = data.categories[context.value]
          ? moment(data.categories[context.value]).utc(isLocalTime).format(dateFormat)
          : '';
        return (
          '<span style="font-size: 10px; font-weight: 800; fill:' +
          highlighted +
          ';">' +
          value +
          '</span>'
        );
      }

      return data.categories[context.value]
        ? moment(data.categories[context.value]).utc(isLocalTime).format(dateFormat)
        : '';
  }

  public defaultTooltipFormatter(context: any, data: ChartModel, isLocalTime: boolean): any {
    if (data.activeBuildTooltip) {
      return;
    }

    let tooltipText =
      '<div id="infoBarTooltip" class="' + data.infoBarClass + 'layout">';

    if (context.x || context.x === 0) {
      tooltipText = this.getFormattedTooltipForXValue(
        context,
        data,
        tooltipText,
        isLocalTime
      );
      tooltipText += '</div>';
    }

    const r = 'textalignright';
    if (
      context.y &&
      context.point &&
      context.y > 0 &&
      context.point.percentage
    ) {
      tooltipText += '<div class="series container ' + r + '">';
      tooltipText = this.getFormattedSeriesPercantage(context, tooltipText);
    }
    tooltipText += '</div>';
    context.infoBarEl.nativeElement.innerHTML = tooltipText;
    return null;
  }

  public customTooltipFormatter(context: any, data: ChartModel, isLocalTime: boolean): string {
    let tooltipText = '<div class="' + data.infoBarClass + 'layout">';

    tooltipText +=
      '<div class="' +
      'item-title">' +
      moment(data.categories[context.x]).utc(isLocalTime).format(data.tooltipDateFormat) +
      '</div>';
    tooltipText += '<div class="' + 'series container">';

    context.points.forEach((point) => {
      let result;
      if (point.y % 1 === 0) {
        result = point.y;
      } else {
        result = point.y.toFixed(2);
      }

      tooltipText +=
        '<div style=" font-size: 11px; !important;margin-top: 3px; " class="' +
        'series value' +
        '">' +
        formatsHelper.numberKM(result) +
        data.tooltipValueSuffix +
        '</div>' +
        '<div style=" font-size: 11px; !important;margin-top: 3px; " class="' +
        'item-' +
        point.series.name.toLowerCase() +
        '">' +
        point.series.name +
        '</div>';
    });
    tooltipText += '</div>';
    tooltipText += '</div>';
    context.infoBarEl.nativeElement.innerHTML = tooltipText;
    return null;
  }

  private getFormattedTooltipForXValue(
    context: any,
    data: ChartModel,
    tooltipText: string,
    isLocalTime: boolean
  ): string {
    if (data.xType !== 'timestamp') {
      const substring = formatsHelper.stringSub(
        data.categories[context.x],
        512,
      );
      tooltipText +=
        '<div class="' +
        'item-title">' +
        substring +
        data.tooltipValueSuffix +
        '</div>';
    } else {
      const formattedData = moment(data.categories[context.x]).utc(isLocalTime).format(
        data.tooltipDateFormat,
      );
      tooltipText += '<div class="' + 'item-title">' + formattedData + '</div>';
    }

    tooltipText += '<div class="' + 'series container">';

    if (!data.sharedTooltip) {
      tooltipText = this.getFormattedSeriesNumbers(context, data, tooltipText);
    } else {
      const rangePoint = context.points.find(
        (p) => p.point.low || p.point.low === 0,
      );
      const selected = context.points.find(
        (p) => p.point.series.name === 'occurrences',
      );

      if (rangePoint && selected) {
        tooltipText +=
          '<div class="' +
          'item-' +
          selected.series.name.toLowerCase() +
          '">' +
          selected.series.name +
          '</div>' +
          '<div class="' +
          'series value">' +
          formatsHelper.numberKM(selected.y) +
          data.tooltipValueSuffix +
          '</div>';

        tooltipText +=
          '<div class="' +
          'item-' +
          'normal' +
          '">' +
          'normal' +
          '</div>' +
          '<div class="' +
          'series value">' +
          formatsHelper.numberKM(rangePoint.point.low) +
          ' - ' +
          formatsHelper.numberKM(rangePoint.point.high) +
          data.tooltipValueSuffix +
          '</div>';

        if (selected.y > rangePoint.point.high) {
          tooltipText +=
            '<div class="' +
            'item-' +
            'anomaly' +
            '">' +
            'anomaly' +
            '</div>' +
            '<div class="' +
            'series value">' +
            formatsHelper.numberKM(selected.y - rangePoint.point.high) +
            ' above normal range' +
            '</div>';
        }
        if (selected.y < rangePoint.point.low) {
          tooltipText +=
            '<div class="' +
            'item-' +
            'anomaly' +
            '">' +
            'anomaly' +
            '</div>' +
            '<div class="' +
            'series value">' +
            formatsHelper.numberKM(rangePoint.point.low - selected.y) +
            ' below normal range' +
            '</div>';
        }
      } else {
        context.points.forEach((point) => {
          tooltipText = this.getFormattedSeriesNumbers(
            point,
            data,
            tooltipText,
          );
        });
      }
    }
    return tooltipText;
  }

  private getFormattedSeriesNumbers(
    point: any,
    data: ChartModel,
    tooltipText: string,
  ): any {
    let result;

    if (point.yType !== 'logarithmic') {
      point.y % 1 === 0 ? (result = point.y) : (result = point.y.toFixed(2));
    } else {
      result = point.y === 0.0001 ? 0 : point.y;
    }

    tooltipText +=
      '<div class="' +
      'item-' +
      point.series.name.toLowerCase() +
      '">' +
      point.series.name +
      '</div>' +
      '<div class="' +
      'series value">' +
      formatsHelper.numberKM(result) +
      data.tooltipValueSuffix +
      '</div>';

    return tooltipText;
  }

  private getFormattedSeriesPercantage(
    context: any,
    tooltipText: string,
  ): string {
    let result;

    context.point.percentage % 1 === 0
      ? (result = context.point.percentage)
      : (result = context.point.percentage.toFixed(2));

    tooltipText +=
      '<div class="' +
      'item-' +
      context.key.toLowerCase() +
      '">' +
      context.key +
      '</div>' +
      '<div class="' +
      'series value">' +
      formatsHelper.numberKM(context.y) +
      ' - ' +
      +result +
      '%' +
      '</div>';
    tooltipText += '</div>';

    return tooltipText;
  }
}
