<div fxLayout="row" class="login-container" fxFlex="100">
  <div fxLayout="column" class="content-container" fxFlex="auto">
    <sh-auth-header [showSignUpText]="!mode?.hideSignUpInHeaderModes"></sh-auth-header>
    <div fxFlexAlign="center" fxLayout="column" class="main-content" fxLayoutAlign="center center">
      <ng-container *ngIf="hasSso || teamName">
        <div *ngIf="teamLogoSrc; else defaultLogoBlock" class="main-content__company-logo">
          <img [src]="teamLogoSrc" />
        </div>
        <ng-template #defaultLogoBlock>
          <div class="main-content__team-icon">
            <i class="fa fa-group"></i>
          </div>
        </ng-template>
      </ng-container>

      <div *ngIf="mode?.title" class="auth-title {{ mode?.titleClass ? mode.titleClass : '' }}">{{ mode.title }}</div>
      <div *ngIf="teamName">
        <ng-template [ngTemplateOutlet]="teamNameTemplate"></ng-template>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div fxLayout="column" class="side-bar" fxFlex="33">
    <div fxFlexAlign="center" class="side-bar__images" fxLayoutAlign="center center">
      <img
        #img
        *ngIf="mode && mode?.sideImageName === imagesEnum?.coralogixFlow"
        alt="{{ mode?.sideImageName }}"
        (error)="img.style.display = 'none'"
        [class]="mode?.sideImageClass ? 'side-bar__' + mode?.sideImageClass : ''"
        [src]="getCurrentModeImage"
      />
      <img
        #img
        *ngIf="mode && mode?.sideImageName !== imagesEnum?.coralogixFlow"
        alt="{{ mode?.sideImageName }}"
        (error)="img.style.display = 'none'"
        [class]="mode?.sideImageClass ? 'side-bar__' + mode?.sideImageClass : ''"
        [src]="getCurrentModeImage"
      />
    </div>
  </div>
  <div *ngIf="isLoading" class="ui active inverted dimmer">
    <div class="ui text loader"></div>
  </div>
</div>

<ng-template #teamNameTemplate>
  <div class="team-item-container">
    <div class="team-item-container__team-item">
      <div class="team-item-container__user-box">
        <div class="team-item-container__use-account-caption">
          Login to:
          <span class="team-item-container__team-name">{{ teamName }}</span>
        </div>
        <div class="team-item-container__team-url">{{ teamName }}.{{ appUrlEnding }}</div>
      </div>
    </div>
  </div>
</ng-template>
