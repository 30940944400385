import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArchivingProviderModel } from '../../../shared/models/archivingProviderModel';
import { ArchivingFormProviderService } from '../../../shared/services/archiving-form-provider';
import { FormGroup } from '@angular/forms';
import { ArchivingProvidersReducer } from '../../../reducer/archiving-providers/archiving-providers.reducer';
import TestingResultState = ArchivingProvidersReducer.TestingResultState;

@Component({
  selector: 'sh-archiving-providers-item-details',
  templateUrl: './archiving-providers-item-details.component.html',
  styleUrls: ['./archiving-providers-item-details.component.scss'],
})
export class ArchivingProvidersItemDetailsComponent implements OnInit {
  public regionOptions: {id: string, name: string}[] = [
    { id: 'eu-west-1', name: 'EU (Ireland)' },
    { id: 'ap-south-1', name: 'Asia Pacific (Mumbai)' },
    { id: 'us-east-1', name: 'US East (N. Virginia)' },
    { id: 'us-east-2', name: 'US East (Ohio)' },
    { id: 'us-west-1', name: 'US West (N. California)' },
    { id: 'us-west-2', name: 'US West (Oregon)' },
    { id: 'eu-central-1', name: 'EU (Frankfurt)' },
    { id: 'ca-central-1', name: 'Canada (Central)' },
    { id: 'eu-west-2', name: 'EU (London)' },
    { id: 'eu-west-3', name: 'EU (Paris)' },
    { id: 'eu-north-1', name: 'EU (Stockholm)' },
    { id: 'ap-east-1', name: 'Asia Pacific (Hong Kong)' },
    { id: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)' },
    { id: 'ap-northeast-2', name: 'Asia Pacific (Seoul)' },
    { id: 'ap-northeast-3', name: 'Asia Pacific (Osaka-Local)' },
    { id: 'ap-southeast-1', name: 'Asia Pacific (Singapore)' },
    { id: 'ap-southeast-2', name: 'Asia Pacific (Sydney)' },
    { id: 'sa-east-1', name: 'South America (São Paulo)' }
  ];

  @Input() set archivingProvidersItem(value: ArchivingProviderModel) {
    if (!value) {
      return;
    }

    this._archivingProvidersItem = value;
    this.formProvider.updateForm(value);
  }

  get archivingProvidersItem(): ArchivingProviderModel {
    return this._archivingProvidersItem;
  }

  public get archivingForm(): FormGroup {
    if (!this.formProvider.archivingForm) {
      this.formProvider.generateNewForm(this.formProvider.defaultValues);
    }
    return this.formProvider.getForm();
  }
  public _archivingProvidersItem: ArchivingProviderModel;

  @Input() public loading: boolean;
  @Input() public saving: boolean;
  @Input() public testing: boolean;
  @Input() public testingResult: TestingResultState;

  @Output() public saveArchivingProvider: EventEmitter<
    ArchivingProviderModel
  > = new EventEmitter<ArchivingProviderModel>();
  @Output() public testArchivingProvider: EventEmitter<
    ArchivingProviderModel
  > = new EventEmitter<ArchivingProviderModel>();

  constructor(public formProvider: ArchivingFormProviderService) {}

  public getIsFieldInvalid(fieldName: string): boolean {
    return (
      this.archivingForm.get(fieldName).enabled &&
      this.archivingForm.get(fieldName).invalid
    );
  }

  public test() {
    if (!this.archivingForm.valid || this.saving || this.testing) {
      return;
    }
    this.testArchivingProvider.emit(this.archivingForm.value);
  }

  public submit() {
    this.saveArchivingProvider.emit(this.archivingForm.value);
  }

  public ngOnInit() {}

  public change(value) {
    if (value.checked) {
      this.archivingForm.controls['region'].enable({
        onlySelf: false,
        emitEvent: false,
      });
      this.archivingForm.controls['name'].enable({
        onlySelf: false,
        emitEvent: false,
      });
    } else {
      this.archivingForm.controls['region'].disable({
        onlySelf: false,
        emitEvent: false,
      });
      this.archivingForm.controls['name'].disable({
        onlySelf: false,
        emitEvent: false,
      });
    }
  }
}
