<div class="container">
    <div class="icon">
        <i class="{{data.iconClass}}"></i>
    </div>
    <div class="message-wrapper">
        <span class="message">{{data.message}}</span>
        <a *ngIf="data.link" class="snack-link" href="{{data.link}}">VIEW</a>
    </div>
    <div class="close" (click)="onClose()"><span>×</span></div>
</div>
