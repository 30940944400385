import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class LoggregationParamsService {
  public buildLoggregationHTMLParams(
    input: any,
    clickableParam: any,
    isJson: boolean = false,
    logExample: any = null,
    placeholdersEnabled: boolean = true
  ): string {
    if (!input) {
      return 'NO TEXT';
    }

    const loggregationParamPattern = this.getLoggregationParamPattern(isJson);
    let replaced;

    if (clickableParam) {
      replaced =
        isJson && logExample
          ? this.getReplacedJSONPatttern(
              input,
              clickableParam,
              logExample,
              loggregationParamPattern,
            )
          : input.replace(loggregationParamPattern, clickableParam);
    } else {
      replaced = this.getReplacedTrimmedNamePattern(
        input,
        loggregationParamPattern,
        clickableParam,
      );
    }

    return replaced.replace(/{CoralogixPHStatsEnabled}/g, placeholdersEnabled ? '' : 'disabled');
  }

  private getLoggregationParamPattern(isJson: boolean): RegExp {
    return isJson
      ? new RegExp(
          /\{CoralogixJsonPH(\d+)_([^]*?.*?)_([json]+)_CoralogixJsonPH\}/g,
        )
      : new RegExp(
          /\{CoralogixPH(\d+)_([^]*?.*?)_([free|categorical|numeric]+)_CoralogixPH\}/g,
        );
  }

  private getReplacedTrimmedNamePattern(
    input: any,
    loggregationParamPattern: any,
    clickableParam: any,
  ): string {
    return input.replace(
      loggregationParamPattern,
      (match, p1, p2) => p2.substring(0, 40),
      clickableParam,
    );
  }

  private getReplacedJSONPatttern(
    input: any,
    clickableParam: any,
    logExample: any,
    loggregationParamPattern: RegExp,
  ): string {
    // region Local Functions
    const getReplacement = (match, p1, p2, p3) => {
      const path = p2.split('.');
      let prop = logExample[path[0]];
      if (path.length > 1) {
        path.shift();
        path.forEach((p) => {
          if (prop) {
            prop = prop[p];
          }
        });
      }
      let parameterHtml = clickableParam.replace('$2', p2);
      parameterHtml = parameterHtml.replace('$3', p3);
      parameterHtml = parameterHtml.replace('$3', p3);
      parameterHtml = parameterHtml.replace(
        '*VALUE',
        !_.isUndefined(prop) ? prop : '*VALUE',
      );
      return parameterHtml;
    };
    // endregion
    return input.replace(
      loggregationParamPattern,
      getReplacement.bind(this),
      clickableParam,
    );
  }
}
