<mat-card class="coralogix card">
  <p class="file-extraction-card-header">{{ headerText }}</p>
  <div class="file-extraction-card-subheader">
    <ng-content select="[select-subheader]"></ng-content>
  </div>
  <form [formGroup]="form">
    <ng-container formArrayName="fields">
      <div *ngFor="let _ of metricFields?.controls; index as i">
        <div class="top-inputs-container">
          <div class="coralogix input-group" [formGroupName]="i">
            <label class="input-label">{{ labelText }}</label>
            <input
              type="text"
              placeholder="Enter an alias"
              class="form-control"
              formControlName="name"
              (focus)="updateValidators(i)"
            />
            <div class="err-container"
                 *ngIf="metricFields?.controls[i].get('name').hasError('duplicateName')">
              <p class="err-message">
                {{ metricConstants.DUPLICATE_NAME_MESSAGE }}
                <mat-icon *ngIf="isMetricField" [matTooltip]="metricConstants.DUPLICATE_TOOLTIP">
                  help_outline
                </mat-icon>
              </p>
            </div>
            <div class="err-container" *ngIf="metricFields?.controls[i].get('name').hasError('pattern')">
              <p class="err-message">
                {{ metricConstants.BAD_NAME_MESSAGE }}
                <mat-icon [matTooltip]="metricConstants.BAD_NAME_TOOLTIP"
                          matTooltipClass="name-error-tooltip">
                  help_outline
                </mat-icon>
              </p>
            </div>
          </div>
          <div class="coralogix input-group" [formGroupName]="i">
            <sh-coralogix-select
              [selectOptions]="filterOptions"
              [disableSelectAll]="true"
              [multiple]="false"
              [searchable]="true"
              [placeholder]="'Select metric fields'"
              [maximumSelection]="1"
              formControlName="fieldPath"
              (focus)="updateValidators(i)"
            >
              <label select-label>
                <span class="text">Extract From</span>
              </label>
            </sh-coralogix-select>
            <div
              class="err-container"
              *ngIf="metricFields?.controls[i].get('fieldPath').hasError('duplicateFieldPath')"
            >
              <p class="err-message">{{ metricConstants.DUPLICATE_FIELD_PATH_MESSAGE }}</p>
            </div>
          </div>
          <mat-icon class="more-vert-icon" [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <div (click)="removeField(i)" class="more-menu-item">
              <img src="assets/settings/groups/icon-trash.svg" alt="delete"/>
              <span class="caption">Delete</span>
            </div>
          </mat-menu>
        </div>
      </div>
      <cgx-button
        [label]="'ADD NEW ' + entityType | uppercase"
        size="md"
        textNoWrap
        srcIconStart="assets/icons/xs-16x16/plus.svg"
        width="190px"
        [stroked]="true"
        (press)="addField()"
      ></cgx-button>
    </ng-container>
  </form>
</mat-card>
