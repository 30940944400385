import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoggregationEntity } from '../../../loggregation/shared/interfaces/loggregation.interface';
import { Log } from '../../../logs/shared/models/log.entity';
import { JsonParameterRequestData } from '../../models/jsonParameterRequestData';
import { JsonKeyAction } from '../../models/filter-by-json-value-action.model';

@Component({
  selector: 'sh-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['json-viewer.component.scss'],
})
export class JsonViewerComponent implements OnInit {
  @Input() public hideJsonParams: boolean;
  @Input() public jsonObject: Object;
  @Input() public log: Log | LoggregationEntity;
  @Output() public jsonKeyClick: EventEmitter<JsonKeyAction> = new EventEmitter<
    JsonKeyAction
  >();
  @Output() public onShowWidget: EventEmitter<
    JsonParameterRequestData
  > = new EventEmitter<JsonParameterRequestData>();
  @Input() public isShowWidgetEnabled = true;
  @Input() public isFiltersEnabled = false;
  constructor() {}

  public ngOnInit() {}

  public jsonKeyClicked(action: JsonKeyAction) {
    this.jsonKeyClick.emit(action);
  }

  public showJsonWidget(key: string) {
    let templateId = null;

    if (this.log instanceof LoggregationEntity) {
      templateId = (this.log as LoggregationEntity).id;
    }
    const jsonParamReqData = new JsonParameterRequestData(templateId, key);
    this.onShowWidget.emit(jsonParamReqData);
  }
}
