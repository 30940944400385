<mat-card class="archive-query-item">
  <mat-card-content>
    <div class="container">
      <div class="side-container">
        <div class="img-container">
          <img [src]="imgSrc" />
        </div>
        <div class="type-container">
          <label class="type-label">{{ queryType }}</label>
        </div>
      </div>
      <div class="details-container">
        <div class="header" (click)="openEditor()">
          {{ archiveQuery.name }}
          <span class="header-font-size">(expires on {{ archiveQuery.expirationTime | date: 'EEEE, MMMM d, y, h:mm:ss a' }})</span>
        </div>
        <div *ngIf="archiveQuery.description" class="sub-header">{{ archiveQuery.description }}</div>
        <div *ngIf="archiveQuery.rowCount != null" class="row-count">
          <div>{{ rowCountMessage }}</div>
          <div
            *ngIf="archiveQuery.rowCount >= maxQueryResults"
            matTooltip="Max results is {{ maxQueryResults }}. Refine your query"
            class="count-warning"
          >
            <img [src]="warningImg" />
          </div>
        </div>
        <div class="log-container">
          <div *ngIf="queryUiState?.showMore">
            <div *ngFor="let log of archiveQuery.log">
              <div class="log-data">
                {{ log.resource }} {{ getActionMessage(log.action) }} on {{ log.date | date: 'EEEE, MMMM d, y, h:mm:ss a' }}
                {{ log.by ? 'by ' + log.by : '' }}
              </div>
            </div>
            <div *ngIf="archiveQuery.log.length > 1" class="show-logs" (click)="collapseEventsLogView(archiveQuery.id, false)">
              Show Less
            </div>
          </div>
          <div *ngIf="!queryUiState?.showMore">
            <div class="log-data" *ngIf="archiveQuery.log.length >= 1">
              {{ (archiveQuery?.log)[0]?.resource }} {{ getActionMessage((archiveQuery?.log)[0]?.action || 0) }} on
              {{ (archiveQuery?.log)[0]?.date | date: 'EEEE, MMMM d, y, h:mm:ss a' }}
              {{ (archiveQuery?.log)[0]?.by ? 'by ' + (archiveQuery?.log)[0]?.by : '' }}
            </div>
            <div *ngIf="archiveQuery.log.length > 1" class="show-logs" (click)="expandEventsLogView(archiveQuery.id, true)">
              Show More...
            </div>
          </div>
        </div>
      </div>
      <div class="commands-container">
        <cgx-button
          *ngIf="archiveQuery.status === ArchiveQueryStateEnum.active"
          label="Logs Preview"
          size="md"
          width="154px"
          [stroked]="true"
          (press)="previewClicked()"
        ></cgx-button>
        <cgx-button
          [label]="queryArchiveStatus"
          size="md"
          width="154px"
          [color]="setArchiveButtonColor()"
          [stroked]="true"
          [disabled]="isArchiveButtonDisabled()"
          (press)="onArchiveClicked()"
        ></cgx-button>
        <cgx-button
          *ngIf="!isReindexButtonHidden()"
          [label]="setReindexButtonLabel()"
          size="md"
          width="154px"
          [color]="setReindexButtonColor()"
          [stroked]="true"
          [disabled]="
            (archiveQuery?.reindexes)[0]?.status === ReindexStateEnum.pending ||
            (archiveQuery?.reindexes)[0]?.status === ReindexStateEnum.failed
          "
          (press)="onReindexClicked()"
        ></cgx-button>
        <cgx-button
          *ngIf="archiveQuery.status !== ArchiveQueryStateEnum.pending"
          label="Clone"
          size="md"
          width="154px"
          [stroked]="true"
          (press)="onCloneClicked()"
        ></cgx-button>
        <div class="status-main-view">
          <div class="ingesting">
            <div *ngIf="showProgression" class="ingesting-statusbar-container">
              <mat-progress-bar mode="determinate" value="{{ getProgress }}"></mat-progress-bar>
              <div class="status-data-display precentages">{{ getProgress }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
