import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  EmptyStateBodyItemType,
  EmptyStateListTypes,
  IListBody,
  IListHeader,
} from '@shared/components/empty-state-container/empty-state-container.models';

@Component({
  selector: 'sh-empty-state-container',
  templateUrl: './empty-state-container.component.html',
  styleUrls: ['./empty-state-container.component.scss'],
})
export class EmptyStateContainerComponent {
  public emptyStateBodyItemType: typeof EmptyStateBodyItemType = EmptyStateBodyItemType;
  public emptyStateListTypes: typeof EmptyStateListTypes = EmptyStateListTypes;
  @Input() public subTitle: string = 'You do not have any Logs2Metrics defined.';
  @Input() public circleImagePath: string = '../../../../../../assets/alerts/alert-empty.png';
  @Input() public mainText: string = 'Convert Logs to Metrics and store them for a period of 12mo. Once activated, Metrics will take 5% of your daily quota, and will not cause any additional costs.';
  @Input() public buttonText: string = 'New Metric';
  @Input() public buttonIconFontAwesomeClass: string = null;
  @Input() public buttonIconUrl: string = null;
  @Input() public listConfig: (IListHeader | IListBody)[];
  @Output() public mainButtonPressed: EventEmitter<void> = new EventEmitter<void>();
}
