<div class="coralogix input-group">
  <label class="input-label"><span class="text">{{label}}</span>
    <span class='input-astrix' *ngIf="isRequired">*</span>
    <i *ngIf="tooltip" class="fa fa-question-circle-o"
       shTooltip
       [tooltip]="tooltip"
    ></i></label>
  <div
    [ngClass]="{'has-validation': isRequired}"
    [class.coralogix-input-error]="!formControl.pristine && !formControl.valid">
    <input
      [type]="inputType"
      [placeholder]="placeholder"
      [formControl]="formControl"
      [ngClass]="{'consolas-font-family': fontFamilyOpt.consolas === fontFamilyOptions}"
      class="form-control">
    <span *ngIf="!formControl.pristine && !formControl.valid"
          class="controls-validation-error">{{errorMsg}}</span>
  </div>
</div>
