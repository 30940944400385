import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sh-inner-labels-slider',
  templateUrl: './inner-labels-slider.component.html',
  styleUrls: ['./inner-labels-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InnerLabelsSliderComponentProvider(),
      multi: true,
    }]
})
export class InnerLabelsSliderComponent implements ControlValueAccessor {
  @Input() public placeholder: string = '';
  @Input() public formControl: FormControl;
  @Input() public selectedLabel: string = 'And';
  @Input() public notSelectedLabel: string = 'Or';
  public onChange: () => void;
  public value: string | number;
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public writeValue(value: number | string): void {
    this.value = value;
  }
}
export function InnerLabelsSliderComponentProvider(): any {
  return forwardRef(() => InnerLabelsSliderComponent);
}
