import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Metric, FieldExtraction } from '@app/settings/metrices-settings/models/metric.model';
import { MetricEditorControls } from '@app/settings/metrices-settings/models/metric-editor-controls';

@Component({
  selector: 'sh-metrics-editor',
  templateUrl: './metrics-editor.component.html',
  styleUrls: ['./metrics-editor.component.scss'],
})
export class MetricsEditorComponent {
  public get detailsGroup(): FormGroup {
    return this.form.get('details') as FormGroup;
  }

  public get queryDefinition(): FormGroup {
    return this.form.get('query') as FormGroup;
  }

  public get permutationsLimitControl(): FormControl {
    return this.form.get('permutationsLimit') as FormControl;
  }

  public get groupByGroup(): FormGroup {
    return this.form.get('groupBy') as FormGroup;
  }

  public get isFormValid(): boolean {
    return this.form.valid && !this.groupsContainsName;
  }

  public get groupMetricFields(): FormGroup {
    return this.form.get('metricFields') as FormGroup;
  }
  public groupsContainsName: boolean = false;
  public metricName: string;
  @Input()
  public isInEditMode: boolean = false;
  @Input()
  public editedMetric: Metric;
  @Input()
  public form: FormGroup;
  @Input()
  public logsSeverityOptions: { id: number; name: string }[];
  @Input()
  public optionalGroupByFields: string[];
  @Input()
  public validationError: string;
  @Input()
  public dbConflictError: string;
  @Input()
  public companyLabelLimit: number;
  @Output()
  public onSubmit: EventEmitter<MetricEditorControls> = new EventEmitter<MetricEditorControls>();
  @Output()
  public onDelete: EventEmitter<Metric> = new EventEmitter<Metric>();
  private selectedLabelNames: string[] = [];

  public setSelectedLabelNames(groups: string[]): void {
    this.selectedLabelNames = groups;
    this.groupsContainsName = this.selectedLabelNames.includes(this.metricName);
  }

  public setMetricName(name: string): void {
    this.metricName = name;
    this.groupsContainsName = this.selectedLabelNames.includes(this.metricName);
  }

  public submit(value: any): void {
    const metric = new MetricEditorControls({
      metricName: value.details.metricName,
      description: value.details.description,
      applicationName: value.query.applicationName,
      subsystemName: value.query.subsystemName,
      logSeverity: value.query.logSeverity,
      text: value.query.text,
      groupBy: this.filterEmptyMetricsAndTrim(value.groupBy.fields),
      metricFields: this.filterEmptyMetricsAndTrim(value.metricFields.fields),
      permutationsLimit: value.permutationsLimit,
    });
    this.onSubmit.emit(metric);
  }

  private filterEmptyMetricsAndTrim(fields: FieldExtraction[]): FieldExtraction[] {
    const filtered = fields.filter((field) => field.name && field.fieldPath);
    filtered.forEach((field) => (field.name = field.name.trim()));
    return filtered;
  }
}
