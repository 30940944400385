<div
  class="header"
  [class.sh-select-disabled]="disabled"
  [class.inline]="mode==='inline'"
  (click)="show()"
  [class.open]="isOpen"
>
  <input
    type="text"
    #inputFilter
    [hidden]="!isOpen"
    (click)="show()"
    [placeholder]="placeholder"
    [(ngModel)]="filter"
    (ngModelChange)="updateFilter($event)"
  />
  <div (click)="show(); $event.stopPropagation()" *ngIf="!isOpen">
    {{ selectedValues?.length ?
        (isMultiselect ?
          selectedValues?.length + ' Selected'
          : (selectedValues[0].name || selectedValues))
        : placeholder}}
  </div>
  <i
    class="close icon clear"
    *ngIf="showClear && selectedValues.length > 0"
    (click)="clear(); $event.stopPropagation()"
  ></i>
</div>
<sh-select-menu
  [isOpen]="isOpen"
  [rows]="rows"
  [selectedValues]="selectedValues"
  (noToggleClick)="toggleSelected($event)"
></sh-select-menu>
