import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileNavbarComponent } from './mobile-navbar.component';
import { RouterModule } from '@angular/router';
import { UserSettingsFiltersModule } from '@app/user/user-settings-filters/user-settings-filters.module';
import { MobileAppMenuModule } from './mobile-app-menu/mobile-app-menu.module';
import { MatMenuModule } from '@angular/material/menu';
import { UserMenuModule } from '../user-menu/user-menu.module';
import { AvatarInitialsModule } from '@app/shared/avatar-initials/avatar-initials.module';

@NgModule({
  declarations: [MobileNavbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    UserSettingsFiltersModule,
    MobileAppMenuModule,
    MatMenuModule,
    UserMenuModule,
    AvatarInitialsModule
  ],
  exports: [MobileNavbarComponent]
})
export class MobileNavbarModule { }
