<div class="sh-alert-view-layout">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start-center">
        <button #tooltip="matTooltip" mat-button matTooltip="Copied" matTooltipPosition="above" class="sh-btn-copy-alert-link"
            [matTooltipHideDelay]="5000" [cdkCopyToClipboard]="host + '/#' + url" (mouseenter)="$event.stopImmediatePropagation()"
            (click)="tooltip.show()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                <i class="fa fa-share-alt sh-shared-icon"></i>
                <span>Copy alert link</span>
            </div>
        </button>

        <!-- do not use this action until further notice -->
        <!-- <button mat-button (click)="editAlert()" class="sh-btn-edit-alert">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                <i class="fa fa-pencil sh-pencil-icon"></i>
                <span>Edit Alert</span>
            </div>
        </button> -->

    </div>

    <div class="sh-alert-type">{{alertType}}</div>
    <div class="sh-title">{{title}}</div>
    <div class="sh-sub-title">{{subTitle}}</div>
    <div fxLayout="row wrap" fxLayoutGap="20px" class="sh-tags">
        <div *ngFor="let tag of tags" class="sh-tag">{{tag}}</div>
    </div>
    <ng-content select=[body]></ng-content>
</div>
