import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import chartHelper from './field-visualization-helpers';
import { ChartData } from '@app/logs-new/shared/models/field-visualization.model';
import { WidgetAddComponent } from '@shared/controls/widgets/widget-add/widget-add.component';
import { filterFalsy } from '@app/logs-new/shared/operators/filter-falsy.operator';
import { WidgetsService } from '@app/widgets/shared/services/widgets.service';
import { MatDialog } from '@angular/material/dialog';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { LogsContent } from '@app/logs-new/shared/models/logs-content.model';
import _cloneDeep from 'lodash-es/cloneDeep';
import _invert from 'lodash-es/invert';
import { ISearchQueryEvent } from '@app/logs-new/shared/models/search.model';
import { IAddSelectedQueryFieldEvent } from '@app/logs-new/shared/models/logs-filter.model';
import { ShDialogService } from '@shared/services/dialogService';

@Component({
  selector: 'sh-field-visualization',
  templateUrl: './field-visualization.component.html',
  styleUrls: ['./field-visualization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldVisualizationComponent implements OnDestroy {
  @Input() public title: string = '';
  @Input() public fullJSONPath: string;
  @Input() public logsQuery$: Observable<ILogQuery>;
  @Input() public activeTab$: Observable<LogsContent>;
  @Output() public closeGraph: EventEmitter<void> = new EventEmitter<void>();
  @Output() public addToSearchQueryEvent = new EventEmitter<ISearchQueryEvent>();
  @Output() public addSelectedQueryFieldEvent = new EventEmitter<IAddSelectedQueryFieldEvent>();
  @ViewChild('chart', { static: true }) public chartEl: ElementRef;
  public _chartData: Array<ChartData[]>;
  public maxNumber: number;
  public showLabelMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fieldLabel: string = String(ChartData.Label);
  public fieldAmount: number = ChartData.Amount;
  public logarithmicView: boolean = false;
  public labelsMenuStyle: { [key: string]: string } = {};
  public hasData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private latestClickedLabel: string = '';
  private unsubscribe$: Subject<void> = new Subject<void>();
  private _customMap: { [key: string]: string };
  private _invertedCustomMap: { [key: string]: string };
  @Input() private MAX_CHART_ITEMS: number = 100;

  constructor(
    private widgetService: WidgetsService,
    private dialog: MatDialog,
    private dialogService: ShDialogService, // used in shared.ts,
  ) {}

  @Input() private set chartData$(chartData$: Observable<Array<ChartData[]>>) {
    if (chartData$) {
      chartData$.pipe(takeUntil(this.unsubscribe$)).subscribe(chartData => {
        this.setChartData(chartData);
      });
    }
  }

  @Input() public set chartData(chartData: Array<ChartData[]>) {
    if (chartData) {
      this.setChartData(chartData);
    }
  }
  public get chartData(): Array<ChartData[]> {
    return this._chartData;
  }

  @Input() public set customMap(customMap: { [key: string]: string }) {
    if (customMap) {
      this._customMap = customMap;
      this._invertedCustomMap = _invert(customMap);
    }
    if (this.chartData) this.renderChart();
  }
  public get customMap(): { [key: string]: string } {
    return this._customMap;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public renderChart(): void {
    const chartOptions = this.logarithmicView ? chartHelper.getLogarithmicChart.call(this) : chartHelper.getNormalChart.call(this);
    Highcharts.chart(chartOptions);
    if (!this.hasData$.getValue()) {
      this.hasData$.next(true);
    }
    this.addLabelMenuClickListeners();
  }

  public pinGraph(): void {
    this.logsQuery$.pipe(take(1)).subscribe(query => {
      const clonedQuery = _cloneDeep(query);
      clonedQuery.queryParams.aggField = this.fullJSONPath;
      const dialogRef = this.dialog.open(WidgetAddComponent, {
        data: { query: clonedQuery, url: 'statistics/logs1' },
        panelClass: 'add-edit-widget-container',
      });
      dialogRef
        .afterClosed()
        .pipe(first(), filterFalsy())
        .subscribe(widget => {
          this.widgetService
            .addWidgets(widget)
            .first()
            .subscribe();
        });
    });
  }

  public logarithmicViewToggle(): void {
    this.logarithmicView = !this.logarithmicView;
    const TOGGLE_TRANSITION_MS = 130;
    setTimeout(() => this.renderChart(), TOGGLE_TRANSITION_MS);
  }

  public addFieldAsFilter(): void {
    this.addSelectedQueryFieldEvent.emit({
      key: this.fullJSONPath,
      value: this.getFieldValue(this.latestClickedLabel),
    });
    this.closeLabelsMenu();
  }

  public addFieldToQuery(): void {
    this.addToSearchQueryEvent.emit({
      payload: this.getFieldValue(this.latestClickedLabel),
      isKey: false,
      jsonPath: this.fullJSONPath,
      toInclude: true,
    });
    this.closeLabelsMenu();
  }

  public closeLabelsMenu(): void {
    this.showLabelMenu$.next(false);
  }

  public getFieldLabel(fieldLabel: string): string {
    return this.customMap ? this.customMap[fieldLabel] : fieldLabel;
  }

  public getFieldValue(fieldLabel: string): string {
    return this.customMap ? this._invertedCustomMap[fieldLabel] : fieldLabel;
  }

  private addLabelMenuClickListeners(): void {
    document.querySelectorAll('.menu-icon-container').forEach(item => {
      fromEvent(item, 'click')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(event => this.toggleLabelDropdown(event));
    });
  }

  private setChartData(chartData: Array<ChartData[]>): void {
    this._chartData = this.sortAndSliceData(chartData);
    this.renderChart();
  }

  private toggleLabelDropdown(evt: any): void {
    evt.stopImmediatePropagation();
    this.latestClickedLabel = evt.target.parentElement.parentElement.children[1].innerText;
    this.labelsMenuStyle.top = `${evt['y']}px`;
    this.labelsMenuStyle.left = `${evt['x']}px`;
    this.showLabelMenu$.next(!this.showLabelMenu$.getValue());
  }

  private sortAndSliceData(chartData: Array<ChartData[]>): Array<ChartData[]> {
    return [...chartData].sort((a, b) => (a[ChartData.Amount] > b[ChartData.Amount] ? -1 : +1)).slice(0, this.MAX_CHART_ITEMS);
  }
}
