import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class AlertProperties {
  @jsonMember
  public logId: string;
  @jsonMember
  public logText: string;
  @jsonMember
  public logTimestamp: number;
}
