<button mat-button class="button coralogix full-action-button__{{menuButtonTheme}} dropdown-button"
        [ngStyle]="{'min-height': buttonHeight, 'line-height': buttonHeight}"
        [matMenuTriggerFor]="belowMenu">
  <span class="dropdown-button__text">{{buttonText}}</span>
  <i class="icon chevron down dropdown-button__icon" style="color: white" [ngStyle]="{'line-height': buttonHeight}"></i>
</button>
<mat-menu #belowMenu="matMenu" yPosition="below" class='dropdown-button-menu'>
  <button class='dropdown-menu-item' mat-menu-item (click)="buttonPressed.emit(item?.id? item?.id: item)"
          *ngFor="let item of menuOptions">
    <span  class="dropdown-menu-item__name">{{item?.name ? item.name : item}}</span>
    <span class="dropdown-menu-item__description" *ngIf="item.description">{{item.description}}</span>
  </button>
</mat-menu>
