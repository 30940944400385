import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { VirtualSelectComponent } from './components/virtual-select/virtual-select.component';
import { VirtualScrollComponent } from './components/virtual-scroll/virtual-scroll.component';
import { VirtualSelectMenuComponent } from './components/virtual-seelct-menu/virtual-select-menu.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    VirtualSelectComponent,
    VirtualScrollComponent,
  ],
  declarations: [
    VirtualSelectComponent,
    VirtualScrollComponent,
    VirtualSelectMenuComponent,
  ]
})
export class VirtualSelectModule {

}
