<sh-coralogix-sidebar
  #sidebarComponent
  (close)="closeEditor()"
  [templateRef]="editor"
  class="tag-sidebar"
>
</sh-coralogix-sidebar>
<ng-template #editor>
  <div fxLayout="column" class="scrollable panel-container">

    <sh-page-sub-header class='header-section' [height]="'84px'" [title]="tagName">
      <button (click)="saveTag()"
              [disabled]="!tagForm.valid || tagForm.pristine"
              class="button coralogix header-section__button {{isCreateMode? 'full-action-button__secondary' :'full-action-button__primary' }}">
        <span class="header-section__button-text">{{saveButtonText}}</span>
      </button>
    </sh-page-sub-header>

    <div class="panel-container__content" fxLayout="row" fxFlex="auto">
      <sh-tag-panel-content
        class="info-container" fxLayout="column" fxFlex="auto"
        [minDate]="minFormDate"
        [timeZoneSubHeader]="timeZoneSubHeader"
        [dateOptions]="dateOptions"
        [isCreateMode]="isCreateMode"
        [tagForm]="tagForm"
      ></sh-tag-panel-content>
    </div>
  </div>
</ng-template>
