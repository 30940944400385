import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { ArchivingProviderModel } from '../models/archivingProviderModel';
import { AuthService } from '../../../auth/shared/services/auth.service';

@Injectable()
export class ArchivingFormProviderService {
  public archivingForm: FormGroup;
  public privateKey: string;

  constructor(
    private formsBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    this.generateNewForm(this.defaultValues);
    this.authService
      .getCompany()
      .first()
      .subscribe(
        (data) => {
          this.privateKey = data.privateKey;
          this.addPrivateKey(this.privateKey);
        },
        (error) => {
          console.log(error);
        },
      );
  }

  public generateNewForm(values: any): void {
    this.archivingForm = this.formsBuilder.group({
      id: [values.id],
      active: [values.active],
      provider: [values.provider, Validators.required],
      region: [values.region, Validators.required],
      name: [values.name, Validators.required],
      private_key: [values.private_key, Validators.required],
    });
  }

  public resetForm(): void {
    this.generateNewForm(this.defaultValues);
  }

  public updateForm(data: ArchivingProviderModel): void {
    const values = {
      id: data.id,
      active: data.active,
      provider: data.provider,
      region: data.region.toLowerCase(),
      name: data.name,
      private_key: data.private_key,
    };
    this.generateNewForm(values);
  }

  get defaultValues(): any {
    return {
      id: '',
      provider: 'S3',
      region: '',
      active: true,
      name: '',
      private_key: this.privateKey,
    };
  }

  getForm(): FormGroup {
    return this.archivingForm;
  }

  addPrivateKey(key: string): void {
    this.archivingForm.patchValue({private_key: key});
  }
}
