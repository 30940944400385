import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'sh-alerts-recipients',
    templateUrl: './alerts-recipients.component.html',
    styleUrls: ['./alerts-recipients.component.scss'],
})
export class AlertsRecipientsComponent {
    @Input()
    public form: FormGroup;
    @Input()
    public integrationOptions: any[];
    @Input()
    public emailOptions: any[];
    @Output()
    public focus: EventEmitter<Event> = new EventEmitter();

    public hasError(controlName: string, errorCode: string): boolean {
        const control = this.form.get(controlName);
        return control.hasError(errorCode);
    }

    public onFocus($event: Event): void {
        this.focus.emit($event);
    }
}
