import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ViewRawLogComponent } from '@app/logs-new/shared/features/view-raw-log/view-raw-log.component';
import { copyToClipboard } from '@shared/utils/utils';
import { ShDialogService } from '@shared/services/dialogService';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from '@app/logs-new/shared/services/theme.service';
import { ISelectedLog } from '@app/logs-new/shared/models/query-params.model';
import { LogsGridService } from '@app/logs-new/shared/services/logs-grid.service';
import { MAT_PAGINATOR_CLASS } from '@app/logs-new/shared/constants/paginator.constant';
import { LogsNewGridComponent } from '../../../logs-new-grid.component';
import { Plugin } from '@app/logs-new/components/plugins-container/model/plugin.model.ts';
import { take } from 'rxjs/operators';
import { CoralogixSidebarComponent } from '@app/shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { Nunjucks } from '@app/shared/models/nunjucks';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { PluginsContainerComponent } from '@app/logs-new/components/plugins-container/plugins-container.component';

interface ISurroundingLog {
  caption: string;
  value: number;
  timeUnit: 'Seconds' | 'Minute' | 'Minutes';
}

@Component({
  selector: 'sh-side-menu-renderer',
  templateUrl: 'side-menu-renderer.component.html',
  styleUrls: ['side-menu-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  showMenu$ = new BehaviorSubject<boolean>(false);
  viewSurroundingLogs: ISurroundingLog[] = [
    { caption: '5', timeUnit: 'Seconds', value: 5 },
    { caption: '30', timeUnit: 'Seconds', value: 30 },
    { caption: '1', timeUnit: 'Minute', value: 60 },
    { caption: '5', timeUnit: 'Minutes', value: 300 },
    { caption: '10', timeUnit: 'Minutes', value: 600 },
  ];
  menuStyle$ = new BehaviorSubject({});
  @ViewChild('sideMenuEl') sideMenuEl: ElementRef;

  @ViewChild('sidebarComponent', { static: true }) sidebar: CoralogixSidebarComponent;

  @ViewChild('pluginsContainer', { static: true }) pluginsContainer: PluginsContainerComponent;

  privatePlugins: Plugin[] = [];

  sharedPlugins: Plugin[] = [];

  constructor(
    private dialog: MatDialog,
    private dialogService: ShDialogService,
    private route: ActivatedRoute,
    public themeService: ThemeService,
    private logsGridService: LogsGridService,
    private cdr: ChangeDetectorRef,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.searchPrivatePluginChange();
    this.searchSharedPluginChange();
  }

  toggleMenu(): void {
    const showMenu = !this.showMenu$.getValue();
    this.showMenu$.next(showMenu);
    if (showMenu) {
      this.cdr.detectChanges();
      const paginator = document.querySelector(MAT_PAGINATOR_CLASS) as HTMLElement;
      const el = this.sideMenuEl.nativeElement;
      const { height, y } = el.getBoundingClientRect();
      const buffer = 20;
      const bottomHeightLeft = window.innerHeight - paginator.offsetHeight - y;
      const isMenuOverflowingFromBottom = bottomHeightLeft <= height + buffer;
      const menuStyle = {
        marginTop: isMenuOverflowingFromBottom ? `-${height + 8}px` : '25px',
      };
      this.menuStyle$.next(menuStyle);
    }
  }

  openInfoPanel(): void {
    this.params.node.setSelected(true);
    this.logsGridService.selectedRow$.next(({
      ...this.params.node,
      context: this.params.context,
    } as unknown) as RowNode);
    this.params.context.parent.setShowInfoPanelEvent.emit(true);
    this.closeMenu();
  }

  highlightLog(): void {
    const {
      data: { logId, index, timestamp },
    } = this.params;
    const selectedLog: ISelectedLog = { id: logId, index, timestamp, comment: '', isDisabled: false, isFocused: false };
    this.params.context.parent.toggleLogSelectionEvent.emit(selectedLog);
    this.closeMenu();
  }

  hideTemplate(): void {
    const templateId = this.params.node.data.templateId;
    this.params.context.parent.addToSearchQueryEvent.emit({
      payload: templateId,
      toInclude: false,
      jsonPath: 'coralogix.templateId',
    });
    this.closeMenu();
  }

  onViewSurroundingLogs(time: ISurroundingLog): void {
    const { data } = this.params.node;
    this.logsGridService.updateSelectedRow(this.params.node);
    this.params.context.parent.viewSurroundingLogsEvent.emit({ log: data, seconds: time.value });
    this.closeMenu();
  }

  onViewSurroundingArchiveLogs(time: ISurroundingLog): void {
    const { data } = this.params.node;
    this.logsGridService.updateSelectedRow(this.params.node);
    this.params.context.parent.viewSurroundingArchiveLogsEvent.emit({ log: data, seconds: time.value });
    this.closeMenu();
  }

  copyPermalink(): void {
    const timestamp = this.params.node.data.timestamp;
    const startDate = timestamp - 1;
    const endDate = timestamp + 1;
    const isTemplates = this.params.context.parent.isTemplates;
    const id = isTemplates ? this.params.node.data.id : this.params.node.data.logId;
    const url = window.location.href.split('?')[0];
    const paramName = isTemplates ? 'templateId' : 'logId';
    const URL = `${url}?permalink=true&startTime=${startDate}&endTime=${endDate}&${paramName}=${id}`;
    copyToClipboard(URL).then(() => {
      const type = isTemplates ? 'Template' : 'Log';
      this.closeMenu();
      this.dialogService.showCoralogixMessage(`${type} permalink copied successfully`);
    });
  }

  viewRawLog(): void {
    this.dialog.open(ViewRawLogComponent, {
      panelClass: 'pretty-json',
      data: { rawLog: this.params.node.data.rawText || this.params.node.data.text },
    });
  }

  closeMenu(): void {
    this.showMenu$.next(false);
  }

  refresh(params: any): boolean {
    return true;
  }

  searchPrivatePluginChange(searchValue: string = ''): void {
    const { applicationName, subsystemName } = this.params.data.metadata;
    const pluginList: Plugin[] = this?.params?.context?.parent?.privatePlugins ?? [];
    this.privatePlugins = pluginList.filter(plugin => {
      if (plugin.isSelectedValue) {
        return false;
      }

      if (plugin.applicationNames.length && !plugin.applicationNames.includes(applicationName)) {
        return false;
      }

      if (plugin.subsystemNames.length && !plugin.subsystemNames.includes(subsystemName)) {
        return false;
      }

      return plugin.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }

  searchSharedPluginChange(searchValue: string = ''): void {
    const { applicationName, subsystemName } = this.params.data.metadata;
    const pluginList: Plugin[] = this?.params?.context?.parent?.sharedPlugins ?? [];
    this.sharedPlugins = pluginList.filter(plugin => {
      if (plugin.isSelectedValue) {
        return false;
      }

      if (plugin.applicationNames.length && !plugin.applicationNames.includes(applicationName)) {
        return false;
      }

      if (plugin.subsystemNames.length && !plugin.subsystemNames.includes(subsystemName)) {
        return false;
      }

      return plugin.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }

  onShowSearch(): void {
    this.searchPrivatePluginChange();
    this.searchSharedPluginChange();
  }

  openPluginsContainer(): void {
    this.pluginsContainer.openPluginsEditor();
    this.closeMenu();
  }

  selectPlugin(plugin: Plugin): void {
    const parent: LogsNewGridComponent = this.params.context.parent;
    const textObject = this.params.data.textObject;
    parent.logsQuery$.pipe(take(1)).subscribe(logsQuery => {
      const $p = {
        start_date: logsQuery.startDate,
        end_date: logsQuery.endDate,
      };
      const $m = { ...this.params.data.metadata, timestamp: this.params.data.timestamp };
      const context = {
        $d: textObject,
        $m,
        $p,
      };

      try {
        const url = Nunjucks.renderString(plugin.url, context);
        window.open(url, '_blank');
      } catch (err) {
        const toastError = err.message.substring(err.message.indexOf('Column') + 7, err.message.indexOf(']'));
        this.dialogService.showCoralogixMessage(
          `ERROR: Expected variable end at column ${toastError}`,
          null,
          dialogServiceIconClasses.failed,
        );
      }
      this.closeMenu();
      this.cdr.markForCheck();
    });
  }
}
