import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AlertEditControls, RelativeAlert } from '@app/alerts/alerts-editor/models/alert-edit-controls.model';
import { EmailValidator } from '@shared/validators/email.validator';
import { NotOnlySpacesValidator } from '@shared/validators/not-only-spaces.validator';
import { AlertHelper } from '@app/alerts/shared/services/alert-helper';
import { UserAlertTypes } from '@app/alerts/shared/models/create-n-update-alert-model';
import { ConditionsOptions } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { AlertSyntaxType, UserAlertViewTypes } from '@app/alerts/alerts-editor/models/alert-editor-view.models';
import { byValue } from '@shared/services/utils';
import { eq, IAlertRelativeTimeOption } from '@app/alerts/alerts-editor/models/alert-relative-time-option';
import { ConditionalSelectService } from '@app/shared/components/conditional-select/conditional-select.service';

@Injectable()
export class AlertsEditorFormService {
  constructor(private fb: FormBuilder, private alertHelper: AlertHelper, private conditionalSelectService: ConditionalSelectService) {}

  public get defaultValues(): AlertEditControls {
    return new AlertEditControls({
      userAlertTypeId: UserAlertViewTypes.UserTextAlert,
      alertName: '',
      alertText: '',
      alertSyntaxType: AlertSyntaxType.Lucene,
      alertSeverity: null,
      description: '',
      logSeverity: null,
      conditionOperator: 3,
      conditionThreshold: 1,
      conditionTimeframe: 10,
      relativeTimeframe: 0,
      conditionMetricField: ['', ''],
      conditionMetricArithmeticOperator: 0,
      conditionMetricArithmeticModifier: null,
      conditionMetricValueThreshold: 0,
      conditionSwapNullValue: false,
      conditionSampleThreshold: 0,
      conditionNonNullPercentage: 0,
      notifyOnResolved: false,
      applicationName: null,
      subsystemName: null,
      notificationPayloadFilter: [],
      notificationsMails: [],
      integrations: [],
      isActive: true,
      groupByFields: [],
      notifyGroupByOnlyAlerts: false,
      limitTriggering: false,
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      activityStarts: moment('00:00:00', 'HH:mm:ss').format('HH:mm:ss'),
      activityEnds: moment('23:59:59', 'HH:mm:ss').format('HH:mm:ss'),
      relativeAlerts: [
        new RelativeAlert({
          alertText: '',
          applicationName: null,
          logSeverity: null,
          subsystemName: null,
          text: '',
        }),
      ],
    });
  }

  public buildForm(value: AlertEditControls, isLocalTime: boolean): FormGroup {
    return this.fb.group(
      {
        isActive: [value.isActive],
        details: this.buildDetailsGroup(value),
        schedule: this.buildScheduleGroup(value, isLocalTime),
        query: this.buildQueryGroup(value),
        conditions: this.buildConditionsGroup(value),
        recipients: this.buildRecipientsGroup(value),
        content: this.buildContentGroup(value),
        relativeAlerts: this.buildRelativeAlertsGroup(value),
        userAlertTypeId: this.getUserAlertTypeControl(value),
      },
      {
        validators: [this.validateCardinalityGroupByFields, this.validateGroupByFields],
      },
    );
  }

  public mapFormValueToEditControls(formValue: any): AlertEditControls {
    const { isActive, details, schedule, query, conditions, recipients, content, relativeAlerts, userAlertTypeId } = formValue;

    return {
      relativeAlerts: relativeAlerts.map(relativeTime => {
        const alert = new RelativeAlert({ ...relativeTime, text: relativeTime.alertText });
        if (conditions.relativeTimeframe !== null && conditions.relativeTimeframe !== undefined) {
          alert.relativeTimeframe = conditions.relativeTimeframe;
        }
        return alert;
      }),
      isActive,
      ...details,
      ...schedule,
      ...query,
      ...conditions,
      ...recipients,
      ...content,
      userAlertTypeId,
    };
  }

  private buildDetailsGroup(value: AlertEditControls): FormGroup {
    return this.fb.group({
      alertName: [value.alertName, Validators.required, NotOnlySpacesValidator.checkValidity],
      alertSeverity: [value.alertSeverity, Validators.required],
      description: [value.description],
    });
  }

  private buildScheduleGroup(value: AlertEditControls, isLocalTime: boolean): FormGroup {
    const daysOfWeek = isLocalTime ? this.alertHelper.transformDaysFromUTC(value.activityStarts, value.daysOfWeek) : value.daysOfWeek;

    return this.fb.group({
      limitTriggering: [value.limitTriggering],
      daysOfWeek: [daysOfWeek || this.defaultValues.daysOfWeek],
      activityStarts: [value.activityStarts || this.defaultValues.activityStarts],
      activityEnds: [value.activityEnds || this.defaultValues.activityEnds],
    });
  }

  private buildQueryGroup(value: AlertEditControls | RelativeAlert): FormGroup {
    return this.fb.group({
      applicationName: this.conditionalSelectService.createFormFromData(value.applicationName),
      subsystemName: this.conditionalSelectService.createFormFromData(value.subsystemName),
      logSeverity: [value.logSeverity],
      alertText: [value.alertText],
      alertSyntaxType: [value.alertSyntaxType],
      alias: new FormControl(
        value.alias ||
          (value.relativeAlerts && value.relativeAlerts.length > 0 && value.relativeAlerts[0].parentAlias
            ? value.relativeAlerts[0].parentAlias
            : 'Query 1'),
        Validators.required,
      ),
    });
  }

  private buildConditionsGroup(value: AlertEditControls): FormGroup {
    const groupByFields = value.groupByFields?.length > 0 ? value.groupByFields : ['None'];
    return this.fb.group(
      {
        conditionOperator: [value.conditionOperator],
        relativeTimeframeOptionId: this.getRelativeTimeframeOptionId(value),
        relativeTimeframe: value.relativeTimeframe,
        conditionThreshold: [
          {
            value: value.conditionThreshold,
            disabled: [ConditionsOptions.notifyImmediately, ConditionsOptions.newValue].includes(value.conditionOperator),
          },
        ],
        conditionTimeframe: [
          {
            value: value.conditionTimeframe,
            disabled:
              value.conditionOperator === ConditionsOptions.notifyImmediately ||
              value.conditionOperator === ConditionsOptions.moreThanUsual,
          },
        ],
        conditionMetricArithmeticOperator: [value.conditionMetricArithmeticOperator],
        conditionMetricArithmeticModifier: [value.conditionMetricArithmeticModifier],
        conditionMetricField: [value.conditionMetricField],
        conditionMetricValueThreshold: [value.conditionMetricValueThreshold],
        conditionSwapNullValue: [value.conditionSwapNullValue],
        conditionSampleThreshold: [value.conditionSampleThreshold],
        conditionNonNullPercentage: [value.conditionNonNullPercentage],
        groupByFields: this.fb.array(groupByFields.map(field => this.fb.control(field))),
        notifyGroupByOnlyAlerts: [value.notifyGroupByOnlyAlerts],
        notifyOnResolved: [value.notifyOnResolved],
        notifyEveryHours: value.notifyEveryHours || 0,
        notifyEveryMinutes: Number.isFinite(value.notifyEveryMinutes) ? value.notifyEveryMinutes : 1,
        cardinalityFields: [this.evalCardinalityFields(value)],
        isCardinalityGroupBy: [value.isCardinalityGroupBy],
        cardinalityGroupByFields: [value.cardinalityGroupByFields ?? 500],
      },
      {
        validator: this.validateNotifyEvery,
      },
    );
  }

  private validateNotifyEvery(formGroup: FormGroup): null | { notifyEveryLessThanError: true } {
    const conditionOperator = formGroup.get('conditionOperator').value;
    const notifyEveryHours = formGroup.get('notifyEveryHours').value;
    const notifyEveryMinutes = formGroup.get('notifyEveryMinutes').value;
    const isLessThan = conditionOperator === ConditionsOptions.less;
    if (isLessThan) {
      const notifyEveryInMinutes = notifyEveryHours * 3600 + notifyEveryMinutes;
      const conditionTimeframe = formGroup.get('conditionTimeframe').value;
      if (notifyEveryInMinutes < conditionTimeframe) {
        return { notifyEveryLessThanError: true };
      }
    }
    return null;
  }

  private validateCardinalityGroupByFields(form: FormGroup): ValidationErrors | null {
    const { userAlertTypeId, conditions } = form.value;
    const { isCardinalityGroupBy, cardinalityGroupByFields, conditionThreshold } = conditions;
    const maxDuplication = 10000; // cardinalityGroupByFields * values = 10000
    if (userAlertTypeId === UserAlertViewTypes.Cardinality && isCardinalityGroupBy) {
      const values = Math.floor(maxDuplication / cardinalityGroupByFields);
      if (conditionThreshold > values) {
        return { conditionThreshold: 'Not greater than total values' };
      }
    }
    return null;
  }

  private validateGroupByFields(form: FormGroup): ValidationErrors | null {
    const { userAlertTypeId, conditions } = form.value;
    const { groupByFields } = conditions;
    const { isCardinalityGroupBy } = conditions;
    if (userAlertTypeId === UserAlertViewTypes.Cardinality && isCardinalityGroupBy) {
      if (!Array.isArray(groupByFields) || !groupByFields.length || groupByFields[0] === 'None') {
        return { groupByFields: 'required' };
      }
    }
    return null;
  }

  private buildRecipientsGroup(value: AlertEditControls): FormGroup {
    return this.fb.group({
      integrations: [value.integrations],
      notificationsMails: [value.notificationsMails, EmailValidator.arrayEmailValidator],
    });
  }

  private buildContentGroup(value: AlertEditControls): FormGroup {
    return this.fb.group({
      notificationPayloadFilter: [value.notificationPayloadFilter],
    });
  }

  private buildRelativeAlertsGroup(value: AlertEditControls): FormArray {
    const forms: FormGroup[] = value.relativeAlerts.map((ra, order) => {
      const relativeFormGroup = this.buildQueryGroup(ra);
      if (!ra.alias) {
        relativeFormGroup.controls.alias.setValue(`Query ${order + 2}`);
      }

      relativeFormGroup.addControl('parentAlias', new FormControl(ra.parentAlias || `Query ${order + 1}`));
      relativeFormGroup.addControl('order', new FormControl(ra.order || order));
      relativeFormGroup.addControl('operator', new FormControl(ra.operator || '/'));
      return relativeFormGroup;
    });

    return this.fb.array(forms);
  }

  private getUserAlertTypeControl(value: AlertEditControls): FormControl {
    let _value: UserAlertViewTypes | UserAlertTypes = UserAlertViewTypes.UserTextAlert;
    if (value.conditionOperator === ConditionsOptions.newValue) {
      _value = UserAlertViewTypes.NewValueAlert;
    } else if (value.userAlertTypeId) {
      _value = value.userAlertTypeId;
    }
    return new FormControl(_value);
  }

  private getRelativeTimeframeOptionId(value: AlertEditControls): number {
    const alertRelativeTimeOption: IAlertRelativeTimeOption = {
      conditionTimeframe: value.conditionTimeframe,
      relativeTimeframe: value.relativeTimeframe,
    };
    return Number(byValue(this.alertHelper.relativeTimeframeOptionIdMapping, eq)(alertRelativeTimeOption) || '1');
  }

  private evalCardinalityFields(controls: AlertEditControls): string {
    if (controls.userAlertTypeId === UserAlertViewTypes.Cardinality) {
      return controls.cardinalityFields || controls.groupByFields[0];
    }
    return null;
  }
}
