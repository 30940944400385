<div *ngIf="(tagsList$ | async)?.length > 0 && !(tagsLoading$ | async)">
  <div class="ui action input">
    <input
      type="text"
      placeholder="Filter..."
      (keyup)="stringFilterChanged($event.target.value)"
    />
  </div>
  <div class="tags-list-container">
    <div class="tags-list">
      <div
        *ngFor="
          let tag of tagsList$
            | async
            | filterPipe
              : stringFilter
              : ['text_key', 'application_name', 'subsystem_name', 'text_value']
        "
        style="width: 25%;display: inline-block;margin: 0px;"
        (click)="onTagSelected(tag.id)"
      >
        <sh-tag-query-item [tagData]="tag"></sh-tag-query-item>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="(tagsList$ | async)?.length === 0 && !(tagsLoading$ | async)"
  class="centered-text"
>
  No tags were added yet, See this
  <a
    href="https://coralogix.com/tutorials/software-builds-display"
    target="_blank"
  >
    tutorial
  </a>
  and learn how to add new tags
</div>

<div *ngIf="tagsLoading$ | async" class="centered-text">Loading tags...</div>
