<sh-coralogix-sidebar #sidebarComponent (close)="closePluginsEditor()" [templateRef]="editor"></sh-coralogix-sidebar>
<ng-template #editor>
  <div class="plugins-sidebar-layout">
    <div class="plugins-sidebar-header">
      <div class="page-header">Actions</div>
      <cgx-button
        label="ADD NEW ACTION"
        size="md"
        srcIconStart="assets/icons/xs-16x16/plus.svg"
        [outline]="true"
        [disabled]="currentPluginForm"
        (press)="addNewPlugin()"
      ></cgx-button>
    </div>
    <div class="plugins-sidebar-content" cdkScrollable>
      <div class="actions-description">
        Coralogix provides you an easy way to trigger 3rd party services based on your search results and/or values under specific keys. You
        can use external tools and pass to them values and/or filters with one click.
        <a href="https://coralogix.com/tutorials/coralogix-action-extension/" target="_blank">Learn More</a>
      </div>
      <ng-container *ngIf="(getLoading$ | async) === false; else getLoadingTemplate">
        <ng-container
          *ngTemplateOutlet="
            pluginsSection;
            context: { $implicit: { title: PluginTypes.Private | enumTranslator: 'PluginTypes', list: privateRenderFormArray } }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="
            pluginsSection;
            context: { $implicit: { title: PluginTypes.Shared | enumTranslator: 'PluginTypes', list: sharedRenderFormArray } }
          "
        ></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #pluginsSection let-context>
  <div class="plugin-group">
    <div *ngIf="context.list.length" class="plugin-category-header">{{ context.title | titlecase }}</div>
    <div class="form-horizontal">
      <div class="drag-drop-list">
        <div class="plugin-wrapper">
          <mat-accordion cdkDropList (cdkDropListDropped)="drop($event, context.title)">
            <mat-expansion-panel
              hideToggle
              *ngFor="let plugin of context.list; let i = index"
              cdkDrag
              [formGroup]="plugin"
              [expanded]="plugin === currentPluginForm"
            >
              <div *cdkDragPlaceholder class="field-placeholder"></div>
              <mat-expansion-panel-header>
                <div class="expansion-panel-inner-wrapper" (click)="$event.stopPropagation(); openPluginForm(plugin)">
                  <div style="display: flex;">
                    <img cdkDragHandle class="drag-handle" src="assets/icons/icon-move.svg" />
                    <div style="width: 220px;">
                      <input
                        *ngIf="plugin === currentPluginForm; else nameReadonly"
                        #nameInput
                        id="nameInput"
                        formControlName="name"
                        placeholder="Name"
                        class="name-input"
                        spellcheck="false"
                        [class.disabled-form-control]="
                          context.title === PluginTypes.Shared &&
                          (username$ | async) !== plugin.controls.userName.value &&
                          (isAdmin$ | async) === false
                            ? 'none'
                            : ''
                        "
                        (click)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()"
                        (keydown.enter)="$event.preventDefault()"
                      />
                      <ng-template #nameReadonly>
                        <div class="plugin-name">
                          {{ plugin.controls.name.value }}
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="expand-header-icons">
                    <div class="actions-title-icons" *ngIf="!newPluginForm" (click)="$event.stopPropagation()">
                      <cgx-button
                        *ngIf="!currentPluginForm || (currentPluginForm === plugin && !newPluginForm)"
                        class="cgx-button-clone"
                        color="text-secondary"
                        hoverColor="primary"
                        [cgxTooltip]="context.title === PluginTypes.Shared ? 'Clone as private' : 'Clone'"
                        position="above"
                        showDelay="0"
                        hideDelay="0"
                        size="sm"
                        icon
                        flat
                        srcIcon="assets/icons/sm-24x24/copy.svg"
                        (press)="$event.stopPropagation(); clonePlugin(plugin)"
                      ></cgx-button>

                      <cgx-button
                        color="text-secondary"
                        hoverColor="primary"
                        [cgxTooltip]="plugin.value.isHidden ? 'Show on actions menu' : 'Hide from actions menu'"
                        position="above"
                        showDelay="0"
                        hideDelay="0"
                        size="sm"
                        icon
                        flat
                        [class.cgx-button-clone]="!plugin.value.isHidden"
                        [disabled]="plugin.value.isHiddenLoading || (currentPluginForm && plugin !== currentPluginForm)"
                        [loading]="plugin.value.isHiddenLoading"
                        [srcIcon]="plugin.value.isHidden ? 'assets/icons/sm-24x24/eye-hide.svg' : 'assets/icons/sm-24x24/eye.svg'"
                        (press)="$event.stopPropagation(); toggleHidden(plugin)"
                      ></cgx-button>
                    </div>
                    <div class="expand-indicator" [style.transform]="plugin === currentPluginForm ? 'rotate(0deg)' : 'rotate(180deg)'">
                      <svg-icon src="assets/icons/xs-16x16/expandable-indicator.svg"></svg-icon>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div style="padding-top: 24px;">
                <div class="url-form-field">
                  <div class="input-label">
                    <div class="icon-label-pair">
                      <span style="margin-inline-end: 4px;">URL TEMPLATE</span>
                      <svg-icon
                        [cgxTooltip]="urlTooltip"
                        [top]="-8"
                        [layout]="false"
                        position="above"
                        maxWidth="524"
                        hoverable
                        textAlign="start"
                        style="display: flex; cursor: pointer;"
                        src="assets/icons/xs-16x16/circle-question-mark.svg"
                      ></svg-icon>
                    </div>
                    <!--                    <div class="icon-label-pair">-->
                    <!--                      <svg-icon-->
                    <!--                        [cgxTooltip]="examplesTooltip"-->
                    <!--                        [top]="-8"-->
                    <!--                        [layout]="false"-->
                    <!--                        position="above"-->
                    <!--                        maxWidth="524"-->
                    <!--                        hoverable-->
                    <!--                        textAlign="start"-->
                    <!--                        style="display: flex; cursor: pointer; margin-inline-end: 4px;"-->
                    <!--                        src="assets/icons/xs-16x16/examples.svg"-->
                    <!--                      ></svg-icon>-->
                    <!--                      Examples-->
                    <!--                    </div>-->

                    <ng-template #urlTooltip>
                      <div class="url-input-tooltip">
                        <div class="tooltip-column">
                          <!--                          <p>-->
                          <!--                            {{ jsExpressionString }}-->
                          <!--                          </p>-->
                          <p>
                            {{ metaDataTooltipHeader }}
                            <br />
                            <span class="tooltip-description">Event metadata. For example, `$m.applicationName` or `$m.timestamp`.</span>
                            <br />
                            {{ keySubKeyTooltipHeader }}
                            <br />
                            <span class="tooltip-description">
                              Actual data from the event. For example, `$d.kubernetes.labels.my_label`.
                            </span>
                          </p>
                        </div>
                        <div class="tooltip-column">
                          $p.start_time
                          <br />
                          <span class="tooltip-description">
                            The start of the selected time range.Unix epoch timestamp, in milliseconds
                          </span>
                          <br />
                          $p.end_time
                          <br />
                          <span class="tooltip-description">
                            The end of the selected time range. Unix epoch timestamp, in milliseconds
                          </span>
                          <br />
                          $p.selected_value
                          <br />
                          <span class="tooltip-description">
                            The value selected in the UI. If activated by pressing the key, the entire value of that key
                          </span>
                        </div>
                      </div>
                    </ng-template>
                    <!--                    <ng-template #examplesTooltip>-->
                    <!--                      <div class="examples-tooltip">-->
                    <!--                        Example Expressions:-->
                    <!--                        <br/>-->
                    <!--                        $d.kubernetes.labels.my_label ? $d.kubernetes.labels.my_label : ‘my_default_value’-->
                    <!--                        <br/>-->
                    <!--                        $d.price > 1000 ? ‘RED’ : ‘YELLOW’-->
                    <!--                        <br/>-->
                    <!--                        $d.log_msg.substring(0,4)-->
                    <!--                        <br/>-->
                    <!--                        new Date($p.START_TIME).toISOString()-->
                    <!--                      </div>-->
                    <!--                    </ng-template>-->
                  </div>
                  <sh-url-autocomplete
                    [urlControl]="plugin.get('url')"
                    [options]="logFields"
                    [closePanel]="dummyCloseUrlPanel$ | async"
                    [isDisabled]="
                      context.title === PluginTypes.Shared &&
                      (username$ | async) !== plugin.controls.userName.value &&
                      (isAdmin$ | async) === false
                    "
                  ></sh-url-autocomplete>
                </div>
                <div class="toggle-advance" (click)="toggleAdvance()">
                  <svg-icon *ngIf="plugin.value.isAdvance; else arrowOpen" src="assets/icons/arrow-down-close-sm.svg"></svg-icon>
                  <ng-template #arrowOpen>
                    <svg-icon src="assets/icons/arrow-right.svg"></svg-icon>
                  </ng-template>
                  Advanced
                </div>
                <div *ngIf="plugin.value.isAdvance" class="advance-section">
                  <div *ngIf="(isAdmin$ | async) && context.title === PluginTypes.Private" class="advance-row">
                    <span class="created-by" style="margin-inline-end: 8px;">Publish this plugin for sharing with the entire team</span>
                    <cgx-toggle label="Make Public when saved" labelPosition="before" formControlName="isShared"></cgx-toggle>
                  </div>
                  <div class="advance-row">
                    <span class="created-by" *ngIf="plugin.controls.userName.value">(created by {{ plugin.controls.userName.value }})</span>
                  </div>
                  <div class="advance-row">
                    <div style="width: 215px;">
                      <div class="input-label">Applications</div>
                      <cgx-select
                        formControlName="applicationNames"
                        placeholder="Select"
                        appendTo="body"
                        selectType="checkbox"
                        size="sm"
                        [searchable]="true"
                        [withSearchHeader]="true"
                        [attr.disabled]="
                          context.title === PluginTypes.Shared &&
                          (username$ | async) !== plugin.controls.userName.value &&
                          (isAdmin$ | async) === false
                        "
                        [items]="applicationNames$ | async"
                      ></cgx-select>
                    </div>

                    <div style="width: 215px;">
                      <div class="input-label">Subsystems</div>
                      <cgx-select
                        formControlName="subsystemNames"
                        placeholder="Select"
                        appendTo="body"
                        selectType="checkbox"
                        size="sm"
                        [searchable]="true"
                        [withSearchHeader]="true"
                        [attr.disabled]="
                          context.title === PluginTypes.Shared &&
                          (username$ | async) !== plugin.controls.userName.value &&
                          (isAdmin$ | async) === false
                        "
                        [items]="subsystemNames$ | async"
                      ></cgx-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="plugin-buttons-wrapper">
                <cgx-button
                  stroked
                  label="DELETE"
                  color="error"
                  size="xs"
                  [disabled]="
                    (saveLoading$ | async) ||
                    (deleteLoading$ | async) ||
                    (context.title === PluginTypes.Shared &&
                      (username$ | async) !== plugin.controls.userName.value &&
                      (isAdmin$ | async) === false)
                  "
                  [loading]="deleteLoading$ | async"
                  srcIconStart="assets/icons/xxs-12x12/trash-full.svg"
                  (press)="deletePlugin()"
                ></cgx-button>

                <div style="display: flex;">
                  <cgx-button
                    style="margin-inline-end: 16px;"
                    label="RESET"
                    size="xs"
                    width="126"
                    [stroked]="true"
                    [disabled]="
                      (loading$ | async) ||
                      (context.title === PluginTypes.Shared &&
                        (username$ | async) !== plugin.controls.userName.value &&
                        (isAdmin$ | async) === false)
                    "
                    (press)="resetPlugin()"
                  ></cgx-button>
                  <cgx-button
                    label="SAVE ACTION"
                    size="xs"
                    width="126"
                    [disabled]="
                      (loading$ | async) ||
                      (context.title === PluginTypes.Shared &&
                        (username$ | async) !== plugin.controls.userName.value &&
                        (isAdmin$ | async) === false)
                    "
                    [loading]="saveLoading$ | async"
                    (press)="savePlugin()"
                  ></cgx-button>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #getLoadingTemplate>
  <cgx-spinner size="40px" class="get-loading"></cgx-spinner>
</ng-template>
