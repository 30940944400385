import { Constants } from '@app/constants';
import { _15MinutesInMS, _1DayInSeconds, _1HourInSeconds, _1SecondInMS, _24HoursInMS } from '@app/shared/constants/times.constant';
import { GraphAggBy, LogsFieldType, IGraphAggBy, LogsGraphType } from './logs-graph.model';

export enum RelativeGraphOption {
  AnHourAgo = 'AnHourAgo',
  DayBefore = 'DayBefore',
  WeekBefore = 'WeekBefore',
  MonthBefore = 'MonthBefore',
  CustomTime = 'CustomTime',
}

export interface IRelativeGraphOption {
  type: LogsGraphType.Relative;
  caption?: string;
  value: RelativeGraphOption;
  startDate?: number;
  endDate?: number;
  seconds?: number;
}

export interface ILogsGraphRelative {
  type: typeof LogsGraphType.Relative;
  aggField: string;
  baseStartDate: number;
  baseEndDate: number;
  compareStartDate: number;
  compareEndDate: number;
  fieldType?: LogsFieldType;
  aggBy?: IGraphAggBy;
}

export class LogsGraphRelative implements ILogsGraphRelative {
  type: LogsGraphType.Relative = LogsGraphType.Relative;
  aggField: string = 'coralogix.metadata.severity';
  baseStartDate: number = new Date().getTime();
  baseEndDate: number = new Date().getTime() - _15MinutesInMS;
  compareStartDate: number = null;
  compareEndDate: number = null;
  aggBy = new GraphAggBy();
  fieldType = LogsFieldType.Log;

  constructor(relativeGraph?: Partial<LogsGraphRelative>, graphOption?: IRelativeGraphOption) {
    if (relativeGraph) {
      Object.keys(relativeGraph).forEach(key => {
        this[key] = relativeGraph[key];
      });
    }
    if (graphOption) this.createRelativeGraphSeries(graphOption);
  }

  getRelativeGraphsOptions(): IRelativeGraphOption[] {
    return [
      {
        type: LogsGraphType.Relative,
        caption: 'An Hour Ago',
        value: RelativeGraphOption.AnHourAgo,
        seconds: _1HourInSeconds,
      },
      {
        type: LogsGraphType.Relative,
        caption: 'Day Before',
        value: RelativeGraphOption.DayBefore,
        seconds: _1DayInSeconds,
      },
      {
        type: LogsGraphType.Relative,
        caption: 'Week Before',
        value: RelativeGraphOption.WeekBefore,
        seconds: _1DayInSeconds * 7,
      },
      {
        type: LogsGraphType.Relative,
        caption: 'Month Before',
        value: RelativeGraphOption.MonthBefore,
        seconds: _1DayInSeconds * 30,
      },
      {
        type: LogsGraphType.Relative,
        caption: 'Custom Time',
        value: RelativeGraphOption.CustomTime,
      },
    ];
  }

  updateSeriesFromBaselineDate(baseStartDate: number, baseEndDate: number): void {
    const timeDiff = this.baseStartDate - this.compareStartDate;
    this.compareStartDate = baseStartDate - timeDiff;
    this.compareEndDate = baseEndDate - timeDiff;
    this.baseStartDate = baseStartDate;
    this.baseEndDate = baseEndDate;
  }

  createRelativeGraphSeries(graphOption: IRelativeGraphOption): void {
    this.compareStartDate = LogsGraphRelative.getRelativeTime(graphOption, graphOption.startDate, this.baseStartDate);
    this.compareEndDate = LogsGraphRelative.getRelativeTime(graphOption, graphOption.endDate, this.baseEndDate);
  }

  static getRelativeTime(graphOption: IRelativeGraphOption, compareDate: number, baseDate: number): number {
    return compareDate || baseDate - graphOption.seconds * _1SecondInMS;
  }
}
