import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class Invite {
  @jsonMember
  public id?: number;
  @jsonMember
  public type: number;
  @jsonMember
  public email: string;
  @jsonMember
  public role: number;
  @jsonMember
  public status: string;
  @jsonMember
  public updated_at: Date;

  public groups?: number[];
}
export interface IInviteData {
  id: number;
  role: number;
  groupIds: number[];
}
export interface IRequestInvite {
  invites: IInviteData[];
  indicesToReset: number[];
}
