import { Component, Input } from '@angular/core';
export enum LegendDataTypes {
  percentage = 1,
  number = 2,
  string = 3
}
export interface ILegendData {
  name: string;
  color: string;
  data?: string;
  dataType?: LegendDataTypes;
}
@Component({
  selector: 'sh-chert-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent {
  @Input() public legendData: ILegendData[];
}
