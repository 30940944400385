import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Constants } from '@app/constants';
import { HttpClient } from '@angular/common/http';
import { IRole } from '@app/settings/account/components/groups/models/models';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }

  public getAllRoles(): Observable<IRole[]> {
    return this.http.get<IRole[]>(`${Constants.GetUrl()}/api/v1/roles`)
  }
}
