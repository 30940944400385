import { type } from '../../../shared/services/utils';
import { Action } from '@ngrx/store';
import ActionTypes = ArchivingProvidersActions.ActionTypes;

export namespace ArchivingProvidersActions {
  export const ActionTypes = {
    GET_ARCHIVING_PROVIDERS: type('[SETTINGS] GET ARCHIVING PROVIDERS'),
    GET_ARCHIVING_PROVIDERS_COMPLETED: type(
      '[SETTINGS] GET ARCHIVING PROVIDERS COMPLETED',
    ),
    GET_ARCHIVING_PROVIDERS_FAILED: type(
      '[SETTINGS] GET ARCHIVING PROVIDERS FAILED',
    ),

    SAVE_ARCHIVING_PROVIDERS: type('[SETTINGS] SAVE ARCHIVING PROVIDERS'),
    SAVE_ARCHIVING_PROVIDERS_COMPLETED: type(
      '[SETTINGS] SAVE ARCHIVING PROVIDERS COMPLETED',
    ),
    SAVE_ARCHIVING_PROVIDERS_FAILED: type(
      '[SETTINGS] SAVE ARCHIVING PROVIDERS FAILED',
    ),

    TEST_ARCHIVING_PROVIDER: type('[SETTINGS] TEST ARCHIVING PROVIDER'),
    TEST_ARCHIVING_PROVIDER_COMPLETED: type(
      '[SETTINGS] TEST ARCHIVING PROVIDER COMPLETED',
    ),
    TEST_ARCHIVING_PROVIDER_FAILED: type(
      '[SETTINGS] TEST ARCHIVING PROVIDER FAILED',
    ),
  };

  export class GetArchivingProvidersAction implements Action {
    public type = ActionTypes.GET_ARCHIVING_PROVIDERS;

    constructor(public payload: any = null) {}
  }
  export class GetArchivingProvidersActionCompleted implements Action {
    public type = ActionTypes.GET_ARCHIVING_PROVIDERS_COMPLETED;

    constructor(public payload: any) {}
  }
  export class GetArchivingProvidersActionFailed implements Action {
    public type = ActionTypes.GET_ARCHIVING_PROVIDERS_FAILED;

    constructor(public payload: boolean) {}
  }
  export class SaveArchivingProvidersAction implements Action {
    public type = ActionTypes.SAVE_ARCHIVING_PROVIDERS;

    constructor(public payload: any = null) {}
  }
  export class SaveArchivingProvidersActionCompleted implements Action {
    public type = ActionTypes.SAVE_ARCHIVING_PROVIDERS_COMPLETED;

    constructor(public payload: any) {}
  }
  export class SaveArchivingProvidersActionFailed implements Action {
    public type = ActionTypes.GET_ARCHIVING_PROVIDERS_FAILED;

    constructor(public payload: boolean) {}
  }

  export class TestArchivingProviderAction implements Action {
    public type = ActionTypes.TEST_ARCHIVING_PROVIDER;

    constructor(public payload: any = null) {}
  }
  export class TestArchivingProviderActionCompleted implements Action {
    public type = ActionTypes.TEST_ARCHIVING_PROVIDER_COMPLETED;

    constructor(public payload: any) {}
  }
  export class SaveArchivingProviderActionFailed implements Action {
    public type = ActionTypes.SAVE_ARCHIVING_PROVIDERS_FAILED;

    constructor(public payload: boolean) {}
  }
  export class TestArchivingProviderActionFailed implements Action {
    public type = ActionTypes.TEST_ARCHIVING_PROVIDER_FAILED;

    constructor(public payload: boolean) {}
  }

  export type Actions =
    | GetArchivingProvidersAction
    | GetArchivingProvidersActionCompleted
    | GetArchivingProvidersActionFailed
    | SaveArchivingProvidersAction
    | SaveArchivingProvidersActionCompleted
    | SaveArchivingProvidersActionFailed
    | TestArchivingProviderAction
    | TestArchivingProviderActionCompleted
    | TestArchivingProviderActionFailed
    | SaveArchivingProviderActionFailed;
}
