<div class="list-container" *ngFor="let archiveQuery of archiveQueries">
  <sh-archive-query-table-item class="list-item"
                               [archiveQuery]="archiveQuery"
                               [queryUiState]="uiState[archiveQuery.id]"
                               (onGetTsvs)="getTsvs($event)"
                               (onReindex)="reindex($event)"
                               (showLogsPreview)="showPreviewLog($event)"
                               (showLogsGrid)="showPreviewGrid($event)"
                               (onGetFailCause)="showFailCause($event)"
  (showLogsScreen)="showLogsScreen($event)"></sh-archive-query-table-item>
</div>
