import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { UserSettingsProvider } from './shared/userSettingsProvider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UserSettingsService } from './shared/user-settings.service';
import { UserResolve } from './shared/userResolve';
import { AnalyticEventService } from './shared/AnalyticEventService';
import { InviteService } from './shared/srvices/invite.service';
import { TeamService } from './shared/srvices/teams.service';
import { TeamUsersService } from './shared/srvices/team-users.service';
import { CompanyService } from './shared/srvices/company.service';
import { FilterPipeModule } from '@app/shared/pipes/filter-pipe/filter-pipe.module';

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    FilterPipeModule
  ],
  declarations: [
  ],
  exports: [],
  providers: [
    UserSettingsProvider,
    UserSettingsService,
    UserResolve,
    AnalyticEventService,
    InviteService,
    TeamService,
    CompanyService,
    TeamUsersService,
  ],
})
export class UserModule { }
