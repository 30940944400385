<div class="page-container">
  <div class="overlay" *ngIf="!form || isLoading">
    <div class="ui active inverted dimmer">
      <div class="ui loader"></div>
    </div>
  </div>
  <form [formGroup]="form">
    <section class="head-section">
      <p class="primary-header coralogix">{{ isEdit ? detailsGroup.value.alertName : 'New Alert' }}</p>
      <div class="controls">
        <mat-slide-toggle labelPosition="before" class="coralogix" formControlName="isActive">
          <span class="slider-label">Enable Alert</span>
        </mat-slide-toggle>
        <cgx-button
          data-test="alerts-save-or-edit-btn"
          width="140px"
          [label]="isEdit ? 'Save Changes' : 'Create Alert'"
          [disabled]="isFormInvalid"
          (press)="onSave()"
        ></cgx-button>
      </div>
    </section>

    <section class="content-wrapper">
      <div class="content" *ngIf="form && !isLoading">
        <sh-alerts-details [form]="detailsGroup" [severityOptions]="selectOptions.alertSeverityOptions"></sh-alerts-details>

        <sh-alert-type [form]="form"></sh-alert-type>

        <sh-alerts-query-container
          [isFormInvalid]="isFormInvalid"
          [form]="form"
          [invalidErrMsg]="invalidErrMsg"
          [relativeAlertsArray]="relativeAlertsArray"
          [queryGroup]="queryGroup"
          [selectOptions]="selectOptions"
          [isFromQuery]="isFromQuery"
          [relativeAlertSelected]="relativeAlertSelected"
          [metricAlertSelected]="metricAlertSelected"
          [relativeCalcStr]="relativeCalcStr"
          [alertType]="alertType"
          [conditionsGroup]="conditionsGroup"
        ></sh-alerts-query-container>

        <sh-alerts-recipients
          *ngIf="alertTypeViewsDefs[alertType]?.includes(componentTypes.recipients)"
          [form]="recipientsGroup"
          [integrationOptions]="integrations$ | async"
          (focus)="getIntegrations()"
        ></sh-alerts-recipients>

        <sh-alerts-schedule
          data-test="alerts-schedule-panel"
          *ngIf="alertTypeViewsDefs[alertType]?.includes(componentTypes.schedule)"
          [weekDayOptions]="selectOptions.weekDayOptions"
          [form]="scheduleGroup"
          [isEdit]="isEdit"
        ></sh-alerts-schedule>

        <sh-alerts-content
          data-test="alerts-notification-content-panel"
          *ngIf="alertTypeViewsDefs[alertType]?.includes(componentTypes.notificationContent)"
          [form]="contentGroup"
          [isEdit]="isEdit"
        ></sh-alerts-content>

        <sh-verify-alert
          data-test="alerts-verify-alert-panel"
          *ngIf="alertTypeViewsDefs[alertType]?.includes(componentTypes.verifyAlert)"
          [form]="form"
        ></sh-verify-alert>
        <sh-alert-history
          *ngIf="isEdit"
          [alertHistory]="alert.history"
          [createDate]="alert.createDate"
          [lastModified]="alert.updateDate"
        ></sh-alert-history>
        <cgx-button
          *ngIf="isEdit"
          color="error"
          label="Delete Alert"
          size="md"
          data-test="delete-alert-btn"
          [outline]="true"
          (press)="onDelete()"
        ></cgx-button>
      </div>
    </section>
  </form>
</div>
