import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class CompanyPlanDetails {
  @jsonMember
  public name: string;
  @jsonMember
  public size: number;
  @jsonMember
  public retention: number;
  @jsonMember
  public price: number;

  get dailySize(): number {
    return this.size / 30;
  }
}

export const GIGA_PRICE_PER_DOLLAR = 1.5;
