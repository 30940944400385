import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesktopNavbarComponent } from './desktop-navbar.component';
import { LoadingBarModule } from '@app/layout/loading-bar/loading-bar.module';
import { UserSettingsFiltersModule } from '@app/user/user-settings-filters/user-settings-filters.module';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { UserMenuModule } from '../user-menu/user-menu.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { AvatarInitialsModule } from '@app/shared/avatar-initials/avatar-initials.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DataFlowRoutesModule } from '@app/layout/navbar/data-flow-routes/data-flow-routes.module';
import { CgxTooltipModule } from '@coralogix/design';

@NgModule({
  declarations: [DesktopNavbarComponent],
  imports: [
    CommonModule,
    LoadingBarModule,
    UserSettingsFiltersModule,
    RouterModule,
    MatMenuModule,
    OverlayModule,
    UserMenuModule,
    AvatarInitialsModule,
    AngularSvgIconModule,
    DataFlowRoutesModule,
    CgxTooltipModule,
  ],
  exports: [DesktopNavbarComponent]
})
export class DesktopNavbarModule { }
