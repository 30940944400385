import { TypedJSON } from 'typedjson';
import { IGetRowsParams } from 'ag-grid';

import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';

export class LoggregationManager {
  public data: any;

  constructor(data: any) {
    this.data = data;
  }

  public getRows(params: IGetRowsParams): void {
    if (!isNaN(params.startRow) && !isNaN(params.endRow)) {

      if (this.data && this.data.length > 0) {
        const data = this.data.slice(params.startRow, params.endRow);
        const results = [];
        data.forEach(entity => {
          try {
            const typedEntity: LoggregationEntity = TypedJSON.parse(JSON.stringify(entity), LoggregationEntity);
            if (entity.logExample) {
              typedEntity.logExample = entity.logExample;
            }
            results.push(typedEntity);
          } catch (e) {
            console.log(e);
          }
        });
        params.successCallback(results, this.data.length);
      } else {
        params.successCallback([], 0);
      }

    }
  }
}
