import { ERuleServerTypes, RuleMatcher, RuleMatcherFieldOptions } from '@app/rules/shared/models/rule.model';
import { UDIDHelper } from '@shared/services/udid.helper';
import { FormControl } from '@angular/forms';
import { ITestParsingThemeRuleResponse } from '@app/rules/shared/models/sample-log-preview.models';

export interface IParsingThemeFormData {
  details: IParsingThemeDetailsFormData;
  ruleMatchers: IRuleMatcherFormData;
  rulesGroups: IRuleGroupFormData[];
  enabled: boolean;
  logSample: string;
  id: string;
}

export interface IParsingThemeDetailsFormData {
  name: string;
  description: string;
}

export interface IRuleMatcherFormData {
  applications: string[];
  subsystems: string[];
  severities: string[];
}

export interface IRuleGroupFormData {
  id: string;
  name: string;
  order: number;
  rules: IRuleFormData[];
}

export interface IRuleFormData {
  id: string;
  name: string;
  description: string;
  order: number;
  enabled: boolean;
  type: ERuleServerTypes;
  sourceField: string;
  destinationField: string;
  rule: string;
  replaceNewVal: string;
  keepBlockedLogs: boolean;
  blockMatching: string;
  logExample: string;
  logExampleRes: ITestParsingThemeRuleResponse;
}

export class RuleMatcherView {
  private readonly applications: string[];
  private readonly subsystems: string[];
  private readonly severities: string[];

  constructor(data: RuleMatcher[]) {
    this.applications = this.getRuleMatcherArrayByProp(RuleMatcherFieldOptions.applications, data);
    this.subsystems = this.getRuleMatcherArrayByProp(RuleMatcherFieldOptions.subsystems, data);
    this.severities = this.getRuleMatcherArrayByProp(RuleMatcherFieldOptions.severities, data);
  }

  public getProps(): IRuleMatcherFormData {
    return {
      applications: this.applications,
      subsystems: this.subsystems,
      severities:  this.severities
    };
  }

  private getRuleMatcherArrayByProp(field: string, data: RuleMatcher[]): string[] {
    const filtered = data?.filter((matcher) => matcher.field === field);
    const propArray = filtered?.reduce((prev, curr) => {
      if (!!curr?.constraint?.trim()) {
        return prev?.length ? [...prev, curr.constraint] : [curr.constraint];
      } else {
        return prev;
      }
    }, []);
    return propArray?.length ? propArray : null;
  }

  private getSeveritiesArray(data: RuleMatcher[]): number[] {
    const ruleMarcherAsArray = this.getRuleMatcherArrayByProp(RuleMatcherFieldOptions.severities, data);
    return ruleMarcherAsArray.map(severity => {
      return Number(severity);
    });
  }
}

export class RuleGroupFormData implements IRuleGroupFormData {
  public id: string = '';
  public order: number = 1;
  public rules: IRuleFormData[] = [];
  public name: string = '';
  constructor(data: Partial<IRuleGroupFormData>) {
    Object.assign(this, data);
    this.rules = data.rules.map(rule => new RuleFormData(rule));
    if (!data?.id) {
      this.id = new UDIDHelper().generateShortUUID();
    }
  }
}

export class RuleFormData implements IRuleFormData {
  public id: string = '';
  public name: string = '';
  public description: string = '';
  public order: number = 0;
  public enabled: boolean = true;
  public type: ERuleServerTypes = null;
  public sourceField: string = '';
  public destinationField: string = '';
  public rule: string = '';
  public replaceNewVal: string = '';
  public keepBlockedLogs: boolean = false;
  public blockMatching: string = ERuleServerTypes.block;
  public logExample: string = '';
  public logExampleRes: ITestParsingThemeRuleResponse = null;
  constructor(data: Partial<IRuleFormData>) {
    Object.assign(this, data);
    if (!data?.id) {
      this.id = new UDIDHelper().generateShortUUID();
    }
  }
}

export interface IRuleFieldOptionValue {
  name: string;
  value: any;
  rulesToSkip: ERuleServerTypes[];
  _metadata?: any;
}

export interface IUpdateParsingThemeRuleLogActionPayload {
  rule: IRuleFormData;
  parsingThemeId: string;
  groupId: string;
}

export interface ITimestampStandardFormat extends IRuleFieldOptionValue {
  enabled: boolean;
  format: string;
  isDefault?: boolean;
}

export interface ITimestampStandard extends IRuleFieldOptionValue {
  _metadata: ITimestampStandardFormat;
}
