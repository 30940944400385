import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Select} from '@ngxs/store';
import {CompanyState} from '@app/ngxs-store/company/company.state';
import {Observable, Subject} from 'rxjs';
import {CompanyRequest} from '@app/auth/shared/models/activateUser.model';
import {takeUntil} from 'rxjs/operators';
import {PermissionsState} from '@app/ngxs-store/permissions/permissions.state';
import {ACTIONS, RESOURCES} from '@app/auth/shared/models/auth.models';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {GetCompany} from '@app/ngxs-store/company/company.action';
import {GetUserPermission} from '@app/ngxs-store/permissions/permissions.action';

@Component({
  selector: 'sh-data-flow-routes',
  templateUrl: './data-flow-routes.component.html',
  styleUrls: ['./data-flow-routes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFlowRoutesComponent implements OnInit, OnDestroy {
  @Select(CompanyState.companyInfo) public companyInfo$: Observable<CompanyRequest>;

  @Select(PermissionsState.getPermission(RESOURCES.API_ACCESS)) public getPermissionAccessApi$: Observable<boolean>;

  @Input() isAdmin: boolean;

  @Input() isReadOnly: boolean;

  @Input() username: string;

  @Output() closeTooltip = new EventEmitter();

  isArchiveVersionValid: boolean;

  destroyed$ = new Subject();

  constructor() {
  }

  ngOnInit(): void {
    this.companyInfo$.pipe(takeUntil(this.destroyed$)).subscribe(company => {
      this.isArchiveVersionValid = company?.archiving_provider?.archive_format !== 1;
    });
    this.getCompany();
    this.getPermission();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeMenuTooltip(): void {
    this.closeTooltip.emit();
  }

  @Dispatch() public getCompany = () => new GetCompany();
  @Dispatch() public getPermission = () => new GetUserPermission(RESOURCES.API_ACCESS, ACTIONS.READ_ONLY);
}
