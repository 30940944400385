import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

@jsonObject
export class IntegrationTypeModel {
  @jsonMember
  public created_at: string;

  @jsonMember
  public icon: string;

  @jsonMember
  public id: number;

  @jsonMember({ name: 'label', constructor: String })
  public name: string;

  @jsonMember
  public updated_at: string;
}

@jsonObject
export class IntegrationTypeFieldModel {
  @jsonMember
  public name: string;

  @jsonMember
  public value: string;
}

@jsonObject
export class IntegrationModel {
  @jsonMember
  public alias: string;

  @jsonMember
  public company_id: number;

  @jsonMember
  public created_at: string;

  @jsonMember
  public id: number;

  @jsonMember
  public integration_type: IntegrationTypeModel;

  @jsonArrayMember(IntegrationTypeFieldModel)
  public integration_type_fields: IntegrationTypeFieldModel[];

  @jsonMember
  public integration_type_id: number;

  @jsonMember
  public updated_at: string;

  @jsonMember
  public url: string;
}

@jsonObject
export class IntegrationTestResult extends IntegrationModel {
  @jsonMember
  public success: boolean;

  @jsonMember
  public statusCode: number;

  constructor() {
    super();
  }
}
