<ng-container [ngSwitch]="viewState">
    <div class="feature-layout__loader" *ngSwitchCase="viewStates.loading">
      <div class="ui active inline loader"></div>
    </div>
    <div class="feature-layout__no-content" *ngSwitchCase="viewStates.noResults">
      {{noResultLabel}}
    </div>
    <div class="feature-layout__no-content" *ngSwitchCase="viewStates.serverError">
      Content not available
    </div>
</ng-container>

