import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromqlEditorComponent } from '@shared/promql-editor/promql-editor.component';

@NgModule({
  declarations: [PromqlEditorComponent],
  imports: [CommonModule],
  exports: [PromqlEditorComponent],
})
export class PromqlEditorModule {}
