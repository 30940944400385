import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'sh-logs-options-menu',
  templateUrl: './logs-options-menu.component.html',
  styleUrls: ['./logs-options-menu.component.scss'],
})
export class LogsOptionsMenuComponent {
  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  @Input() public addWidgetEnable: boolean;

  @Output() public toggleGrpah: EventEmitter<any> = new EventEmitter<any>();

  @Output() public toggleFilters: EventEmitter<any> = new EventEmitter<any>();

  @Output() public export: EventEmitter<any> = new EventEmitter<any>();

  @Output() public widget: EventEmitter<any> = new EventEmitter<any>();

  @Output() public detectGroks: EventEmitter<any> = new EventEmitter<any>();

}
