import { Invite } from '../../shared/models/invite';
import {
  AddInvites,
  ApproveInviteRequests,
  DeclineInviteRequests,
  DeleteInviteRequest,
  GetInvites,
} from './invites.actions';
import { InvatationType } from '../../../settings/account/shared/enums/invitation-type.enum';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ShDialogService } from '@shared/services/dialogService';
import { InviteService } from '@app/user/shared/srvices/invite.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';

export interface IInvites {
  pendingInvites: Invite[];
  acceptedInvites: Invite[];
  pendingInviteRequests: Invite[];
  acceptedInviteRequests: Invite[];
  loadingInvites: boolean;
  sendingInvite: boolean;
}

interface IInviteTypeMap {
  pendingInvites: Invite[];
  acceptedInvites: Invite[];
  pendingInviteRequests: Invite[];
  acceptedInviteRequests: Invite[];
}

const inviteState: IInvites = {
  pendingInvites: [],
  acceptedInvites: [],
  pendingInviteRequests: [],
  acceptedInviteRequests: [],
  loadingInvites: false,
  sendingInvite: false,
};

function buildInvitesTypeMap(invites: Invite[]): IInviteTypeMap {
  const invitesMap: IInviteTypeMap = {
    pendingInvites: [],
    acceptedInvites: [],
    pendingInviteRequests: [],
    acceptedInviteRequests: []
  };
  return invites.reduce((acc, invite) => {
    if (invite.type === InvatationType.Invite && invite.status === 'pending') {
      acc.pendingInvites.push(invite);
    }
    if (invite.type === InvatationType.Invite && invite.status === 'accepted') {
      acc.acceptedInvites.push(invite);
    }
    if (invite.type === InvatationType.Request && invite.status === 'pending') {
      acc.pendingInviteRequests.push(invite);
    }
    if (invite.type === InvatationType.Request && invite.status === 'accepted') {
      acc.acceptedInviteRequests.push(invite);
    }
    return acc;
  }, invitesMap);
}

@State<IInvites>({
  name: 'invites',
  defaults: inviteState
})
@Injectable()
export class InvitesState {

  constructor(private dialogService: ShDialogService, private inviteService: InviteService, private ea: AnalyticEventService) { }

  @Selector()
  public static getPendingInvites (state: IInvites): Invite[] {
    return state.pendingInvites;
  }

  @Selector()
  public static getAcceptedInvites (state: IInvites): Invite[] {
    return state.acceptedInvites;
  }

  @Selector()
  public static getAcceptedInviteRequests (state: IInvites): Invite[] {
    return state.acceptedInviteRequests;
  }

  @Selector()
  public static getPendingInviteRequests (state: IInvites): Invite[] {
    return state.pendingInviteRequests;
  }

  @Selector()
  public static getInvitesLoadingStatus (state: IInvites): boolean {
    return state.loadingInvites;
  }

  @Action(GetInvites)
  public getInvites(ctx: StateContext<IInvites>): Observable<IInvites> {
    ctx.patchState({ loadingInvites: true });
    return this.inviteService.getInvites().pipe(
      map(invites => {
        const invitesTypesMap = buildInvitesTypeMap(invites);
        return ctx.patchState({
          pendingInvites: invitesTypesMap.pendingInvites.sort(
            this.sortInvites,
          ),
          acceptedInvites: invitesTypesMap.acceptedInvites.sort(
            this.sortInvites,
          ),
          pendingInviteRequests: invitesTypesMap.pendingInviteRequests.sort(
            this.sortInvites,
          ),
          acceptedInviteRequests: invitesTypesMap.acceptedInviteRequests.sort(
            this.sortInvites,
          ),
        });
      }),
      catchError(() => {
        this.dialogService.showSnackBar(`ERROR: Failed to fetch invites`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loadingInvites: false });
      })
    );
  }

  @Action(AddInvites)
  public addInvites(ctx: StateContext<IInvites>, { invites }: AddInvites): Observable<IInvites> {
    ctx.patchState({ sendingInvite: true });
    return this.inviteService
      .addInvites(invites).pipe(
        map(invts => {
          this.reportea(invts);
          const invitesTypesMap = buildInvitesTypeMap(invts);
          return ctx.patchState({
              pendingInvites: [ ...ctx.getState().pendingInvites, ...invitesTypesMap.pendingInvites ],
              acceptedInvites: [ ...ctx.getState().acceptedInvites, ...invitesTypesMap.acceptedInvites ],
          });
        }),
        catchError(() => {
          this.dialogService.showSnackBar(`ERROR: Failed to add invites`, null, 5000);
          return of(null);
        }),
        finalize(() => {
          ctx.patchState({ sendingInvite: false });
        })
      );
  }
  @Action(ApproveInviteRequests)
  public approveInviteRequests(ctx: StateContext<IInvites>, { payload }: ApproveInviteRequests): Observable<IInvites> {
    ctx.patchState({ loadingInvites: true } );
    return this.inviteService.approveInviteRequests(payload).pipe(
      map(ids => {
        ctx.dispatch(GetInvites);
        return ctx.getState();
      }),
      catchError((e) => {
        this.dialogService.showSnackBar(`ERROR: Failed to approve invites`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loadingInvites: false });
      })
    );
  }

  @Action(DeclineInviteRequests)
  public declineInviteRequests(ctx: StateContext<IInvites>, { payload }: DeclineInviteRequests): Observable<IInvites> {
    ctx.patchState({ loadingInvites: true });
    return this.inviteService.declineInviteRequests(payload).pipe(
      map(ids => {
        const pendingInvites = ctx.getState().pendingInviteRequests;
        const newPendingInviteRequests = pendingInvites.filter(
          (pendingReq) => !ids.includes(pendingReq.id),
        );
        return ctx.patchState({
          pendingInviteRequests: newPendingInviteRequests,
        });
      }),
      catchError(() => {
        this.dialogService.showSnackBar(`ERROR: Failed to decline invites`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loadingInvites: false });
      })
    );
  }

  @Action(DeleteInviteRequest)
  public deleteInviteRequests(ctx: StateContext<IInvites>, { payload }: DeleteInviteRequest): Observable<IInvites> {
    ctx.patchState({ loadingInvites: true });
    return this.inviteService.deleteInvite(payload).pipe(
      map(() => {
        ctx.dispatch(GetInvites);
        return ctx.getState();
      }),
      catchError(() => {
        this.dialogService.showSnackBar(`ERROR: Failed to remove invites`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loadingInvites: false });
      })
    );
  }

  public reportea(invites: any[]): void {
    if (invites) {
      this.ea.dataLayerReport({
        event: 'invited_team_members',
        count: invites.length,
      });
    }
  }

  private sortInvites(i1: Invite, i2: Invite): number {
    return i2.updated_at.getTime() - i1.updated_at.getTime();
  }
}
