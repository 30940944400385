import { ILegendData } from '@app/statistics/components/chart-legend/chart-legend.component';

export interface ITotalSeverity {
  size: number;
  y: number;
  name: string;
  severity: string;
  color: string;
}
export const lineChartLegend: ILegendData[] = [
  {
    name: 'Current tag',
    color: 'var(--cgx-primary)'
  },
  {
    name: 'Comparison Tag',
    color: '#8d9db9'
  }
];
