import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SmallWidgetComponent } from './widgets/small-widget/small-widget.component';
import { InsightsModule } from '../insights/insights.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { StatisticsModule } from '../statistics/statistics.module';
import { LoggregationModule } from '../loggregation/loggregation.module';
import { LivetailModule } from '../livetail/livetail.module';
import { CommonModule } from '@angular/common';
import { WidgetAddComponent } from '../shared/controls/widgets/widget-add/widget-add.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    InsightsModule,
    WidgetsModule,
    StatisticsModule,
    LoggregationModule,
    LivetailModule,
  ],
  declarations: [DashboardComponent, SmallWidgetComponent],
  entryComponents: [WidgetAddComponent],
})
export class DashboardModule {}
