import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewChildren } from '@angular/core';
import { CoralogixSidebarComponent } from '@shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { Observable, Subject } from 'rxjs';
import { AbstractControl, FormGroup } from '@angular/forms';
import { State } from '@app/app.reducers';
import { Store as NGRXStore } from '@ngrx/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetLogFields, GetPlugins } from '@app/ngxs-store/plugins/plugins.action';
import { getGenericMetadataField } from '@shared/state/selectors/metadata.selectors';
import { PluginsState } from '@app/ngxs-store/plugins/plugins.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { ManagePluginsContainerFormsService } from './manage-plugins-container-forms.service';
import { UserState } from '@app/ngxs-store/user/user.state';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PluginTypes } from '@shared/enums/plugin-types.enum';
import { CdkScrollable } from '@angular/cdk/overlay';

@Component({
  selector: 'sh-plugins-container',
  templateUrl: './plugins-container.component.html',
  styleUrls: ['./plugins-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ManagePluginsContainerFormsService],
})
export class PluginsContainerComponent {
  @ViewChild(CdkScrollable) scrollContainer: CdkScrollable;

  @ViewChild('sidebarComponent', { static: true }) sidebar: CoralogixSidebarComponent;

  @ViewChildren('nameInput') nameInput;

  @SelectSnapshot(PluginsState.pluginsLogFields) public logFields: string[];

  @Select(PluginsState.deleteLoading) public deleteLoading$: Observable<boolean>;

  @Select(PluginsState.saveLoading) public saveLoading$: Observable<boolean>;

  @Select(PluginsState.loading) public loading$: Observable<boolean>;

  @Select(PluginsState.getLoading) public getLoading$: Observable<boolean>;

  @Select(UserState.isAdmin) public isAdmin$: Observable<boolean>;

  @Select(UserState.username) public username$: Observable<string>;

  applicationNames$ = this.ngrxStore.select(getGenericMetadataField('applicationName'));

  subsystemNames$ = this.ngrxStore.select(getGenericMetadataField('subsystemName'));

  destroyed$ = new Subject();

  dummyCloseUrlPanel$ = new Subject();

  jsExpressionString = 'Use {{ <javascript-expression> }} to embed event data in the template.';

  metaDataTooltipHeader = '$m.<metadata>';

  keySubKeyTooltipHeader = '$d.<key>.<subkey>';

  PluginTypes = PluginTypes;

  constructor(
    private cdr: ChangeDetectorRef,
    private ngrxStore: NGRXStore<State>,
    private mpcfService: ManagePluginsContainerFormsService,
  ) {}

  get currentPluginForm(): FormGroup {
    return this.mpcfService.currentPluginForm;
  }

  get newPluginForm(): FormGroup {
    return this.mpcfService.newPluginForm;
  }

  get privateRenderFormArray(): AbstractControl[] {
    return this.mpcfService.privateRenderFormArray;
  }

  get sharedRenderFormArray(): AbstractControl[] {
    return this.mpcfService.sharedRenderFormArray;
  }

  init(): void {
    this.getLogFields();
    this.getPlugins();
  }

  destroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openPluginsEditor(): void {
    this.init();
    this.sidebar.showSidebar();
    this.cdr.markForCheck();
  }

  closePluginsEditor(): void {
    this.sidebar.hideSidebar();
    this.mpcfService.closePanel();
    this.destroy();
  }

  addNewPlugin(): void {
    this.mpcfService.addNewPlugin();
  }

  savePlugin(): void {
    this.mpcfService.savePlugin();
  }

  resetPlugin(): void {
    this.mpcfService.resetPlugin();
  }

  toggleAdvance(): void {
    this.mpcfService.toggleAdvance();
  }

  toggleHidden(pluginForm: FormGroup): void {
    this.mpcfService.toggleHidden(pluginForm);
  }

  deletePlugin(): void {
    this.mpcfService.deletePlugin();
  }

  clonePlugin(plugin: FormGroup): void {
    this.mpcfService.clonePlugin(plugin);
    this.cdr.detectChanges(); // important: finish render the new item, then scroll
    this.scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openPluginForm(pluginForm: FormGroup): void {
    this.mpcfService.openPluginForm(pluginForm);
    this.dummyCloseUrlPanel$.next(new Date());
  }

  focusNameInput(index: number): void {
    this.cdr.detectChanges();
    this.nameInput._results[index].nativeElement.focus();
  }

  drop(event: CdkDragDrop<string[]>, context: PluginTypes): void {
    this.mpcfService.drop(event, context);
  }

  @Dispatch() public getLogFields = () => new GetLogFields();
  @Dispatch() public getPlugins = () => new GetPlugins();
}
