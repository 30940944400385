<mat-expansion-panel
  data-test="verify-alert-expansion-panel"
  class="coralogix"
  (opened)="onExpand()"
  [disabled]="expanded"
  *ngIf="!isMoreThenDynamicAlert"
>
  <mat-expansion-panel-header>
    <mat-panel-title>Verify Alert</mat-panel-title>
    <button class="coralogix button refresh-button" [disabled]="isLoading" (click)="getChartData()">
      <i class="fa fa-refresh" [class.animate]="isLoading"></i>
      <span>Verify</span>
    </button>
  </mat-expansion-panel-header>

  <div class="content" *ngIf="expanded">
    <div class="overlay" [hidden]="!isLoading">
      <div class="ui active inverted dimmer">
        <div class="ui loader"></div>
      </div>
    </div>
    <div *ngIf="!errorMessage">
      <p class="mat-expansion-panel-subheader" *ngIf="!isLoading">
        This alert would have generated
        <span class="highlighted">{{ totalAlerts }} alerts</span>
        in the previous
        <span class="highlighted">{{ verifyTimeRange }} hours</span>
      </p>
      <sh-verify-alert-chart
        #chart
        data-test="sh-verify-alert-chart"
        [verifyAlertData]="verifyAlertData"
        [isLoading]="isLoading"
        [numberOfLogsToALert]="numberOfLogsToAlert"
        [alertSeverity]="alertSeverity"
        [conditionOperator]="conditionOperator"
        [totalAlerts]="totalAlerts"
        [schedule]="schedule"
      ></sh-verify-alert-chart>
    </div>
    <div *ngIf="errorMessage || totalAlerts === 0" class="error-message-container coralogix" data-test="alerts-verify-alert-error">
      <img src="../../../../../assets/alerts/fail-verify.png" />
    </div>
  </div>
</mat-expansion-panel>
