<div
  class="ui inverted transparent right action left icon input selected-quick-query"
  #quickQueryIcon
>
  <i
    class="fa fa-clock-o"
    style="margin-top: auto;margin-bottom:auto;margin-left: 5px; float: left;text-align: left;"
  ></i>
  <span class="label-predefined">{{ selectedPredefinedValue }}</span>
</div>
<div class="ui special popup fluid myPop">
  <sh-quick-query-predefined
    (selectedValueChange)="onSelectedValueChange($event)"
  ></sh-quick-query-predefined>
</div>
