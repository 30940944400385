import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Team } from '../../../user/shared/models/team';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getTeams, State } from '../../../app.reducers';
import { TeamActions } from '../../../user/state/teams/teams.actions';
import GetTeams = TeamActions.GetTeams;

@Component({
  selector: 'sh-login-teams',
  templateUrl: './login-teams.component.html',
  styleUrls: ['./login-teams.component.scss'],
})
export class LoginTeamsComponent {
  public teams$: Observable<Team[]>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<State>,
  ) {
    this.store.dispatch(new TeamActions.GetTeams());
    this.teams$ = this.store.select(getTeams);
  }

  public createNewTeam() {
    this.router.navigate(['/login/createTeam']);
  }

  public selectTeam(team: Team) {
    // this.authService.selectTeam(team);
    this.store.dispatch(
      new TeamActions.SelectTeam({ teamId: team.id, isNew: false }),
    );
    // if(this.authService.redirectTo){
    //   const redirectTo = this.authService.redirectTo;
    //   this.authService.redirectTo = null;
    //   this.router.navigateByUrl(redirectTo)
    // }else {
    //   this.router.navigate(['/dashboard']);
    // }
  }
}
