import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { TypedJSON } from 'typedjson';
import { ParsingThemesResponse, IParsingThemesResponse, IParsingTheme, IToggleParsingThemeAction, ExtendedUniqueFields } from '../models/rule.model';
import { HttpClient } from '@angular/common/http';
import { ITestParsingThemeRuleRequest, ITestParsingThemeRuleResponse } from '@app/rules/shared/models/sample-log-preview.models';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '@app/services/configuration.service';

@Injectable({ providedIn: 'root' })
export class ParsingThemesApiService {
  constructor(private http: HttpClient, private configService: ConfigurationService) {}

  public getParsingThemes(): Observable<IParsingThemesResponse> {
    const parsingThemesUrl = `${this.getBaseUrl()}/rule-set`;
    return this.http
      .get(parsingThemesUrl)
      .map((res: any) => new TypedJSON(ParsingThemesResponse).parse(res))
      .map((parsingThemesResponse: IParsingThemesResponse) => parsingThemesResponse);
  }

  public saveParsingTheme(parsingTheme: IParsingTheme): Observable<IParsingTheme> {
    const saveUrl = `${this.getBaseUrl()}/crud/rule-set`;
    return this.http.put<IParsingTheme>(saveUrl, parsingTheme);
  }

  public createParsingTheme(parsingTheme: IParsingTheme): Observable<IParsingTheme> {
    const createUrl = `${this.getBaseUrl()}/crud/rule-set`;
    return this.http.post<IParsingTheme>(createUrl, parsingTheme);
  }

  public toggleParsingTheme(toggleData: IToggleParsingThemeAction): Observable<string> {
    const toggleUrl = `${this.getBaseUrl()}/rule-set/toggle/${toggleData.parsingThemeId}`;
    return this.http.put(toggleUrl,
    { enabled: toggleData.toggleState },
    { responseType: 'text' });
  }

  public reorderParsingThemes(orders: string[]): Observable<string> {
    const reorderUrl = `${this.getBaseUrl()}/rule-set/reorder`;
    return this.http.put(reorderUrl,
    { orders },
    { responseType: 'text' });
  }

  public deleteParsingTheme(parsingThemeId: string): Observable<string> {
    const deleteUrl = `${this.getBaseUrl()}/crud/rule-set/${parsingThemeId}`;
    return this.http.delete(deleteUrl, { responseType: 'text' });
  }

  public testParsingThemeRule(request: ITestParsingThemeRuleRequest): Observable<ITestParsingThemeRuleResponse> {
    const testUrl = `${this.getBaseUrl()}/rule_tester`;
    return this.http.post<ITestParsingThemeRuleResponse>(testUrl, request);
  }

  public getExtendedUniqueFields(): Observable<string[]> {
    const extendedUniqueFieldsUrl = this.configService.getConfigValue('extendedTemplateIndexFields', '') ||
      this.configService.getConfigValue('indexFieldsUrl', '');
    return this.http.get(extendedUniqueFieldsUrl).pipe(
      map(data => (new TypedJSON(ExtendedUniqueFields).parse(data)).uniqueFields)
    );
  }

  private getBaseUrl(): string {
    const url = this.configService.getConfigValue('url', '');
    const urlVersionPrefix = this.configService.getConfigValue('apiVersionUrl', '');
    const rulesApiPrefix = this.configService.getConfigValue('rulesApiPrefix', '');
    return `${url}${urlVersionPrefix}${rulesApiPrefix}`;
  }
}
