import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { DialogTitleModule } from '../dialog-title/dialog-title.module';
import { DialogBodyModule } from '../dialog-body/dialog-body.module';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { DialogActionsModule } from '../dialog-actions/dialog-actions.module';
import { MaterialModule } from '@app/shared/material/material.module';
import { CgxButtonModule } from '@coralogix/design';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [
    CommonModule,
    BaseDialogModule,
    DialogTitleModule,
    DialogBodyModule,
    DialogActionsModule,
    MaterialModule,
    CgxButtonModule
  ],
  exports: [ConfirmationDialogComponent],
  entryComponents: [ConfirmationDialogComponent],
})
export class ConfirmationDialogModule {}
