<mat-card class="coralogix card" [class.invalid]="!isFormValid">
  <form [formGroup]="form">
    <p class="metric-card-subheader">Target logs by application, subsystem(s) or the text contained within the logs.</p>
    <div class="error-container">
      <p class="error" *ngIf="!isFormValid">At least one of the query fields must be filled</p>
    </div>
    <div class="coralogix input-group">
      <div class="validation-error" *ngIf="validationError">{{ validationError }}</div>
      <sh-search-query formControlName="text"></sh-search-query>
    </div>
    <div class="coralogix input-group shorten-string">
      <sh-conditional-select
        label="Applications"
        [items]="applicationOptions$ | async"
        [allGroups]="$any(form.controls['applicationName'])"
        [btnLabel]="form.controls['applicationName'].value?.length ? 'ADD MORE APPS' : 'ADD APPS'"
      ></sh-conditional-select>
    </div>
    <div class="coralogix input-group shorten-string">
      <sh-conditional-select
        label="Subsystems"
        [items]="subsystemOptions$ | async"
        [allGroups]="$any(form.controls['subsystemName'])"
        [btnLabel]="form.controls['subsystemName'].value?.length ? 'ADD MORE SUBSYSTEMS' : 'ADD SUBSYSTEMS'"
      ></sh-conditional-select>
    </div>
    <div class="coralogix input-group">
      <sh-coralogix-select
        [selectOptions]="logsSeverityOptions"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [multiple]="true"
        [searchable]="true"
        [allSelectedLabel]="'All Severities'"
        [hasColoredIcon]="true"
        [placeholder]="'Select severities'"
        formControlName="logSeverity"
      >
        <label select-label>
          <span class="text">Severities</span>
        </label>
      </sh-coralogix-select>
    </div>
  </form>
</mat-card>
