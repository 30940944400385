import { addMinutes, subMinutes } from 'date-fns';

export function dateFnsLocalDateToUtcDate(date: Date): Date {
  const utcGap = new Date().getTimezoneOffset();
  return addMinutes(date, utcGap);
}

export function dateFnsUtcDateToLocalDate(date: Date): Date {
  const utcGap = new Date().getTimezoneOffset();
  return subMinutes(date, utcGap);
}
