<div
  class="container"
  [class.side-container]="infoPanelPosition === InfoPanelState.Right"
  [class.bottom-container]="infoPanelPosition === InfoPanelState.Bottom"
  [class.logs-info-panel]="(activeTab$ | async) === LogsContent.logs"
  [class.full-info-panel]="isFullScreen$ | async"
  mwlResizable
  [validateResize]="validateResize"
  (resizing)="handleResizing.call(this, $event)"
  [ngStyle]="infoPanelStyle"
>
  <div class="header">
    <div class="modes">
      <div
        class="mode"
        [class.mode-active]="infoPanelMode === InfoPanelModes.JSON"
        (click)="infoPanelMode = InfoPanelModes.JSON"
      >
        JSON
      </div>
      <!--      <div-->
      <!--        class="mode"-->
      <!--        (click)="infoPanelMode = InfoPanelModes.TABLE"-->
      <!--        [class.mode-active]="infoPanelMode === InfoPanelModes.TABLE"-->
      <!--      >-->
      <!--        TABLE-->
      <!--      </div>-->
      <div
        class="mode"
        (click)="infoPanelMode = InfoPanelModes.RAW"
        [class.mode-active]="infoPanelMode === InfoPanelModes.RAW"
      >
        RAW
      </div>
    </div>
    <div class="icons">
      <!--      <img-->
      <!--        [src]="-->
      <!--          'assets/icons/pannel-bottom-' + (infoPanelPosition === InfoPanelState.Bottom ? 'active' : 'rest') + '.svg'-->
      <!--        "-->
      <!--        alt="img"-->
      <!--        class="icon"-->
      <!--        (click)="infoPanelPosition = InfoPanelState.Bottom"-->
      <!--      />-->
      <!--      <img-->
      <!--        [src]="'assets/icons/pannel-right-' + (infoPanelPosition === InfoPanelState.Right ? 'active' : 'rest') + '.svg'"-->
      <!--        alt="img"-->
      <!--        class="icon"-->
      <!--        (click)="infoPanelPosition = InfoPanelState.Right"-->
      <!--      />-->
      <svg-icon
        src="assets/icons/copy.svg"
        svgClass="cgx-icon-primary copy-log"
        (click)="copyLogToClipboard()"
        matTooltip="Copy to clipboard"
        matTooltipClass="coralogix-tooltip"
      ></svg-icon>
      <svg-icon
        [src]="(isFullScreen$ | async) ? 'assets/icons/minimize.svg' : 'assets/icons/expand.svg'"
        svgClass="cgx-icon-primary expand-icon"
        (click)="toggleInfoPanel()"
      ></svg-icon>
      <mat-icon class="icon" (click)="clearInfoPanelEvent.emit()">clear</mat-icon>
    </div>
  </div>

  <ng-container #outlet></ng-container>

  <div class="resize-handle resize-handle-left" [resizeEdges]="{ left: true }" mwlResizeHandle></div>
</div>

<ng-template #content>
  <ng-container *ngIf="infoPanelMode === InfoPanelModes.JSON">
    <sh-new-json-formatter
      [params]="selectedRow$ | async"
      [data]="(selectedRow$ | async)?.data?.text"
    ></sh-new-json-formatter>
  </ng-container>
  <ng-container *ngIf="infoPanelMode === InfoPanelModes.RAW">
    <div class="raw-log">
      {{ (selectedRow$ | async)?.data?.rawText | unescapeForwardSlashes }}
    </div>
  </ng-container>
</ng-template>
