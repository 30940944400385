import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { checkAndParseJSON } from '@shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import {
  getFullJSONPath,
  getTooltipElement,
  JSON_FORMATTER_KEY_CLASS,
  JSON_FORMATTER_TEMPLATE_CLASS,
  JSON_FORMATTER_VALUE_CLASS,
  removeTooltipElement,
} from '@shared/modules/json-formatter/json-formatter.utils';
import { JsonFormatterService } from '@shared/modules/json-formatter/json-formatter.service';
import { ITooltipOptions, tooltipTypes } from '@shared/modules/json-formatter/json-formatter.model';

@Component({
  selector: 'sh-json-formatter',
  templateUrl: './json-formatter.component.html',
  styleUrls: ['./json-formatter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonFormatterComponent implements OnDestroy, AfterViewInit {
  @Input() public tooltipOptions: ITooltipOptions;
  @Input() public data: string;
  @Input() public searchQuery: string;
  @Input() public colId: string = '';
  @Input() public isExpanded: boolean = false;
  @Input() public useTokenizer: boolean = false;
  @Input() public hasMenu = true;
  @Output() public renderedJsonLog = new EventEmitter<boolean>();
  @ViewChild('formattedJson') public formattedJson: ElementRef;
  private tooltipOn: tooltipTypes[];
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private renderer: Renderer2) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public ngAfterViewInit(): void {
    this.renderPrettifiedJSON();
  }

  public renderPrettifiedJSON(): void {
    const data = checkAndParseJSON(this.data);
    const formatterService = new JsonFormatterService(
      data,
      this.isExpanded,
      this.searchQuery,
      this.useTokenizer,
      this.hasMenu,
    );
    const formattedJSON = formatterService.render();
    this.renderer.appendChild(this.formattedJson.nativeElement, formattedJSON);
    if (this.tooltipOptions?.useTooltip) {
      this.tooltipOn = Object.keys(this.tooltipOptions.tooltipOn || {}).filter((type) => !!type) as tooltipTypes[];
      this.setHoverListeners();
      this.setMouseLeaveListeners();
    }
    this.renderedJsonLog.emit(true);
  }

  private setHoverListeners(): void {
    this.tooltipOn.forEach((type) => {
      const allValues = document.querySelectorAll(`.${this.getKeyOrValClassName(type)}`);
      allValues.forEach((value) => {
        fromEvent(value, 'mouseover')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((evt) => {
            const element = evt.target as HTMLElement;
            const elementDetails = element.getBoundingClientRect();
            const toolTipElement = getTooltipElement();

            if (!toolTipElement) {
              const tooltip = document.createElement('div');
              tooltip.classList.add('cgx-tooltip');
              tooltip.innerText = this.tooltipOptions.useJsonPath
                ? getFullJSONPath(element, this.colId)
                : this.tooltipOptions.staticValue;
              tooltip.style.top = elementDetails.top + elementDetails.height / 2 + 'px';
              tooltip.style.left = elementDetails.left + elementDetails.width / 2 + 'px';
              tooltip.style.opacity = '0';
              document.body.appendChild(tooltip);

              const tooltipLeft = elementDetails.left + elementDetails.width / 2;
              const rightWidthLeft = window.innerWidth - tooltipLeft;
              const isTooltipOverflowingFromRight = rightWidthLeft <= tooltip.scrollWidth;
              tooltip.style.left = isTooltipOverflowingFromRight
                ? tooltipLeft - rightWidthLeft - 20 + 'px'
                : tooltipLeft + 'px';
              tooltip.style.opacity = '1';
            }
          });
      });
    });
  }

  private setMouseLeaveListeners(): void {
    this.tooltipOn.forEach((type) => {
      const allValues = document.querySelectorAll(`.${this.getKeyOrValClassName(type)}`);
      allValues.forEach((value) => {
        fromEvent(value, 'mouseleave')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(removeTooltipElement);
      });
    });
  }

  private getKeyOrValClassName(type: tooltipTypes): string {
    switch (type) {
      case 'key':
        return JSON_FORMATTER_KEY_CLASS;
      case 'value':
        return JSON_FORMATTER_VALUE_CLASS;
      case 'template':
        return JSON_FORMATTER_TEMPLATE_CLASS;
    }
  }
}
