import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';


const DEFAULT_MAX_RETRIES = 2;
const DEFAULT_DELAY_MS = 1000;

export const retryableOperator = (delayMs: number = DEFAULT_DELAY_MS, retries: number = DEFAULT_MAX_RETRIES, optionalStatusCodes: number[] = null) => {
  const currentRetries = retries;
  return (src: Observable<any>) => src.pipe(
    retryWhen(error =>
      error.pipe(
        concatMap((err, count) => {
          if ((count < currentRetries) && isStatusCodeFound(optionalStatusCodes, err.status)) {
            return of(error);
          }
          return throwError(error);
        }),
        delay(delayMs)
      )
    )
  )
};

const isStatusCodeFound = (codes: number[] = null, statusCode: number): boolean => {
  return codes ? codes.findIndex(status => status === statusCode) > -1 : true;
};
