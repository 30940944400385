<div class="search-container" *ngIf="columnsFilter?.length > 0">
  <div class="search-container-wrap">
    <input
      class="find-filter-input"
      type="text"
      placeholder="Find Filter..."
      [(ngModel)]="stringFilter"
    />
  </div>
</div>
<div class="filters-content" *ngIf="columnsFilter?.length > 0">
  <div [formGroup]="metadataForm" class="filters-form">
    <div class="category-item" *ngIf="isFilterVisible('applicationName')">
      <sh-logs-filter-category
        [caption]="'Application'"
        [filterString]="stringFilter"
        (selectedValueChange)="change($event, 'applicationName')"
        [categoryAggregation]="aggregationData?.applicationname"
        formControlName="applicationName"
        #applicationNameDD
      ></sh-logs-filter-category>
    </div>
    <div class="category-item" *ngIf="isFilterVisible('subsystemName')">
      <sh-logs-filter-category
        (selectedValueChange)="change($event, 'subsystemName')"
        [caption]="'Subsystem'"
        [filterString]="stringFilter"
        [categoryAggregation]="aggregationData?.subsystemname"
        formControlName="subsystemName"
        #subsystemNameDD
      ></sh-logs-filter-category>
    </div>
    <div class="category-item" *ngIf="isFilterVisible('severity')">
      <sh-logs-filter-severity
        [caption]="'Severity'"
        [filterString]="stringFilter"
        [categoryAggregation]="aggregationData?.severity"
        (selectedValueChange)="change($event, 'severity')"
        formControlName="severity"
        [initialNumberOfItemsToDisplay]="6"
        #severityDD
      ></sh-logs-filter-severity>
    </div>
    <div class="category-item" *ngIf="isFilterVisible('category')">
      <sh-logs-filter-category
        (selectedValueChange)="change($event, 'category')"
        [categoryAggregation]="aggregationData?.category"
        [caption]="'Category'"
        [filterString]="stringFilter"
        formControlName="category"
        #categoryDD
      ></sh-logs-filter-category>
    </div>
    <div class="category-item" *ngIf="isFilterVisible('computerName')">
      <sh-logs-filter-category
        (selectedValueChange)="change($event, 'computerName')"
        [categoryAggregation]="aggregationData?.computername"
        [caption]="'Computer'"
        [filterString]="stringFilter"
        formControlName="computerName"
        #computerNameDD
      ></sh-logs-filter-category>
    </div>
    <div class="category-item" *ngIf="isFilterVisible('IPAddress')">
      <sh-logs-filter-category
        (selectedValueChange)="change($event, 'IPAddress')"
        [categoryAggregation]="aggregationData?.ipaddress"
        [caption]="'IPAddress'"
        [filterString]="stringFilter"
        formControlName="IPAddress"
        #IPAddressDD
      ></sh-logs-filter-category>
    </div>
    <div class="category-item" *ngIf="isFilterVisible('className')">
      <sh-logs-filter-category
        (selectedValueChange)="change($event, 'className')"
        [categoryAggregation]="aggregationData?.classname"
        [caption]="'Class'"
        [filterString]="stringFilter"
        formControlName="className"
        #classNameDD
      ></sh-logs-filter-category>
    </div>
    <div class="category-item" *ngIf="isFilterVisible('methodName')">
      <sh-logs-filter-category
        (selectedValueChange)="change($event, 'methodName')"
        [categoryAggregation]="aggregationData?.methodname"
        [caption]="'Method'"
        [filterString]="stringFilter"
        formControlName="methodName"
        #methodNameDD
      ></sh-logs-filter-category>
    </div>
    <sh-dynamic-filters
      [filtersForm]="dynamicFiltersFormGroup"
      [filterString]="stringFilter"
      [columnsFilter]="columnsFilter"
      (onFilterChange)="dynamicFieldChange($event)"
    ></sh-dynamic-filters>
  </div>

  <div
    class="ui blurring active dimmer inverted"
    [class.active]="isLoading"
    style="background: rgba(238, 238, 238, 0.44);"
  >
    <div class="ui text loader" style="color: #504f4f;">Loading</div>
  </div>
</div>
