import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sh-archive-query-editor-details',
  templateUrl: './archive-query-editor-details.component.html',
  styleUrls: ['./archive-query-editor-details.component.scss']
})
export class ArchiveQueryEditorDetailsComponent implements OnInit, OnDestroy {
  @Input()
  public form: FormGroup;
  public hasError: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor() { }

  public ngOnInit() {
    this.form.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res) => {
      this.hasError = res.name.length === 0;
    })
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
