import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IConfig, EConfigEnum } from '@app/global-config/globalConfig';
import { Constants } from '@app/constants';
import _get from 'lodash-es/get';
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private envUrl = 'https://origin-registry.coralogix.net/myconfig';
  private configSettings: IConfig | {} = {};

  constructor(private http: HttpClient) {}

  public getConfigValue<T>(path: string, defaultValue: T): T {
    return _get(this.configSettings, path, defaultValue);
  }

  public getConfigURL(url: string): string {
    return this.getConfigValue(EConfigEnum.url, '') + url;
  }

  public getConfigHttpURL(url: string): string {
    // This does not work for some case, spend some time debugging it but either EConfigEnum is undefined
    //   or if I put it to constant I just find out `configSettings` is empty.
    // return this.getConfigValue(EConfigEnum.httpServiceGatewayUrl, '') + url;
    return Constants.getConfigurationProp<string>(EConfigEnum.httpServiceGatewayUrl) + url;
  }

  public load(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get(this.envUrl).subscribe(
        (response: IConfig) => {
          this.configSettings = response;
          resolve(true);
        },
        err => {
          this.configSettings = {};
          reject('missing configuration');
        },
      );
    });
  }
}
