import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  State,
  getDynamicFilters,
  getLogsGridColDefs,
} from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { DynamicFilter } from '../../../shared/models/dynamic-filters';
import { FormGroup, FormControl } from '@angular/forms';
import { ColDef } from 'ag-grid';

@Component({
  selector: 'sh-dynamic-filters',
  templateUrl: './dynamic-filters.component.html',
  styleUrls: ['./dynamic-filters.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFiltersComponent implements OnInit, OnDestroy {
  @Input() set filterString(val: string) {
    this._filterString = val;
  }

  get filterString(): string {
    return this._filterString;
  }
  public filters$: Observable<DynamicFilter[]>;
  public colDefs$: Observable<ColDef[]>;
  public filters$Subscription: Subscription;
  public logColumns$Subscription: Subscription;
  public filters: DynamicFilter[] = [];

  @Output() public onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() public filtersForm: FormGroup;
  @Input() public columnsFilter: any;

  protected _filterString: string = '';

  constructor(
    private store: Store<State>,
    private changeDetectionRef: ChangeDetectorRef,
  ) {
    this.colDefs$ = this.store.select(getLogsGridColDefs);
    this.filters$ = this.store.select(getDynamicFilters);
  }

  public ngOnInit() {
    this.logColumns$Subscription = this.colDefs$.subscribe((colDefs) => {
      if (this.filtersForm && colDefs) {
        this.filters = [];
        colDefs.forEach((column) => {
          if (column.field.indexOf('textObject') === 0) {
            this.filtersForm.addControl(column.headerName, new FormControl([]));
            this.filters.push(new DynamicFilter(column.headerName));
          }
        });
      }
      this.changeDetectionRef.markForCheck();
    });

    this.filters$Subscription = this.filters$.subscribe((filters) => {
      filters.forEach((newFilter) => {
        const filter = this.filters.find(
          (f) => f.filterName == newFilter.filterName,
        );
        if (filter) {
          filter.values = newFilter.values;
        }
      });
      this.changeDetectionRef.markForCheck();
    });
  }

  public onChange(event, fieldName) {
    this.onFilterChange.emit({ event, fieldName });
  }

  public ngOnDestroy() {
    this.filters$Subscription.unsubscribe();
    this.logColumns$Subscription.unsubscribe();
  }
}
