import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LogsStatsService } from '@app/logs-new/shared/services/logs-stats.service';
import { MatDialog } from '@angular/material/dialog';
import { ITooltipOptions } from '@shared/modules/json-formatter/json-formatter.model';
import { JSON_FORMATTER_TEMPLATE_CLASS, JSON_FORMATTER_VALUE_CLASS } from '@shared/modules/json-formatter/json-formatter.utils';
import { LogsService } from '@app/logs-new/shared/services/logs.service';
import _get from 'lodash-es/get';
import { JsonFormatterService } from '@app/shared/modules/json-formatter/json-formatter.service';
import { take } from 'rxjs/operators';
import { LogsContent } from '@app/logs-new/shared/models/logs-content.model';

@Component({
  selector: 'sh-new-json-formatter',
  templateUrl: './new-json-formatter.component.html',
  styleUrls: ['./new-json-formatter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewJsonFormatterComponent implements OnDestroy {
  @Input() public params: any;
  @Input() public data: any;
  @Input() public colId: string = '';
  public logsTooltipOptions: ITooltipOptions = {
    useTooltip: true,
    useJsonPath: true,
    tooltipOn: { key: true },
  };
  public templateTooltipOptions: ITooltipOptions = {
    useTooltip: true,
    tooltipOn: { template: true },
    staticValue: 'default value for variable',
  };
  public renderedJsonLog$ = new BehaviorSubject(false);
  private unsubscribe$ = new Subject<void>();

  constructor(
    public elRef: ElementRef,
    private dialog: MatDialog,
    private statsService: LogsStatsService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onJsonRender(): void {
    this.params.context.parent.activeTab$.pipe(take(1)).subscribe(activeTab => {
      const isTemplates = activeTab === LogsContent.templates;
      if (isTemplates) {
        this.replaceTemplatePlaceholders();
      }
      this.renderedJsonLog$.next(true);
      this.cdr.detectChanges();
    });
  }

  private replaceTemplatePlaceholders(): void {
    this.elRef.nativeElement.querySelectorAll(`.${JSON_FORMATTER_VALUE_CLASS}`).forEach((element: HTMLElement) => {
      element.innerHTML = this.replaceTemplateVal(element);
    });
  }

  private replaceTemplateVal(el: HTMLElement): string {
    const { categoricalRegex, categoricalOrJsonRegex, jsonRegex } = LogsService.templatesPlaceholdersRegex;
    return el.innerText.replace(categoricalOrJsonRegex, val => {
      const isDisabled = !this.params.data.placeholdersEnabled;
      const classNames = isDisabled ? `${JSON_FORMATTER_TEMPLATE_CLASS} disabled` : JSON_FORMATTER_TEMPLATE_CLASS;
      if (val.match(categoricalRegex)) {
        const value = val.replace(categoricalRegex, '$2');
        const paramId = val.replace(categoricalRegex, '$1');
        return `<span data-templatetype='categorical' data-paramId='${paramId}' class='${classNames}'>${JsonFormatterService.escapeContent(
          value,
        )}</span>`;
      }
      if (val.match(jsonRegex)) {
        const key = val.replace(jsonRegex, '$2');
        const paramId = val.replace(jsonRegex, '$1');
        const value = _get(this.params.data.logExample, val.replace(jsonRegex, '$2'));
        return `<span data-templatetype='json' data-templatepath='${key}' data-paramId='${paramId}' class='${classNames}'>${JsonFormatterService.escapeContent(
          value,
        )}</span>`;
      }
      return val;
    });
  }
}
