<div class="container">
  <section id="characters">
    <p class="section-header">Characters</p>
    <sh-regex-cheat-sheet-table-section [dataSource]="tableData.characters">
    </sh-regex-cheat-sheet-table-section>
  </section>

  <section id="quantifiers">
    <p class="section-header">Quantifiers</p>
    <sh-regex-cheat-sheet-table-section [dataSource]="tableData.quantifiers">
    </sh-regex-cheat-sheet-table-section>
  </section>

  <section id="characterClasses">
    <p class="section-header">Character Classes</p>
    <sh-regex-cheat-sheet-table-section [dataSource]="tableData.characterClasses" [symbolColWidth]="190">
    </sh-regex-cheat-sheet-table-section>
  </section>

  <section id="characterClassesWithQuantifiers">
    <p class="section-subheader">Character Classes may be used with any Quantifiers:</p>
    <sh-regex-cheat-sheet-table-section
            [dataSource]="tableData.characterClassesWithQuantifiers"
            [hideHeader]="true"
    >
    </sh-regex-cheat-sheet-table-section>
  </section>

  <section id="anchorsAndBoundaries">
    <p class="section-header">Anchors and Boundaries</p>
    <sh-regex-cheat-sheet-table-section [dataSource]="tableData.anchorsAndBoundaries">
    </sh-regex-cheat-sheet-table-section>
  </section>

  <section id="groupsAndBackreferences">
    <p class="section-header">Groups and Backreferences</p>
    <sh-regex-cheat-sheet-table-section [dataSource]="tableData.groupsAndBackreferences" [symbolColWidth]="170">
    </sh-regex-cheat-sheet-table-section>
  </section>

  <section id="greedyVsNonGreedyQuantifiers">
    <p class="section-header">Greedy vs. Non-Greedy Quantifiers</p>
    <sh-regex-cheat-sheet-table-section [dataSource]="tableData.greedyVsNonGreedyQuantifiers" [symbolColWidth]="240">
    </sh-regex-cheat-sheet-table-section>
  </section>

  <section id="inlineModifiers">
    <p class="section-header">Inline Modifiers</p>
    <p class="section-subheader">Note that inline modifiers only work for Rules, and don't work while searching for logs using Logs Query or Kibana</p>
    <sh-regex-cheat-sheet-table-section [dataSource]="tableData.inlineModifiers" [symbolColWidth]="100">
    </sh-regex-cheat-sheet-table-section>
  </section>
</div>
