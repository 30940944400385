import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { AlertModel } from '@app/alerts/shared/models/alert-model';
import { CreateNUpdateAlertModel } from '@app/alerts/shared/models/create-n-update-alert-model';
import { AlertsService } from '@app/alerts/shared/services/alerts-service';
import { ShDialogService } from '@shared/services/dialogService';
import { MatDialog } from '@angular/material/dialog';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { AlertsEditorMode } from '@app/alerts/alerts-editor/models/alerts-editor-mode.enum';
import { AlertEditFromQueryModel } from '@app/alerts/alerts-editor/models/alert-edit-from-query.model';
import { CoralogixSidebarComponent } from '@shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { AlertEditControls } from '@app/alerts/alerts-editor/models/alert-edit-controls.model';
import { AlertsEditorFormService } from '@app/alerts/alerts-editor/services/alerts-editor-form.service';
import { AlertsEditorSelectOptionsService } from '@app/alerts/alerts-editor/services/alerts-editor-select-options.service';
import { AlertMapperService } from '@app/alerts/alerts-editor/services/alert-mapper.service';
import { AlertsEditorSelectOptions } from '@app/alerts/alerts-editor/models/alerts-select-options.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { alertsEditorLoading, getTimezoneViewPreference, openAlertInvalidQuery, State } from '@app/app.reducers';
import * as alertActions from '@app/alerts/shared/actions/alerts.actions';
import { alertId } from '@app/alerts/shared/models/alert-requests.models';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertsBaseUrl, AlertsNewUrl, CloseAlertData } from '@app/alerts/shared/models/alerts.consts';
import { filter, take, takeUntil } from 'rxjs/operators';
import { TimeZoneType } from '@shared/models/timezone-types';
import { UserAlertViewTypes } from '@app/alerts/alerts-editor/models/alert-editor-view.models';
import { AlertTypeKey, ConditionsOptions } from '@app/alerts/alerts-editor/models/alert-editor-consts';

@Component({
  selector: 'sh-alerts-editor-container',
  templateUrl: './alerts-editor-container.component.html',
  styleUrls: ['./alerts-editor-container.component.scss'],
  providers: [AlertsEditorFormService, AlertsEditorSelectOptionsService],
})
export class AlertsEditorContainerComponent implements OnDestroy {
  @Output() public close: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('sidebarComponent', { static: true })

  public sidebar: CoralogixSidebarComponent;

  public alert: AlertModel | AlertEditFromQueryModel;

  public isLoading$: Observable<boolean>;

  public mode: AlertsEditorMode;

  public form: FormGroup;

  public selectOptions: AlertsEditorSelectOptions;

  public isAlertQuerySyntaxInvalid$: Observable<string>;

  public destroyed$: Subject<void> = new Subject();

  private isLocalTimezone: boolean = true;

  constructor(
    private dialog: MatDialog,
    private alertsService: AlertsService,
    private shDialogService: ShDialogService,
    private alertsEditorFormService: AlertsEditorFormService,
    private alertsEditorSelectOptionsService: AlertsEditorSelectOptionsService,
    private alertMapperService: AlertMapperService,
    private location: Location,
    private store: Store<State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {
    this.isLoading$ = store.select(alertsEditorLoading);
    this.store
      .select(getTimezoneViewPreference)
      .take(1)
      .subscribe((tmz) => {
        this.isLocalTimezone = tmz === TimeZoneType.local;
      });
  }

  public get initialFormValue(): AlertEditControls {
    switch (this.mode) {
      case AlertsEditorMode.edit:
        return this.alertMapperService.mapAlertModelToFormControlsModel(this.alert, false, this.isLocalTimezone);
      case AlertsEditorMode.create:
        return this.alertsEditorFormService.defaultValues;
      case AlertsEditorMode.fromQuery:
        return this.initialFormValueForFromQuery;
    }
  }

  public get initialFormValueForFromQuery(): AlertEditControls {
    const defaultValues = this.alertsEditorFormService.defaultValues;
    const valueFromQuery = this.alertMapperService.mapAlertModelFromQueryToForm(
      this.alert as AlertEditFromQueryModel,
      this.isLocalTimezone,
    );
    Object.keys(valueFromQuery).forEach((key) => valueFromQuery[key] === undefined && delete valueFromQuery[key]);
    return {
      ...defaultValues,
      ...valueFromQuery,
    };
  }

  public get userAlertTypeIdControl(): FormControl {
    return this.form.get('userAlertTypeId') as FormControl;
  }

  public get conditionControl(): FormGroup {
    return this.form.get('conditions') as FormGroup;
  }

  public get cardinalityFieldsControl(): FormGroup {
    return this.conditionControl.get('cardinalityFields') as FormGroup;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.router.onSameUrlNavigation = 'ignore';
  }

  public openAlertsEditor(mode: AlertsEditorMode, alert?: AlertModel | AlertEditFromQueryModel, isLocationReplace: boolean = true): void {
    if (mode === AlertsEditorMode.edit) {
      if (isLocationReplace) {
        const newPath = `${AlertsBaseUrl}/${alert.id}`;
        this.location.replaceState(newPath);
      }
    } else if (mode === AlertsEditorMode.create) {
      if (isLocationReplace) {
        const newPath = AlertsNewUrl;
        this.location.replaceState(newPath);
      }
    }
    this.mode = mode;
    this.alert = alert;
    this.isAlertQuerySyntaxInvalid$ = this.store.select(openAlertInvalidQuery);

    this.form = this.alertsEditorFormService.buildForm(this.initialFormValue, this.isLocalTimezone);
    this.updateSelectOptions(this.initialFormValue.conditionOperator === ConditionsOptions.newValue
      ? UserAlertViewTypes.NewValueAlert
      : this.initialFormValue.userAlertTypeId);
    this.subscribeToTypeChanges();
    this.sidebar.showSidebar();
    if (isLocationReplace) {
      this.setRouteSubscription();
    }
  }

  public closeAlertsEditor(): void {
    if (this.form.pristine) {
      this.sidebar.hideSidebar();
      this.store.dispatch(alertActions.updateOpenAlertInvalidQueryAction({ payload: null }));
      this.store.dispatch(alertActions.updateAlertEditorLoadingAction({ payload: false }));
      this.close.emit();
      return;
    }

    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data: CloseAlertData });

    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.sidebar.hideSidebar();
        this.store.dispatch(alertActions.updateOpenAlertInvalidQueryAction({ payload: null }));
        this.store.dispatch(alertActions.updateAlertEditorLoadingAction({ payload: false }));
        this.close.emit();
        this.cdr.detectChanges();
      }
    });
  }

  public onSave(alert: CreateNUpdateAlertModel): void {
    const isEditMode = this.mode === AlertsEditorMode.edit;
    const payload = { payload: alert };
    if (isEditMode) {
      this.store.dispatch(alertActions.updateAlertAction(payload));
    } else {
      this.store.dispatch(alertActions.createAlertAction(payload));
    }
    this.setRouteSubscription();
  }

  public onDelete(id: alertId): void {
    this.store.dispatch(alertActions.deleteAlertAction({ payload: id }));
    this.setRouteSubscription();
  }

  private updateSelectOptions(alertType: UserAlertViewTypes): void {
    this.selectOptions = this.alertsEditorSelectOptionsService.getInitialSelectOptions(this.metadataFilter, alertType);
  }

  private subscribeToTypeChanges(): void {
    this.form.get('userAlertTypeId').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.updateSelectOptions(value);
      this.updateValidators();
      this.cdr.detectChanges();
    });
  }

  private updateValidators(): void {
    if (this.userAlertTypeIdControl.value === UserAlertViewTypes.Cardinality) {
      this.cardinalityFieldsControl.setValidators([Validators.required]);
    } else {
      this.cardinalityFieldsControl.clearValidators();
      this.cardinalityFieldsControl.setErrors(undefined);
    }
  }
  private setRouteSubscription(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        const currentId = this.activatedRoute.snapshot.paramMap.get('id');
        const isNewUrl = this.router.url.includes(AlertsNewUrl);
        if (!isNewUrl && !currentId) {
          this.sidebar.hideSidebar();
          this.store.dispatch(alertActions.updateOpenAlertInvalidQueryAction({ payload: null }));
          this.close.emit();
        }
      });
  }

  private get metadataFilter(): Metadata {
    const metadata = new Metadata({
      applicationName: [],
      subsystemName: [],
      severity: [],
    });

    return metadata;
  }
}
