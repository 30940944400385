import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { environment } from '@environments/environment';
import { Constants } from '@app/constants';

export const RuleAssetsUrl: string = '../../../../../assets/settings/rules';
export const RulesBaseUrl: string = environment['RulesBaseUrl'] || '/rules';
export const RulesBaseThemeUrl: string = `${RulesBaseUrl}/group`;

export const CloseDialogData: ICoralogixDialogData = {
  title: 'Unsaved changes will be lost',
  message:
    'Closing this Rule Group dialog will delete your unsaved changes. ' +
    'Are you sure you want to close and discard the unsaved changes?',
  yesButtonText: 'DISCARD',
  noButtonText: 'CONTINUE EDITING',
};

export const DefaultMetaFieldOptionValue = Constants.getDefaultMetaFieldOptionValue();
