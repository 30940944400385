import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getLogsQueryAction } from '@app/logs-new/store/logs-queries/logs-queries.actions';
import { filter, map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { UDIDHelper } from '@shared/services/udid.helper';
import { ILogQueryParams } from '@app/logs-new/shared/models/router.model';

@Injectable({
  providedIn: 'root',
})
export class LogsRouterService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>,
    private uuidService: UDIDHelper,
  ) {}

  public getNewQueryId(): string {
    return this.uuidService.generateShortUDID();
  }

  public resetQueryParams(): void {
    this.route.snapshot.queryParams = {};
  }

  public get queryParams(): ILogQueryParams {
    return this.route.snapshot.queryParams as ILogQueryParams;
  }

  public get hasStartDate(): boolean {
    return !!this.queryParams.startTime;
  }

  public get hasNoStaticDates(): boolean {
    return !this.hasStartDate && !this.hasEndDate && !this.queryParams.time;
  }

  public get hasEndDate(): boolean {
    return !!this.queryParams.endTime;
  }

  public handlePopState(): void {
    this.router.events
      .pipe(
        filter((routerEvent) => routerEvent instanceof NavigationEnd),
        filter((routerEvent: NavigationEnd) => routerEvent.url.includes('query-new')),
        take(1),
        map(() => this.route.snapshot.queryParamMap.get('id')),
        tap((id) => {
          if (!id) {
            location.reload();
          }
        }),
      )
      .subscribe((id) => {
        this.store.dispatch(getLogsQueryAction({ payload: id }));
      });
  }
}
