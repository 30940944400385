import { TeamUsersActions } from './team-users.actions';
import { Teammate } from '../../shared/models/teammate';
import { ASSIGN } from '@app/settings/account/components/groups/constants/constants';
export namespace TeamUsersReducer {
  export interface State {
    loading: boolean;
    teammates: Teammate[];
    result: string[];
    deletingUsers: { [id: string]: string };
    loadingStatus: { [id: string]: boolean };
  }

  export const initialState: State = {
    loading: false,
    teammates: [],
    result: [],
    deletingUsers: {},
    loadingStatus: {},
  };

  export function reducer(
    state = initialState,
    action: TeamUsersActions.Actions,
  ): State {
    switch (action.type) {
      case TeamUsersActions.LOAD: {
        return Object.assign({}, state, {
          loading: true,
        });
      }

      case TeamUsersActions.LOAD_SUCCESS: {
        return Object.assign({}, state, {
          loading: false,
          teammates: [...action.payload].sort((t1, t2) =>
            t2.created_at.getTime(),
          ),
        });
      }

      case TeamUsersActions.LOAD_FAIL: {
        return Object.assign({}, state, {
          loading: false,
        });
      }

      case TeamUsersActions.DELETE: {
        return state;
      }

      case TeamUsersActions.DELETE_SUCCESS: {
        const index = state.teammates.map((t) => t.id).indexOf(action.payload);
        return Object.assign({}, state, {
          teammates: [
            ...state.teammates.slice(0, index),
            ...state.teammates.slice(index + 1),
          ],
        });
      }

      case TeamUsersActions.DELETE_FAIL: {
        return state;
      }

      case TeamUsersActions.CHANGE_ROLE: {
        return state;
      }

      case TeamUsersActions.CHANGE_ROLE_SUCCESS: {
        const [teammate_id, role]: [string, number] = action.payload;
        const index = state.teammates.map((t) => t.id).indexOf(teammate_id);
        return Object.assign({}, state, {
          teammates: [
            ...state.teammates.slice(0, index),
            Object.assign({}, state.teammates[index], { role }),
            ...state.teammates.slice(index + 1),
          ],
        });
      }

      case TeamUsersActions.CHANGE_ROLE_FAIL: {
        return state;
      }

      case TeamUsersActions.UPDATE_GROUP_SUCCESS: {
        const { groupName, groupId } = action.payload;
        return {
          ...state,
          teammates: state.teammates.map((member) => {
            if (member.id === action.payload.uid) {
              if (action.payload.type === ASSIGN) {
                member.groupIds[groupId] = groupName;
              } else {
                member.groupIds[groupId] = null;
                delete member.groupIds[groupId];
              }
            }
            return member;
          }),
        };
      }

      case TeamUsersActions.UPDATE_LOADING: {
        return Object.assign({}, state, {
          loadingStatus: { ...state.loadingStatus, [action.payload.uid]: action.payload.showLoadingSpinner },
        });
      }

      default: {
        return state;
      }
    }
  }
}
