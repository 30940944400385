import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryDefRequest } from '@app/logs/shared/models/query-def.request';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { BuildResult, Build } from '../../models/build';
import { TypedJSON } from 'typedjson';
import { Observable } from 'rxjs';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { BuildSummaryResponse, ITagSummaryRequest, ITagSummaryResponse } from '@app/deployment/shared/models/summary.models';
import { INewEditTagRequestModel } from '@app/deployment/models/tag.request.models';
import { ITagAlertSummaryRequest, TagsAlertSummaryResponse } from '@app/deployment/shared/models/alerts-summary.models';
import { map } from 'rxjs/operators';
import { ITagErrorVolumeRequest, ITagErrorVolumeResponse, TagErrorVolumeResponse } from '@app/deployment/shared/models/error-volume.models';
import { ConfigurationService } from '@app/services/configuration.service';

@Injectable()
export class DeploymentService {
  public buildsMap: Map<string, Build> = new Map<string, Build>();

  constructor(private http: HttpClient, private ea: AnalyticEventService, private configService: ConfigurationService) {}

  public getBuilds(queryModel: LogQueryModel): Observable<BuildResult> {
    const url = this.configService.getConfigHttpURL('/api/v1/tag');
    const queryDefRequest: QueryDefRequest = new QueryDefRequest(queryModel);
    return this.http.post(url, queryDefRequest).map(res => this.extractData(res));
  }

  public deleteBuild(buildId: number): Observable<number> {
    const url = this.configService.getConfigHttpURL(`/api/v1/deletetag/${buildId}`);
    return this.http
      .delete(url, {
        responseType: 'text',
      })
      .mapTo(buildId);
  }

  public addManualEvent(manualEvent: INewEditTagRequestModel): Observable<any> {
    const url = this.configService.getConfigHttpURL('/api/v1/addtag');
    this.ea.dataLayerReport({
      event: 'added_a_tag',
    });
    return this.http.post(url, manualEvent);
  }

  public editTag(manualEvent: INewEditTagRequestModel): Observable<any> {
    const url = this.configService.getConfigHttpURL('/api/v1/edittag');
    return this.http.post(url, manualEvent);
  }

  public getTagSummary(payload: ITagSummaryRequest): Observable<ITagSummaryResponse> {
    const url = this.configService.getConfigHttpURL('/api/v1/tag/summary');
    return this.http.post<ITagSummaryResponse>(url, payload).pipe(map(data => TypedJSON.parse(data, BuildSummaryResponse)));
  }

  public getTagAlerts(payload: ITagAlertSummaryRequest): Observable<TagsAlertSummaryResponse> {
    const url = this.configService.getConfigHttpURL('/api/v1/tag/alerts');
    return this.http.post<TagsAlertSummaryResponse>(url, payload).pipe(map(data => TypedJSON.parse(data, TagsAlertSummaryResponse)));
  }

  public getTagErrorVolume(payload: ITagErrorVolumeRequest): Observable<ITagErrorVolumeResponse> {
    const url = this.configService.getConfigHttpURL('/api/v1/tag/error-volume');
    return this.http.post<TagErrorVolumeResponse>(url, payload).pipe(map(data => TypedJSON.parse(data, TagErrorVolumeResponse)));
  }

  public extractData(res: any): BuildResult {
    const builds: BuildResult = TypedJSON.parse(res, BuildResult);
    builds.data.forEach(build => {
      if (!this.buildsMap.has(build.id.toString())) {
        this.buildsMap.set(build.id.toString(), build);
      }
    });
    return builds;
  }

  public getNormalizeTagPostRequest(tag: INewEditTagRequestModel): INewEditTagRequestModel {
    return {
      ...tag,
      application_name: Array.isArray(tag.application_name) ? tag.application_name : [],
      subsystem_name: Array.isArray(tag.subsystem_name) ? tag.subsystem_name : [],
    };
  }
}
