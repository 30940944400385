import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'sh-logs-filter-grid-columns',
  templateUrl: 'logs-filter-grid-columns.component.html',
  styleUrls: ['logs-filter-grid-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsFilterGridColumnsComponent implements OnInit {
  @Output()
  public onColumnAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public onColumnRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public availableColumns: any[] = [];
  @Input()
  public selectedColumns: any[] = [];
  @Input()
  public isLoading: boolean;

  public filterText: string;

  constructor() {}

  public addColumn(colId) {
    this.onColumnAdded.emit(colId);
  }

  public removeColumn(colId) {
    this.onColumnRemoved.emit(colId);
  }

  public trackByFn(index, column) {
    return column.colId;
  }

  public ngOnInit() {}
}
