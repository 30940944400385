import {
  Inject,
  OnInit,
  Input,
  ContentChild,
  Directive,
  Component,
  ElementRef,
} from '@angular/core';
import {
  Csv,
  Json,
  ExportType,
  ExportData,
} from '../shared/models/export-file-types.model';
import { MatInput } from '@angular/material/input';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { State, getLogPages, getDateRange } from '../../app.reducers';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sh-export-view',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent implements OnInit {
  @Input() public referrer: string;
  @ContentChild('matInput') public matInput: MatInput;

  public title = 'Export view';

  public fileTypes: Array<ExportType> = [Csv, Json];
  public fileName: string;
  public fileTypeValue: ExportType;
  public delimiter = ',';

  public pageNumbers: number[];
  public dateRange: Map<string, Date> = new Map<string, Date>();

  public getLogPagesSubscription: Subscription;
  public getDateRangeSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>,
  ) {}

  get inputDataValid() {
    return (
      this.fileTypeValue &&
      ((this.fileTypeValue.id === Csv.id && this.delimiter) ||
        this.fileTypeValue.id !== Csv.id) &&
      this.fileName
    );
  }

  get sortedPages() {
    return this.pageNumbers.map((p) => p + 1).sort((a, b) => a - b);
  }

  get dateRangeStr() {
    const minDate = this.dateRange
      .get('min')
      .toISOString()
      .split('.')[0];
    const maxDate = this.dateRange
      .get('max')
      .toISOString()
      .split('.')[0];

    return `${minDate} - ${maxDate}`;
  }

  get fileNameValue() {
    return `${this.fileName}.${this.dateRangeStr}`;
  }

  public onSubmitExport() {
    const exportData: ExportData = {
      id: this.fileTypeValue.id,
      fileName: this.fileNameValue,
      delimiter: this.delimiter,
    };
    this.data.onExportSubscription.emit(exportData);
  }

  public ngOnInit() {
    this.getLogPagesSubscription = this.store
      .select(getLogPages)
      .subscribe((pageNumbers) => (this.pageNumbers = pageNumbers));
    this.getDateRangeSubscription = this.store
      .select(getDateRange)
      .subscribe((dateRange) => (this.dateRange = dateRange));
  }

  public ngOnDestroy() {
    this.getLogPagesSubscription.unsubscribe();
    this.getDateRangeSubscription.unsubscribe();
  }
}
