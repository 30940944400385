import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLogComponent } from './select-log.component';

@NgModule({
  declarations: [SelectLogComponent],
  imports: [
    CommonModule,
  ],
  exports: [SelectLogComponent],
})
export class SelectLogModule { }
