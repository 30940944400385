import { Injectable } from '@angular/core';
import { ShDialogService } from '@app/shared/services/dialogService';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Hide, Show } from './loading-bar.action';

export interface ILoadingBarStateModel {
    loading: boolean;
    path: string;
}

const initialState = (): ILoadingBarStateModel => {
    return {
        loading: false,
        path: ''
    };
};

@State<ILoadingBarStateModel>({
    name: 'loadingBar',
    defaults: initialState()
})
@Injectable()
export class LoadingBarState {

    constructor(
        private dialogService: ShDialogService,
        public store: Store
    ) {
    }

    @Selector()
    public static loading(state: ILoadingBarStateModel): boolean {
        return state.loading;
    }

    @Action(Show)
    public Show(ctx: StateContext<ILoadingBarStateModel>, { path }: Show): ILoadingBarStateModel {
        return ctx.patchState({
            loading: true,
            path
        });
    }

    @Action(Hide)
    public Hide(ctx: StateContext<ILoadingBarStateModel>): ILoadingBarStateModel {
        return ctx.patchState({
            loading: false,
            path: ''
        });
    }
}
