import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Constants } from '../../constants';
declare let $;

@Component({
  selector: 'sh-sales-force-fake-form',
  templateUrl: './sales-force-fake-form.component.html',
  styleUrls: ['./sales-force-fake-form.component.scss'],
})
export class SalesForceFakeFormComponent implements OnInit {
  @ViewChild('fakeFormSubmitButton', { static: true })
  public fakeFormSubmitButton: ElementRef;
  @ViewChild('fake_firstName', { static: true })
  public fakeFormFirstName: ElementRef;
  @ViewChild('fake_lastName', { static: true })
  public fakeFormLastName: ElementRef;
  @ViewChild('fake_email', { static: true }) public fakeFormEmail: ElementRef;
  @ViewChild('fake_company', { static: true })
  public fakeFormCompany: ElementRef;
  @ViewChild('fake_phone', { static: true }) public fakeFormPhone: ElementRef;

  public retURL: string;

  constructor(private renderer: Renderer2) {
    this.retURL = Constants.signupRedirectURL;
  }

  public ngOnInit() {
    this.setFakeFormValues();
  }

  public submit() {
    $(this.fakeFormSubmitButton.nativeElement).click();
  }

  public setFakeFormValues(
    firstName: string = '',
    lastName: string = '',
    company: string = '',
    email: string = '',
    phone: string = '',
  ) {
    this.renderer.setProperty(this.fakeFormFirstName.nativeElement, 'value', firstName);
    this.renderer.setProperty(this.fakeFormLastName.nativeElement, 'value', lastName);
    this.renderer.setProperty(this.fakeFormCompany.nativeElement, 'value', company);
    this.renderer.setProperty(this.fakeFormEmail.nativeElement, 'value', email);
    this.renderer.setProperty(this.fakeFormPhone.nativeElement, 'value', phone);
  }
}
