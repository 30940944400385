import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy,
  OnInit, Output, Renderer2, SimpleChanges, ViewChild
} from '@angular/core';

interface IChangeEvent {
  start?: number;
  end?: number;
}

interface IDimensions {
  itemCount: number;
  viewWidth: number;
  viewHeight: number;
  childWidth: number;
  childHeight: number;
  itemsPerRow: number;
  itemsPerCol: number;
  itemsPerRowByCalc: number;
}

@Component({
  selector: 'sh-virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss'],
})
export class VirtualScrollComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public items: any[] = [];
  @Input() public scrollbarWidth: number;
  @Input() public scrollbarHeight: number;
  @Input() public childWidth: number;
  @Input() public childHeight: number;

  @Output() public update: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public change: EventEmitter<IChangeEvent> = new EventEmitter<IChangeEvent>();
  @Output() public start: EventEmitter<IChangeEvent> = new EventEmitter<IChangeEvent>();
  @Output() public end: EventEmitter<IChangeEvent> = new EventEmitter<IChangeEvent>();

  @ViewChild('content', { read: ElementRef })
  public contentElementRef: ElementRef;

  public onScrollListener: () => void;
  public topPadding: number;
  public scrollHeight: number;
  public previousStart: number;
  public previousEnd: number;
  public startupLoop: boolean = true;

  constructor(private element: ElementRef, private renderer: Renderer2) { }

  public ngOnInit(): void {
    this.onScrollListener = this.renderer.listen(this.element.nativeElement, 'scroll', this.refresh.bind(this));
    this.scrollbarWidth = 0; // this.element.nativeElement.offsetWidth - this.element.nativeElement.clientWidth;
    this.scrollbarHeight = 0; // this.element.nativeElement.offsetHeight - this.element.nativeElement.clientHeight;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.previousStart = undefined;
    this.previousEnd = undefined;
    this.refresh();
  }

  public ngOnDestroy(): void {
    // Check that listener has been attached properly:
    // It may be undefined in some cases, e.g. if an exception is thrown, the component is
    // not initialized properly but destroy may be called anyways (e.g. in testing).
    if (this.onScrollListener !== undefined) {
      // this removes the listener
      this.onScrollListener();
    }
  }

  public refresh(): void {
    requestAnimationFrame(this.calculateItems.bind(this));
  }

  public scrollInto(item: any): void {
    const index: number = (this.items || []).indexOf(item);
    if (index < 0 || index >= (this.items || []).length) {
      return;
    }

    const d = this._calculateDimensions();
    this.element.nativeElement.scrollTop = Math.floor(index / d.itemsPerRow) *
      d.childHeight - Math.max(0, (d.itemsPerCol - 1)) * d.childHeight;
    this.refresh();
  }

  private _countItemsPerRow(): number {
    let offsetTop;
    let itemsPerRow;
    const children = this.contentElementRef.nativeElement.children;
    for (itemsPerRow = 0; itemsPerRow < children.length; itemsPerRow++) {
      if (offsetTop !== undefined && offsetTop !== children[itemsPerRow].offsetTop) {
        break;
      }
      offsetTop = children[itemsPerRow].offsetTop;
    }
    return itemsPerRow;
  }

  private _calculateDimensions(): IDimensions {
    const el = this.element.nativeElement;
    const content = this.contentElementRef.nativeElement;

    const items = this.items || [];
    const itemCount = items.length;
    const viewWidth = el.clientWidth - this.scrollbarWidth;
    const viewHeight = el.clientHeight - this.scrollbarHeight;

    let contentDimensions;
    if (this.childWidth === undefined || this.childHeight === undefined) {
      contentDimensions = content.children[0] ? content.children[0].getBoundingClientRect() : {
        width: viewWidth,
        height: viewHeight
      };
    }
    const childWidth = this.childWidth || contentDimensions.width;
    const childHeight = this.childHeight || contentDimensions.height;

    let itemsPerRow = Math.max(1, this._countItemsPerRow());
    const itemsPerRowByCalc = Math.max(1, Math.floor(viewWidth / childWidth));
    const itemsPerCol = Math.max(1, Math.floor(viewHeight / childHeight));
    if (itemsPerCol === 1 && Math.floor(el.scrollTop / this.scrollHeight * itemCount) + itemsPerRowByCalc >= itemCount) {
      itemsPerRow = itemsPerRowByCalc;
    }

    return {
      itemCount: itemCount,
      viewWidth: viewWidth,
      viewHeight: viewHeight,
      childWidth: childWidth,
      childHeight: childHeight,
      itemsPerRow: itemsPerRow,
      itemsPerCol: itemsPerCol,
      itemsPerRowByCalc: itemsPerRowByCalc
    };
  }

  private calculateItems(): void {
    const el = this.element.nativeElement;

    const d = this._calculateDimensions();
    const items = this.items || [];
    this.scrollHeight = d.childHeight * d.itemCount / d.itemsPerRow;
    if (this.element.nativeElement.scrollTop > this.scrollHeight) {
      this.element.nativeElement.scrollTop = this.scrollHeight;
    }

    const indexByScrollTop = el.scrollTop / this.scrollHeight * d.itemCount / d.itemsPerRow;
    const end = Math.min(d.itemCount, Math.ceil(indexByScrollTop) * d.itemsPerRow + d.itemsPerRow * (d.itemsPerCol + 1));

    let maxStartEnd = end;
    const modEnd = end % d.itemsPerRow;
    if (modEnd) {
      maxStartEnd = end + d.itemsPerRow - modEnd;
    }
    const maxStart = Math.max(0, maxStartEnd - d.itemsPerCol * d.itemsPerRow - d.itemsPerRow);
    const start = Math.min(maxStart, Math.floor(indexByScrollTop) * d.itemsPerRow);

    this.topPadding = d.childHeight * Math.ceil(start / d.itemsPerRow);
    if (start !== this.previousStart || end !== this.previousEnd) {

      // update the scroll list
      this.update.emit(items.slice(start, end));

      // emit 'start' event
      if (start !== this.previousStart && this.startupLoop === false) {
        this.start.emit({ start, end });
      }

      // emit 'end' event
      if (end !== this.previousEnd && this.startupLoop === false) {
        this.end.emit({ start, end });
      }

      this.previousStart = start;
      this.previousEnd = end;

      if (this.startupLoop === true) {
        this.refresh();
      } else {
        this.change.emit({ start, end });
      }

    } else if (this.startupLoop === true) {
      this.startupLoop = false;
      this.refresh();
    }
  }
}
