import { Component, Input } from '@angular/core';
import { ControlType, IRuleExampleData, RuleSampleType } from '@app/rules/shared/models/rule-samples-view.models';

@Component({
  selector: 'sh-rule-example-item',
  templateUrl: './rule-example-item.component.html',
  styleUrls: ['./rule-example-item.component.scss']
})
export class RuleExampleItemComponent {
  @Input() public sample: IRuleExampleData;
  @Input() public ruleNumber: number;
  public ruleSampleType: typeof RuleSampleType = RuleSampleType;
  public ruleControlType: typeof ControlType = ControlType;
}
