import { Component, OnInit } from '@angular/core';

interface INoResultCause {
  title: string;
  paragraph: string;
}

@Component({
  selector: 'sh-empty-alert-logs-grid',
  templateUrl: './empty-alert-logs-grid.component.html',
  styleUrls: ['./empty-alert-logs-grid.component.scss']
})
export class EmptyAlertLogsGridComponent implements OnInit {
  public readonly noResultCauses: INoResultCause[] = [
    {
      title: 'Logs Not Yet Indexed',
      paragraph: 'Try to run your query again soon'
    },
    {
      title: 'Field Mapping Exceptions',
      paragraph: 'To check your mapping exceptions, go to Kibana > Mapping Exceptions Dashboard'
    },
    {
      title: 'You May Have Exceeded Your Mapped Field Limit',
      paragraph: 'Reach out to customer support for help via the in-app chat'
    }
  ];

  constructor() {}

  public ngOnInit(): void {}

}
