<div class="plan-limit-bar">
  <div
    style="display: flex; align-items: center; width: 400px; height: 100%;"
    hoverable
    [cgxTooltip]="tooltip"
    [maxWidth]="320"
    [top]="8"
    [layout]="false"
  >
    <svg-icon style="height: 12px; margin-inline-end: 4px;" src="assets/icons/xxs-12x12/circle-exclamation-mark.svg"></svg-icon>
    <span>Usage Warning</span>
    <div class="bar-container">
      <div class="plan-bar">
        <div class="plan" [style.width.%]="usagePercent"></div>
        <div class="pay-as-you-go" [style.width.%]="usagePercent + payAsYouGoPercent"></div>
        <div class="over" [style.width.%]="usagePercent + payAsYouGoPercent + overPercent"></div>
        <div class="block" [style.width.%]="usagePercent + payAsYouGoPercent + overPercent + blockPercent"></div>
      </div>
    </div>
  </div>
  <div *ngIf="usageAbovePlan > 0; else leftGBMessage" class="msg msg-right">
    <span>Your estimated daily usage is {{ usageAbovePlan | number: '1.0-2' }} units above you plan -</span>
    <a
      [routerLink]="['/settings/subscriptions/plan']"
      *ngIf="planStatus.plans_url?.indexOf(externalPrefix) === -1; else externalReviewBtn"
      class="review-link"
    >
      &nbsp;REVIEW PLANS
    </a>
    <ng-template #externalReviewBtn>
      <a [href]="planStatus.plans_url" class="review-link">&nbsp;REVIEW PLANS</a>
    </ng-template>
  </div>
  <ng-template #leftGBMessage>
    <div class="msg msg-right">
      <span>Your remaining estimated daily usage is {{ remainingEstimatedUsage | number: '1.0-2' }} units</span>
    </div>
  </ng-template>
</div>

<ng-template #tooltip>
  <div class="usage-tooltip">
    <div style="margin-bottom: 8px;">Your estimated daily usage is {{ planStatus.estimatedUsage | number: '1.0-2' }} units</div>
    <div style="display: flex; margin-bottom: 8px;">
      <div class="plan-size-color" style="background-color: #01ffa1;"></div>
      <span>Your current plan is {{ planStatus.plan_size | number: '1.0-2' }} units/day</span>
    </div>
    <div *ngIf="planStatus.overageEnabled" style="display: flex; margin-bottom: 8px;">
      <div class="plan-size-color" style="background-color: #10a17a;"></div>
      <span>Your pay as you go plan is {{ planStatus.plan_size | number: '1.0-2' }} units/day</span>
    </div>
    <div *ngIf="overGB > 0" style="display: flex; margin-bottom: 8px;">
      <div class="plan-size-color" style="background-color: #fec52d;"></div>
      <span>We will index an extra {{ overGB | number: '1.0-2' }} units free of charge (because we love you)</span>
    </div>
    <div *ngIf="blockGB > 0" style="display: flex; margin-bottom: 8px;">
      <div class="plan-size-color" style="background-color: #ef4d7b;"></div>
      <span>
        We will have to block {{ blockGB | number: '1.0-2' }} units of your data today in case you decide not to upgrade. Indexing will
        resume at 00:00UTC
      </span>
    </div>
  </div>
</ng-template>
