import { IEditorState } from '@app/alerts/shared/reducers/alerts.reducer';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';

export const AlertsBaseUrl = '/alerts';
export const AlertsNewUrl = `${AlertsBaseUrl}/new`;

export const CloseAlertData: ICoralogixDialogData = {
  title: 'Unsaved changes will be lost',
  message:
    'Closing this Alert dialog will delete your unsaved changes. ' +
    'Are you sure you want to close and discard the unsaved changes?',
  yesButtonText: 'DISCARD',
  noButtonText: 'CONTINUE EDITING',
};

export const EmptyEditorState: IEditorState = {
  loading: false,
  invalidQuery: null
};
