import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sh-insights-timeline-no-items',
  templateUrl: './insights-timeline-no-items.component.html',
  styleUrls: ['./insights-timeline-no-items.component.scss'],
})
export class InsightsTimelineNoItemsComponent implements OnInit {
  @Output() public onClearFilters: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public ngOnInit() {}
}
