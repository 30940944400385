import {
  EmptyStateBodyItemType,
  EmptyStateListTypes,
  IListBody,
  IListHeader,
} from '@shared/components/empty-state-container/empty-state-container.models';

export const ListConfig: (IListHeader | IListBody)[] = [
  {
    type: EmptyStateBodyItemType.header,
    title: 'Manual Tags',
    description: 'Manual tags allow you to set the reference to an event and get your insights in context of that event. let it be a new version or even a major customer starting to use your system.',
  },
  {
    type: EmptyStateBodyItemType.header,
    title: 'Automatic Tags',
    description: 'Define a webhook to notify Coralogix on new deployments and get the full quality status of your builds, per repository, environment, and component.',
  },
  {
    type: EmptyStateBodyItemType.body,
    title: null,
    listType: EmptyStateListTypes.imageList,
    listWidth: '59px',
    list: [
      {
        title: 'Heroku',
        imageUrl: '/assets/deployment-icons/large/heroku2.png',
        imageWidth: '50px',
        description: 'Setup Heroku pipelines to add automatic tags.',
        linkUrl: 'https://coralogix.com/software-builds-display/',
        linkText: 'See More'
      },
      {
        title: 'GitLab',
        imageUrl: '/assets/deployment-icons/large/gitlabLogo.png',
        imageWidth: '59px',
        description: 'Setup GitLab pipelines and CI to add automatic tags.',
        linkUrl: 'https://coralogix.com/software-builds-display/',
        linkText: 'See More'
      },
      {
        title: 'BitBucket',
        imageUrl: '/assets/deployment-icons/large/bitbucketLogo.png',
        imageWidth: '56px',
        description: 'Setup BitBucket pipelines and web hooks to add automatic tags.',
        linkUrl: 'https://coralogix.com/software-builds-display/',
        linkText: 'See More'
      },
      {
        title: 'TFS Online',
        imageUrl: '/assets/deployment-icons/large/vstfsLogo.png',
        imageWidth: '56px',
        description: 'Setup TFS Online web service and add CI builds to add automatic tags.',
        linkUrl: 'https://coralogix.com/software-builds-display/',
        linkText: 'See More'
      },
    ]
  }
];
