<div class="selected-tag-box">
  <div class="selected-tag-box__title">Alert Volume
    <ng-container *ngIf="delta$ | async as delta">- {{delta}}</ng-container>
  </div>
  <mat-card class="selected-tag-box__card selected-tag-box__content" *ngIf="(viewState$ | async) === completedViewState">
    <sh-summary-line-chart
      class="selected-tag-box__line-chart"
      [legend]="lineGraphLegend"
      [chartData]="graphInfo"
    ></sh-summary-line-chart>
    <div fxLayout="row" class="selected-tag-box__alerts-data">
      <div fxLayout="column" fxFlexOffset="10px">
        <sh-severity-chart
          class="selected-tag-box__severity"
          [totalSeverity]="totalSeverity"
          *ngIf="totalSeverity?.length > 0"
          [description]="'Alerts'"
        ></sh-severity-chart>
        <sh-chert-legend [legendData]="severityGraphLegend"></sh-chert-legend>
      </div>
      <div fxFlex="60"
           class="selected-tag-box__severity-list"
           fxLayout="row"
           fxLayout.lt-lg="column"
           fxLayoutGap="0px"
           fxFlex.lt-lg="50"
           fxLayoutGap.lt-lg="20">
        <sh-summary-severity-list
          class="selected-tag-box__list"
          fxFlex="50"
          fxFlex="50"
          fxFlex.lt-lg="100"
          *ngIf="listSummary?.length > 0"
          [listSummary]="listSummary | slice: 0 : (listSummary?.length / 2) + 1"
          [listLength]="5"
        ></sh-summary-severity-list>
        <sh-summary-severity-list
          class="selected-tag-box__list"
          fxFlex.lt-lg="100"
          fxFlex="50"
          *ngIf="listSummary?.length > (listSummary?.length / 2) + 1"
          [listSummary]="listSummary | slice: (listSummary?.length / 2) + 1 : listSummary?.length"
          [listLength]="5"
        ></sh-summary-severity-list>
      </div>
    </div>
  </mat-card>
  <sh-selected-tag-feature-layout [viewState]="viewState$ | async" noResultLabel="No New Alerts Since Last Tag">
  </sh-selected-tag-feature-layout>
</div>
