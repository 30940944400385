<form
  [formGroup]="createNewTeamForm"
  (submit)="submit(createNewTeamForm.value)"
  class="create-team-container"
>
  <sh-team-name-control [formGroup]="createNewTeamForm" controlName="teamName"></sh-team-name-control>
  <div class="create-team-container__info">The team name will be used to create your custom URL</div>
    <button
      [disabled]="loading"
      class="sh-login-btn-full create-team-container__submit-btn"
    >
      <span *ngIf="!loading">Create Team</span>
      <div *ngIf="loading" class="ui active tiny inline loader"></div>
    </button>
</form>
