import { ISummaryBaseMap, IStatisticsViewData, ITagSummary, TagSummaryTypes } from '@app/deployment/shared/models/summary.models';
import { SummaryViewMapBase } from '@app/deployment/shared/consts/summary.consts';

function getGrowthPercentage(tagResult: number, compareTagResult: number): number {
  if ((tagResult || compareTagResult) && tagResult !== compareTagResult) {
    const divided = (tagResult - compareTagResult) / compareTagResult;
    return divided * 100;
   } else {
     return 0;
  }
}

export function getMergedSummaryViewModel(summaryResponse: ITagSummary[]): IStatisticsViewData[] {
  const summaryBase: ISummaryBaseMap = SummaryViewMapBase;
  const mergedArr = [];

  for (const summaryBaseKey in summaryBase) {
    if (summaryBase.hasOwnProperty(summaryBaseKey)) {
      const summaryResItem = summaryResponse?.find(summary => summary.type === summaryBaseKey);
      const summaryBaseType = summaryBase[summaryBaseKey];
      const linkCacheId = summaryResItem?.linkCacheId ? '?id=' + summaryResItem?.linkCacheId : '';
      const redirectLink = `${summaryBaseType.redirectLink}${linkCacheId}`;
      const tagRes = summaryResItem.tagResult;
      const compareRes = summaryResItem.compareResult;
      const growthPercentage = summaryBaseKey === TagSummaryTypes.highSeverityRatio ?
        tagRes - compareRes :
        getGrowthPercentage(tagRes, compareRes);
      const isNegativeGrowth = growthPercentage < 0;

      mergedArr.push({
        ...summaryBaseType,
        ...summaryResItem,
        isNegativeGrowth,
        growthPercentage: Math.abs(growthPercentage),
        redirectLink
      });
    }
  }
  return mergedArr;
}
