import { Pipe, PipeTransform } from '@angular/core';
import { RulesServerTypesToViewTypes } from '@app/rules/shared/models/rule-types-view.models';
import { RuleGroup } from '../models/rule.model';

@Pipe({ name: 'removeduplicates' })
export class RemoveDuplicatesPipe implements PipeTransform {
  // Get an array of RuleGroups
  // Returns a string of the inner Rules types with out duplicates
  transform(value: RuleGroup[]): string {
    const rulesTypeArr = value.reduce( (acc, rg) => (acc.push(...rg.rules.map( r => RulesServerTypesToViewTypes[r.type][0])), acc), [])
    return [...new Set(rulesTypeArr)].join(', ');
  }
}
