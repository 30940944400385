import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'sh-coralogix-sidebar',
  templateUrl: './coralogix-sidebar.component.html',
  styleUrls: ['./coralogix-sidebar.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [style({ transform: 'translateX(100%)' }), animate('200ms', style({ transform: 'translateX(0)' }))]),
      transition(':leave', [style({ transform: 'translateX(0)' }), animate('200ms', style({ transform: 'translateX(100%)' }))]),
    ]),
    trigger('fadeAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CoralogixSidebarComponent {
  @Input()
  public templateRef: TemplateRef<any>;
  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();
  public showSideBar: boolean = false;

  public backDropClick(): void {
    this.close.emit();
  }

  public showSidebar(): void {
    this.showSideBar = true;
    document.querySelector('body').classList.add('hide-scroll-on-modal');
  }

  public hideSidebar(): void {
    this.showSideBar = false;
    document.querySelector('body').classList.remove('hide-scroll-on-modal');
  }
}
