import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CoralogixTimeInputSectionComponent } from '@shared/form-controls/coralogix-time-input/coralogix-time-input-time-section/coralogix-time-input-section.component';

@Component({
  selector: 'sh-coralogix-time-input',
  templateUrl: 'coralogix-time-input.component.html',
  styleUrls: ['coralogix-time-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CoralogixTimeInputComponent,
    multi: true,
  }],
})
export class CoralogixTimeInputComponent {
  @Input() public hasValidationError: boolean;
  @ViewChild('minutesInput', { static: true })
  public minutesInput: CoralogixTimeInputSectionComponent;
  @ViewChild('hoursInput', { static: true })
  public hoursInput: CoralogixTimeInputSectionComponent;
  @ViewChild('secondsInput', { static: true })
  public secondsInput: CoralogixTimeInputSectionComponent;
  @Output()
  public minutesKeyUp: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  public showSeconds: boolean;
  @Input()
  public showMinutes: boolean = true;
  @Input()
  public disableCtrl: boolean;

  public seconds: string = '00';
  public minutes: string = '00';
  public hours: string = '00';

  public value: string;

  private onChangeFn: (val: any) => any;
  private onTouchedFn: (val: any) => any;

  public registerOnChange(fn: (val: any) => any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: (val: any) => any): void {
    this.onTouchedFn = fn;
  }

  public onHoursChange(hours: string): void {
    this.hours = hours;
    this.value = `${this.hours}:${this.minutes}:${this.seconds}`;
    if (this.onChangeFn) {
      this.onChangeFn(this.value);
    }
  }

  public onMinutesChange(minutes: string): void {
    this.minutes = minutes;
    this.value = `${this.hours}:${this.minutes}:${this.seconds}`;
    if (this.onChangeFn) {
      this.onChangeFn(this.value);
    }
  }

  public onSecondsChange(seconds: string): void {
    this.seconds = seconds;
    this.value = `${this.hours}:${this.minutes}:${this.seconds}`;
    if (this.onChangeFn) {
      this.onChangeFn(this.value);
    }
  }

  public writeValue(value: string): void {
    this.value = value ? value : '00:00:00';
    const valueParsed = this.value.split(':');
    this.hours = valueParsed[0] ? valueParsed[0] : '00';
    this.minutes = valueParsed[1] ? valueParsed[1] : '00';
    this.seconds = valueParsed[2] ? valueParsed[2] : '00';
  }

  public onHoursKeyUp(): void {
    if (this.minutesInput) {
      this.minutesInput.makeInputFocused();
    }
  }

  public onMinutesKeyup(): void {
    this.minutesKeyUp.emit();
    if (this.secondsInput) {
      this.secondsInput.makeInputFocused();
    }
  }

  public onPreviousInputLastSectionKeyUp(): void {
    if (this.hoursInput) {
      this.hoursInput.makeInputFocused();
    }
  }
}
