import { ColDef } from 'ag-grid-community';
import { isMetadataField, removeCoralogixPrefix } from '@app/logs-new/shared/utils/logs-columns.utils';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { getLogsColumnsDefinitions, getLogsColumnsState, getSidebarCol } from '@app/logs-new/shared/assets/data/logs-columns';

interface IColumns {
  colsDefs?: ColDef[];
  colsState?: ColumnState[];
}

export class LogsColumnsService {
  static columnsDefinitions = getLogsColumnsDefinitions();
  static columnsState = getLogsColumnsState();

  constructor(private columns?: IColumns, private isTemplates?: boolean) {}

  getColumnDefinition(col: ColDef): ColDef {
    const columnDefinition = LogsColumnsService.getColDef(col);
    const cellRenderer = LogsColumnsService.matchCellRendererByPattern(col, this.isTemplates);
    if (cellRenderer) {
      columnDefinition.cellRenderer = cellRenderer;
    }
    return {
      ...columnDefinition,
      colId: LogsColumnsService.getColIdFromStateToGrid(columnDefinition.field),
    };
  }

  getAllColumnsDefinitions(withSidebar: boolean = true): ColDef[] {
    if (!this.columns.colsDefs) return [];
    const mappedColumns = this.columns.colsDefs.map(col => this.getColumnDefinition(col));
    return withSidebar ? [getSidebarCol().colDef, ...mappedColumns] : mappedColumns;
  }

  getAllColumnsState(withSidebar: boolean = true): ColumnState[] {
    if (!this.columns.colsState) return [];
    const colsState = this.columns.colsState.map(col => ({
      ...col,
      colId: LogsColumnsService.getColIdFromStateToGrid(col.colId),
    }));
    return withSidebar ? [getSidebarCol().colState, ...colsState] : colsState;
  }

  static createGridColumnDefFromString(colName: string): ColDef {
    if (colName in this.columnsDefinitions) {
      return this.columnsDefinitions[colName];
    }
    return {
      headerName: colName,
      field: 'textObject.' + colName,
      width: 200,
    };
  }

  static getDefaultLogsCols(extraColumns?: string[]): IColumns {
    const columns = [
      'index',
      'coralogix.timestamp',
      'coralogix.metadata.severity',
      'coralogix.text',
      'coralogix.metadata.applicationName',
      'coralogix.metadata.subsystemName',
      'coralogix.metadata.companyId',
      ...(extraColumns?.length ? extraColumns : []),
    ];
    return {
      colsDefs: columns.map(field => this.columnsDefinitions[field]),
      colsState: columns.map(field => this.columnsState[field]),
    };
  }

  static getDefaultTemplateCols(): IColumns {
    const columns = [
      'index',
      'coralogix.timestamp',
      'coralogix.metadata.severity',
      'occurrences',
      'ratio',
      'coralogix.text',
      'coralogix.metadata.applicationName',
      'coralogix.metadata.subsystemName',
      'coralogix.metadata.companyId',
    ];
    return {
      colsDefs: columns.map(field => this.columnsDefinitions[field]),
      colsState: columns.map(field => this.columnsState[field]),
    };
  }

  static getAqDefaultCols(): IColumns {
    const columns = [
      'index',
      'coralogix.timestamp',
      'coralogix.metadata.severity',
      'coralogix.text',
      'coralogix.metadata.applicationName',
      'coralogix.metadata.subsystemName',
    ];
    return {
      colsDefs: columns.map(field => this.columnsDefinitions[field]),
      colsState: columns.map(field => this.columnsState[field]),
    };
  }

  static createColState(name: string): ColumnState {
    const colId = LogsColumnsService.columnsDefinitions[name]?.field || `textObject.${name}`;
    return {
      colId,
      hide: false,
      aggFunc: null,
      width: 200,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
      flex: 0,
    };
  }

  static getColIdFromStateToGrid(field: string): string {
    return isMetadataField(`coralogix.${field}`) ? `coralogix.${field}` : field;
  }

  static getColIdFromGridToState(colId: string): string {
    return isMetadataField(colId) ? removeCoralogixPrefix(colId) : colId;
  }

  private static matchCellRendererByPattern(col: ColDef, isTemplates: boolean): string | null {
    if (col.field.startsWith('text')) {
      return isTemplates ? 'templatesFormatterRenderer' : 'jsonFormatterRenderer';
    }
    return null;
  }

  private static getColDef(column: ColDef): ColDef {
    const colId = this.getColIdFromStateToGrid(column.field);
    return this.columnsDefinitions[colId] || column;
  }
}
