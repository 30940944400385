import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-page-sub-header',
  templateUrl: './page-sub-header.component.html',
  styleUrls: ['./page-sub-header.component.scss'],
})
export class PageSubHeaderComponent {
  @Input() public title: string;
  @Input() public height: string = '83px';
}
