import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { nextTick } from '@shared/utils/utils';
import { ShDialogService } from '@shared/services/dialogService';
import { IHeaderParams } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogsContent } from '@app/logs-new/shared/models/logs-content.model';
import { removeTextObjectPrefix } from '@app/logs-new/shared/utils/logs-columns.utils';
import { ISortModel, LogsSortingType } from '@app/logs-new/shared/models/sort.model';
import { map, take } from 'rxjs/operators';
import { LogsColumnsService } from '@app/logs-new/shared/services/logs-columns.service';

@Component({
  selector: 'sh-custom-grid-header',
  templateUrl: 'custom-grid-header.component.html',
  styleUrls: ['custom-grid-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomGridHeaderComponent {
  isSorted$ = new BehaviorSubject<boolean>(false);
  params: IHeaderParams;
  tooltipText: string;
  colId = '';
  gridColId = '';
  LogsContent = LogsContent;
  activeSortModel$: Observable<ISortModel>;

  constructor(private dialogService: ShDialogService, private cdr: ChangeDetectorRef) {}

  public agInit(params: IHeaderParams): void {
    this.params = params;
    this.colId = params.column.getColId();
    this.gridColId = LogsColumnsService.getColIdFromStateToGrid(this.colId);
    this.tooltipText = removeTextObjectPrefix(params.column.getColId());
    this.activeSortModel$ = this.params?.context?.parent?.activeSortModel$?.pipe(
      map((activeSortModel: ISortModel) => {
        return {
          ...activeSortModel,
          field: LogsColumnsService.getColIdFromStateToGrid(activeSortModel.field),
        };
      }),
    );
    this.setInitialSortData();
  }

  public removeColumn(): void {
    this.params.context.parent.loadingQueryEvent.emit(true);
    nextTick(() => {
      this.params.context.parent.removeLogsColumnEvent.emit(this.gridColId);
      this.params.context.parent.loadingQueryEvent.emit(false);
      this.dialogService.showCoralogixMessage('Column removed successfully');
    });
  }

  public sortColumn(): void {
    this.params.progressSort();
    const sort = this.params.column.getSort() as LogsSortingType;
    if (!this.activeSortModel$) this.isSorted$.next(!!sort);
    if (this.colId === 'coralogix.timestamp' && !sort) return;
    this.params.context.parent.sortQueryEvent.emit({ sort, field: this.colId });
  }

  private setInitialSortData(): void {
    if (!this.activeSortModel$) {
      const sort = this.params.column.getSort() as LogsSortingType;
      if (sort) this.isSorted$.next(true);
      return;
    }
    this.activeSortModel$.pipe(take(1)).subscribe(sortModel => {
      if (sortModel?.field === LogsColumnsService.getColIdFromGridToState(this.colId) && sortModel.ordering) {
        this.params.setSort(sortModel.ordering);
        this.cdr.detectChanges();
      }
    });
  }
}
