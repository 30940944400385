import { Component, Input } from '@angular/core';
import { EFeatureViewMode } from '@app/deployment/shared/models/reducer.models';

@Component({
  selector: 'sh-selected-tag-feature-layout',
  templateUrl: './selected-tag-feature-layout.component.html',
  styleUrls: ['./selected-tag-feature-layout.component.scss']
})
export class SelectedTagFeatureLayoutComponent {
  @Input() public viewState: EFeatureViewMode;
  public viewStates: typeof EFeatureViewMode = EFeatureViewMode;

  @Input() public noResultLabel: string = 'No results';
}
