import { LogsGridColumnsActions } from './logs-grid-columns.actions';
import * as _ from 'lodash';

export namespace LogsGridColumnsReducer {
  export interface State {
    colDefs: any[];
    colState: any[];
  }

  const initalState: State = {
    colDefs: [],
    colState: [],
  };

  export function reducer(
    state: State = initalState,
    action: LogsGridColumnsActions.Actions,
  ): State {
    switch (action.type) {
      case LogsGridColumnsActions.ActionTypes.SET_LOGS_GRID_COLUMNS:
        return Object.assign({}, state, {
          colDefs: action.payload.colDefs,
          colState: action.payload.colState,
        });

      default:
        return state;
    }
  }
}
