import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  LogsViewStateProvider,
  QueryViewState,
} from '../shared/services/logsViewState.provider';
import { Log } from '../shared/models/log.entity';

@Component({
  selector: 'sh-logs-query-result',
  templateUrl: './logs-query-result.component.html',
  styleUrls: ['logs-query-result.component.scss'],
})
export class LogsQueryResultComponent implements OnInit {

  get viewState(): QueryViewState {
    return this.logsViewStateProvider.viewState;
  }

  constructor(private logsViewStateProvider: LogsViewStateProvider) {}

  public ngOnInit(): void {
    this.initState();
  }

  public initState(): void {
    setTimeout(() => {
      this.logsViewStateProvider.viewState.selectedLog = null;
      this.logsViewStateProvider.viewState.showFind = true;
    }, 0);
  }

  public rowSelected(log: Log): void {
    this.viewState.selectedLog = log;
  }
}
