import {
  ElementRef,
  Input,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  forwardRef,
  Directive,
  HostListener,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { getDateFormatViewPreference, State } from '@app/app.reducers';
import { DateFormatType } from '@shared/models/date-format-types';
import { DatePickerDateFormatTypes } from '@shared/helpers/sh-pickadate-directive-helper';
declare let $;

@Directive({
  selector: '[shPickADate]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ShPickadateProvider(),
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: ShPickadateProvider(), multi: true },
  ],
})
export class ShPickADateDirective implements AfterViewInit, OnDestroy, ControlValueAccessor {

  public format: string = DatePickerDateFormatTypes.european;
  @Input() public disable: any = [];
  @Input() public min: any;
  @Input() public max: any;
  @Input() public placeholder: string;
  @Input('container-selector') public containerSelector: string;

  @Output() public opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() public selected: EventEmitter<Date> = new EventEmitter<Date>();

  private input: HTMLElement;

  private onChange: any = Function.prototype;
  private onTouched: any = Function.prototype;

  private date: string;
  private datepicker: any;
  get options(): any {
    return {
      disable: this.disable,
      format: this.format,
      min: this.min,
      max: this.max,
      container: this.containerSelector,
      position: 'absolute',
      editable: false,
    };
  }
  constructor(private el: ElementRef, private store: Store<State>) {
    const dateFormatType = this.store.select(getDateFormatViewPreference);
    const dpTypes = DatePickerDateFormatTypes;
    dateFormatType.take(1).subscribe((dateType) => {
      this.format = dateType === DateFormatType.european ? dpTypes.european : dpTypes.american;
    });
  }

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    event.stopPropagation();
    this.datepicker.open();
  }

  public ngAfterViewInit(): any {
    this.assignGivenInputElement();
    const picker: any = $(this.el.nativeElement).pickadate(this.options);
    this.datepicker = picker.pickadate('picker');

    if (this.date) {
      this.datepicker.set('select', this.date, { format: this.format });
    }

    if (this.placeholder) {
      this.el.nativeElement.placeholder = this.placeholder;
    }

    this.datepicker.on('open', () => {
      this.onTouched();
      this.opened.emit(null);
    });
    this.datepicker.on('close', () => this.closed.emit(null));

    this.datepicker.on('set', (value) => {
      this.onChange(value.select);
      this.selected.emit(value.select);
    });
  }

  public ngOnDestroy(): void {
    this.datepicker.off('open', 'close', 'set');
  }

  public writeValue(value: string): void {
    this.date = value;
    if (this.datepicker) {
      this.datepicker.set('select', this.date, { format: this.format });
    }
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public validate(c: AbstractControl): {} {
    return this.validateFn(c);
  }
  private validateFn: any = (c: AbstractControl) => { return; };

  private assignGivenInputElement(): void {
    if (this.el.nativeElement.tagName === 'INPUT') {
      this.input = this.el.nativeElement;
    } else {
      this.input = this.findGivenInputElement();
    }
  }

  private findGivenInputElement(): HTMLElement {
    return this.el.nativeElement.getElementsByTagName('input')[0];
  }
}

export function ShPickadateProvider(): any {
  return forwardRef(() => ShPickADateDirective);
}
