import { jsonObject, jsonMember } from 'typedjson';
import { User } from './user.model';

@jsonObject
export class LoginResult {
  @jsonMember({ constructor: String })
  public token: string;
  @jsonMember({ constructor: User })
  public user: User;
}
