import { type } from '@shared/services/utils';
import { Action } from '@ngrx/store';
import {
  CreateMetricRequest,
  DeleteMetricRequest,
  EditMetricRequest,
  Metric
} from '@app/settings/metrices-settings/models/metric.model';

export namespace MetricsActions {
  export const ActionTypes = {
    GET_METRICS: type('[Metrics] Get all'),
    GET_METRICS_COMPLETED: type('[Metrics] Get all completed'),
    GET_METRICS_FAILED: type('[Metrics] Get all failed'),
    CREATE_METRICS: type('[Metrics] Create metric'),
    CREATE_METRICS_COMPLETED: type('[Metrics] Create metric completed'),
    CREATE_METRICS_FAILED: type('[Metrics] Create metric failed'),
    DELETE_METRICS: type('[Metrics] Delete metric'),
    DELETE_METRICS_COMPLETED: type('[Metrics] Delete metric completed'),
    DELETE_METRICS_FAILED: type('[Metrics] Delete metric failed'),
    EDIT_METRICS: type('[Metrics] Edit metric'),
    EDIT_METRICS_COMPLETED: type('[Metrics] Edit metric completed'),
    EDIT_METRICS_FAILED: type('[Metrics] Edit metric failed'),
    SHOW_METRICS_FAILED_MSG_COMPLETED: type('[Metrics] Show metric action failed msg completed')
  };

  export class GetMetricsAction implements Action {
    public type = ActionTypes.GET_METRICS;

    constructor(public payload: any) {}
  }

  export class GetMetricsCompletedAction implements Action {
    public type = ActionTypes.GET_METRICS_COMPLETED;

    constructor(public payload: Metric[]) {}
  }

  export class GetMetricsFailedAction implements Action {
    public type = ActionTypes.GET_METRICS_FAILED;

    constructor(public payload: any) {}
  }

  export class CreateMetricAction implements Action {
    public type = ActionTypes.CREATE_METRICS;

    constructor(public payload: CreateMetricRequest) {}
  }

  export class CreateMetricCompletedAction implements Action {
    public type = ActionTypes.CREATE_METRICS_COMPLETED;

    constructor(public payload: Metric) {}
  }

  export class CreateMetricFailedAction implements Action {
    public type = ActionTypes.CREATE_METRICS_FAILED;

    constructor(public payload: any) {}
  }

  export class DeleteMetricAction implements Action {
    public type = ActionTypes.DELETE_METRICS;

    constructor(public payload: DeleteMetricRequest) {}
  }

  export class DeleteMetricCompletedAction implements Action {
    public type = ActionTypes.DELETE_METRICS_COMPLETED;

    constructor(public payload: DeleteMetricRequest) {}
  }

  export class DeleteMetricFailedAction implements Action {
    public type = ActionTypes.DELETE_METRICS_FAILED;

    constructor(public payload: any) {}
  }

  export class EditMetricAction implements Action {
    public type = ActionTypes.EDIT_METRICS;

    constructor(public payload: EditMetricRequest) {}
  }

  export class EditMetricCompletedAction implements Action {
    public type = ActionTypes.EDIT_METRICS_COMPLETED;

    constructor(public payload: Metric) {}
  }

  export class EditMetricFailedAction implements Action {
    public type = ActionTypes.EDIT_METRICS_FAILED;

    constructor(public payload: any) {}
  }

  export class ShowMetricActionFailedMsgCompleted implements Action {
    public type = ActionTypes.SHOW_METRICS_FAILED_MSG_COMPLETED;

    constructor(public payload: any) {}
  }

  export type Actions =
    GetMetricsAction |
    GetMetricsCompletedAction |
    GetMetricsFailedAction |
    CreateMetricAction |
    CreateMetricCompletedAction |
    CreateMetricFailedAction |
    DeleteMetricAction |
    DeleteMetricCompletedAction |
    DeleteMetricFailedAction |
    EditMetricAction |
    EditMetricCompletedAction |
    EditMetricFailedAction |
    ShowMetricActionFailedMsgCompleted;
}
