<div class="tag-item" [ngClass]="{'tag-item--selected': isSelected}" fxLayout="column" (click)="tagSelected.emit(_tagData)">
  <div class="tag-item__title" fxLayout="row" fxFlexAlign="space-between center">
    <span class="tag-item__title-text">{{ formattedTagName }}</span>
    <sh-action-menu
      class="tag-item__action-menu"
      (editAction)="editTag.emit(_tagData)"
      (deleteAction)="deleteTag.emit(_tagData)" >
    </sh-action-menu>
  </div>
  <div class="tag-item__filters" *ngIf="showFilters">
    <div class="tag-item__filters-title">FILTERS</div>
    <div class="tag-item__filters-list">{{ _tagData?.application_name }} {{ _tagData?.subsystem_name }}</div>
  </div>
  <div class="tag-item__raised">
    Raised
    <span class="bold">
      {{ dateAgo }}
    </span>
    <div class="bold">{{_tagData?.tag_timestamp | dateFormatByUserSettings :
      null : null : null : momentUniqDateFormat }}</div>
  </div>
</div>
