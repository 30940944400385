import isObject from 'lodash-es/isObject';
import isEmpty from 'lodash-es/isEmpty';
import { jsonElementClass, jsonFormatterCssClass } from '@shared/modules/json-formatter/json-formatter.utils';
import { escapeRegExp } from 'lodash-es';
import xss from 'xss';

/* JSON prettify service, if given an object the service will return an HTML element
 * with the prettified values, otherwise if given a primitive value it will return the value
 * itself. If you'd like to prettify a JSON string, make sure to JSON.parse it beforehand. */
export class JsonFormatterService {
  constructor(
    private data: any,
    private isExpanded: boolean = true,
    private searchQuery: string = '',
    private useTokenizer: boolean = false,
    private hasMenu: boolean = true,
  ) {}

  static getSafeContent(content: string): string {
    return xss(content, {
      whiteList: {},
    });
  }

  static escapeContent<T>(content: T): string | T {
    if (typeof content !== 'string') return content;
    const stringLineBreaksRegex = /\\n\\r|\\r|\\n|\r\n|\r|\n/gi;
    const safeContent = this.getSafeContent(content);
    return safeContent.replace(stringLineBreaksRegex, '<br />');
  }

  render(): HTMLElement {
    const element = document.createElement('div');
    element.classList.add(jsonFormatterCssClass('container'));
    if (this.hasMenu) element.classList.add(jsonFormatterCssClass('with-menu'));
    element.innerHTML = isObject(this.data) ? this.addCurlyBraces(this.getJsonFormattedData()) : this.createValue(this.data);
    if (!this.isExpanded) {
      element.classList.add(jsonFormatterCssClass('compact'));
    }
    return element;
  }

  /* Loops through the object while building HTML elements for each
   row, key and value. The function creates 1 level of depth for an object, therefore
   when an object has a key that holds another object, it calls itself, effectively
   running recursively for all levels of depth until no data is present.
 */
  private getJsonFormattedData(data?: string | object, isArrayVal?: boolean): string {
    data = data ?? this.data;
    if (isObject(data)) {
      return Object.entries(data)
        .map(([key, value]) => {
          const keyData = this.createKey(key);

          /* creates object recursively until no value or the object is empty */
          let keyValue;
          if (Array.isArray(value) && !isEmpty(value)) {
            keyValue = this.createArray(value);
          } else if (Array.isArray(value) && isEmpty(value)) {
            keyValue = '[]';
          } else if (isObject(value) && !isEmpty(value)) {
            keyValue = this.createObject(value);
          } else if (isObject(value) && isEmpty(value)) {
            keyValue = '{}';
          } else {
            keyValue = this.createValue(value);
          }

          const keyRowData = isArrayVal ? keyValue : `${keyData}${keyValue}`;
          /* each key is also indented to the left, which is why it's also a row */
          return this.getRow(keyRowData);
        })
        .join('');
    } else {
      return this.createValue(data);
    }
  }

  private getRow(content: string): string {
    return this.createElement('row', content, 'div');
  }

  private createKey(content: string): string {
    const safeContent = JsonFormatterService.getSafeContent(content);
    return `${this.createElement('key', safeContent)}${this.getColons()}`;
  }

  private createObject(content: object): string {
    const data = this.addCurlyBraces(this.getJsonFormattedData(content));
    return this.createElement('object', data);
  }

  private createArray(content: object): string {
    const data = this.addBrackets(this.getJsonFormattedData(content, true));
    return this.createElement('array', data);
  }

  private createValue(content: any): string {
    /* If trying to format a primitive value, return it immediately with query highlight */
    if (this.useTokenizer) {
      const tokenizedContent = content && typeof content === 'string' ? content.split(' ') : [content];
      const valueElements = tokenizedContent
        .filter(word => word !== '')
        .map(word => this.createElement('value', JsonFormatterService.escapeContent(word)))
        .join(' ');
      return this.createValueRow(valueElements);
    }
    return this.createValueRow(this.createElement('value', JsonFormatterService.escapeContent(content)));
  }

  private createValueRow(content: string): string {
    return this.createElement('value-row', content);
  }

  private addCurlyBraces(content: string): string {
    return `${this.createElement('curly-braces', '{')}${content}${this.createElement('curly-braces', '}')}`;
  }

  private addBrackets(content: string): string {
    return `${this.createElement('brackets', '[')}<br />${content}${this.createElement('brackets', ']')}`;
  }

  private getColons(): string {
    return this.createElement('colons', ':');
  }

  /* All elements created while iterating the object are created with this function, which is responsible
   * of:
   * 1. Adding relevant classes to each element created
   * 2. Highlighting keys and values
   * 3. The function is also able to take either a string or an Element and append it to the newly created element,
   * which allows us to create elements more dynamically.
   *  */
  private createElement(className: jsonElementClass, content: string = '', type: string = 'span'): string {
    const testAttribute = jsonFormatterCssClass(className);
    const isKeyOrValue = className === 'key' || className === 'value';
    content = isKeyOrValue ? this.highlightQuery(content) : content;
    className = jsonFormatterCssClass(className) as jsonElementClass;
    if (!this.isExpanded) {
      className = `${className} ${jsonFormatterCssClass('compact')}` as jsonElementClass;
    }
    return `<${type} data-test='${testAttribute}' class='${className}'>${content}</${type}>`;
  }

  private highlightQuery(content: string): string {
    if (!content || !this.searchQuery || typeof content !== 'string') {
      return content;
    }
    const re = new RegExp(escapeRegExp(this.searchQuery), 'g');
    const includesQuery = content.match(re);
    return includesQuery ? content.replace(re, this.createElement('highlighted', '$&', 'mark')) : content;
  }
}
