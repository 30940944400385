import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsightsTimelineComponent } from './components/insights-timeline/insights-timeline.component';
import { InsightsService } from './shared/services/insights-service';
import { AnomalyService } from './shared/services/anomaly-service';
import { InsightTimelineItemComponent } from './components/insight-timeline-item/insight-timeline-item.component';
import { InsightsTextFilterPipe } from './shared/pipes/insights-text-filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupInsightsPipe } from './shared/pipes/group-insights.pipe';
import { SharedModule } from '../shared/shared.module';
import { InsightsComponent } from './insights.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { StatisticsModule } from '../statistics/statistics.module';
import { LogsModule } from '../logs/logs.module';
import { InsightsProvider } from './shared/services/insights.provider';
import { LoggregationModule } from '../loggregation/loggregation.module';
import { InsightsLoggregationComponent } from './components/insights-loggregation/insights-loggregation.component';
import { AnomalyLoggregationComponent } from './components/anomaly-loggregation/anomaly-loggregation.component';
import { InsightsLogsComponent } from './components/insights-logs/insights-logs.component';
import { InsightFilterComponent } from './components/insight-filter/insight-filter.component';
import { InsightsFilterPipe } from './shared/pipes/insights-filter.pipe';
import { AnomalyTuningComponent } from './components/anomaly-tuning/anomaly-tuning.component';
import { AnomalyTuningFormProvider } from './components/anomaly-tuning/anomaly-tuning-form-provider';
import { InsightInfoBarComponent } from './components/insight-info-bar/insight-info-bar.component';
import { AdvanceAlertService } from './shared/services/advance-alert.service';
import { InsightsMetadataFilterPipe } from './shared/pipes/insights-metadata-filter';
import { FlowAnomalyComponent } from './components/flow-anomaly/flow-anomaly.component';
import { InsightsTimelineNoItemsComponent } from './components/insights-timeline-no-items/insights-timeline-no-items.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AdvancedAlertComponent } from './components/advanced-alert/advanced-alert.component';
import { SimpleAlertComponent } from './components/simple-alert/simple-alert.component';
import { InsightsHitCountComponent } from './components/insights-hit-count/insights-hit-count.component';
import { GroupByAlertComponent } from './components/group-by-alert/group-by-alert.component';
import { VolumeAnomalyComponent } from './components/volume-anomaly/volume-anomaly.component';
import { VolumeAnomalyWidgetsComponent } from './components/volume-anomaly/volume-anomaly-widgets/volume-anomaly-widgets.component';
import { VolumeAnomalyGraphComponent } from './components/volume-anomaly/volume-anomaly-graph/volume-anomaly-graph.component';
import { EmptyAlertLogsGridComponent } from './components/empty-alert-logs-grid/empty-alert-logs-grid.component';
import { CardinalityAlertModule } from './components/cardinality-alert/cardinality-alert.module';
import { DateFormatByUserSettingsModule } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.module';
import { AlertViewLayoutModule } from './components/alert-view-layout/alert-view-layout.module';
import { MetricAlertModule } from './components/metric-alert/metric-alert.module';
import { MetricAlertService } from './shared/services/metric-alert.service';
import { FormControlsModule } from '@app/shared/form-controls/form-controls.module';
import { SnoozeButtonModule } from './components/snooze-button/snooze-button.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SearchModule } from '@app/shared/controls/search/search.module';
import { AboveBelowNormalModule } from '@app/shared/pipes/above-below-normal/above-below-normal.module';

@NgModule({
  declarations: [
    InsightsTimelineComponent,
    InsightTimelineItemComponent,
    InsightsTextFilterPipe,
    GroupInsightsPipe,
    InsightsLoggregationComponent,
    AnomalyLoggregationComponent,
    InsightsLogsComponent,
    InsightsComponent,
    InsightFilterComponent,
    InsightsFilterPipe,
    InsightsMetadataFilterPipe,
    AnomalyTuningComponent,
    InsightInfoBarComponent,
    FlowAnomalyComponent,
    InsightsTimelineNoItemsComponent,
    AdvancedAlertComponent,
    SimpleAlertComponent,
    InsightsHitCountComponent,
    GroupByAlertComponent,
    VolumeAnomalyComponent,
    VolumeAnomalyWidgetsComponent,
    VolumeAnomalyGraphComponent,
    EmptyAlertLogsGridComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    StatisticsModule,
    LogsModule,
    LoggregationModule,
    NoopAnimationsModule,
    CardinalityAlertModule,
    DateFormatByUserSettingsModule,
    AlertViewLayoutModule,
    MetricAlertModule,
    FormControlsModule,
    SnoozeButtonModule,
    MatTableModule,
    MatSortModule,
    SearchModule,
    AboveBelowNormalModule,
  ],
  exports: [InsightsTimelineComponent],
  providers: [InsightsService, InsightsProvider, AnomalyTuningFormProvider, AdvanceAlertService, AnomalyService, MetricAlertService],
})
export class InsightsModule {}
