import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getApplicationNames, getSubsystemNames, State } from '@app/app.reducers';
import moment from 'moment';

@Component({
  selector: 'sh-reindex-editor-filters',
  templateUrl: './reindex-editor-filters.component.html',
  styleUrls: ['./reindex-editor-filters.component.scss']
})
export class ReindexEditorFiltersComponent {

  @Input()
  public form: FormGroup;
  @Input()
  public logsSeverityOptions: {id: number, name: string}[];

  public applicationOptions$: Observable<string[]>;
  public subsystemOptions$: Observable<string[]>;

  public timePickerSeen: boolean = false;
  public timePickerLabel: string = 'Choose a date and time';

  constructor(private store: Store<State>) {
    this.applicationOptions$ = store.select(getApplicationNames);
    this.subsystemOptions$ = store.select(getSubsystemNames);
  }

  public get isFormInvalid(): boolean {
    return this.form.invalid;
  }

  public displayTimePicker(): void {
    this.timePickerSeen = !this.timePickerSeen;
  }

  public acceptTime(): void {
    this.displayTimePicker();
    const date = moment(this.form.get('from').value).format('DD/MM/YYYY');
    const hour = this.form.get('startTime').value.substring(0, 5);

    this.timePickerLabel = `${date}, ${hour}`;
  }
}
