import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataSize',
})
export class DataSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  public transform(bytes: number = 0, precision: number = 2): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '?';
    }

    let unit = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }
    const floatNumberReg = /^-?[0-9]+$/;
    const isInteger = floatNumberReg.test(String(bytes));
    return isInteger ? `${bytes} ${this.units[unit]}` : `${bytes.toFixed(+precision)} ${this.units[unit]}`;
  }
}
