export enum LogSeveritiesEnum {
  'DEBUG' = 1,
  'VERBOSE' = 2,
  'INFO' = 3,
  'WARNING' = 4,
  'ERROR' = 5,
  'CRITICAL' = 6
}

export enum LogSeveritiesEnumValues {
  DEBUG = 'DEBUG',
  VERBOSE = 'VERBOSE',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL'
}
