import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Entity } from '@shared/models/entity.model';

@Component({
    selector: 'sh-alerts-content',
    templateUrl: './alerts-content.component.html',
    styleUrls: ['./alerts-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsContentComponent implements OnInit {
    @Input()
    public isEdit: boolean;
    @Input()
    public form: FormGroup;
    @Input()
    public jsonFields: Entity[];

    public isOpened: boolean;
    public disabled: boolean;

    public ngOnInit(): void {
        if (this.isEdit) {
            this.isOpened = true;
            this.disabled = true;
        }
    }

    public afterExpand(): void {
        this.disabled = true;
    }
}
