<div>
  <form [formGroup]="form" (submit)="submit(form.value)">
    <section class="head-section">
      <p class="primary-header coralogix">Archive to S3</p>
      <div>
        <button
          [disabled]="isFormInvalid"
          [class.disabled]="isFormInvalid"
          class="button coralogix primary-add-button"
          type="submit" >SAVE</button>
      </div>
    </section>

    <section class="content-wrapper">
      <div class="content">
        <sh-reindex-editor-archive
          [regionOptions]="regionOptions"
          [form]="form"
          (testArchivingProvider)="testArchivingProvider.emit($event)"
          [testing]="testing"
          [testingResult]="testingResult"></sh-reindex-editor-archive>
      </div>
    </section>
  </form>
</div>

