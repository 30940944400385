import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AgGridEvent,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowClickedEvent,
  RowNode,
  RowSelectedEvent,
} from 'ag-grid-community';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { LogsGridService } from '@app/logs-new/shared/services/logs-grid.service';
import { filter, skip, take, takeUntil, tap } from 'rxjs/operators';
import { defaultFrameworkComponents } from './grid.config';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { getPaginationToAndFrom, nextTick } from '@shared/utils/utils';
import { filterFalsy } from '@app/logs-new/shared/operators/filter-falsy.operator';
import { ISelectedLog } from '../../models/query-params.model';
import { LogsContent } from '../../models/logs-content.model';
import { ColDef } from 'ag-grid';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { ILog } from '@app/logs-new/shared/models/logs-data.model';
import { AgGridColumn } from 'ag-grid-angular';
import { IGridPaginationEvent } from '@app/logs-new/shared/models/grid.model';
import { ISortModel, LogsSortingType } from '@app/logs-new/shared/models/sort.model';
import { ISearchQueryEvent } from '@app/logs-new/shared/models/search.model';
import { LogFilterType } from '@app/logs-new/shared/models/logs-filter.model';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { defaultColDef } from '@app/logs-new/shared/features/logs-new-grid/grid.config';
import { ContextualRegistryService } from '@app/logs-new/shared/services/contextual-registry.service';
import { ArchiveLogsService } from '@app/logs-new/services/archive-logs.service';
import { Plugin } from '@app/logs-new/components/plugins-container/model/plugin.model.ts';

const HIGHLIGHT_CLASS = 'highlighted';

@Component({
  selector: 'sh-logs-new-grid',
  templateUrl: 'logs-new-grid.component.html',
  styleUrls: ['./logs-new-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsNewGridComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() withInfoPanel = true;
  @Input() activeTab$: Observable<LogsContent> = new BehaviorSubject<LogsContent>(LogsContent.logs);
  @Input() selectedLogs$: Observable<ISelectedLog[]>;
  @Input() colsDefs$: Observable<ColDef[]>;
  @Input() colsState$: Observable<ColumnState[]>;
  @Input() suppressNoRowsOverlay: boolean;
  @Input() rowsData$: Observable<ILog[]>;
  @Input() defaultColDef: Partial<AgGridColumn> = defaultColDef;
  @Input() paginationItemsPerPage = 100;
  @Input() usePagination = false;
  @Input() rowsCount$: Observable<number>;
  @Input() privatePlugins: Plugin[];
  @Input() sharedPlugins: Plugin[];
  @Output() rowSelectedEvent = new EventEmitter<RowSelectedEvent>();
  @Output() gridReadyEvent = new EventEmitter<GridReadyEvent>();
  @Output() columnStateChanged = new EventEmitter<ColumnState[]>();
  @Output() paginationChanged = new EventEmitter<IGridPaginationEvent>();
  @Output() setLoadingSpinner = new EventEmitter<boolean>();
  @Output() selectLog = new EventEmitter<{ selectedLog: ISelectedLog }>();
  @Output() focusLog = new EventEmitter<ISelectedLog>();
  @Output() clearSelectedLogs = new EventEmitter();
  @Output() loadingQueryEvent = new EventEmitter<boolean>();
  @Output() removeLogsColumnEvent = new EventEmitter<string>();
  @Output() sortQueryEvent = new EventEmitter<{ sort: LogsSortingType; field: string }>();
  @Output() setShowInfoPanelEvent = new EventEmitter<boolean>();
  @Output() setActiveTabEvent = new EventEmitter<LogsContent>();
  @Output() addToSearchQueryEvent = new EventEmitter<ISearchQueryEvent>();
  @Output() addLogsColumnEvent = new EventEmitter<{ payload: string; correlationId: string }>();
  @Output() toggleLogSelectionEvent = new EventEmitter<ISelectedLog>();
  @Output() addSelectedQueryFieldEvent = new EventEmitter<{
    payload: { key: string; value: string };
    correlationId?: string;
  }>();
  @Output() viewSurroundingLogsEvent = new EventEmitter<{ log: ILog; seconds: number }>();
  @Output() viewSurroundingArchiveLogsEvent = new EventEmitter<{ log: ILog; seconds: number }>();
  @Output() addQueryFieldEvent = new EventEmitter<{ colField: string; filterType: LogFilterType }>();
  @Output() resetViewSurroundingLogs = new EventEmitter<void>();
  @Input() showDeleteColumnIcon: boolean;
  @Input() searchQuery$: Observable<string>;
  @Input() activeSortModel$: Observable<ISortModel>;
  @Input() logsQuery$: Observable<ILogQuery>;
  @Input() showJsonFormatterMenu = true;
  gridContext: { [key: string]: any } = { parent: this.context };
  frameworkComponents: { [key: string]: any } = defaultFrameworkComponents;
  expandedModeSnapshot: boolean = true;
  isTemplates: boolean;
  isArchiveDisabled: boolean;
  selectedLogsMap: Map<string, ISelectedLog> = new Map<string, ISelectedLog>();
  isTableReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resetPagination$: Subject<boolean> = this.logsGridService.resetPagination$;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  rowClassRules = {
    highlighted: row => this.selectedLogsMap.has(row.data.logId),
    'row-selected': row => row.node.isSelected(),
  };
  isSelectedLogFocus: boolean = true;
  logsContent = LogsContent;
  logSelectionEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showInfoPanel$ = new BehaviorSubject(false);
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  private destroyed$: Subject<void> = new Subject<void>();
  private _expandedMode$: Observable<boolean>;
  private _gridOptions: GridOptions;

  constructor(
    private logsGridService: LogsGridService,
    private ctxRegistryService: ContextualRegistryService,
    private archiveLogsService: ArchiveLogsService,
  ) {}

  @Input()
  set showInfoPanel(showInfoPanel: boolean) {
    this.showInfoPanel$.next(showInfoPanel);
  }
  get showInfoPanel(): boolean {
    return this.showInfoPanel$.getValue();
  }

  @Input()
  set gridOptions(gridOptions: GridOptions) {
    this._gridOptions = gridOptions || {};
  }
  get gridOptions(): GridOptions {
    return this._gridOptions;
  }

  @Input()
  set expandedMode$(expandedMode$: Observable<boolean>) {
    this._expandedMode$ = expandedMode$;
    this.onExpandedModeChange(expandedMode$);
  }
  get expandedMode$(): Observable<boolean> {
    return this._expandedMode$ || new BehaviorSubject<boolean>(true);
  }

  get context(): {} {
    return this;
  }

  ngOnInit(): void {
    this.initHighlightLogs();
    if (this.withInfoPanel) this.handleOnSpacePress();
    this.setInitialContextData();
    this.ctxRegistryService?.registryObs$?.pipe(takeUntil(this.destroyed$)).subscribe(registry => {
      this.logSelectionEnabled$.next(registry?.isEnabled('highlightLogs'));
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    if (this.usePagination) {
      this.handleQueryReset();
      this.removePaginationTooltip();
      this.onPaginationIndexChange();
    }
  }

  initHighlightLogs(): void {
    if (this.isTableReady$ && this.selectedLogs$) {
      combineLatest([this.isTableReady$, this.selectedLogs$])
        .pipe(
          filter(([isTableReady]) => isTableReady),
          takeUntil(this.destroyed$),
        )
        .subscribe(([isTableReady, selectedLogs]) => {
          this.selectedLogsMap.clear();
          this.removeHighlightedClass();
          if (this.gridApi && !this.isTemplates && selectedLogs?.length > 0) {
            this.gridApi.deselectAll();
            this.highlightLogs(selectedLogs);
          }
        });
    }
  }

  getRowHeight(params: any): number {
    const { logsGridService, expandedModeSnapshot } = params.context.parent;
    const biggestProp = logsGridService.getBiggestProp(params.node, params.node.columnApi, expandedModeSnapshot);
    const width = params.node.columnApi.getColumn(biggestProp)?.getActualWidth();
    return logsGridService.getRowHeight(params.node, biggestProp, width, expandedModeSnapshot);
  }

  onPaginationChanged(pageEvent: PageEvent): void {
    const { to, from } = getPaginationToAndFrom(pageEvent.pageIndex, pageEvent.pageSize);
    this.paginationChanged.emit({ ...pageEvent, to, from });
  }

  onGridReady(params: AgGridEvent): void {
    this.logsGridService.params = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.colsState$) {
      this.handleColsStateChange();
    }
    this.gridReadyEvent.emit(params);
  }

  onRowDataChanged(): void {
    this.isTableReady$.next(true);
    const selectedLog = this.logsGridService.selectLog$.getValue();
    if (!selectedLog) {
      return;
    }
    this.gridApi?.forEachNode(node => {
      if (node.data.logId === selectedLog.logId) {
        node.setSelected(true);
        this.gridApi.ensureIndexVisible(node.rowIndex, 'middle');
        this.logsGridService.selectLog$.next(null);
        this.resetViewSurroundingLogs.emit();
      }
    });
  }

  onColumnResize(event: any): void {
    if (event.source === 'uiColumnDragged' && event.finished) {
      this.resetRows();
      this.emitColumnState();
    }
  }

  resetRows(): void {
    this.gridApi?.resetRowHeights();
    this.gridApi?.refreshCells({ force: true });
  }

  onRowSelected(event: RowSelectedEvent): void {
    event.node.setData({ ...event.data });
    this.rowSelectedEvent.emit(event);
  }

  onRowClicked(event: RowClickedEvent): void {
    const node = this.getRow(event.node);
    this.logsGridService.updateSelectedRow(node);
    const metaKey = event.event['metaKey'];
    const ctrlKey = event.event['ctrlKey'];
    if ((metaKey || ctrlKey) && this.logSelectionEnabled$.getValue()) {
      const {
        data: { logId, index, timestamp },
      } = event;
      const selectedLog: ISelectedLog = {
        id: logId,
        index,
        timestamp,
        comment: '',
        isDisabled: false,
        isFocused: false,
      };
      this.selectLog.emit({ selectedLog });
    } else {
      if (this.selectedLogsMap.size) {
        this.highlightLogs(Array.from(this.selectedLogsMap.values()), true);
      }
    }
  }

  public clearSelectedRows(): void {
    this.gridApi.deselectAll();
    this.clearSelectedLogs.emit();
  }

  public setFocusedRow(log: ISelectedLog): void {
    this.isSelectedLogFocus = true;
    this.focusLog.emit(log);
  }

  public highlightLogs(selectedLogs: ISelectedLog[], isRegularClick: boolean = false): void {
    selectedLogs.forEach(log => this.selectedLogsMap.set(log.id, log));
    this.gridApi.forEachNode(node => {
      if (this.selectedLogsMap.has(node.data.logId)) {
        const selectedLog = this.selectedLogsMap.get(node.data.logId);
        if (!selectedLog.isDisabled) {
          if (!isRegularClick) {
            // this is done in order for the rowClassRules to engage
            node.setData({ ...node.data });
          }
          if (selectedLog.isFocused && this.isSelectedLogFocus) {
            this.isSelectedLogFocus = !this.isSelectedLogFocus;
            this.gridApi.ensureIndexVisible(node.rowIndex, 'middle');
          }
        }
      }
    });
  }

  public removeHighlightedClass(): void {
    document.querySelectorAll(`.ag-row.ag-row-level-0.${HIGHLIGHT_CLASS}`).forEach(row => row.classList.remove(HIGHLIGHT_CLASS));
  }

  emitColumnState(): void {
    const columnState = this.gridColumnApi?.getColumnState();
    if (columnState?.length) this.columnStateChanged.emit(columnState);
    this.setLoadingSpinner.emit(false);
  }

  setInfoPanel(showInfoPanel: boolean): void {
    this.showInfoPanel$.next(showInfoPanel);
    this.setShowInfoPanelEvent.emit(showInfoPanel);
  }

  navigateToNextCell(params: any): any {
    const suggestedNextCell = params.nextCellPosition;
    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const noUpOrDownKeyPressed = params.key !== KEY_DOWN && params.key !== KEY_UP;
    if (noUpOrDownKeyPressed || !suggestedNextCell || suggestedNextCell.rowIndex < 0) {
      return params.previousCellPosition;
    }
    this.gridApi.forEachNode(node => {
      if (node.rowIndex === suggestedNextCell.rowIndex) {
        node.setSelected(true);
        this.logsGridService.updateSelectedRow(this.getRow(node));
      } else {
        node.setSelected(false);
      }
    });
    return suggestedNextCell;
  }

  private onPaginationIndexChange(): void {
    this.logsGridService.paginationIndex$.pipe(takeUntil(this.destroyed$)).subscribe(pageIndex => {
      if (typeof pageIndex !== null) {
        this.paginator.pageIndex = pageIndex;
      }
    });
  }

  private getRow(node: RowNode): RowNode {
    return ({
      ...node,
      context: this.gridContext,
    } as unknown) as RowNode;
  }

  private resetGrid(): void {
    this.gridApi?.resetRowHeights();
    this.gridApi?.redrawRows();
  }

  private handleColsStateChange(): void {
    this.colsState$.pipe(takeUntil(this.destroyed$)).subscribe(colsState => {
      if (colsState?.length) {
        nextTick(() => this.gridColumnApi.setColumnState(colsState));
      }
    });
  }

  private onExpandedModeChange(expandedMode$: Observable<boolean>): void {
    /* Sets expanded mode and skips first time because there is no need to reset the grid in the first time
     * but setting expanded mode the first time is important if the default is true */
    expandedMode$
      .pipe(
        tap(expandedMode => (this.expandedModeSnapshot = expandedMode)),
        skip(1),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.setLoadingSpinner.emit(true);
        nextTick(() => {
          this.resetGrid();
          this.setLoadingSpinner.emit(false);
        });
      });
  }

  /* Angular Material comes with tooltip for the paginator by default */
  private removePaginationTooltip(): void {
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.nextPageLabel = '';
    paginatorIntl.previousPageLabel = '';
    paginatorIntl.firstPageLabel = '';
    paginatorIntl.lastPageLabel = '';
  }

  private handleQueryReset(): void {
    if (this.resetPagination$) {
      this.resetPagination$.pipe(filterFalsy(), takeUntil(this.destroyed$)).subscribe(() => {
        this.paginator.firstPage();
      });
    }
  }

  private handleOnSpacePress(): void {
    fromEvent(document, 'keyup')
      .pipe(
        filter((event: KeyboardEvent) => event.code === 'Space'),
        filter((event: KeyboardEvent) => {
          const excludedTagNames = ['INPUT', 'TEXTAREA'];
          return (
            !event.target?.['isContentEditable'] && (!event.target?.['tagName'] || !excludedTagNames.includes(event.target?.['tagName']))
          );
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.setInfoPanel(!this.showInfoPanel);
      });
  }

  private setInitialContextData(): void {
    this.activeTab$.pipe(take(1)).subscribe(activeTab => {
      this.isTemplates = activeTab === LogsContent.templates;
    });
    this.archiveLogsService
      .getArchivePermissions()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(isArchiveDisabled => {
        this.isArchiveDisabled = isArchiveDisabled;
      });
  }
}
