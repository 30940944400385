import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { DeploymentService } from './services/deployment.service';
import { Action, Store } from '@ngrx/store';
import { getSelectedShowAllSubsystems, getSelectedTag, getSelectedTagForCompare, getTags, State } from '@app/app.reducers';
import { combineLatest, Observable } from 'rxjs';
import { TagsActions } from './actions/tag.actions';
import { of } from 'rxjs/observable/of';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { switchMap, map, tap, withLatestFrom, take, filter } from 'rxjs/operators';
import { DefaultCompareEnd, getTagRedirectUrl } from '@app/deployment/shared/consts/tags.consts';
import { IStatisticsViewData, ITagSummaryResponse, ITagWithCompareBaseRequest } from '@app/deployment/shared/models/summary.models';
import { getMergedSummaryViewModel } from '@app/deployment/shared/helpers/summary.helper';
import { TagsCompareService } from '@app/deployment/shared/services/tags-compare.service';
import { INewTagActionPayload } from '@app/deployment/shared/models/actions-models';
import { Build } from '@app/deployment/models/build';
import { INewEditTagRequestModel } from '@app/deployment/models/tag.request.models';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { subDays } from 'date-fns';

@Injectable()
export class TagsEffects {
  @SelectSnapshot(CompanyState.retention) public retention: number;

  @Effect()
  public load$: Observable<Action> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.GET_TAGS),
    switchMap((action: any) => {
      return this.tagService
        .getBuilds(action.payload)
        .map(tags => new TagsActions.GetTagsCompletedAction(tags.data))
        .catch(err => of(new TagsActions.GetTagsFailedAction(err)));
    }),
  );

  @Effect()
  public delete$: Observable<Action> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.DELETE_TAG),
    switchMap((action: any) => {
      return this.tagService
        .deleteBuild(action.payload)
        .pipe(
          tap(data => {
            combineLatest([
              this.store.select(getSelectedTag),
              this.store.select(getSelectedTagForCompare),
              this.store.select(getTags).pipe(map(builds => this.filterBuildByMinRetentionDate(builds))),
              this.store.select(getSelectedShowAllSubsystems),
            ])
              .pipe(filter(([tag, compare, tagList]) => !!tag))
              .take(1)
              .subscribe(([tag, compare, tagList, allSubsystems]) => {
                const isDeletedEqualSelected = action.payload === tag?.id;
                const isDeletedEqualCompareTag = action.payload === compare?.id;
                if (!!isDeletedEqualSelected) {
                  this.store.dispatch(new TagsActions.SelectTagsAction(null));
                  this.redirectToUpdatedRoute();
                } else if (!!isDeletedEqualCompareTag) {
                  const selectedApplications = this.userSettingsProvider.userSettings.queryMetadata.applicationName;
                  const selectedSubsystems = this.userSettingsProvider.userSettings.queryMetadata.subsystemName;

                  const comparableTagList = tag.getComparableTagList(tagList, selectedApplications, selectedSubsystems, allSubsystems);
                  const [olderTagList, newerTagList] = tag.olderNewerTagLists(comparableTagList);
                  const compareTag = !!olderTagList.length ? olderTagList[0] : null;
                  this.store.dispatch(new TagsActions.SelectTagToCompare(compareTag));
                  this.store.dispatch(new TagsActions.SetTagNCompareBasePayloadAction(null));
                  this.redirectToUpdatedRoute();
                }
              });
          }),
        )
        .map(tags => new TagsActions.DeleteTagCompletedAction(tags))
        .catch(err => of(new TagsActions.DeleteTagFailedAction(err)));
    }),
  );

  @Effect()
  public add$: Observable<Action> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.ADD_TAG),
    switchMap((action: { payload: INewTagActionPayload }) => {
      const tagData: INewEditTagRequestModel = action.payload.tagData;
      return this.tagService
        .addManualEvent(this.tagService.getNormalizeTagPostRequest(tagData))
        .map((tag: Build) => new TagsActions.AddTagCompletedAction({ tagData: tag, compareTagId: action.payload.compareTagId }))
        .catch(err => of(new TagsActions.AddTagFailedAction(err)));
    }),
  );

  @Effect({ dispatch: false })
  public addCompleted$: Observable<any> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.ADD_TAG_COMPLETED),
    tap((action: { payload: INewTagActionPayload }) => {
      const { tagData } = action.payload;
      this.store
        .select(getSelectedTagForCompare)
        .take(1)
        .subscribe(compare => {
          const compareId = !!compare?.id ? compare.id : DefaultCompareEnd;
          const redirectRoute = getTagRedirectUrl(tagData?.id, compareId);
          this.router.navigate([redirectRoute]).finally();
        });
    }),
  );

  @Effect()
  public edit$: Observable<Action> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.EDIT_TAG),
    switchMap((action: any) => {
      return this.tagService
        .editTag(this.tagService.getNormalizeTagPostRequest(action.payload))
        .pipe(
          tap(data => {
            combineLatest([this.store.select(getSelectedTag), this.store.select(getSelectedTagForCompare)])
              .pipe(filter(([tag, compare]) => !!tag))
              .pipe(filter(([tag, compare]) => !!tag))
              .take(1)
              .subscribe(([tag, compare]) => {
                const isEditedSelected = action.payload?.id === tag?.id;
                const isEditedEqualCompareTag = action.payload === compare?.id;
                if (!!isEditedSelected) {
                  this.store.dispatch(new TagsActions.SelectTagsAction(data));
                  this.store.dispatch(new TagsActions.SetTagNCompareBasePayloadAction(null));
                }
                if (!!isEditedEqualCompareTag) {
                  this.store.dispatch(new TagsActions.SelectTagToCompare(data));
                  this.store.dispatch(new TagsActions.SetTagNCompareBasePayloadAction(null));
                }
              });
          }),
        )
        .map(tag => new TagsActions.EditTagCompletedAction(tag))
        .catch(err => of(new TagsActions.EditTagFailedAction(err)));
    }),
  );

  @Effect()
  public setTagSummary$: Observable<Action> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.GET_TAG_SUMMARY),
    switchMap((action: any) => {
      return this.tagService
        .getTagSummary(action.payload)
        .pipe(
          map((tagSummaryRes: ITagSummaryResponse) => getMergedSummaryViewModel(tagSummaryRes.data)),
          map((tagSummary: IStatisticsViewData[]) => new TagsActions.GetTagSummaryCompletedAction(tagSummary)),
        )
        .catch(err => of(new TagsActions.GetTagSummaryFailedAction(err)));
    }),
  );

  @Effect()
  public setAlertsSummary$: Observable<Action> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.GET_TAG_ALERTS),
    switchMap((action: { type: string; payload: ITagWithCompareBaseRequest }) => {
      const { tag, compareTag } = action.payload;
      const { startTime, endTime } = tag;
      const diff = endTime - startTime;

      const _3hours = 1000 * 60 * 60 * 3;
      const _1hour = 1000 * 60 * 60;
      const _1minute = 1000 * 60;

      const step = diff < _3hours ? _1minute : _1hour;

      const payload: ITagWithCompareBaseRequest = {
        ...action.payload,
        tag: { ...tag, step },
        compareTag: { ...compareTag, step },
      };
      return this.tagService
        .getTagAlerts(payload)
        .pipe(map(data => new TagsActions.GetTagsAlertsActionCompleted(data)))
        .catch(err => of(new TagsActions.GetTagsAlertsActionFailed(err)));
    }),
  );

  @Effect()
  public setTagErrorVolume$: Observable<Action> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.GET_TAG_ERROR_VOLUME),
    switchMap((action: { type: string; payload: ITagWithCompareBaseRequest }) => {
      const { tag, compareTag } = action.payload;
      const { startTime, endTime } = tag;
      const diff = endTime - startTime;

      const _3hours = 1000 * 60 * 60 * 3;
      const _1hour = 1000 * 60 * 60;
      const _1minute = 1000 * 60;

      const step = diff < _3hours ? _1minute : _1hour;

      const payload: ITagWithCompareBaseRequest = {
        ...action.payload,
        tag: { ...tag, step },
        compareTag: { ...compareTag, step },
      };

      return this.tagService
        .getTagErrorVolume(payload)
        .pipe(map(data => new TagsActions.GetTagErrorVolumeActionCompleted(data)))
        .catch(err => of(new TagsActions.GetTagErrorVolumeActionFailed(err)));
    }),
  );

  @Effect()
  public setSelectedTagNCompareRanges$: Observable<Action> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.SET_TAG_N_COMPARE_TAG_PAYLOAD_BASE),
    withLatestFrom([this.actions$.pipe(ofType(TagsActions.ActionTypes.SELECT_TAG))]),
    switchMap((action: any) => {
      return this.compareService.getComparePayload().pipe(
        filter(data => !!data?.tag && !!data?.tag?.startTime),
        map((payload: ITagWithCompareBaseRequest) => new TagsActions.SetTagNCompareBasePayloadCompleteAction(payload)),
      );
    }),
  );

  @Effect({ dispatch: false })
  public updateCompareView: Observable<any> = this.actions$.pipe(
    ofType(TagsActions.ActionTypes.SET_TAG_N_COMPARE_TAG_PAYLOAD_BASE_COMPLETE),
    tap(action => {
      this.store.dispatch(new TagsActions.GetTagSummaryAction(action.payload));
      this.store.dispatch(new TagsActions.GetTagsAlertsAction(action.payload));
      this.store.dispatch(new TagsActions.GetTagErrorVolumeAction(action.payload));
    }),
  );

  constructor(
    private actions$: Actions,
    private tagService: DeploymentService,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store<State>,
    private router: Router,
    private compareService: TagsCompareService,
    private userSettingsProvider: UserSettingsProvider,
  ) {}

  private redirectToUpdatedRoute(): void {
    combineLatest([this.store.select(getSelectedTag), this.store.select(getSelectedTagForCompare)])
      .take(1)
      .subscribe(([tag, compare]) => {
        const compareId = !!compare?.id ? compare.id : DefaultCompareEnd;
        const redirectRoute = getTagRedirectUrl(tag?.id, compareId);
        this.router.navigate([redirectRoute]).finally();
      });
  }

  private filterBuildByMinRetentionDate(builds: Build[]): Build[] {
    const retentionMinDate = subDays(new Date(), this.retention).valueOf();
    return builds.filter(build => (retentionMinDate ? build?.tag_timestamp > retentionMinDate : false));
  }
}
