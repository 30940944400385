import { Constants } from '../../../constants';
import { Observable } from 'rxjs';
import { getNonSelectedLogsFilterGridColumns, getSelectedLogsFilterGridColumns, State } from '@app/app.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class RecentQueriesService {
  public cacheSize: number;
  public visibleSize: number;
  public recentSearchOptions: string[];
  public jsonFieldOptions: string[];
  public filterGridColumns$: Observable<any>;

  constructor(private store: Store<State>) {
    this.cacheSize = Constants.CACHE_MAX_SIZE;
    this.visibleSize = Constants.SEARCH_VISIBLE_QUERIES_AMOUNT;

    const recent = window.localStorage.getItem(Constants.LOCAL_STORAGE_KEY);
    if (recent) {
      this.recentSearchOptions = JSON.parse(recent);
    } else {
      this.recentSearchOptions = [];
    }

    this.filterGridColumns$ = Observable.zip(
      this.store.select(getNonSelectedLogsFilterGridColumns),
      this.store.select(getSelectedLogsFilterGridColumns),
    ).map(([nonSelectedFields, selectedFields]) => {
      const nonSelectedFieldsResult = nonSelectedFields ? nonSelectedFields.map((f) => f.colId) : [];
      const selectedFieldsResult = selectedFields ? selectedFields.map((f) => f.colId) : [];
      return [...nonSelectedFieldsResult, ...selectedFieldsResult];
    });

    this.filterGridColumns$.subscribe((fields) => {
      this.jsonFieldOptions = fields;
    });
  }

  public getRecents(searchTerm: string): string[] {
    const lowerCaseTerm = searchTerm.toLowerCase();

    return this.recentSearchOptions
      .filter((term) => term.toLowerCase().includes(lowerCaseTerm))
      .slice(0, this.visibleSize);
  }

  public getJsonFieldsOptions(searchTerm: string): string[] {
    const lowerCaseTerm = searchTerm.toLowerCase();

    if (searchTerm === '') {
      return [];
    }
    return this.jsonFieldOptions
      .filter((term) => term.toLowerCase().includes(lowerCaseTerm))
      .slice(0, this.visibleSize);
  }

  public update(term: string) {
    if (term !== '') {
      // removing the term from the middle of the array if exists
      const termIndex = this.recentSearchOptions.indexOf(term);
      if (termIndex >= 0) {
        this.recentSearchOptions.splice(termIndex, 1);
      }

      // inserting the term on the top of the array
      this.recentSearchOptions.unshift(term);

      // trimming down the array to the max size of the cache
      this.recentSearchOptions = this.recentSearchOptions.slice(0, this.cacheSize);

      // string the array in local storage
      window.localStorage.setItem(Constants.LOCAL_STORAGE_KEY, JSON.stringify(this.recentSearchOptions));
    }
  }
}
