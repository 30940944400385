import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sh-grid-paging',
  templateUrl: './grid-paging.component.html',
  styleUrls: ['./grid-paging.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class GridPagingComponent implements OnInit, AfterViewInit {

  @Input() public paginationControll: any;
  @Input() public currentPage: number;
  @Input() public totalPages: number;
  constructor() { }

  public ngOnInit() {
  }
  public ngAfterViewInit() {

  }
  public pageClicked(e) {
    this.paginationControll.currentPage = e;
    this.paginationControll.loadPage();
    this.currentPage = this.paginationControll.currentPage;
    this.scrollToElement();
    setTimeout(() => {
      this.scrollToElement();
    }, 300);
  }
  public NextPrevClicked(e?: any) {
    if (e) {
      e.click();
      this.currentPage = this.paginationControll.currentPage;
      this.scrollToElement();
    }
  }
  public scrollToElement() {
    const currentElement = document.getElementById('pageButton' + this.paginationControll.currentPage);
    if (currentElement) {
      currentElement.parentElement.parentElement.scrollLeft = currentElement.offsetLeft - 100 - currentElement.clientWidth * 3;
    }
  }
}
