import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { FieldVisualizationComponent } from './field-visualization.component';
import { MatIconModule } from '@angular/material/icon';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClickOutsideModule } from '@shared/directives/click-outside.module';
import { StatisticsModule } from '@app/statistics/statistics.module';
import { FormsModule } from '@angular/forms';
import { FieldVisualizationDialogComponent } from '@app/logs-new/shared/features/field-visualization/field-visualization-dialog/field-visualization-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [FieldVisualizationComponent, FieldVisualizationDialogComponent],
  imports: [
    CommonModule,
    HighchartsChartModule,
    MatIconModule,
    DirectivesModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatProgressBarModule,
    ScrollingModule,
    ClickOutsideModule,
    MatTooltipModule,
    StatisticsModule,
    FormsModule,
    MatProgressSpinnerModule,
    AngularSvgIconModule,
  ],
  exports: [FieldVisualizationComponent, FieldVisualizationDialogComponent],
})
export class FieldVisualizationModule {}
