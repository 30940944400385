import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownPosition } from '@ng-select/ng-select';

@Component({
    selector: 'sh-coralogix-select',
    templateUrl: 'coralogix-select.component.html',
    styleUrls: ['coralogix-select.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CoralogixSelectComponent,
        multi: true
    }],
})
export class CoralogixSelectComponent implements ControlValueAccessor, AfterContentInit {
    @Input()
    set selectOptions(options: any[]) {
        this.options = options;
        this.shouldLoadVirtually = options?.length > 100 ? true : false;
    }
    @Input()
    public bindLabel: string;
    @Input()
    public bindValue: string;
    @Input()
    public multiple: boolean;
    @Input()
    public searchable: boolean;
    @Input()
    public clearable: boolean;
    @Input()
    public disableSelectAll: boolean;
    @Input()
    public allSelectedLabel: string = 'All';
    @Input()
    public dropDownPosition: DropdownPosition = 'auto';
    @Input()
    public hasColoredIcon: boolean;
    @Input()
    public hasIconBefore: boolean;
    @Input()
    public placeholder: string;
    @Input()
    public hideValue: boolean;
    @Input()
    public maximumSelection: number;
    @Input()
    public groupBy: () => void;

    @Input()
    public appendTo: string = '';
    @Input()
    public hideSelected: boolean = true;
    @Input()
    public tagMode: boolean = false;

    @Input()
    public backgroundColor: string;

    @Input()
    public optionsTooltip: {[id: number]: string};

    @Output()
    public focus: EventEmitter<Event> = new EventEmitter();

    public options: any[] = [];
    public shouldLoadVirtually: boolean;
    public value: any;
    public isAllSelected: boolean;
    public disabled: boolean;
    public showOptionTooltip: boolean = false;

    private onChangeFn: (val: any) => any;
    private onTouchedFn: (val?: any) => any;

    constructor(private elRef: ElementRef) {}

    public ngAfterContentInit(): void {
      this.optionsTooltip ? this.setToolTipStyle(0, 1) : this.setToolTipStyle(0, 1050);
    }

  public onClose(): void {
        this.onTouchedFn();
    }

    public toggleSelectAll(isChecked: boolean): void {
        isChecked ? this.selectAllOptions() : this.deselectAllOptions();
    }

    public registerOnChange(fn: (val: any) => any): void {
        this.onChangeFn = fn;
    }

    public registerOnTouched(fn: (val: any) => any): void {
        this.onTouchedFn = fn;
    }

    public onModelChange(value: any): void {
        this.value = value;
        if (this.onChangeFn) {
            this.onChangeFn(value);
        }
    }

    public writeValue(value: any): void {
        if (value && value.length === 0 && !this.disableSelectAll) {
            this.selectAllOptions();
        }
        this.value = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onFocus(event: Event): void {
        this.focus.emit(event);
    }

    private selectAllOptions(): void {
        const selectedOptions = [];
        this.isAllSelected = true;
        this.disabled = true;
        this.onModelChange(selectedOptions);
    }

    private deselectAllOptions(): void {
        const selectedOptions = null;
        this.isAllSelected = false;
        this.disabled = false;
        this.onModelChange(selectedOptions);
    }

    private setToolTipStyle(containerZindex: number, dpZindex: number): void {
      this.elRef.nativeElement.style.setProperty('--ng-select-container-z-index', containerZindex);
      this.elRef.nativeElement.style.setProperty('--ng-select-dropdown-container-z-index', dpZindex);
    }
}
