import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AlertModel } from '../shared/models/alert-model';
import { Observable, Subject } from 'rxjs';
import { Insight } from '@app/insights/shared/models/insight.model';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { UserSettings } from '@app/user/shared/user.settings';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getAlerts,
  getAlertsGraphLoading,
  getAlertsInsights,
  getAlertsTableLoading,
  getTeammates,
  State,
} from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { TeamUsersActions } from '@app/user/state/teams/team-users.actions';
import { AlertsNewUrl } from '@app/alerts/shared/models/alerts.consts';
import {
  getAlertsAction,
  getAlertsInsightsAction,
  updateAlertGraphLoadingAction,
  updateAlertTableLoadingAction,
} from '@app/alerts/shared/actions/alerts.actions';
import { FormControl } from '@angular/forms';
import { defaultInsightTime } from '../alerts-table/alerts-insights.model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetTeammate } from '@app/ngxs-store/user/user.action';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Teammate } from '@app/user/shared/models/teammate';
import { Teammate as NewTeammate } from '@app/shared/models/teammate.model';

@Component({
  selector: 'sh-alerts-details-feed',
  templateUrl: './alerts-details-feed.component.html',
  styleUrls: ['./alerts-details-feed.component.scss'],
})
export class AlertsDetailsFeedComponent implements OnInit, OnDestroy {
  @Select(UserState.user) public user$: Observable<NewTeammate>;

  @Output() public openNewAlert: EventEmitter<void> = new EventEmitter<void>();

  @Output() public openEditAlert: EventEmitter<AlertModel> = new EventEmitter<AlertModel>();

  public alerts: AlertModel[] = [];

  public filteredAlerts: AlertModel[];

  public filteredInsights: Insight[];

  public users$: Observable<Teammate[]>;

  public insightsTime = new FormControl(defaultInsightTime);

  public alerts$ = this.store.select(getAlerts);

  public insights$ = this.store.select(getAlertsInsights);

  public isLoadingAlertsGraph$ = this.store.select(getAlertsGraphLoading);

  public isLoadingAlertsTable$ = this.store.select(getAlertsTableLoading);

  private insights: Insight[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private userSettingsProvider: UserSettingsProvider,
    private activatedRoute: ActivatedRoute,
    private store: Store<State>,
    private router: Router,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.getTeammate();
    this.users$ = this.getTeammatesList();
    this.loadAlerts();
    this.onAlertsChange();
    this.onInsightsChange();
    this.onUserSettingsChanged();
    this.onInsightsQueryChange();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openAlertsEditor(isEdit: boolean, alert?: AlertModel): void {
    isEdit ? this.openEditAlert.emit(alert) : this.openNewAlert.emit();
  }

  public loadAlerts(): void {
    this.store.dispatch(getAlertsAction());
    this.store.dispatch(getAlertsInsightsAction({ payload: this.insightsTime.value }));
  }

  public updateAlertsAndInsightsFilters(): void {
    if (this.userSettingsProvider.userSettings?.queryMetadata) {
      const selectedApplications = this.userSettingsProvider.userSettings.queryMetadata.applicationName;
      const selectedSubsystems = this.userSettingsProvider.userSettings.queryMetadata.subsystemName;
      this.updateFilters(selectedApplications, selectedSubsystems);
    }
  }

  private onInsightsQueryChange(): void {
    this.insightsTime.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((insightsTime) => {
      this.store.dispatch(getAlertsInsightsAction({ payload: insightsTime }));
    });
  }

  private onAlertsChange(): void {
    this.alerts$
      .pipe(
        filter((alertsList) => Array.isArray(alertsList)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((alertsList) => {
        this.alerts = alertsList;
        this.store.dispatch(updateAlertTableLoadingAction({ payload: false }));
        this.updateAlertsAndInsightsFilters();
        this.checkUrlAlert();
      });
  }

  private onInsightsChange(): void {
    this.insights$
      .pipe(
        filter((insights) => Array.isArray(insights)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((insights) => {
        this.insights = insights;
        this.updateAlertsAndInsightsFilters();
        this.store.dispatch(updateAlertGraphLoadingAction({ payload: false }));
      });
  }

  private updateFilters(applicationsFilter: string[], subsystemsFilter: string[]): void {
    let filteredAlerts = [];

    if (!applicationsFilter.length && !subsystemsFilter.length) {
      this.filteredAlerts = this.alerts.slice();
      this.filteredInsights = this.insights.slice();
      return;
    }

    if (applicationsFilter.length) {
      filteredAlerts = this.alerts?.filter(
        (alert) => alert.applicationName?.filter((app) => -1 !== applicationsFilter.indexOf(app)).length,
      );
    }

    if (subsystemsFilter.length) {
      if (applicationsFilter.length) {
        filteredAlerts = filteredAlerts?.filter(
          (alert) => alert?.subsystemName?.filter((subsystem) => -1 !== subsystemsFilter.indexOf(subsystem)).length,
        );
      } else {
        filteredAlerts = this.alerts?.filter(
          (alert) => alert?.subsystemName?.filter((subsystem) => -1 !== subsystemsFilter.indexOf(subsystem)).length,
        );
      }
    }

    this.filteredAlerts = filteredAlerts;
    const filteredAlertsIds = this.filteredAlerts?.map((alert) => alert.id);
    this.filteredInsights = this.insights?.filter((event) => filteredAlertsIds?.includes(event.alertId));
  }

  private checkUrlAlert(): void {
    const currentId = this.activatedRoute.snapshot.paramMap.get('id');
    const alertModel = this.alerts?.find((alert) => alert.id === currentId);
    if (alertModel) {
      this.openEditAlert.emit(alertModel);
    } else if (this.router.url.includes(AlertsNewUrl)) {
      this.openNewAlert.emit();
    }
  }

  private onUserSettingsChanged(): void {
    this.userSettingsProvider.userSettingsChanged
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((settings: UserSettings) => {
        this.updateFilters(settings.queryMetadata.applicationName, settings.queryMetadata.subsystemName);
      });
  }

  private getTeammatesList(): Observable<Teammate[]> {
    this.store.dispatch(new TeamUsersActions.LoadAction());
    return this.store.select(getTeammates);
  }

  @Dispatch() private getTeammate = () => new GetTeammate();
}
