import { Injectable } from '@angular/core';
import { ShDialogService } from '@app/shared/services/dialogService';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { PolicyCriteria } from '@app/tco/models/policy-criteria.model';
import {
    AddNewPolicyMode,
    CancelEditPolicy,
    CancelNewPolicyMode,
    ChangePolicyOrder,
    CreatePolicyCriteria,
    DeletePolicy,
    Destroy,
    EditPolicy,
    GetQuotaPolicies,
    Loading,
    SaveEditPolicy,
    SetLoadingPolicyId,
    SetPriority
} from './quota-policy.action';
import { catchError, finalize, map } from 'rxjs/operators';
import { QuotaPolicyService } from '@app/tco/services/quota-policy/quota-policy.service';
import { Observable, of } from 'rxjs';
import { IPolicyCriteriaSerialized } from '@app/tco/serialized/policy.criteria.serialized';
import { GetOverrides } from '../quota-policy-overrides/quota-policy-overrides.action';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';

export interface IQuotaPolicyStateModel {
    policies: { [id: string]: PolicyCriteria };
    policyIds: string[];
    addNewPolicyMode: boolean;
    loading: boolean;
    expandedElement: PolicyCriteria;
    editedPolicyCopy: PolicyCriteria;
    loadingPolicyId: string;
}

const initialState = (): IQuotaPolicyStateModel => {
    return {
        policies: {},
        policyIds: [],
        addNewPolicyMode: false,
        loading: false,
        expandedElement: null,
        editedPolicyCopy: null,
        loadingPolicyId: null
    };
};

@State<IQuotaPolicyStateModel>({
    name: 'quotaPolicy',
    defaults: initialState()
})
@Injectable()
export class QuotaPolicyState {

    constructor(
        private dialogService: ShDialogService,
        private quotaPolicyService: QuotaPolicyService,
        public store: Store
    ) {
    }

    @Selector()
    public static policies(state: IQuotaPolicyStateModel): { [id: string]: PolicyCriteria } {
        return state?.policies;
    }

    @Selector()
    public static policyIds(state: IQuotaPolicyStateModel): string[] {
        return state?.policyIds;
    }

    @Selector()
    public static addNewPolicyMode(state: IQuotaPolicyStateModel): boolean {
        return state.addNewPolicyMode;
    }

    @Selector()
    public static loadingPolicyId(state: IQuotaPolicyStateModel): string {
        return state.loadingPolicyId;
    }

    @Selector()
    public static loading(state: IQuotaPolicyStateModel): boolean {
        return state?.loading;
    }

    @Selector()
    public static expandedElement(state: IQuotaPolicyStateModel): PolicyCriteria {
        return state?.expandedElement;
    }

    @Selector()
    public static editedPolicyCopy(state: IQuotaPolicyStateModel): PolicyCriteria {
        return state?.editedPolicyCopy;
    }

    @Selector([QuotaPolicyState.policies, QuotaPolicyState.policyIds])
    public static policyList(policies: { [id: string]: PolicyCriteria }, policyIds: string[]): PolicyCriteria[] {
        return policyIds.map(id => policies[id]);
    }

    @Action(Destroy)
    public Destroy(ctx: StateContext<IQuotaPolicyStateModel>): IQuotaPolicyStateModel {
        return ctx.setState(initialState());
    }

    @Action(Loading)
    public Loading(ctx: StateContext<IQuotaPolicyStateModel>, { bool }: Loading): IQuotaPolicyStateModel {
        return ctx.patchState({ loading: bool });
    }

    @Action(GetQuotaPolicies)
    public GetQuotaPolicies(ctx: StateContext<IQuotaPolicyStateModel>): Observable<IQuotaPolicyStateModel> {
        ctx.patchState({ loading: true });

        return this.quotaPolicyService.getPolicies().pipe(
            map(policyList => {
                const policyIds = policyList.map(policy => policy.id);
                const policies = policyList.reduce((acc, curr) => {
                    acc[curr.id] = curr;
                    return acc;
                }, {});

                return ctx.patchState({ policies, policyIds });
            }),
            finalize(() => {
                ctx.patchState({ loading: false });

            })
        );
    }

    @Action(AddNewPolicyMode)
    public AddNewPolicyMode(ctx: StateContext<IQuotaPolicyStateModel>): IQuotaPolicyStateModel {
        return ctx.patchState({ addNewPolicyMode: true });
    }

    @Action(EditPolicy)
    public EditPolicy(ctx: StateContext<IQuotaPolicyStateModel>, { policy }: EditPolicy): IQuotaPolicyStateModel {
        return ctx.patchState({ expandedElement: policy, editedPolicyCopy: new PolicyCriteria(policy) });
    }

    @Action(SaveEditPolicy)
    public SaveEditPolicy(ctx: StateContext<IQuotaPolicyStateModel>, { policy }: SaveEditPolicy): any {
        const body: IPolicyCriteriaSerialized = PolicyCriteria.serializer(policy);
        ctx.patchState({ loading: true });
        return this.quotaPolicyService.editPolicy(body, policy.id).pipe(
            map(newPolicy => {
                const { policies, policyIds } = ctx.getState();
                const index = policyIds.findIndex(id => id === policy.id);
                const newPolicyIds = [...policyIds.slice(0, index), newPolicy.id, ...policyIds.slice(index + 1, policyIds.length)];
                const newPolicies = { ...policies, [newPolicy.id]: newPolicy };

                ctx.dispatch(new GetOverrides());
                return ctx.patchState({ policies: newPolicies, policyIds: newPolicyIds, expandedElement: null, editedPolicyCopy: null });
            }),
            catchError((httpErr) => {
                if (httpErr?.error?.message) {
                    this.dialogService.showCoralogixMessage(httpErr?.error?.message, null, dialogServiceIconClasses.failed);
                }
                return of(null);
            }),
            finalize(() => {
                ctx.dispatch(new Loading(false));
            })
        );
    }

    @Action(CancelEditPolicy)
    public CancelEditPolicy(ctx: StateContext<IQuotaPolicyStateModel>): IQuotaPolicyStateModel {
        return ctx.patchState({ expandedElement: null, editedPolicyCopy: null });
    }

    @Action(CancelNewPolicyMode)
    public CancelNewPolicyMode(ctx: StateContext<IQuotaPolicyStateModel>): IQuotaPolicyStateModel {
        return ctx.patchState({ addNewPolicyMode: false });
    }

    @Action(DeletePolicy)
    public DeletePolicy(ctx: StateContext<IQuotaPolicyStateModel>, { id }: DeletePolicy): Observable<IQuotaPolicyStateModel> {
        ctx.dispatch(new Loading(true));
        return this.quotaPolicyService.deletePolicies(id).pipe(
            map(() => {
                const { policies, policyIds } = ctx.getState();
                const newPolicies = { ...policies };
                delete newPolicies[id];
                const index = policyIds.findIndex(policyId => policyId === id);
                const newPolicyIds = [...policyIds.slice(0, index), ...policyIds.slice(index + 1, policyIds.length)];
                ctx.dispatch(new GetOverrides());
                return ctx.patchState({ policies: newPolicies, policyIds: newPolicyIds });
            }),
            catchError((err: any) => {
                this.dialogService.showCoralogixMessage('ERROR: Failed to delete the policy', null, dialogServiceIconClasses.failed);
                return of(null);
            }),
            finalize(() => {
                ctx.dispatch(new Loading(false));
            })
        );
    }

    @Action(CreatePolicyCriteria)
    public CreatePolicyCriteria(
        ctx: StateContext<IQuotaPolicyStateModel>,
        payload: CreatePolicyCriteria): Observable<IQuotaPolicyStateModel> {
        const { applicationCondition, applicationString, subsystemCondition, subsystemString, severities, priority, name } = payload;

        const policy: PolicyCriteria = new PolicyCriteria({
            id: undefined,
            name,
            applicationCondition,
            applicationString,
            subsystemCondition,
            subsystemString,
            severities,
            priority
        });

        const body = PolicyCriteria.serializer(policy);

        ctx.dispatch(new Loading(true));

        return this.quotaPolicyService.createPolicy(body).pipe(
            map(newPolicy => {
                const policies = { ...ctx.getState().policies };
                policies[newPolicy.id] = newPolicy;

                const policyIds = [...ctx.getState().policyIds, newPolicy.id];
                ctx.dispatch(new GetOverrides());
                return ctx.patchState({ policies, policyIds, addNewPolicyMode: false });
            }),
            catchError((httpErr) => {
                if (httpErr?.error?.message) {
                    this.dialogService.showCoralogixMessage(httpErr?.error?.message, null, dialogServiceIconClasses.failed);
                }
                return of(null);
            }),
            finalize(() => {
                ctx.dispatch(new Loading(false));
            })
        );
    }

    @Action(SetPriority)
    public SetPriority(ctx: StateContext<IQuotaPolicyStateModel>, { priority, policyId }: SetPriority): Observable<any> {
        const { policies, policyIds } = ctx.getState();
        const policy = new PolicyCriteria({ ...policies[policyId], priority });
        const body: IPolicyCriteriaSerialized = PolicyCriteria.serializer(policy);
        ctx.patchState({ loading: true, loadingPolicyId: policyId });
        return this.quotaPolicyService.editPolicy(body, policy.id).pipe(
            map(newPolicy => {
                const index = policyIds.findIndex(id => id === policy.id);
                const newPolicyIds = [...policyIds.slice(0, index), newPolicy.id, ...policyIds.slice(index + 1, policyIds.length)];
                const newPolicies = { ...policies, [newPolicy.id]: newPolicy };
                ctx.dispatch(new GetOverrides());
                return ctx.patchState({ policies: newPolicies, policyIds: newPolicyIds });
            }),
            catchError((err: any) => {
                ctx.patchState({ policies: { ...policies, [policyId]: new PolicyCriteria({ ...policies[policyId] }) } });
                this.dialogService.showCoralogixMessage('ERROR: Failed to change priority', null, dialogServiceIconClasses.failed);
                return of(null);
            }),
            finalize(() => {
                ctx.patchState({ loading: false, loadingPolicyId: null });
            })
        );
    }

    @Action(ChangePolicyOrder)
    public ChangePolicyOrder(
        ctx: StateContext<IQuotaPolicyStateModel>, { previousIndex, currentIndex }: ChangePolicyOrder): Observable<IQuotaPolicyStateModel> {
        const { policyIds } = ctx.getState();
        let newPolicyIds: string[];
        if (previousIndex === currentIndex) {
            return of(ctx.getState());
        }
        if (previousIndex < currentIndex) {
            newPolicyIds =
                [
                    ...policyIds.slice(0, previousIndex),
                    ...policyIds.slice(previousIndex + 1, currentIndex + 1),
                    policyIds[previousIndex],
                    ...policyIds.slice(currentIndex + 1, policyIds.length),
                ];
        } else {
            newPolicyIds = [
                ...policyIds.slice(0, currentIndex),
                policyIds[previousIndex],
                ...policyIds.slice(currentIndex, previousIndex),
                ...policyIds.slice(previousIndex + 1, policyIds.length),

            ];
        }

        return this.quotaPolicyService.reorder(newPolicyIds).pipe(
            map(() => {
                ctx.dispatch(new GetOverrides());
                return ctx.patchState({ policyIds: newPolicyIds });
            }),
            catchError((err: any) => {
                ctx.patchState({ policyIds: [...policyIds] });
                this.dialogService.showCoralogixMessage('ERROR: Failed to save the policy', null, dialogServiceIconClasses.failed);
                return of(null);
            }),
            finalize(() => {
                ctx.dispatch(new Loading(false));
            })
        );
    }

    @Action(SetLoadingPolicyId)
    public SetLoadingPolicyId(
        ctx: StateContext<IQuotaPolicyStateModel>, { policyId }: SetLoadingPolicyId): IQuotaPolicyStateModel {
        return ctx.patchState({ loadingPolicyId: policyId });
    }
}
