import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'sh-resize-handler',
  templateUrl: './resize-handler.component.html',
  styleUrls: ['resize-handler.component.scss'],
})
export class ResizeHandlerComponent {

  @Output()
  public dragged: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public dragEnd: EventEmitter<number> = new EventEmitter<number>();

  private isResizerClicked: boolean = false;

  @HostListener('window:mousemove', ['$event'])
  public onDrag(event: MouseEvent): void {
    if (this.isResizerClicked) {
      this.dragged.emit(event.clientX);
    }
  }

  @HostListener('window:mouseup')
  public onMouseUp(): void {
    if (this.isResizerClicked) {
      this.isResizerClicked = false;
      this.dragEnd.emit();
    }
  }

  public onMouseDown(): void {
    this.isResizerClicked = true;
  }
}
