import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'sh-team-item',
  templateUrl: './team-item.component.html',
  styleUrls: ['./team-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamItemComponent {
  @Input() name = '';

  @Input() isSigned = false;

  @Input() searchTerm = '';
}
