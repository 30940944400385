import nunjucks from 'nunjucks';

export interface INunjucksToken {
  line: number;
  start: number;
  end: number;
}

export class Nunjucks {
  static renderString(url: string, ctx: object): string {
    return nunjucks.renderString(url, ctx);
  }

  static tokenList(url: string): INunjucksToken[] {
    const tokenizer = nunjucks['lexer'].lex(url);
    const list = [];
    let token;
    do {
      token = tokenizer.nextToken();
      if (token) {
        list.push(token);
      }
    } while (token);

    let i: number;
    let j: number;
    const res: INunjucksToken[] = [];
    for (i = 0; i < list.length; i++) {
      token = list[i];
      if (token.type === nunjucks['lexer'].TOKEN_VARIABLE_START) {
        const nextToken = list[i + 1];
        if (!nextToken) {
          continue;
        }

        if (nextToken.type === nunjucks['lexer'].TOKEN_VARIABLE_END) {
          res.push({
            line: nextToken.lineno,
            start: nextToken.colno,
            end: nextToken.colno,
          });

          i = i + 1;
        }
        continue;
      }
      if (!(token.type === nunjucks['lexer'].TOKEN_SYMBOL)) {
        continue;
      }
      let counter = 0;
      let innerToken;

      for (j = i + 1; j < list.length; j++) {
        innerToken = list[j];
        if (!innerToken) {
          break;
        }
        if (innerToken.lineno !== token.lineno) {
          break;
        }
        if (counter % 2 === 0) {
          if (!(innerToken.type === nunjucks['lexer'].TOKEN_OPERATOR && innerToken.value === '.')) {
            break;
          }
        } else {
          if (innerToken.type !== nunjucks['lexer'].TOKEN_SYMBOL) {
            break;
          }
        }
        counter++;
      }
      let k = i;
      let length = 0;
      while (k < j) {
        length += list[k].value.length;
        k++;
      }
      res.push({
        line: token.lineno,
        start: token.colno,
        end: token.colno + length,
      });

      i = j - 1;
    }
    return res;
  }

  static isInside(url: string, selectionStart: number, selectionEnd: number): boolean {
    const token = Nunjucks.getCurrentToken(url, selectionStart, selectionEnd);
    return !!token;
  }

  static getCurrentToken(url: string, selectionStart: number, selectionEnd: number): INunjucksToken {
    const tokenList = Nunjucks.tokenList(url);

    for (const token of tokenList) {
      if (selectionStart >= token.start && selectionEnd <= token.end) {
        return token;
      }
    }
    return null;
  }

  static getCurrentTokenValue(url: string, selectionStart: number, selectionEnd: number): string {
    const token = Nunjucks.getCurrentToken(url, selectionStart, selectionEnd);
    if (!token) {
      return null;
    }
    return url.substring(token.start, token.end);
  }
}
