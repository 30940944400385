import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalRatio',
})
export class NormalRatioPipe implements PipeTransform {
  public transform(value: any, args?: number): any {
    let factor = args;
    factor = factor ? factor : 10000;
    const result = value ? Math.round(value * factor) / 100 : 0;
    if (result > 1000) {
      return 'Over 1000';
    }

    return value ? Math.round(value * factor) / 100 : 0;
  }
}
