import { Component } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'sh-saml-sso',
  templateUrl: './saml-sso.component.html',
  styleUrls: ['./saml-sso.component.scss'],
})
export class SamlSsoComponent {
  constructor(authService: AuthService) {
    authService.getSamlSsoLoginUrl().subscribe((res: any) => {
      window.location.href = res;
    });
  }
}
