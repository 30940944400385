import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { ReindexEditorControls } from '@app/roi/reindex/models/reindex-editor-controls';
import moment from 'moment';

@Injectable()
export class ReindexEditorForm {
  public get defaultValues(): ReindexEditorControls {
    return new ReindexEditorControls({
      name: '',
      description: '',
      applicationName: null,
      subsystem: null,
      severities: null,
      text: null,
      from: new Date(),
      startTime: '00:00',
      endTime: 1,
    });
  }
  constructor(private fb: FormBuilder) {}

  public buildForm(value: ReindexEditorControls): FormGroup {
    return this.fb.group({
      details: this.buildDetailsGroup(value),
      filters: this.buildFilterGroup(value),
    });
  }

  private buildDetailsGroup(value: ReindexEditorControls): FormGroup {
    return this.fb.group({
      name: [value.name, Validators.required],
      description: [value.description],
    });
  }

  private buildFilterGroup(value: ReindexEditorControls): FormGroup {
    return this.fb.group({
      applicationName: [value.applicationName],
      subsystemName: [value.subsystem],
      logSeverity: [value.severities],
      text: value.text,
      from: value.from,
      startTime: value.startTime,
      endTime: value.endTime,
    });
  }
}
