import { Component, Input } from '@angular/core';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'sh-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
})
export class MobileNavbarComponent {
  @Select(UserState.username) public username$: Observable<string>;

  @Input() public isUserHasAlertPermissions: boolean;
  @Input() public showUserSettingsFilters: boolean;

  public get isSmallScreen(): boolean {
    return window.screen.width < 500;
  }
}
