import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-rules-limit-notification',
  templateUrl: './rules-limit-notification.component.html',
  styleUrls: ['./rules-limit-notification.component.scss'],
})
export class RulesLimitNotificationComponent {
  @Input() rulesLeft: number;
}
