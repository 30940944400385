import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as moment from 'moment';

@jsonObject
export class Reindex {
  @jsonMember
  public id: string;
  @jsonMember
  public name: string = '';
  @jsonMember
  public description: string;
  @jsonArrayMember(String)
  public applicationName: string[];
  @jsonArrayMember(String)
  public subsystem: string[];
  @jsonArrayMember(Number)
  public severity: number[];
  @jsonMember
  public text: string;
  @jsonMember
  public createBy: string;
  @jsonMember
  public createDate: string;
  @jsonMember
  public from: string;
  @jsonMember
  public startTime: string;
  @jsonMember
  public endTime: string;
  @jsonMember
  public status: string;
  @jsonMember
  public progressPercent: number;
}

@jsonObject
export class Estimation {
  @jsonMember
  public estimation: number;
}

export class ReindexS3BucketRequest {
  public bucket: string = '';
  public region: string;

  constructor(init?: Partial<ReindexS3BucketRequest>) {
    Object.assign(this, init);
  }
}

export class ReindexRequest {
  public name: string = '';
  public description: string;
  public applicationName: string[];
  public subsystem: string[];
  public severity: number[];
  public startTime: moment.Moment;
  public endTime: moment.Moment;
  public text: string = null;

  constructor(init?: Partial<ReindexRequest>) {
    Object.assign(this, init);
  }
}

@jsonObject
export class ReindexResponse {
  @jsonArrayMember(Reindex)
  public reindexes: Reindex[];
}

export class RetryResponse {
  public failedReindexId: string;
  public newReindex: Reindex;

  constructor(failedId: string, newReindex: Reindex) {
    this.failedReindexId = failedId;
    this.newReindex = newReindex;
  }
}

@jsonObject
export class CreateCachedQueryResponse {
  @jsonMember
  public queryId: string;
}

@jsonObject
export class ReindexBucketPermission {
  @jsonMember
  public hasPermission: boolean;
  @jsonMember
  public msg: string;
  @jsonMember
  public err: string;
}
