<div class="sidebar" [class.in]="isOpen" [class.full]="isFull">
  <div class="header">
    <i class="icon remove" (click)="toggleOpen()"></i>
    Info-Panel
    <span class="action" (click)="toggleFullScreen()">
      <i class="fa" [ngClass]="isFull ? 'fa-compress' : 'fa-expand'"></i>
    </span>
  </div>
  <div class="content" *ngIf="selectedLog">
    <div class="sub-header" style="margin-left: -8px">
      <span *ngIf="isJson">
        <mat-button-toggle-group
          class="toggle-group"
          (change)="toggleShowRawData($event)"
        >
          <mat-button-toggle [value]="false" [checked]="!showRawData">
            JSON
          </mat-button-toggle>
          <mat-button-toggle [value]="true" [checked]="showRawData">
            RAW
          </mat-button-toggle>
        </mat-button-toggle-group>
      </span>
      <span *ngIf="!isJson" class="title">Text</span>
    </div>

    <div class="text-container">
      <div
        *ngIf="!isJson || (isJson && showRawData)"
        class="text"
        [class.fullOut]="!isFull"
        [innerHTML]="logText | breakLinePipe"
      ></div>
      <div *ngIf="isJson && !showRawData">
        <sh-json-viewer
          [jsonObject]="logJson"
          (jsonKeyClick)="jsonKeyClicked($event)"
          [log]="selectedLog"
          (onShowWidget)="showJsonWidget($event)"
          [hideJsonParams]="hideJsonParams"
          [isShowWidgetEnabled]="isShowJsonWidgetEnabled"
          [isFiltersEnabled]="isFiltersEnabled && !isTemplate"
        ></sh-json-viewer>
      </div>
    </div>
    <sh-logs-action-buttons
      [selectedLog]="selectedLog"
      (logActionPerformed)="logActionPerformed($event)"
      (saveAlert)="onSaveAlert($event)"
    ></sh-logs-action-buttons>

    <div class="sub-title">
      <span class="title">
        Details
      </span>
    </div>
    <table class="properties">
      <tr class="properties-row">
        <td class="key">Timestamp</td>
        <td class="value">{{ selectedLog?.timestamp | dateFormatByUserSettings : ampmBigLettersOption }}</td>
      </tr>
      <tr class="properties-row hover-highlight">
        <td class="key">Severity</td>
        <td
          class="value"
          [className]="selectedLog?.metadata.severity | lowercase"
        >
          {{ selectedLog?.metadata.severity }}
        </td>
        <sh-json-action-buttons
          class="metadata-actions"
          [isAddWidgetEnabled]="true"
          [isCopyToClipboardEnabled]="false"
          (onShowWidget)="showMetaDataWidget('severity')">
        </sh-json-action-buttons>
      </tr>
      <tr
        class="properties-row hover-highlight"
        *ngFor="let item of metadataTitles | keyvalue; let index = index, let length = count"
      >
          <td class="key">{{ item.value }}</td>
          <td class="value">{{ (selectedLog?.metadata)[item.key] }}</td>
          <sh-json-action-buttons
            class="metadata-actions"
            [isAddWidgetEnabled]="true"
            [isCopyToClipboardEnabled]="false"
            [tooltipPosition]="index < length - 2 ? 'bottom right' : 'top right'"
            (onShowWidget)="showMetaDataWidget(item.key)">
          </sh-json-action-buttons>
      </tr>
    </table>
  </div>
  <div *ngIf="!selectedLog" class="noItemSelected">
    <h2>No Item Selected</h2>
  </div>
</div>
<button
  class="ui icon button"
  (click)="toggleOpen()"
  [disabled]="!selectedLog"
  data-tooltip="Info Panel"
  data-position="bottom center"
>
  <i class="icon columns inverted"></i>
</button>
<sh-alerts-editor-container #alertEditor>
</sh-alerts-editor-container>
<sh-statistics-chart-modal></sh-statistics-chart-modal>
