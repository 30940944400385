import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArchivingProviderModel } from '../../../shared/models/archivingProviderModel';

@Component({
  selector: 'sh-archiving-providers-list',
  templateUrl: './archiving-providers-list.component.html',
  styleUrls: ['./archiving-providers-list.component.scss'],
})
export class ArchivingProvidersListComponent implements OnInit {
  @Input() public archivingProvidersList: ArchivingProviderModel[];
  @Input() public loading: boolean;
  @Output() public editArchivingProvider: EventEmitter<
    ArchivingProviderModel
  > = new EventEmitter<ArchivingProviderModel>();
  @Output() public deleteArchivingProvider: EventEmitter<
    ArchivingProviderModel
  > = new EventEmitter<ArchivingProviderModel>();

  constructor() {}

  public ngOnInit() {}
}
