import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class InviteRequestStatusModel {
  @jsonMember
  public email: string;
  @jsonMember
  public team_url: string;
  @jsonMember
  public status: string;
}
