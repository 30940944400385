import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { PromQLExtension } from 'codemirror-promql';
import { EditorView, placeholder, ViewUpdate } from '@codemirror/view';
import { EditorState, Extension } from '@codemirror/state';
import { _24HoursInMS } from '@shared/constants/times.constant';
import { MetricsService } from '@app/settings/shared/services/metrics.service';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { ConfigurationService } from '@app/services/configuration.service';
import { basicSetup } from '@codemirror/basic-setup';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HighlightStyle, tags } from '@codemirror/highlight';
import { AuthService } from '@app/auth/shared/services/auth.service';

@Component({
  selector: 'sh-promql-editor',
  templateUrl: './promql-editor.component.html',
  styleUrls: ['./promql-editor.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PromqlEditorComponent), multi: true }],
})
export class PromqlEditorComponent implements AfterViewInit, OnInit, ControlValueAccessor {
  @ViewChild('promQlEditorEl') promQlEditorEl: ElementRef;
  @Input() placeholder = '';
  esApiKeyValue = '';
  private _value = '';
  private codemirrorView: EditorView | null = null;
  private static promQLHighlightTheme = HighlightStyle.define([
    {
      tag: tags.invalid,
      color: '#ef4d7b',
    },
    {
      tag: tags.keyword,
      color: 'var(--cm-keyword)',
    },
    {
      tag: tags.operator,
      color: '#1782D1',
    },
    {
      tag: tags.atom,
      color: '#3CBBC4',
    },
    {
      tag: tags.number,
      color: '#14B287',
    },
    {
      tag: tags.string,
      color: '#216DEF',
    },
    {
      tag: tags.definition(tags.variableName),
      color: '#6AED9C',
    },
    {
      tag: tags.labelName,
      color: '#38BCF1',
    },
    {
      tag: tags.typeName,
      color: '#FEC52D',
    },
    {
      tag: tags.function(tags.variableName),
      color: '#A17CF1',
    },
    {
      tag: tags.definition(tags.propertyName),
      color: '#9200FF',
    },
    {
      tag: tags.comment,
      color: '#F1A1FF',
    },
  ]);

  constructor(
    private metricsService: MetricsService,
    private userSettingsProvider: UserSettingsProvider,
    private authService: AuthService,
    private configService: ConfigurationService,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
    this._onChange(value);
    this.setEditorValue();
  }

  get codeEditorValue(): string {
    return this.codemirrorView?.state?.doc?.toString() ?? '';
  }

  ngOnInit(): void {
    this.esApiKeyValue = this.userSettingsProvider.userSettings.esApiKey || '';
  }

  ngAfterViewInit(): void {
    this.initializeCodemirror();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  private initializeCodemirror(): void {
    this.codemirrorView = new EditorView({
      state: EditorState.create({
        extensions: [
          basicSetup,
          placeholder(this.placeholder),
          PromqlEditorComponent.promQLHighlightTheme,
          this.getPromQLExtension(),
          EditorView.lineWrapping,
          EditorView.updateListener.of(this.onCodeChange.bind(this)),
        ],
        doc: this.value ?? '',
      }),
      parent: this.promQlEditorEl.nativeElement,
    });
    this.cdr.detectChanges();
  }

  private onCodeChange(value: ViewUpdate): void {
    if (this.value !== this.codeEditorValue) {
      this.value = this.codeEditorValue;
    }
  }

  private setEditorValue(): void {
    if (this.value && this.value !== this.codeEditorValue) {
      this.codemirrorView.destroy();
      this.initializeCodemirror();
    }
  }

  private getPromQLExtension(): Extension {
    const self = this;
    return new PromQLExtension()
      .setComplete({
        remote: {
          url: self.configService.getConfigURL('/api/v1/prom-api'),
          lookbackInterval: _24HoursInMS,
          fetchFn(input: RequestInfo, init?: RequestInit): any | Promise<Response> {
            const initReq = init || {};
            return fetch(input, {
              ...initReq,
              headers: {
                'Authorization': self.authService.getBearerToken(),
              },
            });
          },
        },
      })
      .activateLinter(false)
      .asExtension();
  }

  private _onChange: (value: string) => void = () => {
    //
  };

  private _onTouch: () => void = () => {
    //
  };
}
