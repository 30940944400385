import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@app/constants';
import { Teammate } from '@app/shared/models/teammate.model';
import { ITeammateSerialized } from '@app/shared/serialized/teammate.serialized';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeammateService {

  constructor(private http: HttpClient) { }

  public me(): Observable<Teammate> {
    return this.http.get(Constants.teammates + '/me')
      .map(({ data }: { data: ITeammateSerialized[] }) => {
        return Teammate.deserializer(data[0]);
      });
  }
}
