import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { LogsQuerFormProvider } from '../logs-query/logs-query-form.provider';
import * as moment from 'moment';
import { localDateToUtcDate, MomentTimeFormatTypes, utcDateToLocalDate } from '@shared/helpers/moment.helper';
import { DateHelper } from '@shared/services/date.helper';
import { IQueryDateFormValues, IRangeDates } from '@app/logs/shared/models/forms/query-form-provider.models';
import { TimeZoneType } from '@shared/models/timezone-types';
import { Moment } from 'moment';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'sh-absolute-query',
  templateUrl: './absolute-query.component.html',
  styleUrls: ['./absolute-query.component.scss'],
})
export class AbsoluteQueryComponent implements OnDestroy {
  @Select(CompanyState.queryRangeNumber) public queryRangeNumber$: Observable<number>;

  @SelectSnapshot(CompanyState.queryRangeNumber) public queryRangeNumber: number;

  @Output() public absoluteQuery: EventEmitter<any> = new EventEmitter<any>();

  public date: Date = new Date();

  public viewOnlyForm: FormGroup;

  @Input() public set newQueryForm(value: FormGroup) {
    if (value) {
      this._newQueryForm = value;
    }
  }

  private _newQueryForm: FormGroup = new FormGroup({});

  private subs: Subscription[] = [];

  public get newQueryForm(): FormGroup {
    return this._newQueryForm;
  }

  constructor(private formProvider: LogsQuerFormProvider, private store: Store<State>) {
    const timezoneSub = this.store.select(getTimezoneViewPreference).subscribe((tmz) => {
      const keepLocalTime = tmz === TimeZoneType.local;
      this.createAbsoluteFormWithTimeZoneGap(keepLocalTime);
    });
    this.subs.push(timezoneSub);
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public get checkDatesValidity(): boolean {
    const dates = this.formProvider.getDateTime();
    const startDate = moment(dates.fromDate);
    const endDate = moment(dates.toDate);

    return this.viewOnlyForm.valid && moment.duration(endDate.diff(startDate)).asDays() <= this.queryRangeNumber;
  }

  private createAbsoluteFormWithTimeZoneGap(isLocalTime: boolean): void {
    if (!this.viewOnlyForm) {
      const model = this.formProvider.getModel();
      const dates = { fromDate: new Date(model.startDate), toDate: new Date(model.endDate) };
      const newVal = this.getFormValuesByTimeZonePreference(isLocalTime, dates, localDateToUtcDate);
      this.viewOnlyForm = this.formProvider.getDateForm(newVal);
      this.subscribeToFormValues(isLocalTime);
    } else {
      this.viewOnlyForm.patchValue(this.newQueryForm.getRawValue().dates, { emitEvent: false });
    }
  }

  private subscribeToFormValues(isLocalTime: boolean): void {
    const sub = this.viewOnlyForm.valueChanges.subscribe((value) => {
      const dates = this.getMergedFromValues(value);
      const newVal = this.getFormValuesByTimeZonePreference(isLocalTime, dates, utcDateToLocalDate);
      this.newQueryForm.get('dates')?.patchValue(newVal);
    });
    this.subs.push(sub);
  }

  private getFormValuesByTimeZonePreference(
    isLocalTime: boolean,
    dates: IRangeDates,
    localeFunction: (date: Date) => Moment,
  ): IQueryDateFormValues {
    const fromDate = isLocalTime ? moment(dates.fromDate) : localeFunction(dates.fromDate);
    const toDate = isLocalTime ? moment(dates.toDate) : localeFunction(dates.toDate);
    return {
      fromTime: fromDate.format(MomentTimeFormatTypes.standard),
      toTime: toDate.format(MomentTimeFormatTypes.standard),
      from: fromDate.toDate(),
      to: toDate.toDate(),
    };
  }

  private getMergedFromValues(value: IQueryDateFormValues): IRangeDates {
    return {
      fromDate: DateHelper.mergeDateAndTime(value.from, value.fromTime),
      toDate: DateHelper.mergeDateAndTime(value.to, value.toTime),
    };
  }
}
