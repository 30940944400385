<div fxLayout="column" class="sh-card">
  <div class="alerts-summary-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="labelWrapper">
      <div class="label-caption">Alerts Summary - {{ alertsInsightsTime.value.caption }}</div>
    </div>
    <sh-coralogix-select
      class="insights-time"
      bindLabel="caption"
      [selectOptions]="insightsTime"
      [formControl]="alertsInsightsTime"
    ></sh-coralogix-select>
  </div>

  <div class="ui inline loader" [class.active]="isLoading"></div>

  <div class="alerts-summary-no-data" *ngIf="!isLoading && (!_insights?.length || !_alerts?.length)">
    Alerts summary is not available for the chosen timeframe
  </div>

  <div fxLayout="row" [class.hidden]="!_insights?.length || !_alerts?.length || isLoading" class="summaryWrapper">
    <div fxFlex="70" fxLayout="column">
      <sh-summary-chart [insights]="insights | insightsLastHoursFilter: alertsInsightsTime.value.seconds / 3600"></sh-summary-chart>
    </div>
    <div fxFlex="30" fxLayout="column">
      <sh-alerts-severity-chart
        [description]="'alerts in the ' + alertsInsightsTime.value.caption"
        *ngIf="totalSeverity?.length > 0"
        [totalSeverity]="totalSeverity"
      ></sh-alerts-severity-chart>
      <sh-summary-severity-list *ngIf="aggregationSummary?.length > 0" [listSummary]="aggregationSummary"></sh-summary-severity-list>
    </div>
  </div>
</div>
