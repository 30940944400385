<mat-card
  class="coralogix"
>
  <p class="mat-card-header">Archive to AWS S3</p>
  <p class="mat-card-subtitle">Archive the logs that are blocked by this rule to your AWS S3 bucket</p>
  <form [formGroup]="form">

    <div class="coralogix input-group">
      <div class="has-validation" [class.coralogix-input-error]="hasError('region', 'required')" >
          <label select-label>
            <span class="text">Bucket Region</span>
          </label>
          <sh-coralogix-select
            [bindValue]="'id'"
            [bindLabel]="'name'"
            [selectOptions]="regionOptions"
            formControlName="region">
          </sh-coralogix-select>
          <span *ngIf="hasError('region', 'required')" class="error" >
            Region is required
          </span>
        </div>
    </div>

    <div class="coralogix input-group">
      <label class="input-label">Bucket Name</label>
      <div class="has-validation" [class.coralogix-input-error]="hasError('bucket', 'required')">
        <input
          type="text"
          placeholder="Enter your AWS S3 Bucket Name"
          class="form-control"
          formControlName="bucket"
        >
        <span *ngIf="hasError('bucket', 'required')" class="error" >
          Bucket name is required
        </span>
      </div>
    </div>
  </form>
  <br><br>
  <sh-reindex-access-permisions-info></sh-reindex-access-permisions-info>
  <br>

  <mat-card class="verify coralogix">

    <div class="verify-header-container">
      <div class="verify-label">Verify Bucket</div>
      <button
        type="button"
        class="coralogix button refresh-button"
        [disabled]="testing || !formValid"
        (click)="verifyBucketSettings()">
        <i class="fa fa-refresh" [class.animate]="testing"></i>
        <span>Verify</span>
      </button>
    </div>

    <div class="verify-content" *ngIf="isTestingResultMessage" >
      <img [src]="verifyImage">
      <div class="verify-content-text" [class.success]="testSuccess">{{testingResultMessage}}</div>
    </div>

  </mat-card>

</mat-card>
