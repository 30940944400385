import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Insight } from '../../shared/models/insight.model';
import {
  InsightTypeId,
  InsightSeverity,
  AnomalySubTypeId,
} from '../../shared/models/enums';
import { Alert } from '../../shared/models/alert.model';
import { InsightsService } from '@app/insights/shared/services/insights-service';
import { MomentTimeFormatTypes } from '@shared/helpers/moment.helper';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { AlertsState } from '@app/ngxs-store/alerts/alerts-snooze.state';
import moment from 'moment';
import { SnoozeInfo } from '@app/alerts/shared/models/snooze.model';
import { UserAlertTypes } from '@app/alerts/shared/models/create-n-update-alert-model';
declare let $: any;
@Component({
  selector: 'sh-insight-timeline-item',
  templateUrl: './insight-timeline-item.component.html',
  styleUrls: ['insight-timeline-item.component.scss'],
})
export class InsightTimelineItemComponent implements OnInit {
  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;
  @Select(AlertsState.snoozeData) public snoozeData$: Observable<{[id: string]: SnoozeInfo}>;

  @Input() public insight: Insight;

  @Input() public selected: boolean = false;

  @Input() public groupBy: string = '(24H)';

  @Input() public isRoot: boolean;

  @Input() public occurrences: number;

  @Input() public uniqueCompNum: number;

  @Output() public occurrencesButtonClickedEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() public insightClickedEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() public anomalyTuningClickedEvent: EventEmitter<any> = new EventEmitter<any>();

  public insightTypeId: typeof InsightTypeId = InsightTypeId;

  public anomalySubTypeId: typeof AnomalySubTypeId = AnomalySubTypeId;

  public insightSeverity: typeof InsightSeverity = InsightSeverity;

  public resolvedTitle: string = '';

  public hoursFormat: typeof MomentTimeFormatTypes = MomentTimeFormatTypes;

  public showSnooze: boolean = false;

  public defaultApps: string = 'Mult. Apps';
  public defaultSubsystems: string = 'Mult. Subs';
  public defaultServers: string = 'Mult. Servers';

  constructor(public insightsService: InsightsService) {
  }

  public ngOnInit(): void {
    if ((this.insight as Alert).isResolved) {
      this.resolvedTitle = this.insight.name.replace('[RESOLVED] ', '');
    }
    if (this.insight.subTypeId === UserAlertTypes.MetricAlert) {
      this.defaultApps = '';
      this.defaultSubsystems = '';
      this.defaultServers = '';
    }
  }

  public occurrencesButtonClicked(): void {
    this.occurrencesButtonClickedEvent.emit();
  }

  public insightClicked(): void {
    this.insightClickedEvent.emit();
  }

  public anomalyTuningClicked(e: Event): void {
    e.stopPropagation();
    this.anomalyTuningClickedEvent.emit(this.insight);
  }

  public getAlertComputerName(insight: Insight): string {
    const alert: Alert = insight as Alert;
    return alert ? alert.computerName : '';
  }

  public getAlertIpAddress(insight: Insight): string {
    const alert: Alert = insight as Alert;
    return alert ? alert.IPAddress : '';
  }

  public mouseHover(): void {
    if (!this.showSnooze) {
      this.showSnooze = true;
    }
  }

  public mouseLeave(e: any): void {
    if (this.showSnooze && e?.toElement?.classList?.value !== 'cdk-overlay-backdrop cdk-overlay-transparent-backdrop') {
      this.showSnooze = false;
    }
  }

  public isSnoozed(snoozeData: {[id: string]: SnoozeInfo}): boolean {
    return !_.isNil(this.insight?.alertId) &&
      snoozeData[this.insight.alertId]?.snoozeEndTimestamp > moment().utc().valueOf();
  }

  public metricAlertSubtitle(subTypeId: number): string {
    if (subTypeId === UserAlertTypes.MetricAlert) {
      return 'metric ';
    }

    return '';
  }

  public getInsightPropertiesText(): string {
    const apps = this.insight.applicationName || this.defaultApps;
    const subsystems = this.insight.subsystemName || this.defaultSubsystems;
    const servers = this.getAlertComputerName(this.insight) || this.getAlertIpAddress(this.insight) || this.defaultServers;
    return [apps, subsystems, servers].filter(s => !_.isEmpty(s)).join(',');
  }
}
