import { CompanyService } from '@app/user/shared/srvices/company.service';
import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { TeamService } from '@app/user/shared/srvices/teams.service';
import { Constants } from '@app/constants';
import { SamlService } from '@app/settings-common/shared/services/saml.service';
import { IAuthState, LoginModes, LoginModesImages, LoginRoutes, modesViewStates } from '@app/auth/shared/models/login-view-models';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  moduleId: 'login.component',
  selector: 'sh-login-view',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  public loginForm: FormGroup;
  public loading: boolean;
  public mode: IAuthState;
  public teamName: string;
  public hasSso: boolean;
  public imageUrlBase: string = '/assets/login';
  public isLoading: boolean;
  public teamLogoSrc: SafeUrl;
  public imagesEnum: typeof LoginModesImages = LoginModesImages;
  public appUrlEnding: string = Constants.getAppUrlEnding;
  private subs: Subscription[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private companyService: CompanyService,
    private teamService: TeamService,
    private samlService: SamlService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
  ) {
    const teamNameUrl = this.teamService.getTeamNameFromUrl();
    this.setInitialValues(teamNameUrl);
    this.companyService
      .getCompanyLogo(teamNameUrl)
      .first()
      .filter(data => !!data)
      .subscribe((base64Img: string) => {
        this.teamLogoSrc = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64Img);
      });
  }

  public get getCurrentModeImage(): string {
    return this.mode ? `${this.imageUrlBase}/${this.mode.sideImageName}` : `${this.imageUrlBase}/${this.imagesEnum.team}`;
  }

  public login(): void {
    location.href = Constants.getAuthGuardRedirectUrl;
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private setInitialValues(teamNameUrl: string): void {
    if (this.router.url !== LoginRoutes.createTeam) {
      localStorage.removeItem('token');
      localStorage.removeItem('t_id');
    }
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.subs.push(
      combineLatest([this.getRouterEndObservable(), this.checkSso()]).subscribe(([event, ssoData]) => {
        this.setMode(event.url, teamNameUrl, ssoData);
        this.isLoading = false;
      }),
    );
  }

  private setMode(url: string, teamName: string, ssoData?: { has_saml: boolean }): void {
    this.teamName = '';
    if (url.includes(LoginRoutes.teams)) {
      this.mode = modesViewStates[LoginModes.teams];
    } else if (url.includes(LoginRoutes.createTeam)) {
      this.mode = modesViewStates[LoginModes.createTeam];
    } else if (url.includes(LoginRoutes.forgot)) {
      this.mode = modesViewStates[LoginModes.forgot];
    } else if (url.includes(LoginRoutes.resetPassword)) {
      this.mode = modesViewStates[LoginModes.resetPassword];
    } else if (url.includes(LoginRoutes.status)) {
      this.mode = modesViewStates[LoginModes.status];
    } else if (url.includes(LoginRoutes.signInWithTeam) && ssoData?.hasOwnProperty('has_saml') && teamName) {
      this.teamName = teamName;
      this.mode = modesViewStates[LoginModes.signInWithTeam];
    } else if (url.includes(LoginRoutes.signIn)) {
      this.mode = modesViewStates[LoginModes.signIn];
    } else if (url.includes(LoginRoutes.newTeamMember)) {
      this.mode = modesViewStates[LoginModes.newTeamMember];
    } else {
      this.mode = modesViewStates[LoginModes.team];
    }
  }

  private getRouterEndObservable(): Observable<NavigationEnd> {
    return this.router.events.filter((event: any) => event instanceof NavigationEnd);
  }

  private checkSso(): Observable<any> {
    const hostArr = window.location.hostname.split('.');
    if (hostArr.length > 1 && hostArr[0] !== 'dashboard') {
      this.isLoading = true;

      const teamUrl = hostArr[0];
      return this.samlService
        .getCompanySamlInfo(teamUrl)
        .pipe(
          tap(
            res => {
              this.hasSso = res.has_saml;
              this.isLoading = false;
            },
            () => {
              this.isLoading = false;
            },
          ),
        )
        .catch(() => of({}));
    } else {
      this.isLoading = false;
      return of({});
    }
  }
}
