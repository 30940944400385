import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnomalyUpdateParams } from '../../shared/models/Anomaly-update-params';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class AnomalyTuningFormProvider {
  get defaultValues(): any {
    return {
      name: '',
      severity: [],
      isEnabled: true,
    };
  }
  public newForm: FormGroup;

  public insightSeverityOptions = [
    { id: 1, name: 'Info' },
    { id: 2, name: 'Warning' },
    { id: 3, name: 'Critical' },
  ];

  private model: AnomalyUpdateParams = new AnomalyUpdateParams();

  constructor() {
    this.newForm = this.generateNewForm(this.defaultValues);
  }

  public getModel(): AnomalyUpdateParams {
    this.updateModelByForm(this.model);
    return this.model;
  }

  public updateFormByModel(updateParams: AnomalyUpdateParams) {
    this.newForm.controls['name'].setValue(updateParams.name);
    this.newForm.controls['enabled'].setValue(updateParams.isActive);
    let anomalySeverityOption = this.insightSeverityOptions[0];
    const option = _.find(
      this.insightSeverityOptions,
      (severity) => severity.id == updateParams.severity,
    );
    if (option) {
      anomalySeverityOption = option;
    }
    this.newForm.controls['severity'].setValue([anomalySeverityOption]);
  }

  public updateModelByForm(updateParams: AnomalyUpdateParams) {
    updateParams.name = this.newForm.value.name;
    updateParams.severity = this.newForm.value.severity[0].id;
    updateParams.isActive = this.newForm.value.enabled;
  }

  private generateNewForm(values: any) {
    return new FormGroup({
      name: new FormControl(values.name, Validators.required),
      severity: new FormControl(values.severity),
      enabled: new FormControl(values.isActive),
    });
  }
}
