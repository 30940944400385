import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { ShDialogService } from '@shared/services/dialogService';
import * as alertActions from '@app/alerts/shared/actions/alerts.actions';
import { Constants } from '@app/constants';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { AlertsBaseUrl } from '@app/alerts/shared/models/alerts.consts';
import { IPartialError } from '@app/alerts/shared/models/actions.models';
import { AlertsErrorStatusCodeOptionsEnum, ERROR_MSG } from '@app/alerts/shared/models/alert-model';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { defaultInsightTime } from '@app/alerts/alerts-table/alerts-insights.model';

@Injectable()
export class AlertsServiceHelper {
  constructor(
    private store: Store<State>,
    private dialogService: ShDialogService,
    private router: Router,
    private location: Location,
  ) {}

  public dispatchUpdateAlertsInitialData(): void {
    this.store.dispatch(alertActions.getAlertsAction());
    this.store.dispatch(alertActions.getAlertsInsightsAction({ payload: defaultInsightTime }));
  }

  public getAlertsCreateOrUpdateRoutingLink(alertId: string): string {
    let alertLink: string = null;
    // No points in redirecting to the alerts screen if we created the alert from it
    if (!window.location.href.includes('alerts')) {
      const teamName = window.location.hostname.split('.')[0];
      alertLink = Constants.getAlertRedirectUrl.replace('%s', teamName) + alertId;
    }
    return alertLink;
  }

  public closeAlertPanelAndShowMessage(
    message: string,
    icon: dialogServiceIconClasses = dialogServiceIconClasses.success,
    routerLink: string = null,
  ): void {
    this.router.onSameUrlNavigation = 'reload';
    const currentPath = this.location.path();
    const newArr = currentPath.split('/');
    if (currentPath.includes(AlertsBaseUrl)) {
      newArr.pop();
    }
    this.router
      .navigateByUrl(newArr.join('/'))
      .then(() => {
        this.setEditorLoaderState(false);
        this.dialogService.showCoralogixMessage(message, routerLink, icon);
      })
      .catch((err) => {
        this.setEditorLoaderState(false);
      })
      .finally(() => {
        this.router.onSameUrlNavigation = 'ignore';
      });
  }

  public setEditorLoaderState(state: boolean): void {
    this.store.dispatch(alertActions.updateAlertEditorLoadingAction({ payload: state }));
  }

  public getErrSyntaxInvalid(err: IPartialError): string {
    const isErrString = typeof err.error === 'string';
    const invalidErr = err.status === AlertsErrorStatusCodeOptionsEnum.invalidQuery && isErrString ? err.error : null;
    return invalidErr;
  }

  public handleAlertInvalidQueryAction(payload: IPartialError, closeEditor: boolean = true): Action {
    const invalidQueryMessage = this.getErrSyntaxInvalid(payload);
    const alertMsg = ERROR_MSG[payload.status] || ERROR_MSG.default;
    if (invalidQueryMessage) {
      this.dialogService.showCoralogixMessage(alertMsg, null, dialogServiceIconClasses.failed);
      this.setEditorLoaderState(false);
    } else if (closeEditor) {
      this.closeAlertPanelAndShowMessage(alertMsg, dialogServiceIconClasses.failed);
    }
    return alertActions.updateOpenAlertInvalidQueryAction({ payload: invalidQueryMessage });
  }
}
