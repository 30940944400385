<div class="empty-list-body">
    <div class="wrapper">
      <div class="content-header">
        <h1>Getting started with Logs2Metrics</h1>
        <p>
	  To create a new metric - simply define the query, metric fields and labels. And Coralogix will produce a metric document every 1 minute for dashboards and alerts.
        </p>
      </div>
      <div class="content-body">
        <h1>Examples</h1>
        <div class="list-item">
          <div class="count">1</div>
          <div class="description">
            The query: status:[500 TO *] AND env:PROD. Will store the amount of 5XX responses on your server every 5 minutes for a period of 1 year. This will allow long term analysis on your API stability.
          </div>
        </div>
        <div class="list-item">
          <div class="count">2</div>
          <div class="description">
            The query: "user completed purchase successfully". Will store the amount of successful purchases on your website every 5 minutes for a period of 1 year and allow you to discover business trends.
          </div>
        </div>
        <div class="list-item">
          <div class="count">3</div>
          <div class="description">
            The query: message:"Exception" and Severity:ERROR. will store the amount of exceptions you have every 5 minutes for a period of 1 year to help you discover trends in your application quality.
          </div>
        </div>
        <div class="list-item">
          <div class="count">4</div>
          <div class="description">
            The query:"NXDOMAIN" will allow you to store the amount of NXDOMAIN responses by your DNS resolver every 5 minutes for a period of 1 year to identify trend and possible attacks.
          </div>
        </div>
      </div>
    </div>
  </div>
