import { AlertInfo, LineGraphResult, TotalSeverity } from '@app/deployment/shared/models/alerts-summary.models';
import { Build } from '@app/deployment/models/build';
import { IStatisticsViewData, ITagWithCompareBaseRequest } from '@app/deployment/shared/models/summary.models';
import { ITagErrorVolumeResponse } from '@app/deployment/shared/models/error-volume.models';

export interface IAlertsData {
  severityInfo: TotalSeverity[];
  alertsListInfo: AlertInfo[];
  volumeCompareGraph: LineGraphResult;
}

export interface ISelectedTagView {
  selectedShowAllSubsystems: boolean;
  selectedTag: Build;
  selectTagForCompare: Build;
  compareNSelectedPayloadBase: ITagWithCompareBaseRequest;
  summaryViewMode: EFeatureViewMode;
  summaryArray: IStatisticsViewData[];
  alerts: IAlertsData;
  errorVolume: ITagErrorVolumeResponse;
  errorVolumeViewMode: EFeatureViewMode;
  alertsViewMode: EFeatureViewMode;
  graphsDelta: string;
  selectedLog: any;
}

export enum EFeatureViewMode {
  initialState = 'initial',
  loading = 'loading',
  completed = 'completed',
  noResults = 'No Results',
  serverError = 'Content not available'
}
