import { Injectable, Output, EventEmitter } from '@angular/core';
import { ApplicationDispatcher } from '../../../shared/services/application-dispatcher';
import { Subscription } from 'rxjs';
@Injectable()
export class LogsGroupByService {
  @Output() public onColumnGroupedAdded: EventEmitter<
    [string, Array<string>, Array<string>]
  > = new EventEmitter<[string, Array<string>, Array<string>]>();
  @Output() public onColumnGroupedRemoved: EventEmitter<
    [string, Array<string>, Array<string>]
  > = new EventEmitter<[string, Array<string>, Array<string>]>();
  public groupedHeaderName: Array<string> = [];
  private groupedColumns: Array<string> = [];
  private onColumnGroupedClickedSubscription: Subscription;

  constructor(private applicationDispatcher: ApplicationDispatcher) {
    this.onColumnGroupedClickedSubscription = this.applicationDispatcher.onColumnHeaderGrouped.subscribe(
      (x) => {
        //      console.log(x);
        let colname = '';
        if (x.field.indexOf('.') > -1) {
          colname = x.field.substr(x.field.indexOf('.') + 1, x.length);
        } else {
          colname = x.field.toLowerCase();
        }
        // console.log(colname);
        if (this.groupedColumns.indexOf(colname) >= 0) {
          const groupIndex = this.groupedColumns.indexOf(colname);
          this.groupedColumns.splice(groupIndex, 1);
          this.groupedHeaderName.splice(groupIndex, 1);

          this.onColumnGroupedRemoved.emit([
            colname,
            this.groupedColumns,
            this.groupedHeaderName,
          ]);
        } else {
          if (this.groupedColumns.length < 3) {
            // since this is a new column we need to make sure that we are not grouping more than 3
            this.groupedColumns.push(colname);
            this.groupedHeaderName.push(x.header);
            this.onColumnGroupedAdded.emit([
              colname,
              this.groupedColumns,
              this.groupedHeaderName,
            ]);
          }
        }
      },
    );
  }
}
