import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aboveBelowNormal',
})
export class AboveBelowNormalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value > 0) {
      return 'above';
    } else {
      return 'below';
    }
  }
}
