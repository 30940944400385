import {
  ERuleServerTypes,
  IParsingTheme,
  IRuleGroupResponseData,
  RuleMatcher,
  RuleMatcherFieldOptions,
} from '@app/rules/shared/models/rule.model';
import { IParsingThemeFormData, IRuleGroupFormData, IRuleMatcherFormData } from '@app/rules/shared/models/parsing-theme-editor.models';

export type parsingThemeId = string;

export class ParsingThemeUpdateRequest implements IParsingTheme {
  public id: string = '';
  public name: string = '';
  public description?: string = '';
  public order: number = 1;
  public enabled: boolean = true;
  public ruleMatchers: RuleMatcher[] = [];
  public rulesGroups: IRuleGroupResponseData[] = [];

  constructor(parsingTheme?: IParsingTheme | IParsingThemeFormData) {
    if (parsingTheme) {
      Object.assign(this, parsingTheme);
    }
    if (parsingTheme.hasOwnProperty('details')) {
      this.mapFormDataToRequest(parsingTheme as IParsingThemeFormData);
      if (this['details']) {
        delete this['details'];
      }
    }
  }

  public mapFormDataToRequest(parsingTheme?: IParsingThemeFormData): void {
    this.setRuleMatchersRequest(parsingTheme.ruleMatchers);
    this.name = parsingTheme.details.name;
    this.description = parsingTheme.details.description;
    this.rulesGroups = this.mapRuleGroupsToRequest(parsingTheme.rulesGroups);
  }

  private setRuleMatchersRequest(matchers: IRuleMatcherFormData): void {
    const resultArray: RuleMatcher[] = [];
    Object.keys(matchers).forEach((matcherKey) => {
      matchers[matcherKey]?.forEach(constraint => {
        const newData: RuleMatcher = {
          field: RuleMatcherFieldOptions[matcherKey],
          constraint: constraint,
        };
        resultArray.push(newData);
      });
    });
    this.ruleMatchers = resultArray;
  }

  private mapRuleGroupsToRequest(ruleGroups: IRuleGroupFormData[]): IRuleGroupResponseData[] {
    ruleGroups = this.removeFieldsToString(ruleGroups);
    this.getUpdatedRuleGroupsWithBlockLogic(ruleGroups);
    return ruleGroups as IRuleGroupResponseData[];
  }

  private getUpdatedRuleGroupsWithBlockLogic(ruleGroups: IRuleGroupFormData[]): void {
    ruleGroups.forEach((group, groupIndex) => {
      const blockRules = group.rules?.filter((rule) => rule.type === ERuleServerTypes.block);
      if (blockRules?.length) {
        const allowRules = blockRules.filter((bRule) => bRule.blockMatching === ERuleServerTypes.allow);
        if (allowRules?.length) {
          allowRules.forEach((allowRule) => {
            const ind = group.rules.findIndex(matched => matched.id === allowRule.id);
            const selected = ruleGroups[groupIndex].rules[ind];
            ruleGroups[groupIndex].rules[ind] = { ...selected, type: ERuleServerTypes.allow };
          });
        }
      }
      group = this.removeUnusedRulesParams(group);
    });
  }

  private removeUnusedRulesParams(ruleGroup: IRuleGroupFormData): IRuleGroupFormData {
    ruleGroup.rules.forEach(rule => {
      delete rule.logExampleRes;
    });
    return ruleGroup;
  }

  private removeFieldsToString(ruleGroups: IRuleGroupFormData[]): IRuleGroupFormData[] {
    return ruleGroups.map(ruleGroup => {
      ruleGroup.rules = ruleGroup.rules.map(rule => {
        if (rule.type === ERuleServerTypes.removeFields && Array.isArray(rule.rule)) {
          rule.rule = rule.rule.join(',');
        }
        return rule;
      });
      return ruleGroup;
    });
  }
}
