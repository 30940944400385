import { Injectable } from '@angular/core';
import { AllValues, IParsingThemeFilter } from '@app/rules/shared/models/parsing-themes-filters';
import { IParsingTheme, RuleMatcherFieldOptions } from '@app/rules/shared/models/rule.model';
import { IRuleData } from '@app/rules/shared/models/rule-types-view.models';

@Injectable({ providedIn: 'root' })
export class ParsingThemeFilterService {

  public getFilteredData(themeFilter: IParsingThemeFilter, parsingThemes: IParsingTheme[]): IParsingTheme[] {
    let filteredList: IParsingTheme[] = parsingThemes;
    if (!!themeFilter?.applications?.length) {
      filteredList = this.getFilteredListByRuleMatcher(themeFilter.applications, parsingThemes, RuleMatcherFieldOptions.applications);
    }
    if (!!themeFilter?.subsystems?.length) {
      filteredList = this.getFilteredListByRuleMatcher(themeFilter.subsystems, parsingThemes, RuleMatcherFieldOptions.subsystems);
    }
    if (!!themeFilter?.ruleTypes?.length && themeFilter?.ruleTypes !== AllValues) {
      // supports multiple / single choice selection types
      const typeList = Array.isArray(themeFilter.ruleTypes) ? themeFilter.ruleTypes : [themeFilter.ruleTypes];
      filteredList = this.getFilteredListByRuleProp(typeList, filteredList, 'type');
    }
    if (!!themeFilter?.searchTerm?.length) {
      filteredList = this.getFilteredListByQuery(themeFilter.searchTerm, filteredList);
    }
    return filteredList;
  }

  private getFilteredListByQuery(textsToSearch: string, parsingThemes: IParsingTheme[]): IParsingTheme[] {
    const queryText = textsToSearch.toLocaleLowerCase();
    return parsingThemes.filter((parsingTheme) => {
      let isIncluded = null;
      // searches inside Rule Titles, Rule Descriptions, parsing themes titles, parsing themes descriptions.
      const ruleSearchTexts = parsingTheme.rulesGroups?.length ? parsingTheme.rulesGroups.reduce((prev, curr) => {
        const extractedRuleData = curr.rules?.reduce((prevResults, currRule) => {
          const currentExtractedData = [currRule?.name, currRule?.description];
          return prevResults ? [...prevResults, ...currentExtractedData] : currentExtractedData;
        }, []);
        return !!prev ? [...prev, ...extractedRuleData] : extractedRuleData;
      }, []) : [];
      const ptData = parsingTheme.description ? [parsingTheme.name, parsingTheme.description] : [parsingTheme.name];
      ruleSearchTexts.push(...ptData);
      ruleSearchTexts.forEach((text => {
        if (text.toLowerCase().includes(queryText)) {
          isIncluded = true;
        }
      }));
      return isIncluded;
    });
  }

  private getFilteredListByRuleProp(searchList: string[], parsingThemes: IParsingTheme[], propKey: keyof IRuleData): IParsingTheme[] {
    return parsingThemes.filter((parsingTheme) => {
      let isIncluded = null;
      const ruleSearchPropList = parsingTheme.rulesGroups?.length ? parsingTheme.rulesGroups.reduce((prev, curr) => {
        const extractedRuleData = curr.rules?.reduce((prevResults, currRule) => {
          const currentExtractedData = [currRule[propKey]];
          return !!prevResults ? [...prevResults, ...currentExtractedData] : currentExtractedData;
        }, []);
        return !!prev ? [...prev, ...extractedRuleData] : extractedRuleData;
      }, []) : [];
      ruleSearchPropList.forEach((propFromList => {
        if (searchList.find(_prop => _prop === propFromList)) {
          isIncluded = true;
        }
      }));
      return isIncluded;
    });
  }

  private getFilteredListByRuleMatcher(searchList: string[], parsingThemes: IParsingTheme[],
                                       fieldValue: string): IParsingTheme[] {
    return parsingThemes.filter((parsingTheme) => {
      let isIncluded = null;
      const mappedList: string[] = parsingTheme.ruleMatchers?.length ? parsingTheme?.ruleMatchers?.filter(
        (rule) => rule.field === fieldValue)?.map((matcher) => matcher.constraint) : [];
      mappedList?.forEach((propFromList => {
        if (searchList.find(_prop => _prop === propFromList)) {
          isIncluded = true;
        }
      }));
      return mappedList?.length ? isIncluded : false;
    });
  }
}
