import { Pipe, PipeTransform } from '@angular/core';
import { Insight } from '../../insights/shared/models/insight.model';
import * as moment from 'moment';
import { DateHelper } from '../services/date.helper';

@Pipe({ name: 'insightsLastHoursFilter' })
export class InsightsLastHoursFilterPipe implements PipeTransform {
  public transform(items: Insight[], hours: number): Insight[] {
    if (items && items.length > 0) {
      // Getting the last X hours insights
      const t = items.filter((x) => x.timestamp / 1000 > moment(moment().subtract(hours, 'hours')).unix());
      return t;
    }
    return items;
  }
}

@Pipe({ name: 'insightsIdFilter' })
export class InsightsIdFilterPipe implements PipeTransform {
  public transform(items: Insight[], id: string): Insight[] {
    if (items && items.length > 0) {
      return items.filter((x) => x.alertId == id);
    }
    return items;
  }
}

@Pipe({ name: 'insightsTimeAggregator' })
export class InsightsTimeAggregatorPipe implements PipeTransform {
  public transform(items: Insight[], numberOfBins: number) {
    const bins = [];

    if (items && items.length > 0) {
      const numberOfHoursPerBin: number = 24 / numberOfBins;
      let binStartTime = DateHelper.getRoundHour(
        moment()
          .subtract(24, 'hours')
          .toDate(),
      );
      for (let _i = 0; _i < numberOfBins + 1; _i++) {
        bins.push({ date: binStartTime.toDate(), size: 0 });
        binStartTime = moment(binStartTime).add(numberOfHoursPerBin, 'hours');
      }
      items.forEach((x) => {
        //  console.log(DateHelper.getRoundHour(moment(x.timestamp).toDate()));
        for (let _i = 0; _i < numberOfBins; _i++) {
          if (
            DateHelper.getRoundHour(moment(x.timestamp).toDate()) <= moment(bins[_i + 1].date) &&
            DateHelper.getRoundHour(moment(x.timestamp).toDate()) > moment(bins[_i].date)
          ) {
            bins[_i].size += 1;
            break;
          }
        }
      });

      bins.splice(numberOfBins, 1);
    }
    return bins;
  }
}
