import { ERuleServerTypes } from '@app/rules/shared/models/rule.model';
import {
  DynamicRuleControlType,
  IDynamicRadioButtonCol,
  IDynamicRuleCol,
  IDynamicRuleLayout,
  IDynamicTextareaCol,
  IDynamicSelectCol, IDynamicMultiSelectCol,
} from '@app/rules/shared/models/dynamic-rule-config.models';
import { EFontFamilyOptions } from '@shared/controls/basic-input/basic-input.component';
import { TimestampFormatStandards } from '@app/rules/shared/models/parsing-theme-editor-consts';
import { FormGroup } from '@angular/forms';

const NameRowConfig: IDynamicRuleCol[] = [
  {
    label: 'Name',
    controlName: 'name',
    isOptional: false,
    tooltip: null,
    type: DynamicRuleControlType.text,
  }
];
const DescriptionRowConfig: IDynamicTextareaCol[] = [
  {
    label: 'Description',
    controlName: 'description',
    isOptional: true,
    tooltip: null,
    type: DynamicRuleControlType.textarea,
    numberOfInputRows: 2,
    inputClass: ''
  },
];

const SourceConfig: IDynamicSelectCol = {
  label: 'Source Field',
  controlName: 'sourceField',
  isOptional: false,
  tooltip: 'The field on which the RegEx will operate on',
  type: DynamicRuleControlType.select,
};

const ReplaceWithConfig: IDynamicRuleCol = {
  label: 'Replacement String',
  controlName: 'replaceNewVal',
  isOptional: true,
  tooltip: 'The string that will replace the matched RegEx',
  type: DynamicRuleControlType.text,
  fontFamily: EFontFamilyOptions.consolas
};

export const DestinationConfig: IDynamicSelectCol = {
    label: 'Destination Field',
    controlName: 'destinationField',
    isOptional: false,
    tooltip: 'The field that will be populated by the results of the RegEx operation',
    type: DynamicRuleControlType.select,
};

export const RegexAsJsonKeyConfig: IDynamicRuleCol = {
  label: 'JSON Key',
  controlName: 'rule',
  isOptional: false,
  tooltip: null,
  type: DynamicRuleControlType.text,
  fontFamily: EFontFamilyOptions.consolas
};

export const TimeStandardConfig: IDynamicSelectCol = {
    label: 'Field Format Standard',
    controlName: 'destinationField',
    isOptional: false,
    tooltip: 'The Format standard you want to use',
    type: DynamicRuleControlType.select,
    options: TimestampFormatStandards,
    valueChangeSubscription: (form: FormGroup, value) => {
      const metadata = TimestampFormatStandards.find(v => v.value === value)?._metadata;
      const formatControlName = 'replaceNewVal';
      if (metadata) {
        form.get(formatControlName).setValue(metadata.format);
        if (metadata.enabled) {
          form.get(formatControlName).enable();
        } else {
          form.get(formatControlName).disable();
        }
      }
    }
};

export const TimeStandardFormatConfig: IDynamicRuleCol = {
  label: 'Time Format',
  controlName: 'replaceNewVal',
  isOptional: false,
  tooltip: null,
  type: DynamicRuleControlType.text,
  fontFamily: EFontFamilyOptions.consolas
};

const SourceNDestinationRowConfig: IDynamicRuleCol[] = [
  SourceConfig,
  DestinationConfig
];

const RegexConfig: IDynamicTextareaCol = {
  label: 'Regular Expression',
  controlName: 'rule',
  isOptional: false,
  tooltip: null,
  type: DynamicRuleControlType.textarea,
  numberOfInputRows: 4,
  inputClass: 'regex-control-textarea'
};

const BlockedCheckboxRow: IDynamicRuleCol[] = [
  {
    label: 'View blocked logs in LiveTail and archive to S3',
    controlName: 'keepBlockedLogs',
    isOptional: false,
    tooltip: 'Save 85% of your consumed quota for these blocked logs',
    type: DynamicRuleControlType.checkbox,
  }
];

const BlockedSelectRow: IDynamicRadioButtonCol[] = [
  {
    label: 'Block Logic',
    controlName: 'blockMatching',
    isOptional: false,
    tooltip: 'Save 85% of your consumed quota for these blocked logs',
    type: DynamicRuleControlType.radioButtons,
    options: [
      {
        label: 'Block all matching logs',
        isDefault: false,
        value: ERuleServerTypes.block
      },
      {
        label: 'Block all non-matching logs',
        isDefault: true,
        value: ERuleServerTypes.allow
      }
    ]
  }
];

const excludedFieldsRow: IDynamicMultiSelectCol[] = [
  {
    label: 'Excluded fields',
    controlName: 'rule',
    isOptional: false,
    tooltip: 'Excluded fields will not be indexed',
    type: DynamicRuleControlType.multiSelect,
    removePrefix: 'text.',
  },
];

export const DynamicRuleConfigs: { [key: string]: IDynamicRuleLayout } = {
  [ERuleServerTypes.parse]: {
    description: 'Parse unstructured logs into JSON format using named Regex groups.',
    learnMoreLink: 'https://coralogix.com/tutorials/log-parsing-rules/#parse',
    rowConfig: [
      NameRowConfig,
      DescriptionRowConfig,
      SourceNDestinationRowConfig,
      [RegexConfig],
    ],
  },
  [ERuleServerTypes.extract]: {
    description: 'Use a named RegEx group to extract specific values you need as JSON keys without having to parse the entire log.',
    learnMoreLink: 'https://coralogix.com/tutorials/log-parsing-rules/#extract',
    rowConfig: [
      NameRowConfig,
      DescriptionRowConfig,
      [SourceConfig],
      [RegexConfig],
    ],
  },
  [ERuleServerTypes.jsonExtract]: {
    description: 'Name a JSON key to extract its value directly into a Coralogix metadata field.',
    learnMoreLink: 'https://coralogix.com/tutorials/log-parsing-rules/#extract-json',
    rowConfig: [
      NameRowConfig,
      DescriptionRowConfig,
      [RegexAsJsonKeyConfig, DestinationConfig]
    ],
  },
  [ERuleServerTypes.replace]: {
    description: 'Replace rules are used to strings in order to fix log structure, change log severity, or obscure information.',
    learnMoreLink: 'https://coralogix.com/tutorials/log-parsing-rules/#replace',
    rowConfig: [
      NameRowConfig,
      DescriptionRowConfig,
      SourceNDestinationRowConfig,
      [ReplaceWithConfig],
      [RegexConfig],
    ],
  },
  [ERuleServerTypes.block]: {
    description: 'Block rules allow for refined filtering of incoming logs with a RegEx.',
    learnMoreLink: 'https://coralogix.com/tutorials/log-parsing-rules/#block',
    rowConfig: [
      NameRowConfig,
      DescriptionRowConfig,
      [SourceConfig],
      [RegexConfig],
      BlockedCheckboxRow,
      BlockedSelectRow
    ],
  },
  [ERuleServerTypes.timestampExtract]: {
    description: 'Replace log\'s timestamp with JSON field value',
    learnMoreLink: 'https://coralogix.com/tutorials/log-parsing-rules/#timestamp',
    rowConfig: [
      NameRowConfig,
      DescriptionRowConfig,
      [SourceConfig],
      [TimeStandardConfig],
      [TimeStandardFormatConfig],
    ],
  },
  [ERuleServerTypes.removeFields]: {
    description: 'Remove fields allows to select fields that will now be indexed',
    learnMoreLink: 'https://coralogix.com/tutorials/log-parsing-rules/#removeFields',
    rowConfig: [
      NameRowConfig,
      DescriptionRowConfig,
      excludedFieldsRow,
    ],
  },
};
