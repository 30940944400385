export interface IPluginSerialized {
  id: string;

  name: string;

  url: string;

  userId: string;

  userName: string;

  applicationNames: string[];

  subsystemNames: string[];

  isPrivate: boolean;

  isHidden: boolean;

  isSelectedValue: boolean;
}

export interface IPlugin {
  id: string;

  name: string;

  url: string;

  userId: string;

  userName: string;

  applicationNames: string[];

  subsystemNames: string[];

  isPrivate: boolean;

  isHidden: boolean;
}

export class Plugin implements IPlugin {
  id: string;

  name: string;

  url: string;

  userId: string;

  userName: string;

  applicationNames: string[];

  subsystemNames: string[];

  isPrivate: boolean;

  isHidden: boolean;

  static regexp = /{{[^{}]+}}/g; // double open and close curly bracket with string inside

  constructor(obj: IPlugin) {
    this.id = obj.id;
    this.name = obj.name;
    this.url = obj.url;
    this.userId = obj.userId;
    this.userName = obj.userName;
    this.applicationNames = obj.applicationNames;
    this.subsystemNames = obj.subsystemNames;
    this.isPrivate = obj.isPrivate;
    this.isHidden = obj.isHidden;
  }

  get matchingRegex(): string[] {
    return this.url.match(Plugin.regexp)?.map(path => path.substring(2, path.length - 2)) || [];
  }

  get isSelectedValue(): boolean {
    const args = this.matchingRegex;
    return args.some(exp => exp.includes('$p.selected_value'));
  }

  urlAssignner = (args: string[]) => {
    let i = 0;
    return this.url.replace(Plugin.regexp, () => {
      if (args[i] !== undefined) {
        return args[i++];
      } else {
        i++;
        return null;
      }
    });
  };

  static deserializer(obj: IPluginSerialized): Plugin {
    let plugin: Plugin = null;

    if (obj) {
      plugin = new Plugin({
        id: obj.id,
        name: obj.name,
        url: obj.url,
        userId: obj.userId,
        userName: obj.userName,
        applicationNames: obj.applicationNames,
        subsystemNames: obj.subsystemNames,
        isPrivate: obj.isPrivate,
        isHidden: obj.isHidden,
      });
    }

    return plugin;
  }

  static serializer(plugin: Plugin): IPluginSerialized {
    const obj: IPluginSerialized = {
      id: plugin.id,
      name: plugin.name,
      url: plugin.url,
      userId: plugin.userId,
      userName: plugin.userName,
      applicationNames: plugin.applicationNames,
      subsystemNames: plugin.subsystemNames,
      isPrivate: plugin.isPrivate,
      isHidden: plugin.isHidden,
      isSelectedValue: plugin.isSelectedValue,
    };

    return obj;
  }

  static create(obj: IPlugin): Plugin {
    let plugin: Plugin = null;
    if (obj) {
      plugin = new Plugin({
        id: obj.id,
        name: obj.name,
        url: obj.url,
        userId: obj.userId,
        userName: obj.userName,
        applicationNames: obj.applicationNames,
        subsystemNames: obj.subsystemNames,
        isPrivate: obj.isPrivate,
        isHidden: obj.isHidden,
      });
    }

    return plugin;
  }
}
