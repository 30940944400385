import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoggregationService } from '../../loggregation/shared/services/loggregation.service';
import { Subscription } from 'rxjs';
import { LogsViewStateProvider } from '../shared/services/logsViewState.provider';
import { formatsHelper } from '../../shared/services/formatsHelper';
import { LogQueryModel } from '../shared/models/logsquery.model';
import { LogsQuerFormProvider } from '../logs-query/logs-query-form.provider';
import { LogsService } from '../shared/services/logs-service';

@Component({
  selector: 'sh-logs-loggregation',
  templateUrl: './logs-loggregation.component.html',
  styleUrls: ['./logs-loggregation.component.scss'],
})
export class LogsLoggregationComponent implements OnInit, OnDestroy {
  public data: any;
  public queryModel: LogQueryModel;
  public isGridOverlayVisible: boolean = true;
  private newLoggregationSubscirption: Subscription;
  private newQueryRequestSubscirption: Subscription;

  constructor(
    private loggregationService: LoggregationService,
    private formProvider: LogsQuerFormProvider,
    private logsService: LogsService,
    private logsViewStateProvider: LogsViewStateProvider,
  ) {}

  public ngOnInit() {
    this.newLoggregationSubscirption = this.loggregationService.loggregationSubject.subscribe(
      (res) => {
        this.data = res;
        this.isGridOverlayVisible = false;
        this.queryModel = this.formProvider.getModel(true);
        this.logsViewStateProvider.viewState.selectedLog = null;
        // TODO: NG4 remove do check
        setTimeout(() => {
          this.logsViewStateProvider.viewState.showFind = false;
        }, 0);

        if (this.data.length) {
          this.logsViewStateProvider.viewState.queryResultCount =
            formatsHelper.numberWithCommas(this.data.length) + ' Templates';
          this.logsViewStateProvider.viewState.templateCount = this.data.length;
        } else {
          this.logsViewStateProvider.viewState.queryResultCount = 'No results';
          this.logsViewStateProvider.viewState.templateCount = 0;
        }
      },
      (error) => console.log(error),
    );

    this.newQueryRequestSubscirption = this.logsService.newQueryRequest
      .do((j) => (this.isGridOverlayVisible = true))
      .subscribe((queryModel) => {
        this.loggregationService.GetLoggregation(queryModel);
      });
    if (this.logsViewStateProvider.lastExcecutedQuery) {
      this.loggregationService.GetLoggregation(
        this.formProvider.getModel(true),
      );
    }
  }

  public ngOnDestroy() {
    this.newLoggregationSubscirption.unsubscribe();
    this.newQueryRequestSubscirption.unsubscribe();
  }

  public onSelectedItemChanged(item) {
    this.logsViewStateProvider.viewState.selectedLog = item;
  }

  public onGridButtonClick(event) {
    this.logsViewStateProvider.textInfoClicked.emit(event);
  }
}
