<div class="empty-grid">
  <div class="icon-and-top-text-container">
    <div class="icon-container">
      <div class="icon-wrapper">
        <img class="icon" src="assets/log.png" />
      </div>
    </div>
    <h3 class="top-text">No result found</h3>
  </div>
  <div class="text-container">
    <span class="text-container-header">One of the following may be the cause:</span>
    <div class="cause-wrapper" *ngFor="let cause of noResultCauses">
      <h4 class="title">{{ cause.title }}</h4>
      <p class="paragraph">{{ cause.paragraph }}</p>
    </div>
  </div>
</div>
