import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ConditionalSelectService } from '@shared/components/conditional-select/conditional-select.service';
import { CompareType } from '@shared/components/conditional-select/conditional-select.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sh-conditional-select',
  templateUrl: './conditional-select.component.html',
  styleUrls: ['./conditional-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionalSelectComponent implements OnInit {
  @Input() set items(items: any[]) {
    if (items) this.options$.next(items);
  }
  options$ = new BehaviorSubject<string[]>([]);
  CompareType = CompareType;
  compareTypes = [CompareType.startsWith, CompareType.contains, CompareType.endsWith, CompareType.equals];
  @Input() label: string;
  @Input() allGroups: FormArray;
  @Input() btnLabel = 'ADD MORE';
  allSelected = false;
  isDisabled$ = new BehaviorSubject(false);

  constructor(private conditionalSelectService: ConditionalSelectService) {}

  ngOnInit(): void {
    this.handleEmptyForm();
  }

  addControl(): void {
    if (this.allSelected) {
      this.allSelected = false;
      this.isDisabled$.next(false);
    }
    this.allGroups.push(this.conditionalSelectService.createNewGroup());
  }

  onSelectAllChange(selectAll: boolean): void {
    if (selectAll) this.allGroups.clear();
    this.isDisabled$.next(selectAll);
  }

  deleteGroup(index: number): void {
    this.allGroups.removeAt(index);
  }

  getGroupValue(group: FormGroup): string {
    const groupValues = group.controls['values'].value;
    return groupValues?.[0] || '';
  }

  onCompareTypeChange(group: FormGroup): void {
    group.controls['values'].setValue([]);
  }

  onInputChange(group: FormGroup, value: string): void {
    group.controls['values'].setValue([value]);
  }

  private handleEmptyForm(): void {
    const isEmpty = !this.allGroups.value || this.allGroups.value.length === 0;
    if (isEmpty) {
      this.allSelected = true;
      this.isDisabled$.next(true);
    }
  }
}
