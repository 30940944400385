import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataFlowRoutesComponent } from './data-flow-routes.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [DataFlowRoutesComponent],
  exports: [
    DataFlowRoutesComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    RouterModule,
  ],
})
export class DataFlowRoutesModule { }
