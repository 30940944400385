<div class="timeline-block">
  <div class="emptyMarker"></div>
  <div class="content">
    <div class="header">
      <!--<div [ngSwitch]="insight.typeId" class="insight-icon">-->
      <!--<i class="warning sign icon" *ngSwitchCase="insightTypeId.Alert"></i>-->
      <!--<i class="warning circle icon" *ngSwitchCase="insightTypeId.Anomaly"></i>-->
      <!--</div>-->
      <div>
        <div class="title">
          <span class="insight-name">All insights are filtered</span>
        </div>
        <div class="sub-title">
          <span
            class="clear"
            style="font-weight: 400"
            (click)="onClearFilters.emit()"
          >
            Clear filters
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
