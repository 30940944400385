<sh-coralogix-sidebar
  #sidebarComponent
  [templateRef]="editor">
</sh-coralogix-sidebar>
<ng-template #editor>
  <sh-reindex-s3-form
    [form]="form"
    [regionOptions]="regionOptions"
    (onSubmit)="submit($event)"
    (testArchivingProvider)="testArchivingProvider($event)"
    [testing]="testing$ | async"
    [testingResult]="testingResult$ | async">
  </sh-reindex-s3-form>
</ng-template>
