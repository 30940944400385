import { IRuleFieldOptionValue } from '@app/rules/shared/models/parsing-theme-editor.models';

export interface IConfig {
  url: string;
  httpServiceGatewayUrl: string;
  livaTailUrl: string;
  signupRedirectURL: string;
  kibanaUrl: string;
  stripeKey: string;
  authGuardRedirectUrl: string;
  userLoginRedirectUrl: string;
  teamRedirectUrl: string;
  teamSamlRedirectUrl: string;
  alertRedirectUrl: string;
  defaultHost: string;
  apiUrl: string;
  samlApiUrl: string;
  iconsUrl: string;
  signup: string;
  urlEnding: string;
  appUrlEnding: string;
  recaptchaV2SiteKey: string;
  recaptchaV3SiteKey: string;
  billFlowEmbedKey: string;
  proxyLoginRedirect: string;
  apiVersionUrl: string;
  identifier: string;
  metadataFields: string[];
  rulesApiPrefix: string;
  indexFieldsUrl: string;
  extendedTemplateIndexFields: string;
  metadataFieldsUrl: string;
  textFieldName: string;
  rulesFieldOptions: IRuleFieldOptionValue[];
  defaultMetaFieldOptionValue: string;
  enableArchiveQueryLogScreen: boolean;
}

export type ConfigMemberType = keyof IConfig;

export enum EConfigEnum {
  url = 'url',
  httpServiceGatewayUrl = 'httpServiceGatewayUrl',
  livaTailUrl = 'livaTailUrl',
  signupRedirectURL = 'signupRedirectURL',
  kibanaUrl = 'kibanaUrl',
  stripeKey = 'stripeKey',
  authGuardRedirectUrl = 'authGuardRedirectUrl',
  userLoginRedirectUrl = 'userLoginRedirectUrl',
  teamRedirectUrl = 'teamRedirectUrl',
  teamSamlRedirectUrl = 'teamSamlRedirectUrl',
  alertRedirectUrl = 'alertRedirectUrl',
  defaultHost = 'defaultHost',
  apiUrl = 'apiUrl',
  samlApiUrl = 'samlApiUrl',
  iconsUrl = 'iconsUrl',
  signup = 'signup',
  urlEnding = 'urlEnding',
  appUrlEnding = 'appUrlEnding',
  recaptchaV2SiteKey = 'recaptchaV2SiteKey',
  recaptchaV3SiteKey = 'recaptchaV3SiteKey',
  billFlowEmbedKey = 'billFlowEmbedKey',
  proxyLoginRedirect = 'proxyLoginRedirect',
  apiVersionUrl = 'apiVersionUrl',
  metadataFields = 'metadataFields',
  rulesApiPrefix = 'rulesApiPrefix',
  indexFieldsUrl = 'indexFieldsUrl',
  extendedTemplateIndexFields = 'extendedTemplateIndexFields',
  metadataFieldsUrl = 'metadataFieldsUrl',
  textFieldName = 'textFieldName',
  rulesFieldOptions = 'rulesFieldOptions',
  defaultMetaFieldOptionValue = 'defaultMetaFieldOptionValue',
  enableArchiveQueryLogScreen = 'enableArchiveQueryLogScreen',
}
