import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanLimitBarComponent } from './plan-limit-bar.component';
import { RouterModule } from '@angular/router';
import { PopupDirectiveModule } from '@app/shared/directives/popup/popup.directive.module';
import { MatButtonModule } from '@angular/material/button';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CgxTooltipModule } from '@coralogix/design';

@NgModule({
  declarations: [PlanLimitBarComponent],
  imports: [CommonModule, RouterModule, PopupDirectiveModule, MatButtonModule, AngularSvgIconModule, CgxTooltipModule],
  exports: [PlanLimitBarComponent],
})
export class PlanLimitBarModule {}
