import { Component, Input, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'sh-basic-input',
  templateUrl: './basic-input.component.html',
  styleUrls: ['./basic-input.component.scss']
})
export class BasicInputComponent implements ControlValueAccessor {

  @Input() public inputType: string = 'text';
  @Input() public tooltip: string = '';
  @Input() public label: string = 'Rule Group Name';
  @Input() public isRequired: boolean = false;
  @Input() public placeholder: string = '';
  @Input() public errorMsg: string = 'This field is required';
  @Input() public formControl: FormControl;
  @Input() public fontFamilyOptions: EFontFamilyOptions;
  public fontFamilyOpt: typeof EFontFamilyOptions = EFontFamilyOptions;
  public onChange: (value: number | string) => void;
  public onTouched: () => void;
  public value: string | number;

  constructor(@Self() public controlDir: NgControl) {
    this.controlDir.valueAccessor = this;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: number | string): void {
    this.value = value;
  }
}

export enum EFontFamilyOptions {
  consolas,
  nunitoSans
}
