import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'sh-coralogix-number-input',
    templateUrl: 'coralogix-number-input.component.html',
    styleUrls: ['coralogix-number-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CoralogixNumberInputComponent,
        multi: true
    }],
})
export class CoralogixNumberInputComponent implements ControlValueAccessor {
    @Input()
    public itemSingleName: string;
    @Input()
    public itemPluralName: string;
    @Input()
    public minValue: number;
    @Input()
    public maxValue: number;
    @Input()
    public showValue: boolean = true;
    @Input()
    public step: number;

    public value: number = 0;
    public showText: boolean = true;
    public disabled: boolean;

    private readonly symbolWidth: number = 10.3;

    private onChangeFn: (val: any) => any;
    private onTouchedFn: (val?: any) => any;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public get labelText(): string {
        return this.value === 1 ? this.itemSingleName : this.itemPluralName;
    }

    public get inputWidth(): number {
        return this.value ? this.value.toString().length * this.symbolWidth : this.symbolWidth;
    }

    public onFocus(): void {
        this.showText = false;
    }

    public onBlur(): void {
        this.showText = true;
        if (this.value < this.minValue) {
            this.value = this.minValue;
        }
        if (this.value > this.maxValue) {
          this.value = this.maxValue;
        }
    }

    public increaseValue(): void {
        if (this.value >= this.maxValue ) {
            return;
        }

        if (this.step !== null && this.step !== undefined) {
          this.value += this.step;
        } else {
          this.value++;
        }

        this.onModelChange(this.value);
    }

    public decreaseValue(): void {
        if (this.value <= this.minValue) {
            return;
        }

        if (this.step !== null && this.step !== undefined) {
          this.value -= this.step;
        } else {
          this.value--;
        }

        this.onModelChange(this.value);
    }

    public registerOnChange(fn: (val: any) => any): void {
        this.onChangeFn = fn;
    }

    public registerOnTouched(fn: (val: any) => any): void {
        this.onTouchedFn = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onModelChange(value: any): void {
      this.value = value;
      if (this.changeDetectorRef) {
        this.changeDetectorRef.markForCheck();
      }
      if (this.onChangeFn) {
        this.onChangeFn(this.value);
      }
      if (this.onTouchedFn) {
        this.onTouchedFn(this.value);
      }
    }

    public writeValue(value: any): void {
      this.onModelChange(value);
    }

}
