import { Injectable } from '@angular/core';
import { enumsTranslator } from '@app/shared/config/enums-translator';
import { QuotaPolicyService } from '@app/tco/services/quota-policy/quota-policy.service';
import { PolicyOverride } from '@app/tco/models/policy-override.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { subDays } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { SortDirection } from '@angular/material/sort';

import { IQuotaPeriodOptionDictionary, QuotaState } from '../quota/quota.state';
import {
  CancelEditOverride,
  Destroy,
  EditOverride,
  GetOverrides,
  Loading,
  ResetAllOverridesSuccess,
  SetEmptyStateAvailable,
  SetOverride,
  TogglePolicyOverrides,
  UpdateAppNames,
  UpdatePaginationProps,
  UpdateSearchTerm,
  UpdateSelectedPolicyStatus,
  UpdateSelectedPriorities,
  UpdateSortActive,
  UpdateSubNames
} from './quota-policy-overrides.action';
import { Priority } from '@app/tco/enums/priority.enum';
import { PolicyStatus } from '@app/tco/enums/policy-status.enum';
import { intersection, startCase, unionBy } from 'lodash';
import { PolicyOverrideSeverity } from '@app/tco/models/policy-override-severity.model';
import { ISetOverridesRequestBody } from '@app/tco/interfaces/set-overrides-request-body.interface';
import { ShDialogService } from '@app/shared/services/dialogService';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';
import { CompanyState } from '../company/company.state';
import { CompanyPlanDetails, GIGA_PRICE_PER_DOLLAR } from '@app/user/shared/models/company-plan-details.model';
import { IPrioritiesChartData } from '@app/tco/interfaces/priorities-chart-data.interface';
import { buildNamesHierarchy, evalNewSelectedSubsystemNames, evalSubsystemNames } from './quota-policy-overrides.helper';

export type SortActiveType = 'quota' | 'applicationName' | 'subsystemName';

export interface ILabelValue {
  label: string;
  value: any;
}

export interface IPolicyOverridesFiltersModel {
  selectedPriorities: Priority[];
  selectedPolicyStatus: PolicyStatus[];
  selectedApplicationNames: string[];
  selectedSubsystemNames: string[];
  searchTerm: string;
  enablePolicyOverrides: boolean;
  sortActive: SortActiveType;
  sortDirection: SortDirection;
  pageSize: number;
  pageIndex: number;
}

const initialFilter = (): IPolicyOverridesFiltersModel => ({
  selectedPriorities: [],
  selectedPolicyStatus: [],
  selectedApplicationNames: [],
  selectedSubsystemNames: [],
  searchTerm: '',
  enablePolicyOverrides: true,
  sortActive: 'quota',
  sortDirection: 'desc',
  pageSize: 20,
  pageIndex: 0
});

export interface IQuotaPolicyOverridesStateModel {
  policyStatusOptions: ILabelValue[];
  priorityOptions: ILabelValue[];
  names: { [name: string]: { [name: string]: boolean } };
  filters: IPolicyOverridesFiltersModel;
  expandedElement: PolicyOverride;
  overrides: { [id: string]: PolicyOverride };
  overrideIds: string[];
  loading: boolean;
  loadingEditPolicyOverrideSeverity: boolean;
  emptyStateAvailable: boolean;
}

const initialState = (): IQuotaPolicyOverridesStateModel => ({
  policyStatusOptions: [
    {
      label: startCase(enumsTranslator.PolicyStatus[PolicyStatus.NoPolicy]),
      value: PolicyStatus.NoPolicy
    },
    {
      label: startCase(enumsTranslator.PolicyStatus[PolicyStatus.Policy]),
      value: PolicyStatus.Policy
    }
  ],
  priorityOptions: [
    {
      label: startCase(enumsTranslator.Priority[Priority.Block]),
      value: Priority.Block
    },
    {
      label: startCase(enumsTranslator.Priority[Priority.High]),
      value: Priority.High
    },
    {
      label: startCase(enumsTranslator.Priority[Priority.Low]),
      value: Priority.Low
    },
    {
      label: startCase(enumsTranslator.Priority[Priority.Medium]),
      value: Priority.Medium
    },
  ],
  names: {},
  filters: initialFilter(),
  expandedElement: null,
  overrides: {},
  overrideIds: [],
  loading: false,
  loadingEditPolicyOverrideSeverity: false,
  emptyStateAvailable: false
});

@State<IQuotaPolicyOverridesStateModel>({
  name: 'quotaPolicyOverrides',
  defaults: initialState(),
})
@Injectable()
export class QuotaPolicyOverridesState {
  @SelectSnapshot(QuotaState.quotaPeriodOptions) public quotaPeriodOptions: IQuotaPeriodOptionDictionary;

  @SelectSnapshot(QuotaState.selectedQuotaPeriodKey) public selectedQuotaPeriodKey: string;

  constructor(
    private quotaPolicyService: QuotaPolicyService,
    private dialogService: ShDialogService,
  ) { }
  @Selector()
  public static filters(state: IQuotaPolicyOverridesStateModel): IPolicyOverridesFiltersModel {
    return state.filters;
  }

  @Selector()
  public static expandedElement(state: IQuotaPolicyOverridesStateModel): PolicyOverride {
    return state.expandedElement;
  }

  @Selector()
  public static overrides(state: IQuotaPolicyOverridesStateModel): { [id: string]: PolicyOverride } {
    return state.overrides;
  }

  @Selector()
  public static overrideIds(state: IQuotaPolicyOverridesStateModel): string[] {
    return state.overrideIds;
  }

  @Selector()
  public static loading(state: IQuotaPolicyOverridesStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static policyStatusOptions(state: IQuotaPolicyOverridesStateModel): ILabelValue[] {
    return state.policyStatusOptions;
  }

  @Selector()
  public static priorityOptions(state: IQuotaPolicyOverridesStateModel): ILabelValue[] {
    return state.priorityOptions;
  }

  @Selector()
  public static enablePolicyOverrides(state: IQuotaPolicyOverridesStateModel): boolean {
    return state.filters.enablePolicyOverrides;
  }

  @Selector()
  public static loadingEditPolicyOverrideSeverity(state: IQuotaPolicyOverridesStateModel): boolean {
    return state.loadingEditPolicyOverrideSeverity;
  }

  @Selector()
  public static emptyStateAvailable(state: IQuotaPolicyOverridesStateModel): boolean {
    return state.emptyStateAvailable;
  }

  @Selector()
  public static names(state: IQuotaPolicyOverridesStateModel): { [name: string]: { [name: string]: boolean } } {
    return state.names;
  }

  @Selector([QuotaPolicyOverridesState.names])
  public static applicationNames(names: { [name: string]: { [name: string]: boolean } }): string[] {
    return Object.keys(names);
  }

  @Selector([QuotaPolicyOverridesState.names, QuotaPolicyOverridesState.selectedApplicationNames])
  public static subsystemNames(names: { [name: string]: { [name: string]: boolean } }, selectedApplicationNames: string[]): string[] {
    return evalSubsystemNames(names, selectedApplicationNames);
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static selectedApplicationNames(filters: IPolicyOverridesFiltersModel): string[] {
    return filters.selectedApplicationNames;
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static selectedSubsystemNames(filters: IPolicyOverridesFiltersModel): string[] {
    return filters.selectedSubsystemNames;
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static searchTerm(filters: IPolicyOverridesFiltersModel): string {
    return filters.searchTerm;
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static selectedPriorities(filters: IPolicyOverridesFiltersModel): Priority[] {
    return filters.selectedPriorities;
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static pageIndex(filters: IPolicyOverridesFiltersModel): number {
    return filters.pageIndex;
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static pageSize(filters: IPolicyOverridesFiltersModel): number {
    return filters.pageSize;
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static selectedPolicyStatus(filters: IPolicyOverridesFiltersModel): PolicyStatus[] {
    return filters.selectedPolicyStatus;
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static sortActive(filters: IPolicyOverridesFiltersModel): SortActiveType {
    return filters.sortActive;
  }

  @Selector([QuotaPolicyOverridesState.filters])
  public static sortDirection(filters: IPolicyOverridesFiltersModel): SortDirection {
    return filters.sortDirection;
  }

  @Selector([QuotaPolicyOverridesState.selectedPriorities])
  public static selectedPrioritiesText(selectedPriorities: string[]): string {
    const prioritiesLength = Object.values(enumsTranslator.Priority).length;
    if (selectedPriorities.length === prioritiesLength) {
      return 'All';
    } else if (selectedPriorities.length === 1) {
      return startCase(enumsTranslator.Priority[selectedPriorities[0]]);
    } else {
      return selectedPriorities.length ? `(${selectedPriorities.length}) Selected` : '';
    }
  }

  @Selector([QuotaPolicyOverridesState.selectedPolicyStatus])
  public static selectedPolicyStatusText(selectedPolicyStatus: string[]): string {
    const policyStatusLength = Object.values(enumsTranslator.PolicyStatus).length;
    if (selectedPolicyStatus.length === policyStatusLength) {
      return 'All';
    } else if (selectedPolicyStatus.length === 1) {
      return startCase(enumsTranslator.PolicyStatus[selectedPolicyStatus[0]]);
    } else {
      return selectedPolicyStatus.length ? `(${selectedPolicyStatus.length}) Selected` : '';
    }
  }

  @Selector([QuotaPolicyOverridesState.selectedApplicationNames])
  public static selectedApplicationNamesLabel(selectedApplicationNames: string[]): string {
    if (selectedApplicationNames.length === 0) {
      return '';
    } else if (selectedApplicationNames.length === 1) {
      return selectedApplicationNames[0];
    } else {
      return `${selectedApplicationNames.length} Selected`;
    }
  }

  @Selector([QuotaPolicyOverridesState.selectedSubsystemNames])
  public static selectedSubsystemNamesLabel(selectedSubsystemNames: string[]): string {
    if (selectedSubsystemNames.length === 0) {
      return '';
    } else if (selectedSubsystemNames.length === 1) {
      return selectedSubsystemNames[0];
    } else {
      return `${selectedSubsystemNames.length} Selected`;
    }
  }

  @Selector([
    QuotaPolicyOverridesState.overrides,
    QuotaPolicyOverridesState.overrideIds,
    QuotaPolicyOverridesState.selectedApplicationNames
  ])
  public static filteredOverrideListByApplicationNames(
    overrides: { [id: string]: PolicyOverride },
    overrideIds: string[],
    selectedApplicationNames: string[]
  ): string[] {
    if (!selectedApplicationNames.length) {
      return overrideIds;
    }
    return overrideIds.filter(id => selectedApplicationNames.includes(overrides[id].applicationName));
  }

  @Selector([
    QuotaPolicyOverridesState.overrides,
    QuotaPolicyOverridesState.overrideIds,
    QuotaPolicyOverridesState.selectedSubsystemNames
  ])
  public static filteredOverrideListBySubsystemNames(
    overrides: { [id: string]: PolicyOverride },
    overrideIds: string[],
    selectedSubsystemNames: string[]
  ): string[] {
    if (!selectedSubsystemNames.length) {
      return overrideIds;
    }
    return overrideIds.filter(id => selectedSubsystemNames.includes(overrides[id].subsystemName));
  }

  @Selector([
    QuotaPolicyOverridesState.overrides,
    QuotaPolicyOverridesState.overrideIds,
    QuotaPolicyOverridesState.searchTerm
  ])
  public static filteredOverrideListBySearchTerm(
    overrides: { [id: string]: PolicyOverride },
    overrideIds: string[],
    searchTerm: string
  ): string[] {
    if (!searchTerm) {
      return overrideIds;
    }
    return overrideIds.filter(id =>
      overrides[id].applicationName.includes(searchTerm) || overrides[id].subsystemName.includes(searchTerm));
  }

  @Selector([
    QuotaPolicyOverridesState.overrides,
    QuotaPolicyOverridesState.overrideIds,
    QuotaPolicyOverridesState.selectedPriorities
  ])
  public static filteredOverrideListByPriorities(
    overrides: { [id: string]: PolicyOverride },
    overrideIds: string[],
    selectedPriorities: Priority[]
  ): string[] {
    if (!selectedPriorities.length) {
      return overrideIds;
    }
    return overrideIds.filter(id => intersection(overrides[id].evalPriorities, selectedPriorities).length);
  }

  @Selector([
    QuotaPolicyOverridesState.overrides,
    QuotaPolicyOverridesState.overrideIds,
  ])
  public static overrideList(
    overrides: { [id: string]: PolicyOverride },
    overrideIds: string[]
  ): PolicyOverride[] {
    return overrideIds.map(id => overrides[id]);
  }

  @Selector([
    QuotaPolicyOverridesState.overrides,
    QuotaPolicyOverridesState.overrideIds,
    QuotaPolicyOverridesState.selectedPolicyStatus
  ])
  public static filteredOverrideListByPolicyStatus(
    overrides: { [id: string]: PolicyOverride },
    overrideIds: string[],
    selectedPolicyStatus: PolicyStatus[]
  ): string[] {
    if (!selectedPolicyStatus.length) {
      return overrideIds;
    }
    const includesNoPolicy = selectedPolicyStatus.includes(PolicyStatus.NoPolicy);
    const includesPolicy = selectedPolicyStatus.includes(PolicyStatus.Policy);
    const noPolicyFilteredOverrides = includesNoPolicy ? overrideIds.filter(id => overrides[id].hasAtLeastOneWithoutPolicy) : [];
    const policyFilteredOverrides = includesPolicy ? overrideIds.filter(id => overrides[id].hasAtLeastOnePolicy) : [];
    const filteredOverrides = unionBy(noPolicyFilteredOverrides, policyFilteredOverrides, (id) => id);
    return filteredOverrides;
  }

  @Selector([
    QuotaPolicyOverridesState.filteredOverrideListByApplicationNames,
    QuotaPolicyOverridesState.filteredOverrideListBySubsystemNames,
    QuotaPolicyOverridesState.filteredOverrideListBySearchTerm,
    QuotaPolicyOverridesState.filteredOverrideListByPriorities,
    QuotaPolicyOverridesState.filteredOverrideListByPolicyStatus
  ])
  public static intersectedFiltered(
    filteredOverrideListByApplicationNames: string[],
    filteredOverrideListBySubsystemNames: string[],
    filteredOverrideListBySearchTerm: string[],
    filteredOverrideListByPriorities: string[],
    filteredOverrideListByPolicyStatus: string[],
  ): string[] {
    return intersection(
      filteredOverrideListByApplicationNames,
      filteredOverrideListBySubsystemNames,
      filteredOverrideListBySearchTerm,
      filteredOverrideListByPriorities,
      filteredOverrideListByPolicyStatus
    );
  }

  @Selector([
    QuotaPolicyOverridesState.overrides,
    QuotaPolicyOverridesState.intersectedFiltered,
    QuotaPolicyOverridesState.sortActive,
    QuotaPolicyOverridesState.sortDirection
  ])
  public static sortedOverrides(
    overrides: PolicyOverride[],
    intersectedFiltered: string[],
    sortActive: SortActiveType,
    sortDirection: SortDirection
  ): string[] {
    const sortedOverrides: string[] = [...intersectedFiltered];

    if (sortActive === 'applicationName' || sortActive === 'subsystemName') {
      sortedOverrides.sort((idA, idB) => overrides[idA][sortActive].toLocaleLowerCase() > overrides[idB][sortActive].toLocaleLowerCase() ?
        1 : -1
      );
    } else if (sortActive === 'quota') {
      sortedOverrides.sort((idA, idB) => overrides[idA].totalSizeFactorized - overrides[idB].totalSizeFactorized);
    }

    if (sortDirection === 'desc') {
      sortedOverrides.reverse();
    }
    return sortedOverrides;
  }

  @Selector([QuotaPolicyOverridesState.intersectedFiltered])
  public static totalFilteredOverrideList(intersectedFiltered: PolicyOverride[]): number {
    return intersectedFiltered.length;
  }

  @Selector([
    QuotaPolicyOverridesState.sortedOverrides,
    QuotaPolicyOverridesState.overrides,
    QuotaPolicyOverridesState.pageIndex,
    QuotaPolicyOverridesState.pageSize
  ])
  public static paginatedOverrideList(
    sortedOverrides: string[],
    overrides: { [id: string]: PolicyOverride },
    pageIndex: number,
    pageSize: number
  ): PolicyOverride[] {
    return sortedOverrides.slice(pageSize * pageIndex, pageSize * (pageIndex + 1)).map(id => overrides[id]);
  }

  @Selector([QuotaPolicyOverridesState.emptyStateAvailable, QuotaPolicyOverridesState.overrideIds])
  public static showEmptyState(emptyStateAvailable: boolean, overrideIds: string[]): boolean {
    return emptyStateAvailable && overrideIds.length === 0;
  }

  @Selector([QuotaPolicyOverridesState.overrideList])
  public static totalSize(overrideList: PolicyOverride[]): number {
    return overrideList.reduce((acc, override) => {
      acc += override.totalSize;
      return acc;
    }, 0);
  }

  @Selector([QuotaPolicyOverridesState.overrideList, QuotaPolicyOverridesState.totalSize])
  public static percentageOfQuotaSaving(overrideList: PolicyOverride[], totalSize: number): number {
    const totalSizeFactorized = overrideList.reduce((acc, override) => {
      acc += override.totalSizeFactorized;
      return acc;
    }, 0);

    const percentageOfQuotaSaving = Math.round((1 - (totalSizeFactorized / totalSize)) * 100);
    return percentageOfQuotaSaving || 0;
  }

  @Selector([QuotaPolicyOverridesState.percentageOfQuotaSaving, CompanyState.planDetails])
  public static yearlySavings(percentageOfQuotaSaving: number, planDetails: CompanyPlanDetails): number {
    const monthlySize = planDetails?.size || 0;
    const quotaUsageAnnualCost = (monthlySize * 12) * GIGA_PRICE_PER_DOLLAR;
    const yearlySavings = Math.round(quotaUsageAnnualCost * (percentageOfQuotaSaving / 100.0));

    return yearlySavings || 0;
  }

  @Selector([QuotaPolicyOverridesState.overrideList, QuotaPolicyOverridesState.totalSize])
  public static priorityChartsData(overrideList: PolicyOverride[], totalSize: number): IPrioritiesChartData {
    const { block: blockUsage, low: lowUsage, medium: mediumUsage, high: highUsage } = overrideList.reduce((acc, override) => {
      const severitiesSum = override.policyOverrideSeverities.reduce((accPolicyOverrideSeverities, policyOverrideSeverity) => {
        accPolicyOverrideSeverities[policyOverrideSeverity.evalPriority] += policyOverrideSeverity.totalSize;
        return accPolicyOverrideSeverities;
      }, { low: 0, medium: 0, high: 0, block: 0 });
      acc.low += severitiesSum.low;
      acc.block += severitiesSum.block;
      acc.medium += severitiesSum.medium;
      acc.high += severitiesSum.high;
      return acc;
    }, { low: 0, medium: 0, high: 0, block: 0 });

    const low = ((lowUsage + blockUsage) / totalSize) * 100;
    const medium = (mediumUsage / totalSize) * 100;
    const high = (highUsage / totalSize) * 100;
    const prioritiesChartDataAsPercentages: IPrioritiesChartData = {
      low,
      medium,
      high
    };
    return prioritiesChartDataAsPercentages;
  }

  @Action(Destroy)
  public Destroy(ctx: StateContext<IQuotaPolicyOverridesStateModel>): IQuotaPolicyOverridesStateModel {
    return ctx.setState(initialState());
  }

  @Action(Loading)
  public Loading(ctx: StateContext<IQuotaPolicyOverridesStateModel>, { loading }: Loading): IQuotaPolicyOverridesStateModel {
    return ctx.patchState({ loading });
  }

  @Action(UpdateSearchTerm)
  public UpdateSearchTerm(ctx: StateContext<IQuotaPolicyOverridesStateModel>,
    { searchTerm }: UpdateSearchTerm): IQuotaPolicyOverridesStateModel {
    return ctx.patchState({ filters: { ...ctx.getState().filters, searchTerm, pageIndex: 0 } });
  }

  @Action(UpdateSelectedPriorities)
  public UpdateSelectedPriorities(ctx: StateContext<IQuotaPolicyOverridesStateModel>,
    { selectedPriorities }: UpdateSelectedPriorities): IQuotaPolicyOverridesStateModel {
    return ctx.patchState({ filters: { ...ctx.getState().filters, selectedPriorities, pageIndex: 0 } });
  }

  @Action(UpdateSelectedPolicyStatus)
  public UpdateSelectedPolicyStatus(ctx: StateContext<IQuotaPolicyOverridesStateModel>,
    { selectedPolicyStatus }: UpdateSelectedPolicyStatus): IQuotaPolicyOverridesStateModel {
    return ctx.patchState({ filters: { ...ctx.getState().filters, selectedPolicyStatus, pageIndex: 0 } });
  }

  @Action(UpdateAppNames)
  public UpdateAppNames(ctx: StateContext<IQuotaPolicyOverridesStateModel>,
    { selectedApplicationNames }: UpdateAppNames): IQuotaPolicyOverridesStateModel {
    const { names, filters } = ctx.getState();
    const { selectedSubsystemNames } = filters;
    const newSubsystemNames = evalSubsystemNames(names, selectedApplicationNames);
    const newSelectedSubsystemNames = intersection(selectedSubsystemNames, newSubsystemNames);
    return ctx.patchState({
      filters: {
        ...ctx.getState().filters,
        selectedApplicationNames,
        selectedSubsystemNames: newSelectedSubsystemNames,
        pageIndex: 0
      }
    });
  }

  @Action(UpdateSubNames)
  public UpdateSubNames(ctx: StateContext<IQuotaPolicyOverridesStateModel>,
    { selectedSubsystemNames }: UpdateSubNames): IQuotaPolicyOverridesStateModel {
    return ctx.patchState({ filters: { ...ctx.getState().filters, selectedSubsystemNames, pageIndex: 0 } });
  }

  @Action(TogglePolicyOverrides)
  public TogglePolicyOverrides(ctx: StateContext<IQuotaPolicyOverridesStateModel>): IQuotaPolicyOverridesStateModel {
    const enablePolicyOverrides = !ctx.getState().filters.enablePolicyOverrides;
    return ctx.patchState({ filters: { ...ctx.getState().filters, enablePolicyOverrides } });
  }

  @Action(SetEmptyStateAvailable)
  public SetEmptyStateAvailable(
    ctx: StateContext<IQuotaPolicyOverridesStateModel>,
    { available }: SetEmptyStateAvailable): IQuotaPolicyOverridesStateModel {
    return ctx.patchState({ emptyStateAvailable: available });
  }

  @Action(GetOverrides)
  public GetOverrides(ctx: StateContext<IQuotaPolicyOverridesStateModel>): Observable<IQuotaPolicyOverridesStateModel> {
    const selectedQuotaPeriod = this.quotaPeriodOptions[this.selectedQuotaPeriodKey];
    const startDate = subDays(new Date(), selectedQuotaPeriod.value);
    const endDate = new Date();
    ctx.dispatch(new Loading(true));
    return this.quotaPolicyService.getOverridesAndFactors(startDate, endDate).pipe(
      map(({ factors, overrides }) => {
        PolicyOverrideSeverity.factors = factors;
        const { filters } = ctx.getState();
        const { selectedApplicationNames, selectedSubsystemNames } = filters;
        const overrideIds = overrides.map(override => override.id);
        const overrideMap = overrides.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
        // TODO: const names = buildNamesHierarchy(overrides);
        const names = buildNamesHierarchy(overrides);
        const newApplicationNames = Object.keys(names);
        const newSelectedApplicationNames = intersection(newApplicationNames, selectedApplicationNames);
        const newSelectedSubsystemNames = evalNewSelectedSubsystemNames(names, selectedSubsystemNames);
        return ctx.patchState({
          overrides: overrideMap,
          overrideIds,
          filters: {
            ...filters,
            pageIndex: 0,
            selectedApplicationNames: newSelectedApplicationNames,
            selectedSubsystemNames: newSelectedSubsystemNames
          }, names
        });
      }),
      catchError(() => {
        this.dialogService.showCoralogixMessage('ERROR: Failed to get overrides information', null, dialogServiceIconClasses.failed);
        return of(ctx.getState());
      }),
      finalize(() => {
        ctx.dispatch(new Loading(false));
        ctx.dispatch(new SetEmptyStateAvailable(true));
      })
    );
  }

  @Action(EditOverride)
  public EditOverride(
    ctx: StateContext<IQuotaPolicyOverridesStateModel>,
    { policyOverride }: EditOverride): IQuotaPolicyOverridesStateModel {
    return ctx.patchState({ expandedElement: policyOverride });
  }

  @Action(CancelEditOverride)
  public CancelEditOverride(
    ctx: StateContext<IQuotaPolicyOverridesStateModel>): IQuotaPolicyOverridesStateModel {
    return ctx.patchState({ expandedElement: null });
  }

  @Action(UpdatePaginationProps)
  public UpdatePaginationProps(
    ctx: StateContext<IQuotaPolicyOverridesStateModel>, { pageIndex, pageSize }: UpdatePaginationProps): IQuotaPolicyOverridesStateModel {
    const { filters } = ctx.getState();
    return ctx.patchState({ filters: { ...filters, pageIndex, pageSize } });
  }

  @Action(UpdateSortActive)
  public UpdateSortActive(
    ctx: StateContext<IQuotaPolicyOverridesStateModel>, { sortActive }: UpdateSortActive): IQuotaPolicyOverridesStateModel {
    const { filters } = ctx.getState();
    const { sortDirection } = filters;

    const oppositeSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    const newSortDirection = filters.sortActive === sortActive ? oppositeSortDirection : 'desc';

    return ctx.patchState({ filters: { ...filters, sortActive, sortDirection: newSortDirection, pageIndex: 0 } });
  }

  @Action(SetOverride)
  public SetOverride(
    ctx: StateContext<IQuotaPolicyOverridesStateModel>, { override }: SetOverride): Observable<IQuotaPolicyOverridesStateModel> {
    const { overrides } = ctx.getState();
    const oldOverride = overrides[override.id];

    const body: ISetOverridesRequestBody[] = override.policyOverrideSeverities.map(policyOverrideSeverities => ({
      id: policyOverrideSeverities.id,
      priority: policyOverrideSeverities.priority,
      severity: policyOverrideSeverities.severity,
      applicationName: override.applicationName,
      subsystemName: override.subsystemName
    }));
    ctx.patchState({ loadingEditPolicyOverrideSeverity: true });
    return this.quotaPolicyService.setOverrides(body).pipe(
      map(overridesResponse => {
        let hasError: boolean = false;
        overridesResponse.forEach(overrideResponse => {
          const policyOverrideSeverityIndex = override.policyOverrideSeverities.findIndex(
            policyOverrideSeverity => policyOverrideSeverity.severity === overrideResponse.override.severity);
          if (overrideResponse.status === 200) {
            override.policyOverrideSeverities[policyOverrideSeverityIndex].id = overrideResponse.override.id;
          } else {
            hasError = true;
            const oldPriority = oldOverride.policyOverrideSeverities[policyOverrideSeverityIndex].priority;
            override.policyOverrideSeverities[policyOverrideSeverityIndex].priority = oldPriority;
          }
        });
        if (hasError) {
          this.dialogService.showCoralogixMessage('Failed to update some priorities', null, dialogServiceIconClasses.failed);
        }
        const newOverride = PolicyOverride.create(override);

        return ctx.patchState({
          overrides: { ...overrides, [newOverride.id]: newOverride },
          expandedElement: null
        });
      }),
      catchError(() => {
        this.dialogService.showCoralogixMessage('Error: Request failed', null, dialogServiceIconClasses.failed);
        return of(ctx.patchState({
          overrides: { ...overrides, [oldOverride.id]: oldOverride },
          expandedElement: oldOverride
        }));
      }),
      finalize(() => {
        ctx.patchState({ loadingEditPolicyOverrideSeverity: false });
      })
    );
  }

  @Action(ResetAllOverridesSuccess)
  public ResetAllOverridesSuccess(
    ctx: StateContext<IQuotaPolicyOverridesStateModel>, { response }: ResetAllOverridesSuccess): IQuotaPolicyOverridesStateModel {
    let hasError = false;
    const newOverrides = { ...ctx.getState().overrides };
    response.forEach(setOverridesResponse => {
      if (setOverridesResponse.status !== 200) {
        hasError = true;
      } else {
        const policyOverrideId = `${setOverridesResponse.override.applicationName}|${setOverridesResponse.override.subsystemName}`;
        const override = PolicyOverride.create(newOverrides[policyOverrideId]);
        const severityIndex = override.policyOverrideSeverities
          .findIndex(policyOverrideSeverity => policyOverrideSeverity.severity === setOverridesResponse.override.severity);
        override.policyOverrideSeverities[severityIndex].priority = null;
        override.policyOverrideSeverities[severityIndex].id = undefined;
        newOverrides[policyOverrideId] = override;
      }
    });
    if (hasError) {
      this.dialogService.showCoralogixMessage('Error: Some overrides failed to reset', null, dialogServiceIconClasses.failed);
    }

    return ctx.patchState({ overrides: newOverrides });
  }
}
