import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { getSelectedTeam, getSelectedTeamId, getTeamsExceptFromSelected, State } from '@app/app.reducers';
import { AuthService } from '@app/auth/shared/services/auth.service';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Teammate } from '@app/shared/models/teammate.model';
import { Team } from '@app/user/shared/models/team';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { TeamActions } from '@app/user/state/teams/teams.actions';
import { Store } from '@ngrx/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { ThemeService } from '@app/logs-new/shared/services/theme.service';
import { UserSettingsService } from '@app/user/shared/user-settings.service';
import { CdkScrollable } from '@angular/cdk/overlay';

@Component({
  selector: 'sh-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @ViewChild(CdkScrollable) scrollContainer: CdkScrollable;

  @Select(UserState.username) public username$: Observable<string>;

  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  @SelectSnapshot(UserState.user) public user: Teammate;

  @Input()
  set isOpened(value: boolean) {
    this.search$.next('');
    if (value === true && this.scrollContainer) {
      this.scrollContainer.scrollTo({ top: 1, behavior: 'smooth' });
    }
  }

  teams$: Observable<Team[]> = this.store.select(getTeamsExceptFromSelected);

  selectedTeamId$: Observable<number> = this.store.select(getSelectedTeamId);

  selectedTeam$: Observable<Team> = this.store.select(getSelectedTeam);

  search$: BehaviorSubject<string> = new BehaviorSubject('');

  search = '';

  @Output() close = new EventEmitter<void>();

  destroyed$: Subject<void> = new Subject();

  constructor(
    private store: Store<State>,
    private router: Router,
    private authService: AuthService,
    private userSettingsProvider: UserSettingsProvider,
    private cdr: ChangeDetectorRef,
    public themeService: ThemeService,
    private userSettingsService: UserSettingsService,
  ) {}

  ngOnInit(): void {
    this.search$.pipe(debounceTime(250), takeUntil(this.destroyed$)).subscribe(value => {
      this.search = value;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  createNewTeam(): void {
    this.router.navigate(['/login/createTeam']);
  }

  navToSettings(): void {
    if (this.user !== null && this.user.id !== null) {
      this.router.navigate(['/settings/account']);
    }
    this.close.emit();
  }

  logout(): void {
    this.userSettingsProvider.userSettings = null;
    window.Intercom('shutdown');
    this.selectedTeamId$.pipe(take(1)).subscribe(id => {
      this.authService.logout().subscribe(
        res => this.authService.reloadApp(id),
        err => this.authService.reloadApp(id),
      );
    });
    this.close.emit();
  }

  switchTeam(team: Team): void {
    this.store.dispatch(new TeamActions.SelectTeam({ teamId: team.id, isNew: false }));
    this.close.emit();
  }

  searchChanged(value: string): void {
    this.search$.next(value);
  }

  toggleThemeMode(): void {
    this.themeService.toggleTheme();
    this.userSettingsProvider.userSettings.logsTheme = this.themeService.theme;
    this.userSettingsService.updateUserSettings(this.userSettingsProvider.userSettings).subscribe();
  }

  public trackByFn(index: number, team: Team): number {
    return team.id;
  }
}
