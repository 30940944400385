<mat-card
  class="coralogix"
  *ngIf="form"
>
  <p class="metric-card-header">Details</p>
  <form [formGroup]="form">
    <div class="coralogix input-group">
      <label class="metric-card-label">Metric Name</label>
      <div
        class="has-validation"
        [class.coralogix-input-error]="hasError('metricName', 'required')"
      >
        <input
          type="text"
          placeholder="Enter a name"
          class="form-control"
          formControlName="metricName"
        >
        <span
          *ngIf="hasError('metricName', 'required')"
          class="text-cgx-error"
        >
          Metric name is required
        </span>
      </div>
    </div>
    <div class="coralogix input-group">
      <label class="metric-card-label">Metric Description</label>
      <div class="has-validation">
        <textarea
          type="text"
          placeholder="Enter a description"
          class="form-control autoresizable"
          formControlName="description"
        ></textarea>
      </div>
    </div>
  </form>
</mat-card>

