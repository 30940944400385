import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { LogsView } from '../../shared/models/logs-view.model';
import { Store } from '@ngrx/store';
import { getLogsGridSavedViews, State } from '../../../app.reducers';
import { LogsViewsActions } from '../../shared/state/logs-views/logs-views.actions';
import { ShDialogService } from '../../../shared/services/dialogService';

@Component({
  selector: 'sh-logs-open-view',
  templateUrl: './logs-open-view.component.html',
  styleUrls: ['./logs-open-view.component.scss'],
})
export class LogsOpenViewComponent implements OnInit {
  @Output() public onOpen: EventEmitter<any> = new EventEmitter<any>();

  public logsGridSavedViews$: Observable<LogsView[]>;
  public filterType = 'myViews';
  public stringFilter = '';
  public savedViewsFilter = {
    type: this.filterType,
    filter: this.stringFilter,
  };

  constructor(private store: Store<State>, private shDialog: ShDialogService) {}

  public ngOnInit() {
    this.logsGridSavedViews$ = this.store
      .select(getLogsGridSavedViews)
      .map((views) => views.savedViews);
  }

  public selectView(logView) {
    this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(logView.id));
    this.onOpen.emit();
  }

  public deleteView(logView) {
    let title = 'Delete view';
    let message = `You are about to delete view "${logView.name}", are you sure you want to proceed?`;

    if (!logView.isMine) {
      title = 'WARNING';
      message = `You are about to delete a view("${logView.name}") that is not yours!, are you sure you want to proceed?`;
    }

    this.shDialog
      .openDialog(title, message, 'Delete')
      .first()
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(
            new LogsViewsActions.DeleteLogsViewAction(logView.id),
          );
        }
      });
  }

  public filterTypeChanged(value) {
    this.filterType = value;
    this.savedViewsFilter = {
      type: this.filterType,
      filter: this.stringFilter,
    };
  }

  public stringFilterChanged(value) {
    this.stringFilter = value.trim();
    this.savedViewsFilter = {
      type: this.filterType,
      filter: this.stringFilter,
    };
  }
}
