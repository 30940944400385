import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/observable/of';
import { State } from '../app.reducers';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ShDialogService } from '@shared/services/dialogService';
import { RulesMainViewMode } from '@app/rules/shared/models/shared-vew.models';
import { ParsingThemesApiService } from '@app/rules/shared/services/parsing-themes-api.service';
import * as parsingThemesActions from '@app/rules/actions/parsing-themes.actions';
import {
  IParsingTheme,
  IToggleParsingThemeAction,
  ParsingThemesResponse
} from '@app/rules/shared/models/rule.model';

import { Router } from '@angular/router';
import { RulesBaseUrl } from '@app/rules/shared/models/rules-consts';
import { Location } from '@angular/common';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';

@Injectable()
export class ParsingThemesEffects {
  @Effect()
  public getAllParsingThemes$: Observable<Action> = this.actions$.pipe(
    ofType(parsingThemesActions.getParsingThemesAction),
    switchMap(() => {
      return this.parsingThemeService.getParsingThemes().pipe(switchMap((data: ParsingThemesResponse) => {
        // patch - fix the server order bug
        const ptLength = data.parsingThemes?.length;
        if (ptLength && data.parsingThemes[ptLength - 1].order !== ptLength) {
          return this.parsingThemeService.reorderParsingThemes(data.parsingThemes.map(pt => pt.id)).pipe(
            map(() => parsingThemesActions.getParsingThemesCompletedAction({
              payload: {
                ...data,
                parsingThemes: data.parsingThemes.map((pt, index) => ({ ...pt, order: index + 1 }))
              }
            })),
          ).catch((err) => of(parsingThemesActions.getParsingThemesFailedAction(err)));
        } else {
          return of(parsingThemesActions.getParsingThemesCompletedAction({ payload: data }));
        }
      })).catch((err) => {
          return of(parsingThemesActions.getParsingThemesFailedAction(err));
      });
    }));

  @Effect()
  public getAllParsingThemesFailed$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.getParsingThemesFailedAction),
    switchMap((action: {payload: any}) => {
      return of(parsingThemesActions.setParsingThemeMainViewAction({ payload: RulesMainViewMode.welcome }));
    })
  );

  @Effect()
  public updateParsingTheme$: Observable<Action> = this.actions$.pipe(
    ofType(parsingThemesActions.updateParsingThemeAction),
    switchMap((action: {payload: IParsingTheme}) => {
      return this.parsingThemeService.saveParsingTheme(action.payload)
        .map((data: IParsingTheme) => parsingThemesActions.updateParsingThemeCompletedAction({
          payload : data
        }))
        .catch((err) => of(parsingThemesActions.updateParsingThemeFailedAction(err)));
    }),
  );

  @Effect({ dispatch: false })
  public updateParsingThemeCompletedAction: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.updateParsingThemeCompletedAction),
    tap(() => this.closeParsingThemePanelAndShowMessage('Rule group updated'))
  );

  @Effect({ dispatch: false })
  public updateParsingThemeFailed$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.updateParsingThemeFailedAction),
    tap(() => this.closeParsingThemePanelAndShowMessage('Failed to update rule group', dialogServiceIconClasses.failed))
  );

  @Effect()
  public createParsingThemeAction$: Observable<Action> = this.actions$.pipe(
    ofType(parsingThemesActions.createParsingThemeAction),
    switchMap((action: {payload: IParsingTheme}) => {
      return this.parsingThemeService.createParsingTheme(action.payload)
        .map((data) => parsingThemesActions.createParsingThemeCompletedAction({
          payload:  data
        }))
        .catch((err) => of(parsingThemesActions.createParsingThemeFailedAction(err)));
    }),
  );

  @Effect()
  public toggleParsingThemeAction$: Observable<Action> = this.actions$.pipe(
    ofType(parsingThemesActions.toggleParsingThemeAction),
    switchMap((action: {payload: IToggleParsingThemeAction}) => {
      return this.parsingThemeService.toggleParsingTheme(action.payload)
        .map(() => parsingThemesActions.toggleParsingThemeCompletedAction({ payload:  action.payload }))
        .catch(() => of(parsingThemesActions.toggleParsingThemeFailedAction({ payload:  action.payload })));
    }),
  );

  @Effect({ dispatch: false })
  public toggleParsingThemeCompletedAction$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.toggleParsingThemeCompletedAction),
    tap((action: {payload: IToggleParsingThemeAction}) => {
      this.dialogService.showCoralogixMessage(`Rule group ${action.payload.toggleState ? 'activated' : 'deactivated'}`);
    })
  );

  @Effect({ dispatch: false })
  public toggleParsingThemeFailedAction$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.toggleParsingThemeFailedAction),
    tap(() => this.dialogService.showCoralogixMessage('Failed to toggle rule group', null, dialogServiceIconClasses.failed))
  );

  @Effect()
  public updateParsingThemesOrderAction$: Observable<Action> = this.actions$.pipe(
    ofType(parsingThemesActions.updateParsingThemesOrderAction),
    switchMap((action: {payload: IParsingTheme[]}) => {
      const newOrdersArr: string[] = action.payload.map(pt => pt.id);
      return this.parsingThemeService.reorderParsingThemes(newOrdersArr)
        .map(() => parsingThemesActions.updateParsingThemesOrderCompletedAction(action))
        .catch(() => of(parsingThemesActions.updateParsingThemesOrderFailedAction(action)));
    }),
  );

  @Effect({ dispatch: false })
  public updateParsingThemesOrderCompletedAction$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.updateParsingThemesOrderCompletedAction),
    tap(() => {
      this.dialogService.showCoralogixMessage('Reordered successfully');
    })
  );

  @Effect({ dispatch: false })
  public updateParsingThemesOrderFailedAction$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.updateParsingThemesOrderFailedAction),
    tap(() => this.dialogService.showCoralogixMessage('Failed to reorder', null, dialogServiceIconClasses.failed))
  );

  @Effect({ dispatch: false })
  public createParsingThemeCompletedAction$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.createParsingThemeCompletedAction),
    tap(() => this.closeParsingThemePanelAndShowMessage('Rule group created'))
  );

  @Effect({ dispatch: false })
  public createParsingThemeActionFailed$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.createParsingThemeFailedAction),
    tap((err) => this.closeParsingThemePanelAndShowMessage(err.error, dialogServiceIconClasses.failed))
  );

  @Effect()
  public deleteParsingThemeAction$: Observable<Action> = this.actions$.pipe(
    ofType(parsingThemesActions.deleteParsingThemeAction),
    switchMap((action: {payload: string}) => {
      return this.parsingThemeService.deleteParsingTheme(action.payload)
        .map((data) => parsingThemesActions.deleteParsingThemeCompletedAction({
          payload:  action.payload
        }))
        .catch((err) => of(parsingThemesActions.deleteParsingThemeFailedAction()));
    }),
  );

  @Effect({ dispatch: false })
  public deleteParsingThemeCompletedAction$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.deleteParsingThemeCompletedAction),
    tap(() => this.closeParsingThemePanelAndShowMessage('Rule group removed'))
  );

  @Effect({ dispatch: false })
  public deleteParsingThemeFailedAction$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.deleteParsingThemeFailedAction),
    tap(() => this.closeParsingThemePanelAndShowMessage('Failed to remove rule group', dialogServiceIconClasses.failed))
  );

  @Effect()
  public getParsingThemeExtendedUniqueFieldsAction$: Observable<any> = this.actions$.pipe(
    ofType(parsingThemesActions.getParsingThemeExtendedUniqueFields),
    switchMap((action: {}) => {
      return this.parsingThemeService.getExtendedUniqueFields().pipe(
        map(uniqueFields => parsingThemesActions.getParsingThemeExtendedUniqueFieldsCompleted({ payload: uniqueFields })),
        catchError(err => of(parsingThemesActions.getParsingThemeExtendedUniqueFieldsCompleted({ payload: [] })))
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dialogService: ShDialogService,
    private parsingThemeService: ParsingThemesApiService,
    private router: Router,
    private location: Location,
  ) {}

  private closeParsingThemePanelAndShowMessage(message: string, icon: dialogServiceIconClasses = dialogServiceIconClasses.success): void {
    this.location.replaceState(`${RulesBaseUrl}`);
    this.router.navigateByUrl(RulesBaseUrl).finally(() => {
      this.dialogService.showCoralogixMessage(message, null, icon);
    }).catch((err) => {
      console.log('navigation error ', err);
    });
  }
}
