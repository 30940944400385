import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { getRolesCompleteAction, getRolesFailedAction } from '@app/settings/account/components/roles/store/roles.actions';
import { IRole } from '@app/settings/account/components/roles/models/roles.model';

export interface IRolesState {
  allRoles: IRole[];
}

const initialState: IRolesState = {
  allRoles: [],
};

const rolesReducer: ActionReducer<any> = createReducer(initialState,
  on(getRolesCompleteAction, (state, { payload }) => {
    return {
      ...state,
      allRoles: payload,
    };
  }),

  on(getRolesFailedAction,
    (state) => ({
      ...state
    })),
);

export function reducer(state: IRolesState = initialState, action: Action): IRolesState {
  return rolesReducer(state, action);
}
