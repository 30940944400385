<div class="empty-list-header">
  <div class="header-content">
    <div class="header-content__icon">
      <div class="header-content__icon-circle">
        <img [src]="circleImagePath">
      </div>
    </div>
    <div class="header-content__sub-title">{{subTitle}}</div>
    <div class="header-content__main-text">
      {{ mainText }}
    </div>
    <div>
      <button mat-raised-button class="header-content__action-button button_wrapper"
              (click)="mainButtonPressed.emit()">
        <span *ngIf="buttonIconFontAwesomeClass" class="button_wrapper__icon">
          <i class="{{buttonIconFontAwesomeClass}}"></i>
        </span>
        <div class="button_wrapper__img" *ngIf="buttonIconUrl">
          <svg-icon [src]="buttonIconUrl"></svg-icon>
        </div>
        <span class="button_wrapper__label">{{buttonText}}</span>
      </button>
    </div>
  </div>
</div>
<div class="empty-list-body">
  <div class="wrapper">
    <ng-container *ngFor="let listItem of listConfig">
      <ng-container [ngSwitch]="listItem.type">
        <div class="content-header" *ngSwitchCase="emptyStateBodyItemType.header">
          <div class="content-header__title">{{listItem.title}}</div>
          <p class="content-header__description">
            {{listItem.description}}
          </p>
        </div>
        <div class="content-body" *ngSwitchCase="emptyStateBodyItemType.body">
          <h1 class="content-body__title" *ngIf="listItem.title">{{listItem.title}}</h1>
          <div class="list-item" *ngFor="let innerItem of listItem.list; let i = index">
            <ng-container [ngSwitch]="listItem.listType">

              <ng-container *ngSwitchCase="emptyStateListTypes.numbersList">
                <div class="list-item__bullet">{{innerItem.bulletContent}}</div>
                <div class="list-item__description">
                  {{innerItem.description}}
                </div>
              </ng-container>
              <div *ngSwitchCase="emptyStateListTypes.imageList"
                   fxLayout="row"
                   fxLayoutGap="19px"
                   class="image-list-item">
                <div class="image-list-item__logo-container" fxLayout="row" fxLayoutAlign="space-around center"
                     [style.width]="listItem.listWidth">
                  <img class="image-list-item__logo" [style.width]="innerItem.imageWidth" [src]="innerItem.imageUrl"/>
                </div>
                <div class="image-list-item__text-section">
                  <div class="image-list-item__title">{{innerItem.title}}</div>
                  <span class="image-list-item__description">{{innerItem.description}}</span>
                  <a *ngIf="innerItem.linkUrl as link"
                     class="image-list-item__link-item"
                     [href]="link" target="_blank">
                    {{innerItem.linkText}}
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
