import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);

import { formatsHelper } from '@shared/services/formatsHelper';
import { ClipboardService } from '@shared/services/clipboard-service';

declare let $;

@Component({
  moduleId: 'loggregation-free-chart',
  selector: 'sh-loggregation-free-chart',
  templateUrl: 'loggregation-chart.component.html',
  styleUrls: ['loggregation-chart.component.scss'],
})
export class LoggregationChartComponent implements OnDestroy {
  @Input() set data(data: any) {
    if (data && !Array.isArray(data)) {
      this._data = [data];
    } else {
      this._data = data;
    }
    if (this._data) {
      this.setChartOptions(this._data[0]);
      if (this._data.length > 1) {
        const parsedData = this.parseData(this._data[1]);
        if (parsedData[1].length) {
          this._chart.addSeries({
            name: 'Normal Behavior',
            type: this.getChartType(data[0]),
            data: parsedData[1],
          });
        }
      }
      this._chart.redraw();
    } else {
      this.setChartOptions(this._data);
    }
  }
  public options: Highcharts.Options;
  @ViewChild('chart', { static: true }) public chartEl: ElementRef;
  @ViewChild('infoBar', { static: true }) public infoBarEl: ElementRef;
  private _data: any;
  private _chart: any;

  constructor(private clipboardService: ClipboardService) {}

  public setChartOptions(data) {
    const infoBar = this.infoBarEl;
    const parsedData = this.parseData(data);
    let categories = [];
    let series = [];
    if (parsedData) {
      categories = parsedData[0];
      series = parsedData[1];
    }

    const chartOptions: any = {
      colors: ['#5DADE2', '#48C9B0'],
      chart: {
        reflow: true,
        renderTo: this.chartEl.nativeElement,
        borderWidth: 0,
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        selectionMarkerFill: 'rgba(255, 0, 0, 0.2)',
        resetZoomButton: {
          theme: {
            display: 'true',
          },
        },

        // Explicitly tell the width and height of a chart
        width: null,
        height: null,
      },
      title: {
        text: null,
      },
      xAxis: {
        type: this.getxAxisType(data),
        showLastTickLabel: true,
        categories,

        labels: {
          formatter() {
            if (Object.prototype.toString.call(this.value) != '[object Date]') {
              if (this.value.length > 20) {
                return formatsHelper.stringSub(this.value, 20);
              }

              return this.value;
            }

            return moment(this.value).format('h:mma');
          },
        },
        crosshair: {
          width: 1,
          color: 'black',
        },
        title: {
          text: null,
        },
      },
      yAxis: {
        gridLineWidth: 1,
        labels: {
          enabled: true,
        },
        title: {
          text: null,
        },
        showFirstLabel: false,
      },
      tooltip: {
        formatter() {
          let s = '<div class="chart-infoBar layout">';
          if (this.x) {
            if (Object.prototype.toString.call(this.x) != '[object Date]') {
              s +=
                '<div class="item-title">' +
                formatsHelper.stringSub(this.x, 512) +
                '</div>';
            } else {
              s +=
                '<div class="item-title">' +
                moment(this.x).format('MMM Do, h:mm a') +
                '</div>';
            }
          }
          s += '<div class="series container">';
          $.each(this.points, function() {
            let result;
            if (this.y % 1 === 0) {
              result = this.y;
            } else {
              result = this.y.toFixed(2);
            }
            if (this.series.type === 'line') {
              result += ' Avg.';
            }
            const seriesName = this.series.name.toLowerCase();
            const words: any[] = seriesName.split(' ', 2);
            s +=
              '<div class=" item-' +
              words[0] +
              '">' +
              this.series.name +
              '</div>' +
              '<div class="series value">' +
              result +
              '</div>';
          });
          s += '</div>';
          s += '</div>';
          infoBar.nativeElement.innerHTML = s;
          return [];
        },
        shared: true,
        split: true,
        borderWidth: 0,
        hideDelay: 0,
        crosshairs: [true],
        useHTML: true,
        animation: false,
        style: { padding: '0', margin: '0' },
        backgroundColor: 'rgba(49,49,49,0.9)',
        borderRadius: 10,
      },
      plotOptions: {
        series: {
          events: {
            click: this.pointClicked.bind(this),
            mouseOut() {
              infoBar.nativeElement.innerHTML = null;
            },
          },
          marker: {
            enabled: false,
          },
        },
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 30,
        borderWidth: 0,
      },
      series: [
        {
          name: 'Current Query',
          type: this.getChartType(data),
          data: series,
        },
      ],
      credits: {
        enabled: false,
      },
    };

    // this.options = chartOptions;
    if (this.chartEl && this.chartEl.nativeElement) {
      this._chart = new Highcharts.Chart(chartOptions);
    }
  }

  public getChartType(data): string {
    if (!data) {
      return 'column';
    }

    switch (data.type) {
      case 'numericPHRTStats':
        return 'line';
      case 'numericPHGlobalStats':
        return 'line';

      case 'categoricalPHRTStats': // Category
        return 'bar';
      case 'templateRTAggStats':
        return 'column';
      case 'categoricalPHGlobalStats': // Category
        return 'bar';
      case 'templateGlobalAggStats':
        return 'column';
      case 'freeText_query_statistics': // Category
        return 'bar';
      case 'template_ids_query_statistics':
        return 'bar';
    }

    return 'column';
  }

  public getxAxisType(data): string {
    if (!data) {
      return 'time';
    }

    switch (data.data.xAxis.type) {
      case 'text':
        return 'category';
      case 'timestamp':
        return 'datetime';
    }

    return 'time';
  }

  public pointClicked(event) {
    if (
      !this._data.clickable &&
      this._data &&
      this._data[0] &&
      this._data[0].data &&
      this._data[0].data.xAxis &&
      this._data[0].data.xAxis.type === 'text'
    ) {
      this.clipboardService.copyToClipboard(event.point.category, true);
      return;
    }
  }

  public ngOnDestroy() {
    this._chart.destroy();
  }

  private parseData(res) {
    if (!res) {
      return null;
    }

    const arraysCategoriesLength: number = Object.keys(res.data.xAxis.values)
      .length;
    const arraysSeriesLength: number = Object.keys(res.data.yAxis.values)
      .length;
    const xCategories = [];
    const series = [];

    // Parse categories to Datetime
    for (let i = 0; i < arraysCategoriesLength; i++) {
      if (this.getxAxisType(res) === 'datetime') {
        xCategories[i] = this.parseDateFromUnix(res.data.xAxis.values[i]);
      } else {
        xCategories[i] = res.data.xAxis.values[i];
      }
    }

    // Parse series - series structure is Array[datetime,value]
    for (let j = 0; j < arraysSeriesLength; j++) {
      let value = !_.isNil(res.data.yAxis.values[j].count)
        ? res.data.yAxis.values[j].count
        : res.data.yAxis.values[j];
      if (res.data.yAxis.values[j].avg >= 0) {
        value = res.data.yAxis.values[j].avg;
      }

      series[j] = value;
    }
    return [xCategories, series];
  }

  private parseDateFromUnix(unixDate) {
    return moment.unix(unixDate / 1000).toDate();
  }
}
