import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'sh-avatar-initials',
  templateUrl: './avatar-initials.component.html',
  styleUrls: ['./avatar-initials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarInitialsComponent {
  @Input() name: string = '';

  @Input() size: string = 'md';

  get initials(): string {
    return this.name?.slice(0, 2);
  }

  classes(): {} {
    return {
      [this.size]: true
    };
  }
}
