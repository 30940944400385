import { Action } from '@ngrx/store';
import { type } from '@shared/services/utils';
import { LogQueryModel } from '../models/logsquery.model';
import { LogqueryStats } from '../models/Logquery-stats.response';

export namespace LogActions {
  export const ActionTypes = {
    GET_LOGS_AND_TEMPLATE_COUNT: type('[Log] Get Logs and template counts'),
    GET_LOGS_AND_TEMPLATE_COUNT_COMPLETED: type(
      '[Log] Get Logs and template counts Completed',
    ),
    GET_LOGS_AND_TEMPLATE_COUNT_FAILED: type(
      '[Log] Get Logs and template counts Failed',
    ),
    CREATE_CACHED_QUERY: type('[Log] create cached query'),
    CREATE_CACHED_QUERY_COMPLETED: type('[Log] create cached query completed'),
    CREATE_CACHED_QUERY_FAILED: type('[Log] create cached query failed'),
    SET_LOGS_QUERY: type('[Log] set current view query')
  };

  export class GetQueryCount implements Action {
    public type = ActionTypes.GET_LOGS_AND_TEMPLATE_COUNT;

    constructor(public payload: LogQueryModel) {}
  }

  export class GetQueryCountCompleted implements Action {
    public type = ActionTypes.GET_LOGS_AND_TEMPLATE_COUNT_COMPLETED;

    constructor(public payload: LogqueryStats) {}
  }

  export class GetQueryCountFailed implements Action {
    public type = ActionTypes.GET_LOGS_AND_TEMPLATE_COUNT_FAILED;

    constructor(public payload: any) {}
  }

  export class CreateCachedQuery implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY;

    constructor(public payload: LogQueryModel) {}
  }

  export class CreateCachedQueryCompleted implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY_COMPLETED;

    constructor(public payload: LogQueryModel) {}
  }

  export class CreateCachedQueryFailed implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY_FAILED;

    constructor(public payload: any) {}
  }

  export class SetLogPanelQuery implements Action {
    public type = ActionTypes.SET_LOGS_QUERY;

    constructor(public payload: LogQueryModel) {}
  }

  export type Actions =
    | GetQueryCount
    | GetQueryCountCompleted
    | GetQueryCountFailed
    | CreateCachedQuery
    | CreateCachedQueryCompleted
    | CreateCachedQueryFailed
    | SetLogPanelQuery
    ;
}
