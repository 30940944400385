<div *ngIf="legendData">
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
    <div *ngFor="let item of legendData">
      <div class="single-stat-container" fxLayout="row">
        <div class="rectangle-bullet" [ngStyle]="{ 'background-color': item.color }"></div>
        <span class="single-stat-header">{{ item.name | uppercase }}</span>
      </div>
    </div>
  </div>
</div>
