import { Metadata } from './query-metadata';
import { QueryExternalFilters } from '@app/logs/shared/models/query-external-filters';
export class MetadataRequest {
  public queryDef: MetadataFilter = new MetadataFilter();

  constructor(field: string, filters: Metadata, externalFilters?: QueryExternalFilters) {
    this.queryDef = new MetadataFilter();
    this.queryDef.queryParams.query = { field };
    this.queryDef.queryParams.filter = filters;
    this.queryDef.queryParams.externalFilters = externalFilters;
  }
}

export class MetadataFilter {
  public queryParams: MetadataQueryParams = new MetadataQueryParams();
  public type: string = 'metadataAutocomplete';
}

export class MetadataQueryParams {
  public query: {
    field: string;
  };
  public filter: Metadata = new Metadata();
  public externalFilters: QueryExternalFilters = null;
}
