<div class="header">
  <div class="title">
    Reindex
    <div class="beta-container"><label class="beta-label">BETA</label></div>
  </div>
  <div fxFlex="20" class="newReindexButton">
    <cgx-button
      label="REINDEX ARCHIVE"
      size="lg"
      textNoWrap
      srcIconStart="assets/roi/icon-reindex.svg"
      width="200px"
      [srcIconStartStyle]="{ width: 24, height: 24 }"
      [disabled]="btnDisabled"
      (press)="openEditor()"
    ></cgx-button>
  </div>
</div>

<!-- Loading -->
<div>
  <div class="overlay" [hidden]="!isLoading">
    <div class="ui active inverted dimmer overlay">
      <div class="ui text loader">Loading...</div>
    </div>
  </div>
</div>

<sh-reindex-permissions-error
  *ngIf="!isLoading && this.reindexPermCheckError"
  (messageBtnAction)="openS3PermissionModal()"
  permissionMsg="Could not connect to S3 bucket. Please check your bucket settings and read permissions"
  btnText="FIX S3 PERMISSIONS"
></sh-reindex-permissions-error>

<sh-reindex-permissions-error
  *ngIf="!isLoading && displayPermissionErrorMsg"
  (messageBtnAction)="openS3PermissionModal()"
  permissionMsg="The Reindex feature requires read permission of your AWS S3 archive bucket"
  btnText="FIX S3 PERMISSIONS"
></sh-reindex-permissions-error>

<sh-reindex-permissions-error
  *ngIf="!isLoading && displayArchiveMissingMsg"
  (messageBtnAction)="openBucketEditor()"
  permissionMsg="The Reindex feature requires an Amazon S3 archive bucket to be defined"
  btnText="CONFIGURE S3"
></sh-reindex-permissions-error>

<sh-reindex-empty
  [btnDisabled]="btnDisabled"
  *ngIf="(reindexes$ | async)?.length <= 0 && !isLoading; else elseBlock"
  (openEditor)="openEditor()"
></sh-reindex-empty>
<ng-template #elseBlock>
  <div style="overflow-y: scroll; width: 100%; display: flex;">
    <sh-reindex-table-container style="margin: 0 auto" [reindexes]="reindexes$ | async"></sh-reindex-table-container>
  </div>
</ng-template>

<sh-reindex-s3-form-container></sh-reindex-s3-form-container>

<sh-reindex-form-container></sh-reindex-form-container>

<!-- Modal dialog showing steps for fixing permissions problems -->
<div class="ui basic test modal" modal-directive [closeable]="true">
  <div class="modal-content">
    <div class="container" style="width: 681px; margin: auto;">
      <span class="icon exit-modal" (click)="closeModal()">
        <i class="fa fa-times"></i>
      </span>
      <sh-reindex-access-permisions-info></sh-reindex-access-permisions-info>
    </div>
  </div>
</div>
