import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@app/app.reducers';
import { Constants } from '@app/constants';
import { Build } from '@app/deployment/models/build';
import { ILogsView } from '@app/logs-new/shared/models/logs-view.model';
import { AggIntervalMilliSecondNum, ILogQueries, ILogQuery, LogQueryModel } from '@app/logs-new/shared/models/logsquery.model';
import {
  IPredefinedCustomQuery,
  IPredefinedQueryData,
  IPredefinedQuickQuery,
  IPredefinedRelativeQuery,
  IPredefinedTimeQuery,
  PredefinedCustomTimeQuery,
  PredefinedQuickQuery,
  TimeQueryType,
} from '@app/logs-new/shared/models/predefined-time-queries.model';
import { Metadata } from '@app/logs-new/shared/models/query-metadata';
import { defaultQuickTimeQuery, PredefinedQuickQueryType, PredefinedQuickTimes } from '@app/logs-new/shared/models/time/quick-times.model';
import { LogsGridService } from '@app/logs-new/shared/services/logs-grid.service';
import { LogsStatsService } from '@app/logs-new/shared/services/logs-stats.service';
import { LogsService } from '@app/logs-new/shared/services/logs.service';
import { metadataDisplayNameMap } from '@app/logs-new/shared/utils/logs-columns.utils';
import { getDateMinusSeconds, getStartOfTodayDate } from '@app/logs-new/shared/utils/time.utils';
import {
  _15MinutesInMS,
  _15MinutesInSeconds,
  _1HourInMS,
  _1HourInSeconds,
  _1MinuteInMS,
  _1SecondInMS,
  _24HoursInMS,
} from '@app/shared/constants/times.constant';
import { Action, Store } from '@ngrx/store';
import { UDIDHelper } from '@shared/services/udid.helper';
import cloneDeep from 'lodash-es/cloneDeep';
import { BehaviorSubject, timer } from 'rxjs';
import { map, scan, takeWhile, tap } from 'rxjs/operators';
import { ISelectedLog } from '../models/query-params.model';
import { LogsGraphType } from '@app/logs-new/shared/models/graph/logs-graph.model';
import { LogsGraphRelative } from '@app/logs-new/shared/models/graph/relative-graph.model';
import { LogsGraphHistogram } from '@app/logs-new/shared/models/graph/histogram-graph.model';
import { Subscription } from 'rxjs/Subscription';

@Injectable({
  providedIn: 'root',
})
export class LogsQueryService {
  refreshQueryCountdown$ = new BehaviorSubject('');
  private refreshQueryTimer$: Subscription;

  constructor(
    private logsService: LogsService,
    private logsGridService: LogsGridService,
    private statisticsService: LogsStatsService,
    private uuidService: UDIDHelper,
    private http: HttpClient,
    private store: Store<State>,
  ) {}

  /* There are two type of queries, the first one is the normal one that's used throughout the entire logs screen, the second one
   * is a special one which is used solely to get the filters that are not metadata.
   * Ideally it would be better to have just one query, and it's an optimization worth doing in the future.
   */
  newQuery(
    logQuery: ILogQuery,
    timeQuery: IPredefinedTimeQuery,
    viewId: number,
    searchQuery: string,
    resetQueryIndex: boolean = true,
    selectedLogs: ISelectedLog[],
    allTags: Build[],
    persistQueryId: boolean = false,
  ): ILogQueries {
    const { pageIndex, sortModel } = logQuery;
    const { startDate, endDate, tagId } = LogsQueryService.extractQueryDates(timeQuery);
    const newQuery = new LogQueryModel(startDate, endDate, tagId, viewId, pageIndex, logQuery.selectedSurroundLog);

    if (tagId) {
      setTagsQuery();
    }

    newQuery.sortModel = sortModel;
    newQuery.queryParams.query.text = searchQuery;
    newQuery.queryParams.metadata = logQuery.queryParams.metadata;
    newQuery.queryParams.jsonObject = logQuery.queryParams.jsonObject;
    newQuery.queryParams.externalFilters = logQuery.queryParams.externalFilters;
    newQuery.queryParams.aggField = logQuery.queryParams.aggField;
    newQuery.queryParams.aggregationInterval = LogsQueryService.getAggregationInterval(newQuery.startDate, newQuery.endDate);
    newQuery.queryParams.selectedLogs = selectedLogs;
    newQuery.graphs = logQuery.graphs;
    if (persistQueryId) {
      newQuery.cacheQueryId = logQuery.cacheQueryId;
    }

    this.setGraphsQuery(newQuery);
    if (resetQueryIndex) {
      newQuery.pageIndex = 0;
      this.logsGridService.paginationIndex$.next(0);
    }
    return this.getLogQueries(newQuery);

    function setTagsQuery(): void {
      const selectedTag = allTags.find(tag => tag.id === tagId);
      if (!selectedTag) {
        newQuery.startDate = new Date().getTime();
        newQuery.endDate = new Date().getTime() - _15MinutesInMS;
      }

      const nextTag = allTags.find(
        tag =>
          tag.id !== selectedTag.id &&
          tag.tag_timestamp > selectedTag.tag_timestamp &&
          tag.tag_timestamp < selectedTag.tag_timestamp + _24HoursInMS &&
          ((tag.application_name === selectedTag.application_name && tag.subsystem_name === selectedTag.subsystem_name) ||
            (tag.application_name === selectedTag.application_name && !selectedTag.subsystem_name)),
      );

      newQuery.startDate = selectedTag.tag_timestamp;
      newQuery.endDate = nextTag ? nextTag.tag_timestamp : selectedTag.tag_timestamp + _24HoursInMS;

      if (newQuery.endDate > Date.now()) {
        newQuery.endDate = Date.now();
      }
    }
  }

  getLogQueries(query: ILogQuery): ILogQueries {
    return {
      metadataQuery: query,
      filtersQuery: this.createFiltersQuery(query),
    };
  }

  killRefreshQuery(): void {
    this.refreshQueryTimer$?.unsubscribe();
  }

  refreshQuery(ms: number, actions: Action[]): void {
    this.killRefreshQuery();
    const refreshQuerySeconds = ms / 1000;
    const dispatchActions = storeActions => storeActions.forEach(action => this.store.dispatch(action));
    this.refreshQueryTimer$ = timer(0, 1000)
      .pipe(
        scan(accumulator => (accumulator - 1 >= -1 ? accumulator - 1 : refreshQuerySeconds), refreshQuerySeconds + 1),
        tap(countdown => {
          if (countdown === -1) {
            dispatchActions(actions);
          }
        }),
        takeWhile(countdownSeconds => countdownSeconds >= 0),
        map(countdown => {
          const timeStrStartIndexFromISOString = 11;
          const timeStrLength = 8; // hh:mm:ss
          return new Date(countdown * 1000).toISOString().substr(timeStrStartIndexFromISOString, timeStrLength);
        }),
      )
      .subscribe(countdown => {
        this.refreshQueryCountdown$.next(countdown);
      });
  }

  static extractQueryDates(selectedQuery: IPredefinedTimeQuery): { startDate: Date; endDate: Date; tagId: number } {
    let startDate: Date;
    let endDate: Date;
    let tagId: number;
    let queryData: IPredefinedQueryData;

    switch (selectedQuery.type) {
      case TimeQueryType.Quick:
        queryData = selectedQuery.data as IPredefinedQuickQuery;
        const isToday = queryData.value === PredefinedQuickQueryType.Today;
        startDate = isToday ? getStartOfTodayDate() : getDateMinusSeconds(queryData.seconds);
        endDate = new Date();
        break;
      case TimeQueryType.Custom:
      case TimeQueryType.Relative:
        queryData = selectedQuery.data as IPredefinedCustomQuery | IPredefinedRelativeQuery;
        startDate = queryData.fromDate;
        endDate = queryData.toDate;
        break;
      case TimeQueryType.Tag:
        queryData = selectedQuery.data as Build;
        tagId = queryData.id;
        break;
    }

    return { startDate, endDate, tagId };
  }

  getRecentQueries(): string[] {
    const recentQueries = localStorage.getItem(Constants.LS_KEY_RECENT_QUERIES);
    return recentQueries ? JSON.parse(recentQueries) : [];
  }

  saveRecentQueriesInLS(recentQueries: string[]): void {
    localStorage.setItem(Constants.LS_KEY_RECENT_QUERIES, JSON.stringify(recentQueries));
  }

  addSearchQueryToRecentQueries(searchQuery: string): void {
    let recentQueries: string[] = this.getRecentQueries();
    if (!recentQueries.includes(searchQuery)) {
      recentQueries = [searchQuery, ...recentQueries].splice(0, Constants.SEARCH_VISIBLE_QUERIES_AMOUNT);
      this.saveRecentQueriesInLS(recentQueries);
    }
  }

  static getFilterFieldWithType(field: string): { field: string; type: 'metadata' | 'jsonObject' } {
    return {
      type: this.isMetadataField(field) ? 'metadata' : 'jsonObject',
      field,
    };
  }

  /* Takes selected fields hashmap, transform it
  into an object with metadata and jsonObject, that is
  then used to update the log query.
   */
  static getFieldsFromFieldsMap(fieldsMap: {
    [key: string]: boolean;
  }): { metadata: Partial<Metadata>; jsonObject: { [key: string]: any[] } } {
    function transformSelectedField(key: string): string[] {
      return Object.entries(fieldsMap[key])
        .filter(([fieldLabel, val]) => val)
        .map(([fieldLabel, val]) => fieldLabel);
    }
    const fieldsEntries = Object.entries(fieldsMap);
    return fieldsEntries.reduce(
      (acc, [key, isSelected]) => {
        const fieldsArr = transformSelectedField(key);
        if (this.isMetadataField(key) && isSelected) {
          acc['metadata'][key] = fieldsArr;
        } else {
          acc['jsonObject'][key] = fieldsArr;
        }
        return acc;
      },
      { metadata: {}, jsonObject: {} },
    ) as { metadata: Partial<Metadata>; jsonObject: { [key: string]: any[] } };
  }

  createFiltersQuery(logsQuery: ILogQuery): ILogQuery {
    const query = cloneDeep(logsQuery);
    query.queryParams.jsonAggFields = Object.keys(query.queryParams.jsonObject);
    return query;
  }

  static isMetadataField(field: string): boolean {
    return !!metadataDisplayNameMap[`coralogix.metadata.${field}`];
  }

  static getField(field: string): string {
    return this.isMetadataField(field) ? `coralogix.metadata.${field}` : field;
  }

  static getLogsViewQueryTime(
    selectedView: ILogsView,
    viewQuery?: ILogQuery,
  ): { predefinedQuery: IPredefinedTimeQuery; startDate: number; endDate: number } {
    const query = viewQuery || selectedView?.query_model?.queryModel || new LogQueryModel();
    const quickTimes = new PredefinedQuickTimes().predefinedQuickTimes;
    const queryTime =
      !viewQuery && quickTimes.filter(time => time?.caption?.toLowerCase() === selectedView?.query_model?.caption?.toLowerCase())[0];

    let predefinedQuery;
    if (!selectedView?.query_model && !viewQuery) {
      predefinedQuery = defaultQuickTimeQuery;
    } else {
      predefinedQuery =
        queryTime && !viewQuery ? new PredefinedQuickQuery(queryTime) : new PredefinedCustomTimeQuery(query.startDate, query.endDate);
    }

    const extractedDates = LogsQueryService.extractQueryDates(predefinedQuery);
    const startDate = extractedDates.startDate.getTime();
    const endDate = extractedDates.endDate.getTime();

    return { predefinedQuery, startDate, endDate };
  }

  static getAggregationInterval(startDate: number, endDate: number): AggIntervalMilliSecondNum {
    const diff = endDate - startDate;
    const _30MinutesInMS = _15MinutesInMS * 2;
    const _12HoursInMS = _1HourInMS * 12;

    if (diff <= _15MinutesInMS) {
      return _1SecondInMS;
    }
    if (diff <= _12HoursInMS) {
      return _1MinuteInMS;
    }
    if (diff <= _24HoursInMS) {
      return _30MinutesInMS;
    }
    return _1HourInMS;
  }

  setGraphsQuery(logQuery: ILogQuery): void {
    if (!logQuery.graphs) {
      logQuery.graphs = [new LogsGraphHistogram()];
    }
    logQuery.graphs = logQuery.graphs.map(graph => {
      if (graph.type === LogsGraphType.Relative) {
        const updatedRelativeGraph = new LogsGraphRelative(graph);
        updatedRelativeGraph.updateSeriesFromBaselineDate(logQuery.startDate, logQuery.endDate);
        return updatedRelativeGraph;
      }
      return new LogsGraphHistogram({ ...graph, startDate: logQuery.startDate, endDate: logQuery.endDate });
    });
  }
}
