import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import {
  CapabilityTypes,
  IRegistry,
  IUrlsPostFix, ResetFields,
} from '@app/logs-new/shared/abilities-state-registry/capabilities-model';

@Injectable({
  providedIn: 'root'
})
export class ContextualRegistryService {
  private registry: IRegistry = null;
  private registrySubject$: BehaviorSubject<IRegistry> = new BehaviorSubject<IRegistry>(null);
  public registryObs$: Observable<IRegistry> = this.registrySubject$.asObservable();

  public registerContextualAbilities(contextualRegistry: IRegistry): void {
    this.registry = contextualRegistry;
    this.registrySubject$.next(this.registry);
  }

  public isCapabilityEnabled(type: CapabilityTypes): boolean {
    if (this.registry?.isEnabled('isEnabled')) return true;
    return this.registry?.isEnabled(type);
  }

  public deleteRegistry(): void {
    this.registrySubject$.unsubscribe();
    this.registry = null;
  }

  public getRegistry(): IRegistry {
    return this.registry;
  }

  public getCapabilitiesURLS(): Partial<IUrlsPostFix> {
    return this.registry?.urlsPostFix;
  }

  public getFieldsToReset(): ResetFields {
    return this.registry?.fieldsToReset;
  }
}
