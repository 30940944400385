import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricAlertComponent } from './metric-alert.component';
import { MetricAlertRoutingModule } from './metric-alert.routes';
import { SharedModule } from '@app/shared/shared.module';
import { AlertViewLayoutModule } from '../alert-view-layout/alert-view-layout.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChartModule } from 'angular-highcharts';
import { MetricAlertGraphComponent } from './metric-alert-graph/metric-alert-graph.component';
import { FormControlsModule } from '@app/shared/form-controls/form-controls.module';
import { CgxButtonModule } from '@coralogix/design';

@NgModule({
  declarations: [MetricAlertComponent, MetricAlertGraphComponent],
  imports: [
    CommonModule,
    MetricAlertRoutingModule,
    SharedModule,
    AlertViewLayoutModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ScrollingModule,
    ChartModule,
    FormControlsModule,
    CgxButtonModule,
  ],
})
export class MetricAlertModule {}
