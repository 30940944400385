export class ReindexEditorControls {
  public name: string;
  public description: string;
  public applicationName: string[];
  public subsystem: string[];
  public severities: number[];
  public text: string;
  public from: Date;
  public startTime: string;
  public endTime: number;

  constructor(init?: Partial<ReindexEditorControls>) {
    Object.assign(this, init);
  }
}

export class ReindexS3PermissionsEditorControls {
  public region: string;
  public bucket: string;

  constructor(init?: Partial<ReindexS3PermissionsEditorControls>) {
    Object.assign(this, init);
  }
}
