import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

interface IPasswordValues {
  special: string;
  numeric: string;
  lowerCase: string;
  upperCase: string;
}

@Injectable()
export class ChangePasswordFormProvider {
  changePasswordForm: FormGroup;

  private MIN_PASSWORD_LENGTH: number = 8;

  private MAX_PASSWORD_LENGTH: number = 35;

  private SPECIAL_CHARACTERS = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  private mandatoryChars: IPasswordValues = {
    special: this.SPECIAL_CHARACTERS,
    numeric: '0-9',
    lowerCase: 'a-z',
    upperCase: 'A-Z',
  };

  private mandatoryPatterns: IPasswordValues = Object.keys(this.mandatoryChars).reduce((acc, field) => {
    acc[field] = `(?=.*[${this.mandatoryChars[field]}])`;
    return acc;
  }, Object.assign({}, this.mandatoryChars));

  private passwordValidPatterns: string = `[${Object.values(this.mandatoryChars).join('')}]`;

  private passwordPattern: string = `^${Object.values(this.mandatoryPatterns).join('')}${this.passwordValidPatterns}{${
    this.MIN_PASSWORD_LENGTH
  },${this.MAX_PASSWORD_LENGTH}}$`;

  constructor(private fb: FormBuilder) {
    this.generateNewForm();
  }

  generateNewForm(): void {
    this.changePasswordForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.minLength(this.MIN_PASSWORD_LENGTH),
            Validators.maxLength(this.MAX_PASSWORD_LENGTH),
            Validators.pattern(new RegExp(this.passwordPattern)),
          ],
        ],
        repeatPassword: ['', [Validators.required]],
      },
      {
        validators: [this.matchingPasswords('password', 'repeatPassword')],
      },
    );
  }

  resetForm(): void {
    this.changePasswordForm.reset();
  }

  errorMsgs(password: string): string[] {
    const errMsgs: string[] = [];
    const ctrl = this.changePasswordForm.controls['password'];
    if (ctrl.errors && ctrl.errors.notEquivalent) {
      errMsgs.push('Not match');
      return errMsgs;
    }

    if (password.length < this.MIN_PASSWORD_LENGTH) {
      errMsgs.push(`Must be at least ${this.MIN_PASSWORD_LENGTH} characters`);
    } else if (password.length > this.MAX_PASSWORD_LENGTH) {
      errMsgs.push(`Must be at most ${this.MAX_PASSWORD_LENGTH} characters`);
    }

    if (!new RegExp(this.mandatoryPatterns.numeric).test(password)) {
      errMsgs.push('Must be at least 1 numeric character');
    }

    if (!new RegExp(this.mandatoryPatterns.special).test(password)) {
      errMsgs.push(`Must be at least 1 special character` + ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~');
    }

    if (!new RegExp(this.mandatoryPatterns.lowerCase).test(password)) {
      errMsgs.push('Must be at least 1 lowercase alphabetical character');
    }

    if (!new RegExp(this.mandatoryPatterns.upperCase).test(password)) {
      errMsgs.push('Must be at least 1 uppercase alphabetical character');
    }

    if (new RegExp(this.passwordValidPatterns).test(password)) {
      const invalidChars = new Set(password.replace(new RegExp(this.passwordValidPatterns, 'g'), ''));
      if (invalidChars.has('\\')) {
        invalidChars.delete('\\');
      }
      if (invalidChars.size !== 0) {
        errMsgs.push(`One or more of the provided characters is invalid '${String.prototype.concat(...invalidChars)}'`);
      }
    }

    return errMsgs;
  }

  private matchingPasswords(passwordKey: string, passwordConfirmationKey: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return { notEquivalent: true };
      }

      return null;
    };
  }
}
