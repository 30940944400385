import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../app.reducers';
import { LogQueryModel } from '../../../logs/shared/models/logsquery.model';
import { StatisticsQueryDefinition, StatisticsCategory } from '../../shared/models/statistics-query-definition';
import { Widget } from '../../../widgets/shared/models/widget';
import { WidgetsService } from '../../../widgets/shared/services/widgets.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WidgetAddComponent } from '../../../shared/controls/widgets/widget-add/widget-add.component';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Observable } from 'rxjs';
declare let $;
@Component({
  selector: 'sh-statistics-chart-modal',
  templateUrl: './statistics-chart-modal.component.html',
  styleUrls: ['./statistics-chart-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StatisticsChartModalComponent implements AfterViewInit {
  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  @ViewChild('chartModal', { static: true }) public chartModal: ElementRef;

  public headerName: string;

  public chartHeight = 300;

  public chartQueryDef: StatisticsQueryDefinition;

  constructor(private widgetService: WidgetsService, public dialog: MatDialog, private store: Store<State>) { }

  public ngAfterViewInit(): void {
    $(this.chartModal.nativeElement).modal({ closable: true });
  }

  public openModal(): void {
    $(this.chartModal.nativeElement).modal('show');
  }
  public openChart(queryModel: LogQueryModel, aggField: string, title?: string): void {
    this.headerName = title ? title : aggField;
    queryModel.queryParams.aggField = aggField;
    const statQueryDef: StatisticsQueryDefinition = new StatisticsQueryDefinition();
    statQueryDef.logQeuryModel = queryModel;
    statQueryDef.category = StatisticsCategory.LOGS;
    statQueryDef.seriesType = 'column';
    this.chartQueryDef = statQueryDef;
    console.log(this.chartQueryDef);
    this.openModal();
  }
  public addWidget(query: any): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = { query, url: 'statistics/logs1' };
    config.panelClass = 'add-edit-widget-container';
    const dialogRef = this.dialog.open(WidgetAddComponent, config);
    dialogRef
      .afterClosed()
      .first()
      .subscribe((widget) => {
        this.pinToDashboard(widget);
      });
  }
  public hideModal(): void {
    $(this.chartModal.nativeElement).modal('hide');
    this.headerName = 'Loading...';
  }

  public pinToDashboard(widget: Widget): void {
    this.widgetService
      .addWidgets(widget)
      .first()
      .subscribe((w) => console.log(w));
  }
}
