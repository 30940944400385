import { Widget } from './widget';
import { StatisticsQueryDefinition } from '../../../statistics/shared/models/statistics-query-definition';
import { jsonObject } from 'typedjson';

@jsonObject
export class StatisticsWidget extends Widget {
  public statisticsQueryDefinition: StatisticsQueryDefinition;
}
export class JsonStatisticsWidget extends Widget {
  public statisticsQueryDefinition: StatisticsQueryDefinition;
}
