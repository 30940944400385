import { ChangeDetectorRef, NgModule, Provider } from '@angular/core';
import { AsyncPipe, CommonModule, PercentPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AgGridModule, AgGridNg2 } from 'ag-grid-ng2';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { HighchartsChartModule } from 'highcharts-angular';

import { SnackBarStatusComponent } from '../settings/shared/controls/snack-bar-status/snack-bar-status.component';
import { TimestampCellComponent } from '@shared/controls/grid-cells';
import { SeverityDirective } from './directives/severity.directive';
import { GridOptionsFilterComponent } from './controls/grid-filters/grid-options-filter/grid-options-filter.component';
import { ToggleButtonComponent } from './controls/toggle-button.component';
import { ShPickADateDirective } from './directives/pickadate.directive';
import { BasicChartComponent } from './controls/charts/basic-chart/basic-chart.component';
import { GlobalParamsProvider } from './services/globalParams.provider';
import { ModalDirective } from './directives/modal.directive';
import { WidgetAddComponent } from './controls/widgets/widget-add/widget-add.component';
import { YesNoModalComponent } from './controls/modals/yes-no-modal/yes-no-modal.component';
import { MasterFilterComponent } from './controls/grid-filters/master-filter/master-filter.component';
import { ActiveRouterDirective } from './directives/active-router.directive';
import { FindLogInQueryService } from './services/find-log-in-query.service';
import { JsonPropertyComponent } from './controls/json-view/json-property.component';
import { JsonViewerComponent } from './controls/json-viewer/json-viewer.component';
import { BreakLinePipe } from './pipes/breakeLinePip';
import { JsonActionButtonsComponent } from './controls/json-action-buttons/json-action-buttons.component';
import { ClipboardService } from './services/clipboard-service';
import { GridColumnService } from './services/grid-column-service';
import { ApplicationDispatcher } from './services/application-dispatcher';
import { CodeViewerComponent } from './controls/code-viewer/code-viewer.component';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { GridPagingComponent } from './controls/grid/grid-paging/grid-paging.component';
import { NumberToArrayPipe } from './pipes/numberToArrayPipe';
import { PreviewPlaceholderComponent } from './controls/preview-placeholder/preview-placeholder.component';
import { LoggregationPipe } from './pipes/loggregation.pipe';
import { SafeResourceUrlPipe } from './pipes/safe-resource-url.pipe';
import { ShDialogService } from './services/dialogService';
import { DeleteDialogComponent } from './controls/delete-dialog/delete-dialog.component';
import { NormalRatioPipe } from './pipes/normalRation.Pipe';
import { NumbersWithKFormatPipe } from './pipes/numbersWithKFormatPipe';
import { TokenResolver } from './resolvers/token-resolver';
import { ShDateTimeInputDirective } from './directives/date-time-input';
import { HighlightPipe, StringToColorPipe } from './pipes/highlighPipe';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { ReadOnlyGuard } from './guards/read-only.guard';
import { MetadataSelectorComponent } from './controls/metadata-selector/metadata-selector.component';
import { CloudSecurityGuard } from '@shared/guards/cloud-security.guard';
import { FormControlsModule } from './form-controls/form-controls.module';
import { PopupsModule } from '@shared/popups/popups.module';
import { VirtualSelectModule } from '@shared/modules/virtual-select/virtual-select.module';
import { ShNgDatepickerModule } from '@shared/controls/sh-ng-datepicker/ng-datepicker.module';
import { GridComponent } from '@shared/controls/grid';
import { OverlayModule } from '@angular/cdk/overlay';
import { DirectivesModule } from '@shared/directives/directives.module';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { TutorialsModule } from '@shared/tutorials/tutorials.module';
import { PageSubHeaderComponent } from './components/page-sub-header/page-sub-header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BasicInputComponent } from './controls/basic-input/basic-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { ZipService } from './services/zip.service';
import { CsvExportService } from './services/csv-export.service';
import { DeleteDialogV2Component } from '@shared/controls/delete-dialog-v2/delete-dialog-v2.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SearchHighlightModule } from '@app/shared/pipes/search-highlight/search-highlight.module';
import { FromDateModule } from '@shared/pipes/from-date.module';
import { TimePickerModule } from '@shared/directives/time-picker.module';
import { CoralogixNgDatePickerComponent } from '@shared/controls/coralogix-ng-date-picker/coralogix-ng-date-picker.component';
import { CustomFormsModule } from 'ngx-custom-validators';
import { ChartModule } from 'angular-highcharts';
import { InsightsLastHoursFilterPipe } from './pipes/insights-alert-filter.pipe';
import { FilterPipeModule } from './pipes/filter-pipe/filter-pipe.module';
import { KeysPipeModule } from './pipes/keys-pipe/keys-pipe.module';
import { MaterialModule } from './material/material.module';
import { NumbersFormatPipeModule } from './pipes/numbers-format/numbers-format.module';
import { WidgetLoaderModule } from './components/widget-loader/widget-loader.module';
import { DataSizeModule } from './pipes/data-size.pipe/data-size.module';
import { InputDialogComponent } from './controls/input-dialog/input-dialog.component';
import { DateFormatByUserSettingsModule } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.module';
import { PopupDirectiveModule } from './directives/popup/popup.directive.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [
    SnackBarStatusComponent,
    PreviewPlaceholderComponent,
    TimestampCellComponent,
    SeverityDirective,
    GridOptionsFilterComponent,
    ToggleButtonComponent,
    ShPickADateDirective,
    BasicChartComponent,
    GridComponent,
    ModalDirective,
    WidgetAddComponent,
    YesNoModalComponent,
    MasterFilterComponent,
    ActiveRouterDirective,
    JsonPropertyComponent,
    BreakLinePipe,
    JsonViewerComponent,
    JsonActionButtonsComponent,
    CodeViewerComponent,
    PrettyJsonPipe,
    NumberToArrayPipe,
    LoggregationPipe,
    NumbersWithKFormatPipe,
    GridPagingComponent,
    SafeResourceUrlPipe,
    NormalRatioPipe,
    DeleteDialogComponent,
    DeleteDialogV2Component,
    ShDateTimeInputDirective,
    HighlightPipe,
    StringToColorPipe,
    ClickStopPropagationDirective,
    MetadataSelectorComponent,
    PageSubHeaderComponent,
    BasicInputComponent,
    CoralogixConfirmationDialogComponent,
    CoralogixNgDatePickerComponent,
    InsightsLastHoursFilterPipe,
    InputDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([MasterFilterComponent, GridOptionsFilterComponent]),
    ShNgDatepickerModule,
    NgJsonEditorModule,
    HighchartsChartModule,
    VirtualSelectModule,
    FormControlsModule,
    TimePickerModule,
    DirectivesModule,
    PopupsModule,
    OverlayModule,
    TutorialsModule,
    FlexLayoutModule,
    NgSelectModule,
    PerfectScrollbarModule,
    SearchHighlightModule,
    FromDateModule,
    CustomFormsModule,
    ChartModule,
    FilterPipeModule,
    KeysPipeModule,
    MaterialModule,
    NumbersFormatPipeModule,
    WidgetLoaderModule,
    DataSizeModule,
    DateFormatByUserSettingsModule,
    PopupDirectiveModule,
    AngularSvgIconModule,
  ],
  exports: [
    PopupDirectiveModule,
    GridPagingComponent,
    SearchHighlightModule,
    TimePickerModule,
    PreviewPlaceholderComponent,
    BasicChartComponent,
    NumbersWithKFormatPipe,
    TimestampCellComponent,
    SeverityDirective,
    ToggleButtonComponent,
    ShPickADateDirective,
    GridComponent,
    WidgetAddComponent,
    YesNoModalComponent,
    ModalDirective,
    ActiveRouterDirective,
    JsonPropertyComponent,
    BreakLinePipe,
    JsonViewerComponent,
    CodeViewerComponent,
    PrettyJsonPipe,
    NumberToArrayPipe,
    LoggregationPipe,
    SafeResourceUrlPipe,
    NormalRatioPipe,
    CodeViewerComponent,
    ShNgDatepickerModule,
    ShDateTimeInputDirective,
    HighlightPipe,
    StringToColorPipe,
    ClickStopPropagationDirective,
    NgJsonEditorModule,
    MetadataSelectorComponent,
    HighchartsChartModule,
    FormControlsModule,
    PopupsModule,
    VirtualSelectModule,
    AgGridNg2,
    TutorialsModule,
    JsonActionButtonsComponent,
    PageSubHeaderComponent,
    BasicInputComponent,
    CoralogixConfirmationDialogComponent,
    FromDateModule,
    FlexLayoutModule,
    CoralogixNgDatePickerComponent,
    ChartModule,
    InsightsLastHoursFilterPipe,
    MaterialModule,
    NgSelectModule,
    NumbersFormatPipeModule,
    WidgetLoaderModule,
    DataSizeModule,
  ],
  entryComponents: [
    SnackBarStatusComponent,
    DeleteDialogComponent,
    DeleteDialogV2Component,
    CodeViewerComponent,
    BasicInputComponent,
    CoralogixConfirmationDialogComponent,
  ],
  providers: [
    GlobalParamsProvider,
    FindLogInQueryService,
    ClipboardService,
    GridColumnService,
    ApplicationDispatcher,
    ShDialogService,
    TokenResolver,
    ReadOnlyGuard,
    PercentPipe,
    CloudSecurityGuard,
    HtmlRowMeasure,
    AsyncPipe,
    ChangeDetectorRef as Provider,
    ZipService,
    CsvExportService,
  ],
})
export class SharedModule {}
