import { Component, ViewChild } from '@angular/core';
import { RegexCheatSheetTableComponent } from '@shared/tutorials/regex-cheat-sheet/components/table/regex-cheat-sheet-table.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'sh-regex-cheat-sheet',
    templateUrl: './regex-cheat-sheet.component.html',
    styleUrls: [ './regex-cheat-sheet.component.scss' ],
})
export class RegexCheatSheetComponent {
    @ViewChild('tableComponent', { static: true })
    public table: RegexCheatSheetTableComponent;
    public highlightedSection: string;

    constructor(
      public dialogRef: MatDialogRef<RegexCheatSheetComponent>,
    ) {}

    public onClose(): void {
        this.dialogRef.close();
    }

    public onSectionHovered(id: string): void {
        this.highlightedSection = id;
    }

    public onSectionSelected(id: string): void {
        this.table.scrollToSection(id);
    }
}
