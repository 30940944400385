import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyMetricsComponent } from './empty-metrics.component';
import { MaterialModule } from '@app/shared/material/material.module';
import { Logs2MatricsDescriptionModule } from '../logs-2-matrics-description/logs-2-matrics-description.module';
import { CgxButtonModule } from '@coralogix/design';

@NgModule({
  declarations: [EmptyMetricsComponent],
  imports: [CommonModule, MaterialModule, Logs2MatricsDescriptionModule, CgxButtonModule],
  exports: [EmptyMetricsComponent],
})
export class EmptyMetricsModule {}
