import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { RulesServerTypesToViewTypes, RuleTypesForDropDownMenu } from '@app/rules/shared/models/rule-types-view.models';
import { ParsingThemeFormService } from '@app/rules/shared/services/parsing-theme-form.service';
import {
  IRuleFieldOptionValue,
  IRuleFormData,
  IRuleGroupFormData,
  IUpdateParsingThemeRuleLogActionPayload,
  RuleFormData,
  RuleGroupFormData,
} from '@app/rules/shared/models/parsing-theme-editor.models';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { IDropdownMenuEntity } from '@shared/models/entity.model';
import { ERuleServerTypes, Rule } from '@app/rules/shared/models/rule.model';
import { getExtendedUniqueFields, State } from '@app/app.reducers';
import { Observable, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { DynamicRuleConfigs } from '@app/rules/shared/models/dynamic-rule-config.consts';
import { MatExpansionPanel } from '@angular/material/expansion';
import _ from 'lodash';
import { LogsFilterGridColumnsActions } from '@app/logs/shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { ParsingThemesApiService } from '@app/rules/shared/services/parsing-themes-api.service';
import { ITestParsingThemeRuleRequest } from '@app/rules/shared/models/sample-log-preview.models';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { ShDialogService } from '@shared/services/dialogService';
import * as parsingThemesActions from '@app/rules/actions/parsing-themes.actions';
import { ConfirmationDialogComponent, IConfirmationDialogData } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'sh-rule-groups-accordion',
  templateUrl: './rule-groups-accordion.component.html',
  styleUrls: ['./rule-groups-accordion.component.scss'],
})
export class RuleGroupsAccordionComponent implements AfterViewInit, OnDestroy {
  @Input() public set ruleGroupsForm(form: FormArray) {
    this._ruleGroupsForm = form;
    this.updateSlidersFormControls();
  }
  public get ruleGroupsForm(): FormArray {
    return this._ruleGroupsForm;
  }
  public rulesServerTypesToViewTypes: {} = RulesServerTypesToViewTypes;
  public sliderTypes: typeof SliderTypes = SliderTypes;
  public groupsSlidersArray: FormGroup = new FormGroup({});
  public rulesSlidersArray: FormGroup = new FormGroup({});
  public addRuleToLastGroupSlider: FormControl = new FormControl(true);
  public ruleTypeOptions: IDropdownMenuEntity[] = RuleTypesForDropDownMenu;
  public ruleFormConfigs: typeof DynamicRuleConfigs = DynamicRuleConfigs;
  @ViewChildren('ruleExpandPanel') public expandPanels: QueryList<MatExpansionPanel>;
  @ViewChildren('ruleSlider') public innerSlidersEls: QueryList<MatSlideToggle>;
  @ViewChildren('groupSlider') public outerSlidersEls: QueryList<MatSlideToggle>;
  public extendedFieldOptions$: Observable<IRuleFieldOptionValue[]>;
  public extendedUniqueFields$: Observable<string[]>;
  public dragInProgress: boolean = false;

  @Input() public openLastRuleOnInit: boolean;
  @Input() public parsingThemeId: string;

  @Output() public addNewGroupWithRule: EventEmitter<ERuleServerTypes> = new EventEmitter<ERuleServerTypes>();
  @Output() public getLogSampleResult: EventEmitter<IUpdateParsingThemeRuleLogActionPayload> =
    new EventEmitter<IUpdateParsingThemeRuleLogActionPayload>();
  public eServerRuleTypes: typeof ERuleServerTypes = ERuleServerTypes;
  @Input() private newRuleAdded: boolean = false;
  private subs: Subscription[] = [];
  private _ruleGroupsForm: FormArray;
  private sampleSubscribers: Subscription[] = [];
  constructor(private parsingThemeFormService: ParsingThemeFormService,
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private parsingThemeApiService: ParsingThemesApiService,
    private dialogService: ShDialogService,
    private dialog: MatDialog
  ) {
    this.setRulesFieldOptions();
  }

  public ngAfterViewInit(): void {
    if ((this.openLastRuleOnInit || this.newRuleAdded) && this.expandPanels?.length) {
      this.expandPanels.last.toggle();
      this.cdr.detectChanges();
      this.newRuleAdded = false;
    }
    this.setExpandPanelSubscription();
    this.setSampleUpdateSubscription();
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.sampleSubscribers.forEach(sub => sub.unsubscribe());
  }

  public handleDropAction(event: CdkDragDrop<FormGroup[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.updateFormAndViewControls();
  }

  public mergeTwoGroups(topGroup: any | FormGroup,
    bottomGroup: any | FormGroup,
    topGroupIndex: number): void {
    if (bottomGroup) {
      bottomGroup.controls.rules.controls.forEach(rule => {
        (topGroup.controls.rules as FormArray).push(rule);
      });
      this.ruleGroupsForm.removeAt(topGroupIndex + 1);
      this.updateFormAndViewControls();
    }
  }

  public splitGroupToTwoGroups(selectedGroup: any | FormGroup,
    selectedGroupIndex: number,
    topRuleIndex: number): void {
    const selectedGroupRules = selectedGroup.controls.rules as FormArray;
    const rulesForNextGroup: IRuleFormData[] = selectedGroup.controls.rules.value.slice(
      topRuleIndex + 1,
      selectedGroupRules.controls.length + 1);

    rulesForNextGroup.forEach(currRule => {
      const _index = (selectedGroupRules.value as IRuleFormData[])
        .findIndex((rule) => rule.id === currRule.id);
      (selectedGroup.controls.rules as FormArray)?.removeAt(_index);
    });

    const newGroupData: IRuleGroupFormData = new RuleGroupFormData({ rules: rulesForNextGroup });
    const newRuleGroup: FormGroup = this.parsingThemeFormService.getGroupForm(newGroupData);
    this.ruleGroupsForm.controls.splice(selectedGroupIndex + 1, 0, newRuleGroup);
    this.updateFormAndViewControls();
  }

  public updateSlider(sliderIndex: number, sliderType: SliderTypes, event: Event): void {
    event.preventDefault();
    const isGroupSliders = sliderType === SliderTypes.groupSlider;
    const chosenList = isGroupSliders ? this.outerSlidersEls : this.innerSlidersEls;
    const currEl: MatSlideToggle = chosenList.find((el, ind) => ind === sliderIndex);
    if (currEl) {
      currEl.checked = !currEl.checked;
    }
  }

  public updateAddRuleSlider(event: Event): void {
    event.preventDefault();
    this.addRuleToLastGroupSlider.setValue(!this.addRuleToLastGroupSlider.value);
  }

  public addRule(ruleType: ERuleServerTypes): void {
    const shouldAddRuleToTheLastGroup = this.addRuleToLastGroupSlider.value;
    if (shouldAddRuleToTheLastGroup) {
      this.addNewRuleToLastGroup(ruleType);
    } else {
      this.addNewGroupWithRule.emit(ruleType);
    }
    this.updateFormAndViewControls();
    this.newRuleAdded = true;
  }

  public deleteRule(groupIndex: number, ruleIndex: number): void {
    const thisGroupRules: FormArray = (this.ruleGroupsForm.at(groupIndex) as FormGroup)?.controls?.rules as FormArray;
    if (thisGroupRules?.length) {
      const data: IConfirmationDialogData = {
        title: `Delete Rule ${thisGroupRules.value[ruleIndex].name}`,
        body: 'Are you sure you want to delete this rule?',
        secondaryActionLabel: 'Cancel',
        primaryActionLabel: 'Delete',
        primaryActionColor: 'error',
        width: '420px',
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data,
      });
      dialogRef.afterClosed()
        .take(1)
        .subscribe(shouldDelete => {
        if (shouldDelete) {
          thisGroupRules.removeAt(ruleIndex);
          this.updateFormAndViewControls();
        }
      });

    }
  }

  public setDragInProgress(isInProgress: boolean): void {
    this.dragInProgress = isInProgress;
  }

  private addNewRuleToLastGroup(ruleType: ERuleServerTypes): void {
    const ruleGroupsArray = this.ruleGroupsForm.getRawValue();
    const lastGroupIndex = ruleGroupsArray.length - 1;
    const lastGroup: IRuleGroupFormData = ruleGroupsArray[lastGroupIndex];
    const lastGroupRulesFormArray: FormArray = (this.ruleGroupsForm.controls[lastGroupIndex] as FormGroup).controls.rules as FormArray;
    const newRuleData = new RuleFormData({ type: ruleType, order: lastGroup.rules.length + 1 });
    const newRule = this.parsingThemeFormService.getRuleForm(newRuleData);
    lastGroupRulesFormArray.push(newRule);
  }

  // view update private functions

  private updateFormAndViewControls(): void {
    let newGroupValues = [];
    newGroupValues = this.getUpdatedFormValuesByFormControlsStructure();
    newGroupValues = this.getUpdatedRulesAndRuleGroupsOrder([...newGroupValues]);
    this.ruleGroupsForm.patchValue(newGroupValues);
    this.updateSlidersFormControls();
    this.updateFormSubscribers();
  }

  private updateFormSubscribers(): void {
    this.sampleSubscribers.forEach(sub => sub.unsubscribe());
    this.setSampleUpdateSubscription();
  }

  // this function is here because moving controls between form arrays,
  // doesn't update the whole form structure so this is what it does :)
  private getUpdatedFormValuesByFormControlsStructure(): IRuleGroupFormData[] {
    const newGroupValues: IRuleGroupFormData[] = [];
    let emptyGroupIndex = null;
    this.ruleGroupsForm.controls.forEach((ruleGroup: FormGroup, index: number) => {
      const rulesFormArray: FormArray = ruleGroup.get('rules') as FormArray;
      const noRulesInGroup = !rulesFormArray.controls?.length;
      if (noRulesInGroup) {
        emptyGroupIndex = index;
        return ruleGroup.getRawValue();
      } else {
        const innerRawValue = {
          ...ruleGroup.getRawValue(),
          rules: (ruleGroup.get('rules') as FormArray)?.getRawValue()
        };
        newGroupValues.push(innerRawValue);
      }
    });
    if (!_.isNull(emptyGroupIndex)) {
      this.ruleGroupsForm.removeAt(emptyGroupIndex);
    }
    return newGroupValues;
  }

  private getUpdatedRulesAndRuleGroupsOrder(groups: IRuleGroupFormData[]): IRuleGroupFormData[] {
    return groups.map((group, index) => ({
      ...group,
      order: index + 1,
      rules: group.rules.map((rule, ruleIndex) => ({
        ...rule,
        order: ruleIndex + 1
      }))
    }));
  }

  private updateSlidersFormControls(): void {
    this.groupsSlidersArray = new FormGroup({});
    this.rulesSlidersArray = new FormGroup({});
    this._ruleGroupsForm.controls.forEach((ruleGroup: FormGroup, i) => {
      const currCtrlName = `group${i}`;
      if (!this.groupsSlidersArray.get(currCtrlName)) {
        this.groupsSlidersArray.addControl(currCtrlName, new FormControl(false));
      } else {
        this.groupsSlidersArray.get(currCtrlName).setValue(false);
      }
      (ruleGroup.controls.rules as FormArray).controls.forEach((rule, ruleIndex) => {
        const currRuleCtrlName = `rule${i}${ruleIndex}`;
        if (!this.rulesSlidersArray.get(currRuleCtrlName)) {
          this.rulesSlidersArray.addControl(currRuleCtrlName, new FormControl(true));
        } else {
          this.rulesSlidersArray.get(currRuleCtrlName).setValue(true);
        }
      });
    });
  }

  private setRulesFieldOptions(): void {
    this.extendedFieldOptions$ = this.parsingThemeFormService.getExtendedRulesFieldOptions();
    this.extendedUniqueFields$ = this.store.select(getExtendedUniqueFields);

    this.store.dispatch(new LogsFilterGridColumnsActions.LogsFilterGridTemplateColumnsInit());
    this.store.dispatch(parsingThemesActions.getParsingThemeExtendedUniqueFields());
  }

  private setExpandPanelSubscription(): void {
    this.subs.push(this.expandPanels?.changes?.subscribe((change: QueryList<MatExpansionPanel>) => {
      if (this.newRuleAdded) {
        this.newRuleAdded = false;
        change.last.toggle();
        this.cdr.detectChanges();
      }
    }));
  }

  private setSampleUpdateSubscription(): void {
    (this.ruleGroupsForm as FormArray).controls.forEach(group => {
      ((group as FormGroup).controls.rules as FormArray).controls.forEach((ruleFormGroup: FormGroup) => {
        this.sampleSubscribers.push(ruleFormGroup.valueChanges.debounceTime(1000).subscribe((ruleChanges: IRuleFormData) => {
          if (ruleFormGroup.value.logExample && ruleChanges.rule) {
            const ruleData: IRuleFormData = { ...ruleChanges, name: ruleChanges.name || 'Rule Example' };
            if (ruleChanges.type === ERuleServerTypes.block) {
              ruleData.type = ruleData.blockMatching as ERuleServerTypes;
            }
            if (ruleData.type === ERuleServerTypes.removeFields && Array.isArray(ruleData.rule)) {
              ruleData.rule = ruleData.rule.join(',');
            }
            delete ruleData.logExample;
            delete ruleData.logExampleRes;
            const request: ITestParsingThemeRuleRequest = {
              rule: new Rule(ruleData),
              logExample: ruleChanges.logExample
            };
            this.parsingThemeApiService.testParsingThemeRule(request).take(1).catch((err: any) => {
              return of(this.dialogService.showCoralogixMessage('Failed to check log validity',
                null, dialogServiceIconClasses.failed));
            }).subscribe((response) => {
              ruleFormGroup.get('logExampleRes').setValue(response, { emitEvent: false });
            });
          }
        }));
      });
    });
  }
}

export enum SliderTypes {
  groupSlider,
  ruleSlider
}
