<div>
  <form [formGroup]="form" (submit)="submit(form.value)">
    <section class="head-section">
      <div class="title-container">
        <p class="primary-header coralogix editor-aq-title">{{isEditable ? 'New Archive Query' : 'Archive Query'}}
        </p>
        <i
          class="fa fa-link fa-2x link-icon"
          aria-hidden="true"
          *ngIf="!isEditable"
          (click)="copyUrl()"
          shTooltip
          tooltip="Copy link to archive query">
        </i>
      </div>

      <button
        [disabled]="isFormInvalid || !isEditable"
        [hidden]="!isEditable"
        [class.disabled]="isFormInvalid"
        class="button coralogix secondary-add-button"
        type="submit"
      >
        RUN ARCHIVE QUERY
      </button>
      <button
        [hidden]="isEditable"
        class="button coralogix secondary-add-button"
        (click)="onCloneClick(form.value)"
        type="button"
      >
        Clone
      </button>
    </section>

    <section class="content-wrapper">
      <div class="content">
        <sh-archive-query-editor-details [form]="detailsGroup" [class.disabled]="!isEditable"></sh-archive-query-editor-details>
        <sh-archive-query-editor-filters
          [isEditable]="isEditable"
          [form]="filterGroup"
          [logsSeverityOptions]="logsSeverityOptions"
          [isTimeRangeValid] = "isTimeRangeValid"
          [isPrePopulated]="isPrePopulated"
          [validationError]="validationError"
          (bubbleFormErr) = "emitErr($event)"
        ></sh-archive-query-editor-filters>
      </div>
    </section>
  </form>
</div>
