import { IPredefinedQuickQuery, TimeQueryType } from '@app/logs-new/shared/models/predefined-time-queries.model';

export enum PredefinedQuickQueryType {
  Today = 0,
  _2Days = 1,
  _3Days = 2,
  _5Days = 3,
  _7Days = 4,
  Last1Hour = 5,
  Last2Hours = 6,
  Last6Hours = 7,
  Last12Hours = 8,
  Last24Hours = 9,
  Last1Minute = 10,
  Last2Minutes = 11,
  Last5Minutes = 12,
  Last15Minutes = 13,
  Last30Minutes = 14,
}

export class PredefinedQuickTimes {
  public predefinedQuickTimes: IPredefinedQuickQuery[] = [
    {
      caption: 'Today',
      value: PredefinedQuickQueryType.Today,
    },
    {
      caption: 'Last 2 Days',
      value: PredefinedQuickQueryType._2Days,
      seconds: 172800,
    },
    {
      caption: 'Last 3 Days',
      value: PredefinedQuickQueryType._3Days,
      seconds: 259200,
    },
    {
      caption: 'Last 5 Days',
      value: PredefinedQuickQueryType._5Days,
      seconds: 432000,
    },
    {
      caption: 'Last 7 Days',
      value: PredefinedQuickQueryType._7Days,
      seconds: 604800,
    },
    {
      caption: 'Last 1 hour',
      seconds: 3600,
      value: PredefinedQuickQueryType.Last1Hour,
    },
    {
      caption: 'Last 2 hours',
      seconds: 7200,
      value: PredefinedQuickQueryType.Last2Hours,
    },
    {
      caption: 'Last 6 hours',
      seconds: 21600,
      value: PredefinedQuickQueryType.Last6Hours,
    },
    {
      caption: 'Last 12 hours',
      seconds: 43200,
      value: PredefinedQuickQueryType.Last12Hours,
    },
    {
      caption: 'Last 24 hours',
      seconds: 86400,
      value: PredefinedQuickQueryType.Last24Hours,
    },
    {
      caption: 'Last 1 minute',
      seconds: 60,
      value: PredefinedQuickQueryType.Last1Minute,
    },
    {
      caption: 'Last 2 minutes',
      seconds: 120,
      value: PredefinedQuickQueryType.Last2Minutes,
    },
    {
      caption: 'Last 5 minutes',
      seconds: 300,
      value: PredefinedQuickQueryType.Last5Minutes,
    },
    {
      caption: 'Last 15 minutes',
      seconds: 900,
      value: PredefinedQuickQueryType.Last15Minutes,
    },
    {
      caption: 'Last 30 minutes',
      seconds: 1800,
      value: PredefinedQuickQueryType.Last30Minutes,
    },
  ];

  public sortedPredefinedQuickTimes: IPredefinedQuickQuery[] = [...this.predefinedQuickTimes].sort(
    (a, b) => a.seconds - b.seconds,
  );

  public slicedQueries: Array<IPredefinedQuickQuery[]> = [
    this.predefinedQuickTimes.slice(10, 15),
    this.predefinedQuickTimes.slice(5, 10),
    this.predefinedQuickTimes.slice(0, 5),
  ];

  public slicedArchiveQueries: Array<IPredefinedQuickQuery[]> = [
    this.predefinedQuickTimes.slice(10, 15),
    this.predefinedQuickTimes.slice(5, 10),
    this.predefinedQuickTimes.slice(0, 1),
  ];
}

export const defaultQuickTimeQuery = {
  data: new PredefinedQuickTimes().predefinedQuickTimes[PredefinedQuickQueryType.Last15Minutes],
  type: TimeQueryType.Quick,
};
