import { jsonMember, jsonObject } from 'typedjson';
import { Constants } from '@app/constants';

export enum ErrorTypes {
  TokenNotFound = 'TOKEN_NOT_FOUND',
  Unauthorized = 401
}

export interface IRedirectData {
  token: string;
  teamId: number;
  userName: string;
  isNewTeamRoute: string;
  teamUrl: string;
}

export const UnAuthResRoutesToCheck: string[] = [Constants.getCompanyUrl, Constants.paymentPlansUrl, Constants.paymentCustomerUrl];

@jsonObject
export class RefreshTokenResponse {
  @jsonMember
  public token: string;
}

export const RESOURCES = {
  CORALOGIX: 1,
  KIBANA: 2,
  API_ACCESS: 3
};

export const ACTIONS = {
  ADMIN: 1,
  USER: 2,
  READ_ONLY: 3
};
