import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoralogixNumberInputComponent } from './coralogix-number-input/coralogix-number-input.component';
import { CoralogixTimeInputComponent } from '@shared/form-controls/coralogix-time-input/coralogix-time-input.component';
import { CoralogixTimeInputSectionComponent } from '@shared/form-controls/coralogix-time-input/coralogix-time-input-time-section/coralogix-time-input-section.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { CoralogixChipsInputComponent } from './coralogix-chips-input/coralogix-chips-input.component';
import { SearchQueryComponent } from '@shared/form-controls/search-query/search-query.component';
import { PopupsModule } from '@shared/popups/popups.module';
import { TutorialsModule } from '@shared/tutorials/tutorials.module';
import { CoralogixMultipleSelectionMenuComponent } from './coralogix-multiple-selection-menu/coralogix-multiple-selection-menu.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EllipsisDetectorDirective } from '../directives/ellipsis-detector.directive';
import { MaterialModule } from '../material/material.module';
import { CoralogixSelectModule } from './coralogix-select/coralogix-select.module';
import { PromqlEditorModule } from '@shared/promql-editor/promql-editor.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    DirectivesModule,
    PopupsModule,
    TutorialsModule,
    ScrollingModule,
    ReactiveFormsModule,
    MaterialModule,
    CoralogixSelectModule,
    PromqlEditorModule,
  ],
  declarations: [
    CoralogixNumberInputComponent,
    CoralogixTimeInputComponent,
    CoralogixTimeInputSectionComponent,
    CoralogixChipsInputComponent,
    SearchQueryComponent,
    CoralogixMultipleSelectionMenuComponent,
    EllipsisDetectorDirective,
  ],
  exports: [
    MaterialModule,
    CoralogixNumberInputComponent,
    CoralogixTimeInputComponent,
    CoralogixTimeInputSectionComponent,
    DirectivesModule,
    CoralogixChipsInputComponent,
    SearchQueryComponent,
    CoralogixMultipleSelectionMenuComponent,
    EllipsisDetectorDirective,
    CoralogixSelectModule,
  ],
})
export class FormControlsModule {}
