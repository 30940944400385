import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getArchivingProvider,
  getArchivingProvidersLoading,
  getArchivingProvidersSaving,
  getArchivingProvidersTesting,
  getArchivingProvidersTestingResult,
  State,
} from '../../../../app.reducers';
import { Observable } from 'rxjs/Observable';
import { ArchivingProvidersActions } from '../../../reducer/archiving-providers/archiving-providers.action';
import { ArchivingProviderModel } from '../../../shared/models/archivingProviderModel';
import { ArchivingProvidersReducer } from '../../../reducer/archiving-providers/archiving-providers.reducer';
import TestingResultState = ArchivingProvidersReducer.TestingResultState;

@Component({
  selector: 'sh-archiving-providers-layout',
  templateUrl: './archiving-providers-layout.component.html',
  styleUrls: ['./archiving-providers-layout.component.scss'],
})
export class ArchivingProvidersLayoutComponent implements OnInit {
  public archivingProviders$: Observable<any>;
  public isLoading$: Observable<boolean>;
  public isSaving$: Observable<boolean>;
  public testing$: Observable<boolean>;
  public testingResult$: Observable<TestingResultState>;

  constructor(private store: Store<State>) {
    this.archivingProviders$ = this.store.select(getArchivingProvider);
    this.isLoading$ = this.store.select(getArchivingProvidersLoading);
    this.isSaving$ = this.store.select(getArchivingProvidersSaving);
    this.testingResult$ = this.store.select(getArchivingProvidersTestingResult);
    this.testing$ = this.store.select(getArchivingProvidersTesting);
  }

  public editArchivingProvider(event: ArchivingProviderModel) {
    this.store.dispatch(
      new ArchivingProvidersActions.SaveArchivingProvidersAction(event),
    );
  }

  public testArchivingProvider(event: ArchivingProviderModel) {
    this.store.dispatch(
      new ArchivingProvidersActions.TestArchivingProviderAction(event),
    );
  }

  public ngOnInit() {
    this.store.dispatch(
      new ArchivingProvidersActions.GetArchivingProvidersAction(),
    );
  }
}
