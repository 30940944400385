import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@app/constants';
import { Team } from '@app/user/shared/models/team';
import { Team2 } from '@app/user/shared/models/team.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private url = Constants.GetUrl() + '/api/v1/auditing';
  constructor(private http: HttpClient) {}

  createAuditTeam(team: Team): Observable<Team2> {
    return this.http.post<Team2>(this.url, { companyToCreate: team });
  }

  getAuditCompanies(): Observable<Team[]> {
    return this.http.get<Team[]>(this.url);
  }

  getAuditTeamConfigured(): Observable<Team2 | null> {
    return this.http.get<any>(`${this.url}/configured`);
  }

  attachToAuditTeam(team: Team2): Observable<Team2> {
    return this.http.post<Team2>(`${this.url}/attach`, {
      companyIdToAttach: team.id,
    });
  }

  detachFromAuditTeam(team: Team2): Observable<Team2> {
    return this.http.post<Team2>(`${this.url}/deactivate`, {
      companyIdToDetach: team.id,
    });
  }
}
