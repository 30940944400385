export class RegexTableRow {
    public symbol: string = null;
    public explanation: string = null;
    public example: string = null;
    public match: string[] = [];
    public noMatch: string[] = [];
}

export interface IRegexTableData {
    characters: RegexTableRow[];
    quantifiers: RegexTableRow[];
    characterClasses: RegexTableRow[];
    characterClassesWithQuantifiers: RegexTableRow[];
    anchorsAndBoundaries: RegexTableRow[];
    groupsAndBackreferences: RegexTableRow[];
    greedyVsNonGreedyQuantifiers: RegexTableRow[];
    inlineModifiers: RegexTableRow[];
}
