import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { MetadataActions } from '@shared/state/actions/metadata.actions';
import { MetadataService } from '@app/logs/shared/services/metadata.service';

@Injectable()
export class MetadataEffects {
  @Effect()
  public getApplicationName$: Observable<Action> = this.actions$.pipe(
    ofType(MetadataActions.ActionTypes.GET_APPLICATION_NAME),
    switchMap((action: any) => {
      return this.metadataService
        .getMetaData('applicationName', action.payload)
        .map((res) => new MetadataActions.GetApplicationNameCompletedAction(res))
        .catch((err) => of(new MetadataActions.GetApplicationNameFailedAction(err)));
    }),
  );

  @Effect()
  public getSubsystemName$: Observable<Action> = this.actions$.pipe(
    ofType(MetadataActions.ActionTypes.GET_SUBSYSTEM_NAME),
    switchMap((action: any) => {
      return this.metadataService
        .getMetaData('subsystemName', action.payload)
        .map((res) => new MetadataActions.GetSubsystemNameCompletedAction(res))
        .catch((err) => of(new MetadataActions.GetSubsystemNameFailedAction(err)));
    }),
  );
  constructor(private actions$: Actions, private metadataService: MetadataService) {}
}
