import { type } from '@shared/services/utils';
import { Action } from '@ngrx/store';
import { Metadata } from '@app/logs/shared/models/query-metadata';

export namespace MetadataActions {
  export const ActionTypes = {
    GET_APPLICATION_NAME: type('[Metadata] get application name'),
    GET_APPLICATION_NAME_COMPLETED: type('[Metadata] get application name completed'),
    GET_APPLICATION_NAME_FAILED: type('[Metadata] get application name failed'),
    GET_SUBSYSTEM_NAME: type('[Metadata] get subsystem name'),
    GET_SUBSYSTEM_NAME_COMPLETED: type('[Metadata] get subsystem name completed'),
    GET_SUBSYSTEM_NAME_FAILED: type('[Metadata] get subsystem name failed')
  };

  export class GetApplicationNameAction implements Action {
    public type = ActionTypes.GET_APPLICATION_NAME;

    constructor(public payload: Metadata) {}
  }

  export class GetApplicationNameCompletedAction implements Action {
    public type = ActionTypes.GET_APPLICATION_NAME_COMPLETED;

    constructor(public payload: string[]) {}
  }

  export class GetApplicationNameFailedAction implements Action {
    public type = ActionTypes.GET_APPLICATION_NAME_FAILED;

    constructor(public payload: any) {}
  }

  export class GetSubsystemNameAction implements Action {
    public type = ActionTypes.GET_SUBSYSTEM_NAME;

    constructor(public payload: Metadata) {}
  }

  export class GetSubsystemNameCompletedAction implements Action {
    public type = ActionTypes.GET_SUBSYSTEM_NAME_COMPLETED;

    constructor(public payload: string[]) {}
  }

  export class GetSubsystemNameFailedAction implements Action {
    public type = ActionTypes.GET_SUBSYSTEM_NAME_FAILED;

    constructor(public payload: any) {}
  }

  export type Actions =
    GetApplicationNameAction |
    GetSubsystemNameAction |
    GetApplicationNameCompletedAction |
    GetApplicationNameFailedAction |
    GetSubsystemNameCompletedAction |
    GetSubsystemNameFailedAction;
}
