<mat-card class="coralogix card" [class.invalid]="isFormInvalid || invalidErrMsg" [formGroup]="form">
  <ng-container *ngIf="metricAlertSelected; else alertQueryTemplate">
    <mat-tab-group
      animationDuration="0"
      [selectedIndex]="selectedAlertSyntaxType.value"
      (selectedIndexChange)="onTabChange($event)"
      class="alerts-query-tab-group"
    >
      <mat-tab label="Lucene">
        <ng-template matTabContent>
          <ng-container *ngTemplateOutlet="alertQueryTemplate"></ng-container>
        </ng-template>
      </mat-tab>
      <mat-tab label="PromQL">
        <ng-template matTabContent>
          <ng-container *ngTemplateOutlet="alertQueryTemplate"></ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <ng-container *ngIf="!relativeAlertSelected">
    <ng-container *ngTemplateOutlet="alertsConditionTemplate"></ng-container>
  </ng-container>

  <div *ngIf="alertTypeViewsDefs[alertType]?.includes(componentTypes.queryArray)">
    <ng-container formArrayName="relativeAlerts">
      <div *ngFor="let raFormGroup of relativeAlertsArray.controls; index as queryIndex" class="relative-alert-query-container">
        <sh-alerts-query
          [form]="raFormGroup"
          [logsSeverityOptions]="selectOptions.logSeverityOptions"
          [applicationOptions]="selectOptions.applicationOptions | async"
          [subsystemOptions]="selectOptions.subsystemOptions | async"
          [isFromQuery]="false"
          [isRelative]="relativeAlertSelected"
          [isMetric]="metricAlertSelected"
          [queryIndex]="queryIndex"
        ></sh-alerts-query>
      </div>
      <ng-container *ngTemplateOutlet="alertsConditionTemplate"></ng-container>
    </ng-container>
  </div>
</mat-card>

<ng-template #alertsConditionTemplate>
  <sh-alerts-conditions
    *ngIf="alertTypeViewsDefs[alertType]?.includes(componentTypes.conditions)"
    [alertType]="alertType"
    [form]="conditionsGroup"
    [conditionsOperatorOptions]="selectOptions.conditionsOperatorOptions"
    [conditionTimeframeOptions]="selectOptions.conditionTimeframeOptions"
    [relativeTimeframeOptions]="selectOptions.relativeTimeframeOptions"
    [relativeTimeframeOptionIdMapping]="selectOptions.relativeTimeframeOptionIdMapping"
    [relativeTimeframeSilenceDisplay]="selectOptions.relativeTimeframeSilenceDisplay"
    [cardinalityTimeframeOptions]="selectOptions.cardinalityTimeframeOptions"
    [conditionMetricArithmeticOptions]="selectOptions.conditionMetricArithmeticOptions"
    [metrics]="selectOptions.metrics | async"
    [newMetricsMetricFields]="selectOptions.newMetricsMetricFields | async"
    [newMetricsMetricLabels]="selectOptions.newMetricsMetricLabels | async"
    [groupByOptions]="selectOptions.groupByOptions | async"
    [relativeCalcStr]="relativeCalcStr"
    [isPromQLSyntax]="isPromQLSyntax"
  ></sh-alerts-conditions>
</ng-template>

<ng-template #alertQueryTemplate>
  <sh-alerts-query
    [isPromQLSyntax]="isPromQLSyntax"
    [form]="queryGroup"
    [logsSeverityOptions]="selectOptions.logSeverityOptions"
    [applicationOptions]="selectOptions.applicationOptions | async"
    [subsystemOptions]="selectOptions.subsystemOptions | async"
    [isFromQuery]="isFromQuery"
    [isRelative]="relativeAlertSelected"
    [invalidErrMsg]="invalidErrMsg"
    [isMetric]="metricAlertSelected"
  ></sh-alerts-query>
</ng-template>
