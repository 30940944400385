import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { TypedJSON } from 'typedjson';

import { Constants } from '@app/constants';
import { SamlConfigurationsModel } from '@app/settings/shared/models/samlConfigurationsModel';
import { AuthService } from '@app/auth/shared/services/auth.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { ConfigurationService } from '@app/services/configuration.service';

@Injectable()
export class SamlService {
  private saml_url: string = Constants.companySamlConfigurationUrl;
  private httpClientBypass: HttpClient;

  constructor(
    private httpClient: HttpClient,
    private httpHandler: HttpBackend,
    private authService: AuthService,
    private configService: ConfigurationService,
  ) {
    // Creating new instance of HttpClient to bypass the global http interceptor
    this.httpClientBypass = new HttpClient(httpHandler);
  }

  public saveMetadataXML(xml_file: File): Observable<SamlConfigurationsModel> {
    const formData: any = new FormData();
    formData.append('metadata', xml_file, xml_file.name);

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.getToken()}`,
    });

    return this.httpClientBypass.post(`${this.saml_url}/metadata`, formData, { headers }).pipe(
      map((res) => new TypedJSON(SamlConfigurationsModel).parse(JSON.stringify(res))),
      catchError(this.handleError),
    );
  }

  public saveSamlConfigurations(saml_configurations: SamlConfigurationsModel): Observable<SamlConfigurationsModel> {
    return this.httpClient.post(this.saml_url, saml_configurations).pipe(
      map((res: any) => new TypedJSON(SamlConfigurationsModel).parse(res)),
      catchError(this.handleError),
    );
  }

  public getSamlConfigurations(): Observable<SamlConfigurationsModel> {
    return this.httpClient.get(this.saml_url).pipe(
      map((res: any) => new TypedJSON(SamlConfigurationsModel).parse(res)),
      catchError(this.handleError),
    );
  }

  public getCompanySamlInfo(team_url: string): Observable<any> {
    return this.httpClient.get(`${this.saml_url}/${team_url}`).pipe(
      map((res: any) => res),
      catchError(this.handleError),
    );
  }

  public SaveCompanySamlTeamEntityId(): Observable<SamlConfigurationsModel> {
    return this.httpClient.put(`${this.saml_url}`, {}).pipe(
      map((res) => new TypedJSON(SamlConfigurationsModel).parse(res)),
      catchError(this.handleError),
    );
  }

  public SaveCompanySamlToken(): Observable<SamlConfigurationsModel> {
    return this.httpClient.put(`${this.saml_url}/token`, {}).pipe(
      map((res) => new TypedJSON(SamlConfigurationsModel).parse(res)),
      catchError(this.handleError),
    );
  }

  public RemoveCompanySamlToken(): Observable<SamlConfigurationsModel> {
    return this.httpClient.delete(`${this.saml_url}/token`, {}).pipe(
      map((res) => new TypedJSON(SamlConfigurationsModel).parse(res)),
      catchError(this.handleError),
    );
  }

  updateDefaultGroups(groupManagement: { toDelete: number[]; toAdd: number[] }): Observable<{ [key: string]: number }> {
    const url = this.configService.getConfigURL('/api/v1/companies/defaultGroups');
    return this.httpClient.post(url, { groupManagement }).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }

  getDefaultGroups(): Observable<{ [key: string]: number }> {
    const url = this.configService.getConfigURL('/api/v1/companies/defaultGroups');
    return this.httpClient.get(url).pipe(
      map((res: string[]) => res),
      catchError(this.handleError),
    );
  }

  private handleError(error: any): Observable<any> {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg);
    return throwError(error);
  }
}
