<sh-json-formatter
  [searchQuery]="params?.context?.parent?.searchQuery$ | async"
  [isExpanded]="params?.context?.parent?.expandedMode$ | async"
  [hasMenu]="params?.context?.parent?.showJsonFormatterMenu"
  [data]="data"
  [colId]="colId"
  [tooltipOptions]="params?.context?.parent?.isTemplates ? templateTooltipOptions : logsTooltipOptions"
  (renderedJsonLog)="onJsonRender()"
  [useTokenizer]="!params?.context?.parent?.isTemplates"
></sh-json-formatter>

<sh-json-formatter-menu
  [elRef]="elRef"
  *ngIf="params?.context?.parent?.showJsonFormatterMenu && (renderedJsonLog$ | async)"
  data-test="sh-json-formatter-menu"
  [params]="params"
></sh-json-formatter-menu>
