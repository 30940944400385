import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sh-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
})
export class ActionMenuComponent {
  @Output() public deleteAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() public editAction: EventEmitter<any> = new EventEmitter<any>();
  @Input() public menuImg: string = 'vertical-menu.svg';
}
