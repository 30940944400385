import { enumsTranslator } from '@app/shared/config/enums-translator';
import { startCase } from 'lodash';
import { Priority } from '../enums/priority.enum';
import { IPolicyOverride } from '../interfaces/policy-override.interface';
import { IPolicyOverrideSerialized } from '../serialized/policy-override.serialized';
import { PolicyCriteria } from './policy-criteria.model';
import { PolicyOverrideSeverity } from './policy-override-severity.model';

export class PolicyOverride implements IPolicyOverride {
    public applicationName: string;

    public subsystemName: string;

    public policyOverrideSeverities: PolicyOverrideSeverity[];

    constructor(obj: IPolicyOverride) {
        this.applicationName = obj.applicationName;
        this.subsystemName = obj.subsystemName;
        this.policyOverrideSeverities = obj.policyOverrideSeverities as PolicyOverrideSeverity[];
    }

    get id(): string {
        return `${this.applicationName}|${this.subsystemName}`;
    }

    get policies(): PolicyCriteria[] {
        return this.policyOverrideSeverities.map(obj => obj.policy).filter(policy => !!policy);
    }

    get policiesTooltipText(): string {
        const severities = enumsTranslator.Severity;
        return this.policyOverrideSeverities.reduce((tooltip, policyOverrideSeverity) => {
            tooltip += `${startCase(severities[policyOverrideSeverity.severity])}: ${policyOverrideSeverity?.policy?.name || '-'}\n`;
            return tooltip;
        }, '');
    }

    get prioritiesTooltipText(): string {
        const severities = enumsTranslator.Severity;
        return this.policyOverrideSeverities.reduce((tooltip, policyOverrideSeverity) => {
            tooltip += `${startCase(severities[policyOverrideSeverity.severity])}: ${policyOverrideSeverity?.evalPriority}\n`;
            return tooltip;
        }, '');
    }

    get policiesDiffNumber(): number {
        const policyMap: { [name: string]: number } = this.policies.reduce((acc, curr) => {
            acc[curr.name] = acc[curr.name] ? acc[curr.name] + 1 : 1;
            return acc;
        }, {});

        const policiesNumber = Object.values(policyMap).reduce((acc: number, curr: number) => {
            acc += curr;
            return acc;
        }, 0);

        // In case no policies
        if (Object.values(policyMap).length === 0) {
            return 0;
        }
        //  In case all policyOverrideSeverity items has the same policy
        if (Object.values(policyMap).length === 1 && policiesNumber === 6) {
            return 1;
        }
        // In case all PolicyOverrideSeverity has a policy, and at least 2 different ones
        if (policiesNumber === 6) {
            return Object.keys(policyMap).length;
        }
        // In case at least 1 PolicyOverrideSeverity item has no policy and at least 1 PolicyOverrideSeverity has a policy
        return Object.keys(policyMap).length + 1;
    }

    get policyStatus(): string {
        if (this.policiesDiffNumber === 0) {
            return 'No Policy';
        } else if (this.policiesDiffNumber !== 1) {
            return 'Multiple';
        } else {
            return this.policies[0].name;
        }
    }

    get priorities(): Priority[] {
        return this.policyOverrideSeverities.map(obj => obj.priority);
    }

    get evalPriorities(): Priority[] {
        return this.policyOverrideSeverities.map(obj => obj.evalPriority);
    }

    get hasOverrides(): boolean {
        return this.policyOverrideSeverities.filter((policyOverrideSeverity => !!policyOverrideSeverity.priority)).length > 0;
    }

    get priorityNumber(): { [priority: string]: number } {
        return this.evalPriorities.reduce((acc, curr) => {
            acc[String(curr)] = acc[String(curr)] ? acc[String(curr)] + 1 : 1;
            return acc;
        }, {});
    }

    get priorityDiffNumber(): number {
        const priorityKeys = Object.keys(this.priorityNumber);

        if (priorityKeys.length === 1 && priorityKeys[0] === 'null') {
            return 0;
        }

        return priorityKeys.length;
    }

    get priorityStatus(): string {
        const priorities = enumsTranslator.Priority;
        let priority: string;
        if (this.priorityDiffNumber === 0) {
            priority = 'High';
        } else if (this.priorityDiffNumber === 1) {
            const str = Object.keys(this.priorityNumber)[0];
            priority = startCase(priorities[str]);
        } else {
            priority = 'Multiple';
        }

        if (!this.hasOverrides) {
            return `Default (${priority})`;
        }

        return priority;
    }

    get totalSize(): number {
        return this.policyOverrideSeverities.reduce((acc, curr) => {
            acc += curr.totalSize;
            return acc;
        }, 0);
    }

    get totalSizeFactorized(): number {
        return this.policyOverrideSeverities.reduce((acc, curr) => {
            acc += curr.totalSizeFactorized;
            return acc;
        }, 0);
    }

    get hasAtLeastOnePolicy(): boolean {
        return this.policyOverrideSeverities.filter(obj => !!obj.policy).length > 0;
    }

    get hasAtLeastOneWithoutPolicy(): boolean {
        return this.policyOverrideSeverities.filter(obj => !obj.policy).length > 0;
    }

    public static deserializer(obj: IPolicyOverrideSerialized): PolicyOverride {
        let policyOverride: PolicyOverride = null;
        if (obj) {
            policyOverride = new PolicyOverride({
                applicationName: obj.applicationName,
                subsystemName: obj.subsystemName,
                policyOverrideSeverities: obj.policyOverrideSeverities.map(item => PolicyOverrideSeverity.deserializer(item))
            });
        }
        return policyOverride;
    }

    public static create(obj: IPolicyOverride): PolicyOverride {
        let policyOverride: PolicyOverride = null;

        if (obj) {
            policyOverride = new PolicyOverride({
                applicationName: obj.applicationName,
                subsystemName: obj.subsystemName,
                policyOverrideSeverities: obj.policyOverrideSeverities.map(item => PolicyOverrideSeverity.create(item))
            });
        }

        return policyOverride;
    }
}
