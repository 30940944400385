import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CreatePlugin, DeletePlugin, UpdatePlugin, UpdatePluginsOrder } from '@app/ngxs-store/plugins/plugins.action';
import { PluginsState } from '@app/ngxs-store/plugins/plugins.state';
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { isEqual, omit } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { IPlugin, Plugin } from './model/plugin.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PluginTypes } from '@shared/enums/plugin-types.enum';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ShDialogService } from '@app/shared/services/dialogService';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';
import { HttpErrorResponse } from '@angular/common/http';
import { NunjucksValidator } from '@shared/validators/nunjucks.validator';

const defaultPluginValues = (): IPlugin => ({
  id: undefined,
  name: 'Untitled Action',
  userId: undefined,
  userName: undefined,
  url: '',
  applicationNames: [],
  subsystemNames: [],
  isPrivate: true,
  isHidden: false,
});

@Injectable()
export class ManagePluginsContainerFormsService implements OnDestroy {
  @Select(PluginsState.privatePluginList) public privatePlugins$: Observable<Plugin[]>;

  @Select(PluginsState.sharedPluginList) public sharedPlugins$: Observable<Plugin[]>;

  @SelectSnapshot(PluginsState.plugins) public allPlugins: { [id: string]: Plugin };

  privatePluginsForm: FormGroup;

  sharedPluginsForm: FormGroup;

  pluginsForm: FormGroup = this.fb.group({
    privatePlugins: this.fb.array([]),
    sharedPlugins: this.fb.array([]),
    currentPluginForm: [null],
    newPluginForm: [null],
  });

  destroyed$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private store: Store,
    private actions$: Actions,
    private dialogService: ShDialogService,
  ) {
    this.init();
  }

  get privatePluginsArray(): FormArray {
    return this.pluginsForm.get('privatePlugins') as FormArray;
  }

  get sharedPluginsArray(): FormArray {
    return this.pluginsForm.get('sharedPlugins') as FormArray;
  }

  get currentPluginForm(): FormGroup {
    return this.pluginsForm.get('currentPluginForm').value as FormGroup;
  }

  get newPluginForm(): FormGroup {
    return this.pluginsForm.get('newPluginForm').value as FormGroup;
  }

  get privateRenderFormArray(): AbstractControl[] {
    return this.newPluginForm ? [this.newPluginForm, ...this.privatePluginsArray.controls] : this.privatePluginsArray.controls;
  }

  get sharedRenderFormArray(): AbstractControl[] {
    return this.sharedPluginsArray.controls;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggleAdvance(): void {
    const isAdvance = !this.currentPluginForm.value.isAdvance;
    this.currentPluginForm.patchValue({ isAdvance });
    this.cdr.markForCheck();
  }

  toggleHidden(pluginForm: FormGroup): void {
    if (this.currentPluginForm && this.currentPluginForm !== pluginForm) {
      return;
    }
    const isHidden = !pluginForm.value.isHidden;
    const isEditPanelOpen = this.currentPluginForm === pluginForm;
    if (isEditPanelOpen) {
      pluginForm.patchValue({ isHidden });
    } else {
      pluginForm.patchValue({ isHiddenLoading: true });
      const plugin = Plugin.create({
        ...pluginForm.value,
        isPrivate: !pluginForm.value.isShared,
        isHidden,
      });
      this.handleToggleHiddenAction(pluginForm, isHidden);
      this.updatePlugin(plugin);
    }
    this.cdr.markForCheck();
  }

  handleToggleHiddenAction(pluginForm: FormGroup, isHidden: boolean): void {
    this.actions$.pipe(ofActionCompleted(UpdatePlugin), take(1)).subscribe(actionCompletion => {
      if (actionCompletion.result.error) {
        const message = (actionCompletion.result.error as HttpErrorResponse)?.error?.message || 'Failed to update action';
        this.dialogService.showCoralogixMessage(message, null, dialogServiceIconClasses.failed);
      } else if (actionCompletion.result.successful) {
        pluginForm.patchValue({ isHidden });
      }
      pluginForm.patchValue({ isHiddenLoading: false });
      this.cdr.markForCheck();
    });
  }

  openPluginForm(pluginFormGroup: FormGroup): void {
    if (this.currentPluginForm) {
      const originalPluginForm = this.getOriginalPluginForm(this.currentPluginForm);
      if (this.newPluginForm) {
        this.openConfirmationDialog(originalPluginForm, pluginFormGroup);
      } else if (pluginFormGroup === this.currentPluginForm) {
        if (this.areEqualPlugins(this.currentPluginForm, originalPluginForm)) {
          this.currentPluginForm.patchValue({ isAdvance: false });
          this.updateCurrentPlugin(null);
        } else {
          this.openConfirmationDialog(originalPluginForm, pluginFormGroup);
        }
      } else {
        if (this.areEqualPlugins(this.currentPluginForm, originalPluginForm)) {
          this.currentPluginForm.patchValue({ isAdvance: false });
          this.updateCurrentPlugin(pluginFormGroup);
        } else {
          this.openConfirmationDialog(originalPluginForm, pluginFormGroup);
        }
      }
    } else {
      this.updateCurrentPlugin(pluginFormGroup);
    }
  }

  updateCurrentPlugin(pluginFormGroup: FormGroup): void {
    this.pluginsForm.patchValue({
      currentPluginForm: pluginFormGroup,
    });
    this.cdr.markForCheck();
  }

  updateNewPlugin(pluginFormGroup: FormGroup): void {
    this.pluginsForm.patchValue({
      newPluginForm: pluginFormGroup,
    });
    this.cdr.markForCheck();
  }

  getOriginalPluginForm(pluginFormGroup: FormGroup): FormGroup {
    if (pluginFormGroup.value.id) {
      const originalPlugin = this.allPlugins[this.currentPluginForm?.value?.id];
      return this.pluginFormBuilder(originalPlugin);
    } else {
      return this.getDefaultPluginForm();
    }
  }

  addNewPlugin(): void {
    if (this.currentPluginForm) {
      return;
    }
    const pluginForm = this.getDefaultPluginForm();
    this.updateCurrentPlugin(pluginForm);
    this.updateNewPlugin(pluginForm);
  }

  clonePlugin(plugin: FormGroup): void {
    if (this.currentPluginForm) {
      const originalPluginForm = this.getOriginalPluginForm(this.currentPluginForm);
      this.currentPluginForm.patchValue({ ...originalPluginForm.value });
    }
    const pluginForm = this.getDefaultPluginForm(plugin.value);
    this.updateCurrentPlugin(pluginForm);
    this.updateNewPlugin(pluginForm);
  }

  getDefaultPluginForm(plugin: IPlugin = defaultPluginValues()): FormGroup {
    return this.fb.group({
      id: [plugin.id],
      name: [plugin.name, [Validators.required]],
      url: [plugin.url, [Validators.required, Validators.maxLength(32768), NunjucksValidator.nunjucksExpression]],
      userId: [plugin.userId],
      userName: [plugin.userName],
      applicationNames: [plugin.applicationNames],
      subsystemNames: [plugin.subsystemNames],
      isShared: [false],
      isAdvance: [false],
      isHidden: [plugin.isHidden],
      isHiddenLoading: [false],
    });
  }

  areEqualPlugins(a: FormGroup, b: FormGroup): boolean {
    const aValue = omit(a.value, ['isAdvance']);
    const bValue = omit(b.value, ['isAdvance']);
    return isEqual(aValue, bValue);
  }

  openConfirmationDialog(originalPluginForm: FormGroup, selectedPlugin: FormGroup): void {
    const data: IConfirmationDialogData = {
      title: 'Discard changes',
      body: 'Are you sure you want to discard all your changes?',
      secondaryActionLabel: 'Cancel',
      primaryActionLabel: 'Discard',
      width: '400px',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: boolean) => {
        if (response) {
          if (this.currentPluginForm === selectedPlugin) {
            if (this.newPluginForm) {
              this.updateCurrentPlugin(null);
              this.updateNewPlugin(null);
            } else {
              this.currentPluginForm.patchValue({ ...originalPluginForm.value });
              this.updateCurrentPlugin(null);
            }
          } else {
            if (this.newPluginForm) {
              this.updateCurrentPlugin(selectedPlugin);
              this.updateNewPlugin(null);
            } else {
              this.currentPluginForm.patchValue({ ...originalPluginForm.value });
              this.updateCurrentPlugin(selectedPlugin);
            }
          }
        }
      });
  }

  resetPlugin(): void {
    const originalPluginForm = this.getOriginalPluginForm(this.currentPluginForm);
    const { isAdvance } = this.currentPluginForm.value;
    originalPluginForm.patchValue({ isAdvance });
    this.currentPluginForm.patchValue({ ...originalPluginForm.value });
    this.cdr.markForCheck();
  }

  deletePlugin(): void {
    if (this.newPluginForm) {
      this.updateNewPlugin(null);
      this.updateCurrentPlugin(null);
    } else {
      this.store
        .dispatch(new DeletePlugin(this.currentPluginForm.value.id))
        .pipe(
          take(1),
          map(() => {
            this.updateCurrentPlugin(null);
            this.updateNewPlugin(null);
          }),
        )
        .subscribe();
    }
  }

  savePlugin(): void {
    this.currentPluginForm.markAllAsTouched();
    if (this.currentPluginForm.valid) {
      const plugin = Plugin.create({ ...this.currentPluginForm.value, isPrivate: !this.currentPluginForm.value.isShared });
      if (this.newPluginForm) {
        this.store
          .dispatch(new CreatePlugin(plugin))
          .pipe(
            take(1),
            map(() => {
              this.updateCurrentPlugin(null);
              this.updateNewPlugin(null);
            }),
          )
          .subscribe();
      } else {
        this.handleUpdateAction();
        this.updatePlugin(plugin);
      }
    }
  }

  handleUpdateAction(): void {
    this.actions$.pipe(ofActionCompleted(UpdatePlugin), take(1)).subscribe(actionCompletion => {
      if (actionCompletion.result.error) {
        const message = (actionCompletion.result.error as HttpErrorResponse)?.error?.message || 'Failed to update action';
        this.dialogService.showCoralogixMessage(message, null, dialogServiceIconClasses.failed);
      } else if (actionCompletion.result.successful) {
        this.updateCurrentPlugin(null);
      }
    });
  }

  closePanel(): void {
    this.updateCurrentPlugin(null);
    this.updateNewPlugin(null);
  }

  drop(event: CdkDragDrop<string[]>, context: PluginTypes): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    const formArray = context === PluginTypes.Private ? this.privatePluginsArray : this.sharedPluginsArray;
    const ids = formArray.controls.map(pluginForm => pluginForm.value.id);
    moveItemInArray(ids, event.previousIndex, event.currentIndex);
    this.updatePluginsOrder(context, ids);
  }

  private init(): void {
    this.privatePlugins$.pipe(takeUntil(this.destroyed$)).subscribe(plugins => {
      this.privatePluginsArray.controls = [];
      for (const plugin of plugins) {
        this.privatePluginsArray.controls.push(this.pluginFormBuilder(plugin));
      }
      this.cdr.markForCheck();
    });

    this.sharedPlugins$.pipe(takeUntil(this.destroyed$)).subscribe(plugins => {
      this.sharedPluginsArray.controls = [];
      for (const plugin of plugins) {
        this.sharedPluginsArray.controls.push(this.pluginFormBuilder(plugin));
      }
      this.cdr.markForCheck();
    });
  }

  private pluginFormBuilder(plugin?: Plugin): FormGroup {
    const pluginForm = this.fb.group({
      id: [plugin.id],
      name: [plugin.name, [Validators.required]],
      url: [plugin.url, [Validators.required, Validators.maxLength(32768), NunjucksValidator.nunjucksExpression]],
      userId: [plugin.userId],
      userName: [plugin.userName],
      applicationNames: [plugin.applicationNames],
      subsystemNames: [plugin.subsystemNames],
      isShared: [!plugin.isPrivate],
      isAdvance: [false],
      isHidden: [plugin.isHidden],
      isHiddenLoading: [false],
    });
    return pluginForm;
  }

  @Dispatch() private updatePluginsOrder = (type: PluginTypes, ids: string[]) => new UpdatePluginsOrder(type, ids);
  @Dispatch() private updatePlugin = (plugin: Plugin) => new UpdatePlugin(plugin);
}
