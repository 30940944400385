<div class="center-panel">
  <h1>
    <i class="line chart icon large"></i>
    See your logs live!
  </h1>
  <div>
    <button
      (click)="startLiveTail.emit()"
      class="ui inverted blue button massive"
    >
      <i class="play icon"></i>
      Get All
    </button>
    <button
      (click)="showFilters.emit()"
      class="ui inverted pink button massive"
    >
      <i class="filter icon"></i>
      Set Filters
    </button>
  </div>
  <div></div>
</div>
