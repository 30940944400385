import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid/main';
import { GridColumnDef, GridRowStyle } from './grid-column-def';
import { SearchProvider } from '../search/search.provider';
import { Subscription } from 'rxjs';
import { LogQueryData } from '@app/logs/shared/models/logquery.data';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import * as _ from 'lodash';
import { ApplicationDispatcher } from '../../services/application-dispatcher';
import { State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { GridPagingComponent } from './grid-paging/grid-paging.component';
import { GridCellHelper } from '../grid-cells/grid-cell-helper';
import { Constants } from '@app/constants';
import { LogManager } from '@app/logs/shared/services/logs-manager';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';

const PAGES_LiMIT: number = 150;

@Component({
  moduleId: 'grid',
  selector: 'sh-grid',
  templateUrl: 'grid.component.html',
  styleUrls: ['grid.component.scss']
})
export class GridComponent implements OnInit, OnDestroy {

  @Input() set selectedRowIndex(index: number) {
    this._selectedRowIndex = index;
    this.selectRow(index);
  }

  @Input() set state(val: LogManager) {
    if (val) {
      this._logManager = val;
      this.updateLogManager(val);
    }
  }

  @Input() set highlightedLogs(map: Map<string, string>) {
    this._highlightedLogs = map;
    if (this.gridOptions && this.gridOptions.api) {
      this.refreshGrid();
    }
  }

  get highlightedLogs(): Map<string, string> {
    return this._highlightedLogs;
  }

  @Input() set searchProvider(provider: SearchProvider) {
    this.OnFindSubscription = provider.onFind.subscribe(e => {
      this.OnFindKeyUp(e);
    });
    this.OnFindUpSubscription = provider.onFindUp.subscribe(e => this.findUp(e));
    this.OnFindDownSubscription = provider.onFindDown.subscribe(e => this.findDown(e));
  }

  @Input() set logsQueryData(logQueryData: LogQueryData) {
    const isNew = !this._logManager;
    if (logQueryData) {
      if (isNew) {
        // subscribe to the new log manager event
        this._logManager = new LogManager(logQueryData.logsService, this.htmlRowMeasure, this.store);
        this._logManager.setQueryResult(logQueryData);
        this._logManager.startFromIndex = logQueryData.startFromIndex;
        this.updateLogManager(this._logManager);
      } else {
        this._logManager.setQueryResult(logQueryData);
        this.highlightedLogs = logQueryData.highlightedLogs || this.highlightedLogs;
        this._logManager.startFromIndex = logQueryData.startFromIndex;
        this.setDataSource(this._logManager);
      }
    }
  }

  @Output() public gridRowSelectedEvent: EventEmitter<any> = new EventEmitter();
  @Output() public gridReadyEvent: EventEmitter<GridOptions> = new EventEmitter<GridOptions>();
  @Output() public beforeApiDestroyEvent: EventEmitter<GridOptions> = new EventEmitter<GridOptions>();
  @Output() public saveStateBeforeDestroy: EventEmitter<LogManager> = new EventEmitter<LogManager>();
  @Output() public logManagerUpdated: EventEmitter<LogManager> = new EventEmitter<LogManager>();
  @Output() public dataLoadedEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() public filterChangedEvent: EventEmitter<LogQueryModel> = new EventEmitter<any>();
  public _selectedRowIndex: number;
  public startLoadingDataSubscription: Subscription;
  public dataLoadedSubscription: Subscription;
  public currentPage: number;
  public totalPages: number;

  @ViewChild(GridPagingComponent) public pagingComponent: GridPagingComponent;

  @Input() public isOverlayVisible: boolean;
  @Input() public isGroupingSupported: boolean;
  @Input() public showGridFilters: boolean = false;

  @Output() public isOverlayVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public textInfoClicked: EventEmitter<string> = new EventEmitter<string>();

  public _highlightedLogs: Map<string, string>;

  public gridOptions: GridOptions;
  public paginationControll: any;
  public highlightedText: string = '';
  private _logManager: LogManager;
  private columnController: any;
  private lastFindPosition: number = 0;
  private findMatchedRowIds: any = [];
  private isGridReady: boolean = false;
  private OnFindSubscription: any;
  private OnFindUpSubscription: any;
  private OnFindDownSubscription: any;

  // shouldn't be necessary to inject ViewContainerRef here, but if we don't the child AgGridCellRendererFactory
  // doesn't get it injected either (and an error is thrown)
  constructor(private htmlRowMeasure: HtmlRowMeasure,
              private applicationDispatcher: ApplicationDispatcher,
              private store: Store<State>) {
    this.setDefaultGridOptions();
  }

  public setDefaultGridOptions(): void {
    this.gridOptions = ({
      rowHeight: 35,
      headerHeight: 40,
      enableServerSideSorting: true,
      enableServerSideFilter: true,
      enableColResize: true,
      rowModelType: 'pagination',
      paginationPageSize: Constants.QUERY_PAGE_SIZE,
      maxPagesInCache: 2,
      rowSelection: 'single',
      getRowHeight: this.getGridRowHeight.bind(this),
      context: this,
    } as GridOptions);
  }

  public initGridOptions(gridOptions: GridOptions): void {

    gridOptions.columnDefs = GridColumnDef.createColumnDefs(this.showGridFilters);

    GridRowStyle.setRowStyle(gridOptions);
    // Set the grid context to this
    gridOptions.context = this;
    // select row on cell selection
    gridOptions.onCellFocused = (params) => {
      if (params && params.forceBrowserFocus) {
        this.selectedRowIndex = params.rowIndex;
      }
    };

    gridOptions.onRowSelected = (params) => {
      if (params.node.selected) {
        this.gridRowSelectedEvent.emit(params.node.data);
      }
    };

    // get reference to columnController
    gridOptions.onGridReady = (params) => {
      this.columnController = params.columnApi._columnController;
      // TODO: NG4 do check avoid
      setTimeout(() => {
        this.paginationControll = params.api.paginationController;
      }, 0);

      this.isGridReady = true;
      this.setDataSource(this._logManager);
      this.jumoToStartIndex();
      this.selectRow(this._selectedRowIndex);
      this.gridReadyEvent.emit(this.gridOptions);
      /*if (this.gridWidth)
        this.fixColumnSize(this.gridWidth, false);*/
      // workaround to allow to get the grid scroll position before destroy
      // override the api destroy function to raise event before - then call the base
      this.gridOptions.api.destroy = () => {
        this.saveStateBeforeDestroy.emit(this._logManager);
        this.beforeApiDestroyEvent.emit(this.gridOptions);
        GridApi.prototype.destroy.call(this.gridOptions.api);
      };
    };

    // fix text column to expand if there is an empty space
    /*gridOptions.onGridSizeChanged = (params) => {
      if (this.isGridReady) {
        this.fixColumnSize(params.clientWidth, false);
      }
      this.gridWidth = params.clientWidth;
    }*/

    this.gridOptions = gridOptions;
  }

  public onHeaderClicked(header: any): void {
    this.applicationDispatcher.onColumnHeaderGrouped.next(header);
  }

  public setDataSource(logManager: LogManager): void {
    // set source
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setDatasource(logManager);
    }
    if (!logManager) {
      this.refreshGrid();
    }

    this.jumoToStartIndex();
  }

  public unSubscribe(): void {
    // if log manager exist un subscribe events
    if (this.startLoadingDataSubscription) {
      this.startLoadingDataSubscription.unsubscribe();
      this.startLoadingDataSubscription = null;
    }

    if (this.dataLoadedSubscription) {
      this.dataLoadedSubscription.unsubscribe();
      this.dataLoadedSubscription = null;
    }

    if (this.OnFindSubscription) {
      this.OnFindSubscription.unsubscribe();
    }
    if (this.OnFindUpSubscription) {
      this.OnFindUpSubscription.unsubscribe();
    }
    if (this.OnFindDownSubscription) {
      this.OnFindDownSubscription.unsubscribe();
    }
  }

  public showLoadingOverlay(show: boolean): void {
    this.isOverlayVisible = show;
    this.isOverlayVisibleChange.emit(show);
  }

  public ngOnInit(): void {
    this.initGridOptions(this.gridOptions);
  }

  /*private fixColumnSize(width: number, forceFix: boolean) {
    if (this.sizeFixed && !forceFix)
      return;

    let columns = this.columnController.gridColumns;
    let totalColumnsWitdh = 0;
    let gridWidth = width;

    //sum all columns width
    for (let i = 0; i < columns.length; i++) {
      totalColumnsWitdh = totalColumnsWitdh + columns[i].actualWidth;
    }

    //if gridWidth > totalColumnsWidth we have space to expand 'Text' col
    if (gridWidth > totalColumnsWitdh) {
      let textColumn = columns.find(col => col.colDef.field === 'text');

      if (textColumn) {
        let newSize = textColumn.actualWidth +
          (gridWidth - totalColumnsWitdh);
        // add the diff width to text column
        this.columnController.setColumnWidth(textColumn, newSize);
        this.htmlRowMeasure.textColumnWidth = newSize;
      }
    }
    this.sizeFixed = true;
  }*/

  public OnFindKeyUp(e: string): void {
    this.buildFindResult(e);

    // This will call cellRenderer
    if (this.gridOptions.api) {
      this.refreshGrid();
    }
  }

  public findDown(e: any): void {
    if (this.findMatchedRowIds.length === 0) {
      return;
    }
    if (this.lastFindPosition <= this.findMatchedRowIds.length) {
      this.selectedRowIndex = this.findMatchedRowIds[this.lastFindPosition];
      if (this.gridOptions.api) {
        this.gridOptions.api.ensureIndexVisible(this.findMatchedRowIds[this.lastFindPosition]);
      }
      this.lastFindPosition++;
    }
  }

  public findUp(e: any): void {
    if (this.findMatchedRowIds.length === 0) {
      return;
    }

    if (this.lastFindPosition >= 0) {
      if (this.lastFindPosition > 0) {
        this.lastFindPosition--;
      }

      this.selectedRowIndex = this.findMatchedRowIds[this.lastFindPosition];
      if (this.gridOptions.api) {
        this.gridOptions.api.ensureIndexVisible(this.findMatchedRowIds[this.lastFindPosition]);
      }
    }
  }

  public ngOnDestroy(): void {
    this.unSubscribe();
  }

  public onShowMore(params: { data }): void {
    this.htmlRowMeasure.calcAll = true;
    this.htmlRowMeasure.prepareRowsHeights([params.data]).first().subscribe(() => {
      this.gridOptions.api.resetRowHeights();
      this.refreshGrid();
    });
  }

  private getGridRowHeight(params: { data }): number {

    if (this.gridOptions.columnDefs.findIndex((colDef: ColDef) => colDef.field === 'text') === -1) {
      return 60;
    }

    let h = this.htmlRowMeasure.rowsHeight.get(params.data.id.toString());
    if (!h) {
      h = 56;
    }

    return h + 24;
  }

  private jumoToStartIndex(): void {
    if (!this._logManager) {
      return;
    }
    const justToIndexInPage = this._logManager.startFromIndex -
      ((this._logManager.logQueryModel.pageIndex) * this._logManager.logQueryModel.pageSize);
    if (this.paginationControll && this._logManager.logQueryModel.pageIndex) {
      if (this.pagingComponent) {
        this.pagingComponent.pageClicked(this._logManager.logQueryModel.pageIndex);
      }
    }

    const startIndex = this._logManager.startFromIndex;

    if (startIndex) {
      const tmpSubscription = this._logManager.dataLoaded.first().subscribe(() => {
        tmpSubscription.unsubscribe();
        setTimeout(() => {
          if (!this.gridOptions.api) {
            return;
          }

          this.gridOptions.api.ensureIndexVisible(justToIndexInPage);
          const rowElement = GridCellHelper.getElementsByAttribute(document, 'ag-row', 'row', (justToIndexInPage - 1).toString());
          const gridElement = document.getElementsByClassName('ag-body-viewport')[0];
          if (rowElement[2] && rowElement && gridElement) {
            gridElement.scrollTop = rowElement[2].offsetTop - 200;
          }

        }, 500);
      });
    }
  }

  private selectRow(index: number): void {
    if (!_.isUndefined(index) && this.isGridReady && this.gridOptions.api) {
      const row = this.gridOptions.api.getModel().getRow(index);
      if (row) {
        row.setSelected(true, true);
      }
    }
  }

  private updateLogManager(logManager: LogManager): void {
    this.setDataSource(logManager);
    this.logManagerUpdated.emit(logManager);
    logManager.onFilterChanged.subscribe((queryModel) => this.filterChangedEvent.emit(queryModel));

    this.startLoadingDataSubscription = logManager.startLoadingData.subscribe(() => {
      this.showLoadingOverlay(true);
    });

    this.dataLoadedSubscription = logManager.dataLoaded.subscribe(() => {
      this.currentPage = this.paginationControll.currentPage;
      this.totalPages = this.paginationControll.totalPages > PAGES_LiMIT ? PAGES_LiMIT : this.paginationControll.totalPages;
      if (this.gridOptions.api) {
        this.gridOptions.api.ensureIndexVisible(0);
      }
      setTimeout(() => {
        this.dataLoadedEvent.emit(logManager.totalQueryLogCount);
        this.buildFindResult(this.highlightedText);
        this.showLoadingOverlay(false);
      }, 600);
    });
  }

  private buildFindResult(value: string): void {
    this.findMatchedRowIds = [];
    this.lastFindPosition = 0;
    this.highlightedText = value;
    if (!value) {
      return;
    }

    if (this._logManager) {
      const indexsToSubtract = this.paginationControll.currentPage * this.paginationControll.pageSize;
      const logs = this._logManager.logPages.get(this.paginationControll.currentPage);
      if (logs) {
        this.findMatchedRowIds = [];
        logs.forEach(log => {
          const logText = log.text.toLowerCase();
          if (logText.includes(this.highlightedText)) {
            this.findMatchedRowIds.push(log.index - indexsToSubtract - 1);
          }
        });
      }
    }
  }

  private refreshGrid(): void {
    if (this.gridOptions && this.gridOptions.api) {
      setTimeout(() => {
        if (this.gridOptions.api) {
          this.gridOptions.api.refreshView();
        }
      }, 0);
    }
  }
}
