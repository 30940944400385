import { ArchiveQueryActions } from '@app/roi/archive-queries/state/actions/archive-query.actions';
import { EReindexState, IArchiveQuery, IReindex, IUiState, MAXIMUM_ITEMS } from '@app/roi/archive-queries/models/archive-query.model';

export namespace ArchiveQueryReducer {
  export interface State {
    loading: boolean;
    secondLoader: boolean;
    archiveQueries: IArchiveQuery[];
    archiveQueriesUIState: IUiState;
    pageIndex: number;
    pageSize: number;
    filter: string;
    totalQueries: number;
    permissionsError: boolean;
  }

  const initialState: State = {
    loading: false,
    secondLoader: false,
    archiveQueries: [],
    archiveQueriesUIState: {},
    pageIndex: 1,
    pageSize: MAXIMUM_ITEMS,
    filter: '',
    totalQueries: 0,
    permissionsError: false
  };

  export function reducer(
    state: ArchiveQueryReducer.State = initialState,
    action: ArchiveQueryActions.Actions,
  ): ArchiveQueryReducer.State {
    switch (action.type) {
      case ArchiveQueryActions.ActionTypes.ACTIVATE_LOADING:
        return { ...state, secondLoader: true };
      case ArchiveQueryActions.ActionTypes.CLEAR_LOADING:
        return { ...state, secondLoader: false };
      case ArchiveQueryActions.ActionTypes.GET_ARCHIVE_QUERIES:
        return {
          ...state,
          loading: action.payload?.loading,
          pageIndex: action.payload?.pageIndex,
          pageSize: action.payload?.pageSize,
          filter: action.payload?.filter
        };
      case ArchiveQueryActions.ActionTypes.GET_ARCHIVE_QUERIES_COMPLETED:
        const queries = action.payload?.archiveQueries || [];
        const uiState: IUiState = queries.reduce((acc, val) => {
          const foundQuery = state.archiveQueries.find((query) => query.id === val.id);
          if (foundQuery) {
            acc[val.id] = { showMore: state.archiveQueriesUIState[foundQuery.id]?.showMore };
          } else {
            acc[val.id] = { showMore: false };
          }
          return acc;
        }, {});
        return { ...state,
            loading: false,
            archiveQueries: queries,
            totalQueries: action.payload?.queriesCount,
            archiveQueriesUIState: uiState
        };
      case ArchiveQueryActions.ActionTypes.GET_ARCHIVE_QUERIES_FAILED:
        return { ...state, ...{ loading: false } };
      case ArchiveQueryActions.ActionTypes.SET_ARCHIVE_QUERIES_UI_STATE:
        const payload: {queryId: string, showMore: boolean} = action.payload;
        const updateUiState: IUiState = {...state.archiveQueriesUIState};
        updateUiState[payload.queryId] = {showMore: payload.showMore};
        return {...state, archiveQueriesUIState: updateUiState};
      case ArchiveQueryActions.ActionTypes.CREATE_QUERY_COMPLETED:
        const queriesUiState: IUiState = {...state.archiveQueriesUIState};
        queriesUiState[action.payload.id] = {showMore: false};
        const allQueries = [action.payload, ...state.archiveQueries];
        return {...state, archiveQueries:allQueries, archiveQueriesUIState: queriesUiState};
      case ArchiveQueryActions.ActionTypes.SET_PAGINATION:
        return { ...state, pageIndex: action.payload?.pageIndex, pageSize: action.payload?.pageSize };
      case ArchiveQueryActions.ActionTypes.SET_SEARCH_TERM:
        return { ...state, filter: action.payload.term, pageIndex: 1 };
      case ArchiveQueryActions.ActionTypes.CREATE_REINDEX_COMPLETED:
        const reindex: IReindex = action.payload;
        const newQueries: IArchiveQuery[] = state.archiveQueries.map(query => {
          if (query.id.replace(/-/g, "_") === reindex.archiveQueryId) {
            query.reindexes = [{
                id: reindex.id,
                createDate: reindex.createDate,
                progressPercent: reindex.progressPercent,
                status: reindex.status as EReindexState
              }]
            return query;
          }
          return query
        })
        return {...state, archiveQueries: newQueries}
      case ArchiveQueryActions.ActionTypes.GET_BUCKET_PERMISSIONS:
        return { ...state, loading: true };
      case ArchiveQueryActions.ActionTypes.GET_BUCKET_PERMISSIONS_COMPLETED:
        return { ...state, loading: false, permissionsError: false  };
      case ArchiveQueryActions.ActionTypes.GET_BUCKET_PERMISSIONS_FAILED:
        return { ...state, loading: false, permissionsError: true };

      default:
        return state;
    }
  }
}

