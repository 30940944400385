<div [formGroup]="formGroup">
  <div formArrayName="groupByFields" class="group-by-fields">
    <ng-container *ngFor="let field of groupByFields.controls; let i = index">
      <sh-coralogix-select [selectOptions]="options" [searchable]="true" [clearable]="true" [formControl]="field" *ngIf="i == 0">
        <label class="label-with-tooltip" select-label>
          <span class="text">Group By</span>
          <div>
            <i
              class="fa fa-question-circle-o"
              position="right"
              [horizontal]="8"
              horizontalDirection="left"
              cgxTooltipClass="cgx-alerts-tooltip"
              cgxTooltip="An alert will trigger whenever any of the aggregated values appear more than the threshold within the specified timeframe"
            ></i>
          </div>
        </label>
      </sh-coralogix-select>

      <div *ngIf="i > 0" class="nested-group-by">
        <div class="nested-group-by-line"></div>
        <sh-coralogix-select [selectOptions]="options" [searchable]="true" [clearable]="true" [formControl]="field" class="group-by-select">
          <label class="label" select-label>
            <span class="text">Level {{ i + 1 }} Aggregation Group By</span>
          </label>
        </sh-coralogix-select>
        <cgx-button outline label="Remove" matIconStart="do_disturb_on" (press)="removeGroupBy(i)" class="remove-button"></cgx-button>
      </div>
    </ng-container>
  </div>
  <cgx-button
    outline
    label="ADD ANOTHER GROUP BY"
    matIconStart="add"
    *ngIf="showAddButton"
    (press)="addGroupBy()"
    class="add-button"
  ></cgx-button>
  <div *ngIf="hasError('groupByFields', 'required')" class="error">
    Group By is required
  </div>
  <div *ngIf="formGroup.errors?.primaryGroupByRequired" class="error">
    Outer aggregation must be defined
  </div>
  <div *ngIf="groupByControl?.errors?.groupByInvalidCount" class="error">Too many group by fields (limit is {{ groupByLengthLimit }})</div>
  <div class="notify-group-by-only-alerts">
    <cgx-checkbox formControlName="notifyGroupByOnlyAlerts" label="Notifications should only contain triggered labels"></cgx-checkbox>
  </div>
</div>
