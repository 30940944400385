import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  VerifyAlertData,
  VerifyAlertResponse,
} from '@app/alerts/alerts-editor/components/alerts-verify-alert/models/verify-alert-response';
import { VerifyAlertChartComponent } from '@app/alerts/alerts-editor/components/alerts-verify-alert/verify-alert-chart/verify-alert-chart.component';
import { AlertEditControls } from '@app/alerts/alerts-editor/models/alert-edit-controls.model';
import { ConditionsOptions } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { AlertMapperService } from '@app/alerts/alerts-editor/services/alert-mapper.service';
import { AlertsEditorFormService } from '@app/alerts/alerts-editor/services/alerts-editor-form.service';
import { IPartialError } from '@app/alerts/shared/models/actions.models';
import { CreateNUpdateAlertModel } from '@app/alerts/shared/models/create-n-update-alert-model';
import { AlertsService } from '@app/alerts/shared/services/alerts-service';
import { AlertsServiceHelper } from '@app/alerts/shared/services/alerts-service-helper';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { TimeZoneType } from '@app/shared/models/timezone-types';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sh-verify-alert',
  templateUrl: 'verify-alert.component.html',
  styleUrls: ['verify-alert.component.scss'],
  providers: [AlertsEditorFormService],
})
export class VerifyAlertComponent implements OnInit, OnDestroy {
  private get formValue(): AlertEditControls {
    return this.alertsEditorFormService.mapFormValueToEditControls(this.form.value);
  }

  @Input()
  public form: FormGroup;
  @ViewChild('chart')
  public chart: VerifyAlertChartComponent;
  public expanded: boolean;
  public isLoading: boolean;
  public isLocalTimezone: boolean = true;
  public verifyAlertData: VerifyAlertData[];
  public totalAlerts: number;
  public timeRange: number;
  public numberOfLogsToAlert: number;
  public errorMessage: string;
  public alertSeverity: number;
  public conditionOperator: number;
  public schedule: Partial<AlertEditControls>;
  public readonly verifyTimeRange: number = 24;
  private verifyAlertReq: CreateNUpdateAlertModel;
  private subs: Subscription[] = [];

  constructor(
    private alertsEditorFormService: AlertsEditorFormService,
    private alertMapperService: AlertMapperService,
    private alertsService: AlertsService,
    private store: Store<State>,
    private serviceHelper: AlertsServiceHelper,
    private cdr: ChangeDetectorRef,
  ) {
    this.store
      .select(getTimezoneViewPreference)
      .take(1)
      .subscribe((tmz) => {
        this.isLocalTimezone = tmz === TimeZoneType.local;
      });
  }

  public ngOnInit(): void {
    this.subscribeOnCOnditionsChanges();
    this.timeRange = this.formValue.conditionTimeframe;
    this.numberOfLogsToAlert = this.formValue.conditionThreshold;
    this.conditionOperator = this.formValue.conditionOperator;
    this.alertSeverity = this.formValue.alertSeverity;
    this.schedule = this.form.get('schedule').value;
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public getChartData(): void {
    this.isLoading = true;
    this.errorMessage = null;
    this.verifyAlertReq = this.alertMapperService.mapFormToRequest(this.formValue, false, null, this.isLocalTimezone);
    this.verifyAlertReq.verifyTimeRange = this.verifyTimeRange;
    this.parseQueryStringForElasticsearch();
    this.updateConditions();
    const sub = this.alertsService
      .verifyAlert(this.verifyAlertReq)
      .finally(() => {
        this.isLoading = false;
        this.cdr.detectChanges();
      })
      .subscribe(
        (res: VerifyAlertResponse) => {
          if (this.conditionOperator === 0) {
            this.totalAlerts = res.total.value;
          } else {
            this.getTotalAlerts(res.alerts);
          }
          this.verifyAlertData = res.alerts;
        },
        (err: IPartialError) => {
          this.store.dispatch(this.serviceHelper.handleAlertInvalidQueryAction(err, false));
          this.errorMessage = 'Failed to verify alert. Please check your Query and Condition inputs.';
        },
      );
    this.subs.push(sub);
  }

  public onExpand(): void {
    this.expanded = true;
    this.getChartData();
  }

  private getTotalAlerts(data: VerifyAlertData[]): void {
    this.totalAlerts = data
      .filter((item) => {
        if (this.conditionOperator === ConditionsOptions.more) {
          return item.doc_count > this.numberOfLogsToAlert;
        }
        if (this.conditionOperator === ConditionsOptions.less) {
          return item.doc_count < this.numberOfLogsToAlert;
        }
        return item.doc_count;
      })
      .map((item) => item.doc_count).length;
  }

  private subscribeOnCOnditionsChanges(): void {
    const conditionsSub = this.form.get('conditions').valueChanges.subscribe((val) => {
      this.numberOfLogsToAlert = val.conditionThreshold;
      this.conditionOperator = val.conditionOperator;
      if (this.verifyAlertReq && this.timeRange !== val.conditionTimeframe) {
        this.timeRange = val.conditionTimeframe;
        this.getChartData();
      }
      this.timeRange = val.conditionTimeframe;
    });
    const scheduleSub = this.form.get('schedule').valueChanges.subscribe((val) => {
      this.schedule = val;
    });
    const alertSeveritySub = this.form
      .get('details')
      .get('alertSeverity')
      .valueChanges.subscribe((val) => {
        this.alertSeverity = val;
        if (this.chart) {
          this.chart.drawChart();
        }
      });
    this.subs.push(conditionsSub, scheduleSub, alertSeveritySub);
  }

  private updateConditions(): void {
    this.verifyAlertReq.conditionTimeframe =
      this.conditionOperator === ConditionsOptions.notifyImmediately ? 5 : this.timeRange;
  }

  private parseQueryStringForElasticsearch(): void {
    if (!this.verifyAlertReq.text) {
      return;
    }
    let query = this.verifyAlertReq.text;
    query = query.trim();
    if (query[0] === '/' && query[query.length - 1] === '/') {
      query = query.slice(1, -1);
    }
    this.verifyAlertReq.text = query;
  }

  public get isMoreThenDynamicAlert(): boolean {
    return this.conditionOperator === ConditionsOptions.moreThanUsual;
  }
}
