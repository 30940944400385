import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSizePipe } from './data-size.pipe';

@NgModule({
  declarations: [DataSizePipe],
  imports: [
    CommonModule
  ],
  exports: [DataSizePipe]
})
export class DataSizeModule { }
