import { MetricEditorActions } from '../actions/metric-editor.actions';
import {Metric} from "@app/settings/metrices-settings/models/metric.model";

export namespace MetricEditorReducer {

  export interface State {
    isOpen: boolean;
    editMode: boolean;
    editedMetric: Metric;
  }

  const initialState: State = {
    isOpen: false,
    editMode: false,
    editedMetric: null
  };

  export function reducer(state: MetricEditorReducer.State = initialState, action: MetricEditorActions.Actions): MetricEditorReducer.State {
    switch (action.type) {
      case MetricEditorActions.ActionTypes.TOGGLE_EDITOR:
        if (action.payload === true) {
          return { ...state, ...{ isOpen: action.payload } };
        }
        return { ...state, ...{ isOpen: action.payload }, editMode: false, editedMetric: null };
      case MetricEditorActions.ActionTypes.EDIT_METRIC:
        return {...state, ...{isOpen: true, editMode:true, editedMetric: action.payload}};
      default:
        return state;
    }
  }
}
