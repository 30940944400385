import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

@jsonObject
export class Grok {
  @jsonMember
  public Identifier: string;
  @jsonMember
  public Definition: string;
  @jsonMember
  public Description: string;
}

@jsonObject
export class GrokMatch {
  @jsonMember
  public grok: Grok;
  @jsonArrayMember(String)
  public branches: string[] = [];
}

@jsonObject
export class GroksResponse {
  @jsonArrayMember(GrokMatch)
  public groksMatches: GrokMatch[];
}

@jsonObject
export class GrokDefsResponse {
  @jsonArrayMember(Grok)
  public groks: Grok[];
}
