import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { ShDialogService } from '@shared/services/dialogService';
import { ChangePasswordFormProvider } from '../../settings/account/containers/change-password/change-password-form-provider';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { TeamActions } from '@app/user/state/teams/teams.actions';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';

@Component({
  selector: 'sh-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  errorString: string;

  loading$ = new BehaviorSubject(false);

  resetKey: string;

  passwordError: string[] = [];

  hide = true;

  confirmHide = true;

  private destroyed$ = new Subject();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialogService: ShDialogService,
    private changePasswordFormProvider: ChangePasswordFormProvider,
    private store: Store<State>,
  ) {}

  get resetPasswordForm(): FormGroup {
    return this.changePasswordFormProvider.changePasswordForm;
  }

  ngOnInit(): void {
    this.changePasswordFormProvider.generateNewForm();
    this.resetKey = this.route.snapshot.params.key;

    this.resetPasswordForm
      .get('password')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(password => {
        this.passwordError = this.changePasswordFormProvider.errorMsgs(password);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  submit(): void {
    if (this.resetKey) {
      this.errorString = null;
      this.loading$.next(true);
      this.authService
        .resetPassword(
          {
            password: this.resetPasswordForm.value.password,
          },
          this.resetKey,
        )
        .pipe(
          tap(() => this.store.dispatch(new TeamActions.LoginToTeam(null))),
          catchError(err => {
            this.onError(err);
            return of(null);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    } else {
      this.onError();
    }
  }

  private onError(err?: { status }): void {
    let errMessage = 'Failed to reset password';
    if (err?.status) {
      errMessage = err.status === 404 ? 'Reset password token expired' : 'Failed to reset password';
    }
    this.dialogService.showSnackBar(errMessage, null, 3000);
    this.loading$.next(false);
  }
}
