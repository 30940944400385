import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import io from 'socket.io-client';
import * as _ from 'lodash';
import { AuthService } from '../../../auth/shared/services/auth.service';
import { LivetailEntity } from '../models/livetailEntity';
import { Constants } from '../../../constants';
import { formatsHelper } from '../../../shared/services/formatsHelper';
import { Subject } from 'rxjs';
import { ShDialogService } from '@shared/services/dialogService';

const maxLogTextLen = 30000;
const KEY_DOES_NOT_EXIST = 'key_does_not_exist';
const DOT = '.';

@Injectable()
export class LivetailService {
  get isSocketConnected(): boolean {
    return this.socket && !this.socket.disconnected && this.socket.connected && window.navigator.onLine;
  }

  get IsRegistered(): boolean {
    return this.isRegistered;
  }
  public isRegistered: boolean = false;
  public liveTailDisconnected$ = new Subject<void>();

  public onDisconnected: EventEmitter<any> = new EventEmitter();
  private socket: SocketIOClient.Socket = io(Constants.livaTailUrl, {
    query: 'token=' + this.authService.getToken() + '&t_id=' + this.authService.getSelectedTeamId(),
  });

  constructor(private authService: AuthService, private dialogService: ShDialogService) {}

  public subscribeLivetail(metaData: any): void {
    if (this.socket && this.socket.disconnected) {
      this.socket.connect();
    }
    this.socket.emit('livetail:subscribe', metaData);
    this.isRegistered = true;
  }

  public unsubscribeLivetail(): void {
    this.isRegistered = false;
    this.socket.emit('livetail:unsubscribe');
  }

  public cleanLivetail(): void {
    if (this.socket) {
      this.socket.removeAllListeners();
    }
  }

  public startLivetail(): Observable<Array<LivetailEntity>> {
    const observable: Observable<any> = new Observable((observer) => {
      this.socket.on('livetail:results', (data) => {
        observer.next(this.extractData(data));
      });
      return () => {
        this.onDisconnected.emit();
        this.unsubscribeLivetail();
      };
    });

    this.socket.on('reconnect', () => {
      if (this.socket && this.socket.disconnected) {
        this.socket.connect();
      }
    });

    this.socket.on('invalidParametersErr', () => {
      this.dialogService.showErrorMessage('Error, invalid filter parameters for applications and subsystems');
      this.liveTailDisconnected$.next();
    });

    return observable;
  }

  public getTextObjectAsJson(livetailEntity: LivetailEntity, keysToFilter: string[]): string {
    if (!keysToFilter?.length) {
      return livetailEntity.text;
    }
    if (!livetailEntity.textObject) {
      return '';
    }
    let filteredData = {};
    keysToFilter.forEach((key: string) => {
      const trimmedKey = key.trim();
      const textObject: { [key: string]: any } = livetailEntity.textObject;
      if (textObject.hasOwnProperty(trimmedKey)) {
        filteredData[key] = textObject[trimmedKey];
      } else if (this.isValidNestedKeyPattern(trimmedKey) && !filteredData.hasOwnProperty(trimmedKey)) {
        const keys = trimmedKey.split(DOT);
        const nestedData = _.get(textObject, keys, KEY_DOES_NOT_EXIST);
        if (nestedData !== KEY_DOES_NOT_EXIST) {
          filteredData = {
            ...filteredData,
            [trimmedKey]: nestedData,
          };
        }
      }
    });
    if (keysToFilter.length === 1) {
      return typeof filteredData[keysToFilter[0]] === 'string'
        ? filteredData[keysToFilter[0]]
        : JSON.stringify(filteredData[keysToFilter[0]]);
    }
    return Object.keys(filteredData).length ? JSON.stringify(filteredData) : '';
  }

  private isValidNestedKeyPattern(key: string): boolean {
    const startOrEndWithDotRegex = new RegExp('(^\\.|\\.$)');
    const multipleDotsRegex = /(\.)\1+/g;
    return !startOrEndWithDotRegex.test(key) && !multipleDotsRegex.test(key);
  }

  private extractData(res: any): Array<LivetailEntity> {
    const mappedResponse = [];
    res.results.forEach((o) => {
      try {
        if (typeof o.text === 'object') {
          o.jsonUuid = 'true';
          o.textObject = o.text;
          o.text = JSON.stringify(o.text);
        } else {
          o.textObject = JSON.parse(o.text);
          o.jsonUuid = 'true';
        }
      } catch (e) {}

      o.threadId = o.threadId ? o.threadId.toString() : '';
      o.text = formatsHelper.stringSub(o.text.toString(), maxLogTextLen);
      mappedResponse.push(new LivetailEntity(o));
    });

    return mappedResponse;
  }
}
