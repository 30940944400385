<div>
  <form [formGroup]="form" (submit)="submit(form.value)">
    <section class="head-section">
      <p class="primary-header coralogix">New Reindex</p>
      <button
        [disabled]="isFormInvalid"
        [class.disabled]="isFormInvalid"
        class="button coralogix secondary-add-button"
        type="submit"
      >
        REINDEX ARCHIVED DATA
      </button>
    </section>

    <section class="content-wrapper">
      <div class="content">
        <sh-reindex-editor-details [form]="detailsGroup"></sh-reindex-editor-details>
        <sh-reindex-editor-filters
          [form]="filterGroup"
          [logsSeverityOptions]="logsSeverityOptions"
        ></sh-reindex-editor-filters>
      </div>
    </section>
  </form>
</div>
