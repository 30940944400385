<div class="ui equal width stackable grid" [formGroup]="metadataForm">
  <div class="row metadata-group">
    <div
      class="column transition-width"
      [class.expendOnShow]="applicationDD.isOpen"
    >
      <sh-select
        [options]="options.applicationMetadata$ | async"
        [isMultiselect]="true"
        (onHide)="onHide($event, 'applicationName')"
        (onShow)="onShow()"
        (onClear)="onClear('applicationName')"
        formControlName="applicationName"
        #applicationDD
        placeholder="Application"
      ></sh-select>
    </div>
    <div
      class="column transition-width"
      [class.expendOnShow]="subsystemDD.isOpen"
    >
      <sh-select
        [options]="options.subsystemMetadata$ | async"
        [isMultiselect]="true"
        (onHide)="onHide($event, 'subsystemName')"
        (onShow)="onShow()"
        (onClear)="onClear('subsystemName')"
        formControlName="subsystemName"
        #subsystemDD
        placeholder="Subsystem"
      ></sh-select>
    </div>
    <div
      class="column transition-width"
      [class.expendOnShow]="categoryDD.isOpen"
    >
      <sh-select
        [options]="options.categoryMetadata$ | async"
        [isMultiselect]="true"
        (onHide)="onHide($event, 'category')"
        (onShow)="onShow()"
        (onClear)="onClear('category')"
        formControlName="category"
        #categoryDD
        placeholder="Category"
      ></sh-select>
    </div>
    <div class="column transition-width" [class.expendOnShow]="classDD.isOpen">
      <sh-select
        [options]="options.classMetadata$ | async"
        [isMultiselect]="true"
        (onHide)="onHide($event, 'className')"
        (onShow)="onShow()"
        (onClear)="onClear('className')"
        formControlName="className"
        #classDD
        placeholder="Class"
      ></sh-select>
    </div>
    <div class="column transition-width" [class.expendOnShow]="methodDD.isOpen">
      <sh-select
        [options]="options.methodMetadata$ | async"
        [isMultiselect]="true"
        (onHide)="onHide($event, 'methodName')"
        (onShow)="onShow()"
        (onClear)="onClear('methodName')"
        formControlName="methodName"
        #methodDD
        placeholder="Method"
      ></sh-select>
    </div>
  </div>
</div>
