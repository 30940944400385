import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetLoaderComponent } from './widget-loader.component';

@NgModule({
  declarations: [WidgetLoaderComponent],
  imports: [
    CommonModule
  ],
  exports: [WidgetLoaderComponent],
  entryComponents: [WidgetLoaderComponent]
})
export class WidgetLoaderModule { }
