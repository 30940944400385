import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ChangeQuotaPeriod } from './quota.action';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { GetOverrides } from '../quota-policy-overrides/quota-policy-overrides.action';

export interface IQuotaPeriodOption {
    key: string;
    name: string;
    value: number;
}

export interface IQuotaPeriodOptionDictionary {
    [key: string]: IQuotaPeriodOption;
}

interface IQuotaModel {
    quotaPeriodOptions: IQuotaPeriodOptionDictionary;
    selectedQuotaPeriodKey: string;
    usageDays: number;
}

const initialState = (): IQuotaModel => {
    return {
        quotaPeriodOptions: {
            last24Hours: {
                key: 'last24Hours',
                name: 'Last 24 Hours',
                value: 1
            },
            last7Days: {
                key: 'last7Days',
                name: 'Last 7 Days',
                value: 7
            },
        },
        selectedQuotaPeriodKey: 'last24Hours',
        usageDays: 1,
    };
};

@State<IQuotaModel>({
    name: 'quota',
    defaults: initialState()
})
@Injectable()
export class QuotaState {
    @SelectSnapshot(QuotaState.selectedQuotaPeriodValue) private selectedQuotaPeriodValue: number;

    constructor(
        public store: Store
    ) {
    }

    @Selector()
    public static quotaPeriodOptions(state: IQuotaModel): IQuotaPeriodOptionDictionary {
        return state?.quotaPeriodOptions;
    }

    @Selector()
    public static quotaPeriodOptionList(state: IQuotaModel): IQuotaPeriodOption[] {
        return Object.values(state?.quotaPeriodOptions);
    }

    @Selector()
    public static selectedQuotaPeriodKey(state: IQuotaModel): string {
        return state?.selectedQuotaPeriodKey;
    }

    @Selector()
    public static usageDays(state: IQuotaModel): number {
        return state?.usageDays;
    }

    @Selector([QuotaState.selectedQuotaPeriodKey, QuotaState.quotaPeriodOptions])
    public static selectedQuotaPeriodValue(selectedQuotaPeriodKey: string, quotaPeriodOptions: IQuotaPeriodOptionDictionary): number {
        return quotaPeriodOptions[selectedQuotaPeriodKey].value;
    }

    @Action(ChangeQuotaPeriod)
    public ChangeQuotaPeriod(ctx: StateContext<IQuotaModel>, { key }: ChangeQuotaPeriod): void {
        ctx.patchState({ selectedQuotaPeriodKey: key });
        ctx.dispatch(new GetOverrides());
    }
}
