import { Pipe, PipeTransform } from '@angular/core';
import { LogsView } from '../models/logs-view.model';

@Pipe({ name: 'logsSavedViewsFilter' })
export class LogsSavedViewsFilterPipe implements PipeTransform {
  public transform(savedViews: LogsView[], filterObj?: any): any {
    const showMyViews = filterObj.type === 'myViews';
    const filtered = savedViews.filter((view) =>
      view.name.toLowerCase().includes(filterObj.filter.toLowerCase()),
    );

    if (showMyViews) {
      return filtered.filter((view) => view.isMine);
    }

    return filtered;
  }
}
