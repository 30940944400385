import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClipboardService } from '../../services/clipboard-service';
import * as _ from 'lodash';
import { JsonKeyAction } from '../../models/filter-by-json-value-action.model';

@Component({
  selector: 'sh-json-property',
  templateUrl: 'json-property.component.html',
  styleUrls: ['json-property.component.scss'],
})
export class JsonPropertyComponent {
  @Input() set parentKey(value: string) {
    this._parentKey = value;
    this.isChild = true;
  }

  get parentKey(): string {
    return this._parentKey;
  }

  @Input() set jsonObject(value: Object) {
    this.isArray = Array.isArray(value);
    this._jsonObject = value;
  }

  get jsonObject(): Object {
    return this._jsonObject;
  }
  public isArray;
  @Input() public hideJsonParams: boolean;
  @Input() public isShowWidgetEnabled: boolean = true;
  @Input() public isFiltersEnabled: boolean = false;

  @Output() public filterByJsonValue: EventEmitter<JsonKeyAction> = new EventEmitter<JsonKeyAction>();
  @Output() public onShowWidget: EventEmitter<string> = new EventEmitter<string>();
  private _jsonObject: object;
  private _parentKey;
  private isChild;

  constructor(private clipboardService: ClipboardService) {}

  public isObjectArray(value: any): boolean {
    return Array.isArray(value);
  }

  public getArrayValue(value: any): any[] {
    if (this.isObjectArray(value)) {
      return value as any[];
    }
    return undefined;
  }

  public isJson(value: any): boolean {
    return (
      typeof value !== 'string' &&
      typeof value !== 'number' &&
      value !== null &&
      typeof value !== 'boolean'
    );
  }

  public isNumber(value: any): boolean {
    return typeof value == 'number';
  }

  public isString(value: any): boolean {
    return typeof value == 'string';
  }

  public getJsonValueString(value): string {
    if (this.hideJsonParams) {
      return 'VALUE';
    }
    if (this.isString(value)) {
      return '"' + value + '"';
    }
    if (_.isNil(value)) {
      return 'null';
    }
    return value;
  }

  public showWidgetClicked(key: string): void {
    this.onShowWidget.emit(key);
  }

  public filterByValue(key: string, value: string): void {
    this.filterByJsonValue.emit(new JsonKeyAction(key, value));
  }

  public propagateShowWidgetClick(key: string, keyPath: any): void {
    this.propagateKey(key, keyPath, this.onShowWidget);
  }

  public propagateJsonKeyClicked(jsonKeyAction: JsonKeyAction, key: string): void {
    this.propagateJsonEvent(jsonKeyAction, key, this.filterByJsonValue);
  }

  public propagateJsonEvent(
    jsonKeyAction: JsonKeyAction,
    key: string,
    event: EventEmitter<JsonKeyAction>,
  ) {
    if (!key) {
      event.emit(jsonKeyAction);
    } else {
      event.emit(
        new JsonKeyAction(`${key}.${jsonKeyAction.key}`, jsonKeyAction.value),
      );
    }
  }

  public propagateKey(key: string, keyPath: any, event: EventEmitter<string>) {
    if (!key) {
      event.emit(keyPath);
    } else {
      if (keyPath || keyPath == 0) {
        event.emit(key + '.' + keyPath);
      } else {
        event.emit(key);
      }
    }
  }

  public copyValueToClipboard(value: any, key: string) {
    let valueToCopy = typeof value !== 'object' ? value : JSON.stringify(value);
    if (key) {
      if (value[key]) {
        valueToCopy = '"' + key + '":' + JSON.stringify(value[key]);
      } else {
        valueToCopy = '"' + key + '":' + valueToCopy;
      }
    }
    this.clipboardService.copyToClipboard(valueToCopy);
  }
}
