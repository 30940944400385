<form class="reset-password-form" fxFlex="100" fxLayout="column" [formGroup]="resetPasswordForm" (submit)="submit()">
  <mat-form-field class="multiline-hint-abs-under">
    <mat-placeholder>New Password</mat-placeholder>
    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" />
    <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon class="login-password-visibility-icon">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-hint *ngIf="resetPasswordForm.controls['password'].dirty && !resetPasswordForm.controls['password'].valid" align="start">
      <span *ngIf="passwordError.length === 1">{{ passwordError[0] }}</span>
      <ul *ngIf="passwordError.length > 1">
        <li *ngFor="let errMsg of passwordError">
          {{ errMsg }}
        </li>
      </ul>
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="reset-password-form__confirm-field">
    <mat-placeholder>Confirm Password</mat-placeholder>
    <input matInput formControlName="repeatPassword" [type]="confirmHide ? 'password' : 'text'" />
    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="confirmHide = !confirmHide"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="confirmHide"
    >
      <mat-icon class="login-password-visibility-icon">{{ confirmHide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </mat-form-field>

  <cgx-button
    label="RESET"
    color="green"
    size="lg"
    width="100%"
    style="width: 100%; margin-top: 20px;"
    [disabled]="!resetPasswordForm?.valid || (loading$ | async)"
    [loading]="loading$ | async"
    (press)="submit()"
  ></cgx-button>
</form>
