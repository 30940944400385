import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '@app/constants';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { ILog, ILogsData, ILogsResponse, ITemplate } from '@app/logs-new/shared/models/logs-data.model';
import { map, switchMap } from 'rxjs/operators';
import _get from 'lodash-es/get';
import { ITemplatesRegex } from '@app/logs-new/shared/models/template.model';
import { checkAndParseJSON } from '@shared/utils/utils';
import { escapeString } from '@shared/services/utils';
import { cloneDeep } from 'lodash-es';
import { LogsGridService } from '@app/logs-new/shared/services/logs-grid.service';
import { ContextualRegistryService } from '@app/logs-new/shared/services/contextual-registry.service';
import { ConfigurationService } from '@app/services/configuration.service';
import { Router } from '@angular/router';
import { LogsRouterService } from '@app/logs-new/shared/services/logs-router.service';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  private templatesURL: string = Constants.GetUrl() + Constants.loggregationPaginatedUrl;

  constructor(
    private http: HttpClient,
    private logsGridService: LogsGridService,
    private contextualCaps: ContextualRegistryService,
    private configService: ConfigurationService,
    private router: Router,
    private logsRouterService: LogsRouterService,
  ) {}

  getLogs(data: ILogQuery): Observable<ILogsData> {
    const queryLogsUrl = this.contextualCaps.getCapabilitiesURLS()?.queryUrl ?? Constants.queryUrl;
    return this.http
      .post<ILogsResponse>(Constants.GetUrl() + queryLogsUrl, { queryDef: data })
      .map(this.extractLogsValues);
  }

  getSurroundedLogs(data: ILogQuery): Observable<ILogsData> {
    const queryLogsUrl = this.configService.getConfigURL('/api/v1/archive-logs/surrounding-logs');
    return this.http
      .post<ILogsResponse>(queryLogsUrl, { queryDef: data })
      .map(logsResp => {
        const firstLogIndex = logsResp.logs?.[0]?.index || 0;
        const pageIndex = Math.floor(firstLogIndex / Constants.LOGS_QUERY_PAGE_SIZE);
        const newURL = `/query-new/archive-logs`;
        this.navigateToPageIndex(newURL, pageIndex);
        this.logsGridService.paginationIndex$.next(pageIndex);
        return this.extractLogsValues(logsResp);
      });
  }

  getLogsWithValue(data: ILogQuery): Observable<ILog[]> {
    return this.getLogs(data).pipe(
      map(res => res.logs),
      map(logs => logs.map(log => ({ ...log, text: checkAndParseJSON(log.text) }))),
    );
  }

  /* Used for the "view surrounding logs" feature, gets a specific log with logs that are before and after the selected log */
  getLogsWithSpecificLog(query: ILogQuery, selectedLog: ILog): Observable<ILogsData> {
    // step 1: get total logs from queryModel start time to log time (as the end time of the query)
    const getIndexQuery = cloneDeep(query);
    getIndexQuery.pageSize = 0; // we only care about the total not the logs
    if (getIndexQuery.sortModel?.length && getIndexQuery.sortModel[0].ordering === 'desc') {
      getIndexQuery.startDate = selectedLog.timestamp + 1;
    } else {
      getIndexQuery.endDate = selectedLog.timestamp + 1; // +1 to make sure we will not miss our log
    }
    return this.getLogs(getIndexQuery).pipe(
      map(res => {
        // step 2: calculate page index of log in query
        const clonedQuery = cloneDeep(query);
        const pageIndex = Math.floor(res.total / Constants.LOGS_QUERY_PAGE_SIZE);
        const newURL = `/query-new/logs`;
        this.navigateToPageIndex(newURL, pageIndex);
        this.logsGridService.paginationIndex$.next(pageIndex);
        clonedQuery.pageIndex = pageIndex;
        return clonedQuery;
      }),
      // step 3: get query page result
      switchMap(updatedQuery => this.getLogs(updatedQuery)),
    );
  }

  static get templatesPlaceholdersRegex(): ITemplatesRegex {
    return {
      categoricalRegex: /{CoralogixPH(\d+)_(.+?)_categorical_CoralogixPH}/g,
      jsonRegex: /{CoralogixJsonPH(\d+)_(.+?)_json_CoralogixJsonPH}/g,
      categoricalOrJsonRegex: /{CoralogixPH(\d+)_(.+?)_categorical_CoralogixPH}|{CoralogixJsonPH(\d+)_(.+?)_json_CoralogixJsonPH}/g,
    };
  }

  getTemplates(data: ILogQuery): Observable<ITemplate[]> {
    return this.http
      .post<ITemplate[]>(this.templatesURL, { queryDef: data })
      .map(templates => templates.sort((a, b) => (a.count > b.count ? -1 : 1)));
  }

  getTemplatesWithValue(data: ILogQuery): Observable<ITemplate[]> {
    return this.getTemplates(data).pipe(
      map(templates =>
        templates?.map(template => {
          return {
            ...template,
            text: LogsService.extractTemplateValues(template),
          };
        }),
      ),
    );
  }

  static extractTemplateValues(template: ITemplate): string {
    const { categoricalRegex, categoricalOrJsonRegex, jsonRegex } = this.templatesPlaceholdersRegex;
    return template.text.replace(categoricalOrJsonRegex, val => {
      if (val.match(categoricalRegex)) {
        return escapeString(val.replace(categoricalRegex, '$2'));
      }
      if (val.match(jsonRegex)) {
        return escapeString(_get(template.logExample, val.replace(jsonRegex, '$2')));
      }
      return val;
    });
  }

  private navigateToPageIndex(url: string, pageIndex: number): void {
    const params = this.logsRouterService.queryParams;
    this.router.navigate([url], {
      queryParams: { ...params, page: pageIndex },
      state: { hasData: true },
    });
  }

  private extractLogsValues(logsRes: ILogsResponse): ILogsData {
    return {
      total: logsRes.total,
      logs: logsRes.logs.map(log => {
        const textObject = checkAndParseJSON(log.text);
        return { ...log, rawText: log.text, textObject };
      }),
    };
  }
}
