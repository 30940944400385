<div class="ngx-datepicker-container">
  <input
    type="text"
    *ngIf="!headless"
    class="ngx-datepicker-input"
    [(ngModel)]="displayValue"
    readonly
    (click)="toggle()"
  />
  <ng-content></ng-content>
  <div
    class="ngx-datepicker-calendar-container ngx-datepicker-position-{{
      position
    }}"
    [class.inline]="inline"
    *ngIf="isOpened"
  >
    <div class="topbar-container">
      <svg
        width="7px"
        height="10px"
        viewBox="0 0 7 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        (click)="prevMonth()"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-923.000000, -1882.000000)" fill="#CED0DA">
            <g transform="translate(80.000000, 1361.000000)">
              <g transform="translate(0.000000, 430.000000)">
                <g transform="translate(825.000000, 0.000000)">
                  <g transform="translate(0.000000, 72.000000)">
                    <g transform="translate(18.000000, 15.000000)">
                      <polygon
                        id="Back"
                        points="6.015 4 0 9.013 6.015 14.025"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <span class="topbar-title" (click)="toggleView()">{{ barTitle }}</span>
      <svg
        width="7px"
        height="10px"
        viewBox="0 0 6 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        (click)="nextMonth()"
      >
        <g
          id="Source-Sans---UI-Elements-Kit"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="White-Layout"
            transform="translate(-1182.000000, -1882.000000)"
            fill="#CED0DA"
          >
            <g
              id="Dropdowns-&amp;-Selector"
              transform="translate(80.000000, 1361.000000)"
            >
              <g id="Dropdowns" transform="translate(0.000000, 430.000000)">
                <g id="Calendar" transform="translate(825.000000, 0.000000)">
                  <g
                    transform="translate(0.000000, 72.000000)"
                    id="Top-Bar-Nav"
                  >
                    <g transform="translate(18.000000, 15.000000)">
                      <polygon
                        id="Forward"
                        transform="translate(262.007500, 9.012500) scale(-1, 1) translate(-262.007500, -9.012500) "
                        points="265.015 4 259 9.013 265.015 14.025"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="main-calendar-container" *ngIf="view === 'days'">
      <div class="main-calendar-day-names">
        <span class="day-name-unit" *ngFor="let name of dayNames">
          {{ name }}
        </span>
      </div>
      <div class="main-calendar-days">
        <span
          class="day-unit"
          *ngFor="let day of days; let i = index"
          [ngClass]="{
            'is-prev-month': !day.inThisMonth,
            'is-today': day.isToday,
            'is-selected': day.isSelected,
            'is-disabled': !day.isSelectable
          }"
          (click)="day.isSelectable && setDate(i)"
        >
          {{ day.day }}
        </span>
      </div>
    </div>
    <div class="main-calendar-container" *ngIf="view === 'years'">
        <perfect-scrollbar class="main-calendar-years" [config]="scrollOptions">
           <span
             class="year-unit"
             *ngFor="let year of years; let i = index"
             [ngClass]="{ 'is-selected': year.isThisYear }"
             (click)="setYear(i)"
           >
            {{ year.year }}
          </span>
        </perfect-scrollbar>
    </div>
  </div>
</div>
