import { type } from '../../../../shared/services/utils';
import { Action } from '@ngrx/store';
import { SecurityOnionMappingModel } from '@app/settings/shared/models/securityOnionMappingModel';

export const ActionTypes = {
  SET_SECURITY_ONION_MAPPINGS: type('[SecurityOnion] SetMapping SecurityOnion'),
  SET_SECURITY_ONION_MAPPINGS_COMPLETED: type('[SecurityOnion] SetMapping Completed'),
  SET_SECURITY_ONION_MAPPINGS_FAILED: type('[SecurityOnion] SetMapping FAILED'),
};

export class SetMappingSecurityOnionAction implements Action {
  public type: string = ActionTypes.SET_SECURITY_ONION_MAPPINGS;

  constructor(public payload?: SecurityOnionMappingModel) {}
}
export class SetMappingSecurityOnionCompletedAction implements Action {
  public type: string = ActionTypes.SET_SECURITY_ONION_MAPPINGS_COMPLETED;

  constructor(public payload: any) {}
}
export class SetMappingSecurityOnionFailedAction implements Action {
  public type: string = ActionTypes.SET_SECURITY_ONION_MAPPINGS_FAILED;

  constructor(public payload: any) {}
}

export type Actions =
  | SetMappingSecurityOnionAction
  | SetMappingSecurityOnionCompletedAction
  | SetMappingSecurityOnionFailedAction;
