import { jsonObject, jsonMember } from 'typedjson';
import * as moment from 'moment';
import { Constants } from '../../../constants';

@jsonObject
export class InsightsQueryModel {
  @jsonMember
  public startDate: number;
  @jsonMember
  public endDate: number;
  @jsonMember
  public pageSize: number = Constants.DEFAULT_INSIGHTS_PAGE_SIZE;
  @jsonMember
  public isActive: boolean;

  constructor(startDate?: Date, endDate?: Date) {
    this.startDate = moment(startDate).unix() * 1000 - Constants.INSIGHTS_TIME_FRAME;
    this.endDate = moment(endDate).unix() * 1000;
  }
}
