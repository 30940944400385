<form class="ui form inverted" [formGroup]="saveViewForm" (ngSubmit)="save()">
  <div class="required field">
    <label>View name</label>
    <input formControlName="viewName" placeholder="View name" type="text" />
    <div
      class="ui pointing red basic label"
      *ngIf="this.saveViewForm.dirty && !this.saveViewForm.valid"
    >
      This field is required
    </div>
  </div>
  <div class="inline fields">
    <label>Who can use this view?</label>
    <div class="field">
      <div class="ui radio checkbox">
        <input formControlName="visibility" type="radio" value="private" />
        <label>Just me</label>
      </div>
    </div>
    <div class="field">
      <div class="ui radio checkbox">
        <input formControlName="visibility" type="radio" value="public" />
        <label>Everyone</label>
      </div>
    </div>
  </div>
  <div class="field">
    <div class="ui checkbox">
      <input formControlName="includeQueryParams" type="checkbox" />
      <label>Include query parameters</label>
    </div>
  </div>
  <div class="field">
    <div class="ui checkbox">
      <input formControlName="isDefault" type="checkbox" />
      <label>Set as default view</label>
    </div>
  </div>
  <div>
    <button
      class="ui skyblue submit button"
      [ngClass]="{ 'disabled': !this.saveViewForm.valid }"
      type="submit"
    >
      {{ !editView ? 'Save' : 'Update' }}
    </button>
  </div>
</form>
