import { type } from '../../shared/services/utils';
import { Action } from '@ngrx/store';
import {
  EmailFilterDefinition,
  EmailFilterModel,
} from '../shared/models/emailFilterModel';
import ActionTypes = SettingsActions.ActionTypes;

export namespace SettingsActions {
  export const ActionTypes = {
    GET_EMAIL_FILTERS: type('[SETTINGS] GET EMAIL FILTERS'),
    GET_EMAIL_FILTERS_COMPLETED: type('[SETTINGS] GET EMAIL FILTERS Completed'),
    GET_EMAIL_FILTERS_FAILED: type('[SETTINGS] GET EMAIL FILTERS FAILED'),

    ADD_EMAIL_FILTER: type('[SETTINGS] ADD EMAIL FILTER'),
    ADD_EMAIL_FILTER_FAILED: type('[SETTINGS] ADD EMAIL FILTER FAILED'),
    ADD_EMAIL_FILTER_COMPLETED: type('[SETTINGS] ADD EMAIL FILTER COMPLETED'),

    DELETE_EMAIL_FILTER: type('[SETTINGS] DELETE EMAIL FILTER'),
    DELETE_EMAIL_FILTER_FAILED: type('[SETTINGS] DELETE EMAIL FILTER FAILED'),
    DELETE_EMAIL_FILTER_COMPLETED: type(
      '[SETTINGS] DELETE EMAIL FILTER COMPLETED',
    ),

    EDIT_EMAIL_FILTER: type('[SETTINGS] EDIT EMAIL FILTER'),
    EDIT_EMAIL_FILTER_FAILED: type('[SETTINGS] EDIT EMAIL FILTER FAILED'),
    EDIT_EMAIL_FILTER_COMPLETED: type('[SETTINGS] EDIT EMAIL FILTER COMPLETED'),
  };

  export class AddEmailFilterAction implements Action {
    public type = ActionTypes.ADD_EMAIL_FILTER;

    constructor(public payload: EmailFilterDefinition) {}
  }
  export class AddEmailFilterActionFailed implements Action {
    public type = ActionTypes.ADD_EMAIL_FILTER_FAILED;

    constructor(public payload: any = null) {}
  }
  export class AddEmailFilterActionCompleted implements Action {
    public type = ActionTypes.ADD_EMAIL_FILTER_COMPLETED;

    constructor(public payload: any = null) {}
  }
  export class GetEmailFiltersAction implements Action {
    public type = ActionTypes.GET_EMAIL_FILTERS;

    constructor(public payload: any = null) {}
  }
  export class GetEmailFiltersActionCompleted implements Action {
    public type = ActionTypes.GET_EMAIL_FILTERS_COMPLETED;

    constructor(public payload: EmailFilterModel) {}
  }
  export class GetEmailFiltersActionFailed implements Action {
    public type = ActionTypes.GET_EMAIL_FILTERS_FAILED;

    constructor(public payload: boolean) {}
  }
  export class DeleteEmailFilterAction implements Action {
    public type = ActionTypes.DELETE_EMAIL_FILTER;

    constructor(public payload: EmailFilterDefinition) {}
  }
  export class DeleteEmailFilterActionFailed implements Action {
    public type = ActionTypes.DELETE_EMAIL_FILTER_FAILED;

    constructor(public payload: any = null) {}
  }
  export class DeleteEmailFilterActionCompleted implements Action {
    public type = ActionTypes.DELETE_EMAIL_FILTER_COMPLETED;

    constructor(public payload: any = null) {}
  }
  export class EditEmailFilterAction implements Action {
    public type = ActionTypes.EDIT_EMAIL_FILTER;

    constructor(public payload: EmailFilterDefinition) {}
  }
  export class EditEmailFilterActionFailed implements Action {
    public type = ActionTypes.EDIT_EMAIL_FILTER_FAILED;

    constructor(public payload: any = null) {}
  }
  export class EditEmailFilterActionCompleted implements Action {
    public type = ActionTypes.EDIT_EMAIL_FILTER_COMPLETED;

    constructor(public payload: any = null) {
      console.log(type, payload);
    }
  }

  export type Actions =
    | GetEmailFiltersAction
    | GetEmailFiltersActionCompleted
    | GetEmailFiltersActionFailed
    | AddEmailFilterAction
    | AddEmailFilterActionCompleted
    | AddEmailFilterActionFailed
    | DeleteEmailFilterAction
    | DeleteEmailFilterActionCompleted
    | DeleteEmailFilterActionFailed
    | EditEmailFilterAction
    | EditEmailFilterActionCompleted
    | EditEmailFilterActionFailed;
}
