import { EventEmitter, Injectable } from '@angular/core';

import { Log } from '../models/log.entity';
import { QueryParams } from '../models/query-params.model';
import { QueryHistoryModel } from '../models/query-history.model';
import { LogManager } from '@app/logs/shared/services/logs-manager';
import { SearchProvider } from '@shared/controls/search/search.provider';

@Injectable()
export class LogsViewStateProvider {
  public viewState: QueryViewState = new QueryViewState();
  public onStartBeforeAfter: EventEmitter<any> = new EventEmitter();
  public onExport: EventEmitter<any> = new EventEmitter();
  public onDetectGroks: EventEmitter<any> = new EventEmitter();
  public onFilterChange: EventEmitter<QueryParams> = new EventEmitter();
  public onAfterDynamicFiltersInit: EventEmitter<void> = new EventEmitter();
  public textInfoClicked: EventEmitter<void> = new EventEmitter();

  public logsGridState: LogManager;
  public lastExcecutedQuery: QueryHistoryModel;
  public doBeforeAfter: { seconds: number; selectedLog: Log };
}

export class QueryViewState {
  public showTimeline: boolean = true;
  public _selectedLog?: any;
  public loggregationSelectedIndex: number;
  public logsSelectedIndex: number;
  public isQueryVisible: boolean = true;
  public queryResultCount: string;
  public showFind: boolean = true;
  public logsManager: LogManager;
  public logsLastVisibleIndex: number;
  public logsColumnState: any;
  public logsFindText: string;
  public filterModel: any;
  public logsSearchProvider: SearchProvider = new SearchProvider();
  public templateCount: number;
  public logsCount: number;

  constructor() {
    this._selectedLog = null;
  }

  set selectedLog(value) {
    this._selectedLog = value;
  }

  get selectedLog() {
    return this._selectedLog;
  }
}
