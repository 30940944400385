import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SettingsCommonModule } from '../settings-common/settings-common.module';
import { UserModule } from '../user/user.module';
import { CommonModule } from '@angular/common';
import { DeploymentModule } from '../deployment/deployment.module';
import { FilterPipeModule } from '@app/shared/pipes/filter-pipe/filter-pipe.module';
import { NavbarModule } from './navbar/navbar.module';
import { PlanLimitBarModule } from '@app/shared/controls/plan-limit-bar/plan-limit-bar.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    SettingsCommonModule,
    UserModule,
    DeploymentModule,
    FilterPipeModule,
    NavbarModule,
    PlanLimitBarModule,
  ],
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
})
export class LayoutModule {}
