import { LogsRegistry } from '@app/logs-new/shared/abilities-state-registry/logs-context';
import { TemplatesRegistry } from '@app/logs-new/shared/abilities-state-registry/templates-context';
import { AQRegistry } from '@app/logs-new/shared/abilities-state-registry/archive-query-context';
import { IRegistry } from '@app/logs-new/shared/abilities-state-registry/capabilities-model';

export enum LogsContent {
  logs,
  templates,
  archiveQuery
}

export const abilities: {[key: number]: IRegistry} = {
  [LogsContent.logs] : new LogsRegistry(),
  [LogsContent.templates] : new TemplatesRegistry(),
  [LogsContent.archiveQuery] : new AQRegistry()
};
