import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { MetricsActions } from '@app/settings/metrices-settings/state/actions/metrics.actions';
import { MetricsService } from '@app/settings/shared/services/metrics.service';
import { ShDialogService } from '@shared/services/dialogService';

@Injectable()
export class MetricsEffects {
  @Effect()
  public getMetrics$: Observable<Action> = this.actions$.pipe(
    ofType(MetricsActions.ActionTypes.GET_METRICS),
    switchMap((action: any) => {
      return this.metricsService
        .getMetrics()
        .map((res) => new MetricsActions.GetMetricsCompletedAction(res))
        .catch((err) => of(new MetricsActions.GetMetricsFailedAction(err)));
    }),
  );

  @Effect()
  public deleteMetric$: Observable<Action> = this.actions$.pipe(
    ofType(MetricsActions.ActionTypes.DELETE_METRICS),
    switchMap((action: any) => {
      return this.metricsService
        .deleteMetric(action.payload)
        .map((res) => new MetricsActions.DeleteMetricCompletedAction(res))
        .catch((err) => of(new MetricsActions.DeleteMetricFailedAction(err)));
    })
  );

  @Effect()
  public createMetric$: Observable<Action> = this.actions$.pipe(
    ofType(MetricsActions.ActionTypes.CREATE_METRICS),
    switchMap((action: any) => {
      return this.metricsService
        .createMetric(action.payload)
        .map((res) => new MetricsActions.CreateMetricCompletedAction(res))
        .catch((err) => of(new MetricsActions.CreateMetricFailedAction(err)));
    }),
  );

  @Effect()
  public editMetric$: Observable<Action> = this.actions$.pipe(
    ofType(MetricsActions.ActionTypes.EDIT_METRICS),
    switchMap((action: any) => {
      return this.metricsService
        .editMetric(action.payload)
        .map((res) => new MetricsActions.EditMetricCompletedAction(res))
        .catch((err) => of(new MetricsActions.EditMetricFailedAction(err)));
    }),
  );

  @Effect()
  public createMetricFailed$: Observable<Action> = this.actions$.pipe(
    ofType(MetricsActions.ActionTypes.CREATE_METRICS_FAILED),
    switchMap((action: any) => {
      this.dialogService.showSnackBar('Failed to create metric');
      return Observable.of(new MetricsActions.ShowMetricActionFailedMsgCompleted(action.payload));
    }),
  );

  @Effect()
  public editMetricFailed$: Observable<Action> = this.actions$.pipe(
    ofType(MetricsActions.ActionTypes.EDIT_METRICS_FAILED),
    switchMap((action: any) => {
      this.dialogService.showSnackBar('Failed to edit metric');
      return Observable.of(new MetricsActions.ShowMetricActionFailedMsgCompleted(action.payload));
    }),
  );

  @Effect()
  public deleteMetricFailed$: Observable<Action> = this.actions$.pipe(
    ofType(MetricsActions.ActionTypes.DELETE_METRICS_FAILED),
    switchMap((action: any) => {
      this.dialogService.showSnackBar('Failed to delete metric');
      return Observable.of(new MetricsActions.ShowMetricActionFailedMsgCompleted(null));
    }),
  );
  constructor(private actions$: Actions, private metricsService: MetricsService, private dialogService: ShDialogService) {}
}
