import _ from 'lodash';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

export interface IMetricField {
  name: string;
  fieldPath: string;
  metricId?: string;
}

export interface IMetricsLabelsMap {
  [metricId: string]: number;
}

export interface IMetricFieldsRes {
  allFields: IMetricField[];
  allLabels: Array<IMetricField[]>;
  labelsMap: IMetricsLabelsMap;
}

export interface IPrometheusLabelsReq {
  field: string;
  start: number;
  end: number;
}

export interface IPrometheusLabelsRes {
  status: string;
  data: string[];
  warnings: string[];
}

@jsonObject
export class FieldExtraction {
  @jsonMember
  public name: string;
  @jsonMember
  public fieldPath: string;
}

@jsonObject
export class Metric {
  @jsonMember
  public id: string;
  @jsonMember
  public name: string;
  @jsonMember
  public description: string;
  @jsonArrayMember(Number)
  public severity: number[];
  @jsonMember({ name: 'specificText' })
  public text: string;
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public applicationName: any[];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public subsystem: any[];
  @jsonMember
  public createDate: string;
  @jsonMember
  public updateDate: string;
  @jsonMember
  public permutationsLimit: number;
  @jsonMember
  public hasExceededLimit: boolean;
  @jsonArrayMember(FieldExtraction, { deserializer: groupByBackwardCompatibilityDeserializer })
  public groupBy: FieldExtraction[];
  @jsonArrayMember(FieldExtraction)
  public metricFields: FieldExtraction[];
}

function groupByBackwardCompatibilityDeserializer(value: any[]): FieldExtraction[] {
  const typed = new TypedJSON(FieldExtraction);
  try {
    const parsed = !_.isEmpty(value) ? value.map(inner => typed.parse(inner)) : [];
    return parsed;
  } catch {
    return typed.parseAsArray(
      value.map(group => {
        return { name: `${group}`, fieldPath: `${group}` };
      }),
    );
  }
}

@jsonObject
export class MetricsResponse {
  @jsonArrayMember(Metric)
  public metrics: Metric[];
}

export class CreateMetricRequest {
  public name: string;
  public description: string;
  public severity: number[];
  public specificText: string;
  public applicationName: string[];
  public subsystem: string[];
  public groupBy: FieldExtraction[];
  public metricFields: FieldExtraction[];
  public permutationsLimit: number;
  constructor(init?: Partial<CreateMetricRequest>) {
    Object.assign(this, init);
  }
}

export class EditMetricRequest extends CreateMetricRequest {
  public id: string;

  constructor(init?: Partial<EditMetricRequest>) {
    super(init);
    this.id = init.id;
  }
}

export class DeleteMetricRequest {
  public metricId: string;

  constructor(metricId: string) {
    this.metricId = metricId;
  }
}
