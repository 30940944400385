import { AsyncPipe } from '@angular/common';
import { Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { AmPmMomentOptions, DateInputType, MomentTimeFormatTypes } from '@shared/helpers/moment.helper';
import { TimeService } from '@shared/services/time.service';

@Pipe({
  name: 'dateFormatByUserSettings',
})
@Injectable({
  providedIn: 'root',
})
export class DateFormatByUserSettingsPipe implements PipeTransform, OnDestroy {
  constructor(private asyncPipe: AsyncPipe, private timeService: TimeService) {}
  public transform(
    value: any,
    amPmFormat: AmPmMomentOptions = AmPmMomentOptions.smallLetters,
    hoursFormat: MomentTimeFormatTypes = MomentTimeFormatTypes.withMilliseconds,
    showDate: boolean = true,
    uniqueFullFormat: string = null,
    dateInputType: DateInputType = DateInputType.unix,
  ): any {
    const finalData$ = this.timeService.getStringDateByUserSettings$(
      value,
      amPmFormat,
      hoursFormat,
      showDate,
      uniqueFullFormat,
      dateInputType,
    );
    return this.asyncPipe.transform(finalData$);
  }
  public ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }
}
