
export class DeploySecurityService {
    public static readonly type: string = '[Cloud Security] Deploy Security Service';
}

export class UpdatePrivateKey {
    public static readonly type: string = '[Cloud Security] Update Private Key';
    constructor(public privateKey: string) { }
}

export class UpdateSecurityOnionRegion {
    public static readonly type: string = '[Cloud Security] Update Security Onion Region';
    constructor(public securityOnionRegion: string) { }
}

export class UpdateNamesAndRegion {
    public static readonly type: string = '[Cloud Security] Update Security Onion Region';
    constructor(public securityOnionRegion: string, public appName: string, public subsystemName: string) { }
}
