import { UserAlertTypes } from '@app/alerts/shared/models/create-n-update-alert-model';
import { AlertSyntaxType, UserAlertViewTypes } from '@app/alerts/alerts-editor/models/alert-editor-view.models';
import { IConditionalSelectGroup } from '@app/shared/components/conditional-select/conditional-select.model';

export class RelativeAlert {
  public id: string = null;
  public applicationName: IConditionalSelectGroup[];
  public logSeverity: number[];
  public alias: string;
  public parentAlias: string;
  public subsystemName: IConditionalSelectGroup[];
  public text: string;
  public alertText: string;
  public alertSyntaxType: AlertSyntaxType;
  public order: number;
  public operator: string = '/';
  public relativeAlerts: any = null;
  public userAlertTypeId: UserAlertTypes;
  public relativeTimeframe: number;

  constructor(init?: Partial<RelativeAlert>) {
    Object.assign(this, init);
  }
}

export class AlertEditControls {
  public alertName: string;
  public alertText: string;
  public alertSyntaxType: AlertSyntaxType;
  public alertSeverity: number;
  public alertEndDate: Date;
  public alertStartDate: Date;
  public notifyEveryHours: number;
  public notifyEveryMinutes: number;
  public description: string;
  public logSeverity: number[];
  public conditionOperator: number;
  public conditionThreshold: number;
  public conditionTimeframe: number;
  public relativeTimeframe: number;
  public conditionMetricField: [string, string];
  public conditionMetricArithmeticOperator: number;
  public conditionMetricArithmeticModifier: number;
  public conditionMetricValueThreshold: number;
  public conditionSwapNullValue: boolean;
  public conditionSampleThreshold: number;
  public conditionNonNullPercentage: number;
  public notifyOnResolved: boolean;
  public applicationName: IConditionalSelectGroup[];
  public subsystemName: IConditionalSelectGroup[];
  public notificationPayloadFilter: string[];
  public notificationsMails: string[];
  public integrations: string[];
  public isActive: boolean;
  public groupByFields: string[];
  public notifyGroupByOnlyAlerts: boolean;
  public cardinalityFields: string;
  public limitTriggering: boolean;
  public daysOfWeek: number[];
  public activityStarts: string;
  public activityEnds: string;
  public alias: string;
  public relativeAlerts: RelativeAlert[];
  public userAlertTypeId: UserAlertViewTypes;
  public isCardinalityGroupBy: boolean;
  public cardinalityGroupByFields: number;

  constructor(init?: Partial<AlertEditControls>) {
    Object.assign(this, init);
  }
}
