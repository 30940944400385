import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { StatisticsQueryDefinition } from '../../shared/models/statistics-query-definition';
import { StatisticService } from '../../shared/services/statistics-service';
import { StatisticsChartService } from '../../shared/services/statistics-chart.service';
import { WidgetBaseComponent } from '@shared/components/widget-base-component';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
  selector: 'sh-basic-statistic-chart',
  templateUrl: './basic-statistic-chart.component.html',
  styleUrls: ['basic-statistic-chart.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BasicStatisticChartComponent extends WidgetBaseComponent {
  @Input() set queryDefinition(queryDef: StatisticsQueryDefinition) {
    if (queryDef) {
      const clonedQuery = cloneDeep(queryDef);
      if (clonedQuery.logQeuryModel['graphs']) delete clonedQuery.logQeuryModel['graphs'];
      this.isLoading = true;
      this.statisticService
        .getStats(clonedQuery)
        .first()
        .subscribe(res => {
          const chartModel = this.statisticsChartService.getChartModel(res, queryDef);
          chartModel.infoBarClass = this.infoBarClass;
          chartModel.enableCopyClipboard = this.enableCopyClipboard;
          chartModel.backgroundColor = 'transparent';
          if (this.chartHeight) {
            chartModel.height = this.chartHeight;
          }
          this.model = chartModel;
          this.isLoading = false;
        });
    }
  }

  @Input() public infoBarClass: string;
  @Input() public chartHeight: number;
  @Input() public enableCopyClipboard: boolean = false;
  @Input() public enableLoading: boolean = false;

  constructor(private statisticService: StatisticService, private statisticsChartService: StatisticsChartService) {
    super();
  }
}
