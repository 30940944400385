import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class SnackBarStatusModel {
  @jsonMember
  public message: string;

  @jsonMember
  public status: StatusesEnum;

  get className(): string {
    return {
      [StatusesEnum.ERROR]: 'status-error',
      [StatusesEnum.INFO]: 'status-info',
      [StatusesEnum.SUCCESS]: 'status-success',
      [StatusesEnum.WARNING]: 'status-warning',
    }[this.status];
  }
}

export enum StatusesEnum {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
}
