import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { selectTeamById } from '@app/user/state/teams/team.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'sh-team-renderer',
  template: `
    <div *ngIf="value" class="teams">
      <span class="abbrv">{{ (teamValue$ | async)?.toUpperCase()?.substr(0, 2) }}</span>
      <span class="team-name">{{ teamValue$ | async }}</span>
    </div>
  `,
  styleUrls: ['team-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamRendererComponent implements ICellRendererAngularComp {
  public value: string = '';
  public teamValue$: Observable<string>;
  private params: ICellRendererParams;

  constructor(private store: Store<State>) {}

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = this.getCompanyId(params) || null;
    this.teamValue$ = this.store.select(selectTeamById(this.value));
  }

  public refresh(params: any): boolean {
    return true;
  }

  private getCompanyId(params: ICellRendererParams): string {
    return params?.data?.metadata?.companyId || params?.data?.logExample?.coralogix?.metadata?.companyId;
  }
}
