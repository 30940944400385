export enum CompareType {
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  contains = 'contains',
  equals = 'equals',
}

export interface IConditionalSelectGroup {
  compareType: CompareType;
  values: string[];
}

export type ConditionalSelectGroups = Record<CompareType, string[]>;
