import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { ZipService } from './zip.service';

@Injectable()
export class CsvExportService {
  constructor(private zipService: ZipService) {}

  public exportCsv(rows: string[], fileName: string, createAsZip: boolean = true): Promise<any> | void {
    const csvContent = rows.join('\r\n');
    if (createAsZip) {
      return this.zipService.zipFiles(csvContent, fileName, 'csv');
    } else {
      const blob = new Blob([csvContent], { type: 'text/csv' });
      return FileSaver.saveAs(blob, `${fileName}.${'csv'}`);
    }
  }
}
