<div class="container coralogix" [class.with-icon]="showIcon">
  <mat-form-field class="coralogix" floatLabel="never" >
    <mat-chip-list #chipList>
      <mat-chip class="coralogix" *ngFor="let item of value" [selectable]="selectable"
                [removable]="removable" (removed)="remove(item)">
        <span class="value">{{item}}</span>
        <span class="remove" matChipRemove *ngIf="removable">×</span>
      </mat-chip>
      <input [placeholder]="placeholder"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="add($event)"
      >
    </mat-chip-list>
  </mat-form-field>
  <div class="custom-icon" *ngIf="showIcon">
    <ng-content select="[custom-icon]"></ng-content>
  </div>
</div>
