import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs/Rx';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'sh-metric-field-picker',
  templateUrl: './metric-field-picker.component.html',
  styleUrls: ['./metric-field-picker.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: MetricFieldPickerComponent,
      multi: true
  }]
})
export class MetricFieldPickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) public virtualScroll: CdkVirtualScrollViewport;
  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;

  @Input()
  set fields(metricFields: [string, string][]) {
    this.metricFields = metricFields.sort((a, b) => {
      const aProm = this.isPrometheusMetric(a);
      const bProm = this.isPrometheusMetric(b);
      if ((aProm && bProm) || (!aProm && !bProm)) {
        return 0;
      } else if (aProm) {
        return 1;
      } else {
        return -1;
      }
    });
    this.filteredMetricFields = this.metricFields.filter(
      (field) => field[0].includes(this.searchControl.value)
    );
    this.firstPrometheusIndex = this.getFirstPrometheusFieldIndex();
    this.hasLogs2MetricsFields = this.hasLogs2Metrics();
  }

  public set value(field: [string, string]) {
    this.pickedField = field;
    if (this.onChangeFn) {
      this.onChangeFn(field);
    }
    if (this.onTouchedFn) {
      this.onTouchedFn(field);
    }
  }
  public get value(): [string, string] {
    return this.pickedField;
  }

  public pickedField: [string, string];
  public metricFields: [string, string][];
  public firstPrometheusIndex: number = -1;
  public filteredMetricFields: [string, string][];
  public hasLogs2MetricsFields: boolean = false;
  public searchControl: FormControl = new FormControl('');

  private unsubscribe$: Subject<void> = new Subject<void>();
  private onChangeFn: (val: any) => any;
  private onTouchedFn: (val?: any) => any;

  constructor(private store: Store<State>) {}

  public ngOnInit(): void {
    this.setInitialData();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public setInitialData(): void {
    this.filteredMetricFields = this.metricFields;
    this.firstPrometheusIndex = this.getFirstPrometheusFieldIndex();
    this.searchControl.valueChanges.pipe(
      startWith(''),
      takeUntil(this.unsubscribe$)
    ).subscribe(searchQuery => {
      this.filteredMetricFields = this.metricFields.filter(
        (field) => field[0].includes(searchQuery)
      );
      this.firstPrometheusIndex = this.getFirstPrometheusFieldIndex();
    });
  }

  public isPrometheusMetric(metricField: [string, string]): boolean {
    return metricField[1] === 'coralogix.metrics.prometheus';
  }

  public closeMenu(): void {
    if (this.onTouchedFn) {
      this.onTouchedFn();
    }

    this.virtualScroll.scrollToIndex(0);
  }

  public registerOnChange(fn: (val: any) => any): void {
      this.onChangeFn = fn;
  }

  public registerOnTouched(fn: (val: any) => any): void {
      this.onTouchedFn = fn;
  }

  public writeValue(value: [string, string]): void {
      this.value = value;
      if (this.trigger) {
        this.trigger.closeMenu();
      }
  }

  public hasLogs2Metrics(): boolean {
    return this.filteredMetricFields.length > 0 && this.firstPrometheusIndex !== 0;
  }

  private getFirstPrometheusFieldIndex(): number {
    return this.filteredMetricFields.findIndex(val =>
      this.isPrometheusMetric(val)
    );
  }
}
