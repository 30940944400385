import { ERuleServerTypes } from '@app/rules/shared/models/rule.model';
import { RuleAssetsUrl } from '@app/rules/shared/models/rules-consts';
import { IDropdownMenuEntity, ISelectEntity } from '@shared/models/entity.model';

export enum ERuleTypesNames {
  assingSeverity = 'Assign Severity',
  parse = 'Parse',
  extract = 'Extract',
  extractJson = 'Json Extract',
  replace = 'Replace',
  block = 'Block',
  timestampExtract = 'Timestamp Extract',
  removeFields = 'Remove Fields'
}

export interface IRuleData {
  logoUrl: string;
  title: ERuleTypesNames;
  description: string;
  buttonText: string;
  iconClass: string;
  type: ERuleServerTypes;
}

export const RulesTypes: IRuleData[] = [
  {
    logoUrl: `${RuleAssetsUrl}/rule-parse.svg`,
    iconClass: 'rule-parse',
    title: ERuleTypesNames.parse,
    description: 'Parse unstructured logs into JSON format using named Regex groups',
    buttonText: ERuleTypesNames.parse,
    type: ERuleServerTypes.parse,
  },
  {
    logoUrl: `${RuleAssetsUrl}/rule-extract.svg`,
    iconClass: 'rule-extract',
    title: ERuleTypesNames.extract,
    description: 'Use a named RegEx group to extract specific values you need as JSON keys without having to parse the entire log',
    buttonText: ERuleTypesNames.extract,
    type: ERuleServerTypes.extract,
  },
  {
    logoUrl: `${RuleAssetsUrl}/rule-extract-json.svg`,
    iconClass: 'rule-extract-json',
    title: ERuleTypesNames.extractJson,
    description: 'Name a JSON field to extract its value directly into a Coralogix metadata field',
    buttonText: ERuleTypesNames.extractJson,
    type: ERuleServerTypes.jsonExtract,
  },
  {
    logoUrl: `${RuleAssetsUrl}/rule-replace.svg`,
    iconClass: 'rule-replace',
    title: ERuleTypesNames.replace,
    description: 'Replace rules are used to strings in order to fix log structure, change log severity, or obscure information',
    buttonText: ERuleTypesNames.replace,
    type: ERuleServerTypes.replace,
  },
  {
    logoUrl: `${RuleAssetsUrl}/rule-block.svg`,
    iconClass: 'rule-block',
    title: ERuleTypesNames.block,
    description: 'Block rules allow for refined filtering of incoming logs with a RegEx',
    buttonText: ERuleTypesNames.block,
    type: ERuleServerTypes.block,
  },
  {
    logoUrl: `${RuleAssetsUrl}/rule-timestamp.svg`,
    iconClass: 'rule-timestamp',
    title: ERuleTypesNames.timestampExtract,
    description: 'Replace rules are used to replace logs timestamp with JSON field',
    buttonText: ERuleTypesNames.timestampExtract,
    type: ERuleServerTypes.timestampExtract,
  },
  {
    logoUrl: `${RuleAssetsUrl}/remove-fields.svg`,
    iconClass: 'rule-remove-fields',
    title: ERuleTypesNames.removeFields,
    description: 'Remove Fields allows to select fields that will not be indexed',
    buttonText: ERuleTypesNames.removeFields,
    type: ERuleServerTypes.removeFields,
  },
];

export const RulesServerTypesToViewTypes: {} = {
  [ERuleServerTypes.block]: [ERuleTypesNames.block],
  [ERuleServerTypes.extract]: [ERuleTypesNames.extract],
  [ERuleServerTypes.replace]: [ERuleTypesNames.replace],
  [ERuleServerTypes.jsonExtract]: [ERuleTypesNames.extractJson],
  [ERuleServerTypes.parse]: [ERuleTypesNames.parse],
  [ERuleServerTypes.allow]: [ERuleTypesNames.block],
  [ERuleServerTypes.timestampExtract]: [ERuleTypesNames.timestampExtract],
  [ERuleServerTypes.removeFields]: [ERuleTypesNames.removeFields]
};
export const RuleServerTypesArray = RulesTypes.reduce((prev, curr) => {
  return prev ? [...prev, curr.type] : [curr.type];
}, []);

export const RuleTypesForSelect: ISelectEntity[] = RulesTypes.map(rule => ( { name: rule.title, id: rule.type }));

export const RuleTypesForDropDownMenu: IDropdownMenuEntity[] = RuleTypesForSelect.map(rule => {
  const newRule: IDropdownMenuEntity = { ...rule };
  switch (rule.id) {
    case ERuleServerTypes.parse:
      newRule.description = 'Convert unstructured text to JSON';
      break;
    case ERuleServerTypes.extract:
      newRule.description = 'Extract a value into a new JSON field';
      break;
    case ERuleServerTypes.jsonExtract:
      newRule.description = 'Extract a JSON field onto metadata';
      break;
    case ERuleServerTypes.replace:
      newRule.description = 'Replace a log substring';
      break;
    case ERuleServerTypes.block:
      newRule.description = 'Block matching or non-matching logs';
      break;
    case ERuleServerTypes.timestampExtract:
      newRule.description = 'Replace a log timestamp';
      break;
    case ERuleServerTypes.removeFields:
      newRule.description = 'Remove selected fields from log';
  }
  return newRule;
});
