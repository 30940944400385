import { Component } from '@angular/core';

@Component({
  template: ``,
})
export class SignUpComponent {
  constructor() {
    window.location.href = 'https://signup.coralogix.com/#/';
  }
}
