import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, finalize, map, filter, take } from 'rxjs/operators';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { ShDialogService } from '@app/shared/services/dialogService';
import { Teammate } from '@app/user/shared/models/teammate';
import { Team } from '@app/user/shared/models/team';
import { Team2 } from '@app/user/shared/models/team.model';
import {
  AttachToAuditTeam,
  CreateAuditTeam,
  Destroy,
  DetachAuditTeam,
  GetAuditCompanies,
  GetAuditTeamConfigured,
  PaginateTeammates,
  RoleChange,
  SearchChange,
  UpdateAuditId,
  UpdateAuditTeamConfigured,
  UpdateCurrentTeam,
  UpdateLoading,
  UpdateTeammates,
  UpdateTeams,
} from './audit.action';
import { intersection } from 'lodash';
import { SortOrders } from '@app/shared/helpers/sorting.helper';
import { AuditService } from '@app/settings/audit/audit.service';

const resetState = {
  selectedRole: null,
  search: '',
  paginator: {
    pageIndex: 0,
    pageSize: 25,
  },
};

export interface IPagenator {
  pageIndex: number;
  pageSize: number;
  sortOrder?: SortOrders;
  sortActive?: 'aaa' | 'bbb';
}

export interface IAuditStateModel {
  currentTeam: Team2;
  originalTeam: Team2;
  teams: Team[];
  teammates: Teammate[];
  selectedRole: number;
  search: string;
  paginator: IPagenator;
  loading: boolean;
  isAuditTeamConfigured: boolean;
  isAuditTeamActive: boolean;
  auditingId: number;
}

@State<IAuditStateModel>({
  name: 'audit',
  defaults: {
    currentTeam: null,
    originalTeam: null,
    teams: [],
    teammates: [],
    selectedRole: null,
    search: '',
    paginator: {
      pageIndex: 0,
      pageSize: 25,
    },
    loading: false,
    isAuditTeamConfigured: false,
    isAuditTeamActive: true,
    auditingId: null,
  },
})
@Injectable()
export class AuditState {
  constructor(private dialogService: ShDialogService, private auditService: AuditService) {}

  @Selector()
  public static paginator(state: IAuditStateModel): any {
    return state?.paginator;
  }

  @Selector()
  public static auditingId(state: IAuditStateModel): any {
    return state?.auditingId;
  }

  @Selector([AuditState.teams, AuditState.auditingId])
  public static filteredTeams(teams: Team[], auditingId: number): Team[] {
    return teams.filter(team => team.id !== auditingId);
  }

  @Selector()
  public static isAuditTeamConfigured(state: IAuditStateModel): any {
    return state?.isAuditTeamConfigured;
  }

  @Selector()
  public static isAuditTeamActive(state: IAuditStateModel): any {
    return state?.isAuditTeamActive;
  }

  @Selector([AuditState.teammates])
  public static teammatesLen(teammates: Teammate[]): any {
    return teammates?.length;
  }

  @Selector([AuditState.paginator])
  public static pageIndex(paginator: IPagenator): number {
    return paginator.pageIndex;
  }

  @Selector([AuditState.paginator])
  public static pageSize(paginator: IPagenator): number {
    return paginator.pageSize;
  }

  @Selector([AuditState.paginator])
  public static sortActive(paginator: IPagenator): string {
    return paginator.sortActive;
  }

  @Selector([AuditState.paginator])
  public static sortOrder(paginator: IPagenator): SortOrders {
    return paginator.sortOrder;
  }

  @Selector()
  public static currentTeam(state: IAuditStateModel): Team2 {
    return state?.currentTeam;
  }

  @Selector()
  public static originalTeam(state: IAuditStateModel): Team2 {
    return state?.originalTeam;
  }

  @Selector()
  public static teams(state: IAuditStateModel): Team[] {
    return state?.teams;
  }

  @Selector()
  public static teammates(state: IAuditStateModel): Teammate[] {
    return state?.teammates;
  }

  @Selector()
  public static loading(state: IAuditStateModel): boolean {
    return state?.loading;
  }

  @Selector()
  public static search(state: IAuditStateModel): string {
    return state?.search;
  }

  @Selector()
  public static selectedRole(state: IAuditStateModel): number {
    return state?.selectedRole;
  }

  @Selector([AuditState.paginator])
  public static paginateFilteredTeammates(
    teammates: Teammate[],
    { from, to }: { from: number; to: number },
  ): Teammate[] {
    return teammates.slice(from, to);
  }

  @Selector([AuditState.teammates, AuditState.search])
  public static searchFilteredTeammates(teammates: Teammate[], search: string): Teammate[] {
    if (!search) {
      return teammates;
    }
    return teammates.filter(teammate => teammate.username.toLowerCase().includes(search.toLowerCase()));
  }

  @Selector([AuditState.teammates, AuditState.selectedRole])
  public static rolesFilteredTeammates(teammates: Teammate[], selectedRole: number): Teammate[] {
    if (!selectedRole) {
      return teammates;
    }
    return teammates.filter(teammate => teammate.role === selectedRole);
  }

  @Selector([
    AuditState.searchFilteredTeammates,
    AuditState.rolesFilteredTeammates,
    AuditState.pageIndex,
    AuditState.pageSize,
    AuditState.sortOrder,
    AuditState.sortActive,
  ])
  public static FilteredTeammates(
    searchFilteredTeammates: Teammate[],
    rolesFilteredTeammates: Teammate[],
    pageIndex: number,
    pageSize: number,
  ): Teammate[] {
    const data = intersection(searchFilteredTeammates, rolesFilteredTeammates);
    const start = pageIndex * pageSize;
    const end = (pageIndex + 1) * pageSize;
    return data.slice(start, end);
    // return intersection(searchFilteredTeammates, rolesFilteredTeammates);
  }

  @Action(UpdateCurrentTeam)
  public UpdateCurrentTeam(ctx: StateContext<IAuditStateModel>, { currentTeam }: IAuditStateModel): any {
    ctx.patchState({ currentTeam, originalTeam: currentTeam });
  }

  @Action(UpdateTeams)
  public UpdateTeams(ctx: StateContext<IAuditStateModel>, { teams }: IAuditStateModel): any {
    ctx.patchState({ teams });
  }

  @Action(UpdateTeammates)
  public UpdateTeammates(ctx: StateContext<IAuditStateModel>, { teammates }: IAuditStateModel): any {
    ctx.patchState({ teammates });
  }

  @Action(UpdateLoading)
  public UpdateLoading(ctx: StateContext<IAuditStateModel>, { loading }: IAuditStateModel): any {
    ctx.patchState({ loading });
  }

  @Action(PaginateTeammates)
  public PaginateTeammates(ctx: StateContext<IAuditStateModel>, { pageIndex, pageSize }: PaginateTeammates): any {
    ctx.patchState({ paginator: { pageIndex, pageSize } });
  }

  @Action(RoleChange)
  public RoleChange(ctx: StateContext<IAuditStateModel>, { role }: RoleChange): any {
    ctx.patchState({ selectedRole: role });
  }

  @Action(SearchChange)
  public SearchChange(ctx: StateContext<IAuditStateModel>, { search }: SearchChange): any {
    ctx.patchState({ search });
  }

  @Action(Destroy)
  public Destroy(ctx: StateContext<IAuditStateModel>): any {
    ctx.patchState(resetState);
  }

  @Action(UpdateAuditTeamConfigured)
  public UpdateAuditTeamConfigured(
    ctx: StateContext<IAuditStateModel>,
    { isConfigured }: UpdateAuditTeamConfigured,
  ): any {
    ctx.patchState({ isAuditTeamConfigured: isConfigured });
  }

  @Action(GetAuditCompanies)
  public GetAuditCompanies(ctx: StateContext<IAuditStateModel>): any {
    return this.auditService.getAuditCompanies().pipe(
      map((teams: Team[]) => {
        ctx.patchState({ teams });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`Error: error fetching audit companies`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(GetAuditTeamConfigured)
  public GetAuditTeamConfigured(ctx: StateContext<IAuditStateModel>): any {
    return this.auditService.getAuditTeamConfigured().pipe(
      map((configuredAuditTeam: Team2 | null) => {
        if (configuredAuditTeam) {
          ctx.patchState({
            currentTeam: configuredAuditTeam,
            auditingId: configuredAuditTeam.id,
            isAuditTeamConfigured: configuredAuditTeam.isAuditTeamActive,
            isAuditTeamActive: configuredAuditTeam.isAuditTeamActive
          });
        }
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`Error: error checking if company configured`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(CreateAuditTeam)
  public CreateAuditTeam(ctx: StateContext<IAuditStateModel>, { team }: CreateAuditTeam): any {
    ctx.patchState({ loading: true });
    return this.auditService.createAuditTeam(team).pipe(
      map((newAuditTeam: Team2) => {
        ctx.patchState({ currentTeam: newAuditTeam, isAuditTeamConfigured: true });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`Error: error creating audit team`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(UpdateAuditId)
  public UpdateAuditId(ctx: StateContext<IAuditStateModel>, { id }: UpdateAuditId): any {
    ctx.patchState({ auditingId: id });
  }

  @Action(AttachToAuditTeam)
  public AttachToAuditTeam(ctx: StateContext<IAuditStateModel>, { team }: AttachToAuditTeam): any {
    ctx.patchState({ loading: true });
    return this.auditService.attachToAuditTeam(team).pipe(
      map((newAuditTeam: Team2) => {
        ctx.patchState({ currentTeam: team, isAuditTeamConfigured: true, isAuditTeamActive: true });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`Error: error attaching audit team`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(DetachAuditTeam)
  public DetachAuditTeam(ctx: StateContext<IAuditStateModel>, { team }: DetachAuditTeam): any {
    ctx.patchState({ loading: true });
    return this.auditService.detachFromAuditTeam(team).pipe(
      map((newAuditTeam: Team2) => {
        ctx.patchState({ currentTeam: team, isAuditTeamConfigured: false, isAuditTeamActive: false });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`Error: error detaching audit team`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

}
