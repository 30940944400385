import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '@app/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetadataRequest } from '../models/metadata.request';
import { GenericMetadataType, Metadata } from '../models/query-metadata';
import { GenericMetadataRequest } from '@app/logs/shared/models/generic-metadata.request';

@Injectable({ providedIn: 'root' })
export class MetadataService {
  private metadata_url: string = Constants.GetUrl() + '/api/v1/company/metadata';

  private generic_metadata_url: string = Constants.getMetadataFields();

  constructor(private http: HttpClient) {}

  public getMetaData(filedName: string, filter: Metadata): Observable<string[]> {
    const queryDefRequest: MetadataRequest = new MetadataRequest(filedName, filter);

    return this.http
      .post(this.metadata_url, JSON.stringify(queryDefRequest))
      .map(this.extractData)
      .publishLast()
      .refCount()
      .catch(this.handleError);
  }

  public getGenericMetadata(fieldName: string, filters: GenericMetadataType): Observable<string[]> {
    const queryDefRequest: GenericMetadataRequest = new GenericMetadataRequest(fieldName, filters);

    const queryDef = JSON.stringify(queryDefRequest);
    return this.http.post(this.generic_metadata_url, queryDef).pipe(map(this.extractData));
  }

  private extractData(res): string[] {
    return res;
  }

  private handleError(error: any) {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
