import { Team } from '@app/user/shared/models/team';
import { Team2 } from '@app/user/shared/models/team.model';
import { Teammate } from '@app/user/shared/models/teammate';

export class RoleChange {
  public static readonly type: string = '[AUDIT] Role Change';
  constructor(public role: number) {}
}

export class SearchChange {
  public static readonly type: string = '[AUDIT] Search Change';
  constructor(public search: string) {}
}

export class UpdateCurrentTeam {
  public static readonly type: string = '[AUDIT] Update Current Team';
  constructor(public currentTeam: Team2) {}
}

export class UpdateTeams {
  public static readonly type: string = '[AUDIT] Update Teams';
  constructor(public teams: Team[]) {}
}

export class UpdateTeammates {
  public static readonly type: string = '[AUDIT] Update Teammates';
  constructor(public teammates: Teammate[]) {}
}

export class UpdateAuditTeamConfigured {
  public static readonly type: string = '[AUDIT] Update Audit Team Configured';
  constructor(public isConfigured: boolean) {}
}

export class UpdateLoading {
  public static readonly type: string = '[AUDIT] Update Loading';
  constructor(public loading: boolean) {}
}

export class PaginateTeammates {
  public static readonly type: string = '[AUDIT] Paginate Teammmates';
  constructor(public pageIndex: number, public pageSize: number) {}
}

export class GetAuditCompanies {
  public static readonly type: string = '[AUDIT] Get Audit Companies';
}

export class CreateAuditTeam {
  public static readonly type: string = '[AUDIT] Create Audit Team';
  constructor(public team: Team) {}
}

export class AttachToAuditTeam {
  public static readonly type: string = '[AUDIT] Attach To Audit Team';
  constructor(public team: Team2) {}
}

export class DetachAuditTeam {
  public static readonly type: string = '[AUDIT] Detach Audit Team';
  constructor(public team: Team2) {}
}

export class GetAuditTeamConfigured {
  public static readonly type: string = '[AUDIT] Get Audit Team Configured';
}

export class Destroy {
  public static readonly type: string = '[AUDIT] Destroy';
}

export class UpdateAuditId {
  public static readonly type: string = '[AUDIT] Update Audit Id';
  constructor(public id: number) {}
}
