import { Anomaly } from './anomaly.model';
import { AnomalyUpdateParams } from './Anomaly-update-params';

export class AnomalyUpdateModel {
  public anomalyTypeId: number;
  public anomalyParams: AnomalyUpdateParams;

  constructor(anomaly: Anomaly, params: AnomalyUpdateParams) {
    this.anomalyTypeId = anomaly.subTypeId;
    this.anomalyParams = params;
  }
}
