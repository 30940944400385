import { IHistogramGraphOption, ILogsGraphHistogram } from './histogram-graph.model';
import { ILogsGraphRelative, IRelativeGraphOption } from './relative-graph.model';
import { ILogsField } from '@app/logs-new/shared/models/logs-columns.model';

export enum LogsGraphType {
  Relative = 'Relative',
  Histogram = 'Histogram',
}

export enum GraphAggType {
  Count = 'count',
  Avg = 'avg',
  Min = 'min',
  Max = 'max',
  Sum = 'sum',
}

export interface IGraphAggBy {
  field: string;
  type: GraphAggType;
}

export enum LogsFieldType {
  Log = 'log',
  Metric = 'metric',
  PromMetric = 'promMetric',
}

export type ILogsGraph = ILogsGraphRelative | ILogsGraphHistogram;

export type LogsGraphOption = IHistogramGraphOption | IRelativeGraphOption;

export class GraphAggBy implements IGraphAggBy {
  field = null;
  type: GraphAggType = GraphAggType.Count;

  constructor(aggBy?: Partial<IGraphAggBy>) {
    if (aggBy?.field) this.field = aggBy.field;
    if (aggBy?.type) this.type = aggBy.type;
  }
}

export interface ILogsGraphOptions {
  aggType: GraphAggType;
  aggFuncField: ILogsField;
  groupByField: string;
  compareTo: {
    caption: string;
    value: LogsGraphOption;
  };
}
