export class QueryExternalFilters {
  public teams: number[] = [];

  constructor(data: {} = null ) {
    if (!data) {
      return;
    }
    Object.assign(this, data);
  }
}
