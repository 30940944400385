import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserSettingsDefaultOptionsEnum } from '../contants/user-settings-default-options.enum';

@Component({
  selector: 'sh-user-settings-mobile-filters',
  templateUrl: './user-settings-mobile-filters.component.html',
  styleUrls: ['./user-settings-mobile-filters.component.scss'],
})
export class UserSettingsMobileFiltersComponent {
  @Input() applicationNameOptions: string[];
  @Input() subsystemNameOptions: string[];
  @Input() selectedApps: string[];
  @Input() selectedSubsystems: string[];

  @Output() applicationChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() subsystemChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  opened: boolean;

  appSearch: string;
  subsystemSearch: string;

  get isFilterApplied(): boolean {
    return (
      this.selectedApplicationsNumber !== 'All' ||
      this.selectedSubsystemsNumber !== 'All'
    );
  }

  get selectedApplicationsNumber(): string {
    const isAllSelected =
      this.selectedApps.includes(
        UserSettingsDefaultOptionsEnum.AllApplications,
      ) || this.selectedApps.length === 0;
    return isAllSelected ? 'All' : this.selectedApps.length.toString();
  }

  get selectedSubsystemsNumber(): string {
    const isAllSelected =
      this.selectedSubsystems.includes(
        UserSettingsDefaultOptionsEnum.AllSubsystems,
      ) || this.selectedSubsystems.length === 0;
    return isAllSelected ? 'All' : this.selectedSubsystems.length.toString();
  }

  isAppSelected(name: string): boolean {
    return this.selectedApps.includes(name);
  }

  onAppChange(status: boolean, app: string): void {
    status
      ? (this.selectedApps = this.selectItem(app, this.selectedApps))
      : this.deselectItem(app, this.selectedApps);
    this.applicationChange.emit(this.selectedApps);
  }

  onSubsystemChange(status: boolean, subsystem: string): void {
    status
      ? (this.selectedSubsystems = this.selectItem(
          subsystem,
          this.selectedSubsystems,
        ))
      : this.deselectItem(subsystem, this.selectedSubsystems);

    this.subsystemChange.emit(this.selectedSubsystems);
  }

  open(): void {
    this.opened = true;
  }

  close(): void {
    this.opened = false;
  }

  isSubsystemSelected(name: string): boolean {
    return this.selectedSubsystems.includes(name);
  }

  private selectItem(item: string, array: string[]): string[] {
    array.push(item);
    return array;
  }

  private deselectItem(item: string, array: string[]): string[] {
    const index = array.indexOf(item);
    array.splice(index, 1);
    return array;
  }
}
