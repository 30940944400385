import { Injectable } from '@angular/core';
import { SamlService } from '@app/settings-common/shared/services/saml.service';
import { SamlConfigurationsModel } from '@app/settings/shared/models/samlConfigurationsModel';
import { ShDialogService } from '@app/shared/services/dialogService';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, flatMap, tap } from 'rxjs/operators';
import {
  GetSumlConfigurations,
  RemoveSamlToken,
  SaveSamlToken,
  SaveSumlConfigurations,
  UploadIdpMetadataFile,
  ToggleSamlMultipleTeams,
  FetchDefaultGroups,
  UpdateDefaultGroups,
} from './saml.action';

interface ISamlModel {
  configurations: SamlConfigurationsModel;
  loading: boolean;
  multiple_teams: boolean;
  defaultGroups: { [key: string]: number };
}

const initialState = (): ISamlModel => {
  return {
    configurations: null,
    loading: false,
    multiple_teams: false,
    defaultGroups: {},
  };
};

@State<ISamlModel>({
  name: 'saml',
  defaults: initialState(),
})
@Injectable()
export class SamlState {
  constructor(private samlService: SamlService, private dialogService: ShDialogService) {}

  @Selector()
  static loading(state: ISamlModel): boolean {
    return state?.loading;
  }

  @Selector()
  static configurations(state: ISamlModel): SamlConfigurationsModel {
    return state.configurations;
  }

  @Selector([SamlState.configurations])
  static configured(configurations: SamlConfigurationsModel): boolean {
    return configurations?.configured;
  }

  @Selector()
  static isMultipleTeams(state: ISamlModel): boolean {
    return state?.multiple_teams;
  }

  @Selector([SamlState.configurations])
  static isActive(configurations: SamlConfigurationsModel): boolean {
    return configurations?.is_active;
  }

  @Selector([SamlState.configurations])
  static teamEntityId(configurations: SamlConfigurationsModel): string {
    return configurations?.team_entity_id;
  }

  @Selector([SamlState.configurations])
  static token(configurations: SamlConfigurationsModel): string {
    return configurations?.token;
  }

  @Selector()
  static getDefaultGroupNames(state: ISamlModel): string[] {
    return Object.keys(state?.defaultGroups) || [];
  }

  @Action(GetSumlConfigurations)
  getSumlConfigurations(ctx: StateContext<ISamlModel>): Observable<ISamlModel> {
    ctx.patchState({ loading: true });
    return this.samlService.getSamlConfigurations().pipe(
      map(samlConfigurations => {
        return ctx.patchState({
          configurations: samlConfigurations,
        });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`ERROR: Failed to get saml configurations`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(SaveSumlConfigurations)
  saveSumlConfigurations(ctx: StateContext<ISamlModel>, { configurations }: SaveSumlConfigurations): Observable<SamlConfigurationsModel> {
    ctx.patchState({ loading: true });
    return this.samlService.saveSamlConfigurations(configurations).pipe(
      tap(samlConfigurations => {
        this.dialogService.showSnackBar(`Configurations saved successfully`, null, 5000);

        ctx.patchState({
          configurations: samlConfigurations,
        });
      }),
      catchError(() => {
        this.dialogService.showSnackBar(`ERROR: Failed to save saml configurations`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(UploadIdpMetadataFile)
  uploadIdpMetadataFile(ctx: StateContext<ISamlModel>, { file }: UploadIdpMetadataFile): Observable<ISamlModel> {
    ctx.patchState({ loading: true });
    const state = ctx.getState();
    const team_entity_id = state.configurations?.team_entity_id;
    const { multiple_teams } = state;

    return this.samlService.saveMetadataXML(file).pipe(
      flatMap(configurations => {
        if (multiple_teams && !team_entity_id) {
          return this.samlService.SaveCompanySamlTeamEntityId();
        }

        return of(configurations);
      }),
      map(configurations => {
        this.dialogService.showSnackBar(`File uploaded successfully`, null, 5000);

        return ctx.patchState({
          configurations,
        });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`ERROR: ${err?.error ? err.error : 'Failed to upload IdP metadata file'}`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(SaveSamlToken)
  saveSamlToken(ctx: StateContext<ISamlModel>): Observable<ISamlModel> {
    ctx.patchState({ loading: true });
    return this.samlService.SaveCompanySamlToken().pipe(
      tap(configurations => {
        ctx.patchState({
          configurations,
        });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`ERROR: Failed to Save Saml Token`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(RemoveSamlToken)
  removeSamlToken(ctx: StateContext<ISamlModel>): Observable<ISamlModel> {
    ctx.patchState({ loading: true });
    return this.samlService.RemoveCompanySamlToken().pipe(
      tap(configurations => {
        ctx.patchState({
          configurations,
        });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`ERROR: Failed to Remove Saml Token`, null, 5000);
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(ToggleSamlMultipleTeams)
  toggleMultipleTeams(ctx: StateContext<ISamlModel>): Observable<ISamlModel> {
    const current = ctx.getState().multiple_teams;
    ctx.patchState({ multiple_teams: !current });

    if (ctx.getState().configurations?.configured) {
      ctx.patchState({ loading: true });
      return this.samlService.SaveCompanySamlTeamEntityId().pipe(
        tap(configurations => {
          const currentMultipleTeams = ctx.getState().multiple_teams;

          ctx.patchState({
            configurations,
            multiple_teams: !currentMultipleTeams,
          });
        }),
        catchError(err => {
          this.dialogService.showSnackBar(`ERROR: Failed to Save Saml Team Entity ID`, null, 5000);
          return of(null);
        }),
        finalize(() => {
          ctx.patchState({ loading: false });
        }),
      );
    }

    return of(null);
  }

  @Action(FetchDefaultGroups)
  fetchDefaultGroups(ctx: StateContext<ISamlModel>): Observable<ISamlModel> {
    return this.samlService.getDefaultGroups().pipe(
      tap(res => {
        ctx.patchState({
          defaultGroups: res,
        });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`ERROR: Failed to fetch default groups`, null, 5000);
        return of(null);
      }),
    );
  }

  @Action(UpdateDefaultGroups)
  updateDefaultGroups(ctx: StateContext<ISamlModel>, { groups }: UpdateDefaultGroups): Observable<ISamlModel> {
    const groupStateIds = Object.values(ctx.getState().defaultGroups);
    const candidateGroupsIds = Object.values(groups);
    const groupsToDelete = groupStateIds.filter(grpId => !candidateGroupsIds.includes(grpId));
    const groupsToAdd = candidateGroupsIds.filter(grpId => !groupStateIds.includes(grpId));
    const groupManagement: { toDelete: number[]; toAdd: number[] } = {
      toDelete: groupsToDelete,
      toAdd: groupsToAdd,
    };
    return this.samlService.updateDefaultGroups(groupManagement).pipe(
      map(res => {
        this.dialogService.showCoralogixMessage('Update groups successfully');
        return ctx.patchState({
          defaultGroups: groups,
        });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(`ERROR: Failed to update default groups`, null, 5000);
        return of(null);
      }),
    );
  }
}
