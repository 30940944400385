export function sortArrayByPropName(arrayToSort: any[], propNames: string[], orders: SortOrders[]): any[] {
  if (Array.isArray(arrayToSort) && Array.isArray(propNames) && Array.isArray(orders)) {
    try {
      const sortedData = arrayToSort.sort((a, b) => {
        return propNames.reduce((acc, prop, i) => {
          return acc || (orders && orders[i] === 'desc' ? b[prop] - a[prop] : a[prop] - b[prop]);
        }, 0);
      });
      return sortedData;
    } catch (e) {
      return arrayToSort;
    }
  } else {
    return arrayToSort;
  }
}

export enum SortOrders {
  asc = 'asc',
  desc = 'desc'
}
