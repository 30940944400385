import { AfterViewInit, Component, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sh-coralogix-multiple-selection-menu',
  templateUrl: './coralogix-multiple-selection-menu.component.html',
  styleUrls: ['./coralogix-multiple-selection-menu.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CoralogixMultipleSelectionMenuComponent,
    multi: true
  }]
})
export class CoralogixMultipleSelectionMenuComponent implements ControlValueAccessor, AfterViewInit {
  @Input() set options(val: string[]) {
    this._options = val;
    this.filteredOptions = val;
    this.assignOptionsStatusObject();
    this.updateValue();
    this.assignSelectedValuesText();
  }
  @Input() public defaultValueIsNone: boolean;

  @Input() public widthLimited: boolean = true;

  @Input() public positionAbsolute: boolean = true;

  get options(): string[] {
    return this._options;
  }

  set values(val: string[]) {
    this._values = val ? val : [];
    this.assignSelectedValuesText();
    this.assignOptionsStatusObject();
  }

  get values(): string[] {
    return this._values;
  }

  private get hasAllOptionsSelected(): boolean {
    return this.values?.length === this.options?.length && this.options?.length > 1;
  }

  private get noOptionsSelected(): boolean {
    return !this.values?.length && this.options?.length > 1;
  }

  private get isValuesEmpty(): boolean {
    return !this.values?.length;
  }

  private get isOptionsEmpty(): boolean {
    return !this.options?.length;
  }

  private get isOptionsLengthEqualsToOne(): boolean {
    return this.options?.length === 1;
  }

  private get isValuesLengthEqualsToOne(): boolean {
    return this.values?.length === 1;
  }
  @ViewChild('menuTrigger') public menuTrigger: ElementRef<HTMLDivElement>;
  @ViewChild('menu') public menu: ElementRef<HTMLDivElement>;
  @ViewChild('selectionMenu') public selectionMenu: ElementRef<HTMLDivElement>;
  @Input() public label: string;
  @Input() public isInline: boolean = false;

  public readonly ALL: string = 'All';
  public readonly NONE: string = 'None';

  public optionsStatusDict: { [key: string]: boolean } = {};
  public filteredOptions: string[];
  public selectedValuesText: string;
  public isMenuOpen: boolean;
  public itemSize: number = 22;

  private _values: string[];
  private _options: string[];
  private _onChange: (val: any) => void;
  private _onTouched: (val: any) => void;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.isInline) {
      this.renderer.addClass(this.selectionMenu.nativeElement, 'inline-label');
    }
  }

  @HostListener('window:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (this.menuTrigger &&
      this.menu &&
      !this.menuTrigger.nativeElement.contains(targetElement) &&
      !this.menu.nativeElement.contains(targetElement)
    ) {
      if (!this.menuTrigger.nativeElement.contains(targetElement) && !this.menu.nativeElement.contains(targetElement)) {
        this.toggleMenu();
      }
    }
  }

  public registerOnChange(fn: (val: any) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: (val: any) => void): void {
    this._onTouched = fn;
  }

  public writeValue(selectedValues: string[]): void {
    this.values = selectedValues;
  }

  public get virtualScrollHeight(): number {
    return this.itemSize * this.filteredOptions.length + 10;
  }

  public selectOption(option: string): void {
    const optionIndex = this.values.findIndex((selectedOption: string) => selectedOption === option);
    if (optionIndex === -1) {
      this.values.push(option);
      this.optionsStatusDict[option] = true;
    } else {
      this.values.splice(optionIndex, 1);
      this.optionsStatusDict[option] = false;
    }
    this.assignSelectedValuesText();
    this._onChange(this.values);
  }

  public selectAllOptions(): void {
    this.values = [...this.options];
    this.assignGlobalOptionsStatus(true);
    this._onChange(this.values);
  }

  public unselectAllOptions(): void {
    this.values = [];
    this.assignGlobalOptionsStatus(false);
    this._onChange(this.values);
  }

  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    if (!this.isMenuOpen) {
      this.filteredOptions = [...this.options];
    }
  }

  public filterMenuOptions(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.filteredOptions = this.options?.filter((option: string) => {
      return option?.toLowerCase().includes(inputValue?.toLowerCase());
    });
  }

  public selectOnlyOneOption(event: Event, option: string): void {
    event.stopPropagation();
    if (this.values?.length === 1 && this.values[0] === option) {
      return;
    }
    this.values = [option];
    this.assignGlobalOptionsStatus(false);
    this.optionsStatusDict[option] = true;
    this._onChange(this.values);
  }

  private assignSelectedValuesText(): void {
    if (this.hasAllOptionsSelected) {
      this.selectedValuesText = this.ALL;
    } else if (this.noOptionsSelected) {
      this.selectedValuesText = this.defaultValueIsNone ? this.NONE : this.ALL;
    } else if (this.isValuesLengthEqualsToOne) {
      this.selectedValuesText = this.values[0];
    } else if (this.isValuesEmpty && this.isOptionsLengthEqualsToOne) {
      this.selectedValuesText = this.options[0];
    } else {
      this.selectedValuesText = `(${this.values?.length}) Selected`;
    }
  }

  private assignOptionsStatusObject(): void {
    this.optionsStatusDict = {};
    this.options?.forEach((option: string) => {
      this.optionsStatusDict = {
        ...this.optionsStatusDict,
        [option]: this.values?.includes(option)
      };
    });
  }

  private assignGlobalOptionsStatus(status: boolean): void {
    Object.keys(this.optionsStatusDict).forEach((key: string) => {
      this.optionsStatusDict[key] = status;
    });
  }

  private updateValue(): void {
    if (!this.isValuesEmpty && !this.isOptionsEmpty) {
      this.values = this.values?.filter((value: string) => {
        return this.options?.includes(value);
      });
    }
  }
}
