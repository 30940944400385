export class statisticsTrend {
  public statisticsBeforeTag: tagLogsStatistics;
  public statisticsAfterTag: tagLogsStatistics;

  get highSeverityTrend(): number {
    if (this.statisticsAfterTag.highSeverityPercent == 0) {
      return 0;
    }
    return (
      this.statisticsAfterTag.highSeverityPercent -
      this.statisticsBeforeTag.highSeverityPercent
    );
  }

  get highSeverityTrendAbs(): number {
    if (this.statisticsAfterTag.highSeverityPercent == 0) {
      return 0;
    }
    return Math.abs(
      this.statisticsAfterTag.highSeverityPercent -
        this.statisticsBeforeTag.highSeverityPercent,
    );
  }

  constructor() {
    this.statisticsBeforeTag = new tagLogsStatistics();
    this.statisticsAfterTag = new tagLogsStatistics();
  }
}

export class tagLogsStatistics {
  public totalLogs: number = 0;
  public totalHighSeverityLogs: number = 0;
  public totalCriticalSeverityLogs: number = 0;
  public totalErrorSeverityLogs: number = 0;
  public totalWarningSeverityLogs: number = 0;

  get highSeverityPercent(): number {
    if (this.totalLogs == 0) {
      return 0;
    }
    return this.totalHighSeverityLogs / this.totalLogs;
  }
}
