import { Pipe, PipeTransform } from '@angular/core';
import { Insight } from '../models/insight.model';
import { Anomaly } from '../models/anomaly.model';
import { Alert } from '../models/alert.model';
import { InsightsService } from '@app/insights/shared/services/insights-service';

@Pipe({
  name: 'InsightsTextFilter',
})
export class InsightsTextFilterPipe implements PipeTransform {

  public constructor(private insightsService: InsightsService) {

  }
  public transform(items: Insight[], args?: any): any {
    if (items) {
      if (args) {
        const subString = args.toLowerCase();
        const filteredInsights = items.filter(
          (insight) =>
            this.contains(this.insightsService.getInsightName(insight), subString) ||
            this.contains(insight.applicationName, subString) ||
            this.contains(insight.subsystemName, subString) ||
            this.containsInAnomalyShortId(insight, subString) ||
            this.containsInAlertsData(insight, subString),
        );

        return filteredInsights;
      }
      return items;
    }
    return null;
  }

  public contains(value: string, subString: string): boolean {
    if (value) {
      const valueToLower = value.toLowerCase();
      return valueToLower.indexOf(subString) !== -1;
    }
    return false;
  }

  public containsInAlertsData(insight: Insight, subString: string): boolean {
    const alert = insight as Alert;
    if (alert && (alert.computerName || alert.IPAddress)) {
      if (alert.computerName) {
        return alert.computerName.toLowerCase().indexOf(subString) !== -1;
      } else {
        return alert.IPAddress.toLowerCase().indexOf(subString) !== -1;
      }
    }
    return false;
  }

  public containsInAnomalyShortId(
    insight: Insight,
    subString: string,
  ): boolean {
    const anomaly = insight as Anomaly;
    if (anomaly && anomaly.shortId) {
      return anomaly.shortId.indexOf(subString) !== -1;
    }
    return false;
  }
}
