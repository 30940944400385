import { Directive, ElementRef, OnDestroy, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[timePicker-directive]',
})
export class TimePickerDirective implements OnDestroy, AfterViewInit {
  private lastValue;
  constructor(private el: ElementRef) {
    el.nativeElement.addEventListener('input', this.onInput.bind(this));
  }
  @HostListener('keyup', ['$event']) public onInputChange(event) {}
  public ngAfterViewInit() {
    const date = this.el.nativeElement.value.split(':');
    this.lastValue = parseInt(date[0]);
  }
  public ngOnDestroy() {
    this.el.nativeElement.removeEventListener('input', this.onInput.bind(this), false);
  }
  private onInput(e) {
    const date = this.el.nativeElement.value.split(':');
    const currentValue = parseInt(date[0]);
    if (currentValue == 0 && this.lastValue == 11) {
      this.el.nativeElement.value = '12' + ':' + date[1]?.toString() + ':' + date[2]?.toString();
      this.lastValue = 12;
      return;
    }
    if (currentValue == 12 && this.lastValue == 23) {
      this.el.nativeElement.value = '00' + ':' + date[1]?.toString() + ':' + date[2]?.toString();
      this.lastValue = 0;
      return;
    }
    if (currentValue == 23 && this.lastValue == 12) {
      this.el.nativeElement.value = '11' + ':' + date[1]?.toString() + ':' + date[2]?.toString();
      this.lastValue = 11;
      return;
    }
    if (currentValue == 11 && this.lastValue == 0) {
      this.el.nativeElement.value = '23' + ':' + date[1]?.toString() + ':' + date[2]?.toString();
      this.lastValue = 23;
      return;
    }
    this.lastValue = currentValue;
  }
}
