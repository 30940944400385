import { Component, Input } from '@angular/core';
import {
  subDays,
  startOfDay,
  addDays,
  addSeconds,
  subSeconds,
  isAfter,
  getTime
} from 'date-fns';
import { AnomalyService } from '../../shared/services/anomaly-service';
import { Anomaly } from '../../shared/models/anomaly.model';
import {
  VolumeAnomalyGraphResult,
  VolumeAnomalyQueryModel,
  VolumeAnomalyWidgetResult,
} from '../../shared/models/volume-anomaly-query.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sh-volume-anomaly',
  templateUrl: './volume-anomaly.component.html',
  styleUrls: ['./volume-anomaly.component.scss'],
})
export class VolumeAnomalyComponent {
  public anomalyWidgetData$: Observable<VolumeAnomalyWidgetResult>;
  public anomalyGraphData$: Observable<[VolumeAnomalyGraphResult, number]>;

  constructor(private anomalyService: AnomalyService) { }

  @Input() set Anomaly(anomaly: Anomaly) {
    const stepInSecods = 900;

    const widgetQueryModel = this.getSpikeAnomalyQueryModel(
      anomaly,
      anomaly.timestamp,
      anomaly.timestamp,
      stepInSecods
    );
    this.anomalyWidgetData$ = this.anomalyService.getVolumeAnomalyWidgetData(widgetQueryModel).pipe(take(1));

    const { start, end } = this.calcTimesForAnomalyGraph(anomaly, stepInSecods);

    const graphQueryModel = this.getSpikeAnomalyQueryModel(
      anomaly,
      start,
      end,
      stepInSecods
    );
    this.anomalyGraphData$ = Observable.zip(
      this.anomalyService.getVolumeAnomalyGraphData(graphQueryModel).first(),
      Observable.of(anomaly.timestamp)
    );
  }

  public getSpikeAnomalyQueryModel(
    anomaly: Anomaly,
    startTime: number,
    endTime: number,
    step: number = 900
  ): VolumeAnomalyQueryModel {
    const queryModel = new VolumeAnomalyQueryModel();
    queryModel.applicationName = anomaly.applicationName;
    queryModel.subsystemName = anomaly.subsystemName;
    queryModel.startTime = getTime(startTime);
    queryModel.endTime = getTime(endTime);
    queryModel.step = step;
    return queryModel;
  }

  private calcTimesForAnomalyGraph(anomaly: Anomaly, stepInSecods: number): { start: number, end: number } {
    const nextDay = addDays(startOfDay(anomaly.timestamp), 1).getTime();
    const maxEnd = Math.min(nextDay, Date.now());

    let graphEndTime = anomaly.timestamp;
    while (!isAfter(graphEndTime, maxEnd)) {
      graphEndTime = addSeconds(graphEndTime, stepInSecods).getTime();
    }
    if (isAfter(graphEndTime, maxEnd)) {
      graphEndTime = subSeconds(graphEndTime, stepInSecods).getTime();
    }

    const graphStartTime = subDays(graphEndTime, 1).getTime();

    return { start: graphStartTime, end: graphEndTime };
  }
}
