import { InsightSeverity } from './enums';
import { Metadata } from '../../../logs/shared/models/query-metadata';
export class insightsFilter {
  public severities: InsightSeverity[];
  public showAlerts: boolean;
  public showAnomalies: boolean;
  public showMutedInsights: boolean;
  public showActiveInsights: boolean;
  public queryMetadata: Metadata = new Metadata();

  get isUsingFiltersOn() {
    return !(
      this.showAlerts &&
      this.showAnomalies &&
      this.showActiveInsights &&
      this.severities.length == 3
    );
  }
}
