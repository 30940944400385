import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { UDIDHelper } from '@shared/services/udid.helper';
import { Constants } from '@app/constants';

@jsonObject
export class RuleMatcher {
  @jsonMember
  public constraint: string;
  @jsonMember
  public field: string;
}

@jsonObject
export class Rule {
  @jsonMember
  public id: string = '';
  @jsonMember
  public name: string = '';
  @jsonMember
  public description: string = '';
  @jsonMember
  public enabled: boolean = true;
  @jsonArrayMember(RuleMatcher)
  public ruleMatchers?: RuleMatcher[] = [];
  @jsonMember
  public rule: string = '';
  @jsonMember
  public sourceField: string = '';
  @jsonMember
  public destinationField: string = '';
  @jsonMember
  public replaceNewVal: string = '';
  @jsonMember
  public type: ERuleServerTypes = null;
  @jsonMember
  public order: number = 1;
  @jsonMember
  public keepBlockedLogs: boolean = false;

  constructor(data?: Partial<IRuleResponse>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

@jsonObject
export class RuleGroup {
  @jsonMember
  public id: string;
  @jsonMember
  public name: string;
  @jsonMember
  public order: number;
  @jsonMember
  public enabled: boolean = true;
  @jsonArrayMember(Rule)
  public rules: Rule[] = [];

  constructor(data?: IRuleGroupResponseData) {
    if (data) {
      this.name = data.name;
      this.id = data.id;
      this.rules = data.rules ? data.rules : [];
      this.order = data.order;
      this.enabled = data.enabled;
    }
  }
}

@jsonObject
export class ParsingTheme {
  @jsonMember
  public id: string = new UDIDHelper().generateShortUUID();
  @jsonMember
  public name: string = '';
  @jsonMember
  public description: string = '';
  @jsonMember
  public order: number;
  @jsonMember
  public enabled: boolean = true;
  @jsonMember
  public creator: string;
  @jsonArrayMember(RuleMatcher)
  public ruleMatchers: RuleMatcher[];
  @jsonMember
  public updatedAt: string;
  @jsonMember
  public createdAt: string;
  @jsonArrayMember(RuleGroup)
  public rulesGroups: IRuleGroupResponseData[];

  constructor(data?: Partial<IParsingTheme>) {
    if (data) {
      this.id = data.id || new UDIDHelper().generateShortUUID();
      this.name = data.name || '';
      this.description = data.description || '';
      this.order = data.order || 1;
      this.enabled = data.enabled || true;
      this.creator = data.creator || '';
      this.ruleMatchers = data.ruleMatchers || [];
      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
      this.rulesGroups = data.rulesGroups ? data.rulesGroups : [];
    }
  }
}

@jsonObject
export class ParsingThemesResponse {
  @jsonArrayMember(ParsingTheme)
  public parsingThemes: ParsingTheme[];
  @jsonMember
  public maxAllowedRules: number;
  @jsonMember
  public companyName: string;
  @jsonMember
  public companyId: number;
}

export interface IParsingThemesResponse {
  parsingThemes: ParsingTheme[];
  maxAllowedRules: number;
  companyId: number;
  companyName: string;
  QuotaRulesGroup: any;
  GrokIdentifiers: number;
}

export interface IRuleResponse {
  id: string;
  name: string;
  order: number;
  description: string;
  enabled: boolean;
  ruleMatchers?: RuleMatcher[];
  type: ERuleServerTypes;
  sourceField: string;
  destinationField: string;
  rule: string;
  replaceNewVal: string;
  keepBlockedLogs: boolean;
}

export interface IRuleGroupResponseData {
  id: string;
  name: string;
  rules: Rule[];
  order: number;
  enabled?: boolean;
  type?: EGroupServerTypes;
}

export interface IParsingTheme {
  id: string;
  name: string;
  description?: string;
  order: number;
  enabled: boolean;
  creator?: string;
  ruleMatchers: RuleMatcher[];
  updatedAt?: string;
  createdAt?: string;
  rulesGroups: IRuleGroupResponseData[];
}

export enum EGroupServerTypes {
  user = 1,
  quota = 2
}

export enum ERuleServerTypes {
  extract = 'extract',
  jsonExtract = 'jsonextract',
  parse = 'parse',
  replace = 'replace',
  allow = 'allow',
  block = 'block',
  timestampExtract = 'timestampextract',
  removeFields = 'removefields'
}

export const RuleMatcherFieldOptions = {
  applications: Constants.metadataFields()[0] || 'applicationName',
  subsystems: Constants.metadataFields()[1] || 'subsystemName',
  severities: 'severity',
};

export interface ISaveParsingThemeAction {
  parsingTheme: IParsingTheme;
  actionType: RulesGroupSaveTypes;
}

export enum RulesGroupSaveTypes {
  DISABLE,
  ENABLE,
  SAVE
}

export interface IToggleParsingThemeAction {
  parsingThemeId: string;
  toggleState: boolean;
}

@jsonObject
export class ExtendedUniqueFields {
  @jsonMember({ name: 'company_id', constructor: String })
  public companyId: string;
  @jsonMember
  public status: number;
  @jsonArrayMember(String, { name: 'unique_fields' })
  public uniqueFields: string[];
}
