import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { DesktopNavbarModule } from './desktop-navbar/desktop-navbar.module';
import { MobileNavbarModule } from './mobile-navbar/mobile-navbar.module';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    DesktopNavbarModule,
    MobileNavbarModule
  ],
  exports: [NavbarComponent]
})
export class NavbarModule { }
