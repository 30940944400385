import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
  FormBuilder,
  FormGroup, ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateHelper } from '@shared/services/date.helper';
import { DateValidator } from '@shared/validators/dates-delta.validator';
import { Build } from '../../models/build';
import { localDateToUtcDate, MomentTimeFormatTypes, utcDateToLocalDate } from '@shared/helpers/moment.helper';
import { ITagFormInitialData } from '@app/deployment/shared/models/view.models';

@Injectable()
export class ManualEventFormProviderService {
  public manualEventForm: FormGroup;

  constructor(private formsBuilder: FormBuilder) {
  }

  public generateNewFormWithDefaultValues(isLocalTime: boolean, minDate: Date): void {
    this.manualEventForm = this.generateNewForm(this.getDefaultValues(isLocalTime), isLocalTime, minDate);
  }

  public generateNewForm(values: Partial<ITagFormInitialData>, isLocalTime: boolean, minDate: Date): FormGroup {
    const application_name = values.application_name ? values.application_name?.split(',') : null;
    const subsystem_name = values.subsystem_name ? values.subsystem_name?.split(',') : null;
    return this.formsBuilder.group({
      id: [values.id],
      company_id: [values.company_id, Validators.required],
      private_key: [values.private_key, Validators.required],
      text_key: [values.text_key],
      text_value: [values.text_value, Validators.required],
      avatar_url: ['/assets/deployment-icons/event.png', Validators.required],
      application_name: [
        application_name,
      ],
      subsystem_name: [subsystem_name],
      tag_timestamp: [values.tag_timestamp, Validators.required],
      ts_group: this.formsBuilder.group(
        {
          build_timestamp_date: [
            values.build_timestamp_date,
            Validators.required,
          ],
          build_timestamp_time: [
            values.build_timestamp_time,
            Validators.required,
          ],
        },
        {
          validator: this.getTimeValidators(isLocalTime, minDate, values.tag_timestamp),
        },
      ),
    });
  }

  public resetForm(isLocalTime: boolean, minDate: Date): void {
    this.generateNewFormWithDefaultValues(isLocalTime, minDate);
  }

  public updateForm(tag: Build, isLocalTime: boolean, minDate: Date): void {
    const updatedDate = isLocalTime ? moment(tag.tag_timestamp) : localDateToUtcDate(new Date(tag.tag_timestamp));
    const values: Partial<ITagFormInitialData> = {
      id: tag.id,
      company_id: tag.company_id,
      private_key: 'NONE',
      text_key: tag.text_key,
      text_value: tag.text_value,
      application_name: tag.application_name,
      subsystem_name: tag.subsystem_name,
      tag_timestamp: updatedDate.toDate(),
      build_timestamp_date: updatedDate.toDate(),
      build_timestamp_time: updatedDate.format(MomentTimeFormatTypes.standard),
    };
    this.manualEventForm = this.generateNewForm(values, isLocalTime, minDate);
  }

  public setTimeStamp(isLocalTime: boolean): void {
    const timeStamp = DateHelper.mergeDateAndTime(
      (this.manualEventForm.controls['ts_group'] as FormGroup).controls[
        'build_timestamp_date'
      ].value,
      (this.manualEventForm.controls['ts_group'] as FormGroup).controls[
        'build_timestamp_time'
      ].value,
    );

    const unixStamp = (isLocalTime ? moment(timeStamp) : utcDateToLocalDate(moment(timeStamp).toDate())).unix() * 1000;
    this.manualEventForm.controls['tag_timestamp'].setValue(unixStamp);
  }

  private getTimeValidators(isLocalTime: boolean, minDate: Date, timestamp: Date): ValidatorFn[] {
    const validators = [
      DateValidator.checkLessThanNow(
      'build_timestamp_date',
      'build_timestamp_time',
      isLocalTime
    )];
    const isDateAboveMinValue = minDate?.valueOf() <= timestamp.valueOf();
    if (isDateAboveMinValue) {
      validators.push(DateValidator.checkDeltaFromNow(
        'build_timestamp_date',
        'build_timestamp_time',
        minDate,
        isLocalTime));
    }
    return validators;
  }

  private getDefaultValues(isLocalTime: boolean): Partial<ITagFormInitialData> {
    const updatedDate = isLocalTime ? moment() : localDateToUtcDate(new Date());
      return {
      id: '',
      company_id: -1,
      private_key: '',
      text_key: 'Manual event',
      text_value: '',
      application_name: null,
      subsystem_name: null,
      tag_timestamp: updatedDate.toDate(),
      build_timestamp_date: updatedDate.toDate(),
      build_timestamp_time: updatedDate.format(MomentTimeFormatTypes.standard),
    };
  }
}
