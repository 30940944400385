import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Build } from '../../models/build';

@Component({
  selector: 'sh-toggle-compare-tag',
  templateUrl: './toggle-compare-tag.component.html',
  styleUrls: ['./toggle-compare-tag.component.scss'],
})
export class ToggleCompareTagComponent implements OnInit {
  @Input() public tagId: number;
  @Input() public stateLeft: boolean;
  @Input() public stateRight: boolean;

  @Output() public toggleCompareClicked = new EventEmitter<any>();

  constructor() {}

  public click(event, side: string) {
    event.stopPropagation();
    this.toggleCompareClicked.emit(side);
  }

  public ngOnInit() {}
}
