import { PolicyStatus } from '@app/tco/enums/policy-status.enum';
import { Priority } from '@app/tco/enums/priority.enum';
import { ISetOverridesResponse } from '@app/tco/interfaces/set-overrides-response.interface';
import { PolicyOverride } from '@app/tco/models/policy-override.model';
import { SortActiveType } from './quota-policy-overrides.state';

export class Destroy {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Destroy';
}

export class Loading {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Loading';
    constructor(public loading: boolean) { }
}

export class UpdateSearchTerm {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Change Search Term';
    constructor(public searchTerm: string) { }
}

export class UpdateSelectedPriorities {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Update Selected Priority';
    constructor(public selectedPriorities: Priority[]) { }
}

export class UpdateSelectedPolicyStatus {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Update Selected Policy Status';
    constructor(public selectedPolicyStatus: PolicyStatus[]) { }
}

export class UpdateAppNames {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Update Selected App Names';
    constructor(public selectedApplicationNames: string[]) { }
}

export class UpdateSubNames {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Update Selected Subsystem Names';
    constructor(public selectedSubsystemNames: string[]) { }
}

export class TogglePolicyOverrides {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Toggle Policy Overrides';
}

export class GetOverrides {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Get Overrides';
}

export class EditOverride {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Edit Override';
    constructor(public policyOverride: PolicyOverride) { }
}

export class CancelEditOverride {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Cancel Edit Override';
}

export class UpdatePaginationProps {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Update Pagination Props';
    constructor(public pageIndex: number, public pageSize: number) { }
}

export class UpdateSortActive {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Update Sort Active';
    constructor(public sortActive: SortActiveType) { }
}

export class SetEmptyStateAvailable {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Set Empty State Available';
    constructor(public available: boolean) { }
}

export class SetOverride {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Set Override';
    constructor(public override: PolicyOverride) { }
}

export class ResetAllOverridesSuccess {
    public static readonly type: string = '[QUOTA POLICY OVERRIDES] Reset All Overrides Success';
    constructor(public response: ISetOverridesResponse[]) { }
}
