<div style="display: flex">
  <i class="fa fa-terminal terminal-icon" style="width: 30px"></i>
  <div class="grep-topbar">
    <div class="grep-toolbar">
      <input
        placeholder="| grep / Exact text / Regex"
        type="text"
        (keyup.enter)="onGrepInput(grepInput.value)"
        class=" ui icon input grep-input"
        #grepInput
      />
    </div>
    <div class="actions-toolbar">
      <button mat-button (click)="onFiltersPanelButtonClicked()">
        <i class="fa fa-list-alt" aria-hidden="true"></i>
        Choose fields
      </button>
      <button mat-button (click)="onPrettify()">
        <i class="fa fa-code" aria-hidden="true"></i>
        {{isPrettified ? 'Unprettify' : 'Prettify'}}
      </button>
      <button
        *ngIf="!isConnected"
        mat-button
        (click)="onGrepInput(grepInput.value)"
      >
        <i class="fa fa-play" aria-hidden="true"></i>
        Start
      </button>
      <button *ngIf="isConnected" mat-button (click)="onStop()">
        <i class="fa fa-pause" aria-hidden="true"></i>
        Pause
      </button>
      <button mat-button (click)="onClear()">
        <i class="fa fa-trash" aria-hidden="true"></i>
        Clear
      </button>
    </div>
  </div>
</div>
