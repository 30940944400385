import { Component } from '@angular/core';
import { IRegexTableData } from '@shared/tutorials/regex-cheat-sheet/models/regex-table-row.models';
import regexTableData from '@shared/tutorials/regex-cheat-sheet/assets/regex-cheat-sheet.json';

@Component({
    selector: 'sh-regex-cheat-sheet-table',
    templateUrl: './regex-cheat-sheet-table.component.html',
    styleUrls: [ './regex-cheat-sheet-table.component.scss' ],
})
export class RegexCheatSheetTableComponent {
  public tableData: IRegexTableData = regexTableData as IRegexTableData;

  public scrollToSection(sectionId: string): void {
   const section = document.getElementById(sectionId);
   section.scrollIntoView({ behavior: 'smooth' });
  }
}
