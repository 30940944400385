import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Log } from '../shared/models/log.entity';
import { LogsViewStateProvider } from '../shared/services/logsViewState.provider';
import { LogsQuerFormProvider } from '../logs-query/logs-query-form.provider';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';
import { SaveAlertFromQueryConfig } from '@app/logs/logs-save-menu/models/logs-save-menu-config.model';
import { EQueryTypesOptions } from '@app/logs/shared/models/query-types.options';
import { ILogButtonAction } from '@app/logs/shared/models/log-info-panel-view.models';

@Component({
  selector: 'sh-logs-action-buttons',
  templateUrl: 'logs-action-buttons.component.html',
  styleUrls: ['logs-action-buttons.component.scss'],
})
export class LogsActionButtonsComponent {
  @Input() public selectedLog: Log | LoggregationEntity;
  @Output() public logActionPerformed: EventEmitter<ILogButtonAction> = new EventEmitter<ILogButtonAction>();
  @Output() public saveAlert: EventEmitter<SaveAlertFromQueryConfig> = new EventEmitter<SaveAlertFromQueryConfig>();

  constructor(
    private logsFormProvider: LogsQuerFormProvider,
    private logsViewStateProvide: LogsViewStateProvider,
  ) {}

  public queryText(log: Log | LoggregationEntity): void {
    const logSelection: string = this.getTextSelection();
    logSelection
      ? this.logsFormProvider.setText(logSelection)
      : this.logsFormProvider.setText(log.text);

    this.logsViewStateProvide.viewState.isQueryVisible = true;
    this.emitLogAction(EQueryTypesOptions.FREE);
  }

  public queryTemplate(log: Log | LoggregationEntity): void {
    this.logsFormProvider.setTemplate(log);
    this.logsViewStateProvide.viewState.isQueryVisible = true;
    this.emitLogAction(EQueryTypesOptions.TEMPLATE);
  }

  public defineAlertOnText(selectedLog: Log | LoggregationEntity): void {
    const config = new SaveAlertFromQueryConfig({
      text: this.getTextSelection().length > 0 ? this.getTextSelection() : this.selectedLog.text,
    });
    this.saveAlert.emit(config);
  }

  public getTextSelection(): string {
    const selection = window.getSelection();
    let s = '';
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0).cloneContents();
      for (let i = 0; i < range.childNodes.length; i++) {
        s += range.childNodes[i].textContent;
      }
    }
    return s;
  }

  private emitLogAction(logType: EQueryTypesOptions): void {
    this.logActionPerformed.emit({
      type: logType,
      text: this.logsFormProvider.getModel().queryParams.query.text
    });
  }
}
