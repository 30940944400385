import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-summary-severity-list',
  templateUrl: './summary-severity-list.component.html',
  styleUrls: ['./summary-severity-list.component.scss'],
})
export class SummarySeverityListComponent {
  @Input() public listSummary: ISeveritySummaryItem[];
  @Input() public listLength: number = 7;
}

export interface ISeveritySummaryItem {
  name: string;
  color: string;
  size: number;
}
