import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ITestParsingThemeRuleResponse } from '@app/rules/shared/models/sample-log-preview.models';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'sh-sample-log-preview',
  templateUrl: './sample-log-preview.component.html',
  styleUrls: ['./sample-log-preview.component.scss'],
})
export class SampleLogPreviewComponent implements AfterViewInit {
  @Input() public sampleForBlockRule: boolean = false;
  @Input() public sampleForAllowRule: boolean = false;
  @ViewChild('matExpansionPanel') public expandPanel: MatExpansionPanel;
  _samplePreviewData: ITestParsingThemeRuleResponse = null;

  constructor(private cdr: ChangeDetectorRef) {}

  @Input()
  set samplePreviewData(data: ITestParsingThemeRuleResponse) {
    if (data) {
      this._samplePreviewData = data;
      this.openPanelIfNeeded();
    }
  }

  get samplePreviewData(): ITestParsingThemeRuleResponse {
    return this._samplePreviewData;
  }

  ngAfterViewInit(): void {
    this.openPanelIfNeeded();
  }

  openPanelIfNeeded(): void {
    if (
      !!this.expandPanel &&
      ((this.samplePreviewData?.match && !this.sampleForBlockRule) || (!this.samplePreviewData?.match && this.sampleForBlockRule))
    ) {
      this.expandPanel?.open();
      this.cdr.detectChanges();
    }
  }
}
