import { Metadata } from '../../logs/shared/models/query-metadata';
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';
import { TimeZoneType } from '@shared/models/timezone-types';
import { DateFormatType } from '@shared/models/date-format-types';
import { StripeUserData } from '@shared/models/stripe-user-data';

@jsonObject
export class UserSettings {
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public livetailFilteredKeys?: any[] = [];
  @jsonMember
  public queryMetadata: Metadata = new Metadata();
  @jsonMember
  public logsDefaultGridViewId: number = -1;
  @jsonMember
  public apiKey: string;
  @jsonMember
  public teamsApiKey: string;
  @jsonMember
  public esApiKey: string;
  @jsonMember
  public timeZoneViewType: TimeZoneType;
  @jsonMember
  public logsTheme: 'dark' | 'light';
  @jsonMember
  public dateFormatType: DateFormatType;
  @jsonMember
  public showAllWidgets: boolean = true;
  @jsonMember
  public showTagsInLogsGraph: boolean = true;
  @jsonMember
  public stripeData: StripeUserData;
}
