import { addHours, addMinutes, differenceInMinutes, subDays } from 'date-fns';
import { DatepickerOptions } from '@shared/controls/sh-ng-datepicker/ng-datepicker.component';
import { MaxAllowedRequestHours, MaxAllowedRequestMinutes } from '@app/deployment/shared/consts/tags.consts';
import { Build } from '@app/deployment/models/build';
import { ITagRequest } from '@app/deployment/shared/models/summary.models';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { LogSeveritiesEnum } from '@app/logs/shared/models/log-severities.enum';

export function getUpdatedDateOptions(daysForMinDate: number): DatepickerOptions {
  const dayForViewSetting = 1;
  return {
    maxDate: new Date(),
    minDate: subDays(new Date(), daysForMinDate + dayForViewSetting),
  };
}

export function getArrayFromStringList(list: string): string[] {
  return !!list ? list.split(',') : [];
}

export function getTagEndTimeByTagsList(tag: Build, tags: Build[]): number {
  if (!tag || !tags) {
    return 0;
  }
  const buildF = tags
    .filter((build) => build.application_name === tag.application_name)
    .filter((build) => build.subsystem_name === tag.subsystem_name);
  const index = buildF?.findIndex(item => item.id === tag.id);

  const nextItem = index > 0 && Array.isArray(tags) ? tags[index - 1] : null;

  if (!!nextItem) {
    const diff = Math.abs(differenceInMinutes(tag.tag_timestamp, nextItem.tag_timestamp));
    if (diff <= MaxAllowedRequestMinutes) {
      // smaller than a day
      return addMinutes(tag.tag_timestamp, diff).valueOf();
    }
    return addHours(tag.tag_timestamp, MaxAllowedRequestHours).valueOf();
  } else {
    // if the item is the first item
    const now = new Date().valueOf();
    const diff = Math.abs(differenceInMinutes(tag.tag_timestamp, now));
    if (diff <= MaxAllowedRequestMinutes) {
      return addMinutes(tag.tag_timestamp, diff).valueOf();
    }
    return addHours(tag.tag_timestamp, MaxAllowedRequestHours).valueOf();
  }
}

export function getLogsBaseQueryModel(tag: ITagRequest, cachedQueryId: string): LogQueryModel {
  const model: LogQueryModel = new LogQueryModel();
  const { startTime, applicationName, subsystemName, endTime } = tag;
  model.endDate = endTime;
  model.startDate = startTime;
  model.queryParams.metadata.applicationName = applicationName;
  model.queryParams.metadata.subsystemName = subsystemName;
  model.queryParams.aggregationInterval = 3600 * 1000;
  model.type = QueryTypes.FREE;
  model.cacheQueryId = cachedQueryId;
  model.queryParams.metadata.severity = [LogSeveritiesEnum.ERROR, LogSeveritiesEnum.CRITICAL];
  return model;
}
