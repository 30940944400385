import { DOWN_ARROW, ENTER, ESCAPE, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Nunjucks } from '@app/shared/models/nunjucks';
import { trim } from 'lodash-es';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sh-url-autocomplete',
  templateUrl: './url-autocomplete.component.html',
  styleUrls: ['./url-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('urlInput') urlInput: ElementRef<HTMLInputElement>;

  @ViewChild('urlAutocompleteTrigger') urlAutocompleteTrigger: MatAutocompleteTrigger;

  @Input() options: string[];

  @Input() urlControl: FormControl;

  @Input() isDisabled: boolean;

  doubleCurlyBrackets = /{{[^{}]*}}/g;

  inputAutocompleteControl = new FormControl();

  insideAutocomplete$ = new BehaviorSubject(false);

  autocompletSelected$ = new BehaviorSubject(false);

  searchTerm$ = new BehaviorSubject('');

  filteredOptions$: Observable<string[]> = of([]);

  slicedOptions$: Observable<string[]> = of([]);

  lastSelectionStart: number = null;

  lastSelectionEnd: number = null;

  destoryed$ = new Subject();

  constructor(@Inject(DOCUMENT) public document: Document) {}

  @Input() set closePanel(dummyTime: Date) {
    if (this.urlAutocompleteTrigger) {
      this.urlAutocompleteTrigger.closePanel();
    }
  }

  get urlControlErrorsKeyList(): string[] {
    return Object.keys(this.urlControl.errors || {});
  }

  ngOnInit(): void {
    this.onInputAutocompleteChanges();

    this.filteredOptions$ = this.searchTerm$.pipe(
      map(term => {
        const options = this.options?.filter(option => option.toLowerCase().includes(term.toLowerCase())) || [];
        return options;
      }),
      takeUntil(this.destoryed$),
    );

    this.slicedOptions$ = this.filteredOptions$.pipe(map(options => options.slice(0, 10)));
  }

  ngOnDestroy(): void {
    this.destoryed$.next();
    this.destoryed$.complete();
  }

  onInputAutocompleteChanges(): void {
    this.inputAutocompleteControl.valueChanges.pipe(takeUntil(this.destoryed$)).subscribe(content => {
      const input = this.urlInput.nativeElement;
      this.applyAutoComplete(input, content);
    });
  }

  onKeydownEvent(e: KeyboardEvent): void {
    if (this.insideAutocomplete$.value) {
      if ([DOWN_ARROW, ENTER, ESCAPE, TAB, UP_ARROW].includes(e.keyCode)) {
        e.preventDefault();
        this.urlAutocompleteTrigger._handleKeydown(e);
      }
    }
  }

  errorMsg(): string {
    const errorObjMessage = {
      required: 'Required',
      nunjucksColumn: this.urlControl.errors?.nunjucksColumn,
      nunjucksExpectedVariableEnd: this.urlControl.errors?.nunjucksExpectedVariableEnd,
      nunjucksEndOfFile: this.urlControl.errors?.nunjucksEndOfFile,
      nunjucksUnknown: this.urlControl.errors?.nunjucksUnknown,
      maxlength: 'Max length of 32768',
    };

    if (this.urlControl.errors) {
      return errorObjMessage[Object.keys(this.urlControl.errors)[0]];
    }
    return '';
  }

  onKeyupEvent(e: KeyboardEvent): void {
    const input: HTMLInputElement = e.target as HTMLInputElement;

    this.updateLastPositionCaret(input);
    this.handleDoubleCurlyBrackets(e);
    this.onCaretPositionChange(e);
  }

  onCaretPositionChange(e: KeyboardEvent | MouseEvent): void {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    this.updateLastPositionCaret(input);

    const { value, selectionStart, selectionEnd } = input;
    const isInside = Nunjucks.isInside(value, selectionStart, selectionEnd);
    this.insideAutocomplete$.next(isInside);
    if (isInside && !this.autocompletSelected$.value) {
      setTimeout(() => {
        this.urlAutocompleteTrigger.openPanel();
        const searchTerm = Nunjucks.getCurrentTokenValue(value, selectionStart, selectionEnd);
        this.searchTerm$.next(trim(searchTerm));
      }, 0);
    } else {
      this.autocompletSelected$.next(false);
      this.urlAutocompleteTrigger.closePanel();
    }
  }

  updateLastPositionCaret(input: HTMLInputElement): void {
    const { selectionStart, selectionEnd } = input;
    this.lastSelectionStart = selectionStart;
    this.lastSelectionEnd = selectionEnd;
  }

  getContentInsideDoubleCurlyBrackets(input: HTMLInputElement): string {
    const { value, selectionStart, selectionEnd } = input;

    const matches = value.matchAll(this.doubleCurlyBrackets);
    for (const match of matches) {
      if (selectionStart >= match.index + 2 && selectionEnd <= match.index + match[0].length - 2) {
        return match[0].substring(2, match[0].length - 2);
      }
    }
    return null;
  }

  applyAutoComplete(input: HTMLInputElement, content: string): void {
    const { value, selectionStart, selectionEnd } = input;
    const token = Nunjucks.getCurrentToken(value, selectionStart, selectionEnd);
    const newCaretPosition = (value.substring(0, token.start) + content).length;
    input.value = value.substring(0, token.start) + content + value.substring(token.end, value.length);
    this.urlControl.patchValue(input.value);
    setTimeout(() => {
      input.focus();
      input.selectionStart = newCaretPosition;
      input.selectionEnd = newCaretPosition;
      this.updateLastPositionCaret(input);
      this.autocompletSelected$.next(true);
    }, 0);
  }

  handleDoubleCurlyBrackets(e: KeyboardEvent): void {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    if (e.key === '{') {
      const caret = input.selectionStart;
      const str = input.value.substring(caret - 2, caret);
      if (str === '{{') {
        const firstPart = input.value.substring(0, caret);
        const lastPart = input.value.substring(caret, input.value.length);
        input.value = `${firstPart}}}${lastPart}`;
        this.urlControl.patchValue(input.value);
        input.selectionStart = caret;
        input.selectionEnd = caret;
      }
    }
  }
}
