import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'sh-severity-renderer',
  template: `
    <div class="severity" *ngIf="value" [class]="value.toLowerCase()">
      {{ value }}
    </div>
  `,
  styleUrls: ['severity-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeverityRendererComponent implements ICellRendererAngularComp {
  public value: string = '';
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
  }

  public refresh(params: any): boolean {
    return true;
  }
}
