import { NgModule } from '@angular/core';
import { ConditionalSelectComponent } from '@shared/components/conditional-select/conditional-select.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CgxCheckboxModule } from '@coralogix/design';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EnumTranslatorModule } from '@shared/pipes/enum-translator/enum-translator.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [ConditionalSelectComponent],
  imports: [
    CommonModule,
    MatIconModule,
    CgxCheckboxModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    EnumTranslatorModule,
    MatMenuModule,
  ],
  exports: [ConditionalSelectComponent],
})
export class ConditionalSelectModule {}
