<div *ngIf="deletedId == _build?.id">
  <h2 class="title" style="text-align: center;">Tag removed</h2>
  <span class="deleted">Tag "{{ _build.text_value }}" was deleted</span>
</div>
<div *ngIf="viewState === completedViewState" fxLayout="column" fxLayoutGap="37px" [class.hidden]="deletedId == _build?.id">
  <sh-summary-tiles></sh-summary-tiles>
  <sh-tag-error-volume></sh-tag-error-volume>
  <sh-alerts-summary></sh-alerts-summary>
  <sh-error-anomalies></sh-error-anomalies>
  <sh-newly-introduced-logs data-html2canvas-ignore="true"></sh-newly-introduced-logs>
  <div>
    <div class="selected-tag-box__title">Custom Widgets</div>
    <mat-card class="selected-tag-box__card">
      <div class="row dark">
        <div *ngFor="let widget of customChartWidgets" class="column custom widget-card-wrap">
          <div class="dashboard-card border">
            <div class="widget-header" fxLayout="row" fxLayoutAlign="space-between end">
              <div>
                <div class="widget-header__title">
                  {{ widget.title }}
                </div>
                <div class="widget-header__sub-title" *ngIf="widget.description">
                  {{ widget.description }}
                </div>
              </div>
              <sh-action-menu
                class="widget-header__menu"
                [menuImg]="menuImg"
                (deleteAction)="deleteWidgetYesNo.showModal(widget)"
                (editAction)="showWidget(widget)"
              ></sh-action-menu>
            </div>
            <div [ngSwitch]="widget.url">
              <div *ngSwitchCase="'statistics/logs'">
                <sh-compare-line-graph-widget [queryDefinition]="widget.statisticsQueryDefinition"></sh-compare-line-graph-widget>
              </div>
              <div *ngSwitchCase="'statistics/logs1'">
                <sh-basic-statistic-chart #customChart2 [queryDefinition]="widget.statisticsQueryDefinition"></sh-basic-statistic-chart>
              </div>
              <div *ngSwitchCase="'loggregation/parameter'">
                <sh-templates-charts #templateChart [loggregationQuery]="widget.queryRequest"></sh-templates-charts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <sh-top-error-templates></sh-top-error-templates>
</div>

<sh-selected-tag-feature-layout [viewState]="viewState"></sh-selected-tag-feature-layout>

<sh-yes-no-modal #deleteWidgetYesNo (onAnswer)="onDeleteWidget($event)" [message]="'Are you sure?'"></sh-yes-no-modal>
