import { Component, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import {
  ArchiveQueryDownloadTsvParams,
  ArchiveQueryPreviewGridParams,
  ArchiveQueryPreviewParams,
  EArchiveQueryState,
  IArchiveQuery,
  IFile,
  ILog,
  IUiState
} from '@app/roi/archive-queries/models/archive-query.model';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { ArchiveQueryActions } from '@app/roi/archive-queries/state/actions/archive-query.actions';
import { MatDialog } from '@angular/material/dialog';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';
import { ShDialogService } from '@shared/services/dialogService';
import { Estimation, ReindexRequest } from '@app/roi/reindex/models/reindex.model';
import { DownloadResultsFormComponent } from '@app/roi/archive-queries/components/download-results-form/download-results-form.component';
import { ArchiveQueryLogsPreviewComponent } from '@app/roi/archive-queries/components/logs-preview/archive-query-logs-preview.component';
import { filter, take } from 'rxjs/operators';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { arch } from 'os';
import { LogsPreviewGridComponent } from '@app/roi/archive-queries/components/logs-preview-grid/\logs-preview-grid.component';

@Component({
  selector: 'sh-archive-queries-table-container',
  templateUrl: './archive-queries-table-container.component.html',
  styleUrls: ['./archive-queries-table-container.component.scss']
})
export class ArchiveQueriesTableContainerComponent {

  @Input()
  public archiveQueries: IArchiveQuery[];
  @Input()
  public uiState: IUiState;

  constructor(private store: Store<State>,
              private archiveQueryService: ArchiveQueriesService,
              private dialogService: ShDialogService,
              private dialog: MatDialog,
  ) { }

  public getTsvs(archiveQueryId: string): void {
    const data: ICoralogixDialogData & ArchiveQueryDownloadTsvParams = {
      title: 'Archive preview logs',
      message: '',
      yesButtonText: 'CONFIRM',
      noButtonText: 'CLOSE',
      id: archiveQueryId
    };
    const dialogRef = this.dialog.open(DownloadResultsFormComponent, { data });
  }

  public reindex(archiveQuery: IArchiveQuery): void {
    this.store.dispatch(new ArchiveQueryActions.ActivateLoadingAction());
    this.archiveQueryService
      .createArchiveQueryReindexEstimation(archiveQuery.id, archiveQuery.name)
      .subscribe(
        (res: Estimation) => {
          if (res.estimation !== null) {
            this.estimationModalDisplay(res, archiveQuery);
          }
        }
      );
  }

  public showPreviewLog(previewParams: ArchiveQueryPreviewParams): void {
    this.openLogsPreview(previewParams);
  }

  public showPreviewGrid(previewParams: ArchiveQueryPreviewGridParams): void {
    this.openPreviewGrid(previewParams);
  }

  public showLogsScreen(archiveQuery: IArchiveQuery): void {
    this.store.dispatch(new ArchiveQueryActions.CreateCachedQueryAction(archiveQuery));
  }

  public showFailCause(archiveQuery: IArchiveQuery): void {
    const failedLog: ILog = archiveQuery.log.filter(log => log.action === EArchiveQueryState.failed).pop();
    const data: ICoralogixDialogData = {
      title: `Query Failed`,
      message: failedLog?.cause ? failedLog.cause : `Please refine your query and try again`,
      yesButtonText: 'Close'
    };
    this.dialog.open(CoralogixConfirmationDialogComponent, { data });
  }

  private estimationModalDisplay(estimationResponse: Estimation, archiveQuery: IArchiveQuery): void {
    const { estimation } = estimationResponse;
    const sizeEstimation = estimation === 0 ? '~0' : estimation;
    this.openEstimationDialog(sizeEstimation, archiveQuery);
    this.store.dispatch(new ArchiveQueryActions.ClearLoadingAction());
  }

  private openEstimationDialog(sizeEstimation: any, archiveQuery: IArchiveQuery): void {
    const data: ICoralogixDialogData = {
      title: 'This will affect your daily quota',
      message:
        `This reindexing action will decrease your daily quota by an estimated ${sizeEstimation}MB. Click “confirm” ` +
        `to reindex your data.`,
      yesButtonText: 'CONFIRM',
      noButtonText: 'CANCEL',
    };
    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data });
    dialogRef.afterClosed().pipe(
      take(1),
      filter(isConfirmed => isConfirmed)
    ).subscribe(() => {
        this.store.dispatch(new ArchiveQueryActions.CreateReindexAction(archiveQuery));
    });
  }

  private openPreviewGrid(previewParams: ArchiveQueryPreviewGridParams): void {
    const data: ICoralogixDialogData & ArchiveQueryPreviewGridParams = {
      title: 'Archive preview logs',
      message: '',
      yesButtonText: 'CONFIRM',
      noButtonText: 'CLOSE',
      id: previewParams.id
    };
    const dialogRef = this.dialog.open(LogsPreviewGridComponent, { data });
  }
  private openLogsPreview(previewParams: ArchiveQueryPreviewParams): void {
    const data: ICoralogixDialogData & ArchiveQueryPreviewParams = {
      title: 'Archive preview logs',
      message: '',
      yesButtonText: 'CONFIRM',
      noButtonText: 'CLOSE',
      id: previewParams.id,
      filterType: previewParams.filterType,
      filter: previewParams.filter
    };
    const dialogRef = this.dialog.open(ArchiveQueryLogsPreviewComponent, { data });
  }
}
