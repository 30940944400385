<mat-card class="coralogix" *ngIf="form">
  <p class="mat-card-header">Details</p>
  <form [formGroup]="form">
    <div class="coralogix input-group">
      <label class="input-label">Alert Name</label>
      <div class="has-validation" [class.coralogix-input-error]="hasError('alertName', 'required')">
        <input type="text" placeholder="Enter a name" class="form-control" formControlName="alertName" data-test="alerts-name-input" />
        <span *ngIf="hasError('alertName', 'required')" class="error">
          Alert name is required
        </span>
      </div>
    </div>
    <div class="coralogix input-group">
      <label class="input-label">Alert Description</label>
      <div class="has-validation">
        <textarea
          data-test="alerts-description-input"
          shAutoresize
          type="text"
          placeholder="Enter a description which will appear in alert notifications"
          class="form-control autoresizable"
          formControlName="description"
        ></textarea>
      </div>
    </div>
    <div class="coralogix input-group">
      <div class="has-validation" [class.coralogix-input-error]="isSelectInvalid('alertSeverity') || hasError('alertSeverity', 'required')">
        <sh-coralogix-select
          data-test="alerts-severity-select"
          formControlName="alertSeverity"
          [selectOptions]="severityOptions"
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [hasColoredIcon]="true"
          [placeholder]="'Select severity'"
        >
          <label class="label-with-tooltip" select-label>
            <span class="text">Alert Severity</span>
            <div>
              <i
                class="fa fa-question-circle-o"
                cgxTooltipClass="cgx-alerts-tooltip"
                position="right"
                [horizontal]="8"
                horizontalDirection="left"
                cgxTooltip="Choose any severity level to assign to this particular alert"
              ></i>
            </div>
          </label>
        </sh-coralogix-select>
        <span *ngIf="hasError('alertSeverity', 'required')" class="error">
          Select alert severity
        </span>
      </div>
    </div>
  </form>
</mat-card>
