import { RouterModule, Routes } from '@angular/router';
import { TagsMainLayoutComponent } from './containers/tags-main-layout/tags-main-layout.component';
import { EventInfoContainerComponent } from './containers/event-info-container/event-info-container.component';
import { TagsWelcomeComponent } from './components/welcome/tags-welcome/tags-welcome.component';
import { AuthGuard } from '../auth/shared/guards/auth.guard';
import { HerokuGurd } from '../shared/guards/heroku-payment.gurd';
import { ReadOnlyGuard } from '@shared/guards/read-only.guard';
import { NgModule } from '@angular/core';
import { PaymentGuard } from '@app/shared/guards/payment.guard';

export const DeploymentRoutes: Routes = [
  {
    path: 'tags',
    component: TagsMainLayoutComponent,
    data: {
      title: 'Tags',
    },
    canActivate: [PaymentGuard, HerokuGurd, AuthGuard, ReadOnlyGuard],
    children: [
      {
        path: '',
        component: TagsWelcomeComponent,
      },
      {
        path: 'edit/:editTagId',
        data: {
          title: 'Tags',
        },
        component: TagsWelcomeComponent,
      },
      {
        path: 'new',
        data: {
          title: 'Tags',
        },
        component: TagsWelcomeComponent,
      },
      {
        path: 'tag/:tagId/compare/:compareTagId/all-subsystems/:isAllSubsystems',
        data: {
          title: 'Tags',
        },
        component: EventInfoContainerComponent,
      },
      {
        path: 'tag/:tagId/compare/:compareTagId/all-subsystems/:isAllSubsystems/edit/:editTagId',
        data: {
          title: 'Tags',
        },
        component: EventInfoContainerComponent,
      },
      {
        path: 'tag/:tagId/compare/:compareTagId/all-subsystems/:isAllSubsystems/new',
        data: {
          title: 'Tags',
        },
        component: EventInfoContainerComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(DeploymentRoutes)],
  exports: [RouterModule],
})
export class DeploymentRoutingModule {}
