import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CreateMetricRequest,
  DeleteMetricRequest,
  EditMetricRequest,
  IMetricField,
  IMetricFieldsRes,
  IPrometheusLabelsReq,
  IPrometheusLabelsRes,
  Metric,
  MetricsResponse,
} from '@app/settings/metrices-settings/models/metric.model';
import { Constants } from '@app/constants';
import { TypedJSON } from 'typedjson';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '@app/services/configuration.service';

@Injectable()
export class MetricsService {
  constructor(private http: HttpClient, private configService: ConfigurationService) {}

  public createMetric(metric: CreateMetricRequest): Observable<Metric> {
    return this.http.put(Constants.metricsUrl, metric).map(res => TypedJSON.parse(res, Metric));
  }

  public editMetric(metric: EditMetricRequest): Observable<Metric> {
    return this.http.post(`${Constants.metricsUrl}/${metric.id}`, metric).map(res => TypedJSON.parse(res, Metric));
  }

  public getMetrics(): Observable<Metric[]> {
    return this.http.get(Constants.getMetricsUrl).map(res => this.extractMetrics(res));
  }

  public getMetricsFields(): Observable<IMetricFieldsRes> {
    return this.getMetrics().pipe(
      map(esMetrics => {
        const metricsFields = {
          allFields: [],
          allLabels: [],
          labelsMap: {},
        };
        return esMetrics.reduce((metrics, esMetric, index) => {
          esMetric.metricFields?.forEach(field => {
            metrics.allFields.push(field);
            metrics.labelsMap[field.name] = index;
          });
          metrics.allLabels.push(esMetric.groupBy);
          return metrics;
        }, metricsFields);
      }),
    );
  }

  getPrometheusFieldLabels(labelsReq: IPrometheusLabelsReq): Observable<string[]> {
    const url = this.configService.getConfigURL('/api/v1/prometheus-fields/labels');
    return this.http
      .get<IPrometheusLabelsRes>(url, {
        params: {
          start: String(labelsReq.start),
          end: String(labelsReq.end),
          field: labelsReq.field,
        },
      })
      .pipe(map(res => res.data));
  }

  public deleteMetric(deleteMetricRequest: DeleteMetricRequest): Observable<any> {
    return this.http.delete(`${Constants.metricsUrl}/${deleteMetricRequest.metricId}`, { responseType: 'text' }).mapTo(deleteMetricRequest);
  }

  public extractMetrics(res: any): Metric[] {
    const parsedResult = TypedJSON.parse({ metrics: res }, MetricsResponse);
    return parsedResult.metrics;
  }

  public getNewMetricsMetricFields(): Observable<string[]> {
    return this.http.get(Constants.companyNewMetricsMetricFieldsUrl).map((res: string[]) => res);
  }

  public getNewMetricsMetricLabels(): Observable<string[]> {
    return this.http.get(Constants.companyNewMetricsMetricLabelsUrl).map((res: string[]) => res);
  }
}
