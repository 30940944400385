<div class="main-container">
  <div class="title">
    Presto Cheat Sheet
  </div>
  <div class="page-content">
    <markdown>{{ readme }}</markdown>
  </div>
  <div mat-dialog-actions>
    <div>
      <cgx-button
        label="CLOSE"
        size="md"
        color="text-primary"
        stroked
        (press)="onNoClick()"
      ></cgx-button>
    </div>
  </div>
</div>
