import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { LivetailService } from './shared/services/livetail.service';
import { AuthModule } from '../auth/auth.module';
import { LivetailProvider } from './shared/services/livetail.provider';
import { LoggregationModule } from '../loggregation/loggregation.module';
import { LogsModule } from '../logs/logs.module';
import { LivetailBannerComponent } from './livetail-banner/livetail-banner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LivetailStatsComponent } from './livetail-stats/livetail-stats.component';
import { CommonModule } from '@angular/common';
import { LivetailTerminalComponent } from './livetail-terminal/livetail-terminal.component';
import { LivetailTerminalInputComponent } from './livetail-terminal-input/livetail-terminal-input.component';
import { LivetailStatsService } from './shared/services/livetailstats.service';
import { LivetailFiltersPanelComponent } from './livetail-filters-panel/livetail-filters-panel.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LivetailTerminalContainerModule } from '@app/livetail/livetail-terminal-container/livetail-terminal-container.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    AuthModule,
    LoggregationModule,
    ReactiveFormsModule,
    LogsModule,
    ScrollingModule,
    LivetailTerminalContainerModule,
  ],
  declarations: [
    LivetailBannerComponent,
    LivetailStatsComponent,
    LivetailTerminalComponent,
    LivetailTerminalInputComponent,
    LivetailFiltersPanelComponent,
  ],
  exports: [LivetailStatsComponent],
  providers: [LivetailService, LivetailProvider, LivetailStatsService],
})
export class LivetailModule {}
