import { Component, OnDestroy, OnInit } from '@angular/core';

import { GridOptions } from 'ag-grid';
import { Observable, Subscription } from 'rxjs';

import { LogQueryData } from '@app/logs/shared/models/logquery.data';
import { InsightsProvider } from '../../shared/services/insights.provider';
import { Insight } from '../../shared/models/insight.model';
import { Alert } from '../../shared/models/alert.model';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { Log } from '@app/logs/shared/models/log.entity';
import { InsightsHelper } from '../../shared/helpers/insightsHelper';
import { formatsHelper } from '@shared/services/formatsHelper';
import { InsightsService } from '../../shared/services/insights-service';
import { LogqueryResult } from '@app/logs/shared/models/logquery.response';
import { LogMetadata } from '@app/logs/shared/models/log-metadata.model';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { SeveritiesHelper } from '@shared/services/severities.helper';

@Component({
  selector: 'sh-simple-alert',
  templateUrl: './simple-alert.component.html',
  styleUrls: ['./simple-alert.component.scss'],
})
export class SimpleAlertComponent implements OnInit, OnDestroy {
  public queryData: LogQueryData;
  public gridOptions: GridOptions;
  public currentInsightsChangedSubscription: Subscription;
  public isOverlayVisible: boolean;
  constructor(
    public insightsProvider: InsightsProvider,
    private logsService: LogsService,
    private insightsService: InsightsService,
    private htmlRowMeasure: HtmlRowMeasure
  ) {}

  public ngOnInit(): void {
    this.currentInsightsChangedSubscription = this.insightsProvider.currentInsightChanged.subscribe(
      (insight) => {
        this.getLogs(insight);
      },
    );
  }

  public ngOnDestroy(): void {
    this.insightsProvider.globalState.selectedLog = null;
    this.currentInsightsChangedSubscription.unsubscribe();
  }

  public getAlertQueryModel(alert: Alert): LogQueryModel {
    return InsightsHelper.getLogQueryModelByInsight(alert);
  }

  public getLogs(insight: Insight): void {
    this.isOverlayVisible = true;
    const alert = insight as Alert;
    const queryModel = this.getAlertQueryModel(alert);
    let eventLog = null;
    const log = new Log();
    log.metadata = new LogMetadata();
    try {
      eventLog = JSON.parse(alert.properties.logText);
      if (eventLog.coralogix && eventLog.coralogix.metadata) {
        log.text = eventLog.logText;
        log.metadata.applicationName = eventLog.coralogix.metadata.applicationName;
        log.metadata.subsystemName = eventLog.coralogix.metadata.subsystemName;
        log.metadata.severity = SeveritiesHelper.getLogSeverityReversedById(eventLog.coralogix.metadata.severity);
        log.metadata.category = eventLog.coralogix.metadata.category;
        log.metadata.className = eventLog.coralogix.metadata.className;
        log.metadata.computerName = eventLog.coralogix.metadata.computerName;
        log.metadata.IPAddress = eventLog.coralogix.metadata.IPAddress;
        log.metadata.methodName = eventLog.coralogix.metadata.methodName;
        log.metadata.threadId = eventLog.coralogix.metadata.threadId;
        log.timestamp = alert.properties.logTimestamp;
        try {
          const obj = JSON.parse(eventLog.logText);
          log.jsonUuid = 'jsonUuid'; } catch (e) {
          log.jsonUuid = null;
        }
      } else {
        log.text = JSON.stringify(eventLog);
        log.metadata.applicationName = insight.applicationName;
        log.metadata.subsystemName = insight.subsystemName;
        log.metadata.severity = SeveritiesHelper.getEventSeverityById(alert.severity);
        log.timestamp = alert.properties.logTimestamp;
        log.jsonUuid = 'jsonUuid';
      }
    } catch (e) {
      log.text = alert.properties.logText;
      log.metadata.applicationName = alert.applicationName;
      log.metadata.subsystemName = alert.subsystemName;
      log.metadata.computerName = alert.computerName;
      log.metadata.IPAddress = alert.IPAddress;
      log.metadata.severity = SeveritiesHelper.getEventSeverityById(alert.severity);
      log.timestamp = alert.properties.logTimestamp;
      log.jsonUuid = null;
    }
    this.htmlRowMeasure.prepareRowsHeights([log]).first().subscribe(res => {
      const logQueryRes = new LogqueryResult();
      logQueryRes.total = 1;
      logQueryRes.logs = res as Log[];
      const logQueryData = new LogQueryData(
        queryModel,
        logQueryRes,
        this.logsService,
      );
      logQueryData.startFromIndex = 0;
      this.queryData = logQueryData;
    });

  }

  public updateQueryLogCount(logCount: number): void {
    this.insightsProvider.globalState.queryResultCount =
      formatsHelper.numberWithCommas(logCount) + ' Logs';
  }

  public onGridReady(options: GridOptions): void {
    this.gridOptions = options;
    setTimeout(() => {
      const insight = this.insightsProvider.currentInsight;
      if (insight) {
        this.getLogs(insight);
      }
    }, 0);
  }

  public onGridButtonClick(event: any): void {
    this.insightsProvider.textInfoClicked.emit(event);
  }

  public onSelectedRow(e: any): void {
    this.insightsProvider.globalState.selectedLog = e;
  }
}
