import { PolicyOverride } from '@app/tco/models/policy-override.model';
import { has, uniq } from 'lodash';

export const buildNamesHierarchy = (overrides: PolicyOverride[]): { [name: string]: { [name: string]: boolean } } => {
    return overrides.reduce((names: { [name: string]: { [name: string]: boolean } }, override) => {
        names[override.applicationName] = names[override.applicationName] || {};
        names[override.applicationName][override.subsystemName] = true;
        return names;
    }, {});
};

export const evalSubsystemNames = (
    names: { [name: string]: { [name: string]: boolean } },
    selectedApplicationNames: string[]
): string[] => {
    if (selectedApplicationNames.length) {
        return uniq(selectedApplicationNames.reduce((acc, applicationName) => [...acc, ...Object.keys(names[applicationName])], []));
    } else {
        return uniq(Object.values(names).reduce((acc, curr) => [...acc, ...Object.keys(curr)], []));
    }
};

export const evalNewSelectedSubsystemNames = (names: { [name: string]: { [name: string]: boolean } }, selectedSubsystemNames: string[]) => {
    const newSubsystemNamesDic = Object.values(names).reduce((acc, subsystemNameDic) => {
        acc = { ...acc, ...subsystemNameDic };
        return acc;
    }, {});
    return selectedSubsystemNames.filter(subsystemName => has(newSubsystemNamesDic, subsystemName));
};
