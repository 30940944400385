import { Injectable } from '@angular/core';

@Injectable()
export class MetricConstants {
  public readonly DUPLICATE_NAME_MESSAGE: string = 'This Name already exists';
  public readonly DUPLICATE_TOOLTIP: string = "Metric Field names can't have duplicates (company wide)";
  public readonly DUPLICATE_FIELD_PATH_MESSAGE: string = 'This metric path already exists';
  public readonly BAD_NAME_MESSAGE: string = 'The name contains forbidden characters';
  public readonly BAD_NAME_TOOLTIP: string = 'The name may only contain the following characters:\na-z,A-Z,0-9,-,_';
  public readonly OLD_FORMAT_MESSAGE: string =
    "The Metric Labels are in the old format, can't save this metric! Please contact Customer Success!";
  public readonly metricExtractionNameRegex: RegExp = /^\s*[\w/-]+\s*$/;
  public readonly METRIC_LABEL_LIMIT_DEFAULT: number = 6;
}

export const ERROR_STATUSES = {
  DB_CONFLICT_ERROR_CODE: 409,
  LUCENE_VALIDATOR_CODE: 417,
  DEFAULT_ERROR: 500,
};
