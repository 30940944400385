import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

@jsonObject
export class NewAlertResult {
  @jsonArrayMember(String)
  public id: string[];
  @jsonMember
  public success: boolean;
  @jsonMember
  public status: number;
}

@jsonObject
export class EditAlertResult {
  @jsonMember
  public id: string;
  @jsonMember
  public success: boolean;
  @jsonMember
  public status: number;
}
