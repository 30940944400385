import { enumsTranslator } from '@app/shared/config/enums-translator';
import { Condition } from '../enums/condition.enum';
import { Priority } from '../enums/priority.enum';
import { Severity } from '../enums/severity.enum';
import { IPolicyCriteriaRuleSerialized } from '../serialized/policy-criteria-rule.serialized';
import { IPolicyCriteria } from '../interfaces/policy-criteria.interface';
import { IPolicyCriteriaSerialized } from '../serialized/policy.criteria.serialized';
import { Colors } from '@app/shared/models/colors.model';

export class PolicyCriteria implements IPolicyCriteria {
    public id: string;

    public name: string;

    public applicationCondition: Condition;

    public applicationString: string;

    public subsystemCondition: Condition;

    public subsystemString: string;

    public severities: Severity[];

    public priority: Priority;

    constructor(obj: IPolicyCriteria) {
        this.name = obj.name;
        this.applicationCondition = obj.applicationCondition;
        this.applicationString = obj.applicationString;
        this.id = obj.id;
        this.priority = obj.priority;
        this.severities = obj.severities;
        this.subsystemCondition = obj.subsystemCondition;
        this.subsystemString = obj.subsystemString;
    }

    public get criteria(): string {
        let res = 'For all <b class="criteria-bold-word">applications</b>';
        if (this.applicationString) {
            const shorter = this.applicationString.length > 32 ? `${this.applicationString.slice(0, 32)}...` : this.applicationString;
            res += ` that [${enumsTranslator.Condition[this.applicationCondition]}] <b class="criteria-bold-word">${shorter}</b>`;
        }
        res += ' and all <b class="criteria-bold-word">subsystems</b>';
        if (this.subsystemString) {
            const shorter = this.subsystemString.length > 32 ? `${this.subsystemString.slice(0, 32)}...` : this.subsystemString;
            res += ` that [${enumsTranslator.Condition[this.subsystemCondition]}] <b class="criteria-bold-word">${shorter}</b>`;
        }
        return res;
    }

    public severityColor(severity: Severity): string {
        return Colors.severityColors[severity];
    }

    public static deserializer(obj: IPolicyCriteriaSerialized): PolicyCriteria {
        let policyCriteria: PolicyCriteria = null;

        if (obj) {
            policyCriteria = new PolicyCriteria({
                id: obj.id,
                name: obj.name,
                applicationCondition: undefined,
                applicationString: undefined,
                subsystemCondition: undefined,
                subsystemString: undefined,
                severities: [],
                priority: obj.priority as Priority
            });

            for (const rule of obj?.rules || []) {
                switch (rule.metaField) {
                    case 'applicationName':
                        policyCriteria.applicationCondition = rule.ruleTypeId;
                        policyCriteria.applicationString = rule.rule;
                        break;
                    case 'subsystemName':
                        policyCriteria.subsystemCondition = rule.ruleTypeId;
                        policyCriteria.subsystemString = rule.rule;
                        break;
                    case 'severity':
                        policyCriteria.severities = rule.rule ? rule.rule.split(',').map(severity => +severity) : [];
                        break;
                }
            }
        }

        return policyCriteria;
    }

    public static serializer(policyCriteria: PolicyCriteria): IPolicyCriteriaSerialized {
        const obj: IPolicyCriteriaSerialized = {
            id: policyCriteria.id,
            name: policyCriteria.name,
            rules: (() => {
                const res: IPolicyCriteriaRuleSerialized[] = [];
                if (policyCriteria.applicationCondition) {
                    res.push({
                        metaField: 'applicationName',
                        ruleTypeId: policyCriteria.applicationCondition,
                        rule: policyCriteria.applicationString
                    });
                }

                if (policyCriteria.subsystemCondition) {
                    res.push({
                        metaField: 'subsystemName',
                        ruleTypeId: policyCriteria.subsystemCondition,
                        rule: policyCriteria.subsystemString
                    });
                }

                if (policyCriteria.severities) {
                    res.push({
                        metaField: 'severity',
                        ruleTypeId: Condition.Is,
                        rule: policyCriteria.severities.join(',')
                    });
                }
                return res;
            })(),
            priority: policyCriteria.priority,
        };

        return obj;
    }

    public static create(obj: IPolicyCriteria): PolicyCriteria {
        let policyCriteria: PolicyCriteria = null;
        if (obj) {
            policyCriteria = new PolicyCriteria({
                name: obj.name,
                applicationCondition: obj.applicationCondition,
                applicationString: obj.applicationString,
                id: obj.id,
                priority: obj.priority,
                severities: obj.severities,
                subsystemCondition: obj.subsystemCondition,
                subsystemString: obj.subsystemString,
            });
        }

        return policyCriteria;
    }
}
