declare var $: any;
import { HostListener, ElementRef, Injectable } from '@angular/core';

@Injectable()
export class ScrollHelperService {
  public isAutoScrollEnable: boolean = true;

  public scrollToElm(elm: ElementRef) {
    $('html,body').animate(
      { scrollTop: $(elm.nativeElement).offset().top },
      250,
    );
  }

  public scrollTodocumentElement(elm: ElementRef) {
    $('html,body').animate(
      {
        scrollTop: $(elm.nativeElement.ownerDocument.documentElement).offset()
          .top,
      },
      250,
    );
  }

  public hideBodyOverFlow() {
    $('body').css({ overflow: 'hidden' });
  }
  public showBodyOverFlow() {
    $('body').css({ overflow: 'auto' });
  }
}
