import { Action } from '@ngrx/store';

import { type } from '@shared/services/utils';
import { Log } from '../../models/log.entity';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';

export namespace LogsQueryActions {
  export const ActionTypes = {
    QUERY_BY_TEMPLATE: type('[LogsQuery] perform query by template'),
    REDIRECT_TO_LOGS_COMPLETED: type(
      ' [LogsQuery] redirect to logs screen completed',
    ),
  };

  export class QueryByTemplate implements Action {
    public type = ActionTypes.QUERY_BY_TEMPLATE;

    constructor(public payload: Log | LoggregationEntity) {}
  }

  export class RedirectToLogsCompleted implements Action {
    public type = ActionTypes.REDIRECT_TO_LOGS_COMPLETED;

    constructor(public payload: any) {}
  }

  export type Actions = QueryByTemplate | RedirectToLogsCompleted;
}
