import { ISummaryBaseMap, TagSummaryTypes } from '@app/deployment/shared/models/summary.models';
import { StatisticsResultTypes } from '@shared/components/statistics-tile/statistics-tile.component';

export const queryLogsRoute = '/query-new/logs';
export const insightsRoute = '/insights';

export const SummaryViewMapBase: ISummaryBaseMap = {
  [TagSummaryTypes.highSeverityRatio]: {
    title: 'CHANGE IN HIGH SEVERITY LOG RATIO',
    insightSentence: 'of all logs are high severity compared to',
    redirectLink: queryLogsRoute,
    isNegativeGrowth: false,
    dataType: StatisticsResultTypes.percentage
  },
  [TagSummaryTypes.newlyIntroducedErrors]: {
    title: 'CHANGE IN NEWLY INTRODUCED ERRORS',
    insightSentence: 'new error logs compared to',
    redirectLink: queryLogsRoute,
    isNegativeGrowth: false,
    dataType: StatisticsResultTypes.number
  },
  [TagSummaryTypes.errorVolume]: {
    title: 'CHANGE IN ERROR VOLUME',
    insightSentence: 'errors compared to',
    redirectLink: queryLogsRoute,
    isNegativeGrowth: false,
    dataType: StatisticsResultTypes.number
  },
  [TagSummaryTypes.triggeredAnomalies]: {
    title: 'CHANGE IN TRIGGERED ANOMALIES',
    insightSentence: 'anomalies were triggered compared to',
    redirectLink: insightsRoute,
    isNegativeGrowth: false,
    dataType: StatisticsResultTypes.number
  },
  [TagSummaryTypes.triggeredAlerts]: {
    title: 'CHANGE IN TRIGGERED ALERTS',
    insightSentence: 'alerts were triggered compared to',
    redirectLink: insightsRoute,
    isNegativeGrowth: false,
    dataType: StatisticsResultTypes.number
  },
};
