import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { ArchiveQueryEditorControls } from '@app/roi/archive-queries/models/archive-query-editor-controls';

@Injectable()
export class ArchiveQueryEditorForm {
  public get defaultValues(): ArchiveQueryEditorControls {
    const defaultTime = new Date();
    defaultTime.setHours(defaultTime.getHours() + 1);
    defaultTime.setMinutes(0, 0, 0);
    return new ArchiveQueryEditorControls({
      name: '',
      description: '',
      applicationName: [],
      subsystem: [],
      severities: [],
      textSql: null,
      textLucene: null,
      from: defaultTime,
      to: defaultTime,
      startTime: '00:00',
      endTime: `${defaultTime.getHours()}:00`,
      querySyntax: 'Lucene'
    });
  }
  constructor(private fb: FormBuilder) {}

  public buildForm(value: ArchiveQueryEditorControls): FormGroup {
    return this.fb.group({
      details: this.buildDetailsGroup(value),
      filters: this.buildFilterGroup(value),
    });
  }

  public buildTimePickerForm(form?: FormGroup): FormGroup {
    return this.fb.group({
      from: form?.get('from')?.value || this.defaultValues.from,
      to: form?.get('to')?.value || this.defaultValues.to,
      startTime: form?.get('startTime')?.value || this.defaultValues.startTime,
      endTime: form?.get('endTime')?.value || this.defaultValues.endTime,
    });
  }

  private buildDetailsGroup(value: ArchiveQueryEditorControls): FormGroup {
    return this.fb.group({
      name: [value.name, Validators.required],
      description: [value.description],
    });
  }

  private buildFilterGroup(value: ArchiveQueryEditorControls): FormGroup {
    return this.fb.group({
      applicationName: [value.applicationName],
      subsystemName: [value.subsystem],
      logSeverity: [value.severities],
      textSql: value.textSql,
      textLucene: value.textLucene,
      from: value.from,
      to: value.to,
      startTime: value.startTime,
      endTime: value.endTime,
      querySyntax: value.querySyntax,
    });
  }
}
