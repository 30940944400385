<div class="close-section">
  <div class="close-button" (click)="onNoClick()"><span>×</span></div>
</div>
<div class="body-wrapper">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content>
    {{ data.message }}
  </div>
  <ng-container *ngIf="data.deleteConfirmPattern">
    <div class="coralogix input-group">
      <input #inputElement [formControl]="formControl" [pattern]="data.deleteConfirmPattern" type="text" class="form-control" />
      <span *ngIf="!formControl.valid && inputElement?.value?.length" class="error">Invalid value</span>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions class="bottom-actions">
  <button class="coralogix button dialog-cancel-button" *ngIf="data.noButtonText" (click)="onNoClick()">
    {{ data.noButtonText }}
  </button>
  <button
    data-test="confirmation-dialog-yes-btn"
    class="coralogix button dialog-delete-button"
    [disabled]="data.deleteConfirmPattern && (!formControl.valid || formControl.pristine || !formControl.value)"
    [mat-dialog-close]="true"
  >
    {{ data.yesButtonText }}
  </button>
</div>
