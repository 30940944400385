import {Injectable} from '@angular/core';
import {ChartModel} from '../models/chart.model';

@Injectable()
export class ChartOptionsService {
  public getLegendOptions(data: ChartModel): any {
    return {
      enabled: data.showLegend,
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 30,
      borderWidth: 0,
    };
  }

  public getBasePlotOptions(data: ChartModel): any {
    return {
      column: {
        borderWidth: 0,
        dataLabels: {
          enabled: data.enableDataLabel,
        },
      },
      bar: {
        dataLabels: {
          enabled: data.enableDataLabel,
          format:
            `<div style="color:${data.dataLabelsInside ? 'var(--cgx-text-primary)' : 'var(--cgx-text-primary)'} ">{point.y}` +
            data.tooltipValueSuffix +
            '</div>',
          inside: data.dataLabelsInside,
          padding: 5,
          y: -1,
          overflow: 'justify',
          align: data.dataLabelsInside && 'right',
          style: {
            textShadow: false,
            fontSize: '8px',
            fontWeight: 'bolder',
            inside: true,
          },
        },
        pointPadding: 0,
      },
      pie: {
        borderWidth: 0,
        size: '95%',
        dataLabels: {
          enabled: true,
          distance: 2,
          padding: 1,
          connectorWidth: 0,
          format: '<b style="color:{point.color} ">{point.name}</b>',
          color: data.labelHighlightColor ? data.labelHighlightColor : 'white',
          style: {
            textShadow: false,
            fontSize: '10px',
            cursor: 'pointer',
            width: '500px',
          },
        },
        startAngle: -90,
        endAngle: 270,
        center: data.center,
      },
      areaspline: {},
      series: {
        marker: {
          enabled: false,
          radius: 1,
          symbol: 'circle',
        },
        states: {
          hover: {
            enabled: data.hoverable,
          },
        },
        turboThreshold: 1500, // data array limit size
        cursor: data.getCursor(),
        animation: data.useAnimation,
        stacking: data.stacking,
      },
    };
  }

  public getBaseChartOptions(data: ChartModel): any {
    return {
      inverted: data.inverted,
      reflow: true,
      margin: data.hideAxis ? [0, 0, 0, 0] : null,
      borderWidth: 0,
      zoomType: data.zoomType,
      panning: true,
      plotBackgroundColor: null,
      plotBorderWidth: data.plotBorderWidth || 0,
      plotShadow: false,
      panKey: 'shift',
      spacingBottom: 5,
      selectionMarkerFill: 'rgba(255, 0, 0, 0.2)',
      resetZoomButton: {
        position: {
          x: -10,
          y: 10,
        },
        relativeTo: 'chart',
        theme: {
          display: data.displayZoomButton(),
          fill: 'white',
          stroke: 'white',
          style: {
            color: 'black',
            'font-family': 'Roboto',
          },
          r: 2,
          states: {
            hover: {
              fill: 'white',
              stroke: 'white',
              r: 2,
              style: {
                color: 'black',
                cursor: 'pointer',
                'font-family': 'Roboto',
              },
            },
          },
        },
      },
      width: data.width,
      height: data.height,
      backgroundColor: data.backgroundColor,
    };
  }

  public getTitleOptions(data: ChartModel): any {
    return {
      text: data.title,
      align: 'center',
      verticalAlign: 'middle',
      y: data.titleY,
      style: {
        color: data.fontColor,
        fontSize: data.fontSize,
        display: data.showTitle ? 'block' : 'none',
      },
    };
  }
}
