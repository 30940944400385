import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LogsViewStateProvider, QueryViewState } from '../logs/shared/services/logsViewState.provider';
import { ScrollHelperService } from '../shared/services/scroll-helper/scroll-helper.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { LogQueryModel } from '../logs/shared/models/logsquery.model';
import { Anomaly } from './shared/models/anomaly.model';
import { InsightsProvider } from './shared/services/insights.provider';
import {
  StatisticsCategory,
  StatisticsQueryDefinition,
  StatisticsTypes,
} from '../statistics/shared/models/statistics-query-definition';
import { InsightsHelper } from './shared/helpers/insightsHelper';
import { InsightsQueryModel } from './shared/models/insights-query.model';
import { InsightsService } from './shared/services/insights-service';
import { Insight } from './shared/models/insight.model';
import { UpdatedAnomaliesModel } from './shared/models/updated-anomalies-model';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { Log } from '../logs/shared/models/log.entity';
import { QueryTypes } from '../logs/shared/models/query-types.options';
import { LogsInfoPanelComponent } from '../logs/logs-info-panel/logs-info-panel.component';
import { Alert } from './shared/models/alert.model';
import { AnomalySubTypeId, InsightTypeId } from './shared/models/enums';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { UserAlertTypes } from '@app/alerts/shared/models/create-n-update-alert-model';
import { ConditionsOptions } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { Store } from '@ngrx/store';
import { queryTemplate, State } from '@app/app.reducers';
import { debounceTime, filter, map, skip, take, takeUntil } from 'rxjs/operators';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetSnoozedAlerts } from '@app/ngxs-store/alerts/alerts-snooze.action';
import { MetricsActions } from '@app/settings/metrices-settings/state/actions/metrics.actions';

@Component({
  selector: 'sh-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
})
export class InsightsComponent implements OnInit, OnDestroy, AfterViewInit {
  get viewState(): QueryViewState {
    return this.logsViewStateProvider.viewState;
  }

  get selectedLog(): any {
    return this.viewState.selectedLog;
  }

  @ViewChild('contentElm', { static: true }) public contentElm: ElementRef;

  @ViewChild('queryElm', { static: true }) public queryElm: ElementRef;

  @ViewChild('infoPanel', { static: true })
  public infoPanel: LogsInfoPanelComponent;

  public logsViewStateProvider: LogsViewStateProvider;

  public statisticsQueryDefinition: StatisticsQueryDefinition;

  public currentViewedInsightId: string;

  public autoSelectFirst: boolean = false;

  public insights: Insight[];

  public anomalySubTypeId: any = AnomalySubTypeId;

  public currentFilterType: string = '';

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private scrollHelper: ScrollHelperService,
    private router: Router,
    private logsViewSateProvider: LogsViewStateProvider,
    private insightsService: InsightsService,
    public insightsProvider: InsightsProvider,
    private route: ActivatedRoute,
    private ae: AnalyticEventService,
    private store: Store<State>,
  ) {
    this.logsViewStateProvider = new LogsViewStateProvider();
    this.insightsProvider.globalState.showTimeline = false;
    this.scrollHelper.showBodyOverFlow();
    scrollHelper.hideBodyOverFlow();
  }

  public ngOnInit(): void {
    this.insightsProvider.textInfoClicked.pipe(takeUntil(this.destroyed$)).subscribe(() => this.infoPanel.toggleOpen());
    this.queryTemplateListener();
    this.store.dispatch(new MetricsActions.GetMetricsAction(null));
  }

  public ngOnDestroy(): void {
    this.scrollHelper.showBodyOverFlow();
    this.destroyed$.next();
  }

  public queryTemplateListener(): void {
    this.store
      .select(queryTemplate)
      .pipe(skip(1), takeUntil(this.destroyed$))
      .subscribe((entity) => {
        if (entity) {
          this.router.navigate(['query-new/logs'], { replaceUrl: true });
        }
      });
  }

  public ngAfterViewInit(): void {
    this.getInsights();
    this.registerToRoutingParams();
  }

  public getInsightTitle(insight: Insight): string {
    return insight.subTypeId === this.anomalySubTypeId.VolumeAnomaly
      ? 'Error Spike Anomaly'
      : this.insightsProvider.currentInsight.name || 'Flow Anomaly #' + (insight as Anomaly).shortId;
  }

  public registerToRoutingParams(): void {
    this.route.queryParams
      .pipe(
        // TODO: NG4 ng c
        debounceTime(0),
        map((params) => [params['id'], params['filter'], params['severity']]),
        filter((val) => {
          this.autoSelectFirst = false;
          if (val[1]) {
            let filterType = val[1];
            if (val[2]) {
              filterType = filterType + '-' + val[2];
            }
            this.currentFilterType = filterType;
            this.autoSelectFirst = true;
          }
          if (!val[0]) {
            this.autoSelectFirst = true;
          }
          return val && val[0] && val[0] !== this.currentViewedInsightId;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (params) => {
          this.currentViewedInsightId = params[0];
        },
        (error) => console.log(error),
      );
  }

  public getFirstInsight(): Insight {
    if (this.insights) {
      return this.insights[0];
    }
    return null;
  }

  public getInsightById(insightId: string): Observable<Insight> {
    if (this.insights) {
      const selectedInsight = _.find(this.insights, (insight) => insight.id === insightId);
      if (selectedInsight) {
        return Observable.of(selectedInsight);
      } else {
        return this.insightsService.getInsight(insightId).pipe(
          map((insight) => {
            if (insight) {
              this.insights = this.insights.concat(insight);
              return insight;
            }
            return null;
          }),
          take(1),
        );
      }
    }
    return Observable.of(null);
  }

  public updateAnomalies(updateModel: UpdatedAnomaliesModel): void {
    this.insights = this.insights.map((insight) => {
      if (insight.alertId === updateModel.anomalyId) {
        insight.name = updateModel.anomalyUpdateParams.name;
        insight.severity = updateModel.anomalyUpdateParams.severity;
        insight.isActive = updateModel.anomalyUpdateParams.isActive;
      }
      return insight;
    });
  }

  public isGroupByFieldsAlert(insight: Insight): boolean {
    if (insight instanceof Alert) {
      const alert = insight as Alert;
      if (alert.typeId === InsightTypeId.AlertGroupByFields && alert.subTypeId !== InsightTypeId.MetricAlert) {
        return true;
      }
    }
    return false;
  }

  public isRelativeAlert(insight: Insight): boolean {
    if (insight instanceof Alert) {
      const alert = insight as Alert;
      if (alert.typeId === InsightTypeId.AlertRelative) {
        return true;
      }
    }
    return false;
  }

  public isAdvancedAlert(insight: Insight): boolean {
    if (insight instanceof Alert) {
      const alert = insight as Alert;
      if (
        !this.isCardinalityAlert &&
        !this.isMetricAlert &&
        alert.hasConditions &&
        alert.typeId === InsightTypeId.Alert &&
        alert.conditionOperator !== ConditionsOptions.newValue
      ) {
        return true;
      }
    }
    return false;
  }
  public isNewValueAlert(insight: Insight): boolean {
    if (insight instanceof Alert) {
      const alert = insight as Alert;
      if (
        !this.isCardinalityAlert &&
        !this.isMetricAlert &&
        alert.hasConditions &&
        alert.typeId === InsightTypeId.Alert &&
        alert.conditionOperator === ConditionsOptions.newValue
      ) {
        return true;
      }
    }
    return false;
  }

  public isSimpleAlert(insight: Insight): boolean {
    if (!this.isCardinalityAlert && !this.isMetricAlert && insight instanceof Alert) {
      const alert = insight as Alert;
      if (alert.hasConditions) {
        return false;
      }
      return true;
    }
    return false;
  }

  public get isCardinalityAlert(): boolean {
    return this?.insightsProvider?.currentInsight?.subTypeId === UserAlertTypes.Cardinality;
  }

  public get isMetricAlert(): boolean {
    return this?.insightsProvider?.currentInsight?.subTypeId === UserAlertTypes.MetricAlert;
  }

  public getInsights(): void {
    const insightsQueryModel: InsightsQueryModel = new InsightsQueryModel();
    // TODO: NG4 do check avoid
    this.insightsService
      .getInsights(insightsQueryModel)
      .pipe(debounceTime(0), takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.insights = res.insights;
          const groupedInsightsById = _.groupBy(
            this.insights,
            insight => insight.alertId
          );
          this.getSnoozedAlerts(_.keys(groupedInsightsById));
          _.forEach(groupedInsightsById,
            (insights, key) =>
              _.maxBy(insights, insight => insight.timestamp)
                .isLatest = true
          );
          if (this.insights.length) {
            if (this.currentViewedInsightId) {
              this.getInsightById(this.currentViewedInsightId)
                .pipe(take(1))
                .subscribe((insight) => {
                  if (insight) {
                    this.onInsightClicked(insight);
                  } else {
                    const firstInsight = this.getFirstInsight();
                    if (firstInsight) {
                      this.onInsightClicked(firstInsight);
                    }
                  }
                });
            } else if (!this.insightsProvider.currentInsight) {
              this.onInsightClicked(this.insights[0]);
            } else {
              this.onInsightClicked(this.insightsProvider.currentInsight);
            }
          }
        },
        (error) => console.log(error),
      );
  }

  public chartVisibility(): void {
    this.insightsProvider.globalState.showTimeline = !this.insightsProvider.globalState.showTimeline;
  }

  public onInsightClicked(e: Insight): void {
    this.ae.event({ eventName: 'insight - get insight' });
    this.insightsProvider.currentInsight = e;
    const insightId = e.id;
    this.currentViewedInsightId = insightId;
    this.insightsProvider.currentInsightChanged.emit(e);
    this.statisticsQueryDefinition = this.getStatisticsQuery(InsightsHelper.getLogQueryModelByInsight(e));
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: insightId,
        filter: this.currentFilterType,
      },
    };

    if (!(this.insightsProvider.currentInsight instanceof Anomaly)) {
      if (this.isGroupByFieldsAlert(this.insightsProvider.currentInsight)) {
        this.router.navigate(['/insights/groupbyalert'], navigationExtras);
      } else if (this.isAdvancedAlert(this.insightsProvider.currentInsight)) {
        this.router.navigate(['/insights/advancedalert'], navigationExtras);
      } else if (this.isNewValueAlert(this.insightsProvider.currentInsight)) {
        this.router.navigate(['/insights/newvalue'], navigationExtras);
      } else if (this.insightsProvider.currentInsight.subTypeId === UserAlertTypes.Cardinality) {
        this.router.navigate(['/insights/unique-count-alert'], navigationExtras);
      } else if (this.isSimpleAlert(this.insightsProvider.currentInsight)) {
        this.router.navigate(['/insights/alert'], navigationExtras);
      } else if (this.isRelativeAlert(this.insightsProvider.currentInsight)) {
        this.router.navigate(['/insights/ratioalert'], navigationExtras);
      } else if (this.insightsProvider.currentInsight.subTypeId === UserAlertTypes.MetricAlert) {
        this.router.navigate(['/insights/metricalert'], navigationExtras);
      } else {
        this.router.navigate(['/insights'], navigationExtras);
      }
    } else {
      this.router.navigate(['/insights/anomaly'], navigationExtras);
    }
  }

  public navigateToLogsQuery(): void {
    this.router.navigate(['/query-new/logs']);
  }

  public onBeforeAfter(args: { seconds: number; selectedLog: Log }): void {
    this.logsViewSateProvider.doBeforeAfter = args;
    this.router.navigate(['/query-new/logs'], {
      state: { viewSurroundingLogs: { log: args.selectedLog, seconds: args.seconds } },
    });
  }

  @Dispatch() public getSnoozedAlerts = (alertIds) => new GetSnoozedAlerts(alertIds);

  private getStatisticsQuery(logQeury: LogQueryModel): StatisticsQueryDefinition {
    const statisticsQuery = new StatisticsQueryDefinition();
    statisticsQuery.logQeuryModel = logQeury;
    if (statisticsQuery.logQeuryModel.type === QueryTypes.EVENT) {
      statisticsQuery.logQeuryModel.type = QueryTypes.FREE;
    }
    statisticsQuery.category = StatisticsCategory.LOGS;
    statisticsQuery.seriesType = 'areaspline';
    statisticsQuery.type = StatisticsTypes.Daily;
    return statisticsQuery;
  }
}
