import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { State } from '../../app.reducers';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { SettingsActions } from './settings.actions';
import { EmailFiltersService } from '../../settings-common/shared/services/email-filters.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SettingsEffects {
  @Effect()
  public loadEmailFilters$: Observable<Action> = this.actions$.pipe(
    ofType(SettingsActions.ActionTypes.GET_EMAIL_FILTERS),
    switchMap((action) => {
      return this.emailFiltersService
        .getEmailFilters()
        .map((filters) => {
          return new SettingsActions.GetEmailFiltersActionCompleted(filters);
        })
        .catch((err) =>
          of(new SettingsActions.GetEmailFiltersActionFailed(err)),
        );
    }),
  );

  @Effect()
  public addEmailFilter$: Observable<Action> = this.actions$.pipe(
    ofType(SettingsActions.ActionTypes.ADD_EMAIL_FILTER),
    switchMap((action: any) => {
      return this.emailFiltersService
        .addEmailFilter(action.payload)
        .map(
          (filter) => new SettingsActions.AddEmailFilterActionCompleted(filter),
        )
        .catch((err) =>
          of(new SettingsActions.AddEmailFilterActionFailed(err)),
        );
    }),
  );

  @Effect()
  public deleteFilter$: Observable<Action> = this.actions$.pipe(
    ofType(SettingsActions.ActionTypes.DELETE_EMAIL_FILTER),
    switchMap((action: any) => {
      return this.emailFiltersService
        .deleteFilter(action.payload.id)
        .map(
          (filter) =>
            new SettingsActions.DeleteEmailFilterActionCompleted(filter),
        )
        .catch((err) =>
          of(new SettingsActions.DeleteEmailFilterActionFailed(err)),
        );
    }),
  );

  @Effect()
  public editFilter$: Observable<Action> = this.actions$.pipe(
    ofType(SettingsActions.ActionTypes.EDIT_EMAIL_FILTER),
    switchMap((action: any) => {
      return this.emailFiltersService
        .editEmailFilter(action.payload)
        .map(
          (filter) =>
            new SettingsActions.EditEmailFilterActionCompleted(filter),
        )
        .catch((err) =>
          of(new SettingsActions.EditEmailFilterActionFailed(err)),
        );
    }),
  );
  constructor(
    private actions$: Actions,
    private emailFiltersService: EmailFiltersService,
    private store: Store<State>,
  ) {}
}
