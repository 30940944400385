import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { State } from '../../../app.reducers';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { ArchivingProvidersService } from '../../../settings-common/shared/services/archiving-providers.service';
import { ArchivingProvidersActions } from './archiving-providers.action';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ArchivingProvidersEffects {
  @Effect()
  public loadGetArchivingProviders$: Observable<Action> = this.actions$.pipe(
    ofType(ArchivingProvidersActions.ActionTypes.GET_ARCHIVING_PROVIDERS),
    switchMap((action) => {
      return this.archivingProvidersService
        .getArchivingProviders()
        .map((archivingProviders) => {
          return new ArchivingProvidersActions.GetArchivingProvidersActionCompleted(
            archivingProviders,
          );
        })
        .catch((err) =>
          of(
            new ArchivingProvidersActions.GetArchivingProvidersActionFailed(
              err,
            ),
          ),
        );
    }),
  );

  @Effect()
  public saveArchivingProviders$: Observable<Action> = this.actions$.pipe(
    ofType(ArchivingProvidersActions.ActionTypes.SAVE_ARCHIVING_PROVIDERS),
    switchMap((action: any) => {
      return this.archivingProvidersService
        .saveArchivingProviders(action.payload)
        .map((archivingProviders) => {
          return new ArchivingProvidersActions.SaveArchivingProvidersActionCompleted(
            archivingProviders,
          );
        })
        .catch((err) =>
          of(
            new ArchivingProvidersActions.SaveArchivingProvidersActionFailed(
              err,
            ),
          ),
        );
    }),
  );

  @Effect()
  public testArchivingProviders$: Observable<Action> = this.actions$.pipe(
    ofType(ArchivingProvidersActions.ActionTypes.TEST_ARCHIVING_PROVIDER),
    switchMap((action: any) => {
      return this.archivingProvidersService
        .testArchivingProvider(action.payload)
        .map((result) => {
          return new ArchivingProvidersActions.TestArchivingProviderActionCompleted(
            result,
          );
        })
        .catch((err) =>
          of(
            new ArchivingProvidersActions.TestArchivingProviderActionFailed(
              err,
            ),
          ),
        );
    }),
  );
  constructor(
    private actions$: Actions,
    private archivingProvidersService: ArchivingProvidersService,
    private store: Store<State>,
  ) {}
}
