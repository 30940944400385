import { ERuleServerTypes } from '@app/rules/shared/models/rule.model';

export interface IParsingThemeFilter {
  searchTerm: string;
  applications: string[];
  subsystems: string[];
  ruleTypes: ERuleServerTypes[] | string;
}

export const AllValues = 'all';

export function getPTListEmptyFilters(): IParsingThemeFilter {
  return {
    applications: [],
    subsystems: [],
    ruleTypes: AllValues,
    searchTerm: ''
  };
}
