import { Injectable } from '@angular/core';
import { LogsView } from '../models/logs-view.model';

@Injectable({
  providedIn: 'root',
})
export class LogsViewMapperService {
  public mapResponseToModel(model: any): LogsView {
    const logsView = new LogsView({
      id: model.id,
      name: model.name,
      isPublic: model.is_public,
      isMine: model.is_mine,
      isEditable: model.is_editable,
      logsGridColDefs: model.column_definitions,
      logsGridColState: model.columns_state,
      queryHistoryModel: model.query_model,
    });
    return logsView;
  }
}
