import { Component, OnDestroy } from '@angular/core';
import { EFeatureViewMode } from '@app/deployment/shared/models/reducer.models';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getSelectedTagQueryBase, State } from '@app/app.reducers';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { getLogsBaseQueryModel } from '@app/deployment/shared/helpers/helper-functions';

@Component({
  selector: 'sh-top-error-templates',
  templateUrl: './top-error-templates.component.html',
  styleUrls: ['./top-error-templates.component.scss']
})
export class TopErrorTemplatesComponent implements OnDestroy {
  public completedViewState: EFeatureViewMode = EFeatureViewMode.completed;
  public viewState$: EFeatureViewMode;
  public logQueryModel: LogQueryModel;
  protected destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store<State>,
              private logsService: LogsService,
              private htmlRowMeasure: HtmlRowMeasure,
              private router: Router) {
    this.viewState$ = EFeatureViewMode.initialState;
    this.store.select(getSelectedTagQueryBase())
      .pipe(takeUntil(this.destroy$),
       filter((tagData) => !!tagData))
      .subscribe((data) => {
        this.logQueryModel = getLogsBaseQueryModel(data, this.logsService.getNewQueryId());
      });
  }

  public redirectToLogsPage(): void {
    this.logsService.saveLogsQuery(this.logQueryModel)
      .first()
      .subscribe(() => {
        const url = '#' + this.router.serializeUrl(
          this.router.createUrlTree(['query-new', 'logs'], {
            queryParams: { id: this.logQueryModel.cacheQueryId }
          }));
        window.open(url, '_blank');
      });
  }

  public changeReadyState(readyState: EFeatureViewMode): void {
    this.viewState$ = readyState;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
