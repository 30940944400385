import { CompareType } from '@shared/components/conditional-select/conditional-select.model';

export const filterPrefix = 'filter';

const filterStartsWithPattern = `${filterPrefix}:${CompareType.startsWith}:`;
const filterEndsWithPattern = `${filterPrefix}:${CompareType.endsWith}:`;
const filterContainsPattern = `${filterPrefix}:${CompareType.contains}:`;
export const filterEqualsPattern = `${filterPrefix}:${CompareType.equals}:`;

export const compareTypeFilters = [
  {
    pattern: filterStartsWithPattern,
    enum: CompareType.startsWith,
  },
  {
    pattern: filterEndsWithPattern,
    enum: CompareType.endsWith,
  },
  {
    pattern: filterContainsPattern,
    enum: CompareType.contains,
  },
  {
    pattern: filterEqualsPattern,
    enum: CompareType.equals,
  },
];
