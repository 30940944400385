<div class="menu" *ngIf="isOpen && _rows">
  <!--virtual-->
  <sh-virtual-scroll
    class ="scroll"
    [items]="_rows"
    (update)="onVirtualScrollUpdate($event)"
    [style.height]="scrollHeight"
  >
    <div
      class="item"
      *ngFor="let row of viewPortItems"
      [class.selected]="selectedValues?.indexOf(row) !== -1"
      (click)="toggleSelected(row)"
    >
      {{ row.name || row }}
    </div>
  </sh-virtual-scroll>
</div>
