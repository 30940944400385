import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { InsightsProvider } from '../../shared/services/insights.provider';
import { LogQueryData } from '@app/logs/shared/models/logquery.data';
import { Insight } from '../../shared/models/insight.model';
import { InsightsHelper } from '../../shared/helpers/insightsHelper';
import { Observable, Subscription } from 'rxjs';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { Alert } from '../../shared/models/alert.model';
import { Log } from '@app/logs/shared/models/log.entity';
import { GridOptions } from 'ag-grid';
import { formatsHelper } from '@shared/services/formatsHelper';
import {
  StatisticsCategory,
  StatisticsQueryDefinition,
  StatisticsTypes,
} from '@app/statistics/shared/models/statistics-query-definition';
import {
  AddvanceAlertRes,
  AdvanceAlertService,
} from '../../shared/services/advance-alert.service';
import { Constants } from '@app/constants';
import { InsightsService } from '../../shared/services/insights-service';
import { AnomalySubTypeId } from '../../shared/models/enums';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { Store } from '@ngrx/store';
import { State as appState } from '@app/app.reducers';
import { LogActions } from '@app/logs/shared/state/log.actions';
@Component({
  selector: 'sh-insights-logs',
  templateUrl: './insights-logs.component.html',
  styleUrls: ['./insights-logs.component.scss'],
})
export class InsightsLogsComponent implements OnInit, OnDestroy {
  public queryData: LogQueryData;
  public highlightedLogs: Map<string, string> = new Map<string, string>();
  public isOverlayVisible: boolean;
  public statisticsQueryDefinition: StatisticsQueryDefinition;
  public onNewQueryRequestSubscription: Subscription;
  private currentInsightsChangedSubscirption: Subscription;
  private state: State = new State();

  constructor(
    private logsService: LogsService,
    public insightsProvider: InsightsProvider,
    private htmlRowMeasure: HtmlRowMeasure,
    private advanceAlertService: AdvanceAlertService,
    private insightsService: InsightsService,
    private store: Store<appState>
  ) {}

  public ngOnInit(): void {
    // TODO NG4 do Check remove
    setTimeout(() => {
      this.insightsProvider.globalState.showFind = true;
    }, 0);
    this.insightsProvider.insightsLogsState
      ? (this.state = this.insightsProvider.insightsLogsState)
      : (this.state = new State());
    if (this.insightsProvider.currentInsight) {
      this.statisticsQueryDefinition = this.getStatisticsQuery(
        InsightsHelper.getLogQueryModelByInsight(
          this.insightsProvider.currentInsight,
        ),
      );
    }

    this.currentInsightsChangedSubscirption = this.insightsProvider.currentInsightChanged.subscribe(
      (insight) => {
        this.getlogs(insight);
        this.statisticsQueryDefinition = this.getStatisticsQuery(
          InsightsHelper.getLogQueryModelByInsight(insight),
        );
      },
    );

    this.onNewQueryRequestSubscription = this.logsService.newQueryRequest.subscribe(
      (queryModel) => {
        this.logsService
          .getLogs(queryModel)
          .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs)
            .mapTo(logqueryRes))
          .subscribe(
            (res) => {
              this.queryData = new LogQueryData(
                queryModel,
                res,
                this.logsService,
              );
              this.store.dispatch(new LogActions.SetLogPanelQuery(queryModel));
            }
          );
      },
    );
  }

  public ngOnDestroy(): void {
    this.insightsProvider.globalState.selectedLog = null;
    this.insightsProvider.globalState.queryResultCount = null;
    this.insightsProvider.insightsLogsState = this.state;
    this.currentInsightsChangedSubscirption.unsubscribe();
    this.onNewQueryRequestSubscription.unsubscribe();
  }

  public onGridReady(options: GridOptions): void {
    // NG4 remove do check
    setTimeout(() => {
      if (this.state.gridColumns) {
        options?.columnApi?.setColumnState(this.state.gridColumns);
      }
      if (this.state.gridFilters) {
        options.api.setFilterModel(this.state.gridFilters);
      }
      if (this.insightsProvider.currentInsight) {
        this.getlogs(this.insightsProvider.currentInsight);
      }
    }, 0);
  }

  public getVolumeAnomalyLogs(insight: Insight): Subscription {
    const queryModel = InsightsHelper.getLogQueryModelByInsight(insight);
    return this.logsService
      .getLogs(queryModel)
      .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
      .first()
      .subscribe(
        (res) => {
          const logQueryData = new LogQueryData(
            queryModel,
            res,
            this.logsService,
          );
          this.queryData = logQueryData;
        },
        (error) => console.log(error),
        () => (this.isOverlayVisible = false),
      );
  }

  public getInightOrSimpleAlertLogs(insight: Insight): void {
    this.highlightedLogs = InsightsHelper.getHighlightedLogsByInsight(insight);
    const queryModel = InsightsHelper.getLogQueryModelByInsight(insight);

    Observable.from([insight])
      .first()
      .switchMap((_insight) =>
        InsightsHelper.isAnomaly(_insight)
          ? this.getFirsTemplatetLogInInsight(_insight)
          : this.getAlertFirtLog(_insight as Alert),
      )
      .switchMap((log) => {
        this.isOverlayVisible = true;
        return this.insightsService.getLogIndexInQuery(log, queryModel);
      })
      .switchMap((indexRes) =>
        this.insightsService.getPageContaingIndex(
          indexRes.total,
          queryModel,
          indexRes.log,
        ),
      )
      .subscribe(
        (res) => {
          const logQueryData = new LogQueryData(
            queryModel,
            res.logsRes,
            this.logsService,
          );
          logQueryData.startFromIndex = res.exactIndex;
          logQueryData.highlightedLogs = this.highlightedLogs;
          this.queryData = logQueryData;
        },
        (error) => console.log(error),
        () => (this.isOverlayVisible = false),
      );
  }

  public updateQueryLogCount(logCount: number): void {
    this.insightsProvider.globalState.queryResultCount =
      formatsHelper.numberWithCommas(logCount) + ' Logs';
  }

  public onSelectedRow(e: any): void {
    this.insightsProvider.globalState.selectedLog = e;
  }

  public beforeApiDestroy(e: GridOptions): void {
    if (e.api) {
      this.state.gridFilters = e.api.getFilterModel();
    }

    if (e.columnApi) {
      this.state.gridColumns = e.columnApi.getColumnState();
    }
  }

  public getAdvanceAlert(alert: Alert): void {
    // get all alert logs and marked logs and zip them to one stream
    this.advanceAlertService
      .getAdvanceAlert(alert)
      .first()
      .subscribe((addvanceAlertRes: AddvanceAlertRes) => {
        const mapLogToColor = new Map<string, string>();
        // console.log(addvanceAlertRes);
        addvanceAlertRes.markedLogs.forEach((log) =>
          mapLogToColor.set(log.logId, Constants.MARKED_LOG_COLOR),
        );

        this.highlightedLogs = mapLogToColor;
        addvanceAlertRes.firstMarkedPageLogsData.highlightedLogs = mapLogToColor;
        this.queryData = addvanceAlertRes.firstMarkedPageLogsData;
      });
  }

  public onGridButtonClick(event: string): void {
    this.insightsProvider.textInfoClicked.emit(event);
  }

  private getlogs(insight: Insight): void {
    this.isOverlayVisible = true;

    if (insight instanceof Alert) {
      const alert: Alert = insight as Alert;

      if (alert.hasConditions || alert.subTypeId === 5) {
        this.getAdvanceAlert(alert);
      } else {
        this.getInightOrSimpleAlertLogs(insight);
      }
    } else if (insight.subTypeId === AnomalySubTypeId.VolumeAnomaly) {
      this.getVolumeAnomalyLogs(insight);
    } else {
      this.getInightOrSimpleAlertLogs(insight);
    }
  }

  // this will return the first log in the anomaly that is included in the anomalies templates
  private getFirsTemplatetLogInInsight(insight: Insight): Observable<Log> {
    const query: LogQueryModel = InsightsHelper.getLogQueryModelByInsight(
      insight,
    );
    query.type = QueryTypes.TEMPLATE_IDS;
    query.queryParams.templateIds.push(
      ...InsightsHelper.getemplateIdsByInsight(insight),
    );
    console.log(query);
    return this.logsService
      .getLogs(query)
      .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
      .map((logsRes) => logsRes.logs)
      .filter((logs) => logs.length > 0)
      .map((logs) => logs[0]);
  }

  private getAlertFirtLog(alert: Alert): Observable<Log> {
    const log = new Log();
    log.timestamp = alert.properties.logTimestamp;
    log.logId = alert.properties.logId;

    return Observable.from([log]);
  }

  private getStatisticsQuery(logQeury: LogQueryModel): StatisticsQueryDefinition {
    const statisticsQuery = new StatisticsQueryDefinition();
    statisticsQuery.logQeuryModel = logQeury;
    if (statisticsQuery.logQeuryModel.type === QueryTypes.EVENT) {
      statisticsQuery.logQeuryModel.type = QueryTypes.FREE;
    }
    statisticsQuery.category = StatisticsCategory.LOGS;
    statisticsQuery.seriesType = 'areaspline';
    statisticsQuery.type = StatisticsTypes.Daily;
    return statisticsQuery;
  }
}

class State {
  public gridFilters: any;
  public gridColumns: any;
}
