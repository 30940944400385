import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Build } from '../../models/build';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MomentHistoryDateTime } from '@shared/helpers/moment.helper';

@Component({
  selector: 'sh-tags-list-item',
  templateUrl: './tags-list-item.component.html',
  styleUrls: ['./tags-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsListItemComponent {
  public _tagData: Build;
  public dateAgo: string;
  public refId: string;
  public readonly maxTagDisplayedLength: number = 30;
  public momentUniqDateFormat: string = MomentHistoryDateTime;
  @Input() public isSelected: boolean = false;
  @Input() set tagData(build: Build) {
    const delimiterIndex = build.text_value.lastIndexOf('\\');
    const newBuild = _.cloneDeep(build);

    if (delimiterIndex !== -1) {
      newBuild.text_value = build.text_value.substr(0, delimiterIndex);
      this.refId = build.text_value.substr(
        delimiterIndex + 1,
        build.text_value.length - delimiterIndex,
      );
    }

    this._tagData = newBuild;
    this.dateAgo = this.convertDateAgo(build.tag_timestamp);
  }

  @Output() public tagSelected: EventEmitter<Build> = new EventEmitter<Build>();
  @Output() public deleteTag: EventEmitter<Build> = new EventEmitter<Build>();
  @Output() public editTag: EventEmitter<Build> = new EventEmitter<Build>();
  @Output() public compareTag: EventEmitter<any[]> = new EventEmitter<any[]>();

  public get formattedTagName(): string {
    let tag = this._tagData.text_value;

    if (this.isTagLengthOverlapLimit) {
      tag = `${tag.substr(0, this.maxTagDisplayedLength)}...`;
    }

    return tag;
  }

  public get isTagLengthOverlapLimit(): boolean {
    const tag = this._tagData.text_value;
    return tag.split(' ')[0].length > this.maxTagDisplayedLength;
  }

  public get showFilters(): boolean {
    return !!(this._tagData?.application_name || this._tagData?.subsystem_name);
  }

  public convertDateAgo(date: number): string {
    return moment(date).fromNow();
  }

}
