import { Injectable } from '@angular/core';
import { LogQueryModel } from '../../logs/shared/models/logsquery.model';
import { Log } from '../../logs/shared/models/log.entity';
import { LogsService } from '../../logs/shared/services/logs-service';
import * as _ from 'lodash';
import { LogqueryResult } from '../../logs/shared/models/logquery.response';
import { Observable } from 'rxjs';
import { Constants } from '../../constants';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';

@Injectable()
export class FindLogInQueryService {
  constructor(
    private logService: LogsService,
    private htmlRowMeasure: HtmlRowMeasure,
  ) {}

  public findLogInQuery(
    queryModel: LogQueryModel,
    log: Log,
  ): Observable<LogInQueryRes> {
    // step 1: get total logs from queryModel start time to log time (as the end time of the query)
    const logInQueryRes: LogInQueryRes = new LogInQueryRes(
      _.cloneDeep<LogQueryModel>(queryModel),
      log,
    );
    const logEndTimeQueryModel: LogQueryModel = _.cloneDeep<LogQueryModel>(
      queryModel,
    ) as LogQueryModel;
    logEndTimeQueryModel.pageSize = 0; // we only care about the total not the logs
    logEndTimeQueryModel.endDate = log.timestamp + 1; // +1 to make sure we will not miss our log

    if (
      queryModel.sortModel &&
      queryModel.sortModel.length &&
      queryModel.sortModel[0].ordering === 'desc'
    ) {
      logEndTimeQueryModel.startDate = log.timestamp + 1;
      logEndTimeQueryModel.endDate = queryModel.endDate;
    }

    return (
      this.logService
        .getLogs(logEndTimeQueryModel)
        // step 2: calculate page index of log in query
        .map((res: LogqueryResult) =>
          Math.floor(res.total / Constants.QUERY_PAGE_SIZE),
        )
        // step 3: get query page result
        .do((pageIndex) => (logInQueryRes.queryModel.pageIndex = pageIndex))
        .switchMap((pageIndex) =>
          this.logService.getLogs(logInQueryRes.queryModel),
        )
        .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
        .do(
          (pageRes: LogqueryResult) =>
            (logInQueryRes.logPageInQueryRes = pageRes),
        ) // save page res in
        // step 4: find exact index of log in page result
        .map((pageRes: LogqueryResult) =>
          pageRes.logs.find((a_log) => a_log.logId === logInQueryRes.log.logId),
        )
        .do((a_log: Log) =>
          a_log ? (logInQueryRes.logIndexInQuery = a_log.index) : null,
        )
        .mapTo(logInQueryRes)
    );
  }
}

export class LogInQueryRes {
  public logIndexInQuery: number;
  public logPageInQueryRes: LogqueryResult;

  constructor(
    public queryModel: LogQueryModel,
    public log: Log,
    logPageInQueryRes?: LogqueryResult,
  ) {
    this.logPageInQueryRes = logPageInQueryRes;
  }
}
