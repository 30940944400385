import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '@app/constants';
import { Observable, throwError } from 'rxjs';
import { PaymentPlan, PaymentPlanResponse } from '@app/shared/models/payment-plan';
import { TypedJSON } from 'typedjson';

@Injectable()
export class PaymentService {
  constructor(private http: HttpClient) {}

  // New Stripe routes
  public getStripeCustomerId(): Observable<any> {
    return this.http.get(Constants.paymentUrl + '/stripe').map(id => id);
  }

  public createStripeSubscription(stripeResponseObject: any): Observable<any> {
    return this.http.post(Constants.paymentUrl + '/create', { stripeResponseObject }).map(id => id);
  }

  public changeStripeSubscription(stripeResponseObject: any): Observable<any> {
    return this.http.post(Constants.paymentUrl + '/change', { stripeResponseObject }).map(id => id);
  }

  // Old Routes - should delete
  public getPlans(): Observable<PaymentPlanResponse> {
    return this.http
      .get(Constants.paymentPlansUrl)
      .map(this.extractPlans)
      .catch(this.handleError);
  }
  public getCustomerPlan(): Observable<any> {
    return this.http
      .get(Constants.paymentCustomerUrl)
      .map(this.extractPlan)
      .catch(this.handleError);
  }
  public createPayment(subscriptionRquest: any): Observable<any> {
    return this.http
      .post(Constants.GetUrl() + '/api/v1/payment/subscribe', subscriptionRquest)
      .map(this.extractPlan)
      .catch(this.handleError);
  }
  public changePlan(plan: PaymentPlan): Observable<any> {
    return this.http
      .post(Constants.GetUrl() + '/api/v1/payment/changePlan', plan)
      .map(this.extractPlan)
      .catch(this.handleError);
  }
  public unsubscribe(): Observable<any> {
    return this.http.post(Constants.GetUrl() + '/api/v1/payment/unsubscribe', null).catch(this.handleError);
  }
  public extractPlans(res: PaymentPlanResponse): PaymentPlanResponse {
    return new TypedJSON(PaymentPlanResponse).parse(res);
  }
  public extractPlan(res: PaymentPlan): PaymentPlan {
    return new TypedJSON(PaymentPlan).parse(res);
  }
  public extractData(res: any): any {
    return JSON.parse(res);
  }

  private handleError(error: any): Observable<any> {
    return throwError(error);
  }
}
