import { Component } from '@angular/core';
import { IRuleExampleData, RuleSamples } from '@app/rules/shared/models/rule-samples-view.models';

@Component({
  selector: 'sh-rule-welcome',
  templateUrl: 'rules-welcome.component.html',
  styleUrls: ['rules-welcome.component.scss'],
})
export class RulesWelcomeComponent {
  public ruleSamples: IRuleExampleData[] = RuleSamples;
}
