export class OrderNumbersList {
  private readonly _filteredList: IOrderListItem[];

  constructor(fullList: {order: number}[], filteredList: {order: number}[]) {
    this._filteredList = this.getMappedList(filteredList);
    this.setLastItemData(fullList[fullList.length - 1 ].order);
  }

  public get filteredList(): IOrderListItem[] {
    return this._filteredList;
  }

  private getMappedList(list: {order: number}[]): IOrderListItem[] {
    return list.reduce((prev, curr, i) => {
      const currOrder = curr.order;
      if (prev?.length) {
        const prevItem = prev[i - 1];
        const currentGapBefore = this.isGapIsMoreThan1(currOrder, prevItem.order);
        prev[i - 1].hasGapAfter = currentGapBefore;
        if (prevItem.order >= 1 ) {
          prev[i - 1].isLast = false;
        }
        return [...prev, new OrderListItem(currOrder, currentGapBefore, false, false, true)];
      } else {
        return [new OrderListItem(currOrder, this.isGapIsMoreThan1(currOrder), false, currOrder === 1, true)];
      }
    }, []);
  }

  private isGapIsMoreThan1(orderNum: number, prevNum: number = 0): boolean {
    const hasLegitGapInOrder = orderNum === prevNum + 1;
    return orderNum !== 1 ? !hasLegitGapInOrder : false;
  }

  private setLastItemData(fullListLastItemOrder: number): void {
    const filteredList = this._filteredList;
    if (!filteredList.length || !fullListLastItemOrder) {
      return;
    }
    if (filteredList[filteredList.length - 1 ].order < fullListLastItemOrder) {
      this._filteredList[filteredList.length - 1 ].isLast = false;
    }
  }
}

export interface IOrderListItem {
  order: number;
  hasGapBefore: boolean; // true = dotted line false = solid line
  hasGapAfter: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

export class OrderListItem {
  public order: number;
  public hasGapBefore: boolean;
  public hasGapAfter: boolean;
  public isFirst?: boolean;
  public isLast?: boolean;

  constructor(order: number, hasGapBefore: boolean, hasGapAfter: boolean, isFirst?: boolean, isLast?: boolean) {
    this.order = order;
    this.hasGapBefore = hasGapBefore;
    this.hasGapAfter = hasGapAfter;
    this.isFirst = isFirst;
    this.isLast = isLast;
  }
}
