import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allBrowsersDate',
})
export class AllBrowsersDatePipe implements PipeTransform {
  transform(value: string): any {
    let returnVal: string | Date = value;
    try {
      value = value.replace(' ', 'T');
      returnVal = new Date(value);
    } catch (e) {
      return returnVal;
    }
    return returnVal;
  }
}
