import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PredefinedQueries } from '../../shared/models/predefined-queries';

@Component({
  selector: 'sh-quick-query-predefined',
  templateUrl: './quick-query-predefined.component.html',
  styleUrls: ['./quick-query-predefined.component.scss'],
})
export class QuickQueryPredefinedComponent implements OnInit {
  @Output() public selectedValueChange: EventEmitter<any> = new EventEmitter<
    any
  >();

  public predefineQueries: PredefinedQueries;

  constructor() {
    this.predefineQueries = new PredefinedQueries();
  }

  public ngOnInit() {}
}
