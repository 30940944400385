import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sh-presto-cheat-sheet',
  templateUrl: './presto-cheat-sheet.component.html',
  styleUrls: ['./presto-cheat-sheet.component.scss'],
})
export class PrestoCheatSheetComponent {
  readme = require('raw-loader!./presto-cheat-sheet.md').default;

  constructor(public dialogRef: MatDialogRef<PrestoCheatSheetComponent>) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
