import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PrettyJsonPipe } from '../pipes/pretty-json.pipe';
import { Log } from '@app/logs/shared/models/log.entity';

const showMoreHeight: number = 400;
const maxTextLengthForCalc: number = 3000;

export interface MeasureOject {
  id: number;
  text: string;
  jsonUuid: string;
  viewObject: string;
  showMore: boolean;
}

@Injectable()
export class HtmlRowMeasure {
  public rowsHeight: Map<string, number> = new Map<string, number>();
  public textColumnWidth: number = 800;
  public prettyJasonPipe: PrettyJsonPipe = new PrettyJsonPipe();
  public calcAll: boolean = false;

  public clear(): void {
    this.rowsHeight.clear();
  }

  public removeRows(items: MeasureOject[]): void {
    items.forEach((item) => this.rowsHeight.delete(item.id.toString()));
  }

  public prepareRowsHeights(rows: MeasureOject[]): Observable<MeasureOject[]> {
    return Observable.create((observer) => {
      const rootElm = this.createDummyElements(rows, this.textColumnWidth);
      // add the calculate element to the dom
      document.body.appendChild(rootElm);
      // let the dom calculate the layout
      setTimeout(() => {
        for (let i = 0; i < rootElm.children.length; i++) {
          const node: any = rootElm.children[i];
          this.rowsHeight.set(node.id, document.getElementById(node.id).offsetHeight);
        }
        // remove child from dom;
        document.body.removeChild(rootElm);
        observer.next(rows);
      }, 10);
    });
  }

  private buildStyles(width: number): string {
    const styles = [
      'position:fixed',
      'visibility: hidden',
      'padding:0',
      'margin:0',
      'font-size:12px !important',
      'word-wrap: break-word',
      'white-space: normal',
      'line-height: 1.4',
      'font-family: Roboto, serif',
      'width:' + width + 'px',
      'max-width:' + width + 'px',
      'min-width:' + width + 'px',
    ];
    return styles.join(';');
  }

  private buildStylesVisible(width: number): string {
    const styles = [
      'padding:0',
      'margin:0',
      'font-size:12px !important',
      'word-wrap: break-word',
      'white-space: normal',
      'line-height: 1.4',
      'font-family: Roboto, serif',
      'width:' + width + 'px',
      'max-width:' + width + 'px',
      'min-width:' + width + 'px',
    ];
    return styles.join(';');
  }

  private createDummyElements(rows: MeasureOject[], width: number): HTMLDivElement {
    // generateRootElements
    const cssText = this.buildStyles(width);
    const rootElm = document.createElement('div');
    rootElm.style.cssText = cssText;
    // map rows to divs
    rows.forEach((log: MeasureOject) => {
      let text;
      let child;
      let jsonText;

      if (log.jsonUuid) {
        try {
          jsonText = JSON.parse(log.text);
        } catch (e) {
          log.jsonUuid = '';
          console.warn('unable to parse json for log', log);
        }
      }

      if (jsonText) {
        let highlghtedKeysDict = null;
        if (log instanceof Log) {
          const logEntity = log as Log;
          if (logEntity.highlight) {
            Object.entries(logEntity.highlight).forEach(([key, val]) => {
              if (jsonText[key]) {
                jsonText[key] = val[0];
              } else if (key === 'coralogix.json_keys' && val && val.length === 1) {
                const highlightedKeys = val[0]
                  .split(' ')
                  .filter((jsonKey) => jsonKey.indexOf('<em>') > -1 && jsonKey.indexOf('</em>') > -1);
                highlghtedKeysDict = highlightedKeys.reduce((acc, item) => {
                  acc[item.replace('<em>', '').replace('</em>', '')] = item.replace(
                    '<em>',
                    '<em style="background: yellow; font-style: normal">',
                  );
                  return acc;
                }, {});
              }
            });
          }
        }
        text = this.prettyJasonPipe.transform(jsonText, highlghtedKeysDict);
        child = document.createElement('pre');
        child.className = 'grid-json-theme';
        child.setAttribute('id', log.id.toString());
        child.innerHTML = text;
        log.viewObject = child.outerHTML;
        if (text.length < maxTextLengthForCalc || this.calcAll) {
          log.showMore = false;
          rootElm.appendChild(child);
        } else {
          this.rowsHeight.set(child.id, showMoreHeight);
          log.showMore = true;
        }
      } else {
        log.text = log.text || '';
        if (log instanceof Log) {
          const logEntity = log as Log;
          if (logEntity.highlight && logEntity.highlight.text) {
            log.text = logEntity.highlight.text[0];
          }
        }
        text = log.text
          .replace(/&/g, '&amp;')
          .replace(/>/g, '&gt;')
          .replace(/</g, '&lt;')
          .replace(/(?:\\n\\r|\\r|\\n|\r\n|\r|\n$)\b/g, '<br />')
          .replace(/"/g, '&quot;')
          .replace(/&lt;em&gt;/g, '<em style="background: yellow; font-style: normal">')
          .replace(/&lt;\/em&gt;/g, '</em>');
        child = document.createElement('div');

        child.setAttribute('id', log.id.toString());
        child.innerHTML = text;
        child.style.cssText = this.buildStylesVisible(width);
        log.viewObject = child.outerHTML;
        child.style.cssText = this.buildStyles(width);
        if (text.length < maxTextLengthForCalc || this.calcAll) {
          log.showMore = false;
          rootElm.appendChild(child);
        } else {
          this.rowsHeight.set(child.id, showMoreHeight);
          log.showMore = true;
        }
      }
    });
    this.calcAll = false;
    return rootElm;
  }
}
