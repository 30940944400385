import { Injectable } from '@angular/core';
import { IUpdateNewTeammateDetailsModel } from '@app/settings/shared/models/update-new-teammate-details-model';
import { Constants } from '../../../constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ChangePasswordRequestModel } from '../../../settings/shared/models/changePasswordRequest';
@Injectable()
export class PasswordService {
  private changeUserPasswordUrl: string = Constants.GetUrl() + Constants.changeUserPasswordUrl;

  private updateNewTeammateMemberDetailsUrl: string = Constants.GetUrl() + Constants.updateNewTeammateMemberDetailsUrl;

  constructor(private http: HttpClient) {}

  public savePasswordStr(newPassword: string, oldPassword: string): Observable<any> {
    const model = new ChangePasswordRequestModel();
    model.newPassword = newPassword;
    model.oldPassword = oldPassword;
    return this.savePassword(model);
  }

  public savePassword(changePassword: ChangePasswordRequestModel): Observable<any> {
    return this.http.post(this.changeUserPasswordUrl, JSON.stringify(changePassword), { responseType: 'text' }).catch(this.handleError);
  }

  public updateNewTeammateDetails(updateNewTeammateDetailsModel: IUpdateNewTeammateDetailsModel): Observable<any> {
    return this.http
      .post(this.updateNewTeammateMemberDetailsUrl, updateNewTeammateDetailsModel, { responseType: 'text' })
      .catch(this.handleError);
  }

  private handleError(error: any): Observable<never> {
    const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}
