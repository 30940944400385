import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/shared/services/auth.service';
import { AnalyticEventService } from './user/shared/AnalyticEventService';
import { combineLatest, Observable, Subject } from 'rxjs';
import { formatsHelper } from '@shared/services/formatsHelper';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { UserState } from './ngxs-store/user/user.state';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Teammate } from './shared/models/teammate.model';
import { ThemeService } from '@app/logs-new/shared/services/theme.service';
import { TrackingService } from '@shared/services/tracking.service';
import { Title } from '@angular/platform-browser';

declare let history: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public user$: Observable<Teammate> = this.store.select(UserState.user).pipe(
    filter(user => user !== null && user.id !== null),
    take(1),
  );

  public title: string = 'app works!';

  public isInitialized: boolean;

  destroyed$ = new Subject();

  constructor(
    public router: Router,
    private ae: AnalyticEventService,
    private authService: AuthService,
    private store: Store,
    themeService: ThemeService,
    trackingService: TrackingService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    trackingService.init();
    window.analytics.load(environment.SegmentSourceKey);
    const router$ = router.events
      .filter(current => current instanceof NavigationEnd)
      .distinctUntilChanged((previous: any, current: any) => {
        return formatsHelper.getPathFromUrl(previous.url) === formatsHelper.getPathFromUrl(current.url);
      });

    const combiend = combineLatest([router$, this.user$]);
    combiend.subscribe(([routerValue, user]) => {
      const dataLayerObj = {};

      if (routerValue && routerValue.url) {
        dataLayerObj['event'] = 'newURL';
        dataLayerObj['cleanURL'] = window.location.href.replace('/#', '');

        if (user && user.id) {
          dataLayerObj['userId'] = user.id;
          dataLayerObj['companyId'] = window.localStorage.getItem('t_id');
        }

        ae.update({
          path: routerValue.url,
          email: this.authService.userName,
          userType: this.authService.getUserType(),
        });

        this.ae.dataLayerReport(dataLayerObj);
      }
    });

    if ('scrollRestoration' in history) {
      // Back off, browser, I got this...
      history.scrollRestoration = 'manual';
    }
  }

  ngOnInit(): void {
    this.changeTitleOnRouteChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changeTitleOnRouteChanges(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        takeUntil(this.destroyed$),
      )
      .subscribe(event => {
        if (event.title) {
          this.titleService.setTitle(`${event.title} - Coralogix`);
        } else {
          this.titleService.setTitle('Coralogix');
        }
      });
  }
}
