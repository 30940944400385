import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Observable, Subject } from 'rxjs';
import { ICellRendererParams } from 'ag-grid-community';
import { LogsContent } from '@app/logs-new/shared/models/logs-content.model';
import { JsonFormatterComponent } from '@app/logs-new/shared/features/logs-new-grid/components/grid-json-formatter/grid-json-formatter.component';

@Component({
  selector: 'sh-templates-formatter-renderer',
  templateUrl: 'templates-formatter-renderer.component.html',
  styleUrls: ['templates-formatter-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatesFormatterRendererComponent implements ICellRendererAngularComp, OnDestroy {
  public params: ICellRendererParams;
  @ViewChild('jsonFormatterRenderer') public jsonFormatterRenderer: JsonFormatterComponent;
  private unsubscribe$ = new Subject<void>();

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public refresh(params: any): boolean {
    this.jsonFormatterRenderer.refresh();
    return true;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public searchLogsByTemplate(): void {
    const templateId = this.params.data.id;
    this.params.context.parent.setActiveTabEvent.emit(LogsContent.logs);
    this.params.context.parent.addToSearchQueryEvent.emit({
      payload: templateId,
      toInclude: true,
      jsonPath: 'coralogix.templateId',
    });
  }
}
