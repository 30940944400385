<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon><i class="fa fa-ellipsis-v" aria-hidden="true"></i></mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button
    *ngIf="(isReadOnly$ | async) === false"
    mat-menu-item
    (click)="widget.emit()"
    [disabled]="!addWidgetEnable"
  >
    <mat-icon><i class="fa fa-tachometer" aria-hidden="true"></i></mat-icon>
    <span>Pin Graph</span>
  </button>
  <button mat-menu-item (click)="toggleGrpah.emit()">
    <mat-icon><i class="fa fa-area-chart" aria-hidden="true"></i></mat-icon>
    <span>Toggle Graph</span>
  </button>
  <button mat-menu-item (click)="toggleFilters.emit()">
    <mat-icon><i class="fa fa-filter" aria-hidden="true"></i></mat-icon>
    <span>Toggle Filters</span>
  </button>
  <button mat-menu-item (click)="export.emit()">
    <mat-icon><i class="fa fa-cloud-download" aria-hidden="true"></i></mat-icon>
    <span>Export View</span>
  </button>
</mat-menu>
