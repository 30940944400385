export enum EmptyStateBodyItemType {
  header,
  body
}

export enum EmptyStateListTypes {
  imageList,
  bulletsList,
  numbersList
}

export interface IListHeader {
  type: EmptyStateBodyItemType.header;
  title: string;
  description: string;
}

export interface IListItem {
  bulletContent?: string;
  imageUrl?: string;
  imageWidth?: string;
  title?: string;
  linkUrl?: string;
  linkText?: string;
  description: string;
}

export interface IListBody {
  type: EmptyStateBodyItemType.body;
  title: string;
  listType: EmptyStateListTypes;
  listWidth: string;
  list: IListItem[];
}
