import { LogsViewsActions } from './logs-views.actions';
import { LogsView } from '../../models/logs-view.model';
import * as _ from 'lodash';

export namespace LogsViewsReducer {
  export interface State {
    selectedViewId: number;
    savedViews: LogsView[];
    isLoading: boolean;
    isInitialized: boolean;
  }

  const initalState: State = {
    selectedViewId: -1,
    savedViews: [],
    isLoading: false,
    isInitialized: false
  };

  export function reducer(
    state: State = initalState,
    action: LogsViewsActions.Actions,
  ): State {
    let currState = null;
    let currViews = null;
    let selectedViewId = null;
    let targetIndex = null;

    switch (action.type) {
      case LogsViewsActions.ActionTypes.LOGS_VIEWS_LOAD:
      case LogsViewsActions.ActionTypes.LOGS_VIEW_SAVE:
      case LogsViewsActions.ActionTypes.LOGS_VIEW_DELETE:
        currState = _.cloneDeep(state);

        return Object.assign({}, state, {
          savedViews: currState.savedViews,
          selectedViewId: currState.selectedViewId,
          isLoading: true,
        });

      case LogsViewsActions.ActionTypes.LOGS_VIEWS_LOAD_FAILED:
      case LogsViewsActions.ActionTypes.LOGS_VIEW_SAVE_FAILED:
      case LogsViewsActions.ActionTypes.LOGS_VIEW_DELETE_FAILED:
        currState = _.cloneDeep(state);

        return Object.assign({}, state, {
          savedViews: currState.savedViews,
          selectedViewId: currState.selectedViewId,
          isLoading: false,
        });

      case LogsViewsActions.ActionTypes.LOGS_VIEWS_LOAD_COMPLETED:
        selectedViewId = _.cloneDeep(state.selectedViewId);

        return Object.assign({}, state, {
          savedViews: action.payload,
          selectedViewId,
          isLoading: false,
          isInitialized: true
        });

      case LogsViewsActions.ActionTypes.LOGS_VIEW_SAVE_COMPLETED:
        selectedViewId = _.cloneDeep(state.selectedViewId);
        currViews = _.cloneDeep(state.savedViews);

        targetIndex = currViews.findIndex((v) => v.id === action.payload.id);

        if (targetIndex !== -1) {
          currViews[targetIndex] = action.payload;
        } else {
          currViews.push(action.payload);
        }

        return Object.assign({}, state, {
          savedViews: currViews,
          selectedViewId,
          isLoading: false,
          isInitialized: true
        });
      case LogsViewsActions.ActionTypes.LOGS_VIEW_DELETE_COMPLETED:
        selectedViewId = _.cloneDeep(state.selectedViewId);
        currViews = _.cloneDeep(state.savedViews);

        targetIndex = currViews.findIndex((v) => v.id === action.payload);

        if (targetIndex !== -1) {
          currViews.splice(targetIndex, 1);
        }
        if (selectedViewId === action.payload) {
          selectedViewId = -1;
        }

        return Object.assign({}, state, {
          savedViews: currViews,
          selectedViewId,
          isLoading: false,
        });
      case LogsViewsActions.ActionTypes.LOGS_VIEW_SELECT:
        currState = _.cloneDeep(state);
        currViews = currState.savedViews;

        targetIndex = currViews.findIndex((v) => v.id === action.payload);

        selectedViewId = -1;
        if (targetIndex !== -1) {
          selectedViewId = action.payload;
        }

        return Object.assign({}, state, {
          savedViews: currViews,
          selectedViewId,
          isLoading: currState.isLoading,
        });
      default:
        return state;
    }
  }
}
