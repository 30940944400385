import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogQueryModel } from '../../../logs/shared/models/logsquery.model';
import { InsightsProvider } from '../../shared/services/insights.provider';
import { LogsService } from '../../../logs/shared/services/logs-service';
import { GridOptions } from 'ag-grid';
import { LogqueryModelTranslator } from '../../../logs/shared/translators/logquery-model.translator';
import { Observable, Subscription } from 'rxjs';
import { Alert } from '../../shared/models/alert.model';
import { AlertsService } from '../../../alerts/shared/services/alerts-service';
import { LogQueryData } from '../../../logs/shared/models/logquery.data';
import { formatsHelper } from '../../../shared/services/formatsHelper';
import { Insight } from '../../shared/models/insight.model';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';

@Component({
  selector: 'sh-advanced-alert',
  templateUrl: './advanced-alert.component.html',
  styleUrls: ['./advanced-alert.component.scss'],
})
export class AdvancedAlertComponent implements OnInit, OnDestroy {
  public queryData: LogQueryData;
  public gridOptions: GridOptions;
  public currentInsightsChangedSubscription: Subscription;
  public newQueryRequestedSubscription: Subscription;
  public isEmptyGrid: boolean = false;

  constructor(
    public insightsProvider: InsightsProvider,
    private logsService: LogsService,
    private alertsService: AlertsService,
    private htmlRowMeasure: HtmlRowMeasure,
  ) {}

  public ngOnInit() {
    this.currentInsightsChangedSubscription = this.insightsProvider.currentInsightChanged.subscribe(
      (insight) => {
        this.getLogs(insight, null);
      },
    );
    this.newQueryRequestedSubscription = this.logsService.newQueryRequest.subscribe(
      (logQueryModel) =>
        this.getLogs(
          this.insightsProvider.currentInsight,
          logQueryModel.sortModel,
        ),
      (error) => console.log(error),
    );
  }

  public ngOnDestroy() {
    if (this.currentInsightsChangedSubscription) {
      this.currentInsightsChangedSubscription.unsubscribe();
    }
    if (this.newQueryRequestedSubscription) {
      this.newQueryRequestedSubscription.unsubscribe();
    }
  }

  public getAdvancedAlertQueryModel(alert: Alert): Observable<LogQueryModel> {
    return this.alertsService
      .getAlertDefenition(alert.alertId)
      .map((alertDef) =>
        LogqueryModelTranslator.modelFromAlert(alert, alertDef),
      );
  }

  public getLogs(insight: Insight, sortmodel) {
    const alert = insight as Alert;
    this.getAdvancedAlertQueryModel(alert)
      .first()
      .subscribe((queryModel) => {
        if (sortmodel) {
          queryModel.sortModel = sortmodel;
          this.setFiltersByQueryModel(queryModel);
        } else {
          this.setFiltersByQueryModel(queryModel);
          this.logsService
            .getLogs(queryModel)
            .switchMap(logQueryRes => this.htmlRowMeasure.prepareRowsHeights(logQueryRes.logs)
              .mapTo(logQueryRes))
            .first()
            .subscribe(
              (res) => {
                this.isEmptyGrid = !res.total;
                if (this.isEmptyGrid) {
                  this.updateQueryLogCount(0);
                }
                this.queryData = new LogQueryData(
                  queryModel,
                  res,
                  this.logsService);
              }
            );
        }
      });
  }

  public onGridReady(options: GridOptions) {
    this.gridOptions = options;
    setTimeout(() => {
      const insight = this.insightsProvider.currentInsight;
      if (insight) {
        this.getLogs(insight, null);
      }
    }, 0);
  }

  public updateQueryLogCount(logCount: number) {
    this.insightsProvider.globalState.queryResultCount =
      formatsHelper.numberWithCommas(logCount) + ' Logs';
  }

  public setFiltersByQueryModel(queryModel: LogQueryModel) {
    const metadata = queryModel.queryParams.metadata;
    const filters = {};
    Object.keys(metadata).forEach(key => {
      const filter = metadata[key];
      if (filter) {
        filters['metadata.' + key] = filter;
      }
    });
    if (queryModel.queryParams.query.text) {
      filters['text'] = queryModel.queryParams.query.text;
    }
    if (filters && this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setFilterModel(filters);
    }
  }

  public onGridButtonClick(event) {
    this.insightsProvider.textInfoClicked.emit(event);
  }

  public onSelectedRow(e) {
    this.insightsProvider.globalState.selectedLog = e;
  }
}
