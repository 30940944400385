import { GroksActions } from './groks.actions';
import { GrokMatch } from '../../models/grok.model';
export namespace GroksReducer {
  export interface State {
    detectedGroks: GrokMatch[];
    searchingGroks: boolean;
  }

  const initialState = {
    detectedGroks: null,
    searchingGroks: false,
  };

  export function reducer(
    state: State = initialState,
    action: GroksActions.Actions,
  ): State {
    switch (action.type) {
      case GroksActions.ActionTypes.DETECT_GROKS:
        return Object.assign({}, state, { searchingGroks: true });
      case GroksActions.ActionTypes.DETECT_GROKS_COMPLETED:
        return Object.assign({}, state, {
          detectedGroks: action.payload,
          searchingGroks: false,
        });
      case GroksActions.ActionTypes.DETECT_GROKS_FAILED:
        return Object.assign({}, state, { searchingGroks: false });
      default:
        return state;
    }
  }
}
