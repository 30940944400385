<div class="metric-view" *ngIf="alert">
  <div body *ngIf="!spliced && alert.esInfo; else elseBlock">
    <sh-alert-view-layout alertType="METRIC ALERT" [title]="title" [subTitle]="subTitle" [tags]="tags" [alertId]="alert.alertId">
      <div body class="sh-rest-content">
        <sh-metric-alert-graph
          [alert]="alert"
          [chartName]=""
          [spliced]="true"
          [timeZone]="timeZone"
          [dateFormatType]="dateFormatType"
          [chartData]="chartResult$ | async"
        ></sh-metric-alert-graph>
        <div *ngIf="!isPromql && !isPrometheusMetric" class="view-logs-container">
          <cgx-button (press)="viewRelatedLogs()" label="View Related Logs"></cgx-button>
        </div>
      </div>
    </sh-alert-view-layout>
  </div>
  <ng-template #elseBlock>
    <sh-alert-view-layout alertType="METRIC ALERT" [title]="title" [subTitle]="subTitle" [tags]="tags" [alertId]="alert.alertId">
      <div body class="sh-rest-content" *ngFor="let data of chartResult$ | async">
        <sh-metric-alert-graph
          [alert]="alert"
          [chartName]="data.name"
          [spliced]="false"
          [timeZone]="timeZone"
          [dateFormatType]="dateFormatType"
          [chartData]="data.graphData"
        ></sh-metric-alert-graph>
        <div *ngIf="!isPromql && !isPrometheusMetric" class="view-logs-container">
          <cgx-button (press)="viewRelatedLogs()" label="View Related Logs"></cgx-button>
        </div>
      </div>
    </sh-alert-view-layout>
  </ng-template>
</div>
