import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/services/configuration.service';
import { IFactors } from '@app/tco/interfaces/factors.interface';
import { IPolicyCriteriaSerialized } from '@app/tco/serialized/policy.criteria.serialized';
import { PolicyCriteria } from '@app/tco/models/policy-criteria.model';
import { PolicyOverride } from '@app/tco/models/policy-override.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPolicyOverrideSerialized } from '@app/tco/serialized/policy-override.serialized';
import { ISetOverridesRequestBody } from '@app/tco/interfaces/set-overrides-request-body.interface';
import { ISetOverridesResponse } from '@app/tco/interfaces/set-overrides-response.interface';

@Injectable({
  providedIn: 'root'
})
export class QuotaPolicyService {

  constructor(private http: HttpClient, private configService: ConfigurationService) { }

  public createPolicy(body: IPolicyCriteriaSerialized): Observable<PolicyCriteria> {
    const url = this.configService.getConfigValue('url', '') + '/api/v1/quota-policies';
    return this.http.post<IPolicyCriteriaSerialized>(url, body).pipe(
      map(obj => PolicyCriteria.deserializer(obj))
    );
  }

  public editPolicy(body: IPolicyCriteriaSerialized, id: string): Observable<PolicyCriteria> {
    const url = this.configService.getConfigValue('url', '') + `/api/v1/quota-policies/${id}`;
    return this.http.put<IPolicyCriteriaSerialized>(url, body).pipe(
      map(obj => PolicyCriteria.deserializer(obj))
    );
  }

  public getPolicies(): Observable<PolicyCriteria[]> {
    const url = this.configService.getConfigValue('url', '') + '/api/v1/quota-policies';
    return this.http.get<IPolicyCriteriaSerialized[]>(url).pipe(
      map(policies => policies.map(policy => PolicyCriteria.deserializer(policy)))
    );
  }

  public deletePolicies(id: string): Observable<any> {
    const url = this.configService.getConfigValue('url', '') + `/api/v1/quota-policies/${id}`;
    return this.http.delete<any>(url);
  }

  public reorder(policyIds: string[]): Observable<any> {
    const url = this.configService.getConfigValue('url', '') + '/api/v1//quota-policies/reorder';
    return this.http.put<any>(url, policyIds);
  }

  public getOverridesAndFactors(startDate: Date, endDate: Date): Observable<{ factors: IFactors, overrides: PolicyOverride[] }> {
    const url = this.configService.getConfigValue('url', '') + `/api/v1/quota-overrides?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`;
    return this.http.get<{ factors: IFactors, overrides: IPolicyOverrideSerialized[] }>(url).pipe(
      map(({ factors, overrides }) => {
        return { factors, overrides: overrides.map(override => PolicyOverride.deserializer(override)) };
      })
    );
  }

  public setOverrides(body: ISetOverridesRequestBody[]): Observable<ISetOverridesResponse[]> {
    const url = this.configService.getConfigValue('url', '') + `/api/v1/quota-overrides`;
    return this.http.put<ISetOverridesResponse[]>(url, body);
  }

  public resetAllOverrides(): Observable<ISetOverridesResponse[]> {
    const url = this.configService.getConfigValue('url', '') + `/api/v1/quota-overrides`;
    return this.http.delete<ISetOverridesResponse[]>(url);
  }
}
