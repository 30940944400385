import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

import { QueryHistoryModel } from './query-history.model';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class LogsView {
  @jsonMember
  public id: number;
  @jsonMember
  public name: string;
  @jsonMember({ name: 'is_public' })
  public isPublic: boolean;
  @jsonMember({ name: 'is_mine' })
  public isMine: boolean;
  @jsonMember({ name: 'is_editable' })
  public isEditable: boolean;
  @jsonArrayMember(TypedJsonAny, Object.assign({ name: 'column_definitions', emitDefaultValue: true }, TypedJsonAny.OPTIONS))
  public logsGridColDefs: any[];
  @jsonArrayMember(TypedJsonAny, Object.assign({ name: 'columns_state', emitDefaultValue: true }, TypedJsonAny.OPTIONS))
  public logsGridColState: any[];
  @jsonMember({ name: 'query_model' })
  public queryHistoryModel: QueryHistoryModel;

  public isDefault: boolean;

  constructor(init?: Partial<LogsView>) {
    Object.assign(this, init);
  }
}
