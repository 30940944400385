import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  GroupByMetricAlertGraphResult,
  PromqlMetricAlertGraphResult,
  PromqlMetricAlertGraphData,
  MetricAlertGraphResult,
  MetricAlertQueryModel,
  PromqlMetricAlertQueryModel,
  NamedMetricAlertGraphData,
} from '../models/alert-metric.model';
import { Observable, throwError } from 'rxjs';
import { ConfigurationService } from '@app/services/configuration.service';

@Injectable()
export class MetricAlertService {
  metricAlertGraphQueryUrl = this.cs.getConfigURL('/api/v1/metric-alert/query');
  promqlMetricAlertGraphQueryUrl = this.cs.getConfigURL('/api/v1/metric-alert/query/promql');

  constructor(private http: HttpClient, private cs: ConfigurationService) {}

  public getMetricAlertGraphData(queryModel: MetricAlertQueryModel): Observable<NamedMetricAlertGraphData[]> {
    return this.http.post(this.metricAlertGraphQueryUrl, JSON.stringify(queryModel)).map((res: MetricAlertGraphResult) => {
      const data = new NamedMetricAlertGraphData('', res.graphData);

      return [data];
    });
  }

  public getGroupByMetricAlertGraphData(queryModel: MetricAlertQueryModel): Observable<NamedMetricAlertGraphData[]> {
    return this.http
      .post(this.metricAlertGraphQueryUrl, JSON.stringify(queryModel))
      .map((res: GroupByMetricAlertGraphResult) => {
        return res.groupByGraphData;
      })
      .catch(this.handleError);
  }

  public getPromqlMetricAlertGraphData(queryModel: PromqlMetricAlertQueryModel): Observable<PromqlMetricAlertGraphData[]> {
    return this.http
      .post(this.promqlMetricAlertGraphQueryUrl, JSON.stringify(queryModel))
      .map((res: PromqlMetricAlertGraphResult) => {
        return res.graphData;
      })
      .catch(this.handleError);
  }

  public getIntervalByTimerframe(timeframe: number): number {
    return timeframe <= 30 ? 1 : timeframe < 720 ? 5 : 10;
  }

  private handleError(error: any): Observable<never> {
    const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return throwError(error);
  }
}
