import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingGroksComponent } from './components/loading-groks/loading-groks.component';
import { GroksService } from './shared/services/groks.service';
import { SharedModule } from '../shared/shared.module';
import { DetectGroksDialogComponent } from './components/detect-groks-dialog/detect-groks-dialog.component';
import { GrokDefsService } from './shared/services/grokDefs.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  providers: [GroksService, GrokDefsService],
  declarations: [LoadingGroksComponent, DetectGroksDialogComponent],
  entryComponents: [DetectGroksDialogComponent],
})
export class GroksModule {}
