import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sh-livetail-banner',
  templateUrl: './livetail-banner.component.html',
  styleUrls: ['./livetail-banner.component.scss'],
})
export class LivetailBannerComponent implements OnInit {
  @Output() public startLiveTail: EventEmitter<any> = new EventEmitter();
  @Output() public showFilters: EventEmitter<any> = new EventEmitter();
  constructor() {}

  public ngOnInit() {}
}
