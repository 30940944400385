import { Injectable } from '@angular/core';
import { AlertEditControls, RelativeAlert } from '@app/alerts/alerts-editor/models/alert-edit-controls.model';
import { ActiveWhenTimeframe, AlertModel, Conditions, MetricAlert, NotificationsProperties } from '@app/alerts/shared/models/alert-model';
import { CreateNUpdateAlertModel, UserAlertTypes } from '@app/alerts/shared/models/create-n-update-alert-model';
import * as moment from 'moment';
import { AlertSearchQueryParserService } from '@app/alerts/alerts-editor/services/alert-search-query-parser.service';
import { AlertEditFromQueryModel } from '@app/alerts/alerts-editor/models/alert-edit-from-query.model';
import { AlertHelper } from '@app/alerts/shared/services/alert-helper';
import { MomentTimeFormatTypes } from '@shared/helpers/moment.helper';
import { ConditionsOptions } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { AlertSyntaxType, UserAlertViewTypes } from '@app/alerts/alerts-editor/models/alert-editor-view.models';
import { ConditionalSelectService } from '@app/shared/components/conditional-select/conditional-select.service';
import { takeWhile } from 'lodash';

@Injectable()
export class AlertMapperService {
  private readonly emailRegex: RegExp = new RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{1,63}$');

  constructor(
    private alertSearchQueryParserService: AlertSearchQueryParserService,
    private alertHelper: AlertHelper,
    private conditionalSelectService: ConditionalSelectService,
  ) {}

  public mapAlertModelToFormControlsModel(alertItem: AlertModel, isFromQuery: boolean, isLocalTime: boolean = true): AlertEditControls {
    const { conditions, notifProperties, activeWhen, metricAlert } = alertItem;
    const timeframe = activeWhen && activeWhen.timeframes ? this.getMappedTimeframe(activeWhen.timeframes, isLocalTime) : {};
    const mappedConditions = this.getMappedConditions(conditions, metricAlert);
    const mappedNotifications = this.getMappedNotifProperties(notifProperties);

    const groupByFields = this.evalGroupByFields(alertItem);
    const cardinalityFields = this.evalCardinalityFields(alertItem);
    const isCardinalityGroupBy = this.evalIsCardinalityGroupBy(alertItem);
    const relativeAlerts: RelativeAlert[] = [];

    if (alertItem.relativeAlerts && alertItem.relativeAlerts.length > 0) {
      alertItem.relativeAlerts.forEach(ra => {
        relativeAlerts.push({ ...ra, alertText: ra.text } as RelativeAlert);
      });
    } else {
      relativeAlerts.push(new RelativeAlert({}));
    }

    const refractoryPeriod = alertItem.refractoryPeriod || 60;

    const editFormControlsValues = new AlertEditControls({
      userAlertTypeId: alertItem.userAlertTypeId,
      isActive: alertItem.isActive,
      description: alertItem.description,
      alertName: alertItem.name,
      alertSyntaxType: this.getAlertSyntaxType(alertItem),
      alertSeverity: !alertItem.alertSeverity && !isFromQuery ? 1 : alertItem.alertSeverity,
      alertText: this.getTextFieldFromAlert(alertItem),
      applicationName: this.conditionalSelectService.mapToFormData(alertItem.applicationName),
      subsystemName: this.conditionalSelectService.mapToFormData(alertItem.subsystemName),
      logSeverity: alertItem.logSeverity,
      groupByFields,
      notifyGroupByOnlyAlerts: alertItem.notifyGroupByOnlyAlerts,
      cardinalityFields: cardinalityFields && cardinalityFields.length > 0 ? cardinalityFields[0] : 'None',
      cardinalityGroupByFields: alertItem.cardinalityGroupByFields,
      isCardinalityGroupBy,
      notifyOnResolved: alertItem.notifyOnResolved,
      relativeTimeframe: relativeAlerts[0]?.relativeTimeframe,
      notifyEveryMinutes: Math.floor(refractoryPeriod / 60) % 60,
      notifyEveryHours: Math.floor(alertItem.refractoryPeriod / 3600),
      relativeAlerts: relativeAlerts.map(relativeAlert => ({
        ...relativeAlert,
        applicationName: this.conditionalSelectService.mapToFormData(relativeAlert.applicationName as any),
        subsystemName: this.conditionalSelectService.mapToFormData(relativeAlert.subsystemName as any),
      })),
      ...timeframe,
      ...mappedConditions,
      ...mappedNotifications,
    });
    return editFormControlsValues;
  }

  public mapAlertModelFromQueryToForm(alertModel: AlertEditFromQueryModel, isLocalTime: boolean = true): AlertEditControls {
    const text = this.alertSearchQueryParserService.parseAlert(alertModel.queryParamsJson, alertModel.text);
    alertModel.text = text;
    const mappedForm = this.mapAlertModelToFormControlsModel(alertModel, true, isLocalTime);
    return mappedForm;
  }

  public mapFormToRequest(
    form: AlertEditControls,
    isEdit: boolean,
    alert: AlertModel,
    isLocalTime: boolean = true,
  ): CreateNUpdateAlertModel {
    const conditions = this.mapConditionsFromNewFormToRequest(form);
    const isRatioType = form.userAlertTypeId === UserAlertViewTypes.RatioAlert;
    const isRelativeTime = form.userAlertTypeId === UserAlertViewTypes.RelativeTime;
    if (!isRatioType && !isRelativeTime) {
      form.relativeAlerts = [];
    }
    let metricAlert: MetricAlert = null;
    const isMetricAlert = form.userAlertTypeId === UserAlertViewTypes.MetricAlert;
    if (isMetricAlert) {
      metricAlert = {
        arithmeticOperator: form.conditionMetricArithmeticOperator,
        arithmeticOperatorModifier: form.conditionMetricArithmeticModifier,
        metricFieldExtraction: form.conditionMetricField[0],
        metricId: form.conditionMetricField[1],
        valueThreshold: form.conditionMetricValueThreshold,
        swapNullValue: form.conditionSwapNullValue === true ? 0 : null,
        sampleThreshold: form.conditionSampleThreshold,
        nonNullPercentage: form.conditionNonNullPercentage,
        promqlText: form.alertText,
      };
    }
    const userAlertTypeId = this.getMappedAlertViewTypeToAlertType(form.userAlertTypeId);
    const notifyOnResolved: boolean = form.hasOwnProperty('notifyOnResolved') && !isRatioType ? form.notifyOnResolved : false;
    const groupByFields: string[] = this.calcGroupByFields(form);
    const cardinalityFields: string[] = this.calcCardinalityFields(form);

    const item: CreateNUpdateAlertModel = {
      id: isEdit && alert !== null ? alert.id : 0,
      description: form.description,
      endDate: isEdit && alert !== null ? alert.endDate : null,
      startDate:
        isEdit && alert !== null
          ? alert.endDate
          : moment()
              .subtract({ minutes: 15 })
              .unix() * 1000,
      userAlertTypeId,
      isActive: form.isActive,
      name: form.alertName,
      alertSeverity: form.alertSeverity,
      applicationName: this.conditionalSelectService.mapToRequest(form.applicationName),
      subsystemName: this.conditionalSelectService.mapToRequest(form.subsystemName),
      logSeverity: form.logSeverity,
      groupByFields,
      notifyGroupByOnlyAlerts: form.notifyGroupByOnlyAlerts,
      cardinalityFields,
      cardinalityGroupByFields: form.cardinalityGroupByFields,
      notifyOnResolved,
      notifPayloadFilter: form.notificationPayloadFilter,
      emailNotifAddresses: form.notificationsMails?.concat(form.integrations),
      activeWhen: {
        timeframes: this.mapTimeframesToRequest(form, isLocalTime),
      },
      ...conditions,
      relativeAlerts: form.relativeAlerts.map(relativeAlert => ({
        ...relativeAlert,
        applicationName: this.conditionalSelectService.mapToRequest(relativeAlert.applicationName),
        subsystemName: this.conditionalSelectService.mapToRequest(relativeAlert.subsystemName),
      })),
      metricAlert: metricAlert,
      ...this.mapTextFieldsToRequest(form, metricAlert),
    };

    return item;
  }

  public getAlertSyntaxType(alertItem: AlertModel): AlertSyntaxType {
    if (alertItem?.metricAlert?.promqlText) {
      return AlertSyntaxType.PromQL;
    }
    return AlertSyntaxType.Lucene;
  }

  public getMappedAlertViewTypeToAlertType(type: UserAlertViewTypes): UserAlertTypes {
    const mapObj = {
      [UserAlertViewTypes.Cardinality]: UserAlertTypes.Cardinality,
      [UserAlertViewTypes.NewValueAlert]: UserAlertTypes.UserTextAlert,
      [UserAlertViewTypes.RatioAlert]: UserAlertTypes.RatioAlert,
      [UserAlertViewTypes.UserTextAlert]: UserAlertTypes.UserTextAlert,
      [UserAlertViewTypes.RelativeTime]: UserAlertTypes.RelativeTime,
      [UserAlertViewTypes.MetricAlert]: UserAlertTypes.MetricAlert,
    };

    return mapObj[type];
  }

  public getMappedAlertTypeToAlertViewType(type: UserAlertTypes): UserAlertViewTypes {
    const mapObj = {
      [UserAlertTypes.Cardinality]: UserAlertViewTypes.Cardinality,
      [UserAlertTypes.RatioAlert]: UserAlertViewTypes.RatioAlert,
      [UserAlertTypes.UserTextAlert]: UserAlertViewTypes.UserTextAlert,
      [UserAlertTypes.RelativeTime]: UserAlertViewTypes.RelativeTime,
      [UserAlertTypes.MetricAlert]: UserAlertViewTypes.MetricAlert,
    };

    return mapObj[type];
  }

  private mapTextFieldsToRequest(
    form: AlertEditControls,
    metricAlert: MetricAlert,
  ): {
    text: string;
    metricAlert?: MetricAlert;
  } {
    if (!metricAlert) {
      return {
        text: form.alertText,
      };
    }

    if (form.alertSyntaxType === AlertSyntaxType.PromQL) {
      return {
        text: '',
        metricAlert: {
          ...metricAlert,
          promqlText: form.alertText,
        },
      };
    }

    return {
      text: form.alertText,
      metricAlert: {
        ...metricAlert,
        promqlText: null,
      },
    };
  }

  private getTextFieldFromAlert(alert: AlertModel): string {
    return alert?.metricAlert?.promqlText ?? alert.text;
  }

  private mapTimeframesToRequest(form: AlertEditControls, isLocalTime: boolean): ActiveWhenTimeframe[] {
    if (form.limitTriggering) {
      const daysOfWeek = isLocalTime ? this.alertHelper.transformDaysToUTC(form.activityStarts, form.daysOfWeek) : form.daysOfWeek;
      const startTime = this.getMappedHoursToRequest(form.activityStarts, isLocalTime);
      const endTime = this.getMappedHoursToRequest(form.activityEnds, isLocalTime);
      return [
        {
          days_of_week: daysOfWeek,
          activity_starts: startTime,
          activity_ends: endTime,
        },
      ];
    } else {
      return [];
    }
  }

  private getMappedHoursToRequest(hours: string, isLocalTime: boolean): string {
    const newHours = moment(hours, MomentTimeFormatTypes.standard);
    return (isLocalTime ? newHours.utc(false) : newHours).format(MomentTimeFormatTypes.standard);
  }

  private mapConditionsFromNewFormToRequest(form: AlertEditControls): Partial<CreateNUpdateAlertModel> {
    const isNewAlertForm = form.userAlertTypeId === UserAlertViewTypes.NewValueAlert;
    const refractoryPeriod = this.calcRefractoryPeriod(form);
    if (form.conditionOperator === ConditionsOptions.notifyImmediately && !isNewAlertForm) {
      return {
        hasConditions: false,
        refractoryPeriod,
      };
    }
    const returnConditions = {
      hasConditions: true,
      conditionOperator: form.conditionOperator,
      conditionThreshold: form.conditionThreshold,
      conditionTimeframe: form.conditionTimeframe,
      refractoryPeriod,
    };
    if (isNewAlertForm) {
      return {
        ...returnConditions,
        conditionOperator: ConditionsOptions.newValue,
        conditionThreshold: 1,
      };
    }
    return returnConditions;
  }

  private getMappedTimeframe(timeframes: ActiveWhenTimeframe[], isLocalTime: boolean): Partial<AlertEditControls> {
    if (timeframes.length === 0) {
      return {
        limitTriggering: false,
      };
    }
    const timeframe = timeframes[0];
    const utcStartDate = moment.utc(timeframe.activity_starts, 'HH:mm:ss');
    const utcEndDate = moment.utc(timeframe.activity_ends, 'HH:mm:ss');
    const mappedTimeframe: Partial<AlertEditControls> = {
      limitTriggering: true,
      daysOfWeek: timeframe.days_of_week,
      activityStarts: isLocalTime ? utcStartDate.local().format('HH:mm:ss') : utcStartDate.format('HH:mm:ss'),
      activityEnds: isLocalTime ? utcEndDate.local().format('HH:mm:ss') : utcEndDate.format('HH:mm:ss'),
    };

    return mappedTimeframe;
  }

  private evalGroupByFields(alert: AlertModel): string[] {
    if (alert.userAlertTypeId === UserAlertViewTypes.Cardinality) {
      return Array.isArray(alert.cardinalityFields) && alert.cardinalityFields ? alert.groupByFields : [];
    }
    return alert.groupByFields || [];
  }

  private evalCardinalityFields(alert: AlertModel): string[] {
    if (alert.userAlertTypeId === UserAlertViewTypes.Cardinality) {
      return Array.isArray(alert.cardinalityFields) && alert.cardinalityFields ? alert.cardinalityFields : alert.groupByFields || [];
    }
    return [];
  }

  private evalIsCardinalityGroupBy(alert: AlertModel): boolean {
    return (
      alert.userAlertTypeId === UserAlertViewTypes.Cardinality &&
      Array.isArray(alert.cardinalityFields) &&
      !!alert.cardinalityFields.length &&
      !!alert.groupByFields?.length
    );
  }

  private getMappedConditions(conditions: Conditions, metricAlert: MetricAlert): Partial<AlertEditControls> {
    if (!conditions) {
      return {
        conditionOperator: ConditionsOptions.notifyImmediately,
        conditionThreshold: 1,
        conditionTimeframe: 10,
        conditionMetricArithmeticOperator: 0,
        conditionMetricArithmeticModifier: null,
        conditionMetricField: ['', ''],
        conditionMetricValueThreshold: 0,
        conditionSwapNullValue: false,
        conditionSampleThreshold: 0,
        conditionNonNullPercentage: 0,
      };
    }

    const { conditionTimeframe, conditionThreshold, conditionOperator } = conditions;

    const mappedConditions: Partial<AlertEditControls> = {
      conditionTimeframe,
      conditionThreshold,
      conditionOperator,
      conditionMetricArithmeticOperator: metricAlert?.arithmeticOperator,
      conditionMetricArithmeticModifier: metricAlert?.arithmeticOperatorModifier,
      conditionMetricField: [metricAlert?.metricFieldExtraction, metricAlert?.metricId],
      conditionMetricValueThreshold: metricAlert?.valueThreshold,
      conditionSwapNullValue: metricAlert?.swapNullValue === 0,
      conditionSampleThreshold: metricAlert?.sampleThreshold,
      conditionNonNullPercentage: metricAlert?.nonNullPercentage,
    };
    return mappedConditions;
  }

  private getMappedNotifProperties(notifProperties: NotificationsProperties): Partial<AlertEditControls> {
    if (!notifProperties) {
      return {};
    }
    const notifications: Partial<AlertEditControls> = {
      notificationPayloadFilter: notifProperties.payloadFilter,
      notificationsMails: [],
      integrations: [],
    };

    if (notifProperties.emailAddresses) {
      // Split emails field onto emails and integrations
      notifProperties.emailAddresses.forEach(item => {
        this.emailRegex.test(item) ? notifications.notificationsMails.push(item) : notifications.integrations.push(item);
      });
    }

    return notifications;
  }

  private calcRefractoryPeriod(form: AlertEditControls): number {
    const refractoryPeriod: number =
      form.userAlertTypeId === UserAlertViewTypes.Cardinality
        ? form.conditionTimeframe * 60
        : form.notifyEveryHours * 3600 + form.notifyEveryMinutes * 60 || 600;
    return refractoryPeriod;
  }

  private calcGroupByFields(form: AlertEditControls): string[] {
    const notNone = field => field && field !== 'None';
    if (
      (form.userAlertTypeId === UserAlertViewTypes.UserTextAlert && form.conditionOperator === ConditionsOptions.more) ||
      form.userAlertTypeId === UserAlertViewTypes.MetricAlert
    ) {
      return takeWhile(form.groupByFields, notNone);
    }
    if (form.userAlertTypeId === UserAlertViewTypes.Cardinality) {
      return form.isCardinalityGroupBy ? [form.groupByFields[0]] : [];
    }
    if ([ConditionsOptions.more, ConditionsOptions.moreThanUsual, ConditionsOptions.newValue].includes(form.conditionOperator)) {
      return notNone(form.groupByFields[0]) ? [form.groupByFields[0]] : [];
    }
    return [];
  }

  private calcCardinalityFields(form: AlertEditControls): string[] {
    return form.userAlertTypeId === UserAlertViewTypes.Cardinality && form.cardinalityFields
      ? [form.cardinalityFields]
      : [form.groupByFields[0]];
  }
}
