export class Metadata {
  public category?: any[];
  public className?: any[];
  public methodName?: any[];
  public applicationName: any[] = [];
  public subsystemName: any[] = [];
  public computerName?: any[];
  public severity: number[] = [1, 2, 3, 4, 5, 6];
  public IPAddress?: any[];
  public sdkId?: any[];
  public threadId?: string;
  public companyId?: number;
  public priorities?: string[];
  public freeText?: string;

  constructor(data: Partial<Metadata> = null) {
    if (!data) {
      return;
    }
    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });
  }
}
