import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid';
import * as _ from 'lodash';

import { LogColumn } from '../models/log-column';
import { MasterFilterComponent } from '../controls/grid-filters/master-filter/master-filter.component';
import { escapeHTML } from './utils';

@Injectable()
export class GridColumnService {

  public static getListValues(propertyPath: any, data: object): any[] {
    const result = [];
    if (propertyPath && propertyPath.length > 0) {
      if (Array.isArray(data)) {
        for (const item of data) {
          result.push(...this.getListValues(propertyPath, item));
        }
      } else if (typeof data === 'object') {
        if (data[propertyPath[0]]) {
          result.push(...this.getListValues(propertyPath.slice(1, propertyPath.length), data[propertyPath[0]]));
        } else if (data[propertyPath.join('.')]) {
          result.push((data[propertyPath.join('.')]));
        }
      } else {
        if (!_.isUndefined(data)) {
          result.push(data);
        }
      }
    }
    return result;
  }

  private columnsDisplayNameMappings: object = {};
  private defaultColumns: string[] = [
    'coralogix.timestamp',
    'coralogix.metadata.severity',
    'coralogix.text',
    'coralogix.metadata.applicationName',
    'coralogix.metadata.subsystemName'
  ];

  constructor() {
    this.initColumnsDisplayNameMappings();
  }

  public getDefaultColumns(): string[] {
    return this.defaultColumns;
  }

  public getColumnDisplayName(key: string): string {
    if (!this.columnsDisplayNameMappings[key]) {
      return key;
    }

    return this.columnsDisplayNameMappings[key];
  }

  public createGridColumnDefFromString(colName: string, useCustomFilter: boolean = false): any {
    const logColumn = new LogColumn();
    logColumn.id = -1;
    logColumn.headerName = colName;
    logColumn.fieldName = colName;

    return this.createGridColumnDef(logColumn, useCustomFilter);
  }

  public createGridColumnDef(logColumn: LogColumn, useCustomFilter: boolean = false): any {
    const colDef: ColDef = {
      headerName: logColumn.headerName,
      field: 'textObject.' + logColumn.fieldName,
      width: 200,
      suppressSorting: false,

      cellRenderer(params: any): HTMLElement | string {
        let res = '';
        let isNumericRes = false;

        if (params.value) {
          const paramType = typeof params.value;
          if (paramType === 'string' || paramType === 'number') {
            res = params.value;
            if (paramType === 'number') {
              isNumericRes = true;
            }
          } else {
            try {
              const parsedJson = JSON.stringify(params.value);
              res = parsedJson;
            } catch (e) { /* do nothing */ }
          }
        } else {
          if (params.data && params.data.textObject) {
            const propertyPath = params.colDef.headerName.split('.');
            const listValues = GridColumnService.getListValues(propertyPath, params.data.textObject);
            try {
              if (listValues && listValues.length > 0) {
                res = listValues.join(', ');
              }
            } catch (e) { /* do nothing */ }
          }
        }

        return `<p class="fade-bottom">${isNumericRes ? res : escapeHTML(res)}</p>`;
      }
    };
    if (useCustomFilter) {
      colDef['filterFramework'] = MasterFilterComponent;
    } else {
      colDef['suppressFilter'] = true;
    }
    return colDef;
  }

  private initColumnsDisplayNameMappings(): void {
    this.columnsDisplayNameMappings['coralogix.timestamp'] = 'Timestamp';
    this.columnsDisplayNameMappings['coralogix.metadata.severity'] = 'Severity';
    this.columnsDisplayNameMappings['coralogix.text'] = 'Text';
    this.columnsDisplayNameMappings['coralogix.metadata.applicationName'] = 'Application';
    this.columnsDisplayNameMappings['coralogix.metadata.subsystemName'] = 'Subsystem';
    this.columnsDisplayNameMappings['coralogix.metadata.category'] = 'Category';
    this.columnsDisplayNameMappings['coralogix.metadata.computerName'] = 'Computer';
    this.columnsDisplayNameMappings['coralogix.metadata.IPAddress'] = 'Ip address';
    this.columnsDisplayNameMappings['coralogix.metadata.className'] = 'Class';
    this.columnsDisplayNameMappings['coralogix.metadata.methodName'] = 'Method';
    this.columnsDisplayNameMappings['coralogix.metadata.threadId'] = 'Thread Id';
  }
}
