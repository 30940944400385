<button class="ui icon button" #dropdown [disabled]="!selectedLog">
  <!--<i class="linkify icon inverted"></i>-->
  <div style="display: -webkit-box;height: 20px;">
    <p style="margin-right: 1px;">+</p>
    \
    <p style="margin-right: 1px"></p>
    <p>-</p>
  </div>
</button>
<div class="ui special popup menu-popup">
  <div class="title">Query selected log before &amp; After</div>
  <div class="popup-content">
    <div class="sh-item" (click)="adjustTimeSpan(5)">
      <i class="wait icon"></i>
      5s
    </div>
    <div class="sh-item" (click)="adjustTimeSpan(30)">
      <i class="wait icon"></i>
      30s
    </div>
    <div class="sh-item" (click)="adjustTimeSpan(60)">
      <i class="wait icon"></i>
      1m
    </div>
    <div class="sh-item" (click)="adjustTimeSpan(300)">
      <i class="wait icon"></i>
      5m
    </div>
    <div class="sh-item" (click)="adjustTimeSpan(600)">
      <i class="wait icon"></i>
      10m
    </div>
  </div>
</div>
