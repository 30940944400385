import * as moment from 'moment';
import { Moment } from 'moment';

export enum AmPmMomentOptions {
  smallLetters = 'a',
  bigLetters = 'A',
}

export enum MomentDateFormatTypes {
  european = 'DD/MM/YYYY',
  american = 'MM/DD/YYYY',
  shortEuropean = 'DD/MM',
  shortAmerican = 'MM/DD',
}

export enum MomentTimeFormatTypes {
  standard = 'HH:mm:ss',
  withMilliseconds = 'HH:mm:ss.SSS',
}

export enum DateInputType {
  epoch = 'epoch',
  unix = 'unix',
}

export const ShortMonthDayYear = 'MMM DD, YYYY';

// November 29th, 2020 5:03 PM
export const MomentHistoryDateTime = 'MMMM Do, YYYY LT';

export const InvalidDateResult: string = 'Invalid date';

export function getDateFromUnixValue(value: number, keepLocalTime: boolean, fullDateTimeFormat: string): string {
  const result = moment(value / 10000)
    .utc(keepLocalTime)
    .format(fullDateTimeFormat);

  if (result === InvalidDateResult) {
    return null;
  }
  return result;
}

export function getDateFromEpochValue(value: number, keepLocalTime: boolean, fullDateTimeFormat: string): string {
  const result = moment(value * 1000)
    .utc(keepLocalTime)
    .format(fullDateTimeFormat);
  if (result === InvalidDateResult) {
    return null;
  }
  return result;
}

export function localDateToUtcDate(date: Date): Moment {
  const utcGap = new Date().getTimezoneOffset();
  return moment(date).add(utcGap, 'm');
}

export function utcDateToLocalDate(date: Date): Moment {
  const utcGap = new Date().getTimezoneOffset();
  return moment(date).subtract(utcGap, 'm');
}
