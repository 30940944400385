import { ISearchQueryEvent } from '@app/logs-new/shared/models/search.model';
import { IAddSelectedQueryFieldEvent } from '@app/logs-new/shared/models/logs-filter.model';
import { Observable } from 'rxjs';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { LogsContent } from '@app/logs-new/shared/models/logs-content.model';

export enum ChartData {
  Label = 0,
  Amount = 1,
}

export enum FieldVisualizationEvent {
  AddToSearchQuery,
  AddSelectedQueryField,
}

export interface IFieldVisualizationRes {
  type: FieldVisualizationEvent;
  payload: ISearchQueryEvent | IAddSelectedQueryFieldEvent;
}

export interface IFieldVisualizationDialogData {
  chartData: Array<ChartData[]>;
  chartData$: Observable<any>;
  title: string;
  jsonPath: string;
  customMap: { [key: string]: string };
  logsQuery$: Observable<ILogQuery>;
  activeTab$: Observable<LogsContent>;
}
