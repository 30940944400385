import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TypedJSON } from 'typedjson';
import { MappingStats } from '@app/user/shared/models/mapping-stats.model';
import { Constants } from '@app/constants';
import moment from 'moment';
import _ from 'lodash';

@Injectable()
export class MappingStatsService {

  constructor(private http: HttpClient) {}

  public getMappingStats(): Observable<MappingStats> {
      return this.http.post(Constants.statisticsUrl + '/mapping', {})
      .map(res => TypedJSON.parse(res, MappingStats));
  }
}
