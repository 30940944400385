<div class="selected-tag-box">
  <div class="selected-tag-box__title">Error Volume
    <ng-container *ngIf="delta$ | async as delta">- {{delta}}</ng-container>
  </div>

  <mat-card *ngIf="(viewState$ | async) === completedViewState" class="selected-tag-box__card selected-tag-box__content">
        <sh-summary-line-chart
          class="selected-tag-box__line-chart"
          [legend]="lineGraphLegend"
          [chartData]="graphInfo"
          (handleTooltipPress)="navigateToLogs($event)"
        ></sh-summary-line-chart>
  </mat-card>
  <sh-selected-tag-feature-layout [viewState]="viewState$ | async" noResultLabel="No New Errors Since Last Tag">
  </sh-selected-tag-feature-layout>
</div>
