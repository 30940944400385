import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { TeamsReducer } from '@app/user/state/teams/teams.reducer';

export const selectTeamsData = createSelector(
  createFeatureSelector<State, TeamsReducer.State>('teams'),
  (state) => state,
);

export const selectTeamsFilters = createSelector(selectTeamsData, (state) => state.teams.reduce((acc: number[][], team) => {
  acc.push([team.id, 0]);
  return acc;
}, [] ));

export const selectTeamsMap = createSelector(selectTeamsData, (state) => state.teams.reduce((acc: {[id: string]: string} , team) => {
  acc[team.id] = team.name;
  return acc;
}, {} ));

export const selectTeamById = (teamId: number | string) => createSelector(
  selectTeamsMap,
  (teamsMap) => teamsMap[teamId]
);

export const selectCurrentTeamId = createSelector(selectTeamsData, (state) => state.selectedTeamId);
