import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamItemComponent } from './team-item.component';
import { SearchHighlightModule } from '@app/shared/pipes/search-highlight/search-highlight.module';
import { AvatarInitialsModule } from '@app/shared/avatar-initials/avatar-initials.module';

@NgModule({
  declarations: [TeamItemComponent],
  imports: [
    CommonModule,
    SearchHighlightModule,
    AvatarInitialsModule
  ],
  exports: [TeamItemComponent]
})
export class TeamItemModule { }
