import {ReindexEditorActions} from "@app/roi/reindex/state/actions/reindex-editor.actions";


export namespace ReindexEditorReducer {

  export interface State {
    isOpen: boolean;
    isS3PermissionOpen: boolean;
  }

  const initialState: State = {
    isOpen: false,
    isS3PermissionOpen: false
  };

  export function reducer(state: ReindexEditorReducer.State = initialState, action: ReindexEditorActions.Actions): ReindexEditorReducer.State {
    switch (action.type) {
      case ReindexEditorActions.ActionTypes.TOGGLE_EDITOR:
        return { ...state, ...{ isOpen: action.payload } };
      case ReindexEditorActions.ActionTypes.TOGGLE_S3_PERMISSIONS_EDITOR:
        return { ...state, ...{ isS3PermissionOpen: action.payload } };
      default:
        return state;
    }
  }
}
