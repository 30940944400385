import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { getSelectedTagQueryBase, State } from '@app/app.reducers';
import { ITagRequest } from '@app/deployment/shared/models/summary.models';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { LogsService as LogsNewService } from '@app/logs-new/shared/services/logs.service';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { getLogsBaseQueryModel } from '@app/deployment/shared/helpers/helper-functions';
import { BehaviorSubject, Subject } from 'rxjs';
import { LogsColumnsService } from '@app/logs-new/shared/services/logs-columns.service';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { ColDef } from 'ag-grid-community';
import { ILog } from '@app/logs-new/shared/models/logs-data.model';

@Component({
  selector: 'sh-newly-introduced-logs',
  templateUrl: './newly-introduced-logs.component.html',
  styleUrls: ['./newly-introduced-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewlyIntroducedLogsComponent implements AfterViewInit, OnDestroy, OnInit {
  columnDefs$ = new BehaviorSubject<ColDef[]>([]);
  rowsData$ = new BehaviorSubject<ILog[]>([]);
  logsCount$ = new BehaviorSubject<number>(0);
  private destroyed$: Subject<unknown> = new Subject();

  constructor(private logsNewService: LogsNewService, private store: Store<State>, private logsService: LogsService) {}

  ngAfterViewInit(): void {
    this.getSelectedTagQueryBase();
    this.newQueryRequest();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.setInitialCols();
  }

  getSelectedTagQueryBase(): void {
    this.store
      .select(getSelectedTagQueryBase())
      .pipe(
        filter(tagData => !!tagData),
        takeUntil(this.destroyed$),
      )
      .subscribe(data => {
        this.setQueryData(data);
      });
  }

  newQueryRequest(): void {
    this.logsService.newQueryRequest.pipe(takeUntil(this.destroyed$)).subscribe((queryModel: LogQueryModel) => {
      this.getLogs(queryModel);
    });
  }

  private setInitialCols(): void {
    const extraCols = [
      'coralogix.metadata.category',
      'coralogix.metadata.computerName',
      'coralogix.metadata.IPAddress',
      'coralogix.metadata.className',
      'coralogix.metadata.methodName',
      'coralogix.metadata.threadId',
    ];
    const colsDefs = LogsColumnsService.getDefaultLogsCols(extraCols).colsDefs;
    const colsService = new LogsColumnsService({ colsDefs });
    this.columnDefs$.next(colsService.getAllColumnsDefinitions(false));
  }

  private setQueryData(tag: ITagRequest): void {
    const model: LogQueryModel = getLogsBaseQueryModel(tag, this.logsService.getNewQueryId());
    model.type = QueryTypes.NEWLOGS;
    this.getLogs(model);
  }

  private getLogs(logQuery: LogQueryModel): void {
    this.logsNewService.getLogs((logQuery as unknown) as ILogQuery).subscribe(res => {
      this.logsCount$.next(res?.total ?? 0);
      this.rowsData$.next(res?.logs ?? []);
    });
  }
}
