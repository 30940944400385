<sh-coralogix-sidebar #sidebarComponent [templateRef]="editor"></sh-coralogix-sidebar>
<ng-template #editor>
  <sh-archive-query-form
    [form]="form"
    [queryId]="archiveQueryId"
    [logsSeverityOptions]="logsSeverityOptions"
    [isPrePopulated]="isPrePopulated"
    [validationError]="validationTextError"
    [isEditable]="isEditable"
    (submitEvent)="submit($event)"
  ></sh-archive-query-form>
</ng-template>
