import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { EnrichmentsService } from '../../../shared/services/enrichment.service';
import { Observable } from 'rxjs/Observable';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { EnrichmentsActions } from './enrichment.actions';
import { of } from 'rxjs/observable/of';
import { State } from '../../../../app.reducers';
import { switchMap, tap } from 'rxjs/operators';
import { CustomEnrichmentsService } from '@app/settings/shared/services/custom-enrichments.service';
import {
  ChangeEnrichmentResponse,
  UploadUrlResponse,
} from '@app/settings/enrichments-settings/custom-enrichment.model';
import CustomEnrichmentsLoadingAction = EnrichmentsActions.CustomEnrichmentsLoadingAction;
import { ShDialogService } from '@shared/services/dialogService';
import GetCustomEnrichmentsAction = EnrichmentsActions.GetCustomEnrichmentsAction;

@Injectable()
export class EnrichmentEffects {

  @Effect()
  public get$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.GET_ENRICHMENTS),
    switchMap((action) => {
      return this.enrichmentService
        .getEnrichments()
        .map(
          (enrichments) =>
            new EnrichmentsActions.GetEnrichmentsCompletedAction(enrichments),
        )
        .catch((err) =>
          of(new EnrichmentsActions.GetEnrichmentsFailedAction(err)),
        );
    }),
  );

  @Effect()
  public delete: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.DELETE_ENRICHMENTS),
    switchMap((action: any) => {
      return this.enrichmentService
        .deleteEnrichments(action.payload)
        .map(
          (enrichments) =>
            new EnrichmentsActions.DeleteEnrichmentsCompletedAction(
              enrichments,
            ),
        )
        .catch((err) =>
          of(new EnrichmentsActions.DeleteEnrichmentsFailedAction(err)),
        );
    }),
  );

  @Effect()
  public create: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.CREATE_ENRICHMENTS),
    switchMap((action: any) => {
      return this.enrichmentService
        .createEnrichments(action.payload)
        .map(
          (enrichments) =>
            new EnrichmentsActions.CreateEnrichmentsCompletedAction(
              enrichments,
            ),
        )
        .catch((err) =>
          of(new EnrichmentsActions.CreateEnrichmentsFailedAction(err)),
        );
    }),
  );

  @Effect()
  public getCustom$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.GET_CUSTOM_ENRICHMENTS),
    switchMap((action) => {
      return this.customEnrichmentService
        .getEnrichments()
        .map(
          (customEnrichments) =>
            new EnrichmentsActions.GetCustomEnrichmentsCompletedAction(customEnrichments),
        )
        .catch((err) =>
          of(new EnrichmentsActions.GetCustomEnrichmentsFailedAction(err)),
        );
    }),
  );

  @Effect()
  public createCustom$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.CREATE_CUSTOM_ENRICHMENT),
    switchMap((action: any) => {
      this.store.dispatch(new CustomEnrichmentsLoadingAction(true));
      const { name, description, file } = action.payload;
      return this.customEnrichmentService
        .getUploadUrl(file.name)
        .flatMap(
          (uploadUrlResponse: UploadUrlResponse) =>
            this.customEnrichmentService
              .uploadToS3(uploadUrlResponse.url, file.type, file)
              .flatMap(_ =>
                this.customEnrichmentService
                  .addCustomEnrichment(name, description, uploadUrlResponse.s3Key).map(
                  (customEnrichments: ChangeEnrichmentResponse) =>
                    new EnrichmentsActions.CreateCustomEnrichmentCompletedAction(customEnrichments),
                ),
              ),
        )
        .catch((err) => {
            return of(new EnrichmentsActions.CreateCustomEnrichmentFailedAction(err));
          },
        ).finally(() => this.store.dispatch(new CustomEnrichmentsLoadingAction(false)));
    }),
  );

  @Effect()
  public createCustomSucceeded$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.CREATE_CUSTOM_ENRICHMENT_COMPLETED),
    switchMap((action: any) => {
      this.shDialogService.showSnackBar('Successfully created enrichment');
      return of(new GetCustomEnrichmentsAction());
    }),
  );

  @Effect({ dispatch: false })
  public createCustomFailed$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.CREATE_CUSTOM_ENRICHMENT_FAILED),
    tap((action: any) => {
      this.shDialogService.showSnackBar('Failed to create enrichment');
    }),
  );

  @Effect()
  public updateCustom$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.UPDATE_CUSTOM_ENRICHMENT),
    switchMap((action: any) => {
      this.store.dispatch(new CustomEnrichmentsLoadingAction(true));
      const customEnrichmentId = action.payload[0];
      const { name, description, file } = action.payload[1];
      let s3Key$: Observable<string> = new Observable(subscriber => subscriber.next(null));
      if (file) {
        s3Key$ = this.customEnrichmentService
          .getUploadUrl(file.name)
          .flatMap(
            (uploadUrlResponse: UploadUrlResponse) =>
              this.customEnrichmentService
                .uploadToS3(uploadUrlResponse.url, file.type, file)
                .map(_ => uploadUrlResponse.s3Key));
      }
      return s3Key$.flatMap(s3Key =>
        this.customEnrichmentService
          .updateCustomEnrichment(customEnrichmentId, name, description, s3Key).map(
          customEnrichments => {
            this.store.dispatch(new CustomEnrichmentsLoadingAction(false));
            return new EnrichmentsActions.UpdateCustomEnrichmentCompletedAction(customEnrichments);
          },
        ),
      )
        .catch((err) => {
            this.store.dispatch(new CustomEnrichmentsLoadingAction(false));
            return of(new EnrichmentsActions.UpdateCustomEnrichmentFailedAction(err));
          },
        );
    }),
  );

  @Effect()
  public updateCustomSucceeded$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.UPDATE_CUSTOM_ENRICHMENT_COMPLETED),
    switchMap((action: any) => {
      this.shDialogService.showSnackBar('Successfully updated enrichment');
      return of(new GetCustomEnrichmentsAction());
    }),
  );

  @Effect({ dispatch: false })
  public updateCustomFailed$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.UPDATE_CUSTOM_ENRICHMENT_FAILED),
    tap((action: any) => {
      this.shDialogService.showSnackBar('Failed to update enrichment');
    }),
  );

  @Effect()
  public deleteCustom$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.DELETE_CUSTOM_ENRICHMENT),
    switchMap((action: any) => {
      return this.customEnrichmentService
        .deleteCustomEnrichment(action.payload)
        .map(
          (customEnrichments) =>
            new EnrichmentsActions.DeleteCustomEnrichmentCompletedAction(customEnrichments),
        )
        .catch((err) =>
          of(new EnrichmentsActions.DeleteCustomEnrichmentFailedAction(err)),
        );
    }),
  );

  @Effect()
  public deleteCustomSucceeded$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.DELETE_CUSTOM_ENRICHMENT_COMPLETED),
    switchMap((action: any) => {
      this.shDialogService.showSnackBar('Successfully deleted enrichment');
      return of(new GetCustomEnrichmentsAction());
    }),
  );

  @Effect({ dispatch: false })
  public deleteCustomFailed$: Observable<Action> = this.actions$.pipe(
    ofType(EnrichmentsActions.ActionTypes.DELETE_CUSTOM_ENRICHMENT_FAILED),
    tap((action: any) => {
      this.shDialogService.showSnackBar('Failed to delete enrichment');
    }),
  );

  constructor(
    private actions$: Actions,
    private enrichmentService: EnrichmentsService,
    private customEnrichmentService: CustomEnrichmentsService,
    private store: Store<State>,
    private shDialogService: ShDialogService,
  ) {
  }
}
