import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class Metadata {
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public category?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public className?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public methodName?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public applicationName: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public subsystemName: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public computerName?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public severity: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public IPAddress?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public sdkId?: any[] = [];
  @jsonMember
  public threadId?: string = '';
  @jsonMember
  public companyId?: number;
  @jsonArrayMember(Number)
  public priorities?: string[] = [];
  @jsonMember
  public freeText?: string = '';

  constructor(data: Partial<Metadata> = null) {
    if (!data) {
      return;
    }
    Object.keys(this).forEach((key) => {
      this[key] = data[key];
    });
  }
}

export interface IGenericMetadata<T> {
  [key: string]: T;
}

export type GenericMetadataType = IGenericMetadata<string[] | string>;
