<table
  mat-table
  [dataSource]="dataSource"
>
  <ng-container matColumnDef="symbol">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element" [ngStyle]="{'width.px': symbolColWidth}">
      <div class="symbol">
        <span>
           {{element.symbol}}
        </span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="explanation">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element">
      <div class="explanation" [innerHtml]="element.explanation">
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="example">
    <th mat-header-cell *matHeaderCellDef>EXAMPLE</th>
    <td mat-cell *matCellDef="let element">
      <div class="example">
        <span [innerHtml]="element.example">
        </span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="match">
    <th mat-header-cell *matHeaderCellDef>MATCH</th>
    <td mat-cell *matCellDef="let element">
      <p class="match" *ngFor="let item of element.match" [innerHtml]="item"></p>
    </td>
  </ng-container>
  <ng-container matColumnDef="noMatch">
    <th mat-header-cell *matHeaderCellDef>NO MATCH</th>
    <td mat-cell *matCellDef="let element">
      <p class="no-match" *ngFor="let item of element.noMatch" [innerHtml]="item"></p>
    </td>
  </ng-container>
  <tr [hidden]="hideHeader "mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
