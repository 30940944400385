import { Insight } from './insight.model';
import * as _ from 'lodash';
import { InsightTypeId } from './enums';
import { Alert } from './alert.model';

export class InsightGroup {
  public insights: Insight[];
  public insightsTypeId: InsightTypeId;
  public uniqueComputers: number;

  constructor(insights: Insight[]) {
    this.insights = insights;
    this.insightsTypeId = insights[0].typeId;
    this.uniqueComputers = this.calculateNumberOfUniqueComputers();
  }

  private calculateNumberOfUniqueComputers(): number {
    if (this.insights[0] instanceof Alert) {
      return _.uniq(
        this.insights.map((insight) => (insight as Alert).computerName),
      ).length;
    }
    return 1;
  }
}
