import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HerokuService } from '../../settings-common/shared/services/heroku.service';
import { AuthService } from '../../auth/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HerokuGurd implements CanActivate {
  constructor(private router: Router, private herokuService: HerokuService, private authService: AuthService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.herokuService
      .isHerokuBlock()
      .map(res => {
        if (state.url.indexOf('settings/heroku/payment') === -1) {
          return true;
        } else {
          this.router.navigate(['/dashboard']);
          return true;
        }
      })
      .first()
      .catch((error: any) => {
        if (error.status === 402 && state.url.indexOf('settings/heroku/payment') === -1) {
          this.router.navigate(['/settings/heroku/payment']);
          return Observable.of(false);
        } else {
          return Observable.of(true);
        }
      });
  }
}
