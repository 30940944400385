import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {WidgetsService} from './shared/services/widgets.service';
import {LoggregationModule} from '../loggregation/loggregation.module';
import {StatisticsModule} from '@app/statistics/statistics.module';
import {ErrorsWidgetComponent} from '@app/widgets/shared/components/errors-widget/errors-widget.component';
import {SuspectedTemplateWidgetComponent} from '@app/widgets/shared/components/suspected-template-widget/suspected-template-widget.component';
import {CgxButtonModule} from "@coralogix/design";

@NgModule({
  imports: [CommonModule, SharedModule, LoggregationModule, StatisticsModule, CgxButtonModule],
  declarations: [ErrorsWidgetComponent, SuspectedTemplateWidgetComponent],
  providers: [WidgetsService],
  exports: [
    ErrorsWidgetComponent,
    SuspectedTemplateWidgetComponent
  ],
})
export class WidgetsModule {
}
