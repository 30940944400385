<input #text type="text" [(ngModel)]="filter" placeholder="Find filter">
<div class="list-wraper">
  <div class="ui inverted dimmer" [class.active]="isLoading">
    <div class="ui text loader"></div>
  </div>
  <div class="checkbox-list" #list>
  <div *ngFor="let option of filterOptions | filterPipe:filter:'name'" class="ui checkbox filter">
    <input type="checkbox" [checked]="option.isSelected" (change)="onChange($event.target.checked,option)"
           [attr.id]="option.name">
    <label [attr.for]="option.name" style="padding-left: 34px;">{{option.name}}</label>
  </div>
  <div class="massage" *ngIf="!isLoading && !hasFilters">No matching filters</div>
</div>
</div>
<div style="width: 100%;padding: 10px">
<div class="ui button primary" (click)="applyFilter()">Apply</div>
</div>
