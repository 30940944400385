import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonFormatterModule } from '@shared/modules/json-formatter/json-formatter.module';
import { JsonFormatterMenuModule } from '@app/logs-new/shared/features/new-json-formatter/json-formatter-menu/json-formatter-menu.module';
import { NewJsonFormatterComponent } from '@app/logs-new/shared/features/new-json-formatter/new-json-formatter.component';

@NgModule({
  declarations: [NewJsonFormatterComponent],
  imports: [CommonModule, JsonFormatterModule, JsonFormatterMenuModule],
  exports: [NewJsonFormatterComponent],
})
export class NewJsonFormatterModule {}
