import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sh-alerts-empty',
  templateUrl: './alerts-empty.component.html',
  styleUrls: ['./alerts-empty.component.scss'],
})
export class AlertsEmptyComponent {
  @Output()
  public openNewAlert: EventEmitter<void> = new EventEmitter<void>();

  public openAlertsEditor(): void {
    this.openNewAlert.emit();
  }
}
