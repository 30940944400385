import { Component, Input } from '@angular/core';
import { Insight } from '../../insights/shared/models/insight.model';
import { ChartModel, ChartSeriesModel } from '../../shared/controls/charts/models/chart.model';
import { SeveritiesHelper } from '@app/shared/services/severities.helper';

@Component({
  selector: 'sh-alerts-item-statistics',
  templateUrl: './alerts-item-statistics.component.html',
  styleUrls: ['./alerts-item-statistics.component.scss'],
})
export class AlertsItemStatisticsComponent {
  @Input() public alertSeverity: number;

  @Input() set insights(data: Insight[]) {
    if (data && data.length > 0) {
      this.createChartModel(data);
    } else {
      this.shouldShowChart = false;
    }
  }

  public shouldHideChart: boolean = true;

  public chartModel: ChartModel;

  public shouldShowChart: boolean = true;

  public createChartModel(data: any): void {
    const model = new ChartModel();
    const seriaY = new ChartSeriesModel();
    const severityColor = SeveritiesHelper.getEventSeverityAlertColor(this.alertSeverity);

    seriaY.name = 'alerts';
    seriaY.type = 'column';
    seriaY.data = [];
    data.forEach((x) => {
      model.categories.push(x.date);
      seriaY.data.push([x.date, x.size]);
    });
    model.xType = 'timestamp';
    model.series = [seriaY];
    model.scale = 'd';
    model.tickLength = 10;
    model.colors = [severityColor];
    model.zoomable = false;
    model.tooltipDateFormat = 'LT';
    model.tooltipEnabled = false;
    model.height = 42;
    model.width = 148;
    model.useLabels = false;
    model.hideAxis = true;
    model.backgroundColor = 'transparent';
    model.tooltipValueSuffix = '';
    model.useDefaultFormater = false;
    // model
    this.chartModel = model;
  }
}
