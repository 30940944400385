import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonFormatterDirective } from './json-formatter.directive';

@NgModule({
  declarations: [JsonFormatterDirective],
  imports: [
    CommonModule
  ],
  exports: [JsonFormatterDirective]
})
export class JsonFormatterModule { }
