import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FieldVisualizationEvent,
  IFieldVisualizationDialogData,
} from '@app/logs-new/shared/models/field-visualization.model';
import { ISearchQueryEvent } from '@app/logs-new/shared/models/search.model';
import { IAddSelectedQueryFieldEvent } from '@app/logs-new/shared/models/logs-filter.model';

@Component({
  selector: 'sh-field-visualization-dialog',
  templateUrl: './field-visualization-dialog.component.html',
  styleUrls: ['./field-visualization-dialog.component.scss'],
})
export class FieldVisualizationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFieldVisualizationDialogData,
    public dialogRef: MatDialogRef<FieldVisualizationDialogComponent>,
  ) {}

  onAddToSearchQuery(payload: ISearchQueryEvent): void {
    this.dialogRef.close({ payload, type: FieldVisualizationEvent.AddToSearchQuery });
  }

  onAddSelectedQueryField(payload: IAddSelectedQueryFieldEvent): void {
    this.dialogRef.close({ payload, type: FieldVisualizationEvent.AddSelectedQueryField });
  }
}
