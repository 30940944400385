import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { AlertModel } from '@app/alerts/shared/models/alert-model';
import * as alertActions from '@app/alerts/shared/actions/alerts.actions';
import { Insight } from '@app/insights/shared/models/insight.model';
import { EmptyEditorState } from '@app/alerts/shared/models/alerts.consts';
import { updateAlertGraphLoadingAction } from '@app/alerts/shared/actions/alerts.actions';

export interface IAlertsReducerState {
  alertsList: AlertModel[];
  alertsInsights: Insight[];
  alertsGraphLoading: boolean;
  alertsListLoading: boolean;
  editor: IEditorState;
}

export interface IEditorState {
  loading: boolean;
  invalidQuery: string;
}

const initialState: IAlertsReducerState = {
  alertsList: null,
  alertsInsights: null,
  alertsGraphLoading: false,
  alertsListLoading: false,
  editor: EmptyEditorState,
};

const alertsReducer: ActionReducer<IAlertsReducerState, Action> = createReducer(
  initialState,
  on(alertActions.getAlertsCompletedAction, (state, { payload }) => ({
    ...state,
    alertsList: payload,
  })),
  on(alertActions.getAlertsFailedAction, (state) => ({
    ...state,
    alertsList: [],
  })),
  on(alertActions.getAlertsInsightsCompletedAction, (state, { payload }) => ({
    ...state,
    alertsInsights: payload,
  })),
  on(alertActions.getAlertsFailedAction, (state) => ({
    ...state,
    alertsInsights: [],
  })),
  on(alertActions.updateOpenAlertInvalidQueryAction, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      invalidQuery: payload,
    },
  })),
  on(alertActions.createAlertCompletedAction, (state, { payload }) => {
    const newAlert = new AlertModel({ ...payload, id: payload.id as string });
    const alertL = Array.isArray(state.alertsList) ? state.alertsList : [];
    const alertsList = [newAlert, ...alertL];
    return {
      ...state,
      alertsList,
      editor: getUpdatedEditorStateWithCurrentLoader(state),
    };
  }),
  on(alertActions.updateAlertCompletedAction, (state, { payload }) => {
    const newAlert = new AlertModel({ ...payload, id: payload.id as string });
    const alertsList = [...state.alertsList].map((alert) => (alert.id === payload.id ? newAlert : alert));
    return {
      ...state,
      alertsList,
      editor: getUpdatedEditorStateWithCurrentLoader(state),
    };
  }),
  on(alertActions.deleteAlertCompletedAction, (state, { payload }) => {
    return {
      ...state,
      alertsList: state.alertsList.filter((alert) => payload !== alert.id),
      alertsInsights: state.alertsInsights.filter((insight) => payload !== insight.alertId),
      editor: getUpdatedEditorStateWithCurrentLoader(state),
    };
  }),
  on(alertActions.updateAlertEditorLoadingAction, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      loading: payload,
    },
  })),
  on(alertActions.updateAlertTableLoadingAction, (state, { payload }) => ({
    ...state,
    alertsListLoading: payload,
  })),

  on(alertActions.updateAlertGraphLoadingAction, (state, { payload }) => ({
    ...state,
    alertsGraphLoading: payload,
  })),
);

export function reducer(state: IAlertsReducerState = initialState, action: Action): IAlertsReducerState {
  return alertsReducer(state, action);
}

function getUpdatedEditorStateWithCurrentLoader(state: IAlertsReducerState): IEditorState {
  return {
    ...EmptyEditorState,
    loading: state.editor.loading,
  };
}
