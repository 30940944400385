<div class="filter-group-container">
  <div class="title-container">
    <div class="caption" style="flex: 1;">
      <label class="label">{{ caption }}</label>
      <label *ngIf="selectedValues?.length > 0" class="secondary-label">
        {{ selectedValues ? '(' + selectedValues?.length + ' selected)' : '' }}
      </label>
      <div style="width: 10px;height: 10px;" class="clear-all">
        <i
          class="remove icon"
          *ngIf="selectedValues ? selectedValues.length > 0 : false"
          style="width: 10px;height: 10px;"
          (click)="clearSelection()"
        ></i>
      </div>
    </div>
    <div
      *ngIf="filterRowsWithAutocomplete?.length > initialNumberOfItemsToDisplay"
      style="text-align: right;"
    >
      <label
        class="expander-label"
        style="position: relative;
                    right: 35px;"
        (click)="toggleShowMore()"
      >
        {{ !showMore ? '' : 'Show less' }}
      </label>
    </div>
  </div>
  <div>
    <div>
      <input
        type="text"
        placeholder="Add filter..."
        [(ngModel)]="addFilterText"
        (keyup.enter)="addCustomFilter(addFilterText)"
        (keyup)="onTextInputKeyUp($event)"
      />
      <div *ngFor="let filter of customFilters">
        <div class="item wrap-text">
          <mat-checkbox
            type="checkbox"
            class="tree-node-checkbox no-margin-checkbox"
            [checked]="selectedValues?.indexOf(filter) !== -1"
            (change)="toggleSelected(filter)"
            matTooltip="{{ filter }}"
            disableRipple="true"
          >
            {{ filter }}
          </mat-checkbox>
        </div>
      </div>
      <div *ngFor="let row of filterRowsWithAutocomplete | slice: 0:initialNumberOfItemsToDisplay; let index = index">
        <div
          class="item wrap-text"
          *ngIf="row"
          #editTooltip="matTooltip"
          (mouseleave)="onCheckboxMouseLeave($event, editTooltip)"
          matTooltip="{{ getRowTitle(row) }}"
          matTooltipPosition="above"
        >
          <mat-checkbox
            class="tree-node-checkbox no-margin-checkbox"
            disableRipple="true"
            [checked]="selectedValues?.indexOf(row.id || row.name) !== -1"
            (change)="toggleSelected(row.id || row.name)"
          ></mat-checkbox>
          <label
            *ngIf="!row.titleParts"
            class="tree-node-label"
          >{{ getRowTitle(row) }}</label>
          <label
            *ngIf="!!row.titleParts"
            class="tree-node-label"
          >
              <span
                *ngFor="let part of row.titleParts"
                class="title-part"
                [ngClass]="{
                  'highlight': part.isHighlighted
                }"
              >{{ part.text }}</span>
          </label>
          <label class="third-label">({{ row.count | numbersFormat }})</label>
        </div>
      </div>
      <div
        *ngIf="showMore && filterRowsWithAutocomplete?.length > initialNumberOfItemsToDisplay"
      >
        <div
          *ngFor="let row of filterRowsWithAutocomplete | slice: initialNumberOfItemsToDisplay:filterRowsWithAutocomplete.length; let index = index"
        >
          <div
            class="item wrap-text"
            *ngIf="row"
            #editTooltip="matTooltip"
            (mouseleave)="onCheckboxMouseLeave($event, editTooltip)"
            matTooltip="{{ getRowTitle(row) }}"
            matTooltipPosition="above"
          >
            <mat-checkbox
              class="tree-node-checkbox no-margin-checkbox"
              disableRipple="true"
              [checked]="selectedValues?.indexOf(row.id || row.name) !== -1"
              (change)="toggleSelected(row.id || row.name)"
            ></mat-checkbox>
            <label
              *ngIf="!row.titleParts"
              class="tree-node-label"
            >{{ getRowTitle(row) }}</label>
            <label
              *ngIf="!!row.titleParts"
              class="tree-node-label"
            >
              <span
                *ngFor="let part of row.titleParts"
                class="title-part"
                [ngClass]="{
                  'highlight': part.isHighlighted
                }"
              >{{ part.text }}</span>
            </label>
            <label class="third-label">({{ row.count | numbersFormat }})</label>
          </div>
        </div>
      </div>
      <div
        class="expander-label-container"
        *ngIf="filterRowsWithAutocomplete?.length > initialNumberOfItemsToDisplay"
      >
        <label class="expander-label" (click)="toggleShowMore()">
          {{ !showMore ? 'Show More...' : 'Show less' }}
        </label>
      </div>
    </div>
  </div>
</div>
