import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMenuComponent } from './user-menu.component';
import { CgxButtonModule, CgxInputFieldModule, CgxTooltipModule } from '@coralogix/design';
import { TeamItemModule } from './team-item/team-item.module';
import { FormsModule } from '@angular/forms';
import { AvatarInitialsModule } from '@app/shared/avatar-initials/avatar-initials.module';
import { FilterPipeModule } from '@app/shared/pipes/filter-pipe/filter-pipe.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [UserMenuComponent],
  imports: [
    CommonModule,
    CgxButtonModule,
    CgxInputFieldModule,
    TeamItemModule,
    FormsModule,
    AvatarInitialsModule,
    FilterPipeModule,
    AngularSvgIconModule,
    ScrollingModule,
    CgxTooltipModule,
  ],
  exports: [UserMenuComponent],
})
export class UserMenuModule {}
