import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyPlanStatus } from '../../../user/shared/models/company-plan-status.model';

@Component({
  selector: 'sh-plan-limit-bar',
  templateUrl: './plan-limit-bar.component.html',
  styleUrls: ['./plan-limit-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanLimitBarComponent {
  @Input() public planStatus: CompanyPlanStatus;

  externalPrefix: string = 'https://dashboard.heroku.com';

  get blockLimitGB(): number {
    const { plan_size, blockLimit } = this.planStatus;
    return blockLimit - plan_size;
  }
  get planGB(): number {
    return this.planStatus.plan_size ?? 10;
  }

  get total(): number {
    const { estimatedUsage, overageEnabled } = this.planStatus;
    if (overageEnabled) {
      return estimatedUsage > this.planGB * 2 ? estimatedUsage : this.planGB * 2;
    } else {
      return estimatedUsage > this.planGB ? estimatedUsage : this.planGB;
    }
  }

  get exceedsPlan(): boolean {
    const { estimatedUsage, overageEnabled } = this.planStatus;
    if (overageEnabled) {
      return estimatedUsage > this.planGB * 2 ? true : false;
    } else {
      return estimatedUsage > this.planGB ? true : false;
    }
  }

  get usageGB(): number {
    const { estimatedUsage, overageEnabled } = this.planStatus;
    if (this.exceedsPlan) {
      return this.planGB;
    } else {
      if (overageEnabled) {
        return estimatedUsage - this.payAsYouGoGB;
      } else {
        return estimatedUsage;
      }
    }
  }

  get usagePercent(): number {
    return (100 * this.usageGB) / this.total;
  }

  get payAsYouGoGB(): number {
    const { estimatedUsage, overageEnabled } = this.planStatus;
    if (overageEnabled) {
      return Math.max(Math.min(estimatedUsage - this.planGB, this.planGB), 0);
    } else {
      return 0;
    }
  }

  get payAsYouGoPercent(): number {
    return (100 * this.payAsYouGoGB) / this.total;
  }

  get overGB(): number {
    const { estimatedUsage, overageEnabled } = this.planStatus;
    if (overageEnabled) {
      return Math.max(Math.min(estimatedUsage - this.planGB - this.payAsYouGoGB, this.blockLimitGB), 0);
    } else {
      return Math.max(Math.min(estimatedUsage - this.planGB, this.blockLimitGB), 0);
    }
  }

  get overPercent(): number {
    return (100 * this.overGB) / this.total;
  }

  get usageAbovePlan(): number {
    const { estimatedUsage, overageEnabled } = this.planStatus;
    if (overageEnabled) {
      return Math.max(estimatedUsage - this.planGB * 2, 0);
    } else {
      return Math.max(estimatedUsage - this.planGB, 0);
    }
  }

  get remainingEstimatedUsage(): number {
    const { overageEnabled, plan_size } = this.planStatus;
    if (overageEnabled) {
      return Math.max(plan_size * 2 - this.planGB - this.payAsYouGoGB, 0);
    } else {
      return Math.max(plan_size - this.usageGB, 0);
    }
  }
  get blockGB(): number {
    const { estimatedUsage, overageEnabled } = this.planStatus;
    if (overageEnabled) {
      return Math.max(estimatedUsage - this.blockLimitGB - this.planGB - this.planGB, 0);
    } else {
      return Math.max(estimatedUsage - this.blockLimitGB - this.planGB, 0);
    }
  }

  get blockPercent(): number {
    const { estimatedUsage } = this.planStatus;

    if (this.exceedsPlan && estimatedUsage - this.planGB > this.blockLimitGB) {
      return (100 * this.blockGB) / this.total;
    } else {
      return 0;
    }
  }
}
