export const metadataDisplayNameMap: { [key: string]: string } = {
  'coralogix.timestamp': 'Timestamp',
  'coralogix.metadata.severity': 'Severity',
  'coralogix.metadata.restorationId': 'Restoration Id',
  'coralogix.metadata.applicationName': 'Application',
  'coralogix.metadata.subsystemName': 'Subsystem',
  'coralogix.metadata.category': 'Category',
  'coralogix.metadata.computerName': 'Computer',
  'coralogix.metadata.IPAddress': 'IP address',
  'coralogix.metadata.className': 'Class',
  'coralogix.metadata.methodName': 'Method',
  'coralogix.metadata.threadId': 'Thread Id',
  'coralogix.metadata.processName': 'Process Name',
  'coralogix.metadata.sdkId': 'SDK Id',
};

export const defaultTemplatesSortingColumn = 'occurrences';

export const columnsDisplayNameMappings: { [key: string]: string } = {
  ...metadataDisplayNameMap,
  'coralogix.text': 'Text',
};

export const getColumnDisplayName = (key: string) => columnsDisplayNameMappings[key] || key;

export const isMetadataRegex = /^coralogix\.metadata\./g;

export const isMetadataField = (field) => {
  if (!field) return false;
  return field.match(isMetadataRegex) || !!metadataDisplayNameMap[field];
};

export const removeMetadataPrefix = (colId) => colId?.replace(isMetadataRegex, '');
export const removeCoralogixPrefix = (colId) => colId?.replace(/^coralogix\./g, '');
export const removeTextObjectPrefix = (colId) => colId?.replace(/^textObject\./g, '');
