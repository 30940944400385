import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { AlertModel } from '../shared/models/alert-model';
import { Teammate } from '@app/user/shared/models/teammate';
import { IPeriodicElement } from './interfaces';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'sh-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss'],
})
export class AlertsTableComponent implements AfterViewInit {
  @Input('alerts')
  public set dataSource(alerts: IPeriodicElement[]) {
    this._alerts = alerts;
    this.setAlertsData();
  }

  get source(): MatTableDataSource<IPeriodicElement> {
    return this._dataSource;
  }
  public displayedColumns: string[] = ['name', 'updateDate', 'alertSeverity', 'isActive', 'lastRaisedDate'];
  @Input() public alertModel: AlertModel;
  @Input() public users: Teammate[] = [];
  @Input() public currentUser: Teammate;
  @Output() public editAlert: EventEmitter<AlertModel> = new EventEmitter<AlertModel>();
  public initialized: boolean;
  public _alerts: IPeriodicElement[] | AlertModel[] = [];
  @ViewChild(MatPaginator) private paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;
  private _dataSource: MatTableDataSource<IPeriodicElement> = new MatTableDataSource([]);

  public ngAfterViewInit(): void {
    this.setAlertsData();
  }

  public getUserName(row: AlertModel): string {
    if (row && row.history && row.history.length) {
      const historyItem = row.history[0];
      if (this.currentUser && this.currentUser.id === historyItem.userId) {
        if (this.currentUser.firstName.length) {
          return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
        } else if (this.currentUser && !this.currentUser.firstName.length) {
          return this.currentUser.username;
        }
      }
      const userObj = this.users.find(user => {
        return user.id === historyItem.userId;
      });
      if (userObj && userObj.firstName.length) {
        return `${userObj.firstName} ${userObj.lastName}`;
      } else if (userObj && !userObj.firstName.length) {
        return userObj.username;
      }
      return '';
    }
    return '';
  }

  public getFormattedDate(date: number): string {
    return moment.unix(date).format('MMM DD, YYYY');
  }

  public stopEvent(e: MouseEvent): void {
    e.stopPropagation();
  }

  public onEditAlert(row: any): void {
    const alert: AlertModel = (this._alerts as AlertModel[]).find(item => item.id === row.id);
    this.editAlert.emit(alert);
  }

  public applyFilter(filterValue: string): void {
    this._dataSource.filter = filterValue.trim().toLowerCase();
  }

  private setAlertsData(): void {
    if (this._alerts?.length) {
      this._dataSource = new MatTableDataSource(this._alerts as IPeriodicElement[]);
      this._dataSource.sort = this.sort;
      this._dataSource.paginator = this.paginator;
    }
  }
}
