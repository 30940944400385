import { Metric } from '@app/settings/metrices-settings/models/metric.model';
import { Entity } from '@shared/models/entity.model';
import { Observable } from 'rxjs';
import { IAlertRelativeTimeOption } from '@app/alerts/alerts-editor/models/alert-relative-time-option';

export class AlertsEditorSelectOptions {
    public logSeverityOptions: Entity[];
    public alertSeverityOptions: Entity[];
    public applicationOptions: Observable<string[]>;
    public subsystemOptions: Observable<string[]>;
    public conditionTimeframeOptions: Entity[];
    public relativeTimeframeOptions: Entity[];
    public relativeTimeframeOptionIdMapping: { [key: number]: IAlertRelativeTimeOption; };
    public relativeTimeframeSilenceDisplay: { [key: number]: string; };
    public conditionsOperatorOptions: Entity[];
    public conditionMetricArithmeticOptions: Entity[];
    public metrics: Observable<Metric[]>;
    public newMetricsMetricFields: Observable<string[]>;
    public newMetricsMetricLabels: Observable<string[]>;
    public weekDayOptions: Entity[];
    public groupByOptions: Observable<string[]>;
    public integrations: Observable<any[]>;
    public cardinalityTimeframeOptions: Entity[];

    constructor(init?: Partial<AlertsEditorSelectOptions>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
