import { graphPointValue, timestampVal } from '@app/statistics/shared/models/line-chart.models';
import { jsonArrayMember, jsonObject } from 'typedjson';
import { ITagWithCompareBaseRequest } from '@app/deployment/shared/models/summary.models';

export interface ITagErrorVolumeResponse {
  compareTag: [timestampVal, graphPointValue][];
  tag: [timestampVal, graphPointValue][];
}

@jsonObject
export class TagErrorVolumeResponse implements ITagErrorVolumeResponse {
  @jsonArrayMember(Number, { dimensions: 2 })
  public compareTag: [timestampVal, graphPointValue][];
  @jsonArrayMember(Number, { dimensions: 2 })
  public tag: [timestampVal, graphPointValue][];
}

export interface ITagErrorVolumeRequest extends ITagWithCompareBaseRequest {}
