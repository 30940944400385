import { Colors } from '@app/shared/models/colors.model';
import { Priority } from '../enums/priority.enum';
import { Severity } from '../enums/severity.enum';
import { IFactors } from '../interfaces/factors.interface';
import { IPolicyOverrideSeverity } from '../interfaces/policy-override-severity.interface';
import { IPolicyOverrideSeveritySerialized } from '../serialized/policy-override-severity.serialized';
import { PolicyCriteria } from './policy-criteria.model';

export class PolicyOverrideSeverity implements IPolicyOverrideSeverity {
    public static factors: IFactors = null;

    public id: string;

    public policy: PolicyCriteria;

    public severity: Severity;

    public priority: Priority;

    public totalSize: number;

    constructor(obj: IPolicyOverrideSeverity) {
        this.id = obj.id;
        this.policy = obj.policy;
        this.severity = obj.severity;
        this.priority = obj.priority;
        this.totalSize = obj.totalSize;
    }

    get evalPriority(): Priority {
        return this.priority ?? this?.policy?.priority ?? Priority.High;
    }

    get evalPriorityWithoutOverride(): Priority {
        return this?.policy?.priority ?? Priority.High;
    }

    get totalSizeFactorized(): number {
        const totalQuota = this.totalSize * PolicyOverrideSeverity.factors[this.evalPriority];
        return totalQuota;
    }

    get color(): string {
        return Colors.severityColors[this.severity];
    }

    public static deserializer(obj: IPolicyOverrideSeveritySerialized): PolicyOverrideSeverity {
        let policyOverride: PolicyOverrideSeverity = null;

        if (obj) {
            policyOverride = new PolicyOverrideSeverity({
                id: obj.id,
                policy: PolicyCriteria.deserializer(obj.policy),
                severity: obj.severity,
                priority: obj.priority,
                totalSize: obj.totalSize
            });
        }
        return policyOverride;
    }

    public static create(obj: IPolicyOverrideSeverity): PolicyOverrideSeverity {
        let policyOverrideSeverity: PolicyOverrideSeverity = null;

        if (obj) {
            policyOverrideSeverity = new PolicyOverrideSeverity({
                id: obj.id,
                policy: PolicyCriteria.create(obj.policy),
                severity: obj.severity,
                priority: obj.priority,
                totalSize: obj.totalSize
            });
        }

        return policyOverrideSeverity;
    }
}
