import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CompanyRequest, CompanySettings } from '@app/auth/shared/models/activateUser.model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetCompany } from '@app/ngxs-store/company/company.action';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { takeUntil } from 'rxjs/operators';
import { UserState } from '@app/ngxs-store/user/user.state';

@Component({
  selector: 'sh-roi-main-layout',
  templateUrl: './roi-main-layout.component.html',
  styleUrls: ['./roi-main-layout.component.scss']
})
export class RoiMainLayoutComponent implements OnInit, OnDestroy {
  @Select(CompanyState.companyInfo) public companyInfo$: Observable<CompanyRequest>;

  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  @Select(UserState.isAdmin) public isAdmin$: Observable<boolean>;

  public settings: CompanySettings;

  public isArchiveVersionValid: boolean;

  private destroyed$: Subject<void> = new Subject();

  public ngOnInit(): void {
    this.getCompany();

    this.companyInfo$.pipe(takeUntil(this.destroyed$)).subscribe((company) => {
      if (company && company.settings) {
        this.settings = company.settings;
      }
      this.isArchiveVersionValid = company?.archiving_provider?.archive_format !== 1;
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @Dispatch() public getCompany = () => new GetCompany();

}
