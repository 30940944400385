import { Component } from '@angular/core';
import { IChartTooltipPointData } from '@app/statistics/shared/helpers/line-chart-tags-helper';
import { Point } from 'highcharts';

@Component({
  selector: 'sh-dynamic-logs-tooltip',
  templateUrl: './dynamic-logs-tooltip.component.html',
  styleUrls: ['./dynamic-logs-tooltip.component.scss']
})
export class DynamicLogsTooltipComponent {
  public series: Point[] = [];
  public set currChartData(data: IChartTooltipPointData) {
    if (this.currPoint !== data.y) {
      this.currPoint = data.y;
      this.series = data.points;
      this.pointGap = (data.points[0]?.y - data.points[1]?.y) || 0;
      this.timeGap = this.getTimeGap(data.x);
    }
  }
  public dataIndex: number;
  public currPoint: number;
  public timeGap: string;
  public pointGap: number;
  public chartTooltipDataTitle: string;
  public tooltipButtonText: string;
  private getTimeGap(passedTime: number): string {
    return `${passedTime} since tag started`;
  }

}
