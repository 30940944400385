import { Component, Input } from '@angular/core';
import { ChartModel } from '@shared/controls/charts/models/chart.model';

@Component({ template: `` })
export class WidgetBaseComponent {
  public isLoading: boolean = false;
  @Input() public enableLoading: boolean = false;
  @Input() public addInvertedClassToLoader: boolean = false;
  public model: ChartModel;
}
