import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PluginsContainerComponent } from './plugins-container.component';
import { CoralogixSidebarModule } from '@shared/popups/coralogix-sidebar/coralogix-sidebar.module';
import {
  CgxButtonModule,
  CgxCheckboxModule,
  CgxInputFieldModule,
  CgxSelectModule,
  CgxSpinnerModule,
  CgxToggleModule,
  CgxTooltipModule,
} from '@coralogix/design';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfirmationDialogModule } from '@app/shared/components/confirmation-dialog/confirmation-dialog.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UrlAutocompleteModule } from './url-autocomplete/url-autocomplete.module';
import { EnumTranslatorModule } from '@shared/pipes/enum-translator/enum-translator.module';

@NgModule({
  declarations: [PluginsContainerComponent],
  imports: [
    CommonModule,
    CoralogixSidebarModule,
    CgxButtonModule,
    ReactiveFormsModule,
    MatExpansionModule,
    CgxInputFieldModule,
    MatAutocompleteModule,
    CgxSelectModule,
    CgxCheckboxModule,
    CgxToggleModule,
    DragDropModule,
    ConfirmationDialogModule,
    AngularSvgIconModule,
    MatAutocompleteModule,
    UrlAutocompleteModule,
    CgxTooltipModule,
    EnumTranslatorModule,
    CgxSpinnerModule,
  ],
  exports: [PluginsContainerComponent],
})
export class PluginsContainerModule {}
