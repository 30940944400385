import { Pipe, PipeTransform } from '@angular/core';
import { Insight } from '../models/insight.model';
import * as _ from 'lodash';
import { InsightGroup } from '../models/insight-group';
import { AggregationType } from '../models/enums';

@Pipe({
  name: 'groupInsights',
})
export class GroupInsightsPipe implements PipeTransform {
  public aggregationType: AggregationType = AggregationType.Daily;

  public transform(insights: Insight[], args?: any): any {
    this.aggregationType = args;
    // group insights
    const groupedInsightsDict = _.groupBy(
      insights,
      this.calculateGroupForInsight.bind(this),
    );
    // map dictionary to array of insight group
    const groupedInsights = _.map(groupedInsightsDict, this.mapToInsightsGroup);

    return groupedInsights;
  }
  /**
   * create insightGroup object from insight list
   */
  public mapToInsightsGroup(insights: Insight[], key: string): InsightGroup {
    const group: InsightGroup = new InsightGroup(insights);
    return group;
  }

  public calculateGroupForInsight(insight: Insight) {
    const insightDate = new Date(insight.timestamp);
    const key = [];
    key.push(insight.alertId);
    key.push(insight.severity);
    key.push(insightDate.getDate());
    key.push(insightDate.getMonth());
    key.push(insightDate.getFullYear());
    if (this.aggregationType === AggregationType.Hourly) {
      key.push(insightDate.getHours());
    }
    return key.join(' ');
  }
}
