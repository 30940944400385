import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReindexPermissionsErrorComponent } from '@app/roi/reindex/components/reindex-permissions-error/reindex-permissions-error.component';
import { SharedModule } from '@shared/shared.module';
import { CgxButtonModule } from '@coralogix/design';

@NgModule({
  declarations: [ReindexPermissionsErrorComponent],
  imports: [CommonModule, SharedModule, CgxButtonModule],
  exports: [ReindexPermissionsErrorComponent],
})
export class ReindexPermissionErrorModule {}
