import { formatsHelper } from '../../../shared/services/formatsHelper';
import * as _ from 'lodash';
import { PrettyJsonPipe } from '../../../shared/pipes/pretty-json.pipe';
import { TemplateType } from '../../shared/interfaces/templateType.enum';
export class LoggregationlHelper {
  public static clickableParam: string =
    '<div class="ui lable loggregation loggregation-$3-style {CoralogixPHStatsEnabled} dark"' +
    ' parameterId="$1" parameterType="$3" id="parameter"> *$2</div>';

  public static clickableJsonParam: string =
    '<div class="ui lable loggregation loggregation-$3-style {CoralogixPHStatsEnabled} dark"' +
    ' parameterId="$2" parameterType="$3" id="parameter"> *VALUE</div>';
  public static prettyJasonPipe: PrettyJsonPipe = new PrettyJsonPipe();
  public static loggregationRenderer(
    params: any,
    context: any,
    cellClass: string = 'loggregationCellContainer',
  ): HTMLElement {
    if (params.text) {
      let innerElementText = params.text;
      try {
        const value = LoggregationlHelper.prettyJasonPipe.transform(
          JSON.parse(innerElementText),
        );
        innerElementText = LoggregationlHelper.buildLoggregationParametersHTML(
          value,
          LoggregationlHelper.clickableJsonParam,
          true,
          params.logExample,
        );
      } catch (e) {
        console.warn('Failed to render loggregation', e);
      }
      innerElementText = LoggregationlHelper.buildLoggregationParametersHTML(
        innerElementText,
        LoggregationlHelper.clickableParam,
      );
      const innerElement = document.createElement('div');
      innerElement.className = cellClass;
      innerElement.innerHTML = innerElementText;
      innerElement.addEventListener('click', (e) =>
        context.onCellClicked(e, params),
      );
      innerElement.addEventListener('mouseover', (e) => context.OnMouseover(e));
      innerElement.addEventListener('mouseout', (e) => context.OnMouseout(e));

      const eButton = document.createElement('div');
      eButton.className = 'action-icon-container';
      const eIcon = document.createElement('i');
      eIcon.classList.add('fa');
      eIcon.classList.add('fa-ellipsis-h');
      eIcon.style.cssText = 'font-size:14px; margin:auto;top: 50%;';
      eButton.addEventListener('click', () => context.textInfoClicked.emit());
      eButton.appendChild(eIcon);
      const divElement = document.createElement('div');
      divElement.appendChild(innerElement);
      divElement.appendChild(eButton);

      return divElement;
    }
    return null;
  }

  public static occurrencesenderer(params: any): HTMLElement {
    if (!_.isUndefined(params.text)) {
      const element = document.createElement('div');
      element.className =
        'ui lable loggregation loggregation-occurrences-style';
      element.appendChild(
        document.createTextNode(formatsHelper.numberWithCommas(params.text)),
      );
      element.addEventListener('click', () => {
        params.context.showModal(params.node.data.id);
      });
      return element;
    }
    return null;
  }

  public static currentRatioRenderer(params: any): HTMLElement {
    if (!_.isUndefined(params.text)) {
      const value = params.value
        ? Math.round(params.value * 10000) / 100 + '%'
        : 0 + '%';
      const element = document.createElement('div');
      element.appendChild(document.createTextNode(value));
      if (params.data && params.data.isAnomalous) {
        element.className = 'anomaly-breakage-cell';
      }
      return element;
    }
    return null;
  }

  public static getLoggregationParamPattern(isJson: boolean): RegExp {
    if (isJson) {
      return new RegExp(
        /\{CoralogixJsonPH(\d+)_([^]*?.*?)_([json]+)_CoralogixJsonPH\}/g,
      );
    } else {
      return new RegExp(
        /\{CoralogixPH(\d+)_([^]*?.*?)_([free|categorical|numeric]+)_CoralogixPH\}/g,
      );
    }
  }

  public static buildLoggregationParametersHTML(
    input: any,
    clickableParam: string,
    isJson: boolean = false,
    logExample: any = null,
    placeholdersEnabled: boolean = true,
  ): string {
    if (!input) {
      return 'NO TEXT';
    }

    let loggregationParamPattern: RegExp;
    loggregationParamPattern = LoggregationlHelper.getLoggregationParamPattern(
      isJson,
    );
    let replaced;

    if (clickableParam) {
      if (isJson && logExample) {
        replaced = input.replace(
          loggregationParamPattern,
          (match, p1, p2, p3) => {
            const path = p2.split('.');
            let prop = logExample[path[0]];
            if (path.length > 1) {
              path.shift();
              path.forEach((p) => {
                if (prop) {
                  prop = prop[p];
                }
              });
            }
            let parameterHtml = clickableParam.replace('$2', p2);
            parameterHtml = parameterHtml.replace('$3', p3);
            parameterHtml = parameterHtml.replace('$3', p3);
            parameterHtml = parameterHtml.replace(
              '*VALUE',
              !_.isUndefined(prop) ? prop : '*VALUE',
            );
            return parameterHtml;
          },
          clickableParam,
        );
      } else {
        replaced = input
          .replace(loggregationParamPattern, clickableParam);
      }
    } else {
      // replace the server data with the parameter name and trim it for rowHeight calculation.
      replaced = input
        .replace(
          loggregationParamPattern,
          (match, p1, p2) => p2.substring(0, 40),
          clickableParam,
        );
    }

    return replaced.replace(/{CoralogixPHStatsEnabled}/g, placeholdersEnabled ? '' : 'disabled');
  }
}
