import { Component, Input } from '@angular/core';
import { ArithmeticOptions } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import {
  MetricAlertGraphData,
  NamedMetricAlertGraphData,
  PromqlMetricAlertGraphData,
} from '@app/insights/shared/models/alert-metric.model';
import { Alert } from '@app/insights/shared/models/alert.model';
import { MetricAlertService } from '@app/insights/shared/services/metric-alert.service';
import { DateFormatType } from '@app/shared/models/date-format-types';
import { TimeZoneType } from '@app/shared/models/timezone-types';
import { Chart } from 'angular-highcharts';
import Highcharts from 'highcharts';
import _, { valuesIn } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'sh-metric-alert-graph',
  templateUrl: './metric-alert-graph.component.html',
  styleUrls: ['./metric-alert-graph.component.scss'],
})
export class MetricAlertGraphComponent {
  @Input()
  public alert: Alert;
  @Input()
  public chartName: string = '';
  @Input()
  public spliced: boolean = true;
  @Input()
  public timeZone: TimeZoneType;
  @Input()
  public dateFormatType: DateFormatType;
  @Input()
  set chartData(value: MetricAlertGraphData[] | NamedMetricAlertGraphData[] | PromqlMetricAlertGraphData[]) {
    this.initChart(value);
  }

  public chart: Chart;
  private eventPlotlineTimestamp: number = -1;

  constructor(private metricAlertService: MetricAlertService) {}

  private initChart(chartData: MetricAlertGraphData[] | NamedMetricAlertGraphData[] | PromqlMetricAlertGraphData[]): void {
    if (!chartData) {
      return;
    }
    let series: any[] = [];

    if (this.alert?.metricAlert?.promqlText) {
      series = this.calcPromqlSeries(chartData as PromqlMetricAlertGraphData[]);
    } else if (this.spliced) {
      series = this.calcSplicedSeries(chartData as NamedMetricAlertGraphData[]);
    } else {
      series = this.calcSingleSeries(chartData as MetricAlertGraphData[]);
    }

    const triggeredStartTimestamp = _.isEmpty(this.alert.esInfo)
      ? this.alert.logTimestamp - this.alert.conditionTimeframe * 60 * 1000
      : this.alert.esInfo.fromTimestamp;

    const triggeredEndTimestamp = _.isEmpty(this.alert.esInfo) ? this.alert.logTimestamp : this.alert.esInfo.toTimestamp;

    const triggeredTimeframeSeries = _.isEmpty(this.alert.esInfo)
      ? [
          [this.alert.logTimestamp - this.alert.conditionTimeframe * 60 * 1000, null, null],
          [this.alert.logTimestamp, null, null],
        ]
      : [
          [this.alert.esInfo.fromTimestamp, null, null],
          [this.alert.esInfo.toTimestamp, null, null],
        ];

    series.unshift({
      name: 'Triggered Timeframe',
      color: '#d4e4ff',
      type: 'arearange',
      // linkedTo: ':previous',
      zIndex: -1,
      data: triggeredTimeframeSeries,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      yAxis: 1,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    });

    const earliestGraphData = series[1].data[0]?.x;
    const latestGraphData = series[1].data[series[1].data.length - 1]?.x;

    series.unshift({
      name: 'Threshold',
      color: '#2A3850',
      type: 'line',
      dashStyle: 'solid',
      lineWidth: 2,
      data: [
        { x: Math.min(earliestGraphData, triggeredStartTimestamp) - 600000, y: this.alert.metricAlert.valueThreshold },
        { x: Math.max(latestGraphData, triggeredEndTimestamp) + 600000, y: this.alert.metricAlert.valueThreshold },
      ],
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      yAxis: 0,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    });

    const currContext = this;
    const H = Highcharts as any;

    Highcharts.wrap(H.seriesTypes.arearange.prototype, 'translate', function(proceed: any): void {
      const yAxis = this.yAxis;
      proceed.apply(this, Array.prototype.slice.call(arguments, 1));
      Highcharts.each(
        this.points,
        (point: any, i: number) => {
          if (point.high === null && point.low === null) {
            point.isNull = false;
            point.plotHigh = 0;
            point.plotY = yAxis.len;
            point.plotLow = yAxis.len;
          }
        },
        this,
      );
    });

    this.chart = new Chart({
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      time: {
        useUTC: this.timeZone === TimeZoneType.GMT,
      },
      chart: {
        type: 'line',
        backgroundColor: 'transparent',
        marginRight: 15,
        zoomType: 'x',
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0,
        minorTickLength: 2,
        tickLength: 0,
        lineWidth: 1,
        minorGridLineWidth: 1,
        type: 'datetime',
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        labels: {
          rotation: -45,
        },
        gridLineWidth: 1,
        gridLineColor: '#dae2f0',
      },
      yAxis: [
        {
          title: {
            text: '',
          },
        },
        { visible: false },
      ],
      series: series,
      legend: {
        enabled: true,
        reversed: true,
        align: 'left',
        symbolHeight: 12,
        symbolWidth: 12,
        itemStyle: {
          fontSize: '12px',
          fontFamily: 'Nunito Sans',
          color: '#687996',
        },
        itemDistance: 12,
        itemMarginBottom: 12,
        navigation: {
          enabled: false,
        },
      },
      tooltip: {
        backgroundColor: '#35435b',
        style: {
          color: '#fff',
          fontWeight: '800',
          fontFamily: 'Nunito Sans',
        },
        padding: 12,
        borderRadius: 4,
        borderWidth: 0,
        formatter(): string {
          const dateFormat = currContext.dateFormatType === DateFormatType.american ? 'MM/DD' : 'DD/MM';
          const rows = [];
          const groupByFields = this.point.series.options['groupByFields'];
          groupByFields?.forEach((groupByField, index) => {
            const backgroundStyle = index % 2 !== 0 ? 'background-color: rgba(196,203,216,0.1);' : '';

            rows.push(
              `<tr style="height: 24px; ${backgroundStyle}">
                <td> ${groupByField} </td>
                <td> ${this.point.series.options['fieldValues']?.[index]} </td>
               </tr>`,
            );
          });

          const tooltipGroupByLabelChart =
            groupByFields && groupByFields.length > 0
              ? `
          <div class="chart-tooltip" >
            <table class="chart-series-title" style="width: 295px;">
              <tr style="height: 24px; font-weight: bold; color: #e2e9f5; background-color: rgba(196,203,216,0.1);">
                <td style="min-width: 110px">Label Details</td>
              </tr>
              ${rows.join('')}
            </table>
          </div>
          `
              : '';

          return `
          <div class="chart-tooltip">${moment((this.point as any).x)
            .utc(currContext.timeZone === TimeZoneType.local)
            .format(`${dateFormat} HH:mm`)}</div>
          <div class="chart-tooltip">
            <span style="font-weight: normal; font-size: 40px">Value</span>
            <span>${this.y}</span>
          </div>
          ${tooltipGroupByLabelChart}
          `;
        },
        useHTML: true,
        outside: true,
      },
    });
  }

  private calcSingleSeries(chartData: MetricAlertGraphData[]): any[] {
    const actualInterval = this.metricAlertService.getIntervalByTimerframe(this.alert.conditionTimeframe);
    const valuesInTimeframe = this.alert.conditionTimeframe / actualInterval;
    const data = chartData?.map((item, index) => {
      let valueToShow = item.value;

      if (this.isSumOrCount()) {
        if (index >= valuesInTimeframe) {
          valueToShow = this.sumPreviousIndices(chartData, index, valuesInTimeframe);
        } else {
          valueToShow = null;
        }
      }

      return { y: this.roundToPrecision(valueToShow, 6), x: item.timestamp };
    });

    return [
      {
        data,
        type: 'spline',
        showInLegend: true,
        name: `Metric field value for: ${this.alert.metricAlert.metricFieldExtraction}`,
        color: '#216def',
        lineWidth: 1,
      },
    ];
  }

  private calcSplicedSeries(chartData: NamedMetricAlertGraphData[]): any[] {
    const serieList = [];
    const colors = ['#14B287', '#E685FF', '#216DEF', '#9200FF', '#D422BC'];
    const actualInterval = this.metricAlertService.getIntervalByTimerframe(this.alert.conditionTimeframe);
    const valuesInTimeframe = this.alert.conditionTimeframe / actualInterval;

    chartData.forEach((namedChart, index) => {
      const data = namedChart?.graphData?.map((item, itemIndex) => {
        let valueToShow = item.value;

        if (this.isSumOrCount()) {
          if (itemIndex >= valuesInTimeframe) {
            valueToShow = this.sumPreviousIndices(namedChart.graphData, itemIndex, valuesInTimeframe);
          } else {
            valueToShow = null;
          }
        }

        return { y: this.roundToPrecision(valueToShow, 6), x: item.timestamp };
      });

      const fieldValues = NamedMetricAlertGraphData.fieldValues(namedChart.name);
      const groupByFields = this.alert.esInfo.getGroupByFields();

      serieList.push({
        data,
        type: 'spline',
        showInLegend: true,
        name: `${fieldValues?.join(' / ')}`,
        groupByFields,
        fieldValues,
        color: colors[index],
        lineWidth: 1,
      });
    });
    return serieList;
  }

  private calcPromqlSeries(chartData: PromqlMetricAlertGraphData[]): any[] {
    const serieList = [];
    const colors = ['#14B287', '#E685FF', '#216DEF', '#9200FF', '#D422BC'];

    chartData.forEach((promqlChart, index) => {
      const data = promqlChart?.values?.map((item, itemIndex) => {
        const valueToShow = item.value;
        item.timestamp *= 1000;

        return { y: this.roundToPrecision(valueToShow, 6), x: item.timestamp };
      });

      const fieldValues = PromqlMetricAlertGraphData.fieldValues(promqlChart.metric);
      const groupByFields = PromqlMetricAlertGraphData.labels(promqlChart.metric);

      serieList.push({
        data,
        type: 'spline',
        showInLegend: true,
        name: `${fieldValues?.join(' / ')}`,
        groupByFields,
        fieldValues,
        color: colors[index],
        lineWidth: 1,
      });
    });
    return serieList;
  }

  private roundToPrecision(value: number, decimals: number): number {
    if (value === null) {
      return null;
    }

    const pow = Math.pow(10, decimals);
    return Math.round((value + Number.EPSILON) * pow) / pow;
  }

  private sumPreviousIndices(chartData: MetricAlertGraphData[], index: number, valuesInTimeframe: number): number {
    let sumValue = 0;
    while (valuesInTimeframe > 0) {
      if (chartData[index].value !== undefined) {
        sumValue += chartData[index].value;
      }

      index--;
      valuesInTimeframe--;
    }

    return sumValue;
  }

  private isSumOrCount(): boolean {
    const arithmeticOperator = this.alert.metricAlert.arithmeticOperator;

    return arithmeticOperator === ArithmeticOptions.count || arithmeticOperator === ArithmeticOptions.sum;
  }
}
