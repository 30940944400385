import {Component, Input, Output, EventEmitter} from '@angular/core';
import {LoggregationService} from '../../shared/services/loggregation.service';
import {LogQueryModel} from '@app/logs/shared/models/logsquery.model';
import {LoggregationEntity} from '../../shared/interfaces/loggregation.interface';
import * as _ from 'lodash';
import {
  StatisticsQueryDefinition,
  StatisticsCategory,
} from '@app/statistics/shared/models/statistics-query-definition';
import {QueryTypes} from '@app/logs/shared/models/query-types.options';
import {Metadata} from '@app/logs/shared/models/query-metadata';
import {SeveritiesHelper} from '@shared/services/severities.helper';
import {LoggregationPipe} from '@shared/pipes/loggregation.pipe';
import {Router} from '@angular/router';
import {GlobalParamsProvider} from '@shared/services/globalParams.provider';
import {TemplateType} from '../../shared/interfaces/templateType.enum';
import {LogsService} from '@app/logs/shared/services/logs-service';
import {ChartModel} from '@shared/controls/charts/models/chart.model';
import {WidgetBaseComponent} from '@shared/components/widget-base-component';

const generalTemplateTypes = [1, 2, 3, 4];

@Component({
  selector: 'sh-common-errors-widget',
  templateUrl: './common-errors-widget.component.html',
  styleUrls: ['./common-errors-widget.component.scss'],
})
export class CommonErrorsWidgetComponent extends WidgetBaseComponent {
  @Input() set queryModel(queryModel: LogQueryModel) {
    this.isReady.emit(false);
    this.isLoading = true;
    this.commonErrorResult = [];

    if (!queryModel) {
      return;
    }

    this.commonErrorResult = [];
    this.loggregationService
      .getLoggregationResult(queryModel)
      .first()
      .subscribe(
        (res) => {
          this.commonErrorResult = this.getTopLoggregationResult(res)
            .filter((l) => !generalTemplateTypes.includes(l.templateType)) // Ignore general templates
            .slice(0, this.maxResult)
            .map((l) => {
              let logText = l.text;
              if (l.templateType === TemplateType.jsonTemplate) {
                logText = logText.replace(
                  this.loggregationJsonParamPattern,
                  (match: string, p1: string, p2: string) => {
                    return p2.substring(0, 40);
                  },
                );
              }
              const commonError = new CommonErrorResult();
              commonError.statisticsQueryDefinition = new StatisticsQueryDefinition();
              commonError.loggregationEntity = l;
              commonError.statisticsQueryDefinition.logQeuryModel = _.cloneDeep<LogQueryModel>(queryModel);
              commonError.statisticsQueryDefinition.logQeuryModel.cacheQueryId = this.logsService.getNewQueryId();
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.query.text = `coralogix.templateId:"${l.id}"`;
              commonError.statisticsQueryDefinition.category = StatisticsCategory.LOGS;
              commonError.statisticsQueryDefinition.logQeuryModel.type = QueryTypes.TEMPLATE;
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.metadata = new Metadata();
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.applicationName.push(
                l.metadata.applicationName,
              );
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.subsystemName.push(
                l.metadata.subsystemName,
              );
              commonError.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.severity.push(
                SeveritiesHelper.getLogSeverityByName(l.metadata.severity),
              );
              commonError.statisticsQueryDefinition.seriesType = 'column';
              if (!commonError.link) {
                this.logsService
                  .saveLogsQuery(commonError.statisticsQueryDefinition.logQeuryModel)
                  .first()
                  .subscribe(() => {
                    commonError.link =
                      '/#/query-new/logs?id=' + commonError.statisticsQueryDefinition.logQeuryModel.cacheQueryId;
                  });
              }
              return commonError;
            });
          this.isReady.emit(true);
          this.isLoading = false;
        },
        (err) => console.log(err),
      );
  }

  public commonErrorResult: CommonErrorResult[] = [];

  @Input() public maxResult: number = 3;
  @Input() public theme: string = '';
  @Output() public isReady: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * query model for loggregation query
   */

  private loggregationPipe: LoggregationPipe = new LoggregationPipe();
  private loggregationParamPattern: RegExp = new RegExp(
    /\{CoralogixPH(\d+)_([^]*?.*?)_([free|categorical|numeric]+)_CoralogixPH\}/g,
  );
  private loggregationJsonParamPattern: RegExp = new RegExp(
    /\{CoralogixJsonPH(\d+)_([^]*?.*?)_([json]+)_CoralogixJsonPH\}/g,
  );

  constructor(
    private loggregationService: LoggregationService,
    private router: Router,
    private logsService: LogsService,
    private globalParams: GlobalParamsProvider,
  ) {
    super();
    this.loggregationPipe.clickableParam = '$2';
  }

  /**
   * get top most result ordered by count desc.
   * loggregationEntities: array of loggreagation
   * limit: number of results
   */
  getTopLoggregationResult(loggregationEntities: Array<LoggregationEntity>): Array<LoggregationEntity> {
    return _.orderBy(
      loggregationEntities,
      (entity: LoggregationEntity): number => {
        return entity.count;
      },
      'desc',
    );
  }

  onItemCliecked(commonErrorResult: CommonErrorResult): void {
    // if (commonErrorResult)
    //   this.navToLogs(commonErrorResult.statisticsQueryDefinition.logQeuryModel);
  }

  onChartClicked(e: any[]): void {
    if (e[0]) {
      this.navToLogs(e[0]);
    }
  }

  navToLogs(queryModel: LogQueryModel): void {
    this.globalParams.chartClickedLogQueryModel = queryModel;
    this.router.navigate(['/query-new/logs']);
  }

  severityColor(colorName: string): { [className: string]: boolean } {
    return {
      ['text-cgx-' + colorName]: true
    }
  }
}

class CommonErrorResult {
  public loggregationEntity: LoggregationEntity;
  public statisticsQueryDefinition: StatisticsQueryDefinition;
  public chartModel: ChartModel;
  public link: string;
}
