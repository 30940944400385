import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromDate',
})
export class FromDatePipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    return moment(value).fromNow();
  }
}
