import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICellHeightInfo } from '@app/logs-new/shared/models/grid-cells.model';
import { ICellRendererParams } from 'ag-grid-community';
import { getValueFromObjectByDotPath } from '@shared/modules/json-formatter/json-formatter.utils';

@Component({
  selector: 'sh-json-formatter-renderer',
  templateUrl: 'grid-json-formatter.component.html',
  styleUrls: ['grid-json-formatter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonFormatterComponent {
  @Input() set params(params: ICellRendererParams) {
    if (params && !this.params) {
      this._params = params;
      this.columnId = params.colDef.colId;
      this.value = getValueFromObjectByDotPath(params.data, this.columnId);
      this.setShowMoreStatus();
    }
  }
  get params(): ICellRendererParams {
    return this._params;
  }
  public value: string = '';
  public isRowExpanded$ = new BehaviorSubject(false);
  public columnId: string;
  public hasMoreContent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _params: ICellRendererParams;

  public setRowHeight(): void {
    const rowNode = this.params.api.getRowNode(this.params.node.id);
    const heightMap = this.getHeightMapValue();
    const newHeight = this.isRowExpanded$.getValue() ? heightMap.previewHeight : heightMap.actualHeight;
    rowNode.setRowHeight(newHeight);
    this.params.api.onRowHeightChanged();
    this.isRowExpanded$.next(!this.isRowExpanded$.getValue());
  }

  public refresh(): boolean {
    this.isRowExpanded$.next(false);
    this.setShowMoreStatus();
    return true;
  }

  private getHeightMapValue(): ICellHeightInfo {
    const { logsGridService } = this.params.context.parent;
    const heightMap = logsGridService.gridCellsHeightMap$.getValue();
    const rowId = this.params.node.id;
    return heightMap.get(`${rowId}-${this.columnId}`);
  }

  private setShowMoreStatus(): void {
    const cellInfo = this.getHeightMapValue();
    const hasMoreContent = cellInfo && cellInfo.actualHeight > cellInfo.previewHeight;
    this.hasMoreContent$.next(hasMoreContent);
  }
}
