import { Action } from '@ngrx/store';
import { type } from '../../shared/services/utils';
import { Rule } from '../shared/models/rule.model';

export namespace RulesValidateActions {
  export const ActionTypes = {
    CHANGE_SELECTED_PREVIEW_LOG: type('[Rule] Change Selected preview log'),
    VALIDATE_PREVIEW_TEXT: type('[Rule] Validate Preview Text'),
    VALIDATE_PREVIEW_TEXT_COMPLETED: type(
      '[Rule] Validate Preview Text Completed',
    ),
    VALIDATE_PREVIEW_TEXT_FAILED: type('[Rule] Validate Preview Text Failed'),
  };

  export class ChangeSelectedPreviewLogAction implements Action {
    public type = ActionTypes.CHANGE_SELECTED_PREVIEW_LOG;

    constructor(public payload: string) {}
  }

  export class ValidatePreviewTextAction implements Action {
    public type = ActionTypes.VALIDATE_PREVIEW_TEXT;

    constructor(public payload: Rule) {}
  }

  export class ValidatePreviewTextActionCompleted implements Action {
    public type = ActionTypes.VALIDATE_PREVIEW_TEXT_COMPLETED;

    constructor(public payload: any) {}
  }

  export class ValidatePreviewTextActionFailed implements Action {
    public type = ActionTypes.VALIDATE_PREVIEW_TEXT_FAILED;

    constructor(public payload: any) {}
  }

  export type Actions =
    | ChangeSelectedPreviewLogAction
    | ValidatePreviewTextAction
    | ValidatePreviewTextActionCompleted
    | ValidatePreviewTextActionFailed;
}
