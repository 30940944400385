<div [formGroup]="queryFrom" class="ui secondary pointing menu tabs bar">
  <sh-toggle-button formControlName="isTemplateSearch" #isTemplateToggleButton>
    <span un-checked>
      Text
      <i
        style="transform: rotate(90deg);margin-left: 10px !important;"
        class="fa fa-sort"
        aria-hidden="true"
      ></i>
    </span>
    <span checked>
      Template
      <i
        style="transform: rotate(90deg)"
        class="fa fa-sort"
        aria-hidden="true"
      ></i>
    </span>
  </sh-toggle-button>

  <div class="ui left icon input sh-search-bar">
    <input
      type="text"
      placeholder="Search..."
      (keyup.enter)="executeSearch()"
      #query
      formControlName="text"
      matInput
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      class="recent-queries-box"
      (optionSelected)="submitSuggested($event.option.value)">
      <div class="recent-queries-box header" *ngIf="(filteredFieldsOptions | async)?.length > 0">KEY SUGGESTIONS</div>
      <mat-option
        (onSelectionChange)="onSelectionChanged()"
        *ngFor="let option of filteredFieldsOptions | async"
        [value]="option"
        class="recent-queries-option"
      >
        <i class="search icon"></i>
        <span class="suggested-text">{{ option.suggestedHead }}</span>{{ option.term }}<span class="suggested-text">{{ option.suggestedTail }}</span>
      </mat-option>
      <div class="recent-queries-box header">RECENT SEARCHES</div>
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
        class="recent-queries-option"
      >
        <i class="search icon"></i>
        <span class="suggested-text">{{ option.suggestedHead }}</span>{{ option.term }}<span class="suggested-text">{{ option.suggestedTail }}</span>
      </mat-option>
    </mat-autocomplete>
    <i class="lab icon" #queryicon></i>
    <div class="ui special fluid popup" style="width: 100%">
      <sh-logs-query-tutorial></sh-logs-query-tutorial>
    </div>
  </div>
  <div (click)="executeSearch()" class="go-btn">GO</div>
  <sh-logs-before-after
    *ngIf="showFind"
    (onBeforeAfter)="beforeAfter.emit($event)"
    [selectedLog]="selectedLog"
  ></sh-logs-before-after>

  <sh-logs-options-menu
    (toggleFilters)="toggleFilters.emit()"
    (toggleGrpah)="toggleVisibility.emit()"
    (widget)="addWidget.emit()"
    [addWidgetEnable]="addWidgetEnable"
    (export)="exportLogs.emit()"
    (detectGroks)="detectGroks.emit()"
  ></sh-logs-options-menu>
</div>
