import { Injectable } from '@angular/core';
import {
  PredefinedQueries,
  PredefinedQuickQueryType,
} from '../models/predefined-queries';
import * as moment from 'moment';
import { LogQueryModel } from '../models/logsquery.model';

@Injectable()
export class PredefinedQueryHelper {
  private static predefinedQueries = new PredefinedQueries();

  public static getQueryCaption(queryModel: LogQueryModel): string {
    const queryTimeInSeconds =
      (queryModel.endDate - queryModel.startDate) / 1000;
    const predefinedQuery = this.predefinedQueries.Queries.find(
      (query) => query.seconds === queryTimeInSeconds,
    );

    if (!predefinedQuery) {
      return null;
    }

    return predefinedQuery.caption;
  }

  constructor() {}

  public getStartDate(predefinedQuery): number {
    let seconds;
    let startDate;
    switch (predefinedQuery.value) {
      case PredefinedQuickQueryType.Today: {
        return (
          moment(Date.now())
            .startOf('day')
            .unix() * 1000
        );
        // console.log(startDate);
      }
      default: {
        seconds = predefinedQuery.seconds;
        startDate = Date.now() - seconds * 1000;
      }
    }
    return startDate;
  }
}
