import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AlertsComponent } from './alerts.component';
import { AlertsDetailsFeedComponent } from './alerts-details-feed/alerts-details-feed.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogsModule } from '../logs/logs.module';
import { AlertsService } from './shared/services/alerts-service';
import { AlertHelper } from './shared/services/alert-helper';
import { AlertsItemStatisticsComponent } from './alerts-item-statistics/alerts-item-statistics.component';
import { StatisticsModule } from '../statistics/statistics.module';
import { InsightsModule } from '../insights/insights.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { InsightsIdFilterPipe, InsightsTimeAggregatorPipe } from '@shared/pipes/insights-alert-filter.pipe';
import { AlertsSummaryCardComponent } from './alerts-summary-cards/alerts-summary-card.component';
import { CommonModule } from '@angular/common';
import { AlertsEditorModule } from '@app/alerts/alerts-editor/alerts-editor.module';
import { AlertsTableComponent } from './alerts-table/alerts-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { AlertMapperService } from '@app/alerts/alerts-editor/services/alert-mapper.service';
import { AlertsEditorFormService } from '@app/alerts/alerts-editor/services/alerts-editor-form.service';
import { AlertsEmptyComponent } from './alerts-empty/alerts-empty.component';
import { AlertsServiceHelper } from '@app/alerts/shared/services/alerts-service-helper';
import { AlertsSeverityChartComponent } from '@app/alerts/alerts-summary-cards/components/alerts-severity-chart/alerts-severity-chart.component';
import { SummaryChartComponent } from '@app/alerts/alerts-summary-cards/components/summary-chart/summary-chart.component';
import { CgxButtonModule } from '@coralogix/design';
import { DateFormatByUserSettingsModule } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    SharedModule,
    FormsModule,
    LogsModule,
    StatisticsModule,
    InsightsModule,
    MatTableModule,
    AlertsEditorModule,
    MatTableModule,
    MatSortModule,
    CgxButtonModule,
    DateFormatByUserSettingsModule,
  ],
  declarations: [
    AlertsComponent,
    AlertsDetailsFeedComponent,
    AlertsItemStatisticsComponent,
    InsightsIdFilterPipe,
    InsightsTimeAggregatorPipe,
    AlertsSummaryCardComponent,
    AlertsTableComponent,
    AlertsEmptyComponent,
    AlertsSeverityChartComponent,
    SummaryChartComponent,
  ],
  providers: [AlertsService, AlertHelper, AlertMapperService, AlertsEditorFormService, AlertsServiceHelper],
})
export class AlertsModule {}
