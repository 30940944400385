import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DeploymentService } from './shared/services/deployment.service';
import { ManualEventFormProviderService } from './shared/services/manual-event-form-provider.service';
import { TagsMainLayoutComponent } from './containers/tags-main-layout/tags-main-layout.component';
import { TagsListComponent } from './list/tags-list/tags-list.component';
import { TagsListItemComponent } from './list/tags-list-item/tags-list-item.component';
import { RouterModule } from '@angular/router';
import { EventInfoContainerComponent } from './containers/event-info-container/event-info-container.component';
import { EventInfoViewerComponent } from './components/event-info-viewer/event-info-viewer.component';
import { LoggregationModule } from '../loggregation/loggregation.module';
import { StatisticsModule } from '../statistics/statistics.module';
import { LogsModule } from '../logs/logs.module';
import { TagsWelcomeComponent } from './components/welcome/tags-welcome/tags-welcome.component';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { HighSeverityLogsDistributionComponent } from './components/high-severity-logs-distribution/high-severity-logs-distribution.component';
import { ToggleCompareTagComponent } from './components/toggle-compare-tag/toggle-compare-tag.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { TagPanelContainerComponent } from './containers/tag-panel-container/tag-panel-container.component';
import { TagPanelContentComponent } from './components/tag-panel-content/tag-panel-content.component';
import { DeploymentStoreService } from './shared/services/deployment.store.service';
import { AlertsEditorModule } from '@app/alerts/alerts-editor/alerts-editor.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { SummaryTilesComponent } from './components/summary-tiles/summary-tiles.component';
import { AlertsSummaryComponent } from './components/alerts-summary/alerts-summary.component';
import { TagErrorVolumeComponent } from './components/tag-error-volume/tag-error-volume.component';
import { SelectedTagFeatureLayoutComponent } from './components/selected-tag-feature-layout/selected-tag-feature-layout.component';
import { NewlyIntroducedLogsComponent } from './components/newly-introduced-logs/newly-introduced-logs.component';
import { TopErrorTemplatesComponent } from './components/top-error-templates/top-error-templates.component';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { ErrorAnomaliesComponent } from './components/error-anomalies/error-anomalies.component';
import { CompareLineGraphWidgetComponent } from './components/compare-line-graph-widget/compare-line-graph-widget.component';
import { FilterPipeModule } from '@app/shared/pipes/filter-pipe/filter-pipe.module';
import { DateFormatByUserSettingsModule } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.module';
import { CgxButtonModule } from '@coralogix/design';
import { LogsNewGridModule } from '@app/logs-new/shared/features/logs-new-grid/logs-new-grid.module';
import { EmptyStateContainerModule } from '@app/shared/components/empty-state-container/empty-state-container.module';
import { StatisticsTileModule } from '@app/shared/components/statistics-tile/statistics-tile.module';

@NgModule({
  declarations: [
    TagsMainLayoutComponent,
    TagsListComponent,
    EventInfoContainerComponent,
    EventInfoViewerComponent,
    TagsWelcomeComponent,
    HighSeverityLogsDistributionComponent,
    ToggleCompareTagComponent,
    TagsListItemComponent,
    SummaryTilesComponent,
    ActionMenuComponent,
    TagPanelContainerComponent,
    TagPanelContentComponent,
    AlertsSummaryComponent,
    TagErrorVolumeComponent,
    SelectedTagFeatureLayoutComponent,
    TopErrorTemplatesComponent,
    NewlyIntroducedLogsComponent,
    ErrorAnomaliesComponent,
    CompareLineGraphWidgetComponent,
  ],
  imports: [
    SharedModule,
    LogsModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    LoggregationModule,
    StoreRouterConnectingModule.forRoot(),
    StatisticsModule,
    AlertsEditorModule,
    FlexModule,
    FlexLayoutModule,
    WidgetsModule,
    FilterPipeModule,
    DateFormatByUserSettingsModule,
    CgxButtonModule,
    LogsNewGridModule,
    EmptyStateContainerModule,
    StatisticsTileModule,
  ],
  providers: [DeploymentService, ManualEventFormProviderService, DeploymentStoreService],
})
export class DeploymentModule {}
