import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { ActiveWhenProperties, MetricAlert, RelativeAlert } from './alert-model';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class CreateNUpdateAlertModel {
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public IPAddress?: any[] = [];
  @jsonMember
  public alertSeverity?: number;
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public applicationName?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public category?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public className?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public computerName?: any[] = [];
  @jsonMember
  public conditionOperator?: number;
  @jsonMember
  public conditionThreshold?: number;
  @jsonMember
  public conditionTimeframe?: number;
  @jsonMember
  public description?: string;
  @jsonMember
  public notifyOnResolved?: boolean;
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public emailNotifAddresses?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public notifPayloadFilter?: any[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public groupByFields?: any[] = [];
  @jsonMember
  public notifyGroupByOnlyAlerts?: boolean;
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public cardinalityFields?: any[] = [];
  @jsonMember
  public cardinalityGroupByFields?: number;
  @jsonMember
  public endDate?: number;
  @jsonMember
  public hasConditions?: boolean;
  @jsonMember
  public id: number | string;
  @jsonArrayMember(Number)
  public logSeverity?: number[] = [];
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public methodName?: any[] = [];
  @jsonMember
  public name?: string;
  @jsonMember
  public startDate?: number;
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public subsystemName?: any[] = [];
  @jsonMember
  public text?: string;
  @jsonMember
  public userAlertTypeId?: UserAlertTypes;
  @jsonMember
  public isActive?: boolean;
  @jsonMember
  public verifyTimeRange?: number;
  @jsonMember
  public refractoryPeriod?: number;
  @jsonMember
  public activeWhen?: ActiveWhenProperties;
  @jsonArrayMember(RelativeAlert)
  public relativeAlerts?: RelativeAlert[] = [];
  @jsonMember
  public metricAlert: MetricAlert;
}

export enum UserAlertTypes {
  UserTextAlert = 1,
  LogRecordAlert = 2,
  DynamicAlert = 3,
  RatioAlert = 4,
  Cardinality = 5,
  MetricAlert = 6,
  RelativeTime = 7,
}
