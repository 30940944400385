export class PredefinedQueries {
  public readonly Queries;

  constructor() {
    this.Queries = [
      {
        caption: 'Last 1 minute',
        seconds: 60,
        value: PredefinedQuickQueryType.Last1Minute,
      },
      {
        caption: 'Last 2 minutes',
        seconds: 120,
        value: PredefinedQuickQueryType.Last2Minutes,
      },
      {
        caption: 'Last 5 minutes',
        seconds: 300,
        value: PredefinedQuickQueryType.Last5Minutes,
      },
      {
        caption: 'Last 15 minutes',
        seconds: 900,
        value: PredefinedQuickQueryType.Last15Minutes,
      },
      {
        caption: 'Last 30 minutes',
        seconds: 1800,
        value: PredefinedQuickQueryType.Last30Minutes,
      },
      {
        caption: 'Last 1 hour',
        seconds: 3600,
        value: PredefinedQuickQueryType.Last1Hour,
      },
      {
        caption: 'Last 2 hours',
        seconds: 7200,
        value: PredefinedQuickQueryType.Last2Hours,
      },
      {
        caption: 'Last 6 hours',
        seconds: 21600,
        value: PredefinedQuickQueryType.Last6Hours,
      },
      {
        caption: 'Last 12 hours',
        seconds: 43200,
        value: PredefinedQuickQueryType.Last12Hours,
      },
      {
        caption: 'Last 24 hours',
        seconds: 86400,
        value: PredefinedQuickQueryType.Last24Hours,
      },
      {
        caption: 'Today',
        value: PredefinedQuickQueryType.Today,
      },
      {
        caption: 'Last 2 Days',
        value: PredefinedQuickQueryType._2Days,
        seconds: 172800,
      },
      {
        caption: 'Last 3 Days',
        value: PredefinedQuickQueryType._3Days,
        seconds: 259200,
      },
      {
        caption: 'Last 7 Days',
        value: PredefinedQuickQueryType._7Days,
        seconds: 604800,
      },
    ];
  }
}

export enum PredefinedQuickQueryType {
  Last1Minute = 1,
  Last2Minutes = 2,
  Last5Minutes = 3,
  Last15Minutes = 4,
  Last30Minutes = 5,
  Last1Hour = 6,
  Last2Hours = 7,
  Last6Hours = 8,
  Last12Hours = 9,
  Last24Hours = 10,
  Today = 11,
  _2Days = 12,
  _3Days = 13,
  _7Days = 14,
}
