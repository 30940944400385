import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertViewLayoutComponent } from './alert-view-layout.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/shared/material/material.module';

@NgModule({
  declarations: [AlertViewLayoutComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule
  ],
  exports: [AlertViewLayoutComponent]
})
export class AlertViewLayoutModule { }
