import {
  Component, ViewEncapsulation, ChangeDetectionStrategy,
  forwardRef, ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LogsFilterCategoryComponent } from '../logs-filter-category/logs-filter-category.component';
import { CustomFiltersProvider } from '../logs-filter-category/CustomFilters.provider';
import {LogsViewStateProvider} from '@app/logs/shared/services/logsViewState.provider';

@Component({
  selector: 'sh-logs-filter-severity',
  templateUrl: './logs-filter-severity.component.html',
  styleUrls: ['./logs-filter-severity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FilterSeverityProvider(),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.Emulated,
  // changeDetection: ChangeDetectionStrategy.Default
})
export class LogsFilterSeverityComponent extends LogsFilterCategoryComponent
  implements ControlValueAccessor {
  constructor(
    protected customFiltersProvider: CustomFiltersProvider,
    protected _cd: ChangeDetectorRef,
    protected logsViewStateProvider: LogsViewStateProvider
  ) {
    super(customFiltersProvider, _cd, logsViewStateProvider);
  }

  // protected filter() {
  //   this.filterRows = this.filterPipe.transform(this._categoryAggregation, this._filterString, 'name');
  // }

  protected sort(val: any): any {
    return val;
  }

  // protected filterEmptyRows() {
  //   if (this.filterRows) {
  //     this.filterRows = this.filterRows.filter((x) => x && x.name);
  //   }
  //   console.log(this.filterRows);
  // }
}

export function FilterSeverityProvider(): any {
  return forwardRef(() => LogsFilterSeverityComponent);
}
