import { Injectable } from '@angular/core';
import { getDateFormatViewPreference, getTimezoneViewPreference, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import moment, { isDate, Moment } from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AmPmMomentOptions,
  DateInputType,
  getDateFromEpochValue,
  getDateFromUnixValue,
  InvalidDateResult,
  MomentDateFormatTypes,
  MomentTimeFormatTypes,
} from '../helpers/moment.helper';
import { DateFormatType } from '../models/date-format-types';
import { TimeZoneType } from '../models/timezone-types';

@Injectable({ providedIn: 'root' })
export class TimeService {
  public timezone$ = this.store.select(getTimezoneViewPreference);
  public dateFormatType$ = this.store.select(getDateFormatViewPreference);
  public timeZoneSnapshot: TimeZoneType;
  public dateFormatTypeSnapshot: DateFormatType;

  constructor(private store: Store<State>) {
    this.timezone$.subscribe(timezone => (this.timeZoneSnapshot = timezone));
    this.dateFormatType$.subscribe(dateFormat => (this.dateFormatTypeSnapshot = dateFormat));
  }

  public getUserFormattedDate(date: Date | string): { fullDate: string; time: string } {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const formattedDate = this.getStringDateByUserSettings(date, null, MomentTimeFormatTypes.standard) as string;
    if (!formattedDate) return { fullDate: '', time: '' };
    const [fullDate, time] = formattedDate.split(' ');
    return { fullDate, time };
  }

  public getStringDateByUserSettings$(
    value: any,
    amPmFormat: AmPmMomentOptions = AmPmMomentOptions.smallLetters,
    hoursFormat: MomentTimeFormatTypes = MomentTimeFormatTypes.withMilliseconds,
    showDate: boolean = true,
    uniqueFullFormat: string = null,
    dateInputType: DateInputType = DateInputType.unix,
  ): Observable<Moment | string> {
    return combineLatest([this.timezone$, this.dateFormatType$]).pipe(
      map(([tmzOption, dateType]) => {
        return this.getStringDateByUserSettings(
          value,
          amPmFormat,
          hoursFormat,
          tmzOption,
          dateType,
          showDate,
          uniqueFullFormat,
          dateInputType,
        );
      }),
    );
  }

  public getStringDateByUserSettings(
    value: any,
    amPmFormat: AmPmMomentOptions = AmPmMomentOptions.smallLetters,
    hoursFormat: MomentTimeFormatTypes = MomentTimeFormatTypes.withMilliseconds,
    tmzOption?: TimeZoneType,
    dateType?: DateFormatType,
    showDate: boolean = true,
    uniqueFullFormat: string = null,
    dateInputType: DateInputType = DateInputType.unix,
  ): string | Moment {
    tmzOption = tmzOption || this.timeZoneSnapshot;
    dateType = dateType || this.dateFormatTypeSnapshot;
    const keepLocalTime: boolean = tmzOption === TimeZoneType.local;
    const uniqueFullDatetimeFormat = this.getDateFormatByUser(dateType, amPmFormat, showDate, hoursFormat, uniqueFullFormat);
    try {
      const valueAsDate: Date = new Date(value);
      let finalDate: Moment | string = moment(value);

      if (isDate(valueAsDate)) {
        finalDate = moment(valueAsDate)
          .utc(keepLocalTime)
          .format(uniqueFullDatetimeFormat);
      }
      if (finalDate === InvalidDateResult && dateInputType === DateInputType.unix) {
        finalDate = getDateFromUnixValue(value, keepLocalTime, uniqueFullDatetimeFormat);
      }
      if (dateInputType === DateInputType.epoch) {
        finalDate = getDateFromEpochValue(value, keepLocalTime, uniqueFullDatetimeFormat);
      }
      return finalDate;
    } catch (e) {
      return null;
    }
  }

  public getDateByUserSettings(date: Date, localeFunction: (date: Date) => Moment): Date {
    const keepLocalTime: boolean = this.timeZoneSnapshot === TimeZoneType.local;
    return keepLocalTime ? moment(date).toDate() : localeFunction(date).toDate();
  }

  private getDateFormatByUser(
    dateType: DateFormatType,
    amPmFormat: AmPmMomentOptions = AmPmMomentOptions.smallLetters,
    showDate: boolean = true,
    hoursFormat: MomentTimeFormatTypes = MomentTimeFormatTypes.withMilliseconds,
    uniqueFullFormat: string = null,
  ): string {
    const momentDates: typeof MomentDateFormatTypes = MomentDateFormatTypes;
    const userDateType: MomentDateFormatTypes = dateType === DateFormatType.european ? momentDates.european : momentDates.american;
    const fullDateTimeFormat: string = `${showDate ? userDateType + ' ' : ''}${hoursFormat}${amPmFormat ? ' ' + amPmFormat : ''}`;
    const uniqueFullDatetimeFormat: string = uniqueFullFormat ? uniqueFullFormat : fullDateTimeFormat;
    return uniqueFullDatetimeFormat;
  }
}
