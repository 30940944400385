import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { statisticsTrend } from '../../shared/models/statisticsTrend';
declare var require: any;
const ProgressBar = require('progressbar.js');
@Component({
  selector: 'sh-high-severity-logs-distribution',
  templateUrl: './high-severity-logs-distribution.component.html',
  styleUrls: ['./high-severity-logs-distribution.component.scss'],
})
export class HighSeverityLogsDistributionComponent implements OnInit {
  @Input() set statisticsTrend(value: statisticsTrend) {
    this._statisticsTrend = value;
    // console.log(this._statisticsTrend);
    if (this.bar && value) {
      this.bar.animate(value.statisticsAfterTag.highSeverityPercent);
    }
  }
  @ViewChild('progressContainer', { static: true }) public element: ElementRef;

  // @Input() statisticsTrend: statisticsTrend;
  public _statisticsTrend: statisticsTrend;

  public color: string = '#f04879';
  public trailColor: string = '#eee';
  private bar: any;

  constructor() {}

  public ngOnInit() {
    this.bar = new ProgressBar.Circle(this.element.nativeElement, {
      strokeWidth: 6,
      easing: 'easeInOut',
      duration: 1400,
      color: this.color,
      trailColor: this.trailColor,
      trailWidth: 1,
      svgStyle: null,
    });
  }
}
