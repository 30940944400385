import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

export interface IConfirmationDialogData {
  title: string;
  body: string;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  width?: string;
  height?: string;
  hideCloseBtn?: boolean;
  primaryActionColor?;
  action$?: () => Observable<any>;
}
@Component({
  selector: 'sh-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  public loading: boolean = false;
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData) {
    dialogRef.disableClose = true;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onApply(): void {
    if (this.data.action$) {
      this.loading = true;
      this.data
        .action$()
        .pipe(
          map(data => {
            this.dialogRef.close(data);
          }),
          catchError(err => {
            this.dialogRef.close(new Error(err));
            return of();
          }),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe();
    } else {
      this.dialogRef.close(true);
    }
  }
}
