import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumTranslatorPipe } from './enum-translator.pipe';

@NgModule({
  declarations: [EnumTranslatorPipe],
  imports: [
    CommonModule
  ],
  exports: [EnumTranslatorPipe]
})
export class EnumTranslatorModule { }
