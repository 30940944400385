import { Component, Input } from '@angular/core';
import { Entity } from '@shared/models/entity.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'sh-alerts-day-picker',
    templateUrl: './alerts-day-picker.component.html',
    styleUrls: ['./alerts-day-picker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AlertsDayPickerComponent,
        multi: true
    }],
})
export class AlertsDayPickerComponent {
    @Input()
    public weekDayOptions: Entity[];

    public value: number[] = [];

    private onChangeFn: (val: any) => any;
    private onTouchedFn: (val: any) => any;

    public registerOnChange(fn: (val: any) => any): void {
        this.onChangeFn = fn;
    }

    public registerOnTouched(fn: (val: any) => any): void {
        this.onTouchedFn = fn;
    }

    public onModelChange(day: number): void {
      if (this.value.includes(day) && this.value.length > 1) {
        // Check that we have at list two days in the week before removing
        this.value.splice(this.value.indexOf(day), 1);
      } else if (!this.value.includes(day)) {
            this.value.push(day);
      }

        if (this.onChangeFn) {
            this.onChangeFn(this.value);
        }
    }

    public writeValue(value: number[]): void {
        if (value) {
            this.value = value;
        }
    }
}
