import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class ExportType {
  @jsonMember
  public id: number;
  @jsonMember
  public typeName: string;
  @jsonMember
  public fileExtention: string;
}

@jsonObject
export class Json extends ExportType {
  public static id: number = 0;
  public static typeName: string = 'JSON';
  public static fileExtention: string = 'json';
}

@jsonObject
export class Csv extends ExportType {
  public static id: number = 1;
  public static typeName: string = 'CSV';
  public static fileExtention: string = 'csv';
}

@jsonObject
export class ExportData {
  @jsonMember
  public id: number;
  @jsonMember
  public fileName: string;
  @jsonMember
  public delimiter: string;
}
