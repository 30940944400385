<div class="content userSettings" *ngIf="!isSmallScreen">
  <form>
    <mat-form-field>
      <mat-select
        class="app-select"
        disableOptionCentering
        floatPlaceholder="never"
        (selectionChange)="changeSelectedApplication($event.value)"
        [(ngModel)]="applicationName"
        name="application"
        panelClass="userSettingsOptions"
        multiple
      >
        <div class="search-container">
          <div class="search-container-wrap">
            <input
              type="text"
              placeholder="Filter applications..."
              (keyup)="filterApplicationChanged($event.target.value)"
            />
          </div>
        </div>
        <mat-select-trigger>
          {{ applicationName.length > 1 ? applicationName.length + ' Applications' : applicationName[0] }}
        </mat-select-trigger>
        <mat-option value="All applications">All applications</mat-option>
        <mat-optgroup label="Select applications">
          <mat-option
            *ngFor="let value of applicationNameOptions"
            [value]="value"
            [class.hidden]="!isAppMatchedWithFilter(value)"
          >
            {{ value }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        class="subsystem-select"
        disableOptionCentering
        floatPlaceholder="never"
        [(ngModel)]="subsystemName"
        (selectionChange)="changeSelectedSubsystem($event.value)"
        name="subsystem"
        panelClass="userSettingsOptions"
        multiple
      >
        <div class="search-container">
          <div class="search-container-wrap">
            <input
              type="text"
              placeholder="Filter subsystems..."
              (keyup)="filterSubsystemsChanged($event.target.value)"
            />
          </div>
        </div>
        <mat-select-trigger>
          {{ subsystemName.length > 1 ? subsystemName.length + ' Subsystems' : subsystemName[0] }}
        </mat-select-trigger>
        <mat-option value="All subsystems">All subsystems</mat-option>
        <mat-optgroup label="Select subsystems">
          <mat-option
            *ngFor="let value of subsystemNameOptions"
            [value]="value"
            [class.hidden]="!isSubsystemMatchedWithFilter(value)"
          >
            {{ value }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<sh-user-settings-mobile-filters
  *ngIf="isSmallScreen"
  [applicationNameOptions]="applicationNameOptions"
  [subsystemNameOptions]="subsystemNameOptions"
  [selectedApps]="applicationName"
  [selectedSubsystems]="subsystemName"
  (applicationChange)="changeSelectedApplication($event)"
  (subsystemChange)="changeSelectedSubsystem($event)"
></sh-user-settings-mobile-filters>
