import { LogActions } from './log.actions';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
export namespace LogReducer {
  export interface State {
    logCount: number;
    templateCount: number;
    logsPanelQuery: LogQueryModel;
  }

  const initalState: State = {
    logCount: 0,
    templateCount: 0,
    logsPanelQuery: null
  };

  export function reducer(
    state: State = initalState,
    action: LogActions.Actions,
  ): State {
    switch (action.type) {
      case LogActions.ActionTypes.GET_LOGS_AND_TEMPLATE_COUNT:
        return state;
      case LogActions.ActionTypes.GET_LOGS_AND_TEMPLATE_COUNT_COMPLETED:
        return Object.assign({}, state, {
          logCount: action.payload.logsCount,
          templateCount: action.payload.templateCount,
        });
      case LogActions.ActionTypes.GET_LOGS_AND_TEMPLATE_COUNT_FAILED:
        return state;
      case LogActions.ActionTypes.SET_LOGS_QUERY:
        return Object.assign({}, state, {
          logsPanelQuery: action.payload
        });
      default:
        return state;
    }
  }
}
