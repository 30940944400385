import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CompanyRequest } from '@app/auth/shared/models/activateUser.model';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

export interface IRoutingPath {
  label: string;
  path: string;
  isVisible: boolean;
}

@Component({
  selector: 'sh-desktop-navbar',
  templateUrl: './desktop-navbar.component.html',
  styleUrls: ['./desktop-navbar.component.scss'],
})
export class DesktopNavbarComponent implements OnInit, OnDestroy {
  @ViewChild('dataFlowTooltipRef') dataFlowTooltipRef;

  @ViewChild('appsTooltipRef') appsTooltipRef;

  @SelectSnapshot(UserState.username) public username: string;

  @Select(UserState.username) public username$: Observable<string>;

  @Select(CompanyState.companyInfo) public companyInfo$: Observable<CompanyRequest>;

  @SelectSnapshot(UserState.isReadOnly) public isReadOnly: boolean;

  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  @Input() public isUserHasAlertPermissions: boolean;

  @Input() public companyDetails: CompanyRequest;

  @Input() public showUserSettingsFilters: boolean;

  @Input() isAdmin: boolean;

  dataFlowRoutingPaths: IRoutingPath[];

  isArchiveVersionValid: boolean;

  destroyed$ = new Subject();

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.companyInfo$.pipe(takeUntil(this.destroyed$)).subscribe(company => {
      this.isArchiveVersionValid = company?.archiving_provider?.archive_format !== 1;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeTooltip(): void {
    this.dataFlowTooltipRef.hide();
    // this.appsTooltipRef.hide();
  }

  checkDataFlowActivated(): boolean {
    return ['/integration', '/tco', '/extensions', '/enrichments', '/rules'].some(path => this.router.url.startsWith(path));
  }
}
