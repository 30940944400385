import { Injectable } from '@angular/core';
import { AgGridEvent, ColumnApi, RowNode } from 'ag-grid-community';
import { ICellHeightInfo } from '@app/logs-new/shared/models/grid-cells.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { JsonFormatterService } from '@shared/modules/json-formatter/json-formatter.service';
import { MAX_GRID_ROW_HEIGHT } from '@app/logs-new/shared/constants/logs-screen.constants';
import { checkAndParseJSON } from '@shared/utils/utils';
import { ILog } from '@app/logs-new/shared/models/logs-data.model';
import { getValueFromObjectByDotPath } from '@shared/modules/json-formatter/json-formatter.utils';

@Injectable({
  providedIn: 'root',
})
export class LogsGridService {
  public gridCellsHeightMap$: BehaviorSubject<Map<string, ICellHeightInfo>> = new BehaviorSubject<Map<string, ICellHeightInfo>>(new Map());
  public params: AgGridEvent;
  public selectLog$ = new BehaviorSubject<ILog>(null);
  public paginationIndex$ = new BehaviorSubject(null);
  public resetPagination$: Subject<boolean> = new Subject<boolean>();
  public selectedRow$ = new BehaviorSubject<RowNode>(null);

  public getRowHeight(rowNode: RowNode, prop: string, width: number, isExpanded: boolean): number {
    const cellData = prop === 'text' ? rowNode.data.rawText || rowNode.data.text : getValueFromObjectByDotPath(rowNode.data, prop);
    const height = this.renderDataAndGetHeight(cellData, width, isExpanded);
    this.updateCellHeightMap(rowNode, prop, height, isExpanded);
    if (height <= MAX_GRID_ROW_HEIGHT || !isExpanded) {
      return height;
    }
    return MAX_GRID_ROW_HEIGHT;
  }

  public getBiggestProp(rowContext: RowNode, gridColumnApi: ColumnApi, isExpanded: boolean): string {
    const textColumn = gridColumnApi.getColumn('text');
    const textExists = rowContext.data.text && textColumn;
    return textExists ? 'text' : this.renderAllDataAndGetBiggestProp(rowContext, gridColumnApi, isExpanded);
  }

  public updateSelectedRow(rowNode: RowNode): void {
    this.selectedRow$.next(rowNode);
  }

  public renderElementAndGetHeight(formattedElement: HTMLElement, width: number): number {
    const div = document.createElement('div');
    const cellLineHeight = '20px';
    const cellLinePadding = '8px 16px';
    div.setAttribute(
      'style',
      `position: absolute; opacity: 0; line-height: ${cellLineHeight}; max-width: ${width}px; padding: ${cellLinePadding}`,
    );
    div.appendChild(formattedElement);
    const renderedElement = document.body.appendChild(div);
    const buffer = 20;
    const elementHeight = renderedElement.scrollHeight;
    renderedElement.remove();
    return elementHeight + buffer;
  }

  public resetSurroundLog(): void {
    this.selectLog$.next(null);
  }

  private renderAllDataAndGetBiggestProp(rowContext: RowNode, gridColumnApi: ColumnApi, isExpanded: boolean): string {
    let biggestHeight = 0;
    let propKey = '';
    gridColumnApi.getColumnState().forEach(col => {
      const colData = getValueFromObjectByDotPath(rowContext.data, col.colId);
      const height = this.renderDataAndGetHeight(colData, col.width, isExpanded);
      if (height > biggestHeight) {
        biggestHeight = height;
        propKey = col.colId;
      }
      this.updateCellHeightMap(rowContext, col.colId, height, isExpanded);
    });
    return propKey;
  }

  private updateCellHeightMap(rowContext: RowNode, field: string, actualHeight: number, isExpanded: boolean): void {
    const rowId = rowContext.id;
    const cellsMap = this.gridCellsHeightMap$.getValue();
    const previewHeight = actualHeight >= MAX_GRID_ROW_HEIGHT && isExpanded ? MAX_GRID_ROW_HEIGHT : actualHeight;
    cellsMap.set(`${rowId}-${field}`, { actualHeight, previewHeight });
    this.gridCellsHeightMap$.next(cellsMap);
  }

  private renderDataAndGetHeight(dataToRender: any, width: number, isExpanded: boolean): number {
    const parsedData = typeof dataToRender !== 'object' ? checkAndParseJSON(dataToRender) : dataToRender;
    const formatterService = new JsonFormatterService(parsedData, isExpanded);
    const formattedElement = formatterService.render();
    return this.renderElementAndGetHeight(formattedElement, width);
  }
}
