<div *ngFor="let filter of filters" [formGroup]="filtersForm">
  <div class="category-item">
    <sh-logs-filter-category
      (selectedValueChange)="onChange($event, filter.filterName)"
      [categoryAggregation]="filter.values"
      [caption]="filter.filterName"
      [filterString]="filterString"
      formControlName="{{ filter.filterName }}"
    ></sh-logs-filter-category>
  </div>
</div>
