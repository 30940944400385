import { createAction, props } from '@ngrx/store';
import { AlertModel } from '@app/alerts/shared/models/alert-model';
import { alertId } from '@app/alerts/shared/models/alert-requests.models';
import { Insight } from '@app/insights/shared/models/insight.model';
import { CreateNUpdateAlertModel } from '@app/alerts/shared/models/create-n-update-alert-model';
import { IPartialError } from '@app/alerts/shared/models/actions.models';
import { IInsightTime } from '@app/alerts/alerts-table/alerts-insights.model';

export const getAlertsAction = createAction('[Alerts] Get - Alerts');

export const getAlertsCompletedAction = createAction(
  '[Alerts] Get - Alerts completed',
  props<{ payload: AlertModel[] }>(),
);

export const getAlertsFailedAction = createAction('[Alerts] Get - Alerts failed', props<{ payload: any }>());

export const getAlertsInsightsAction = createAction(
  '[Alerts] Get - Alerts Insights',
  props<{ payload: IInsightTime }>(),
);

export const getAlertsInsightsCompletedAction = createAction(
  '[Alerts] Get - Alerts Insights completed',
  props<{ payload: Insight[] }>(),
);

export const getAlertsInsightsFailedAction = createAction(
  '[Alerts] Get - Alerts Insights failed',
  props<{ payload: any }>(),
);

export const updateAlertAction = createAction('[Alerts] update - Alert', props<{ payload: CreateNUpdateAlertModel }>());

export const updateAlertCompletedAction = createAction(
  '[Alerts] update - Alert completed',
  props<{ payload: CreateNUpdateAlertModel }>(),
);

export const updateAlertFailedAction = createAction(
  '[Alerts] update - Alert failed',
  props<{ payload: IPartialError }>(),
);

export const createAlertAction = createAction('[Alerts] create - Alert', props<{ payload: CreateNUpdateAlertModel }>());

export const createAlertCompletedAction = createAction(
  '[Alerts] create - Alert completed',
  props<{ payload: CreateNUpdateAlertModel }>(),
);

export const createAlertFailedAction = createAction(
  '[Alerts] create - Alert failed',
  props<{ payload: IPartialError }>(),
);

export const deleteAlertAction = createAction('[Alerts] delete - Alert', props<{ payload: alertId }>());

export const deleteAlertCompletedAction = createAction(
  '[Alerts] delete - Alert completed',
  props<{ payload: alertId }>(),
);

export const deleteAlertFailedAction = createAction('[Alerts] delete - Alert failed');

export const updateOpenAlertInvalidQueryAction = createAction(
  '[Alerts] Update open alert invalid query state',
  props<{ payload: string }>(),
);

export const updateAlertEditorLoadingAction = createAction(
  '[Alerts] Update alert editor loading state',
  props<{ payload: boolean }>(),
);

export const updateAlertTableLoadingAction = createAction(
  '[Alerts] Update alert table loading state',
  props<{ payload: boolean }>(),
);

export const updateAlertGraphLoadingAction = createAction(
  '[Alerts] Update alert graph loading state',
  props<{ payload: boolean }>(),
);
