import { ChartModel } from '@shared/controls/charts/models/chart.model';

export class LivetailStatsEntity {
  public severity: string = '';
  public total: number = 0;
  public timestamp: number;
  public livetailStatsCount: LivetailStatsCount = new LivetailStatsCount(0);
  public chartModel: ChartModel = new ChartModel();
}

export class LivetailStatsCount {
  private _count: number;

  constructor(value: number) {
    this._count = value;
  }
  get count(): number {
    return this._count;
  }
}
