export class SeveritiesHelper {
  public static getLogSeverityById(id: number): string {
    switch (id) {
      case 1:
        return 'Critical';
      case 2:
        return 'Error';
      case 3:
        return 'Warning';
      case 4:
        return 'Info';
      case 5:
        return 'Verbose';
      case 6:
        return 'Debug';
    }
    return null;
  }
  public static getLogSeverityReversedById(id: number): string {
    switch (id) {
      case 6:
        return 'Critical';
      case 5:
        return 'Error';
      case 4:
        return 'Warning';
      case 3:
        return 'Info';
      case 2:
        return 'Verbose';
      case 1:
        return 'Debug';
    }
    return null;
  }
  public static getEventSeverityById(id: number): string {
    switch (id) {
      case 3:
        return 'Critical';
      case 2:
        return 'Warning';
      case 1:
        return 'Info';
    }
    return 'Info';
  }

  public static getLogSeverityByName(name: string): number {
    switch (name.toLowerCase()) {
      case 'debug':
        return 1;
      case 'verbose':
        return 2;
      case 'info':
        return 3;
      case 'warning':
        return 4;
      case 'error':
        return 5;
      case 'critical':
        return 6;
    }
    return null;
  }

  public static getSeverityColor(id: number): string {
    switch (id) {
      case 1:
        return '#d423bb';
      case 2:
        return '#f04879';
      case 3:
        return '#ffc400';
      case 4:
        return '#32bdf4';
      case 5:
        return '#68ee9a';
      case 6:
        return '#b5b5b5';
    }
    return '#b5b5b5';
  }

  public static getSeverityCoralogixColor(id: number): string {
    switch (id) {
      case 1:
        return '#d139d1';
      case 2:
        return '#f16a8d';
      case 3:
        return '#f3ca75';
      case 4:
        return '#83dcff';
      case 5:
        return '#a6ddab';
      case 6:
        return '#8d9db9';
    }
    return '#b5b5b5';
  }

  public static getEventSeverityColorByName(name: string): string {
    switch (name) {
      case 'Critical':
        return this.getEventSeverityAlertColor(3);
      case 'Warning':
        return this.getEventSeverityAlertColor(2);
      case 'Info':
        return this.getEventSeverityAlertColor(1);
    }
    return this.getEventSeverityAlertColor(1);
  }

  public static severityOptions: { id: number; name: string }[] = [
    { id: 1, name: 'Info' },
    { id: 2, name: 'Warning' },
    { id: 3, name: 'Critical' },
  ];

  public static getAlertSeverityColor(id: number): string {
    switch (id) {
      case 1:
        return '#32bdf4';
      case 2:
        return '#ffc400';
      case 3:
        return '#f04879';
    }
    return '#32bdf4';
  }

  public static getEventSeverityColor(id: number): string {
    switch (id) {
      case 3:
        return '#f04879';
      case 2:
        return '#ffc400';
      case 1:
        return '#32bdf4';
    }
    return '#32bdf4';
  }

  public static getEventSeverityAlertColor(id: number): string {
    switch (id) {
      case 3:
        return '#d139d1';
      case 2:
        return '#f3ca75';
      case 1:
        return '#83dcff';
    }
    return '#83dcff';
  }
}
