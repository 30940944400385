import { formatsHelper } from '../../services/formatsHelper';
export class GridState {
  public selectedItem: any;
  public startDate: Date;
  public endDate: Date;
  public itemsCount: number = 0;
  public filterModel: any;
  public selectedIndex: number;
  public data: any = [];
  public countLabel: string;
  constructor(itemsCountLabel: string) {
    this.countLabel = itemsCountLabel;
  }
  get itemsCountLabel() {
    return this.itemsCount
      ? formatsHelper.numberWithCommas(this.itemsCount) + ' ' + this.countLabel
      : null;
  }
}
