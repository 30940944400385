import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetMetricFields, GetMetricLabels } from './new-metrics.action';
import { MetricsService } from '@app/settings/shared/services/metrics.service';
import { ShDialogService } from '@app/shared/services/dialogService';

interface INewMetricsModel {
  metricFields: string[];
  metricLabels: string[];
}

const initialState = (): INewMetricsModel => {
  return {
      metricFields: [],
      metricLabels: []
  };
};

@State<INewMetricsModel>({
  name: 'newmetrics',
  defaults: initialState()
})
@Injectable()
export class NewMetricsState {
  constructor(
      private metricsService: MetricsService,
      private dialogService: ShDialogService
  ) {
  }

  @Selector()
  public static metricFields(state: INewMetricsModel): string[] {
      return state?.metricFields;
  }

  @Selector()
  public static metricLabels(state: INewMetricsModel): string[] {
      return state?.metricLabels;
  }

  @Action(GetMetricFields)
  public GetMetricFields(ctx: StateContext<INewMetricsModel>): any {
    return this.metricsService.getNewMetricsMetricFields().pipe(
      map((metricFields) => ctx.patchState({ metricFields })),
      catchError(err => {
        this.dialogService.showSnackBar(
          `Error: error fetching the New Metrics - Metric Fields`,
          null,
          5000,
        );
        return of(null);
      }));
  }

  @Action(GetMetricLabels)
  public GetMetricLabels(ctx: StateContext<INewMetricsModel>): any {
    return this.metricsService.getNewMetricsMetricLabels().pipe(
      map((metricLabels) => ctx.patchState({ metricLabels })),
      catchError(err => {
        this.dialogService.showSnackBar(
          `Error: error fetching the New Metrics - Metric Labels`,
          null,
          5000,
        );
        return of(null);
      }));
  }
}
