import { Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HerokuSsoComponent } from './sso/heroku-sso/heroku-sso.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { LoginTeamsComponent } from './login/login-teams/login-teams.component';
import { CreateTeamComponent } from './login/create-team/create-team.component';
import { TeamSsoComponent } from './sso/team-sso/team-sso.component';
import { SamlSsoComponent } from './sso/saml-sso/saml-sso.component';
import { LoginTeamComponent } from './login/login-team/login-team.component';
import { LoginStatusComponent } from './login/login-status/login-status.component';
import { SignUpComponent } from '@app/auth/signup/sign-up.component';

export const AuthRoutes: Routes = [
  {
    path: 'login',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        children: [
          {
            path: '',
            component: LoginTeamComponent,
          },
          {
            path: 'user',
            component: LoginFormComponent,
          },
          {
            path: 'teams',
            component: LoginTeamsComponent,
          },
          {
            path: 'createTeam',
            component: CreateTeamComponent,
          },
          {
            path: 'forgot',
            loadChildren: () => import('../features/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
          },
          {
            path: 'reset/:key',
            component: ResetPasswordComponent,
          },
          {
            path: 'status/:reqId',
            component: LoginStatusComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'signup',
    component: SignUpComponent,
  },
  {
    path: 'sso/team/:token/:team_id/:user_name/:is_new',
    component: TeamSsoComponent,
  },
  {
    path: 'sso/:token',
    component: HerokuSsoComponent,
  },
  {
    path: 'sso/:token/:team_id',
    component: HerokuSsoComponent,
  },
  {
    path: 'sso/reset/:token/:team_id',
    component: LoginComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./sso/invite-sso/invite-sso.module').then(m => m.InviteSsoModule),
      },
    ],
  },
  {
    path: 'ssologin',
    component: SamlSsoComponent,
  },
];
