import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TypedJSON } from 'typedjson';

import { Constants } from '@app/constants';
import { CreateNUpdateAlertModel } from '../models/create-n-update-alert-model';
import { EditAlertResult, NewAlertResult } from '../models/new-alert.response';
import { Observable, throwError } from 'rxjs';
import { NewAlertDefRequest } from '../models/new-alert-def.request';
import { AlertModel, AlertRes } from '../models/alert-model';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { VerifyAlertResponse } from '@app/alerts/alerts-editor/components/alerts-verify-alert/models/verify-alert-response';
import { alertId } from '@app/alerts/shared/models/alert-requests.models';
import { AlertMapperService } from '@app/alerts/alerts-editor/services/alert-mapper.service';
import { ConfigurationService } from '@app/services/configuration.service';
import moment from 'moment';
import { SnoozeInfo } from '../models/snooze.model';

@Injectable()
export class AlertsService {
  private alerts$: Observable<AlertModel[]>;

  constructor(
    private httpClient: HttpClient,
    private ea: AnalyticEventService,
    private alertMapperService: AlertMapperService,
    private configService: ConfigurationService,
  ) {}

  public createNewAlert(alertModel: CreateNUpdateAlertModel): Observable<NewAlertResult> {
    const request = new NewAlertDefRequest(alertModel);
    this.ea.event({ eventName: 'Creating new alert' });

    this.ea.dataLayerReport({
      event: 'added_an_alert',
    });
    const createAlertUrl = this.getUrl() + Constants.alertUrl;
    const observable = this.httpClient
      .post(createAlertUrl, JSON.stringify(request))
      .map(this.extractCreateData)
      .catch(this.handleError);

    return observable;
  }

  public editAlert(alertModel: CreateNUpdateAlertModel): Observable<EditAlertResult> {
    const request = new NewAlertDefRequest(alertModel);
    this.ea.event({ eventName: 'Edited alert' });

    this.ea.dataLayerReport({
      event: 'edited_an_alert',
    });
    const editAlertUrl = this.getUrl() + Constants.alertUrl;
    const observable = this.httpClient
      .post(editAlertUrl, JSON.stringify(request))
      .map(this.extractEditData)
      .catch(this.handleError);

    return observable;
  }

  public getAlerts(): Observable<AlertModel[]> {
    if (this.alerts$) {
      return this.alerts$;
    } else {
      const getAlerts = this.getUrl() + Constants.alertUrl;
      return (this.alerts$ = this.httpClient
        .get(getAlerts)
        .map(this.extractAlertsData)
        .map((res) => {
          this.alerts$ = null;
          return res.results;
        })
        .map((alerts) =>
          alerts.map((alert) => ({
            ...alert,
            userAlertTypeId: this.alertMapperService.getMappedAlertTypeToAlertViewType(alert.userAlertTypeId),
          })),
        )
        .catch((error) => this.handleError(error)));
    }
  }

  public verifyAlert(alert: CreateNUpdateAlertModel): Observable<VerifyAlertResponse> {
    const verifyAlertUrl = this.getUrl() + '/api/v1/verify-alert';
    return this.httpClient.post(verifyAlertUrl, alert).catch((error) => this.handleError(error)) as Observable<
      VerifyAlertResponse
    >;
  }

  public deleteAlert(id: alertId): Observable<any> {
    const deleteAlertUrl = this.getUrl() + Constants.alertUrl;
    const params = { id: id };
    return this.httpClient
      .delete(deleteAlertUrl, { params })
      .map((res) => {
        return res;
      })
      .catch((error) => this.handleError(error));
  }

  public getAlertDefenition(id: string): Observable<AlertModel> {
    const getAlertsUrl = this.getUrl() + Constants.alertUrl + '/' + id;
    return this.httpClient
      .get(getAlertsUrl)
      .map(this.extractAlertDefData)
      .catch(this.handleError);
  }

  public extractAlertDefData(res: AlertModel): AlertModel {
    return TypedJSON.parse(res, AlertModel);
  }

  public snoozeAlert(id: alertId, seconds: number): Observable<SnoozeInfo> {
    const snoozeAlertUrl = `${this.getUrl()}${Constants.alertUrl}/${id}/snooze`;
    const endTimestamp = moment().utc().add(seconds, 'seconds').valueOf();
    return this.httpClient
      .post(snoozeAlertUrl, { endTimestamp: endTimestamp })
      .map(this.extractSnoozeData)
      .catch(this.handleError);
  }

  public unSnoozeAlert(id: alertId): Observable<void> {
    const unsnoozeAlertUrl = `${this.getUrl()}${Constants.alertUrl}/${id}/unsnooze`;
    return this.httpClient
      .post(unsnoozeAlertUrl, {})
      .catch(this.handleError);
  }

  public getSnoozeStatus(id: alertId): Observable<SnoozeInfo> {
    const snoozeStatusUrl = `${this.getUrl()}${Constants.alertUrl}/${id}/snooze`;
    return this.httpClient
      .get(snoozeStatusUrl)
      .catch(this.handleError);
  }

  public getSnoozedAlerts(alertIds: alertId[]): Observable<SnoozeInfo[]> {
    const snoozedAlertsUrl = `${this.getUrl()}${Constants.alertUrl}/snoozed`;
    return this.httpClient
      .post(snoozedAlertsUrl, { alertIds })
      .catch(this.handleError);
  }

  private extractAlertsData(res: AlertRes): AlertRes {
    return TypedJSON.parse({ results: res }, AlertRes);
  }

  private handleError(error: any): Observable<any> {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';

    console.error(errMsg);
    return throwError(error);
  }

  private extractCreateData(res: CreateNUpdateAlertModel): NewAlertResult {
    return TypedJSON.parse(res, NewAlertResult);
  }

  private extractEditData(res: EditAlertResult): EditAlertResult {
    return TypedJSON.parse(res, EditAlertResult);
  }

  private extractSnoozeData(res: SnoozeInfo): SnoozeInfo {
    return TypedJSON.parse(res, SnoozeInfo);
  }

  private getUrl(): string {
    return this.configService.getConfigValue('url', '');
  }
}
