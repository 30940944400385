import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { StatisticService } from './shared/services/statistics-service';
import { StatisticsChartService } from './shared/services/statistics-chart.service';
import { StatisticChartComponent } from './components/statistic-chart/statistics-chart/statistics-chart.component';
import { BasicStatisticChartComponent } from './components/basic-statistic-chart/basic-statistic-chart.component';
import { StatisticsChartModalComponent } from './components/statistics-chart-modal/statistics-chart-modal.component';
import { CommonModule } from '@angular/common';
import { ChartLegendComponent } from '@app/statistics/components/chart-legend/chart-legend.component';
import { SummaryLineChartComponent } from '@app/statistics/components/summary-line-chart/summary-line-chart.component';
import { SummarySeverityListComponent } from '@app/statistics/components/summary-severity-list/summary-severity-list.component';
import { SeverityChartComponent } from '@app/statistics/components/severity-chart/severity-chart.component';
import { FlexModule } from '@angular/flex-layout';
import { CgxTooltipModule } from '@coralogix/design';

@NgModule({
  imports: [CommonModule, SharedModule, FlexModule, CgxTooltipModule],
  declarations: [
    StatisticChartComponent,
    BasicStatisticChartComponent,
    StatisticsChartModalComponent,
    ChartLegendComponent,
    SummaryLineChartComponent,
    SummarySeverityListComponent,
    SeverityChartComponent,
  ],
  exports: [
    StatisticChartComponent,
    BasicStatisticChartComponent,
    StatisticsChartModalComponent,
    ChartLegendComponent,
    SummaryLineChartComponent,
    SummarySeverityListComponent,
    SeverityChartComponent,
  ],
  providers: [StatisticService, StatisticsChartService],
})
export class StatisticsModule {}
