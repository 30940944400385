import * as moment from 'moment';
import { LogQueryModel } from './logsquery.model';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class QueryHistoryModel {
  @jsonMember
  public queryModel: LogQueryModel = new LogQueryModel();
  @jsonMember({ constructor: Number })
  public queryType: QUERY_TYPE = QUERY_TYPE.ABSOLUTE;
  @jsonMember
  public caption: string;

  constructor(
    query?: LogQueryModel,
    queryType: QUERY_TYPE = QUERY_TYPE.ABSOLUTE,
    caption?: string,
  ) {
    this.queryModel = Object.assign({}, query);

    Object.setPrototypeOf(this.queryModel, LogQueryModel.prototype);

    this.queryType = queryType;

    if (caption) {
      this.caption = caption;
    } else {
      this.caption = this.getQueryTimeRangeString();
    }
  }

  public getQueryTimeRangeString(): string {
    if (!this.queryModel) {
      return '';
    }

    return (
      moment(this.queryModel.startDate).format('DD/MM/YYYY HH:mm:ss.SSS') +
      ' - ' +
      moment(this.queryModel.endDate).format('DD/MM/YYYY HH:mm:ss.SSS')
    );
  }
}

export enum QUERY_TYPE {
  ABSOLUTE,
  RELATIVE,
  TAG,
}
