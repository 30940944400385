import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  getIsMetricEditorInEditMode,
  getIsMetricEditorOpen,
  getMetricEditorEditedMetric,
  getMetricEditorStatus,
  getNonAllLogsFilterGridColumns,
  State,
} from '@app/app.reducers';
import { LogsFilterGridColumnsActions } from '@app/logs/shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { GetCompany } from '@app/ngxs-store/company/company.action';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { MetricEditorControls } from '@app/settings/metrices-settings/models/metric-editor-controls';
import { MetricEditorForm } from '@app/settings/metrices-settings/models/metric-editor-form';
import { ERROR_STATUSES } from '@app/settings/metrices-settings/models/metric.constants';
import {
  CreateMetricRequest,
  DeleteMetricRequest,
  EditMetricRequest,
  Metric,
} from '@app/settings/metrices-settings/models/metric.model';
import { MetricEditorActions } from '@app/settings/metrices-settings/state/actions/metric-editor.actions';
import { MetricsActions } from '@app/settings/metrices-settings/state/actions/metrics.actions';
import { Store } from '@ngrx/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { CoralogixSidebarComponent } from '@shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { MetricsStoreService } from '@app/settings/shared/services/metrics-store.service';
import { ConditionalSelectService } from '@app/shared/components/conditional-select/conditional-select.service';

@Component({
  selector: 'sh-metrics-editor-container',
  templateUrl: './metrics-editor-container.component.html',
  styleUrls: ['./metrics-editor-container.component.scss'],
})
export class MetricsEditorContainerComponent implements OnInit, OnDestroy {
  @Select(CompanyState.companyLabelLimit) public companyLabelLimit$: Observable<number>;

  @Input()
  public metrics: Metric[];
  @ViewChild('sidebarComponent', { static: true })
  public sidebar: CoralogixSidebarComponent;
  public form: FormGroup;
  public isEditMode: boolean = false;
  public editedMetric: Metric;
  public optionalFields$: Observable<string[]>;
  public logsSeverityOptions: { id: number; name: string }[] = [
    { id: 1, name: 'Debug' },
    { id: 2, name: 'Verbose' },
    { id: 3, name: 'Info' },
    { id: 4, name: 'Warning' },
    { id: 5, name: 'Error' },
    { id: 6, name: 'Critical' },
  ];

  public validationTextError: string = null;
  public dbConflictError: string = null;
  private editorStateSubscription: Subscription;
  private editorCloseSubscription: Subscription;
  private metricEditorStatus: Observable<{ error: string; isLoading: boolean; errorStatus: number }>;

  constructor(
    public dialog: MatDialog,
    private store: Store<State>,
    private metricEditorForm: MetricEditorForm,
    private location: Location,
    private metricsStoreService: MetricsStoreService,
    private conditionalSelectService: ConditionalSelectService,
  ) {}

  public ngOnInit(): void {
    const editorOpen = this.store.select(getIsMetricEditorOpen);
    const editorEditMode = this.store.select(getIsMetricEditorInEditMode);
    const editorEditedMetric = this.store.select(getMetricEditorEditedMetric);
    this.metricEditorStatus = this.store.select(getMetricEditorStatus);
    this.editorStateSubscription = Observable.combineLatest(editorOpen, editorEditMode, editorEditedMetric).subscribe(
      ([isOpen, isInEditMode, editedMetric]) => {
        const newURL = isOpen ? editedMetric?.id || 'new' : '';
        this.setMetricsURL(newURL);
        if (isOpen === true) {
          if (isInEditMode === true && editedMetric !== null) {
            this.isEditMode = true;
            this.editedMetric = editedMetric;
            this.metricsStoreService.selectedMetric$.next(editedMetric);
            this.form = this.metricEditorForm.buildForm(this.metricEditorForm.buildControlsFromMetric(editedMetric));
            this.sidebar.showSidebar();
          } else {
            this.isEditMode = false;
            this.editedMetric = null;
            this.form = this.metricEditorForm.buildForm(this.metricEditorForm.defaultValues);
            this.sidebar.showSidebar();
          }
        } else {
          this.isEditMode = false;
          this.editedMetric = null;
          this.validationTextError = null;
          this.dbConflictError = null;
          this.sidebar.hideSidebar();
        }
      },
    );

    this.getCompany();
    this.store.dispatch(new LogsFilterGridColumnsActions.LogsFilterGridTemplateColumnsInit());

    this.optionalFields$ = this.store
      .select(getNonAllLogsFilterGridColumns)
      .filter(
        (gridColumn) =>
          Boolean(gridColumn?.filter) && Boolean(gridColumn.filter.available) && Boolean(gridColumn.filter.selected),
      )
      .map((gridColumns) => {
        const fields = [];
        fields.push(
          ...gridColumns.filter.available.map((c) => c.colId),
          ...gridColumns.filter.selected.map((c) => c.colId),
        );
        return fields;
      });

    this.editorCloseSubscription = this.sidebar.close.subscribe(() => {
      if (this.form.pristine) {
        this.store.dispatch(new MetricEditorActions.ToggleEditorAction(false));
      } else {
        const data: ICoralogixDialogData = {
          title: 'Unsaved changes will be lost',
          message:
            'Closing this Logs2Metric dialog will delete your unsaved changes. ' +
            'Are you sure you want to close and discard the unsaved changes?',
          yesButtonText: 'DISCARD',
          noButtonText: 'CONTINUE EDITING',
        };
        const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data });

        dialogRef.afterClosed().subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.validationTextError = null;
            this.dbConflictError = null;
            this.store.dispatch(new MetricEditorActions.ToggleEditorAction(false));
          }
        });
      }
    });
  }

  public setMetricsURL(path: string): void {
    this.location.replaceState('/tco/metrics/' + path);
  }

  public ngOnDestroy(): void {
    if (this.editorStateSubscription) {
      this.editorStateSubscription.unsubscribe();
    }
    if (this.editorCloseSubscription) {
      this.editorCloseSubscription.unsubscribe();
    }
    this.store.dispatch(new MetricEditorActions.ToggleEditorAction(false));
  }

  public submit(value: MetricEditorControls): void {
    const specificText = value.text ? value.text : null;
    if (this.isEditMode) {
      const metric = new EditMetricRequest({
        name: value.metricName,
        description: value.description,
        applicationName: this.conditionalSelectService.mapToRequest(value.applicationName),
        subsystem: this.conditionalSelectService.mapToRequest(value.subsystemName),
        severity: value.logSeverity,
        specificText: specificText,
        groupBy: value.groupBy,
        id: this.editedMetric.id,
        metricFields: value.metricFields,
        permutationsLimit: value.permutationsLimit,
      });
      this.store.dispatch(new MetricsActions.EditMetricAction(metric));
    } else {
      const metric = new CreateMetricRequest({
        name: value.metricName,
        description: value.description,
        applicationName: this.conditionalSelectService.mapToRequest(value.applicationName),
        subsystem: this.conditionalSelectService.mapToRequest(value.subsystemName),
        severity: value.logSeverity,
        specificText: specificText,
        groupBy: value.groupBy,
        metricFields: value.metricFields,
        permutationsLimit: value.permutationsLimit,
      });
      this.store.dispatch(new MetricsActions.CreateMetricAction(metric));
    }
    this.metricEditorStatus
      .pipe(
        filter((res) => !res.isLoading),
        take(1),
      )
      .subscribe((res) => {
        if (res.errorStatus) {
          this.getCompany();
        }
        if (res.errorStatus === ERROR_STATUSES.LUCENE_VALIDATOR_CODE) {
          this.validationTextError = res.error;
        } else if (res.errorStatus === ERROR_STATUSES.DB_CONFLICT_ERROR_CODE) {
          this.dbConflictError = res.error;
          this.store.dispatch(new MetricsActions.GetMetricsAction(null));
        } else {
          this.validationTextError = null;
          this.dbConflictError = null;
          this.store.dispatch(new MetricEditorActions.ToggleEditorAction(false));
        }
      });
  }

  public delete(value: Metric): void {
    if (this.metrics.length === 1) {
      const data: ICoralogixDialogData = {
        title: 'Unsaved changes will be lost',
        message:
          'Deleting the last metric will delete all your Logs2Metric data. ' +
          'Are you sure you want to close and delete the metric?',
        yesButtonText: 'DELETE',
        noButtonText: 'CONTINUE EDITING',
      };
      const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data });

      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.store.dispatch(new MetricsActions.DeleteMetricAction(new DeleteMetricRequest(value.id)));
          this.store.dispatch(new MetricEditorActions.ToggleEditorAction(false));
        }
      });
    } else {
      this.store.dispatch(new MetricsActions.DeleteMetricAction(new DeleteMetricRequest(value.id)));
      this.store.dispatch(new MetricEditorActions.ToggleEditorAction(false));
    }
  }

  @Dispatch() public getCompany = () => new GetCompany();
}
