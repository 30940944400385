import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs/Rx';
import { LogsService } from '../shared/services/logs-service';
import { MetadataProvider } from './metadata.provider';
import { ScrollHelperService } from '../../shared/services/scroll-helper/scroll-helper.service';
import { FormGroup } from '@angular/forms';
import { LogsQuerFormProvider } from '../logs-query/logs-query-form.provider';

@Component({
  moduleId: 'logs-metadata-query',
  selector: 'sh-logs-metadata-query',
  templateUrl: 'logs-metadata-query.component.html',
  styleUrls: ['logs-metadata-query.component.scss'],
})
export class LogsMetadataQueryComponent implements OnInit, OnDestroy {
  get options() {
    return this.metadataProvider.options;
  }
  @Input('group') public metadataForm: FormGroup;
  @Input() public updateQueryOnChange: boolean = true;

  private updateMetadataStream: Subject<any> = new Subject();
  private onUpdateObserver: Subscription;

  constructor(
    private metadataProvider: MetadataProvider,
    private logService: LogsService,
    private formProvider: LogsQuerFormProvider,
    private scrollHelper: ScrollHelperService,
  ) {}

  public ngOnInit() {
    this.metadataProvider.updateAllMetadata(this.metadataForm.value);
  }

  public ngOnDestroy() {
    if (this.onUpdateObserver) {
      this.onUpdateObserver.unsubscribe();
    }
  }

  //////// drop down events ////////
  public onHide(values, field) {
    this.metadataProvider.getAllRelatedMetadata(this.metadataForm.value);
    // start update denounce
    this.subscribeForUpdate();
    this.updateMetadataStream.next({});
    this.scrollHelper.isAutoScrollEnable = true;
  }

  public onShow() {
    if (this.onUpdateObserver) {
      this.onUpdateObserver.unsubscribe();
    }

    this.scrollHelper.isAutoScrollEnable = false;
  }

  /////// updateMetadataStream function //////

  public onClear(field) {
    this.metadataProvider.getAllRelatedMetadata(this.metadataForm.value);
    this.updateMetadataStream.next({});
  }

  private subscribeForUpdate() {
    if (!this.updateQueryOnChange) {
      return;
    }

    this.onUpdateObserver = this.updateMetadataStream
      .debounceTime(2000)
      .subscribe(() => {
        this.logService.newQueryRequest.emit(this.formProvider.getModel());
      });
  }
}
