<form class="ui form" [formGroup]="newQueryForm" (submit)="onSubmitQuery()">
  <div class="row" #validationError [@visibilityChanged]="
      newQueryForm.controls['dates'].valid ? 'valid' : 'inValid'
    " style="margin-bottom: 0px !important;padding-bottom: 0px!important;">
    <div class="column header">
      <div class="title ">
        <label>
          Query range must be under 7 days, the query end date must be greater
          than start date.
        </label>
      </div>
    </div>
  </div>
  <div class="ui equal width padded stackable grid">
    <div class="row">
      <div class="column" style="max-width: 160px;">
        <h3>New Query</h3>
        <span>{{ newQueryForm.errors }}</span>
      </div>
    </div>
    <div class="row " formGroupName="dates">
      <div class="column">
        <div class="ui left icon input">
          <input type="text" shPickADate formControlName="from" class="input-padding" />
          <i class="calendar icon"></i>
        </div>
      </div>
      <div class="column">
        <div class="ui left icon input">
          <input type="time" step="1" timePicker-directive formControlName="fromTime" class="input-padding" />
          <i class="wait icon"></i>
        </div>
      </div>
      <div class="column">
        <div class="ui left icon input">
          <input type="text" shPickADate formControlName="to" class="input-padding" />
          <i class="calendar icon"></i>
        </div>
      </div>
      <div class="column">
        <span class="ui left icon input" style="display:inline-block">
          <input type="time" step="1" timePicker-directive formControlName="toTime" placeholder="Start Time" class="input-padding" />
          <i class="wait icon"></i>
        </span>
        <span class="input-overlay time noselect" (click)="toggleEndTimeNow()" style="display:inline-block" [class.active]="now">
          Now
        </span>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <div class="ui left icon input enable-events">
          <input class="column" type="text" formControlName="text" [placeholder]="
              isTemplateToggleButton.isChecked ? 'Template' : 'Text'
            " class="input-padding" />
          <i class="filter icon" #queryicon></i>
        </div>
        <div class="ui special fluid popup">
          <div class="regex-tooltip-content-section">
            <p style="font-weight: bold;">Running a simple query</p>
          </div>
          <ul class="regex-tooltip-content-section">
            <li>To perform a free text search, simply enter a text string. For example, to find all response_codes 200 for GET requests,
              simply enter 'GET 200' (without quotes). It'll find all logs containing the words '200' and 'GET'.</li>
          </ul>
          <div class="regex-tooltip-content-section">
            <p style="font-weight: bold;">Running a complex query</p>
          </div>
          <ul class="regex-tooltip-content-section">
            <li>To perform a phrase search, wrap your phrase with double quotes("a phrase"). For example, "GET 200" will search for all logs
              containing '200' right after 'GET'.</li>
            <li>To perform search on a value in a specific field, prefix the value with the name of the field. For example, enter
              'environment:production' to search for all the entries that contain the value 'production' in the 'environment' field.</li>
            <li>To perform search on a range of numeric values, use the bracketed range syntax [START_VALUE TO END_VALUE]. For example, to
              search for all 4xx status codes under the 'status' field, enter 'status.numeric:[400 TO 499]'.</li>
            <li>To define an alert when a regular expression matches a value, wrap your regex with '/' and use it as the expression for the
              field. For example, to define an alert the regions west-europe-1, west-europe-2, west-us-1, west-us-2, enter
              'region:/west-(europe|us)-[12]/'.</li>
            <li>To specify a more complex search criteria, use the Boolean operators AND, OR, and NOT. For example, to search for all
              entries that have 4xx status codes and have an extension of php, enter 'status.numeric:[400 TO 499] AND extension:php'.</li>
            <li><a href="https://www.elastic.co/guide/en/elasticsearch/reference/6.3/query-dsl-query-string-query.html" target="_blank"
                rel="noopener"> To specify specific terms, use Elasticsearch's Query String Query syntax</a></li>
          </ul>
          <div class="regex-tooltip-content-section">
            <p>Example: searching for logs from your production with status codes 5xx not originating from west-europe or west-us, use this
              expression:</p>
            <p><em>environment:production AND status.numeric:[400 TO 499] NOT region:/west-(europe|us)-[12]/</em></p>
          </div>
        </div>
        <div class="input-overlay noselect active" style="display:inline-block;">
          <sh-toggle-button formControlName="isTemplateSearch" #isTemplateToggleButton>
            <span un-checked>Free Text</span>
            <span checked>Template</span>
          </sh-toggle-button>
        </div>
      </div>
      <div class="column" style="max-width: 395px">
        <sh-logs-severity [control]="severityFormControl"></sh-logs-severity>
      </div>
    </div>
    <div class="row"></div>
    <div class="row">
      <div class="column">
        <button class="column" class="ui button primary" type="submit" [disabled]="!newQueryForm.valid">
          Get Logs!
        </button>
        <button (click)="resetQuery()" class="column" class="ui button resetBtn" type="button" [disabled]="!newQueryForm.dirty">
          Reset
        </button>
      </div>
    </div>
  </div>
</form>
