import { Injectable } from '@angular/core';
import { Alert } from '../models/alert.model';
import { Observable } from 'rxjs';
import { LogQueryData } from '../../../logs/shared/models/logquery.data';
import { LogqueryModelTranslator } from '../../../logs/shared/translators/logquery-model.translator';
import { LogqueryResult } from '../../../logs/shared/models/logquery.response';
import { AlertsService } from '../../../alerts/shared/services/alerts-service';
import { LogsService } from '../../../logs/shared/services/logs-service';
import { LogQueryModel } from '../../../logs/shared/models/logsquery.model';
import { Constants } from '../../../constants';
import {
  FindLogInQueryService,
  LogInQueryRes,
} from '../../../shared/services/find-log-in-query.service';
import { Log } from '../../../logs/shared/models/log.entity';
import * as moment from 'moment';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';

@Injectable()
export class AdvanceAlertService {
  constructor(
    private alertService: AlertsService,
    private findLogInQueryService: FindLogInQueryService,
    private htmlRowMeasure: HtmlRowMeasure,
    private logsService: LogsService,
  ) {}

  public getAdvanceAlertMarkedLogs(alert: Alert): Observable<Log[]> {
    // gat alert definition and build model according to alert time and definition parameter
    return this.alertService
      .getAlertDefenition(alert.alertId)
      .map((alertDef) =>
        LogqueryModelTranslator.modelFromAlert(alert, alertDef),
      )
      .do(
        (queryModel) => (queryModel.pageSize = Constants.MARKED_LOGS_MAX_SIZE),
      )
      .switchMap((queryModel: LogQueryModel) =>
        this.logsService.getLogs(queryModel),
      )
      .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
      .map((res) => res.logs);
  }

  public getAdvanceAlerFirstMarkedPage(
    alert: Alert,
    firstMarkLog: Log,
  ): Observable<LogInQueryRes> {
    // build query model according to alert time
    const queryModel = LogqueryModelTranslator.modelFromAlert(alert);

    if (!firstMarkLog) {
      return this.logsService
        .getLogs(queryModel)
        .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
        .map(
          (res: LogqueryResult) =>
            new LogInQueryRes(queryModel, firstMarkLog, res),
        );
    }

    const logTimestampEpoch = firstMarkLog.timestamp / 1000;
    const startDate = moment
      .unix(logTimestampEpoch)
      .subtract(5, 'seconds')
      .toDate();
    const endDate = moment
      .unix(logTimestampEpoch)
      .add(5, 'seconds')
      .toDate();
    const tmpQueryModel = new LogQueryModel(startDate, endDate);

    queryModel.startDate = tmpQueryModel.startDate;
    queryModel.endDate = tmpQueryModel.endDate;

    if (alert.applicationName) {
      queryModel.queryParams.metadata.applicationName.push(
        alert.applicationName,
      );
    }

    if (alert.subsystemName) {
      queryModel.queryParams.metadata.subsystemName.push(alert.subsystemName);
    }

    return this.findLogInQueryService.findLogInQuery(queryModel, firstMarkLog);
  }

  public getAdvanceAlert(alert: Alert): Observable<AddvanceAlertRes> {
    const addvanceAlertData: AddvanceAlertRes = new AddvanceAlertRes();
    // get all alert logs and marked logs and zip them to one stream
    return this.getAdvanceAlertMarkedLogs(alert)
      .do((markedLogs: Log[]) => (addvanceAlertData.markedLogs = markedLogs))
      .map((markedLogs: Log[]) => markedLogs[0]) // get first mark log
      .switchMap((firstMarkLog: Log) =>
        this.getAdvanceAlerFirstMarkedPage(alert, firstMarkLog),
      )
      .do(
        (logInQueryRes: LogInQueryRes) =>
          (addvanceAlertData.firstMarkedPageLogsData = this.buildLogQuryData(
            logInQueryRes,
          )),
      )
      .mapTo(addvanceAlertData);
  }

  public buildLogQuryData(inQueryRes: LogInQueryRes): LogQueryData {
    const queryData: LogQueryData = new LogQueryData(
      inQueryRes.queryModel,
      inQueryRes.logPageInQueryRes,
      this.logsService,
    );
    queryData.startFromIndex = inQueryRes.logIndexInQuery;

    return queryData;
  }
}

export class AddvanceAlertRes {
  public firstMarkedPageLogsData: LogQueryData;
  public markedLogs: Log[];
}
