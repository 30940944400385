import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { getPermutationInUse, State } from '@app/app.reducers';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { Metric } from '@app/settings/metrices-settings/models/metric.model';

@Injectable({ providedIn: 'root' })
export class MetricsStoreService {
  public selectedMetric$ = new BehaviorSubject<Metric>(null);
  @Select(CompanyState.logs2MetricsPermutationLimit) public logs2MetricsPermutationLimit$: Observable<number>;
  public permutationsLeft$ = combineLatest([
    this.logs2MetricsPermutationLimit$,
    this.store.select(getPermutationInUse),
  ]).pipe(map(([permutationsLimit, permutationsInUse]) => permutationsLimit - permutationsInUse));

  constructor(private store: Store<State>) {}
}
