import { FormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { DateHelper } from '../services/date.helper';
import { dateFnsLocalDateToUtcDate } from '@shared/helpers/date-fns.helper';

const maxQuery = 60 * 60 * 24 * 90; // 90 days in seconds
export class DateValidator {
  public static checkLessThanNow(
    startDateField: string,
    startTimeField: string,
    isLocalTime: boolean
  ): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const errors = {};
      const startDateInput = group.controls[startDateField].value;
      const startTimeInput = group.controls[startTimeField].value;
      const startDate = moment(
        DateHelper.mergeDateAndTime(startDateInput, startTimeInput),
      );
      const nowWithTimezone = isLocalTime ? moment(Date.now()) : moment(dateFnsLocalDateToUtcDate(new Date()));
      if (startDate.unix() > nowWithTimezone.unix()) {
        return { startDateField: true };
      }
      return errors;
    };
  }

  public static checkDelta(
    startDateField: string,
    startTimeField: string,
    endDateField: string,
    endTimeField: string,
  ): {} {
    return (group: FormGroup): { [key: string]: any } => {
      const errors = {};
      const startDateInput = group.controls[startDateField].value;
      const startTimeInput = group.controls[startTimeField].value;
      const endDateInput = group.controls[endDateField].value;
      const endTimeInput = group.controls[endTimeField].value;
      const startDate = moment(
        DateHelper.mergeDateAndTime(startDateInput, startTimeInput),
      );
      const endDate = moment(
        DateHelper.mergeDateAndTime(endDateInput, endTimeInput),
      );

      const diff = moment.duration(endDate.diff(startDate)).asSeconds();
      if (diff < 0 || diff > maxQuery) {
        errors['invalidStartEndDate'] = true;
      }
      return errors;
    };
  }

  public static checkDeltaFromNow(startDateField: string,
                                  startTimeField: string,
                                  minValue: Date,
                                  isLocalTime: boolean
                                  ): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const errors = {};
      const startDateInput = group.controls[startDateField].value;
      const startTimeInput = group.controls[startTimeField].value;
      const startDate = DateHelper.mergeDateAndTime(startDateInput, startTimeInput);
      const minValueWithTMZOffset = isLocalTime ? minValue : dateFnsLocalDateToUtcDate(minValue);
      if (startDate.valueOf() < minValueWithTMZOffset.valueOf()) {
        return { endDateField: true };
      }

      return errors;
    };
  }
}
