<div class="sh-list">
  <button (click)="createNewTeam()" class="sh-login-btn-outline">
    Create new team
  </button>
  <h3 class="auth-sub-title" *ngIf="(teams$ | async)?.length">Login to one of your teams</h3>
  <div class="teams-list" data-test="login-teams-list">
    <div class="sh-list-item" *ngFor="let team of teams$ | async" (click)="selectTeam(team)">
      <div class="sh-list-icon team-icon"><i class="fa fa-group"></i></div>
      <div class="sh-list-content">
        <div class="sh-list-item-title">{{ team.name }}</div>
        <div class="sh-list-item-sub-title">Sign in to {{ team.name }}</div>
      </div>
      <div class="sufix"><i class="fa fa-angle-right"></i></div>
    </div>
  </div>
</div>
