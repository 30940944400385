<div class="header">
  <div class="title">Manage Logs2Metrics</div>
  <div fxFlex="20" class="newMetricButton">
    <button mat-raised-button class="buttonWrapper" (click)="openEditor()">
      <span class="buttonIcon">
        <i class="fa fa-bar-chart"></i>
      </span>
      New Log2Metric
    </button>
  </div>
</div>
<div>
  <div class="overlay" [hidden]="(isLoading$ | async) === false">
    <div class="ui active inverted dimmer overlay" [class.active]="isLoading$ | async">
      <div class="ui text loader">Loading metrics</div>
    </div>
  </div>
</div>
<sh-empty-metrics *ngIf="(getMetrics$ | async)?.length <= 0 && (isLoading$ | async) === false; else elseBlock"></sh-empty-metrics>
<ng-template #elseBlock>
  <sh-metrics-table-container [metrics]="getMetrics$ | async"></sh-metrics-table-container>
</ng-template>
<sh-metrics-editor-container [metrics]="getMetrics$ | async"></sh-metrics-editor-container>
