<mat-card class="white-theme" [class]="'card-border-' + reindexStatusEnum[reindexStatus]">
  <mat-card-content>
    <div class="container">
      <div class="img-container">
        <img [src]="imgSrc">
      </div>
      <div class="detailes-container">
        <div class="header">{{reindex.name}}</div>
        <div class="sub-header">{{reindex.description}}</div>
        <div class="metadata-section">
          <div class="metadata-container">
            <span class="metadata-header">Application</span>
            <div class="metadata-value" *ngIf="reindex.applicationName">
              {{ !reindex.applicationName || reindex.applicationName.length === 0 ? 'All'
              : reindex.applicationName.length > 1 ? 'Multiple'
              : reindex.applicationName[0]
              }}
            </div>
          </div>
          <div class="metadata-container">
            <span class="metadata-header">Subsystem</span>
            <div class="metadata-value" *ngIf="reindex.subsystem">
              {{ !reindex.subsystem || reindex.subsystem.length === 0 ? 'All'
                  : reindex.subsystem.length > 1 ? 'Multiple'
                  : reindex.subsystem[0]
              }}
            </div>
          </div>
        </div>
        <div class="created-data">Created on <span style="font-weight: bold">{{getReindexCreateDate()}}</span> by <span style="font-weight: bold">{{reindex.createBy}}</span></div>
      </div>
      <div class="status-container">
        <button [class]="'status-btn-' + reindexStatusEnum[reindexStatus]"
                [disabled]="reindexStatus === reindexStatusEnum.ingesting"
        (click)="onClick()">
          <span class="status-btn-text">{{getButtonText(reindex)}}</span>
        </button>
        <div class="status-main-view" [ngSwitch]="reindexStatus">
          <div class="status-data-display" *ngSwitchCase="reindexStatusEnum.finished">
            <!-- <div>{{reindexSize}} MB</div>
            <div>REINDEXED DATA</div> -->
          </div>
          <div class="ingesting" *ngSwitchCase="reindexStatusEnum.ingesting">
            <div class="status-data-display">
              <!-- <div>{{estimatedTime}} Min</div>
              <div>ESTIMATED TIME</div> -->
            </div>
            <div class="ingesting-statusbar-container">
              <mat-progress-bar mode="determinate" value="{{reindexProgressPercent}}"></mat-progress-bar>
              <div class="status-data-display precentages">{{reindexProgressPercent}}%</div>
            </div>
          </div>
          <div class="failed" *ngSwitchCase="reindexStatusEnum.failed">
            Failed to reindex
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
