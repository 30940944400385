import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'sh-side-menu-header-renderer',
  templateUrl: 'side-menu-renderer-header.component.html',
  styleUrls: ['side-menu-header-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuHeaderRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
