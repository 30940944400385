import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';

import { LogQueryModel } from './shared/models/logsquery.model';
import { ShDialogService } from '@shared/services/dialogService';
import { LogsViewStateProvider, QueryViewState } from './shared/services/logsViewState.provider';
import { SavedQueryService } from './shared/services/saved-query.service';
import { MetadataProvider } from './logs-metadata-query/metadata.provider';
import {
  StatisticsCategory,
  StatisticsQueryDefinition,
  StatisticsTypes,
} from '../statistics/shared/models/statistics-query-definition';
import { LogsQuerFormProvider } from './logs-query/logs-query-form.provider';
import { Widget } from '../widgets/shared/models/widget';
import { WidgetsService } from '../widgets/shared/services/widgets.service';
import { Log } from './shared/models/log.entity';
import { GlobalParamsProvider } from '@shared/services/globalParams.provider';
import { QueryTypes } from './shared/models/query-types.options';
import { LogqueryModelTranslator } from './shared/translators/logquery-model.translator';
import { FindLogInQueryService } from '@shared/services/find-log-in-query.service';
import { LogQueryData } from './shared/models/logquery.data';
import { StatisticsChartModalComponent } from '../statistics/components/statistics-chart-modal/statistics-chart-modal.component';
import { LogsInfoPanelComponent } from './logs-info-panel/logs-info-panel.component';
import { PredefinedQueryHelper } from './shared/services/predefined-query.service';
import { JsonParameterRequestData } from '@shared/models/jsonParameterRequestData';
import { QUERY_TYPE, QueryHistoryModel } from './shared/models/query-history.model';
import {
  getLogsGridSavedViews,
  getNonSelectedLogsFilterGridColumns,
  getQueryLogCount,
  getQueryTemplateCount,
  getTags,
  queryTemplate,
  State,
} from '../app.reducers';
import { LogActions } from './shared/state/log.actions';
import { LogsView } from './shared/models/logs-view.model';
import { LogsViewsActions } from './shared/state/logs-views/logs-views.actions';
import { LogsFilterGridColumnsActions } from './shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { UserSettingsProvider } from '../user/shared/userSettingsProvider';
import { TagsActions } from '../deployment/shared/actions/tag.actions';
import { WidgetAddComponent } from '@shared/controls/widgets/widget-add/widget-add.component';
import { ExportComponent } from './export/export.component';
import { TemplateType } from '../loggregation/shared/interfaces/templateType.enum';
import { LoggregationlHelper } from '../loggregation/shared/services/loggregation.helper';
import { BasicChartComponent } from '@shared/controls/charts/basic-chart/basic-chart.component';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { ScrollHelperService } from '@shared/services/scroll-helper/scroll-helper.service';
import { QueryPanelComponent } from '@app/logs/query-panel/query-panel.component';
import { SaveAlertFromQueryConfig } from '@app/logs/logs-save-menu/models/logs-save-menu-config.model';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { MomentTimeFormatTypes } from '@shared/helpers/moment.helper';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { CompanyPlanStatus } from '@app/user/shared/models/company-plan-status.model';
import { filter, take } from 'rxjs/operators';
import { DateFormatByUserSettingsPipe } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.pipe';

@Component({
  moduleId: 'logs.component',
  selector: 'sh-logs',
  templateUrl: 'logs.component.html',
  styleUrls: ['logs.component.scss'],
})
export class LogsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(CompanyState.planStatus) public planStatus$: Observable<CompanyPlanStatus>;

  get viewState(): QueryViewState {
    return this.logsViewStateProvider.viewState as QueryViewState;
  }

  get selectedLog(): any {
    return this.viewState.selectedLog;
  }

  get newQueryForm(): FormGroup {
    return this.formProvider.newQueryForm;
  }

  @Output() public saveAlert: EventEmitter<SaveAlertFromQueryConfig> = new EventEmitter<SaveAlertFromQueryConfig>();

  @ViewChild('queryElm', { static: true }) public queryElm: ElementRef;

  @ViewChild('infoPanel', { static: true })
  public infoPanel: LogsInfoPanelComponent;

  @ViewChild('queryPanel', { static: true })
  public queryPanel: QueryPanelComponent;

  @ViewChild('topPanelWrapper', { static: true }) public topPanelWrapper: ElementRef<HTMLDivElement>;

  @ViewChild(StatisticsChartModalComponent, { static: true })
  public statisticsChartModal: StatisticsChartModalComponent;

  public totalLogs: number;

  public lastExecutedQueryId: string;

  public lastExecutedQuery: QueryHistoryModel;

  public shortIdSubscription: Subscription;

  public queryParamSubscription: Subscription;

  public viewNameSubscription: Subscription;

  public defaultViewSubscription: Subscription;

  public newQueryRequestedSubscription: Subscription;

  public logManagerDataLoaded: Subscription;

  public newQueryIdSub: Subscription;

  public statisticsQueryDefinition: StatisticsQueryDefinition;

  public logsCount$: Observable<number>;

  public templatesCount$: Observable<number>;

  public selectedView$: Observable<LogsView>;

  public selectedViewSubscription: Subscription;

  public isCompanyPlanStatusBarOpened: boolean;

  public queryTemplateSubscription: Subscription;

  public textInfoClickedSubscription: Subscription;

  public basicChartComponent: BasicChartComponent;

  public isSideBarOpen: boolean = true;

  public logsViewPortWidth: number;

  private _sideBarWidth: number;

  private _mobileWidth: number = 1025;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  private predefinedQueryHelper: PredefinedQueryHelper;

  private dynamicFilterViewsInitSubscription: Subscription;

  private dynamicFilterViewsInitSubject: Subject<boolean> = new Subject();

  private dynamicFilterViewsInit$: Observable<boolean> = this.dynamicFilterViewsInitSubject.asObservable();

  private isDynamicFilterViewsInit: boolean;

  constructor(
    private logsService: LogsService,
    private scrollHelper: ScrollHelperService,
    private router: Router,
    private route: ActivatedRoute,
    private logService: LogsService,
    public formProvider: LogsQuerFormProvider,
    private savedQueryService: SavedQueryService,
    private metadataProvider: MetadataProvider,
    private widgetService: WidgetsService,
    private globalParams: GlobalParamsProvider,
    private dialogService: ShDialogService,
    private findLogInQueryService: FindLogInQueryService,
    private store: Store<State>,
    public dialog: MatDialog,
    public logsViewStateProvider: LogsViewStateProvider,
    private userSettingsProvider: UserSettingsProvider,
    private logsGridSavedViewsActions$: Actions,
    private ae: AnalyticEventService,
    private dateTransformPipe: DateFormatByUserSettingsPipe,
  ) {
    scrollHelper.hideBodyOverFlow();
    this.predefinedQueryHelper = new PredefinedQueryHelper();
    this.logsCount$ = this.store.select(getQueryLogCount);
    this.templatesCount$ = this.store.select(getQueryTemplateCount);
    this.selectedView$ = this.store.select(getLogsGridSavedViews).map((views) => {
      const selectedView = views.savedViews.find((v: LogsView) => v.id === views.selectedViewId);
      return selectedView;
    });
  }

  public get elementsToReduceFromHeight(): HTMLDivElement[] {
    return [this.topPanelWrapper.nativeElement];
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    this._resetChartWidth();
  }

  public onSideBarInitialized(initialWidth: number): void {
    this._sideBarWidth = initialWidth;
    this.logsViewPortWidth = window.innerWidth - initialWidth;
  }

  public onSideBarDrag(width: number): void {
    this._sideBarWidth = width;
    this.logsViewPortWidth = window.innerWidth - width;
  }

  public onSideBarDragEnd(width: number): void {
    this._sideBarWidth = width;
    this._resetChartWidth();
  }

  public ngAfterViewInit(): void {
    this.initFromStateIfSpecified();
  }

  public ngOnInit(): void {
    this.dynamicFilterViewsInitSubscription = this.logsViewStateProvider.onAfterDynamicFiltersInit.subscribe(() => {
      this.isDynamicFilterViewsInit = true;
      this.dynamicFilterViewsInitSubject.next(true);
    });

    this.newQueryRequestedSubscription = this.logsService.newQueryRequest.subscribe(
      (logQueryModel) => this.onNewQueryRequest(logQueryModel),
      () => this.dialogService.showSnackBar('Query link expired or invalid', 'Dismiss', 5000),
    );

    this.textInfoClickedSubscription = this.logsViewStateProvider.textInfoClicked.subscribe(() => {
      this.infoPanel.toggleOpen();
    });

    this.newQueryIdSub = this.logsService.onNewQueryId.subscribe((id) => {
      this.viewState.filterModel = null;
      this.logsViewStateProvider.logsGridState = null;
      this.updateQueryParam(id);
    });

    this.store
      .select(getNonSelectedLogsFilterGridColumns)
      .first()
      .subscribe((columns) => {
        if (!columns) {
          this.store.dispatch(new LogsFilterGridColumnsActions.LogsFilterGridColumnsInit());
        }
      });

    this.queryTemplateSubscription = this.store.select(queryTemplate).subscribe((entity) => {
      if (entity) {
        const loggregation = new LoggregationEntity();
        loggregation.timestamp = entity.timestamp;
        loggregation.text = entity.text;
        loggregation.metadata = entity.metadata;
        loggregation.id = entity.id;
        loggregation.templateType = entity.templateType;
        loggregation.logExample = entity.logExample;
        loggregation.text = LoggregationlHelper.buildLoggregationParametersHTML(
          loggregation.text,
          null,
          loggregation.templateType === TemplateType.jsonTemplate,
          loggregation.logExample,
        );
        this.queryByTemplate(loggregation);
        this.router.navigate(['query/logs'], { replaceUrl: true });
      }
    });

    this.store
      .select(getTags)
      .first()
      .subscribe((tags) => {
        if (!tags?.length) {
          const model: LogQueryModel = new LogQueryModel();
          model.endDate = null;
          model.startDate = null;
          this.store.dispatch(new TagsActions.GetTagsAction(model));
        }
      });

    this.logsGridSavedViewsActions$
      .pipe(ofType(LogsViewsActions.ActionTypes.LOGS_VIEW_SELECT))
      .takeUntil(this.destroyed$)
      .do((action: LogsViewsActions.SelectLogsViewAction) => {
        this.store
          .select(getLogsGridSavedViews)
          .first((logsGridViews) => Array.isArray(logsGridViews.savedViews) && logsGridViews.savedViews.length > 0)
          .subscribe((logsGridViews) => {
            // Do not query by view when querying by template ids
            if (this.route.snapshot.queryParams['queryType'] === QueryTypes.TEMPLATE_IDS) {
              return;
            }

            const selectedView = logsGridViews.savedViews.find((view) => view.id === action.payload);
            const currentLogQueryModel = _.cloneDeep(this.formProvider.getModel());

            if (action.includeViewQueryParams && selectedView && selectedView.queryHistoryModel) {
              const queryModel = _.cloneDeep(selectedView.queryHistoryModel.queryModel);
              queryModel.selectedViewId = selectedView.id;

              if (queryModel.tagId && queryModel.tagId !== -1) {
                this.performTagQuery(queryModel);
              } else {
                if (selectedView.queryHistoryModel.queryType === QUERY_TYPE.RELATIVE) {
                  const queryTimeDiff = queryModel.endDate - queryModel.startDate;
                  queryModel.endDate = Date.now();
                  queryModel.startDate = queryModel.endDate - queryTimeDiff;
                  this.preformNewQuery(queryModel, QUERY_TYPE.RELATIVE, selectedView.queryHistoryModel.caption);
                } else {
                  this.preformNewQuery(queryModel);
                }
              }
            } else if (
              (selectedView && currentLogQueryModel.selectedViewId !== selectedView.id) ||
              action.payload === -1
            ) {
              currentLogQueryModel.selectedViewId = selectedView ? selectedView.id : -1;
              this.updateQueryModel(currentLogQueryModel);
            }
          });
      })
      .subscribe();

    this.planStatus$
      .pipe(
        filter((plan) => plan && plan.status !== 'inplan'),
        take(1),
      )
      .subscribe(() => {
        this.isCompanyPlanStatusBarOpened = true;
      });

    this.registerToOnRoutrParamsChanged();

    this.store.dispatch(new LogsViewsActions.LoadLogsViewAction());
  }

  public ngOnDestroy(): void {
    this.scrollHelper.showBodyOverFlow();
    if (this.logManagerDataLoaded) {
      this.logManagerDataLoaded.unsubscribe();
    }
    if (this.selectedViewSubscription) {
      this.selectedViewSubscription.unsubscribe();
    }
    this.newQueryRequestedSubscription.unsubscribe();
    this.shortIdSubscription.unsubscribe();
    this.queryParamSubscription.unsubscribe();
    this.viewNameSubscription.unsubscribe();
    this.defaultViewSubscription.unsubscribe();
    this.queryTemplateSubscription.unsubscribe();
    this.newQueryIdSub.unsubscribe();
    this.textInfoClickedSubscription.unsubscribe();
    this.dynamicFilterViewsInitSubscription.unsubscribe();
    this.logsViewStateProvider.logsGridState = null;
    this.viewState.isQueryVisible = true;

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public updateQueryParam(cachedQueryID: string): void {
    this.formProvider.setCachedQueryId(cachedQueryID);

    const navigationExtras: NavigationExtras = {
      queryParams: { id: cachedQueryID },
    };
    if (this.router.url.includes('/query/logs')) {
      this.router.navigate(['/query/logs'], navigationExtras).then();
    }
  }

  public onNewQueryRequest(
    logQueryModel: LogQueryModel,
    type: QUERY_TYPE = QUERY_TYPE.ABSOLUTE,
    caption?: string,
  ): void {
    this.statisticsQueryDefinition = this.getStatisticsQuery(logQueryModel);
    this.lastExecutedQueryId = logQueryModel.cacheQueryId;
    const _caption = caption && !caption.includes('-') ? caption : this.getUpdatedTimeRangeCaption(logQueryModel);
    this.lastExecutedQuery = new QueryHistoryModel(logQueryModel, type, _caption);
    this.logsViewStateProvider.lastExcecutedQuery = this.lastExecutedQuery;
    this.store.dispatch(new LogActions.GetQueryCount(_.cloneDeep(logQueryModel)));
  }

  public registerToOnRoutrParamsChanged(): void {
    const userDefaultViewId = this.userSettingsProvider.userSettings.logsDefaultGridViewId;
    let routeParamEvent$;
    if (this.isDynamicFilterViewsInit) {
      routeParamEvent$ = this.route.queryParams;
    } else {
      routeParamEvent$ = Observable.combineLatest(this.dynamicFilterViewsInit$.first(), this.route.queryParams)
        .filter(([isInitialized]) => isInitialized === true)
        .map(([, params]) => params);
    }

    const queryParamsEvent$ = routeParamEvent$
      .filter((params) => {
        return (params['query'] || params['startTime'] || params['endTime'] || params['queryType']) && !params['id'];
      })
      .switchMap((params) => Observable.zip(Observable.of(params), this.handleParams(params)))
      .do(([params, queryModel]) => {
        setTimeout(() => {
          this.updateQueryModel(queryModel, false);

          if (params['viewName'] || this.userSettingsProvider.userSettings.logsDefaultGridViewId !== -1) {
            this.store
              .select(getLogsGridSavedViews)
              .first((logsGridViews) => logsGridViews.isInitialized)
              .subscribe((logsGridViews) => {
                if (!_.isEmpty(logsGridViews.savedViews)) {
                  const defaultIdExist = logsGridViews.savedViews.map((view) => view.id).includes(userDefaultViewId);
                  const urlViewName = params['viewName'];

                  if (urlViewName) {
                    const urlView = logsGridViews?.savedViews.find((x) => x.name === urlViewName);
                    if (urlView?.isPublic && logsGridViews.selectedViewId !== urlView.id) {
                      this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(urlView.id, true));
                    }
                  } else if (
                    defaultIdExist &&
                    logsGridViews.selectedViewId === -1 &&
                    userDefaultViewId !== -1 &&
                    logsGridViews.selectedViewId !== userDefaultViewId
                  ) {
                    this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(userDefaultViewId, false));
                  }
                }
              });
          }
        }, 0);
      });

    const shortIdEvent$ = routeParamEvent$
      .filter((params) => params['id'] && params['id'] !== this.lastExecutedQueryId)
      .switchMap((params) => Observable.zip(Observable.of(params), this.handleParams(params)))
      .do(
        ([params, queryModel]) => {
          this.updateQueryModel(queryModel, false);

          if (params['viewName'] || (queryModel.selectedViewId && queryModel.selectedViewId !== -1)) {
            this.store
              .select(getLogsGridSavedViews)
              .first((logsGridViews) => logsGridViews.isInitialized)
              .subscribe((logsGridViews) => {
                if (!_.isEmpty(logsGridViews.savedViews)) {
                  const defaultIdExist = logsGridViews.savedViews.map((view) => view.id).includes(userDefaultViewId);
                  const urlViewName = params['viewName'];

                  if (urlViewName) {
                    const urlView = logsGridViews?.savedViews.find((x) => x.name === urlViewName);
                    if (urlView?.isPublic && logsGridViews.selectedViewId !== urlView.id) {
                      this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(urlView.id, true));
                    }
                  } else if (logsGridViews.selectedViewId !== queryModel.selectedViewId) {
                    this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(queryModel.selectedViewId, false));
                  } else if (
                    defaultIdExist &&
                    logsGridViews.selectedViewId === -1 &&
                    userDefaultViewId !== -1 &&
                    logsGridViews.selectedViewId !== userDefaultViewId
                  ) {
                    this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(userDefaultViewId, false));
                  }
                }
              });
          }
        },
        () => {
          this.dialogService.showSnackBar('Query link expired or invalid', 'Dismiss', 5000);
        },
      );

    const viewNameEvent$ = routeParamEvent$
      .filter((params) => _.keys(params).length === 1 && params['viewName'])
      .do((params) => {
        this.store
          .select(getLogsGridSavedViews)
          .first((logsGridViews) => logsGridViews.isInitialized)
          .subscribe((logsGridViews) => {
            if (!_.isEmpty(logsGridViews.savedViews)) {
              const urlView = logsGridViews?.savedViews.find((x) => x.name === params['viewName']);
              if (urlView?.isPublic && logsGridViews.selectedViewId !== urlView.id) {
                this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(urlView.id, true));
              }
            }
          });
      });

    const defaultRoutingEvent$ = routeParamEvent$
      .filter((params) => _.keys(params).length === 0)
      .do(() => {
        this.store
          .select(getLogsGridSavedViews)
          .first((logsGridViews) => logsGridViews.isInitialized)
          .subscribe((logsGridViews) => {
            if (!_.isEmpty(logsGridViews.savedViews)) {
              const defaultIdExist = logsGridViews.savedViews.map((view) => view.id).includes(userDefaultViewId);

              if (defaultIdExist) {
                this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(userDefaultViewId, true));
              }
            }
          });
      });

    this.defaultViewSubscription = defaultRoutingEvent$.subscribe(() => this.preformNewQueryNoURLParams());
    this.viewNameSubscription = viewNameEvent$.subscribe();
    this.shortIdSubscription = shortIdEvent$.subscribe();
    this.queryParamSubscription = queryParamsEvent$.subscribe();
  }

  public updateQueryModel(queryModel: LogQueryModel, generateQuery: boolean = true): void {
    if (generateQuery) {
      queryModel.cacheQueryId = this.logService.getNewQueryId();
      this.logService.onNewQueryId.emit(queryModel.cacheQueryId);
    }

    this.formProvider.updateFormByModel(queryModel);
    this.metadataProvider.updateAllMetadata(queryModel.queryParams.metadata);

    if (queryModel.type === QueryTypes.TEMPLATE_IDS || queryModel.selectedViewId !== -1) {
      this.logService.newQueryRequest.emit(queryModel);
    } else {
      this.logService.newQueryRequest.emit(this.formProvider.getModel());
    }
  }

  public chartVisibility(): void {
    this.viewState.showTimeline = !this.viewState.showTimeline;
  }

  public onChartTimeRangeSelected(logsQuery: LogQueryModel): void {
    this.store.dispatch(new LogActions.GetQueryCount(_.cloneDeep(logsQuery)));
    this.updateQueryModel(logsQuery);
  }

  public onBasicChartInit(component: BasicChartComponent): void {
    this.basicChartComponent = component;
  }

  public pinToDashboard(widget: Widget): void {
    this.widgetService
      .addWidgets(widget)
      .first()
      .subscribe();
  }

  public exportLogs(): void {
    const onExportSubscription = this.logsViewStateProvider.onExport;
    this.dialog.open(ExportComponent, {
      data: {
        onExportSubscription,
      },
    });
  }

  public detectGroks(): void {
    this.logsViewStateProvider.onDetectGroks.emit();
  }

  public initFromStateIfSpecified(): void {
    if (this.globalParams.chartClickedLogQueryModel) {
      this.navigatedFromChartClicked();
    } else if (this.logsViewStateProvider.doBeforeAfter) {
      this.onBeforeAfter(this.logsViewStateProvider.doBeforeAfter);
      this.logsViewStateProvider.doBeforeAfter = null;
    }
  }

  public onBeforeAfter(args: { seconds: number; selectedLog: Log }): void {
    const queryModel: LogQueryModel = new LogQueryModel();
    queryModel.startDate = args.selectedLog.timestamp - (args.seconds / 2) * 1000;
    queryModel.endDate = args.selectedLog.timestamp + (args.seconds / 2) * 1000;
    queryModel.queryParams.metadata.subsystemName.push(args.selectedLog.metadata.subsystemName);
    queryModel.queryParams.metadata.applicationName.push(args.selectedLog.metadata.applicationName);
    this.preformNewQuery(queryModel);
    this.findLogInQueryService
      .findLogInQuery(queryModel, args.selectedLog)
      .first()
      .subscribe((logsQueryData) => {
        const queryData = new LogQueryData(logsQueryData.queryModel, logsQueryData.logPageInQueryRes, this.logsService);
        queryData.startFromIndex = logsQueryData.logIndexInQuery;
        queryData.highlightedLogs.set(logsQueryData.log.logId, '#22354c');
        this.logService.onBeforeAfter.emit(queryData);
      });
  }

  public navigatedFromChartClicked(): void {
    // ugly workaround to wait for grid ready. consider moving it to logs grid
    setTimeout(() => {
      this.updateQueryModel(this.globalParams.chartClickedLogQueryModel);
      this.globalParams.chartClickedLogQueryModel = null;
    }, 0);
  }

  public showWidget(jsonParamReqData: JsonParameterRequestData): void {
    const queryModel = this.formProvider.getModel();
    const newQuery = _.cloneDeep(queryModel);
    if (jsonParamReqData.templateId) {
      newQuery.queryParams.query.templateIds = [jsonParamReqData.templateId];
      newQuery.type = QueryTypes.TEMPLATE_IDS;
    }
    this.statisticsChartModal.openChart(newQuery, jsonParamReqData.key, jsonParamReqData.title);
  }

  public addWidget(query: any): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = { query, url: 'statistics/logs' };
    config.panelClass = 'add-edit-widget-container';
    const dialogRef = this.dialog.open(WidgetAddComponent, config);
    dialogRef
      .afterClosed()
      .first()
      .subscribe((widget) => {
        this.pinToDashboard(widget);
      });
  }

  public quickQueryChanged(value: any): void {
    const queryModel = this.formProvider.getModel();
    queryModel.tagId = -1;
    this.performQuickQuery(queryModel, value);
  }

  public absoluteQuery(): void {
    const queryModel = this.formProvider.getModel();
    queryModel.tagId = -1;
    this.preformNewQuery(queryModel, QUERY_TYPE.ABSOLUTE, null);
  }

  public tagQuery(tagId: number): void {
    const queryModel = this.formProvider.getModel();
    queryModel.tagId = tagId;
    this.performTagQuery(queryModel);
  }

  public queryByTemplate(entity: LoggregationEntity): void {
    this.formProvider.setTemplate(entity);
    this.queryWithLastTime();
  }

  public queryWithLastTime(): void {
    const model = this.formProvider.getModel();
    if (this.lastExecutedQuery) {
      const lastModel = this.lastExecutedQuery.queryModel;

      if (this.lastExecutedQuery.queryType === QUERY_TYPE.RELATIVE) {
        model.endDate = Date.now();
        model.startDate = model.endDate - (lastModel.endDate - lastModel.startDate);
      } else {
        model.startDate = lastModel.startDate;
        model.endDate = lastModel.endDate;
      }
      this.preformNewQuery(model, this.lastExecutedQuery.queryType, this.lastExecutedQuery.caption);
    } else {
      this.preformNewQuery(model);
    }
  }

  public relativeQuery(startTimeStamp: number): void {
    const queryModel = this.formProvider.getModel();
    queryModel.startDate = startTimeStamp;
    queryModel.endDate = Date.now();
    queryModel.tagId = -1;
    this.preformNewQuery(queryModel, QUERY_TYPE.RELATIVE);
  }

  public filterChanged(model: LogQueryModel): void {
    if (this.lastExecutedQuery) {
      this.preformNewQuery(model, this.lastExecutedQuery.queryType, this.lastExecutedQuery.caption);
    } else {
      this.preformNewQuery(model);
    }
    this.ae.event({ eventName: 'Logs - UseFilter (managecloumns)' });
  }

  public clearForm(): void {
    let selectedViewId = -1;
    let queryModel = _.cloneDeep(this.formProvider.getModel());
    if (queryModel.selectedViewId && queryModel.selectedViewId !== -1) {
      selectedViewId = queryModel.selectedViewId;
    }

    this.formProvider.resetForm();

    queryModel = _.cloneDeep(this.formProvider.getModel());
    queryModel.selectedViewId = selectedViewId;

    this.preformNewQuery(queryModel, QUERY_TYPE.RELATIVE, 'Last 15 minutes');
  }

  public clearSelectedView(): void {
    const defaultViewId = this.userSettingsProvider.userSettings.logsDefaultGridViewId
      ? this.userSettingsProvider.userSettings.logsDefaultGridViewId
      : -1;

    this.formProvider.resetForm();

    this.store.dispatch(new LogsViewsActions.SelectLogsViewAction(defaultViewId));
  }

  public onSaveAlert(config: SaveAlertFromQueryConfig): void {
    this.saveAlert.emit(config);
  }

  private _resetChartWidth(): void {
    const padding = 30;
    const isMobile = window.innerWidth < this._mobileWidth;
    const chartFullWidth = window.innerWidth - padding;
    const chartWidthWithSideBar = chartFullWidth - this._sideBarWidth;
    this.basicChartComponent.setWidth(isMobile ? chartFullWidth : chartWidthWithSideBar);
  }

  private handleParams(params: Params): Observable<LogQueryModel> {
    const id = params['id'];
    if (id) {
      this.lastExecutedQueryId = id;
      this.formProvider.setCachedQueryId(id);
      return this.savedQueryService.getQuery(id);
    } else {
      const logQueryModel: LogQueryModel = LogqueryModelTranslator.createQueryModelFromQueryString(params);
      return Observable.of(logQueryModel);
    }
  }

  private getStatisticsQuery(logQeury: LogQueryModel): StatisticsQueryDefinition {
    const statisticsQuery = new StatisticsQueryDefinition();
    statisticsQuery.logQeuryModel = logQeury;
    statisticsQuery.category = StatisticsCategory.LOGS;
    statisticsQuery.seriesType = 'areaspline';
    statisticsQuery.type = StatisticsTypes.Daily;
    statisticsQuery.logQeuryModel.queryParams.aggregationInterval = this.getInterval(logQeury);
    statisticsQuery.getBuilds = true;
    return statisticsQuery;
  }

  private getInterval(logQueryModel: LogQueryModel): number {
    const diff = (logQueryModel.endDate - logQueryModel.startDate) / 1000;
    if (diff <= 900) {
      return 1000;
    }
    if (diff < 3600 * 12) {
      return 60 * 1000;
    }

    return 3600 * 1000;
  }

  private preformNewQuery(queryModel: LogQueryModel, type: QUERY_TYPE = QUERY_TYPE.ABSOLUTE, caption?: string): void {
    queryModel.cacheQueryId = this.logService.getNewQueryId();
    this.logService.onNewQueryId.emit(queryModel.cacheQueryId);
    this.updateQueryModel(queryModel, false);
    this.onNewQueryRequest(queryModel, type, caption);
  }

  private performQuickQuery(queryModel: LogQueryModel, value: any): void {
    queryModel.endDate = Date.now();
    queryModel.startDate = this.predefinedQueryHelper.getStartDate(value);
    this.preformNewQuery(queryModel, QUERY_TYPE.RELATIVE, value.caption);
  }

  private performTagQuery(queryModel: LogQueryModel): void {
    if (!queryModel.tagId || queryModel.tagId === -1) {
      return;
    }

    // 24 hours in milliseconds
    const h24 = 24 * 60 * 60 * 1000;
    const newQuery = _.cloneDeep(queryModel);

    this.store
      .select(getTags)
      .filter((tags) => tags.length > 0)
      .first()
      .subscribe((tags) => {
        const selectedTag = tags.find((t) => t.id === newQuery.tagId);
        if (!selectedTag) {
          this.clearForm();
        } else {
          newQuery.tagId = selectedTag.id;
          newQuery.startDate = selectedTag.tag_timestamp;
          newQuery.endDate = selectedTag.tag_timestamp + h24;

          if (newQuery.endDate > Date.now()) {
            newQuery.endDate = Date.now();
          }

          const nextTag = tags.find(
            (t) =>
              t.id !== selectedTag.id &&
              t.tag_timestamp > selectedTag.tag_timestamp &&
              t.tag_timestamp < selectedTag.tag_timestamp + h24 &&
              ((t.application_name === selectedTag.application_name &&
                t.subsystem_name === selectedTag.subsystem_name) ||
                (t.application_name === selectedTag.application_name && !selectedTag.subsystem_name)),
          );

          if (nextTag) {
            newQuery.endDate = nextTag.tag_timestamp;
          }

          this.preformNewQuery(newQuery, QUERY_TYPE.TAG, selectedTag.text_value);
        }
      });
  }

  private preformNewQueryNoURLParams(): void {
    if (
      !this.route.snapshot.queryParams['id'] &&
      this.route.snapshot.queryParams['queryType'] !== QueryTypes.TEMPLATE_IDS &&
      !this.route.snapshot.queryParams['query'] &&
      !this.route.snapshot.queryParams['startTime'] &&
      !this.route.snapshot.queryParams['endTime']
    ) {
      if (!this.logsViewStateProvider.lastExcecutedQuery) {
        this.preformNewQuery(this.formProvider.getModel(), QUERY_TYPE.RELATIVE, 'Last 15 minutes');
      } else {
        const lastExecutedQuery: QueryHistoryModel = this.logsViewStateProvider.lastExcecutedQuery;
        this.preformNewQuery(lastExecutedQuery.queryModel, lastExecutedQuery.queryType, lastExecutedQuery.caption);
      }
    }
  }

  private getUpdatedTimeRangeCaption(queryModel: LogQueryModel): string {
    return this.getDateByUserSettings(queryModel.startDate) + ' - ' + this.getDateByUserSettings(queryModel.endDate);
  }

  private getDateByUserSettings(date: number): string {
    return this.dateTransformPipe.transform(date, null, MomentTimeFormatTypes.withMilliseconds, true);
  }
}
