<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  {{ message }}
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="dialogRef.close(true)">
    {{ action }}
  </button>
  <button mat-button (click)="dialogRef.close(false)">Cancel</button>
</mat-dialog-actions>
