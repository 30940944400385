import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { Action, Store } from '@ngrx/store';
import { getGroups, State } from '@app/app.reducers';
import { GroupsProxyService } from '@app/settings/account/components/groups/services/groups-proxy.service';
import * as groupsStateActions from '@app/settings/account/components/groups/store/groups.actions';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { IGroup } from '@app/settings/account/components/groups/models/models';
import { ShDialogService } from '@shared/services/dialogService';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { CompanyRequest } from '@app/auth/shared/models/activateUser.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

@Injectable()
export class GroupsEffects {
  @SelectSnapshot(CompanyState.companyInfo) public companyInfo: CompanyRequest;

  @Effect()
  public getAllCompanyGroups$: Observable<Action> = this.actions$.pipe(
    ofType(groupsStateActions.getGroupsAction),
    switchMap(() => {
      if (this.companyInfo) {
        return this.groupsProxyService.fetchAllGroups(this.companyInfo.id).pipe(
          map((groups) => groupsStateActions.getGroupsCompleteAction({ payload: { groups } })),
          catchError((error) => of(groupsStateActions.getGroupsFailedAction()))
        );
      }
      return of(groupsStateActions.getGroupsCompleteAction({ payload: { groups: [] } }));
    })
  );

  @Effect()
  public upsertCompanyGroup$: Observable<Action> = this.actions$.pipe(
    ofType(groupsStateActions.updateGroupDetailsAction),
    withLatestFrom(this.store.select(getGroups)),
    switchMap(([action, storeGroups]) => {
      const createdGroup = action.payload.group;
      const isNameAlreadyExists = (storeGroups as IGroup[]).find((storeGroup) => {
        return (storeGroup.name === createdGroup.name) && storeGroup.id !== createdGroup.id;
      });
      if (isNameAlreadyExists) {
        return of(groupsStateActions.updateGroupDetailsFailedAction({ payload: { errMsg: 'Group name already exists' } }));
      }
      return this.groupsProxyService.upsertGroup(action.payload.group).pipe(
        map(() => groupsStateActions.updateGroupDetailsCompleteAction({ payload: { group: action.payload.group } }))
      ).catch((error) => of(groupsStateActions.updateGroupDetailsFailedAction({ payload: { errMsg: 'Failed to update group' } })));
    })
  );

  @Effect()
  public createCompanyGroup$: Observable<Action> = this.actions$.pipe(
    ofType(groupsStateActions.createGroupAction),
    withLatestFrom(this.store.select(getGroups)),
    switchMap(([action, storeGroups]) => {
      const createdGroup = action.payload.group;
      const isNameAlreadyExists = (storeGroups as IGroup[]).find((storeGroup) => storeGroup.name === createdGroup.name);
      if (isNameAlreadyExists) {
        return of(groupsStateActions.createGroupActionFailedAction({ payload: { errMsg: 'Group name already exists' } }));
      }
      return this.groupsProxyService.createGroup(action.payload.group).pipe(
        map((res) => {
          const group: IGroup = { ...action.payload.group, id: res.groupId };
          return groupsStateActions.createGroupActionCompleteAction({ payload: { group } });
        })
      ).catch((error) => of(groupsStateActions.createGroupActionFailedAction({ payload: { errMsg: 'Failed to create group' } })));
    })

  );

  @Effect()
  public deleteCompanyGroup$: Observable<Action> = this.actions$.pipe(
    ofType(groupsStateActions.deleteGroupAction),
    switchMap((action) => this.groupsProxyService.deleteGroup(action.payload.companyId, action.payload.groupId).pipe(
      map((res) => groupsStateActions.deleteGroupActionCompleteAction({ payload: { groupId: action.payload.groupId } }))
    ).catch((error) => of(groupsStateActions.deleteGroupActionFailedAction()))
    )
  );

  @Effect({ dispatch: false })
  public updateGroupDetailsFailedAction$: Observable<any> = this.actions$.pipe(
    ofType(groupsStateActions.updateGroupDetailsFailedAction),
    tap((action) => this.dialogService.showCoralogixMessage(action.payload.errMsg, null, dialogServiceIconClasses.failed))
  );

  @Effect({ dispatch: false })
  public createGroupActionFailedAction$: Observable<any> = this.actions$.pipe(
    ofType(groupsStateActions.createGroupActionFailedAction),
    tap((action) => this.dialogService.showCoralogixMessage(action.payload.errMsg, null, dialogServiceIconClasses.failed))
  );

  @Effect({ dispatch: false })
  public deleteGroupActionFailedAction$: Observable<any> = this.actions$.pipe(
    ofType(groupsStateActions.deleteGroupActionFailedAction),
    tap(() => this.dialogService.showCoralogixMessage('Failed to delete group', null, dialogServiceIconClasses.failed))
  );

  @Effect({ dispatch: false })
  public getGroupsFailedAction$: Observable<any> = this.actions$.pipe(
    ofType(groupsStateActions.getGroupsFailedAction),
    tap(() => this.dialogService.showCoralogixMessage('Failed to retrieve groups', null, dialogServiceIconClasses.failed))
  );

  @Effect({ dispatch: false })
  public updateGroupDetailsCompleteAction: Observable<any> = this.actions$.pipe(
    ofType(groupsStateActions.updateGroupDetailsCompleteAction),
    tap(() => this.dialogService.showCoralogixMessage('Group updated successfully', null, dialogServiceIconClasses.success))
  );

  @Effect({ dispatch: false })
  public createGroupActionCompleteAction$: Observable<any> = this.actions$.pipe(
    ofType(groupsStateActions.createGroupActionCompleteAction),
    tap((action) => this.dialogService.showCoralogixMessage('Group created successfully', null, dialogServiceIconClasses.success))
  );

  @Effect({ dispatch: false })
  public deleteGroupActionCompleteAction$: Observable<any> = this.actions$.pipe(
    ofType(groupsStateActions.deleteGroupActionCompleteAction),
    tap(() => this.dialogService.showCoralogixMessage('Deleted group successfully', null, dialogServiceIconClasses.success))
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private groupsProxyService: GroupsProxyService,
    private dialogService: ShDialogService,
  ) { }
}
