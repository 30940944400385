import {Component, OnInit, Input} from '@angular/core';
import {LogQueryModel} from '../../../logs/shared/models/logsquery.model';
import * as _ from 'lodash';
import {
  StatisticsQueryDefinition,
  StatisticsCategory,
} from '../../../statistics/shared/models/statistics-query-definition';
import {StatisticService} from '../../../statistics/shared/services/statistics-service';
import {QueryTypes} from '../../../logs/shared/models/query-types.options';
import {Metadata} from '../../../logs/shared/models/query-metadata';
import {SeveritiesHelper} from '../../../shared/services/severities.helper';
import {LoggregationPipe} from '../../../shared/pipes/loggregation.pipe';
import {Router} from '@angular/router';
import {GlobalParamsProvider} from '../../../shared/services/globalParams.provider';
import {Anomaly} from '../../shared/models/anomaly.model';
import {AnomalyDetails} from '../../shared/models/anomaly-properties.model';
import {InsightsHelper} from '../../shared/helpers/insightsHelper';
import {InsightsProvider} from '../../shared/services/insights.provider';
import {
  ChartModel,
  ChartSeriesModel,
} from '../../../shared/controls/charts/models/chart.model';
import * as moment from 'moment';

@Component({
  selector: 'sh-flow-anomaly',
  templateUrl: './flow-anomaly.component.html',
  styleUrls: ['./flow-anomaly.component.scss'],
})
export class FlowAnomalyComponent {
  /**
   * query model for loggregation query
   */
  @Input() set Anomaly(anomaly: Anomaly) {
    this.selectedItem = null;
    this.AnomalyItems = [];
    console.log(anomaly);
    if (!anomaly || !anomaly.properties || !anomaly.properties.details) {
      return;
    }

    this.queryModel = InsightsHelper.getLogQueryModelByInsight(anomaly);
    let totalCount = 0;
    anomaly.properties.details.forEach((detail) => {
      totalCount += detail.count;
    });

    const normal_values = {};
    this.statisticsService
      .getPatternDetailsByInsight(anomaly.id)
      .first()
      .subscribe((res) => {
        const templates = res.templates;
        const anomaly_bin_index = Math.floor(
          templates[0].x_axis.values.length / 2,
        );
        for (let i = 0; i < templates.length; i++) {
          let normal;
          const min =
            templates[i].y_axis.values[0][anomaly_bin_index] < 0
              ? 0
              : templates[i].y_axis.values[0][anomaly_bin_index];
          const current = templates[i].y_axis.values[1][anomaly_bin_index];
          const max =
            templates[i].y_axis.values[2][anomaly_bin_index] < 0
              ? 0
              : templates[i].y_axis.values[2][anomaly_bin_index];
          if (current < min) {
            normal = min;
          } else if (max < current) {
            normal = max;
          } else {
            normal = current;
          }
          normal_values[templates[i].template_id] = normal;
        }

        anomaly.properties.details.forEach((detail) => {
          const paramPattern = this.loggregationParamPattern;
          const replaced = detail.text
            .replace(paramPattern, function (match, p1, p2) {
              return p2.substring(0, 40);
            })
            .replace(/{{/g, '{')
            .replace(/}}/g, '}');
          const anomalyItem = new AnomalyItem();
          anomalyItem.statisticsQueryDefinition = new StatisticsQueryDefinition();
          anomalyItem.anomalyDetail = detail;
          anomalyItem.statisticsQueryDefinition.logQeuryModel = _.cloneDeep<LogQueryModel>(this.queryModel);
          anomalyItem.statisticsQueryDefinition.logQeuryModel.queryParams.query.text = replaced;
          anomalyItem.statisticsQueryDefinition.category =
            StatisticsCategory.LOGS;
          anomalyItem.statisticsQueryDefinition.logQeuryModel.type =
            QueryTypes.TEMPLATE;
          anomalyItem.statisticsQueryDefinition.logQeuryModel.queryParams.metadata = new Metadata();
          anomalyItem.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.applicationName.push(
            detail.metadata.applicationName,
          );
          anomalyItem.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.subsystemName.push(
            detail.metadata.subsystemName,
          );
          anomalyItem.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.severity.push(
            parseInt(detail.metadata.severity),
          );
          if (anomalyItem.anomalyDetail.isAnomalous) {
            this.selectedAnomalyDetails(anomalyItem);
          }
          anomalyItem.normalCount = normal_values[detail.id];
          const model = new ChartModel();
          model.seriesType = 'column';
          model.series = new Array<ChartSeriesModel>();

          const normal = new ChartSeriesModel();
          normal.data = [];
          const time = moment(this.queryModel.startDate).toDate();
          normal.data.push([
            'Normal',
            Math.round(detail.normalRatio * 10000) / 100,
          ]);
          model.categories = ['current', 'normal'];
          normal.type = 'bar';
          normal.name = 'Normal Behavior';
          normal.color = 'rgba(72,201,176,0.3)';

          const current = new ChartSeriesModel();
          current.data = [];
          current.data.push([
            'Current',
            Math.round(detail.anomalyRatio * 10000) / 100,
          ]);
          current.type = 'bar';
          current.name = 'Current Query';
          current.color = 'rgba(93, 173, 226, 0.3)';
          if (detail.isAnomalous) {
            current.color = 'rgba(255, 0, 0, 0.3)';
          }
          model.xType = 'category';
          model.series.push(current);
          model.series.push(normal);
          anomalyItem.chartModel = model;
          model.height = 65;
          model.lineWidth = 0;
          model.minorGridLineWidth = 0;
          model.fontSize = '5px';
          model.inverted = true;
          model.tooltipEnabled = false;
          model.tooltipValueSuffix = '%';
          model.enableDataLabel = true;
          model.backgroundColor = 'transparent';
          if (detail.isAnomalous) {
            model.highlightedXlables.set('Current', 'red');
          }
          this.AnomalyItems.push(anomalyItem);
        });
      });
  }

  public AnomalyItems = [];
  public selectedItem: AnomalyItem;
  public math: any;

  // Is widget overlay (loading circle)  is visible
  public isOverlayVisible: boolean = true;
  public queryModel: LogQueryModel;

  private loggregationPipe: LoggregationPipe = new LoggregationPipe();
  private loggregationParamPattern: RegExp = new RegExp(
    /\{CoralogixPH(\d+)_([^]*?.*?)_([free|categorical|numeric]+)_CoralogixPH\}/g,
  );

  constructor(
    private router: Router,
    private insightsProvider: InsightsProvider,
    private globalParams: GlobalParamsProvider,
    private statisticsService: StatisticService,
  ) {
    this.loggregationPipe.clickableParam = '$2';
    this.math = Math;
  }

  onItemCliecked(anomalyItem: AnomalyItem): void {
    this.selectedAnomalyDetails(anomalyItem);
    this.insightsProvider.globalState.selectedLog = anomalyItem.anomalyDetail;
  }

  onCountCliecked(e, anomalyItem: AnomalyItem): void {
    if (e && anomalyItem) {
      e.showModal(anomalyItem.anomalyDetail.id);
    }
  }

  onChartClicked(e): void {
    if (e[0]) {
      this.navToLogs(e[0]);
    }
  }

  navToLogs(queryModel: LogQueryModel): void {
    this.globalParams.chartClickedLogQueryModel = queryModel;

    this.router.navigate(['/query-new/logs']);
  }

  selectedAnomalyDetails(anomalyItem: AnomalyItem): void {
    if (this.selectedItem) {
      this.selectedItem.isSelected = false;
    }

    this.selectedItem = anomalyItem;
    this.selectedItem.isSelected = true;
  }

  severityColor(colorName: string): { [className: string]: boolean } {
    return {
      ['text-cgx-' + colorName]: true
    }
  }
}

class AnomalyItem {
  public anomalyDetail: AnomalyDetails;
  public statisticsQueryDefinition: StatisticsQueryDefinition;
  public isSelected: boolean = false;
  public chartModel: ChartModel;
  public normalCount: number;
}
