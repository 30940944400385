import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSettingsMobileFiltersComponent } from './user-settings-mobile-filters.component';
import { FilterPipeModule } from '@app/shared/pipes/filter-pipe/filter-pipe.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UserSettingsMobileFiltersComponent],
  imports: [
    CommonModule,
    FilterPipeModule,
    MatCheckboxModule,
    MatTabsModule,
    FormsModule
  ],
  exports: [UserSettingsMobileFiltersComponent]
})
export class UserSettingsMobileFiltersModule { }
