<div class="preview-logs-container">
  <div class="title">
    <div class="search-bar-container">
      <mat-form-field class="search-logs-preview-field" appearance="outline">
        <input
          type="text"
          placeholder="Enter a text string, use the Elasticsearch Query Syntax, or leave blank to match any text"
          aria-label="Enter a text string, use the Elasticsearch Query Syntax, or leave blank to match any text"
          autocomplete="off"
          name="search"
          matInput
          class="search-input logs-preview-search-input"
          (keyup.enter)="filterLogs()"
          [formControl]="formControl"
        />
        <button class="coralogix button search-button" (click)="filterLogs()" mat-button matSuffix aria-label="search">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <div class="validation-error">{{ validationErr$ | async }}</div>
    </div>
  </div>
  <div></div>
  <div class="logs-container">
    <div *ngIf="isLoading" class="loader-container">
      <div class="ui active inline loader"></div>
      <div>Loading...</div>
    </div>
    <div *ngIf="!hasLogsForPreview && !isLoading">
      No logs to preview
    </div>
    <sh-livetail-terminal-container
      *ngIf="!isLoading && hasLogsForPreview"
      [livetailLogs]="logs$ | async"
      [isTerminalMode]="false"
    ></sh-livetail-terminal-container>
  </div>
  <div mat-dialog-actions>
    <div class="paginator-container">
      <mat-paginator
        class="paginator"
        [length]="(filteredLogs$ | async).length"
        [pageSize]="100"
        (page)="getPageQueries($event)"
      ></mat-paginator>
    </div>
    <div style="display: flex;">
      <cgx-button
        style="margin-inline-end: 8px;"
        label="CLOSE"
        size="md"
        color="text-primary"
        [stroked]="true"
        (press)="onNoClick()"
      ></cgx-button>
      <cgx-button label="DOWNLOAD TSV" size="md" (press)="getTsvs()"></cgx-button>
    </div>
  </div>
</div>
