import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReindexRequest } from '@app/roi/reindex/models/reindex.model';
import moment from 'moment';

interface IReindexEditorSplittedControl {
  details: {
    name: string;
    description?: string;
  };
  filters: {
    applicationName: string[];
    subsystemName: string[];
    logSeverity: number[];
    text: string;
    from: Date;
    startTime: string;
    endTime: number;
  };
}

@Component({
  selector: 'sh-reindex-form',
  templateUrl: './reindex-form.component.html',
  styleUrls: ['./reindex-form.component.scss'],
})
export class ReindexFormComponent {
  @Input()
  public form: FormGroup;
  @Input()
  public logsSeverityOptions: { id: number; name: string }[];

  @Output()
  public Submit: EventEmitter<ReindexRequest> = new EventEmitter<ReindexRequest>();

  public get detailsGroup(): FormGroup {
    return this.form?.get('details') as FormGroup;
  }

  public get filterGroup(): FormGroup {
    return this.form?.get('filters') as FormGroup;
  }

  public submit(value: IReindexEditorSplittedControl): void {
    const { filters, details } = value;
    const { name, description } = details;
    const { applicationName, subsystemName, logSeverity, from, startTime, endTime, text } = filters;
    const [hour, minute] = startTime.split(':');

    const newStartTime = moment(from)
      .set('hour', +hour)
      .set('minute', +minute)
      .set('seconds', 0)
      .utc();
    const newEndTime = moment(newStartTime).add(endTime, 'm');

    const reindexReq = new ReindexRequest({
      name: name,
      description,
      applicationName: applicationName || [],
      subsystem: subsystemName || [],
      severity: logSeverity || [],
      text: text || null,
      startTime: newStartTime,
      endTime: newEndTime,
    });

    this.Submit.emit(reindexReq);
  }

  public get isFormInvalid(): boolean {
    return this.form?.invalid;
  }
}
