import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sh-change-pass-control',
  templateUrl: './change-pass-control.component.html',
  styleUrls: ['./change-pass-control.component.scss'],
})
export class ChangePassControlComponent {
  @Input() changePasswordForm: FormGroup;

  @Input() passwordError: string[];

  @Input() repeatPassword: boolean = true;

  @Input() passwordLabel: string = 'Enter new password';

  @Input() repeatPasswordLabel: string = 'Retype password';

  @Input() additionalClassName: string = '';

  @Input() showPasswordButton: boolean = false;

  showPassword: boolean = false;

  showRepeatPassword: boolean = false;
}
