import { Insight } from '../models/insight.model';
import { LogQueryModel } from '../../../logs/shared/models/logsquery.model';
import * as moment from 'moment';
import { QueryParams } from '../../../logs/shared/models/query-params.model';
import { Metadata } from '../../../logs/shared/models/query-metadata';
import { Anomaly } from '../models/anomaly.model';
import { Alert } from '../models/alert.model';
import { SortModel } from '../../../logs/shared/models/sort.model';
import { AnomalySubTypeId } from '../models/enums';
export class InsightsHelper {
  public static getLogQueryModelByInsight(insight: Insight): any {
    if (insight) {
      let startDate;
      let endDate;
      if (this.isAnomaly(insight)) {
        if (insight.subTypeId === AnomalySubTypeId.FlowAnomaly) {
          startDate = moment.unix(insight.timestamp / 1000).toDate();
          endDate = moment
            .unix(insight.timestamp / 1000)
            .add(1, 'm')
            .toDate();
        } else {
          startDate = moment
            .unix(insight.timestamp / 1000)
            .subtract(1, 'h')
            .toDate();
          endDate = moment.unix(insight.timestamp / 1000).toDate();
        }
      } else {
        const alert = insight as Alert;
        startDate = moment.unix((alert.properties.logTimestamp - 10) / 1000).toDate();
        endDate = moment
          .unix(alert.properties.logTimestamp / 1000)
          .add(1, 'm')
          .toDate();
      }
      const queryModel = new LogQueryModel(startDate, endDate);
      const sortModel = new SortModel();
      sortModel.ordering = 'asc';
      queryModel.sortModel = [sortModel];

      queryModel.queryParams = new QueryParams();
      queryModel.queryParams.metadata = new Metadata();

      if (insight.applicationName) {
        queryModel.queryParams.metadata.applicationName.push(insight.applicationName);
      }

      if (insight.subsystemName) {
        queryModel.queryParams.metadata.subsystemName.push(insight.subsystemName);
      }

      if (this.isAnomaly(insight) && insight.subTypeId === AnomalySubTypeId.VolumeAnomaly) {
        queryModel.queryParams.metadata.severity.push(5, 6);
      }

      queryModel.queryParams.aggregationInterval = 1000;
      return queryModel;
    }
    return null;
  }

  public static getLogQueryTopErrorModelByInsight(insight: Insight): any {
    if (insight) {
      let startDate;
      let endDate;
      if (this.isAnomaly(insight)) {
        if (insight.subTypeId === AnomalySubTypeId.FlowAnomaly) {
          startDate = moment
            .unix(insight.timestamp / 1000)
            .subtract(1, 'm')
            .toDate();
          endDate = moment
            .unix(insight.timestamp / 1000)
            .add(1, 'm')
            .toDate();
        } else {
          startDate = moment
            .unix(insight.timestamp / 1000)
            .subtract(1, 'h')
            .toDate();
          endDate = moment.unix(insight.timestamp / 1000).toDate();
        }
      } else {
        const alert = insight as Alert;
        startDate = moment.unix((alert.properties.logTimestamp - 10) / 1000).toDate();
        endDate = moment
          .unix(alert.properties.logTimestamp / 1000)
          .add(1, 'm')
          .toDate();
      }
      const queryModel = new LogQueryModel(startDate, endDate);

      queryModel.queryParams = new QueryParams();
      queryModel.queryParams.metadata = new Metadata();

      if (insight.applicationName) {
        queryModel.queryParams.metadata.applicationName.push(insight.applicationName);
      }

      if (this.isAnomaly(insight) && insight.subTypeId === AnomalySubTypeId.VolumeAnomaly && insight.subsystemName) {
        queryModel.queryParams.metadata.subsystemName.push(insight.subsystemName);
      }
      queryModel.queryParams.metadata.severity = [];
      queryModel.queryParams.metadata.severity.push(...[4, 5, 6]);
      queryModel.queryParams.aggregationInterval = 1000;
      return queryModel;
    }
    return null;
  }

  public static getHighlightedLogsByInsight(insight: Insight): any {
    const highlightedLogs: Map<string, string> = new Map<string, string>();
    if (insight) {
      if (this.isAnomaly(insight) && insight.subTypeId === AnomalySubTypeId.FlowAnomaly) {
        const anomaly = insight as Anomaly;
        anomaly.properties.details.forEach((d) => {
          highlightedLogs.set(d.id, '#22354c');
        });
      } else {
        const alert = insight as Alert;
        highlightedLogs.set(alert.properties.logId, '#22354c');
      }
    }
    return highlightedLogs;
  }

  public static isAnomaly(insight: Insight): boolean {
    return insight instanceof Anomaly ? true : false;
  }

  public static getemplateIdsByInsight(insight: Insight): string[] {
    const templateIds: string[] = [];
    if (this.isAnomaly(insight) && insight.subTypeId === AnomalySubTypeId.FlowAnomaly) {
      const anomaly = insight as Anomaly;
      anomaly.properties.details.forEach((a) => templateIds.push(a.id));
    }
    return templateIds;
  }

  public static isMultiLevelGroupBy(insight: Insight): boolean {
    return Array.isArray(insight.esInfo.hits);
  }

  public static getMultiLevelGroupByFieldName(hit: any): string {
    return Object.keys(hit).filter(key => key !== 'hitCount' && key !== 'nestedAggs')[0];
  }

  public static getMultiLevelGroupByFieldNames(insight: Insight): string[] {
    const traverseHits = (acc, hit) => {
      const res = [...acc, this.getMultiLevelGroupByFieldName(hit)];
      return hit.nestedAggs ?
        traverseHits(res, hit.nestedAggs[0])
        : res;
    };
    return traverseHits([], insight.esInfo.hits[0]);
  }
}
