<mat-card class="coralogix">
  <p class="mat-card-header">Recipients</p>
  <p class="mat-card-subheader">
    Enter the emails for anyone that should receive this alert and/or select which of your Webhook integrations to send the alert to.
  </p>
  <form [formGroup]="form">
    <div class="coralogix input-group">
      <label select-label class="input-label">
        <span class="text">Email Recipients</span>
      </label>
      <div class="has-validation" [class.coralogix-input-error]="hasError('notificationsMails', 'example')">
        <sh-coralogix-chips-input
          data-test="alerts-email-recipients"
          [placeholder]="'Add email addresses'"
          formControlName="notificationsMails"
          [showIcon]="true"
        >
          <i class="fa fa-envelope-o input-icon-before" custom-icon></i>
        </sh-coralogix-chips-input>
        <span class="error" *ngIf="hasError('notificationsMails', 'example')">Enter valid email</span>
      </div>
    </div>
    <div class="coralogix input-group">
      <sh-coralogix-select
        data-test="alerts-webhook-recipients-select"
        [bindLabel]="'alias'"
        [bindValue]="'alias'"
        [multiple]="true"
        [searchable]="true"
        [disableSelectAll]="true"
        [hasIconBefore]="true"
        [selectOptions]="integrationOptions"
        [placeholder]="'Add webhook integrations'"
        formControlName="integrations"
        (focus)="onFocus($event)"
      >
        <label select-label class="label-with-link">
          <span class="text">Webhook Recipients</span>
          <a class="create-webhook-link" target="_blank" routerLink="/webhooks">Create Webhook</a>
        </label>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" custom-icon-before>
          <path
            fill="#95A0B3"
            fill-rule="evenodd"
            d="M1.788 13.777l.796 1.083c-.723.477-1.196 1.273-1.196 2.174 0 1.46 1.243 2.644 2.776 2.644 1.368 0 2.505-.942 2.734-2.182v-1.21h11.808c.25-.347.67-.574 1.144-.574.767 0 1.388.592 1.388 1.322 0 .73-.621 1.322-1.388 1.322-.512 0-.96-.265-1.2-.659H8.27C7.94 19.572 6.228 21 4.165 21 1.864 21 0 19.224 0 17.034c0-1.35.707-2.54 1.788-3.257zm9.536-10.944c.662-.366 1.505-.154 1.883.472.247.41.234.895.011 1.284l5.18 8.582c1.87-.69 4.031-.008 5.056 1.69 1.134 1.88.445 4.292-1.54 5.388a4.324 4.324 0 0 1-3.831.17l.588-1.193c.789.351 1.743.339 2.558-.111 1.323-.731 1.783-2.34 1.027-3.593-.757-1.253-2.442-1.677-3.766-.946a3.482 3.482 0 0 1-.017.01l-.012-.02-.695.384L11.93 5.28a1.374 1.374 0 0 1-1.118-.65c-.378-.627-.148-1.431.513-1.796zm2.8-2.301c1.218.672 1.945 1.847 2.058 3.093l-1.377.115a2.633 2.633 0 0 0-1.372-2.062c-1.322-.73-3.013-.297-3.777.97-.748 1.239-.33 2.817.93 3.567l.761.42-5.896 9.77c.168.335.186.732.026 1.09a1.382 1.382 0 0 1-1.298.864c-.764 0-1.383-.592-1.383-1.322 0-.197.045-.385.126-.553a1.34 1.34 0 0 1 .085-.167c.256-.423.717-.66 1.193-.662l5.205-8.626C7.871 5.82 7.431 3.69 8.46 1.986 9.604.087 12.14-.564 14.125.532z"
          />
        </svg>
      </sh-coralogix-select>
    </div>
  </form>
</mat-card>
