import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Metric } from '@app/settings/metrices-settings/models/metric.model';
import { State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { Observable } from 'rxjs';
import { MetricsStoreService } from '@app/settings/shared/services/metrics-store.service';

@Component({
  selector: 'sh-permutations-settings',
  templateUrl: './permutations-settings.component.html',
  styleUrls: ['./permutations-settings.component.scss'],
})
export class PermutationsSettingsComponent {
  @Input() public permutationsLimitControl: FormControl;
  @Input() public metric: Metric;
  @Input() public isInEditMode = false;
  @Select(CompanyState.logs2MetricsPermutationLimit) public logs2MetricsPermutationLimit$: Observable<number>;
  public permutationsLeft$ = this.metricsStoreService.permutationsLeft$;

  constructor(private store: Store<State>, private metricsStoreService: MetricsStoreService) {}
}
