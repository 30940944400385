<div class="info-container" fxLayout="column" fxFlex="auto" fxLayoutGap="40px">
  <section class="content-wrapper">
    <mat-card class="coralogix" fxLayout="column" fxLayoutGap="30px">
      <div class="rule-card-header">Details</div>
      <sh-details-form [detailsForm]="detailsForm"></sh-details-form>
    </mat-card>
  </section>
  <mat-expansion-panel class="content-wrapper info-container__rule-matcher coralogix mat-card" #matcherPanel>
    <mat-expansion-panel-header>
      <span class="info-container-headlines">
        <div class="rule-card-header">Rule Matcher</div>
        <p class="rule-card-subheader">Rules will execute on logs that match the following applications, subsystems and severities.</p>
      </span>
    </mat-expansion-panel-header>
    <sh-rule-matcher-form class="rule-matcher-form" [ruleMatchersForm]="ruleMatchersForm"></sh-rule-matcher-form>
  </mat-expansion-panel>
  <section class="content-wrapper rules-container">
    <mat-card class="coralogix">
      <div class="rule-card-header">Rules</div>
      <p class="rule-card-subheader">
        Build a sequence of rules to perform on your data to structure unstructured logs, assign severities, modify log messages, and block
        unnecessary logs.
        <span class="rules-container__notes">
          <b>Note:</b>
          The ordering of rules is important, as it affects the final outcome.
        </span>
      </p>
      <div
        fxLayout="row wrap"
        class="rules-container__rule-creation-tiles"
        fxLayoutAlign="start stretch"
        fxLayoutGap="3%"
        *ngIf="!parsingThemeHasRuleGroups"
      >
        <sh-small-rule-card
          *ngFor="let rule of ruleCardsData"
          fxFlex.lt-lg="30.33%"
          fxFlex.lt-md="47%"
          [title]="rule.title"
          [buttonText]="rule.buttonText"
          [description]="rule.description"
          [type]="rule.type"
          (buttonClick)="createNewRuleGroupWithRule($event)"
        ></sh-small-rule-card>
      </div>
      <sh-rule-groups-accordion
        class="rules-container__rule-groups"
        *ngIf="parsingThemeHasRuleGroups"
        [ruleGroupsForm]="ruleGroupsForm"
        [openLastRuleOnInit]="isCreateMode"
        [newRuleAdded]="newRuleAdded"
        (addNewGroupWithRule)="createNewRuleGroupWithRule($event)"
        [parsingThemeId]="parsingThemeId"
      ></sh-rule-groups-accordion>
    </mat-card>
  </section>
  <cgx-button
    *ngIf="!isCreateMode"
    label="DELETE RULE GROUP"
    textNoWrap
    size="lg"
    width="212px"
    color="error"
    [outline]="true"
    (press)="deleteParsingTheme.emit()"
  ></cgx-button>
</div>
