import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Enrichment } from '../../enrichments-settings/enrichment.model';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Constants } from '../../../constants';

@Injectable()
export class EnrichmentsService {
  constructor(private http: HttpClient) {}

  public getEnrichments(): Observable<Enrichment[]> {
    return this.extractEnrichments(this.http.get(Constants.enrichmentsUrl));
  }

  public deleteEnrichments(
    enrichments: Enrichment[],
  ): Observable<Enrichment[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: enrichments,
    };
    return this.extractEnrichments(
      this.http.delete(Constants.enrichmentsUrl, httpOptions),
    );
  }

  public createEnrichments(
    enrichments: Enrichment[],
  ): Observable<Enrichment[]> {
    return this.extractEnrichments(
      this.http.put(Constants.enrichmentsUrl, enrichments),
    );
  }

  private extractEnrichments(response): Observable<Enrichment[]> {
    return response.map((res) => res);
  }
}
