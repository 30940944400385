import { Action } from '@ngrx/store';
import { type } from '../../../../shared/services/utils';
import { LogsView } from '../../models/logs-view.model';

export namespace LogsViewsActions {
  export const ActionTypes = {
    LOGS_VIEWS_LOAD: type('[LogsViews] Load views'),
    LOGS_VIEWS_LOAD_COMPLETED: type('[LogsViews] Load views completed'),
    LOGS_VIEWS_LOAD_FAILED: type('[LogsViews] Load views failed'),
    LOGS_VIEW_SAVE: type('[LogsViews] Save logs view'),
    LOGS_VIEW_SAVE_COMPLETED: type('[LogsViews] Save logs view completed'),
    LOGS_VIEW_SAVE_FAILED: type('[LogsViews] Save logs view failed'),
    LOGS_VIEW_DELETE: type('[LogsViews] Delete logs view'),
    LOGS_VIEW_DELETE_COMPLETED: type('[LogsViews] Delete logs view completed'),
    LOGS_VIEW_DELETE_FAILED: type('[LogsViews] Delete logs view failed'),
    LOGS_VIEW_SELECT: type('[LogsViews] Select logs view'),
  };

  export class LoadLogsViewAction implements Action {
    public type = ActionTypes.LOGS_VIEWS_LOAD;

    constructor(public payload: any = null) {}
  }

  export class LoadLogsViewCompletedAction implements Action {
    public type = ActionTypes.LOGS_VIEWS_LOAD_COMPLETED;

    constructor(public payload: LogsView[]) {}
  }

  export class LoadLogsViewFailedAction implements Action {
    public type = ActionTypes.LOGS_VIEWS_LOAD_FAILED;

    constructor(public payload: any) {}
  }

  export class SaveLogsViewAction implements Action {
    public type = ActionTypes.LOGS_VIEW_SAVE;

    constructor(public payload: any) {}
  }

  export class SaveLogsViewCompletedAction implements Action {
    public type = ActionTypes.LOGS_VIEW_SAVE_COMPLETED;

    constructor(public payload: LogsView) {}
  }

  export class SaveLogsViewFailedAction implements Action {
    public type = ActionTypes.LOGS_VIEW_SAVE_FAILED;

    constructor(public payload: any) {}
  }

  export class DeleteLogsViewAction implements Action {
    public type = ActionTypes.LOGS_VIEW_DELETE;

    constructor(public payload: number) {}
  }

  export class DeleteLogsViewCompletedAction implements Action {
    public type = ActionTypes.LOGS_VIEW_DELETE_COMPLETED;

    constructor(public payload: number) {}
  }

  export class DeleteLogsViewFailedAction implements Action {
    public type = ActionTypes.LOGS_VIEW_DELETE_FAILED;

    constructor(public payload: any) {}
  }

  export class SelectLogsViewAction implements Action {
    public type = ActionTypes.LOGS_VIEW_SELECT;

    constructor(public payload: number, public includeViewQueryParams: boolean = true) {}
  }

  export type Actions =
    | LoadLogsViewAction
    | LoadLogsViewCompletedAction
    | LoadLogsViewFailedAction
    | SaveLogsViewAction
    | SaveLogsViewCompletedAction
    | SaveLogsViewFailedAction
    | DeleteLogsViewAction
    | DeleteLogsViewCompletedAction
    | DeleteLogsViewFailedAction
    | SelectLogsViewAction;
}
