import { NgModule } from '@angular/core';
import { LivetailTerminalContainerComponent } from '@app/livetail/livetail-terminal-container/livetail-terminal-container.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { JsonFormatterModule } from '@app/shared/directives/json-formatter/json-formatter.module';
import { DateFormatByUserSettingsModule } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.module';
import { PrettyObjectFormatterModule } from '@app/shared/directives/pretty-object-formatter/pretty-object-formatter.module';

@NgModule({
  declarations: [LivetailTerminalContainerComponent],
  imports: [CommonModule, SharedModule, JsonFormatterModule, DateFormatByUserSettingsModule, PrettyObjectFormatterModule],
  exports: [LivetailTerminalContainerComponent],
})
export class LivetailTerminalContainerModule {}
