import { Store } from '@ngrx/store';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { TimeZoneType } from '@shared/models/timezone-types';

export class TimeZoneBaseClass {
  protected isLocalDate: boolean = true;
  constructor(store: Store<State>) {
    store.select(getTimezoneViewPreference).take(1).subscribe((tmz) => {
      this.isLocalDate = tmz === TimeZoneType.local;
    });
  }
}
