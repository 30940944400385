import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

@jsonObject
export class EmailFilterDefinition {
  @jsonMember
  public id: number;
  @jsonMember
  public applicationName: string;
  @jsonMember
  public subsystemName: string;
  @jsonMember
  public email_filter_id: number;
}

@jsonObject
export class EmailFilterModel {
  @jsonMember
  public id: number;
  @jsonMember
  public email_filter_id: number;
  @jsonArrayMember(EmailFilterDefinition)
  public email_filter_definitions: EmailFilterDefinition[] = [];
}
