import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogsView } from '../models/logs-view.model';
import { Constants } from '../../../constants';
import { GridColumnService } from '../../../shared/services/grid-column-service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class LogsFilterGridColumnsService {
  private url: string = Constants.companyIndexFieldsUrl;
  private redisTemplatesUrl: string = Constants.companyRedisTemplateIndexFieldsUrl;
  private redisExtendedTemplatesUrl: string = Constants.companyRedisExtendedTemplateIndexFieldsUrl;

  constructor(
    private http: HttpClient,
    private gridColumnService: GridColumnService,
  ) {}

  public loadCompanyIndexFields(): Observable<LogsView[]> {
    return this.http
      .get(this.url)
      .map((res: any) => this.extractData(res))
      .catch((error) => this.handleError(error));
  }

  public loadCompanyIndexFieldsFromRedis(): Observable<LogsView[]> {
    return this.http
      .get(this.redisTemplatesUrl)
      .map((res: any) => this.extractData(res))
      .catch((error) => this.handleError(error));
  }

  public loadCompanyExtendedIndexFieldsFromRedis(): Observable<LogsView[]> {
    return this.http
      .get(this.redisExtendedTemplatesUrl)
      .map((res: any) => this.extractData(res))
      .catch((error) => this.handleError(error));
  }

  private handleError(error: any): Observable<any> {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';

    return throwError(errMsg);
  }

  private extractData(res: any): any {
    const fields = res.unique_fields;
    const availableCols = [];
    const selectedCols = [];

    fields.forEach((field) => {
      const displayName = this.gridColumnService.getColumnDisplayName(field);
      const column = {
        colId: field,
        headerName: displayName,
        isDefault: false,
      };

      if (this.gridColumnService.getDefaultColumns().includes(field)) {
        column.isDefault = true;
        selectedCols.push(column);
      } else {
        availableCols.push(column);
      }
    });

    selectedCols.sort((a, b) => a.colId.localeCompare(b.colId));
    availableCols.sort((a, b) => a.colId.localeCompare(b.colId));
    return { selected: selectedCols, available: availableCols };
  }
}
