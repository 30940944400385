import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ISelectedLog } from '../../models/query-params.model';

@Component({
  selector: 'sh-select-log',
  templateUrl: './select-log.component.html',
  styleUrls: ['./select-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectLogComponent implements OnInit {
  @Input() public selectedLogs$: ISelectedLog[];
  @Output() public setFocusedLog: EventEmitter<ISelectedLog> = new EventEmitter<ISelectedLog>();
  @Output() public clearSelectedLogs: EventEmitter<ISelectedLog> = new EventEmitter<ISelectedLog>();
  public selectKey: '⌘ Command' | 'Ctrl' | 'Command/Ctrl';

  public ngOnInit(): void {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Win')) {
      this.selectKey = 'Ctrl';
    } else if (userAgent.includes('Mac')) {
      this.selectKey = '⌘ Command';
    } else {
      this.selectKey = 'Command/Ctrl';
    }
  }
}
