import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPredefinedQuickQuery } from '@app/logs-new/shared/models/predefined-time-queries.model';
import { PredefinedQuickTimes } from '@app/logs-new/shared/models/time/quick-times.model';
import { State } from '@app/app.reducers';
import { ILogsModuleState } from '@app/logs-new/store';
import { getQueryLastWord } from '@app/logs-new/shared/utils/query-search.utils';
import { selectCurrentTeamId } from '@app/user/state/teams/team.selectors';
import { formatDistanceStrict } from 'date-fns';
import { LogsGraphType } from '@app/logs-new/shared/models/graph/logs-graph.model';

export const selectLogsQueries = createSelector(createFeatureSelector<State, ILogsModuleState>('logsModule'), state => state?.logsQueries);

export const getSelectedTimeQuery = createSelector(selectLogsQueries, state => state.predefinedQuery);

export const getSelectedQuerySortedIndex = createSelector(selectLogsQueries, state => {
  const query = state.predefinedQuery.data as IPredefinedQuickQuery;
  return new PredefinedQuickTimes().sortedPredefinedQuickTimes.findIndex(q => q.value === query.value);
});

export const selectLogQuery = createSelector(selectLogsQueries, state => state.logQuery);

export const selectSearchQuery = createSelector(selectLogsQueries, state => state.searchQuery);

export const selectActiveSortModel = createSelector(selectLogsQueries, state => state?.logQuery?.sortModel[0]);

export const selectRecentQueries = createSelector(selectLogsQueries, selectSearchQuery, (state, searchQuery: string) =>
  state.recentQueries.filter(query => query.toLowerCase().includes(getQueryLastWord(searchQuery).toLowerCase())),
);

export const selectRefreshQuery = createSelector(selectLogsQueries, state => state.refreshQuery);

export const selectShowRefreshQueryBar = createSelector(selectLogsQueries, state => state.showRefreshQueryBar);

export const selectQueryRange = createSelector(selectLogsQueries, state => state.queryRange);

export const selectSelectedLogs = createSelector(selectLogsQueries, state => state.logQuery.queryParams.selectedLogs);

export const selectGraphAggFuncField = createSelector(selectLogsQueries, state => state.logQuery?.graphs?.[0]?.aggBy?.field || null);

export const selectGraphGroupByField = createSelector(selectLogsQueries, state => state.logQuery?.graphs?.[0]?.aggField || null);

export const selectGraphCompareToCaption = createSelector(selectLogsQueries, state => {
  const graph = state.logQuery?.graphs?.[0];
  if (graph?.type === LogsGraphType.Relative) {
    return formatDistanceStrict(graph.compareStartDate, graph.baseStartDate, { addSuffix: true });
  }
  return null;
});

export const selectGraphAggFuncType = createSelector(selectLogsQueries, state => state.logQuery?.graphs?.[0]?.aggBy?.type || null);

export const selectActiveLogsGraph = createSelector(selectLogsQueries, state => state.logQuery?.graphs?.[0]);

export const selectActiveLogsGraphType = createSelector(selectLogsQueries, state => state.logQuery?.graphs?.[0]?.type);

export const selectExternalFilters = createSelector(selectLogsQueries, state => state.logQuery.queryParams.externalFilters);

export const selectIsCurrentTeam = createSelector(selectExternalFilters, selectCurrentTeamId, (externalFilters, currentTeam) => {
  const hasSeveralSelections = externalFilters.teams?.length > 1;
  const hasSingleSelection = externalFilters.teams?.length === 1;
  const firstSelectedTeamId = externalFilters.teams?.[0] === currentTeam;

  if (hasSeveralSelections) return false;
  if (hasSingleSelection) return firstSelectedTeamId;
  return true;
});
