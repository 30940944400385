import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import HC_more from 'highcharts/highcharts-more';
import { SeveritiesHelper } from '@app/shared/services/severities.helper';
import { Insight } from '@app/insights/shared/models/insight.model';
import { DateHelper } from '@shared/services/date.helper';
import { Store } from '@ngrx/store';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { TimeZoneType } from '@shared/models/timezone-types';
import { localDateToUtcDate } from '@shared/helpers/moment.helper';

HC_more(Highcharts);

@Component({
  selector: 'sh-summary-chart',
  templateUrl: './summary-chart.component.html',
  styleUrls: ['./summary-chart.component.scss'],
})
export class SummaryChartComponent implements OnInit {
  public isLocalTimezone: boolean = true;
  public chart: Chart;

  @Input()
  public set insights(insights: Insight[]) {
    this.chartModel = { ...this.chartModel, series: this.normalizeData(insights) };
    this.chart = new Chart(this.chartModel);
  }

  public bins: { date: Date }[] = [];

  public chartModel: any = {
    chart: {
      type: 'column',
      backgroundColor: 'var(--cgx-background)',
      borderColor: 'var(--cgx-border-primary)',
      borderWidth: 1,
      borderRadius: 4,
      height: 364,
      width: 650,
    },
    title: {
      text: '',
    },
    xAxis: {
      labels: {
        style: { color: 'var(--cgx-text-secondary)' },
        useHTML: true,
        formatter: context => {
          if (!this.bins || !this.bins[context.value]) {
            return null;
          }
          const currDate = this.bins[context.value].date;
          const dateBySettings = this.isLocalTimezone ? currDate : localDateToUtcDate(this.bins[context.value].date).toDate();
          const hours = dateBySettings.getHours() + 1;
          return `<div class="summary-label">${hours < 10 ? '0' + hours : hours}:00</div>`;
        },
      },
      tickInterval: 1,
    },
    yAxis: {
      gridLineColor: 'var(--cgx-border-primary)',
      allowDecimals: false,
      min: 0,
      title: {
        text: '',
      },
      tickLength: 20,
      labels: {
        enabled: false,
      },
    },
    isLocalTime: this.isLocalTimezone,
    tooltip: {
      useHTML: true,
      shared: true,
      followPointer: true,
      formatter(): string {
        // TODO - fix - there is a bug here, the tooltip doesn't show the correct day
        // the following code is part of the solution
        // const localTimeZone = this.series?.chart?.options?.isLocalTime;
        const date = moment().format('MMM DD, YYYY');
        const rows = this.points
          .reverse()
          .map(point => {
            return `<tr><td class="col-left"><span class="circle" style="color: ${point.color}"></span> ${point.series.name}</td><td class="col-right">${point.y}</td></tr>`;
          })
          .join('');
        return `<div class="quota-optimizer-chart-tooltip">
              <table>
                <thead>
                  <tr>
                    <th class="col-left date">${date}</th>
                    <th class="col-right gb">Triggered <br/> Alerts</th>
                  </tr>
                </thead>
                <tbody>
                    ${rows}
                </tbody>
            </table>
          </div>
          `;
      },
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderWidth: 1,
      borderRadius: 4,
      padding: 0,
      outside: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        showInLegend: false,
        states: {
          inactive: {
            opacity: 0.3,
          },
        },
        point: {
          events: {
            mouseOver: updateStackColor(0.7),
            mouseOut: updateStackColor(0),
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: 'column',
        name: 'Critical',
        data: [5, 3, 4, 7, 2, 5, 3, 4, 7, 2, 2, 5, 6, 2, 1, 5, 3, 4, 7, 2, 5, 3, 4, 7],
        color: '#d139d1',
      },
      {
        type: 'column',
        name: 'Warning',
        data: [2, 5, 6, 2, 1, 5, 3, 4, 7, 2, 2, 5, 6, 2, 1, 5, 3, 4, 7, 2, 5, 3, 4, 7],
        color: '#f3ca75',
      },
      {
        type: 'column',
        name: 'Info',
        data: [3, 0, 4, 4, 3, 5, 3, 4, 7, 2, 2, 5, 6, 2, 1, 5, 3, 4, 7, 2, 5, 3, 4, 7],
        color: '#83dcff',
      },
    ],
  };

  constructor(private store: Store<State>) {
    this.store
      .select(getTimezoneViewPreference)
      .take(1)
      .subscribe(tmz => {
        this.isLocalTimezone = tmz === TimeZoneType.local;
      });
  }

  public ngOnInit(): void {
    this.chart = new Chart(this.chartModel);
  }

  public normalizeData(insights: Insight[]): any {
    if (insights && insights.length) {
      const vals = Object.values(
        insights.reduce((acc, item) => {
          if (acc[item.severity]) {
            acc[item.severity] = Object.assign(acc[item.severity], {
              data: [...acc[item.severity].data, item.timestamp],
            });
            return acc;
          }
          acc[item.severity] = {
            type: 'column',
            data: [item.timestamp],
            color: SeveritiesHelper.getEventSeverityAlertColor(item.severity),
            name: SeveritiesHelper.getEventSeverityById(item.severity),
          };
          return acc;
        }, {}),
      );
      return vals.map((item: any) => ({ ...item, data: [...this.transform(item.data, 24)] }));
    }
    return [];
  }

  public transform(items: Insight[], numberOfBins: number): any {
    const bins = [];
    if (items && items.length > 0) {
      const numberOfHoursPerBin: number = 24 / numberOfBins;
      let binStartTime = DateHelper.getRoundHour(
        moment()
          .subtract(24, 'hours')
          .toDate(),
      );
      for (let _i = 0; _i < numberOfBins + 1; _i++) {
        bins.push({ date: binStartTime.toDate(), size: 0 });
        binStartTime = moment(binStartTime).add(numberOfHoursPerBin, 'hours');
      }
      items.forEach((x: any) => {
        for (let _i = 0; _i < numberOfBins; _i++) {
          const roundedTime = DateHelper.getRoundHour(x).toDate();
          if (roundedTime <= bins[_i + 1].date && roundedTime > bins[_i].date) {
            bins[_i].size += 1;
            break;
          }
        }
      });

      bins.splice(numberOfBins, 1);
      this.bins = bins;
    }
    return bins.map(item => Object.values(item));
  }
}

function updateStackColor(alpha: number): () => void {
  return function(): void {
    const x = this.x;
    const color = Highcharts.color;
    const colors = this.series.chart.series.map(item => item?.color);

    this.series.chart.series.forEach((series, i) => {
      series?.data?.forEach(point => {
        const basePointColor = color(colors[i]);
        point.update(
          {
            color:
              alpha === 0
                ? basePointColor.get() // set original color
                : point.x === x
                ? basePointColor.brighten(0).get() // brighten original color
                : basePointColor.setOpacity(alpha).get(), // dim orignal color
          },
          false,
        );
      });
    });

    this.series.chart.redraw(false);
  };
}
