export class GetData {
  public static readonly type: string = '[Pay As You Go] Get Data';
}

export class Toggle {
  public static readonly type: string = '[Pay As You Go] Toggle';
}

export class SuccessfullyConfigured {
  public static readonly type: string = '[Pay As You Go] Successfully Configured';
  constructor(public email: string) {}
}
