import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { GetTeammate } from './user.action';
import { Teammate } from '@app/shared/models/teammate.model';
import { TeammateService } from '@app/shared/services/teammate/teammate.service';
import { Observable } from 'rxjs';
import { Role } from '@app/shared/enums/role.enum';

@State<Teammate>({
    name: 'user',
    defaults: null
})
@Injectable()
export class UserState {

    constructor(
        private teammateService: TeammateService,
        public store: Store
    ) {
    }

    @Selector()
    public static user(state: Teammate): Teammate {
        return state;
    }

    @Selector()
    public static role(state: Teammate): Role {
        return state?.role;
    }

    @Selector()
    public static username(state: Teammate): string {
        return state?.username;
    }

    @Selector([UserState.role])
    public static isAdmin(role: Role): boolean {
        return role === Role.Admin;
    }

    @Selector([UserState.role])
    public static isReadOnly(role: Role): boolean {
        return role === Role.ReadOnly;
    }

    @Action(GetTeammate)
    public GetTeammate(ctx: StateContext<Teammate>): Observable<Teammate> {
        return this.teammateService.me().pipe(
            map(user => {
                window.analytics.identify(user.id, {
                    name: user.username,
                    company: localStorage.getItem('t_id')
                });

                return ctx.setState(user);
            })
        );
    }

}
