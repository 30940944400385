import { Severity } from '@app/tco/enums/severity.enum';

export class Colors {
    public static severityColors = {
        [Severity.CRITICAL]: '#d423bb',
        [Severity.DEBUG]: '#b5b5b5',
        [Severity.ERROR]: '#f04879',
        [Severity.INFO]: '#32bdf4',
        [Severity.VERBOSE]: '#68ee9a',
        [Severity.WARNING]: '#ffc400',
    };
}
