import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class NotificationSettingsModel {
  @jsonMember
  public enabled: number;
  @jsonMember
  public id: number;
  @jsonMember
  public label: string;
}
