<div class="side-menu-container">
  <svg-icon
    data-test="grid-side-menu"
    class="menu-icon"
    (click)="toggleMenu(); $event.stopImmediatePropagation()"
    src="assets/icons/side-menu.svg"
  ></svg-icon>

  <div
    class="context-menu context-menu-main"
    *ngIf="showMenu$ | async"
    (clickOutside)="closeMenu()"
    data-test="logs-sidemenu"
    [ngStyle]="menuStyle$ | async"
    #sideMenuEl
  >
    <div
      hoverable
      [cgxTooltip]="pluginMenu"
      [originPosition]="{ originX: 'after', originY: 'top' }"
      [overlayPosition]="{ overlayX: 'before', overlayY: 'top' }"
      [layout]="false"
      class="item view-surrounding-logs"
      data-test="view-surrounding-logs"
      (showEvent)="onShowSearch()"
    >
      <svg-icon class="icon" svgClass="cgx-icon-secondary svg-icon-plug" src="assets/icons/xs-16x16/plug.svg"></svg-icon>
      <div class="text">ACTIONS</div>
      <div class="arrow"></div>
    </div>
    <ng-container *shAllowAbility="'enableSurroundingLogs'">
      <div class="item view-surrounding-logs" data-test="view-surrounding-logs" *ngIf="!params?.context?.parent?.isTemplates">
        <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/view-surrounding.svg"></svg-icon>
        <div class="text">VIEW SURROUNDING LOGS</div>

        <div class="arrow"></div>

        <div class="context-menu context-menu-sub" data-test="view-surrounding-logs-menu">
          <div class="item" *ngFor="let time of viewSurroundingLogs" (click)="onViewSurroundingLogs(time)">
            <div class="text">
              <b>{{ time.caption }}</b>
              {{ time.timeUnit }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *shAllowAbility="'enableSurroundingArchiveLogs'">
      <div
        class="item view-surrounding-logs"
        data-test="view-surrounding-logs"
        *ngIf="!params?.context?.parent?.isTemplates && !params.context.parent.isArchiveDisabled"
      >
        <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/sm-24x24/archive.svg"></svg-icon>
        <div class="text">VIEW SURROUNDING ARCHIVE LOGS</div>

        <div class="arrow"></div>

        <div class="context-menu context-menu-sub" data-test="view-surrounding-logs-menu">
          <div class="item" *ngFor="let time of viewSurroundingLogs" (click)="onViewSurroundingArchiveLogs(time)">
            <div class="text">
              <b>{{ time.caption }}</b>
              {{ time.timeUnit }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="item" (click)="viewRawLog()">
      <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/view-raw.svg"></svg-icon>
      <div class="text">VIEW RAW LOG</div>
    </div>

    <div class="item" (click)="copyPermalink()">
      <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/permalink.svg"></svg-icon>
      <div class="text">COPY PERMALINK</div>
    </div>

    <div class="item" (click)="openInfoPanel()">
      <mat-icon class="icon info-icon cgx-icon-secondary">info</mat-icon>
      <div class="text">OPEN INFO PANEL</div>
    </div>
    <ng-container *shAllowAbility="'highlightLogs'">
      <div class="item" (click)="highlightLog()" *ngIf="!params?.context?.parent?.isTemplates">
        <mat-icon class="icon info-icon cgx-icon-secondary">highlight</mat-icon>
        <div class="text">HIGHLIGHT LOG</div>
      </div>
    </ng-container>

    <div class="item" (click)="hideTemplate()" *ngIf="params?.node?.data?.templateId">
      <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/hide.svg"></svg-icon>
      <div class="text">HIDE TEMPLATE LOGS</div>
    </div>
  </div>
</div>

<ng-template #pluginMenu>
  <div class="plugins-sub-menu" (click)="$event.stopPropagation()">
    <cgx-input-field
      #searchPlugin
      style="margin: 0 8px 8px; font-weight: 600;"
      size="sm"
      srcIconEnd="assets/icons/xs-16x16/search.svg"
      placeholder="Search"
      autocomplete="off"
      (input)="searchPrivatePluginChange($event.value); searchSharedPluginChange($event.value)"
    ></cgx-input-field>
    <div *ngIf="!privatePlugins.length && !sharedPlugins.length" class="plugin-list-header">NO RESULTS</div>
    <div *ngIf="privatePlugins.length" class="plugin-list-header">PRIVATE</div>
    <div class="plugin-list">
      <div *ngFor="let plugin of privatePlugins" class="plugin-list-item" (click)="selectPlugin(plugin)">
        {{ plugin.name | uppercase }}
      </div>
    </div>
    <div *ngIf="sharedPlugins.length" class="plugin-list-header">SHARED</div>
    <div class="plugin-list">
      <div *ngFor="let plugin of sharedPlugins" class="plugin-list-item" (click)="selectPlugin(plugin)">
        {{ plugin.name | uppercase }}
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="plugin-list-item" (click)="openPluginsContainer()">MANAGE ACTIONS</div>
  </div>
</ng-template>

<sh-plugins-container #pluginsContainer></sh-plugins-container>
