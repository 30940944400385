<div style="position: relative">
  <nav>
    <ol>
      <a [routerLink]="['/dashboard']" style="margin-left: 0;">
        <img src="assets/coralogix-logo-white.svg" class="logo-lg" />
        <img src="assets/logo-white.svg" class="logo-sm" />
      </a>
      <a [routerLink]="['/dashboard']" routerLinkActive="active" tooltip="Dashboard" flow="down">
        <img src="assets/menu-icons/icon-dashboard.svg" alt="icon" class="desktop-navbar-icon" />
        <span>Dashboard</span>
      </a>
      <a [routerLink]="['/query-new']" routerLinkActive="active" tooltip="Logs" flow="down">
        <img src="assets/menu-icons/icon-logs.svg" alt="icon" class="desktop-navbar-icon" />
        <span>Logs</span>
      </a>
      <a [routerLink]="['/insights']" routerLinkActive="active" tooltip="Insights" flow="down">
        <img src="assets/menu-icons/icon-insight.svg" alt="icon" class="desktop-navbar-icon" />
        <span>Insights</span>
      </a>
      <a *ngIf="isUserHasAlertPermissions" [routerLink]="['/alerts']" routerLinkActive="active" tooltip="Alerts" flow="down">
        <img src="assets/menu-icons/icon-alerts.svg" alt="icon" class="desktop-navbar-icon" />
        <span>Alerts</span>
      </a>
      <a [routerLink]="['/livetail']" routerLinkActive="active" tooltip="LiveTail" flow="down">
        <img src="assets/menu-icons/icon-tail.svg" style="width: 12px" alt="icon" class="desktop-navbar-icon" />
        <span>LiveTail</span>
      </a>
      <a *ngIf="isUserHasAlertPermissions" [routerLink]="['/tags']" routerLinkActive="active" tooltip="Tags" flow="down">
        <img src="assets/menu-icons/icon-tags.svg" style="width: 20px" alt="icon" class="desktop-navbar-icon" />
        <span>Tags</span>
      </a>
      <a
        #dataFlowTooltipRef="cgxTooltip"
        class="dropdown-menu-label"
        tooltip="Data Flow"
        flow="down"
        routerLinkActive="active"
        hoverable
        [class.active]="checkDataFlowActivated()"
        [top]="-4"
        [cgxTooltip]="dataFlowTooltip"
        [maxWidth]="400"
        [showDelay]="100"
        [layout]="false"
        [hideDelay]="100"
      >
        <img src="assets/icons/dataflow.svg" style="width: 20px" alt="icon" class="desktop-navbar-icon" />
        <span>
          Data Flow
          <svg-icon
            [src]="dataFlowTooltipRef.isShow ? 'assets/icons/dropdown-arrow-up.svg' : 'assets/icons/dropdown-arrow-down.svg'"
          ></svg-icon>
        </span>
      </a>
      <ng-template #dataFlowTooltip>
        <div class="top-dropdown-menu">
          <div class="triangle"></div>
          <sh-data-flow-routes
            [isAdmin]="isAdmin"
            [isReadOnly]="isReadOnly$ | async"
            [username]="username$ | async"
            (closeTooltip)="closeTooltip()"
          ></sh-data-flow-routes>
        </div>
      </ng-template>
    </ol>
    <div class="right-flex">
      <div class="kibana-icon-container">
        <a [routerLink]="['/kibana']">
          <img src="assets/menu-icons/kibana-white.svg" alt="icon" class="desktop-navbar-icon" />
        </a>
        <!--        <a #appsTooltipRef="cgxTooltip"-->
        <!--           style="margin-inline-end: 16px;"-->
        <!--           tooltip="Apps"-->
        <!--           flow="down"-->
        <!--           hoverable-->
        <!--           [top]="8"-->
        <!--           [cgxTooltip]="appsTooltip"-->
        <!--           [maxWidth]="400"-->
        <!--           [showDelay]="100"-->
        <!--           [layout]="false"-->
        <!--           [hideDelay]="100">-->
        <!--          <span>-->
        <!--          Apps-->
        <!--          <svg-icon-->
        <!--            [src]="appsTooltipRef.isShow ? 'assets/icons/dropdown-arrow-up.svg' : 'assets/icons/dropdown-arrow-down.svg'"-->
        <!--          ></svg-icon>-->
        <!--        </span>-->
        <!--        </a>-->
        <!--        <ng-template #appsTooltip>-->
        <!--          <div class="top-dropdown-menu">-->
        <!--            <div class="triangle"></div>-->
        <!--            <div>-->
        <!--              <a-->
        <!--                class="menu-dropdown-link"-->
        <!--                routerLinkActive="menu-dropdown-link-active"-->
        <!--                [routerLink]="['/kibana']"-->
        <!--                (click)="closeTooltip()"-->
        <!--              >-->
        <!--                <svg-icon style="margin-inline-end: 4px;" src="assets/icons/xs-16x16/kibana-logo.svg"></svg-icon>-->
        <!--                Kibana-->
        <!--              </a>-->
        <!--              <div class="apps-coming-soon">More Coming Soon</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </ng-template>-->
        <div *ngIf="showUserSettingsFilters" style="margin-top: 2px">
          <sh-user-settings-filters></sh-user-settings-filters>
        </div>
        <div class="avatar-initials-wrapper" #userMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu">
          <sh-avatar-initials size="sm" [name]="username"></sh-avatar-initials>
        </div>
      </div>
    </div>
  </nav>
</div>
<sh-loading-bar></sh-loading-bar>
<mat-menu #userMenu>
  <sh-user-menu
    (click)="$event.stopPropagation()"
    [isOpened]="userMenuTrigger.menuOpen"
    (close)="userMenuTrigger.closeMenu()"
  ></sh-user-menu>
</mat-menu>
