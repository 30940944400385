<sh-coralogix-sidebar #sidebarComponent [templateRef]="editor"></sh-coralogix-sidebar>
<ng-template #editor>
  <sh-metrics-editor
    [form]="form"
    [logsSeverityOptions]="logsSeverityOptions"
    [isInEditMode]="isEditMode"
    [optionalGroupByFields]="optionalFields$ | async"
    [editedMetric]="editedMetric"
    [validationError]="validationTextError"
    [dbConflictError]="dbConflictError"
    [companyLabelLimit]="companyLabelLimit$ | async"
    (onSubmit)="submit($event)"
    (onDelete)="delete($event)"
  ></sh-metrics-editor>
</ng-template>
