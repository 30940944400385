<div>
  <div class="empty-list-header">
    <div class="header-content">
      <div class="icon-wrapper">
        <div class="icon-circle">
          <img src="assets/alerts/alert-empty.png" />
        </div>
      </div>
      <h3>You do not have any Logs2Metrics defined.</h3>
      <p>
        <span>Use Logs2Metrics to convert your log data to metrics on the fly, without storing the raw logs.</span>
        <br />
        <span>This will allow you to save up to 60% of cost, while getting 12 months retention for metrics.</span>
      </p>
      <div>
        <cgx-button label="NEW METRIC" size="lg" width="270px" (press)="openEditor()"></cgx-button>
      </div>
    </div>
  </div>
  <sh-logs-2-matrics-description></sh-logs-2-matrics-description>
</div>
