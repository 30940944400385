import { NgModule } from '@angular/core';
import { LongPressDirective } from '@shared/directives/long-press.directive';
import { AutoresizeDirective } from '@shared/directives/autoresize.directive';
import { TooltipComponent } from '@shared/controls/tooltip/tooltip.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from '@shared/directives/click-outside.module';

const directives = [
  LongPressDirective,
  AutoresizeDirective,
  TooltipDirective,
  TooltipComponent,
];

@NgModule({
  imports: [CommonModule, ClickOutsideModule],
  declarations: [...directives],
  exports: [...directives],
  entryComponents: [TooltipComponent],
})
export class DirectivesModule {}
