<mat-card class="coralogix">
  <p class="archive-card-header">Details</p>
  <form [formGroup]="form">
    <div class="coralogix input-group">
      <label class="input-label archive-card-input-label">Query Name</label>
      <div class="has-validation" [class.coralogix-input-error]="hasError">
        <input type="text" placeholder="Enter a name" class="form-control archive-query-input" formControlName="name" />
        <span *ngIf="hasError" class="error">
          Archive query name is required
        </span>
      </div>
    </div>
    <div class="coralogix input-group">
      <label class="input-label archive-card-input-label">Query Description</label>
      <div class="has-validation">
        <input type="text" placeholder="Enter a description" class="form-control archive-query-input" formControlName="description" />
      </div>
    </div>
  </form>
</mat-card>
