import { ColDef } from 'ag-grid';

import { GridCellHelper } from '@shared/controls/grid-cells/grid-cell-helper';
import { LoggregationGridCellHelper } from '../../shared/services/loggregation-grid-cell-helper';
import { TimestampCellComponent } from '@shared/controls/grid-cells';

export class LoggregationGridColumnDef {

  public static defaultColDefs: { [key: string]: ColDef; } = {
    'coralogix.timestamp': {
      headerName: 'Timestamp',
      field: 'timestamp',
      cellRendererFramework: TimestampCellComponent,
      width: 200,
      suppressFilter: true
    },
    'coralogix.metadata.severity': {
      headerName: 'Severity',
      field: 'metadata.severity',
      cellRenderer: GridCellHelper.severityCellRenderer,
      width: 100,
      suppressFilter: true
    },
    'coralogix.occurences': {
      headerName: 'Occurrences',
      field: 'count',
      width: 130,
      sort: 'desc',
      cellRenderer: LoggregationGridCellHelper.occurrencesCellrenderer
    },
    'coralogix.ratio': {
      headerName: 'Ratio',
      field: 'ratio',
      width: 100
    },
    'coralogix.text': {
      headerName: 'Text',
      field: 'text',
      width: 650,
      cellStyle: { 'text-align': 'left' },
      cellRenderer: LoggregationGridCellHelper.loggregationTextCellRenderer
    },
    'coralogix.normalRatio': {
      headerName: 'Normal Ratio',
      field: 'normalRatio',
      width: 100,
      cellRenderer(params: any): HTMLElement | string {
        return params.value ? (Math.round(params.value * 10000) / 100) + '%' : 0 + '%';
      }
    },
    'coralogix.currentRatio': {
      headerName: 'Current Ratio',
      field: 'anomalyRatio',
      width: 100,
      cellRenderer: LoggregationGridCellHelper.currentRatioCellRenderer
    },
    'coralogix.metadata.applicationName': {
      headerName: 'Application',
      field: 'metadata.applicationName',
      width: 100
    },
    'coralogix.metadata.subsystemName': {
      headerName: 'Subsystem',
      field: 'metadata.subsystemName',
      width: 100
    },
    'coralogix.metadata.category': {
      headerName: 'Category',
      field: 'metadata.category',
      width: 100
    },
    'coralogix.metadata.className': {
      headerName: 'Class',
      field: 'metadata.className',
      width: 100
    },
    'coralogix.metadata.methodName': {
      headerName: 'Method',
      field: 'metadata.methodName',
      width: 100
    },
    'coralogix.metadata.threadId': {
      headerName: 'ThreadId',
      field: 'metadata.threadId',
      width: 100
    },
    'coralogix.metadata.computerName': {
      headerName: 'Computer',
      field: 'metadata.computerName',
      width: 100
    },
  };

  public static createLoggregationColumnDefs(): ColDef[] {
    return [
      {
        headerName: '#',
        field: 'index',
        width: 50
      },
      this.defaultColDefs['coralogix.timestamp'],
      this.defaultColDefs['coralogix.metadata.severity'],
      this.defaultColDefs['coralogix.occurences'],
      this.defaultColDefs['coralogix.ratio'],
      this.defaultColDefs['coralogix.text'],
      this.defaultColDefs['coralogix.metadata.applicationName'],
      this.defaultColDefs['coralogix.metadata.subsystemName']
    ];
  }

  public static createAnomalyLoggregationColumnDefs(): ColDef[] {
    return [
      this.defaultColDefs['coralogix.metadata.severity'],
      this.defaultColDefs['coralogix.normalRatio'],
      this.defaultColDefs['coralogix.currentRatio'],
      this.defaultColDefs['coralogix.occurences'],
      this.defaultColDefs['coralogix.text'],
      this.defaultColDefs['coralogix.metadata.applicationName'],
      this.defaultColDefs['coralogix.metadata.subsystemName']
    ];
  }
}
