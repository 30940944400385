import { ITeam2 } from './team.interface';
import { ITeamSerialized2 } from './team.serialized';

export class Team2 implements ITeam2 {
  id: number;
  name: string;
  teamUrl: string;
  privateKey: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  isAuditTeamActive: boolean;

  constructor(obj: ITeam2) {
    this.id = obj.id;
    this.name = obj.name;
    this.teamUrl = obj.teamUrl;
    this.privateKey = obj.privateKey;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
    this.isActive = obj.isActive;
    this.isAuditTeamActive = obj.isAuditTeamActive;
  }

  public static deserializer(obj: ITeamSerialized2): Team2 {
    let team: Team2 = null;
    if (obj) {
      team = new Team2({
        id: obj.id,
        name: obj.name,
        teamUrl: obj.team_url,
        privateKey: obj.privateKey,
        createdAt: obj.created_at,
        updatedAt: obj.updated_at,
        isActive: obj.isActive,
        isAuditTeamActive: obj?.isAuditTeamActive,
      });
    }
    return team;
}
}