export interface IStripeCustomerDataResponse {
  brand: string;
  customerId: string;
  email: string;
  last4: string;
}

export interface IPlanInformationResponse {
  unitPrice: number;
  enabled: boolean;
  status: PlanInformationStatus;
  totalUsage: number;
  clientSecret: string;
  dailyUsage: number;
}

export enum PlanInformationStatus {
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  COMPLETED = 'COMPLETED',
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  MANUAL_CONFIGURATION_REQUIRED = 'MANUAL_CONFIGURATION_REQUIRED',
}

export interface ITogglePlan {
  enabled: boolean;
}

export interface IEegisterAndSubscribeStep1 {
  subscriptionId: string;
  clientSecret: string;
}
