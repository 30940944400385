import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../../../constants';
import { TypedJSON } from 'typedjson';
import { CompanyPlanStatus } from '../models/company-plan-status.model';
import { CompanyQueryRange } from '../models/company-query-range.model';
import { CompanyPlanDetails } from '../models/company-plan-details.model';
import { map } from 'rxjs/operators';

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient) {}

  public getCompanyPlanDetails(): Observable<CompanyPlanDetails> {
    const headers = new HttpHeaders().set('check-trial-plan', 'true');
    return this.http.get(Constants.companyPlanDetailsUrl, { headers }).map((res: any) => new TypedJSON(CompanyPlanDetails).parse(res));
  }

  public getCompanyPlanStatus(): Observable<CompanyPlanStatus> {
    return this.http.get(Constants.companyUrl).pipe(map((res: any) => new TypedJSON(CompanyPlanStatus).parse(res)));
  }

  public getCompanyQueryRange(): Observable<CompanyQueryRange> {
    return this.http.get(Constants.companyQueryRangeUrl).map((res: any) => new TypedJSON(CompanyQueryRange).parse(res));
  }

  public getCompanyLogo(teamName: string): Observable<string> {
    return this.http
      .get(`${Constants.companyLogoUrl}/${teamName}`, { responseType: 'arraybuffer' })
      .map((res: ArrayBuffer) => {
        const typedArr = new Uint8Array(res);
        const stringCharImg = typedArr.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');

        return btoa(stringCharImg);
      })
      .catch(() => of(''));
  }

  public snooze(): any {
    return this.http.post(Constants.companySnoozeUrl, '').map((res: any) => res);
  }
}
