import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

export enum widgetsShowInOptions {
  dashboard = 'defaultGroup',
  tagsPage = 'tags'
}

@jsonObject
export class Widget {
  @jsonMember
  public id: number;
  @jsonMember
  public isActive: number;
  @jsonMember
  public title: string;
  @jsonMember
  public description: string;
  @jsonMember
  public url: string;
  @jsonMember
  public query: string;
  @jsonMember
  public group: string = widgetsShowInOptions.dashboard;
  @jsonMember
  public isShared: boolean;
  @jsonMember
  public amount: number;
  @jsonMember
  public timeInterval: number;
  public map: any;
}

export class WidgetRequest {
  public widget: Widget;

  constructor(widget: Widget) {
    this.widget = widget;
  }
}

@jsonObject
export class WidgetsResults {
  @jsonArrayMember(Widget)
  public widgets: Widget[];
}

export class NotSupportedWidget extends Widget {}
