import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-ng2/main';

@Component({
  selector: 'sh-timestamp-cell',
  template: '{{ parsedTimestamp | dateFormatByUserSettings }}',
  styles: [
    `
      :host {
        font-size: 12px;
      }
    `,
  ],
})
export class TimestampCellComponent implements AgRendererComponent {
  public parsedTimestamp: any;
  private params: any;

  public agInit(params: any): void {
    this.params = params;
    this.parseTimestamp();
  }

  private parseTimestamp(): any {
    if (this.params.value) {
      this.parsedTimestamp = this.params.value;
    }
  }
}
