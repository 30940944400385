import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Injectable, OnDestroy } from '@angular/core';
import { MetricEditorControls } from '@app/settings/metrices-settings/models/metric-editor-controls';
import { FieldExtraction, Metric } from '@app/settings/metrices-settings/models/metric.model';
import _ from 'lodash';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '@app/constants';
import { MetricsStoreService } from '@app/settings/shared/services/metrics-store.service';
import { ConditionalSelectService } from '@app/shared/components/conditional-select/conditional-select.service';

@Injectable()
export class MetricEditorForm implements OnDestroy {
  private _permutationsLeftSnapshot: number;
  private destroyed$ = new Subject<void>();

  public get permutationsLeft(): number {
    return this._permutationsLeftSnapshot;
  }

  public get selectedMetricPermutationLimit(): number {
    return this.metricsStoreService.selectedMetric$?.getValue()?.permutationsLimit || 0;
  }

  public get defaultPermutationLimit(): number {
    return Constants.DEFAULT_METRIC_PERMUTATIONS_LIMIT <= this.permutationsLeft
      ? Constants.DEFAULT_METRIC_PERMUTATIONS_LIMIT
      : this.permutationsLeft;
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private metricsStoreService: MetricsStoreService,
    private conditionalSelectService: ConditionalSelectService,
  ) {
    this.metricsStoreService.permutationsLeft$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((permutationsLeft) => (this._permutationsLeftSnapshot = permutationsLeft));
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get defaultValues(): MetricEditorControls {
    return new MetricEditorControls({
      metricName: '',
      description: '',
      applicationName: null,
      subsystemName: null,
      logSeverity: null,
      text: null,
      groupBy: null,
      metricFields: null,
      permutationsLimit: this.defaultPermutationLimit,
    });
  }

  public buildForm(value: MetricEditorControls): FormGroup {
    return this.fb.group({
      details: this.buildDetailsGroup(value),
      query: this.buildQueryGroup(value),
      groupBy: this.buildMetricSelectionGroup(value, 'groupBy'),
      metricFields: this.buildMetricSelectionGroup(value, 'metricFields'),
      permutationsLimit: this.fb.control(value?.permutationsLimit, [
        this.validatePermutationAmount.bind(this),
        Validators.min(0),
        Validators.required,
      ]),
    });
  }

  public buildControlsFromMetric(metric: Metric): MetricEditorControls {
    return new MetricEditorControls({
      metricName: metric.name,
      description: metric.description,
      applicationName: this.conditionalSelectService.mapToFormData(metric.applicationName),
      subsystemName: this.conditionalSelectService.mapToFormData(metric.subsystem),
      logSeverity: metric.severity,
      text: metric.text,
      groupBy: metric.groupBy,
      metricFields: metric.metricFields,
      permutationsLimit: metric.permutationsLimit,
    });
  }

  public mainFormValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const queryGroup = control.controls['query'];
    const isControlEmpty = this.isControlEmpty(queryGroup as FormGroup);
    return !queryGroup.touched && isControlEmpty ? { 'missing values': true } : null;
  }

  public queryGroupValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    return control.touched && this.isControlEmpty(control) ? { 'missing values': true } : null;
  }

  private isControlEmpty(control: FormGroup): boolean {
    const entries = Object.entries(control.controls);
    const nonEmptyEntries = entries.filter(([key, formControl]: [string, any]) => {
      return formControl && formControl.value && formControl.value.length > 0;
    });
    return nonEmptyEntries.length < 1;
  }

  private buildDetailsGroup(value: MetricEditorControls): FormGroup {
    return this.fb.group({
      metricName: [value.metricName, Validators.required],
      description: [value.description],
    });
  }

  private validatePermutationAmount(permutationAmountControl: FormControl): null | { [err: string]: boolean } {
    if (!permutationAmountControl) {
      return null;
    }
    const groupByFields = permutationAmountControl?.parent?.value?.groupBy?.fields;
    const hasLabels = !!groupByFields?.length && (!!groupByFields[0].fieldPath || !!groupByFields[0].name);
    const errors: { [err: string]: boolean } = {};
    const permutationAmount = Number(permutationAmountControl.value);
    const permutationLimit = this.permutationsLeft + this.selectedMetricPermutationLimit;
    errors.permutationAmountExceeded = permutationAmount > permutationLimit;
    errors.lessThanOne = hasLabels && permutationAmount < 1;
    const isValid = Object.keys(errors).every((k) => !errors[k]);
    return isValid ? null : errors;
  }

  private buildQueryGroup(value: MetricEditorControls): FormGroup {
    return this.fb.group({
      applicationName: this.conditionalSelectService.createFormFromData(value.applicationName),
      subsystemName: this.conditionalSelectService.createFormFromData(value.subsystemName),
      logSeverity: [value.logSeverity],
      text: [value.text],
    });
  }

  private buildMetricSelectionGroup(value: MetricEditorControls, propName: string): FormGroup {
    let fieldsArray = [];
    if (!_.isEmpty(value) && !_.isEmpty(value[propName])) {
      fieldsArray = value[propName].map(this.createFormGroupForExtraction.bind(this));
    }

    return this.fb.group({
      fields: this.fb.array(fieldsArray, (form: FormControl) => {
        const permutationLimitControl = form?.parent?.parent?.controls?.['permutationsLimit'] as FormControl;
        permutationLimitControl?.updateValueAndValidity();
        return null;
      }),
    });
  }

  private createFormGroupForExtraction(extraction: FieldExtraction): FormGroup {
    return this.fb.group({
      name: this.fb.control(extraction.name),
      fieldPath: this.fb.control(extraction.fieldPath),
    });
  }
}
