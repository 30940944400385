import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllowAbilityDirective } from '@app/logs-new/shared/directives/allow-ability.directive';

@NgModule({
  declarations: [
    AllowAbilityDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [AllowAbilityDirective]
})
export class AllowAbilityModule {}
