import {
  EmailFilterDefinition,
  EmailFilterModel,
} from '../shared/models/emailFilterModel';
import { SettingsActions } from './settings.actions';
export namespace SettingsReducer {
  export interface State {
    emailFilters: EmailFilterDefinition[];
    loading: boolean;
    saving: boolean;
    email_filter_id: number;
  }
  const initialState: State = {
    emailFilters: [],
    loading: false,
    saving: false,
    email_filter_id: 0,
  };

  export function reducer(
    state: State = initialState,
    action: SettingsActions.Actions,
  ): State {
    switch (action.type) {
      case SettingsActions.ActionTypes.GET_EMAIL_FILTERS:
        return Object.assign({}, state, { loading: true });
      case SettingsActions.ActionTypes.GET_EMAIL_FILTERS_COMPLETED:
        let filterDef = [];
        let filterId;
        if (action.payload) {
          filterDef = action.payload.email_filter_definitions;
          filterId = action.payload.email_filter_id;
        }
        return Object.assign({}, state, {
          loading: false,
          emailFilters: filterDef,
          email_filter_id: filterId,
        });
      case SettingsActions.ActionTypes.GET_EMAIL_FILTERS_FAILED:
        return Object.assign({}, state, { loading: false });
      case SettingsActions.ActionTypes.ADD_EMAIL_FILTER:
        return Object.assign({}, state, { saving: true });
      case SettingsActions.ActionTypes.ADD_EMAIL_FILTER_COMPLETED:
        return Object.assign({}, state, {
          saving: false,
          loading: false,
          emailFilters: [...state.emailFilters, action.payload],
        });
      case SettingsActions.ActionTypes.DELETE_EMAIL_FILTER_COMPLETED:
        const filterIndex = state.emailFilters
          .map((t) => t.id)
          .indexOf(action.payload);
        return Object.assign({}, state, {
          emailFilters: [
            ...state.emailFilters.slice(0, filterIndex),
            ...state.emailFilters.slice(filterIndex + 1),
          ],
        });
      case SettingsActions.ActionTypes.EDIT_EMAIL_FILTER_COMPLETED:
        const editIndex = state.emailFilters
          .map((t) => t.id)
          .indexOf(action.payload.id);
        return Object.assign({}, state, {
          emailFilters: [
            ...state.emailFilters.slice(0, editIndex),
            action.payload,
            ...state.emailFilters.slice(editIndex + 1),
          ],
        });
      default:
        return state;
    }
  }
}
