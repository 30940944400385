import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';

interface ISnackbarData {
  message: string;
  link?: string;
  iconClass: dialogServiceIconClasses;
}

@Component({
    selector: 'sh-coralogix-snackbar',
    templateUrl: './coralogix-snackbar.component.html',
    styleUrls: ['./coralogix-snackbar.component.scss'],
})
export class CoralogixSnackbarComponent {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData,
        private _snackRef: MatSnackBarRef<CoralogixSnackbarComponent>,
    ) { }

    public onClose(): void {
        this._snackRef.dismiss();
    }
}
