import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from 'lodash-es';

@Pipe({
  name: 'searchHighlight',
})
export class SearchHighlightPipe implements PipeTransform {
  public transform(value: string, args: string): string {
    if (!args) {
      return value;
    }
    const re = new RegExp(escapeRegExp(args), 'gi');
    return value.replace(re, '<b>$&</b>');
  }
}
