import { Log } from './log.entity';
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

@jsonObject
export class LogqueryResult {
  @jsonArrayMember(Log)
  public logs: Log[] = [];
  @jsonMember
  public total: number;
  @jsonMember
  public status: number;
  @jsonArrayMember(String)
  public templateIds: string[] = [];
}
