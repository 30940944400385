export enum InsightTypeId {
  Alert = 1,
  Anomaly,
  AlertGroupByFields,
  AlertRelative,
  MetricAlert = 6
}

export enum AnomalySubTypeId {
  FlowAnomaly = 2,
  VolumeAnomaly = 3,
}

export enum InsightSeverity {
  Info = 1,
  Warning,
  Critical,
}

export enum AggregationType {
  Hourly,
  Daily,
}

export enum AlertType {
  ByText = 1,
  ByTemplate = 2,
}
