<div class="input-container">
  <div class="hours time-section">
    <sh-coralogix-time-input-section
      [hasValidationError]="hasValidationError"
      [disabled]="disableCtrl"
      #hoursInput
      [maxValue]="23"
      [ngModel]="hours"
      (ngModelChange)="onHoursChange($event)"
      (twoDigitsInput)="onHoursKeyUp()"
    >
    </sh-coralogix-time-input-section>
    <span class="divider">:</span>
  </div>
  <div class="minutes time-section" >
    <sh-coralogix-time-input-section
      [hasValidationError]="hasValidationError"
      [disabled]="(disableCtrl || !showMinutes)"
      #minutesInput
      [maxValue]="59"
      [ngModel]="minutes"
      (ngModelChange)="onMinutesChange($event)"
      (twoDigitsInput)="onMinutesKeyup()"
    >
    </sh-coralogix-time-input-section>
  </div>
  <div class="seconds time-section" *ngIf="showSeconds">
    <span class="divider">:</span>
    <sh-coralogix-time-input-section
      [hasValidationError]="hasValidationError"
      [disabled]="disableCtrl"
      #secondsInput
      [maxValue]="59"
      [ngModel]="seconds"
      (ngModelChange)="onSecondsChange($event)"
    >
    </sh-coralogix-time-input-section>
  </div>
</div>
