<div class="container">
  <div class="content">
    <div class="title">Occurrences</div>
    <div class="pin-graph" (click)="pinGraph.emit()">
      <svg-icon src="assets/icons/pin-graph.svg" svgClass="cgx-icon-primary" class="icon"></svg-icon>
      <div class="pin-graph-title">PIN GRAPH</div>
    </div>
  </div>

  <mat-divider class="divider"></mat-divider>

  <div class="no-data" *ngIf="!chartData">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>

  <div class="chart-container">
    <div class="chart" #chart></div>
  </div>
</div>
