export interface IPredefinedQuickTime {
  caption: string;
  seconds: number;
}

export class SnoozeQuickTimes {
  public predefinedQuickTimes: IPredefinedQuickTime[] = [
    {
      caption: '30 Minutes',
      seconds: 1800,
    },
    {
      caption: '1 Hours',
      seconds: 3600,
    },
    {
      caption: '3 Hours',
      seconds: 10800,
    },
    {
      caption: 'Tomorrow',
      seconds: 86400,
    },
  ];
}
