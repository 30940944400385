import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { MetricEditorActions } from '@app/settings/metrices-settings/state/actions/metric-editor.actions';

@Component({
  selector: 'sh-empty-metrics',
  templateUrl: './empty-metrics.component.html',
  styleUrls: ['./empty-metrics.component.scss']
})
export class EmptyMetricsComponent implements OnInit {

  constructor(private store: Store<State>) { }

  public ngOnInit(): void {
  }

  public openEditor() {
    this.store.dispatch(new MetricEditorActions.ToggleEditorAction(true));
  }
}
