import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { TimeZoneType } from '@shared/models/timezone-types';
import * as settingsActions from './user-settings.actions';
import { DateFormatType } from '@shared/models/date-format-types';

export interface IUserSettingsReducerState {
  timeZoneViewType: TimeZoneType;
  dateFormatType: DateFormatType;
}

const initialState: IUserSettingsReducerState = {
  timeZoneViewType: TimeZoneType.local,
  dateFormatType: DateFormatType.european
};

const userSettingsReducer: ActionReducer<IUserSettingsReducerState, Action> = createReducer(
  initialState,
  on(settingsActions.SetUserTimezoneView, (state, { payload }) => ({
    ...state,
    timeZoneViewType: payload.value,
  })),
  on(settingsActions.SetUserTimezoneViewFailed, (state, { payload }) => ({
    ...state,
    timeZoneViewType: payload,
  })),
  on(settingsActions.SetUserDateFormatView, (state, { payload }) => ({
    ...state,
    dateFormatType: payload.value,
  })),
  on(settingsActions.SetUserDateFormatViewFailed, (state, { payload }) => ({
    ...state,
    dateFormatType: payload,
  })),
  on(settingsActions.SetUserSettingsFromResolver, (state, { payload }) => {
    return {
    ...state,
    timeZoneViewType: payload.timeZoneViewType,
    dateFormatType: payload.dateFormatType
  };
  }),
);

export function reducer(state: IUserSettingsReducerState = initialState, action: Action): IUserSettingsReducerState {
  return userSettingsReducer(state, action);
}
