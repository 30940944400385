<div *ngFor="let anomalyDetailItem of AnomalyItems; let last = last">
    <div
            class="loggregation-item-container"
            (click)="onItemCliecked(anomalyDetailItem)"
            [class.anomaly-selected-item]="anomalyDetailItem.isSelected"
            [class.loggregation-last-item]="last"
    >
        <div class="loggregation-item-info">
            <div class="ui equal width grid column">
                <div class="row large">
                    <div
                            (click)="onItemCliecked(anomalyDetailItem)"
                            class="column loggregation-item-column"
                    >
            <span
                    [ngClass]="severityColor(anomalyDetailItem.anomalyDetail.metadata.severityName.toLowerCase())"
            >
              {{ anomalyDetailItem.anomalyDetail.metadata.severityName }}
            </span>
                    </div>
                </div>
                <div class="row large">
                    <div class="column loggregation-item-column">
                        <div
                                class="loggregation-count-item"
                                (click)="onCountCliecked(loggragationItem, anomalyDetailItem)"
                        >
                            {{ anomalyDetailItem.anomalyDetail.count | numbersFormat }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                    class="loggregation-ration-item anomolus"
                    *ngIf="anomalyDetailItem.anomalyDetail.isAnomalous"
            >
                {{
                math.abs(
                    anomalyDetailItem.anomalyDetail.count -
                    anomalyDetailItem.normalCount
                ) | numbersFormat
                }}
                times
                {{
                anomalyDetailItem.anomalyDetail.count - anomalyDetailItem.normalCount
                    | aboveBelowNormal
                }}
                range
            </div>
        </div>
        <div class="loggregation-item-chart">
            <sh-basic-chart [model]="anomalyDetailItem.chartModel"></sh-basic-chart>
        </div>
        <div
                class="loggregation-item-content"
                (click)="onItemCliecked(anomalyDetailItem)"
        >
            <div class="loggregation-item-text">
                <sh-loggregation-item
                    #loggragationItem
                    [queryModel]="queryModel"
                    [text]="anomalyDetailItem.anomalyDetail"
                ></sh-loggregation-item>
            </div>
            <div class="loggregation-metadata-row">
        <span class="loggregation-item-metadata">
          {{ anomalyDetailItem.anomalyDetail.metadata.applicationName }}
        </span>
                <span class="loggregation-item-metadata">
          {{ anomalyDetailItem.anomalyDetail.metadata.subsystemName }}
        </span>
            </div>
        </div>
    </div>
</div>
