import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { ConfigurationService } from '@app/services/configuration.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private configService: ConfigurationService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (state.url !== '/login' && !this.authService.isLoggedIn()) {
      this.authService.redirectTo = state.url;
      const hostname = window.location.hostname.split('.');
      if (hostname.length > 1 && window.location.host !== this.configService.getConfigValue('defaultHost', '')) {
        window.location.href = this.configService
          .getConfigValue('userLoginRedirectUrl', '')
          .replace('%s', hostname[0].replace('__test', ''));
      } else {
        window.location.href = this.configService.getConfigValue('authGuardRedirectUrl', '');
      }
      this.router.navigate(['/login']).then();
      return false;
    } else if (window.location.host === this.configService.getConfigValue('defaultHost', '')) {
      this.router.navigate(['/login']).then();
      return false;
    }
    return true;
  }
}
