import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-widget-loader',
  templateUrl: './widget-loader.component.html',
  styleUrls: ['./widget-loader.component.scss']
})
export class WidgetLoaderComponent {
  @Input() public addInvertedClass: boolean = false;
}
