import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ChartClickParams, ChartModel, ChartSeriesModel, PlotlineModel} from '../../../shared/controls/charts/models/chart.model';
import {Alert} from '../../shared/models/alert.model';
import {InsightTypeId} from '../../shared/models/enums';
import {ConditionsOptions} from '@app/alerts/alerts-editor/models/alert-editor-consts';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {last} from 'lodash';
import {InsightsHelper} from '../../shared/helpers/insightsHelper';

interface IFieldBreakdown {
  aggregations: string[];
  hitCount: number;
  isOverThreshold: boolean;
}

@Component({
  selector: 'sh-insights-hit-count',
  templateUrl: './insights-hit-count.component.html',
  styleUrls: ['./insights-hit-count.component.scss'],
})
export class InsightsHitCountComponent implements OnChanges, AfterViewInit {
  @Input()
  private set groupByFieldsAlert(alert: Alert) {
    if (alert) {
      this.isMultiLevelGroupBy = InsightsHelper.isMultiLevelGroupBy(alert);
      this.isRelative = alert.typeId === InsightTypeId.AlertRelative;
      this._groupByFieldsAlert = alert;
      if (this.isMultiLevelGroupBy) {
        this.fieldBreakdownColumns = InsightsHelper.getMultiLevelGroupByFieldNames(this.groupByFieldsAlert);
        this.displayedColumns = [...this.fieldBreakdownColumns, 'hitCount'];
        this.fieldBreakdown = this.getFieldBreakdownTableData();
        this.dataSource = new MatTableDataSource(this.fieldBreakdown);
        this.multilevelGroupByFields = this.fieldBreakdownColumns.join(' / ');
        this.setSortingData();
      }
    }
  }

  private get groupByFieldsAlert(): Alert {
    return this._groupByFieldsAlert;
  }

  public get severity(): number {
    return this._groupByFieldsAlert?.severity;
  }

  @Output() public filterSelected: EventEmitter<ChartClickParams> = new EventEmitter<ChartClickParams>();
  @Output() public filterCleared: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;

  public chartModel: ChartModel;
  public relativeCalcChartModel: ChartModel;
  public hitCountField: string;
  public isFiltered: boolean;
  public displayedColumns: string[];
  public dataSource: MatTableDataSource<IFieldBreakdown> = new MatTableDataSource([]);
  public isMultiLevelGroupBy: boolean = false;
  public multilevelGroupByFields?: string;
  public isRelative: boolean = false;
  public fieldBreakdownColumns: string[] = [];
  private _groupByFieldsAlert: Alert;
  private fieldBreakdown: IFieldBreakdown[] = [];

  private severityColors: Map<number, string> = new Map([
    [1, 'rgba(50, 189, 244, 0.7)'],
    [2, 'rgba(245, 195, 69, 0.7)'],
    [3, 'rgba(212, 35, 187, 0.7)'],
  ]);

  ngAfterViewInit(): void {
    this.setSortingData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.chartModel = this.initChartModel();
    this.relativeCalcChartModel = this.initChartModel(true);
  }

  public getFieldBreakdownTableData(): IFieldBreakdown[] {
    const threshold = this.groupByFieldsAlert.conditionThreshold;
    const isOverThreshold = (hitCount: number) => {
      if (this.groupByFieldsAlert.conditionOperator === ConditionsOptions.more) {
        return hitCount > threshold;
      }
      if (this.groupByFieldsAlert.conditionOperator === ConditionsOptions.less) {
        return hitCount < threshold;
      }
      return false;
    };

    const traverseHits = (groupByFields, aggregationValues, hit) => {
      const [groupByField, ...nextFields] = groupByFields;
      const aggregationValue = hit[groupByField];
      if (nextFields.length === 0) {
        const row = {
          aggregations: [...aggregationValues, aggregationValue],
          hitCount: hit.hitCount,
          isOverThreshold: isOverThreshold(hit.hitCount),
        };
        return [row];
      } else {
        const newAggregationValues = [...aggregationValues, aggregationValue];
        return hit.nestedAggs.flatMap(currentAggregation => traverseHits(nextFields, newAggregationValues, currentAggregation));
      }
    };
    return this.groupByFieldsAlert.esInfo.hits.flatMap(currentAggregation =>
      traverseHits(this.fieldBreakdownColumns, [], currentAggregation),
    );
  }

  public conditionOperatorStr(): string {
    return '';
  }

  public initChartModel(isRelativeValues: boolean = false): ChartModel {
    this.hitCountField = this.getHitCountField();
    const chartModel = new ChartModel();
    chartModel.backgroundColor = 'transparent';
    chartModel.fontColor = 'var(--cgx-text-primary)';

    if (!this.groupByFieldsAlert.esInfo.hasHits()) {
      chartModel.title = 'No data to display';
      return chartModel;
    }

    if (this.isInfinite(isRelativeValues)) {
      chartModel.title = this.groupByFieldsAlert.esInfo.hits.calcRes;
      return chartModel;
    }

    chartModel.title = this.getChartTitle();
    chartModel.showTitle = false;
    chartModel.backgroundColor = 'transparent';
    chartModel.categories = this.getChartCategories(isRelativeValues);
    chartModel.fontColor = '#666';
    chartModel.inverted = true;
    chartModel.scale = 'm';
    chartModel.yAxisEnable = true;
    chartModel.yAxisAllowDecimals = false;
    chartModel.xType = 'category';
    chartModel.yType = 'linear';
    chartModel.stacking = null;
    chartModel.useLabels = true;
    chartModel.clickable = true;
    chartModel.generateLogQuery = false;
    chartModel.zoomType = 'None';
    chartModel.zoomable = false;
    chartModel.gridLineWidthY = 1;
    chartModel.plotBorderWidth = 1;
    chartModel.enableDataLabel = true;
    chartModel.dataLabelsInside = true;

    const chartSeries = new ChartSeriesModel();
    chartSeries.name = 'Hit Count';
    chartSeries.data = this.getChartData(isRelativeValues);
    chartSeries.color = '#5dade2';
    chartSeries.type = 'bar';
    chartSeries.innerSize = null;
    const plotLine = this.getPlotLine(isRelativeValues);
    if (plotLine !== null) {
      chartModel.yPlotlines.push(plotLine);
      if (isRelativeValues) {
        chartModel.maxYAxis = Math.max(
          chartSeries.data.map(v => v[1]),
          plotLine.value,
        );
        if (chartModel.maxYAxis === plotLine.value) {
          plotLine.label.x = -70;
        }
      } else {
        chartModel.yPlotBands = [
          {
            from: 0,
            to: plotLine.value,
            color: 'rgba(var(--cgx-rgb-on-tooltip) / 0.8)',
          },
        ];
      }
    }

    chartModel.series.push(chartSeries);
    return chartModel;
  }

  public onChartClicked(clickParams: ChartClickParams): void {
    this.isFiltered = true;
    this.filterSelected.emit(clickParams);
  }

  public clearFilter(): void {
    this.isFiltered = false;
    this.filterCleared.emit();
  }

  private setSortingData(): void {
    const sortingDataAccessor = (data: IFieldBreakdown, sortHeaderId: string) =>
      sortHeaderId === 'hitCount' ? data.hitCount : data.aggregations[this.fieldBreakdownColumns.indexOf(sortHeaderId)];
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
    this.dataSource.sort = this.sort;
  }

  private getHitCountField(): string {
    if (this.isRelative) {
      return '';
    }
    if (this.isMultiLevelGroupBy) {
      return last(InsightsHelper.getMultiLevelGroupByFieldNames(this.groupByFieldsAlert));
    }
    return Object.keys(this.groupByFieldsAlert.esInfo.hits)[0]; // currently supporting in just a single field
  }

  private getChartTitle(isRelativeValues: boolean = false): string {
    if (this.isRelative) {
      return 'ratio chart title ' + isRelativeValues ? 'values' : 'calc';
    }

    return this.hitCountField;
  }

  private getChartCategories(isRelativeValues: boolean = false): string[] {
    if (this.isRelative && isRelativeValues) {
      return ['Quotient'];
    } else if (this.isRelative) {
      return Object.keys(this.groupByFieldsAlert.esInfo.hits).filter(v => v !== 'calcRes');
    }
    if (this.isMultiLevelGroupBy) {
      return this.fieldBreakdown.map(field => field.aggregations.join(' / '));
    }
    return Object.keys(this.groupByFieldsAlert.esInfo.hits[this.hitCountField]);
  }

  private isInfinite(isRelativeValues: boolean = false): boolean {
    return this.isRelative && isRelativeValues && ['Infinity', '-Infinity'].includes(this.groupByFieldsAlert.esInfo.hits.calcRes);
  }

  private getChartData(isRelativeValues: boolean = false): any {
    if (this.isRelative) {
      return this.getChartCategories(isRelativeValues).map(value => [
        value,
        this.groupByFieldsAlert.esInfo.hits[value === 'Quotient' ? 'calcRes' : value],
      ]);
    }

    if (this.isMultiLevelGroupBy) {
      return this.fieldBreakdown.map(field => [field.aggregations.join(' / '), field.hitCount]);
    }

    return this.getChartCategories().map(value => [value, this.groupByFieldsAlert.esInfo.hits[this.hitCountField][value]]);
  }

  private getPlotLine(isRelativeValues: boolean = false): PlotlineModel {
    if (this.isRelative && !isRelativeValues) {
      return null;
    }
    const severityColor = this.severityColors.get(this.groupByFieldsAlert.severity);
    const plotLine = new PlotlineModel();
    plotLine.width = 2;
    plotLine.value = this.groupByFieldsAlert.conditions
      ? this.groupByFieldsAlert.conditions.conditionThreshold
      : this.groupByFieldsAlert.conditionThreshold;
    plotLine.zIndex = 200;
    plotLine.dashStyle = 'Solid';
    plotLine.color = severityColor;
    const randomLabelYPosition = 1;
    const randomLabelXPosition = -46;
    plotLine.label = {
      useHTML: true,
      text: 'Threshold',
      style: {
        color: 'white',
        backgroundColor: severityColor,
        fontWeight: 'bold',
        padding: '2px 16px',
        borderRadius: '8px',
      },
      y: randomLabelYPosition,
      x: randomLabelXPosition,
      rotation: 0,
    };
    return plotLine;
  }
}
