import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ShDialogService } from '@shared/services/dialogService';
import { Constants } from '@app/constants';
import { SamlService } from '@app/settings-common/shared/services/saml.service';
import { SamlBaseContainerComponent } from '@app/auth/shared/components/saml-base-container';
import { ConfigurationService } from '@app/services/configuration.service';

@Component({
  selector: 'sh-login-sso',
  templateUrl: './login-team.component.html',
  styleUrls: ['./login-team.component.scss'],
})
export class LoginTeamComponent extends SamlBaseContainerComponent implements OnInit {
  public loginSsoForm: FormGroup;

  constructor(
    protected router: Router,
    private location: Location,
    protected samlService: SamlService,
    protected dialogService: ShDialogService,
    private formBuilder: FormBuilder,
    private config: ConfigurationService
  ) {
    super(samlService, dialogService, router);
    this.loginSsoForm = formBuilder.group({
      teamUrl: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    const hostname = window.location.hostname.split('.');
    if (this.isHostContainsTeamName(hostname)) {
      const [teamName] = hostname;
      this.team_url = teamName;
      this.checkSaml(teamName);
    }
  }

  public submit(value: {teamUrl}): void {
    if (!this.loginSsoForm.valid) {
      return;
    }

    this.isLoading = true;

    this.team_url = value.teamUrl;
    this.checkSaml(this.team_url);
  }

  public continueToIdp(): void {
    window.location.href = this.config.getConfigValue('teamSamlRedirectUrl', '').replace(
      '%s',
      this.team_url,
    );
  }
}
