import { CompanyRequest } from '@app/auth/shared/models/activateUser.model';

export class GetCompany {
    public static readonly type: string = '[Company] Get Company';
}

export class GetCompanyPlanDetails {
    public static readonly type: string = '[Company] Get Company Plan Details';
}

export class GetCompanyPlanStatus {
    public static readonly type: string = '[Company] Get Company Plan Status';
}

export class GetCompanyPlanQueryRange {
    public static readonly type: string = '[Company] Get Company Plan Query Range';
}

export class SetCompany {
    public static readonly type: string = '[Company] Set Company';
    constructor(public companyInfo: CompanyRequest) { }
}
