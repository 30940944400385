<div class="grid-size-noTimeline">
  <sh-grid *ngIf="!isEmptyGrid; else emptyGrid"
           class="grid"
           (gridReadyEvent)="onGridReady($event)"
           [logsQueryData]="queryData"
           (dataLoadedEvent)="updateQueryLogCount($event)"
           (gridRowSelectedEvent)="onSelectedRow($event)"
           (textInfoClicked)="onGridButtonClick($event)"
           [searchProvider]="insightsProvider.searchProvider"
           [showGridFilters]="true">
  </sh-grid>
</div>

<ng-template #emptyGrid>
  <sh-empty-alert-logs-grid></sh-empty-alert-logs-grid>
</ng-template>
