import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../auth/shared/services/auth.service';
import { PaymentProvider } from '../../settings-common/shared/services/payment/payment.provider';
import { PaymentService } from '../../settings-common/shared/services/payment/payment.service';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard implements CanActivate {
  constructor(
    private router: Router,
    private paymentProvider: PaymentProvider,
    private authService: AuthService,
    private paymentService: PaymentService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isLoggedIn() || state.url.indexOf('login') !== -1) {
      return true;
    }
    // this.paymentService.unsubscribe().first().subscribe();

    const customerPlan$ = this.paymentService.getCustomerPlan();
    const company$ = this.authService.getCompany();
    const plans$ = this.paymentService.getPlans();

    // The payment provider already initiated with the user detail
    // no need to go to the server and retrieve the data
    if (this.paymentProvider.initialized) {
      // User is not paying and is not in the payment page
      if (state.url.indexOf('payment') === -1 && !this.paymentProvider.isUserPaying()) {
        this.router.navigate(['/settings/subscriptions/payment']);
      }
      return true;
    }

    return Observable.zip(customerPlan$, company$, plans$).pipe(
      take(1),
      map(result => {
        this.paymentProvider.subscribedPlan = _.isEmpty(result[0]) ? null : result[0];
        this.paymentProvider.companyId = _.isEmpty(result[1]) ? 0 : result[1].id;
        this.paymentProvider.plans = _.isEmpty(result[2]) ? null : result[2].data;
        this.paymentProvider.initialized = true;

        if (state.url.indexOf('payment') === -1 && !this.paymentProvider.isUserPaying()) {
          this.paymentProvider.setDefaultPlan();

          this.router.navigate(['/settings/subscriptions/payment']);
        }
        return true;
      }),
      catchError(err => {
        return of(false);
      }),
    );
  }
}
