import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Entity } from '@shared/models/entity.model';

@Component({
    selector: 'sh-alerts-details',
    templateUrl: './alerts-details.component.html',
    styleUrls: ['./alerts-details.component.scss'],
})
export class AlertsDetailsComponent {
    @Input()
    public form: FormGroup;
    @Input()
    public severityOptions: Entity[];

    public hasError(controlName: string, errorCode: string): boolean {
        const control = this.form.get(controlName);
        return control.hasError(errorCode) && control.touched;
    }

    public isSelectInvalid(controlName: string): boolean {
        const control = this.form.get(controlName);
        return !control.value && control.touched;
    }
}
