export class Entity {
    public id: number | string;
    public name: string;

    constructor(init?: Entity) {
        Object.assign(this, init);
    }
}

export interface ISelectEntity {
  id: number | string;
  name: string;
}

export interface IDropdownMenuEntity extends ISelectEntity {
  description?: string;
}
