import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  EventEmitter,
  OnDestroy, HostBinding,
} from '@angular/core';
import { LivetailEntity } from '../shared/models/livetailEntity';
import { AmPmMomentOptions } from '@shared/helpers/moment.helper';


@Component({
  selector: 'sh-livetail-terminal-container',
  templateUrl: './livetail-terminal-container.component.html',
  styleUrls: ['./livetail-terminal-container.component.scss'],
})
export class LivetailTerminalContainerComponent implements OnInit, OnDestroy {

  @Input() public livetailLogs: Array<LivetailEntity> = new Array<LivetailEntity>();
  @Input() public scrollItem: EventEmitter<any>;
  @Input() public keysToFilter: string[];
  @Input() public isPrettified: boolean;
  @Input() public searchQuery: string;
  @Input() public isTerminalMode: boolean = true;
  public ampmBigLettersOption: AmPmMomentOptions = AmPmMomentOptions.bigLetters;
  @ViewChild('scrollMe', { static: true })
  private myScrollContainer: ElementRef;

  @HostBinding('style.height') public hostHeight: string;
  public ngOnInit(): void {
    this.addStyle();
    if (this.scrollItem) {
      this.scrollItem.subscribe(() => {
        setTimeout(() => {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        }, 100);
      });
    }
  }

  public addStyle(): void {
    if (!this.isTerminalMode) {
      this.hostHeight = '100%'
    }
  };
  public ngOnDestroy(): void {
    if (this.scrollItem) {
      this.scrollItem.unsubscribe();
    }
  }
}
