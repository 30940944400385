<div class="selected-tag-box">
  <div class="selected-tag-box__title">Error Anomalies</div>
  <mat-card class="selected-tag-box__card top-errors-card">
    <div class="top-error-container">
      <sh-suspected-template-widget
        [queryModel]="logQueryModel"
        [maxResult]="3"
        [theme]="'light'"
        [enableLoading]="true"
        (viewState)="changeReadyState($event)"
        [addInvertedClassToLoader]="true">
      </sh-suspected-template-widget>
      <div *ngIf="viewState$ === completedViewState" class="template-button-wrapper">
        <cgx-button
          label="VIEW ALL TAG ANOMALIES"
          size="md"
          textNoWrap
          [outline]="true"
          (press)="redirectToInsightsPage()"
        ></cgx-button>
      </div>
    </div>
  </mat-card>
</div>
