<div style="height: 100%; width: 100%">
  <div
    id="container"
    #progressContainer
    style="position: absolute;left: 50%;top: 51%;z-index: 1;
    transform:translateX(-50%) translateY(-51%);width: 160px;"
  ></div>
  <div
    style="position: absolute;left: 50%;top: 48%;z-index: 12;
    transform:translateX(-50%) translateY(-48%);margin-top: 15px;"
  >
    <div style="display: block;text-align: center;">
      <div
        matTooltipPosition="above"
        style="line-height: 26px; vertical-align: middle;"
        matTooltip="High Severity logs percentage"
      >
        <div style="font-size: 24px;">
          {{
          (
            _statisticsTrend?.statisticsAfterTag?.highSeverityPercent * 100
          ).toFixed(1)
          }}%
        </div>
      </div>

      <div class="secondary-caption">
        of all logs
      </div>
      <div style="line-height: 28px; margin-top: 15px;">
        <div class="trendLabel" style="display: -webkit-inline-box; ">
          <i
            class="fa fa-arrow-up trend-none"
            style="margin-right: 5px;"
            [class.trend-down]="_statisticsTrend.highSeverityTrend * 100 <= 0"
            [class.trend-up]="_statisticsTrend.highSeverityTrend * 100 > 0"
            aria-hidden="true"
          ></i>
          <div
            *ngIf="_statisticsTrend.highSeverityTrendAbs * 100 >= 0.1"
            matTooltipPosition="above"
            [matTooltip]="
              _statisticsTrend.highSeverityTrend > 0
                ? 'Increase in high severity logs since tag'
                : 'Decrease in high severity logs since tag'
            "
          >
            <div>
              <span>
                {{ (_statisticsTrend.highSeverityTrend * 100).toFixed(1) }}%
              </span>
            </div>
          </div>
          <div
            *ngIf="_statisticsTrend.highSeverityTrendAbs * 100 < 0.1"
            matTooltipPosition="above"
            matTooltip="No significant change in high severity logs since tag"
          >
            0.1% <
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="severity-indicator-container">
    <div class="severity-indicator">
      <div class="label">
        {{
        _statisticsTrend.statisticsAfterTag.totalCriticalSeverityLogs
          | numbersWithKFormat
        }}
      </div>
      <div class="secondary-label critical">
        Critical
      </div>
    </div>
    <div class="severity-indicator">
      <div class="label">
        {{
        _statisticsTrend.statisticsAfterTag.totalErrorSeverityLogs
          | numbersWithKFormat
        }}
      </div>
      <div class="secondary-label error">
        Error
      </div>
    </div>
    <div class="severity-indicator ">
      <div class="label">
        {{
        _statisticsTrend.statisticsAfterTag.totalWarningSeverityLogs
          | numbersWithKFormat
        }}
      </div>
      <div class="secondary-label warningC">
        Warning
      </div>
    </div>
  </div>
</div>
