<div fxLayout="column" fxLayoutAlign="space-between center" class="summary-tile">
  <div
    class="summary-tile-growth"
    [ngClass]="{
      'negative': statistic.isNegativeGrowth,
      'zero': statistic.growthPercentage === 0,
      'large-percentage': statistic.growthPercentage > 100000 && statistic.growthPercentage <= 1000000,
      'x-large-percentage': statistic.growthPercentage > 1000000
    }"
  >
    <i *ngIf="!statistic.isNegativeGrowth && !(statistic.growthPercentage === 0)" class="fa fa-sort-asc sign up" aria-hidden="true"></i>
    <i *ngIf="statistic.isNegativeGrowth && !(statistic.growthPercentage === 0)" class="fa fa-sort-desc sign down" aria-hidden="true"></i>
    <span>{{ statistic.growthPercentage | number: '1.0-1' }}%</span>
  </div>
  <div class="summary-tile-title">{{ statistic.title | uppercase }}</div>
  <div class="summary-tile-insight">
    <a
      class="summary-tile-insight-link"
      target="_blank"
      (click)="redirectByType.emit({ type: statistic.type, link: statistic.redirectLink })"
    >
      {{ statistic.tagResult | number: '1.0-5' }}
      <ng-container *ngIf="statistic.dataType === statisticsResType">%</ng-container>
    </a>
    {{ statistic.insightSentence }}
    {{ statistic.compareResult | number: '1.0-5' }}
    <ng-container *ngIf="statistic.dataType === statisticsResType">%</ng-container>
  </div>
</div>
