import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout/layout.component';
import { LogsRoutes } from './logs/logs.routes';
import { AuthRoutes } from './auth/auth.routes';
import { DashboardRoutes } from './dashboard/dashboard.routes';
import { AlertsRoutes } from './alerts/alerts.routes';
import { LivetailRoutes } from './livetail/livetail.routes';
import { InsightsRoutes } from './insights/insights.routes';
import { UserResolve } from './user/shared/userResolve';
import { AuthGuard } from './auth/shared/guards/auth.guard';
import { KibanaComponent } from './kibana/kibana/kibana.component';
import { DeploymentRoutes } from './deployment/deployment.routes';
import { HerokuGurd } from './shared/guards/heroku-payment.gurd';
import { ROIRoutes } from '@app/roi/roi.routes';
import { PaymentGuard } from './shared/guards/payment.guard';
import { RuleMainLayoutComponent } from '@app/rules/containers/rule-main-layout/rule-main-layout.component';
import { AdminGuard } from '@shared/guards/admin.guard';
import { ReadOnlyGuard } from './shared/guards/read-only.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, PaymentGuard],
    resolve: {
      userSettings: UserResolve,
    },
    children: [
      ...LogsRoutes,
      ...DashboardRoutes,
      ...AlertsRoutes,
      ...LivetailRoutes,
      ...InsightsRoutes,
      ...DeploymentRoutes,
      ...ROIRoutes,
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'integration',
        loadChildren: () => import('./settings/integrations-layout/integrations-layout.module').then(m => m.IntegrationsLayoutModule),
      },
      {
        path: 'enrichments',
        data: {
          title: 'Enrichments',
        },
        loadChildren: () => import('./settings/enrichments-settings/enrichment-settings.module').then(m => m.EnrichmentSettingsModule),
        canActivate: [PaymentGuard, AdminGuard],
      },
      {
        path: 'query-new',
        data: {
          title: 'Logs',
        },
        loadChildren: () => import('./logs-new/logs-new.module').then(m => m.LogsNewModule),
      },
      {
        path: 'extensions',
        loadChildren: () => import('./settings/extensions/extensions.module').then(m => m.ExtensionsModule),
        canActivate: [PaymentGuard, AdminGuard],
      },
      {
        path: 'webhooks',
        loadChildren: () => import('./settings/integrations-webhooks/webhook.module').then(m => m.WebHookModule),
        canActivate: [PaymentGuard, ReadOnlyGuard],
      },
      {
        path: 'kibana',
        component: KibanaComponent,
        canActivate: [PaymentGuard, HerokuGurd, AuthGuard],
      },
      {
        path: 'rules',
        component: RuleMainLayoutComponent,
        data: {
          title: 'Rules',
        },
        children: [
          {
            path: 'group/new',
            component: RuleMainLayoutComponent,
          },
          {
            path: 'group/new/:ruleType',
            component: RuleMainLayoutComponent,
          },
          {
            path: 'group/:themeId',
            component: RuleMainLayoutComponent,
          },
        ],
        canActivate: [AuthGuard, AdminGuard, PaymentGuard],
      },
    ],
  },
  ...AuthRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
