import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { CompanyRequest } from '@app/auth/shared/models/activateUser.model';
import { GetCompany } from '@app/ngxs-store/company/company.action';

@Injectable({
  providedIn: 'root'
})
export class ArchiveQueryGuard implements CanActivate {
  @SelectSnapshot(CompanyState.companyInfo) public companyInfo: CompanyRequest;

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      this.getCompany();
      return of(this.companyInfo?.archiving_provider?.archive_format !== 1);
  }

  @Dispatch() public getCompany = () => new GetCompany();

}
