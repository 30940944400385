<div *ngIf="filterRows && filterRows?.length > 0">
  <div class="title-container">
    <div class="caption" style="flex: 1;">
      <label class="label">{{ caption }}</label>
      <label *ngIf="selectedValues?.length > 0" class="secondary-label">
        {{ selectedValues ? '(' + selectedValues?.length + ' selected)' : '' }}
      </label>
    </div>
    <div
      *ngIf="filterRows?.length > initialNumberOfItemsToDisplay"
      style="text-align: right;"
    >
      <label class="expander-label" (click)="toggleShowMore()">
        {{ !showMore ? '' : 'Show less' }}
      </label>
    </div>
  </div>
  <div>
    <div>
      <div
        *ngFor="let row of filterRows | slice: 0:initialNumberOfItemsToDisplay"
      >
        <div class="item wrap-text" *ngIf="row.name || row">
          <mat-checkbox
            type="checkbox"
            class="tree-node-checkbox"
            disableRipple="true"
            [checked]="selectedValues?.indexOf(row.id || row.name) !== -1"
            (change)="toggleSelected(row.id || row.name)"
          ></mat-checkbox>
          <label
            class="tree-node-label"
            (click)="toggleSelected(row.id || row.name)"
          >
            {{ row.name }}
          </label>
          <label class="third-label">({{ row.count | numbersFormat }})</label>
        </div>
        <div
          class=" severity {{
            (row.name || ' ').toLowerCase()
          }} severity-marker"
        ></div>
      </div>
    </div>
  </div>
</div>
