import { IGroup } from '@app/settings/account/components/groups/models/models';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  createGroupActionCompleteAction,
  createGroupActionFailedAction,
  deleteGroupActionCompleteAction,
  deleteGroupActionFailedAction,
  getGroupsCompleteAction,
  getGroupsFailedAction,
  updateGroupDetailsCompleteAction,
  updateGroupDetailsFailedAction,
  getGroupsAction,
  updateGroupDetailsAction,
  createGroupAction,
  deleteGroupAction,
} from '@app/settings/account/components/groups/store/groups.actions';

export interface IGroupState {
  allGroups: { [key: string]: IGroup };
  groupNames: { [name: string]: number };
  isLoading: false;
}

const initialState: IGroupState = {
  allGroups: {},
  isLoading: false,
  groupNames: {},
};

const groupsReducer: ActionReducer<IGroupState, Action> = createReducer(
  initialState,

  on(getGroupsAction, (state: any) => {
    return { ...state, isLoading: true };
  }),

  on(getGroupsCompleteAction, (state, { payload }) => ({
    ...state,
    allGroups: payload.groups.reduce((acc, group) => {
      acc[group.id] = group;
      return acc;
    }, {}),
    groupNames: payload.groups.reduce((acc, group) => {
      acc[group.name] = group.id;
      return acc;
    }, {}),
    isLoading: false,
  })),

  on(getGroupsFailedAction, (state) => ({
    ...state,
    allGroups: {},
    isLoading: false,
  })),

  on(updateGroupDetailsAction, (state: any) => {
    return { ...state, isLoading: true };
  }),

  on(updateGroupDetailsCompleteAction, (state, { payload }) => {
    const groups = state.allGroups;
    groups[payload.group.id] = payload.group;
    return {
      ...state,
      allGroups: groups,
      isLoading: false,
    };
  }),

  on(updateGroupDetailsFailedAction, (state) => {
    return { ...state, isLoading: false };
  }),

  on(createGroupAction, (state: any) => {
    return { ...state, isLoading: true };
  }),

  on(createGroupActionCompleteAction, (state, { payload }) => {
    const newGroups = state?.allGroups || {};
    newGroups[payload.group.id] = payload.group;
    const groupNames =  (Object.values(newGroups) as IGroup[])?.reduce((acc, group) => {
      acc[group.name] = group.id;
      return acc;
    }, {}) || {};
    return {
      ...state,
      allGroups: newGroups,
      isLoading: false,
      groupNames
    };
  }),

  on(createGroupActionFailedAction, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(deleteGroupAction, (state: any) => {
    return { ...state, isLoading: true };
  }),

  on(deleteGroupActionCompleteAction, (state, { payload }) => {
    const newGroups = state.allGroups;
    delete newGroups[payload.groupId];
    return {
      ...state,
      allGroups: newGroups,
      isLoading: false,
    };
  }),

  on(deleteGroupActionFailedAction, (state) => ({
    ...state,
    deleteGroupSuccess: false,
  })),

  on(deleteGroupActionFailedAction, (state) => {
    return { ...state, isLoading: false };
  }),
);

export function reducer(state: IGroupState = initialState, action: Action): IGroupState {
  return groupsReducer(state, action);
}
