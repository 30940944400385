import { IGridFilter } from './IGridFilter';
import {
  IDoesFilterPassParams, IAfterGuiAttachedParams, IFilterParams, RowNode,
  SetFilterParameters
} from 'ag-grid';
import { FilterOption } from '../grid-options-filter/filter-option';
import { Metadata } from '../../../../logs/shared/models/query-metadata';
import { ChangeDetectorRef, ElementRef, Renderer2 } from '@angular/core';
import { MetadataService } from '../../../../logs/shared/services/metadata.service';
export class GridOptionsFilter implements IGridFilter {
  public filterOptions: FilterOption[];
  public filterChanged: boolean;
  public hidePopupCallback: any;
  public isLoading: boolean;
  public hasFilters: boolean;
  public previousModel: any;
  public textElm: ElementRef;
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;

  constructor(private metadataService: MetadataService, private renderer: Renderer2, private changeDetectionRef: ChangeDetectorRef) {
  }

  public agInit(params: IFilterParams) {
    this.params = params;
    this.valueGetter = params.valueGetter;
    // get the filter values from the col def
    const filterParams: SetFilterParameters = params.colDef.filterParams;
    if (filterParams) {
      const filterValues = filterParams.values;
      if (filterValues.filterOptions) {
        this.filterOptions = filterValues.filterOptions;
        this.hasFilters = true;
      } else if (filterValues.field) {
        this.isLoading = true;
        this.changeDetectionRef.markForCheck();
        this.getMetadataFromService(filterValues.field);
      }
    }
  }

  public getMetadataFromService(fieldName: string) {
    this.metadataService.getMetaData(fieldName, new Metadata).subscribe(
      res => this.updateFilterOptions(res),
      error => console.log(error)
    );
  }

  public updateFilterOptions(res) {
    this.filterOptions = res.filter(item => item != '').map(item => new FilterOption(item, false, item));
    this.isLoading = false;
    this.changeDetectionRef.markForCheck();
    if (this.filterOptions.length > 0) {
      this.setFilters();
      this.hasFilters = true;
    }
  }

  /*
   * called before the data source call getRows
   **/
  public getModel() {
    if (this.filterOptions) {
      return this.filterOptions.filter(option => option.isSelected).map(option => option.value);
    }
    return null;
  }

  public setModel(model: any) {
    this.previousModel = model;
    if (this.hasFilters) {
      this.setFilters();
    }
  }

  public setFilters() {
    if (this.previousModel) {
      for (const filter of this.filterOptions) {
        if (this.previousModel.indexOf(filter.value) !== -1) {
          filter.isSelected = true;
        } else {
          filter.isSelected = false;
        }
      }
      this.previousModel = null;
    }
  }

  /*
   * show filter icon if one or more options are un checked
   **/
  public isFilterActive(): boolean {
    if (this.previousModel && this.previousModel.length > 0) {
      return true;
    }
    if (this.filterOptions) {
      const checkedOptions = this.filterOptions.filter(option => option.isSelected);
      return checkedOptions.length ? true : false;
    }
    return false;
  }

  /*
   * i think this is just for client side filtering
   * to lazy to implement
   **/
  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return false;
  }

  public onChange(checked: boolean, field: FilterOption) {
    field.isSelected = checked;
    this.filterChanged = true;
  }

  public afterGuiAttached(params: IAfterGuiAttachedParams, textElm: ElementRef) {
    this.textElm = textElm;
    this.hidePopupCallback = params.hidePopup;
    this.textElm.nativeElement.focus();
  }

  public applyFilter() {
    if (this.filterChanged) {
      // force the grid to filter data
      this.params.filterChangedCallback();
      this.filterChanged = false;
    }
    // hide the filter popup
    if (this.hidePopupCallback) {
      this.hidePopupCallback();
    }
  }
}
