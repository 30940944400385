import { NgModule } from '@angular/core';
import { IntegrationService } from './shared/services/integration-service';
import { PaymentProvider } from './shared/services/payment/payment.provider';
import { PaymentService } from './shared/services/payment/payment.service';
import { PasswordService } from './shared/services/password-service';
import { EmailFiltersService } from './shared/services/email-filters.service';
import { HerokuService } from './shared/services/heroku.service';
import { ArchivingProvidersService } from './shared/services/archiving-providers.service';
import { SamlService } from './shared/services/saml.service';
import { EnrichmentsService } from '../settings/shared/services/enrichment.service';
import { SecurityOnionService } from '../settings/shared/services/security-onion.service';
import { MetricsService } from '@app/settings/shared/services/metrics.service';
import { ReindexService } from '@app/roi/reindex/services/reindex.service';
import { CustomEnrichmentsService } from '@app/settings/shared/services/custom-enrichments.service';
import { MappingStatsService } from '@app/settings/shared/services/mapping-stats.service';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';

@NgModule({
  providers: [
    IntegrationService,
    PaymentProvider,
    PaymentService,
    EmailFiltersService,
    ArchivingProvidersService,
    IntegrationService,
    PasswordService,
    HerokuService,
    SamlService,
    EnrichmentsService,
    CustomEnrichmentsService,
    MetricsService,
    SecurityOnionService,
    ReindexService,
    MappingStatsService,
    ArchiveQueriesService,
  ],
})
export class SettingsCommonModule {
  // static routes = SettingsRoutes;
}
