import { Injectable } from '@angular/core';
import { LogManager } from './logs-manager';
import { Log } from '../models/log.entity';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Json, Csv, ExportData } from '../models/export-file-types.model';
import { ZipService } from '@app/shared/services/zip.service';
import { CsvExportService } from '@app/shared/services/csv-export.service';

@Injectable()
export class ExportService {
  private _ignoreKeys: Array<string> = [
    'branchId',
    '$$hashKey',
    'index',
    'logId',
    'templateId',
    'viewObject',
    'showMore',
  ];
  private _metaFields: Array<string> = [
    'applicationName',
    'subsystemName',
    'severity',
    'category',
    'className',
    'methodName',
    'threadId',
  ];
  private _timestampField = 'timestamp';
  private _textField = 'text';
  private _metaField = 'metadata';

  constructor(private zipService: ZipService, private csvExportService: CsvExportService) {}

  public exportGridLogs(logManager: LogManager, exportData: ExportData): void {
    if (exportData.id === Json.id) {
      this.exportToJson(logManager, exportData.fileName);
    } else if (exportData.id === Csv.id) {
      this.exportToCsv(logManager, exportData.fileName, exportData.delimiter);
    }
  }

  public exportToJson(logManager: LogManager, fileName: string) {
    const logs = this.flatLogs(logManager).map((l) => {
      const log = {
        metadata: this.getMetadataObject(l.metadata),
      };

      log.metadata[this._timestampField] = this.getTimestamp(l.timestamp);

      if (l.isJson) {
        return Object.assign(log, l.textObject);
      }

      log[this._textField] = l.text;
      return log;
    });
    const strData = JSON.stringify(logs, (key, value) => value, 4);
    return this.zipService.zipFiles(strData, `${fileName}`, 'json');
  }

  public exportToCsv(logManager: LogManager, fileName: string, delimiter: string = ',') {
    const logs = this.flatLogs(logManager);

    const fields = this.getFields(logs);

    const parsedCsv = logs.map((l) => fields.map((f) => this.getCsvValue(l, f)).join(delimiter));

    parsedCsv.unshift(this.getOutputFields(fields).join(delimiter));

    return this.csvExportService.exportCsv(parsedCsv, this.sanitizeFileName(fileName));
  }

  private flatLogs(logManager: LogManager) {
    const flatArray = [];
    logManager.logPages.forEach((v, k) => {
      flatArray.push(...v);
    });
    return flatArray;
  }

  private getMetadataObject(metadata) {
    return Object.keys(metadata)
      .filter((k) => this._metaFields.indexOf(k) !== -1)
      .reduce((acc, key) => {
        acc[key] = metadata[key];
        return acc;
      }, {});
  }

  private getTextFields(obj, prefix = 'text') {
    return Object.keys(obj).reduce((res, el) => {
      if (typeof obj[el] === 'object' && obj[el] !== null) {
        return [...res, ...this.getTextFields(obj[el], prefix + el + '.')];
      } else {
        return [...res, prefix + el];
      }
    }, []);
  }

  private getTimestamp(value) {
    return moment.unix(value / 1000).format('DD/MM/YYYY HH:mm:ss.ms Z');
  }

  private getFields(logs: Array<Log>) {
    return _.union(
      _.flatten([
        // Extract text fields
        ...logs.map((l) => {
          if (l.isJson) {
            // In case of Json Object -> text.key1, text.key2, ..., text.keyN
            return this.getTextFields(l.textObject, `${this._textField}.`);
          }

          // in case of string value -> 'text'
          return this._textField;
        }),
        ...this._metaFields.map((m) => `${this._metaField}.${m}`),
        this._timestampField,
      ]),
    ).sort();
  }

  private getOutputFields(fields: string[]) {
    return fields.map((f) => {
      if (f.startsWith(`${this._textField}.`)) {
        return f.replace(`${this._textField}.`, '');
      }

      if (this.isTimestampField(f)) {
        return `${this._metaField}.${this._timestampField}`;
      }

      return f;
    });
  }

  private isTextField(field: string) {
    return field === this._textField;
  }

  private isTimestampField(field: string) {
    return field === this._timestampField;
  }

  private getCsvValue(log: Log, f: string) {
    if (log.isJson && this.isTextField(f)) {
      return '';
    }

    const fieldValue = _.get(log, f.replace(`${this._textField}.`, `${this._textField}Object.`), '');
    if (typeof fieldValue === 'string') {
      return `"${this.sanitizeCsvString(fieldValue)}"`;
    }

    if (this.isTimestampField(f)) {
      return this.getTimestamp(fieldValue);
    }

    return fieldValue;
  }

  private sanitizeCsvString(value) {
    return value
      .replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ')
      .replace(/"/g, '""')
      .replace(/ +(?= )/g, '');
  }

  private sanitizeFileName(fileName: string) {
    return fileName.replace(/:/g, '_').replace(/\//g, '_');
  }
}
