<mat-card class="coralogix card" [class.invalid]="isFormInvalid">
  <form [formGroup]="form">
    <p class="mat-card-subheader">
      Target logs by application, subsystem(s) or the severities contained within the logs.
    </p>
    <div class="coralogix input-group">
      <sh-search-query formControlName="text"></sh-search-query>
    </div>
    <div class="coralogix input-group shorten-string">
      <sh-coralogix-select
        [selectOptions]="applicationOptions$ | async"
        [multiple]="true"
        [searchable]="true"
        [allSelectedLabel]="'All Applications'"
        [placeholder]="'Select applications'"
        formControlName="applicationName"
      >
        <label select-label>
          <span class="text">Applications</span>
        </label>
      </sh-coralogix-select>
    </div>
    <div class="coralogix input-group shorten-string">
      <sh-coralogix-select
        [selectOptions]="subsystemOptions$ | async"
        [multiple]="true"
        [searchable]="true"
        [allSelectedLabel]="'All Subsystems'"
        [placeholder]="'Select subsystems'"
        formControlName="subsystemName"
      >
        <label select-label>
          <span class="text">Subsystems</span>
        </label>
      </sh-coralogix-select>
    </div>
    <div class="coralogix input-group">
      <sh-coralogix-select
        [selectOptions]="logsSeverityOptions"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [multiple]="true"
        [searchable]="true"
        [allSelectedLabel]="'All Severities'"
        [hasColoredIcon]="true"
        [placeholder]="'Select severities'"
        formControlName="logSeverity"
      >
        <label select-label>
          <span class="text">Severities</span>
        </label>
      </sh-coralogix-select>
    </div>

    <div style="display: flex;">
      <div class="coralogix input-group">
        <label select-label>
          <span class="text">From</span>
        </label>

        <div class="form-timepicker-btn" (click)="displayTimePicker()">
          <img src="../../../../../assets/roi/icon-clock.svg" />
          <span>{{ timePickerLabel }}</span>
        </div>

        <div class="special-timepicker-container" [class.away]="!this.timePickerSeen">
          <div class="floating">
            <div class="date">
              <sh-ng-datepicker
                [isOpened]="true"
                [inline]="true"
                [headless]="true"
                formControlName="from"
              ></sh-ng-datepicker>

              <div class="devider"><hr /></div>
            </div>

            <div class="time">
              <div class="top-inputs-container">
                <span>Time</span>

                <sh-coralogix-time-input formControlName="startTime"></sh-coralogix-time-input>
              </div>
            </div>

            <div class="devider"><hr /></div>

            <div class="special-time-btn-container">
              <button class="special-time-btn" mat-raised-button type="button" (click)="acceptTime()">APPLY</button>
            </div>
          </div>
        </div>
      </div>

      <div class="coralogix input-group">
        <label select-label>
          <span class="text">To</span>
        </label>

        <div class="minute-picker">
          <sh-coralogix-time-input-section
            [minValue]="1"
            [maxValue]="10"
            formControlName="endTime"
          ></sh-coralogix-time-input-section>
          <span>Minutes Later</span>
        </div>
      </div>
    </div>
  </form>
</mat-card>
