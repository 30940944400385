<button class="filter-btn" (click)="open()">
  <i class="fa fa-filter" aria-hidden="true"></i>
  <span class="filter-applied-icon" *ngIf="isFilterApplied"></span>
</button>

<div class="filters-container" *ngIf="opened">
  <mat-tab-group>
    <mat-tab
      class="tab-content"
      label="Application ({{ selectedApplicationsNumber }})"
    >
      <div class="tab-content">
        <div class="search-container">
          <div class="search-container-wrap">
            <input
              type="text"
              placeholder="Search applications..."
              [(ngModel)]="appSearch"
            />
          </div>
        </div>

        <div class="input-container">
          <mat-checkbox
            type="checkbox"
            [checked]="isAppSelected('All applications')"
            (change)="onAppChange($event.checked, 'All applications')"
          >
            All applications
          </mat-checkbox>
        </div>

        <p class="select-label">Select application</p>

        <div class="options-container">
          <div
            class="input-container"
            *ngFor="
              let appOption of applicationNameOptions | filterPipe: appSearch
            "
          >
            <mat-checkbox
              type="checkbox"
              [checked]="isAppSelected(appOption)"
              (change)="onAppChange($event.checked, appOption)"
            >
              {{ appOption }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Subsystem ({{ selectedSubsystemsNumber }})">
      <div class="tab-content">
        <div class="search-container">
          <div class="search-container-wrap">
            <input
              type="text"
              placeholder="Search applications..."
              [(ngModel)]="subsystemSearch"
              [autofocus]="true"
            />
          </div>
        </div>
        <div class="input-container">
          <mat-checkbox
            type="checkbox"
            [checked]="isSubsystemSelected('All subsystems')"
            (change)="onSubsystemChange($event.checked, 'All subsystems')"
          >
            All subsystems
          </mat-checkbox>
        </div>

        <p class="select-label">Select subsystem</p>

        <div class="options-container">
          <div
            class="input-container"
            *ngFor="
              let subsystemOption of subsystemNameOptions
                | filterPipe: subsystemSearch
            "
          >
            <mat-checkbox
              type="checkbox"
              [checked]="isSubsystemSelected(subsystemOption)"
              (change)="onSubsystemChange($event.checked, subsystemOption)"
            >
              {{ subsystemOption }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="bottom-section">
    <button class="ui button primary" (click)="close()">
      Close
    </button>
  </div>
</div>
