<div class="grid-size-noTimeline scrollable">
  <sh-insights-hit-count
    *ngIf="insightsProvider.isAlert"
    [groupByFieldsAlert]="insightsProvider.currentAlert"
    (filterSelected)="onHitCountFilterSelected($event)"
    (filterCleared)="onHitCountFilterCleared()"
  ></sh-insights-hit-count>
  <div class="section-container">
    <div class="section-title">
      <span class="section-title-text">
        Alert's Logs
      </span>
      <span *ngIf="hitCountSelectedValue" class="section-title-text">- Filtered by '{{ hitCountSelectedValue }}'</span>
    </div>
    <mat-card *ngIf="!isEmptyGrid; else emptyGrid">
      <sh-grid
        style="width: 100%;height: 380px"
        (gridReadyEvent)="onGridReady($event)"
        [logsQueryData]="queryData"
        (dataLoadedEvent)="updateQueryLogCount($event)"
        (gridRowSelectedEvent)="onSelectedRow($event)"
        (textInfoClicked)="textInfoClicked($event)"
        [searchProvider]="insightsProvider.searchProvider"
        [showGridFilters]="true"
      ></sh-grid>
    </mat-card>
  </div>
</div>

<ng-template #emptyGrid>
  <sh-empty-alert-logs-grid></sh-empty-alert-logs-grid>
</ng-template>
