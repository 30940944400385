<div>
  <div class="empty-list-header">
    <div class="header-content">
      <div class="icon-wrapper">
        <div class="icon-circle">
          <img src="assets/alerts/alert-empty.png" />
        </div>
      </div>
      <h3>You do not have any alerts</h3>
      <p>
        <span>Get real-time insights based on</span>
        <br />
        <span>criteria of your own choosing</span>
      </p>
      <div>
        <cgx-button
          label="NEW ALERT"
          size="lg"
          [srcIconStartStyle]="{ width: 24, height: 24 }"
          srcIconStart="assets/icons/alert-on.svg"
          width="270px"
          (press)="openAlertsEditor()"
        ></cgx-button>
      </div>
    </div>
  </div>
  <div class="empty-list-body">
    <div class="wrapper">
      <div class="content-header">
        <h1>Getting Started With Alerts</h1>
        <p>
          The user-defined alerts in Coralogix will allow you to obtain real-time insights based on criteria of your own choosing. It is a
          very simple process which grants users full flexivility, advanced alerting options, real-time push notifications and API access.
        </p>
      </div>
      <div class="content-body">
        <h1>NOTES</h1>
        <div class="list-item">
          <div class="count">1</div>
          <div class="description">
            An immediate alert will notify immediately and will be silent for one minute. Hit count will present 1 in immediate alert as we
            notify on the first log that matches.
          </div>
        </div>
        <div class="list-item">
          <div class="count">2</div>
          <div class="description">
            Please note that when updating an alert, there is a ~5 min delay before the alert gets in effect and ~10-15 min if the alert has
            a ‘More/Less’ rule.
          </div>
        </div>
        <div class="list-item">
          <div class="count">3</div>
          <div class="description">
            A ‘More’/’Less’ alert will trigger when the count of the entries that matched the alert definition will be more/less than the
            chosen threshold. Hit count will present the actual number of entries that matched within the selected time window.
          </div>
        </div>
        <div class="list-item">
          <div class="count">4</div>
          <div class="description">
            You can also add an automatic ‘resolve’ message to your ‘More’/’Less’ alerts. Just mark the ‘Notify on resolved’ checkbox near
            the ‘More’/’Less’ control and you’ll get an automatic update once an alert is not occurring anymore (works with all notification
            methods).
          </div>
        </div>
        <div class="list-item">
          <div class="count">5</div>
          <div class="description">
            You can enhance your ‘More’ alerts by adding the ‘Group by’ option: values under the ‘Group By’ field are aggregated into a
            histogram. An alert will trigger whenever any of the aggregated values appear more than the threshold within the specified
            timeframe. Of course, only logs that include the ‘Group By’ field will be included in the count. Up to 5 unique values of the
            selected field and their count will show up in the event details screen, along with the threshold value. Of course, all regular
            filters, such as application, subsystem, severity, etc.. may be applied to the alert as well.
          </div>
        </div>
        <h1>ADVANCED ALERTS</h1>
        <div class="list-item">
          <div class="count">1</div>
          <div class="description">
            To define an advanced alert, use ‘/’ before and after your query text
          </div>
        </div>
        <div class="list-item">
          <div class="count">2</div>
          <div class="description">
            To define an alert on a value in a specific field, prefix the value with the name of the field. For example, enter
            /environment:production/ to define an alert on all the entries that contain the value production in the environment field
          </div>
        </div>
        <div class="list-item">
          <div class="count">3</div>
          <div class="description">
            To define an alert on a range of numeric values, use the bracketed range syntax [START_VALUE TO END_VALUE]. For example, to
            define an alert on entries that have 4xx status codes, enter /status.numeric:[400 TO 499]/
          </div>
        </div>
        <div class="list-item">
          <div class="count">4</div>
          <div class="description">
            To specify more complex alert criteria, use the Boolean operators AND, OR, and NOT. For example, to define an alert on entries
            that have 4xx status codes and have an extension of PHP, enter /status.numeric:[400 TO 499] AND extension:php/
          </div>
        </div>
        <div class="list-item">
          <div class="count">5</div>
          <div class="description">
            To define an alert when a regular expression matches a value, wrap your regex with ‘/’ and use it as the expression for the
            field’s value. For example, to define an alert on the regions west-europe-1, west-europe-2, west-us-1, west-us-2, west-us-3
            etc., enter /region.keyword:/.*west-(europe|us)-[0-9]+.*//
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
