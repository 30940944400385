<sh-basic-chart
  [model]="chartModel"
  *ngIf='shouldShowChart'
></sh-basic-chart>
<div
  class="zeroAlerts"
  shSeverity
  [severity]="alertSeverity"
  [setBackground]="false"
  *ngIf='!shouldShowChart'
>
  0 Alerts
</div>
