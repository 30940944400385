import { formatsHelper } from '../../../shared/services/formatsHelper';
import * as _ from 'lodash';
import { PrettyJsonPipe } from '../../../shared/pipes/pretty-json.pipe';
import { TemplateType } from '../../shared/interfaces/templateType.enum';
export class LoggregationGridCellHelper {
  public static clickableParam: string =
    '<div class="ui lable loggregation loggregation-$3-style {CoralogixPHStatsEnabled}"' +
    ' parameterId="$1" parameterType="$3" id="parameter"> *$2</div>';

  public static clickableJsonParam: string =
    '<div class="ui lable loggregation loggregation-$3-style {CoralogixPHStatsEnabled}"' +
    ' parameterId="$2" parameterType="$3" id="parameter"> *VALUE</div>';
  public static prettyJasonPipe: PrettyJsonPipe = new PrettyJsonPipe();
  public static loggregationTextCellRenderer(params: any): HTMLElement {
    if (params.value) {
      let logExample = null;
      if (params.data && params.data.logExample) {
        logExample = params.data.logExample;
      }
      let innerElementText;
      if (params.data && params.data.templateType === TemplateType.jsonTemplate) {
        let value = '';
        try {
          value = LoggregationGridCellHelper.prettyJasonPipe.transform(
            JSON.parse(params.value),
          );
        } catch (e) {
          value = params.value;
        }
        innerElementText = params.context.buildLoggregationParametersHTML(
          value,
          LoggregationGridCellHelper.clickableJsonParam,
          true,
          logExample,
          params.data.placeholdersEnabled,
        );
        innerElementText = params.context.buildLoggregationParametersHTML(
          innerElementText,
          LoggregationGridCellHelper.clickableParam,
          false,
          null,
          params.data.placeholdersEnabled,
        );
        innerElementText =
          '<pre class="grid-json-theme">' + innerElementText + '</pre>';
      } else {
        innerElementText = params.context.buildLoggregationParametersHTML(
          params.value,
          LoggregationGridCellHelper.clickableParam,
          false,
          null,
          params.data.placeholdersEnabled,
        );
      }
      const innerElement = document.createElement('div');
      innerElement.className = 'loggregationCellContainer';
      innerElement.innerHTML = innerElementText;
      innerElement.addEventListener('click', (e) =>
        params.context.onCellClicked(e, params),
      );
      innerElement.addEventListener('mouseover', (e) =>
        params.context.mouseOver(e),
      );
      innerElement.addEventListener('mouseout', (e) =>
        params.context.OnMouseOut(e),
      );

      const menu = LoggregationGridCellHelper.loggregationButtons(params);
      const divElement = document.createElement('div');
      divElement.appendChild(innerElement);
      divElement.appendChild(menu);

      return divElement;
    }
    return null;
  }

  public static occurrencesCellrenderer(params: any) {
    if (!_.isUndefined(params.value)) {
      const element = document.createElement('div');
      element.className =
        'ui lable loggregation loggregation-occurrences-style';
      element.appendChild(
        document.createTextNode(formatsHelper.numberWithCommas(params.value)),
      );
      element.addEventListener('click', () => {
        params.context.showModal(params.node.data.id);
      });
      return element;
    }
    return null;
  }

  public static currentRatioCellRenderer(params: any) {
    if (!_.isUndefined(params.value)) {
      const value = params.value
        ? Math.round(params.value * 10000) / 100 + '%'
        : 0 + '%';
      const element = document.createElement('div');
      element.appendChild(document.createTextNode(value));
      if (params.data && params.data.isAnomalous) {
        element.className = 'anomaly-breakage-cell';
      }
      return element;
    }
    return null;
  }

  public static createIcon(iconName: string): HTMLElement {
    const icon = document.createElement('i');
    icon.classList.add('fa');
    icon.classList.add(iconName);
    icon.style.cssText = 'font-size:14px; margin:auto;top: 50%;';
    return icon;
  }
  public static loggregationButtons(params): HTMLElement {
    const menu = document.createElement('span');
    menu.className = 'loggregation-btn-group';
    const infoButton = document.createElement('div');
    infoButton.className = 'loggregation-btn';
    infoButton.appendChild(LoggregationGridCellHelper.createIcon('fa-info'));
    // infoButton.setAttribute('data-tooltip', 'Open info panel');
    // infoButton.setAttribute('data-position', 'bottom right');
    infoButton.addEventListener('click', function() {
      params.context.textInfoClicked.emit();
    });
    const queryButton = document.createElement('div');
    queryButton.className = 'loggregation-btn';
    queryButton.appendChild(LoggregationGridCellHelper.createIcon('fa-search'));
    // queryButton.setAttribute('data-tooltip', 'Query by template');
    // queryButton.setAttribute('data-position', 'bottom right');
    queryButton.addEventListener('click', function() {
      params.context.queryByTemplate(params.data);
    });
    menu.appendChild(infoButton);
    menu.appendChild(queryButton);
    return menu;
  }
}
