import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AlertsEditorFormService } from '@app/alerts/alerts-editor/services/alerts-editor-form.service';
import { AlertsEditorSelectOptions } from '@app/alerts/alerts-editor/models/alerts-select-options.model';
import { AlertsEditorSelectOptionsService } from '@app/alerts/alerts-editor/services/alerts-editor-select-options.service';
import { AlertModel } from '@app/alerts/shared/models/alert-model';
import { AlertMapperService } from '@app/alerts/alerts-editor/services/alert-mapper.service';
import { CreateNUpdateAlertModel } from '@app/alerts/shared/models/create-n-update-alert-model';
import { MatDialog } from '@angular/material/dialog';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { AlertsEditorMode } from '@app/alerts/alerts-editor/models/alerts-editor-mode.enum';
import { AlertEditFromQueryModel } from '@app/alerts/alerts-editor/models/alert-edit-from-query.model';
import { getIntegrations, getTimezoneViewPreference, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { TimeZoneType } from '@shared/models/timezone-types';
import { AlertSyntaxType, IAlertType, UserAlertViewTypes } from '@app/alerts/alerts-editor/models/alert-editor-view.models';
import {
  AlertEditorComponentNames,
  AlertTypeNameToValue,
  AlertTypesOptions,
  AlertTypeViewsDefs,
  TwelveHoursInMinutes,
} from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { AlertHelper } from '@app/alerts/shared/services/alert-helper';
import { IntegrationsActions } from '@app/settings/shared/state/integrations/integrations.actions';
import { Observable } from 'rxjs';
import { IntegrationModel } from '@app/settings-common/shared/models/newIntegrationRequest';

@Component({
  selector: 'sh-alerts-editor',
  templateUrl: './alerts-editor.component.html',
  styleUrls: ['./alerts-editor.component.scss'],
  providers: [AlertsEditorFormService, AlertsEditorSelectOptionsService],
})
export class AlertsEditorComponent {
  public isLocalTimezone: boolean = true;
  @Input()
  public alert: AlertModel | AlertEditFromQueryModel;
  @Input()
  public mode: AlertsEditorMode;
  @Input()
  public isLoading: boolean = true;
  @Input()
  public selectOptions: AlertsEditorSelectOptions;
  @Input()
  public form: FormGroup;
  @Input()
  public invalidErrMsg: string;

  @Output()
  public save: EventEmitter<CreateNUpdateAlertModel> = new EventEmitter<CreateNUpdateAlertModel>();
  @Output()
  public delete: EventEmitter<string> = new EventEmitter<string>();

  public alertTypeViewsDefs: typeof AlertTypeViewsDefs = AlertTypeViewsDefs;
  public query: AlertEditorComponentNames = AlertEditorComponentNames.query;
  public componentTypes: typeof AlertEditorComponentNames = AlertEditorComponentNames;

  public integrations$: Observable<IntegrationModel[]> = this.store.select(getIntegrations);

  constructor(
    private alertsEditorFormService: AlertsEditorFormService,
    private alertMapperService: AlertMapperService,
    public dialog: MatDialog,
    private store: Store<State>,
    private alertHelper: AlertHelper,
  ) {
    this.store
      .select(getTimezoneViewPreference)
      .take(1)
      .subscribe(tmz => {
        this.isLocalTimezone = tmz === TimeZoneType.local;
      });
  }

  public get alertType(): UserAlertViewTypes {
    return this.form.getRawValue().userAlertTypeId;
  }

  public get relativeAlertSelected(): boolean {
    return this.alertType === AlertTypeNameToValue.ratio;
  }

  public get metricAlertSelected(): boolean {
    return this.alertType === AlertTypeNameToValue.metric;
  }

  public get isRelativeTimeAlert(): boolean {
    return this.alertType === UserAlertViewTypes.RelativeTime;
  }

  public get isEdit(): boolean {
    return this.mode === AlertsEditorMode.edit;
  }

  public get isFromQuery(): boolean {
    return this.mode === AlertsEditorMode.fromQuery;
  }

  public get detailsGroup(): FormGroup {
    return this.form.get('details') as FormGroup;
  }

  public get contentGroup(): FormGroup {
    return this.form.get('content') as FormGroup;
  }

  public get conditionsGroup(): FormGroup {
    return this.form.get('conditions') as FormGroup;
  }

  public get queryGroup(): FormGroup {
    return this.form.get('query') as FormGroup;
  }

  public get scheduleGroup(): FormGroup {
    return this.form.get('schedule') as FormGroup;
  }

  public get recipientsGroup(): FormGroup {
    return this.form.get('recipients') as FormGroup;
  }

  public get relativeAlertsArray(): FormArray {
    return this.form.get('relativeAlerts') as FormArray;
  }

  public get groupByFieldsArray(): FormArray {
    return this.form.get('conditions').get('groupByFields') as FormArray;
  }

  public get isFormInvalid(): boolean {
    return !this.form.valid || this.isQueryFormInvalid || this.isLoading;
  }

  public get relativeCalcStr(): string {
    const calcs = this.relativeAlertsArray.controls.map((ra, i) => ` ${ra.value.operator} Query ${i + 2}`).join('');
    return `Query 1${calcs}`;
  }

  public get isQueryFormInvalid(): boolean {
    const form = this.form.get('query') as FormGroup;
    const values = Object.values(form.value);
    const nonEmptyValues = values.filter((value: any) => {
      return value && value.length > 0;
    });
    return nonEmptyValues.length === 0;
  }

  public onSave(): void {
    if (this.isRelativeTimeAlert) {
      this.queryGroup.controls.alias.setValue(this.detailsGroup.value.alertName);
      // copy alert to relative alert
      const relativeAlert = this.relativeAlertsArray.controls[0] as FormGroup;
      relativeAlert.controls.alertText.setValue(this.queryGroup.value.alertText);
      relativeAlert.controls.alias.setValue(
        `${this.queryGroup.value.alias} compared to ${
          this.alertHelper.relativeTimeframeOptions.filter(e => e.id === this.conditionsGroup.value.relativeTimeframeOptionId)?.[0]?.name
        }`,
      );
      const appNameControl = relativeAlert.controls.applicationName as FormArray;
      const subNameControl = relativeAlert.controls.subsystemName as FormArray;

      this.queryGroup.value.applicationName.forEach(item => appNameControl.push(new FormControl(item)));
      this.queryGroup.value.subsystemName.forEach(item => subNameControl.push(new FormControl(item)));
      relativeAlert.controls.logSeverity.setValue(this.queryGroup.value.logSeverity);
    }

    if (this.relativeAlertSelected || this.isRelativeTimeAlert) {
      const queries = this.relativeAlertsArray.controls;
      (queries[0] as FormGroup).controls.parentAlias.setValue(this.queryGroup.value.alias);
      this.groupByFieldsArray.reset();
    } else {
      this.relativeAlertsArray.clear();
    }

    this.markAllControlsWithValidatorsTouched();
    if (this.isFormInvalid) {
      return;
    }

    const formValueMapped = this.alertsEditorFormService.mapFormValueToEditControls(this.form.value);
    const request: CreateNUpdateAlertModel = this.alertMapperService.mapFormToRequest(
      formValueMapped,
      this.isEdit,
      this.alert,
      this.isLocalTimezone,
    );
    if (this.alertType === UserAlertViewTypes.NewValueAlert && request.isActive) {
      this.handleAlertNewValueSave(request);
    } else {
      this.save.emit(request);
    }
  }

  public onDelete(): void {
    const data: ICoralogixDialogData = {
      title: 'Are you sure you want to delete this alert?',
      message: 'Deleting this alert is irreversable. Please confirm that you want to delete it.',
      yesButtonText: 'Delete',
      noButtonText: 'Cancel',
    };
    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.delete.emit(this.alert.id);
      }
    });
  }

  public getIntegrations(): void {
    this.store.dispatch(new IntegrationsActions.GetIntegrationsAction());
  }

  public alertTypeChange(type: UserAlertViewTypes): void {
    this.form.get('userAlertTypeId').setValue(type);
  }

  public isMetricAlert(type: UserAlertViewTypes): boolean {
    return type === UserAlertViewTypes.MetricAlert;
  }

  private handleAlertNewValueSave(request: CreateNUpdateAlertModel): void {
    const data: ICoralogixDialogData = {
      title: 'Important note',
      message:
        'In order to learn the different values and prevent false notifications,' +
        ` this alert will become active in ${this.getSelectedTimeFrameByCondition()}.`,
      yesButtonText: 'Ok',
      noButtonText: '',
    };

    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data });

    dialogRef
      .afterClosed()
      .take(1)
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.save.emit(request);
        }
      });
  }

  private getSelectedTimeFrameByCondition(): string {
    const conditionTimeFrame = this.alertHelper.GetConditionTimeNewValueFrameCaption(
      this.conditionsGroup.getRawValue()?.conditionTimeframe,
    );
    const defaultValue = this.alertHelper.GetConditionTimeNewValueFrameCaption(TwelveHoursInMinutes);
    return conditionTimeFrame || defaultValue;
  }

  private markAllControlsWithValidatorsTouched(): void {
    Object.values(this.detailsGroup.controls).forEach(value => value.markAsTouched());
    Object.values(this.recipientsGroup.controls).forEach(value => value.markAsTouched());
    Object.values(this.conditionsGroup.controls).forEach(value => value.markAsTouched());
  }
}
