import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getTagsLoading, State } from '../../app.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Rx';
import { Build } from '../../deployment/models/build';
import { LogsViewStateProvider } from '../shared/services/logsViewState.provider';
import { Subscription } from 'rxjs/Subscription';
import { DeploymentStoreService } from '../../deployment/shared/services/deployment.store.service';

@Component({
  selector: 'sh-tag-query',
  templateUrl: './tag-query.component.html',
  styleUrls: ['./tag-query.component.scss'],
})
export class TagQueryComponent implements OnInit, OnDestroy {
  @Input() public newQueryForm: FormGroup;
  @Output() public tagQuery: EventEmitter<any> = new EventEmitter<any>();

  public tagsList$: Observable<Build[]>;
  public tagsLoading$: Observable<boolean>;
  public stringFilter: string;
  public logsViewFilterChangedSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private logsViewStateProvider: LogsViewStateProvider,
    private tagsStoreService: DeploymentStoreService,
  ) {
    this.tagsLoading$ = this.store.select(getTagsLoading);
    this.loadTags();
  }

  public ngOnInit() {}

  public onTagSelected(tagId: number) {
    this.tagQuery.emit(tagId);
  }

  public stringFilterChanged(term) {
    this.stringFilter = term;
  }

  public ngOnDestroy(): void {
    if (this.logsViewFilterChangedSubscription) {
      this.logsViewFilterChangedSubscription.unsubscribe();
    }
  }

  private loadTags() {
    const selectedApplications = this.logsViewStateProvider.lastExcecutedQuery
      .queryModel.queryParams.metadata.applicationName;
    const selectedSubsystems = this.logsViewStateProvider.lastExcecutedQuery
      .queryModel.queryParams.metadata.subsystemName;

    this.tagsList$ = this.tagsStoreService.filterTagsByApplicationAndSubsystem(
      selectedApplications,
      selectedSubsystems,
    );

    this.logsViewFilterChangedSubscription = this.logsViewStateProvider.onFilterChange.subscribe(
      (params) => {
        this.tagsList$ = this.tagsStoreService.filterTagsByApplicationAndSubsystem(
          params.metadata.applicationName,
          params.metadata.subsystemName,
        );
      },
    );
  }
}
