<div class="container">
  <sh-json-property
    [jsonObject]="jsonObject"
    (filterByJsonValue)="jsonKeyClicked($event)"
    [hideJsonParams]="hideJsonParams"
    (onShowWidget)="showJsonWidget($event)"
    [isShowWidgetEnabled]="isShowWidgetEnabled"
    [isFiltersEnabled]="isFiltersEnabled"
  ></sh-json-property>
</div>
