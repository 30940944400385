<div class="main-container" [ngStyle]="{'margin-right.px': sideBarRightMargin}">
    <div class="sidebar-container"
         [ngStyle]="{'transform': 'translateX(' + translateWidth + 'px)' }"
         [class.hidden]="!isSideBarShown"
         [class.collapsed]="isSideBarCollapsed"
         [class.hovered]="isHovered"
    >
        <div class="side-bar"
             [class.resizing]="isResizing"
             [ngStyle]="{'width.px': sideBarWidth}"
             (mouseover)="isHovered = true"
             (mouseout)="isHovered = false"
        >
            <div class="collapsed-overlay" *ngIf="isSideBarCollapsed"></div>
            <sh-logs-group-by-panel
                    (filterChanged)="filterChanged.emit($event)"
                    [isOpen]="isSideBarShown"
            >
            </sh-logs-group-by-panel>

            <sh-resize-handler
                    class="resizer"
                    (dragged)="onResize($event)"
                    (dragEnd)="onDragEnd()"
            >
            </sh-resize-handler>
        </div>
        <div class="expand-collapse-icon"
             [class.resizing]="isResizing"
             [matTooltip]="isSideBarCollapsed ? 'Expand' : 'Collapse'"
             (click)="toggleSideBar()"
        >
            <i class="fa fa-chevron-right" *ngIf="isSideBarCollapsed"></i>
            <i class="fa fa-chevron-left" *ngIf="!isSideBarCollapsed"></i>
        </div>
    </div>
</div>

