import { AnomalyUpdateParams } from './Anomaly-update-params';

export class UpdatedAnomaliesModel {
  public anomalyId: string;
  public anomalyUpdateParams: AnomalyUpdateParams;

  constructor(anomalyId: string, updateParams: AnomalyUpdateParams) {
    this.anomalyId = anomalyId;
    this.anomalyUpdateParams = updateParams;
  }
}
