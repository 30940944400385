<div class="actionItem">
  <button
    class="ui icon button action"
    (click)="queryText(selectedLog)"
    [disabled]="!selectedLog"
    data-tooltip="Query log text"
  >
    <i class="icon database inverted"></i>
  </button>
</div>
<div class="actionItem">
  <button
    class="circular ui icon button action"
    (click)="queryTemplate(selectedLog)"
    [disabled]="!selectedLog"
    data-tooltip="Query log template"
  >
    <i class="icon database custom-outline"></i>
  </button>
</div>
<div class="actionItem">
  <button
    class="circular ui icon button action"
    (click)="defineAlertOnText(selectedLog)"
    [disabled]="!selectedLog"
    data-tooltip="Define alert on log text"
  >
    <i class="icon flag inverted"></i>
  </button>
</div>
