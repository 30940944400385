<div fxLayout="column" class="tag-details">
  <div fxLayout="row-reverse">
    <cgx-button stroked label="EXPORT" [disabled]="exportLoading" [loading]="exportLoading" (press)="exportToPDF()"></cgx-button>
  </div>
  <div fxLayout="column" fxLayoutGap="37px" id="tags-export-to-pdf">
    <sh-event-info-viewer
      [deletedId]="deletedTagId$ | async"
      [all]="tag$tags$ | async"
      [isSelectAllSubsystems]="filterBySubsystem$ | async"
      [viewState]="viewState$ | async"
    ></sh-event-info-viewer>
  </div>
</div>
