import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  CompareType,
  ConditionalSelectGroups,
  IConditionalSelectGroup,
} from '@shared/components/conditional-select/conditional-select.model';
import { compareTypeFilters, filterPrefix } from '@shared/utils/metadata-translator';

@Injectable({ providedIn: 'root' })
export class ConditionalSelectService {
  constructor(private fb: FormBuilder) {}

  createNewGroup(): FormGroup {
    const newGroup = this.getNewGroup();
    return this.fb.group({
      compareType: this.fb.control(newGroup.compareType),
      values: this.fb.control(newGroup.values),
    });
  }

  mapToFormData(dataArr: string[]): IConditionalSelectGroup[] {
    const allGroups = {} as ConditionalSelectGroups;

    dataArr?.forEach((metaField) => {
      let doesFieldMatchAnyPattern = false;

      for (const filter of compareTypeFilters) {
        if (metaField.startsWith(filter.pattern)) {
          if (!allGroups[filter.enum]) allGroups[filter.enum] = [];
          const field = metaField.substring(filter.pattern.length);
          allGroups[filter.enum].push(field);
          doesFieldMatchAnyPattern = true;
          break;
        }
      }

      if (!doesFieldMatchAnyPattern) {
        if (!allGroups.equals) allGroups.equals = [];
        allGroups.equals.push(metaField);
      }
    });

    return this.createGroup(allGroups);
  }

  createFormFromData(groups: IConditionalSelectGroup[]): FormArray {
    if (!groups) return this.fb.array([]);
    return this.fb.array(
      groups.map((group) => {
        return this.fb.group({
          compareType: this.fb.control(group.compareType),
          values: this.fb.control(group.values),
        });
      }),
    );
  }

  mapToRequest(groups: IConditionalSelectGroup[]): string[] {
    return groups.flatMap((group) => {
      const values = group.values;
      const compareType = group.compareType;
      return values.flatMap((value) => {
        if (!value) return [];
        if (compareType === CompareType.equals) return value;
        return `${filterPrefix}:${compareType}:${value}`;
      });
    });
  }

  validateControl(control: FormControl): boolean {
    return control.value.some((app) => app.values?.length && app.values[0].value);
  }

  private createGroup(groups: ConditionalSelectGroups): IConditionalSelectGroup[] {
    if (!groups) return [this.getNewGroup()];
    return Object.entries(groups).map(([compareType, values]) => ({ compareType, values } as IConditionalSelectGroup));
  }

  private getNewGroup(): IConditionalSelectGroup {
    return { compareType: CompareType.equals, values: [] };
  }
}
