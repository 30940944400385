import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  VolumeAnomalyGraphResult,
  VolumeAnomalyQueryModel,
  VolumeAnomalyWidgetResult,
} from '../models/volume-anomaly-query.model';
import { TypedJSON } from 'typedjson';
import { Observable } from 'rxjs';
import { Constants } from '../../../constants';

@Injectable()
export class AnomalyService {
  constructor(private http: HttpClient) {}

  public getVolumeAnomalyWidgetData(
    queryModel: VolumeAnomalyQueryModel,
  ): Observable<VolumeAnomalyWidgetResult> {
    return this.http
      .post(Constants.volumeAnomlayWidget, JSON.stringify(queryModel))
      .map((res: VolumeAnomalyWidgetResult) =>
        TypedJSON.parse(res, VolumeAnomalyWidgetResult),
      );
  }

  public getVolumeAnomalyGraphData(
    queryModel: VolumeAnomalyQueryModel,
  ): Observable<VolumeAnomalyGraphResult> {
    return this.http
      .post(Constants.volumeAnomlayGraph, JSON.stringify(queryModel))
      .map((res: VolumeAnomalyGraphResult) =>
        TypedJSON.parse(res, VolumeAnomalyGraphResult),
      )
      .map((res) => {
        res.currentValue = res.currentValue.map(([timestamp, val]) =>
          val === 'null' ? [timestamp, null] : [timestamp, val],
        );
        res.range = res.range.map(([timestamp, min, max]) => {
          const actualMin = min === 'null' ? null : min;
          const actualMax = max === 'null' ? null : max;
          return [timestamp, actualMin, actualMax];
        });
        return res;
      });
  }
}
