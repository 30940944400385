<div>
  <mat-card style="position: relative">
    <div *ngIf="!loading">
      <div class="sh-list" style="z-index: 10">
        <div
          class="sh-list-item"
          *ngFor="let archiving of archivingProvidersList"
        >
          <div class="sh-list-icon align-top icon-container">
            <i class="fa fa-envelope-o"></i>
            <span class="sub-icon">
              <i class="fa fa-filter"></i>
            </span>
          </div>
          <div class="sh-list-content">
            <div class="sh-list-item-title">{{ archiving.name }}</div>
            <div class="sh-list-item-sub-title">
              <span class="label">
                {{ archiving.region }}
              </span>
            </div>
          </div>
          <div
            class="sh-list-action-menu"
            style="margin: -14px 0px 0px 0px !important;"
          >
            <div
              class="sh-action delete"
              (click)="deleteArchivingProvider.emit(archiving)"
            >
              <i class="fa fa-trash-o"></i>
              <div class="sh-action-text">Delete Filter</div>
            </div>
            <div
              class="sh-action edit"
              (click)="editArchivingProvider.emit(archiving)"
            >
              <i class="fa fa-pencil"></i>
              <div class="sh-action-text">Edit Filter</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="loading" class="container">
      <sh-preview-placeholder></sh-preview-placeholder>
      <sh-preview-placeholder></sh-preview-placeholder>
    </div>
  </mat-card>
</div>
