import { type } from '@shared/services/utils';
import { Action } from '@ngrx/store';
import {Metric} from "@app/settings/metrices-settings/models/metric.model";

export namespace MetricEditorActions {
  export const ActionTypes = {
    TOGGLE_EDITOR: type('[MetricEditor] Toggle'),
    EDIT_METRIC: type('[MetricEditor] edit')
  };

  export class ToggleEditorAction implements Action {
    public type = ActionTypes.TOGGLE_EDITOR;

    constructor(public payload: boolean) {}
  }

  export class EditMetricAction implements Action {
    public type = ActionTypes.EDIT_METRIC;

    constructor(public payload: Metric) {}
  }

  export type Actions =
    ToggleEditorAction |
    EditMetricAction;
}
