export interface ILatestClickedElement extends HTMLElement {
  selectedField: string;
  keyField: string;
  valueField: string;
  rawField: string;
  isSingleValue: boolean;
  isValidJSON: boolean;
  isURL: boolean;
  isDate: boolean;
  isPrimitiveValue: boolean;
  isKey: boolean;
}

export enum IJsonElements {
  key,
  colons,
  value,
}

export type LogsContextMenuType = 'key' | 'value' | 'selection';

export interface IContextMenu {
  selection: string;
  type: LogsContextMenuType;
  style: { [selector: string]: string | number };
}

export type tooltipTypes = 'key' | 'value' | 'template';

export interface ITooltipOptions {
  useTooltip?: boolean;
  useJsonPath?: boolean;
  tooltipOn?: { [alias in tooltipTypes]?: boolean };
  staticValue?: string;
}
