<div class="container">

  <div style="background-color: #f1f1f1 !important;">
    <sh-statistics-chart
      [hidden]="!insightsProvider.globalState.showTimeline"
      [queryDefinition]="statisticsQueryDefinition"
      [addInvertedClassToLoader]="true"
      [enableLoading]="true"
    ></sh-statistics-chart>
  </div>

  <div
    [class.grid-size]="insightsProvider.globalState.showTimeline"
    [class.grid-size-noTimeline]="!insightsProvider.globalState.showTimeline"
  >
    <sh-grid style="height: 100%; width: 100%;"
             [highlightedLogs]="highlightedLogs"
             [(isOverlayVisible)]="isOverlayVisible"
             [searchProvider]="insightsProvider.searchProvider"
             [logsQueryData]="queryData"
             (gridReadyEvent)="onGridReady($event)"
             (gridRowSelectedEvent)="onSelectedRow($event)"
             (beforeApiDestroyEvent)="beforeApiDestroy($event)"
             (textInfoClicked)="onGridButtonClick($event)"
             (dataLoadedEvent)="updateQueryLogCount($event)"
             [showGridFilters]="true">
    </sh-grid>
  </div>
</div>
