import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MomentTimeFormatTypes } from '@shared/helpers/moment.helper';
import { Select } from '@ngxs/store';
import { CompanyState } from '@app/ngxs-store/company/company.state';

@Component({
  selector: 'sh-relative-query',
  templateUrl: './relative-query.component.html',
  styleUrls: ['./relative-query.component.scss'],
})
export class RelativeQueryComponent {
  @Select(CompanyState.queryRangeNumber) public queryRangeNumber$: Observable<number>;

  @Output() public valueSelected: EventEmitter<number> = new EventEmitter<
    number
  >();

  public last: number = 15;

  public scalar: number = 1000 * 60;

  public fromTimeStamp: number = Date.now() - 15 * 60 * 1000;

  public Date: any = Date;

  public standardHoursFormat: MomentTimeFormatTypes = MomentTimeFormatTypes.standard;
}
