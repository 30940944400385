import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@app/auth/shared/services/auth.service';
import { ErrorTypes, UnAuthResRoutesToCheck } from '@app/auth/shared/models/auth.models';
import { Observable, throwError } from 'rxjs';

export const InterceptorSkipHeader = 'X-Skip-Token-Interceptor';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }
    const tokenizedReq = req.clone({
      setHeaders: {
        Authorization: this.authService.getBearerToken(),
        'Content-Type': 'application/json',
      },
    });
    return next.handle(tokenizedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        const tokenInvalidErr = error?.error?.err === ErrorTypes.TokenNotFound;
        const isAuthCheckValid = !!UnAuthResRoutesToCheck.find(route => route === error.url);
        const unauthorized = error.status === ErrorTypes.Unauthorized && isAuthCheckValid;
        const errType = unauthorized ? ErrorTypes.Unauthorized : tokenInvalidErr ? ErrorTypes.TokenNotFound : null;
        if (errType) {
          this.authService.handleSpecialErr(errType);
        }
        return throwError(error);
      }),
    );
  }
}
