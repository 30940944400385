import { jsonMember, jsonObject } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

export class VolumeAnomalyQueryModel {
  public applicationName: string;
  public subsystemName: string;
  public startTime: number;
  public endTime: number;
  public step: number;
}

@jsonObject
export class VolumeAnomalyWidgetResult {
  @jsonMember({ name: 'avg_witout_offset', constructor: Number })
  public average: number;
  @jsonMember({ name: 'current_value', constructor: Number })
  public current: number;
}

@jsonObject
export class VolumeAnomalyGraphResult {
  @jsonMember(TypedJsonAny.OPTIONS)
  public currentValue: any;
  @jsonMember(TypedJsonAny.OPTIONS)
  public range: any;
}
