import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { getMetadataField, getMetadataFieldCompleted, getMetadataFieldFailed } from '@shared/state/actions/generic-metadata.actions';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { GenericMetadataType } from '@app/logs/shared/models/query-metadata';
import { MetadataService } from '@app/logs/shared/services/metadata.service';
import { of } from 'rxjs';

@Injectable()
export class GenericMetadataEffects {
  @Effect()
  public getGenericMetadata$: Observable<Action> = this.actions$.pipe(
    ofType(getMetadataField),
    mergeMap((action: { payload: { fieldName: string, filters?: GenericMetadataType } }) => {
      return this.metadataService.getGenericMetadata(action?.payload?.fieldName, action?.payload?.filters).pipe(
        map((res) => getMetadataFieldCompleted({ payload: { metadata: res, filedName: action.payload.fieldName } }))
      ).catch((err) => of(getMetadataFieldFailed()));
    })
  );
  constructor(private actions$: Actions, private metadataService: MetadataService) {}
}
