import { type } from '@shared/services/utils';
import { Action } from '@ngrx/store';
import { Build } from '../../models/build';
import {
  IStatisticsViewData,
  ITagSummaryRequest,
  ITagWithCompareBaseRequest,
} from '@app/deployment/shared/models/summary.models';
import {
  INewTagActionPayload,
  INewTagCompleteActionPayload
} from '@app/deployment/shared/models/actions-models';
import { INewEditTagRequestModel } from '@app/deployment/models/tag.request.models';
import { IAlertsData } from '@app/deployment/shared/models/reducer.models';
import { ITagErrorVolumeResponse } from '@app/deployment/shared/models/error-volume.models';

export namespace TagsActions {
  export const ActionTypes = {
    EDIT_TAG: type('[Tag] EDIT Tag'),
    EDIT_TAG_COMPLETED: type('[Tag] EDIT Tag Completed'),
    EDIT_TAG_FAILED: type('[Tag] EDIT TAG FAILED'),
    ADD_TAG: type('[Tag] Add Tag'),
    ADD_TAG_COMPLETED: type('[Tag] ADD Tag Completed'),
    ADD_TAG_FAILED: type('[Tag] ADD TAG FAILED'),
    DELETE_TAG: type('[Tag] DELETE Tag'),
    DELETE_TAG_COMPLETED: type('[Tag] DELETE Tag Completed'),
    DELETE_TAG_FAILED: type('[Tag] DELETE TAG FAILED'),
    SELECT_TAG: type('[Tag] SELECT TAG'),
    GET_TAGS: type('[Tag] Get Tags'),
    GET_TAGS_COMPLETED: type('[Tag] Get Tags Completed'),
    GET_TAGS_FAILED: type('[Tag] Get Tags Failed'),
    SELECT_NEW_TAG: type('[Tag] SELECT NEW Tag'),
    SELECT_TAG_TO_COMPARE: type('[Tag] Select Tag to compare'),
    CHANGE_TAG_FILTER_BY_SUBSYSTEMS: type(
      '[Tag] Change tag filter by subsystems',
    ),

    SELECT_TAG_LOG_ROW: type('[Tag] SELECT Tag LOG ROW'),
    GET_TAG_SUMMARY: type('[Tag] get tag summary'),
    GET_TAG_SUMMARY_COMPLETED: type('[Tag] get tag summary completed'),
    GET_TAG_SUMMARY_FAILED: type('[Tag] get tag summary failed'),
    SET_TAG_N_COMPARE_TAG_PAYLOAD_BASE: type('[Tag] set tag and compare tag time ranges'),
    SET_TAG_N_COMPARE_TAG_PAYLOAD_BASE_COMPLETE: type('[Tag] set tag and compare tag time ranges complete'),
    GET_TAG_ALERTS: type('[Tag] get tags alerts data'),
    GET_TAG_ALERTS_COMPLETED: type('[Tag] get tags alerts data complete'),
    GET_TAG_ALERTS_FAILED: type('[Tag] get tags alerts data failed'),
    GET_TAG_ERROR_VOLUME: type('[Tag] get tags error volume data'),
    GET_TAG_ERROR_VOLUME_COMPLETED: type('[Tag] get tags error volume data complete'),
    GET_TAG_ERROR_VOLUME_FAILED: type('[Tag] get tags error volume data failed')
  };
  export class SelectTagsAction implements Action {
    public type: string = ActionTypes.SELECT_TAG;
    constructor(public payload: Build) {}
  }

  export class GetTagsAlertsAction implements Action {
    public type: string = ActionTypes.GET_TAG_ALERTS;
    constructor(public payload: ITagWithCompareBaseRequest) {}
  }

  export class GetTagsAlertsActionCompleted implements Action {
    public type: string = ActionTypes.GET_TAG_ALERTS_COMPLETED;
    constructor(public payload: IAlertsData) {}
  }

  export class GetTagsAlertsActionFailed implements Action {
    public type: string = ActionTypes.GET_TAG_ALERTS_FAILED;
    constructor(public payload: any) {}
  }

  export class GetTagErrorVolumeAction implements Action {
    public type: string = ActionTypes.GET_TAG_ERROR_VOLUME;
    constructor(public payload: ITagWithCompareBaseRequest) {}
  }

  export class GetTagErrorVolumeActionCompleted implements Action {
    public type: string = ActionTypes.GET_TAG_ERROR_VOLUME_COMPLETED;
    constructor(public payload: ITagErrorVolumeResponse) {}
  }

  export class GetTagErrorVolumeActionFailed implements Action {
    public type: string = ActionTypes.GET_TAG_ERROR_VOLUME_FAILED;
    constructor(public payload: any) {}
  }

  export class SetTagNCompareBasePayloadAction implements Action {
    public type: string = ActionTypes.SET_TAG_N_COMPARE_TAG_PAYLOAD_BASE;
    constructor(public payload: any) {}
  }

  export class SetTagNCompareBasePayloadCompleteAction implements Action {
    public type: string = ActionTypes.SET_TAG_N_COMPARE_TAG_PAYLOAD_BASE_COMPLETE;

    constructor(public payload: ITagWithCompareBaseRequest) {}
  }

  export class SelectNewTagAction implements Action {
    public type: string = ActionTypes.SELECT_NEW_TAG;

    constructor(public payload: any) {}
  }

  export class GetTagSummaryAction implements Action {
    public type: string = ActionTypes.GET_TAG_SUMMARY;

    constructor(public payload: ITagSummaryRequest) {}
  }

  export class GetTagSummaryCompletedAction implements Action {
    public type: string = ActionTypes.GET_TAG_SUMMARY_COMPLETED;

    constructor(public payload: IStatisticsViewData[]) {}
  }

  export class GetTagSummaryFailedAction implements Action {
    public type: string = ActionTypes.GET_TAG_SUMMARY_FAILED;

    constructor(public payload: any) {}
  }

  export class GetTagsAction implements Action {
    public type: string = ActionTypes.GET_TAGS;

    constructor(public payload: any) {}
  }
  export class GetTagsCompletedAction implements Action {
    public type: string = ActionTypes.GET_TAGS_COMPLETED;

    constructor(public payload: Build[]) {
    }
  }
  export class GetTagsFailedAction implements Action {
    public type: string = ActionTypes.GET_TAGS_FAILED;
    constructor(public payload: any) {}
  }
  export class AddTagAction implements Action {
    public type: string = ActionTypes.ADD_TAG;

    constructor(public payload: INewTagActionPayload) {}
  }
  export class AddTagCompletedAction implements Action {
    public type: string = ActionTypes.ADD_TAG_COMPLETED;

    constructor(public payload: INewTagCompleteActionPayload) {}
  }
  export class AddTagFailedAction implements Action {
    public type: string = ActionTypes.ADD_TAG_FAILED;

    constructor(public payload: any) {}
  }

  export class EditTagAction implements Action {
    public type: string = ActionTypes.EDIT_TAG;

    constructor(public payload: INewEditTagRequestModel) {}
  }
  export class EditTagCompletedAction implements Action {
    public type: string = ActionTypes.EDIT_TAG_COMPLETED;

    constructor(public payload: Build) {
    }
  }
  export class EditTagFailedAction implements Action {
    public type: string = ActionTypes.EDIT_TAG_FAILED;

    constructor(public payload: any) {}
  }

  export class DeleteTagAction implements Action {
    public type: string = ActionTypes.DELETE_TAG;

    constructor(public payload: any) {
    }
  }
  export class DeleteTagCompletedAction implements Action {
    public type: string = ActionTypes.DELETE_TAG_COMPLETED;

    constructor(public payload: any) {}
  }
  export class DeleteTagFailedAction implements Action {
    public type: string = ActionTypes.DELETE_TAG_FAILED;

    constructor(public payload: any) {}
  }
  export class SelectTagToCompare implements Action {
    public type: string = ActionTypes.SELECT_TAG_TO_COMPARE;

    constructor(public payload: Build) {}
  }

  export class ChangeTagFilterBySubsystemAction implements Action {
    public type: string = ActionTypes.CHANGE_TAG_FILTER_BY_SUBSYSTEMS;

    constructor(public payload: boolean) {}
  }

  export class SelectTagLogRowAction implements Action {
    public type: string = ActionTypes.SELECT_TAG_LOG_ROW;

    constructor(public payload: boolean) {}
  }

  export type Actions =
    | GetTagsAction
    | GetTagsCompletedAction
    | GetTagsFailedAction
    | AddTagAction
    | AddTagCompletedAction
    | AddTagFailedAction
    | SelectTagsAction
    | SelectNewTagAction
    | DeleteTagAction
    | DeleteTagCompletedAction
    | DeleteTagFailedAction
    | SelectTagToCompare
    | EditTagAction
    | EditTagCompletedAction
    | EditTagFailedAction
    | ChangeTagFilterBySubsystemAction
    | SelectTagLogRowAction
    | GetTagSummaryAction
    | GetTagSummaryCompletedAction
    | GetTagSummaryFailedAction
    | SetTagNCompareBasePayloadAction
    | SetTagNCompareBasePayloadCompleteAction
    | GetTagsAlertsAction
    | GetTagsAlertsActionCompleted
    | GetTagsAlertsActionFailed
    | GetTagErrorVolumeAction
    | GetTagErrorVolumeActionCompleted
    | GetTagErrorVolumeActionFailed
    ;
}
