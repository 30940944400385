<div [formGroup]="viewOnlyForm" *ngIf="viewOnlyForm?.controls['to']?.value">
  <div style="display: flex">
    <div class="start-container">
      <div class="date-type">Start</div>
      <div class="ui left icon input">
        <input
          type="time"
          step="0.1"
          timePicker-directive
          formControlName="fromTime"
          class="input-padding"
        />
        <i class="wait icon"></i>
      </div>
      <sh-ng-datepicker
        [isOpened]="true"
        [inline]="true"
        [headless]="true"
        formControlName="from"
      ></sh-ng-datepicker>
    </div>
    <div class="end-container">
      <div class="date-type">End</div>
      <div class="ui left icon input" style="display:inline-block">
        <input
          type="time"
          step="0.1"
          timePicker-directive
          formControlName="toTime"
          placeholder="Start Time"
          class="input-padding"
        />
        <i class="wait icon"></i>
      </div>
      <sh-ng-datepicker
        [isOpened]="true"
        [inline]="true"
        [headless]="true"
        formControlName="to"
      ></sh-ng-datepicker>
    </div>
    <div class="get-logs">
      <button
        [disabled]="!checkDatesValidity"
        (click)="absoluteQuery.emit(newQueryForm.value)"
        mat-raised-button
        color="primary"
      >
        Get Logs!
      </button>
      <div *ngIf="!checkDatesValidity" class="errMsg">
        Query range must be under {{ queryRangeNumber$ | async }} days, the query end
        date must be greater than start date.
      </div>
    </div>
  </div>
</div>
