import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { SamlState } from './saml/saml.state';
import { QuotaState } from './quota/quota.state';
import { CompanyState } from './company/company.state';
import { UserState } from './user/user.state';
import { PermissionsState } from '@app/ngxs-store/permissions/permissions.state';
import { QuotaPolicyState } from './quota-policy/quota-policy.state';
import { QuotaPolicyOverridesState } from './quota-policy-overrides/quota-policy-overrides.state';
import { LoadingBarState } from './loading-bar/loading-bar.state';
import { CloudSecurityState } from './cloud-security/cloud-security.state';
import { InvitesState } from '@app/user/state/invites/invites.state';
import { MappingStatsState } from './mapping-stats/mapping-stats.state';
import { NewMetricsState } from './new-metrics/new-metrics.state';
import { AlertsState } from './alerts/alerts-snooze.state';
import { AuditState } from './audit/audit.state';
import { PayAYouGoState } from './pay-as-you-go/pay-as-you-go.state';
import { PluginsState } from '@app/ngxs-store/plugins/plugins.state';

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot(
      [
        SamlState,
        QuotaState,
        CompanyState,
        UserState,
        PermissionsState,
        QuotaPolicyState,
        QuotaPolicyOverridesState,
        LoadingBarState,
        CloudSecurityState,
        MappingStatsState,
        NewMetricsState,
        AlertsState,
        InvitesState,
        AuditState,
        PayAYouGoState,
        PluginsState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false,
        },
      },
    ),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'NGXS store',
      disabled: environment.production,
    }),
  ],
})
export class NgxsStoreModule {}
