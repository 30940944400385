import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../constants';
import { TypedJSON } from 'typedjson';
import { IInviteData, Invite } from '../models/invite';
import { InvitesResult } from '../models/invite.result';
import { InviteRequestStatusModel } from '../models/invite-request-status.model';
import { map, mapTo } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

@Injectable()
export class InviteService {
  constructor(private http: HttpClient) {}

  public getInvites(): Observable<Invite[]> {
    return this.http
      .get(Constants.inviteUrl)
      .map((res: any) => new TypedJSON(InvitesResult).parse(res))
      .map((inviteRes) => inviteRes.data);
  }

  public addInvites(invites: Invite[]): Observable<Invite[]> {
    return this.http
      .post(Constants.inviteUrl, JSON.stringify(invites))
      .map((res: any) => new TypedJSON(InvitesResult).parse(res))
      .map((inviteRes) => inviteRes.data);
  }

  public getInviteRequestStatus(
    inviteHash: string,
  ): Observable<InviteRequestStatusModel> {
    return this.http
      .get(Constants.inviteRequestUrl + `/${inviteHash}`)
      .map((res: any) => new TypedJSON(InviteRequestStatusModel).parse(res));
  }

  public approveInviteRequests(
    inviteRequest: IInviteData[],
  ): Observable<number[]> {
    return this.http
      .post(
        Constants.inviteRequestUrl + '/approve',
        JSON.stringify(inviteRequest),
        {
            responseType: 'text' as 'json'
        }
      ).map( res => inviteRequest.map(inv => inv.id));
  }

  public declineInviteRequests(
    inviteRequest: IInviteData[],
  ): Observable<number[]> {
    return this.http
      .post(
        Constants.inviteRequestUrl + '/decline',
        JSON.stringify(inviteRequest),
        {
          responseType: 'text' as 'json'
        }
      ).pipe(
        map(_ => inviteRequest.map(inv => inv.id))
      );
  }

  public deleteInvite(invite_id: string): Observable<string> {
    return this.http
      .delete(Constants.inviteUrl + '/' + invite_id)
      .mapTo(invite_id);
  }

  public resendInvite(invite_id: string): Observable<string> {
    return this.http
      .put(Constants.inviteUrl + '/' + invite_id, {})
      .mapTo(invite_id);
  }
}
