import { Component, OnInit } from '@angular/core';
import { QuickQueryPredefinedComponent } from '../quick-query-predefined/quick-query-predefined.component';

@Component({
  selector: 'sh-quick-query-logs',
  templateUrl: './quick-query-logs.component.html',
  styleUrls: ['./quick-query-logs.component.scss'],
})
export class QuickQueryLogsComponent extends QuickQueryPredefinedComponent
  implements OnInit {
  constructor() {
    super();
  }

  public ngOnInit() {}
}
