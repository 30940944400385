<div
  class="context-menu"
  data-test="json-formatter-context-menu"
  [ngStyle]="contextMenu.style"
  *ngIf="showContextMenu$ | async"
  @inOutAnimation
  (shJsonFormatterClickOutside)="closeMenu()"
  #contextMenuEl
>
  <ng-container
    *ngTemplateOutlet="contextMenu.type === 'selection' ? selectionMenu : contextMenu.type === 'value' ? valueMenu : keyMenu"
  ></ng-container>
  <div class="item" (click)="openURL(); $event.stopPropagation()" *ngIf="latestClickedElement.isURL">
    <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/permalink.svg"></svg-icon>
    <div class="text open-url-text">OPEN URL</div>
  </div>
  <div class="item" (click)="showTime(); $event.stopPropagation()" *ngIf="latestClickedElement.isDate">
    <mat-icon class="cgx-icon-secondary show-time-icon">query_builder</mat-icon>
    <div class="text">SHOW VALUE AS TIME</div>
  </div>
</div>

<ng-template #valueMenu>
  <div
    class="item"
    hoverable
    [cgxTooltip]="pluginMenu"
    [originPosition]="{ originX: 'after', originY: 'top' }"
    [overlayPosition]="{ overlayX: 'before', overlayY: 'top' }"
    [layout]="false"
    (showEvent)="onShowSearch()"
  >
    <svg-icon class="icon" svgClass="cgx-icon-secondary svg-icon-plug" src="assets/icons/xs-16x16/plug.svg"></svg-icon>
    <div class="text">ACTIONS</div>
    <div class="json-formatter-menu-flex-1"></div>
    <div class="arrow"></div>
  </div>
  <ng-container *shAllowAbility="'rendererMenu'">
    <div class="item" (click)="addAsFilter(); $event.stopPropagation()" *ngIf="!latestClickedElement.isSingleValue">
      <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/icon-filter.svg"></svg-icon>
      <div class="text">ADD AS A FILTER</div>
    </div>
  </ng-container>
  <ng-container *ngTemplateOutlet="addToQueryTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="viewValueAsJSONTemplate"></ng-container>
  <div class="item" (click)="copyValueToClipboard(); $event.stopPropagation()">
    <svg-icon class="icon" svgClass="cgx-icon-secondary sm-icon" src="assets/icons/copy-json-path.svg"></svg-icon>
    <div class="text">COPY VALUE TO CLIPBOARD</div>
  </div>
  <ng-container *ngTemplateOutlet="addValueAloneToQueryTemplate"></ng-container>
</ng-template>

<ng-template #keyMenu>
  <div
    *ngIf="latestClickedElement.isPrimitiveValue"
    class="item"
    hoverable
    [cgxTooltip]="pluginMenu"
    [originPosition]="{ originX: 'after', originY: 'top' }"
    [overlayPosition]="{ overlayX: 'before', overlayY: 'top' }"
    [layout]="false"
    (showEvent)="onShowSearch()"
  >
    <svg-icon class="icon" svgClass="cgx-icon-secondary svg-icon-plug" src="assets/icons/xs-16x16/plug.svg"></svg-icon>
    <div class="text">ACTIONS</div>
    <div class="json-formatter-menu-flex-1"></div>
    <div class="arrow"></div>
  </div>
  <ng-container *shAllowAbility="'rendererMenu'">
    <div class="item" (click)="addToFilterList(); $event.stopPropagation()">
      <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/icon-filter.svg"></svg-icon>
      <div class="text">ADD TO FILTER LIST</div>
    </div>
    <div class="item" (click)="addAsColumn(); $event.stopPropagation()">
      <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/columns.svg"></svg-icon>
      <div class="text add-as-column-text">ADD AS COLUMN</div>
    </div>
  </ng-container>

  <div class="item" (click)="showGraphForKey(); $event.stopPropagation()" *shAllowAbility="'showGraph'">
    <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/show-graph.svg"></svg-icon>
    <div class="text show-graph-for-key-text">SHOW GRAPH FOR KEY</div>
  </div>
  <div class="item" (click)="copyCompleteJsonPath(); $event.stopPropagation()">
    <svg-icon class="icon" svgClass="cgx-icon-secondary sm-icon" src="assets/icons/copy-json-path.svg"></svg-icon>
    <div class="text">COPY COMPLETE JSON PATH</div>
  </div>
  <ng-container *ngTemplateOutlet="addToQueryTemplate; context: { isKey: true }"></ng-container>
</ng-template>

<ng-template #selectionMenu>
  <ng-container *ngTemplateOutlet="addToQueryTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="viewValueAsJSONTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="addValueAloneToQueryTemplate"></ng-container>
</ng-template>

<ng-template #addToQueryTemplate let-isKey="isKey">
  <div
    class="item"
    (click)="addToSearchQuery({ toInclude: true, isKey: isKey }); $event.stopPropagation()"
    *shAllowAbility="'rendererMenu'"
  >
    <svg-icon class="icon" svgClass="cgx-icon-secondary sm-icon" src="assets/icons/include-in-query.svg"></svg-icon>
    <div class="text">INCLUDE IN QUERY</div>
  </div>
  <div
    class="item"
    (click)="addToSearchQuery({ toInclude: false, isKey: isKey }); $event.stopPropagation()"
    *shAllowAbility="'rendererMenu'"
  >
    <svg-icon class="icon" svgClass="cgx-icon-secondary sm-icon" src="assets/icons/exclude-in-query.svg"></svg-icon>
    <div class="text">EXCLUDE FROM QUERY</div>
  </div>
</ng-template>

<ng-template #addValueAloneToQueryTemplate>
  <div
    class="item"
    (click)="addToSearchQuery({ toInclude: true, queryValueAlone: true }); $event.stopPropagation()"
    *shAllowAbility="'rendererMenu'"
  >
    <svg-icon class="icon" svgClass="cgx-icon-secondary sm-icon" src="assets/icons/query-that-value.svg"></svg-icon>
    <div class="text">QUERY THAT VALUE ALONE</div>
  </div>
</ng-template>

<ng-template #viewValueAsJSONTemplate>
  <div class="item" (click)="viewValueAsJSON(); $event.stopPropagation()" *ngIf="latestClickedElement?.isValidJSON">
    <svg-icon class="icon" svgClass="cgx-icon-secondary" src="assets/icons/view-raw.svg"></svg-icon>
    <div class="text view-value-as-json-text">VIEW VALUE AS JSON</div>
  </div>
</ng-template>

<ng-template #pluginMenu>
  <div class="plugins-sub-menu" (click)="$event.stopPropagation()">
    <cgx-input-field
      #searchPlugin
      style="margin: 0 8px 8px; font-weight: 600;"
      size="sm"
      srcIconEnd="assets/icons/xs-16x16/search.svg"
      placeholder="Search"
      autocomplete="off"
      (input)="searchPrivatePluginChange($event.value); searchSharedPluginChange($event.value)"
    ></cgx-input-field>
    <div *ngIf="!privatePlugins.length && !sharedPlugins.length" class="plugin-list-header">NO RESULTS</div>
    <div *ngIf="privatePlugins.length" class="plugin-list-header">PRIVATE</div>
    <div class="plugin-list">
      <div *ngFor="let plugin of privatePlugins" class="plugin-list-item" (click)="selectPlugin(plugin)">
        {{ plugin.name | uppercase }}
      </div>
    </div>
    <div class="plugin-list-header" *ngIf="sharedPlugins.length">SHARED</div>
    <div class="plugin-list">
      <div *ngFor="let plugin of sharedPlugins" class="plugin-list-item" (click)="selectPlugin(plugin)">
        {{ plugin.name | uppercase }}
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="plugin-list-item" (click)="openPluginsContainer()">MANAGE ACTIONS</div>
  </div>
</ng-template>

<sh-plugins-container #pluginsContainer></sh-plugins-container>
