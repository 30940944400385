import { Metadata } from './query-metadata';
import { QueryExternalFilters } from '@app/logs/shared/models/query-external-filters';

export class TextQuery {
  public text: string = '';
  public type: string = TextTypes.EXACT;
  public templateIds: string[] = [];
}

export enum TextTypes {
  EXACT = 'exact',
  REGEX = 'regex',
}

export interface ISelectedLog {
  id: string;
  index: number;
  comment: string;
  timestamp: number;
  isFocused: boolean;
  isDisabled: boolean;
}

export class QueryParams {
  public query: TextQuery = new TextQuery();
  public templateIds?: string[] = [];
  public metadata: Metadata = new Metadata();
  public jsonObject?: { [key: string]: any[] } = {};
  public aggField?: string;
  public jsonAggFields?: string[] = [];
  public ts_agg?: string = null;
  public aggregationInterval: number = 1000;
  public externalFilters?: QueryExternalFilters = new QueryExternalFilters();
  public selectedLogs: ISelectedLog[] = [];

  constructor() {}
}

export interface IQueryParams {
  query: TextQuery;
  templateIds?: string[];
  metadata: Metadata;
  jsonObject?: any;
  aggField?: string;
  jsonAggFields?: string[];
  ts_agg?: string;
  aggregationInterval: number;
  selectedLogs?: ISelectedLog[];
  externalFilters?: QueryExternalFilters;
}
