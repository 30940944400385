<div>
  <div
    *ngFor="let quickQuery of predefineQueries.Queries"
    style="width: 30%;display: inline-block;margin: 0px;"
  >
    <span
      class="predefine-label"
      (click)="selectedValueChange.emit(quickQuery)"
    >
      {{ quickQuery.caption }}
    </span>
  </div>
</div>
