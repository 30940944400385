import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IStatisticsViewData } from '@app/deployment/shared/models/summary.models';

@Component({
  selector: 'sh-statistics-tile',
  templateUrl: './statistics-tile.component.html',
  styleUrls: ['./statistics-tile.component.scss']
})
export class StatisticsTileComponent {
  public statisticsResType: StatisticsResultTypes = StatisticsResultTypes.percentage;
  @Input() public statistic: IStatisticsViewData;
  @Output() public redirectByType: EventEmitter<IRedirectByTypeOutput> = new EventEmitter<IRedirectByTypeOutput>();
}

export interface IRedirectByTypeOutput {
  type: any;
  link: string;
}

export enum StatisticsResultTypes {
  number = 1,
  percentage= 2
}
