import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialogComponent } from '../controls/delete-dialog/delete-dialog.component';
import { ComponentType } from '@angular/cdk/portal';
import { Observable } from 'rxjs';
import { CoralogixSnackbarComponent } from '@shared/popups/coralogix-snackbar/coralogix-snackbar.component';
import { dialogServiceIconClasses } from '../models/dialog-service.models';
import { DeleteDialogV2Component } from '@shared/controls/delete-dialog-v2/delete-dialog-v2.component';
import { InputDialogComponent } from '@shared/controls/input-dialog/input-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ShDialogService {
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  public openCustomDialog<T>(componentRef: ComponentType<T>, config?: MatDialogConfig, data?: any): MatDialogRef<T> {
    // config.data = data;
    const dialogRef = this.dialog.open(componentRef, config);
    return dialogRef;
  }

  public openDialog(title: string, message: string, action: string): Observable<any> {
    const config: MatDialogConfig = new MatDialogConfig();
    config.role = 'alertdialog';

    config.data = {
      title,
      message,
      action,
    };

    const dialogRef = this.dialog.open(DeleteDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openDialogV2(title: string, message: string, action: string): Observable<any> {
    const config: MatDialogConfig = new MatDialogConfig();
    config.role = 'alertdialog';
    config.data = {
      title,
      message,
      action,
    };
    config.panelClass = 'custom-delete-v2-dialog';
    const dialogRef = this.dialog.open(DeleteDialogV2Component, config);
    return dialogRef.afterClosed();
  }

  public openInputDialog(title: string, message: string, action: string, placeholder: string): Observable<any> {
    const config: MatDialogConfig = new MatDialogConfig();
    config.role = 'alertdialog';
    config.data = {
      title,
      message,
      action,
      placeholder
    };
    config.panelClass = 'custom-input-dialog';
    const dialogRef = this.dialog.open(InputDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openDeleteDialog(type: string, itemName: string): Observable<any> {
    return this.openDialog(
      `Delete ${type}`,
      `You are about to delete ${type} "${itemName}", are you sure you want to proceed?`,
      'Delete',
    );
  }

  public showSnackBar(message: string, action?: string, time: number = 2000): void {
    this.snackBar.open(message, action, {
      duration: time,
    });
  }

  public showCustomSnackBar<T>(
    componentRef: ComponentType<T>,
    data: any,
    time: number = 2000,
    panelClass?: string[],
  ): void {
    this.snackBar.openFromComponent(componentRef, {
      data,
      duration: time,
      panelClass,
    });
  }

  public showCoralogixMessage(
    message: string,
    link: string = null,
    iconClass: dialogServiceIconClasses = dialogServiceIconClasses.success,
  ): void {
    this.snackBar.openFromComponent(CoralogixSnackbarComponent, {
      data: { message, link, iconClass },
      duration: link ? 6000 : 3000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: ['coralogix'],
    });
  }

  public showErrorMessage(message: string, link: string = null): void {
    this.showCoralogixMessage(message, link, dialogServiceIconClasses.failed);
  }
}
