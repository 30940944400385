import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
declare var $: any;

@Component({
  selector: 'sh-quick-query-container',
  templateUrl: './quick-query-container.component.html',
  styleUrls: ['./quick-query-container.component.scss'],
})
export class QuickQueryContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('quickQueryIcon', { static: true })
  public quickQueryIconElm: ElementRef;
  public selectedPredefinedValue: string;

  @Output() public selectedValueChange: EventEmitter<any> = new EventEmitter<
    any
  >();

  constructor() {
    this.selectedPredefinedValue = 'Query Shortcuts';
  }

  public onSelectedValueChange(event) {
    // this.selectedPredefinedValue = event.caption;
    this.selectedValueChange.emit(event);
  }

  public ngOnInit() {}

  public ngAfterViewInit() {
    $(this.quickQueryIconElm.nativeElement).popup({
      popup: '.myPop',
      hoverable: true,
      overflow: 'auto',
      // lastResort: 'true',
      on: 'hover',
      position: 'bottom left',
      delay: { hide: 800 },
    });
  }
}
