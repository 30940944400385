import { Injectable } from '@angular/core';
import { ThemeType } from '../models/theme.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  public theme$ = new BehaviorSubject<ThemeType>(null);
  constructor() {}

  get theme(): ThemeType {
    return document.documentElement.getAttribute('theme') as ThemeType;
  }

  set theme(name: ThemeType) {
    document.documentElement.setAttribute('theme', name);
    this.theme$.next(this.theme);
  }

  public toggleTheme(): void {
    document.body.classList.add('theme-transition');
    this.theme = this.theme === 'light' ? 'dark' : 'light';
    const TRANSITION_MS = 400;
    setTimeout(() => document.body.classList.remove('theme-transition'), TRANSITION_MS);
  }
}
