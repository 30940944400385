import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';

export class NotOnlySpacesValidator {
  public static checkValidity(control: AbstractControl): Observable<ValidationErrors | null> {
    let returnVal: {[validatorName: string] : boolean} = null;
    if (control && control.value && control.value.length) {
      const stringToCheck: string = control.value.trim();
      if (stringToCheck.length === 0) {
        returnVal = { required: true };
      }
    }
    return of(returnVal);
  }
}
