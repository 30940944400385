<mat-card class="coralogix">
  <p class="mat-card-header">{{ title }}</p>

  <mat-radio-group [(ngModel)]="value" class="radio-group">
    <div
      *ngFor="let item of options"
      data-test="radio-btn-group-item"
      (click)="onItemClick(item)"
      class="radio-group-container"
      [attr.radio-group-checked]="value === item.value"
      [class.radio-group-checked]="value === item.value"
    >
      <mat-radio-button [checked]="value === item.value" class="radio-group-title uppercase" [value]="item.value">
        <span>{{ item.name }}</span>
        <div class="badge-container" *ngIf="item.badge">
          <span class="badge">{{ item.badge }}</span>
        </div>
      </mat-radio-button>
      <p class="radio-group-description">{{ item.description }}</p>
    </div>
  </mat-radio-group>
</mat-card>
