import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropdownMenuEntity } from '@shared/models/entity.model';

@Component({
  selector: 'sh-drop-down-button-menu',
  templateUrl: './drop-down-button-menu.component.html',
  styleUrls: ['./drop-down-button-menu.component.scss']
})
export class DropDownButtonMenuComponent {
  @Input() public menuOptions: IDropdownMenuEntity[] | string[];
  @Input() public buttonText: string = 'Select Option';
  @Input() public buttonHeight: string = '40px';
  @Input() public menuButtonTheme: ButtonMenuThemeOptions = ButtonMenuThemeOptions.primary;
  @Output() public buttonPressed: EventEmitter<string> = new EventEmitter<string>();
}

export enum ButtonMenuThemeOptions {
  primary= 'primary',
  secondary = 'secondary'
}
