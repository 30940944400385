import { RulesMainViewMode } from '@app/rules/shared/models/shared-vew.models';
import { IParsingTheme } from '@app/rules/shared/models/rule.model';
import { getPTListEmptyFilters, IParsingThemeFilter } from '@app/rules/shared/models/parsing-themes-filters';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as parsingThemesActions from '@app/rules/actions/parsing-themes.actions';
import { environment } from '@environments/environment';

export interface IParsingThemesReducerState {
    parsingThemes: IParsingTheme[];
    selectedParsingTheme: IParsingTheme;
    loading: boolean;
    rulesLeft: number;
    maxAllowedRules: number;
    mainViewState: RulesMainViewMode;
    parsingThemesFilter: IParsingThemeFilter;
    cachedParsingThemesOrder: IParsingTheme[];
    extendedUniqueFields: string[];
  }

  const initialState: IParsingThemesReducerState = {
    parsingThemes: [],
    cachedParsingThemesOrder: [],
    selectedParsingTheme: null,
    loading: false,
    rulesLeft: setLimitsForEnv(30),
    maxAllowedRules: setLimitsForEnv(30),
    mainViewState: RulesMainViewMode.empty,
    parsingThemesFilter: getPTListEmptyFilters(),
    extendedUniqueFields: []
  };

  function setLimitsForEnv(limit: number): number {
    return environment['standAlone'] ? Infinity : 30;
  }

  const parsingTeamsReducer: ActionReducer<IParsingThemesReducerState, Action> = createReducer(
    initialState,
    on(parsingThemesActions.getParsingThemesCompletedAction,
      (state, { payload }) => ({
        ...state,
        parsingThemes: payload?.parsingThemes || [],
        mainViewState: payload?.parsingThemes?.length ? RulesMainViewMode.list : RulesMainViewMode.welcome,
        maxAllowedRules: payload?.maxAllowedRules || state?.maxAllowedRules,
        rulesLeft: (payload?.maxAllowedRules || state.maxAllowedRules) - getAllRulesInAllParsingThemes(payload?.parsingThemes)
      })
    ),
    on(parsingThemesActions.updateParsingThemesOrderCompletedAction, (state, { payload }) => ({
      ...state,
      parsingThemes: payload
    })),
    on(parsingThemesActions.setParsingThemeMainViewAction, (state, { payload }) => ({
      ...state,
      mainViewState: payload
    })),
    on(parsingThemesActions.updateParsingThemeCompletedAction, (state, { payload }) => {
      const currList = [...state.parsingThemes];
      const ind = currList.findIndex((item) => item.id === payload.id);
      currList[ind] = payload;
        return { ...state, parsingThemes: currList };
    }),
    on(parsingThemesActions.createParsingThemeCompletedAction, (state, { payload }) => {
      const currList = [...state.parsingThemes, payload];
      const mainViewState = currList.length ? RulesMainViewMode.list : state.mainViewState;
      return { ...state, parsingThemes: currList, mainViewState };
    }),
    on(parsingThemesActions.setParsingThemeFiltersAction, (state, { payload }) => ({
      ...state,
      parsingThemesFilter: payload
    })),
    on(parsingThemesActions.toggleParsingThemeAction, (state, { payload }) => (
      getUpdatedParsingThemesEnabledState(state, payload.parsingThemeId, payload.toggleState))
    ),
    on(parsingThemesActions.toggleParsingThemeFailedAction, (state, { payload }) => (
      getUpdatedParsingThemesEnabledState(state, payload.parsingThemeId, !payload.toggleState))
    ),
    on(parsingThemesActions.updateParsingThemesOrderAction, (state, { payload }) => {
      payload.forEach((pt, i) => pt.order = i + 1);

      return { ...state,
        cachedParsingThemesOrder: state.parsingThemes,
        parsingThemes: payload,
      };
    }),
    on(parsingThemesActions.updateParsingThemesOrderFailedAction, (state, { payload }) => {
      return { ...state, parsingThemes: state.cachedParsingThemesOrder };
    }),
    on(parsingThemesActions.deleteParsingThemeCompletedAction, (state, { payload }) => {
        const _parsingThemes = state.parsingThemes.filter((pt) => payload !== pt.id);
        return {
          ...state,
          parsingThemes: _parsingThemes.map((pt, index) => ({ ...pt, order: index + 1 })),
          mainViewState: _parsingThemes.length ? RulesMainViewMode.list : RulesMainViewMode.welcome
        };
    }),
    on(parsingThemesActions.getParsingThemeExtendedUniqueFieldsCompleted, (state, { payload }) => {
      return {
        ...state,
        extendedUniqueFields: payload
      };
  })
  );

  export function reducer(state: IParsingThemesReducerState = initialState, action: Action): IParsingThemesReducerState {
    return parsingTeamsReducer(state, action);
  }

  function getAllRulesInAllParsingThemes(parsingThemes: IParsingTheme[]): number {
    return parsingThemes.reduce(
      (acc, parsingTheme) => {
        const sum = parsingTheme.rulesGroups?.reduce((_acc, group) => {
          const numOfRules = group?.rules?.length ? group.rules.length : 0;
          return _acc + numOfRules;
        }, 0) || 0;
        return acc + sum;
      }, 0);
  }

  const getUpdatedParsingThemesEnabledState = (state: IParsingThemesReducerState,
    id: string,
    toggleState: boolean): IParsingThemesReducerState => {
      const parsingThemes = state.parsingThemes.map(pt => pt.id === id ? { ...pt, enabled: toggleState } : pt );
      return { ...state, parsingThemes };
  };
