import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Entity } from '@shared/models/entity.model';
import { AlertEditControls } from '@app/alerts/alerts-editor/models/alert-edit-controls.model';
import * as alertActions from '@app/alerts/shared/actions/alerts.actions';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { ConditionalSelectService } from '@shared/components/conditional-select/conditional-select.service';
import { AlertSyntaxType } from '@app/alerts/alerts-editor/models/alert-editor-view.models';

type MetadataField = 'applicationName' | 'subsystemName';

@Component({
  selector: 'sh-alerts-query',
  templateUrl: './alerts-query.component.html',
  styleUrls: ['./alerts-query.component.scss'],
})
export class AlertsQueryComponent {
  @Input()
  public form: FormGroup;
  @Input()
  public logsSeverityOptions: Entity[];
  @Input()
  public applicationOptions: string[];
  @Input()
  public subsystemOptions: string[];
  @Input()
  public isFromQuery: boolean;
  @Input()
  public isPromQLSyntax: boolean;
  @Input()
  public isRelative: boolean = false;
  @Input()
  public isMetric: boolean = false;
  @Input()
  public queryIndex: number = -1;
  @Input()
  public invalidErrMsg: string;

  private requiredFields: string[] = ['applicationName', 'subsystemName', 'logSeverity', 'alertText'];
  private requiredRelativeFields: string[] = ['alias'];

  constructor(private store: Store<State>, private conditionalSelectService: ConditionalSelectService) {}

  public get isFormInvalid(): boolean {
    const mustFields = [...(this.isRelative ? this.requiredRelativeFields : []), ...this.requiredFields];
    if (this.isMetric) {
      return false;
    }
    const entries = Object.entries(this.form.value);
    const emptyEntries = entries.filter(([key, value]: [string, any]) => {
      return mustFields.includes(key) && (!value || value.length <= 0);
    });

    return emptyEntries.length > this.requiredFields.length - 1 && this.form.touched;
  }

  public get query(): Partial<AlertEditControls> {
    return this.form.value;
  }

  public get severity(): string[] {
    const severity = [];
    if (this.query.logSeverity) {
      this.query.logSeverity.forEach(item =>
        this.logsSeverityOptions.some(option => {
          if (option.id === item) {
            severity.push(option.name);
          }
        }),
      );
    }
    return severity;
  }

  public onEdit(): void {
    this.isFromQuery = false;
  }

  public changeInvalidErr(ev: boolean): void {
    this.store.dispatch(alertActions.updateOpenAlertInvalidQueryAction({ payload: null }));
  }

  public validateMetadata(field: MetadataField): boolean {
    return this.conditionalSelectService.validateControl(this.form.controls[field] as FormControl);
  }

  public getQueryDescription(): string {
    if (this.isMetric && this.query.alertSyntaxType !== AlertSyntaxType.PromQL) {
      return (
        'The metric documents contain the following properties: ' +
        'timestamp, matcherId, name, metadata.companyId, ' +
        'labels.<labelname>.value and metrics.<fieldname> for Prometheus Metrics or metrics.<fieldname>.value for Logs2Metrics'
      );
    }
    return '';
  }

  public getPlaceholder(): string {
    if (this.query.alertSyntaxType === AlertSyntaxType.PromQL) {
      return 'Enter a PromQL Query, or leave blank to match any text';
    }
    return 'Enter a text string, use the Elasticsearch Query Syntax, or leave blank to match any text';
  }
}
