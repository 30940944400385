import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver/FileSaver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Constants } from '@app/constants';
import {
  ArchiveQueryRequest,
  IArchiveQueriesResponse,
  IArchiveQuery, IArchiveQueryBucketPermission,
  IArchiveQueryPreviewResponse,
  IArchiveQueryReindexEstimateResponse,
  ICachedQueryResponse,
  IFile,
  IReindex,
} from '@app/roi/archive-queries/models/archive-query.model';
import {filter, map, take} from 'rxjs/operators';
import { LivetailEntity } from '@app/livetail/shared/models/livetailEntity';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { ShDialogService } from '@shared/services/dialogService';
import moment, { Moment } from 'moment';
import { ArchiveQueryActions } from '@app/roi/archive-queries/state/actions/archive-query.actions';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import {DownloadResultsFormComponent} from "@app/roi/archive-queries/components/download-results-form/download-results-form.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class ArchiveQueriesService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private dialogService: ShDialogService, private store: Store<State>) {
  }

  public getArchiveQueries(pageIndex: number, pageSize: number, filter: string): Observable<IArchiveQueriesResponse> {
    return this.http.get(Constants.archiveQueriesUrl, {
      params: {
        pageIndex: pageIndex?.toString(),
        pageSize: pageSize?.toString(),
        filter: filter
      }
    }).pipe(map((res: IArchiveQueriesResponse) => res));
  }

  public createArchiveQuery(archiveQueryReq: ArchiveQueryRequest): Observable<IArchiveQuery> {
    return this.http.post(Constants.archiveQueriesUrl, archiveQueryReq).pipe(map((res: IArchiveQuery) => res));
  }

  public getArchiveQueryResults(id: string): Observable<IFile[]> {
    return this.http.get(`${Constants.archiveQueriesUrl}/${id}/results`).pipe(map((res: IFile[]) => res));
  }

  public createArchiveQueryReindexEstimation(id: string, name: string): Observable<IArchiveQueryReindexEstimateResponse> {
    return this.http.post(
      `${Constants.archiveQueriesUrl}/${id}/estimate`, {})
      .map((res: IArchiveQueryReindexEstimateResponse) => res)
      .catch(() => {
        this.store.dispatch(new ArchiveQueryActions.ClearLoadingAction());
        this.dialogService.showCoralogixMessage(`Failed to Reindex query: ${name}`, null, dialogServiceIconClasses.failed);
        return of({ estimation: null });
      }, );
  }

  public createArchiveQueryReindex(id: string): Observable<IReindex> {
    return this.http.post(`${Constants.archiveQueriesUrl}/${id}/reindex`, {}).pipe(map((res: IReindex) => res));
  }

  public downloadFiles(file: IFile): Observable<void> {
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    return this.http.get(`${file.link}`, { headers: headers, responseType: 'blob' }).pipe(map((res) => {
      saveAs(res, file.fileName);
    }));
  }

  public getUnschematedLogs(id: string): Observable<IArchiveQueryPreviewResponse> {
    return this.http.get(
      `${Constants.archiveQueriesUrl}/${id}/results-view`)
      .pipe(map((response: IArchiveQueryPreviewResponse) => {
        return response;
      }));
  }

  public getStoredLogs(id: string, filterType: string, filter: string): Observable<LivetailEntity[]> {
    return this.http.get(
      `${Constants.archiveQueriesUrl}/${id}/results-view`,
      {
        params: {
          filterType: filterType,
          filter: filter
        }
      }).pipe(
      map((response: { logs: Partial<LivetailEntity>[] }) => response.logs.map(log => new LivetailEntity(log)))
    );
  }

  public createCachedQuery(archiveQuery: IArchiveQuery): Observable<ICachedQueryResponse> {
    return this.http
      .post(`${Constants.archiveQueriesUrl}/${archiveQuery.id}/reindex/${archiveQuery.reindexes[0].id}/query`, {})
      .map((res: ICachedQueryResponse) => res);
  }

  public setEndAndStartTime(from: Date, to: Date, startTime: string, endTime: string, isUtc: boolean = false):
    { newStartTime: Moment, newEndTime: Moment } {
    const startTimeArr = startTime.split(':');
    const endTimeArr = endTime.split(':');
    const newStartTime = ArchiveQueriesService.getTimestamp(from, +startTimeArr[0], +startTimeArr[1], isUtc);
    const newEndTime = ArchiveQueriesService.getTimestamp(to, +endTimeArr[0], +endTimeArr[1], isUtc);
    return {
      newStartTime,
      newEndTime
    };
  }

  public hasBucketPermissions(): Observable<IArchiveQueryBucketPermission> {
    return this.http
      .get(Constants.GetUrl() + Constants.archivingProvider + '/permissions')
      .map((res: IArchiveQueryBucketPermission) => res);
  }

  private static getTimestamp(date: Date, hour: number, minute: number, itUtc: boolean): moment.Moment {
    return itUtc ?
      moment(date)
      .set('hour', hour)
      .set('minute', minute)
      .set('seconds', 0)
      .set('millisecond', 0)
      .utcOffset(0, true) :
      moment(date)
      .set('hour', hour)
      .set('minute', minute)
      .set('seconds', 0)
      .set('millisecond', 0)
      .utc();
  }

}
