import { Action } from '@ngrx/store';
import { type } from '../../../../../shared/services/utils';

export namespace LogsFilterGridColumnsActions {
  export const ActionTypes = {
    LOGS_FILTER_GRID_COLUMNS_INIT: type(
      '[LogsFilterGridColumns] Logs Filter Grid Columns Init',
    ),
    LOGS_FILTER_GRID_TEMPLATE_COLUMNS_INIT: type(
      '[LogsFilterGridTemplateColumns] Logs Filter Grid Template Columns Init',
    ),
    LOGS_FILTER_GRID_COLUMNS_INIT_COMPLETED: type(
      '[LogsFilterGridColumns] Logs Filter Grid Columns Init Completed',
    ),
    LOGS_FILTER_GRID_COLUMNS_FAILURE: type(
      '[LogsFilterGridColumns] Logs Filter Grid Columns Failure',
    ),
    SELECT_DEFAULT_COLUMNS: type(
      '[LogsFilterGridColumns] Select Default Columns',
    ),
    SET_SELECTED_COLUMNS: type('[LogsFilterGridColumns] Set Selected Columns'),
    SELECT_COLUMNS: type('[LogsFilterGridColumns] Select Columns'),
    DESELECT_COLUMNS: type('[LogsFilterGridColumns] Deselect Columns'),
    COLUMNS_SELECTED: type(
      '[LogsFilterGridColumns] Logs Filter Grid Columns Selected',
    ),
    COLUMNS_DESELECTED: type(
      '[LogsFilterGridColumns] Logs Filter Grid Columns Deselected',
    ),
    SET_FILTER_VISIBILITY: type(
      '[LogsFilterGridColumns] Set Filter Visibility',
    ),
  };

  export class LogsFilterGridColumnsInit implements Action {
    public type: string = ActionTypes.LOGS_FILTER_GRID_COLUMNS_INIT;

    constructor(public payload?: any) {}
  }

  export class LogsFilterGridTemplateColumnsInit implements Action {
    public type: string = ActionTypes.LOGS_FILTER_GRID_TEMPLATE_COLUMNS_INIT;

    constructor(public payload?: any) {}
  }

  export class LogsFilterGridColumnsInitCompleted implements Action {
    public type: string = ActionTypes.LOGS_FILTER_GRID_COLUMNS_INIT_COMPLETED;

    constructor(public payload?: any) {}
  }

  export class SelectLogsFilterGridDefualtColumns implements Action {
    public type: string = ActionTypes.SELECT_DEFAULT_COLUMNS;

    constructor(public payload?: any) {}
  }

  export class SetSelectedLogsFilterGridColumns implements Action {
    public type: string = ActionTypes.SET_SELECTED_COLUMNS;

    constructor(public payload?: any) {}
  }

  export class SelectLogsFilterGridColumns implements Action {
    public type: string = ActionTypes.SELECT_COLUMNS;

    constructor(public payload?: any) {}
  }

  export class DeselectLogsFilterGridColumns implements Action {
    public type: string = ActionTypes.DESELECT_COLUMNS;

    constructor(public payload?: any) {}
  }

  export class LogsFilterGridColumnsSelected implements Action {
    public type: string = ActionTypes.COLUMNS_SELECTED;

    constructor(public payload?: any) {}
  }

  export class LogsFilterGridColumnsDeselected implements Action {
    public type: string = ActionTypes.COLUMNS_DESELECTED;

    constructor(public payload?: any) {}
  }

  export class SetVisibilityLogsFilterGridColumns implements Action {
    public type: string = ActionTypes.SET_FILTER_VISIBILITY;

    constructor(public payload?: boolean) {}
  }

  export class LogsFilterGridColumnsFailure implements Action {
    public type: string = ActionTypes.LOGS_FILTER_GRID_COLUMNS_FAILURE;

    constructor(public payload?: any) {}
  }

  export type Actions =
    | LogsFilterGridColumnsInit
    | LogsFilterGridTemplateColumnsInit
    | LogsFilterGridColumnsInitCompleted
    | SelectLogsFilterGridColumns
    | DeselectLogsFilterGridColumns
    | SetVisibilityLogsFilterGridColumns
    | LogsFilterGridColumnsSelected
    | LogsFilterGridColumnsDeselected
    | LogsFilterGridColumnsFailure
    | SelectLogsFilterGridDefualtColumns
    | SetSelectedLogsFilterGridColumns;
}
