import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-ng2/main';
import { formatsHelper } from '../../../shared/services/formatsHelper';

@Component({
  selector: 'sh-loggregation-occurrences-grid-cell',
  templateUrl: './loggregation-occurrences-grid-cell.component.html',
  styleUrls: ['loggregation-occurrences-grid-cell.component.scss']
})
export class LoggregationOccurrencesGridCellComponent implements OnInit, AgRendererComponent {
  public params: any;
  public value: any;

  constructor() {
  }

  public ngOnInit() {
  }

  public agInit(params: any): void {
    this.params = params;
    this.value = formatsHelper.numberWithCommas(params.value);
  }

  public onClick() {
    this.params.context.showModal(this.params.node.data.id);
  }

}
