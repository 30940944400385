import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LoggregationService} from '../../shared/services/loggregation.service';
import {LogQueryModel} from '@app/logs/shared/models/logsquery.model';
import {LoggregationEntity} from '../../shared/interfaces/loggregation.interface';
import * as _ from 'lodash';
import {StatisticsQueryDefinition} from '@app/statistics/shared/models/statistics-query-definition';
import {QueryTypes} from '@app/logs/shared/models/query-types.options';
import {Metadata} from '@app/logs/shared/models/query-metadata';
import {SeveritiesHelper} from '@shared/services/severities.helper';
import {LoggregationPipe} from '@shared/pipes/loggregation.pipe';
import {Router} from '@angular/router';
import {LogsService} from '@app/logs/shared/services/logs-service';
import {ChartModel, ChartSeriesModel} from '@shared/controls/charts/models/chart.model';
import {WidgetBaseComponent} from '@shared/components/widget-base-component';
import {LogsQueryService} from '@app/logs-new/shared/services/logs-query.service';

@Component({
  selector: 'sh-suspected-template',
  templateUrl: './suspected-template.component.html',
  styleUrls: ['./suspected-template.component.scss'],
})
export class SuspectedTemplateComponent extends WidgetBaseComponent {
  @Input() set queryModel(queryModel: LogQueryModel) {
    this.suspectedTemplates = [];
    this.isReady.emit(false);
    this.isLoading = true;
    if (!queryModel) {
      return;
    }

    let result: Array<LoggregationEntity>;
    this.loggregationService
      .getLoggregationResult(queryModel)
      .map(allLoggregationResult => {
        result = allLoggregationResult;
        const templateIds = [];
        allLoggregationResult.forEach(loggregation => templateIds.push(loggregation.id));
        if (allLoggregationResult.length === 0) {
          this.suspectedTemplates = [];
          this.isReady.emit(true);
          this.isLoading = false;
        }
        return templateIds;
      })
      .switchMap(templateIdsResult =>
        this.loggregationService.getOccurrencesDetailBytemplateIds(templateIdsResult, 'templateGlobalAggStats', queryModel),
      )
      .map(normalBehaviorResults => {
        const templateToNormal: Map<string, number> = new Map<string, number>();
        normalBehaviorResults.forEach(normalResult => {
          if (normalResult) {
            let count = 0;
            normalResult.data.data.yAxis.values.forEach(value => {
              count += value.count;
            });
            templateToNormal.set(normalResult.templateId, count);
          }
        });
        return templateToNormal;
      })
      .map(templateToNormalResult => {
        const anomolusTemplates = [];
        result.forEach(loggregationEntity => {
          const normal = templateToNormalResult.get(loggregationEntity.id);
          loggregationEntity.normalCount = normal;
          if (normal === 0) {
            loggregationEntity.countToNormalRation = loggregationEntity.count * 1000;
          } else {
            loggregationEntity.countToNormalRation = 100 / (normal / loggregationEntity.count) - 100;
          }
          if (loggregationEntity.countToNormalRation > this.anomalyFactor) {
            anomolusTemplates.push(loggregationEntity);
          }
        });
        return anomolusTemplates;
      })
      .first()
      .subscribe(
        res => {
          const suspectedTemplateResults: SuspectedTemplateResult[] = [];
          const loggregationResult = this.getTopByRationResult(res, this.maxResult);
          loggregationResult.forEach(l => {
            const suspectedTemplateResult = new SuspectedTemplateResult();
            suspectedTemplateResult.loggregationEntity = l;
            suspectedTemplateResult.logQueryModel = _.cloneDeep<LogQueryModel>(queryModel);
            suspectedTemplateResult.logQueryModel.cacheQueryId = this.logsService.getNewQueryId();
            suspectedTemplateResult.logQueryModel.queryParams.query.text = `coralogix.templateId:"${l.id}"`;
            suspectedTemplateResult.logQueryModel.type = QueryTypes.TEMPLATE;
            suspectedTemplateResult.logQueryModel.queryParams.metadata = new Metadata();
            suspectedTemplateResult.logQueryModel.queryParams.aggregationInterval = LogsQueryService.getAggregationInterval(
              suspectedTemplateResult.logQueryModel.startDate,
              suspectedTemplateResult.logQueryModel.endDate,
            );
            suspectedTemplateResult.logQueryModel.queryParams.metadata.applicationName.push(l.metadata.applicationName);
            suspectedTemplateResult.logQueryModel.queryParams.metadata.subsystemName.push(l.metadata.subsystemName);
            suspectedTemplateResult.logQueryModel.queryParams.metadata.severity.push(
              SeveritiesHelper.getLogSeverityByName(l.metadata.severity),
            );
            if (!suspectedTemplateResult.link) {
              this.logsService
                .saveLogsQuery(suspectedTemplateResult.logQueryModel)
                .first()
                .subscribe(() => {
                  suspectedTemplateResult.link = '/#/query-new/logs?id=' + suspectedTemplateResult.logQueryModel.cacheQueryId;
                });
            }

            const model = new ChartModel();
            model.seriesType = 'column';
            model.series = new Array<ChartSeriesModel>();
            model.backgroundColor = 'transparent';

            const normal = new ChartSeriesModel();
            normal.data = [];

            normal.data.push(['Normal', Math.floor(l.normalCount * 100) / 100]);
            model.categories = ['current', 'normal'];
            normal.type = 'bar';
            normal.name = 'Normal Behavior';
            normal.color = 'rgba(72,201,176,0.3)';

            const current = new ChartSeriesModel();
            current.data = [];
            current.data.push(['Current', l.count]);
            current.type = 'bar';
            current.name = 'Current Query';
            current.color = 'rgba(255, 0, 0, 0.3)';

            model.xType = 'category';
            model.series.push(current);
            model.series.push(normal);

            model.height = 65;
            model.lineWidth = 0;
            model.minorGridLineWidth = 0;
            model.fontSize = '5px';
            model.inverted = true;
            model.tooltipEnabled = false;
            //  model.tooltipValueSuffix = '%';
            model.enableDataLabel = true;
            model.highlightedXlables.set('Current', 'red');
            suspectedTemplateResult.chartModel = model;

            suspectedTemplateResults.push(suspectedTemplateResult);
          });
          this.suspectedTemplates = suspectedTemplateResults;

          this.isReady.emit(true);
          this.isLoading = false;
        },
        err => {
          console.log(err);
          this.isReady.emit(true);
          this.isLoading = false;
        },
      );
  }

  public suspectedTemplates: Array<SuspectedTemplateResult>;
  @Input() public maxResult: number = 0;
  @Input() public excludedTemplates: any[] = [];
  @Input() public anomalyFactor: number = 5;
  @Input() public theme: string = '';
  @Output() public isReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  private loggregationPipe: LoggregationPipe = new LoggregationPipe();
  private loggregationParamPattern: RegExp = new RegExp(/\{CoralogixPH(\d+)_([^]*?.*?)_([free|categorical|numeric]+)_CoralogixPH\}/g);
  private loggregationJsonParamPattern: RegExp = new RegExp(/\{CoralogixJsonPH(\d+)_([^]*?.*?)_([json]+)_CoralogixJsonPH\}/g);

  constructor(private loggregationService: LoggregationService, private router: Router, private logsService: LogsService) {
    super();
    this.loggregationPipe.clickableParam = '$2';
  }

  public getTopByRationResult(loggregationEntities: Array<LoggregationEntity>, limit: number): Array<LoggregationEntity> {
    const result = _.orderBy(
      loggregationEntities,
      (entity: LoggregationEntity) => {
        return entity.countToNormalRation;
      },
      'desc',
    );
    const bySeverity = _.orderBy(
      result,
      (entity: LoggregationEntity) => {
        return SeveritiesHelper.getLogSeverityByName(entity.metadata.severity);
      },
      'desc',
    );
    if (limit !== 0) {
      return bySeverity.slice(0, limit);
    }

    return bySeverity;
  }

  severityColor(colorName: string): { [className: string]: boolean } {
    return {
      ['text-cgx-' + colorName]: true
    }
  }
}

class SuspectedTemplateResult {
  public loggregationEntity: LoggregationEntity;
  public statisticsQueryDefinition: StatisticsQueryDefinition;
  public chartModel: ChartModel;
  public logQueryModel: LogQueryModel;
  public link: string;
}
