import { Component, OnInit, OnDestroy } from '@angular/core';
import { InsightsProvider } from '../../shared/services/insights.provider';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { LoggregationService } from '@app/loggregation/shared/services/loggregation.service';
import { Subscription } from 'rxjs';
import { InsightsHelper } from '../../shared/helpers/insightsHelper';
import { formatsHelper } from '@shared/services/formatsHelper';
import { Insight } from '../../shared/models/insight.model';
import { Alert } from '../../shared/models/alert.model';
import { LogqueryModelTranslator } from '@app/logs/shared/translators/logquery-model.translator';

import { LogActions } from '@app/logs/shared/state/log.actions';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';

@Component({
  selector: 'sh-insights-loggregation',
  templateUrl: './insights-loggregation.component.html',
  styleUrls: ['./insights-loggregation.component.scss'],
})
export class InsightsLoggregationComponent implements OnInit, OnDestroy {
  public highlightedTemplates: string[] = [];
  public data: any;
  public set queryModel(data: LogQueryModel) {
    this._queryModel = data;
    this.store.dispatch(new LogActions.SetLogPanelQuery(data));
  }
  public get queryModel(): LogQueryModel {
    return this._queryModel;
  }
  private _queryModel: LogQueryModel;
  private newLoggregationSubscirption: Subscription;
  private currentInsightsChangedSubscirption: Subscription;
  constructor(
    private insightsProvider: InsightsProvider,
    private loggregationService: LoggregationService,
    private store: Store<State>
  ) {}

  public ngOnInit(): void {
    // TODO: NG4 remove do check
    setTimeout(() => {
      this.insightsProvider.globalState.showFind = false;
    }, 0);
    const insight: Insight = this.insightsProvider.currentInsight;

    if (!insight) {
      return;
    }

    if (insight instanceof Alert && (insight as Alert).hasConditions) {
      this.queryModel = LogqueryModelTranslator.modelFromAlert(
        insight as Alert,
      );
      if (insight.applicationName) {
        this.queryModel.queryParams.metadata.applicationName.push(
          insight.applicationName,
        );
      }

      if (insight.subsystemName) {
        this.queryModel.queryParams.metadata.subsystemName.push(insight.subsystemName);
      }
    } else {
      this.queryModel = InsightsHelper.getLogQueryModelByInsight(
        this.insightsProvider.currentInsight,
      );
    }
    this.getLoggregation(this.queryModel);
  }

  public getLoggregation(queryModel: LogQueryModel): void {
    this.loggregationService.GetLoggregation(queryModel);
    this.newLoggregationSubscirption = this.loggregationService.loggregationSubject.subscribe(
      (res) => {
        this.highlightedTemplates = InsightsHelper.getemplateIdsByInsight(
          this.insightsProvider.currentInsight,
        );
        this.data = res;
      },
      (error) => console.log(error),
    );
    this.currentInsightsChangedSubscirption = this.insightsProvider.currentInsightChanged.subscribe(
      (_insight) => {
        if (_insight) {
          this.queryModel = InsightsHelper.getLogQueryModelByInsight(_insight);
          this.loggregationService.GetLoggregation(this.queryModel);
        }
      },
    );
  }

  public ngOnDestroy(): void {
    this.insightsProvider.globalState.selectedLog = null;
    this.insightsProvider.globalState.queryResultCount = null;
    if (this.newLoggregationSubscirption) {
      this.newLoggregationSubscirption.unsubscribe();
    }
    if (this.currentInsightsChangedSubscirption) {
      this.currentInsightsChangedSubscirption.unsubscribe();
    }
  }
  public onSelectedItemChanged(e: any): void {
    this.insightsProvider.globalState.selectedLog = e;
  }

  public onGridButtonClick(event: any): void {
    this.insightsProvider.textInfoClicked.emit(event);
  }
  public onItemsCountChanged(e: any): void {
    this.insightsProvider.globalState.queryResultCount = e
      ? formatsHelper.numberWithCommas(e) + ' Templates'
      : null;
  }
}
