import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CoralogixSidebarComponent } from '@shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ParsingThemesEditorModes } from '@app/rules/shared/models/parsing-theme-editor-mode.enum';
import {
  ERuleServerTypes,
  IParsingTheme,
  IRuleGroupResponseData,
  Rule,
  RuleGroup,
} from '@app/rules/shared/models/rule.model';
import { CloseDialogData, RulesBaseThemeUrl, RulesBaseUrl } from '@app/rules/shared/models/rules-consts';
import { ParsingThemeFormService } from '@app/rules/shared/services/parsing-theme-form.service';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { RuleServerTypesArray } from '@app/rules/shared/models/rule-types-view.models';
import { IParsingThemeFormData } from '@app/rules/shared/models/parsing-theme-editor.models';
import { ParsingThemeUpdateRequest } from '@app/rules/shared/models/parsing-theme-requests.models';
import { Store } from '@ngrx/store';
import { getParsingThemes, State } from '@app/app.reducers';
import * as parsingThemesActions from '@app/rules/actions/parsing-themes.actions';
import { UDIDHelper } from '@shared/services/udid.helper';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { UserState } from '@app/ngxs-store/user/user.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

@Component({
  selector: 'sh-parsing-theme-editor-panel',
  templateUrl: './parsing-theme-editor-panel.component.html',
  styleUrls: ['./parsing-theme-editor-panel.component.scss'],
  providers: [ParsingThemeFormService]
})
export class ParsingThemeEditorPanelComponent {
  @SelectSnapshot(UserState.username) public teamName: string;

  @Output() public close: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('sidebarComponent', { static: true })

  public sidebar: CoralogixSidebarComponent;

  public parsingTheme: IParsingTheme;

  public isLoading: boolean;

  public mode: ParsingThemesEditorModes;

  public parsingThemeForm: FormGroup;

  public saveButtonText: string;

  public isCreateMode: boolean;

  private initialGroupNameValue: string;

  private subs: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private location: Location,
    private parsingThemeFormService: ParsingThemeFormService,
    private store: Store<State>,
  ) { }

  public openRuleGroupEditor(mode: ParsingThemesEditorModes, parsingTheme: IParsingTheme, ruleType?: ERuleServerTypes): void {
    this.handleRouteReplacementByMode(mode, parsingTheme.id, ruleType);
    this.isCreateMode = mode === ParsingThemesEditorModes.create;
    const shouldAddNewRule = ruleType && this.checkIfRuleTypeIsValid(ruleType) && this.isCreateMode;
    if (shouldAddNewRule) {
      parsingTheme.rulesGroups = [];
      parsingTheme.rulesGroups.push(this.getNewRuleGroupWithType(ruleType));
    }
    this.setInitialTexts(parsingTheme?.name, this.isCreateMode);
    this.mode = mode;
    this.parsingTheme = parsingTheme;
    this.parsingThemeForm = this.parsingThemeFormService.getParsingThemeForm(parsingTheme);
    this.sidebar.showSidebar();
  }

  public get activeControl(): FormControl {
    return this.parsingThemeForm.get('enabled') as FormControl;
  }

  public get detailsForm(): FormGroup {
    return this.parsingThemeForm.get('details') as FormGroup;
  }

  public get logSampleControl(): FormControl {
    return this.parsingThemeForm.get('logSample') as FormControl;
  }

  public get groupName(): string {
    const value = this.detailsForm.get('name').value;
    return value ? value : this.initialGroupNameValue;
  }

  public closeEditor(): void {
    if (this.parsingThemeForm?.pristine) {
      this.hideSidebarAndReplaceUrl();
      return;
    }

    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, { data: CloseDialogData });

    this.subs.push(dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.hideSidebarAndReplaceUrl();
      }
    }));
  }

  public hideSidebarAndReplaceUrl(): void {
    this.sidebar.hideSidebar();
    this.location.replaceState(RulesBaseUrl);
  }

  public saveParsingTheme(): void {
    const formData: IParsingThemeFormData = this.parsingThemeForm.getRawValue();
    const requestData: ParsingThemeUpdateRequest = new ParsingThemeUpdateRequest(formData);
    requestData.mapFormDataToRequest(formData);
    if (this.isCreateMode) {
      this.store.select(getParsingThemes).pipe(take(1)).subscribe((teams) => {
        this.store.dispatch(parsingThemesActions.createParsingThemeAction({
          payload: {
            ...requestData,
            creator: this.teamName,
            order: teams?.length ? teams?.length + 1 : 1
          }
        }));
      });
    } else {
      const mergedData = Object.assign(this.parsingTheme, requestData, { order: this.parsingTheme.order, creator: this.teamName });
      this.store.dispatch(parsingThemesActions.updateParsingThemeAction({ payload: mergedData }));
    }
  }

  public handleDeleteAction(): void {
    const ptName = this.parsingTheme.name;
    const deleteDialogData: ICoralogixDialogData = {
      title: `Delete Rule Group (${ptName})`,
      message: 'Deleting this rule is irreversible. Enter the rule group name to confirm that you want to delete it.',
      yesButtonText: 'DELETE',
      noButtonText: 'CANCEL',
      deleteConfirmPattern: this.getNameRegexPattern(ptName)
    };

    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, {
      data: deleteDialogData,
    });

    this.subs.push(dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(parsingThemesActions.deleteParsingThemeAction({ payload: this.parsingTheme.id }));
        this.hideSidebarAndReplaceUrl();
      }
    }));
  }

  private getNameRegexPattern(name: string): string {
    return name.split('').map(letter => `[${letter.toLowerCase()}${letter.toUpperCase()}]`).join('');
  }

  private setInitialTexts(parsingThemeName: string, isCreateMode: boolean): void {
    this.saveButtonText = isCreateMode ? 'Create rule group' : 'Save changes';
    this.initialGroupNameValue = isCreateMode ? 'New rule group' : parsingThemeName;
  }

  private handleRouteReplacementByMode(mode: ParsingThemesEditorModes, themeId?: string, ruleType?: ERuleServerTypes): void {
    if (mode === ParsingThemesEditorModes.edit && themeId) {
      this.location.go(`${RulesBaseThemeUrl}/${themeId}`);
    } else if (mode === ParsingThemesEditorModes.create) {
      if (ruleType && this.checkIfRuleTypeIsValid(ruleType)) {
        this.location.go(`${RulesBaseThemeUrl}/new/${ruleType}`);
      } else {
        this.location.go(`${RulesBaseThemeUrl}/new`);
      }
    }
  }
  private checkIfRuleTypeIsValid(type: string | ERuleServerTypes): boolean {
    return !!RuleServerTypesArray.find(_type => _type === type);
  }

  private getNewRuleGroupWithType(ruleType: ERuleServerTypes): IRuleGroupResponseData {
    const newRuleData: Rule = new Rule({ type: ruleType, order: 1, id: new UDIDHelper().generateShortUUID() });
    return new RuleGroup({
      rules: [newRuleData],
      id: new UDIDHelper().generateShortUUID(),
      enabled: true,
      order: 1,
      name: ''
    });
  }
}
