import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { Log } from '../shared/models/log.entity';
import { LogsService } from '../shared/services/logs-service';
import { LogsViewStateProvider } from '../shared/services/logsViewState.provider';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';

declare let $;

@Component({
  moduleId: 'logs-before-after',
  selector: 'sh-logs-before-after',
  templateUrl: 'logs-before-after.component.html',
  styleUrls: ['logs-before-after.component.scss'],
})
export class LogsBeforeAfterComponent implements AfterViewInit {
  @ViewChild('dropdown', { static: true }) public dropdownElm: ElementRef;
  public beforeAfterSecondsDiff: number = 5;
  @Output() public onBeforeAfter: EventEmitter<{
    seconds: number;
    selectedLog: Log | LoggregationEntity;
  }> = new EventEmitter<{
    seconds: number;
    selectedLog: Log | LoggregationEntity;
  }>();
  private _selectedLog: Log | LoggregationEntity;
  @Input() set selectedLog(log: Log | LoggregationEntity) {
    // NG4: Remove do check
    setTimeout(() => {
      this._selectedLog = log;
    }, 0);
  }

  get selectedLog(): Log | LoggregationEntity {
    return this._selectedLog;
  }

  constructor(
    private logService: LogsService,
    private viewStateProvider: LogsViewStateProvider,
  ) {}

  public ngAfterViewInit() {
    $(this.dropdownElm.nativeElement).popup({
      popup: '.special.popup',
      hoverable: true,
      on: 'hover',
      position: 'bottom center',
    });
  }

  public adjustTimeSpan(seconds: number) {
    this.viewStateProvider.onStartBeforeAfter.emit();
    this.beforeAfterSecondsDiff = seconds;
    this.onBeforeAfter.emit({ seconds, selectedLog: this._selectedLog });
    this.closeMenuPopup();
  }

  public closeMenuPopup() {
    $(this.dropdownElm.nativeElement).popup('hide');
  }
}
