import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSettingsFiltersComponent } from './user-settings-filters.component';
import { UserSettingsMobileFiltersModule } from './mobile/user-settings-mobile-filters.module';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { FilterMetadataModule } from '@app/shared/pipes/filter-metadata/filter-metadata.module';

@NgModule({
  declarations: [UserSettingsFiltersComponent],
  imports: [CommonModule, UserSettingsMobileFiltersModule, MatSelectModule, MatFormFieldModule, FormsModule],
  exports: [UserSettingsFiltersComponent],
})
export class UserSettingsFiltersModule {}
