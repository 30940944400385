import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { WidgetAddComponent } from '@shared/controls/widgets/widget-add/widget-add.component';
import { filterFalsy } from '@app/logs-new/shared/operators/filter-falsy.operator';
import { WidgetsService } from '@app/widgets/shared/services/widgets.service';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { IOccurrencesData } from '@app/logs-new/shared/models/logs-stats.model';

interface IDialogData {
  chartData$: Observable<IOccurrencesData>;
  templateId: string;
  logsQuery: ILogQuery;
}

@Component({
  selector: 'sh-occurrences-dialog',
  templateUrl: './occurrences-dialog.component.html',
  styleUrls: ['./occurrences-dialog.component.scss'],
})
export class OccurrencesDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public dialogRef: MatDialogRef<OccurrencesDialogComponent>,
    private dialog: MatDialog,
    private widgetService: WidgetsService,
  ) {}

  public pinGraph(): void {
    const dialogRef = this.dialog.open(WidgetAddComponent, {
      data: {
        query: {
          templateId: this.data.templateId,
          queryDef: {
            granularity: 'hour',
            queryType: 'placeholderRTStats',
          },
          queryParams: this.data.logsQuery.queryParams,
        },
        url: 'loggregation/parameter',
      },
      panelClass: 'add-edit-widget-container',
    });
    dialogRef
      .afterClosed()
      .pipe(filterFalsy())
      .subscribe((widget) => {
        this.widgetService
          .addWidgets(widget)
          .pipe(take(1))
          .subscribe();
      });
  }
}
