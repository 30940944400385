<div class="dialog-container">
  <mat-icon class="mat-clear-icon" data-test="field-visualization-dialog-clear-icon" (click)="dialogRef.close()">
    clear
  </mat-icon>
  <sh-field-visualization
    data-test="sh-field-visualization"
    [title]="data.title"
    [chartData$]="data.chartData$"
    [logsQuery$]="data.logsQuery$"
    [activeTab$]="data.activeTab$"
    [chartData]="data.chartData"
    [customMap]="data.customMap"
    [fullJSONPath]="data.jsonPath"
    (addToSearchQueryEvent)="onAddToSearchQuery($event)"
    (addSelectedQueryFieldEvent)="onAddSelectedQueryField($event)"
  ></sh-field-visualization>
</div>
