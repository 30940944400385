import { UUID } from 'angular2-uuid';
import { Injectable } from '@angular/core';
declare let shortid;

@Injectable()
export class UDIDHelper {
  public generateShortUDID(): string {
    return shortid.gen();
  }

  public generateShortUUID(): string {
    return UUID.UUID();
  }
}
