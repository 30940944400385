<div class="wrapper">
  <div
    class="chartLabel"
    fxFlex="column"
  >
    <div class='severityCount'>{{totalCount}}</div>
    <div class="description">{{description}}</div>
  </div>
  <div [chart]="chart"></div>
</div>
