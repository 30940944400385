import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MetricAlertComponent } from './metric-alert.component';

const routes: Routes = [
    {
    path: '',
    component: MetricAlertComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class MetricAlertRoutingModule {}
