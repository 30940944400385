import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ArithmeticOptions, ConditionsOptions, PrometheusMetricsMatcherId } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { UserAlertTypes } from '@app/alerts/shared/models/create-n-update-alert-model';
import { AlertHelper } from '@app/alerts/shared/services/alert-helper';
import { getDateFormatViewPreference, getMetrics, getTimezoneViewPreference, State } from '@app/app.reducers';
import {
  PromqlMetricAlertQueryModel,
  PromqlMetricAlertGraphData,
  MetricAlertQueryModel,
  NamedMetricAlertGraphData,
} from '@app/insights/shared/models/alert-metric.model';
import { Alert } from '@app/insights/shared/models/alert.model';
import { Insight } from '@app/insights/shared/models/insight.model';
import { InsightsProvider } from '@app/insights/shared/services/insights.provider';
import { MetricAlertService } from '@app/insights/shared/services/metric-alert.service';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { LogActions } from '@app/logs/shared/state/log.actions';
import { _30MinutesInSeconds } from '@app/shared/constants/times.constant';
import { DateFormatType } from '@app/shared/models/date-format-types';
import { TimeZoneType } from '@app/shared/models/timezone-types';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sh-metric-alert',
  templateUrl: './metric-alert.component.html',
  styleUrls: ['./metric-alert.component.scss'],
})
export class MetricAlertComponent implements OnInit, OnDestroy {
  @ViewChild('snoozeMenuTrigger')
  public snoozeMenuTrigger: MatMenuTrigger;

  public alert: Alert;

  public title: string = '';

  public subTitle: string = '';

  public severity: number;

  public duration: moment.Duration;

  public threshold: number;

  public chartResult$: Observable<NamedMetricAlertGraphData[] | PromqlMetricAlertGraphData[]>;

  public tags: string[];

  public spliced: boolean = false;

  public timeZone: TimeZoneType;
  public dateFormatType: DateFormatType;
  public timezone$ = this.store.select(getTimezoneViewPreference);
  public dateFormatType$ = this.store.select(getDateFormatViewPreference);
  public isPromql: boolean = false;
  public isPrometheusMetric: boolean = false;

  private destroyed$: Subject<boolean> = new Subject<boolean>();
  private hoursRangeAroundAlert: number;
  private isGroupBy: boolean = false;
  private subscriptions: Subscription[] = [];
  private arithmeticOperatorMap = {
    [ArithmeticOptions.average]: 'averaged',
    [ArithmeticOptions.min]: 'minimum',
    [ArithmeticOptions.max]: 'maximum',
    [ArithmeticOptions.sum]: 'sum',
    [ArithmeticOptions.count]: 'count for',
    [ArithmeticOptions.percentile]: 'percentile',
  };

  constructor(
    public insightsProvider: InsightsProvider,
    public metricAlertService: MetricAlertService,
    public alertHelper: AlertHelper,
    public logsService: LogsService,
    private store: Store<State>,
  ) {
    this.subscriptions.push(this.timezone$.subscribe(timezone => (this.timeZone = timezone)));
    this.subscriptions.push(this.dateFormatType$.subscribe(dateFormat => (this.dateFormatType = dateFormat)));
  }

  public ngOnInit(): void {
    const insight = this.insightsProvider.currentInsight;

    if (insight && insight.subTypeId === UserAlertTypes.MetricAlert) {
      this.initInsight(insight);
    }

    this.insightsSubscription();
  }

  public viewRelatedLogs(): void {
    const minuteInMilliseconds = 60 * 1000;
    const startDate = new Date(this.alert.logTimestamp - this.alert.conditionTimeframe * minuteInMilliseconds);
    const endDate = new Date(this.alert.logTimestamp + this.alert.conditionTimeframe * minuteInMilliseconds);
    this.store
      .select(getMetrics)
      .take(1)
      .subscribe(metrics => {
        const relevantMetric = metrics.find(metric => metric.id === this.alert.metricAlert.metricId);
        const queryModel = new LogQueryModel(startDate, endDate);
        const relevantPath = relevantMetric?.metricFields?.find(field => field.name === this.alert.metricAlert.metricFieldExtraction)
          .fieldPath;
        const metricQueryText = !_.isEmpty(relevantMetric.text) ? ' AND ' + relevantMetric.text : '';

        queryModel.queryParams.query.text = ' _exists_:' + relevantPath + metricQueryText;
        queryModel.queryParams.metadata = new Metadata();
        if (!_.isEmpty(this.alert.applicationName)) {
          queryModel.queryParams.metadata.applicationName.push(this.alert.applicationName);
        } else if (!_.isEmpty(relevantMetric.applicationName)) {
          const applicationNamesRegex = this.getNameRegex(relevantMetric.applicationName);
          const applicationNamePath = 'coralogix.metadata.applicationName';

          queryModel.queryParams.query.text = `${applicationNamePath}:${applicationNamesRegex} AND ${queryModel.queryParams.query.text}`;
        }

        if (!_.isEmpty(this.alert.subsystemName)) {
          queryModel.queryParams.metadata.subsystemName.push(this.alert.subsystemName);
        } else if (!_.isEmpty(relevantMetric.subsystem)) {
          const subsystemNamesRegex = this.getNameRegex(relevantMetric.subsystem);
          const subsystemNamePath = 'coralogix.metadata.subsystemName';

          queryModel.queryParams.query.text = `${subsystemNamePath}:${subsystemNamesRegex} AND ${queryModel.queryParams.query.text}`;
        }

        if (!_.isEmpty(relevantMetric.severity)) {
          queryModel.queryParams.metadata.severity.push(relevantMetric.severity);
        }

        queryModel.cacheQueryId = this.logsService.getNewQueryId();
        this.store.dispatch(new LogActions.CreateCachedQuery(queryModel));
      });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private getNameRegex(names: string[]): string {
    const filterPrefix = 'filter:';
    const regexList = [];

    names.forEach(name => {
      if (_.startsWith(name, filterPrefix)) {
        // filter syntax is: 'filter:<filter type>:<query>'
        const FILTER_PART_AMOUNT = 3;
        const FILTER_TYPE_INDEX = 1;
        const FILTER_VALUE_INDEX = 2;
        const splitFilter = name.split(':');

        if (splitFilter.length !== FILTER_PART_AMOUNT) {
          return;
        }

        switch (splitFilter[FILTER_TYPE_INDEX]) {
          case 'startsWith':
            regexList.push(`(${splitFilter[FILTER_VALUE_INDEX]}.*)`);
            break;
          case 'endsWith':
            regexList.push(`(.*${splitFilter[FILTER_VALUE_INDEX]})`);
            break;
          case 'contains':
            regexList.push(`(.*${splitFilter[FILTER_VALUE_INDEX]}.*)`);
            break;
          case 'equals':
          default:
            regexList.push(`(${splitFilter[FILTER_VALUE_INDEX]})`);
            break;
        }
      } else {
        regexList.push(`(${name})`);
      }
    });

    return `/${regexList.join('|')}/`;
  }

  private insightsSubscription(): void {
    this.insightsProvider.currentInsightChanged
      .pipe(
        filter(insight => insight.subTypeId === UserAlertTypes.MetricAlert),
        takeUntil(this.destroyed$),
      )
      .subscribe(insight => {
        this.initInsight(insight);
      });
  }

  private getMetricAlertQueryModel(alert: Alert, startTime: number, endTime: number, isGroupBy: boolean): MetricAlertQueryModel {
    const queryModel = new MetricAlertQueryModel(alert);

    queryModel.startTime = startTime;
    queryModel.endTime = endTime;
    queryModel.interval = this.metricAlertService.getIntervalByTimerframe(alert.conditionTimeframe);

    if (isGroupBy) {
      queryModel.groupByLabels = alert?.esInfo?.getGroupByFields();
      queryModel.groupByValues = alert?.esInfo?.getGroupByValues();
    }
    return queryModel;
  }

  private getPromqlAlertQueryModel(alert: Alert, startTime: number, endTime: number): PromqlMetricAlertQueryModel {
    const step = this.metricAlertService.getIntervalByTimerframe(alert.conditionTimeframe);
    return new PromqlMetricAlertQueryModel(alert, startTime, endTime, step + 'm', alert.esInfo.hits);
  }

  private initInsight(insight: Insight): void {
    this.alert = insight as Alert;

    const start = moment(this.alert.logTimestamp);
    const end = moment(this.alert.logTimestamp).add(this.alert.conditionTimeframe, 'minutes');
    const diff = end.diff(start);
    this.duration = moment.duration(diff);
    this.severity = this.alert.severity;
    this.threshold = this.alert.metricAlert.valueThreshold;
    const isResolved = this.alert.isResolved;

    this.arithmeticOperatorMap[ArithmeticOptions.percentile] = this.alert?.metricAlert?.arithmeticOperatorModifier + ' percentile';
    this.isPromql = !_.isEmpty(this.alert?.metricAlert?.promqlText);
    this.isPrometheusMetric = this.alert?.metricAlert?.metricId === PrometheusMetricsMatcherId;

    const isSumOrCount =
      this.alert.metricAlert.arithmeticOperator === ArithmeticOptions.count ||
      this.alert.metricAlert.arithmeticOperator === ArithmeticOptions.sum;

    this.isGroupBy = (this.alert.esInfo?.hasHits() || this.alert.esInfo?.isNestedGroupBy()) ?? false;
    this.getGraphData();

    const timeframeText = this.getTimeframeText();
    const resolvedTextAddition = isResolved ? 'OR EQUALS' : '';

    const arithmeticName = this.arithmeticOperatorMap[this.alert.metricAlert.arithmeticOperator];

    const isLocalTimeZone = this.timeZone === TimeZoneType.local;

    const subTitleTimeString = this.getSubtitleTimeString(isLocalTimeZone);

    const toTimestamp = new Date(Math.ceil(this.alert.logTimestamp / this.duration.asMilliseconds()) * this.duration.asMilliseconds());
    const subTitleDateString = `${moment(toTimestamp).format('MMM DD, YYYY')}`;

    const sampleThresholdText = isSumOrCount ? 'in' : `for over ${this.alert.metricAlert.sampleThreshold}% of`;

    let conditionOperatorText = this.alert.conditionOperator === ConditionsOptions.more ? 'has exceeded' : 'did not reach';
    if (isResolved) {
      conditionOperatorText = this.alert.conditionOperator === ConditionsOptions.more ? 'was at least' : 'was at most';
    }

    this.populateTags(resolvedTextAddition, timeframeText, isSumOrCount);

    const targetString = this.isPromql ? 'supplied query' : `${arithmeticName} ${this.alert.metricAlert.metricFieldExtraction}`;

    this.subTitle = `We've detected that the ${targetString} ${conditionOperatorText}
      ${this.alert.metricAlert.valueThreshold} ${sampleThresholdText} the following timeframe: ${subTitleTimeString} on ${subTitleDateString}`;

    const titleTarget = this.isPromql
      ? 'The supplied query'
      : `${_.capitalize(arithmeticName)} ${this.alert.metricAlert.metricFieldExtraction}`;

    this.title = `${titleTarget} is
      ${this.alert.conditionOperator === ConditionsOptions.more ? 'over' : 'under'}
      ${resolvedTextAddition.toLowerCase()} ${this.alert.metricAlert.valueThreshold}`;
  }

  private getGraphData(): void {
    const { graphStartTime, graphEndTime } = this.getGraphStartAndEndTimes();

    const graphQueryModel = this.isPromql
      ? this.getPromqlAlertQueryModel(this.alert, Math.round(graphStartTime / 1000), Math.round(graphEndTime / 1000))
      : this.getMetricAlertQueryModel(this.alert, graphStartTime, graphEndTime, this.isGroupBy);

    if (this.isPromql) {
      this.chartResult$ = this.metricAlertService.getPromqlMetricAlertGraphData(graphQueryModel as PromqlMetricAlertQueryModel).first();
    } else if (this.isGroupBy) {
      this.chartResult$ = this.metricAlertService.getGroupByMetricAlertGraphData(graphQueryModel as MetricAlertQueryModel).first();
    } else {
      this.chartResult$ = this.metricAlertService.getMetricAlertGraphData(graphQueryModel as MetricAlertQueryModel).first();
    }
  }

  private populateTags(resolvedTextAddition: string, timeframeText: string, isSumOrCount: boolean): void {
    if (this.isPromql) {
      this.tags = [this.alert.metricAlert.promqlText];
    } else {
      this.tags = [
        `${this.arithmeticOperatorMap[this.alert.metricAlert.arithmeticOperator].toUpperCase()} ${
          this.alert.metricAlert.metricFieldExtraction
        }`,
        `${this.alert.conditionOperator === ConditionsOptions.more ? 'GREATER THAN' : 'LESS THAN'} ${resolvedTextAddition} ${
          this.threshold
        }`,
        `IN ${timeframeText}`,
      ];

      if (this.isGroupBy) {
        this.tags.push(`GROUPED BY (${this.alert.esInfo.getGroupByFields().join(',')})`);
      }

      if (!isSumOrCount) {
        this.tags.push(`OVER ${this.alert.metricAlert.sampleThreshold}% OF TIMEFRAME`);
      }
    }

    if (!_.isNil(this.alert?.metricAlert?.swapNullValue)) {
      this.tags.push(`REPLACING MISSING VALUES WITH 0`);
    } else {
      this.tags.push('NOT REPLACING MISSING VALUES');
    }
  }

  private getSubtitleTimeString(isLocalTimeZone: boolean): string {
    return this.isGroupBy || this.isPromql
      ? `${moment(this.alert.esInfo.fromTimestamp)
          .utc(isLocalTimeZone)
          .format('HH:mm')}-${moment(this.alert.esInfo.toTimestamp)
          .utc(isLocalTimeZone)
          .format('HH:mm')}`
      : `${moment(this.alert.logTimestamp - this.alert.conditionTimeframe * 60 * 1000)
          .utc(isLocalTimeZone)
          .format('HH:mm')}-${moment(this.alert.logTimestamp)
          .utc(isLocalTimeZone)
          .format('HH:mm')}`;
  }

  private getTimeframeText(): string {
    return this.alertHelper.conditionTimeframeMetricAlertOptions
      .find(option => option.id === this.alert.conditionTimeframe)
      .name.toUpperCase();
  }

  private getGraphStartAndEndTimes(): { graphStartTime: number; graphEndTime: number } {
    const oneMinuteInMillisecond = 60 * 1000;
    const oneHourInMillisecond = 60 * oneMinuteInMillisecond;
    const intervalMilliseconds = this.metricAlertService.getIntervalByTimerframe(this.alert.conditionTimeframe) * oneMinuteInMillisecond;

    this.hoursRangeAroundAlert = this.alert.conditionTimeframe <= 30 ? 3 : this.alert.conditionTimeframe < 720 ? 7 : 16;

    const timeBetweenNowAndAlert = Date.now() - this.alert.logTimestamp;
    const hoursAroundAlertInMilliseconds = this.hoursRangeAroundAlert * oneHourInMillisecond;

    const triggeredTimeframeStart = _.isEmpty(this.alert.esInfo)
      ? this.alert.logTimestamp - this.alert.conditionTimeframe * oneMinuteInMillisecond
      : this.alert.esInfo.fromTimestamp;

    const triggeredTimeframeEnd = _.isEmpty(this.alert.esInfo) ? this.alert.logTimestamp : this.alert.esInfo.toTimestamp;

    const graphStartTime =
      timeBetweenNowAndAlert <= hoursAroundAlertInMilliseconds // calculating time so in total we will see 2 * hoursAroundAlert in the graph
        ? Date.now() - 2 * hoursAroundAlertInMilliseconds + timeBetweenNowAndAlert
        : moment
            .unix(triggeredTimeframeStart / 1000)
            .utc()
            .unix() *
            1000 -
          hoursAroundAlertInMilliseconds;
    const graphEndTime =
      timeBetweenNowAndAlert < hoursAroundAlertInMilliseconds
        ? Date.now() - intervalMilliseconds
        : moment
            .unix(triggeredTimeframeEnd / 1000)
            .utc()
            .unix() *
            1000 +
          hoursAroundAlertInMilliseconds;
    return { graphStartTime, graphEndTime };
  }
}
