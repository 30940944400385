import { jsonObject } from 'typedjson';
import { MeasureOject } from '@shared/services/htmlRowMeasure.service';

@jsonObject
export class LivetailEntity implements MeasureOject {
  public static s_id: number = 0;

  public logId: string;
  public text: string;
  public category: string;
  public methodName: string;
  public applicationName: string;
  public subsystemName: string;
  public computerName: string;
  public severity: string;
  public IPAddress: string;
  public sdkId: any;
  public threadId: string;
  public timestamp: number;
  public id: number = LivetailEntity.s_id++;
  public jsonUuid: string;
  public textObject: any;
  public viewObject: any;
  public showMore: boolean;
  public className: string;
  public highlights: any;

  constructor(init?: Partial<LivetailEntity>) {
    Object.assign(this, init);
    this.id = LivetailEntity.s_id++;
  }

  public getId(): string {
    return '' + this.id;
  }
}

@jsonObject
export class LivetailnRes {
  public results: LivetailEntity[] = [];
}
