const SORT_FIELD_DEFAULT_VALUE = 'timestamp';
const SORT_ORDER_DEFAULT_VALUE = 'desc';
const SORT_MISSING_VALUE = '_last';

export class SortModel {
  public field: string = SORT_FIELD_DEFAULT_VALUE;
  public ordering: string = SORT_ORDER_DEFAULT_VALUE;
  public missing: string = SORT_MISSING_VALUE;

  constructor(field?: any, ordering: LogsSortingType = 'desc') {
    this.field = field || SORT_FIELD_DEFAULT_VALUE;
    this.ordering = ordering || SORT_ORDER_DEFAULT_VALUE;
  }

  public isEqual(field: string, order: string): boolean {
    return this.field === field && this.ordering === order;
  }
}

export interface ISortModel {
  field: string;
  ordering: string;
  missing?: string;
}

export type LogsSortingType = 'desc' | 'asc' | null;
