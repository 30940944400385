<ng-container *ngIf="alert">
  <sh-alert-view-layout alertType="UNIQUE COUNT ALERT" [title]="title" [subTitle]="subTitle" [tags]="tags" [alertId]="alert.alertId">
    <div body class="sh-rest-content">
      <table class="sh-info-table">
        <thead>
          <tr>
            <th>Triggered</th>
            <th>Duration</th>
            <th>Severity</th>
            <th *ngIf="groupByFields">Group By ({{ groupByFields }})</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ chartData[chartData.length - 1]?.timestamp | date: 'longDate' }}</td>
            <td>{{ duration.humanize() }}</td>
            <td>
              <div fxLayout="row" fxLayoutGap="12px">
                <div class="sh-severity-indication" [style.backgroundColor]="SeveritiesHelper.getSeverityColor(severity)"></div>
                <div>{{ SeveritiesHelper.getLogSeverityById(severity) }}</div>
              </div>
            </td>
            <td *ngIf="groupByFields">{{ groupByValue }}</td>
          </tr>
          <tr>
            <td>{{ chartData[chartData.length - 1]?.timestamp | date: 'HH:mm:ss' }}</td>
          </tr>
        </tbody>
      </table>
      <div class="sh-info-detailed">
        <div></div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="sh-triggered-values-title">TRIGGERED VALUES</div>
          <div class="sh-results-title">{{ log.valuesSet.length }} RESULTS</div>
        </div>
        <div>
          <div [chart]="chart" style="width: 100%"></div>
        </div>
        <div class="sh-triggered-values-list-wrapper">
          <div fxLayout="column" class="sh-triggered-values-list-container">
            <mat-form-field class="search-teammate-field" appearance="outline">
              <mat-icon matPrefix class="magnifier-icon">search</mat-icon>
              <input
                type="text"
                placeholder="Search values"
                aria-label="Search values"
                autocomplete="off"
                class="search-input triggered-values-search"
                matInput
                FormControlDirective
                [formControl]="search"
              />
            </mat-form-field>
            <cdk-virtual-scroll-viewport itemSize="15" minBufferPx="200" maxBufferPx="200" class="sh-triggered-values-list">
              <ng-container *cdkVirtualFor="let item of triggeredValueList; let i = index">
                <div class="sh-triggered-values-list__index">{{ i + 1 }}</div>
                <div class="sh-triggered-values-list__value">
                  <span matTooltipPosition="above" [matTooltip]="item" [matTooltipDisabled]="item.length < 19">{{ item }}</span>
                </div>
              </ng-container>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
    </div>
  </sh-alert-view-layout>
</ng-container>
