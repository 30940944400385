import { type } from '@shared/services/utils';
import { Action } from '@ngrx/store';

export namespace ReindexEditorActions {
  export const ActionTypes = {
    TOGGLE_EDITOR: type('[ReindexEditor] Toggle'),
    TOGGLE_S3_PERMISSIONS_EDITOR: type('[ReindexEditor] Toggle S3 permissions Editor')
  };

  export class ToggleEditorAction implements Action {
    public type = ActionTypes.TOGGLE_EDITOR;

    constructor(public payload: boolean) {}
  }

  export class TogglePermissionsEditorAction implements Action {
    public type = ActionTypes.TOGGLE_S3_PERMISSIONS_EDITOR;

    constructor(public payload: boolean) {}
  }

  export type Actions =
    ToggleEditorAction |
    TogglePermissionsEditorAction
}
