import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs/observable/of';
import { LogsViewsActions } from './logs-views.actions';
import { SavedViewsService } from '../../services/saved-views.service';
import { ShDialogService } from '../../../../shared/services/dialogService';
import { UserSettingsService } from '../../../../user/shared/user-settings.service';
import { UserSettingsProvider } from '../../../../user/shared/userSettingsProvider';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class LogsViewsEffects {
  @Effect()
  public getLogsView$: Observable<any> = this.actions$.pipe(
    ofType(LogsViewsActions.ActionTypes.LOGS_VIEWS_LOAD),
    switchMap(() => {
      return this.logsViewsService
        .loadViews()
        .map((views) => new LogsViewsActions.LoadLogsViewCompletedAction(views))
        .catch((err) => of(new LogsViewsActions.LoadLogsViewFailedAction(err)));
    }),
  );

  @Effect()
  public saveLogsView$: Observable<any> = this.actions$.pipe(
    ofType(LogsViewsActions.ActionTypes.LOGS_VIEW_SAVE),
    switchMap((action: any) => {
      return this.logsViewsService
        .saveView(action.payload)
        .do((savedView: any) => {
          if (
            action.payload.isDefault &&
            this.userSettingsProvider.userSettings.logsDefaultGridViewId !==
              savedView.id
          ) {
            this.userSettingsProvider.userSettings.logsDefaultGridViewId =
              savedView.id;
            this.userSettingsService
              .updateUserSettings(this.userSettingsProvider.userSettings)
              .first()
              .subscribe((res: any) => {});
          }

          if (
            !action.payload.isDefault &&
            this.userSettingsProvider.userSettings.logsDefaultGridViewId ===
              savedView.id
          ) {
            this.userSettingsProvider.userSettings.logsDefaultGridViewId = -1;
            this.userSettingsService
              .updateUserSettings(this.userSettingsProvider.userSettings)
              .first()
              .subscribe((res: any) => {});
          }
        })
        .do((savedView: any) =>
          this.dialogService.showSnackBar(
            'View "' + savedView.name + '" was saved successfully',
            null,
            3000,
          ),
        )
        .concatMap((newView: any) => [
          new LogsViewsActions.SaveLogsViewCompletedAction(newView),
          new LogsViewsActions.SelectLogsViewAction(newView.id),
        ])
        .catch((err: any) => {
          if (err) {
            this.dialogService.showSnackBar('ERROR: ' + err, null, 4000);
          }
          return of(new LogsViewsActions.SaveLogsViewFailedAction(err));
        });
    }),
  );

  @Effect()
  public deleteLogsView$: Observable<Action> = this.actions$.pipe(
    ofType(LogsViewsActions.ActionTypes.LOGS_VIEW_DELETE),
    switchMap((action: any) => {
      return this.logsViewsService
        .deleteView(action.payload)
        .do(() => {
          if (
            this.userSettingsProvider.userSettings.logsDefaultGridViewId ===
            action.payload
          ) {
            this.userSettingsProvider.userSettings.logsDefaultGridViewId = -1;
            this.userSettingsService
              .updateUserSettings(this.userSettingsProvider.userSettings)
              .first()
              .subscribe((res: any) => {});
          }
        })
        .do(() =>
          this.dialogService.showSnackBar(
            'View was deleted successfully',
            null,
            3000,
          ),
        )
        .map(
          (deletedViewId) =>
            new LogsViewsActions.DeleteLogsViewCompletedAction(deletedViewId),
        )
        .catch((err) =>
          of(new LogsViewsActions.DeleteLogsViewFailedAction(err)),
        );
    }),
  );
  constructor(
    private actions$: Actions,
    private logsViewsService: SavedViewsService,
    private userSettingsService: UserSettingsService,
    private userSettingsProvider: UserSettingsProvider,
    private dialogService: ShDialogService,
  ) {}
}
