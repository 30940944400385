import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboveBelowNormalPipe } from './above-below-normal.pipe';

@NgModule({
  declarations: [AboveBelowNormalPipe],
  imports: [CommonModule],
  exports: [AboveBelowNormalPipe],
})
export class AboveBelowNormalModule {}
