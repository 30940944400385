import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { State } from '@app/app.reducers';
import { Observable } from 'rxjs/Observable';
import { Entity } from '@shared/models/entity.model';
import { Store } from '@ngrx/store';
import { LogsSeverityOptionsFactoryService } from '@app/logs/shared/factories/logs-severity-options-factory.service';
import { Constants } from '@app/constants';
import { getGenericMetadataField } from '@shared/state/selectors/metadata.selectors';

@Component({
  selector: 'sh-rule-matcher-form',
  templateUrl: './rule-matcher-form.component.html',
  styleUrls: ['./rule-matcher-form.component.scss']
})
export class RuleMatcherFormComponent {
  @Input() public ruleMatchersForm: FormGroup;
  public applicationOptions$: Observable<string[]>;
  public subsystemOptions$: Observable<string[]>;
  public severityOptions: Entity[];
  public metadataField1: string = Constants.metadataFields()[0];
  public metadataField2: string = Constants.metadataFields()[1];
  public metadataField1Placeholder: string = `select ${Constants.metadataFields()[0] || 'applications'}`;
  public metadataField2Placeholder: string = `select ${Constants.metadataFields()[1] || 'subsystems'}`;
  constructor(private store: Store<State>,
              private severityFactory: LogsSeverityOptionsFactoryService) {
    this.applicationOptions$ = this.store.select(getGenericMetadataField(this.metadataField1 || 'applicationName'));
    this.subsystemOptions$ = this.store.select(getGenericMetadataField(this.metadataField2 || 'subsystemName'));
    this.severityOptions = this.severityFactory.createWithEnumValues();
  }
  public hasError(controlName: string, errorCode: string): boolean {
    const control = this.ruleMatchersForm.get(controlName);
    return control.hasError(errorCode) && control.touched;
  }

  public isSelectInvalid(controlName: string): boolean {
    const control = this.ruleMatchersForm.get(controlName);
    return !control.value && control.touched;
  }
}
