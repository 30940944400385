<mat-card
  class="coralogix"
>
  <p class="mat-card-header">Details</p>
  <form [formGroup]="form">
    <div class="coralogix input-group">
      <label class="input-label">Name</label>
      <div
        class="has-validation"
        [class.coralogix-input-error]="hasError('name', 'required')"
      >
        <input
          type="text"
          placeholder="Enter a name"
          class="form-control"
          formControlName="name"
        >
        <span
          *ngIf="hasError('name', 'required')"
          class="error"
        >
          Reindex name is required
        </span>
      </div>
    </div>
    <div class="coralogix input-group">
      <label class="input-label">Description</label>
      <div class="has-validation">
        <input
          type="text"
          placeholder="Enter a description"
          class="form-control"
          formControlName="description"
        >
      </div>
    </div>
  </form>
</mat-card>


