import { ArchiveQueryEditorActions } from '@app/roi/archive-queries/state/actions/archive-query-editor.actions';
import { IArchiveQuery } from '@app/roi/archive-queries/models/archive-query.model';

export namespace ArchiveQueryEditorReducer {

  export interface State {
    isOpen: boolean;
    prePopulatedQuery: IArchiveQuery;
    isEditable: boolean;
  }

  const initialState: State = {
    isOpen: false,
    prePopulatedQuery: null,
    isEditable: false
  };

  export function reducer(state: ArchiveQueryEditorReducer.State = initialState, action: ArchiveQueryEditorActions.Actions):
    ArchiveQueryEditorReducer.State {
    switch (action.type) {
      case ArchiveQueryEditorActions.ActionTypes.TOGGLE_EDITOR:
        return {
          ...state,
          isOpen: action.payload.openEditor,
          prePopulatedQuery: action.payload.prePopulatedQuery,
          isEditable: action.payload.isEditable
        };
      default:
        return state;
    }
  }
}
