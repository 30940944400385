<div
  style="display: flex;align-items:center;align-items: stretch;"
  (click)="tagSelected.emit(_tagData)"
>
  <div class="event-icon-container">
    <div style="display: inline-block;vertical-align: middle;">
      <img
        src="{{ _tagData?.avatar }}"
        class="icon-tag-type"
        onerror="this.src ='/assets/deployment-icons/event.png';"
      />
    </div>
  </div>
  <div class="tag-list-item">
    <div class="tag-info">
      <div>
        <span>
          {{ _tagData?.text_value }}
          <a
            [matTooltip]="'Go to link'"
            href="{{ _tagData.link ? _tagData.link : '#' }}"
            target="_blank"
            shClickStopPropagation
          >
            <code *ngIf="refId" class="code">{{ refId }}</code>
          </a>
        </span>
      </div>
      <div class="tag-info-description">
        <div style="white-space: normal">
          {{ _tagData?.application_name }} {{ _tagData?.subsystem_name }}
        </div>
        <div>
          <span
            [matTooltip]="_tagData?.tag_timestamp | dateFormatByUserSettings : null : standardHoursFormat"
            matTooltipPosition="below"
          >
            {{ dateAgo }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
