import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getSelectedTag, getSelectedTagDelta, getTagErrorVolume, getTagErrorVolumeViewState, State } from '@app/app.reducers';
import { ILineChartConfig } from '@app/statistics/shared/models/line-chart.models';
import { ILegendData } from '@app/statistics/components/chart-legend/chart-legend.component';
import { compareLineChartLegend } from '@app/deployment/models/line-graphs';
import { getTimeUnitScale, getXTimeCategories } from '@app/statistics/shared/helpers/line-chart-tags-helper';
import { EGraphTooltipStyles } from '@shared/controls/charts/models/chart.model';
import { ITagErrorVolumeResponse } from '@app/deployment/shared/models/error-volume.models';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { Build } from '@app/deployment/models/build';
import { Router } from '@angular/router';
import { LogSeveritiesEnum } from '@app/logs/shared/models/log-severities.enum';
import { EFeatureViewMode } from '@app/deployment/shared/models/reducer.models';

@Component({
  selector: 'sh-tag-error-volume',
  templateUrl: './tag-error-volume.component.html',
  styleUrls: ['./tag-error-volume.component.scss']
})
export class TagErrorVolumeComponent implements OnInit, OnDestroy {
  public viewState$: Observable<EFeatureViewMode>;
  public completedViewState: EFeatureViewMode = EFeatureViewMode.completed;
  public delta$: Observable<string> = null;
  public graphInfo: ILineChartConfig;
  public lineGraphLegend: ILegendData[] = compareLineChartLegend;
  private subs: Subscription[] = [];
  private logsTimestampArray: number[] = [];
  private selectedTag: Build;
  constructor(private store: Store<State>, private logsService: LogsService, private router: Router) { }

  public ngOnInit(): void {
    this.viewState$ = this.store.select(getTagErrorVolumeViewState);
    this.delta$ = this.store.select(getSelectedTagDelta);
    const errorsSub = this.store.select(getTagErrorVolume)
      .subscribe((data: ITagErrorVolumeResponse) => {
        if (data) {
          this.graphInfo = this.getMappedLineGraph(data);
          this.logsTimestampArray = data.tag.map(value => value[0]);
        } else {
          this.graphInfo = null;
        }
      });
    const currTagSub = this.store.select(getSelectedTag).subscribe((data) => {
      this.selectedTag = data;
    });
    this.subs.push(errorsSub, currTagSub);
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public navigateToLogs(dataIndex: number): void {
    const timestampArr = this.logsTimestampArray;
    const timeGap = Math.abs(differenceInSeconds(timestampArr[1], timestampArr[0]));
    const startDate = new Date(timestampArr[dataIndex]);
    const endDate = addSeconds(timestampArr[dataIndex], timeGap);
    const queryModel = new LogQueryModel(startDate, endDate);
    const { application_name, subsystem_name } = this.selectedTag;
    const appNames = application_name ? application_name?.split(',') : [];
    const subSys = subsystem_name ? subsystem_name?.split(',') : [];
    queryModel.queryParams.metadata.applicationName.push(...appNames);
    queryModel.queryParams.metadata.subsystemName.push(...subSys);
    queryModel.queryParams.metadata.severity = [LogSeveritiesEnum.ERROR, LogSeveritiesEnum.CRITICAL];
    queryModel.cacheQueryId = this.logsService.getNewQueryId();

    this.logsService.saveLogsQuery(queryModel)
      .first()
      .subscribe(() => {
        const url = '#' + this.router.serializeUrl(
            this.router.createUrlTree(['query-new', 'logs'], {
          queryParams: { id: queryModel.cacheQueryId }
        }));
        window.open(url, '_blank');
      });
  }

  private getMappedLineGraph(graphResult: ITagErrorVolumeResponse): ILineChartConfig {
    const scale = getTimeUnitScale(graphResult.tag);
    return {
      values: {
        seriesValues: [graphResult.tag, graphResult.compareTag]
      },
      config: {
        yTitle: 'ERROR VOLUME',
        xTitle: 'TIME ELAPSED SINCE TAG',
        scale,
        height: 250,
        seriesNames: this.lineGraphLegend.map(item => item.name),
        chartTooltipDataTitle: 'ERROR VOLUME',
        tooltipButtonText: 'VIEW LOGS',
        xCategories: getXTimeCategories(graphResult.tag, scale),
        tooltipStyleType: EGraphTooltipStyles.whiteWithLinkButtonLogs,
        yCategories: [],
        tooltipRightOffsetInPx: 60,
      }
    };
  }
}
