<div class="overlay" [hidden]="!isOverlayVisible">
  <div class="ui active inverted dimmer">
    <div class="ui loader"></div>
  </div>
</div>
<sh-grid-paging #pagingComponent
                [currentPage]="currentPage"
                [totalPages]="totalPages"
                [paginationControll]="paginationControll">
</sh-grid-paging>
<div style="height: 100%;width: 100%">
  <ag-grid-ng2 *ngIf="gridOptions" #agGrid class="ag-material" style="height: 100%; width: 100%;"
               [gridOptions]="gridOptions">
  </ag-grid-ng2>
</div>
