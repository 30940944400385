import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { switchMap, tap } from 'rxjs/operators';
import { ShDialogService } from '@shared/services/dialogService';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { UserSettingsService } from '@app/user/shared/user-settings.service';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { UserSettings } from '@app/user/shared/user.settings';
import * as settingsActions from './user-settings.actions';
import { State } from '@app/app.reducers';

@Injectable()
export class UserSettingsEffects {

  @Effect()
  public changeTimeViewSetting$: Observable<Action> = this.actions$.pipe(
    ofType(settingsActions.SetUserTimezoneView),
    switchMap((action: any) => {
      const userSettings: UserSettings = {
        ...this.userSettingsProvider.userSettings,
        timeZoneViewType: action.payload.value
      };
      // this is temporary until we will remove the old user settings provider
      this.userSettingsProvider.userSettings = userSettings;
      return this.userSettingsService.updateUserSettings(userSettings)
        .map(
          () =>
            settingsActions.SetUserTimezoneViewCompleted(),
        )
        .catch((err) =>
          of(settingsActions.SetUserTimezoneViewFailed({ payload: action.payload.prevValue }))
        );
    }),
  );

  @Effect({ dispatch: false })
  public changeTimeViewSettingComplete$: Observable<Action> = this.actions$.pipe(
    ofType(settingsActions.SetUserTimezoneViewCompleted),
    tap((action: any) => this.dialogService.showCoralogixMessage('Time zone view changed successfully')),
  );

  @Effect({ dispatch: false })
  public changeTimeViewSettingFail$: Observable<Action> = this.actions$.pipe(
    ofType(settingsActions.SetUserTimezoneViewFailed),
    tap(({ payload }) => {
      // this is temporary until we will remove the old user settings provider
      this.userSettingsProvider.userSettings = {
        ...this.userSettingsProvider.userSettings,
        timeZoneViewType: payload
      };
    }),
    tap((action: any) => this.dialogService.showCoralogixMessage('Failed to change Time Zone view',
      null, dialogServiceIconClasses.failed)),
  );

  @Effect()
  public changeDateFormatSetting$: Observable<Action> = this.actions$.pipe(
    ofType(settingsActions.SetUserDateFormatView),
    switchMap((action: any) => {
      const userSettings: UserSettings = {
        ...this.userSettingsProvider.userSettings,
        dateFormatType: action.payload.value
      };
      // this is temporary until we will remove the old user settings provider
      this.userSettingsProvider.userSettings = userSettings;
      return this.userSettingsService.updateUserSettings(userSettings)
        .map(
          () =>
            settingsActions.SetUserDateFormatViewCompleted(),
        )
        .catch((err) =>
          of(settingsActions.SetUserDateFormatViewFailed({ payload: action.payload.prevValue })),
        );
    }),
  );

  @Effect({ dispatch: false })
  public changeDateFormatSettingComplete$: Observable<Action> = this.actions$.pipe(
    ofType(settingsActions.SetUserDateFormatViewCompleted),
    tap((action: any) => this.dialogService.showCoralogixMessage('Date format view changed successfully')),
  );

  @Effect({ dispatch: false })
  public changeDateFormatSettingFail$: Observable<Action> = this.actions$.pipe(
    ofType(settingsActions.SetUserDateFormatViewFailed),
    tap(({ payload }) => {
      // this is temporary until we will remove the old user settings provider
      this.userSettingsProvider.userSettings = {
        ...this.userSettingsProvider.userSettings,
        dateFormatType: payload
      };
    }),
    tap((action: any) => this.dialogService.showCoralogixMessage('Failed to change Date format view',
      null, dialogServiceIconClasses.failed)),
  );

  constructor(
    private actions$: Actions,
    private userSettingsService: UserSettingsService,
    private userSettingsProvider: UserSettingsProvider,
    private store: Store<State>,
    private dialogService: ShDialogService
  ) {}
}
