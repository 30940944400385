import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
declare let $;

@Directive({ selector: '[popup-directive]' })
export class PopupDirective implements AfterViewInit, OnDestroy {
  public _on: string;
  public _position: string;
  @Input() public hoverable: boolean = false;
  @Input() public hideAfterClick: boolean = false;
  @Input() public popup: HTMLElement;

  @Input('popup-directive') set on(value: string) {
    this._on = value;
  }

  @Input('popup-position') set popupPosition(position: string) {
    this._position = position;
  }

  constructor(private el: ElementRef) {}

  public ngAfterViewInit() {
    if (this.el) {
      const position = this._position ? this._position : 'top center';
      const options = {
        inline: false,
        position,
        lastResort: position,
      };
      if (this._on) {
        options['on'] = this._on;
      }

      if (this.hoverable) {
        options['hoverable'] = this.hoverable;
      }

      $(this.el.nativeElement).popup(options);
    }
    if (this.popup && this.hideAfterClick) {
      this.popup.addEventListener('click', () =>
        $(this.el.nativeElement).popup('hide'),
      );
    }
  }

  public ngOnDestroy() {
    if (this.el) {
      $(this.el.nativeElement).popup('remove popup');
    }
  }
}
