import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safeResourceUrl' })
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(url: string, args?: any): SafeResourceUrl {
    if (args) {
      url += args;
    }

    // TODO: probably should be "return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url);"
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
