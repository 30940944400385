import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { IPluginSerialized, Plugin } from '@app/logs-new/components/plugins-container/model/plugin.model';
import { IUpdatePluginsSettingsBody, LogMenuPluginsService } from '@app/services/log-menu-plugins/log-menu-plugins.service';
import { CreatePlugin, DeletePlugin, GetLogFields, GetPlugins, UpdatePlugin, UpdatePluginsOrder } from './plugins.action';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ShDialogService } from '@shared/services/dialogService';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { FormGroup } from '@angular/forms';
import { keyBy } from 'lodash-es';
import { PluginTypes } from '@shared/enums/plugin-types.enum';

export interface IPluginsModel {
  privatePlugins: { [id: string]: Plugin };
  privatePluginIds: string[];
  sharedPlugins: { [id: string]: Plugin };
  sharedPluginIds: string[];
  logFields: string[];
  deleteLoading: boolean;
  saveLoading: boolean;
  currentPluginForm: FormGroup;
  newPluginForm: FormGroup;
  getLoading: boolean;
}

const pluginsInitialState = (): IPluginsModel => {
  return {
    privatePlugins: {},
    privatePluginIds: [],
    sharedPlugins: {},
    sharedPluginIds: [],
    logFields: [],
    deleteLoading: false,
    saveLoading: false,
    currentPluginForm: null,
    newPluginForm: null,
    getLoading: false,
  };
};

@State<IPluginsModel>({
  name: 'plugins',
  defaults: pluginsInitialState(),
})
@Injectable()
export class PluginsState {
  constructor(private logMenuPluginsService: LogMenuPluginsService, private dialogService: ShDialogService) {}

  @Selector()
  public static privatePluginIds(state: IPluginsModel): string[] {
    return state?.privatePluginIds;
  }

  @Selector()
  public static privatePlugins(state: IPluginsModel): { [id: string]: Plugin } {
    return state?.privatePlugins;
  }

  @Selector()
  public static sharedPluginIds(state: IPluginsModel): string[] {
    return state?.sharedPluginIds;
  }

  @Selector()
  public static sharedPlugins(state: IPluginsModel): { [id: string]: Plugin } {
    return state?.sharedPlugins;
  }

  @Selector()
  public static pluginsLogFields(state: IPluginsModel): string[] {
    return state?.logFields;
  }

  @Selector()
  public static deleteLoading(state: IPluginsModel): boolean {
    return state?.deleteLoading;
  }

  @Selector()
  public static saveLoading(state: IPluginsModel): boolean {
    return state?.saveLoading;
  }

  @Selector()
  public static currentPluginForm(state: IPluginsModel): FormGroup {
    return state?.currentPluginForm;
  }

  @Selector()
  public static newPluginForm(state: IPluginsModel): FormGroup {
    return state?.newPluginForm;
  }

  @Selector()
  public static getLoading(state: IPluginsModel): boolean {
    return state?.getLoading;
  }

  @Selector([PluginsState.deleteLoading, PluginsState.saveLoading])
  public static loading(deleteLoading: boolean, saveLoading: boolean): boolean {
    return deleteLoading || saveLoading;
  }

  @Selector([PluginsState.privatePluginIds, PluginsState.privatePlugins])
  public static privatePluginList(privatePluginIds: string[], plugins: { [id: string]: Plugin }): Plugin[] {
    return privatePluginIds.map(id => plugins[id]);
  }

  @Selector([PluginsState.sharedPluginIds, PluginsState.sharedPlugins])
  public static sharedPluginList(sharedPluginIds: string[], plugins: { [id: string]: Plugin }): Plugin[] {
    return sharedPluginIds.map(id => plugins[id]);
  }

  @Selector([PluginsState.privatePluginList])
  public static enabledPrivatePlugins(privatePlugins: Plugin[]): Plugin[] {
    return privatePlugins.filter(plugin => !plugin.isHidden);
  }

  @Selector([PluginsState.sharedPluginList])
  public static enabledSharedPlugins(sharedPlugins: Plugin[]): Plugin[] {
    return sharedPlugins.filter(plugin => !plugin.isHidden);
  }

  @Selector([PluginsState.privatePlugins, PluginsState.sharedPlugins])
  public static plugins(privatePlugins: { [p: string]: Plugin }, sharedPlugins: { [p: string]: Plugin }): { [p: string]: Plugin } {
    return { ...privatePlugins, ...sharedPlugins };
  }

  @Action(GetPlugins)
  public GetPlugins(ctx: StateContext<IPluginsModel>): Observable<void> {
    ctx.patchState({ getLoading: true });
    return this.logMenuPluginsService.getPlugins().pipe(
      map(({ privatePluginList, sharedPluginList }) => {
        const privatePluginIds = privatePluginList.map(plugin => plugin.id);
        const sharedPluginIds = sharedPluginList.map(plugin => plugin.id);
        const privatePlugins = keyBy(privatePluginList, 'id');
        const sharedPlugins = keyBy(sharedPluginList, 'id');
        ctx.patchState({ privatePlugins, sharedPlugins, privatePluginIds, sharedPluginIds });
      }),
      catchError(err => {
        const message = err?.error?.message || 'Failed to get actions';
        this.dialogService.showCoralogixMessage(message, null, dialogServiceIconClasses.failed);
        return throwError(err);
      }),
      finalize(() => {
        ctx.patchState({ getLoading: false });
      }),
    );
  }

  @Action(GetLogFields)
  public GetLogFields(ctx: StateContext<IPluginsModel>): Observable<IPluginsModel> {
    const customGlobalFields = ['$p.start_date', '$p.end_date', '$p.selected_value'];
    return this.logMenuPluginsService.getFields().pipe(
      map(logFields => {
        const metaDataFields = logFields.metadataFields.map(metadataField => metadataField.replace('coralogix.metadata', '$m'));
        const originLogFields = logFields.fields.map(originField => '$d.' + originField);
        return ctx.patchState({ logFields: [...customGlobalFields, ...metaDataFields, ...originLogFields] });
      }),
    );
  }

  @Action(UpdatePlugin, { cancelUncompleted: true })
  public UpdatePlugin(ctx: StateContext<IPluginsModel>, { plugin }: UpdatePlugin): Observable<void> {
    const body: IPluginSerialized = Plugin.serializer(plugin);
    ctx.patchState({ saveLoading: true });
    return this.logMenuPluginsService.updatePlugins(body).pipe(
      map(() => {
        const { privatePlugins, sharedPlugins, privatePluginIds, sharedPluginIds } = ctx.getState();
        const oldPlugin = privatePlugins[plugin.id] ?? sharedPlugins[plugin.id];
        if (oldPlugin.isPrivate !== plugin.isPrivate) {
          if (plugin.isPrivate) {
            const newSharedPluginIds = sharedPluginIds.filter(id => id !== plugin.id);
            const newSharedPlugins = { ...sharedPlugins };
            delete newSharedPlugins[plugin.id];
            const newPrivatePluginIds = [plugin.id, ...privatePluginIds];
            const newPrivatePlugins = { ...privatePlugins, [plugin.id]: plugin };

            ctx.patchState({
              privatePlugins: newPrivatePlugins,
              sharedPlugins: newSharedPlugins,
              privatePluginIds: newPrivatePluginIds,
              sharedPluginIds: newSharedPluginIds,
            });
          } else {
            const newSharedPluginIds = [...sharedPluginIds, plugin.id];
            const newSharedPlugins = { ...sharedPlugins };
            newSharedPlugins[plugin.id] = plugin;
            const newPrivatePluginIds = privatePluginIds.filter(id => id !== plugin.id);
            const newPrivatePlugins = { ...privatePlugins };
            delete newPrivatePlugins[plugin.id];

            ctx.patchState({
              privatePlugins: newPrivatePlugins,
              sharedPlugins: newSharedPlugins,
              privatePluginIds: newPrivatePluginIds,
              sharedPluginIds: newSharedPluginIds,
            });
          }
        } else {
          if (plugin.isPrivate) {
            ctx.patchState({ privatePlugins: { ...privatePlugins, [plugin.id]: plugin } });
          } else {
            ctx.patchState({ sharedPlugins: { ...sharedPlugins, [plugin.id]: plugin } });
          }
        }
      }),
      finalize(() => {
        ctx.patchState({ saveLoading: false });
      }),
    );
  }

  @Action(CreatePlugin)
  public CreatePlugin(ctx: StateContext<IPluginsModel>, { plugin: pluginToUpdate }: CreatePlugin): Observable<void> {
    const body: IPluginSerialized = Plugin.serializer(pluginToUpdate);
    ctx.patchState({ saveLoading: true });
    return this.logMenuPluginsService.createPlugins(body).pipe(
      map(plugin => {
        const { privatePlugins, privatePluginIds, sharedPlugins, sharedPluginIds } = ctx.getState();
        if (plugin.isPrivate) {
          ctx.patchState({
            privatePlugins: { ...privatePlugins, [plugin.id]: plugin },
            privatePluginIds: [plugin.id, ...privatePluginIds],
          });
        } else {
          ctx.patchState({
            sharedPlugins: { ...sharedPlugins, [plugin.id]: plugin },
            sharedPluginIds: [...sharedPluginIds, plugin.id],
          });
        }
      }),
      catchError(err => {
        const message = err?.error?.message || 'Failed to create action';
        this.dialogService.showCoralogixMessage(message, null, dialogServiceIconClasses.failed);
        return throwError(err);
      }),
      finalize(() => {
        ctx.patchState({ saveLoading: false });
      }),
    );
  }

  @Action(DeletePlugin)
  public DeletePlugin(ctx: StateContext<IPluginsModel>, { id }: DeletePlugin): Observable<void> {
    ctx.patchState({ deleteLoading: true });
    return this.logMenuPluginsService.deletePlugins(id).pipe(
      map(() => {
        const { privatePlugins, privatePluginIds, sharedPlugins, sharedPluginIds } = ctx.getState();
        const newPrivatePlugins = { ...privatePlugins };
        const newSharedPlugins = { ...sharedPlugins };
        if (Object.keys(privatePlugins).includes(id)) {
          delete newPrivatePlugins[id];
          const index = privatePluginIds.findIndex(pluginId => pluginId === id);
          const newPrivatePluginIds = [...privatePluginIds.slice(0, index), ...privatePluginIds.slice(index + 1, privatePluginIds.length)];
          ctx.patchState({ privatePlugins: newPrivatePlugins, privatePluginIds: newPrivatePluginIds });
        } else if (Object.keys(sharedPlugins).includes(id)) {
          delete newSharedPlugins[id];
          const index = sharedPluginIds.findIndex(pluginId => pluginId === id);
          const newSharedPluginIds = [...sharedPluginIds.slice(0, index), ...sharedPluginIds.slice(index + 1, sharedPluginIds.length)];
          ctx.patchState({ sharedPlugins: newSharedPlugins, sharedPluginIds: newSharedPluginIds });
        } else {
          ctx.patchState({ sharedPlugins, sharedPluginIds });
        }
      }),
      catchError((err: any) => {
        if (err.message) {
          this.dialogService.showCoralogixMessage(err.message, null, dialogServiceIconClasses.failed);
        } else {
          this.dialogService.showCoralogixMessage('Failed to delete action', null, dialogServiceIconClasses.failed);
        }
        return throwError(err);
      }),
      finalize(() => {
        ctx.patchState({ deleteLoading: false });
      }),
    );
  }

  @Action(UpdatePluginsOrder)
  public UpdatePluginsOrder(ctx: StateContext<IPluginsModel>, { type, ids }: UpdatePluginsOrder): Observable<void> {
    const { privatePluginIds, sharedPluginIds } = ctx.getState();

    const serializeOrderList = (list: string[]) => {
      return list.reduce((acc, id, order) => {
        acc[id] = { order };
        return acc;
      }, {});
    };

    let oldIds;
    if (type === PluginTypes.Private) {
      oldIds = privatePluginIds;
      ctx.patchState({ privatePluginIds: ids });
    } else {
      oldIds = sharedPluginIds;
      ctx.patchState({ sharedPluginIds: ids });
    }

    const newOrder = serializeOrderList(ids);

    const newPrivatePluginIds = type === PluginTypes.Private ? newOrder : serializeOrderList(privatePluginIds);
    const newSharedPluginIds = type === PluginTypes.Shared ? newOrder : serializeOrderList(sharedPluginIds);

    const body: IUpdatePluginsSettingsBody = {
      privateSettings: newPrivatePluginIds,
      sharedSettings: newSharedPluginIds,
    };
    return this.logMenuPluginsService.updatePluginsSettings(body).pipe(
      map(() => {
        ctx.getState();
      }),
      catchError(err => {
        const message = err?.error?.message || 'Failed to change actions order';
        this.dialogService.showCoralogixMessage(message, null, dialogServiceIconClasses.failed);
        if (type === PluginTypes.Private) {
          ctx.patchState({ privatePluginIds: oldIds });
        } else {
          ctx.patchState({ sharedPluginIds: oldIds });
        }
        return throwError(err);
      }),
    );
  }
}
