<div>
  <form [formGroup]="form" (submit)="submit(form.value)">
    <section class="head-section">
      <p class="primary-header coralogix">{{ isInEditMode ? 'Edit' : 'New' }} Log2Metric</p>
      <div matTooltip="Metric name and Metric Label names can't be the same" [matTooltipDisabled]="!groupsContainsName">
        <button
          [disabled]="!isFormValid"
          [class.disabled]="!isFormValid"
          class="button coralogix secondary-add-button"
          type="submit"
        >
          {{ isInEditMode ? 'Save' : 'Create' }} Log2Metric
        </button>
      </div>
    </section>

    <section class="content-wrapper">
      <div class="content">
        <sh-metrics-editor-details
          [form]="detailsGroup"
          [groupsContainsName]="groupsContainsName"
          (metricName)="setMetricName($event)"
        ></sh-metrics-editor-details>
        <sh-metrics-editor-agg-def
          [form]="queryDefinition"
          [validationError]="validationError"
          [logsSeverityOptions]="logsSeverityOptions"
        ></sh-metrics-editor-agg-def>
        <sh-field-extraction-selector
          [form]="groupMetricFields"
          [options]="optionalGroupByFields"
          [metricId]="editedMetric?.id"
          headerText="Metric Fields"
          labelText="Metric Name"
          [limitFields]="10"
          [isMetricField]="true"
	  entityType="metric"
        >
          <p select-subheader class="mat-card-subheader">
            Choose a Metric name and a field to extract minutely 'max', 'min', 'sum', 'count' and 'average' aggregations
            from.
          </p>
        </sh-field-extraction-selector>
        <div class="conflict-error" *ngIf="dbConflictError">
          {{ dbConflictError }}
        </div>
        <sh-field-extraction-selector
          [form]="groupByGroup"
          [options]="optionalGroupByFields"
          [metricId]="editedMetric?.id"
          (selectedLabelNames)="setSelectedLabelNames($event)"
          headerText="Labels"
          labelText="Label Name"
          [limitFields]="companyLabelLimit"
	  entityType="label"
        >
          g
          <p select-subheader class="mat-card-subheader">
            Choose fields to label your visualization by. Choose up to {{ companyLabelLimit }} Group by levels.
          </p>
        </sh-field-extraction-selector>

        <sh-permutations-settings
          [isInEditMode]="isInEditMode"
          [metric]="editedMetric"
          [permutationsLimitControl]="permutationsLimitControl"
        ></sh-permutations-settings>

        <button
          *ngIf="isInEditMode"
          class="button coralogix delete-button"
          type="button"
          (click)="onDelete.emit(editedMetric)"
        >
          Delete Metric
        </button>
      </div>
    </section>
  </form>
</div>
