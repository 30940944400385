
export class RegExpHelper {

  public static escapeRegExp(str: string): string {
    // taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    // $& means the whole matched string
    return str ? str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : '';
  }

}
