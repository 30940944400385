<div class="coralogix input-group">
  <div class="form-timepicker-btn"
       [ngClass]="{ 'form-timepicker-btn--error': hasError,
       'form-timepicker-btn--disabled': disableOpenEvent }" (click)="displayTimePicker()">
    <img class="form-timepicker-btn__img" src="{{sharedAssetsPath}}calendar.svg" />
    <span  class="form-timepicker-btn__label">{{ formControl.value | date : 'longDate' }}</span>
  </div>
  <div class="timepicker-container">
    <div class="timepicker-container__panel">
      <sh-ng-datepicker
        [isOpened]="timePickerSeen"
        [inline]="true"
        [headless]="true"
        class="coralogix-date-picker"
        [formControl]="formControl"
        [options]="dateOptions">
      </sh-ng-datepicker>
    </div>
  </div>
</div>
