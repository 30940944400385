import { Injectable } from '@angular/core';
import { Constants } from '../../../constants';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { TypedJSON } from 'typedjson';
import {
  IntegrationModel,
  IntegrationTypeModel,
  IntegrationTestResult,
} from '../models/newIntegrationRequest';
import * as _ from 'lodash';

@Injectable()
export class IntegrationService {
  public integrationTypes: IntegrationTypeModel[];
  private integrationsUrl: string =
    Constants.GetUrl() + Constants.integrationsUrl;
  private integrationTypesUrl: string =
    Constants.GetUrl() + Constants.integrationTypesUrl;
  private integrations$: Observable<IntegrationModel[]>;
  private integrationTypes$: Observable<IntegrationTypeModel[]>;

  constructor(private http: HttpClient) {}

  public getIntegrations(): Observable<IntegrationModel[]> {
    if (this.integrations$) {
      return this.integrations$;
    } else {
      const obs = (this.integrations$ = this.http
        .get(this.integrationsUrl)
        .map(this.extractIntegrationsData)
        .map((res) => {
          this.integrations$ = null;
          return res;
        })
        .catch((error) => this.handleError(error)));
      return obs;
    }
  }

  public getIntegrationTypes(): Observable<IntegrationTypeModel[]> {
    if (this.integrationTypes$) {
      return this.integrationTypes$;
    } else {
      const obs = (this.integrationTypes$ = this.http
        .get(this.integrationTypesUrl)
        .map(this.extractIntegrationTypeData)
        .map((res) => {
          this.integrationTypes$ = null;
          this.integrationTypes = res;
          return res;
        })
        .catch((error) => this.handleError(error)));
      return obs;
    }
  }

  public saveIntegration(
    model: IntegrationModel,
  ): Observable<IntegrationModel> {
    const observable = this.http
      .post(this.integrationsUrl, JSON.stringify(this.packData(model)))
      .map((res) => this.extractData(res, model))
      .catch(this.handleError);

    return observable;
  }

  public deleteIntegration(id: number) {
    const obs = this.http
      .delete(this.integrationsUrl + '/' + id)
      .map((res) => {
        return res;
      })
      .catch((error) => this.handleError(error));
    return obs;
  }

  public testIntegrationConfig(
    model: IntegrationModel,
  ): Observable<IntegrationTestResult> {
    const observable = this.http
      .post(
        `${this.integrationsUrl}/validate`,
        JSON.stringify(this.packData(model)),
      )
      .map((res: any) => res)
      .catch((e) => Observable.throw(e.json()));

    return observable;
  }

  private extractIntegrationsData(res) {
    const integrationsRes = res.map((integration) => {
      const jsonFields = JSON.parse(integration.integration_type_fields);
      if (jsonFields) {
        integration.integration_type_fields = JSON.parse(
          integration.integration_type_fields,
        ).map((field) => {
          if (typeof field.value === 'object') {
            return { name: field.name, value: JSON.stringify(field.value) };
          }

          return field;
        });
      }

      return new TypedJSON(IntegrationModel).parse(JSON.stringify(integration));
    });

    return integrationsRes;
  }

  private handleError(error: any) {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

  private extractIntegrationTypeData(res): IntegrationTypeModel[] {
    const integrationsRes = res.map((integration) => {
      return new TypedJSON(IntegrationTypeModel).parse(
        JSON.stringify(integration),
      );
    });

    return refactorIntegrationTypes(integrationsRes);
  }

  private packData(model: IntegrationModel) {
    const integration = _.cloneDeep(model);

    return {
      id: integration.id,
      alias: integration.alias,
      integration_type: model.integration_type,
      integration_type_id: integration.integration_type_id,
      integration_type_fields: JSON.stringify(
        integration.integration_type_fields,
      ),
      url: integration.url,
    };
  }

  private extractData(res, model: IntegrationModel): IntegrationModel {
    if (model.id && res[0] === 1) {
      return model;
    }

    const jsonRes = res;
    if (jsonRes.integration_type_fields) {
      jsonRes.integration_type_fields = JSON.parse(
        jsonRes.integration_type_fields,
      ).map((field) => {
        if (typeof field.value === 'object') {
          return { name: field.name, value: JSON.stringify(field.value) };
        }

        return field;
      });
    }

    const integration = new TypedJSON(IntegrationModel).parse(
      JSON.stringify(jsonRes),
    );
    integration.integration_type = model.integration_type;

    return integration;
  }
}

// Helper function for changing the order and names of integration types
const refactorIntegrationTypes = (integrations: IntegrationTypeModel[]): IntegrationTypeModel[] => {
  const result = new Array(integrations.length);
  integrations.forEach( (integration) => {
    switch (integration.name) {
      case 'Slack':
        result[0] = integration;
        break;
      case 'PagerDuty':
        result[1] = integration;
        break;
      case 'Opsgenie':
        result[2] = integration;
        break;
      case 'MicrosoftTeams':
        integration.name = 'Microsoft Teams';
        result[3] = integration;
        break;
      case 'WebHook':
        result[4] = integration;
        break;
      case 'Jira':
        result[5] = integration;
        break;
      case 'Demisto':
        result[6] = integration;
        break;
      case 'EmailGroup':
        integration.name = 'Email Group';
        result[7] = integration;
        break;
      case 'SendLog':
        integration.name = 'Send Log';
        result[8] = integration;
        break;
      default:
        break;
    }
  });

  return result as IntegrationTypeModel[];
};
