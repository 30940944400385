<div class="container limit-warning" *ngIf="rulesLeft > 1">
  You are about to reach your rules limit. You have {{ rulesLeft }} rules left.
</div>

<div class="container limit-warning" *ngIf="rulesLeft === 1">
  You are about to reach your rules limit. You have 1 rule left.
</div>

<div class="container exceed-limit" *ngIf="rulesLeft === 0">
  You've reached your rules limit. Delete an existing rule In order to add a new one.
</div>
