import { Pipe, PipeTransform } from '@angular/core';
import { formatsHelper } from '../../services/formatsHelper';

@Pipe({
  name: 'numbersFormat',
})
export class NumbersFormatPipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    if (value) {
      return formatsHelper.numberWithCommas(value);
    }

    return value;
  }
}
