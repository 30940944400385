import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { getMainParsingThemeViewState, getParsingThemes, getPTRulesLeft, State } from '@app/app.reducers';
import { MatDialog } from '@angular/material/dialog';
import { RulesTypes } from '@app/rules/shared/models/rule-types-view.models';
import { AnalyticEventService, SupportedIntercomePropTypes } from '@app/user/shared/AnalyticEventService';
import { RuleAssetsUrl, RulesBaseThemeUrl, RulesBaseUrl } from '@app/rules/shared/models/rules-consts';
import { RulesMainViewMode } from '@app/rules/shared/models/shared-vew.models';
import { Observable } from 'rxjs';
import { ParsingThemesEditorModes } from '@app/rules/shared/models/parsing-theme-editor-mode.enum';
import { filter, take } from 'rxjs/operators';
import { ERuleServerTypes, IParsingTheme, ParsingTheme } from '@app/rules/shared/models/rule.model';
import { ParsingThemeEditorPanelComponent } from '@app/rules/containers/parsing-theme-editor-panel/parsing-theme-editor-panel.component';
import * as parsingThemesActions from '@app/rules/actions/parsing-themes.actions';
import { getMetadataField } from '@shared/state/actions/generic-metadata.actions';
import { Constants } from '@app/constants';
import { environment } from '@environments/environment';

@Component({
  selector: 'sh-rule-main-layout',
  templateUrl: './rule-main-layout.component.html',
  styleUrls: ['./rule-main-layout.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RuleMainLayoutComponent implements OnDestroy {
  rulesLeft: number;
  parsingThemeEditorMode: typeof ParsingThemesEditorModes = ParsingThemesEditorModes;
  @ViewChild('parsingThemePanel', { static: true })
  parsingThemePanelComponent: ParsingThemeEditorPanelComponent;
  rulesCloseToLimit: boolean = false;
  rulesTypeList: typeof RulesTypes = RulesTypes;
  isNotificationHidden: boolean = false;
  addRuleBtnIconUrl: string = `${RuleAssetsUrl}/icon-parsing-rules.svg`;
  ruleNotificationIconUrl: string = `${RuleAssetsUrl}/support-help.svg`;
  viewMode$: Observable<RulesMainViewMode>;
  viewModeOptions: typeof RulesMainViewMode = RulesMainViewMode;
  isStandAlone: boolean = environment['standAlone'];
  private subs: Subscription[] = [];

  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private router: Router,
    private ae: AnalyticEventService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.setInitialData();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.router.onSameUrlNavigation = 'ignore';
  }

  onNotificationButtonPress(): void {
    this.ae.showIntercomeWithProp(SupportedIntercomePropTypes.showNewMessage);
  }

  openParsingThemePanel(
    mode: ParsingThemesEditorModes,
    ruleType: ERuleServerTypes = null,
    parsingTheme: IParsingTheme = new ParsingTheme(),
  ): void {
    this.parsingThemePanelComponent.openRuleGroupEditor(mode, parsingTheme, ruleType);
  }

  private setInitialData(): void {
    const rulesSub: Subscription = this.store.select(getPTRulesLeft).subscribe(rulesLeft => {
      this.rulesLeft = rulesLeft;
      this.rulesCloseToLimit = rulesLeft <= 5;
    });
    this.store.dispatch(parsingThemesActions.getParsingThemesAction());
    this.store.dispatch(getMetadataField({ payload: { fieldName: Constants.metadataFields()[0] || 'applicationName' } }));
    this.store.dispatch(getMetadataField({ payload: { fieldName: Constants.metadataFields()[1] || 'subsystemName' } }));
    this.viewMode$ = this.store.select(getMainParsingThemeViewState);
    this.setRouteSubscription();
    this.subs.push(rulesSub);
  }

  private setRouteSubscription(): void {
    this.router.onSameUrlNavigation = 'reload';
    const routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const paramChildren = this.activatedRoute.snapshot.children;
      const currentId = paramChildren.length ? paramChildren[0].params?.themeId : null;
      const ruleType = paramChildren.length ? paramChildren[0].params?.ruleType : null;
      if (currentId && this.router.url.includes(RulesBaseThemeUrl)) {
        this.openParsingThemeEditorPanelByThemeId(currentId);
      } else if (this.router.url.includes(`${RulesBaseThemeUrl}/new`)) {
        this.openParsingThemePanel(ParsingThemesEditorModes.create, ruleType);
      } else if (this.router.url.includes(`${RulesBaseUrl}`)) {
        this.parsingThemePanelComponent.hideSidebarAndReplaceUrl();
      }
    });
    this.subs.push(routeSubscription);
  }

  private openParsingThemeEditorPanelByThemeId(parsingThemeId: string): void {
    this.store
      .select(getParsingThemes)
      .pipe(
        filter(groups => !!groups.length),
        take(1),
      )
      .subscribe(ruleGroups => {
        const theme = ruleGroups.find(pTheme => pTheme.id === parsingThemeId);
        if (theme) {
          this.openParsingThemePanel(ParsingThemesEditorModes.edit, null, theme);
        }
      });
  }
}
