import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs/observable/of';
import { LogActions } from './log.actions';
import { LogsService } from '../services/logs-service';
import { switchMap, tap } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { GlobalParamsProvider } from '@shared/services/globalParams.provider';

@Injectable()
export class LogEffects {
  @Effect()
  public getQueryStats$: Observable<Action> = this.actions$.pipe(
    ofType(LogActions.ActionTypes.GET_LOGS_AND_TEMPLATE_COUNT),
    switchMap((action: any) => {
      return this.logService
        .getQueryStats(action.payload)
        .map((res) => new LogActions.GetQueryCountCompleted(res))
        .catch((err) => of(new LogActions.GetQueryCountFailed(err)));
    }),
  );

  @Effect()
  public createCachedQuery: Observable<Action> = this.actions$.pipe(
    ofType(LogActions.ActionTypes.CREATE_CACHED_QUERY),
    switchMap((action: any) => {
      return this.logService.saveLogsQuery(action.payload)
        .map((res) => new LogActions.CreateCachedQueryCompleted(res))
        .catch((err) => of(new LogActions.CreateCachedQueryFailed((err))));
    })
  );

  @Effect({ dispatch: false })
  public navigateToLogs$: Observable<any> = this.actions$.pipe(
    ofType(LogActions.ActionTypes.CREATE_CACHED_QUERY_COMPLETED),
    tap((action: any) => {
      const navigationExtras: NavigationExtras = {
        queryParams: { id: action.payload.cacheQueryId },
      };
      this.globalParams.chartClickedLogQueryModel = action.payload;
      this.router.navigate(['/query-new/logs'], navigationExtras).finally();
    })
  );
  constructor(private actions$: Actions, private logService: LogsService, private router: Router,
              private globalParams: GlobalParamsProvider) {}
}
