<div fxLayout="column" class="tags-layout">
  <sh-page-sub-header class="sub-page-header" [ngClass]="{ 'custom-tags': !!selectedTag }" [title]="headerTitle">
    <div class="custom-content" *ngIf="selectedTag" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="custom-content__replace-title">
        {{ selectedTag.text_value }}
      </div>
      <div fxLayout="row" fxFlex="75" fxLayoutAlign="center center">
        <sh-coralogix-select
          [class.hidden]="!compareTagCtrlList?.length"
          class="custom-content__select-tag"
          [selectOptions]="compareTagCtrlList"
          [multiple]="false"
          [searchable]="true"
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [placeholder]="'Select Compare Tag'"
          [disableSelectAll]="true"
          [formControl]="tagToCompareCtrl"
        >
          <label select-label>
            <span class="control-label">COMPARE WITH</span>
          </label>
        </sh-coralogix-select>
        <mat-slide-toggle labelPosition="after" class="coralogix" [formControl]="isSelectAllSubsystemsCtrl">
          <span class="control-label custom-content__slider-label">COMPARE TO ALL APPLICATION’S SUBSYSTEMS</span>
        </mat-slide-toggle>
      </div>
      <cgx-button
        label="NEW TAG"
        size="md"
        width="144px"
        srcIconStart="assets/icons/tag-info.svg"
        (press)="openEditorPanel(penalModes.create, null)"
      ></cgx-button>
    </div>
  </sh-page-sub-header>
  <div fxLayout="row" class="tags-list-panel-container">
    <sh-tags-list
      class="custom-scroller"
      *ngIf="(tagsList$ | async)?.length"
      [tagsList]="tagsList$ | async"
      (tagSelected)="tagSelected($event)"
      [loading]="loading$ | async"
      (deleteTag)="deleteTag($event)"
      [selectedTag]="selectedTag"
      (editTag)="tagPanelComponent.openEditor(penalModes.edit, $event)"
      (goBack)="redirectToMainTagsRoute()"
    ></sh-tags-list>
    <sh-tag-panel-container #tagPanel></sh-tag-panel-container>
    <div class="details-container custom-scroller">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
