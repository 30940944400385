<div class="alerts-header">
  <div class="title">Manage Alerts</div>
  <div fxFlex="20" class="newAlertButton">
    <cgx-button
      *ngIf="!(isLoadingAlertsTable$ | async) && filteredAlerts?.length"
      data-test="alerts-create-new-alert-btn"
      label="New Alert"
      size="md"
      srcIconStart="assets/icons/sm-24x24/alerts-bell.svg"
      (press)="openAlertsEditor(false)"
    ></cgx-button>
  </div>
</div>

<div class="alerts-content">
  <div class="alerts-card">
    <sh-alerts-summery-card
      [insights]="filteredInsights | insightsLastHoursFilter: 24"
      [alerts]="filteredAlerts"
      [alertsInsightsTime]="insightsTime"
      [isLoading]="(isLoadingAlertsGraph$ | async) || (isLoadingAlertsTable$ | async)"
    ></sh-alerts-summery-card>
  </div>

  <div class="alertsContainer">
    <div [class.active]="isLoadingAlertsTable$ | async" class="ui inline loader"></div>
    <sh-alerts-table
      *ngIf="filteredAlerts?.length"
      [alerts]="filteredAlerts"
      (editAlert)="openAlertsEditor(true, $event)"
      [users]="users$ | async"
      [currentUser]="user$ | async"
    ></sh-alerts-table>
  </div>
</div>

<sh-alerts-empty
  *ngIf="!(isLoadingAlertsGraph$ | async) && !(isLoadingAlertsTable$ | async) && !filteredAlerts?.length"
  (openNewAlert)="openAlertsEditor(false)"
></sh-alerts-empty>
