export class GetSnoozeStatus {
  public static readonly type: string = '[Alerts] Get snooze status';
  constructor(public alertId: string) { }
}

export class GetSnoozedAlerts {
  public static readonly type: string = '[Alerts] Get snoozed alerts';
  constructor(public alertIds: string[]) { }
}

export class SnoozeAlert {
  public static readonly type: string = '[Alerts] Snooze alert';
  constructor(public alertId: string, public seconds: number) { }
}

export class UnsnoozeAlert {
  public static readonly type: string = '[Alerts] Unsnooze alert';
  constructor(public alertId: string) { }
}
