import { AlertModel } from '@app/alerts/shared/models/alert-model';

export class AlertEditFromQueryModel extends AlertModel {
    public queryParamsJson?: any;

    constructor(init?: Partial<AlertEditFromQueryModel>) {
        super();
        Object.assign(this, init);
    }
}
