import {Compiler, Component, ComponentRef, ElementRef, EventEmitter, Injector, Input, Output, ViewChild} from '@angular/core';
import {formatsHelper} from '@shared/services/formatsHelper';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import {ChartSelectionContextObject, PointClickEventObject} from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import {ChartClickParams, ChartModel, EGraphTooltipStyles} from '../models/chart.model';
import {LogQueryModel} from '@app/logs/shared/models/logsquery.model';
import {LivetailStatsCount} from '@app/livetail/shared/models/livetailStatsEntity';
import {Build} from '@app/deployment/models/build';
import {DeploymentService} from '@app/deployment/shared/services/deployment.service';
import {ClipboardService} from '@shared/services/clipboard-service';
import {ChartFormatterService} from '../services/chart-formatter.service';
import {ChartOptionsService} from '../services/chart-options.service';
import {ChartLogQueryService} from '../services/chart-log-query.service';
import {Store} from '@ngrx/store';
import {getTimezoneViewPreference, State} from '@app/app.reducers';
import {tap} from 'rxjs/operators';
import {TimeZoneType} from '@shared/models/timezone-types';
import {
  getDynamicTooltipComponentInstance,
  getWhiteLineTooltipHtml,
  IChartTooltipPointData,
} from '@app/statistics/shared/helpers/line-chart-tags-helper';
import {DynamicLogsTooltipComponent} from '@shared/controls/charts/dynamic-tooltip/dynamic-logs-tooltip/dynamic-logs-tooltip.component';

HC_more(Highcharts);

declare let $;

@Component({
  selector: 'sh-basic-chart',
  templateUrl: './basic-chart.component.html',
  styleUrls: ['./basic-chart.component.scss'],
  providers: [ChartFormatterService, ChartOptionsService, ChartLogQueryService],
})
export class BasicChartComponent {
  @Input() set model(value: ChartModel) {
    this._chartModel = value;
    if (value) {
      this.initChart(value, this.isLocalTime);
    }
  }

  @Input() set realtimeData(value: LivetailStatsCount) {
    if (this._chart) {
      if (this._chart.series[0].data.length > 20) {
        this._chart.series[0].removePoint(0, false, false);
      }
      this._chart.series[0].addPoint([new Date().getTime(), value.count], true, false);
    }
  }

  @ViewChild('chart', {static: true}) public chartEl: ElementRef;
  @Output() public ChartTimeRangeSelected: EventEmitter<LogQueryModel> = new EventEmitter<LogQueryModel>();
  @Output() public chartClicked: EventEmitter<LogQueryModel | ChartClickParams> = new EventEmitter<LogQueryModel | ChartClickParams>();
  @Output() public chartLoaded: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('infoBar', {static: true}) public infoBarEl: ElementRef;
  public options: Highcharts.Options;
  public _chart: any;
  public _chartModel: ChartModel;
  private isLocalTime: boolean = true;

  constructor(
    private deplomentService: DeploymentService,
    private clipboardService: ClipboardService,
    private chartFormatterService: ChartFormatterService,
    private chartOptionsService: ChartOptionsService,
    private chartLogQueryService: ChartLogQueryService,
    private store: Store<State>,
    private injector: Injector,
    private compiler: Compiler,
  ) {
    this.store
      .select(getTimezoneViewPreference)
      .take(1)
      .pipe(
        tap(tmz => {
          this.isLocalTime = tmz === TimeZoneType.local;
        }),
      )
      .subscribe();
  }

  public onResize(): void {
    if (this._chart) {
      this._chart.redraw();
    }
  }

  public setWidth(width: number): void {
    if (this._chart) {
      this._chart.setSize(width, null, false);
    }
  }

  private initChart(data: ChartModel, isLocalTime: boolean): void {
    const that: BasicChartComponent = this;
    const infoBar = that.infoBarEl;
    const xCategories = !!data.xCategories?.length ? data.xCategories : null;
    let dynamicTooltipComp: ComponentRef<DynamicLogsTooltipComponent>;
    const isLogsTooltip = data.tooltipStyleType === EGraphTooltipStyles.whiteWithLinkButtonLogs;
    if (isLogsTooltip) {
      // Dynamically create and inject the tooltip
      dynamicTooltipComp = getDynamicTooltipComponentInstance(this.injector, this.compiler);
    }

    const chartOptions: any = {
      colors: data.colors,
      chart: this.getExtendedChartOptions(data),
      title: this.chartOptionsService.getTitleOptions(data),
      xAxis: {
        max: data.maxXAxis,
        crosshair: true,
        type: data.xType,
        minPadding: 0,
        maxPadding: 0,
        minorTickLength: data.minorTickLength,
        tickLength: data.tickLength,
        minRange: data.xMinRange(),
        lineWidth: data.lineWidth,
        minorGridLineWidth: data.minorGridLineWidth,
        tickInterval: data.tickIntervalX,
        gridLineWidth: data.gridLineWidthX,
        categories: xCategories,
        gridLineColor: 'var(--cgx-border-primary)',
        labels: {
          enabled: data.useLabels,
          formatter(): string {
            return that.chartFormatterService.labelFormatter(this, data, isLocalTime);
          },
          style: {
            color: 'var(--cgx-text-primary)',
          },
        },
        title: {
          text: data.xTitle,
          style: {
            color: 'var(--cgx-text-primary)',
          },
        },
      },
      yAxis: {
        max: data.maxYAxis,
        min: 0.001,
        type: data.yType,
        endOnTick: false,
        tickInterval: data.tickIntervalY,
        gridLineWidth: data.gridLineWidthY,
        gridLineColor: 'var(--cgx-border-primary)',
        labels: {
          enabled: data.yAxisEnable,
          style: {
            color: 'var(--cgx-text-primary)',
          },
        },
        title: {
          text: data.yTitle,
          style: {
            color: 'var(--cgx-text-primary)',
          },
        },
        showFirstLabel: false,
        allowDecimals: data.yAxisAllowDecimals,
        plotBands: data.yPlotBands,
      },
      categories: [...data.yCategories],
      tooltip: {
        enabled: data.tooltipEnabled,
        formatter: data.useDefaultFormater
          ? function (): any {
            if (data.activeBuildTooltip) {
              return [];
            }
            if (data.tooltipStyleType === EGraphTooltipStyles.whiteWithLinkButton) {
              return getWhiteLineTooltipHtml(this, that);
            }
            if (isLogsTooltip) {
              // Pass the point data to the dynamic tooltip dynamicTooltipComp
              const {chartTooltipDataTitle, tooltipButtonText} = that._chartModel;
              const compInstance = dynamicTooltipComp.instance;
              compInstance.currPoint = this.x;
              compInstance.currChartData = this;
              compInstance.chartTooltipDataTitle = chartTooltipDataTitle;
              compInstance.tooltipButtonText = tooltipButtonText;
              compInstance.dataIndex = (this as IChartTooltipPointData).points[0]['point']?.index;
              dynamicTooltipComp.changeDetectorRef.detectChanges();
              // Return the tooltip html to highcharts
              return dynamicTooltipComp.location.nativeElement.outerHTML;
            }
            let s = '<div id="infoBarTooltip" class="' + data.infoBarClass + 'layout">';

            if (this.x || this.x === 0) {
              if (data.xType !== 'timestamp') {
                s +=
                  '<div class="' +
                  'item-title">' +
                  formatsHelper.stringSub(data.categories[this.x], 512) +
                  data.tooltipValueSuffix +
                  '</div>';
              } else {
                s +=
                  '<div class="' +
                  'item-title">' +
                  moment(data.categories[this.x])
                    .utc(isLocalTime)
                    .format(data.tooltipDateFormat) +
                  '</div>';
              }
              s += '<div class="' + 'series container">';

              if (!data.sharedTooltip) {
                let result;
                if (this.y % 1 === 0) {
                  result = this.y;
                } else {
                  result = this.y.toFixed(2);
                }

                s +=
                  '<div class="' +
                  'item-' +
                  this.series.name.toLowerCase() +
                  '">' +
                  this.series.name +
                  '</div>' +
                  '<div class="' +
                  'series value">' +
                  formatsHelper.numberKM(result) +
                  data.tooltipValueSuffix +
                  '</div>';
              } else {
                const rangePoint = this.points.find(p => p.point.low || p.point.low === 0);
                const selected = this.points.find(p => p.point.series.name === 'occurrences');
                if (rangePoint && selected) {
                  s +=
                    '<div class="' +
                    'item-' +
                    selected.series.name.toLowerCase() +
                    '">' +
                    selected.series.name +
                    '</div>' +
                    '<div class="' +
                    'series value">' +
                    formatsHelper.numberKM(selected.y) +
                    data.tooltipValueSuffix +
                    '</div>';
                  s +=
                    '<div class="' +
                    'item-' +
                    'normal' +
                    '">' +
                    'normal' +
                    '</div>' +
                    '<div class="' +
                    'series value">' +
                    formatsHelper.numberKM(rangePoint.point.low) +
                    ' - ' +
                    formatsHelper.numberKM(rangePoint.point.high) +
                    data.tooltipValueSuffix +
                    '</div>';

                  if (selected.y > rangePoint.point.high) {
                    s +=
                      '<div class="' +
                      'item-' +
                      'anomaly' +
                      '">' +
                      'anomaly' +
                      '</div>' +
                      '<div class="' +
                      'series value">' +
                      formatsHelper.numberKM(selected.y - rangePoint.point.high) +
                      ' above normal range' +
                      '</div>';
                  }
                  if (selected.y < rangePoint.point.low) {
                    s +=
                      '<div class="' +
                      'item-' +
                      'anomaly' +
                      '">' +
                      'anomaly' +
                      '</div>' +
                      '<div class="' +
                      'series value">' +
                      formatsHelper.numberKM(rangePoint.point.low - selected.y) +
                      ' below normal range' +
                      '</div>';
                  }
                } else {
                  $.each(this.points, function (): any {
                    let result;
                    if (data.yType !== 'logarithmic') {
                      if (this.y % 1 === 0) {
                        result = this.y;
                      } else {
                        result = this.y.toFixed(2);
                      }
                    } else {
                      result = this.y === 0.0001 ? 0 : this.y;
                    }

                    s +=
                      '<div class="' +
                      'item-' +
                      this.series.name.toLowerCase() +
                      '">' +
                      this.series.name +
                      '</div>' +
                      '<div class="' +
                      'series value">' +
                      formatsHelper.numberKM(result) +
                      data.tooltipValueSuffix +
                      '</div>';
                  });
                }
              }

              s += '</div>';
            }
            const r = 'textalignright';
            if (this.y && this.point && this.y > 0 && this.point.percentage) {
              s += '<div class="series container ' + r + '">';
              let result;
              if (this.point.percentage % 1 === 0) {
                result = this.point.percentage;
              } else {
                result = this.point.percentage.toFixed(2);
              }
              s +=
                '<div class="' +
                'item-' +
                this.key.toLowerCase() +
                '">' +
                this.key +
                '</div>' +
                '<div class="' +
                'series value">' +
                formatsHelper.numberKM(this.y) +
                ' - ' +
                +result +
                '%' +
                '</div>';
              s += '</div>';
            }
            s += '</div>';
            infoBar.nativeElement.innerHTML = s;
            return [];
          }
          : function (): any {
            let s = '<div class="' + data.infoBarClass + 'layout">';
            s +=
              '<div class="' +
              'item-title">' +
              moment(data.categories[this.x])
                .utc(isLocalTime)
                .format(data.tooltipDateFormat) +
              '</div>';
            s += '<div class="' + 'series container">';

            $.each(this.points, function (): any {
              let result;
              if (this.y % 1 === 0) {
                result = this.y;
              } else {
                result = this.y.toFixed(2);
              }

              s +=
                '<div style="font-size: 11px; !important;margin-top: 3px;" class="' +
                'series value' +
                '">' +
                formatsHelper.numberKM(result) +
                data.tooltipValueSuffix +
                '</div>' +
                '<div style="font-size: 10px; !important;margin-top: 3px;" class="' +
                'item-' +
                this.series.name.toLowerCase() +
                '">' +
                this.series.name +
                '</div>';
            });
            s += '</div>';
            s += '</div>';
            infoBar.nativeElement.innerHTML = s;
            return [];
          },
        shared: data.sharedTooltip,
        split: true,
        borderWidth: 0,
        style: {padding: '0', margin: '0', color: data.fontColor},
        hideDelay: 0,
        useHTML: true,
        animation: false,
        shadow: false,
        backgroundColor: 'transparent',
        pointFormat: '<b>{point.percentage:.1f}%</b>',
        distance: data.tooltipRightOffsetInPx,
      },
      plotOptions: this.getPlotOptions(data),
      legend: this.chartOptionsService.getLegendOptions(data),
      credits: {
        enabled: false,
      },
    };

    this.options = chartOptions;

    if (this.chartEl && this.chartEl.nativeElement) {
      this._chart = new Highcharts.Chart(chartOptions, () => {
        this.chartLoaded.emit(this._chart);
      });
      data.series.forEach(s => {
        this._chart.addSeries(
          {
            name: s.name,
            type: s.type,
            data: s.data,
            innerSize: s.innerSize,
            color: s.color,
            marker: s.marker
              ? {
                enabled: s.marker.enabled,
                radius: s.marker.radius,
                symbol: s.marker.symbol,
              }
              : false,
          },
          false,
        );
      });
      data.xPlotlines.forEach(p => {
        this._chart.xAxis[0].addPlotLine({
          color: p.color,
          value: p.value,
          width: p.width,
          dashStyle: p.dashStyle || 'Solid',
          zIndex: p.zIndex || 100,
          label: p.label,
        });
      });
      data.yPlotlines.forEach(p => {
        this._chart.yAxis[0].addPlotLine({
          color: p.color,
          value: p.value,
          width: p.width,
          dashStyle: p.dashStyle,
          zIndex: p.zIndex || 0,
          label: p.label,
        });
      });
    }
    this._chart.redraw();
    this.registerPlotLinesEvents(data);
  }

  private getPlotOptions(data: ChartModel): any {
    const that = this;
    const plotOptions = this.chartOptionsService.getBasePlotOptions(data);

    plotOptions.series.events = {
      click: this.pointClicked.bind(this),
      mouseOut(): void {
        if (!data.activeBuildTooltip) {
          that.infoBarEl.nativeElement.innerHTML = null;
        }
      },
    };

    return plotOptions;
  }

  private getExtendedChartOptions(data: ChartModel): any {
    const baseOptions = this.chartOptionsService.getBaseChartOptions(data);

    const additionalOptions = {
      events: {
        selection: this.onChartSelection.bind(this),
      },
      renderTo: this.chartEl.nativeElement,
    };

    return {...baseOptions, ...additionalOptions};
  }

  private registerPlotLinesEvents(model: ChartModel): void {
    if (model.xPlotlines.length > 0) {
      const plots = this.chartEl.nativeElement.getElementsByClassName('plotline-label');
      for (let i = 0; i < plots.length; i++) {
        const el = plots[i].cloneNode(true);
        plots[i].parentNode.replaceChild(el, plots[i]);

        plots[i].addEventListener('click', this.onPlotClicked.bind(this, plots[i]));
        plots[i].addEventListener('mouseover', this.onPlotMouseEnter.bind(this, plots[i], this.isLocalTime));
        plots[i].addEventListener('mouseleave', this.onPlotMouseLeave.bind(this));
        plots[i].addEventListener(
          'error',
          () => {
            plots[i].src = '/assets/deployment-icons/event.png';
          },
          false,
        );
      }
    }
  }

  private onPlotMouseEnter(e: HTMLImageElement, isLocalTime: boolean): void {
    this._chartModel.activeBuildTooltip = true;
    const plot = this.deplomentService.buildsMap.get(e.getAttribute('id'));
    if (plot) {
      const build: Build = plot;
      if (build instanceof Build) {
        let s = '<div id="build" class="' + this._chartModel.infoBarClass + 'layout "  >';
        s +=
          '<div class="' +
          'item-title">' +
          moment(build.tag_timestamp)
            .utc(isLocalTime)
            .format(this._chartModel.tooltipDateFormat) +
          '</div>';
        s += '<div class="' + 'series container">';
        s +=
          '<div class="' +
          'item-' +
          'repository_name' +
          '">' +
          build.text_key +
          '</div>' +
          '<div class="' +
          'series value">' +
          build.text_value +
          '</div>';
        s += '</div>';
        this.infoBarEl.nativeElement.innerHTML = s;
      }
    }
  }

  private onPlotMouseLeave(): void {
    this._chartModel.activeBuildTooltip = false;
    this.infoBarEl.nativeElement.innerHTML = null;
  }

  private onPlotClicked(e: HTMLImageElement): void {
    window.open(e.getAttribute('link'), 'tab');
  }

  private onChartSelection(e: ChartSelectionContextObject): void {
    this._chartModel.logQueryModel = this.chartLogQueryService.getQueryModelWithUpdatedStartAndEndDate(e, this._chartModel, this._chart);
    this.ChartTimeRangeSelected.emit(this._chartModel.logQueryModel);
  }

  private pointClicked(event: PointClickEventObject): void {
    if (!this._chartModel.clickable) {
      if (this._chartModel.enableCopyClipboard) {
        this.clipboardService.copyToClipboard(this._chartModel.categories[event.point.category], true);
      }
      return;
    }

    let queryModel: LogQueryModel;
    if (this._chartModel.xType === 'timestamp') {
      if (!this._chartModel.isRealtime) {
        queryModel = this.chartLogQueryService.getQueryModelForNotRealTimeData(event, this._chartModel);
      } else {
        this.emitClickParamsForNotRealTimeData(event);
        return;
      }
    } else if (!this._chartModel.generateLogQuery) {
      this.emitClickParamsIfNoQueryModel(event);
      return;
    } else {
      queryModel = this.chartLogQueryService.getQueryModelForNotTimestampChart(event, this._chartModel);
    }

    this.chartClicked.emit(queryModel);
  }

  private emitClickParamsIfNoQueryModel(event: PointClickEventObject): void {
    const clickParams = new ChartClickParams();

    clickParams.index = event.point.category;
    clickParams.name = event.point.name;
    clickParams.chartId = this._chartModel.title;

    this.chartClicked.emit(clickParams);
  }

  private emitClickParamsForNotRealTimeData(event: PointClickEventObject): void {
    const clickParams = new ChartClickParams();

    clickParams.index = event.point.category;
    clickParams.name = this._chart.series[0].name;
    this.chartClicked.emit(clickParams);
  }

  private setCustomTooltipFormatter(context: any, data: ChartModel): any {
    return this.chartFormatterService.customTooltipFormatter(context, data, this.isLocalTime);
  }

  private setDefaultTooltipFormatter(context: any, data: ChartModel): any {
    return this.chartFormatterService.defaultTooltipFormatter(context, data, this.isLocalTime);
  }
}
