import { NgModule } from '@angular/core';
import { LogsMetadataQueryComponent } from './logs-metadata-query/logs-metadata-query.component';
import { LogsSeverityComponent } from './logs-severity/logs-severity.component';
import { LogsQueryComponent } from './logs-query/logs-query.component';
import { LogsGridComponent } from './logs-grid/logs-grid.component';
import { LogsBeforeAfterComponent } from './logs-before-after/logs-before-after.component';
import { LogsInfoPanelComponent } from './logs-info-panel/logs-info-panel.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogsComponent } from './logs.component';
import { LogsService } from './shared/services/logs-service';
import { RouterModule } from '@angular/router';
import { SavedQueryService } from './shared/services/saved-query.service';
import { HttpClientModule } from '@angular/common/http';
import { StatisticsModule } from '../statistics/statistics.module';
import { SearchProvider } from '../shared/controls/search/search.provider';
import { MetadataProvider } from './logs-metadata-query/metadata.provider';
import { LogsQueryResultComponent } from './logs-query-result/logs-query-result.component';
import { LogsViewStateProvider } from './shared/services/logsViewState.provider';
import { LogsActionButtonsComponent } from './logs-action-buttons/logs-action-buttons.component';
import { LogsLoggregationComponent } from './logs-loggregation/logs-loggregation.component';
import { LoggregationModule } from '../loggregation/loggregation.module';
import { LogsQuerFormProvider } from './logs-query/logs-query-form.provider';
import { ExportService } from './shared/services/export.service';
import { ExportComponent } from './export/export.component';
import { LogsGroupByPanelComponent } from './filters/logs-group-by-panel/logs-group-by-panel.component';
import { LogsGroupByService } from './shared/services/logs-groupby-service';
import { LogsFilterComponent } from './filters/logs-filter/logs-filter.component';
import { LogsFilterCategoryComponent } from './filters/logs-filter-category/logs-filter-category.component';
import { LogsFilterSeverityComponent } from './filters/logs-filter-severity/logs-filter-severity.component';
import { CommonModule } from '@angular/common';
import { DynamicFiltersComponent } from './filters/dynamic-filters/dynamic-filters.component';
import { QuickQueryContainerComponent } from './quick-query/quick-query-container/quick-query-container.component';
import { QuickQueryPredefinedComponent } from './quick-query/quick-query-predefined/quick-query-predefined.component';
import { QuickQueryLogsComponent } from './quick-query/quick-query-logs/quick-query-logs.component';
import { CustomFiltersProvider } from './filters/logs-filter-category/CustomFilters.provider';
import { LogsMenuComponent } from './logs-menu/logs-menu.component';
import { LogsOptionsMenuComponent } from './logs-options-menu/logs-options-menu.component';
import { QueryPanelComponent } from './query-panel/query-panel.component';
import { RelativeQueryComponent } from './relative-query/relative-query.component';
import { AbsoluteQueryComponent } from './absolute-query/absolute-query.component';
import { LogsQueryTutorialComponent } from './logs-query-tutorial/logs-query-tutorial.component';
import { LogsFilterGridColumnsComponent } from './filters/logs-filter-grid-columns/logs-filter-grid-columns.component';
import { LogsSaveViewComponent } from './logs-views/logs-save-view/logs-save-view.component';
import { LogsAvailableColumnsFilterPipe } from './shared/pipes/logs-available-columns-filter.pipe';
import { LogsOpenViewComponent } from './logs-views/logs-open-view/logs-open-view.component';
import { SavedViewsService } from './shared/services/saved-views.service';
import { LogsFilterGridColumnsService } from './shared/services/logs-filter-grid-columns.service';
import { LogsSavedViewsFilterPipe } from './shared/pipes/logs-saved-views-filter.pipe';
import { TagQueryComponent } from './tag-query/tag-query.component';
import { TagQueryItemComponent } from './tag-query/tag-query-item/tag-query-item.component';
import { RecentQueriesService } from './shared/services/recent-queries.service';
import { LogsViewMapperService } from './shared/services/logs-view-mapper.service';
import { ResizeHandlerComponent } from './resize-handler/resize-handler.component';
import { LogsSidebarComponent } from '@app/logs/side-bar/logs-sidebar.component';
import { LogsContainerComponent } from '@app/logs/containers/logs-container.component';
import { AlertsEditorModule } from '@app/alerts/alerts-editor/alerts-editor.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { FilterPipeModule } from '@app/shared/pipes/filter-pipe/filter-pipe.module';
import { DateFormatByUserSettingsModule } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.module';
import { HeightCalculatorModule } from '@app/shared/directives/height-calculator/height-calculator.module';

@NgModule({
  declarations: [
    LogsComponent,
    LogsSeverityComponent,
    LogsMetadataQueryComponent,
    LogsQueryComponent,
    LogsGridComponent,
    LogsBeforeAfterComponent,
    LogsInfoPanelComponent,
    LogsQueryResultComponent,
    LogsActionButtonsComponent,
    LogsLoggregationComponent,
    LogsGroupByPanelComponent,
    LogsFilterComponent,
    LogsFilterCategoryComponent,
    LogsFilterSeverityComponent,
    LogsFilterGridColumnsComponent,
    DynamicFiltersComponent,
    QuickQueryContainerComponent,
    QuickQueryPredefinedComponent,
    QuickQueryLogsComponent,
    LogsMenuComponent,
    LogsOptionsMenuComponent,
    QueryPanelComponent,
    RelativeQueryComponent,
    AbsoluteQueryComponent,
    LogsQueryTutorialComponent,
    LogsSaveViewComponent,
    LogsAvailableColumnsFilterPipe,
    LogsOpenViewComponent,
    LogsSavedViewsFilterPipe,
    TagQueryComponent,
    ExportComponent,
    TagQueryItemComponent,
    ResizeHandlerComponent,
    LogsSidebarComponent,
    LogsContainerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    StatisticsModule,
    LoggregationModule,
    AlertsEditorModule,
    OverlayModule,
    FilterPipeModule,
    DateFormatByUserSettingsModule,
    HeightCalculatorModule,
  ],
  providers: [
    LogsService,
    SavedQueryService,
    LogsQuerFormProvider,
    SearchProvider,
    MetadataProvider,
    LogsViewStateProvider,
    ExportService,
    LogsGroupByService,
    CustomFiltersProvider,
    SavedViewsService,
    LogsFilterGridColumnsService,
    RecentQueriesService,
    LogsViewMapperService,
  ],
  exports: [
    LogsMetadataQueryComponent,
    LogsInfoPanelComponent,
    LogsSeverityComponent,
    LogsQueryComponent,
    LogsBeforeAfterComponent,
    LogsGridComponent,
  ],
  entryComponents: [ExportComponent],
})
export class LogsModule {}
