<div *ngIf="!loading && detectedGroks" style="width: 400px">
  <h2 mat-dialog-title>Groks Detected</h2>
  <mat-dialog-content>
    <div *ngFor="let grok of detectedGroks" style="margin-left: 10px">
      <mat-checkbox (change)="toggle(grok, $event)" [checked]="isChecked(grok)">
        {{ grok.Description }}
      </mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="dialogRef.close(selectedGroks)" color="warn">
      Apply
    </button>
  </mat-dialog-actions>
</div>
<div *ngIf="!loading && !detectedGroks" style="width: 400px">
  <h2 mat-dialog-title>No groks detected</h2>
  <button mat-button mat-dialog-close color="warn">Close</button>
</div>
<div *ngIf="loading">
  <sh-loading-groks></sh-loading-groks>
</div>
