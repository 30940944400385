<div class="ui modal sh-modal" #chartModal>
  <div class="header title">
    <i class="icon remove close-button" (click)="hideModal()"></i>
    {{ headerName }}
    <div *ngIf="(isReadOnly$ | async) === false" class="floating-btn-tl">
      <i class="pin icon" (click)="addWidget(chartQueryDef.logQeuryModel)"></i>
    </div>
  </div>
  <div class="chart-container">
    <sh-basic-statistic-chart
      [enableCopyClipboard]="true"
      [queryDefinition]="chartQueryDef"
      [chartHeight]="chartHeight"
      [enableLoading]="true"
      [addInvertedClassToLoader]="true"
    ></sh-basic-statistic-chart>
  </div>
</div>
