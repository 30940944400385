import { Component, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Reindex } from '@app/roi/reindex/models/reindex.model';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import { ReindexActions } from '@app/roi/reindex/state/actions/reindex.actions';

@Component({
  selector: 'sh-reindex-table-container',
  templateUrl: './reindex-table-container.component.html',
  styleUrls: ['./reindex-table-container.component.scss']
})
export class ReindexTableContainerComponent implements OnDestroy, OnChanges {

  @Input()
  public reindexes: Reindex[];
  private statusInterval: any;

  constructor(private store: Store<State>) { }

  public ngOnChanges(changes: SimpleChanges): void {
    clearInterval(this.statusInterval);
    const ingestingReindexes = this.reindexes.filter(reindex => reindex.status === 'Ingesting').map(ingest => ingest.id);

    if (ingestingReindexes.length > 0) {
      this.statusInterval = setInterval(() => {
        this.store.dispatch(new ReindexActions.GetReindexesStatusAction(ingestingReindexes));
      }, 4000);
    }
  }

  public ngOnDestroy(): void {
    clearInterval(this.statusInterval);
  }

  public retryReindex(reindex: Reindex): void {
    this.store.dispatch(new ReindexActions.RetryReindex(reindex));
  }

  public ViewReindexLogs(reindex: Reindex): void {
    this.store.dispatch(new ReindexActions.CreateCachedQuery(reindex.id));
  }
}
