<div class="available-columns-wrapper">
  <div class="available-columns-content">
    <mat-list dense>
      <h3 mat-subheader>Selected Columns</h3>
      <div class="default-value" *ngIf="selectedColumns?.length === 0">
        <p>No columns selected</p>
      </div>
      <mat-list-item *ngFor="let column of selectedColumns">
        <div class="column-container">
          <p
            *ngIf="
              column.headerName === column.colId &&
              column.headerName.length > 20
            "
            [matTooltip]="column.colId"
            [matTooltipPosition]="'after'"
            [matTooltipShowDelay]="500"
          >
            {{ column.headerName }}
          </p>
          <p
            *ngIf="
              column.headerName === column.colId &&
              column.headerName.length <= 20
            "
          >
            {{ column.headerName }}
          </p>
          <p
            *ngIf="column.headerName !== column.colId"
            [matTooltip]="column.colId"
            [matTooltipPosition]="'after'"
            [matTooltipShowDelay]="500"
          >
            {{ column.headerName }}
          </p>
          <div class="overlay-button">
            <button
              lass="text"
              mat-raised-button
              color="warn"
              (click)="removeColumn(column.colId)"
            >
              remove
            </button>
          </div>
        </div>
      </mat-list-item>
      <h3 mat-subheader>Available Columns</h3>
      <div class="search-container">
        <div class="search-container-wrap">
          <input
            type="text"
            placeholder="Find a column..."
            [(ngModel)]="filterText"
          />
        </div>
      </div>
      <mat-list-item
        *ngFor="
          let column of availableColumns
            | logsAvailableColumnsFilter: filterText;
          trackBy: trackByFn
        "
      >
        <div class="column-container">
          <p
            *ngIf="
              column.headerName === column.colId &&
              column.headerName.length > 20
            "
            [matTooltip]="column.colId"
            [matTooltipPosition]="'after'"
            [matTooltipShowDelay]="500"
          >
            {{ column.headerName }}
          </p>
          <p
            *ngIf="
              column.headerName === column.colId &&
              column.headerName.length <= 20
            "
          >
            {{ column.headerName }}
          </p>
          <p
            *ngIf="column.headerName !== column.colId"
            [matTooltip]="column.colId"
            [matTooltipPosition]="'after'"
            [matTooltipShowDelay]="500"
          >
            {{ column.headerName }}
          </p>
          <div class="overlay-button">
            <button
              mat-raised-button
              color="primary"
              (click)="addColumn(column.colId)"
            >
              add
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div
    class="ui blurring active dimmer inverted"
    [class.active]="isLoading"
    style="background: rgba(238, 238, 238, 0.44);"
  >
    <div class="ui text loader" style="color: #504f4f;">Loading</div>
  </div>
</div>
