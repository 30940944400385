import { Constants } from '@app/constants';
import { _15MinutesInMS } from '@app/shared/constants/times.constant';
import { GraphAggBy, LogsFieldType, IGraphAggBy, LogsGraphType } from './logs-graph.model';

export interface IHistogramGraphOption {
  type: LogsGraphType.Histogram;
}

export interface ILogsGraphHistogram {
  startDate: number;
  endDate: number;
  type: typeof LogsGraphType.Histogram;
  aggField: string;
  fieldType?: LogsFieldType;
  aggBy?: IGraphAggBy;
}

export class LogsGraphHistogram implements ILogsGraphHistogram {
  type: LogsGraphType.Histogram = LogsGraphType.Histogram;
  startDate: number = new Date().getTime() - _15MinutesInMS;
  endDate: number = new Date().getTime();
  aggField: string = Constants.SEVERITY_FIELD;
  aggBy = new GraphAggBy();
  fieldType = LogsFieldType.Log;

  constructor(logsGraph?: Partial<ILogsGraphHistogram>) {
    if (logsGraph) {
      Object.keys(logsGraph).forEach(key => {
        this[key] = logsGraph[key];
      });
    }
  }
}

export const histogramOption: IHistogramGraphOption = {
  type: LogsGraphType.Histogram,
};
