export class SeverityTranslator {
  public static translateSeverity(severity: string): number {
    switch (severity.toLowerCase()) {
      case 'critical':
        return 6;
      case 'error':
        return 5;
      case 'warning':
        return 4;
      case 'info':
        return 3;
      case 'verbose':
        return 2;
      case 'debug':
        return 1;
    }
    return null;
  }

  public static translateSeverityLevel(severityLevel: string): string {
    switch (severityLevel) {
      case '6':
        return 'critical';
      case '5':
        return 'error';
      case '4':
        return 'warning';
      case '3':
        return 'info';
      case '2':
        return 'verbose';
      case '1':
        return 'debug';
    }
    return null;
  }

  public static logsSeverityOptions: any[] = [
    { id: 1, name: 'Debug' },
    { id: 2, name: 'Verbose' },
    { id: 3, name: 'Info' },
    { id: 4, name: 'Warning' },
    { id: 5, name: 'Error' },
    { id: 6, name: 'Critical' },
  ];
}
