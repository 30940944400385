import { EventEmitter, Injectable } from '@angular/core';
import { UserSettings } from './user.settings';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsProvider {
  public userSettingsChanged: EventEmitter<UserSettings> = new EventEmitter<
    UserSettings
  >();
  public clearUserSettings: EventEmitter<any> = new EventEmitter();
  public userSettings: UserSettings = new UserSettings();
}
