import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { IRadioButtonGroupItem } from '@app/alerts/alerts-editor/components/radio-btn-group/radio-btn-group.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sh-radio-btn-group',
  templateUrl: './radio-btn-group.component.html',
  styleUrls: ['./radio-btn-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioBtnGroupComponent), multi: true }],
})
export class RadioBtnGroupComponent implements ControlValueAccessor {
  @Input() options: IRadioButtonGroupItem[];
  @Input() title = '';
  private _value = '';

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    this._value = value;
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  onItemClick(item: IRadioButtonGroupItem): void {
    this.value = item.value;
    this._onChange(this.value);
  }

  private _onChange: (value: boolean) => void = () => {
    //
  };

  private _onTouch: () => void = () => {
    //
  };
}
