<div
  style="text-align: center; "
  *ngIf="suspectedTemplates && suspectedTemplates.length == 0 && !isLoading"
>
  <span style="position: relative;color: #666666">No Rows To Show</span>
</div>
<div *ngFor="let suspectedTemplate of suspectedTemplates; let last = last">
  <a
    [href]="suspectedTemplate.link"
    target="tab"
    class="loggregation-item-container {{ theme }}"
    [class.loggregation-last-item]="last"
  >
    <div class="loggregation-item-info">
      <div class="ui equal width grid column">
        <div class="row large">
          <div class="column loggregation-item-column">
            <span
              [ngClass]="severityColor(suspectedTemplate.loggregationEntity.metadata.severity.toLowerCase())"
            >
              {{ suspectedTemplate.loggregationEntity.metadata.severity }}
            </span>
          </div>
        </div>
        <div class="row large">
          <div class="column loggregation-item-column">
            <div class="loggregation-count-item {{ theme }}">
              {{ suspectedTemplate.loggregationEntity.count | numbersFormat }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="loggregation-ration-item anomolus"
        *ngIf="suspectedTemplate.loggregationEntity.countToNormalRation"
      >
        {{
        suspectedTemplate.loggregationEntity.countToNormalRation
          | normalRatio: 100
        }}% above normal
      </div>
    </div>
    <div class="loggregation-item-chart">
      <sh-basic-chart [model]="suspectedTemplate.chartModel"></sh-basic-chart>
    </div>
    <div class="loggregation-item-content">
      <div
        class="loggregation-item-text"
        innerHTML="{{
          suspectedTemplate.loggregationEntity | loggregationPipe
        }}"
      ></div>
      <div class="loggregation-metadata-row">
        <span class="loggregation-item-metadata {{ theme }}">
          {{ suspectedTemplate.loggregationEntity.metadata.applicationName }}
        </span>
        <span class="loggregation-item-metadata {{ theme }}">
          {{ suspectedTemplate.loggregationEntity.metadata.subsystemName }}
        </span>
      </div>
    </div>
  </a>
</div>
<sh-widget-loader *ngIf="enableLoading" [hidden]="!isLoading"
                  [addInvertedClass]="addInvertedClassToLoader"
></sh-widget-loader>
