import { LogsQueryActions } from './logs-query.actions';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';

export namespace LogsQueryReducer {
  export interface State {
    log: LoggregationEntity;
  }

  const initalState: State = {
    log: null,
  };

  export function reducer(
    state: State = initalState,
    action: LogsQueryActions.Actions,
  ): State {
    switch (action.type) {
      case LogsQueryActions.ActionTypes.QUERY_BY_TEMPLATE:
        return Object.assign({}, state, { log: action.payload });
      default:
        return state;
    }
  }
}
