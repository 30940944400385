import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output, ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import * as _ from 'lodash';

import { Log } from '../shared/models/log.entity';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';
import { SearchProvider } from '@shared/controls/search/search.provider';
import { State } from '@app/app.reducers';
import { JsonParameterRequestData } from '@shared/models/jsonParameterRequestData';
import { LogsFilterGridColumnsActions } from '../shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { LogsQuerFormProvider } from '../logs-query/logs-query-form.provider';
import { JsonKeyAction } from '@shared/models/filter-by-json-value-action.model';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { LogActions } from '@app/logs/shared/state/log.actions';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { ILogButtonAction } from '@app/logs/shared/models/log-info-panel-view.models';
import { AlertsEditorContainerComponent } from '@app/alerts/alerts-editor/containers/alerts-editor-container.component';
import { SaveAlertFromQueryConfig } from '@app/logs/logs-save-menu/models/logs-save-menu-config.model';
import { AlertEditFromQueryModel } from '@app/alerts/alerts-editor/models/alert-edit-from-query.model';
import { AlertType } from '@app/insights/shared/models/enums';
import { AlertsEditorMode } from '@app/alerts/alerts-editor/models/alerts-editor-mode.enum';
import { StatisticsChartModalComponent } from '@app/statistics/components/statistics-chart-modal/statistics-chart-modal.component';
import { AmPmMomentOptions } from '@shared/helpers/moment.helper';

@Component({
  moduleId: 'logs-info-panel',
  selector: 'sh-logs-info-panel',
  templateUrl: 'logs-info-panel.component.html',
  styleUrls: ['logs-info-panel.component.scss'],
})
export class LogsInfoPanelComponent implements OnDestroy {
  @Input() set selectedLog(value: Log | LoggregationEntity) {
    const isTemplate = value instanceof LoggregationEntity;
    this.isTemplate = isTemplate;
    setTimeout(() => {
      this.hideJsonParams = isTemplate;
      this._selectedLog = value;
      this._highLighedText = this._selectedLog ? this._highLighedText : null;
      this.logText = this._selectedLog
        ? this.highLighText(this._highLighedText, this._selectedLog.text)
        : (this.logText = null);
      try {
        const parsedObject = this.getEntityText(value);
        if (typeof parsedObject !== 'string') {
          this.logJson = parsedObject;
          this.isJson = true;
        } else {
          this.isJson = false;
        }
      } catch (e) {
        this.isJson = false;
      }
    }, 0);
  }
  public ampmBigLettersOption: AmPmMomentOptions = AmPmMomentOptions.bigLetters;
  get selectedLog(): Log | LoggregationEntity {
    return this._selectedLog;
  }

  @Input() set searchProvider(provider: SearchProvider) {
    this.OnFindSubscription = provider.onFind.subscribe((e) => {
      this.OnFindKeyUp(e);
    });
  }

  @Input() set isOpen(isOpen: boolean) {
    this._isOpen = isOpen;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }
  public showRawData: boolean = false;
  public logText: string;
  public hideJsonParams: boolean = false;
  public isTemplate: boolean = false;
  @Input() public highlightEnabled: boolean;
  @Output() public filterChanged: EventEmitter<LogQueryModel> = new EventEmitter<LogQueryModel>();
  @Output() public logActionPerformedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public showJsonWidgetEvent: EventEmitter<JsonParameterRequestData> =
    new EventEmitter<JsonParameterRequestData>();
  @Input() public isShowJsonWidgetEnabled: boolean = true;
  @Input() public isFiltersEnabled: boolean = false;
  @Input() public isLogNavigationRequired: boolean = true;
  @Input() public isShowGraphEnabled: boolean = true;
  @Output() public saveAlert: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(StatisticsChartModalComponent, { static: true })
  public statisticsChartModal: StatisticsChartModalComponent;

  public _isOpen: boolean;

  public isFull: boolean;
  public metadataTitles: any = {
    applicationName: 'Application',
    subsystemName: 'Subsystem',
    computerName: 'Computer',
    IPAddress: 'IP Address',
    category: 'Category',
    className: 'Class',
    methodName: 'Method',
    processName: 'Process',
    sdkId: 'SDK ID',
    threadId: 'Thread ID',
  };
  public isJson: boolean;
  public logJson: object;

  @ViewChild('alertEditor', { static: true })
  public alertEditor: AlertsEditorContainerComponent;

  private _selectedLog: Log | LoggregationEntity;
  private OnFindSubscription: any;
  private _highLighedText: string;

  constructor(
    private store: Store<State>,
    private logsFormProvider: LogsQuerFormProvider,
    private logsService: LogsService,
  ) {}

  public getEntityText(entity: Log | LoggregationEntity): any {
    return entity instanceof Log
      ? this.getLogText(entity as Log)
      : this.getTemplateText(entity as LoggregationEntity);
  }

  public getTemplateText(template: LoggregationEntity): any {
    if (
      template.logExample &&
      template.logExample.coralogix &&
      !template.logExample.coralogix.failed_reason
    ) {
      this.hideJsonParams = false;
      const templateExample = _.cloneDeep(template.logExample);
      delete templateExample.coralogix;
      return templateExample;
    }
    return JSON.parse(template.text);
  }

  public getLogText(log: Log): any {
    return JSON.parse(log.text);
  }

  public toggleShowRawData(event: any): void {
    this.showRawData = event.value;
  }

  public showJsonWidget(jsonParamReqData: JsonParameterRequestData): void {
   if (this.isLogNavigationRequired) {
      /* TODO - not supported from the server - to uncomment after the
        type = 'newLogsQuery' is supported in "logs/statistics" api call **/
      // this.store.select(getLogPanelQuery).pipe(filter(data => !!data)).first().subscribe((data) => {
      //     this.statisticsChartModal.openChart(data, jsonParamReqData.key, jsonParamReqData.title);
      // });
    } else {
      this.showJsonWidgetEvent.emit(jsonParamReqData);
    }
  }

  public jsonKeyClicked(action: JsonKeyAction): void {
    const currentQuery = this.logsFormProvider.getModel();
    const value = action.value
      .toString()
      .toLowerCase()
      .trim();
    if (value) {
      if (
        Object.keys(currentQuery.queryParams.jsonObject).indexOf(action.key) >=
        0
      ) {
        if (
          currentQuery.queryParams.jsonObject[action.key].indexOf(value) === -1
        ) {
          currentQuery.queryParams.jsonObject[action.key].push(value);
          this.logsFormProvider.updateFormByModel(currentQuery);
          this.filterChanged.emit(currentQuery);
        }
      } else {
        this.store.dispatch(
          new LogsFilterGridColumnsActions.SelectLogsFilterGridColumns([
            action.key,
          ]),
        );
        currentQuery.queryParams.jsonObject[action.key] = [value];
        this.logsFormProvider.updateFormByModel(currentQuery);
        this.filterChanged.emit(currentQuery);
      }
    }
  }

  public toggleOpen(): void {
    this.isOpen = !this.isOpen;
    this.isFull = this.isFull && this.isOpen;
  }

  public toggleFullScreen(): void {
    this.isFull = !this.isFull;
  }

  public logActionPerformed(queryAction: ILogButtonAction): void {
    if (this.isLogNavigationRequired) {
      const endDate = new Date();
      const startDate = new Date(endDate.getTime() - 60000 * 15); // 15 minutes before

      const queryModel = new LogQueryModel(startDate, endDate);
      queryModel.type = queryAction.type;
      queryModel.queryParams.query.text = queryAction.text;
      queryModel.cacheQueryId = this.logsService.getNewQueryId();
      this.store.dispatch(new LogActions.CreateCachedQuery(queryModel));
    } else {
       this.logActionPerformedEvent.emit();
    }
  }

  public OnFindKeyUp(e: string): void {
    this._highLighedText = e;
    this.logText = this._selectedLog
      ? this.highLighText(this._highLighedText, this._selectedLog.text)
      : (this.logText = null);
  }

  public ngOnDestroy(): void {
    if (this.OnFindSubscription) {
      this.OnFindSubscription.unsubscribe();
    }
  }

  public highLighText(text: string, input: string): string {
    if (input && this.highlightEnabled) {
      const escapedHtml = input
        .replace(/&/g, '&amp;')
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;')
         .replace(/"/g, '&quot;')
        .replace(/\\t/g, '\t')
        .replace(/\\\//g, '/');

      if (text) {
        const toSearch = new RegExp('(' + text + ')', 'gmi');
        // Create mark for each matched text and highlight it.
        return escapedHtml.replace(toSearch, '<mark>$1</mark>');
      }
      return escapedHtml;
    }
    return input;
  }
  @HostListener('window:keyup.space', ['$event'])
  public handleKeyboardEvent(event: any): void {
    event.stopImmediatePropagation();
    const element = event.target as HTMLElement;
    if (element && element.localName !== 'input') {
      this.isOpen = !this.isOpen;
    }
  }

  public showMetaDataWidget(key: string): void {
    const reqData: JsonParameterRequestData = {
      templateId: null,
      key: `coralogix.metadata.${key}`,
      title: this.metadataTitles[key]
    };
    this.showJsonWidget(reqData);
  }

  public onSaveAlert(config: SaveAlertFromQueryConfig): void {
    const metadataClone = { ...config.metadata };
    delete metadataClone.applicationName;
    delete metadataClone.subsystemName;
    delete metadataClone.severity;

    for (const key in metadataClone) {
      if (metadataClone.hasOwnProperty(key)) {
        Object.defineProperty(metadataClone, `coralogix.metadata.${key}`,
          Object.getOwnPropertyDescriptor(metadataClone, key));
        delete metadataClone[key];
      }
    }

    const params = {
      ...config.jsonObject,
      ...metadataClone,
    };
    const alert = new AlertEditFromQueryModel({
      text: config.text,
      queryParamsJson: params,
      hasConditions: true,
      userAlertTypeId: AlertType.ByText,
      applicationName:
        config.metadata?.applicationName?.length
          ? config.metadata.applicationName
          : null,
      subsystemName:
        config.metadata?.subsystemName?.length
          ? config.metadata.subsystemName
          : null,
      logSeverity:
        config.metadata?.severity?.length
          ? config.metadata.severity
          : null,
    });

    this.alertEditor.openAlertsEditor(AlertsEditorMode.fromQuery, alert);
  }
}
