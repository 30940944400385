import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TypedJSON } from 'typedjson';
import { Team } from '../models/team';
import { ConfigurationService } from '@app/services/configuration.service';

@Injectable()
export class TeamService {
  constructor(private http: HttpClient, private configService: ConfigurationService) {}

  public getTeamNameFromUrl(): string {
    let teamName = '';
    if (window.location.href) {
      const url = window.location.href;
      const origin = url.replace('__test', '');
      if (origin) {
        const [protocol, host] = origin.split('://');
        const urlTree = host.split('.');
        if (urlTree && urlTree.length > 1) {
          teamName = urlTree[0];
        }
      }
    }
    if (
      teamName.toLocaleLowerCase() !== 'dashboard' &&
      teamName.toLocaleLowerCase() !== 'testdashboard'
    ) {
      return teamName;
    } else {
      return '';
    }
  }
  public getTeams(): Observable<Team[]> {
    return this.http
      .get(this.teamUrl)
      .map((res: any) => {
        return res;
      })
      .map((teamRes) => teamRes.data);
  }

  public createTeam(teamName: string, isNew: boolean = false): Observable<any> {
    const url = isNew ? this.teamUrl + '?new=true' : this.teamUrl;
    return this.http
      .post(url, teamName)
      .map((res: any) => new TypedJSON(Team).parse(res));
  }

  public changeURL(team: Team, newURL: string): Observable<any> {
    const url = this.teamUrl + '/teamURL/' + team.id;
    return this.http
      .put(url, JSON.stringify({ newURL }))
      .map((res: any) => new TypedJSON(Team).parse(res));
  }

  public deleteInvite(team_id: string): Observable<string> {
    return this.http.delete(this.teamUrl + '/' + team_id).mapTo(team_id);
  }

  private get teamUrl(): string {
    return this.configService.getConfigValue('url', '') + '/api/v1/user/team';
  }
}
