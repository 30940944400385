import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetSnoozedAlerts, GetSnoozeStatus, SnoozeAlert, UnsnoozeAlert } from './alerts-snooze.action';
import { ShDialogService } from '@app/shared/services/dialogService';
import { SnoozeInfo } from '@app/alerts/shared/models/snooze.model';
import { AlertsService } from '@app/alerts/shared/services/alerts-service';
import _ from 'lodash';

export interface IAlertsSnoozeStateModel {
  snoozeData: {[id: string]: SnoozeInfo};
  loading: {[id: string]: boolean};
}

const initialState = (): IAlertsSnoozeStateModel => {
  return {
    snoozeData: {},
    loading: {}
  };
};

@State<IAlertsSnoozeStateModel>({
  name: 'alertsSnooze',
  defaults: initialState()
})
@Injectable()
export class AlertsState {
  constructor(
      private alertsService: AlertsService,
      private dialogService: ShDialogService
  ) {
  }

  @Selector()
  public static snoozeData(state: IAlertsSnoozeStateModel): {[id: string]: SnoozeInfo} {
      return state?.snoozeData;
  }

  @Selector()
  public static loading(state: IAlertsSnoozeStateModel): {[id: string]: boolean} {
      return state?.loading;
  }

  @Action(GetSnoozeStatus)
  public GetSnoozeStatus(ctx: StateContext<IAlertsSnoozeStateModel>, { alertId }: GetSnoozeStatus): any {
    return this.alertsService.getSnoozeStatus(alertId).pipe(
      map((snoozeInfo) => {
        const newSnoozeData = { ...ctx.getState().snoozeData };
        newSnoozeData[alertId] = snoozeInfo;
        return ctx.patchState({ snoozeData: newSnoozeData });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(
          `Error: error fetching the snooze status`,
          null,
          5000,
        );
        return of(null);
      })
    );
  }

  @Action(GetSnoozedAlerts)
  public GetSnoozedAlerts(ctx: StateContext<IAlertsSnoozeStateModel>, { alertIds }: GetSnoozedAlerts): any {
    return this.alertsService.getSnoozedAlerts(alertIds).pipe(
      map((snoozeInfo) => {
        const newSnoozeData = { ...ctx.getState().snoozeData };
        snoozeInfo.forEach((info, index) => {
          if (!_.isNil(info)) {
            newSnoozeData[alertIds[index]] = info;
          } else {
            delete newSnoozeData[alertIds[index]];
          }
        });
        return ctx.patchState({ snoozeData: newSnoozeData });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(
          `Error: error fetching the snoozed alerts`,
          null,
          5000,
        );
        return of(null);
      })
    );
  }

  @Action(SnoozeAlert)
  public SnoozeAlert(ctx: StateContext<IAlertsSnoozeStateModel>, { alertId, seconds }: SnoozeAlert): any {
    let newLoading = { ...ctx.getState().loading };
    newLoading[alertId] = true;
    ctx.patchState({ loading: newLoading });
    return this.alertsService.snoozeAlert(alertId, seconds).pipe(
      map((snoozeInfo) => {
        const newSnoozeData = { ...ctx.getState().snoozeData };
        newSnoozeData[alertId] = snoozeInfo;
        return ctx.patchState({ snoozeData: newSnoozeData });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(
          `Error: error snoozing alert`,
          null,
          5000,
        );
        return of(null);
      }),
      finalize(() => {
        newLoading = { ...ctx.getState().loading };
        newLoading[alertId] = false;
        ctx.patchState({ loading: newLoading });
      })
    );
  }

  @Action(UnsnoozeAlert)
  public UnsnoozeAlert(ctx: StateContext<IAlertsSnoozeStateModel>, { alertId }: UnsnoozeAlert): any {
    let newLoading = { ...ctx.getState().loading };
    newLoading[alertId] = true;
    ctx.patchState({ loading: newLoading });
    return this.alertsService.unSnoozeAlert(alertId).pipe(
      map(() => {
        const newSnoozeData = { ...ctx.getState().snoozeData };
        delete newSnoozeData[alertId];
        return ctx.patchState({ snoozeData: newSnoozeData });
      }),
      catchError(err => {
        this.dialogService.showSnackBar(
          `Error: error unsnoozing alert`,
          null,
          5000,
        );
        return of(null);
      }),
      finalize(() => {
        newLoading = { ...ctx.getState().loading, [alertId]: false };
        ctx.patchState({ loading: newLoading });
      })
    );
  }
}
