import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { getTagsLoading, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { ListConfig } from '@app/deployment/models/welcome.models';
import { IListBody, IListHeader } from '@shared/components/empty-state-container/empty-state-container.models';
import { tagsAssetsUrl } from '@app/deployment/models/welcome.consts';
import { Router } from '@angular/router';
import { newTagFromWelcome } from '@app/deployment/shared/consts/tags.consts';
import { Build } from '@app/deployment/models/build';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { DeploymentStoreService } from '@app/deployment/shared/services/deployment.store.service';

@Component({
  selector: 'sh-tags-welcome',
  templateUrl: './tags-welcome.component.html',
  styleUrls: ['./tags-welcome.component.scss'],
})
export class TagsWelcomeComponent {
  public isLoading$: Observable<boolean>;
  public addTagBtnIconUrl: string = `${tagsAssetsUrl}/icon-tag.svg`;
  public listConfig: (IListHeader | IListBody)[] = ListConfig;
  public tagsList$: Observable<Build[]>;
  constructor(private store: Store<State>,
              private router: Router,
              private userSettingsProvider: UserSettingsProvider,
              private tagsStoreService: DeploymentStoreService) {
    this.isLoading$ = this.store.select(getTagsLoading);
    const selectedApplications = this.userSettingsProvider.userSettings
      .queryMetadata.applicationName;
    const selectedSubsystems = this.userSettingsProvider.userSettings
      .queryMetadata.subsystemName;

    this.tagsList$ = this.tagsStoreService.filterTagsByApplicationAndSubsystem(
      selectedApplications,
      selectedSubsystems,
    );
  }
  public openNewTagPanel(): void {
    this.router.navigateByUrl(newTagFromWelcome).finally();
  }
}
