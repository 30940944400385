import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { version } from 'package.json';
import { environment } from '@environments/environment';
import { Constants } from '@app/constants';

Sentry.init({
  dsn: Constants.SENTRY_DSN,
  environment: environment.RUN_MODE,
  release: 'web-app@' + version,
  allowUrls: [/.*\.coralogix\..*/],
  attachStacktrace: true,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', /.*webapi\..*coralogix\..*/],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 0.2,
});
