import { createAction, props } from '@ngrx/store';
import {
  IParsingTheme, IToggleParsingThemeAction,
  ParsingThemesResponse,
} from '@app/rules/shared/models/rule.model';
import { RulesMainViewMode } from '@app/rules/shared/models/shared-vew.models';
import { IParsingThemeFilter } from '@app/rules/shared/models/parsing-themes-filters';
import { parsingThemeId } from '@app/rules/shared/models/parsing-theme-requests.models';

// get all pt
export const getParsingThemesAction = createAction('[Parsing Themes] Get - Parsing Themes');

export const getParsingThemesCompletedAction = createAction(
  '[Parsing Themes] Get - Parsing Themes completed',
  props<{ payload: ParsingThemesResponse }>(),
);

export const getParsingThemesFailedAction = createAction(
  '[Parsing Themes] Get - Parsing Themes failed',
  props<{ payload: any }>(),
);

// update pt
export const updateParsingThemeAction = createAction(
  '[Parsing Themes] update - Parsing Theme',
  props<{ payload: IParsingTheme }>(),
);

export const updateParsingThemeCompletedAction = createAction(
  '[Parsing Themes] update - Parsing Theme completed',
  props<{ payload: IParsingTheme }>(),
);

export const updateParsingThemeFailedAction = createAction(
  '[Parsing Themes] update - Parsing Theme failed',
  props<{ payload: any }>(),
);

// toggle pt

export const toggleParsingThemeAction = createAction(
  '[Parsing Themes] toggle - Parsing Theme',
  props<{ payload: IToggleParsingThemeAction }>(),
);

export const toggleParsingThemeCompletedAction = createAction(
  '[Parsing Themes] toggle - Parsing Theme completed',
  props<{ payload: IToggleParsingThemeAction }>(),
);

export const toggleParsingThemeFailedAction = createAction(
  '[Parsing Themes] toggle - Parsing Theme failed',
  props<{ payload: IToggleParsingThemeAction }>(),
);

// reorder pt
export const updateParsingThemesOrderAction = createAction(
  '[Parsing Themes] update order - Parsing Themes',
  props<{ payload: IParsingTheme[] }>(),
);

export const updateParsingThemesOrderCompletedAction = createAction(
  '[Parsing Themes] update order - Parsing Themes completed',
  props<{ payload: IParsingTheme[] }>(),
);

export const updateParsingThemesOrderFailedAction = createAction(
  '[Parsing Themes] update order - Parsing Themes failed',
  props<{ payload: IParsingTheme[] }>(),
);

// add pt

export const createParsingThemeAction = createAction(
  '[Parsing Themes] create - Parsing Theme',
  props<{ payload: IParsingTheme }>(),
);

export const createParsingThemeCompletedAction = createAction(
  '[Parsing Themes] create - Parsing Theme completed',
  props<{ payload: IParsingTheme }>(),
);

export const createParsingThemeFailedAction = createAction(
  '[Parsing Themes] create - Parsing Theme failed',
  props<{ payload: any }>(),
);

// delete pt

export const deleteParsingThemeAction = createAction(
  '[Parsing Themes] delete - Parsing Theme',
  props<{ payload: parsingThemeId }>(),
);

export const deleteParsingThemeCompletedAction = createAction(
  '[Parsing Themes] delete - Parsing Theme completed',
  props<{ payload: parsingThemeId }>(),
);

export const deleteParsingThemeFailedAction = createAction(
  '[Parsing Themes] delete - Parsing Theme failed'
);

// update pt list view

export const setParsingThemeMainViewAction = createAction(
  '[Parsing Themes] set main layout view - Parsing Themes',
  props<{ payload: RulesMainViewMode }>(),
);

// update pt filters

export const setParsingThemeFiltersAction = createAction(
  '[Parsing Themes] set parsing themes main filters',
  props<{ payload: IParsingThemeFilter }>(),
);

export const getParsingThemeExtendedUniqueFields = createAction(
  '[Parsing Themes] get Parsing Theme Extended Unique Fields',
);

export const getParsingThemeExtendedUniqueFieldsCompleted = createAction(
  '[Parsing Themes] get Parsing Theme Extended Unique Fields Completed',
  props<{ payload: string[] }>(),
);
