import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { CompanySettings } from '@app/auth/shared/models/activateUser.model';
import { of } from 'rxjs';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { GetCompany } from '@app/ngxs-store/company/company.action';

@Injectable()
export class CloudSecurityGuard implements CanActivate {
  @SelectSnapshot(CompanyState.companySettings) public companySettings: CompanySettings;

  constructor(private router: Router) { }
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.getCompany();

    if (!this.companySettings?.cloud_security_enabled) {
      this.router.navigate(['/settings/account']);
      return of(false);
    }
    return of(true);
  }

  @Dispatch() public getCompany = () => new GetCompany();
}
