<div class="content">
  <div
    class="ui basic button"
    [class.active]="filters.showAnomalies"
    (click)="onShowAnomaliesClick()"
  >
    Anomalies
  </div>
  <div
    class="ui basic button"
    [class.active]="filters.showAlerts"
    (click)="onShowAlertsClick()"
  >
    Alerts
  </div>
  <div
    class="ui basic button info"
    [class.active]="isSeveritySelected(severity.Info)"
    (click)="onSeverityClick(severity.Info)"
  >
    Info
  </div>
  <div
    class="ui basic button warning"
    [class.active]="isSeveritySelected(severity.Warning)"
    (click)="onSeverityClick(severity.Warning)"
  >
    Warning
  </div>
  <div
    class="ui basic button critical"
    [class.active]="isSeveritySelected(severity.Critical)"
    (click)="onSeverityClick(severity.Critical)"
  >
    Critical
  </div>
  <div
    class="ui basic button"
    [class.active]="filters.showActiveInsights"
    (click)="onShowActiveInsightsClick()"
  >
    <i class="alarm icon"></i>
  </div>
  <div
    class="ui basic button"
    [class.active]="filters.showMutedInsights"
    (click)="onShowMutedInsightsClick()"
  >
    <i class="alarm mute icon"></i>
  </div>
</div>
