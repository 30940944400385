import { LogQueryModel } from '../../../logs/shared/models/logsquery.model';
export class StatisticsQueryDefinition {
  public category: StatisticsCategory;
  public type: StatisticsTypes;
  public seriesType: string;
  public amount: string;
  public logQeuryModel: LogQueryModel;
  public getBuilds: boolean = false;

  public getNoDataLabel(): string {
    switch (this.category) {
      case StatisticsCategory.ALERTS:
        return 'No Alerts';
      case StatisticsCategory.ANOMALIES:
        return 'No Anomalies';
      case StatisticsCategory.SEVERITY:
      case StatisticsCategory.LOGS:
        return 'No Logs';
    }
    return 'No Items';
  }
}

export enum StatisticsCategory {
  ALERTS,
  ANOMALIES,
  LOGS,
  LOGSAGGRERGATIOM,
  SEVERITY
}
export enum StatisticsTypes {
  Hours,
  Daily,
  Total,
}
