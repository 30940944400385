import { Observable } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReindexS3BucketRequest } from '../../models/reindex.model';
import { ReindexS3PermissionsEditorControls } from '../../models/reindex-editor-controls';
import { ArchivingProviderModel } from '@app/settings/shared/models/archivingProviderModel';
import { ArchivingProvidersReducer } from '@app/settings/reducer/archiving-providers/archiving-providers.reducer';
import TestingResultState = ArchivingProvidersReducer.TestingResultState;

@Component({
  selector: 'sh-reindex-s3-form',
  templateUrl: './reindex-s3-form.component.html',
  styleUrls: ['./reindex-s3-form.component.scss']
})
export class ReindexS3FormComponent implements OnInit {
  @Input()
  public form: FormGroup;
  @Input()
  public regionOptions: {id: number, name: string}[];
  @Input() public testing: Observable<boolean>;
  @Input() public testingResult: Observable<TestingResultState>;;
  @Output()
  public onSubmit: EventEmitter<ReindexS3BucketRequest> = new EventEmitter<ReindexS3BucketRequest>();
  @Output()
  public testArchivingProvider: EventEmitter<ArchivingProviderModel> = new EventEmitter<ArchivingProviderModel>();

  constructor() { }

  public ngOnInit(): void {
  }

  public submit(value: ReindexS3PermissionsEditorControls): void {
    const reindexReq = new ReindexS3BucketRequest({
      region: value.region,
      bucket: value.bucket,
    });
    this.onSubmit.emit(reindexReq);
  }

  public get isFormInvalid(): boolean {
    return this.form?.invalid;
  }

}
