import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import * as _ from 'lodash';
import { StatisticsQueryDefinition } from '@app/statistics/shared/models/statistics-query-definition';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { SeveritiesHelper } from '@shared/services/severities.helper';
import { LoggregationPipe } from '@shared/pipes/loggregation.pipe';
import { Router } from '@angular/router';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { WidgetBaseComponent } from '@shared/components/widget-base-component';
import { LoggregationEntity } from '@app/loggregation/shared/interfaces/loggregation.interface';
import { TemplateType } from '@app/loggregation/shared/interfaces/templateType.enum';
import { LoggregationService } from '@app/loggregation/shared/services/loggregation.service';
import { EFeatureViewMode } from '@app/deployment/shared/models/reducer.models';

@Component({
  selector: 'sh-suspected-template-widget',
  templateUrl: './suspected-template-widget.component.html',
  styleUrls: ['./suspected-template-widget.component.scss'],
})
export class SuspectedTemplateWidgetComponent extends WidgetBaseComponent {
  public ratioData: {
    currentValue: number;
    expectedValues: number;
  }[] = [];
  @Input() set queryModel(queryModel: LogQueryModel) {
    this.suspectedTemplates = [];
    this.viewState.emit(EFeatureViewMode.loading);
    this.isLoading = true;
    if (!queryModel) {
      return;
    }

    let result: Array<LoggregationEntity>;
    this.loggregationService
      .getLoggregationResult(queryModel)
      .map((allLoggregationResult) => {
        result = allLoggregationResult;
        const templateIds = [];
        allLoggregationResult.forEach((loggregation) => templateIds.push(loggregation.id));
        if (allLoggregationResult.length === 0) {
          this.suspectedTemplates = [];
          this.viewState.emit(EFeatureViewMode.noResults);
          this.isLoading = false;
        }
        return templateIds;
      })
      .switchMap((templateIdsResult) =>
        this.loggregationService.getOccurrencesDetailBytemplateIds(
          templateIdsResult,
          'templateGlobalAggStats',
          queryModel,
        ),
      )
      .map((normalBehaviorResults) => {
        const templateToNormal: Map<string, number> = new Map<string, number>();
        normalBehaviorResults.forEach((normalResult) => {
          if (normalResult) {
            let count = 0;
            normalResult.data.data.yAxis.values.forEach((value) => {
              count += value.count;
            });
            templateToNormal.set(normalResult.templateId, count);
          }
        });
        return templateToNormal;
      })
      .map((templateToNormalResult) => {
        const anomolusTemplates = [];
        result.forEach((loggregationEntity) => {
          const normal = templateToNormalResult.get(loggregationEntity.id);
          loggregationEntity.normalCount = normal;
          if (normal === 0) {
            loggregationEntity.countToNormalRation = loggregationEntity.count * 1000;
          } else {
            loggregationEntity.countToNormalRation = 100 / (normal / loggregationEntity.count) - 100;
          }
          if (loggregationEntity.countToNormalRation > this.anomalyFactor) {
            anomolusTemplates.push(loggregationEntity);
          }
        });
        return anomolusTemplates;
      })
      .first()
      .subscribe(
        (res) => {
          const suspectedTemplateResults: SuspectedTemplateResult[] = [];
          const loggregationResult = this.getTopByRationResult(res, this.maxResult);
          loggregationResult.forEach((l) => {
            const suspectedTemplateResult = new SuspectedTemplateResult();
            suspectedTemplateResult.loggregationEntity = l;
            suspectedTemplateResult.logQueryModel = _.cloneDeep<LogQueryModel>(queryModel);
            suspectedTemplateResult.logQueryModel.cacheQueryId = this.logsService.getNewQueryId();
            suspectedTemplateResult.logQueryModel.queryParams.query.text = `coralogix.templateId:"${l.id}"`;
            suspectedTemplateResult.logQueryModel.type = QueryTypes.TEMPLATE;
            suspectedTemplateResult.logQueryModel.queryParams.metadata = new Metadata();
            suspectedTemplateResult.logQueryModel.queryParams.metadata.applicationName.push(l.metadata.applicationName);
            suspectedTemplateResult.logQueryModel.queryParams.metadata.subsystemName.push(l.metadata.subsystemName);
            suspectedTemplateResult.logQueryModel.queryParams.metadata.severity.push(
              SeveritiesHelper.getLogSeverityByName(l.metadata.severity),
            );
            if (!suspectedTemplateResult.link) {
              this.logsService
                .saveLogsQuery(suspectedTemplateResult.logQueryModel)
                .first()
                .subscribe(() => {
                  suspectedTemplateResult.link =
                    '/#/query-new/logs?id=' + suspectedTemplateResult.logQueryModel.cacheQueryId;
                });
            }
            this.ratioData.push({
              currentValue: suspectedTemplateResult.loggregationEntity.count,
              expectedValues: Math.floor(l.normalCount * 100) / 100,
            });

            suspectedTemplateResults.push(suspectedTemplateResult);
          });
          this.suspectedTemplates = suspectedTemplateResults;

          this.viewState.emit(EFeatureViewMode.completed);
          this.isLoading = false;
        },
        (err) => {
          this.viewState.emit(EFeatureViewMode.serverError);
          this.isLoading = false;
        },
      );
  }
  public suspectedTemplates: Array<SuspectedTemplateResult>;
  @Input() public maxResult: number = 0;
  @Input() public excludedTemplates: any[] = [];
  @Input() public anomalyFactor: number = 5;
  @Input() public theme: string = '';
  @Output() public viewState: EventEmitter<EFeatureViewMode> = new EventEmitter<EFeatureViewMode>();

  private loggregationPipe: LoggregationPipe = new LoggregationPipe();

  constructor(
    private loggregationService: LoggregationService,
    private router: Router,
    private logsService: LogsService,
  ) {
    super();
    this.loggregationPipe.clickableParam = '$2';
  }

  public onItemClicked(link: string): void {
    window.open(link, '_blank');
  }

  public getTopByRationResult(
    loggregationEntities: Array<LoggregationEntity>,
    limit: number,
  ): Array<LoggregationEntity> {
    const result = _.orderBy(
      loggregationEntities,
      (entity: LoggregationEntity) => {
        return entity.countToNormalRation;
      },
      'desc',
    );
    const bySeverity = _.orderBy(
      result,
      (entity: LoggregationEntity) => {
        return SeveritiesHelper.getLogSeverityByName(entity.metadata.severity);
      },
      'desc',
    );
    if (limit !== 0) {
      return bySeverity.slice(0, limit);
    }

    return bySeverity;
  }
}
class SuspectedTemplateResult {
  public loggregationEntity: LoggregationEntity;
  public statisticsQueryDefinition: StatisticsQueryDefinition;
  public logQueryModel: LogQueryModel;
  public link: string;
}
