import { State } from '../../app.reducers';
import { Store, Action } from '@ngrx/store';
import { StatisticService } from '../../statistics/shared/services/statistics-service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { FiltersActions } from './filters.actions';
import { of } from 'rxjs/observable/of';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class FiltersEffects {
  @Effect()
  public getDynamicFilters$: Observable<Action> = this.actions$.pipe(
    ofType(FiltersActions.ActionTypes.GET_DYNAMIC_FILTERS),
    switchMap((action: any) => {
      return this.statisticsService
        .getDynamicFieldAggregation(action.payload)
        .map((groups) => new FiltersActions.GetDynamicFiltersCompleted(groups))
        .catch((err) => of(new FiltersActions.GetDynamicFiltersFailed(err)));
    }),
  );
  constructor(
    private actions$: Actions,
    private statisticsService: StatisticService,
    private store: Store<State>,
  ) {}
}
