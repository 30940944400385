import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ContextualRegistryService } from '@app/logs-new/shared/services/contextual-registry.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CapabilityTypes } from '@app/logs-new/shared/abilities-state-registry/capabilities-model';

@Directive({
  selector: '[shAllowAbility]'
})
export class AllowAbilityDirective implements OnInit, OnDestroy {
  private hasView = false;
  private destroy$: Subject<void> = new Subject();
  @Input() shAllowAbility: CapabilityTypes;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef, private contextualAbilities: ContextualRegistryService) {}

  public ngOnInit(): void {
    this.contextualAbilities.registryObs$.pipe(takeUntil(this.destroy$)).subscribe((registry) => {
      this.viewContainer.clear();
      const ctxRegistry = registry;
      const allEnabled = ctxRegistry?.isEnabled('isEnabled');
      if (allEnabled) {
        this.renderView();
        return;
      }
      const isEnabled = ctxRegistry?.isEnabled(this.shAllowAbility);
      if (isEnabled) {
        this.renderView();
      } else {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private renderView(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView = true;
  }
}
