import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class CompanyPlanStatus {
  @jsonMember
  public id: string;
  @jsonMember
  public name: string;
  @jsonMember
  public type: string;
  @jsonMember
  public payment: number;
  @jsonMember
  public plan_size: number;
  @jsonMember
  public indexSize: number;
  @jsonMember
  public plans_url: string;
  @jsonMember
  public block_time: number;
  @jsonMember
  public status: string;
  @jsonMember
  public estimatedUsage: number;
  @jsonMember
  public blockLimit: number;
  @jsonMember
  public snooze_count: number;
  @jsonMember
  public isSnoozed: boolean;
  @jsonMember
  public overageEnabled: boolean;
}
