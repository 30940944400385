import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { getIsCreatingteamUrl, State } from '@app/app.reducers';
import { TeamActions } from '@app/user/state/teams/teams.actions';
import CreateTeam = TeamActions.CreateTeam;
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'sh-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
})
export class CreateTeamComponent implements OnInit, OnDestroy {
  public createNewTeamForm: FormGroup;
  public creatingTeam$Subscription: Subscription;
  public loading: boolean;
  constructor(private formBuilder: FormBuilder, private store: Store<State>) {
    this.createNewTeamForm = formBuilder.group({
      teamName: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    this.creatingTeam$Subscription = this.store
      .select(getIsCreatingteamUrl)
      .subscribe((isCreatingTeamUrl) => {
        this.loading = isCreatingTeamUrl;
      });
  }

  public ngOnDestroy(): void {
    this.creatingTeam$Subscription.unsubscribe();
  }

  public submit(teamName: string): void {
    this.store.dispatch(new CreateTeam(teamName));
  }
}
