import { Routes } from '@angular/router';
import { PaymentGuard } from '@app/shared/guards/payment.guard';
import { AuthGuard } from '../auth/shared/guards/auth.guard';
import { HerokuGurd } from '../shared/guards/heroku-payment.gurd';
import { LivetailTerminalComponent } from './livetail-terminal/livetail-terminal.component';

export const LivetailRoutes: Routes = [
  {
    path: 'livetail',
    component: LivetailTerminalComponent,
    data: {
      title: 'LiveTail',
    },
    canActivate: [PaymentGuard, HerokuGurd, AuthGuard],
  },
];
