import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TypedJSON } from 'typedjson';
import { LogQueryModel } from '../models/logsquery.model';
import { Constants } from '@app/constants';

@Injectable()
export class SavedQueryService {
  private cachedQueryUrl: string = Constants.GetUrl() + Constants.queryUrl;

  constructor(private http: HttpClient) {}

  public getQuery(cachedQueryId: string): Observable<LogQueryModel> {
    const url = this.cachedQueryUrl + '/' + 'querydef/' + cachedQueryId;

    return this.http
      .get(url)
      .map((res) => this.extractData(res))
      .catch((error) => this.handleError(error));
  }

  private extractData(res: object): LogQueryModel {
    return TypedJSON.parse(res, LogQueryModel);
  }

  private handleError(error: any): Observable<never> {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
