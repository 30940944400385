export interface IUrlsPostFix {
  metadataPostfix: string;
  logsAggsPostfix: string;
  logsStatsPostfix: string;
  queryStatePostfix: string;
  queryUrl: string;
}

export interface IRegistry {
  urlsPostFix: Partial<IUrlsPostFix>;
  fieldsToReset?: ResetFields;
  isEnabled: (cap: CapabilityTypes) => boolean;
}

export type CapabilityTypes =
  'isEnabled' |
  'templates' |
  'logs' |
  'archiveQuery' |
  'teams' |
  'addFilter' |
  'enableLogFieldsSelection' |
  'archiveQueryTotalLogs' |
  'enableMetadata' |
  'enableDeleteColumn' |
  'rendererMenu' |
  'rendererAqsMenu' |
  'manageColumns' |
  'createAlert' |
  'highlightLogs' |
  'enableHistogramSelection' |
  'enableSurroundingLogs' |
  'enableSurroundingArchiveLogs' |
  'showGraph';

export const urlsPostfixes: IUrlsPostFix = {
  metadataPostfix: '/api/v1/company/metadata',
  logsAggsPostfix: '/api/v1/statistics/logquery_aggs',
  logsStatsPostfix: '/api/v1/statistics/logs',
  queryStatePostfix: '/api/v1/logquery/stats',
  queryUrl: '/api/v1/logquery'
};
// reset fields
export class ResetFields {
  externalFilters: boolean;
  jsonObject: boolean;
  groupByHistogram: boolean;
  constructor(
    externalFilters: boolean,
    jsonObject: boolean,
    groupByHistogram: boolean
  ) {
      this.externalFilters = externalFilters;
      this.jsonObject = jsonObject;
      this.groupByHistogram = groupByHistogram;
  }
}
// capabilities controller
export class Capabilities {
  isEnabled: boolean;
  templates: boolean;
  logs: boolean;
  archiveQuery: boolean;
  teams: boolean;
  addFilter: boolean;
  enableLogFieldsSelection: boolean;
  archiveQueryTotalLogs: boolean;
  enableMetadata: boolean;
  enableDeleteColumn: boolean;
  rendererMenu: boolean;
  rendererAqsMenu: boolean;
  manageColumns: boolean;
  createAlert: boolean;
  highlightLogs: boolean;
  enableHistogramSelection: boolean;
  enableSurroundingLogs: boolean;
  enableSurroundingArchiveLogs: boolean;
  showGraph: boolean;
  constructor(
    isEnabled: boolean,
    templates: boolean,
    logs: boolean,
    archiveQuery: boolean,
    teams: boolean,
    addFilter: boolean,
    enableLogFieldsSelection: boolean,
    archiveQueryTotalLogs: boolean,
    enableMetadata: boolean,
    enableDeleteColumn: boolean,
    rendererMenu: boolean,
    rendererAqsMenu: boolean,
    manageColumns: boolean,
    createAlert: boolean,
    highlightLogs: boolean,
    enableHistogramSelection: boolean,
    enableSurroundingLogs: boolean,
    enableSurroundingArchiveLogs: boolean,
    showGraph: boolean
  ) {
    this.isEnabled = isEnabled;
    this.templates = templates;
    this.logs = logs;
    this.archiveQuery = archiveQuery;
    this.teams = teams;
    this.addFilter = addFilter;
    this.enableLogFieldsSelection = enableLogFieldsSelection;
    this.archiveQueryTotalLogs = archiveQueryTotalLogs;
    this.enableMetadata = enableMetadata;
    this.enableDeleteColumn = enableDeleteColumn;
    this.rendererMenu = rendererMenu;
    this.rendererAqsMenu = rendererAqsMenu;
    this.manageColumns = manageColumns;
    this.createAlert = createAlert;
    this.highlightLogs = highlightLogs;
    this.enableHistogramSelection = enableHistogramSelection;
    this.enableSurroundingLogs = enableSurroundingLogs;
    this.enableSurroundingArchiveLogs = enableSurroundingArchiveLogs;
    this.showGraph = showGraph;
  }
}
