<div class="ui basic test modal" modal-directive [closeable]="true">
  <div class="modal-content">
    <div class="container">
      <div class="title">
        {{ message }}
      </div>
      <div class="buttons">
        <button class="ui inverted blue button" (click)="yes()">Yes</button>
        <button class="ui inverted button " (click)="no()">No</button>
      </div>
    </div>
  </div>
</div>
