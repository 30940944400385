import { jsonMember, jsonObject } from 'typedjson';
import { Alert } from './alert.model';

@jsonObject
export class MetricAlert {
  @jsonMember
  public arithmeticOperator: number;
  @jsonMember
  public metricFieldExtraction: string;
  @jsonMember
  public metricId: string;
  @jsonMember
  public swapNullValue: number;
  @jsonMember
  public sampleThreshold: number;
  @jsonMember
  public nonNullPercentage: number;
  @jsonMember
  public arithmeticOperatorModifier: number;
  @jsonMember
  public valueThreshold: number;
  @jsonMember
  public promqlText: string;
}

export class MetricAlertQueryModel {
  public alertId: string;
  public metricId: string;
  public metricFieldExtraction: string;
  public arithmeticOperator: number;
  public arithmeticOperatorModifier: number;
  public swapNullValue: number;
  public sampleThreshold: number;
  public nonNullPercentage: number;
  public startTime: number;
  public endTime: number;
  public interval: number;
  public groupByLabels: string[];
  public groupByValues: string[][];

  constructor(alert: Alert) {
    this.alertId = alert.alertId;
    this.metricId = alert.metricAlert.metricId;
    this.metricFieldExtraction = alert.metricAlert.metricFieldExtraction;
    this.arithmeticOperator = alert.metricAlert.arithmeticOperator;
    this.arithmeticOperatorModifier = alert.metricAlert.arithmeticOperatorModifier;
    this.swapNullValue = alert.metricAlert.swapNullValue;
    this.sampleThreshold = alert.metricAlert.sampleThreshold;
    this.nonNullPercentage = alert.metricAlert.nonNullPercentage;
  }
}

export class PromqlMetricAlertQueryModel {
  public alertId: string;
  public start: number;
  public end: number;
  public step: string;
  public labels: string[];

  constructor(alert: Alert, startTime: number, endTime: number, step: string, hitsObj: any) {
    this.alertId = alert.alertId;
    this.start = startTime;
    this.end = endTime;
    this.step = step;
    this.labels = hitsObj.map(hit => Object.keys(hit.labels).map(labelKey => [labelKey, hit.labels[labelKey]]));
  }
}

export class MetricAlertGraphData {
  public timestamp: number;
  public value: number;
}

export class NamedMetricAlertGraphData {
  public name: string;
  public graphData: MetricAlertGraphData[];

  public static fieldValues(name: string): string[] {
    return name?.split('*CORALOGIX_SEPARATOR*');
  }

  constructor(name: string, graphData: MetricAlertGraphData[]) {
    this.name = name;
    this.graphData = graphData;
  }
}

export class PromqlMetricAlertGraphData {
  public metric: { [name: string]: string };
  public values: MetricAlertGraphData[];

  public static fieldValues(metric: { [name: string]: string }): string[] {
    return Object.keys(metric).map(key => metric[key]);
  }

  public static labels(metric: { [name: string]: string }): string[] {
    return Object.keys(metric);
  }

  constructor(metric: { [name: string]: string }, values: MetricAlertGraphData[]) {
    this.metric = metric;
    this.values = values;
  }
}

export class MetricAlertGraphResult {
  public graphData: MetricAlertGraphData[];
}

export class GroupByMetricAlertGraphResult {
  public groupByGraphData: NamedMetricAlertGraphData[];
}

export class PromqlMetricAlertGraphResult {
  public graphData: PromqlMetricAlertGraphData[];
}
