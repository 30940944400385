import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { StatisticsWidget, JsonStatisticsWidget } from '../models/statisticsWidget';
import {
  StatisticsQueryDefinition,
  StatisticsCategory,
  StatisticsTypes,
} from '@app/statistics/shared/models/statistics-query-definition';
import { LogQueryModel } from '../../../logs/shared/models/logsquery.model';
import { QueryParams } from '@app/logs/shared/models/query-params.model';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { DateHelper } from '@shared/services/date.helper';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Widget, WidgetRequest, NotSupportedWidget } from '../models/widget';
import { Constants } from '@app/constants';
import { Observable, of as observableOf } from 'rxjs';
import { TemplateWidget } from '@app/loggregation/shared/interfaces/templateWidget';
import { AnalyticEventService } from '@app/user/shared/AnalyticEventService';
import { LoggregationWidget } from '@app/loggregation/shared/interfaces/loggregation-widget';
import { UserSettings } from '@app/user/shared/user.settings';

@Injectable()
export class WidgetsService {
  constructor(private http: HttpClient, private ae: AnalyticEventService) {}

  public buildWidgetWithQuery(
    startDate: Date,
    endDate: Date,
    description: string,
    category: StatisticsCategory,
    seriesType: string,
  ): StatisticsWidget {
    const widget: StatisticsWidget = this.buildWidget(description, category, seriesType);
    const logQueryModel: LogQueryModel = new LogQueryModel(startDate, endDate);
    logQueryModel.queryParams = new QueryParams();
    logQueryModel.queryParams.metadata = new Metadata();
    widget.statisticsQueryDefinition.logQeuryModel = logQueryModel;
    return widget;
  }

  public buildWidget(description: string, category: StatisticsCategory, seriesType: string): StatisticsWidget {
    const widget: StatisticsWidget = new StatisticsWidget();
    widget.description = description;
    widget.statisticsQueryDefinition = new StatisticsQueryDefinition();
    widget.statisticsQueryDefinition.category = category;
    widget.statisticsQueryDefinition.seriesType = seriesType;
    return widget;
  }

  public getPredefinedStatisticsWidgets() {
    const widgets: StatisticsWidget[] = new Array<StatisticsWidget>();
    const logs24HoursWidget: StatisticsWidget = this.buildWidgetWithQuery(
      DateHelper.getRoundHour(moment().toDate())
        .subtract(24, 'h')
        .toDate(),
      moment().toDate(),
      'Logs 24 hours',
      StatisticsCategory.SEVERITY,
      'pie',
    );
    widgets.push(logs24HoursWidget);

    const highSevWidget: StatisticsWidget = this.buildWidgetWithQuery(
      DateHelper.getRoundHour(moment().toDate())
        .subtract(3, 'h')
        .toDate(),
      moment().toDate(),
      'High sev logs 4 hours',
      StatisticsCategory.SEVERITY,
      'column',
    );
    highSevWidget.statisticsQueryDefinition.logQeuryModel.queryParams.aggregationInterval = 3600 * 1000;
    highSevWidget.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.severity = [4, 5, 6];
    widgets.push(highSevWidget);

    const alertsWidget = this.buildWidget('Alerts 24 hours', StatisticsCategory.ALERTS, 'pie');
    alertsWidget.statisticsQueryDefinition.type = StatisticsTypes.Total;
    widgets.push(alertsWidget);

    const anomalyWidget = this.buildWidget('Anomalies 24 hours', StatisticsCategory.ANOMALIES, 'pie');
    anomalyWidget.statisticsQueryDefinition.type = StatisticsTypes.Total;
    widgets.push(anomalyWidget);

    const activityWidget: StatisticsWidget = this.buildWidgetWithQuery(
      DateHelper.getRoundHour(moment().toDate())
        .subtract(24, 'h')
        .toDate(),
      moment().toDate(),
      'Logs activity 24 hours',
      StatisticsCategory.SEVERITY,
      'areaspline',
    );
    activityWidget.statisticsQueryDefinition.logQeuryModel.queryParams.aggregationInterval = 3600 * 1000;
    activityWidget.group = 'largeCharts';
    activityWidget.statisticsQueryDefinition.getBuilds = true;
    widgets.push(activityWidget);

    return widgets;
  }

  public getPredefinedWidgets(settings: UserSettings): Widget[] {
    const widgets: Widget[] = new Array<Widget>();

    const loggregationCommonErrorsWidget: LoggregationWidget = new LoggregationWidget();
    loggregationCommonErrorsWidget.logQueryModel = this.getTodayErrorsQuery(settings);
    loggregationCommonErrorsWidget.group = 'NonGraphWidget';
    loggregationCommonErrorsWidget.title = 'Top 3 errors 24 hours';
    widgets.push(loggregationCommonErrorsWidget);

    const statisticsWidgets = this.getPredefinedStatisticsWidgets();
    statisticsWidgets.forEach((w) => {
      if (w.statisticsQueryDefinition.logQeuryModel) {
        w.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.subsystemName =
          settings.queryMetadata.subsystemName;
        w.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.applicationName =
          settings.queryMetadata.applicationName;
        w.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.computerName =
          settings.queryMetadata.computerName;
        w.statisticsQueryDefinition.logQeuryModel.queryParams.metadata.IPAddress = settings.queryMetadata.IPAddress;
      }
    });
    widgets.push(...statisticsWidgets);
    return widgets;
  }

  public getTodayErrorsQuery(userSettings: UserSettings): LogQueryModel {
    const startDate: Date = DateHelper.getRoundHour(moment().toDate())
      .subtract(24, 'h')
      .toDate();
    const endDate: Date = moment().toDate();
    const queryModel = new LogQueryModel(startDate, endDate);
    queryModel.queryParams.metadata.severity = [5, 6];
    queryModel.queryParams.metadata.applicationName = userSettings.queryMetadata.applicationName;
    queryModel.queryParams.metadata.subsystemName = userSettings.queryMetadata.subsystemName;
    return queryModel;
  }

  public getWidgets() {
    return this.http.get(Constants.GetUrl() + '/api/v1/widgets').map(this.extractWidgests);
  }

  public getWidget(id: number) {
    let params: HttpParams = new HttpParams();
    if (id) {
      params = params.set('id', id.toString());
    }
    return this.http.get(Constants.GetUrl() + '/api/v1/widgets', { params }).map(this.extractWidgests);
  }

  public addWidgets(widget?: Widget) {
    if (!widget) {
      return observableOf(null);
    }
    const request = new WidgetRequest(widget);
    this.ae.event({ eventName: 'addWidget' });
    return this.http.post(Constants.GetUrl() + '/api/v1/widgets', JSON.stringify(request)).map(this.extractWidget);
  }

  public deleteWidget(id: number) {
    let params: HttpParams = new HttpParams();
    if (id) {
      params = params.set('id', id.toString());
    }
    return this.http.delete(Constants.GetUrl() + '/api/v1/widgets', { params });
  }

  public updateWidget(widget: Widget): Observable<Widget> {
    const request = new WidgetRequest(widget);
    return this.http.put(Constants.GetUrl() + '/api/v1/widgets', JSON.stringify(request)).map(this.extractWidget);
  }

  private handleError(error: any) {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }

  private extractWidget(res): Widget {
    const result: Widget = res;
    return result;
  }

  private extractWidgests(res: any): Widget[] {
    const result: Widget[] = res.map((widget) => {
      if (widget.url === 'statistics/logs') {
        const statisticWidget: StatisticsWidget = widget;
        statisticWidget.statisticsQueryDefinition = new StatisticsQueryDefinition();
        statisticWidget.statisticsQueryDefinition.logQeuryModel = JSON.parse(widget.query);
        if (statisticWidget.statisticsQueryDefinition.logQeuryModel == null) {
          return statisticWidget;
        }

        statisticWidget.statisticsQueryDefinition.logQeuryModel.startDate = widget.amount
          ? moment()
              .subtract(widget.amount, 'h')
              .unix() * 1000
          : DateHelper.getRoundHour(moment().toDate()).unix() * 1000;

        statisticWidget.statisticsQueryDefinition.logQeuryModel.endDate = moment().unix() * 1000;
        statisticWidget.statisticsQueryDefinition.category = StatisticsCategory.LOGS;
        statisticWidget.statisticsQueryDefinition.seriesType = 'areaspline';
        statisticWidget.statisticsQueryDefinition.logQeuryModel.queryParams.aggregationInterval = widget.timeInterval;

        return statisticWidget;
      }

      if (widget.url === 'statistics/logs1') {
        const statisticWidget: JsonStatisticsWidget = widget;
        statisticWidget.statisticsQueryDefinition = new StatisticsQueryDefinition();
        statisticWidget.statisticsQueryDefinition.logQeuryModel = JSON.parse(widget.query);
        if (statisticWidget.statisticsQueryDefinition.logQeuryModel == null) {
          return statisticWidget;
        }

        statisticWidget.statisticsQueryDefinition.logQeuryModel.startDate = widget.amount
          ? moment()
              .subtract(widget.amount, 'h')
              .unix() * 1000
          : DateHelper.getRoundHour(moment().toDate()).unix() * 1000;

        statisticWidget.statisticsQueryDefinition.logQeuryModel.endDate = moment().unix() * 1000;
        statisticWidget.statisticsQueryDefinition.category = StatisticsCategory.LOGS;
        statisticWidget.statisticsQueryDefinition.seriesType = 'areaspline';
        statisticWidget.statisticsQueryDefinition.logQeuryModel.queryParams.aggregationInterval = widget.timeInterval;

        return statisticWidget;
      }
      if (widget.url === 'loggregation/parameter') {
        const templateWidget: TemplateWidget = widget;
        templateWidget.queryRequest = JSON.parse(widget.query);

        templateWidget.queryRequest.queryDef.startDate = widget.amount
          ? moment()
              .subtract(widget.amount, 'h')
              .unix() * 1000
          : DateHelper.getRoundHour(moment().toDate()).unix() * 1000;

        templateWidget.queryRequest.queryDef.endDate = moment().unix() * 1000;
        return templateWidget;
      }
      const notSupportedWidget: NotSupportedWidget = widget;
      notSupportedWidget.timeInterval = 1;
      notSupportedWidget.title = 'Not supported widget';
      return notSupportedWidget;
    });

    return result;
  }
}
