import { LogsFilterGridColumnsActions } from './logs-filter-grid-columns.actions';
import * as _ from 'lodash';

export namespace LogsFilterGridColumnsReducer {
  export interface State {
    filter: {
      selected: any;
      available: any;
    };
    isVisible: boolean;
    isLoading: boolean;
  }

  const initalState: State = {
    filter: {
      selected: null,
      available: null,
    },
    isVisible: true,
    isLoading: false,
  };

  export function reducer(state: State = initalState, action: LogsFilterGridColumnsActions.Actions): State {
    const currState = _.cloneDeep(state);
    const currFilter = currState.filter;
    let targetIndex = null;
    let allColumns = null;

    switch (action.type) {
      case LogsFilterGridColumnsActions.ActionTypes.LOGS_FILTER_GRID_TEMPLATE_COLUMNS_INIT:
      case LogsFilterGridColumnsActions.ActionTypes.LOGS_FILTER_GRID_COLUMNS_INIT:
        return Object.assign({}, state, {
          filter: currFilter,
          isVisible: currState.isVisible,
          isLoading: true,
        });
      case LogsFilterGridColumnsActions.ActionTypes.LOGS_FILTER_GRID_COLUMNS_INIT_COMPLETED:
        return Object.assign({}, state, {
          filter: {
            selected: !state.filter.selected
              ? action.payload.selected
              : _.unionBy(action.payload.selected, state.filter.selected, 'colId'),
            available: action.payload.available,
          },
          isVisible: currState.isVisible,
          isLoading: false,
        });

      case LogsFilterGridColumnsActions.ActionTypes.COLUMNS_SELECTED:
        action.payload.forEach((colId) => {
          targetIndex = currFilter.available.findIndex((x) => x.colId === colId);

          if (targetIndex !== -1) {
            const selectedCol = currFilter.available.splice(targetIndex, 1)[0];
            currFilter.selected.push(selectedCol);
          }
        });

        currFilter.selected.sort((a, b) => a.colId.localeCompare(b.colId));

        return Object.assign({}, state, {
          filter: currFilter,
          isVisible: currState.isVisible,
          isLoading: currState.isLoading,
        });

      case LogsFilterGridColumnsActions.ActionTypes.COLUMNS_DESELECTED:
        action.payload.forEach((colId) => {
          targetIndex = currFilter.selected.findIndex((x) => x.colId === colId);

          if (targetIndex !== -1) {
            const deselectedCol = currFilter.selected.splice(targetIndex, 1)[0];
            currFilter.available.push(deselectedCol);
          }
        });

        currFilter.available.sort((a, b) => a.colId.localeCompare(b.colId));

        return Object.assign({}, state, {
          filter: currFilter,
          isVisible: currState.isVisible,
          isLoading: currState.isLoading,
        });

      case LogsFilterGridColumnsActions.ActionTypes.SET_FILTER_VISIBILITY:
        return Object.assign({}, state, {
          filter: currFilter,
          isVisible: action.payload,
          isLoading: currState.isLoading,
        });

      case LogsFilterGridColumnsActions.ActionTypes.SELECT_DEFAULT_COLUMNS:
        allColumns = currFilter.selected.concat(currFilter.available);
        currFilter.selected = allColumns.filter((col) => col.isDefault);
        currFilter.available = allColumns.filter((col) => !col.isDefault);

        currFilter.selected.sort((a, b) => a.colId.localeCompare(b.colId));
        currFilter.available.sort((a, b) => a.colId.localeCompare(b.colId));

        return Object.assign({}, state, {
          filter: currFilter,
          isVisible: currState.isVisible,
          isLoading: currState.isLoading,
        });

      case LogsFilterGridColumnsActions.ActionTypes.SET_SELECTED_COLUMNS:
        allColumns = currFilter.selected.concat(currFilter.available);
        currFilter.selected = allColumns.filter((col) => action.payload.includes(col.colId));
        currFilter.available = allColumns.filter((col) => !action.payload.includes(col.colId));

        currFilter.selected.sort((a, b) => a.colId.localeCompare(b.colId));
        currFilter.available.sort((a, b) => a.colId.localeCompare(b.colId));

        return Object.assign({}, state, {
          filter: currFilter,
          isVisible: currState.isVisible,
          isLoading: currState.isLoading,
        });

      case LogsFilterGridColumnsActions.ActionTypes.LOGS_FILTER_GRID_COLUMNS_FAILURE:
        return Object.assign({}, state, {
          filter: currFilter,
          isVisible: currState.isVisible,
          isLoading: false,
        });
      default:
        return state;
    }
  }
}
