import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { getMetrics, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FieldExtraction } from '../../models/metric.model';
import { MetricConstants } from '../../models/metric.constants';

@Component({
  selector: 'sh-field-extraction-selector',
  templateUrl: './field-extraction-selector.component.html',
  styleUrls: ['./field-extraction-selector.component.scss'],
})

export class FieldExtractionSelectorComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input()
  public metricId: string;
  @Input()
  public form: FormGroup;
  @Input()
  public set options(options: string[]) {
    this._options = options;
    const nonEmptyFieldPaths = this.metricFields?.controls
      .map(control => control.get('fieldPath').value)
      .filter((path: string) => !_.isEmpty(path));

    this.updateFilterOptions(nonEmptyFieldPaths);
  }
  @Input()
  public headerText: string;
  @Input()
  public labelText: string;
  @Input()
  public limitFields: number;
  @Input()
  public isMetricField: boolean;
  @Input()
  public entityType: string;
  @Output()
  public selectedLabelNames: EventEmitter<string[]> = new EventEmitter<string[]>();
  public existingMetricFieldNames: string[] = [];
  public formChanges$: Subscription;
  public filterOptions: string[];
  public isDisabled: boolean = false;
  public hasReachedLimit: boolean = false;
  public metricFields: FormArray;
  private metricsSubscription$: Subscription;
  private _options: string[];
  private validatorsSet: boolean[] = [];

  constructor(private store: Store<State>, public metricConstants: MetricConstants) { }

  public ngOnInit(): void {
    this.formChanges$ = this.form?.valueChanges?.subscribe((values) => {
      const fields: FieldExtraction[] = values?.fields;
      const nonEmptyFieldExtractionPaths: string[] = fields ?
        fields.map((val: FieldExtraction) => val.fieldPath)
          .filter((path: string) => !_.isEmpty(path))
        : [];

      if (!this.isMetricField && !_.isNumber(this.limitFields)) {
        this.limitFields = this.metricConstants.METRIC_LABEL_LIMIT_DEFAULT;
      }
      this.hasReachedLimit = (fields && fields.length === this.limitFields);
      this.selectedLabelNames?.emit(nonEmptyFieldExtractionPaths.map(val => val.trim()));

      this.updateFilterOptions(nonEmptyFieldExtractionPaths);
    });

    setTimeout(() => {
      this.metricFields = this.form.get('fields') as FormArray;
      if (this.metricFields?.controls?.length > 0) {
        this.metricFields?.controls.forEach((control: AbstractControl, index: number) => {
          this.validatorsSet.push(false);
          this.updateValidators(index);
        });
      } else {
        this.metricFields.push(this.getDefaultExtraction());
        this.validatorsSet.push(false);
      }
    });
  }

  public ngAfterContentInit(): void {
    this.metricsSubscription$ = this.store.select(getMetrics).pipe(delay(0)).subscribe(metrics => {
      this.existingMetricFieldNames = _.flatMap(metrics,
        metric =>
          metric.id !== this.metricId ?
            metric.metricFields?.map(field => field.name) : []
      );
      this.metricFields?.controls?.forEach(c => c.get('name').updateValueAndValidity());
    });
  }

  public removeField(index: number): void {
    const isFirstAndOnly: boolean = index === 0 && this.metricFields?.length === 1;
    const isValidIndex: boolean = index >= 0 && index < this.metricFields?.length;

    if (isFirstAndOnly) {
      const control: AbstractControl = this.metricFields.at(index);
      control.reset();

      const action = (childControl: AbstractControl) => childControl.clearValidators();
      this.performActionForChildControl(control, 'name', action);
      this.performActionForChildControl(control, 'fieldPath', action);

      this.validatorsSet.splice(index, 1);
      this.form.markAsDirty();
    } else if (isValidIndex) {
      this.metricFields.removeAt(index);
      this.validatorsSet.splice(index, 1);
      this.form.markAsDirty();
    }
  }

  public addField(): void {
    if (!this.hasReachedLimit) {
      this.metricFields.push(this.getDefaultExtraction());
      this.validatorsSet.push(false);
    }
  }

  public ngOnDestroy(): void {
    if (this.formChanges$) {
      this.formChanges$.unsubscribe();
    }
    if (this.metricsSubscription$) {
      this.metricsSubscription$.unsubscribe();
    }
  }

  public updateValidators(index: number): void {
    if (!this.validatorsSet[index]) {
      const control: AbstractControl = this.metricFields?.at(index);
      this.performActionForChildControl(control, 'name',
        name => name?.setValidators(this.getValidators('name')));
      this.performActionForChildControl(control, 'fieldPath',
        path => path?.setValidators(this.getValidators('fieldPath')));
      this.validatorsSet[index] = true;
    }
  }

  private duplicateMetricFieldNameValidator(control: FormControl): { [s: string]: boolean } {
    if (this.existingMetricFieldNames?.includes(control.value)) {
      return { duplicateName: true };
    }
    return null;
  }

  private duplicateValidator(propertyName: string): ValidatorFn {
    return ((control: FormControl) => {
      const validatorError = 'duplicate' + _.capitalize(propertyName);

      const validatorFails = this.metricFields?.controls?.some(c => {
        const currName: AbstractControl = c.get(propertyName);
        const isDifferentControlWithName = currName !== control && !_.isEmpty(currName.value);

        return isDifferentControlWithName && currName.value === control.value;
      });

      if (validatorFails) {
        return { [validatorError]: true };
      }
      return null;
    }).bind(this);
  }

  private getDefaultExtraction(): FormGroup {
    return new FormGroup({
      name: new FormControl(null),
      fieldPath: new FormControl(null)
    });
  }

  private performActionForChildControl(
    control: AbstractControl,
    childControlPath: string,
    action: (control: AbstractControl) => void
  ): void {
    const childControl = control?.get(childControlPath);
    action(childControl);
    childControl?.updateValueAndValidity();
  }

  private getValidators(controlName: string): Array<ValidatorFn> {
    const validators = [Validators.required];
    if (controlName === 'name') {
      if (this.isMetricField) {
        validators.push(this.duplicateMetricFieldNameValidator.bind(this));
      }
      validators.push(
        Validators.pattern(this.metricConstants.metricExtractionNameRegex),
        this.duplicateValidator(controlName)
      );
    } else {
      validators.push(this.duplicateValidator(controlName));
    }
    return validators;
  }

  private updateFilterOptions(nonEmptyFieldPaths: any[]): void {
    this.filterOptions = this._options?.filter(x => !nonEmptyFieldPaths?.includes(x));
  }
}
