<mat-card class="coralogix card" [class.invalid]="isFormInvalid">
  <form [formGroup]="form">
    <p class="archive-query-filter-subheader">
      Target logs by application, subsystem(s) or the severities contained within the logs.
    </p>
    <div class="coralogix input-group">
      <div class="validation-error" *ngIf="validationError">{{validationError}}</div>
      <mat-tab-group (selectedTabChange)="syntaxTabClicked($event)" [selectedIndex]="querySyntaxTabInitialIndex">
        <mat-tab class="tab" label="Lucene" [disabled]='!isEditable'>
          <sh-search-query [isEditable]="isEditable" formControlName="textLucene"></sh-search-query>
        </mat-tab>
        <mat-tab class="tab" label="SQL" [disabled]='!isEditable'>
          <sh-search-query [placeholderText]="examplePrestoQuery" [isEditable]="isEditable"
                           [description]="prestoQueryDescription" [showRegexLegend]="false"
                           [showPrestoGuide]="true" [tooltipContent]="prestoTooltipContent"
                           [placeholderAsInitialValue]="true" formControlName="textSql"></sh-search-query>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div *ngIf="(this.isPrestoQuery$ | async)">
      <p class="archive-query-filter-subheader">
        Choose a UTC time range for the query (rounded to the hour)
      </p>
      <p class="archive-query-filter-content">
        Add a condition to the SQL query for more fine-grained control over the time range. <br/>
        Condition format should be <b>timestamp >= 'YYYY-MM-DDThh:mm:ss' and timestamp <
        'YYYY-MM-DDThh:mm:ss'</b> (in UTC as well)
      </p>
      <br/>
    </div>
    <div class="date-container">
      <div>
        <ng-container
          *ngTemplateOutlet="timepicker;
                            context:{
                            dateControl:'from',
                            timeControl:'startTime',
                            title:'From',
                            isSeen:fromTimePickerSeen,
                            timePickerLabel:fromTimePickerLabel
                            }"
        >
        </ng-container>
      </div>
      <div>
        <ng-container
          *ngTemplateOutlet="timepicker;
                            context:{
                            dateControl:'to',
                            timeControl:'endTime',
                            title:'To',
                            isSeen:toTimePickerSeen,
                            timePickerLabel:toTimePickerLabel
                            }"
        >
        </ng-container>
      </div>
      <span class="time-err-msg" *ngIf="showErrMsg">Range limit is 24 hours</span>
    </div>
    <div *ngIf="!(this.isPrestoQuery$ | async)" class="coralogix input-group shorten-string">
      <sh-coralogix-select
        [selectOptions]="applicationOptions$ | async"
        [multiple]="true"
        [searchable]="true"
        [allSelectedLabel]="'All Applications'"
        [placeholder]="'Select applications'"
        formControlName="applicationName"
      >
        <label select-label>
          <span class="text">Applications</span>
        </label>
      </sh-coralogix-select>
    </div>
    <div *ngIf="!(this.isPrestoQuery$ | async)" class="coralogix input-group shorten-string">
      <sh-coralogix-select
        [selectOptions]="subsystemOptions$ | async"
        [multiple]="true"
        [searchable]="true"
        [allSelectedLabel]="'All Subsystems'"
        [placeholder]="'Select subsystems'"
        formControlName="subsystemName"
      >
        <label select-label>
          <span class="text">Subsystems</span>
        </label>
      </sh-coralogix-select>
    </div>
    <div *ngIf="!(this.isPrestoQuery$ | async)" class="coralogix input-group">
      <sh-coralogix-select
        [selectOptions]="logsSeverityOptions"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [multiple]="true"
        [searchable]="true"
        [allSelectedLabel]="'All Severities'"
        [hasColoredIcon]="true"
        [placeholder]="'Select severities'"
        formControlName="logSeverity"
      >
        <label select-label>
          <span class="text">Severities</span>
        </label>
      </sh-coralogix-select>
    </div>

  </form>
</mat-card>

<ng-template #timepicker
             let-dateControl='dateControl'
             let-timeControl='timeControl'
             let-title='title'
             let-isSeen="isSeen"
             let-timePickerLabel="timePickerLabel"
>
  <div [formGroup]="timepickerForm">
    <label select-label>
      <span class="time-picker-title">{{title}}</span>
    </label>

    <div class="form-timepicker-btn" (click)="displayTimePicker(dateControl)">
      <img src="../../../../../assets/roi/icon-clock.svg"/>
      <span>{{ timePickerLabel }}</span>
    </div>

    <div class="special-timepicker-container" [class.away]="!isSeen">
      <div class="floating">
        <div class="date">
          <sh-ng-datepicker
            [isOpened]="true"
            [inline]="true"
            [headless]="true"
            formControlName="{{dateControl}}"
          ></sh-ng-datepicker>

          <div class="devider">
            <hr/>
          </div>
        </div>

        <div class="time">
          <div class="top-inputs-container">
            <span>Time</span>

            <sh-coralogix-time-input formControlName="{{timeControl}}"
                                     [showMinutes]="!(isPrestoQuery$ | async)"></sh-coralogix-time-input>
          </div>
        </div>

        <div class="devider">
          <hr/>
        </div>

        <div class="special-time-btn-container">
          <button class="special-time-btn" mat-raised-button type="button" (click)="acceptTime(dateControl)">
            APPLY
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
