import { Injectable } from '@angular/core';
import { ERuleServerTypes, IParsingTheme, IRuleGroupResponseData, RuleMatcher } from '@app/rules/shared/models/rule.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IRuleFieldOptionValue,
  IRuleFormData,
  IRuleGroupFormData,
  RuleMatcherView,
} from '@app/rules/shared/models/parsing-theme-editor.models';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { getNonAllLogsFilterGridColumns, State } from '@app/app.reducers';
import { Observable, of } from 'rxjs';
import { RuleFieldOptions, DefaultTimestandard, TimestampFormatStandards } from '@app/rules/shared/models/parsing-theme-editor-consts';
import { DefaultMetaFieldOptionValue } from '@app/rules/shared/models/rules-consts';
import { Constants } from '@app/constants';

@Injectable()
export class ParsingThemeFormService {

  constructor(private store: Store<State>) {}

  public getParsingThemeForm(initialData: IParsingTheme): FormGroup {
    return new FormGroup({
      id: new FormControl(initialData.id),
      details: this.getDetailsForm(initialData),
      ruleMatchers: this.getRuleMatcherForm(initialData.ruleMatchers),
      rulesGroups: this.getRuleGroupsValue(initialData.rulesGroups),
      enabled: new FormControl(initialData.enabled),
    });
  }

  public getGroupForm(data: IRuleGroupFormData): FormGroup {
    const ruleForms = new FormArray([]);
    if (data.rules) {
      data.rules.forEach((rule, i) => {
        const ruleInLowerKeys = this.keysToLowerCase({ ...rule }) as IRuleFormData;
        ruleInLowerKeys.order = i + 1;
        ruleForms.push(this.getRuleForm(ruleInLowerKeys));
      });
    }
    return new FormGroup({
      id: new FormControl(data.id),
      order: new FormControl(data.order),
      rules: ruleForms
    });
  }

  public getRuleForm(data: IRuleFormData): FormGroup {
    const isTypeAllow = data.type === ERuleServerTypes.allow;
    const isTypeBlock = data.type === ERuleServerTypes.block;
    const bockMatchingLogic = isTypeAllow ? ERuleServerTypes.allow : isTypeBlock ? ERuleServerTypes.block : null;
    const isTimestampExtract = data.type === ERuleServerTypes.timestampExtract;
    let destinationField = data.destinationField || DefaultMetaFieldOptionValue;
    let replaceNewVal = { value: data.replaceNewVal, disabled: false };

    if (isTimestampExtract) {
      destinationField = data.destinationField || DefaultTimestandard.value;
      const standardMeta = (TimestampFormatStandards.find(s => s.value === destinationField) || DefaultTimestandard)._metadata;
      replaceNewVal = {
        value: standardMeta.enabled ? (data.replaceNewVal || standardMeta.format) : standardMeta.format,
        disabled: !standardMeta.enabled
      };
    }

    return new FormGroup({
      id: new FormControl(data.id),
      name: new FormControl(data.name, [Validators.required]),
      description: new FormControl(data.description),
      enabled: new FormControl(data.enabled),
      type: new FormControl(isTypeAllow ? ERuleServerTypes.block : data.type),
      sourceField: new FormControl(data.sourceField || DefaultMetaFieldOptionValue),
      destinationField: new FormControl(destinationField),
      rule: new FormControl(this.getRule(data), [Validators.required]),
      replaceNewVal: new FormControl(replaceNewVal),
      keepBlockedLogs: new FormControl(data.keepBlockedLogs),
      order: new FormControl(data.order),
      blockMatching: new FormControl(bockMatchingLogic),
      logExample: new FormControl(data.logExample || ''),
      logExampleRes: new FormControl(data.logExampleRes || '')
    });
  }

  // Read JSON fields and construct extendedOptions
  public getExtendedRulesFieldOptions(): Observable<IRuleFieldOptionValue[]> {
    const textFieldName = Constants.getTextFieldName() ? `${Constants.getTextFieldName()}.` : '';
    return this.store
      .select(getNonAllLogsFilterGridColumns)
      .filter(
        (gridColumns) =>
          gridColumns?.filter &&
          gridColumns.filter.available &&
          gridColumns.filter.selected,
      )
      .switchMap((gridColumns) => {
        let fields = [];
        fields.push(
          ...gridColumns.filter.available.map((c) => c.colId),
          ...gridColumns.filter.selected.map((c) => c.colId),
        );

        fields = fields
          .filter((field) => !field.startsWith('coralogix.'))
          .map((field) => {
            return {
              name: `${textFieldName}${field}`,
              value: `${textFieldName.toLowerCase()}${field}`,
              rulesToSkip: [ERuleServerTypes.jsonExtract],
            };
          })
          .sort((field1, field2) => {
            if (field1.name < field2.name) {
              return -1;
            }
            if (field1.name > field2.name) {
              return 1;
            }
            return 0;
          });

        return of(RuleFieldOptions.concat(fields));
      });
  }

  private getRuleGroupsValue(ruleGroups: IRuleGroupResponseData[] | IRuleGroupFormData[]): FormArray {
    if (Array.isArray(ruleGroups) && ruleGroups[0]?.rules?.length) {
      return this.getRuleGroupsFormArray(ruleGroups);
    }
    return new FormArray([]);
  }

  private getRuleGroupsFormArray(ruleGroups: IRuleGroupResponseData[] | IRuleGroupFormData[]): FormArray {
    const ruleGroupFormArray = new FormArray([]);
    ruleGroups?.forEach((group, index) => {
      const groupInLowerKeys = this.keysToLowerCase(group) as IRuleGroupFormData;
      groupInLowerKeys.order = index + 1;
      if (groupInLowerKeys.rules?.length) {
        const formGroup = this.getGroupForm(groupInLowerKeys);
        ruleGroupFormArray.push(formGroup);
      }
    });
    return ruleGroupFormArray;
  }

  private getDetailsForm(initialData: IParsingTheme): FormGroup {
    return new FormGroup({
      name: new FormControl(initialData.name, [Validators.required]),
      description: new FormControl(initialData.description)
    });
  }

  private getRuleMatcherForm(initialData: RuleMatcher[]): FormGroup {
    const mappedRuleMatchers = new RuleMatcherView(initialData).getProps();
    return new FormGroup({
      applications: new FormControl(mappedRuleMatchers.applications),
      subsystems: new FormControl(mappedRuleMatchers.subsystems),
      severities: new FormControl(mappedRuleMatchers.severities?.map((severity) => severity.toUpperCase()) || [])
    });
  }

  private keysToLowerCase(anyObject: {}): any {
    return _.mapKeys(anyObject, (value, key) => {
      return `${key[0].toLowerCase()}${key.substr(1, key.length)}`;
    });
  }

  private getRule(data: IRuleFormData): any {
    switch (data.type) {
      case ERuleServerTypes.timestampExtract:
        return '.*';
      case ERuleServerTypes.removeFields:
        // multi-select component requires array input
        return data.rule ? (Array.isArray(data.rule) ? data.rule : data.rule.split(',')) : [];
      default:
        return data.rule;
    }
  }
}
