export interface ILogQueryParams {
  query?: string;
  startTime?: string;
  endTime?: string;
  id?: string;
  viewName?: string;
  logId?: string;
  time?: string;
  templateId?: string;
  page?: number;
  permalink?: string;
}

export enum RelativeTimeSuffix {
  Seconds = 's',
  Minutes = 'm',
  Hours = 'h',
  Days = 'd',
}

export enum TimeInSeconds {
  Second = 1,
  Minute = 60,
  Hour = 3600,
  Day = 86400,
}
