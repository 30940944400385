import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileAppMenuComponent } from './mobile-app-menu.component';
import { RouterModule } from '@angular/router';
import { PopupDirectiveModule } from '@app/shared/directives/popup/popup.directive.module';

@NgModule({
  declarations: [MobileAppMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    PopupDirectiveModule
  ],
  exports: [MobileAppMenuComponent]
})
export class MobileAppMenuModule { }
