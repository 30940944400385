<div class="header">
  <i class="icon arrow left backBtn" (click)="closeInsightOccurrences()" *ngIf="selectedInsightGroup"></i>
  <div class="title">{{ getInsightGroupTitle(selectedInsightGroup) }}</div>
  <i
    class="filter icon filter-button"
    *ngIf="!selectedInsightGroup"
    [class.active]="filters?.isUsingFiltersOn"
    [popup-directive]="'hover'"
    [hoverable]="true"
    [popup-position]="'bottom center'"
  ></i>
  <div class="ui flowing popup top left transition hidden">
    <sh-insight-filter #filterComp [filterType]="filterType" (filterChanged)="onFilterChanged($event)"></sh-insight-filter>
  </div>
  <i
    class="ellipsis vertical icon filter-button"
    *ngIf="!selectedInsightGroup"
    [popup-directive]="'hover'"
    [hoverable]="true"
    [popup-position]="'bottom center'"
  ></i>
  <div class="ui popup top left transition hidden">
    <div class="group-by-container">
      <span>Group by</span>
      <div class="ui radio checkbox">
        <input
          type="radio"
          name="grouping"
          [value]="aggregationType.Daily"
          (click)="showGroupedInsights()"
          checked="checked"
          [(ngModel)]="insightsAggregationType"
        />
        <label>Day</label>
      </div>
      <div class="ui radio checkbox">
        <input
          type="radio"
          name="grouping"
          [value]="aggregationType.Hourly"
          (click)="showGroupedInsights()"
          [(ngModel)]="insightsAggregationType"
        />
        <label>Hour</label>
      </div>
    </div>
  </div>
</div>

<div class="insights-timeline-content" *ngIf="!isLoading; else loadingTemplate">
  <div class="search-container">
    <div class="search-container-wrap">
      <input type="text" style="display: inline-block" placeholder="Search insights" [(ngModel)]="filterText" />
      <i class="icon search"></i>
    </div>
  </div>
  <div class="items-container" *ngIf="!showInsightOccurences" [@rootViewState]="rootViewState">
    <sh-virtual-scroll
      style="z-index:9;height: 100%;width: 100%;display: block"
      [childWidth]="250"
      [childHeight]="117"
      [items]="
        insights
          | insightsFilter: filters
          | InsightsTextFilter: filterText
          | insightsMetadataFilter: filters
          | groupInsights: insightsAggregationType
      "
      (update)="viewPortItems = $event"
    >
      <div *ngFor="let insightGroup of viewPortItems">
        <div>
          <sh-insight-timeline-item
            [insight]="insightGroup.insights[0]"
            [selected]="isInsightGroupSelected(insightGroup, currentInsight?.id)"
            [groupBy]="insightsAggregationType == aggregationType.Daily ? '(24H)' : '(1H)'"
            [isRoot]="true && insightGroup.insights.length > 1"
            [occurrences]="insightGroup.insights.length"
            [uniqueCompNum]="insightGroup.uniqueComputers"
            (occurrencesButtonClickedEvent)="openInsightOccurrences(insightGroup)"
            (insightClickedEvent)="emitInsight(insightGroup.insights[0])"
            (anomalyTuningClickedEvent)="tuning.openAnomalyTuning($event)"
          ></sh-insight-timeline-item>
        </div>
      </div>
      <div *ngIf="viewPortItems?.length == 0 && insights?.length > 0">
        <sh-insights-timeline-no-items (onClearFilters)="filterComp.clearFilters(); clearFilters()"></sh-insights-timeline-no-items>
      </div>
    </sh-virtual-scroll>
  </div>

  <div *ngIf="showInsightOccurences" [@childsViewState]="childViewState" class="stack">
    <div class="search-container">
      <input type="text" style="display: inline-block" placeholder="Search insights" [(ngModel)]="childFilterText" />
      <i class="icon search"></i>
    </div>
    <div class="stack-items-container">
      <sh-virtual-scroll
        style="z-index:9;height: 100%;width: 100%;display: block"
        [childWidth]="250"
        [childHeight]="117"
        [items]="selectedInsightGroup.insights | InsightsTextFilter: childFilterText | insightsMetadataFilter: filters"
        (update)="selectedItemsViewPort = $event"
      >
        <div *ngFor="let insight of selectedItemsViewPort | InsightsTextFilter: childFilterText">
          <div>
            <sh-insight-timeline-item
              [insight]="insight"
              [isRoot]="false"
              (insightClickedEvent)="emitInsight(insight)"
              (anomalyTuningClickedEvent)="tuning.openAnomalyTuning($event)"
            ></sh-insight-timeline-item>
          </div>
        </div>

        <div *ngIf="selectedItemsViewPort?.length == 0 && selectedInsightGroup?.insights?.length > 0">
          <sh-insights-timeline-no-items (onClearFilters)="childFilterText = ''"></sh-insights-timeline-no-items>
        </div>
      </sh-virtual-scroll>
    </div>
  </div>
</div>

<sh-anomaly-tuning #tuning (onAnomalyUpdated)="updateAnomalies($event)"></sh-anomaly-tuning>

<ng-template #loadingTemplate>
  <div class="loading-spinner-container">
    <sh-widget-loader [addInvertedClass]="false"></sh-widget-loader>
  </div>
</ng-template>
