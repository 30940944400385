import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Constants } from '@app/constants';

@Component({
  selector: 'sh-team-name-control',
  templateUrl: './team-name-control.component.html',
  styleUrls: ['./team-name-control.component.scss'],
})
export class TeamNameControlComponent {
  @Input() public formGroup: FormGroup;
  @Input() public controlName: string;
  public appUrlEnding: string = Constants.getAppUrlEnding;
}
