<form class="user-login-form" fxFlex="100" [formGroup]="loginForm" fxLayout="column">
  <mat-form-field class="user-login-form__email">
    <input matInput placeholder="Username" formControlName="username" data-test="login-username-input" autocomplete="email" />
    <mat-error *ngIf="isFieldInvalid('username')">
      Please enter your username
    </mat-error>
  </mat-form-field>

  <mat-form-field class="user-login-form__password">
    <span class="user-login-form__forgot-password" [routerLink]="['/login/forgot']">
      Forgot Password?
    </span>
    <input
      data-test="login-password-input"
      matInput
      placeholder="Password"
      formControlName="password"
      autocomplete="current-password"
      [type]="hide ? 'password' : 'text'"
    />
    <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon class="login-password-visibility-icon">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="isFieldInvalid('password')">
      Please enter your password
    </mat-error>
  </mat-form-field>

  <re-captcha
    *ngIf="(recaptchaVersion$ | async) === 'v2'"
    #recaptchaRef="reCaptcha"
    (resolved)="onResolveRecaptchaV2($event)"
    [siteKey]="recaptchaV2SiteKey"
  ></re-captcha>

  <div class="action-buttons" fxLayout="column" fxLayoutAlign="space-between center">
    <cgx-button
      data-test="login-sign-in-btn"
      label="SIGN IN"
      color="green"
      size="lg"
      width="100%"
      style="width: 100%; margin-top: 20px;"
      [disabled]="!loginForm?.valid || loadingRecaptchaV3 || isLoading"
      [loading]="loadingRecaptchaV3 || isLoading"
      (press)="submit()"
    ></cgx-button>
    <div class="recaptcha-branding">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a>
      and
      <a href="https://policies.google.com/terms">Terms of Service</a>
      apply.
    </div>
    <div *ngIf="enableLoginWithSso" class="action-buttons__or-separator">
      Or
    </div>
    <a *ngIf="enableLoginWithSso" [routerLink]="['/ssologin']" class="sh-login-link-btn action-buttons__button">
      Login with sso
    </a>
  </div>
</form>
