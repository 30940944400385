import { Component, OnInit } from '@angular/core';
import { ClipboardService } from '@shared/services/clipboard-service';

@Component({
  selector: 'sh-reindex-access-permisions-info',
  templateUrl: './reindex-access-permisions-info.component.html',
  styleUrls: ['./reindex-access-permisions-info.component.scss']
})
export class ReindexAccessPermisionsInfoComponent implements OnInit {
  public coralogixAWSCanonical: string = 'fa35ef450b07d311b09810445df9c1c4a316118d1899e4cd3db935414e4ba62d';


  constructor(private clipboardService: ClipboardService) { }

  ngOnInit(): void {
  }

  copyCanonicalId(): void {
    this.clipboardService.copyToClipboard(this.coralogixAWSCanonical)
  }
}
