import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogQueryModel } from '../../../logs/shared/models/logsquery.model';
import { InsightsProvider } from '../../shared/services/insights.provider';
import { LogsService } from '../../../logs/shared/services/logs-service';
import { GridOptions } from 'ag-grid';
import { LogqueryModelTranslator } from '../../../logs/shared/translators/logquery-model.translator';
import { Observable, Subscription } from 'rxjs';
import { Alert } from '../../shared/models/alert.model';
import { AlertsService } from '../../../alerts/shared/services/alerts-service';
import { LogQueryData } from '../../../logs/shared/models/logquery.data';
import { formatsHelper } from '../../../shared/services/formatsHelper';
import { Insight } from '../../shared/models/insight.model';
import { InsightTypeId } from '../../shared/models/enums';
import { ChartClickParams } from '../../../shared/controls/charts/models/chart.model';
import * as _ from 'lodash';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { Store } from '@ngrx/store';
import { State as appState } from '@app/app.reducers';
import { LogActions } from '@app/logs/shared/state/log.actions';
import { InsightsHelper } from '@app/insights/shared/helpers/insightsHelper';
import { MutliLevelEsInfoHit } from '@app/logs/shared/models/multilevel-esinfo-hit';

@Component({
  selector: 'sh-group-by-alert',
  templateUrl: './group-by-alert.component.html',
  styleUrls: ['./group-by-alert.component.scss'],
})
export class GroupByAlertComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  public currentInsightsChangedSubscription: Subscription;
  public hitCountSelectedValue: string = '';
  public isEmptyGrid: boolean = false;
  public set queryData(data: LogQueryData) {
    this._queryData = data;
    this.store.dispatch(new LogActions.SetLogPanelQuery(data.logQueryModel));
  }
  public get queryData(): LogQueryData {
    return this._queryData;
  }
  private _queryData: LogQueryData;
  constructor(
    public insightsProvider: InsightsProvider,
    private logsService: LogsService,
    private alertsService: AlertsService,
    private htmlRowMeasure: HtmlRowMeasure,
    private store: Store<appState>
  ) {}

  public ngOnInit(): void {
    this.currentInsightsChangedSubscription = this.insightsProvider.currentInsightChanged.subscribe((insight) => {
      this.getLogs(insight);
    });
  }

  public ngOnDestroy(): void {
    if (this.currentInsightsChangedSubscription) {
      this.currentInsightsChangedSubscription.unsubscribe();
    }
  }

  public getAdvancedAlertQueryModel(alert: Alert): Observable<LogQueryModel> {
    return this.alertsService
      .getAlertDefenition(alert.alertId)
      .map((alertDef) => LogqueryModelTranslator.modelFromAlert(alert, alertDef));
  }

  public getRelativeAlertsQueryModel(alert: Alert): Observable<LogQueryModel[]> {
    return this.alertsService.getAlertDefenition(alert.alertId).map((alertDef) => {
      if (this.hitCountSelectedValue === '') {
        return [
          LogqueryModelTranslator.modelFromAlert(alert, alertDef),
          ...alertDef.relativeAlerts.map((ra) =>
            LogqueryModelTranslator.modelFromAlert(alert, Object.assign({}, alertDef, ra)),
          ),
        ];
      }
      const relativeAlert = alertDef.relativeAlerts.filter((r) => r.alias === this.hitCountSelectedValue);
      const relativeAlertDef = Object.assign({}, alertDef, relativeAlert.length > 0 ? relativeAlert[0] : {}, {
        relativeAlerts: [],
      });
      return [LogqueryModelTranslator.modelFromAlert(alert, relativeAlertDef)];
    });
  }

  public getLogs(insight: Insight): void {
    const alert = insight as Alert;

    if (this.gridOptions && this.gridOptions.api && alert.esInfo && !alert.esInfo.hasHits()) {
      this.gridOptions.api.showNoRowsOverlay();
      return;
    }

    if (this.insightsProvider.currentInsight.typeId === InsightTypeId.AlertRelative) {
      this.getRelativeAlertsQueryModel(alert)
        .first()
        .flatMap((queryModels) =>
          Observable.combineLatest(
            queryModels.map((qm) =>
              this.logsService
                .getLogs(qm)
                .switchMap((logQueryRes) => this.htmlRowMeasure.prepareRowsHeights(logQueryRes.logs).mapTo(logQueryRes))
                .map((lqr) => {
                  return { lqr, qm };
                }),
            ),
          ),
        )
        .subscribe((res) => {
          const uniqLogs = _.uniqBy(
            res.reduce((a, v) => {
              a.push(...v.lqr.logs);
              return a;
            }, []),
            (v) => v.logId,
          );
          this.setFiltersByQueryModel(res[0].qm);
          const queryResult = Object.assign({}, res[0].lqr, { logs: uniqLogs });
          this.assignIsEmptyGrid(!queryResult.total);
          this.queryData = new LogQueryData(
            res[0].qm,
            queryResult,
            this.logsService,
          );
        });
    } else {
      this.getAdvancedAlertQueryModel(alert)
        .first()
        .subscribe((queryModel) => {
          this.setFiltersByQueryModel(queryModel);
          this.logsService
            .getLogs(queryModel)
            .switchMap((logQueryRes) => this.htmlRowMeasure.prepareRowsHeights(logQueryRes.logs).mapTo(logQueryRes))
            .subscribe((res) => {
              this.assignIsEmptyGrid(!res.total);
              this.queryData = new LogQueryData(queryModel, res, this.logsService);
            });
        });
    }
  }

  public onGridReady(options: GridOptions): void {
    this.gridOptions = options;
    setTimeout(() => {
      const insight = this.insightsProvider.currentInsight;
      const alert = insight as Alert;

      if (this.gridOptions && this.gridOptions.api && alert && alert.esInfo && !alert.esInfo.hasHits()) {
        this.gridOptions.api.showNoRowsOverlay();
        return;
      }

      if (insight) {
        this.getLogs(insight);
      }
    }, 0);
  }

  public updateQueryLogCount(logCount: number): void {
    this.insightsProvider.globalState.queryResultCount = formatsHelper.numberWithCommas(logCount) + ' Logs';
  }

  public setFiltersByQueryModel(queryModel: LogQueryModel): void {
    const metadata = queryModel.queryParams.metadata;
    const filters = {};
    Object.keys(metadata).forEach((key) => {
      const filter = metadata[key];
      if (filter) {
        filters['metadata.' + key] = filter;
      }
    });
    if (queryModel.queryParams.query.text) {
      filters['text'] = queryModel.queryParams.query.text;
    }
    if (filters && this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setFilterModel(filters);
    }
  }

  public onSelectedRow(e: any): void {
    this.insightsProvider.globalState.selectedLog = e;
  }

  public onHitCountFilterSelected(clickParams: ChartClickParams): void {
    if (
      ![InsightTypeId.AlertGroupByFields, InsightTypeId.AlertRelative].includes(
        this.insightsProvider.currentInsight.typeId,
      )
    ) {
      return;
    }

    const selectedField = clickParams.chartId;
    const selectedValue = clickParams.name;
    const alert = _.cloneDeep(this.insightsProvider.currentInsight) as Alert;
    if (this.insightsProvider.currentInsight.typeId === InsightTypeId.AlertRelative) {
      const hits = {};
      hits[selectedValue] = alert.esInfo.hits[selectedValue];
      alert.esInfo.hits = hits;
      this.hitCountSelectedValue = selectedValue;
    } else if (InsightsHelper.isMultiLevelGroupBy(this.insightsProvider.currentInsight)) {
      const groupByFields = InsightsHelper.getMultiLevelGroupByFieldNames(alert);
      const groupByFieldValues = selectedValue.split(' / ');
      let lastAgg = {} as MutliLevelEsInfoHit;
      const hits = [ lastAgg ];
      groupByFields.forEach(
        (field, index) => {
          lastAgg[field] = groupByFieldValues[index];
          const newLastAgg = {} as MutliLevelEsInfoHit;
          if (index < groupByFields.length - 1) {
            lastAgg.nestedAggs = [newLastAgg];
            lastAgg = newLastAgg;
          }
        }
      );
      alert.esInfo.hits = hits;
      this.hitCountSelectedValue =
        groupByFields.map(
          (field, index) =>
            `${field}: ${groupByFieldValues[index]}`
        ).join(' / ');
    } else {
      const hits = {};
      hits[selectedField] = {};
      hits[selectedField][selectedValue] = _.get(alert.esInfo.hits, `${selectedField}['${selectedValue}']`);
      alert.esInfo.hits = hits;

      this.hitCountSelectedValue = `${selectedField}: ${selectedValue}`;
    }
    this.getLogs(alert);
  }

  public onHitCountFilterCleared(): void {
    this.hitCountSelectedValue = '';
    this.getLogs(this.insightsProvider.currentInsight as Alert);
  }

  public onGridButtonClick(event: any): void {
    this.insightsProvider.textInfoClicked.emit(event);
  }

  public textInfoClicked(value: any): void {
    this.insightsProvider.textInfoClicked.emit(value);
  }

  private assignIsEmptyGrid(isEmpty: boolean): void {
    this.isEmptyGrid = isEmpty;
    if (isEmpty) {
      this.updateQueryLogCount(0);
    }
  }
}
