import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Constants } from '@app/constants';

@Component({
  selector: 'sh-alerts-group-by',
  templateUrl: './alerts-group-by.component.html',
  styleUrls: ['./alerts-group-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsGroupByComponent implements OnInit, OnDestroy {
  get groupByFields(): FormArray {
    return this.formGroup.get('groupByFields') as FormArray;
  }

  get groupByLengthLimit(): number {
    return this.metricAlertGroupByLimit ?? Constants.DEFAULT_ALERT_GROUP_BY_LIMIT;
  }

  get showAddButton(): boolean {
    return this.groupByFields.length < this.groupByLengthLimit;
  }

  get isGroupByLengthValid(): boolean {
    return this.groupByFields.length <= this.groupByLengthLimit;
  }

  @Input() options: string[] = [];
  @Input() formGroup: FormGroup;
  @Input() metricAlertGroupByLimit: number;

  groupByControl: FormArray;

  constructor(private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.groupByControl?.setValidators(null);
  }

  ngOnInit(): void {
    this.setControlAndValidators();
  }

  removeGroupBy(i: number): void {
    this.groupByFields.removeAt(i);
    this.groupByControl.updateValueAndValidity();
  }

  addGroupBy(): void {
    this.groupByFields.push(this.fb.control('None'));
    this.groupByControl.updateValueAndValidity();
  }

  hasError(controlName: string, errorCode: string): boolean {
    const control = this.formGroup.get(controlName);
    return control.hasError(errorCode) && control.touched;
  }

  private setControlAndValidators(): void {
    this.groupByControl = this.formGroup?.get('groupByFields') as FormArray;

    this.groupByControl?.setValidators(this.groupByCountValidation);
    this.groupByControl?.updateValueAndValidity();
  }

  private groupByCountValidation: ValidatorFn = (_: AbstractControl): ValidationErrors | null => {
    return this.isGroupByLengthValid ? null : { groupByInvalidCount: true };
  };
}
