import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { SharedHtmlPath } from '@shared/consts';
import { FormControl } from '@angular/forms';
import { DatepickerOptions } from '@shared/controls/sh-ng-datepicker/ng-datepicker.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sh-coralogix-ng-date-picker',
  templateUrl: './coralogix-ng-date-picker.component.html',
  styleUrls: ['./coralogix-ng-date-picker.component.scss'],
})
export class CoralogixNgDatePickerComponent implements OnDestroy, AfterViewInit {
  public sharedAssetsPath: string = SharedHtmlPath;
  public timePickerSeen: boolean = false;
  public subs: Subscription[] = [];
  public formControl: FormControl = new FormControl();

  @Input() public dateOptions: DatepickerOptions;
  @Input() public hasError: boolean = false;
  @Input() public disableOpenEvent: boolean;
  @Input() public set initialValue(val: Date) {
    if (val) {
      this.formControl.setValue(val, { emitEvent: false });
    } else {
      this.formControl.setValue(new Date(), { emitEvent: false });
    }
  }
  @Output() public acceptTime: EventEmitter<Date> = new EventEmitter<Date>();

  public ngAfterViewInit(): void {
    this.subs.push(
      this.formControl.valueChanges.subscribe(() => {
        this.emitAcceptEvent();
      }),
    );
  }

  public displayTimePicker(): void {
    this.timePickerSeen = !this.timePickerSeen;
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  private emitAcceptEvent(): void {
    this.timePickerSeen = !this.timePickerSeen;
    this.acceptTime.emit(this.formControl.value);
  }
}
