import { NgModule } from '@angular/core';
import { RegexCheatSheetComponent } from '@shared/tutorials/regex-cheat-sheet/regex-cheat-sheet.component';
import { RegexCheatSheetSidebarComponent } from '@shared/tutorials/regex-cheat-sheet/components/sidebar/regex-cheat-sheet-sidebar.component';
import { RegexCheatSheetTableComponent } from '@shared/tutorials/regex-cheat-sheet/components/table/regex-cheat-sheet-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { RegexCheatSheetTableSectionComponent } from '@shared/tutorials/regex-cheat-sheet/components/table/components/regex-cheat-sheet-table-section.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PrestoCheatSheetComponent } from '@shared/tutorials/presto-cheat-sheet/presto-cheat-sheet.component';
import { MarkdownModule } from 'ngx-markdown';
import {CgxButtonModule} from "@coralogix/design";

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatDialogModule,
        FlexLayoutModule,
        MarkdownModule.forRoot(),
        CgxButtonModule
    ],
  declarations: [
    RegexCheatSheetComponent,
    RegexCheatSheetSidebarComponent,
    RegexCheatSheetTableComponent,
    RegexCheatSheetTableSectionComponent,
    PrestoCheatSheetComponent
  ],
  exports: [
    RegexCheatSheetComponent,
    PrestoCheatSheetComponent
  ],
  entryComponents: [
    RegexCheatSheetComponent,
    PrestoCheatSheetComponent
  ]
})
export class TutorialsModule {
}
