import JSONFormatter, { JSONFormatterConfiguration } from '@coralogix/json-formatter';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { checkAndParseJSON } from '@shared/utils/utils';

@Directive({
  selector: 'pretty-object',
})
export class PrettyObjectFormatterDirective implements OnInit {
  @Input() data: any;
  @Input() searchQuery: string;
  @Input() theme: 'dark' | null = null;
  @Input() open: number = 1;
  formatterConfig: JSONFormatterConfiguration;

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.formatterConfig = {
      theme: this.theme,
      hoverPreviewEnabled: true,
    };
    const data = checkAndParseJSON(this.data);
    this.renderPrettifiedObject(data);
  }

  renderPrettifiedObject(object: object | string): void {
    const formatter = new JSONFormatter(object, this.open, this.searchQuery, this.formatterConfig);
    this.elRef.nativeElement.appendChild(formatter.render());
  }
}
