import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { VirtualScrollComponent } from '../virtual-scroll/virtual-scroll.component';

@Component({
  selector: 'sh-select-menu',
  templateUrl: './virtual-select-menu.component.html',
  styleUrls: ['./virtual-select-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualSelectMenuComponent {
  @Input() public selectedValues: any[];
  @Input() public isOpen: boolean;
  @Input() set rows(val: any[]) {
    if (this.virtualScrollElm) {
      this.virtualScrollElm.scrollInto(this._rows[0]);
    }
    this._rows = val;
    if (this.virtualScrollElm) {
      this.virtualScrollElm.refresh();
    }
  }

  @Output() public noToggleClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(VirtualScrollComponent)
  public virtualScrollElm: VirtualScrollComponent;

  public _rows: any[];
  public viewPortItems: any[];

  public get scrollHeight(): string {
    return Math.min(290, 38 * this._rows.length) + 'px';
  }

  public toggleSelected(row: any): void {
    this.noToggleClick.emit(row);
  }

  public onVirtualScrollUpdate(items: any[]): void {
    this.viewPortItems = items;
  }
}
