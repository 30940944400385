import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordFormProvider } from '../settings/account/containers/change-password/change-password-form-provider';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from '@shared/shared.module';
import { SalesForceFakeFormComponent } from './sales-force-fake-form/sales-force-fake-form.component';
import { CommonModule } from '@angular/common';
import { HerokuSsoComponent } from './sso/heroku-sso/heroku-sso.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { LoginTeamsComponent } from './login/login-teams/login-teams.component';
import { CreateTeamComponent } from './login/create-team/create-team.component';
import { TeamSsoComponent } from './sso/team-sso/team-sso.component';
import { SamlSsoComponent } from './sso/saml-sso/saml-sso.component';
import { LoginTeamComponent } from './login/login-team/login-team.component';
import { LoginStatusComponent } from './login/login-status/login-status.component';
import { FlexModule } from '@angular/flex-layout';
import { AuthHeaderComponent } from '@app/auth/shared/components/auth-header/auth-header.component';
import { TeamNameControlComponent } from './shared/components/team-name-control/team-name-control.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';
import { Constants } from '@app/constants';
import { SignUpComponent } from '@app/auth/signup/sign-up.component';
import { ChangePassControlModule } from '@app/shared/controls/change-pass-control/change-pass-control.module';
import { CgxButtonModule } from '@coralogix/design';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    SalesForceFakeFormComponent,
    HerokuSsoComponent,
    LoginFormComponent,
    LoginTeamsComponent,
    CreateTeamComponent,
    TeamSsoComponent,
    SamlSsoComponent,
    LoginTeamComponent,
    LoginStatusComponent,
    AuthHeaderComponent,
    TeamNameControlComponent,
    AuthComponent,
    SignUpComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    FlexModule,
    ChangePassControlModule,
    RecaptchaModule,
    CgxButtonModule,
    RecaptchaV3Module,
  ],
  providers: [ChangePasswordFormProvider, { provide: RECAPTCHA_V3_SITE_KEY, useValue: Constants.GetRecaptchaV3SiteKey() }],
})
export class AuthModule {}
