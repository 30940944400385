<mat-expansion-panel (afterExpand)="afterExpand()" [disabled]="disabled" [(expanded)]="isOpened" class="coralogix">
  <mat-expansion-panel-header>
    <mat-panel-title>Notification Content</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="content">
    <p class="mat-expansion-panel-subheader">
      Choose specific JSON keys to include in the alert notification, or leave this blank to include the full log text in the alert message
    </p>
    <form [formGroup]="form">
      <div class="coralogix input-group">
        <label class="input-label">Include Only These JSON Fields</label>
        <sh-coralogix-chips-input
          data-test="alerts-json-field-input"
          [placeholder]="'Add JSON keys'"
          formControlName="notificationPayloadFilter"
        ></sh-coralogix-chips-input>
      </div>
    </form>
  </div>
</mat-expansion-panel>
