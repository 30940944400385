import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reindex } from '@app/roi/reindex/models/reindex.model';
import * as moment from 'moment';

export enum ReindexStatus {
  ingesting = 1,
  finished = 2,
  failed = 3
}

@Component({
  selector: 'sh-reindex-table-item',
  templateUrl: './reindex-table-item.component.html',
  styleUrls: ['./reindex-table-item.component.scss']
})
export class ReindexTableItemComponent implements OnInit {
  @Input()
  set reindex(reindex: Reindex) {
    this._reindex = reindex;
    this.reindexStatus = this.getStatus(reindex.status);
  }

  get reindex(): Reindex {
    return this._reindex;
  }

  public reindexStatusEnum = ReindexStatus;
  public reindexStatus: ReindexStatus;

  @Output()
  public onRetry: EventEmitter<Reindex> = new EventEmitter<Reindex>();

  @Output()
  public onRedirectToLogs: EventEmitter<Reindex> = new EventEmitter<Reindex>();

  private _reindex: Reindex;

  constructor() { }

  public ngOnInit(): void {
  }

  public get imgSrc(): string {
     switch (this.reindexStatus) {
       case ReindexStatus.ingesting:
         return '../../../../../assets/roi/icon-reindex-ingesting.svg';
       case ReindexStatus.finished:
         return '../../../../../assets/roi/icon-reindex-completed.svg';
       default:
         return '../../../../../assets/roi/icon-reindex-failed.svg';
     }
  }

  public get reindexProgressPercent() {
    return this.reindex.progressPercent;
  }

  public getButtonText(reindex: Reindex): string {
    if (reindex.status === 'Finished') {
      return 'VIEW LOGS';
    } else if (reindex.status === 'Ingesting') {
      return 'REINDEXING...';
    }
    return 'RETRY';
  }

  public getStatus(status: string): number {
    if (status === 'Finished') {
      return ReindexStatus.finished;
    } else if (status === 'Ingesting') {
      return ReindexStatus.ingesting;
    }
    return ReindexStatus.failed;
  }

  public getReindexCreateDate(): string {
    return moment(this.reindex.createDate).format('LLLL');
  }

  public onClick(): void {
    if (this.reindexStatus === ReindexStatus.finished) {
      this.onRedirectToLogs.emit(this.reindex);
    } else if (this.reindexStatus === ReindexStatus.failed) {
      this.onRetry.emit(this.reindex);
    }
  }
}
