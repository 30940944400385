import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ShDialogService } from '@shared/services/dialogService';
import { UUID } from 'angular2-uuid';
import { LogsStatsService } from '@app/logs-new/shared/services/logs-stats.service';
import { MatDialog } from '@angular/material/dialog';
import {
  getFullJSONPath,
  getJsonFormattedElementType,
  JSON_FORMATTER_ACTIVE_CLASS,
  JSON_FORMATTER_KEY_CLASS,
  JSON_FORMATTER_TEMPLATE_CLASS,
  JSON_FORMATTER_VALUE_CLASS,
  removeTooltipElement,
  setJsonFormattedElementData,
} from '@shared/modules/json-formatter/json-formatter.utils';
import { trigger } from '@angular/animations';
import { inOutAnimation } from '@app/logs-new/shared/utils/animations';
import { copyToClipboard, nextTick } from '@shared/utils/utils';
import { PrettyJsonDialogComponent } from '@app/logs-new/shared/features/pretty-json-dialog/pretty-json-dialog.component';
import { IContextMenu, IJsonElements, ILatestClickedElement } from '@shared/modules/json-formatter/json-formatter.model';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { templateType } from '@app/logs-new/shared/models/template.model';
import cloneDeep from 'lodash-es/cloneDeep';
import { ThemeService } from '@app/logs-new/shared/services/theme.service';
import { ISearchQueryOptions } from '@app/logs-new/shared/models/query-search-options';
import { MAT_PAGINATOR_CLASS } from '@app/logs-new/shared/constants/paginator.constant';
import { ShowTimeComponent } from '@app/logs-new/shared/features/new-json-formatter/json-formatter-menu/show-time/show-time.component';
import { LogsDialogService } from '@app/logs-new/shared/services/logs-dialog.service';
import { LogsContent } from '@app/logs-new/shared/models/logs-content.model';
import { Plugin } from '@app/logs-new/components/plugins-container/model/plugin.model.ts';
import { LogsNewGridComponent } from '../../logs-new-grid/logs-new-grid.component';
import { CoralogixSidebarComponent } from '@app/shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { INunjucksToken, Nunjucks } from '@app/shared/models/nunjucks';
import { ILog } from '@app/logs-new/shared/models/logs-data.model';
import { get } from 'lodash-es';
import { dialogServiceIconClasses } from '@shared/models/dialog-service.models';
import { PluginsContainerComponent } from '@app/logs-new/components/plugins-container/plugins-container.component';

@Component({
  selector: 'sh-json-formatter-menu',
  templateUrl: './json-formatter-menu.component.html',
  styleUrls: ['./json-formatter-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('inOutAnimation', inOutAnimation)],
})
export class JsonFormatterMenuComponent implements OnDestroy, AfterViewInit {
  @Input() params: any;
  @Input() elRef: ElementRef;
  @ViewChild('contextMenuEl') contextMenuEl: ElementRef;
  @ViewChild('sidebarComponent', { static: true }) sidebar: CoralogixSidebarComponent;
  @ViewChild('pluginsContainer', { static: true }) pluginsContainer: PluginsContainerComponent;
  showContextMenu$ = new BehaviorSubject<boolean>(false);
  contextMenu = {} as IContextMenu;
  latestClickedElement: ILatestClickedElement;
  privatePlugins: Plugin[] = [];
  sharedPlugins: Plugin[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private dialogService: ShDialogService,
    private statsService: LogsStatsService,
    private dialog: MatDialog,
    public themeService: ThemeService,
    private cdr: ChangeDetectorRef,
    private logsDialogService: LogsDialogService,
  ) {}

  get log(): ILog {
    return this.params.data.textObject;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.setAllListeners();
    this.handleGridScroll();
  }

  public addToFilterList(): void {
    this.params.context.parent.addQueryFieldEvent.emit({
      colField: this.latestElementJsonPath,
      filterType: 'jsonObject',
    });
    this.closeMenu();
  }

  public addToSearchQuery({ toInclude, isKey = false, queryValueAlone = false }: ISearchQueryOptions): void {
    this.params.context.parent.addToSearchQueryEvent.emit({
      payload: this.latestClickedElement.selectedField,
      toInclude,
      isKey,
      jsonPath: this.latestElementJsonPath,
      queryValueAlone,
    });
    this.closeMenu();
  }

  addAsColumn(): void {
    const MAX_NUM_OF_COLUMNS = 20;
    this.closeMenu();
    const colsState = this.params.columnApi.getColumnState();
    if (colsState.length + 1 === MAX_NUM_OF_COLUMNS) {
      this.dialogService.showErrorMessage('Can not add more than 20 columns');
      return;
    }
    const colExists = colsState.some(col => col.colId === `textObject.${this.latestElementJsonPath}`);
    if (colExists) {
      this.dialogService.showErrorMessage('Column already exists');
      return;
    }

    this.params.context.parent.loadingQueryEvent.emit(true);
    nextTick(() => {
      this.params.context.parent.addLogsColumnEvent.emit({
        payload: this.latestElementJsonPath,
        correlationId: UUID.UUID(),
      });
      this.dialogService.showCoralogixMessage('Column added successfully');
      this.params.context.parent.loadingQueryEvent.emit(false);
    });
  }

  addAsFilter(): void {
    this.params.context.parent.addSelectedQueryFieldEvent.emit({
      payload: { key: this.latestElementJsonPath, value: this.latestClickedElement.valueField },
    });
    this.closeMenu();
  }

  showGraphForKey(): void {
    const field = this.latestClickedElement.selectedField;
    const chartData$ = this.statsService.getKeyAggregationStats(this.latestElementJsonPath, true);
    this.logsDialogService.openFieldVisualizationDialog({
      title: this.latestElementJsonPath || field,
      chartData$,
      jsonPath: this.latestElementJsonPath,
    });
    this.closeMenu();
  }

  closeMenu(): void {
    const menuIsOpen = this.latestClickedElement && this.showContextMenu$.getValue();
    if (menuIsOpen) {
      this.latestClickedElement.classList.remove(JSON_FORMATTER_ACTIVE_CLASS);
      this.showContextMenu$.next(false);
    }
  }

  copyCompleteJsonPath(): void {
    copyToClipboard(this.latestElementJsonPath);
    this.dialogService.showCoralogixMessage('Path copied to clipboard');
    this.closeMenu();
  }

  viewValueAsJSON(): void {
    const keyElement = this.latestClickedElement.parentElement.children[IJsonElements.key];
    this.dialog.open(PrettyJsonDialogComponent, {
      panelClass: 'pretty-json',
      data: {
        stringToPrettify: this.latestClickedElement.rawField,
        fullJSONPath: getFullJSONPath(keyElement as HTMLElement),
        fieldName: this.latestClickedElement.keyField,
      },
    });
    this.closeMenu();
  }

  copyValueToClipboard(): void {
    copyToClipboard(this.latestClickedElement.selectedField);
    this.dialogService.showCoralogixMessage('Value copied to clipboard');
    this.closeMenu();
  }

  openURL(): void {
    let URL = this.latestClickedElement.selectedField;
    URL = URL.match(/^https?:/) ? URL : '//' + URL;
    window.open(URL, '_blank');
    this.closeMenu();
  }

  showTime(): void {
    this.dialog.open(ShowTimeComponent, {
      panelClass: 'pretty-json',
      data: {
        strDate: this.latestClickedElement.selectedField,
      },
    });
    this.closeMenu();
  }

  searchPrivatePluginChange(searchValue: string = ''): void {
    const pluginList: Plugin[] = this?.params?.context?.parent?.privatePlugins ?? [];

    this.privatePlugins = this.searchBasedPluginFilter(pluginList, searchValue);
  }

  searchSharedPluginChange(searchValue: string = ''): void {
    const pluginList: Plugin[] = this?.params?.context?.parent?.sharedPlugins ?? [];

    this.sharedPlugins = this.searchBasedPluginFilter(pluginList, searchValue);
  }

  searchBasedPluginFilter(pluginList: Plugin[], searchValue: string): Plugin[] {
    return pluginList.filter(plugin => {
      if (plugin.applicationNames.length && !plugin.applicationNames.includes(this.params.data.metadata.applicationName)) {
        return false;
      }

      if (plugin.subsystemNames.length && !plugin.subsystemNames.includes(this.params.data.metadata.subsystemName)) {
        return false;
      }

      return plugin.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }

  checkFieldInTokenList(plugin: Plugin): boolean {
    const urlTokenList: INunjucksToken[] = Nunjucks.tokenList(plugin.url);
    let isLogFieldExists = false;
    for (let i = 0; i < urlTokenList.length; i++) {
      const key = `$d.${this.latestElementJsonPath}`;
      if (plugin.url.substring(urlTokenList[i].start, urlTokenList[i].end) === key) {
        isLogFieldExists = true;
        break;
      }
    }
    return isLogFieldExists;
  }

  selectPlugin(plugin: Plugin): void {
    const parent: LogsNewGridComponent = this.params.context.parent;
    const selectedValue = this.latestElementJsonPath;
    parent.logsQuery$.pipe(take(1)).subscribe(logsQuery => {
      const $p = {
        start_date: logsQuery.startDate,
        end_date: logsQuery.endDate,
        selected_value: this.latestClickedElement.isKey ? get(this.log, selectedValue) : this.latestClickedElement.selectedField,
      };
      const $m = { ...this.params.data.metadata, timestamp: this.params.data.timestamp };
      const context = {
        $d: this.log,
        $m,
        $p,
      };

      try {
        const url = Nunjucks.renderString(plugin.url, context);
        window.open(url, '_blank');
      } catch (err) {
        const toastError = err.message.substring(err.message.indexOf('Column') + 7, err.message.indexOf(']'));
        this.dialogService.showCoralogixMessage(
          `ERROR: Expected variable end at column ${toastError}`,
          null,
          dialogServiceIconClasses.failed,
        );
      }
      this.closeMenu();
      this.cdr.markForCheck();
    });
  }

  onShowSearch(): void {
    this.searchPrivatePluginChange();
    this.searchSharedPluginChange();
  }

  openPluginsContainer(): void {
    this.closeMenu();
    this.pluginsContainer.openPluginsEditor();
  }

  private setAllListeners(): void {
    this.params.context.parent.activeTab$.pipe(take(1)).subscribe(activeTab => {
      const isTemplates = activeTab === LogsContent.templates;
      if (isTemplates) {
        this.elRef.nativeElement.querySelectorAll(`.${JSON_FORMATTER_TEMPLATE_CLASS}`).forEach((element: HTMLElement) => {
          this.addTemplateValueClickListener(element);
        });
      } else {
        this.elRef.nativeElement.querySelectorAll(`.${JSON_FORMATTER_VALUE_CLASS}`).forEach(element => {
          this.setRightClickListeners(element);
          this.setLeftClickListeners(element);
          this.setSelectionEndListener(element);
        });
        this.elRef.nativeElement.querySelectorAll(`.${JSON_FORMATTER_KEY_CLASS}`).forEach(element => {
          this.setLeftClickListeners(element);
          this.setRightClickListeners(element);
        });
      }
    });
  }

  private setSelectionEndListener(element: HTMLElement): void {
    fromEvent(element, 'pointerup')
      .pipe(
        filter(() => !!window.getSelection().toString()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(evt => {
        this.onMenuOpen(evt);
      });
  }

  private addTemplateValueClickListener(element: HTMLElement): void {
    fromEvent(element, 'click')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(evt => {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        const el = evt.target as ILatestClickedElement;
        this.openTemplateDialog(el);
      });
  }

  private openTemplateDialog(element: ILatestClickedElement): void {
    const logQuery$ = this.params.context.parent.logsQuery$;
    const templateId = this.params.data.id;
    const logType = element.getAttribute('data-templatetype') as templateType;
    const jsonPath = element.getAttribute('data-templatepath') || getFullJSONPath(element);
    const paramId = element.getAttribute('data-paramId');
    logQuery$.pipe(take(1)).subscribe(logQuery => {
      const clonedQuery = cloneDeep(logQuery);
      clonedQuery.queryParams.query.templateIds = [templateId];
      clonedQuery['paramId'] = paramId;
      if (logType === 'json') {
        clonedQuery.queryParams.aggField = jsonPath;
        clonedQuery.type = 'templateIds';
      } else {
        clonedQuery.queryParams.aggField = null;
      }
      if (clonedQuery['isV2']) delete clonedQuery.V2;
      if (clonedQuery.graphs) delete clonedQuery.graphs;
      const statsReq$ =
        logType === 'json'
          ? this.statsService.getTemplateJsonStats(clonedQuery)
          : this.statsService.getTemplateCategoricalStats(clonedQuery);
      this.logsDialogService.openFieldVisualizationDialog({
        title: jsonPath || element.innerText,
        chartData$: statsReq$,
        jsonPath,
      });
    });
  }

  private setRightClickListeners(el: Element): void {
    fromEvent(el, 'contextmenu')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(evt => {
        this.onMenuOpen(evt);
      });
  }

  private setLeftClickListeners(el: Element): void {
    fromEvent(el, 'click')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(evt => {
        this.onMenuOpen(evt);
      });
  }

  private onMenuOpen(evt: Event): void {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    removeTooltipElement();
    this.showContextMenu$.next(false);
    if (this.latestClickedElement) {
      this.latestClickedElement.classList.remove(JSON_FORMATTER_ACTIVE_CLASS);
    }

    const element = evt.target as ILatestClickedElement;
    element.classList.add(JSON_FORMATTER_ACTIVE_CLASS);
    this.contextMenu.selection = window.getSelection().toString();
    if (this.contextMenu.selection) {
      this.contextMenu.type = 'selection';
    }
    this.latestClickedElement = setJsonFormattedElementData(element, this.params, this.contextMenu.selection);
    this.contextMenu.type = getJsonFormattedElementType(element, this.contextMenu.selection);
    this.renderMenu(evt);
  }

  private renderMenu(evt: Event): void {
    this.contextMenu.style = { visibility: 'hidden' };
    this.showContextMenu$.next(true);
    this.cdr.detectChanges();
    const { height, width } = this.contextMenuEl.nativeElement.getBoundingClientRect();
    const buffer = 20;
    const paginator = document.querySelector(MAT_PAGINATOR_CLASS) as HTMLElement;
    const bottomHeightLeft = window.innerHeight - paginator.offsetHeight - evt['pageY'];
    const rightWidthLeft = window.innerWidth - evt['pageX'];
    const isMenuOverflowingFromBottom = bottomHeightLeft <= height + buffer;
    const isMenuOverflowingFromRight = rightWidthLeft <= width + buffer;
    this.contextMenu.style = {
      top: evt['pageY'] + 'px',
      left: evt['pageX'] + 'px',
      marginTop: isMenuOverflowingFromBottom ? `-${height + 16}px` : '16px',
      marginLeft: isMenuOverflowingFromRight ? `-${width - rightWidthLeft}px` : `-${width / 3}px`,
      height: `${height}px`,
      width: `${width}px`,
      visibility: 'visible',
    };
    this.cdr.detectChanges();
  }

  private handleGridScroll(): void {
    const scrollArr = [];
    const GRID_CONTAINER = document.querySelector('.ag-body-viewport');
    const HORIZONTAL_SCROLL_EL = document.querySelector('.ag-body-horizontal-scroll-viewport');
    if (GRID_CONTAINER) scrollArr.push(GRID_CONTAINER);
    if (HORIZONTAL_SCROLL_EL) scrollArr.push(HORIZONTAL_SCROLL_EL);
    fromEvent(scrollArr, 'scroll')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.showContextMenu$.getValue()) {
          this.closeMenu();
        }
      });
  }

  private get latestElementJsonPath(): string {
    return getFullJSONPath(this.latestClickedElement, this.params?.column?.colId);
  }
}
