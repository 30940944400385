import { TypedJSON } from 'typedjson';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../constants';
import { Grok, GrokDefsResponse } from '../models/grok.model';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class GrokDefsService {
  constructor(private http: HttpClient) {}

  public getGrokDefs(): Observable<Grok[]> {
    return this.http
      .get(`${Constants.grokDefsUrl}`)
      .map((res: any) => new TypedJSON(GrokDefsResponse).parse(res))
      .map((groksResponse: GrokDefsResponse) => groksResponse.groks);
  }
}
