import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Build } from '../../models/build';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { Store } from '@ngrx/store';
import { getSelectedTag, getTagsLoading, State } from '@app/app.reducers';
import { TagsActions } from '../../shared/actions/tag.actions';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { UserSettings } from '@app/user/shared/user.settings';
import { DeploymentStoreService } from '../../shared/services/deployment.store.service';
import { tagsAssetsUrl } from '@app/deployment/models/welcome.consts';
import {
  DefaultCompareEnd,
  ETagsRoutes,
  getTagRedirectUrl,
  newTagFromWelcome,
  TagsBaseUrl,
} from '@app/deployment/shared/consts/tags.consts';
import { CoralogixConfirmationDialogComponent } from '@shared/popups/coralogix-dialog/coralogix-confirmation-dialog.component';
import { FormControl } from '@angular/forms';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { Entity } from '@shared/models/entity.model';
import { ISelectedTagData } from '@app/deployment/shared/models/view.models';
import { TagPanelContainerComponent } from '@app/deployment/containers/tag-panel-container/tag-panel-container.component';
import { EditorModes } from '@app/deployment/shared/consts/tag-panel.enums';
import { MetadataActions } from '@shared/state/actions/metadata.actions';
import * as alertActions from '@app/alerts/shared/actions/alerts.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetCompanyPlanDetails } from '@app/ngxs-store/company/company.action';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { subDays } from 'date-fns';
import { isNull } from 'lodash';
@Component({
  selector: 'sh-tags-main-layout',
  templateUrl: 'tags-main-layout.component.html',
  styleUrls: ['tags-main-layout.component.scss'],
})
export class TagsMainLayoutComponent implements OnInit, OnDestroy {
  @SelectSnapshot(CompanyState.retention) retention: number;

  loading$ = this.store.select(getTagsLoading);

  tagsList$: Observable<Build[]>;

  addTagBtnIconUrl: string = `${tagsAssetsUrl}/icon-tag.svg`;

  tagToCompareCtrl: FormControl = new FormControl('');

  isSelectAllSubsystemsCtrl: FormControl = new FormControl(false);

  tagsByAppAndSubSystem$;

  selectedTag: Build;

  selectedTag$ = this.store.select(getSelectedTag);

  compareTagCtrlList: Entity[];

  @ViewChild('tagPanel', { static: true }) tagPanelComponent: TagPanelContainerComponent;

  penalModes: typeof EditorModes = EditorModes;

  protected destroy$: Subject<void> = new Subject<void>();

  private tagsList: Build[];

  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private router: Router,
    private userSettingsProvider: UserSettingsProvider,
    private tagsStoreService: DeploymentStoreService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.getCompanyPlanDetails();
    this.selectedTagSubscription();
    this.userSettingsSubscription();
    this.compareTagSubscription();
    this.setRouteSubscription();
    this.setSubsystemsCtrlSubscription();
    this.store.dispatch(alertActions.getAlertsAction());
  }

  get headerTitle(): string {
    return !!this.selectedTag ? '' : 'Manage Tags';
  }

  tagSelected(selectedTagData: ISelectedTagData): void {
    const { compareTagId, selectedTagId } = selectedTagData;
    const compareRoute = !!compareTagId ? compareTagId : DefaultCompareEnd;
    const redirectRoute = getTagRedirectUrl(selectedTagId, compareRoute);
    this.router.navigate([redirectRoute]).finally();
  }

  selectTagToCompare(): void {
    const paramChildren = this.activatedRoute.snapshot.children;
    const compareTagId = paramChildren.length ? paramChildren[0].params?.compareTagId : null;
    const compareBuild = this.tagsList?.find(tag => tag.id === Number(compareTagId));
    if (!!compareBuild && this.tagToCompareCtrl.value !== Number(compareTagId)) {
      this.tagToCompareCtrl.setValue(Number(compareTagId));
      this.store.dispatch(new TagsActions.SelectTagToCompare(compareBuild));
    } else {
      this.tagToCompareCtrl.setValue(null, { emitEvent: false });
    }
  }

  deleteTag(build: Build): void {
    this.openDeleteDialog('Tag', build.text_value)
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.store.dispatch(new TagsActions.DeleteTagAction(build.id));
        }
      });
  }

  redirectToMainTagsRoute(): void {
    this.router.navigate([TagsBaseUrl]).finally();
  }

  openDeleteDialog(type: string, itemName: string): Observable<any> {
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = {
      title: `Delete ${type}`,
      message: `You are about to delete ${type} "${itemName}", are you sure you want to proceed?`,
      yesButtonText: 'DELETE',
      noButtonText: 'CANCEL',
    };

    const dialogRef = this.dialog.open(CoralogixConfirmationDialogComponent, config);
    return dialogRef.afterClosed();
  }

  changeFilterBySubsystem(value: boolean): void {
    this.store.dispatch(new TagsActions.ChangeTagFilterBySubsystemAction(value));
  }

  ngOnInit(): void {
    const model: LogQueryModel = new LogQueryModel();
    model.endDate = null;
    model.startDate = null;
    this.store.dispatch(new TagsActions.GetTagsAction(model));
    this.store.dispatch(new MetadataActions.GetApplicationNameAction(null));
    this.store.dispatch(new MetadataActions.GetSubsystemNameAction(null));
  }

  ngOnDestroy(): void {
    this.router.onSameUrlNavigation = 'ignore';
    this.destroy$.next();
    this.destroy$.complete();
  }

  openEditorPanel(mode: EditorModes, tagId?: number, initialValues?: Build): void {
    if (initialValues) {
      this.tagPanelComponent.openEditor(mode, initialValues);
    } else {
      this.tagsList$
        .pipe(
          filter(list => Array.isArray(list)),
          take(1),
          tap(list => {
            if (mode === EditorModes.create) {
              const compareId = list[0]?.id ? list[0]?.id : DefaultCompareEnd;
              this.tagPanelComponent.openEditor(mode, null, compareId);
            } else if (tagId && list.length > 0) {
              initialValues = list.find(tag => tag.id === Number(tagId));
              this.tagPanelComponent.openEditor(mode, initialValues);
            }
          }),
        )
        .subscribe();
    }
  }

  private userSettingsSubscription(): void {
    this.userSettingsProvider.userSettingsChanged.pipe(takeUntil(this.destroy$)).subscribe((settings: UserSettings) => {
      this.tagsList$ = this.getFilteredTagsList(settings.queryMetadata.applicationName, settings.queryMetadata.subsystemName);

      this.updateCompareTagCtrlList(settings.queryMetadata.applicationName, settings.queryMetadata.subsystemName);
    });
  }

  private updateCompareTagCtrlList(applicationNames: string[], subsystemNames: string[]): void {
    if (!this.selectedTag) {
      this.compareTagCtrlList = [];
      return;
    }

    const comparableTagList = this.selectedTag.getComparableTagList(
      this.tagsList,
      applicationNames,
      subsystemNames,
      this.isSelectAllSubsystemsCtrl.value,
    );
    const [olderTagList, newerTagList] = this.selectedTag.olderNewerTagLists(comparableTagList);

    this.compareTagCtrlList = olderTagList.map(build => new Entity({ id: build.id, name: build.text_value }));
  }

  private selectedTagSubscription(): void {
    this.selectedTag$
      .pipe(
        takeUntil(this.destroy$),
        tap(tag => {
          const selectedApplications = this.userSettingsProvider.userSettings.queryMetadata.applicationName;
          const selectedSubsystems = this.userSettingsProvider.userSettings.queryMetadata.subsystemName;
          this.selectedTag = tag;
          this.tagsList$ = this.getFilteredTagsList(selectedApplications, selectedSubsystems);
          this.updateCompareTagCtrlList(selectedApplications, selectedSubsystems);
        }),
      )
      .subscribe();
  }

  private getFilteredTagsList(selectedApplications: string[], selectedSubsystems: string[]): Observable<Build[]> {
    return this.tagsStoreService.filterTagsByApplicationAndSubsystem(selectedApplications, selectedSubsystems).pipe(
      tap(builds => {
        this.tagsList = builds;
      }),
    );
  }

  private compareTagSubscription(): void {
    this.tagToCompareCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const paramChildren = this.activatedRoute.snapshot.children;
      const compareTagId = paramChildren.length ? Number(paramChildren[0].params?.compareTagId) : null;
      if (Number(value) !== Number(compareTagId)) {
        const selectedTagId = paramChildren.length ? paramChildren[0].params?.tagId : null;
        this.tagSelected({ compareTagId: value, selectedTagId });
        if (!Number.isNaN(value) && this.tagsList?.length) {
          this.selectTagToCompare();
        } else {
          this.setSelectTagToCompareByList();
        }
      }
    });
  }

  private setSelectTagToCompareByList(): void {
    combineLatest([this.selectedTag$, this.tagsList$])
      .pipe(
        filter(([selected, list]) => !!list?.length && !!selected),
        take(1),
      )
      .subscribe(([selected, list]) => {
        this.tagsList = list;
        this.selectTagToCompare();
      });
  }

  private setSubsystemsCtrlSubscription(): void {
    this.isSelectAllSubsystemsCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      const urlArr = this.router.url.split('/');
      const delFrom = urlArr.findIndex(str => ETagsRoutes.allSubsystems === str);
      urlArr.splice(delFrom, urlArr.length - 1);
      window.location.assign(`#${urlArr.join('/')}/${ETagsRoutes.allSubsystems}/${val}`);
    });
  }

  private setRouteSubscription(): void {
    this.router.onSameUrlNavigation = 'reload';

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const paramChildren = this.activatedRoute.snapshot.children;
      const { tagId, compareTagId, editTagId, isAllSubsystems } = paramChildren?.length ? paramChildren[0]?.params : null;
      const routerUrl = this.router.url;
      const hasBaseUrl = routerUrl.includes(TagsBaseUrl);
      const includesNew = routerUrl.includes(ETagsRoutes.new);
      const includesEdit = routerUrl.includes(ETagsRoutes.edit);
      // welcome
      if (hasBaseUrl && !tagId && !compareTagId) {
        this.store.dispatch(new TagsActions.SelectTagsAction(null));
        if (routerUrl.includes(newTagFromWelcome)) {
          // welcome - new tag
          this.openEditorPanel(EditorModes.create);
        }
        if (editTagId) {
          this.openEditorPanel(EditorModes.edit, editTagId);
        }
      } else if (hasBaseUrl && tagId && compareTagId) {
        // tag compare
        this.compareTagsRoutingLogic(compareTagId, isAllSubsystems, includesNew, includesEdit, editTagId);
      }
    });
  }

  private compareTagsRoutingLogic(
    compareTagId: string,
    isAllSubsystems: string,
    includesNew: boolean,
    includesEdit: boolean,
    editTagId: number,
  ): void {
    if (compareTagId !== DefaultCompareEnd) {
      this.setSelectTagToCompareByList();
    } else if (this.tagsList?.length && compareTagId !== DefaultCompareEnd) {
      this.selectTagToCompare();
    }

    if (!isNull(isAllSubsystems)) {
      const isTrue = isAllSubsystems.toLowerCase() === 'true';
      if (this.isSelectAllSubsystemsCtrl.value !== isTrue) {
        this.isSelectAllSubsystemsCtrl.setValue(isTrue);
      }
      this.changeFilterBySubsystem(isTrue);
    }

    this.store.dispatch(new TagsActions.SetTagNCompareBasePayloadAction(null));

    if (includesNew) {
      this.openEditorPanel(EditorModes.create);
      // tag compare - new tag
    } else if (includesEdit) {
      this.openEditorPanel(EditorModes.edit, editTagId);
      // tag compare -  edit tag
    }
  }

  private filterBuildByMinRetentionDate(builds: Build[]): Build[] {
    const retentionMinDate = subDays(new Date(), this.retention).valueOf();
    return builds.filter(build => (retentionMinDate ? build?.tag_timestamp > retentionMinDate : false));
  }

  @Dispatch() private getCompanyPlanDetails = () => new GetCompanyPlanDetails();
}
