<div class="widgets container">
  <div class="ui grid layout">
    <div class="row">
      <!-- TL column -->
      <div class="ui four wide column timeline-container">
        <div class="timeline-panel">
          <sh-insights-timeline
            [isLoading]="isLoadingInsights"
            [insights]="insights"
            (onInsightClicked)="oninsightclicked($event)"
            (onUpdatedAnomalies)="updateAnomalies($event)"
          ></sh-insights-timeline>
        </div>
      </div>

      <!-- cards column -->
      <div class="ui twelve wide column graphs-container">
        <!-- cards grid -->
        <div>
          <div class="ui equal width grid">
            <div class="row large live-tail">
              <sh-livetail-stats [manager]="livetailStatsManager"></sh-livetail-stats>
            </div>
            <div class="row">
              <!-- card -->
              <div *ngFor="let widget of defaultWidgets" class="column">
                <div class="dashboard-card">
                  <div class="dashboard-card-header">
                    <div class="description">
                      {{ widget.description }}
                    </div>
                  </div>
                  <sh-statistics-chart
                    [infoBarClass]="'chart-infoBar dark '"
                    [useAnimation]="true"
                    [clickable]="true"
                    [queryDefinition]="widget.statisticsQueryDefinition"
                    [enableLoading]="true"
                    (chartClicked)="onChartClicked($event)"
                  ></sh-statistics-chart>
                </div>
              </div>
            </div>
            <div class="row large">
              <!-- card -->
              <div *ngFor="let widget of largeWidgets" class="column low">
                <div class="dashboard-card full">
                  <div [ngSwitch]="widget.group">
                    <div *ngSwitchCase="'largeCharts'">
                      <div class="dashboard-card-header">
                        <div class="description">
                          {{ widget.description }}
                        </div>
                      </div>
                      <sh-statistics-chart
                        [queryDefinition]="widget.statisticsQueryDefinition"
                        [infoBarClass]="'chart-infoBar dark '"
                        [clickable]="true"
                        [useAnimation]="true"
                        [height]="80"
                        [enableLoading]="true"
                        (chartClicked)="onChartClicked($event)"
                      ></sh-statistics-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row large">
              <!-- card -->
              <div *ngFor="let widget of noneGraphWidgets" class="column">
                <div class="dashboard-card nonGraph full">
                  <div [ngSwitch]="widget.group">
                    <div *ngSwitchCase="'NonGraphWidget'">
                      <div class="dashboard-card-header">
                        <div class="description">
                          {{ widget.title }}
                        </div>
                      </div>
                      <div class="commonError-container">
                        <sh-common-errors-widget [queryModel]="widget.logQueryModel"></sh-common-errors-widget>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dashboard-card-header description">
              <span class="settings" *ngIf="(isReadOnly$ | async) === false">
                <mat-slide-toggle floatPlaceholder="never" [(ngModel)]="showAllWidgets" labelPosition="before">
                  Shared Widgets
                </mat-slide-toggle>
              </span>
            </div>
            <div class="row dark">
              <!-- card -->
              <div *ngFor="let widget of customChartWidgets" class="column custom widget-card-wrap">
                <div class="dashboard-card border">
                  <div class="dashboard-card-header">
                    <div class="description">
                      {{ widget.title }}
                    </div>
                    <div class="widgetsButtons">
                      <div class="ui icon button widget-button">
                        <span
                          *ngIf="!widget.isShared"
                          class="fa fa-user inverted info-icon-plain"
                          style="cursor: help;"
                          aria-hidden="true"
                          matTooltipPosition="above"
                          matTooltip="Private"
                        ></span>
                        <span
                          *ngIf="widget.isShared"
                          class="fa fa-users inverted info-icon-plain"
                          style="cursor: help;"
                          aria-hidden="true"
                          matTooltipPosition="above"
                          matTooltip="Public"
                        ></span>
                      </div>
                      <div *ngIf="widget.description" class="ui icon button widget-button">
                        <i
                          class="fa fa-info-circle inverted info-icon-plain"
                          aria-hidden="true"
                          matTooltipPosition="above"
                          matTooltip="{{ widget.description }}"
                        ></i>
                      </div>
                      <div *ngIf="(isAdmin$ | async) || !widget.isShared" class="ui icon button widget-button" (click)="showWidget(widget)">
                        <i class="write inverted icon"></i>
                      </div>
                      <div
                        *ngIf="(isAdmin$ | async) || !widget.isShared"
                        class="ui icon button widget-button"
                        (click)="deleteWidgetYesNo.showModal(widget)"
                      >
                        <i class="remove inverted icon"></i>
                      </div>
                    </div>
                  </div>
                  <div [ngSwitch]="widget.url">
                    <div *ngSwitchCase="'statistics/logs'">
                      <sh-statistics-chart
                        [useAnimation]="true"
                        [clickable]="true"
                        [infoBarClass]="'chart-infoBar dark '"
                        [queryDefinition]="widget.statisticsQueryDefinition"
                        (chartClicked)="onChartClicked($event)"
                        [enableLoading]="true"
                      ></sh-statistics-chart>
                    </div>
                    <div *ngSwitchCase="'statistics/logs1'">
                      <sh-basic-statistic-chart
                        [infoBarClass]="'chart-infoBar dark '"
                        [queryDefinition]="widget.statisticsQueryDefinition"
                        [enableLoading]="true"
                      ></sh-basic-statistic-chart>
                    </div>
                    <div *ngSwitchCase="'loggregation/parameter'">
                      <sh-templates-charts [loggregationQuery]="widget.queryRequest" [enableLoading]="true"></sh-templates-charts>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<sh-yes-no-modal #deleteWidgetYesNo (onAnswer)="onDeleteWidget($event)" [message]="'Are you sure?'"></sh-yes-no-modal>
