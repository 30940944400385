import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class AdditionalData {
  @jsonMember
  public scale: number;
}

@jsonObject
export class XAxis {
  @jsonMember
  public type: string;
  @jsonMember(TypedJsonAny.OPTIONS)
  public values: any = [];
}
@jsonObject
export class YAxis {
  @jsonMember
  public type: string;
  @jsonMember(TypedJsonAny.OPTIONS)
  public values: any;
}
@jsonObject
export class Data {
  @jsonMember
  public additionalData: AdditionalData;
  @jsonMember
  public xAxis: XAxis;
  @jsonMember
  public yAxis: YAxis;
}
@jsonObject
export class Stats {
  @jsonMember(TypedJsonAny.OPTIONS)
  public avg: any;
  @jsonMember(TypedJsonAny.OPTIONS)
  public count: any;
  @jsonMember(TypedJsonAny.OPTIONS)
  public max: any;
  @jsonMember(TypedJsonAny.OPTIONS)
  public min: any;
  @jsonMember(TypedJsonAny.OPTIONS)
  public std_deviation: any;
  @jsonMember(TypedJsonAny.OPTIONS)
  public sum: any;
  @jsonMember(TypedJsonAny.OPTIONS)
  public sum_of_squares: any;
  @jsonMember(TypedJsonAny.OPTIONS)
  public variance: any;
}

@jsonObject
export class StatisticsResult {
  @jsonMember
  public data: Data;
  @jsonMember
  public stats: Stats;
  @jsonMember
  public status: number;
  @jsonMember
  public type: string;
}

@jsonObject
export class StatisticsAggregationData {
  @jsonMember(TypedJsonAny.OPTIONS)
  public methodname: any = {};
  @jsonMember(TypedJsonAny.OPTIONS)
  public classname: any = {};
  @jsonMember(TypedJsonAny.OPTIONS)
  public ipaddress: any = {};
  @jsonMember(TypedJsonAny.OPTIONS)
  public category: any = {};
  @jsonMember(TypedJsonAny.OPTIONS)
  public subsystemname: any = {};
  @jsonMember(TypedJsonAny.OPTIONS)
  public applicationname: any = {};
  @jsonMember(TypedJsonAny.OPTIONS)
  public severity: any = {};
  @jsonMember(TypedJsonAny.OPTIONS)
  public computername: any = {};
}

@jsonObject
export class SeveritySize {
  @jsonMember
  public block: number;
  @jsonMember
  public high: number;
  @jsonMember
  public low: number;
  @jsonMember
  public medium: number;
  @jsonMember
  public totalSize: number;
}

@jsonObject
export class StatisticsSizeAggregationData {
  @jsonMember
  public subsystemName: string;
  @jsonMember
  public applicationName: string;
  @jsonMember
  public ts_agg: number;
  @jsonArrayMember(SeveritySize)
  public severities: SeveritySize[];
}

@jsonObject
export class StatisticsAggregationResult {
  @jsonMember
  public data: StatisticsAggregationData;
}

@jsonObject
export class StatisticsSizeAggregationResult {
  @jsonArrayMember(StatisticsSizeAggregationData)
  public data: StatisticsSizeAggregationData[];
}
