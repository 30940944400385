import { EventEmitter, Injectable } from '@angular/core';
import { GridState } from '../../../shared/models/grid/GridState';

@Injectable()
export class LivetailProvider {
  public onLiveTailLogs: EventEmitter<any> = new EventEmitter();
  public onLiveTailGridClearRequest: EventEmitter<any> = new EventEmitter();
  public selectedItem: any;
  public startDate: Date;
  public endDate: Date;
  public livetailGridState: GridState = new GridState('Logs');
  public livetailLoggregationGridState: GridState = new GridState('Templates');
  public currentGridState: GridState = new GridState('');
  // use to show the banner only once
  public showBanner = true;
  public textInfoClicked: EventEmitter<string> = new EventEmitter<string>();
}
