<div class="container">
  <img src="assets/icons/clear-icon.svg" class="clear-icon" (click)="dialogRef.close()" alt="icon" />

  <div class="title">Selection from field "{{ data.fieldName }}"</div>

  <div class="view-as-json-container">
    <div class="full-json-path">{{ data.fullJSONPath }}</div>
    <div class="pretty-json-container">
      <div #formattedJson></div>
    </div>
  </div>

  <div class="bottom-actions">
    <div class="btn cancel-btn" (click)="dialogRef.close()">CLOSE</div>
  </div>
</div>
