<div class="container">
  <mat-icon class="close-icon" (click)="dialogRef.close(false)">close</mat-icon>
  <div class="content">
    <h2 mat-dialog-title class="title">{{ title }}</h2>
    <span class="subtitle">{{ message }}</span>
  </div>
  <div class="bottom-actions">
    <div class="btn cancel-btn" (click)="dialogRef.close(false)">Cancel</div>
    <div class="btn delete-btn" (click)="dialogRef.close(true)">
      {{ action }}
    </div>
  </div>
</div>
