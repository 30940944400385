<div class='container'>
  <span class='text'>Alert If</span>
  <div class='select' [matMenuTriggerFor]='menu'>
    <span>{{pickedField ? pickedField[0] : "Select Metric Field" }}</span>
    <div class='arrow'>
      <mat-icon class="arrow-font-style">keyboard_arrow_down</mat-icon>
    </div>
  </div>

  <mat-menu #menu='matMenu'
            yPosition='below'
            class='metric-field-picker-panel' (closed)="closeMenu()">
    <div shClickStopPropagation class='container'>
      <div class='search'>
        <cgx-input-field class='search-input' placeholder='Search for a metric field'
                         [formControl]='searchControl'>
        </cgx-input-field>
      </div>

      <cdk-virtual-scroll-viewport
        itemSize='50'
        class='scroll-viewport'
        minBufferPx="400" maxBufferPx="800"
        *ngIf='filteredMetricFields; let localMetricFields'
      >
        <div class='content'>
          <div class='metric'>
            <div class='metric-title'>
              <div class='divider'>
                <mat-divider></mat-divider>
              </div>
              <div class='ninety-width'>
                LOGS2METRICS METRIC FIELDS
              </div>
            </div>
            <div class='ninety-width metric-field-details' *ngIf='!hasLogs2MetricsFields'>
              Add a Logs2Metrics real-time converter <a target="_blank" href="https://coralogix.com/tutorials/logs2metrics/">HERE</a>
            </div>
          </div>

          <div *ngFor='let metricField of localMetricFields; index as i'>
            <div class='metric' *ngIf='hasLogs2MetricsFields && (firstPrometheusIndex === -1 || i < firstPrometheusIndex)'>
              <div class='metric-field' *ngIf='!isPrometheusMetric(metricField)' (click)="writeValue(metricField)">
                <div class='ninety-width metric-field-details'>
                  <span>{{metricField[0]}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class='metric'>
            <div class='metric-title'>
              <div class='divider'>
                <mat-divider></mat-divider>
              </div>
              <div class='ninety-width'>
                <p>PROMETHEUS METRIC FIELDS</p>
              </div>
            </div>
            <div class='ninety-width metric-field-details' *ngIf='firstPrometheusIndex === -1'>
              Add prometheus metrics from <a target="_blank" href="https://coralogix.com/integrations/prometheus/">HERE</a>
            </div>
          </div>
          <div *ngFor='let metricField of localMetricFields; index as i'>
            <div class='metric' *ngIf='firstPrometheusIndex >= 0 && i >= firstPrometheusIndex'>
              <div class='metric-field' *ngIf='firstPrometheusIndex != -1 && i >= firstPrometheusIndex' (click)="writeValue(metricField)">
                <div class='ninety-width metric-field-details'>
                  <span>{{metricField[0]}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>

    </div>
  </mat-menu>
</div>
