<div
  class="ui inverted transparent right action left icon input"
  [hidden]="!allowSearch"
  style="height: 100%"
>
  <i class="search icon"></i>
  <input
    #textInput
    class="input-padding"
    type="text"
    (keyup)="OnFindKeyUp($event.target.value)"
    placeholder="Find"
  />
  <div
    [class.chevron-btn]="currentText"
    [class.disabledbutton]="!currentText"
    (click)="findDown($event)"
  >
    <i class="chevron down icon"></i>
  </div>
  <div
    [class.chevron-btn]="currentText"
    [class.disabledbutton]="!currentText"
    (click)="findUp($event)"
  >
    <i class="chevron up icon"></i>
  </div>
  <div
    [class.chevron-btn]="currentText"
    [class.disabledbutton]="!currentText"
    (click)="clear()"
  >
    <i class="remove icon"></i>
  </div>
</div>
