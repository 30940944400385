import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { OccurrencesDialogComponent } from '@app/logs-new/shared/features/occurrences/occurrences-dialog/occurrences-dialog.component';
import { LogsStatsService } from '@app/logs-new/shared/services/logs-stats.service';
import { Observable } from 'rxjs';
import { ILogQuery } from '@app/logs-new/shared/models/logsquery.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sh-templates-occurrences-renderer',
  templateUrl: 'templates-occurrences-renderer.component.html',
  styleUrls: ['templates-occurrences-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatesOccurrencesRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public value: number;
  public logsQuery$: Observable<ILogQuery>;

  constructor(private dialog: MatDialog, private logsStatsService: LogsStatsService) {}

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.data.count;
    this.logsQuery$ = params.context.parent.logsQuery$;
  }

  public refresh(params: any): boolean {
    return true;
  }

  public openOccurrencesDialog(): void {
    this.logsQuery$.pipe(take(1)).subscribe((logsQuery) => {
      const templateId = this.params.data.id;
      const chartData$ = this.logsStatsService.getOccurrencesStats(logsQuery, templateId);

      this.dialog.open(OccurrencesDialogComponent, {
        panelClass: 'occurrences-dialog',
        data: { chartData$, templateId, logsQuery },
      });
    });
  }
}
