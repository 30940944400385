import { Pipe, PipeTransform } from '@angular/core';
import { Insight } from '../models/insight.model';
import { UserSettingsProvider } from '../../../user/shared/userSettingsProvider';
import * as _ from 'lodash';
import { InsightsHelper } from '../helpers/insightsHelper';

@Pipe({
  name: 'insightsMetadataFilter',
})
export class InsightsMetadataFilterPipe implements PipeTransform {
  constructor() {}
  public transform(items: Insight[], args?: any): any {
    if (!args) {
      return items;
    }

    const filter = args.queryMetadata;
    if (items && filter) {
      const filteredInsights = items.filter(
        (insight) =>
          filter.applicationName.length == 0 ||
          _.includes(filter.applicationName, insight.applicationName) ||
          !insight.applicationName,
      );

      const result = filteredInsights.filter(
        (insight) =>
          filter.subsystemName.length == 0 ||
          _.includes(filter.subsystemName, insight.subsystemName) ||
          !insight.subsystemName,
      );

      return result;
    }
    return null;
  }
}
