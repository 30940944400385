import { Injectable } from '@angular/core';
import { getTagsByAppAndSubSystem, State } from '@app/app.reducers';
import { Observable } from 'rxjs';
import { Build } from '../../models/build';
import { Store } from '@ngrx/store';

@Injectable()
export class DeploymentStoreService {
  constructor(private store: Store<State>) {}

  public filterTagsByApplicationAndSubsystem(
    applicationNames: string[],
    subsystemNames: string[],
  ): Observable<Build[]> {
    return this.store.select(getTagsByAppAndSubSystem(applicationNames, subsystemNames));
  }
}
