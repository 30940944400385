import * as moment from 'moment';

export class DateHelper {
  public static mergeDateAndTime(date: Date, time: string): Date {
    const dateStr = moment(date).format('YYYY-MM-DD');
    return moment(dateStr + ' ' + time, 'YYYY-MM-DD HH:mm:ss').toDate();
  }
  public static getRoundHour(date: Date) {
    const roundDate = moment(date);
    roundDate.set('m', 0);
    roundDate.set('s', 0);
    roundDate.set('ms', 0);
    return roundDate;
  }
}
