<span class="regex-tooltip-content">
    <div class="regex-tooltip-content-section">
      <p style="font-weight: bold">
        To run a complex query, use / before and after your text.
      </p>
    </div>
    <ul class="regex-tooltip-content-section">
      <li>
        To perform a free text search, simply enter a text string.
        For example, if you’re searching web server logs, enter
        safari to define an alert on all fields for the term safari
        (without / around the alert text).
      </li>
      <li>
        To define an alert on a value in a specific field, prefix
        the value with the name of the field. For example, enter
        /environment:production/ to define an alert on all the
        entries that contain the value production in the environment
        field.
      </li>
      <li>
        To define an alert on a range of numeric values, use the
        bracketed range syntax [START_VALUE TO END_VALUE]. For
        example, to define an alert on entries that have 4xx status
        codes, enter /status.numeric:[400 TO 499]/.
      </li>
      <li>
        To specify a more complex alert criteria, use the Boolean
        operators AND, OR, and NOT. For example, to define an alert
        on entries that have 4xx status codes and have an extension
        of php, enter /status.numeric:[400 TO 499] AND
        extension:php/.
      </li>
      <li>
        To define an alert when a regular expression matches a
        value, wrap your regex with '/' and use it as the expression
        for the field. For example, to define an alert the regions
        west-europe-1, west-europe-2, west-us-1, west-us-2,
        west-us-3 etc., enter /region:/west-(europe|us)-[0-9]+//.
      </li>
    </ul>
    <div class="regex-tooltip-content-section">
      <p>
        Example: define an alert on logs from your production with
        status codes 5xx not originating from west-europe or
        west-us, use this expression:
      </p>
      <p>
        /environment:production AND status.numeric:[500 TO 599] NOT
        region:/west-(europe|us)-[0-9]+//
      </p>
    </div>
</span>
