import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldExtractionSelectorComponent} from './field-extraction-selector.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@app/shared/material/material.module';
import {FormControlsModule} from '@app/shared/form-controls/form-controls.module';
import {CgxButtonModule} from "@coralogix/design";

@NgModule({
  declarations: [FieldExtractionSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormControlsModule,
    CgxButtonModule
  ],
  exports: [FieldExtractionSelectorComponent]
})
export class FieldExtractionSelectorModule {
}
