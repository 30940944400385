import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'sh-metrics-editor-details',
  templateUrl: './metrics-editor-details.component.html',
  styleUrls: ['./metrics-editor-details.component.scss']
})
export class MetricsEditorDetailsComponent implements OnInit, OnDestroy {
  @Input()
  public form: FormGroup;
  @Input()
  public groupsContainsName: boolean = false;
  @Output()
  public metricName: EventEmitter<string> = new EventEmitter<string>();
  private metricNameSub: Subscription;

  public hasError(controlName: string, errorCode: string): boolean {
    const control = this.form.get(controlName);
    return control.hasError(errorCode) && control.touched;
  }

  public ngOnInit(): void {
    this.metricNameSub = this.form.get('metricName').valueChanges.subscribe(name => {
      this.metricName.emit(name);
    });
    const nameValue = this.form.get('metricName')?.value;
    if (!_.isEmpty(nameValue)) {
      this.metricName.emit(nameValue);
    }
  }

  public ngOnDestroy(): void {
    this.metricNameSub?.unsubscribe();
  }
}
