import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { SeverityRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/severity-renderer/severity-renderer.component';
import { LogsNewGridComponent } from '@app/logs-new/shared/features/logs-new-grid/logs-new-grid.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ClickOutsideModule } from '@shared/directives/click-outside.module';
import { FieldVisualizationModule } from '@app/logs-new/shared/features/field-visualization/field-visualization.module';
import { JsonFormatterModule } from '@shared/modules/json-formatter/json-formatter.module';
import { CustomGridHeaderComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/custom-grid-header/custom-grid-header.component';
import { SharedModule } from '@shared/shared.module';
import { SideMenuRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/side-menu-renderer/side-menu-renderer.component';
import { SideMenuHeaderRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/side-menu-header-renderer/side-menu-header-renderer.component';
import { TemplatesFormatterRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/templates-formatter-renderer/templates-formatter-renderer.component';
import { LogsFormatterRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/logs-formatter-renderer/logs-formatter-renderer.component';
import { TemplatesOccurrencesRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/components/cell-renderers/templates-occurrences-renderer/templates-occurrences-renderer.component';
import { JsonFormatterComponent } from '@app/logs-new/shared/features/logs-new-grid/components/grid-json-formatter/grid-json-formatter.component';
import { NewJsonFormatterModule } from '@app/logs-new/shared/features/new-json-formatter/new-json-formatter.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TeamRendererComponent } from '@app/logs-new/shared/features/logs-new-grid/cell-renderers/team-renderer/team-renderer.component';
import { SelectLogModule } from '../select-log/select-log.module';
import { LogsInfoPanelComponent } from '@app/logs-new/components/logs-content/logs-info-panel/logs-info-panel.component';
import { UnescapeForwardSlashesModule } from '@shared/pipes/unescape-forward-slashes/unescape-forward-slashes.module';
import { ResizableModule } from 'angular-resizable-element';
import { CgxButtonModule, CgxInputFieldModule, CgxTooltipModule } from '@coralogix/design';
import { AllowAbilityModule } from '@app/logs-new/shared/directives/allow-ability.module';
import { PluginsContainerModule } from '@app/logs-new/components/plugins-container/plugins-container.module';

const AG_GRID_COMPONENTS = [
  SeverityRendererComponent,
  CustomGridHeaderComponent,
  SideMenuRendererComponent,
  LogsFormatterRendererComponent,
  TemplatesFormatterRendererComponent,
  TemplatesOccurrencesRendererComponent,
  SideMenuHeaderRendererComponent,
  TeamRendererComponent,
];

@NgModule({
  declarations: [LogsNewGridComponent, JsonFormatterComponent, LogsInfoPanelComponent, ...AG_GRID_COMPONENTS],
  imports: [
    CommonModule,
    AgGridModule.withComponents(AG_GRID_COMPONENTS),
    JsonFormatterModule,
    MatTooltipModule,
    MatIconModule,
    MatPaginatorModule,
    ClickOutsideModule,
    FieldVisualizationModule,
    JsonFormatterModule,
    SharedModule,
    NewJsonFormatterModule,
    AngularSvgIconModule,
    SelectLogModule,
    UnescapeForwardSlashesModule,
    ResizableModule,
    CgxButtonModule,
    AllowAbilityModule,
    CgxTooltipModule,
    CgxInputFieldModule,
    PluginsContainerModule,
  ],
  exports: [LogsNewGridComponent],
})
export class LogsNewGridModule {}
