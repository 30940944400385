export enum UserAlertViewTypes {
  UserTextAlert = 1,
  DynamicAlert = 3,
  RatioAlert = 4,
  NewValueAlert = 5,
  Cardinality = 6,
  RelativeTime = 7,
  MetricAlert = 8,
  Flow = 9,
}

export interface IAlertType {
  name: string;
  description: string;
  value: UserAlertViewTypes;
  enabled: boolean;
}

export enum AlertSyntaxType {
  Lucene,
  PromQL,
}
