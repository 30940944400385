import { IInviteData, Invite } from '../../shared/models/invite';

export class GetInvites {
  public static readonly type: string = '[Invites] Get invites';
  constructor(public payload?: any) {}
}

export class AddInvites {
  public static readonly type: string = '[Invites] Add invites';
  constructor(public invites?: Invite[]) {}
}

export class ApproveInviteRequests {
  public static readonly type: string = '[Invites] Approve invite requests';
  constructor(public payload?: IInviteData[]) {}
}

export class DeclineInviteRequests {
  public static readonly type: string = '[Invites] Decline invite requests';

  constructor(public payload?: IInviteData[]) {}
}

export class DeleteInviteRequest {
  public static readonly type: string = '[Invites] Delete invite requests';

  constructor(public payload: string) {}
}

export class ClearInvitesForm {
  public static readonly type: string = '[Invites] Clear invites form';

  constructor(public payload: string) {}
}
