import { Component, OnInit, OnDestroy } from '@angular/core';
import { WidgetsService } from '@app/widgets/shared/services/widgets.service';
import { LogQueryModel } from '../../logs/shared/models/logsquery.model';
import { Router, NavigationExtras } from '@angular/router';
import { GlobalParamsProvider } from '@shared/services/globalParams.provider';
import { StatisticsQueryDefinition, StatisticsCategory } from '@app/statistics/shared/models/statistics-query-definition';
import { Insight } from '../../insights/shared/models/insight.model';
import { InsightsQueryModel } from '../../insights/shared/models/insights-query.model';
import { InsightsService } from '../../insights/shared/services/insights-service';

import { UpdatedAnomaliesModel } from '../../insights/shared/models/updated-anomalies-model';
import { Widget } from '../../widgets/shared/models/widget';
import { LivetailStatisticsManager } from '../../livetail/shared/services/livetail.statistics.manager';
import { Observable } from 'rxjs/Observable';
import { UserSettingsService } from '../../user/shared/user-settings.service';
import { UserSettingsProvider } from '../../user/shared/userSettingsProvider';
import { UserSettings } from '../../user/shared/user.settings';
import * as _ from 'lodash';
import { LivetailStatsService } from '@app/livetail/shared/services/livetailstats.service';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WidgetAddComponent } from '@shared/controls/widgets/widget-add/widget-add.component';
import { Subject, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { take, takeUntil } from 'rxjs/operators';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetSnoozedAlerts } from '@app/ngxs-store/alerts/alerts-snooze.action';
import { CompanyState } from '@app/ngxs-store/company/company.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { filterFalsy } from '@app/logs-new/shared/operators/filter-falsy.operator';

@Component({
  selector: 'sh-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Select(UserState.isAdmin) public isAdmin$: Observable<boolean>;
  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  public defaultWidgets: any[];

  public largeWidgets: any[];

  public noneGraphWidgets: any[];

  public customChartWidgets: any[];

  public insights: Insight[];

  public isLoadingInsights = false;

  public livetailStatsManager: LivetailStatisticsManager;

  public livetailTimer = Observable.timer(0, 5000);

  private _showAllWidgets: boolean = true;

  private liveTailDisconnected$ = new Subject<void>();

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private widgetService: WidgetsService,
    private router: Router,
    public dialog: MatDialog,
    private insightsService: InsightsService,
    private globalParams: GlobalParamsProvider,
    private userSettingsProvider: UserSettingsProvider,
    private livetailService: LivetailStatsService,
    private userSettingsService: UserSettingsService,
  ) {}

  get showAllWidgets(): boolean {
    return this._showAllWidgets;
  }
  set showAllWidgets(value: boolean) {
    if (this.showAllWidgets === value) {
      return;
    }

    this._showAllWidgets = value;

    this.userSettingsProvider.userSettings.showAllWidgets = this.showAllWidgets;
    this.refreshCustomWidgets(this.userSettingsProvider.userSettings);
    this.userSettingsService
      .updateUserSettings(this.userSettingsProvider.userSettings)
      .first()
      .subscribe();
  }

  public ngOnInit(): void {
    if (this.userSettingsProvider.userSettings) {
      this.refreshDashboard(this.userSettingsProvider.userSettings);
    }
    this.getInsights();

    this.livetailService
      .startLivetail()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (this.livetailStatsManager) {
            this.livetailStatsManager.updateStats(res);
          }
        },
        err => console.log(err),
      );

    this.userSettingsProvider.userSettingsChanged.pipe(takeUntil(this.destroyed$)).subscribe(settings => {
      this.refreshDashboard(settings);
    });

    this.livetailTimer.pipe(takeUntil(this.liveTailDisconnected$)).subscribe(t => {
      if (this.userSettingsProvider.userSettings) {
        this.livetailService.subscribeLivetail(this.userSettingsProvider.userSettings.queryMetadata);
      }
    });
  }

  public refreshCustomWidgets(settings: UserSettings): void {
    this.showAllWidgets = settings.showAllWidgets;
    this.widgetService.getWidgets().subscribe((res: any) => {
      this.customChartWidgets = res.filter(item => this.filterWidget(item));
    });
  }

  public refreshDashboard(settings: UserSettings): void {
    if (!settings) {
      return;
    }

    this.livetailStatsManager = null;
    this.livetailService.unsubscribeLivetail();
    this.refreshCustomWidgets(settings);
    const widgets = this.widgetService.getPredefinedWidgets(settings);

    this.defaultWidgets = widgets.filter(item => item.group === 'defaultGroup');
    this.largeWidgets = widgets.filter(item => item.group === 'largeCharts');
    this.noneGraphWidgets = widgets.filter(item => item.group === 'NonGraphWidget');
    if (this.userSettingsProvider.userSettings) {
      setTimeout(() => {
        this.livetailStatsManager = new LivetailStatisticsManager(this.userSettingsProvider.userSettings.queryMetadata);
        this.livetailService.subscribeLivetail(this.userSettingsProvider.userSettings.queryMetadata);
      }, 2000);
    }
  }

  public filterWidget(widget: Widget): boolean {
    if (!widget.group.includes('defaultGroup')) {
      return false;
    }

    if (!this.showAllWidgets && widget.isShared) {
      return false;
    }

    let result = false;
    const query = JSON.parse(widget.query);
    const diffApplication = this.userSettingsProvider.userSettings.queryMetadata.applicationName.filter(x =>
      query.queryParams?.metadata?.applicationName?.includes(x),
    );
    const diffSubsystem = this.userSettingsProvider.userSettings.queryMetadata.subsystemName.filter(x =>
      query.queryParams?.metadata?.subsystemName?.includes(x),
    );
    result =
      (this.userSettingsProvider.userSettings.queryMetadata.applicationName.length === 0 || diffApplication.length > 0) &&
      (this.userSettingsProvider.userSettings.queryMetadata.subsystemName.length === 0 || diffSubsystem.length > 0);

    return result;
  }

  public ngOnDestroy(): void {
    this.liveTailDisconnected$.next();
    this.liveTailDisconnected$.complete();
    this.livetailService.unsubscribeLivetail();
    this.livetailService.cleanLivetail();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public getInsights(): void {
    this.isLoadingInsights = true;
    const insightsQueryModel: InsightsQueryModel = new InsightsQueryModel();
    this.insightsService
      .getInsights(insightsQueryModel)
      .first()
      .subscribe(
        res => {
          this.insights = res.insights;
          const groupedInsightsById = _.groupBy(this.insights, insight => insight.alertId);
          this.getSnoozedAlerts(_.keys(groupedInsightsById));
          _.forEach(groupedInsightsById, (insights, key) => (_.maxBy(insights, insight => insight.timestamp).isLatest = true));
        },
        error => console.log(error),
        () => {
          this.isLoadingInsights = false;
        },
      );
  }

  public onChartClicked(e): void {
    const logQuery: LogQueryModel = _.cloneDeep(e[0]);
    const statisticDef: StatisticsQueryDefinition = _.cloneDeep(e[1]);

    if (statisticDef) {
      switch (statisticDef.category) {
        case StatisticsCategory.SEVERITY:
        case StatisticsCategory.LOGS: {
          if (logQuery) {
            this.globalParams.chartClickedLogQueryModel = logQuery;
          }

          this.router.navigate(['/query-new/logs'], { state: { logQuery } });
          break;
        }
        case StatisticsCategory.ALERTS: {
          let filterSev = '';
          if (logQuery && logQuery.queryParams && logQuery.queryParams.metadata && logQuery.queryParams.metadata.severity) {
            const sev = logQuery.queryParams.metadata.severity[0];
            if (sev === 3) {
              filterSev = 'info';
            } else if (sev === 4) {
              filterSev = 'warning';
            } else if (sev === 6) {
              filterSev = 'critical';
            }
          }

          const navigationExtras: NavigationExtras = {
            queryParams: { filter: 'ALERTS', severity: filterSev, id: '' },
          };

          this.router.navigate(['/insights'], navigationExtras);
          break;
        }
        case StatisticsCategory.ANOMALIES: {
          let filterSev = '';
          if (logQuery && logQuery.queryParams && logQuery.queryParams.metadata && logQuery.queryParams.metadata.severity) {
            const sev = logQuery.queryParams.metadata.severity[0];
            if (sev === 3) {
              filterSev = 'info';
            } else if (sev === 4) {
              filterSev = 'warning';
            } else if (sev === 6) {
              filterSev = 'critical';
            }
          }

          const navigationExtras: NavigationExtras = {
            queryParams: { filter: 'ANOMALIES', severity: filterSev, id: '' },
          };
          this.router.navigate(['/insights'], navigationExtras);
          break;
        }
      }
    }
  }

  public oninsightclicked(insight: Insight): void {
    const navigationExtras: NavigationExtras = {
      queryParams: { id: insight.id },
    };
    this.router.navigate(['/insights'], navigationExtras);
  }

  public updateAnomalies(updateModel: UpdatedAnomaliesModel): void {
    this.insights = this.insights.map(insight => {
      if (insight.alertId === updateModel.anomalyId) {
        insight.name = updateModel.anomalyUpdateParams.name;
        insight.severity = updateModel.anomalyUpdateParams.severity;
        insight.isActive = updateModel.anomalyUpdateParams.isActive;
      }
      return insight;
    });

    if (this.userSettingsProvider.userSettings) {
      this.refreshDashboard(this.userSettingsProvider.userSettings);
    }
  }

  public onDeleteWidget(widget: Widget): void {
    this.widgetService
      .deleteWidget(widget.id)
      .first()
      .subscribe(() => {
        const index = this.customChartWidgets.indexOf(widget);
        this.customChartWidgets.splice(index, 1);
      });
  }

  public editWidget(widget: Widget): void {
    if (!widget || !widget.id) {
      return;
    }
    this.widgetService
      .updateWidget(widget)
      .first()
      .switchMap(res => this.widgetService.getWidget(widget.id))
      .first()
      .subscribe((result: any) => {
        if (result.length > 0) {
          const index = this.customChartWidgets.indexOf(widget);
          if (this.filterWidget(widget)) {
            this.customChartWidgets.splice(index, 1, result[0]);
          } else {
            this.customChartWidgets.splice(index, 1);
          }
        }
      });
  }

  public showWidget(widget: Widget): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = { widget };
    config.panelClass = 'add-edit-widget-container';
    const dialogRef = this.dialog.open(WidgetAddComponent, config);
    dialogRef
      .afterClosed()
      .first()
      .subscribe(w => {
        this.editWidget(w);
      });
  }

  @Dispatch() public getSnoozedAlerts = alertIds => new GetSnoozedAlerts(alertIds);
}
