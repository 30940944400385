import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertTypesOptions } from '@app/alerts/alerts-editor/models/alert-editor-consts';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'sh-alert-type',
  templateUrl: './alert-type.component.html',
  styleUrls: ['./alert-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertTypeComponent {
  @Input() form: FormGroup;
  alertTypes = AlertTypesOptions;

  get selectedAlertType(): FormControl {
    return this.form.get('userAlertTypeId') as FormControl;
  }
}
