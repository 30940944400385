import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'sh-coralogix-chips-input',
  templateUrl: 'coralogix-chips-input.component.html',
  styleUrls: ['coralogix-chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CoralogixChipsInputComponent,
      multi: true,
    },
  ],
})
export class CoralogixChipsInputComponent {
  @Input()
  public placeholder: string;
  @Input()
  public showIcon: boolean;

  public value: string[] = [];
  public visible: boolean = true;
  public selectable: boolean = true;
  public removable: boolean = true;
  public addOnBlur: boolean = true;
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private onChangeFn: (val: any) => any;
  private onTouchedFn: () => any;

  public registerOnChange(fn: () => any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouchedFn = fn;
  }

  public onModelChange(value: any): void {
    if (this.onChangeFn) {
      this.onChangeFn(value);
    }
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      const currentValue = this.value || [];
      this.value = [...new Set([...currentValue, value.trim()])];
    }

    if (input) {
      input.value = '';
    }

    this.onModelChange(this.value);
  }

  public remove(value: string): void {
    const index = this.value.indexOf(value);

    if (index >= 0) {
      this.value.splice(index, 1);
    }

    this.onModelChange(this.value);
  }

  public writeValue(value: string[]): void {
    this.value = value;
  }
}
