import { ConfigurationService } from '@app/services/configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationSettingsModel } from '../models/settingsModel';
import { Observable } from 'rxjs';
import { TypedJSON } from 'typedjson';

export interface IDigestIntegrations {
  digestTypeId: number;
  integrationId: number;
  isActive: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settingsList$: Observable<NotificationSettingsModel[]>;
  private settingsList: NotificationSettingsModel[];

  constructor(private http: HttpClient, private cs: ConfigurationService) {}

  private settingsEndpoint: string = '/api/v1/digests';
  private digestIntegrationsEndpoint: string = '/api/v1/digest-integrations';
  private settingsUrl = this.cs.getConfigHttpURL(this.settingsEndpoint);
  private digestIntegrationsUrl = this.cs.getConfigURL('/api/v1/digest-integrations');

  public getSettings(): Observable<NotificationSettingsModel[]> {
    if (this.settingsList$) {
      return this.settingsList$;
    } else {
      const obs = (this.settingsList$ = this.http
        .get(this.settingsUrl)
        .map(this.extractData)
        .map(res => {
          this.settingsList$ = null;
          this.settingsList = res;
          return res;
        })
        .catch(error => this.handleError(error)));
      return obs;
    }
  }

  public saveSettings(settingsModels: NotificationSettingsModel[]): Observable<any> {
    // let test = Observable.empty<any>();
    //
    // settingsModels.forEach(x=> {
    //   test = test.concat(this.http.post(this.Consants.settingsUrl, JSON.stringify(x))
    //     .map(this.extractSaveData).catch(this.handleError));
    // });
    // todo: try to do in a loop
    const actions$ = settingsModels.map(model => this.http.post(this.settingsUrl, JSON.stringify(model)));

    return Observable.zip.apply(null, actions$);

    // let observable = this.http.post(this.Consants.settingsUrl, JSON.stringify(settingsModels[0]));
    // let observable1 = this.http.post(this.Consants.settingsUrl, JSON.stringify(settingsModels[1]));
    // let test = Observable.zip(observable, observable1).map(this.extractSaveData).catch(this.handleError);
    // let test = Observable.empty<any>();
    //
    // let test1 = Observable.from(settingsModels).map(x=> {
    //   this.http.post(this.Consants.settingsUrl, JSON.stringify(x));
    // });

    // let observable = this.http.post(this.Consants.settingsUrl, JSON.stringify(settingsModels[0]))
    //   .map(this.extractSaveData).catch(this.handleError);
    // let observable1 = this.http.post(this.Consants.settingsUrl, JSON.stringify(settingsModels[1]))
    //   .map(this.extractSaveData).catch(this.handleError);

    // return test;
  }

  getDigestIntegrations(): Observable<IDigestIntegrations[]> {
    const url = this.cs.getConfigURL(this.digestIntegrationsEndpoint);
    return this.http.get<IDigestIntegrations[]>(url);
  }

  saveDigestIntegrations(digestIntegrations: IDigestIntegrations[]): Observable<any> {
    const url = this.cs.getConfigURL(this.digestIntegrationsEndpoint);
    return this.http.post(url, {digestIntegrations});
  }

  private extractData(res) {
    // console.log(res);
    // Parsing: array.
    const integrationsRes = res.map(setting => {
      return new TypedJSON(NotificationSettingsModel).parse(JSON.stringify(setting));
    });
    return integrationsRes;
  }

  private handleError(error: any): Observable<never> {
    const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.log(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
