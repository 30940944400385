<div *ngIf="anomalyWidgetData | async as data">
  <div style="display: flex; padding-left: 4px;">
    <div>
      <div class="single-stat-container">
        <div class="light-blue-rectangle-bullet"></div>
        <span class="single-stat-header">Expected Error Ratio</span>
      </div>
      <span class="text-values">
        {{ (data.average | number: '1.2-2') + '%' }}
      </span>
    </div>
    <div>
      <div class="single-stat-container">
        <div class="blue-rectangle-bullet"></div>
        <span class="single-stat-header">Actual Error Ratio</span>
      </div>
      <span class="text-values">
        {{ (data.current | number: '1.2-2') + '%' }}
      </span>
    </div>
    <div>
      <div class="single-stat-container">
        <div class="pink-rectangle-bullet"></div>
        <span class="single-stat-header">Above normal</span>
      </div>
      <span *ngIf="data.average; else zeroAverage" class="text-values">
        {{ (data.current / data.average | number: '1.2-2') + 'x' }}
      </span>
      <ng-template #zeroAverage>
        <span
          class="text-values"
          style="font-size: 37px; line-height: 28px; font-weight: 400;"
        >
          &infin;
        </span>
      </ng-template>
    </div>
  </div>
</div>
