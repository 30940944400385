import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetTeammate } from '@app/ngxs-store/user/user.action';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';
import { Teammate } from '../models/teammate.model';
import { filter, take, tap } from 'rxjs/operators';
import { Role } from '../enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  @Select(UserState.user) public user$: Observable<Teammate>;

  constructor(private router: Router) {}

  public getCurrentUser(): Observable<Teammate> {
    return this.user$.pipe(
      tap(user => {
        if (!user) {
          this.getTeammate();
        }
      }),
      filter((self: any) => self !== null),
      take(1),
    );
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.getCurrentUser()
      .switchMap(user => {
        if (user.role !== Role.Admin) {
          this.router.navigate(['/settings/account']);
          return Observable.of(false);
        }

        return Observable.of(true);
      })
      .catch(err => {
        console.error(err);
        this.router.navigate(['/settings/account']);
        return Observable.of(false);
      });
  }

  @Dispatch() public getTeammate = () => new GetTeammate();
}
