<div style="padding: 14px;width: 600px">
  <h2 mat-dialog-title>{{ title }}</h2>
  <div style="margin-top: 20px;">
    <mat-dialog-content>
      <div class="controls-container">
        <div>
          <label>File Type</label>
          <mat-radio-group class="file-type-group" [(ngModel)]="fileTypeValue">
            <mat-radio-button
              class="file-type-button"
              *ngFor="let fileType of fileTypes"
              [value]="fileType"
              ngDefaultControl
            >
              {{ fileType.typeName }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="controls-container">
        <mat-form-field floatLabel="auto">
          <input
            type="text"
            matInput
            #matInput="matInput"
            placeholder="File Name"
            [(ngModel)]="fileName"
            translate="placeholder"
            class="export-text-input"
          />
        </mat-form-field>
      </div>
      <div
        *ngIf="fileTypeValue && fileTypeValue.id === 1"
        class="controls-container"
      >
        <mat-form-field floatLabel="auto">
          <input
            type="text"
            matInput
            placeholder="Delimiter"
            [(ngModel)]="delimiter"
            translate="placeholder"
            default=","
            class="export-text-input"
          />
        </mat-form-field>
      </div>
      <div>
        <div class="export-description">
          Export Time Range: {{ dateRangeStr }}
        </div>
        <div class="export-description">
          The following pages will be exported: {{ sortedPages }}
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
      <button
        mat-button
        (click)="onSubmitExport()"
        color="warn"
        [disabled]="!inputDataValid"
        mat-dialog-close
      >
        Export
      </button>
    </mat-dialog-actions>
  </div>
</div>
