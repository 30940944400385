export interface IRuleExampleData {
  boldTitle: string;
  title: string;
  description: string;
  codeSamples: IRuleSampleData[];
}

export interface IRuleSampleData {
  title?: string;
  data?: string;
  type: RuleSampleType;
  controlsData?: ITemplateData[];
}

export interface ITemplateData {
  title: string;
  controlText: string;
  controlType: ControlType;
}

export enum ControlType {
  text,
  dropdown
}

export enum RuleSampleType {
  text,
  regex,
  json,
  controls,
  replace
}

export const RuleSamples: IRuleExampleData[] = [
  {
    boldTitle: 'PARSE',
    title: 'Convert an unstructured log message into JSON',
    description: 'The following example takes an Heroku L/H type error log that is sent from Heroku as an unstructured log and converts it to json log.',
    codeSamples: [
      {
        title: 'The original log:',
        data: 'sock=client at=warning code=H27 desc="Client Request Interrupted" method=POST path="/submit/" host=myapp.herokuapp.com fwd=17.17.17.17 dyno=web.1 connect=1ms service=0ms status=499 bytes=0',
        type: RuleSampleType.text,
      },
      {
        title: 'The regex being used:',
        data: `^(sock=)?(?P<sock>(\\S*))\\s*at=(?P<severity>\\S*)\\s*code=(?P<error_code>\\S*)\\s*desc="(?P<desc>[^"]*)"\\s*method=(?P<method>\\S*)\\s*path="(?P<path>[^"]*)"\\s*host=(?P<host>\\S*)\\s*(request_id=)?(?P<request_id>\\S*)\\s*fwd="?(?P<fwd>[^"s]*)"?\\s*dyno=(?P<dyno>\\S*)\\s*connect=(?P<connect>\\d*)(ms)?\\s*service=(?P<service>\\d*)(ms)?\\s*status=(?P<status>\\d*)\\s* bytes=(?P<bytes>\\S*)\\s*`,
        type: RuleSampleType.regex,
      },
      {
        type: RuleSampleType.controls,
        controlsData: [
          {
            title: 'Source Field',
            controlText: 'Text',
            controlType: ControlType.dropdown,
          },
          {
            title: 'Destination Field',
            controlText: 'Text',
            controlType: ControlType.dropdown,
          },
        ],
      },
      {
        title: 'The resulting log:',
        data: `{
  "sock": "client",
  "severity":"warning",
  "error_code":"H27",
  "desc":"Client Request Interrupted",
  "method":"POST",
  "path":"/submit/",
  "host":"myapp.herokuapp.com",
  "request_id":"",
  "fwd":"17.17.17.17",
  "dyno":"web.1",
  "connect":"1",
  "service":"0",
  "status":"499",
  "bytes":"0",
  "protocol":""
}`,
        type: RuleSampleType.json,
      },
    ],
  },
  {
    boldTitle: 'EXTRACT',
    title: 'Convert an unstructured log message into JSON',
    description: 'The following example extracts information from the field message and creates two new fields, bytes, and status that can be queried and visualized:',
    codeSamples: [
      {
        title: 'The original log:',
        data: '{"level":"INFO", "message": "200 bytes sent status is OK"}',
        type: RuleSampleType.text,
      },
      {
        title: 'The regex being used:',
        data: `"message"\\s*:\\s*"(?P<bytes>\\d+)\\s*.*?status\\s*is\\s*(?P<status>[^"]+)`,
        type: RuleSampleType.regex,
      },
      {
        type: RuleSampleType.controls,
        controlsData: [
          {
            title: 'Source Field',
            controlText: 'Text',
            controlType: ControlType.dropdown,
          }
        ],
      },
      {
        title: 'The resulting log:',
        data: `{
  "level":"INFO",
  "message":"200 bytes sent status is OK",
  "Bytes":"200",
  "status":"OK"
}`,
        type: RuleSampleType.json,
      },
    ],
  },
  {
    boldTitle: 'JSON EXTRACT',
    title: 'Extract information from a JSON log message',
    description: 'This example will be taking a field value and populating with it one of Coralogix metadata fields to enable proper classification by the ML algorithm. In this example we extract a field called worker and get the value into the metadata field called category. There is no regex involved.',
    codeSamples: [
      {
        title: 'The original log:',
        data: `{
  "transaction_ID":12543,
  "worker":"A23",
  "Message":"success"
}`,
        type: RuleSampleType.json,
      },
      {
        title: 'Regular Expression',
        data: 'worker',
        type: RuleSampleType.text,
      },
      {
        type: RuleSampleType.controls,
        controlsData: [
          {
            title: 'Source Field',
            controlText: 'Text',
            controlType: ControlType.dropdown,
          },
          {
            title: 'Destination Field',
            controlText: 'Category',
            controlType: ControlType.dropdown,
          },
        ],
      },
      {
        title: 'Results',
        data: 'The log will not change in the log’s screen but the metadata field ‘category’ will be populated with "A23". This will affect the logs classification by the ML algorithms. You can read about loggegation and Flow anomalies, to learn more about some of the ML applications within Coralogix.',
        type: RuleSampleType.text,
      },
    ],
  },
  {
    boldTitle: 'REPLACE',
    title: 'Replace any JSON',
    description: 'A common use case for a replace rule is to repair damaged JSON logs back into JSON format. In this example the JSON logs are sent with a date prefix which breaks the JSON format and turns them into unstructured logs. The regex identifies the substring to replace.',
    codeSamples: [
      {
        title: 'The original log:',
        data: '2020-08-07 {"status":"OK", "user":John Smith", "ops":"J1"}',
        type: RuleSampleType.text,
      },
      {
        title: 'Regular Expression',
        data: '.*{',
        type: RuleSampleType.regex,
      },
      {
        type: RuleSampleType.controls,
        controlsData: [
          {
            title: 'Source Field',
            controlText: 'Text',
            controlType: ControlType.dropdown,
          },
          {
            title: 'Destination Field',
            controlText: 'Text',
            controlType: ControlType.dropdown,
          },
          {
            title: 'Replace To',
            controlText: '{',
            controlType: ControlType.text,
          },
        ],
      },
      {
        title: 'The resulting log:',
        data: '{"status":"OK", "user":John Smith", "ops":"J1"}',
        type: RuleSampleType.text,
      },
    ],
  },
  {
    boldTitle: 'BLOCK',
    title: '',
    description: 'Block rules allow for refined filtering of incoming logs. Like with other rules the heart of the rule will be a regex, identifying the logs to be blocked (or allowed). In this example all the logs that have the substring sql_error_code 28000 will be blocked.',
    codeSamples: [
      {
        title: 'The regex being used:',
        data: 'sql_error_code=28000',
        type: RuleSampleType.regex,
      }
    ],
  },
  {
    boldTitle: 'TIMESTAMPEXTRACT',
    title: 'Replace log timestamp',
    description: '',
    codeSamples: [
      {
        title: 'The original log:',
        data: '2021-01-10T08:58:12.628Z {"status":"OK", "user":John Smith", "ops":"J1", time: "2021-01-10T12:34:56.111Z"}',
        type: RuleSampleType.text,
      },
      {
        type: RuleSampleType.controls,
        controlsData: [
          {
            title: 'Source Field',
            controlText: 'Text',
            controlType: ControlType.dropdown,
          },
          {
            title: 'Standard',
            controlText: 'Text',
            controlType: ControlType.dropdown,
          },
          {
            title: 'Format',
            controlText: '',
            controlType: ControlType.text,
          },
        ],
      },
      {
        title: 'The resulting log:',
        data: '2021-01-10T12:34:56.111Z',
        type: RuleSampleType.text,
      },
    ],
  },

];
