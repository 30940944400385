import { jsonObject, jsonMember } from 'typedjson';
const SORT_FIELD_DEFAULT_VALUE = 'timestamp';
const SORT_ORDER_DEFAULT_VALUE = 'desc';
const SORT_MISSING_VALUE = '_last';

@jsonObject
export class SortModel {
  @jsonMember
  public field: string = SORT_FIELD_DEFAULT_VALUE;
  @jsonMember
  public ordering: string = SORT_ORDER_DEFAULT_VALUE;
  @jsonMember
  public missing: string = SORT_MISSING_VALUE;

  constructor(field?: any, ordering?: any) {
    this.field = field || SORT_FIELD_DEFAULT_VALUE;
    this.ordering = ordering || SORT_ORDER_DEFAULT_VALUE;
  }

  public isEqual(field: string, order: string): boolean {
    return this.field === field && this.ordering === order;
  }

  public isDefaultValue(): boolean {
    return (
      this.field === SORT_FIELD_DEFAULT_VALUE &&
      this.ordering === SORT_ORDER_DEFAULT_VALUE
    );
  }

  public hasDefultSorting(): boolean {
    return this.field === SORT_FIELD_DEFAULT_VALUE;
  }
}
