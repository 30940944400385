import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RegexTableSectionsEnum } from '@shared/tutorials/regex-cheat-sheet/enums/regex-table-sections.enum';
import { KeyValue } from '@angular/common';

@Component({
    selector: 'sh-regex-cheat-sheet-sidebar',
    templateUrl: './regex-cheat-sheet-sidebar.component.html',
    styleUrls: [ './regex-cheat-sheet-sidebar.component.scss' ],
})
export class RegexCheatSheetSidebarComponent {
    public tableSections: typeof RegexTableSectionsEnum = RegexTableSectionsEnum;
    @Output()
    public sectionSelected: EventEmitter<string> = new EventEmitter<string>();

    public sortingCallback = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    public onSectionSelect(id: string): void {
        this.sectionSelected.emit(id);
    }
}
