import { Component, OnInit } from '@angular/core';
import { IStatisticsViewData, TagSummaryTypes } from '@app/deployment/shared/models/summary.models';
import { Store } from '@ngrx/store';
import { getSummaryViewState, getTagSummary, State } from '@app/app.reducers';
import { Observable } from 'rxjs';
import { IRedirectByTypeOutput } from '@shared/components/statistics-tile/statistics-tile.component';
import { EFeatureViewMode } from '@app/deployment/shared/models/reducer.models';

@Component({
  selector: 'sh-summary-tiles',
  templateUrl: './summary-tiles.component.html',
  styleUrls: ['./summary-tiles.component.scss']
})
export class SummaryTilesComponent implements OnInit {
  public summaryViewByType$: Observable<IStatisticsViewData[]>;
  public viewState$: Observable<EFeatureViewMode>;
  public completedViewState: EFeatureViewMode = EFeatureViewMode.completed;
  constructor(private store: Store<State>) {
    this.viewState$ = this.store.select(getSummaryViewState);
  }

  public ngOnInit(): void {
    this.summaryViewByType$ = this.store.select(getTagSummary);
  }

  public redirectByType(data: IRedirectByTypeOutput): void {
    let _redirectLink = '';
    const { type, link } = data;
    // todo - add support for subsystem filter in redirect
    switch (type) {
      case TagSummaryTypes.triggeredAlerts:
        _redirectLink = `#${link}?filter=ALERTS`;
        break;
      case TagSummaryTypes.triggeredAnomalies:
        _redirectLink = `#${link}?filter=ANOMALIES&severity=critical`;
        break;
    }
    if (type === TagSummaryTypes.errorVolume ||
      type === TagSummaryTypes.newlyIntroducedErrors ||
      type === TagSummaryTypes.highSeverityRatio) {
      _redirectLink = `#${link}`;
    }
    window.open(_redirectLink);
  }
}
