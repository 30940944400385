<div *ngIf="planStatus$ | async; let planStatus">
  <sh-plan-limit-bar
    *ngIf="isPlanLimitBarVisible(planStatus)"
    [planStatus]="planStatus"
  ></sh-plan-limit-bar>
</div>
<sh-navbar
  [companyDetails]="companyInfo$ | async"
></sh-navbar>
<div
  class="main-content"
  [class.ribbon-visible]="isPlanLimitBarVisible(planStatus$ | async)"
>
  <router-outlet></router-outlet>
</div>
