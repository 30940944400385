import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Alert } from '@app/insights/shared/models/alert.model';
import { InsightsProvider } from '@app/insights/shared/services/insights.provider';
import { SeveritiesHelper } from '@app/shared/services/severities.helper';
import { Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { Chart } from 'angular-highcharts';
import { UserAlertTypes } from '@app/alerts/shared/models/create-n-update-alert-model';
import { Insight } from '@app/insights/shared/models/insight.model';
import {
  IAlertUiqueCountCountersMap,
  IAlertUiqueCountLog,
  IAlertUniqueCountChartData,
} from '@app/insights/shared/models/alert-cardinality.model';
import { AlertsService } from '@app/alerts/shared/services/alerts-service';

@Component({
  selector: 'sh-cardinality-alert',
  templateUrl: './cardinality-alert.component.html',
  styleUrls: ['./cardinality-alert.component.scss'],
})
export class CardinalityAlertComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: false }) public chartEl: ElementRef;

  public chart: Chart;

  public alert: Alert;

  public log: IAlertUiqueCountLog;

  public alertTimeframe: number;

  public countersMap: IAlertUiqueCountCountersMap;

  public valuesSet: string[];

  public tags: string[] = [];

  public search: FormControl = new FormControl();

  public triggeredValueList: any[] = [];

  public severity: number;

  public threshold: number;

  public fieldName: string = '';

  public title: string = '';

  public subTitle: string = '';

  public chartData: IAlertUniqueCountChartData[];

  public duration: moment.Duration;

  public groupByFields: string;

  public groupByValue: string;

  public SeveritiesHelper: typeof SeveritiesHelper = SeveritiesHelper;

  private searchedValeusSubscription: Subscription;
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(public insightsProvider: InsightsProvider, public alertsService: AlertsService) {}

  public ngOnInit(): void {
    const insight = this.insightsProvider.currentInsight;
    if (insight && insight.subTypeId === UserAlertTypes.Cardinality) {
      this.initInsight(insight);
      this.initialGroupByFields();
    }

    this.insightsSubscription();
  }

  public ngOnDestroy(): void {
    this.insightsProvider.globalState.selectedLog = null;

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initialGroupByFields(): void {
    this.alertsService
      .getAlertDefenition(this.alert.alertId)
      .pipe(take(1))
      .subscribe(alertDef => {
        if (alertDef.groupByFields.length > 0) {
          this.groupByFields = alertDef.groupByFields[0];
          const groupByTag = `GROUP BY (${this.groupByFields})`;
          this.tags.push(groupByTag);
          const groupValuesTag = `GROUP VALUES (${alertDef.uniqueCountAlertInfo?.groupByFieldLimit})`;
          this.tags.push(groupValuesTag);
        }
      });
  }

  private initInsight(insight: Insight): void {
    this.alert = insight as Alert;
    const logTextParsed = JSON.parse(this.alert.properties.logText);
    const log = JSON.parse(logTextParsed.logText);
    this.log = log;

    this.alertTimeframe = log.alertTimeframe;
    this.countersMap = log.countersMap;
    this.valuesSet = log.valuesSet;

    this.triggeredValueList = log?.valuesSet;

    this.threshold = this.alert.conditionThreshold;

    this.fieldName = JSON.parse(log.cardinalityKey)[0];

    this.groupByValue = log.groupByValue;

    this.tags = [`UNIQUE VALUE(${this.fieldName})`, `OVER ${this.threshold}`];

    this.chartData = Object.keys(this.countersMap)
      .reduce((acc: IAlertUniqueCountChartData[], curr) => {
        acc.push({ timestamp: +curr, value: this.countersMap[curr] });
        return acc;
      }, [])
      .sort((a, b) => a.timestamp - b.timestamp);

    const start = moment(this.alert.timestamp);
    const end = moment(this.alert.timestamp).add(this.alertTimeframe, 'minutes');

    const diff = end.diff(start);
    this.duration = moment.duration(diff);

    this.severity = logTextParsed.coralogix.metadata.severity;

    this.title = `Field ${this.fieldName} unique count is over ${this.threshold}`;

    this.subTitle = `We've detected that the number of distinct values for field ${this.fieldName} has exceeded ${
      this.threshold
    } in ${this.duration.humanize()} timeframe`;

    this.filterSearchedValues();

    this.initChart();
  }

  private insightsSubscription(): void {
    this.insightsProvider.currentInsightChanged
      .pipe(
        filter(insight => insight.subTypeId === UserAlertTypes.Cardinality),
        takeUntil(this.destroyed$),
      )
      .subscribe(insight => {
        this.initInsight(insight);
        this.initialGroupByFields();
      });
  }

  private filterSearchedValues(): void {
    if (this.searchedValeusSubscription) {
      return;
    }
    this.searchedValeusSubscription = this.search.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      let list = this.log.valuesSet;
      if (!!value) {
        list = list.filter(item => item.includes(value));
      }
      this.triggeredValueList = list;
    });
  }

  private initChart(): void {
    const categories = this.calcCategories(this.chartData[0].timestamp, this.alertTimeframe, this.chartData.length);

    const data = this.chartData.map(item => ({
      y: item.value,
      additionalData: moment(item.timestamp).format('DD/MM, HH:mm'),
    }));

    this.chart = new Chart({
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      chart: {
        borderColor: 'var(--cgx-border-primary)',
        borderWidth: 1,
        type: 'line',
        backgroundColor: 'var(--cgx-card)',
        marginRight: 30,
      },
      xAxis: {
        categories,
        minPadding: 0,
        maxPadding: 0,
        minorTickLength: 2,
        tickLength: 0,
        lineWidth: 1,
        minorGridLineWidth: 1,
        labels: {
          rotation: 45,
          align: 'left',
          style: {
            color: 'var(--cgx-text-secondary)',
          },
        },
        gridLineWidth: 1,
        gridLineColor: '#dae2f0',
      },
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          style: {
            color: 'var(--cgx-text-secondary)',
          },
        },
      },
      series: [
        {
          data,
          type: 'column',
          showInLegend: true,
          name: `Unique values under ${this.fieldName}`,
          color: 'var(--cgx-primary)',
          pointWidth: 5,
        },
        {
          data: data.map(dataItem => ({ ...dataItem, y: this.threshold })),
          type: 'line',
          showInLegend: true,
          name: 'Threshold',
          color: '#ff5edd',
          lineWidth: 1,
          enableMouseTracking: false,
        },
        {
          data,
          type: 'line',
          name: 'Trend',
          color: 'var(--cgx-border-primary)',
          lineWidth: 1,
          enableMouseTracking: false,
        },
      ],
      legend: {
        enabled: true,
        reversed: true,
        align: 'left',
        symbolHeight: 12,
        symbolWidth: 12,
        itemStyle: {
          fontSize: '12px',
          fontFamily: 'Nunito Sans',
          color: 'var(--cgx-text-secondary)',
        },
      },
      tooltip: {
        backgroundColor: '#35435b',
        style: {
          color: '#fff',
          fontWeight: '800',
          fontFamily: 'Nunito Sans',
        },
        padding: 12,
        borderRadius: 4,
        borderWidth: 0,
        formatter(): string {
          return `
          <div>${(this.point as any).additionalData}</div>
          <br/>
          <div>
            <span style="font-weight: normal; font-size: 40px">Amount</span>
            <span>${this.y}</span>
          </div>
          `;
        },
        useHTML: true,
      },
    });
  }

  private calcCategories(firstEpochTime: number, timeFrameInMinuts: number, length: number): string[] {
    const timeFrameInMillisecods = timeFrameInMinuts * 1000 * 60;
    const categories: string[] = [];

    for (let i = 0; i < length; i++) {
      const momentInstance = moment(firstEpochTime + timeFrameInMillisecods * i);
      categories.push(momentInstance.format('HH:mm'));
    }
    return categories;
  }
}
