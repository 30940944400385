import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { FormControlsModule } from '@shared/form-controls/form-controls.module';
import { AlertsEditorContainerComponent } from '@app/alerts/alerts-editor/containers/alerts-editor-container.component';
import { AlertsEditorComponent } from '@app/alerts/alerts-editor/components/alerts-editor.component';
import { AlertsDetailsComponent } from '@app/alerts/alerts-editor/components/alerts-details/alerts-details.component';
import { AlertsQueryComponent } from '@app/alerts/alerts-editor/components/alerts-query-container/alerts-query/alerts-query.component';
import { AlertsConditionsComponent } from '@app/alerts/alerts-editor/components/alerts-query-container/alerts-conditions/alerts-conditions.component';
import { AlertsRecipientsComponent } from '@app/alerts/alerts-editor/components/alerts-recipients/alerts-recipients.component';
import { AlertsScheduleComponent } from '@app/alerts/alerts-editor/components/alerts-scedule/alerts-schedule.component';
// tslint:disable-next-line:max-line-length
import { AlertsDayPickerComponent } from '@app/alerts/alerts-editor/components/alerts-scedule/alerts-day-picker/alerts-day-picker.component';
import { AlertsContentComponent } from '@app/alerts/alerts-editor/components/alerts-content/alerts-content.component';
import { AlertHelper } from '@app/alerts/shared/services/alert-helper';
import { AlertMapperService } from '@app/alerts/alerts-editor/services/alert-mapper.service';
import { VerifyAlertComponent } from '@app/alerts/alerts-editor/components/alerts-verify-alert/verify-alert.component';
import { AlertSearchQueryParserService } from '@app/alerts/alerts-editor/services/alert-search-query-parser.service';
import { VerifyAlertChartComponent } from './components/alerts-verify-alert/verify-alert-chart/verify-alert-chart.component';
import { StatisticsModule } from '@app/statistics/statistics.module';
import { AlertHistoryComponent } from '@app/alerts/alerts-editor/components/alert-history/alert-history.component';
import { FlexModule } from '@angular/flex-layout';
import { DateFormatByUserSettingsModule } from '@shared/pipes/date-format-by-user-settings/date-format-by-user-settings.module';
import { CgxButtonModule, CgxCheckboxModule, CgxInputFieldModule, CgxSelectModule, CgxTooltipModule } from '@coralogix/design';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MetricFieldPickerComponent } from './components/metric-field-picker/metric-field-picker.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ConditionalSelectModule } from '@app/shared/components/conditional-select/conditional-select.module';
import { AlertsGroupByComponent } from '@app/alerts/alerts-editor/components/alerts-group-by/alerts-group-by.component';
import { MatSliderModule } from '@angular/material/slider';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { RadioBtnGroupComponent } from './components/radio-btn-group/radio-btn-group.component';
import { AlertTypeComponent } from './components/alert-type/alert-type.component';
import { AlertsQueryContainerComponent } from './components/alerts-query-container/alerts-query-container.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    FormsModule,
    FormControlsModule,
    StatisticsModule,
    FlexModule,
    DateFormatByUserSettingsModule,
    CgxButtonModule,
    CgxSelectModule,
    CgxCheckboxModule,
    ConditionalSelectModule,
    MatCardModule,
    MatMenuModule,
    ScrollingModule,
    MatSliderModule,
    DirectivesModule,
    CgxInputFieldModule,
    CgxTooltipModule,
  ],
  declarations: [
    AlertsEditorContainerComponent,
    AlertsEditorComponent,
    AlertsDetailsComponent,
    AlertsQueryComponent,
    AlertsConditionsComponent,
    AlertsRecipientsComponent,
    AlertsScheduleComponent,
    AlertsDayPickerComponent,
    AlertsContentComponent,
    VerifyAlertComponent,
    VerifyAlertChartComponent,
    AlertHistoryComponent,
    MetricFieldPickerComponent,
    AlertsGroupByComponent,
    RadioBtnGroupComponent,
    AlertTypeComponent,
    AlertsQueryContainerComponent,
  ],
  entryComponents: [AlertsEditorContainerComponent],
  exports: [AlertsEditorContainerComponent],
  providers: [AlertHelper, AlertMapperService, AlertSearchQueryParserService],
})
export class AlertsEditorModule {}
