<div class="container">
  <img
    src="assets/icons/clear-icon.svg"
    class="clear-icon"
    data-test="dialog-clear-icon"
    (click)="dialogRef.close()"
    alt="icon"
  />

  <div class="title">Raw log</div>

  <div class="view-as-json-container">
    <div class="pretty-json-container">
      <div data-test="logs-raw-log">
        {{ data.rawLog }}
      </div>
    </div>
  </div>

  <div class="bottom-actions">
    <div class="btn cancel-btn" (click)="dialogRef.close()">CLOSE</div>
  </div>
</div>
