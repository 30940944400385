<pre
  @tooltip
  *ngIf="text.length"
  class="tooltip"
>
  {{ text }}
</pre>
<div
  @tooltip
  *ngIf="!text.length"
  class="tooltip"
>
  <span class="regex-tooltip-content">
    <div class="regex-tooltip-content-section">
      <p class="regex-tooltip-content-section-title">Running a simple query</p>
    </div>
    <ul class="regex-tooltip-content-section">
      <li>To define an alert on free text, simply enter a text string. For example, to find all response_codes 200 for
        GET requests, simply enter 'GET 200' (without quotes). It'll only trigger for logs containing the words '200'
        and 'GET'.
      </li>
    </ul>
    <div class="regex-tooltip-content-section">
      <p class="regex-tooltip-content-section-title">Running a complex query</p>
    </div>
    <ul class="regex-tooltip-content-section">
      <li>To define an alert on a phrase, wrap your phrase with double quotes("a phrase"). For example, "GET 200" will
        only trigger for logs containing '200' right after 'GET'.</li>
      <li>To define an alert for a value in a specific field, prefix the value with the name of the field. For example,
        enter 'environment:production' to define an alert on all the entries that contain the value 'production' in the
        'environment' field.
      </li>
      <li>To define an alert on a range of numeric values, use the bracketed range syntax [START_VALUE TO END_VALUE].
        For example, to define an alert on entries that have 4xx status codes under the 'status' field, enter
        'status.numeric:[400 TO 499]'.</li>
      <li>To define an alert when a regular expression matches a value, wrap your regex with '/' and use it as the
        expression for the field. For example, to define an alert the regions west-europe-1, west-europe-2, west-us-1,
        west-us-2, enter 'region:/west-(europe|us)-[12]/'.
      </li>
      <li>To specify a more complex alert criteria, use the Boolean operators AND, OR, and NOT. For example, to define
        an alert on entries that have 4xx status codes and have an extension of php, enter 'status.numeric:[400 TO 499]
        AND extension:php'.
      </li>
      <li><a
          href="https://www.elastic.co/guide/en/elasticsearch/reference/6.3/query-dsl-query-string-query.html"
          target="_blank"
          rel="noopener"
        > To specify specific terms for the alert, use Elasticsearch's Query String Query
          syntax</a></li>
    </ul>
    <div class="regex-tooltip-content-section">
      <p>Example: define an alert on logs from your production with status codes 5xx not originating from west-europe or
        west-us, use this expression:</p>
      <p><em>environment:production AND status.numeric:[400 TO 499] NOT region:/west-(europe|us)-[12]/</em></p>
    </div>
  </span>
</div>