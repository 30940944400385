import { OnInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { SeveritiesHelper } from '@shared/services/severities.helper';

@Directive({ selector: '[shSeverity]' })
export class SeverityDirective implements OnInit {
  @Input('severity')
  private severity: number;
  @Input('setBackground')
  private setBackground: boolean = true;

  constructor(private el: ElementRef, private render: Renderer2) {}

  public ngOnInit(): void {
    const color = SeveritiesHelper.getEventSeverityAlertColor(this.severity);
    const label = SeveritiesHelper.getEventSeverityById(this.severity);
    if (this.setBackground) {
      this.el.nativeElement.style.backgroundColor = color;
      this.el.nativeElement.innerText = label;
    } else {
      this.el.nativeElement.style.color = color;
      this.el.nativeElement.style.borderColor = color;
    }
  }
}
