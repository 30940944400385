
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CoralogixSidebarComponent } from '@app/shared/popups/coralogix-sidebar/coralogix-sidebar.component';
import { getIsS3PermissionsEditorOpen, State, getArchivingProvider, getArchivingProvidersTestingResult, getArchivingProvidersTesting } from '@app/app.reducers';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ReindexS3BucketForm } from '@app/roi/reindex/models/reindex-s3-bucket-form';
import { ReindexEditorActions } from '../../state/actions/reindex-editor.actions';
import { FormGroup } from '@angular/forms';
import { ArchivingProvidersActions } from '@app/settings/reducer/archiving-providers/archiving-providers.action';
import { ArchivingProviderModel } from '@app/settings/shared/models/archivingProviderModel';
import { ArchivingProvidersReducer } from '@app/settings/reducer/archiving-providers/archiving-providers.reducer';
import TestingResultState = ArchivingProvidersReducer.TestingResultState;

@Component({
  selector: 'sh-reindex-s3-form-container',
  templateUrl: './reindex-s3-form-container.component.html',
  styleUrls: ['./reindex-s3-form-container.component.scss']
})
export class ReindexS3FormContainerComponent implements OnInit, OnDestroy {
  @ViewChild('sidebarComponent', { static: true })
  public sidebar: CoralogixSidebarComponent;
  public form: FormGroup;

  public regionOptions: {id: string, name: string}[] = [
    { id: 'eu-west-1', name: 'EU (Ireland)' },
    { id: 'ap-south-1', name: 'Asia Pacific (Mumbai)' },
    { id: 'us-east-1', name: 'US East (N. Virginia)' },
    { id: 'us-east-2', name: 'US East (Ohio)' },
    { id: 'us-west-1', name: 'US West (N. California)' },
    { id: 'us-west-2', name: 'US West (Oregon)' },
    { id: 'eu-central-1', name: 'EU (Frankfurt)' },
    { id: 'ca-central-1', name: 'Canada (Central)' },
    { id: 'eu-west-2', name: 'EU (London)' },
    { id: 'eu-west-3', name: 'EU (Paris)' },
    { id: 'eu-north-1', name: 'EU (Stockholm)' },
    { id: 'ap-east-1', name: 'Asia Pacific (Hong Kong)' },
    { id: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)' },
    { id: 'ap-northeast-2', name: 'Asia Pacific (Seoul)' },
    { id: 'ap-northeast-3', name: 'Asia Pacific (Osaka-Local)' },
    { id: 'ap-southeast-1', name: 'Asia Pacific (Singapore)' },
    { id: 'ap-southeast-2', name: 'Asia Pacific (Sydney)' },
    { id: 'sa-east-1', name: 'South America (São Paulo)' }
  ];

  public archivingProviders$: Observable<any>;
  public testing$: Observable<boolean>;
  public testingResult$: Observable<TestingResultState>;
  private editorSubscription: Subscription;
  private editorCloseSubscription: Subscription;

  constructor(private store: Store<State>, private reindexS3BucketForm: ReindexS3BucketForm) {
    this.archivingProviders$ = this.store.select(getArchivingProvider);
    this.testingResult$ = this.store.select(getArchivingProvidersTestingResult);
    this.testing$ = this.store.select(getArchivingProvidersTesting);
   }

  public ngOnInit(): void {
    this.editorSubscription = this.store.select(getIsS3PermissionsEditorOpen).subscribe(toggle => {
      if (toggle === true) {
        this.form = this.reindexS3BucketForm.buildForm(this.reindexS3BucketForm.defaultValues);
        this.sidebar.showSidebar();
      } else {
        this.sidebar.hideSidebar();
      }
    });
    this.editorCloseSubscription = this.sidebar.close.subscribe(() => {
      this.store.dispatch(new ReindexEditorActions.TogglePermissionsEditorAction(false));
    });
  }

  public submit(event: any): void {
    const { bucket, region } = event;
    const newArchiveProvider = {
      region,
      name: bucket,
      provider: 'S3',
      active: true,
    };
    this.store.dispatch( new ArchivingProvidersActions.SaveArchivingProvidersAction(newArchiveProvider));
    this.store.dispatch(new ReindexEditorActions.TogglePermissionsEditorAction(false));
  }

  public testArchivingProvider(event: ArchivingProviderModel): void {
    this.store.dispatch(new ArchivingProvidersActions.TestArchivingProviderAction(event));
  }

  public ngOnDestroy(): void {
    this.editorSubscription.unsubscribe();
    this.editorCloseSubscription.unsubscribe();
    this.store.dispatch(new ReindexEditorActions.TogglePermissionsEditorAction(false));
  }
}
