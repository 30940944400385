import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-mobile-app-menu',
  templateUrl: './mobile-app-menu.component.html',
  styleUrls: ['./mobile-app-menu.component.scss'],
})
export class MobileAppMenuComponent {
  @Input()
  public isUserHasAlertPremittions: boolean;
}
