<div class="selected-rows-container" *ngIf="selectedLogs$.length; else noSelection">
  <div class="rows-length">{{selectedLogs$.length}} Highlighted logs:</div>
  <div class="rows-block">
    <div *ngFor="let log of selectedLogs$"
      title="Link to Log"
      class="row-badge"
      [ngClass]="{ 'active': log.isFocused, 'disabled': log.isDisabled }"
      (click)="setFocusedLog.emit(log)">
      {{log.index}}
    </div>
  </div>
  <div class="rows-clear" (click)="clearSelectedLogs.emit()">Clear All</div>
</div>
<ng-template #noSelection>
  <div class="selected-rows-container">
    Hold {{selectKey}} + click to highlight logs
  </div>
</ng-template>
