import { Component, Input } from '@angular/core';
import { ConfigurationService } from '@app/services/configuration.service';

@Component({
  selector: 'sh-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent {
  public signUpUrl: string = this.configService.getConfigValue('signup', '');
  @Input() public showSignUpText: boolean = true;
  constructor(private configService: ConfigurationService) {
  }
}
