import { AbstractControl } from '@angular/forms';

export class EmailValidator {
  public static arrayEmailValidator(
    control: AbstractControl,
  ): { [s: string]: boolean } {
    const r = new RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{1,63}$');
    if (control && control.value && control.value[0]) {
      const arr: string[] = control.value;
      const len = arr ? arr.length : 0;

      const match = (control.value[len - 1] as string).match(r);
      // console.log(match);
      if (match && match.length > 0) {
        // valid
        return null;
      }
      return { example: true };
    } else {
      return null;
    }
  }

  public static emailValidationPattern(): RegExp {
    return new RegExp('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
  }
}
