import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class LogColumn {
  @jsonMember
  public id: number;
  @jsonMember
  public headerName: string;
  @jsonMember
  public fieldName: string;
}
