import { ColDef } from 'ag-grid';

import { MasterFilterComponent } from '@shared/controls/grid-filters/master-filter/master-filter.component';
import { TimestampCellComponent } from '@shared/controls/grid-cells';

export class LogsGridColumnDef {
  public static defaultColDefs: { [key: string]: ColDef; } = {
    'coralogix.timestamp': {
      headerName: 'Timestamp',
      field: 'timestamp',
      cellRendererFramework: TimestampCellComponent,
      width: 200,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.metadata.severity': {
      headerName: 'Severity',
      field: 'metadata.severity',
      cellRenderer(params: any): HTMLElement | string {
        if (params.value) {
          const element = document.createElement('div');
          element.className = params.value.toLowerCase();
          element.appendChild(document.createTextNode(params.value));
          return element;
        }
        return null;
      },
      // headerCellTemplate: ((params)=> {
      //   return params.context.groupedColumnHeaderTemplate('metadata.severity',params)
      // }).bind(this),
      width: 105,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.text': {
      headerName: 'Text',
      field: 'text',
      width: 800,
      cellStyle: { 'text-align': 'left' },
      cellRenderer(params: any): HTMLElement | string {
        let showMore;
        if (params.data.showMore) {
          // *********ShowMore*****//
          showMore = document.createElement('div');
          showMore.className = 'showMore-container';
          showMore.addEventListener('click', () => {
            params.context.onShowMore(params);
          });
          const showMoreLabel = document.createElement('span');
          showMoreLabel.className = 'showMore-label';
          showMoreLabel.innerText = 'SHOW MORE';
          showMore.appendChild(showMoreLabel);
        }

        const eButton = document.createElement('div');
        eButton.className = 'action-icon-container';
        const eIcon = document.createElement('i');
        eIcon.classList.add('fa');
        eIcon.classList.add('fa-ellipsis-h');
        eIcon.style.cssText = 'font-size:14px; margin:auto;top: 50%;';
        eButton.addEventListener('click', () => {
          params.context.textInfoClicked.emit();
        });
        eButton.appendChild(eIcon);

        const divElement = document.createElement('div');

        const text = params.context.highlightedText;
        if (text && params.data.viewObject) {
          const toSearch = new RegExp('(' + text + ')', 'gmi');
          // Create mark for each matched text and highlight it.
          divElement.innerHTML = params.data.viewObject.replace(toSearch, '<mark>$1</mark>');
          divElement.appendChild(eButton);
          return divElement;

        }
        divElement.innerHTML = params.data.viewObject;
        divElement.appendChild(eButton);
        if (showMore) {
          divElement.appendChild(showMore);
        }

        return divElement;
      },
      filterFramework: MasterFilterComponent,
      suppressSorting: true
    },
    'coralogix.metadata.applicationName': {
      headerName: 'Application',
      field: 'metadata.applicationName',
      width: 100,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.metadata.subsystemName': {
      headerName: 'Subsystem',
      field: 'metadata.subsystemName',
      width: 100,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.metadata.category': {
      headerName: 'Category',
      field: 'metadata.category',
      width: 100,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.metadata.computerName': {
      headerName: 'Computer',
      field: 'metadata.computerName',
      width: 100,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.metadata.IPAddress': {
      headerName: 'Ip address',
      field: 'metadata.IPAddress',
      width: 100,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.metadata.className': {
      headerName: 'Class',
      field: 'metadata.className',
      width: 100,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.metadata.methodName': {
      headerName: 'Method',
      field: 'metadata.methodName',
      width: 100,
      suppressFilter: true,
      cellStyle: { 'text-align': 'left' }
    },
    'coralogix.metadata.threadId': {
      headerName: 'ThreadId',
      field: 'metadata.threadId',
      width: 100,
      filterFramework: MasterFilterComponent,
      cellStyle: { 'text-align': 'left' }
    }
  };

  public static createColumnDefs(): ColDef[] {
    return [
      {
        headerName: '#',
        field: 'index',
        width: 50,
        suppressFilter: true,
        suppressSorting: true
      },
      this.defaultColDefs['coralogix.timestamp'],
      this.defaultColDefs['coralogix.metadata.severity'],
      this.defaultColDefs['coralogix.text'],
      this.defaultColDefs['coralogix.metadata.applicationName'],
      this.defaultColDefs['coralogix.metadata.subsystemName'],
    ];
  }
}
