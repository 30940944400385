import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { Metadata } from './query-metadata';
import { TypedJsonAny } from '@shared/models/typedjson/any';

@jsonObject
export class TextQuery {
  @jsonMember
  public text: string = '';
  @jsonMember
  public type: string = TEXT_TYPES.EXACT; // TEXT_TYPES
  @jsonArrayMember(String)
  public templateIds: string[] = [];
}

export class TEXT_TYPES {
  public static EXACT: string = 'exact';
  public static REGEX: string = 'regex';
}

@jsonObject
export class QueryParams {
  @jsonMember
  public query: TextQuery = new TextQuery();
  @jsonArrayMember(String)
  public templateIds?: string[] = [];
  @jsonMember
  public metadata: Metadata = new Metadata();
  @jsonMember
  public aggregationInterval?: number;
  @jsonMember(TypedJsonAny.OPTIONS)
  public jsonObject?: any = {};
  @jsonMember
  public aggField?: string;
  @jsonMember(TypedJsonAny.OPTIONS)
  public jsonAggFields?: any = [];
  @jsonMember
  public ts_agg?: string = null;
}
