import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';

import { BehaviorSubject, Subject, timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';

import { FilterPipe } from '@app/shared/pipes/filter-pipe/filter.pipe';
import { CustomFiltersProvider } from './CustomFilters.provider';
import { RegExpHelper } from '@shared/helpers/reg-exp.helper';
import { LogsViewStateProvider } from '@app/logs/shared/services/logsViewState.provider';

export interface IFilterRowTitlePart {
  text: string;
  isHighlighted?: boolean;
}

export interface IFilterRow {
  id?: string;
  name?: string;
  count?: number;
  titleParts?: IFilterRowTitlePart[];
}

@Component({
  selector: 'sh-logs-filter-category',
  templateUrl: 'logs-filter-category.component.html',
  styleUrls: ['logs-filter-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LogsFilterCategoryComponent),
      multi: true,
    },
  ],
})
export class LogsFilterCategoryComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  private static readonly KEY_UP_DEBOUNCE_TIME: number = 500;

  @Input() public caption: string;

  @Input() set filterString(val: string) {
    this._filterString = val;
    this.filter();
    this.filterEmptyRows();
  }

  @Input() set categoryAggregation(val: any) {
    if (val) {
      this.updateRows(val);
    }
  }

  @Input() set initialNumberOfItemsToDisplay(val: number) {
    if (this._initialNumberOfItemsToDisplay <= 0) {
      return;
    }
    this._initialNumberOfItemsToDisplay = val;
  }

  @Output() public selectedValueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public customFilters: any[] = [];
  public filterRowsWithAutocomplete: IFilterRow[] = [];
  public addFilterText: string;
  public _selectedValues: any[] = [];
  public showMore: boolean = false;

  protected _filterString: string = '';
  protected _categoryAggregation: any[] = [{}];
  protected filterPipe: FilterPipe;

  private _initialNumberOfItemsToDisplay: number = 5;
  private _inputText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _destroyed$: Subject<void> = new Subject();
  private _autoCompleteText: string = '';
  private _filterRows: IFilterRow[] = [];

  constructor(protected customFiltersProvider: CustomFiltersProvider,
              protected _cd: ChangeDetectorRef,
              protected logsViewStateProvider: LogsViewStateProvider) {
    this.filterPipe = new FilterPipe();
  }

  get initialNumberOfItemsToDisplay(): number {
    return this._initialNumberOfItemsToDisplay;
  }

  get filterString(): string {
    return this._filterString;
  }

  get categoryAggregation(): any {
    return this._categoryAggregation;
  }

  get selectedValues(): any[] {
    return this._selectedValues;
  }

  set selectedValues(val: any[]) {
    if (!val) {
      val = [];
    }
    this._selectedValues = val;
    this._updateFilterRowsWithAutocomplete();
  }

  public addCustomFilter(filterName: string): void {
    if (filterName) {
      this.addFilterText = '';
      if (this.customFilters.indexOf(filterName) < 0 && !this.isRowValueExist(filterName)) {
        this.customFilters.push(filterName);
        this.toggleSelected(filterName);
      } else {
        this.toggleSelected(filterName);
      }
    }
  }

  public ngAfterViewInit(): void {
    this.logsViewStateProvider.onAfterDynamicFiltersInit.emit();
  }

  public ngOnInit(): void {
    this.filterString = '';
    this.customFilters = this.customFiltersProvider.getCustomFilters(this.caption) || [];
    this._inputText$
      .pipe(
        takeUntil(this._destroyed$),
        debounce(() => timer(LogsFilterCategoryComponent.KEY_UP_DEBOUNCE_TIME)),
      )
      .subscribe(this._applyAutoComplete.bind(this));
  }

  public ngOnDestroy(): void {
    if (this.customFilters) {
      // this.customFilters = this.customFilters.filter(f => this.selectedValues.indexOf(f) !== -1);
      this.customFiltersProvider.saveCustomFilters(this.caption, this.customFilters);
    }
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public get filterRows(): IFilterRow[] {
    return this._filterRows;
  }

  public set filterRows(rows: IFilterRow[]) {
    this._filterRows = rows;
    this._updateFilterRowsWithAutocomplete();
  }

  public onCheckboxMouseLeave(event: MouseEvent, tooltip: MatTooltip): void {
    tooltip.hide(0);
  }

  public onTextInputKeyUp(event: KeyboardEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
    this._inputText$.next((event.target as HTMLInputElement).value);
  }

  public getRowTitle(row: IFilterRow): string {
    if (!row.name || !row.name.length) {
      return 'NO_' + this.caption.toUpperCase();
    }
    return row.name;
  }

  // Applying filters and sorting the array
  public updateRows(val: any = {}): void {
    if (!val) {
      return;
    }

    this._categoryAggregation = this.sort(val);

    this.filter();
    this.filterEmptyRows();
  }

  //////// ControlValueAccessor imp //////////

  public writeValue(value: any): void {
    if (this.caption === 'msg' || this.caption === 'Application') {
      // console.log(value);
      // console.log(this.filterRows);
    }
    this.selectedValues = value;
  }

  public propagateChange = (_: any) => {
    /* do nothing */
  }

  public updateCustomFilters(value: any): void {
    if (value && Array.isArray(value)) {
      if (this.filterRows) {
        value.forEach((v) => {
          const filter = this.filterRows.find((f) => f.name === v);
          if (!filter && this.customFilters.indexOf(v) === -1) {
            this.customFilters.push(v);
          }
        });
      }
    }
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    /* do nothing */
  }

  public toggleSelected(item: any): void {
    if (!item) {
      return;
    }

    const checked: boolean = this.selectedValues.indexOf(item) === -1;
    if (this.selectedValues.indexOf(item) === -1) {
      this.selectedValues.push(item);
    } else {
      this.selectedValues.splice(this.selectedValues.indexOf(item), 1);
    }

    this.propagateChange(this._selectedValues);
    this.selectedValueChange.emit([this._selectedValues, checked]);

    let total = 0;
    this._selectedValues.forEach((x) => (total += this._categoryAggregation[x]));
  }

  public toggleShowMore(): void {
    this.showMore = !this.showMore;
  }

  public clearSelection(): void {
    this.selectedValues = [];
    this.propagateChange(this._selectedValues);
    this.selectedValueChange.emit([this._selectedValues, false]);
  }

  protected filter(): void {
    this.filterRows = this.filterPipe.transform(this._categoryAggregation, this._filterString, 'name');
    if (this._categoryAggregation && this._categoryAggregation.length > 0) {
      this.updateCustomFilters(this.selectedValues);
    }
  }

  protected sort(val: any): any[] {
    const arr = [];
    Object.keys(val).map((x) => {
      arr.push([x, val[x]]);
    });
    arr.sort((a, b) => {
      // Two values are checked
      if (this.selectedValues.indexOf(a[0]) >= 0 && this.selectedValues.indexOf(b[0]) >= 0) {
        // inner sort count
        if (a[1] < b[1]) {
          return 1;
        } else if (a[1] > b[1]) {
          return -1;
        } else {
          // sort by name
          if (a[0].toLowerCase() < b[0].toLowerCase()) {
            return -1;
          } else if (a[0].toLowerCase() > b[0].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
        // one value is checked
      } else if (this.selectedValues.indexOf(a[0]) >= 0) {
        return -1;
      } else if (this.selectedValues.indexOf(b[0]) >= 0) {
        return 1;
      } else if (a[1] < b[1]) {
        return 1;
      } else if (a[1] > b[1]) {
        return -1;
      } else {
        if (a[0].toLowerCase() < b[0].toLowerCase()) {
          return -1;
        } else if (a[0].toLowerCase() > b[0].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    const sortedObject: any[] = [];
    arr.map((x) => {
      sortedObject.push({ name: x[0], count: x[1] });
    });
    return sortedObject;
  }

  protected filterEmptyRows(): void {
    if (this.filterRows) {
      this.filterRows = this.filterRows.filter((x) => x && x.name);
    }
  }

  private _applyAutoComplete(value: string): void {
    if (value === this._autoCompleteText) {
      return;
    }
    this._autoCompleteText = value;
    this._updateFilterRowsWithAutocomplete();
  }

  private _updateFilterRowsWithAutocomplete(): void {
    if (!this._autoCompleteText) {
      this.filterRowsWithAutocomplete = this.filterRows;
      this.filterRowsWithAutocomplete.forEach((item: any): void => {
        delete item.titleParts;
      });
    } else {
      const regExp = new RegExp(RegExpHelper.escapeRegExp(this._autoCompleteText), 'im');
      this.filterRowsWithAutocomplete = this.filterRows.filter((item: any): boolean => {
        delete item.titleParts;
        if (this.selectedValues.indexOf(item.id || item.name) > -1) {
          return true;
        }
        if (regExp.test(item.name)) {
          item.titleParts = this._getRowTitleParts(item);
          return true;
        }
        return false;
      });
    }
    this._cd.markForCheck();
  }

  private _getRowTitleParts(row: IFilterRow): IFilterRowTitlePart[] {
    const parts: IFilterRowTitlePart[] = [];

    const regExp = new RegExp(RegExpHelper.escapeRegExp(this._autoCompleteText), 'gim');

    let lastProcessedIndex = 0;
    let match = regExp.exec(row.name);
    while (match !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (match.index === regExp.lastIndex) {
        regExp.lastIndex++;
      }

      parts.push({
        text: row.name.substr(lastProcessedIndex, match.index - lastProcessedIndex),
      });
      lastProcessedIndex += match.index - lastProcessedIndex;

      match = regExp.exec(row.name);

      parts.push({
        text: row.name.substr(lastProcessedIndex, this._autoCompleteText.length),
        isHighlighted: true,
      });
      lastProcessedIndex += this._autoCompleteText.length;

      if (match === null) {
        parts.push({
          text: row.name.substr(lastProcessedIndex),
        });
      }
    }

    return parts;
  }

  private isRowValueExist(filterName: string): boolean {
    for (const rowValue of this.filterRowsWithAutocomplete) {
      if (rowValue.name === filterName) {
        return true;
      }
    }
    return false;
  }
}
