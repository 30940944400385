import { Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import { SnoozeInfo } from '@app/alerts/shared/models/snooze.model';
import { getDateFormatViewPreference, getTimezoneViewPreference, State } from '@app/app.reducers';
import { Alert } from '@app/insights/shared/models/alert.model';
import { AnomalySubTypeId } from '@app/insights/shared/models/enums';
import { Insight } from '@app/insights/shared/models/insight.model';
import { IPredefinedQuickTime, SnoozeQuickTimes } from '@app/insights/shared/models/snooze-quick-times.model';
import { SnoozeAlert, UnsnoozeAlert } from '@app/ngxs-store/alerts/alerts-snooze.action';
import { AlertsState } from '@app/ngxs-store/alerts/alerts-snooze.state';
import { DateFormatType } from '@app/shared/models/date-format-types';
import { TimeZoneType } from '@app/shared/models/timezone-types';
import { Store } from '@ngrx/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import _ from 'lodash';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'sh-snooze-button',
  templateUrl: './snooze-button.component.html',
  styleUrls: ['./snooze-button.component.scss'],
})
export class SnoozeButtonComponent implements OnDestroy {
  @Input()
  public alert: Alert;
  @ViewChild('snoozeMenuTrigger')
  public snoozeMenuTrigger: MatMenuTrigger;
  @ViewChild('snoozeCheckbox')
  public snoozeCheckbox: MatCheckbox;

  public timeZone: TimeZoneType;
  public dateFormatType: DateFormatType;
  public timezone$ = this.store.select(getTimezoneViewPreference);
  public dateFormatType$ = this.store.select(getDateFormatViewPreference);
  public showSnoozeTooltip: boolean = false;
  public predefinedQuickTimes: IPredefinedQuickTime[] = new SnoozeQuickTimes().predefinedQuickTimes;
  @Select(AlertsState.snoozeData) public snoozeData$: Observable<{[id: string]: SnoozeInfo}>;
  @SelectSnapshot(AlertsState.snoozeData) public snoozeData: {[id: string]: SnoozeInfo};
  @Select(AlertsState.loading) public snoozeLoading$: Observable<{[id: string]: boolean}>;

  private subscriptions: Subscription[] = [];

  constructor(private store: Store<State>) {
    this.subscriptions.push(this.timezone$.subscribe((timezone) => (this.timeZone = timezone)));
    this.subscriptions.push(this.dateFormatType$.subscribe((dateFormat) => (this.dateFormatType = dateFormat)));
  }

  public snoozeAlertClick(seconds: number): void {
    this.snoozeAlert(seconds);
  }

  public isSnoozed(snoozeData: {[id: string]: SnoozeInfo}): boolean {
    const snoozeEndTimestamp = snoozeData[this.alert?.alertId]?.snoozeEndTimestamp;
    return !_.isNil(snoozeEndTimestamp) && snoozeEndTimestamp > moment().utc().valueOf();
  }

  public snoozeEndFormat(snoozeData: {[id: string]: SnoozeInfo}): string {
    const snoozeEndTimestamp = snoozeData[this.alert?.alertId]?.snoozeEndTimestamp;
    if (!_.isNil(snoozeEndTimestamp)) {
      const dateFormat = this.dateFormatType === DateFormatType.american ? 'MM/DD/YYYY, ' : 'DD/MM/YYYY, ';
      const keepLocalTime = this.timeZone === TimeZoneType.local;
      return moment(snoozeEndTimestamp).utc(keepLocalTime).format(dateFormat + 'HH:mm');
    } else {
      return '';
    }
  }

  public getSnoozingUser(snoozeData: {[id: string]: SnoozeInfo}): string {
    const snoozingUser = snoozeData[this.alert?.alertId]?.userId;
    return snoozingUser;
  }

  public snoozeToggleClick(): void {
      if (this.isSnoozed(this.snoozeData)) {
        this.unsnoozeAlert();
      } else {
        this.snoozeMenuTrigger.openMenu();
      }
  }

  public isCurrentAlertSnoozeLoading(loadingState: {[id: string]: boolean}): boolean {
    return !_.isNil(loadingState[this.alert.alertId]) && loadingState[this.alert.alertId];
  }

  public isAnomaly(insight: Insight): boolean {
    return insight.subTypeId === AnomalySubTypeId.FlowAnomaly ||
      insight.subTypeId === AnomalySubTypeId.VolumeAnomaly;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  @Dispatch() public snoozeAlert = (seconds: number) => new SnoozeAlert(this.alert.alertId, seconds);

  @Dispatch() public unsnoozeAlert = () => new UnsnoozeAlert(this.alert.alertId);
}
