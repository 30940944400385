import { type } from '@shared/services/utils';
import { Action } from '@ngrx/store';
import {
  Reindex,
  ReindexRequest,
  ReindexBucketPermission,
  RetryResponse,
  CreateCachedQueryResponse
} from '@app/roi/reindex/models/reindex.model';

export namespace ReindexActions {
  export const ActionTypes = {
    GET_REINDEXES_STATUS: type('[Reindex] get reindexes status'),
    GET_REINDEXES_STATUS_COMPLETED: type('[Reindex] get reindexes status completed'),
    GET_REINDEXES_STATUS_FAILED: type('[Reindex] get reindexes status failed'),
    CREATE_REINDEX: type('[Reindex] create'),
    CREATE_REINDEX_COMPLETED: type('[Reindex] create completed'),
    CREATE_REINDEX_FAILED: type('[Reindex] failed'),
    GET_REINDEXES: type('[Reindex] get all'),
    GET_REINDEXES_COMPLETED: type('[Reindex] get all completed'),
    GET_REINDEXES_FAILED: type('[Reindex] get all failed'),
    GET_BUCKET_PERMISSIONS: type('[Reindex] Get bucket permissions'),
    GET_BUCKET_PERMISSIONS_COMPLETED: type('[Reindex] Get bucket permissions completed'),
    GET_BUCKET_PERMISSIONS_FAILED: type('[Reindex] Get bucket permissions failed'),
    RETRY_REINDEX: type('[Reindex] Retry reindex'),
    RETRY_REINDEX_COMPLETED: type('[Reindex] Retry reindex completed'),
    RETRY_REINDEX_FAILED: type('[Reindex] Retry reindex failed'),
    CREATE_CACHED_QUERY: type('[Reindex] create cached query'),
    CREATE_CACHED_QUERY_COMPLETED: type('[Reindex] create cached query completed'),
    CREATE_CACHED_QUERY_FAILED: type('[Reindex] create cached query failed'),
    ACTIVATE_LOADING: type('[Reindex] activate loading'),
    CLEAR_LOADING: type('[Reindex] clear loading')
  };

  export class ActivateLoadingAction implements Action {
    public type = ActionTypes.ACTIVATE_LOADING;

    constructor() {}
  }

  export class ClearLoadingAction implements Action {
    public type = ActionTypes.CLEAR_LOADING;

    constructor() {}
  }

  export class GetReindexesStatusAction implements Action {
    public type = ActionTypes.GET_REINDEXES_STATUS;

    constructor(public payload: string[]) {}
  }

  export class GetReindexesStatusCompletedAction implements Action {
    public type = ActionTypes.GET_REINDEXES_STATUS_COMPLETED;

    constructor(public payload: any) {}
  }

  export class GetReindexesStatusFailedAction implements Action {
    public type = ActionTypes.GET_REINDEXES_STATUS_FAILED;

    constructor(public payload: any) {}
  }
  export class CreateReindexAction implements Action {
    public type = ActionTypes.CREATE_REINDEX;

    constructor(public payload: ReindexRequest) {}
  }

  export class CreateReindexCompletedAction implements Action {
    public type = ActionTypes.CREATE_REINDEX_COMPLETED;

    constructor(public payload: Reindex) {}
  }

  export class CreateReindexFailedAction implements Action {
    public type = ActionTypes.CREATE_REINDEX_FAILED;

    constructor(public payload: any) {}
  }

  export class GetReindexesAction implements Action {
    public type = ActionTypes.GET_REINDEXES;

    constructor(public payload: any) {}
  }

  export class GetReindexesCompletedAction implements Action {
    public type = ActionTypes.GET_REINDEXES_COMPLETED;

    constructor(public payload: Reindex[]) {}
  }

  export class GetReindexesFailedAction implements Action {
    public type = ActionTypes.GET_REINDEXES_FAILED;

    constructor(public payload: any) {}
  }

  export class GetBucketPermissionsAction implements Action {
    public type = ActionTypes.GET_BUCKET_PERMISSIONS;

    constructor() {}
  }
  export class GetBucketPermissionsCompletedAction implements Action {
    public type = ActionTypes.GET_BUCKET_PERMISSIONS_COMPLETED;

    constructor(public payload: ReindexBucketPermission) {}
  }
  export class GetBucketPermissionsFailedAction implements Action {
    public type = ActionTypes.GET_BUCKET_PERMISSIONS_FAILED;

    constructor(public payload: ReindexBucketPermission) {}
  }

  export class RetryReindex implements Action {
    public type = ActionTypes.RETRY_REINDEX;

    constructor(public payload: Reindex) {}
  }

  export class RetryReindexCompeleted implements Action {
    public type = ActionTypes.RETRY_REINDEX_COMPLETED;

    constructor(public payload: RetryResponse) {}
  }

  export class RetryReindexFailed implements Action {
    public type = ActionTypes.RETRY_REINDEX_FAILED;

    constructor(public payload: any) {}
  }

  export class CreateCachedQuery implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY;

    constructor(public payload: string) {}
  }

  export class CreateCachedQueryCompleted implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY_COMPLETED;

    constructor(public payload: CreateCachedQueryResponse) {}
  }

  export class CreateCachedQueryFailed implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY_FAILED;

    constructor(public payload: any) {}
  }

  export type Actions =
    GetReindexesStatusAction |
    GetReindexesStatusCompletedAction |
    GetReindexesStatusFailedAction |
    CreateReindexAction |
    CreateReindexCompletedAction |
    CreateReindexFailedAction |
    GetReindexesAction |
    GetReindexesCompletedAction |
    GetReindexesFailedAction |
    GetBucketPermissionsAction |
    GetBucketPermissionsCompletedAction |
    GetBucketPermissionsFailedAction |
    RetryReindex |
    RetryReindexCompeleted |
    RetryReindexFailed |
    CreateCachedQuery |
    CreateCachedQueryCompleted |
    CreateCachedQueryFailed |
    ActivateLoadingAction |
    ClearLoadingAction;
}
