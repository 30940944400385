import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssignOrUnAssignEnum, Teammate } from '../models/teammate';
import { Constants } from '../../../constants';
import { TeammateResult } from '../models/teammate.restuls';
import { TypedJSON } from 'typedjson';

@Injectable()
export class TeamUsersService {
  constructor(private http: HttpClient) {
  }

  public getTeammates(): Observable<Teammate[]> {
    return this.http
      .get<TeammateResult>(Constants.teammates)
      .map((res: TeammateResult) => TypedJSON.parse(res, TeammateResult).data);
  }

  public deleteTeammate(teammate_id: string): Observable<string> {
    return this.http.delete(Constants.teammates + '/' + teammate_id).mapTo(teammate_id);
  }

  public changeRole([teammate_id, role]: [string, number]): Observable<any> {
    return this.http.put(Constants.teammates + '/' + teammate_id, { role }).mapTo([teammate_id, role]);
  }

  public me(): Observable<Teammate> {
    return this.http
      .get(Constants.teammates + '/me')
      .map((res: any) => {
        return res;
      })
      .map((teamRes: any) => teamRes.data[0]);
  }

  public updateTeammateGroup(companyId: string, userId: string, type: AssignOrUnAssignEnum, data: { groupId: string }): any {
    const requestOptions = { responseType: 'text' as any };
    const addOrRemove = type === 'assign' ? 'addToGroup' : 'removeFromGroup';
    return this.http.post<string>(`${Constants.groupRbacUrl}/${companyId}/users/${userId}/${addOrRemove}`, data, requestOptions);
  }

  public bulkUpdateTeammateGroups(companyId: string, userId: string, type: AssignOrUnAssignEnum, data: { groupsIds: string[] }): any {
    const requestOptions = { responseType: 'text' as any };
    const addOrRemove = type === 'assign' ? 'addUserToGroups' : 'removeUserFromGroups';
    return this.http.post<string>(`${Constants.groupRbacUrl}/${companyId}/users/${userId}/${addOrRemove}`, data, requestOptions);
  }
}
