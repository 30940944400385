import { ClipboardService } from './../../../../shared/services/clipboard-service';
import { ReindexActions } from '@app/roi/reindex/state/actions/reindex.actions';
import { Reindex, ReindexBucketPermission } from '@app/roi/reindex/models/reindex.model';
import * as _ from 'lodash';

export namespace ReindexReducer {
  export interface State {
    reindexes: Reindex[];
    loading: boolean;
    permissions: ReindexBucketPermission;
    permErr: boolean;
    creationError: string;
  }

  const initialState: State = {
    reindexes: [],
    loading: false,
    permissions: null,
    permErr: false,
    creationError: '',
  };

  export function reducer(
    state: ReindexReducer.State = initialState,
    action: ReindexActions.Actions,
  ): ReindexReducer.State {
    switch (action.type) {
      case ReindexActions.ActionTypes.ACTIVATE_LOADING:
        return { ...state, ...{ loading: true } };
      case ReindexActions.ActionTypes.CLEAR_LOADING:
        return { ...state, ...{ loading: false } };
      case ReindexActions.ActionTypes.GET_REINDEXES_STATUS:
        return { ...state };
      case ReindexActions.ActionTypes.GET_REINDEXES_STATUS_COMPLETED:
        if (action.payload?.length === 0) {
          return { ...state };
        }
        const updatedReindexes = _.values({
          ..._.keyBy(state.reindexes, 'id'),
          ..._.keyBy(action.payload, 'id'),
        }) as Reindex[];
        return { ...state, ...{ reindexes: updatedReindexes } };
      case ReindexActions.ActionTypes.GET_REINDEXES_STATUS_FAILED:
        return { ...state, creationError: action.payload };
      case ReindexActions.ActionTypes.CREATE_REINDEX:
        return { ...state, ...{ loading: true, creationError: '' } };
      case ReindexActions.ActionTypes.CREATE_REINDEX_COMPLETED:
        return { ...state, ...{ loading: false, reindexes: [...state.reindexes, action.payload], creationError: '' } };
      case ReindexActions.ActionTypes.CREATE_REINDEX_FAILED:
        return { ...state, ...{ loading: false, creationError: action.payload } };
      case ReindexActions.ActionTypes.GET_REINDEXES:
        return { ...state, ...{ loading: true } };
      case ReindexActions.ActionTypes.GET_REINDEXES_COMPLETED:
        return { ...state, ...{ loading: false, reindexes: action.payload } };
      case ReindexActions.ActionTypes.GET_REINDEXES_FAILED:
        return { ...state, ...{ loading: false } };
      case ReindexActions.ActionTypes.GET_BUCKET_PERMISSIONS:
        return { ...state, ...{ loading: true } };
      case ReindexActions.ActionTypes.GET_BUCKET_PERMISSIONS_COMPLETED:
        return { ...state, ...{ loading: false, permErr: false, permissions: action.payload } };
      case ReindexActions.ActionTypes.GET_BUCKET_PERMISSIONS_FAILED:
        return { ...state, ...{ loading: false, permErr: true, permissions: action.payload } };
      case ReindexActions.ActionTypes.RETRY_REINDEX:
        return { ...state, ...{ loading: true, creationError: '' } };
      case ReindexActions.ActionTypes.RETRY_REINDEX_COMPLETED:
        const oldReindexIdx = state.reindexes.findIndex((m) => m.id === action.payload.failedReindexId);
        if (oldReindexIdx > -1) {
          return {
            ...state,
            ...{ loading: false, creationError: '' },
            ...{
              reindexes: [
                ...state.reindexes.slice(0, oldReindexIdx),
                action.payload.newReindex,
                ...state.reindexes.slice(oldReindexIdx + 1, state.reindexes.length),
              ],
            },
          };
        }
        return {
          ...state,
          ...{ loading: false, creationError: '' },
          ...{ reindexes: [...state.reindexes, action.payload.newReindex] },
        };
      case ReindexActions.ActionTypes.RETRY_REINDEX_FAILED:
        return { ...state, ...{ loading: false, creationError: action.payload } };
      case ReindexActions.ActionTypes.CREATE_CACHED_QUERY:
        return { ...state, ...{ loading: true } };
      case ReindexActions.ActionTypes.CREATE_CACHED_QUERY_COMPLETED:
      case ReindexActions.ActionTypes.CREATE_CACHED_QUERY_FAILED:
        return { ...state, ...{ loading: false } };
      default:
        return state;
    }
  }
}
