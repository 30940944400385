<form
  [class.hidden]="true"
  ngNoForm
  action="https://www.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
  method="POST"
>
  <input type="text" name="oid" value="00D410000013Odk" />
  <input type="text" name="retURL" [attr.value]="retURL" />
  <input
    id="first_name"
    #fake_firstName
    maxlength="40"
    name="first_name"
    size="20"
    type="text"
    value=""
  />
  <input
    id="last_name"
    #fake_lastName
    maxlength="80"
    name="last_name"
    size="20"
    type="text"
    value=""
  />
  <input
    id="email"
    #fake_email
    maxlength="80"
    name="email"
    size="20"
    type="text"
    value=""
  />
  <input
    id="company"
    #fake_company
    maxlength="40"
    name="company"
    size="20"
    type="text"
    value=""
  />
  <input
    id="phone"
    #fake_phone
    maxlength="40"
    name="phone"
    size="20"
    type="text"
    value=""
  />
  <input id="city" maxlength="40" name="city" size="20" type="text" value="" />
  <input
    id="state"
    maxlength="20"
    name="state"
    size="20"
    type="text"
    value=""
  />
  <input type="submit" #fakeFormSubmitButton name="submit" />
</form>
