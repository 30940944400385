<sh-base-dialog [hideCloseBtn]="data.hideCloseBtn" [width]="data.width" [height]="data.height" (close)="onClose()">
  <sh-dialog-title *ngIf="data.title">
    <div [innerHtml]="data.title"></div>
  </sh-dialog-title>

  <sh-dialog-body>
    <div [innerHtml]="data.body"></div>
  </sh-dialog-body>

  <sh-dialog-actions>
    <cgx-button stroked color="text-secondary" size="sm" [label]="data.secondaryActionLabel" (press)="onClose()"></cgx-button>
    <cgx-button
      [color]="data.primaryActionColor"
      size="sm"
      [label]="data.primaryActionLabel"
      [disabled]="loading"
      [loading]="loading"
      (press)="onApply()"
    ></cgx-button>
  </sh-dialog-actions>
</sh-base-dialog>
