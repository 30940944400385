import { Injectable } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import { Entity } from '@shared/models/entity.model';
import { IAlertRelativeTimeOption } from '@app/alerts/alerts-editor/models/alert-relative-time-option';

enum UTCTranformEnum {
  toUtc = -1,
  fromUtc = 1
}

@Injectable()
export class AlertHelper {
  public alertIsoWeekdayOptions: Entity[] = [
    { id: 0, name: 'Monday' },
    { id: 1, name: 'Tuesday' },
    { id: 2, name: 'Wednesday' },
    { id: 3, name: 'Thursday' },
    { id: 4, name: 'Friday' },
    { id: 5, name: 'Saturday' },
    { id: 6, name: 'Sunday' },
  ];

  public limitTriggeringOptions: Entity[] = [
    { id: 0, name: 'Always active' },
    { id: 1, name: 'Limit triggering' },
  ];

  public conditionsOperatorOptions: Entity[] = [
    { id: 0, name: 'Notify immediately' },
    { id: 1, name: 'Less' },
    { id: 3, name: 'More' },
    { id: 4, name: 'More than usual' },
  ];

  public conditionTimeframeOptions: Entity[] = [
    { id: 5, name: '5 Minutes' },
    { id: 10, name: '10 Minutes' },
    { id: 30, name: '30 Minutes' },
    { id: 60, name: '1 Hours' },
    { id: 120, name: '2 Hours' },
    { id: 240, name: '4 Hours' },
    { id: 360, name: '6 Hours' },
    { id: 720, name: '12 Hours' },
    { id: 1440, name: '24 Hours' },
    { id: 2160, name: '36 Hours' },
  ];

  public conditionTimeframeMetricAlertOptions: Entity[] = [
    { id: 1, name: '1 Minute' },
    { id: 5, name: '5 Minutes' },
    { id: 10, name: '10 Minutes' },
    { id: 30, name: '30 Minutes' },
    { id: 60, name: '1 Hours' },
    { id: 120, name: '2 Hours' },
    { id: 240, name: '4 Hours' },
    { id: 360, name: '6 Hours' },
    { id: 720, name: '12 Hours' },
    { id: 1440, name: '24 Hours' },
  ];

  public relativeTimeframeOptions: Entity[] = [
    {
      name: 'Previous hour', id: 1
    },
    {
      name: 'Same hour yesterday', id: 2
    },
    {
      name: 'Same hour last week', id: 3
    },
    {
      name: 'Yesterday', id: 4
    },
    {
      name: 'Same day last week', id: 5
    },
    {
      name: 'Same day last month', id: 6
    },
  ];

  public relativeTimeframeOptionIdMapping: { [key: number]: IAlertRelativeTimeOption; } = {
    1: {
      conditionTimeframe: 60,
      relativeTimeframe: 60
    },
    2: {
      conditionTimeframe: 60,
      relativeTimeframe: 60 * 24
    },
    3: {
      conditionTimeframe: 60,
      relativeTimeframe: 60 * 24 * 7
    },
    4: {
      conditionTimeframe: 60 * 24,
      relativeTimeframe: 60 * 24
    },
    5: {
      conditionTimeframe: 60 * 24,
      relativeTimeframe: 60 * 24 * 7
    },
    6: {
      conditionTimeframe: 60 * 24,
      relativeTimeframe: 60 * 24 * 28
    }
  };

  public relativeTimeframeSilenceDisplay: { [key: number]: string; } = {
    60: '1 Hour',
    1440: '1 Day',
    10080: '1 Week',
    40320: '1 Month',
  };

  public cardinalityTimeframeOptions: Entity[] = [
    { id: 10, name: '10 Minutes' },
    { id: 30, name: '30 Minutes' },
    { id: 60, name: '1 Hours' },
    { id: 120, name: '2 Hours' },
    { id: 240, name: '4 Hours' },
    { id: 360, name: '6 Hours' },
    { id: 720, name: '12 Hours' },
    { id: 1440, name: '24 Hours' },
  ];

  public conditionTimeFrameNewValueOptions: Entity[] = [
    { id: 720, name: '12 Hours' },
    { id: 1440, name: '24 Hours' },
    { id: 2280, name: '48 Hours' },
    { id: 4320, name: '72 Hours' },
    { id: 10080, name: '1 Week' },
    { id: 43200, name: '1 Month' },
    { id: 86400, name: '2 Months' },
    { id: 129600, name: '3 Months' },
  ];

  public conditionMetricArithmeticOptions: Entity[] = [
    { id: 0, name: 'Average' },
    { id: 1, name: 'Min' },
    { id: 2, name: 'Max' },
    { id: 3, name: 'Sum' },
    { id: 4, name: 'Count' },
    { id: 5, name: 'Percentile' }
  ];

  public alertWeekdayShortNameOptions: Entity[] = [
    { id: 0, name: 'S' },
    { id: 1, name: 'M' },
    { id: 2, name: 'T' },
    { id: 3, name: 'W' },
    { id: 4, name: 'T' },
    { id: 5, name: 'F' },
    { id: 6, name: 'S' },
  ];

  public alertSeverityOptions: Entity[] = [
    { id: 1, name: 'Info' },
    { id: 2, name: 'Warning' },
    { id: 3, name: 'Critical' },
  ];
  private sevenDays: string = '7 Days';

  private conditionTimeFrameNewValueOptionsAlertDisplay: Entity[] = [
    { id: 720, name: '12 Hours' },
    { id: 1440, name: '24 Hours' },
    { id: 2280, name: '48 Hours' },
    { id: 4320, name: '72 Hours' },
    { id: 10080, name: this.sevenDays },
    { id: 43200, name: this.sevenDays },
    { id: 86400, name: this.sevenDays },
    { id: 129600, name: this.sevenDays },
  ];

  public GetAlertSeverityCaption(severity: number): string {
    return this.getCaption(severity, this.alertSeverityOptions);
  }

  public GetConditionsOperatoCaption(operator: number): string {
    return this.getCaption(operator, this.conditionsOperatorOptions);
  }

  public GetConditionTimeFrameCaption(conditionTimeframe: number): string {
    return this.getCaption(conditionTimeframe, this.conditionTimeframeOptions);
  }

  public GetConditionTimeNewValueFrameCaption(conditionTimeframe: number): string {
    return this.getCaption(conditionTimeframe, this.conditionTimeFrameNewValueOptionsAlertDisplay);
  }

  public transformDaysToUTC(activityStarts: string, daysOfWeek: number[]): number[] {
    return this.transformDays(activityStarts, daysOfWeek, UTCTranformEnum.toUtc);
  }

  public transformDaysFromUTC(activityStarts: string, daysOfWeek: number[]): number[] {
    return this.transformDays(activityStarts, daysOfWeek, UTCTranformEnum.fromUtc);
  }

  private getCaption(value: number, arr: Array<any>): string {
    let returnValue = '';
    arr.forEach((x) => {
      if (x.id === value) {
        returnValue = x.name;
      }
    });
    return returnValue;
  }

  private transformDays(activityStarts: string, daysOfWeek: number[], transformValue: UTCTranformEnum): number[] {
    if (_.isEmpty(activityStarts) || _.isEmpty(daysOfWeek)) {
      return daysOfWeek;
    }

    const activityTimeArr = activityStarts.split(':').map(s => parseInt(s, 10));
    const startDate = moment().set({
      hour: activityTimeArr[0],
      minute: activityTimeArr[1],
      second: activityTimeArr[2],
    });

    const day = startDate.day();
    const utcDay = startDate.utc().day();
    // Since the default % operator handles negative numbers differently
    const moduloFunc = (n: number, m: number) => ((n % m) + m) % m;

    const utcDayBehindLocalTime = day === moduloFunc(utcDay + 1, 7);
    const utcDayAheadOfLocalTime = day === moduloFunc(utcDay - 1, 7);

    if (utcDayBehindLocalTime) {
      return daysOfWeek.map(num => num = moduloFunc(num + transformValue, 7));
    } else if (utcDayAheadOfLocalTime) {
      return daysOfWeek.map(num => num = moduloFunc(num - transformValue, 7));
    } else {
      return daysOfWeek;
    }
  }
}
