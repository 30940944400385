import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { VerifyAlertData } from '@app/alerts/alerts-editor/components/alerts-verify-alert/models/verify-alert-response';
import { SeveritiesHelper } from '@shared/services/severities.helper';
import * as moment from 'moment';
import { AlertEditControls } from '@app/alerts/alerts-editor/models/alert-edit-controls.model';
import { Store } from '@ngrx/store';
import { getTimezoneViewPreference, State } from '@app/app.reducers';
import { TimeZoneType } from '@shared/models/timezone-types';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ThemeService } from '@app/logs-new/shared/services/theme.service';

@Component({
  selector: 'sh-verify-alert-chart',
  templateUrl: './verify-alert-chart.component.html',
  styleUrls: ['./verify-alert-chart.component.scss'],
})
export class VerifyAlertChartComponent {
  @Input()
  set verifyAlertData(val: VerifyAlertData[]) {
    if (val) {
      this.updateChart(val);
    }
  }
  public Highcharts: typeof Highcharts = Highcharts;
  public chartModel: Highcharts.ChartOptions;
  @Input()
  public isLoading: boolean = true;
  @Input()
  public numberOfLogsToALert: number = 1;
  @Input()
  public alertSeverity: number;
  @Input()
  public conditionOperator: number;
  @Input()
  public totalAlerts: number;
  @Input()
  public schedule: Partial<AlertEditControls>;
  private series: any[];

  private isLocalTimezone: boolean = true;
  constructor(private store: Store<State>, private themeService: ThemeService) {
    this.store
      .select(getTimezoneViewPreference)
      .take(1)
      .subscribe((tmz) => {
        this.isLocalTimezone = tmz === TimeZoneType.local;
      });
  }

  public drawChart(): void {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
    });
    const options = {
      chart: {
        plotBorderColor: 'var(--verify-alert-border-color)',
        plotBorderWidth: 1,
        height: 200,
        width: 650,
        padding: 0,
        backgroundColor: 'var(--verify-alert-chart-bg)',
      },
      isLocalTime: this.isLocalTimezone,
      series: this.series,
      legend: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: 'var(--tooltip-background)',
        borderColor: 'transparent',
        style: {
          color: 'var(--tooltip-color)',
          fontFamily: 'Nunito Sans',
          boxShadow: 'var(--tooltip-shadow)',
        },
        formatter: function(): string {
          const time = this.x;
          const logs = this.y;
          const timezone = this.series?.chart?.options?.isLocalTime;
          const isLocalTimeZone = isNotNullOrUndefined(timezone) ? timezone : true;
          return `<b>Date: </b> ${moment(time)
            .utc(isLocalTimeZone)
            .format('Do of MMMM')} <br>
                    <b>Time: </b> ${moment(time)
                      .utc(isLocalTimeZone)
                      .format('HH:mm')} <br>
                     <b>Logs: </b> ${logs}`;
        },
      },
      title: false,
      credits: false,
      yAxis: {
        labels: {
          style: {
            color: 'var(--graph-text-color)',
            fontFamily: 'Nunito Sans',
            fontSize: 12,
          },
        },
        title: {
          text: 'Matching Logs',
          style: {
            color: 'var(--graph-text-color)',
          },
        },
        gridLineColor: 'var(--verify-alert-border-color)',
        gridLineWidth: 1,
        plotLines: [
          {
            tooltip: false,
          },
        ],
      },
      xAxis: {
        type: 'datetime',
        gridLineColor: 'var(--verify-alert-border-color)',
        gridLineWidth: 1,
        tickLength: 0,
        zoomEnabled: true,
        labels: {
          style: {
            color: 'var(--graph-text-color)',
            fontFamily: 'Nunito Sans',
            fontSize: 12,
          },
          formatter(): string {
            const isLocalTime = this?.chart?.options?.isLocalTime;
            const isLocalTimeZone = isNotNullOrUndefined(isLocalTime) ? isLocalTime : true;
            const time = this.value;
            return `${moment(time)
              .utc(isLocalTimeZone)
              .format('HH:mm')}`;
          },
        },
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
            select: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
          marker: {
            states: {
              inactive: {
                opacity: 1,
              },
              select: {
                opacity: 1,
              },
              hover: {
                enabled: false,
              },
            },
          },
        },
      },
    };
    this.chartModel = options as Highcharts.ChartOptions;
  }

  private updateChart(data: VerifyAlertData[]): void {
    this.series = this.mapResponseToSeries(data);
    this.drawChart();
  }

  private mapResponseToSeries(data: VerifyAlertData[]): any[] {
    const logs = [];
    const occurencies = [];

    data.forEach((item) => {
      const point = {
        x: item.key,
        y: item.doc_count,
        marker: {
          enabled: this.checkAlertCondition(item),
          symbol: `url(../../../../../assets/alerts/icon-alert-${
            this.alertSeverity ? SeveritiesHelper.getEventSeverityById(this.alertSeverity).toLowerCase() : 'info'
          }.png)`,
          height: 25,
          width: 26,
          radius: 25,
        },
      };
      logs.push(point);
      const oocPoint = [item.key, this.numberOfLogsToALert];
      occurencies.push(oocPoint);
    });
    const occurenciesSerie = {
      enableMouseTracking: false,
      data: occurencies,
      type: 'area',
      states: { hover: { enabled: false } },
      color: 'rgba(237,244,255, 0.6)',
      fillColor: 'rgba(237,244,255, 0.6)',
      zIndex: 0,
      marker: {
        enabled: false,
      },
    };
    const logsSerie = {
      type: 'spline',
      states: { hover: { enabled: false } },
      name: 'Logs',
      data: logs,
      lineWidth: 3,
      zIndex: 10,
      color: '#adb8c9',
      marker: {
        height: 25,
        width: 26,
        radius: 25,
      },
    };
    return [logsSerie, occurenciesSerie];
  }

  private checkAlertCondition(item: VerifyAlertData): boolean {
    if (this.conditionOperator === 3) {
      return item.doc_count > this.numberOfLogsToALert;
    }
    if (this.conditionOperator === 1) {
      return item.doc_count < this.numberOfLogsToALert;
    }
    return true;
  }
}
