import { Component, OnInit, EventEmitter, Output, OnDestroy, AfterViewInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, Subscription } from 'rxjs';
import { ColDef, ColumnChangeEvent, GridOptions } from 'ag-grid';
import * as _ from 'lodash';

import { LogsService } from '../shared/services/logs-service';
import { SearchProvider } from '@shared/controls/search/search.provider';
import { LogsViewStateProvider } from '../shared/services/logsViewState.provider';
import { QueryParams } from '../shared/models/query-params.model';
import { LogQueryData } from '../shared/models/logquery.data';
import { LogsQuerFormProvider } from '../logs-query/logs-query-form.provider';
import { LogManager } from '../shared/services/logs-manager';
import { LogQueryModel } from '../shared/models/logsquery.model';
import { formatsHelper } from '@shared/services/formatsHelper';
import { ExportService } from '../shared/services/export.service';
import { LogqueryResult } from '../shared/models/logquery.response';
import { getLogsGridColumns, getLogsGridSavedViews, State } from '@app/app.reducers';
import { GroksActions } from '@app/groks/shared/state/groks/groks.actions';
import { Log } from '../shared/models/log.entity';
import { LogsGridColumnsActions } from '../shared/state/logs-grid-columns/logs-grid-columns.actions';
import { LogsFilterGridColumnsActions } from '../shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { GridColumnService } from '@shared/services/grid-column-service';
import { LogsGridColumnDef } from './logs-grid-col-def';
import SetVisibilityLogsFilterGridColumns = LogsFilterGridColumnsActions.SetVisibilityLogsFilterGridColumns;
import { LogsView } from '../shared/models/logs-view.model';
import { LogActions } from '../shared/state/log.actions';
import { ShDialogService } from '@shared/services/dialogService';
import { GlobalParamsProvider } from '@shared/services/globalParams.provider';
import { HtmlRowMeasure } from '@shared/services/htmlRowMeasure.service';
import { takeUntil } from 'rxjs/operators';

const virtual_rows_count: number = 15;

@Component({
  moduleId: 'logs-grid',
  selector: 'sh-logs-grid',
  templateUrl: 'logs-grid.component.html',
  styleUrls: ['logs-grid.component.scss'],
})
export class LogsGridComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() public onGridRowSelected: EventEmitter<any> = new EventEmitter();

  public isGridOverlayVisible: boolean = false;
  public queryData: LogQueryData;
  public searchProvider: SearchProvider;
  public highlightedLogs: Map<string, string> = new Map<string, string>();
  public gridOptions: GridOptions;
  public currentState: LogManager;
  public restoredState: LogManager;

  private newQueryRequestSubscirption: Subscription;
  private beforeAfterSubscirption: Subscription;
  private viewStateBeforeAfterSubscirption: Subscription;
  private viewStateFiltersChangeSubscription: Subscription;
  private onExportSubscription: Subscription;
  private onDetectGroksSubscription: Subscription;
  private logsGridColDefsSubscription: Subscription;
  private jsonColumnsSubscription: Subscription;
  private logsGridSavedViewsSubscription: Subscription;
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private logsService: LogsService,
    private gridColumnService: GridColumnService,
    private formProvider: LogsQuerFormProvider,
    private exportService: ExportService,
    private htmlRowMeasure: HtmlRowMeasure,
    private logsViewState: LogsViewStateProvider,
    private dialogService: ShDialogService,
    private store: Store<State>,
    private applicationActions$: Actions,
    private globalParams: GlobalParamsProvider,
  ) {
    this.searchProvider = this.logsViewState.viewState.logsSearchProvider;
  }

  public ngOnInit(): void {
    this.newQueryRequestSubscirption = this.logsService.newQueryRequest
      .do(() => (this.isGridOverlayVisible = true))
      .switchMap((query: LogQueryModel) =>
        this.logsService
          .getLogs(query)
          .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
          .map(res => ({
            queryRes: res,
            query: query,
          })),
      )
      .subscribe(
        (res: { queryRes: LogqueryResult; query: LogQueryModel }) => {
          this.setQueryResults(new LogQueryData(res.query, res.queryRes, this.logsService));
        },
        error => console.log(error),
      );

    this.viewStateFiltersChangeSubscription = this.logsViewState.onFilterChange.subscribe(params => {
      if (this.currentState) {
        this.currentState.updateFilters(params);
      }
    });

    this.viewStateBeforeAfterSubscirption = this.logsViewState.onStartBeforeAfter.subscribe(() => {
      this.isGridOverlayVisible = true;
    });

    this.beforeAfterSubscirption = this.logsService.onBeforeAfter.subscribe(queryData => {
      this.isGridOverlayVisible = true;
      this.setQueryResults(queryData);
    });

    this.onExportSubscription = this.logsViewState.onExport.subscribe(exportData => {
      this.exportService.exportGridLogs(this.currentState, exportData);
    });

    this.onDetectGroksSubscription = this.logsViewState.onDetectGroks.subscribe(() => {
      if (this.currentState && this.currentState.logPages) {
        const logs: Log[] = _.cloneDeep(this.currentState.logPages.get(this.currentState.currentPage));
        this.store.dispatch(new GroksActions.DetectGroks(logs));
      }
    });

    this.logsGridSavedViewsSubscription = this.store
      .select(getLogsGridSavedViews)
      .do(views => (views.isLoading ? (this.isGridOverlayVisible = true) : (this.isGridOverlayVisible = false)))
      .filter(views => !views.isLoading)
      .map(views => {
        return views.savedViews.find((v: LogsView) => v.id === views.selectedViewId);
      })
      .subscribe((selectedView: LogsView) => {
        if (this.gridOptions) {
          if (selectedView) {
            this.setGridColumns(selectedView.logsGridColDefs, selectedView.logsGridColState);
            this.updateLogsGridColumnsState(selectedView.logsGridColDefs, selectedView.logsGridColState);
            this.remeasureTextColumnWidth();
          } else {
            this.setGridColumns(LogsGridColumnDef.createColumnDefs());
            this.updateLogsGridColumnsState();
            this.remeasureTextColumnWidth();
          }
        }
      });

    this.subscribeToLogsGridColumnsFilterUpdates();
    this.subscribeToLogsQueryFailures();

    this.store.dispatch(new SetVisibilityLogsFilterGridColumns(true));
  }

  public subscribeToLogsQueryFailures(): void {
    this.applicationActions$
      .pipe(ofType(LogActions.ActionTypes.GET_LOGS_AND_TEMPLATE_COUNT_FAILED), takeUntil(this.destroyed$))
      .do(action => {
        this.isGridOverlayVisible = false;
        this.dialogService.showSnackBar('Query failed - please refine your query syntax', 'Dismiss', 5000);
      })
      .subscribe();
  }

  public subscribeToLogsGridColumnsFilterUpdates(): void {
    this.applicationActions$
      .pipe(
        ofType(LogsFilterGridColumnsActions.ActionTypes.COLUMNS_SELECTED, LogsFilterGridColumnsActions.ActionTypes.COLUMNS_DESELECTED),
        takeUntil(this.destroyed$),
      )
      .filter((action: any) => action.payload.length === 1)
      .map(action => {
        return { type: action.type, payload: action.payload[0] };
      })
      .do(action => {
        const selectedColName = action.payload;
        const colDefs = _.cloneDeep(this.gridOptions.columnDefs);

        if (
          action.type === LogsFilterGridColumnsActions.ActionTypes.COLUMNS_SELECTED &&
          colDefs.filter((col: ColDef) => !['index', 'timestamp', 'text'].includes(col.field)).length >= 20
        ) {
          this.store.dispatch(new LogsFilterGridColumnsActions.DeselectLogsFilterGridColumns([selectedColName]));
          this.dialogService.showSnackBar('Maximum columns allowed is 20', 'Dismiss', 5000);
          return;
        }

        let prevGridColState;
        let currGridColState;

        switch (action.type) {
          case LogsFilterGridColumnsActions.ActionTypes.COLUMNS_SELECTED:
            let newColDef;
            if (LogsGridColumnDef.defaultColDefs[selectedColName]) {
              newColDef = LogsGridColumnDef.defaultColDefs[selectedColName];
            } else {
              newColDef = this.gridColumnService.createGridColumnDefFromString(selectedColName, false);
            }

            colDefs.push(newColDef);
            prevGridColState = _.cloneDeep(this.gridOptions.columnApi.getColumnState());
            this.setGridColumns(colDefs);
            currGridColState = _.cloneDeep(this.gridOptions.columnApi.getColumnState());
            this.gridOptions.columnApi?.setColumnState(_.unionBy(prevGridColState, currGridColState, 'colId'));
            this.updateLogsGridColumnsState();
            if (newColDef.field === 'text') {
              this.remeasureTextColumnWidth();
            }
            break;

          case LogsFilterGridColumnsActions.ActionTypes.COLUMNS_DESELECTED:
            const targetCol = LogsGridColumnDef.defaultColDefs[selectedColName]
              ? LogsGridColumnDef.defaultColDefs[selectedColName].headerName
              : selectedColName;
            const targetIndex = colDefs.findIndex(c => c.headerName === targetCol);
            if (targetIndex !== -1) {
              colDefs.splice(targetIndex, 1)[0];
              prevGridColState = _.cloneDeep(this.gridOptions.columnApi.getColumnState());
              this.setGridColumns(colDefs);
              currGridColState = _.cloneDeep(this.gridOptions.columnApi.getColumnState());
              this.gridOptions.columnApi?.setColumnState(_.intersectionBy(prevGridColState, currGridColState, 'colId'));
              this.updateLogsGridColumnsState();
            }
            break;
        }
      })
      .subscribe();
  }

  public gridDataLoaded(totalLogCount: number): void {
    this.isGridOverlayVisible = false;
    this.logsViewState.viewState.queryResultCount = formatsHelper.numberWithCommas(totalLogCount) + ' Logs';
    this.logsViewState.viewState.logsCount = totalLogCount;
  }

  public ngAfterViewInit(): void {
    if (this.logsViewState.logsGridState) {
      this.logsViewState.logsGridState.startFromIndex = this.logsViewState.viewState.logsLastVisibleIndex;
      this.updateFiltersByQueryModel(this.gridOptions, this.logsViewState.logsGridState.logQueryModel.queryParams);
      this.restoredState = this.logsViewState.logsGridState;
    } else if (this.logsViewState.lastExcecutedQuery && !this.globalParams.chartClickedLogQueryModel) {
      this.logsService
        .getLogs(this.logsViewState.lastExcecutedQuery.queryModel)
        .first()
        .switchMap(logqueryRes => this.htmlRowMeasure.prepareRowsHeights(logqueryRes.logs).mapTo(logqueryRes))
        .map(res => ({
          queryRes: res,
          query: this.logsViewState.lastExcecutedQuery.queryModel,
        }))
        .subscribe(
          (res: { queryRes: LogqueryResult; query: LogQueryModel }) => {
            this.setQueryResults(new LogQueryData(res.query, res.queryRes, this.logsService));
          },
          error => console.log(error),
        );
    }
  }

  public updateFiltersByQueryModel(gridOptions: GridOptions, queryParams: QueryParams): void {
    const filters = {};
    if (queryParams.query.text) {
      filters['text'] = queryParams.query.text;
    }
    if (queryParams.metadata.threadId) {
      filters['metadata.threadId'] = queryParams.metadata.threadId;
      // console.log(queryParams.metadata.threadId);
    }
    if (filters && gridOptions.api) {
      gridOptions.api.setFilterModel(filters);
    }
  }

  public setQueryResults(logQueryData: LogQueryData): void {
    this.highlightedLogs = logQueryData.highlightedLogs;
    this.updateFiltersByQueryModel(this.gridOptions, logQueryData.logQueryModel.queryParams);
    this.queryData = logQueryData;
    this.queryData.logsService = this.logsService;
  }

  public onGridReady(gridOptions: GridOptions): void {
    this.gridOptions = gridOptions;

    this.store
      .select(getLogsGridColumns)
      .first()
      .subscribe(columnsState => {
        if (!columnsState || columnsState.colDefs.length === 0) {
          this.setGridColumns(LogsGridColumnDef.createColumnDefs());
          this.updateLogsGridColumnsState();
        } else {
          this.setGridColumns(columnsState.colDefs, columnsState.colState);
        }
      });

    this.subscribeToGridEvents(gridOptions);
  }

  public subscribeToGridEvents(gridOptions: GridOptions): void {
    gridOptions.onColumnResized = (e: ColumnChangeEvent) => {
      if (e.isFinished()) {
        const changedColumn = e.getColumn();
        if (changedColumn.getColId() === 'text') {
          this.remeasureTextColumnWidth();
        }
        this.updateLogsGridColumnsState();
      }
    };

    gridOptions.onDragStopped = params => {
      this.updateLogsGridColumnsState();
    };
  }

  public remeasureTextColumnWidth(): void {
    const textCol = this.gridOptions.columnApi.getColumn('text');
    if (textCol && this.queryData && textCol.getActualWidth() !== this.htmlRowMeasure.textColumnWidth) {
      this.htmlRowMeasure.textColumnWidth = textCol.getActualWidth();
      this.htmlRowMeasure
        .prepareRowsHeights(this.queryData.logQueryResult.logs)
        .first()
        .subscribe(() => {
          this.gridOptions.api.resetRowHeights();
          this.refreshGrid();
        });
    }
  }

  public setGridColumns(colDefs: ColDef[], colState: any = null): void {
    const clonedColDefs = _.cloneDeep(colDefs);

    for (let i = 0; i < clonedColDefs.length; i++) {
      if (LogsGridColumnDef.defaultColDefs['coralogix.' + clonedColDefs[i].field]) {
        clonedColDefs[i] = LogsGridColumnDef.defaultColDefs['coralogix.' + clonedColDefs[i].field];
      } else if (clonedColDefs[i].field.indexOf('textObject') !== -1) {
        clonedColDefs[i] = this.gridColumnService.createGridColumnDefFromString(clonedColDefs[i].headerName, false);
      }
    }

    this.gridOptions.columnDefs = clonedColDefs;
    this.gridOptions.api.setColumnDefs(clonedColDefs);
    if (colState) {
      this.gridOptions.columnApi?.setColumnState(colState);
    }
    this.gridOptions.api.resetRowHeights();
  }

  public updateLogsGridColumnsState(colDefs: ColDef[] = null, colState: any = null): void {
    this.store.dispatch(
      new LogsGridColumnsActions.SetLogsGridColumns({
        colDefs: !colDefs ? this.gridOptions.columnDefs : colDefs,
        colState: !colState ? this.gridOptions.columnApi.getColumnState() : colState,
      }),
    );
  }

  public beforeApiDestroy(gridOptions: GridOptions): void {
    this.logsViewState.viewState.logsLastVisibleIndex = gridOptions.api.getLastRenderedRow() - virtual_rows_count;
    this.logsViewState.viewState.logsColumnState = gridOptions.columnApi.getColumnState();
    this.logsViewState.viewState.filterModel = gridOptions.api.getFilterModel();
  }

  public gridFilterChanged(logQueryModel: LogQueryModel): void {
    this.formProvider.updateFormByModel(logQueryModel);
  }

  public saveGridState(state: LogManager): void {
    this.logsViewState.logsGridState = state;
  }

  public logManagerUpdated(state: LogManager): void {
    this.currentState = state;
  }

  public ngOnDestroy(): void {
    this.newQueryRequestSubscirption.unsubscribe();
    this.onExportSubscription.unsubscribe();
    this.onDetectGroksSubscription.unsubscribe();
    if (this.logsGridColDefsSubscription) {
      this.logsGridColDefsSubscription.unsubscribe();
    }

    if (this.beforeAfterSubscirption) {
      this.beforeAfterSubscirption.unsubscribe();
    }

    if (this.viewStateBeforeAfterSubscirption) {
      this.viewStateBeforeAfterSubscirption.unsubscribe();
    }

    if (this.jsonColumnsSubscription) {
      this.jsonColumnsSubscription.unsubscribe();
    }

    if (this.logsGridSavedViewsSubscription) {
      this.logsGridSavedViewsSubscription.unsubscribe();
    }

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public publishGridRowSelected(row): void {
    this.onGridRowSelected.emit(row);
  }

  public onGridButtonClick(event): void {
    this.logsViewState.textInfoClicked.emit(event);
  }

  private refreshGrid(): void {
    if (this.gridOptions && this.gridOptions.api) {
      setTimeout(() => this.gridOptions.api.refreshView(), 0);
    }
  }
}
