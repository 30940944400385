import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { RulesTypesCardsData } from '@app/rules/shared/models/parsing-theme-editor-consts';
import { ERuleServerTypes } from '@app/rules/shared/models/rule.model';
import { ParsingThemeFormService } from '@app/rules/shared/services/parsing-theme-form.service';
import { IRuleGroupFormData, RuleFormData, RuleGroupFormData } from '@app/rules/shared/models/parsing-theme-editor.models';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'sh-main-pt-editor-content',
  templateUrl: './main-pt-editor-content.component.html',
  styleUrls: ['./main-pt-editor-content.component.scss']
})
export class MainPtEditorContentComponent implements AfterViewInit {
  @Input() public parsingThemeForm: FormGroup;
  @Input() public isCreateMode: boolean;
  @Output() public deleteParsingTheme: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('matcherPanel') public ruleMatchersExpandPanel: MatExpansionPanel;
  public ruleCardsData: typeof RulesTypesCardsData = RulesTypesCardsData;
  public newRuleAdded: boolean = false;
  constructor(private parsingThemeFormService: ParsingThemeFormService, private cdr: ChangeDetectorRef) {
  }

  public ngAfterViewInit(): void {
    if (this.isCreateMode && this.ruleMatchersExpandPanel) {
      this.ruleMatchersExpandPanel.toggle();
      this.cdr.detectChanges();
    }
  }

  public get detailsForm(): FormGroup {
    return this.parsingThemeForm.get('details') as FormGroup;
  }

  public get parsingThemeId(): string {
    return this.parsingThemeForm.get('id').value;
  }

  public createNewRuleGroupWithRule(cardType: ERuleServerTypes): void {
    const ruleGroupOrder = this.parsingThemeHasRuleGroups ? this.ruleGroupsForm.value.length : 1;
    const newRuleData = new RuleFormData({ type: cardType, order: ruleGroupOrder });
    const newRuleGroup: IRuleGroupFormData = new RuleGroupFormData({ rules: [newRuleData] });
    const newRuleGroupForm: FormGroup = this.parsingThemeFormService.getGroupForm(newRuleGroup);
    this.ruleGroupsForm.push(newRuleGroupForm);
    this.newRuleAdded = true;
  }

  public get ruleMatchersForm(): FormGroup {
    return this.parsingThemeForm.get('ruleMatchers') as FormGroup;
  }

  public get ruleGroupsForm(): FormArray {
    return this.parsingThemeForm.get('rulesGroups') as FormArray;
  }

  public get parsingThemeHasRuleGroups(): boolean {
    return !!this.ruleGroupsForm?.controls?.length;
  }
}
