import { Action } from '@ngrx/store';
import { type } from '../../../../shared/services/utils';

export namespace GrokDefinitionsActions {
  export const ActionTypes = {
    GET_GROK_DEFS: type('[Grok] get grok definitions'),
    GET_GROK_DEFS_COMPLETED: type('[Grok] get grok definitions completed'),
    GET_GROK_DEFS_FAILED: type('[Grok] get grok definitions failed'),
  };

  export class GetGrokDefs implements Action {
    public type = ActionTypes.GET_GROK_DEFS;

    constructor(public payload: any = null) {}
  }

  export class GetGrokDefsCompleted implements Action {
    public type = ActionTypes.GET_GROK_DEFS_COMPLETED;

    constructor(public payload: any) {}
  }
  export class GetGrokDefsFailed implements Action {
    public type = ActionTypes.GET_GROK_DEFS_FAILED;

    constructor(public payload: any) {}
  }

  export type Actions = GetGrokDefs | GetGrokDefsCompleted | GetGrokDefsFailed;
}
