export class SearchTerm {
  public suggestedHead: string;
  public term: string;
  public suggestedTail: string;
  public searchOnClick: boolean;
  public prefix: string;
  public possfix: string;

  constructor(term: string, suggestion: string, searchOnClick: boolean, prefix: string, possfix: string) {
    this.suggestedHead = suggestion.substring(0, suggestion.indexOf(term));
    this.term = term;
    this.suggestedTail = suggestion.substring(
      suggestion.indexOf(term) + term.length,
    );
    this.searchOnClick = searchOnClick;
    this.prefix = prefix;
    this.possfix = possfix;

  }
  public toString = (): string => {
    return this.prefix + this.suggestedHead + this.term + this.suggestedTail + this.possfix;
  }
}
