import { Action } from '@ngrx/store';
import { type } from '../../../../shared/services/utils';
import {
  IntegrationModel,
  IntegrationTypeModel,
  IntegrationTestResult,
} from '../../../../settings-common/shared/models/newIntegrationRequest';
import { IDigestIntegrations } from '../../services/settings-service';
import { AlertModel } from '@app/alerts/shared/models/alert-model';

export namespace IntegrationsActions {
  export const ActionTypes = {
    GET_INTEGRATIONS: type('[Integrations] Get integrations'),
    GET_INTEGRATIONS_COMPLETED: type(
      '[Integrations] Get integrations completed',
    ),
    GET_INTEGRATION_TYPES: type('[Integrations] Get integration types'),
    GET_INTEGRATION_TYPES_COMPLETED: type(
      '[Integrations] Get integration types completed',
    ),
    ADD_NEW_INTEGRATION: type('[Integrations] Add new integration'),
    REMOVE_NEW_INTEGRATION: type('[Integrations] Remove new integration'),
    SAVE_INTEGRATION: type('[Integrations] Save integration'),
    SAVE_INTEGRATION_COMPLETED: type(
      '[Integrations] Save integration completed',
    ),
    DELETE_INTEGRATION: type('[Integrations] Delete integration'),
    DELETE_INTEGRATION_COMPLETED: type(
      '[Integrations] Delete integration completed',
    ),
    INTEGRATIONS_FAILURE: type('[Integrations] Integrations failure'),
    INTEGRATIONS_FAILURE_COMPLETED: type(
      '[Integrations] Integrations failure completed',
    ),
    INTEGRATIONS_CONFIG_TEST: type(
      '[Integrations] Integrations Configuration Test',
    ),
    INTEGRATIONS_CONFIG_TEST_COMPLETED: type(
      '[Integrations] Integrations Configuration Test completed',
    ),
    INTEGRATIONS_CONFIG_TEST_FAILED: type(
      '[Integrations] Integrations Configuration Test failed',
    ),
    GET_DIGEST_INTEGRATIONS: type(
      '[Integrations] Get digest integrations',
    ),
    GET_DIGEST_INTEGRATIONS_COMPLETED: type(
      '[Integrations] Get digest integrations completed',
    ),
    SAVE_DIGEST_INTEGRATION: type('[Integrations] Save new digest integration'),
    SAVE_DIGEST_INTEGRATION_COMPLETED: type('[Integrations] Save new digest integration completed'),
  };

  export class GetIntegrationsAction implements Action {
    public type = ActionTypes.GET_INTEGRATIONS;
  }

  export class GetIntegrationsCompletedAction implements Action {
    public type = ActionTypes.GET_INTEGRATIONS_COMPLETED;

    constructor(public payload: IntegrationModel[]) {}
  }

  export class GetIntegrationTypesAction implements Action {
    public type = ActionTypes.GET_INTEGRATION_TYPES;

    constructor(public payload: any) {}
  }

  export class GetIntegrationTypesCompletedAction implements Action {
    public type = ActionTypes.GET_INTEGRATION_TYPES_COMPLETED;

    constructor(public payload: IntegrationTypeModel[]) {}
  }

  export class AddNewIntegrationAction implements Action {
    public type = ActionTypes.ADD_NEW_INTEGRATION;

    constructor(public payload: IntegrationModel) {}
  }

  export class RemoveNewIntegrationAction implements Action {
    public type = ActionTypes.REMOVE_NEW_INTEGRATION;

    constructor(public payload: any) {}
  }

  export class SaveIntegrationAction implements Action {
    public type = ActionTypes.SAVE_INTEGRATION;

    constructor(public payload: {integrationModel: IntegrationModel, slackDigests: Partial<IDigestIntegrations>[]}) {}
  }

  export class SaveIntegrationCompletedAction implements Action {
    public type = ActionTypes.SAVE_INTEGRATION_COMPLETED;

    constructor(public payload: {integration: IntegrationModel, slackDigests: Partial<IDigestIntegrations>[]}) {}
  }

  export class DeleteIntegrationAction implements Action {
    public type = ActionTypes.DELETE_INTEGRATION;

    constructor(public payload: IntegrationModel) {}
  }

  export class DeleteIntegrationCompletedAction implements Action {
    public type = ActionTypes.DELETE_INTEGRATION_COMPLETED;

    constructor(public payload: number) {}
  }

  export class IntegrationFailureAction implements Action {
    public type = ActionTypes.INTEGRATIONS_FAILURE;

    constructor(public payload: any) {}
  }

  export class IntegrationFailureCompletedAction implements Action {
    public type = ActionTypes.INTEGRATIONS_FAILURE_COMPLETED;

    constructor(public payload: any) {}
  }

  export class TestIntegrationConfigurationAction implements Action {
    public type = ActionTypes.INTEGRATIONS_CONFIG_TEST;

    constructor(public payload: IntegrationModel) {}
  }

  export class TestIntegrationConfigurationCompletedAction implements Action {
    public type = ActionTypes.INTEGRATIONS_CONFIG_TEST_COMPLETED;

    constructor(public payload: IntegrationTestResult) {}
  }

  export class TestIntegrationConfigurationFailedAction implements Action {
    public type = ActionTypes.INTEGRATIONS_CONFIG_TEST_FAILED;

    constructor(public payload: IntegrationTestResult) {}
  }

  export class GetDigestIntegrationsAction implements Action {
    public type = ActionTypes.GET_DIGEST_INTEGRATIONS;
  }

  export class GetDigestIntegrationsCompletedAction implements Action {
    public type = ActionTypes.GET_DIGEST_INTEGRATIONS_COMPLETED;

    constructor(public payload: IDigestIntegrations[]) {}
  }

  export class SaveDigestIntegrationAction implements Action {
    public type = ActionTypes.SAVE_DIGEST_INTEGRATION;

    constructor(public payload: IDigestIntegrations[]) {}
  }

  export class SaveDigestIntegrationCompletedAction implements Action {
    public type = ActionTypes.SAVE_DIGEST_INTEGRATION_COMPLETED;

    constructor(public payload: IDigestIntegrations[]) {}
  }

  export type Actions =
    | GetIntegrationsAction
    | GetIntegrationsCompletedAction
    | GetIntegrationTypesAction
    | GetIntegrationTypesCompletedAction
    | AddNewIntegrationAction
    | RemoveNewIntegrationAction
    | SaveIntegrationAction
    | SaveIntegrationCompletedAction
    | DeleteIntegrationAction
    | DeleteIntegrationCompletedAction
    | IntegrationFailureAction
    | IntegrationFailureCompletedAction
    | TestIntegrationConfigurationFailedAction
    | TestIntegrationConfigurationCompletedAction
    | TestIntegrationConfigurationAction
    | GetDigestIntegrationsAction
    | GetDigestIntegrationsCompletedAction
    | SaveDigestIntegrationAction
    | SaveDigestIntegrationCompletedAction;
}
