<div fxLayout="row" [ngClass]="{ 'exit': startExitAnimation }" class="info-noti-container" fxLayoutAlign="space-between center">
  <a (click)="emitCloseAction()" class="close-button info-noti-container__close-btn">
    <span class="info-noti-container__close-btn-icon">×</span>
  </a>
  <div fxLayout="row" fxLayoutAlign="start center">
    <svg-icon [src]="sideIconUrl" class="info-icon"></svg-icon>
    <div class="info-noti-container__titles" fxLayout="column" fxLayoutAlign="space-between start">
      <div class="info-noti-container__main-title">{{ title }}</div>
      <div class="info-noti-container__sub-title">{{ subTitle }}</div>
    </div>
  </div>
  <button (click)="actionButtonClicked.emit()" class="info-noti-container__action-btn">
    {{ buttonText }}
  </button>
</div>
