import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sh-reindex-editor-details',
  templateUrl: './reindex-editor-details.component.html',
  styleUrls: ['./reindex-editor-details.component.scss']
})
export class ReindexEditorDetailsComponent {

  @Input()
  public form: FormGroup;
  constructor() { }

  public hasError(controlName: string, errorCode: string): boolean {
    const control = this.form?.get(controlName);
    return control?.hasError(errorCode) && control?.touched;
  }

}
