<div class="container">
  <div class="ui action input">
    <input
      type="text"
      placeholder="Filter..."
      (keyup)="stringFilterChanged($event.target.value)"
    />
    <select
      class="ui compact selection dropdown"
      (change)="filterTypeChanged($event.target.value)"
    >
      <option value="allViews">All views</option>
      <option selected="" value="myViews">My views</option>
    </select>
  </div>
  <div
    *ngIf="
      (logsGridSavedViews$ | async | logsSavedViewsFilter: savedViewsFilter)
        ?.length === 0
    "
    style="padding-top: 20px; padding-left: 16px; text-align: center"
  >
    <p>No results found...</p>
  </div>
  <div
    *ngIf="
      (logsGridSavedViews$ | async | logsSavedViewsFilter: savedViewsFilter)
        ?.length > 0
    "
    style="padding-top: 20px; padding-left: 16px"
  >
    <div
      style="max-height: 203px; overflow: auto;"
      class="ui middle aligned stripe inverted selection list"
    >
      <div
        class="item"
        *ngFor="
          let view of logsGridSavedViews$
            | async
            | logsSavedViewsFilter: savedViewsFilter
        "
        (click)="selectView(view)"
      >
        <div class="content item-content">
          <i class="fa fa-table"></i>
          <span style="padding-left: 3px">{{ view.name }}</span>
        </div>
        <div *ngIf="view.isEditable" class="content">
          <button
            class="removeViewBtn"
            (click)="deleteView(view)"
            shClickStopPropagation
          >
            <i class="remove red icon"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
