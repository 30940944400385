import { Insight } from './insight.model';
import { jsonObject, jsonMember } from 'typedjson';
import { AnomalyProperties } from './anomaly-properties.model';

@jsonObject
export class Anomaly extends Insight {
  @jsonMember
  public shortId: string;
  @jsonMember({ name: 'eventProperties' })
  public properties: AnomalyProperties;
}
