import { type } from '@shared/services/utils';
import { Action } from '@ngrx/store';
import {
  IArchiveQueriesResponse,
  IArchiveQuery, IArchiveQueryBucketPermission,
  ICachedQueryResponse,
  IFile,
  IReindex
} from '@app/roi/archive-queries/models/archive-query.model';

export namespace ArchiveQueryActions {
  export const ActionTypes = {
    ACTIVATE_LOADING: type('[ArchiveQuery] activate loading'),
    CLEAR_LOADING: type('[ArchiveQuery] clear loading'),
    GET_ARCHIVE_QUERIES: type('[ArchiveQuery] get archive queries'),
    SET_ARCHIVE_QUERIES_UI_STATE: type('[ArchiveQuery] set archive queries ui state'),
    GET_ARCHIVE_QUERIES_FAILED: type('[ArchiveQuery] get archive queries failed'),
    GET_ARCHIVE_QUERIES_COMPLETED: type('[ArchiveQuery] get archive queries completed'),
    DOWNLOAD_RESULTS: type('[ArchiveQuery] download archive query results'),
    DOWNLOAD_RESULTS_COMPLETED: type('[ArchiveQuery] download archive query results completed'),
    DOWNLOAD_RESULTS_FAILED: type('[ArchiveQuery] download archive query results failed'),
    CREATE_REINDEX: type('[ArchiveQuery] create reindex'),
    CREATE_REINDEX_COMPLETED: type('[ArchiveQuery] create reindex completed'),
    CREATE_REINDEX_FAILED: type('[ArchiveQuery] create reindex failed'),
    CREATE_CACHED_QUERY: type('[ArchiveQuery] create cache query'),
    CREATE_CACHED_QUERY_COMPLETED: type('[ArchiveQuery] create cache query completed'),
    CREATE_CACHED_QUERY_FAILED: type('[ArchiveQuery] create cache query failed'),
    CREATE_QUERY_COMPLETED: type('[ArchiveQuery] create query completed'),
    CREATE_QUERY_FAILED: type('[ArchiveQuery] create query failed'),
    SET_PAGINATION: type('[ArchiveQuery] set query paging'),
    SET_SEARCH_TERM: type('[ArchiveQuery] set query search term'),
    GET_BUCKET_PERMISSIONS: type('[ArchiveQuery] Get bucket permissions'),
    GET_BUCKET_PERMISSIONS_COMPLETED: type('[ArchiveQuery] Get bucket permissions completed'),
    GET_BUCKET_PERMISSIONS_FAILED: type('[ArchiveQuery] Get bucket permissions failed'),
  };

  export class ActivateLoadingAction implements Action {
    public type = ActionTypes.ACTIVATE_LOADING;

    constructor() {}
  }

  export class ClearLoadingAction implements Action {
    public type = ActionTypes.CLEAR_LOADING;

    constructor() {}
  }

  export class GetArchiveQueriesAction implements Action {
    public type = ActionTypes.GET_ARCHIVE_QUERIES;

    constructor(public payload: {loading: boolean, pageIndex: number, pageSize: number, filter: string}) {}
  }

  export class GetArchiveQueriesFailedAction implements Action {
    public type = ActionTypes.GET_ARCHIVE_QUERIES_FAILED;

    constructor(public payload: Error) {}
  }

  export class GetArchiveQueriesCompletedAction implements Action {
    public type = ActionTypes.GET_ARCHIVE_QUERIES_COMPLETED;

    constructor(public payload: IArchiveQueriesResponse) {}
  }

  export class DownloadResultsAction implements Action {
    public type = ActionTypes.DOWNLOAD_RESULTS;

    constructor(public payload: IFile) {}
  }

  export class DownloadResultsCompletedAction implements Action {
    public type = ActionTypes.DOWNLOAD_RESULTS_COMPLETED;

    constructor() {}
  }

  export class CreateReindexAction implements Action {
    public type = ActionTypes.CREATE_REINDEX;

    constructor(public payload: IArchiveQuery) {}
  }

  export class CreateReindexCompletedAction implements Action {
    public type = ActionTypes.CREATE_REINDEX_COMPLETED;

    constructor(public payload: IReindex) {}
  }

  export class CreateReindexFailedAction implements Action {
    public type = ActionTypes.CREATE_REINDEX_FAILED;

    constructor(public payload: Error) {}
  }

  export class CreateCachedQueryAction implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY;

    constructor(public payload: IArchiveQuery) {}
  }

  export class CreateCachedQueryCompletedAction implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY_COMPLETED;

    constructor(public payload: ICachedQueryResponse) {}
  }

  export class CreateCachedQueryFailedAction implements Action {
    public type = ActionTypes.CREATE_CACHED_QUERY_FAILED;

    constructor(public payload: Error) {}
  }


  export class DownloadResultsFailedAction implements Action {
    public type = ActionTypes.DOWNLOAD_RESULTS_FAILED;

    constructor(public payload: Error) {}
  }

  export class SetArchiveQueryUiState implements Action {
    public type = ActionTypes.SET_ARCHIVE_QUERIES_UI_STATE;

    constructor(public payload: {queryId: string, showMore: boolean}) {}
  }

  export class CreateQueryCompletedAction implements Action {
    public type = ActionTypes.CREATE_QUERY_COMPLETED;

    constructor(public payload: IArchiveQuery) {}
  }

  export class CreateQueryFailedAction implements Action {
    public type = ActionTypes.CREATE_QUERY_FAILED;

    constructor() {}
  }

  export class SetPagingAction implements Action {
    public type = ActionTypes.SET_PAGINATION;

    constructor(public payload: { pageIndex: number, pageSize: number }) {}
  }

  export class SetSearchTermAction implements Action {
    public type = ActionTypes.SET_SEARCH_TERM;

    constructor(public payload: {term: string}) {}
  }

  export class GetBucketPermissionsAction implements Action {
    public type = ActionTypes.GET_BUCKET_PERMISSIONS;

    constructor() {}
  }
  export class GetBucketPermissionsCompletedAction implements Action {
    public type = ActionTypes.GET_BUCKET_PERMISSIONS_COMPLETED;

    constructor(public payload: IArchiveQueryBucketPermission) {}
  }
  export class GetBucketPermissionsFailedAction implements Action {
    public type = ActionTypes.GET_BUCKET_PERMISSIONS_FAILED;

    constructor(public payload: IArchiveQueryBucketPermission) {}
  }


  export type Actions =
    ActivateLoadingAction |
    ClearLoadingAction |
    GetArchiveQueriesAction |
    GetArchiveQueriesFailedAction |
    GetArchiveQueriesCompletedAction |
    DownloadResultsAction |
    CreateReindexAction |
    CreateReindexCompletedAction |
    CreateReindexFailedAction |
    CreateCachedQueryAction |
    CreateCachedQueryCompletedAction |
    CreateCachedQueryFailedAction |
    DownloadResultsFailedAction |
    SetArchiveQueryUiState |
    CreateQueryCompletedAction |
    CreateQueryFailedAction |
    SetPagingAction |
    SetSearchTermAction |
    GetBucketPermissionsAction |
    GetBucketPermissionsCompletedAction |
    GetBucketPermissionsFailedAction;
}
