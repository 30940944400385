import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { ChartSelectionContextObject } from 'highcharts';

import { ChartModel } from '../models/chart.model';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { SeveritiesHelper } from '@shared/services/severities.helper';
import { QueryParams } from '@app/logs/shared/models/query-params.model';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';

@Injectable()
export class ChartLogQueryService {
  public getQueryModelForNotRealTimeData(
    event,
    chartModel: ChartModel,
  ): LogQueryModel {
    const interval: unitOfTime.Base = chartModel.scale as unitOfTime.Base;
    let queryModel: LogQueryModel;

    let endDate = moment(chartModel.categories[event.point.index]);
    endDate = endDate.add(1, interval);

    if (chartModel.series[0].type !== 'pie') {
      queryModel = new LogQueryModel(
        chartModel.categories[event.point.index],
        endDate.toDate(),
      );
      queryModel.queryParams = chartModel.logQueryModel
        ? chartModel.logQueryModel.queryParams
        : null;
      queryModel.type = chartModel.logQueryModel
        ? chartModel.logQueryModel.type
        : QueryTypes.FREE;
    } else {
      queryModel = chartModel.logQueryModel;
      queryModel.queryParams.metadata.severity = [];
      queryModel.queryParams.metadata.severity.push(
        SeveritiesHelper.getLogSeverityByName(event.point.name),
      );
    }

    return queryModel;
  }

  public getQueryModelForNotTimestampChart(
    event,
    chartModel: ChartModel,
  ): LogQueryModel {
    let queryModel: LogQueryModel;
    let startDate = moment();

    startDate = startDate.subtract(1, 'days');
    queryModel = new LogQueryModel(startDate.toDate(), moment().toDate());
    queryModel.queryParams = new QueryParams();
    queryModel.queryParams.metadata = new Metadata();

    const severityId = SeveritiesHelper.getLogSeverityByName(
      chartModel.series[0].data[event.point.index][0],
    );

    queryModel.queryParams.metadata.severity.push(severityId);

    return queryModel;
  }

  public getQueryModelWithUpdatedStartAndEndDate(
    e: ChartSelectionContextObject,
    chartModel: ChartModel,
    chart: any,
  ): LogQueryModel {
    if (!chartModel.logQueryModel || chartModel.zoomable) {
      return chartModel.logQueryModel;
    }

    const interval: unitOfTime.Base = chartModel.scale as unitOfTime.Base;
    const xColumnOffset = 0.5;
    const xClickedValue =
      chart.xAxis[0].toValue(chart.mouseDownX) + xColumnOffset;
    const xMin = e.xAxis[0].min;
    const xMax = e.xAxis[0].max;
    const range = xMax - xMin;
    const startDate = chartModel.categories[0];
    const logQueryModel: LogQueryModel = chartModel.logQueryModel;

    if ((xClickedValue - xColumnOffset).toFixed(5) > xMin.toFixed(5)) {
      logQueryModel.startDate = this.getUpdatedDate(
        startDate,
        xClickedValue - range,
        interval,
      );
      logQueryModel.endDate = this.getUpdatedDate(
        startDate,
        xClickedValue,
        interval,
      );
    } else {
      logQueryModel.startDate = this.getUpdatedDate(
        startDate,
        xClickedValue,
        interval,
      );
      logQueryModel.endDate = this.getUpdatedDate(
        startDate,
        range + xClickedValue,
        interval,
      );
    }

    return logQueryModel;
  }

  private getUpdatedDate(startDate, amount, interval): number {
    return (
      moment(this.getParsedDateFromUnix(startDate))
        .add(amount, interval)
        .unix() * 1000
    );
  }

  private getParsedDateFromUnix(unixDate) {
    return moment.unix(unixDate / 1000).toDate();
  }
}
