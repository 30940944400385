<div class="alerts-searth" data-test="alerts-search">
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search alerts" autocomplete="off" />
  <mat-icon aria-hidden="false" aria-label="Example home icon">
    <i class="fa fa-search" aria-hidden="true"></i>
  </mat-icon>
</div>
<div class="container alerts-table-wrapper">
  <table mat-table [dataSource]="source" matSort matSortStart="desc">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        NAME / CREATOR
      </th>
      <td mat-cell *matCellDef="let element" data-test="alerts-name-td">
        <div class="name-label">{{ element.name }}</div>
        <div class="creator-label">{{ getUserName(element) }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="updateDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        LAST MODIFIED
      </th>
      <td mat-cell *matCellDef="let element" class="baseTableText">
        {{ element.updateDate ? getFormattedDate(element.updateDate) : null }}
      </td>
    </ng-container>
    <ng-container matColumnDef="alertSeverity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        SEVERITY
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="severity" shSeverity [severity]="element.alertSeverity">
          {{ element.alertSeverity }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        STATUS
      </th>
      <td mat-cell *matCellDef="let element" (click)="stopEvent($event)">
        <span class="baseTableText">
          {{ element.isActive ? 'Active' : 'Inactive' }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="lastRaisedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        LAST TRIGGERED
      </th>
      <td mat-cell *matCellDef="let element" class="baseTableText">
        {{ element.lastRaisedDate ? getFormattedDate(element.lastRaisedDate) : 'Never triggered' }}
      </td>
    </ng-container>
    <tbody>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="onEditAlert(row)"></tr>
    </tbody>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="5" showFirstLastButtons></mat-paginator>
</div>
