import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicLogsTooltipComponent} from './dynamic-logs-tooltip.component';

@NgModule({
  declarations: [DynamicLogsTooltipComponent],
  imports: [
    CommonModule
  ]
})
export class DynamicLogsTooltipModule {
}
