<div class="content-container">
  <section class="explanation-container">
    <h2 class="explanation-container__title">Getting Started With Rules</h2>
    <p class="explanation-container__paragraph">
      Rules provide you the ability to parse, extract, map,
      convert and filter your log entries. Rules can help you convert unstructured log data into JSON format,
      extract important information from your logs onto JSON fields,
      and filter your logs according to various conditions to manage your quota.
      Coralogix uses the <a class="link" href="https://github.com/google/re2/wiki/Syntax">RE2</a> regular expression syntax.
      The following are typical examples of how to use the six types of rule actions that Coralogix provides.
    </p>
  </section>

  <div class="explanation-container__examples" fxLayoutGap="88px" fxLayout="column">
    <sh-rule-example-item
      class="explanation-container__example"
      *ngFor="let sample of ruleSamples; let i = index"
      [sample]="sample" [ruleNumber]="i + 1"
    ></sh-rule-example-item>
  </div>

</div>
