import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class StripeUserData {
  @jsonMember
  public stripeCustomerId: string = '';
  @jsonMember
  public billflowHash: string = '';
  @jsonMember
  public planName: string = '';
}
