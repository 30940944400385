<div class="no-rows" *ngIf="commonErrorResult && commonErrorResult.length == 0 && !isLoading">
  <span class="no-rows__text">No Rows To Show</span>
</div>
<div fxLayout="column">
  <div class="template-item" fxLayout="column"
       *ngFor="let commonError of commonErrorResult; let last = last">
    <div class="template-item__main" fxLayout="row">
      <div class="template-item__summary {{ theme }}"
           [class.loggregation-last-item]="last">
        <div class="template-item__info">
          <div class="template-item__severity text-cgx-{{
                commonError.loggregationEntity.metadata.severity.toLowerCase()
              }}">
            {{ commonError.loggregationEntity.metadata.severity }}
          </div>
          <div>
            <div class="template-item__count {{ theme }} ">
              {{ commonError.loggregationEntity.count | numbersFormat }} logs
            </div>
            <div class="template-item__ratio-item anomolus"
                 *ngIf="commonError.loggregationEntity.countToNormalRation"
                 matTooltip="{{commonError.loggregationEntity.countToNormalRation
                  | normalRatio: 100}}% above normal">
              {{commonError.loggregationEntity.countToNormalRation
              | normalRatio: 100}}%
            </div>
            <div class="template-item__ratio-item"
                 *ngIf="!commonError.loggregationEntity.countToNormalRation">
              {{ commonError.loggregationEntity.ratio }}
            </div>
            <div class="template-item__ratio-item-sentence">OF TOTAL ERRORS</div>
          </div>
        </div>
      </div>
      <div class="column" class="template-item__chart">
        <sh-statistics-chart
          class="template-item__chart-component"
          [height]="40"
          [isCompact]="true"
          [clickable]="false"
          (chartClicked)="onChartClicked($event)"
          [infoBarClass]="'chart-infoBar dark '"
          [queryDefinition]="commonError.statisticsQueryDefinition"
        ></sh-statistics-chart>
      </div>
      <div class="template-item__content" fxFlex="60">
        <div class="template-item__content-text"
             innerHTML="{{ commonError.loggregationEntity | loggregationPipe }}"
        ></div>
        <div class="template-item__metadata-row">
        <span class="template-item__metadata {{ theme }}">
          {{ commonError.loggregationEntity.metadata.applicationName }}
        </span>
          <span class="template-item__metadata {{ theme }}">
          {{ commonError.loggregationEntity.metadata.subsystemName }}
        </span>
        </div>
      </div>
      <cgx-button
        style="align-self: center;"
        label="VIEW LOGS"
        size="md"
        textNoWrap
        [outline]="true"
        (press)="onItemClicked(commonError.link)"
      ></cgx-button>
    </div>
    <div class="separator"></div>
  </div>
</div>
<sh-widget-loader *ngIf="enableLoading" [hidden]="!isLoading" [addInvertedClass]="addInvertedClassToLoader"></sh-widget-loader>
