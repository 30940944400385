import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Build } from '../../models/build';
import { FormControl } from '@angular/forms';
import { ISelectedTagData } from '@app/deployment/shared/models/view.models';
import { UserSettingsProvider } from '@app/user/shared/userSettingsProvider';
import { Store } from '@ngrx/store';
import { getSelectedShowAllSubsystems, State } from '@app/app.reducers';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sh-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
})
export class TagsListComponent implements OnInit, OnDestroy {
  @Input() tagsList: Build[];

  @Input() loading: boolean;

  @Input() selectedTag: Build;

  allSubsystems$ = new BehaviorSubject(false);

  @Output() tagSelected: EventEmitter<ISelectedTagData> = new EventEmitter<ISelectedTagData>();

  @Output() deleteTag: EventEmitter<Build> = new EventEmitter<Build>();

  @Output() editTag: EventEmitter<Build> = new EventEmitter<Build>();

  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('textFilter', { static: true }) textFilter: ElementRef;

  searchControl: FormControl = new FormControl('');

  destoryed$ = new Subject();

  constructor(private userSettingsProvider: UserSettingsProvider, private store: Store<State>) {}

  ngOnInit(): void {
    this.store
      .select(getSelectedShowAllSubsystems)
      .pipe(takeUntil(this.destoryed$))
      .subscribe(allSubsystems => {
        this.allSubsystems$.next(allSubsystems);
      });
  }

  ngOnDestroy(): void {
    this.destoryed$.next();
    this.destoryed$.complete();
  }

  tagPressed(tagSelected: Build): void {
    const selectedApplications = this.userSettingsProvider.userSettings.queryMetadata.applicationName;
    const selectedSubsystems = this.userSettingsProvider.userSettings.queryMetadata.subsystemName;

    const comparableTagList = tagSelected.getComparableTagList(
      this.tagsList,
      selectedApplications,
      selectedSubsystems,
      this.allSubsystems$.value,
    );
    const [olderTagList, newerTagList] = tagSelected.olderNewerTagLists(comparableTagList);
    this.tagSelected.emit({
      compareTagId: olderTagList[0]?.id || null,
      selectedTagId: tagSelected.id,
    });
  }
}
