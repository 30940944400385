import { Pipe, PipeTransform } from '@angular/core';
import { insightsFilter } from '../models/insight-filter-model';
import { Insight } from '../models/insight.model';
import { InsightTypeId } from '../models/enums';

@Pipe({
  name: 'insightsFilter',
})
export class InsightsFilterPipe implements PipeTransform {
  public transform(value: Insight[], args?: insightsFilter): any {
    if (args && value) {
      return value.filter(
        (insight) =>
          (([InsightTypeId.Alert, InsightTypeId.AlertGroupByFields, InsightTypeId.AlertRelative, InsightTypeId.MetricAlert].includes(
            insight.typeId,
          ) &&
            args.showAlerts) ||
            (insight.typeId === InsightTypeId.Anomaly && args.showAnomalies)) &&
          ((insight.isActive && args.showActiveInsights) || (!insight.isActive && args.showMutedInsights)) &&
          args.severities.indexOf(insight.severity) !== -1,
      );
    }
    return value;
  }
}
