import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class SamlIdpTypeModel {
  @jsonMember
  public label: string;
  @jsonMember
  public icon: string;
}

@jsonObject
export class SamlConfigurationsModel {
  @jsonMember
  public id: number;
  @jsonMember
  public is_active: boolean;
  @jsonMember
  public configured: boolean;
  @jsonMember
  public idp_info: SamlIdpTypeModel;
  @jsonMember
  public team_entity_id: string;
  @jsonMember
  public token: string;
}
