<div class="url-input-wrapper">
  <input
    #urlInput
    type="text"
    [formControl]="urlControl"
    class="url-input"
    placeholder="URL"
    maxlength="32768"
    [class.disabled-form-control]="isDisabled"
    (keyup)="onKeyupEvent($event)"
    (keydown)="onKeydownEvent($event)"
    (click)="onCaretPositionChange($event)"
  />
  <input
    #urlAutocompleteTrigger="matAutocompleteTrigger"
    class="url-input url-input-autocomplete-trigger"
    [matAutocomplete]="auto"
    [formControl]="inputAutocompleteControl"
  />
  <mat-autocomplete #auto="matAutocomplete" class="url-autocomplete">
    <div class="url-autocomplete-description">{{ (slicedOptions$ | async)?.length }}/{{ (filteredOptions$ | async)?.length }} results</div>
    <mat-option
      *ngFor="let option of slicedOptions$ | async"
      [value]="option"
      [innerHTML]="option | searchHighlight: (searchTerm$ | async)"
    ></mat-option>
  </mat-autocomplete>
  <div class="error-caret-line">
    <div class="text-cgx-error">
      <ng-container *ngIf="urlControl.touched && urlControl.errors">
        {{ errorMsg() }}
      </ng-container>
    </div>
    <div>
      <ng-container *ngIf="urlInput === document.activeElement">Column: {{ lastSelectionEnd }}</ng-container>
    </div>
  </div>
</div>
