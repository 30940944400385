import { jsonObject, jsonMember } from 'typedjson';
import 'reflect-metadata';

@jsonObject
export class ActivateUserModel {
  @jsonMember
  public id: number;
  @jsonMember
  public company: string;
  @jsonMember
  public fullname: string;
  @jsonMember
  public email: string;
  @jsonMember
  public key: string;
  @jsonMember
  public timestamp: number;
  @jsonMember
  public isExistingUser: boolean;
}

@jsonObject
export class ActivateUserModelRequest {
  @jsonMember
  public id: number;
  @jsonMember
  public company: string;

  @jsonMember
  public firstName: string;

  @jsonMember
  public how: string;

  @jsonMember
  public isExistingUser: boolean;

  @jsonMember
  public jobtitle: string;

  @jsonMember
  public key: string;

  @jsonMember
  public lastName: string;

  @jsonMember
  public password: string;

  @jsonMember
  public phone: string;

  @jsonMember
  public username: string;
}

@jsonObject
export class CompaniesLimit {
  @jsonMember
  public roi_enabled: boolean;
}

@jsonObject
export class CompanySettings {
  @jsonMember
  public enrichment_enabled: boolean;
  @jsonMember
  public cloud_security_enabled: boolean;
  @jsonMember
  public roi_enabled: boolean;
  @jsonMember
  public rbac_ui_enable: boolean;
  @jsonMember
  public logs2metrics_label_limit: number;
  @jsonMember
  public logs2metrics_permutations_limit: number;
  @jsonMember
  public max_insights_displayed: number;
  @jsonMember
  public metric_alert_group_by_limit: number;
}

@jsonObject
export class ArchiveQuery {
  @jsonMember
  public archive_format: number;
}

@jsonObject
export class CompanyRequest {
  @jsonMember
  public created_at: string;
  @jsonMember
  public emailSuffix: string;
  @jsonMember
  public genericSDKId: string;
  @jsonMember
  public id: number;
  @jsonMember
  public isActive: boolean;
  @jsonMember
  public name: string;
  @jsonMember
  public privateKey: string;
  @jsonMember
  public updated_at: string;
  @jsonMember
  public settings: CompanySettings;
  @jsonMember
  public archiving_provider: ArchiveQuery;
}

@jsonObject
export class ActivateLoginResult {
  @jsonMember
  public token: string;
}
