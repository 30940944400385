import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { getNonAllLogsFilterGridColumns, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { of } from 'rxjs/observable/of';

declare const $;

@Component({
  selector: 'sh-livetail-filters-panel',
  templateUrl: './livetail-filters-panel.component.html',
  styleUrls: ['./livetail-filters-panel.component.scss'],
})

export class LivetailFiltersPanelComponent implements AfterViewInit {
  @ViewChild('input') public input: ElementRef<HTMLInputElement>;
  @ViewChild('filtersIcon') public filtersIcon: ElementRef;
  @Input() public disabled: boolean;
  @Input() public allProperties$: Observable<string[]>;
  @Input() public keysToFilter: string[];
  @Output() public filtersChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  public readonly PLACEHOLDER: string = 'Show only the following fields';
  public readonly TOOLTIP_TEXT: string = 'Add JSON fields that you want to display in the results below. All non-matching text will be hidden from view except for the Coralogix metadata fields. Note: This action affects the view but doesn’t modify the query.';
  public searchQuery = '';
  public allFields$: Observable<string[]>;

  constructor(private store: Store<State>) {
  }

  public ngAfterViewInit(): void {
    $(this.filtersIcon.nativeElement).popup({
      popup: '.special.popup',
      position: 'bottom left',
      lastResort: 'bottom left',
      hoverable: true,
    });
  }

  public addFilter(event: Event, isSpaceClicked?: boolean): void {
    if (isSpaceClicked) {
      event.preventDefault();
    }
    const inputElement = this.input.nativeElement;
    const value = inputElement.value.trim();
    this.emptyInput();
    if (value && !this.keysToFilter.includes(value)) {
      this.keysToFilter.push(value);
      this.filtersChange.emit(this.keysToFilter);
    }
    this.searchQuery = '';
  }

  public onSearch() {
    this.allFields$ = this.store.select(getNonAllLogsFilterGridColumns)
      .filter(
        (gridColumns) =>
          gridColumns?.filter && gridColumns.filter.available && gridColumns.filter.selected,
      )
      .switchMap((gridColumns) => {
        let fields = [];
        if (!this.searchQuery) {
          return of([])
        }
        fields.push(
          ...gridColumns.filter.available.map((c) => c.colId),
          ...gridColumns.filter.selected.map((c) => c.colId),
        );
        fields = fields.filter((field) => {
          return !this.keysToFilter.includes(field) && field.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
        return of(fields.sort());
      });
  }

  public removeLastFilter(): void {
    if (!this.input.nativeElement.value) {
      this.removeFilter(this.keysToFilter.length - 1);
    }
  }

  public removeFilter(index: number): void {
    this.keysToFilter.splice(index, 1);
    this.input.nativeElement.focus();
    this.filtersChange.emit(this.keysToFilter);
  }

  private emptyInput(): void {
    this.input.nativeElement.value = '';
  }
}
