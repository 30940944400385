import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UserSettingsService } from './user-settings.service';
import { UserSettingsProvider } from './userSettingsProvider';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@app/app.reducers';
import * as userSettingsActions from '@app/user/state/user-settings/user-settings.actions';
import { UserSettings } from '@app/user/shared/user.settings';
import { TimeZoneType } from '@shared/models/timezone-types';
import { DateFormatType } from '@shared/models/date-format-types';
import { PaymentService } from '@app/settings-common/shared/services/payment/payment.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserResolve implements Resolve<any> {
  constructor(
    private userSettingsService: UserSettingsService,
    private userSettingsProvider: UserSettingsProvider,
    private paymentService: PaymentService,
    private store: Store<State>
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.userSettingsService
      .getUserSettings()
      .switchMap((settings: UserSettings) => {
        // Getting stripe customer id and insert it to the setting object for later use on Plan page
        return this.paymentService.getStripeCustomerId()
          .pipe(map(stripeData => {
            return { ...settings, stripeData };
          }))
          .catch( () => of(settings));
      })
      .map((settings: UserSettings) => {
        settings.timeZoneViewType = settings.timeZoneViewType || TimeZoneType.local;
        settings.dateFormatType = settings.dateFormatType || DateFormatType.european;
        this.userSettingsProvider.userSettings = settings;
        this.userSettingsProvider.userSettingsChanged.emit(
          this.userSettingsProvider.userSettings,
        );
        this.store.dispatch(userSettingsActions.SetUserSettingsFromResolver({ payload: settings }));
      })
      .first();
  }
}
