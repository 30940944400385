import { jsonObject, jsonMember } from 'typedjson';
import { QueryParams } from '@app/logs/shared/models/query-params.model';

@jsonObject
export class LoggregationQueryParams {
  @jsonMember
  public startDate: number;
  @jsonMember
  public endDate: number;
  @jsonMember
  public granularity: string = 'hour';
  @jsonMember
  public queryType: string = 'placeholderRTStats';
}

@jsonObject
export class LoggregationParameterRequest {
  @jsonMember
  public queryDef: LoggregationQueryParams = new LoggregationQueryParams();
  @jsonMember
  public templateId: string;
  @jsonMember
  public paramId: number;
  @jsonMember
  public queryParams: QueryParams;
}

export class PlaceholderDataRequest {
  public startDate: number;
  public endDate: number;
  public paramId: string;
  public queryParams: QueryParams;
  public type: string;
}
