import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICoralogixDialogData } from '@shared/popups/coralogix-dialog/models/coralogix-dialog-data.interface';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sh-coralogix-dialog',
    templateUrl: './coralogix-confirmation-dialog.component.html',
    styleUrls: ['./coralogix-confirmation-dialog.component.scss']
})
export class CoralogixConfirmationDialogComponent {
  public formControl: FormControl = new FormControl('');
    constructor(
        public dialogRef: MatDialogRef<CoralogixConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ICoralogixDialogData) {
      this.dialogRef.addPanelClass('coralogix-confirm-dialog');
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }
}
