import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IParsingTheme, RuleGroup, RuleMatcherFieldOptions } from '@app/rules/shared/models/rule.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RulesServerTypesToViewTypes } from '@app/rules/shared/models/rule-types-view.models';
import { environment } from '@environments/environment';
import { Constants } from '@app/constants';

@Component({
  selector: 'sh-parsing-themes-draggable-table',
  templateUrl: './parsing-themes-draggable-table.component.html',
  styleUrls: ['./parsing-themes-draggable-table.component.scss']
})
export class ParsingThemesDraggableTableComponent implements AfterViewChecked {
  public _dataSource: MatTableDataSource<IParsingTheme>;
  public displayedColumns: string[] = ['parsing-themes-draggable-table-anchor', 'parsing-themes-draggable-table-name-n-rules', 'parsing-themes-draggable-table-applications', 'parsing-themes-draggable-table-subsystems', 'parsing-themes-draggable-table-modified-creator', 'parsing-themes-draggable-table-action'];
  public rulesServerTypesToViewTypes: typeof RulesServerTypesToViewTypes = RulesServerTypesToViewTypes;
  public ruleMatcherOptions: typeof RuleMatcherFieldOptions = RuleMatcherFieldOptions;
  public metadataFields: string[] = Constants.metadataFields();
  @ViewChild('table') public table: MatTable<IParsingTheme>;

  @Input() public set dataSource(data: IParsingTheme[]) {
    this._dataSource = new MatTableDataSource<IParsingTheme>([...data]);
  }
  @Input() public disableDrag: boolean = false;
  public get tableDataSource(): MatTableDataSource<IParsingTheme> {
    return this._dataSource;
  }

  @Output() public orderGroupsChange: EventEmitter<IParsingTheme[]> = new EventEmitter<IParsingTheme[]>();
  @Output() public ruleGroupSelected: EventEmitter<IParsingTheme> = new EventEmitter<IParsingTheme>();
  @Output() public groupEnableChanged: EventEmitter<[IParsingTheme, boolean]> = new EventEmitter<[IParsingTheme, boolean]>();
  @Output() public dragDisableDragAction: EventEmitter<void> = new EventEmitter<void>();

  private disableDragEventEmitted: boolean = false;

  constructor(private cdRef: ChangeDetectorRef) {}
  public ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
  public onOrderChange(event: CdkDragDrop<MatTableDataSource<RuleGroup>>): void {
    const prevIndex = this.tableDataSource.data.findIndex((d) => d === event.item.data);
    moveItemInArray(this.tableDataSource.data, prevIndex, event.currentIndex);
    this.table.renderRows();
    this.orderGroupsChange.emit(this.tableDataSource.data);
  }

  public emitSelectedTeamEvent(team: IParsingTheme): void {
    this.ruleGroupSelected.emit(team);
  }

  public emitEventIfDragDisabled(): void {
    if (this.disableDrag && !this.disableDragEventEmitted) {
      this.disableDragEventEmitted = true;
      this.dragDisableDragAction.emit();
      setTimeout(() => {
        this.disableDragEventEmitted = false;
      }, 1000);
    }
  }
  public get isStandAlone(): boolean {
    return !!environment['standAlone'];
  }
}
