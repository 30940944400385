import { Insight } from './insight.model';
import { Alert } from './alert.model';
import { Anomaly } from './anomaly.model';

import { jsonObject, jsonArrayMember } from 'typedjson';

@jsonObject({
  knownTypes: [Alert, Anomaly],
})
export class InsightsQueryResult {
  @jsonArrayMember(Insight)
  public insights: Insight[];

  constructor() {
    this.insights = [];
  }
}
