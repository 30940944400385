<form [formGroup]="ruleMatchersForm" *ngIf="ruleMatchersForm" fxLayout="column">
  <div fxFlex="20" class="coralogix input-group">
    <sh-coralogix-select
      [selectOptions]="applicationOptions$ | async"
      [multiple]="true"
      [searchable]="true"
      [placeholder]="metadataField1Placeholder"
      [disableSelectAll]="true"
      formControlName="applications"
    >
      <label select-label>
        <span class="text">{{this.metadataField1 || 'Applications'}}</span>
      </label>
    </sh-coralogix-select>
  </div>
  <div fxFlex="20" class="coralogix input-group">
    <sh-coralogix-select
      [selectOptions]="subsystemOptions$ | async"
      [multiple]="true"
      [searchable]="true"
      [placeholder]="metadataField2Placeholder"
      [disableSelectAll]="true"
      formControlName="subsystems"
    >
      <label select-label>
        <span class="text">{{this.metadataField2 || 'Subsystems'}}</span>
      </label>
    </sh-coralogix-select>
  </div>
  <div class="coralogix input-group" *ngIf="severityOptions?.length > 0">
      <sh-coralogix-select
        formControlName="severities"
        [selectOptions]="severityOptions"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [hasColoredIcon]="true"
        [searchable]="true"
        [multiple]="true"
        [disableSelectAll]="true"
        [placeholder]="'Select severity'">
        <label
          class="label-with-tooltip"
          select-label>
          <span class="text">Severities</span>
          <div>
            <i class="fa fa-question-circle-o"
               shTooltip
               tooltip="Choose any severity level to assign to this particular rule group"
            ></i>
          </div>
        </label>
      </sh-coralogix-select>
  </div>
</form>
