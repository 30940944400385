import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
declare var $: any;
@Component({
  selector: 'sh-logs-query-tutorial',
  templateUrl: './logs-query-tutorial.component.html',
  styleUrls: ['./logs-query-tutorial.component.scss'],
})
export class LogsQueryTutorialComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
