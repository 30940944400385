<sh-desktop-navbar
  class="inverted"
  *ngIf="!isMobile"
  [isUserHasAlertPermissions]="(isReadOnly$ | async) === false"
  [companyDetails]="companyDetails"
  [isAdmin]="isAdmin$ | async"
  [showUserSettingsFilters]="showUserSettingsFilters"
></sh-desktop-navbar>

<sh-mobile-navbar
  *ngIf="isMobile"
  class="inverted"
  [isUserHasAlertPermissions]="(isReadOnly$ | async) === false"
  [showUserSettingsFilters]="showUserSettingsFilters"
></sh-mobile-navbar>
