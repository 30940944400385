import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { getApplicationNames, getSubsystemNames, State } from '@app/app.reducers';
import { SharedHtmlPath } from '@shared/consts';
import { DatepickerOptions } from '@shared/controls/sh-ng-datepicker/ng-datepicker.component';
import { DateHelper } from '@shared/services/date.helper';
import { Subscription } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'sh-tag-panel-content',
  templateUrl: './tag-panel-content.component.html',
  styleUrls: ['./tag-panel-content.component.scss'],
})
export class TagPanelContentComponent implements OnDestroy, AfterViewInit {
  @Input() public tagForm: FormGroup;
  @Input() public isCreateMode: boolean;
  @Input() public timeZoneSubHeader: string;
  @Input() public dateOptions: DatepickerOptions;
  @Input() public minDate: Date;
  public applicationOptions$: Observable<string[]>;
  public subsystemOptions$: Observable<string[]>;
  public sharedAssetsPath: string = SharedHtmlPath;
  public isTimeDisabled: boolean = null;
  private subs: Subscription[] = [];

  constructor(private store: Store<State>, private cdr: ChangeDetectorRef) {
    this.applicationOptions$ = this.store.select(getApplicationNames);
    this.subsystemOptions$ = this.store.select(getSubsystemNames);
  }

  public ngAfterViewInit(): void {
    const { build_timestamp_time, build_timestamp_date } = this.tsGroup?.controls;
    this.setIsTimeDisabled(build_timestamp_time.value, build_timestamp_date.value);
    this.subs.push(this.tsGroup?.valueChanges.subscribe((val) => {
      if (_.isNull(this.isTimeDisabled)) {
        this.setIsTimeDisabled(val.build_timestamp_time, val.build_timestamp_date);
      }
    }));
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public get tsGroup(): FormGroup {
    return this.tagForm?.controls?.ts_group as FormGroup;
  }

  public acceptTime(date: Date): void {
    this.tsGroup.controls.build_timestamp_date.setValue(date.valueOf());
    this.tsGroup.markAsDirty();
    this.tsGroup.updateValueAndValidity();
  }

  private setIsTimeDisabled(build_timestamp_time: string, build_timestamp_date: Date): void {
    const startDate = DateHelper.mergeDateAndTime(build_timestamp_date, build_timestamp_time).valueOf();
    this.isTimeDisabled = !this.isCreateMode && this.minDate.valueOf() > startDate;
    this.cdr.detectChanges();
  }

}
