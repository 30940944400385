import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IOccurrencesData } from '@app/logs-new/shared/models/logs-stats.model';
import { Chart, Options } from 'highcharts';
import * as Highcharts from 'highcharts/highstock';
import { getStringHoursFromDate } from '@app/logs-new/shared/utils/time.utils';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import HC_more from 'highcharts/highcharts-more';

HC_more(Highcharts);

@Component({
  selector: 'sh-occurrences',
  templateUrl: './occurrences.component.html',
  styleUrls: ['./occurrences.component.scss'],
})
export class OccurrencesComponent {
  @Input() public set chartData$(chartData$: Observable<IOccurrencesData>) {
    chartData$.subscribe((chartData) => {
      this.chartData = chartData;
      this.renderChart();
    });
  }
  @Output() public pinGraph = new EventEmitter();
  public chartData: IOccurrencesData;
  private chart: Chart;
  @ViewChild('chart', { static: true }) private chartEl: ElementRef;

  private renderChart(): void {
    const chartOptions = this.getLogsStatsOptions();
    this.chart = Highcharts.chart(chartOptions);
  }

  private getLogsStatsOptions(): Options {
    return {
      chart: {
        renderTo: this.chartEl.nativeElement,
        type: 'areasplinerange',
        height: 520,
        zoomType: 'x',
        backgroundColor: 'var(--menu-bg)',
      },
      title: null,
      yAxis: {
        title: false,
        min: 0,
        labels: {
          style: {
            color: 'var(--graph-text-color)',
          },
        },
      },
      xAxis: {
        categories: this.chartData.categories,
        labels: {
          formatter(): string {
            return `${getStringHoursFromDate(new Date(this.value))}`;
          },
          style: {
            color: 'var(--graph-text-color)',
          },
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'top',
        itemStyle: {
          color: 'var(--graph-text-color)',
        },
        itemHoverStyle: {
          color: 'var(--graph-text-color)',
        },
      },
      tooltip: {
        animation: false,
        useHTML: true,
        shared: true,
        followPointer: true,
        outside: false,
        hideDelay: 0,
        formatter(): string {
          const normalAmount = this.points.filter((point) => point?.series?.name === 'Normal Amount')[0]?.y || 0;
          const currentQuery = this.points.filter((point) => point?.series?.name === 'Current Query')[0]?.y || 0;
          return `<div class='chart-tooltip'>
                      <div class='date'>
                        ${formatDate(this.x, 'mediumDate', 'en-US')} ${getStringHoursFromDate(new Date(this.x))}
                      </div>
                      <div class='data-title'>Current Query</div>
                      <div class='amount'>${Math.round(currentQuery)}</div>
                      <div class='data-title'>Normal Amount</div>
                      <div class='amount'>${Math.round(normalAmount)}</div>
                   </div>`.trim();
        },
        backgroundColor: 'var(--tooltip-background)',
        borderColor: 'transparent',
        borderRadius: 8,
      },
      series: [
        {
          innerSize: '65%',
          type: 'areasplinerange',
          color: 'var(--areasplinerange-color)',
          name: 'Normal Amount',
          data: this.chartData.normalAmount,
        },
        {
          innerSize: '65%',
          type: 'spline',
          color: 'var(--spline-color)',
          data: this.chartData.currentQuery,
          name: 'Current Query',
        },
      ],
    } as object;
  }
}
