import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeightCalculatorDirective } from './height-calculator.directive';

@NgModule({
  declarations: [HeightCalculatorDirective],
  imports: [CommonModule],
  exports: [HeightCalculatorDirective],
})
export class HeightCalculatorModule {}
