import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  HostListener,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  setYear,
  eachDayOfInterval,
  getDate,
  getMonth,
  getYear,
  isToday,
  isSameDay,
  isSameMonth,
  isSameYear,
  format,
  getDay,
  subDays,
  setDay,
} from 'date-fns';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

export interface DatepickerOptions {
  minYear?: number; // default: current year - 30
  maxYear?: number; // default: current year + 30
  displayFormat?: string; // default: 'MMM dd yyyy'
  barTitleFormat?: string; // default: 'MMMM yyyy'
  firstCalendarDay?: number; // 0 = Sunday (default), 1 = Monday, ..
  locale?: object;
  minDate?: Date;
  maxDate?: Date;
}

/**
 * Internal library helper that helps to check if value is empty
 * @param value
 */
const isNil = (value: Date | DatepickerOptions) => {
  return typeof value === 'undefined' || value === null;
};

@Component({
  selector: 'sh-ng-datepicker',
  templateUrl: 'ng-datepicker.component.html',
  styleUrls: ['ng-datepicker.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgDatepickerComponent),
      multi: true,
    },
  ],
})
export class NgDatepickerComponent
  implements ControlValueAccessor, OnInit, OnChanges {
  get value(): Date {
    return this.innerValue;
  }

  set value(val: Date) {
    this.innerValue = val;
    this.onChangeCallback(this.innerValue);
  }
  @Input() public options: DatepickerOptions;

  /**
   * Disable datepicker's input
   */
  @Input() public headless = false;

  /**
   * Set datepicker's visibility state
   */
  @Input() public isOpened = false;

  /**
   * Datepicker dropdown position
   */
  @Input() public position = 'bottom-right';

  /**
   * inline to keep picker open
   */
  @Input() public inline = false;

  public innerValue: Date;
  public displayValue: string;
  public displayFormat: string;
  public date: Date;
  public barTitle: string;
  public barTitleFormat: string;
  public minYear: number;
  public maxYear: number;
  public firstCalendarDay: number;
  public view: string;
  public years: { year: number; isThisYear: boolean }[];
  public dayNames: string[];
  public scrollOptions: PerfectScrollbarConfigInterface;
  public days: {
    date: Date;
    day: number;
    month: number;
    year: number;
    inThisMonth: boolean;
    isToday: boolean;
    isSelected: boolean;
    isSelectable: boolean;
  }[];
  public locale: object;
  private positions = ['bottom-left', 'bottom-right', 'top-left', 'top-right'];

  constructor(private elementRef: ElementRef) {
    this.scrollOptions = {
      suppressScrollX: true
    };
  }

  public ngOnInit() {
    this.view = 'days';
    this.date = new Date();
    this.setOptions();
    this.initDayNames();
    this.initYears();

    // Check if 'position' property is correct
    if (this.positions.indexOf(this.position) === -1) {
      throw new TypeError(
        `ng-datepicker: invalid position property value '${
          this.position
        }' (expected: ${this.positions.join(', ')})`,
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('options' in changes) {
      this.setOptions();
      this.initDayNames();
      this.init();
      this.initYears();
    }
  }

  public setOptions(): void {
    const today = new Date(); // this const was added because during my tests, I noticed that at this level this.date is undefined
    this.minYear =
      (this.options && this.options.minYear) || getYear(today) - 30;
    this.maxYear =
      (this.options && this.options.maxYear) || getYear(today) + 30;
    this.displayFormat =
      (this.options && this.options.displayFormat) || 'MMM dd yyyy';
    this.barTitleFormat =
      (this.options && this.options.barTitleFormat) || 'MMMM yyyy';
    this.firstCalendarDay =
      (this.options && this.options.firstCalendarDay) || 0;
    this.locale = (this.options && { locale: this.options.locale }) || {};
  }

  public nextMonth(): void {
    this.date = addMonths(this.date, 1);
    this.init();
  }

  public prevMonth(): void {
    this.date = subMonths(this.date, 1);
    this.init();
  }

  public setDate(i: number): void {
    this.date = this.days[i].date;
    this.value = this.date;
    this.init();
    this.close();
  }

  public setYear(i: number): void {
    this.date = setYear(this.date, this.years[i].year);
    this.init();
    this.initYears();
    this.view = 'days';
  }

  public init(): void {
    const start = startOfMonth(this.date);
    const end = endOfMonth(this.date);

    this.days = eachDayOfInterval({ start, end }).map((date) => {
      return {
        date,
        day: getDate(date),
        month: getMonth(date),
        year: getYear(date),
        inThisMonth: true,
        isToday: isToday(date),
        isSelected:
          isSameDay(date, this.innerValue) &&
          isSameMonth(date, this.innerValue) &&
          isSameYear(date, this.innerValue),
        isSelectable: this.isDateSelectable(date),
      };
    });

    for (let i = 1; i <= getDay(start) - this.firstCalendarDay; i++) {
      const date = subDays(start, i);
      this.days.unshift({
        date,
        day: getDate(date),
        month: getMonth(date),
        year: getYear(date),
        inThisMonth: false,
        isToday: isToday(date),
        isSelected:
          isSameDay(date, this.innerValue) &&
          isSameMonth(date, this.innerValue) &&
          isSameYear(date, this.innerValue),
        isSelectable: this.isDateSelectable(date),
      });
    }

    this.displayValue = this.innerValue
      ? format(this.innerValue, this.displayFormat, this.locale)
      : '';
    this.barTitle = format(start, this.barTitleFormat, this.locale);
  }

  public initYears(): void {
    const range = this.maxYear - this.minYear;
    this.years = Array.from(new Array(range), (x, i) => i + this.minYear).map(
      (year) => {
        return { year, isThisYear: year === getYear(this.date) };
      },
    );
  }

  public initDayNames(): void {
    this.dayNames = [];
    const start = this.firstCalendarDay;
    for (let i = start; i <= 6 + start; i++) {
      const date = setDay(new Date(), i);
      this.dayNames.push(format(date, 'iii', this.locale));
    }
  }

  public toggleView(): void {
    this.view = this.view === 'days' ? 'years' : 'days';
  }

  public toggle(): void {
    this.isOpened = !this.isOpened || this.inline;
  }

  public close(): void {
    this.isOpened = this.inline;
  }

  public writeValue(val: Date) {
    if (val) {
      this.date = val;
      this.innerValue = val;
      this.init();
      this.displayValue = format(
        this.innerValue,
        this.displayFormat,
        this.locale,
      );
      this.barTitle = format(
        startOfMonth(val),
        this.barTitleFormat,
        this.locale,
      );
    }
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @HostListener('document:click', ['$event']) public onBlur(e: MouseEvent) {
    if (!this.isOpened) {
      return;
    }

    const input = this.elementRef.nativeElement.querySelector(
      '.ngx-datepicker-input',
    );

    if (input == null) {
      return;
    }

    if (e.target === input || input.contains(e.target as any)) {
      return;
    }

    const container = this.elementRef.nativeElement.querySelector(
      '.ngx-datepicker-calendar-container',
    );
    if (
      container &&
      container !== e.target &&
      !container.contains(e.target as any) &&
      !(e.target as any).classList.contains('year-unit')
    ) {
      this.close();
    }
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  /**
   * Checks if specified date is in range of min and max dates
   * @param date
   */
  private isDateSelectable(date: Date): boolean {
    if (isNil(this.options)) {
      return true;
    }

    const minDateSet = !isNil(this.options.minDate);
    const maxDateSet = !isNil(this.options.maxDate);
    const timestamp = date.valueOf();

    if (minDateSet && timestamp < this.options.minDate.valueOf()) {
      return false;
    }

    if (maxDateSet && timestamp > this.options.maxDate.valueOf()) {
      return false;
    }

    return true;
  }
}
