<sh-archiving-providers-item-details
  [archivingProvidersItem]="archivingProviders$ | async"
  [loading]="isLoading$ | async"
  [saving]="isSaving$ | async"
  [testing]="testing$ | async"
  (saveArchivingProvider)="editArchivingProvider($event)"
  (testArchivingProvider)="testArchivingProvider($event)"
  [testingResult]="testingResult$ | async"
></sh-archiving-providers-item-details>
<sh-archiving-providers-s3-tutorial></sh-archiving-providers-s3-tutorial>
