import { Insight } from './insight.model';
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { AlertConditions } from './alert-conditions.model';
import { AlertProperties } from './alert-properties.model';
import { AlertESInfoModel } from './alert-es-info.model';
import { RelativeAlert } from './relative-alert.model';
import { MetricAlert } from './alert-metric.model';

@jsonObject
export class Alert extends Insight {
  @jsonMember
  public IPAddress: string;
  @jsonMember
  public computerName: string;
  @jsonMember
  public conditions: AlertConditions;
  @jsonMember({ name: 'eventProperties' })
  public properties: AlertProperties;
  @jsonMember
  public hasConditions: boolean;
  @jsonMember
  public sdkId: string;
  @jsonMember
  public isResolved: boolean;
  @jsonMember
  public conditionThreshold: number;
  @jsonMember
  public conditionTimeframe: number;
  @jsonMember
  public conditionOperator: number;
  @jsonMember
  public esInfo: AlertESInfoModel;
  @jsonArrayMember(RelativeAlert)
  public relativeAlerts: RelativeAlert[] = [];
  @jsonMember
  public metricAlert: MetricAlert;
}
