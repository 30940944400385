import JSONFormatter, { JSONFormatterConfiguration } from '@coralogix/json-formatter';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'json-formatter',
})
export class JsonFormatterDirective implements OnInit {
  @Input() public data: any;
  @Input() public searchQuery: string;
  public formatterConfig: JSONFormatterConfiguration = {
    theme: 'dark',
    hoverPreviewEnabled: true,
  };

  constructor(private elRef: ElementRef) {
  }

  public ngOnInit(): void {
    const data = this.checkAndParseJSON(this.data) || this.data;
    this.renderPrettifiedObject(data);
  }

  public renderPrettifiedObject(object: object): void {
    const formatter = new JSONFormatter(object, 1, this.searchQuery, this.formatterConfig);
    this.elRef.nativeElement.appendChild(formatter.render());
  }

  public checkAndParseJSON(str: string): false | object {
    try {
      return JSON.parse(str);
    } catch (e) {
      return false;
    }
  }
}
