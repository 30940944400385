import { EnrichmentsResponse } from '../../../enrichments-settings/enrichment.model';
import { EnrichmentsActions } from './enrichment.actions';
import { CustomEnrichment, CustomEnrichmentsResponse } from '@app/settings/enrichments-settings/custom-enrichment.model';

/* tslint:disable-next-line:no-namespace */
export namespace EnrichmentsReducer {
  export interface IState {
    enrichments: EnrichmentsResponse;
    customEnrichments: CustomEnrichmentsResponse;
    customEnrichmentsEditorLoading: boolean;
    customEnrichmentDeleted: number;
  }

  const initialState: IState = {
    enrichments: null,
    customEnrichments: null,
    customEnrichmentsEditorLoading: false,
    customEnrichmentDeleted: null,
  };

  export function reducer(
    state: IState = initialState,
    action: EnrichmentsActions.Actions,
  ): IState {
    switch (action.type) {
      case EnrichmentsActions.ActionTypes.GET_ENRICHMENTS_COMPLETED: {
        return Object.assign({}, state, { enrichments: action.payload || [] });
      }
      case EnrichmentsActions.ActionTypes.CREATE_ENRICHMENTS_COMPLETED: {
        return Object.assign({}, state, { enrichments: action.payload || [] });
      }
      case EnrichmentsActions.ActionTypes.DELETE_ENRICHMENTS_COMPLETED: {
        return Object.assign({}, state, { enrichments: action.payload || [] });
      }

      case EnrichmentsActions.ActionTypes.GET_CUSTOM_ENRICHMENTS_COMPLETED: {
        return Object.assign({}, state, { customEnrichments: action.payload || [] });
      }
      case EnrichmentsActions.ActionTypes.DELETE_CUSTOM_ENRICHMENT: {
        return Object.assign({}, state, { customEnrichmentDeleted: action.payload });
      }
      case EnrichmentsActions.ActionTypes.DELETE_CUSTOM_ENRICHMENT_COMPLETED: {
        return Object.assign({}, state, { customEnrichmentDeleted: null });
      }
      case EnrichmentsActions.ActionTypes.DELETE_CUSTOM_ENRICHMENT_FAILED: {
        return Object.assign({}, state, { customEnrichmentDeleted: null });
      }

      case EnrichmentsActions.ActionTypes.CUSTOM_ENRICHMENTS_LOADING: {
        return Object.assign({}, state, { customEnrichmentsEditorLoading: action.payload });
      }

      default:
        return state;
    }
  }
}
