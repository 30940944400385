import { EGraphTooltipStyles } from '@shared/controls/charts/models/chart.model';

export type timestampVal = number;
export type graphPointValue = number;
export type minValue = number;
export type maxValue = number;
export interface ILineGraph {
  seriesValues: [timestampVal, graphPointValue][][];
  rangeValues?: [timestampVal, minValue, maxValue][][];
}
export const seriesColors = ['var(--cgx-primary)', '#8d9db9'];
export const RangeSeriesColors = ['#d4e4ff'];
export interface IInitialConfig {
  xTitle?: string;
  yTitle: string;
  height: number;
  gridLineWidthX?: number;
  gridLineWidthY?: number;
  seriesNames?: string[];
  rangeSeriesNames?: string[];
  tooltipButtonText?: string;
  chartTooltipDataTitle?: string;
  xCategories: (string | number)[];
  yCategories: (string | number)[];
  tooltipStyleType: EGraphTooltipStyles;
  tooltipRightOffsetInPx: number;
  scale?: EScaleOptions;
}

export enum EScaleOptions {
  minutes = 'm',
  hours = 'h',
  seconds = 's'
}

export interface ILineChartConfig {
  values: ILineGraph;
  config: IInitialConfig;
}
