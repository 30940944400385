import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ShDialogService } from '@shared/services/dialogService';
import { State } from '@app/app.reducers';
import { TeamActions } from '@app/user/state/teams/teams.actions';
import { AuthService } from '@app/auth/shared/services/auth.service';
import { SamlBaseContainerComponent } from '@app/auth/shared/components/saml-base-container';
import { SamlService } from '@app/settings-common/shared/services/saml.service';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';
import { RecaptchaComponent, ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '@environments/environment';
import { Constants } from '@app/constants';

@Component({
  selector: 'sh-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends SamlBaseContainerComponent implements AfterViewInit, OnInit {
  @ViewChild('recaptchaRef', { static: false }) recaptchaRef: RecaptchaComponent;

  recaptchaV2SiteKey = Constants.GetRecaptchaV2SiteKey();

  loginForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  recaptchaVersion$ = new BehaviorSubject('v3');

  errorString: string;

  hide = true;

  enableLoginWithSso = false;

  teamName$: Observable<string>;

  loadingRecaptchaV3 = false;

  token: string = undefined;

  constructor(
    private authService: AuthService,
    protected router: Router,
    private store: Store<State>,
    protected dialogService: ShDialogService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    protected samlService: SamlService,
  ) {
    super(samlService, dialogService, router);
  }

  ngOnInit(): void {
    window.Intercom('shutdown');
    const hostname = window.location.hostname.split('.');
    if (this.isHostContainsTeamName(hostname)) {
      const [teamName] = hostname;
      this.checkSaml(teamName);
    }
  }

  ngAfterViewInit(): void {
    // fix for material input place holder.
    // if detect chrome auto fill, remove class 'mat-empty' from place holder label
    setTimeout(() => {
      try {
        const elem = document.querySelector('input[type=password]:-webkit-autofill');
        if (elem) {
          elem.nextElementSibling.children[0].className = elem.nextElementSibling.children[0].className.replace('mat-empty', '');
        }
      } catch (e) {
        return;
      }
    }, 150);
  }

  isFieldInvalid(name: string): boolean {
    return this.loginForm.get(name).invalid;
  }

  async submit(): Promise<void> {
    if (this.loadingRecaptchaV3 || this.isLoading) {
      return;
    }

    const { testtoken } = this.route.snapshot.queryParams;
    if (!testtoken) {
      if (!environment.skipRecaptcha && !this.token && this.recaptchaVersion$.value === 'v3') {
        await this.receiveTokenFromRecaptchaV3();
      }
    }

    this.loginForm.markAsTouched();

    if (!this.loginForm.valid) {
      return;
    }

    const { username, password } = this.loginForm.value;

    this.errorString = null;
    this.isLoading = true;

    this.authService
      .login({
        username,
        password,
        token: this.token,
        version: this.recaptchaVersion$.value,
      })
      .pipe(
        take(1),
        tap(() => {
          this.store.dispatch(new TeamActions.LoginToTeam(null));
        }),
        catchError(err => {
          if (err?.status === 412) {
            this.recaptchaVersion$.next('v2');
          }

          if (err.error) {
            this.errorString = err.error;
          } else {
            this.errorString = 'Ooops... Something went wrong';
          }
          this.dialogService.showCoralogixMessage(this.errorString, null, dialogServiceIconClasses.failed);
          if (this.recaptchaRef) {
            this.recaptchaRef.reset();
          }

          return of(null);
        }),
        finalize(() => {
          this.isLoading = false;
          this.token = null;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  onResolveRecaptchaV2(token: string): void {
    this.token = token;
  }

  async receiveTokenFromRecaptchaV3(): Promise<void> {
    try {
      this.loadingRecaptchaV3 = true;
      const tokenToUpdate = await this.recaptchaV3Service.execute('login').toPromise();
      this.token = tokenToUpdate;
    } catch (err) {
      const message = 'Failed to authenticate user as a human';
      this.dialogService.showCoralogixMessage(message, null, dialogServiceIconClasses.failed);
      this.recaptchaVersion$.next('v2');
      return;
    } finally {
      this.loadingRecaptchaV3 = false;
    }
  }
}
