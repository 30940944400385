import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { TypedJsonAny } from '@shared/models/typedjson/any';
import { AlertHistory } from '@app/alerts/shared/models/alert-history.model';

@jsonObject
export class Conditions {
  @jsonMember
  public conditionOperator: number;
  @jsonMember
  public conditionThreshold: number;
  @jsonMember
  public conditionTimeframe: number;
}

@jsonObject
export class NotificationsProperties {
  @jsonArrayMember(String)
  public payloadFilter: string[];
  @jsonArrayMember(String)
  public emailAddresses: string[];
}

@jsonObject
export class ActiveWhenTimeframe {
  @jsonArrayMember(Number)
  public days_of_week: number[];
  @jsonMember
  public activity_ends: string;
  @jsonMember
  public activity_starts: string;
}

@jsonObject
export class ActiveWhenProperties {
  @jsonArrayMember(ActiveWhenTimeframe)
  public timeframes: ActiveWhenTimeframe[];
}

@jsonObject
export class RelativeAlert {
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public applicationName: any[];
  @jsonArrayMember(Number)
  public logSeverity: number[];
  @jsonMember
  public alias: string;
  @jsonMember
  public parentAlias: string;
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public subsystemName: any[];
  @jsonMember
  public text: string;
  @jsonMember
  public alertText: string;
  @jsonMember
  public order: number;
  @jsonMember
  public operator: string = '/';
  @jsonMember
  public relativeTimeframe: number = 0;
}

@jsonObject
export class MetricAlert {
  @jsonMember
  public arithmeticOperator: number;
  @jsonMember
  public metricFieldExtraction: string;
  @jsonMember
  public metricId: string;
  @jsonMember
  public swapNullValue: number;
  @jsonMember
  public sampleThreshold: number;
  @jsonMember
  public nonNullPercentage: number;
  @jsonMember
  public arithmeticOperatorModifier: number;
  @jsonMember
  public valueThreshold: number;
  @jsonMember
  public promqlText: string;
}

@jsonObject
export class AlertModel {
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public IPAddress: any[];
  @jsonMember
  public alertSeverity: number;
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public applicationName: any[];
  @jsonArrayMember(String)
  public category: string[];
  @jsonArrayMember(String)
  public className: string[];
  @jsonMember
  public companyId: string;
  @jsonArrayMember(String)
  public computerName: string[];
  @jsonMember
  public conditions: Conditions;
  @jsonMember
  public relativeTimeframe: number;
  @jsonMember
  public notifyOnResolved: boolean;
  @jsonMember
  public createDate: number;
  @jsonMember
  public description: string;
  @jsonMember
  public endDate: number;
  @jsonMember
  public hasConditions: boolean;
  @jsonMember
  public id: string;
  @jsonMember
  public isActive: boolean;
  @jsonMember
  public activeWhen: ActiveWhenProperties;
  @jsonMember
  public isNotifActive: boolean;
  @jsonArrayMember(AlertHistory)
  public history: AlertHistory[];
  @jsonArrayMember(Number)
  public logSeverity: number[];
  @jsonArrayMember(String)
  public methodName: string[];
  @jsonMember
  public name: string;
  @jsonMember
  public notifProperties: NotificationsProperties;
  @jsonMember
  public processName: string;
  @jsonMember
  public refractoryPeriod: number;
  @jsonMember
  public startDate: number;
  @jsonArrayMember(TypedJsonAny, TypedJsonAny.OPTIONS)
  public subsystemName: any[];
  @jsonMember
  public text: string;
  @jsonMember
  public updateDate: number;
  @jsonMember
  public lastRaisedDate: number;
  @jsonMember
  public userAlertTypeId: number;
  @jsonArrayMember(String)
  public groupByFields: string[];
  @jsonMember
  public notifyGroupByOnlyAlerts: boolean;
  @jsonArrayMember(String)
  public cardinalityFields: string[];
  @jsonMember
  public cardinalityGroupByFields: number;
  @jsonArrayMember(RelativeAlert)
  public relativeAlerts: RelativeAlert[];
  @jsonMember
  public metricAlert: MetricAlert;
  @jsonMember
  public uniqueCountAlertInfo: { groupByFieldLimit: number };

  constructor(init?: Partial<AlertModel>) {
    this.logSeverity = [];
    this.IPAddress = [];
    this.category = [];
    this.subsystemName = [];
    this.computerName = [];
    this.className = [];
    this.applicationName = [];
    this.methodName = [];
    this.relativeAlerts = [];
    Object.assign(this, init);
  }
}

@jsonObject()
export class AlertRes {
  @jsonArrayMember(AlertModel)
  public results: AlertModel[];
}

export enum AlertsErrorStatusCodeOptionsEnum {
  default = 'default',
  invalidQuery = 417,
}

export const ERROR_MSG: { [key in AlertsErrorStatusCodeOptionsEnum]: string } = {
  417: 'Alerts Query invalid',
  default: 'Failed to save alert',
};
