import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { EGraphTooltipStyles } from '@shared/controls/charts/models/chart.model';
import { StatisticsQueryDefinition } from '@app/statistics/shared/models/statistics-query-definition';
import { WidgetBaseComponent } from '@shared/components/widget-base-component';
import { StatisticService } from '@app/statistics/shared/services/statistics-service';
import { InsightsService } from '@app/insights/shared/services/insights-service';
import { combineLatest } from 'rxjs';
import { getTagNComparePayloadBase, State } from '@app/app.reducers';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';
import { ITagRequest, ITagWithCompareBaseRequest } from '@app/deployment/shared/models/summary.models';
import { ILegendData } from '@app/statistics/components/chart-legend/chart-legend.component';
import { compareLineChartLegend } from '@app/deployment/models/line-graphs';
import { ILineChartConfig } from '@app/statistics/shared/models/line-chart.models';
import { getTimeUnitWidgetScale, getXTimeCategories } from '@app/statistics/shared/helpers/line-chart-tags-helper';
import { lineChartLegend } from '@app/deployment/models/alerts-summary';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { LogsService } from '@app/logs/shared/services/logs-service';
import { Router } from '@angular/router';
import { StatisticsResult } from '@app/statistics/shared/models/statistics-logs.response';

@Component({
  selector: 'sh-compare-line-graph-widget',
  templateUrl: './compare-line-graph-widget.component.html',
  styleUrls: ['./compare-line-graph-widget.component.scss'],
})
export class CompareLineGraphWidgetComponent extends WidgetBaseComponent {
  @Input() set queryDefinition(queryDef: StatisticsQueryDefinition) {
    if (queryDef) {
      this._statisticsQueryDefinition = queryDef;
      this.isLoading = true;
      this.store
        .select(getTagNComparePayloadBase)
        .take(1)
        .pipe(
          switchMap((data: ITagWithCompareBaseRequest) => {
            const tagData: StatisticsQueryDefinition = this.getStatisticPayloadFromTag(queryDef, data.tag);
            const compareData: StatisticsQueryDefinition = this.getStatisticPayloadFromTag(queryDef, data.compareTag);
            return combineLatest([
              this.statisticService.getStats(tagData),
              this.statisticService.getStats(compareData),
            ]);
          }),
        )
        .subscribe(([tagData, compareData]) => {
          if (!!tagData) {
            this.logsTimestampArray = tagData.data?.xAxis?.values;
            this.graphInfo = this.getMappedLineGraph(tagData, compareData);
          }
        });
    }
  }
  public _statisticsQueryDefinition: StatisticsQueryDefinition;
  public lineGraphLegend: ILegendData[] = compareLineChartLegend;
  public graphInfo: ILineChartConfig;
  private logsTimestampArray: number[] = [];

  constructor(
    private statisticService: StatisticService,
    private insightsService: InsightsService,
    private store: Store<State>,
    private logsService: LogsService,
    private router: Router,
  ) {
    super();
  }

  public navigateToLogs(dataIndex: number): void {
    const timestampArr = this.logsTimestampArray;
    const timeGap = Math.abs(differenceInSeconds(timestampArr[1], timestampArr[0]));
    const startDate = new Date(timestampArr[dataIndex]);
    const endDate = addSeconds(timestampArr[dataIndex], timeGap);
    const queryModel = new LogQueryModel(startDate, endDate);
    queryModel.queryParams = _.cloneDeep(this._statisticsQueryDefinition.logQeuryModel.queryParams);
    queryModel.cacheQueryId = this.logsService.getNewQueryId();

    this.logsService
      .saveLogsQuery(queryModel)
      .first()
      .subscribe(() => {
        const url =
          '#' +
          this.router.serializeUrl(
            this.router.createUrlTree(['query-new', 'logs'], {
              queryParams: { id: queryModel.cacheQueryId },
            }),
          );
        window.open(url, '_blank');
      });
  }

  private getStatisticPayloadFromTag(value: StatisticsQueryDefinition, tag: ITagRequest): StatisticsQueryDefinition {
    return {
      ..._.cloneDeep(value),
      logQeuryModel: {
        ...value.logQeuryModel,
        startDate: tag.startTime,
        endDate: tag.endTime,
      },
    } as StatisticsQueryDefinition;
  }

  private getMappedLineGraph(tagResults: StatisticsResult, compareResults: StatisticsResult): ILineChartConfig {
    const scale = getTimeUnitWidgetScale(tagResults.data.xAxis.values);
    const tagValuesMap = this.getExtractedDataFromStatistics(tagResults);
    const tagCompareValuesMap = this.getExtractedDataFromStatistics(compareResults);
    return {
      values: {
        seriesValues: [tagValuesMap, tagCompareValuesMap],
      },
      config: {
        yTitle: 'LOG VOLUME',
        xTitle: 'TIME ELAPSED SINCE TAG',
        scale,
        height: 250,
        seriesNames: lineChartLegend.map((item) => item.name),
        chartTooltipDataTitle: '',
        tooltipButtonText: 'VIEW LOGS',
        xCategories: getXTimeCategories(tagValuesMap, scale),
        tooltipStyleType: EGraphTooltipStyles.whiteWithLinkButtonLogs,
        yCategories: [],
        tooltipRightOffsetInPx: 60,
      },
    };
  }

  private getExtractedDataFromStatistics(results: StatisticsResult): [number, number][] {
    return results.data.xAxis.values.map((data, index) => {
      const sumY = results.data.yAxis.values.reduce((prev, curr) => {
        return prev + Number(curr[index]);
      }, 0);
      return [data, sumY];
    }, []);
  }
}
