import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: 'preview-placeholder',
  selector: 'sh-preview-placeholder',
  templateUrl: 'preview-placeholder.component.html',
  styleUrls: ['preview-placeholder.component.scss'],
})
export class PreviewPlaceholderComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
