<div class="main-container">
    <div class="header">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div
                    class="title"
            >
                RegEx Cheat Sheet
            </div>
            <div class="close-button" (click)="onClose()"><span>×</span></div>
        </div>
    </div>
        <div class="page-content" fxLayout="row">
            <div class="col sidebar-container">
                <sh-regex-cheat-sheet-sidebar (sectionSelected)="onSectionSelected($event)">
                </sh-regex-cheat-sheet-sidebar>
            </div>
            <div class="col table-container">
                <sh-regex-cheat-sheet-table #tableComponent>
                </sh-regex-cheat-sheet-table>
            </div>

        </div>
</div>
