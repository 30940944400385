import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface IDialogData {
  rawLog: string;
}

@Component({
  selector: 'sh-view-rawlog-popup',
  templateUrl: './view-raw-log.component.html',
  styleUrls: ['./view-raw-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewRawLogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IDialogData, public dialogRef: MatDialogRef<ViewRawLogComponent>) {}
}
