import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SearchProvider } from './search.provider';
@Component({
  selector: 'sh-logs-grid-find',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss'],
})
export class SearchComponent {
  @Input() public searchProvider: SearchProvider;

  @ViewChild('textInput', { static: true }) public textInputref: ElementRef;

  public allowSearch: boolean;

  public currentText: string;

  public getLoweredText(input: string): string {
    let str: string = input;
    if (input) {
      str = input.toLowerCase();
    }
    return str;
  }

  public OnFindKeyUp(e: string): void {
    this.currentText = this.getLoweredText(e);
    this.searchProvider.onFind.emit(this.getLoweredText(e));
  }

  public findDown(e: any): void {
    this.searchProvider.onFindDown.emit(e);
  }

  public findUp(e: any): void {
    this.searchProvider.onFindUp.emit(e);
  }

  public clear(): void {
    this.textInputref.nativeElement.value = null;
    this.currentText = null;
    this.searchProvider.onFind.emit(null);
  }
}
