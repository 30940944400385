<div class="json-header">
  <div *ngIf="parentKey" class="parent-key">
    <span class="json-key">{{ parentKey }}</span>
    :
  </div>
  <div class="open-brackets">{{ isArray ? '[' : '{' }}</div>
  <div class="action-buttons">
    <sh-json-action-buttons
      [isCopyToClipboardEnabled]="!hideJsonParams"
      (onCopyToClipbboard)="copyValueToClipboard(jsonObject, parentKey)"
    ></sh-json-action-buttons>
  </div>
</div>
<div class="json-item">
  <div *ngIf="!isObjectArray(jsonObject)">
    <div *ngFor="let key of jsonObject | keys">
      <div>
        <div
          *ngIf="!isJson(jsonObject[key]) && !isObjectArray(jsonObject[key])"
          class="json-key-value"
        >
          <span class="json-key margin-right">{{ key }}</span>
          :
          <div
            class="json-value"
            [class.null-value]="!jsonObject[key]"
            [class.number-value]="isNumber(jsonObject[key])"
          >
            {{ getJsonValueString(jsonObject[key]) }}
          </div>
          <div class="action-buttons">
            <sh-json-action-buttons
              (onFilterByValue)="filterByValue(key, jsonObject[key])"
              [isAddWidgetEnabled]="isShowWidgetEnabled"
              [isFiltersEnabled]="isFiltersEnabled"
              [isCopyToClipboardEnabled]="!hideJsonParams"
              (onShowWidget)="showWidgetClicked(key)"
              (onCopyToClipbboard)="copyValueToClipboard(jsonObject, key)"
            ></sh-json-action-buttons>
          </div>
        </div>
        <div *ngIf="isJson(jsonObject[key]) || isObjectArray(jsonObject[key])">
          <sh-json-property
            *ngIf="isJson"
            [jsonObject]="jsonObject[key]"
            [hideJsonParams]="hideJsonParams"
            [isFiltersEnabled]="isFiltersEnabled"
            (filterByJsonValue)="propagateJsonKeyClicked($event, key)"
            (onShowWidget)="propagateShowWidgetClick(key, $event)"
            [parentKey]="key"
          ></sh-json-property>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isObjectArray(jsonObject)">
    <div *ngFor="let item of getArrayValue(jsonObject); let key = index">
      <div *ngIf="isJson(item) || isObjectArray(item)">
        <sh-json-property
          [jsonObject]="item"
          [hideJsonParams]="hideJsonParams"
          [isFiltersEnabled]="isFiltersEnabled"
          (filterByJsonValue)="propagateJsonKeyClicked($event, '' + key)"
          (onShowWidget)="propagateShowWidgetClick('' + key, $event)"
        ></sh-json-property>
      </div>
      <div *ngIf="!isJson(item) && !isObjectArray(item)" class="json-key-value">
        <div
          class="json-value"
          [class.null-value]="!item"
          [class.number-value]="isNumber(item)"
        >
          {{ getJsonValueString(item) }},
        </div>
        <div class="action-buttons">
          <sh-json-action-buttons
            [isCopyToClipboardEnabled]="!hideJsonParams"
            (onCopyToClipbboard)="copyValueToClipboard(item, parentKey)"
          ></sh-json-action-buttons>
        </div>
      </div>
    </div>
  </div>
</div>
{{ isArray ? ']' : '}' }}
