<div style="display: flex;max-width: 40px;">
  <div
    (click)="click($event, 'left')"
    [class.marked]="stateLeft"
    class="toggle-button"
  >
    L
  </div>
  <div
    (click)="click($event, 'right')"
    [class.marked]="stateRight"
    class="toggle-button"
  >
    R
  </div>
</div>
