import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GridOptions } from 'ag-grid/main';
import * as _ from 'lodash';

import { LoggregationService } from '../../shared/services/loggregation.service';
import { LoggregationGridColumnDef } from './Loggregation-column-def';
import { LoggregationEntity } from '../../shared/interfaces/loggregation.interface';
import { LogQueryModel } from '@app/logs/shared/models/logsquery.model';
import { AnomalyDetails } from '@app/insights/shared/models/anomaly-properties.model';
import { LoggregationParameterRequest } from '../../shared/interfaces/loggregation-parameter-request';
import { Widget } from '@app/widgets/shared/models/widget';
import { WidgetsService } from '@app/widgets/shared/services/widgets.service';
import { TemplateType } from '../../shared/interfaces/templateType.enum';
import { PrettyJsonPipe } from '@shared/pipes/pretty-json.pipe';
import { StatisticService } from '@app/statistics/shared/services/statistics-service';
import { StatisticsQueryDefinition, StatisticsCategory } from '@app/statistics/shared/models/statistics-query-definition';
import { QueryTypes } from '@app/logs/shared/models/query-types.options';
import { State } from '@app/app.reducers';
import { LogsFilterGridColumnsActions } from '@app/logs/shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { Log } from '@app/logs/shared/models/log.entity';
import { LogsQueryActions } from '@app/logs/shared/state/logs-query/logs-query.actions';
import { LoggregationManager } from '@app/logs/shared/services/loggregation-manager';
import { GridPagingComponent } from '@shared/controls/grid/grid-paging/grid-paging.component';
import { WidgetAddComponent } from '@shared/controls/widgets/widget-add/widget-add.component';
import { Select } from '@ngxs/store';
import { UserState } from '@app/ngxs-store/user/user.state';

declare let $;

@Component({
  moduleId: 'loggregation-grid',
  selector: 'sh-loggregation-grid',
  templateUrl: 'loggregation-grid.component.html',
  styleUrls: ['loggregation-grid.component.scss'],
})
export class LoggregationGridComponent implements OnInit, AfterViewInit {
  @Select(UserState.isReadOnly) public isReadOnly$: Observable<boolean>;

  @Input() set data(value: any) {
    if (value) {
      this.items = value;
      this.setData(this.items);
    }
  }

  @Input() public highlightedTemplates: string[];

  @Input() public isAnomaly: boolean;

  @Input() public isGridOverlayVisible: boolean;

  @Input() public queryModel: LogQueryModel;

  @Output() public textInfoClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output() public selectedItemChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() public onItemsCountChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') public modal: ElementRef;

  @ViewChild(GridPagingComponent) public pagingComponent: GridPagingComponent;

  public gridOptions: GridOptions;

  public items: any;

  public PAGE_SIZE: number = 100;

  public isGridReady: boolean;

  public parameterData: any;

  public isChartOverlayVisible: boolean = true;

  public parameterQueryModel: any;

  public widgetUrl: string = 'loggregation/parameter';

  public columnDefs: any;

  public chartType: string = 'Loading...';

  public prettyJsonPipe: PrettyJsonPipe = new PrettyJsonPipe();

  public currentPage: number;

  public totalPages: number;

  public paginationControll: any;

  public loggregationManager: LoggregationManager;

  public elm: any;

  constructor(
    private loggregationService: LoggregationService,
    private widgetService: WidgetsService,
    public dialog: MatDialog,
    private statisticsService: StatisticService,
    private store: Store<State>,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(new LogsFilterGridColumnsActions.SetVisibilityLogsFilterGridColumns(false));
  }

  public ngAfterViewInit(): void {
    $(this.modal.nativeElement).modal({ closable: true });
  }

  // clickableParam is the template of the loggregation parameter (span, div etc...),
  public buildLoggregationParametersHTML(
    input: any,
    clickableParam: string,
    isJson: boolean = false,
    logExample: any = null,
    placeholdersEnabled: boolean = true,
  ): string {
    if (!input) {
      return 'NO TEXT';
    }

    let loggregationParamPattern: RegExp;
    if (isJson) {
      loggregationParamPattern = new RegExp(/\{CoralogixJsonPH(\d+)_([^]*?.*?)_([json]+)_CoralogixJsonPH\}/g);
    } else {
      loggregationParamPattern = new RegExp(/\{CoralogixPH(\d+)_([^]*?.*?)_([free|categorical|numeric]+)_CoralogixPH\}/g);
    }
    let replaced;

    if (clickableParam) {
      // replace the server data with loggregation parameter template
      if (isJson && logExample) {
        replaced = input.replace(
          loggregationParamPattern,
          (match, p1, p2, p3) => {
            const path = p2.split('.');

            const prop = this.getProp(logExample, path);
            let parameterHtml = clickableParam.replace('$2', p2);
            parameterHtml = parameterHtml.replace('$3', p3);
            parameterHtml = parameterHtml.replace('$3', p3);
            parameterHtml = parameterHtml.replace('*VALUE', !_.isUndefined(prop) ? prop : '*VALUE');

            return parameterHtml;
          },
          clickableParam,
        );
      } else {
        replaced = input.replace(loggregationParamPattern, clickableParam);
      }
    } else {
      // replace the server data with the parameter name and trim it for rowHeight calculation.
      replaced = input.replace(
        loggregationParamPattern,
        (match, p1, p2) => {
          return p2.substring(0, 40);
        },
        clickableParam,
      );
    }

    return replaced.replace(/{CoralogixPHStatsEnabled}/g, placeholdersEnabled ? '' : 'disabled');
  }

  public showModal(templateId: any, parameterId: any, parameterType: any): void {
    $(this.modal.nativeElement).modal('show');
    this.parameterQueryModel = null;
    this.widgetUrl = 'loggregation/parameter';
    if (parameterType === 'json') {
      this.widgetUrl = 'statistics/logs1';
      this.getJsonParameterData(templateId, parameterId, parameterType);
    } else {
      const request = new LoggregationParameterRequest();
      request.templateId = templateId;
      request.paramId = parameterId ? parseInt(parameterId, 10) : null;
      this.parameterQueryModel = request;
      if (parameterId) {
        this.loggregationService.getParameterData(templateId, parameterId, parameterType, this.queryModel).subscribe(
          res => {
            this.parameterData = res;
            this.chartType = this.getTypeTitle(parameterType) + ' Parameter';
            this.isChartOverlayVisible = false;
          }, // this will set the chart child component with data;
          error => console.log(error),
        );
      } else {
        this.loggregationService.getOccurrencesData(templateId, this.queryModel).subscribe(
          res => {
            this.parameterData = res;
            this.chartType = 'Occurrences';
            this.isChartOverlayVisible = false;
          },
          error => console.log(error),
        );
      }
    }
  }

  public getJsonParameterData(templateId, paramId, parameterType): void {
    const paramQueryModel: LogQueryModel = _.cloneDeep(this.queryModel);
    paramQueryModel.queryParams.aggField = paramId;
    if (templateId) {
      paramQueryModel.queryParams.query.templateIds = [templateId];
      paramQueryModel.type = QueryTypes.TEMPLATE_IDS;
    }
    this.parameterQueryModel = paramQueryModel;
    const statQueryDef: StatisticsQueryDefinition = new StatisticsQueryDefinition();
    statQueryDef.logQeuryModel = paramQueryModel;
    statQueryDef.category = StatisticsCategory.LOGS;
    statQueryDef.seriesType = 'column';
    this.statisticsService
      .getStats(statQueryDef)
      .first()
      .subscribe(
        res => {
          this.parameterData = [res];
          this.chartType = this.getTypeTitle(parameterType) + ' Parameter';
          this.isChartOverlayVisible = false;
        },
        error => console.log(error),
      );
  }

  public hideModal(): void {
    this.parameterData = null;
    this.chartType = 'Loading...';
    this.isChartOverlayVisible = true;
    $(this.modal.nativeElement).modal('hide');
  }

  public OnMouseOut(e: any): void {}

  public mouseOver(e: any): void {
    if (e.srcElement) {
      const disabled = this.elementDisabled(e.srcElement);
      if (e.srcElement.getAttribute('id') === 'parameter') {
        const hideTimeout = disabled ? 50 : 0;
        const popupSettings = {
          inline: false,
          position: 'top center',
          html: null,
          content: null,
          hoverable: false,
          exclusive: true,
          delay: {
            show: 0,
            hide: hideTimeout,
          },
        };
        if (disabled) {
          popupSettings.html =
            'Parameter breakdowns are only available for High Priority logs<br>' +
            '<a target="_blank" href="https://coralogix.com/tutorials/optimize-log-management-costs/">Learn More</a>';
          popupSettings.hoverable = true;
        } else {
          popupSettings.content = '*Default value for this variable';
        }
        const element = $(e.srcElement);
        element.popup(popupSettings);
        element.popup('show');
      }
    }
  }

  public onCellClicked(e: any, params: any): void {
    if (this.elementDisabled(e.srcElement)) {
      return;
    }

    const paramId = e.target.attributes.getNamedItem('parameterId') ? e.target.attributes.getNamedItem('parameterId').value : null;
    const paramType = e.target.attributes.getNamedItem('parameterType') ? e.target.attributes.getNamedItem('parameterType').value : null;
    if (e.target.attributes.getNamedItem('parameterType')) {
      params.context.showModal(params.node.data.id, paramId, paramType);
    }
  }

  public pinToDashboard(widget: Widget): void {
    this.widgetService
      .addWidgets(widget)
      .first()
      .subscribe(w => console.log(w));
  }

  public getTypeTitle(paramType: string): string {
    switch (paramType) {
      case 'free':
        return 'Free';
      case 'numeric':
        return 'Numeric';
      case 'categorical':
        return 'Categorical';
      case 'json':
        return 'Json';
    }
    return '';
  }

  public queryByTemplate(entity: Log | LoggregationEntity): void {
    this.store.dispatch(new LogsQueryActions.QueryByTemplate(entity));
  }

  public addWidget(query: any): void {
    const config: MatDialogConfig = new MatDialogConfig();

    if (this.widgetUrl === 'loggregation/parameter') {
      const queryWithLoggreagtionPramater = _.cloneDeep(query);
      queryWithLoggreagtionPramater.queryParams = this.queryModel.queryParams;
      config.data = { query: queryWithLoggreagtionPramater, url: this.widgetUrl };
    } else {
      config.data = { query: query, url: this.widgetUrl };
    }
    config.panelClass = 'add-edit-widget-container';
    const dialogRef = this.dialog.open(WidgetAddComponent, config);
    dialogRef
      .afterClosed()
      .first()
      .subscribe(widget => {
        this.pinToDashboard(widget);
      });
  }
  private getProp(logExample: any, path: string[]): string {
    if (_.isUndefined(logExample)) {
      return '*VALUE';
    }
    if (path.length <= 0) {
      return logExample;
    }
    // If key contains '.'
    const value = logExample[path.join('.')];
    if (!_.isUndefined(value)) {
      return value;
    }
    const key = path[0];
    const subLog = logExample[key];
    if (Array.isArray(subLog)) {
      return this.getProp(subLog[0], path.slice(1, path.length));
    }
    return this.getProp(subLog, path.slice(1, path.length));
  }

  private setData(data: any[]): void {
    if (data) {
      this.onItemsCountChanged.emit(data.length);
    }
    if (data && this.isGridReady && this.gridOptions.api) {
      this.currentPage = 0;
      this.totalPages = Math.ceil(data.length / this.PAGE_SIZE);
      this.loggregationManager = new LoggregationManager(data);
      this.gridOptions.api.setDatasource(this.loggregationManager);
      this.sort(null);
      return;
    }
    if (data && data.length && data[0] instanceof AnomalyDetails) {
      this.columnDefs = LoggregationGridColumnDef.createAnomalyLoggregationColumnDefs();
    } else {
      this.columnDefs = LoggregationGridColumnDef.createLoggregationColumnDefs();
    }
    const options: GridOptions = {
      headerHeight: 40,
      enableColResize: true,
      rowSelection: 'single',
      context: this,
      enableSorting: true,
      enableFilter: true,
      rowModelType: 'pagination',
      paginationPageSize: this.PAGE_SIZE,
      maxPagesInCache: 2,
      columnDefs: this.columnDefs,
      getRowHeight: this.htmlRowHeight.bind(this),
    };
    options.onColumnResized = params => {
      if (!params.finished) {
        return;
      }
    };
    options.onCellFocused = params => {
      if (params && params.forceBrowserFocus) {
        this.selectRow(params.rowIndex);
      }
    };
    options.onGridReady = params => {
      if (this.isGridReady) {
        return;
      }
      // TODO: NG4 do check avoid
      setTimeout(() => {
        this.isGridReady = true;
        this.paginationControll = params.api.paginationController;
        this.setData(this.items);
      }, 0);
    };

    options.onItemsAdded = params => {
      this.onItemsCountChanged.emit(this.gridOptions.api.getModel().getRowCount());
    };
    options.onFilterChanged = params => {
      this.onItemsCountChanged.emit(this.gridOptions.api.getModel().getRowCount());
    };
    options.onAfterSortChanged = params => {
      this.sort(null);
    };
    options.onRowSelected = params => {
      if (params.node.selected) {
        const loggregation = new LoggregationEntity();
        loggregation.timestamp = params.node.data.timestamp;
        loggregation.text = params.node.data.text;
        loggregation.metadata = params.node.data.metadata;
        loggregation.id = params.node.data.id;
        loggregation.templateType = params.node.data.templateType;
        loggregation.logExample = params.node.data.logExample;
        loggregation.text = this.buildLoggregationParametersHTML(
          loggregation.text,
          null,
          loggregation.templateType === TemplateType.jsonTemplate,
          loggregation.logExample,
        );
        this.selectedItemChanged.emit(loggregation);
      }
    };
    options.getRowStyle = params => {
      const isHighlighted = this.highlightedTemplates && this.highlightedTemplates.find(id => id === params.data.id);
      if (params.data.isAnomalous || isHighlighted) {
        return {
          'background-color': '#ecf9fd',
        };
      }

      return null;
    };

    this.gridOptions = options;
  }

  private sort(model: any): void {
    const sortModel = model ? model : this.gridOptions.api.getSortModel();
    if (sortModel.length > 0) {
      const data = _.orderBy(this.loggregationManager.data, [sortModel[0]['colId']], [sortModel[0]['sort']]);
      this.loggregationManager.data = data;
      this.gridOptions.api.setDatasource(this.loggregationManager);
      this.currentPage = 0;
      if (this.pagingComponent && this.pagingComponent.paginationControll) {
        this.pagingComponent.pageClicked(0);
      }
    }
  }

  private htmlRowHeight(params: any): number {
    if (!params.api?.columnController?.gridColumns) {
      return 35;
    }

    const textColumn = params.api.columnController.gridColumns.find(col => col.colDef.field === 'text');
    const clickableParam =
      '<div class="ui lable loggregation loggregation-$3-style {CoralogixPHStatsEnabled}"' +
      ' parameterId="$1" parameterType="$3" id="parameter"> *$2</div>';

    const clickableJsonParam =
      '<div class="ui lable loggregation loggregation-$3-style {CoralogixPHStatsEnabled}"' +
      ' parameterId="$2" parameterType="$3" id="parameter"> *PARAM</div>';

    let text: string;
    if (params.data.text) {
      if (params.data && params.data.templateType === TemplateType.jsonTemplate) {
        let value = '';
        try {
          value = this.prettyJsonPipe.transform(JSON.parse(params.data.text));
        } catch (e) {
          value = params.data.text;
          // console.log(e);
        }
        text = this.buildLoggregationParametersHTML(value, clickableJsonParam, true, null, params.data.placeholdersEnabled);
        text = this.buildLoggregationParametersHTML(text, clickableParam, false, null, params.data.placeholdersEnabled);
        text = '<pre class="grid-json-theme">' + text + '</pre>';
      } else {
        text = this.buildLoggregationParametersHTML(params.data.text, clickableParam, false, null, params.data.placeholdersEnabled);
      }
    }

    const columnWidth = textColumn.actualWidth - 4;

    if (!this.elm) {
      this.elm = document.createElement('div');

      this.elm.style.cssText =
        'position:fixed;' +
        'width:' +
        columnWidth +
        'px;visibility: hidden;max-width:' +
        columnWidth +
        'px;min-width:' +
        columnWidth +
        'px;padding:0;margin:0;font-size:12px !important;' +
        'word-wrap: break-word;white-space: normal;' +
        'line-height: 1.4;font-family: Roboto, serif;';
      document.body.appendChild(this.elm);
    }
    this.elm.innerHTML = text;
    const height = document.defaultView.getComputedStyle(this.elm, null).height;

    const parsedHeight = parseInt(height, 10);

    return parsedHeight + 22;
  }

  private elementDisabled(e: HTMLElement): boolean {
    return e.classList.contains('disabled');
  }

  private selectRow(index: number): void {
    if (index && this.isGridReady) {
      const row = this.gridOptions.api.getModel().getRow(index);
      if (row) {
        row.setSelected(true, true);
      }
    }
  }
}
