import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getApplicationNames, getSubsystemNames, State } from '@app/app.reducers';
import moment, { Moment } from 'moment';
import { ArchiveQueriesService } from '@app/roi/archive-queries/services/archive-queries.service';
import { ArchiveQueryEditorForm } from '@app/roi/archive-queries/models/archive-query-editor-form';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Constants } from '@app/constants';

@Component({
  selector: 'sh-archive-query-editor-filters',
  templateUrl: './archive-query-editor-filters.component.html',
  styleUrls: ['./archive-query-editor-filters.component.scss']
})
export class ArchiveQueryEditorFiltersComponent implements OnInit {

  @Input()
  public form: FormGroup;
  @Input()
  public logsSeverityOptions: {id: number, name: string}[];
  @Input()
  public isTimeRangeValid: boolean;
  @Input()
  public isPrePopulated: boolean;
  @Input()
  public isEditable: boolean;
  @Input()
  public validationError: string;
  @Output()
  public bubbleFormErr: EventEmitter<boolean> = new EventEmitter<boolean>();

  public applicationOptions$: Observable<string[]>;
  public subsystemOptions$: Observable<string[]>;

  public fromTimePickerSeen: boolean = false;
  public toTimePickerSeen: boolean = false;
  public timePickerPlacholder: string = 'Choose a date and time';
  public fromTimePickerLabel: string = this.timePickerPlacholder;
  public toTimePickerLabel: string = this.timePickerPlacholder;
  public showErrMsg: boolean = false;
  public timepickerForm: FormGroup;
  public examplePrestoQuery: string = 'SELECT * FROM LOGS';
  public prestoQueryDescription: string = 'Available columns: timestamp, severity, text, applicationname, subsystemname';
  public querySyntaxTabInitialIndex: number = 0;
  public isPrestoQuery$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public prestoTooltipContent: string = 'Please provide a legal SQL statement, use LOGS as the table reference. You can use presto functions.';

  constructor(
    private store: Store<State>,
    private archiveQueriesService: ArchiveQueriesService, private archiveQueryEditorForm: ArchiveQueryEditorForm) {
    this.applicationOptions$ = store.select(getApplicationNames);
    this.subsystemOptions$ = store.select(getSubsystemNames);
  }

  public ngOnInit(): void {
    if (this.isPrePopulated) {
      this.prePopulateTime();
      this.timepickerForm = this.archiveQueryEditorForm.buildTimePickerForm(this.form);
      this.querySyntaxTabInitialIndex = this.form.get('querySyntax').value === Constants.PRESTO ? 1 : 0;
      this.isPrestoQuery$.next(this.form.get('querySyntax').value === Constants.PRESTO);
    } else {
      this.timepickerForm = this.archiveQueryEditorForm.buildTimePickerForm();
    }
  }

  public get isFormInvalid(): boolean {
    return this.form.invalid || !this.isTimeRangeValid;
  }

  public displayTimePicker(type: 'from' | 'to'): void {
    switch (type) {
      case 'from':
        this.fromTimePickerSeen = !this.fromTimePickerSeen;
        return;
      case 'to':
        this.toTimePickerSeen = !this.toTimePickerSeen;
        return;
    }
  }

  public acceptTime(type: 'from' | 'to'): void {
    this.displayTimePicker(type);
    this.updateMainForm(
      this.timepickerForm.get('from').value,
      this.timepickerForm.get('to').value,
      this.timepickerForm.get('startTime').value,
      this.timepickerForm.get('endTime').value
    );
    const { from, to, startTime, endTime } = this.getDateValues();
    const { newStartTime, newEndTime } = this.archiveQueriesService.setEndAndStartTime(from, to, startTime, endTime);
    const isRangeIsValid = this.checkRangeValidation(newStartTime, newEndTime);
    this.showErrMsg = !isRangeIsValid;
    const date = moment(this.form.get(type).value).format('DD/MM/YYYY');
    if (type === 'from') {
      const hour = this.form.get('startTime').value.substring(0, 5);
      this.fromTimePickerLabel = `${date}, ${hour}`;
    } else {
      const hour = this.form.get('endTime').value.substring(0, 5);
      this.toTimePickerLabel = `${date}, ${hour}`;
    }
    this.bubbleFormErr.emit(isRangeIsValid);
  }

  public syntaxTabClicked(event: MatTabChangeEvent): void {
    if (!this.form.controls['textSql'].value && event.tab.textLabel === Constants.SQL) {
      this.form.controls['textSql'].patchValue(this.examplePrestoQuery);
      this.zeroHoursOnTabSwitch();
    }
    this.form.controls['querySyntax'].patchValue(event.tab.textLabel === Constants.SQL ? Constants.PRESTO : Constants.LUCENE);
    this.isPrestoQuery$.next(this.form.get('querySyntax').value === Constants.PRESTO);
  }

  private zeroHoursOnTabSwitch(): void {
    const startHour = `${this.timepickerForm.get('startTime').value.substring(0, 2)}:00`;
    const endHour = `${this.timepickerForm.get('endTime').value.substring(0, 2)}:00`;
    this.timepickerForm.controls['startTime'].patchValue(startHour);
    this.timepickerForm.controls['endTime'].patchValue(endHour);
    this.form.controls['startTime'].patchValue(startHour);
    this.form.controls['endTime'].patchValue(endHour);
    if (this.fromTimePickerLabel !== this.timePickerPlacholder) {
      this.fromTimePickerLabel = ArchiveQueryEditorFiltersComponent.getTimepickerLabel(this.timepickerForm.get('from').value, startHour);
    }
    if (this.toTimePickerLabel !== this.timePickerPlacholder) {
      this.toTimePickerLabel = ArchiveQueryEditorFiltersComponent.getTimepickerLabel(this.timepickerForm.get('to').value, endHour);
    }
  }

  private static getTimepickerLabel(date: string, hour: string): string {
    const fromDate = moment(date).format('DD/MM/YYYY');
    return `${fromDate}, ${hour}`;
  }

  private prePopulateTime(): void {
    const { from, to } = this.getDateValues();
    const fromDate = moment(from).format('DD/MM/YYYY');
    const toDate = moment(to).format('DD/MM/YYYY');
    const startTime = moment(from).format('HH:mm');
    const endTime = moment(to).format('HH:mm');
    this.form.controls['startTime'].patchValue(startTime);
    this.form.controls['endTime'].patchValue(endTime);
    this.fromTimePickerLabel = `${fromDate}, ${moment(from).format('HH:mm')}`;
    this.toTimePickerLabel = `${toDate}, ${moment(to).format('HH:mm')}`;
  }

  private updateMainForm(from: Date, to: Date, startTime: string, endTime: string): void {
    this.form.controls['from'].patchValue(from);
    this.form.controls['to'].patchValue(to);
    this.form.controls['startTime'].patchValue(startTime);
    this.form.controls['endTime'].patchValue(endTime);
  }

  private getDateValues(): {from: Date, to: Date, startTime: string, endTime: string} {
    const from = this.form.get('from').value;
    const to = this.form.get('to').value;
    const startTime = this.form.get('startTime').value;
    const endTime = this.form.get('endTime').value;
    return { from, to, startTime, endTime };
  }

  private checkRangeValidation(newStartTime: Moment, newEndTime: Moment): boolean {
    const duration = moment.duration(newEndTime.diff(newStartTime));
    return (duration.asMinutes() < ((60 * 24) + 1) && duration.asSeconds() > 0); // between 0 seconds to 1 day and a minute
  }
}
