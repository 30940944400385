<div *ngIf="!isFromQuery">
  <form [formGroup]="form">
    <p class="mat-card-header" *ngIf="!isRelative">Query</p>
    <div *ngIf="isRelative" class="alias-container">
      <p class="mat-card-header">Query {{ queryIndex + 2 }}</p>
      <div class="coralogix input-group">
        <label class="input-label">Query Alias</label>
        <input type="text" placeholder="Enter an alias" class="form-control" formControlName="alias" />
      </div>
    </div>
    <p class="mat-card-subheader" *ngIf="!isMetric; else metricHeader">
      Target logs by application, subsystem(s) or the text contained within the logs.
    </p>
    <ng-template #metricHeader>
      <p class="mat-card-subheader">
        <span>
          This query will be performed on the
        </span>
        <span class="bold-metric">
          relevant metric documents
        </span>
        <span>
          (Logs2Metrics or Prometheus Metrics)
        </span>
      </p>
    </ng-template>
    <div class="error-container">
      <div class="error" *ngIf="isFormInvalid">At least one of the query fields must be filled</div>
      <div class="error" *ngIf="invalidErrMsg">{{ invalidErrMsg }}</div>
    </div>
    <div class="coralogix input-group">
      <sh-search-query
        data-test="alerts-query"
        formControlName="alertText"
        [hasTitleIcon]="!isMetric"
        [isPromQLQuery]="isPromQLSyntax"
        [placeholderText]="getPlaceholder()"
        [description]="getQueryDescription()"
        (changeInvalidQueryErr)="changeInvalidErr($event)"
      ></sh-search-query>
    </div>
    <div class="coralogix input-group shorten-string" *ngIf="form?.controls?.applicationName && !isMetric">
      <sh-conditional-select
        data-test="alerts-applications-select"
        label="Applications"
        [items]="applicationOptions"
        [allGroups]="$any(form.controls['applicationName'])"
        [btnLabel]="form.controls['applicationName'].value?.length ? 'ADD MORE APPS' : 'ADD APPS'"
      ></sh-conditional-select>
    </div>

    <div class="coralogix input-group shorten-string" *ngIf="form?.controls?.subsystemName && !isMetric">
      <sh-conditional-select
        data-test="alerts-subsystems-select"
        label="Subsystems"
        [items]="subsystemOptions"
        [allGroups]="$any(form.controls['subsystemName'])"
        [btnLabel]="form.controls['subsystemName'].value?.length ? 'ADD MORE SUBSYSTEMS' : 'ADD SUBSYSTEMS'"
      ></sh-conditional-select>
    </div>
    <div class="coralogix input-group" *ngIf="form?.controls?.logSeverity && !isMetric">
      <sh-coralogix-select
        data-test="alerts-query-severity-select"
        [selectOptions]="logsSeverityOptions"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [multiple]="true"
        [searchable]="true"
        [allSelectedLabel]="'All Severities'"
        [hasColoredIcon]="true"
        [placeholder]="'Select severities'"
        [formControl]="$any(form.controls['logSeverity'])"
      >
        <label select-label>
          <span class="text">Severities</span>
        </label>
      </sh-coralogix-select>
    </div>
  </form>
</div>
<div *ngIf="isFromQuery">
  <div class="header-section coralogix">
    <p class="mat-card-header">Query</p>
    <button class="edit-button button coralogix" (click)="onEdit()">Edit</button>
  </div>
  <div class="content-section">
    <div class="query-config">
      <div class="name">
        Search Query
      </div>
      <div class="value">
        <span>{{ query.alertText }}</span>
      </div>
    </div>
    <div class="query-config" *ngIf="validateMetadata('applicationName')">
      <div class="name">
        Application
      </div>
      <div class="value">
        <ng-container *ngTemplateOutlet="appOrSubPreview; context: { controlName: 'applicationName' }"></ng-container>
      </div>
    </div>
    <div class="query-config" *ngIf="validateMetadata('subsystemName')">
      <div class="name">
        Subsystem
      </div>
      <div class="value">
        <ng-container *ngTemplateOutlet="appOrSubPreview; context: { controlName: 'subsystemName' }"></ng-container>
      </div>
    </div>
    <div class="query-config" *ngIf="severity.length > 0">
      <div class="name">
        Severity
      </div>
      <div class="value">
        <div class="value-item" *ngFor="let severity of severity">
          <span class="bullet" [ngClass]="severity.toLowerCase()"></span>
          <span>{{ severity }}</span>
        </div>
      </div>
    </div>

    <ng-template #appOrSubPreview let-controlName="controlName">
      <ng-container *ngFor="let compareCategory of form.controls[controlName].value">
        <div class="value-item" *ngFor="let app of compareCategory.values">
          <span class="bullet"></span>
          <span>{{ app.value }}</span>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
