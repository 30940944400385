import { Injectable } from '@angular/core';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { IntegrationService } from '../../../../settings-common/shared/services/integration-service';
import {
  SnackBarStatusModel,
  StatusesEnum,
} from '../../../../settings-common/shared/models/snack-bar-status';
import { IntegrationsActions } from './integrations.actions';
import { of } from 'rxjs/observable/of';
import { TypedJSON } from 'typedjson';
import { ShDialogService } from '../../../../shared/services/dialogService';
import { SnackBarStatusComponent } from '../../../shared/controls/snack-bar-status/snack-bar-status.component';
import { filter, switchMap } from 'rxjs/operators';
import { IDigestIntegrations, SettingsService } from '../../services/settings-service';
import { AlertModel } from '@app/alerts/shared/models/alert-model';
import { AlertsService } from '@app/alerts/shared/services/alerts-service';
import * as alertActions from '@app/alerts/shared/actions/alerts.actions';
import { State } from '@app/app.reducers';
import { pipe } from 'rxjs';
import { IntegrationModel } from '@app/settings-common/shared/models/newIntegrationRequest';
import { CreateNUpdateAlertModel } from '@app/alerts/shared/models/create-n-update-alert-model';
import { AlertMapperService } from '@app/alerts/alerts-editor/services/alert-mapper.service';
import { AlertsEditorFormService } from '@app/alerts/alerts-editor/services/alerts-editor-form.service';

@Injectable()
export class IntegrationsEffects {
  @Effect()
  public getIntegrations$: Observable<Action> = this.actions$.pipe(
    ofType(IntegrationsActions.ActionTypes.GET_INTEGRATIONS),
    switchMap((action: Action) => {
      return this.integrationsService
        .getIntegrations()
        .map(
          (integrations) =>
            new IntegrationsActions.GetIntegrationsCompletedAction(
              integrations,
            ),
        )
        .catch((err) =>
          of(
            new IntegrationsActions.IntegrationFailureAction({
              type: action.type,
              error: 'Failed to get integrations',
            }),
          ),
        );
    }),
  );

  @Effect()
  public getIntegrationTypes$: Observable<Action> = this.actions$.pipe(
    ofType(IntegrationsActions.ActionTypes.GET_INTEGRATION_TYPES),
    switchMap((action: Action) => {
      return this.integrationsService
        .getIntegrationTypes()
        .map(
          (integrationTypes) =>
            new IntegrationsActions.GetIntegrationTypesCompletedAction(
              integrationTypes,
            ),
        )
        .catch((err) =>
          of(
            new IntegrationsActions.IntegrationFailureAction({
              type: action.type,
              error: 'Failed to get integration types',
            }),
          ),
        );
    }),
  );

  @Effect()
  public saveIntegration$: Observable<Action> = this.actions$.pipe(
    ofType(IntegrationsActions.ActionTypes.SAVE_INTEGRATION),
    switchMap((action: any) => {
      return this.integrationsService
        .saveIntegration(action.payload.integrationModel)
        .map((integration) => {
          return new IntegrationsActions.SaveIntegrationCompletedAction({integration, slackDigests: action.payload.slackDigests});
          })
        .do(() => {this.dialogService.showSnackBar(
          `Webhook saved successfully`,
          null,
          3000,
          );
        })
        .catch((err) =>
          of(
            new IntegrationsActions.IntegrationFailureAction({
              type: action.type,
              error: 'Failed to save webhook',
            }),
          ),
        );
    }),
  );

  @Effect()
  public deleteIntegration$: Observable<Action> = this.actions$.pipe(
    ofType(IntegrationsActions.ActionTypes.DELETE_INTEGRATION),
    switchMap((action: any) => {
      return this.integrationsService
        .deleteIntegration(action.payload.id)
        .map(
          () =>
            new IntegrationsActions.DeleteIntegrationCompletedAction(
              action.payload.id,
            ),
        )
        .do(() =>
          this.dialogService.showSnackBar(
            `Webhook deleted successfully`,
            null,
            3000,
          ),
        )
        .catch((err) =>
          of(
            new IntegrationsActions.IntegrationFailureAction({
              type: action.type,
              error: 'Failed to delete webhook',
            }),
          ),
        );
    }),
  );

  @Effect()
  public integrationsFailure$: Observable<Action> = this.actions$
    .pipe(ofType(IntegrationsActions.ActionTypes.INTEGRATIONS_FAILURE))
    .do((action: any) =>
      this.dialogService.showSnackBar(
        `ERROR: ${action.payload.error}`,
        null,
        3000,
      ),
    )
    .map(() => {
      return new IntegrationsActions.IntegrationFailureCompletedAction(null);
    });

  @Effect()
  public testIntegrationConfig$: Observable<Action> = this.actions$.pipe(
    ofType(IntegrationsActions.ActionTypes.INTEGRATIONS_CONFIG_TEST),
    switchMap((action: any) => {
      return this.integrationsService
        .testIntegrationConfig(action.payload)
        .map((integration) => {
          const snackBarStatus = new TypedJSON(SnackBarStatusModel).parse(
            JSON.stringify({
              status: integration.success
                ? StatusesEnum.SUCCESS
                : StatusesEnum.ERROR,
              message: integration.success
                ? 'Succeeded!'
                : integration.statusCode >= 400 && integration.statusCode < 500
                ? 'Please check your configuration'
                : 'Remote server error',
            }),
          );

          this.dialogService.showSnackBar(
            snackBarStatus.message,
            null,
            3000,
          );
          return new IntegrationsActions.TestIntegrationConfigurationCompletedAction(
            integration,
          );
        })
        .catch((err) =>
          of(
            new IntegrationsActions.TestIntegrationConfigurationFailedAction(
              err,
            ),
          ),
        );
    }),
  );

  @Effect()
  public getDigestIntegrations$: Observable<Action> = this.actions$.pipe(
    ofType(IntegrationsActions.ActionTypes.GET_DIGEST_INTEGRATIONS),
    switchMap((action: Action) => {
      return this.settingService
        .getDigestIntegrations()
        .map(digestIntegrations => new IntegrationsActions.GetDigestIntegrationsCompletedAction(digestIntegrations))
        .catch(err =>
          of(
            new IntegrationsActions.IntegrationFailureAction({
              type: action.type,
              error: 'Failed to get integrations',
            }),
          ),
        );
    }),
  );

  @Effect()
  public saveDigestIntegrations$: Observable<Action> = this.actions$.pipe(
    ofType(IntegrationsActions.ActionTypes.SAVE_DIGEST_INTEGRATION),
    switchMap((action: any) => {
      return this.settingService
        .saveDigestIntegrations(action.payload)
        .map(() => new IntegrationsActions.SaveDigestIntegrationCompletedAction(action.payload))
        .catch(err =>
          of(
            new IntegrationsActions.IntegrationFailureAction({
              type: action.type,
              error: 'Failed to get integrations',
            }),
          ),
        );
    }),
  );

  onSaveIntegrationAction$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(IntegrationsActions.ActionTypes.SAVE_INTEGRATION_COMPLETED),
    switchMap((res: {payload: {integration: IntegrationModel, slackDigests: Partial<IDigestIntegrations>[]}}) => {
      const dispatchArr: Action[] = [];
      const { payload: {integration, slackDigests} } = res;
      if (slackDigests?.length) {
        slackDigests.forEach(digest => digest.integrationId = integration.id);
        dispatchArr.push(
          new IntegrationsActions.SaveDigestIntegrationAction(slackDigests as IDigestIntegrations[]),
        );
      }
      return dispatchArr;
    }),
  ),
);

  constructor(
    private actions$: Actions,
    private integrationsService: IntegrationService,
    private dialogService: ShDialogService,
    private settingService: SettingsService,
  ) {}
}
