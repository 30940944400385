import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SavedQueryService } from '../logs/shared/services/saved-query.service';
import { HttpClientModule } from '@angular/common/http';
import { StatisticsModule } from '../statistics/statistics.module';
import { SearchProvider } from '@shared/controls/search/search.provider';
import { LoggregationService } from './shared/services/loggregation.service';
import { LoggregationGridComponent } from './loggregation-grid/grid/loggregation-grid.component';
import { LoggregationTextGridCellComponent } from './loggregation-grid/loggregation-text-grid-cell/loggregation-text-grid-cell.component';
import { LoggregationChartComponent } from './loggregation-charts/loggregation-free-chart/loggregation-chart.component';
import { LoggregationOccurrencesGridCellComponent } from './loggregation-grid/loggregation-occurrences-grid-cell/loggregation-occurrences-grid-cell.component';
import { TemplatesChartsComponent } from './loggregation-charts/templates-charts/templates-charts.component';
import { CommonModule } from '@angular/common';
import { CommonErrorsWidgetComponent } from './components/common-errors-widget/common-errors-widget.component';
import { LoggregationItemComponent } from './components/loggregation-item/loggregation-item.component';
import { SuspectedTemplateComponent } from './components/suspected-template/suspected-template.component';
import { LoggregationParamsService } from './loggregation-grid/grid/services/loggregation-params.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    StatisticsModule,
  ],
  declarations: [
    LoggregationGridComponent,
    LoggregationTextGridCellComponent,
    LoggregationChartComponent,
    LoggregationOccurrencesGridCellComponent,
    TemplatesChartsComponent,
    CommonErrorsWidgetComponent,
    LoggregationItemComponent,
    SuspectedTemplateComponent,
  ],
  exports: [
    LoggregationGridComponent,
    TemplatesChartsComponent,
    CommonErrorsWidgetComponent,
    LoggregationItemComponent,
    SuspectedTemplateComponent,
  ],
  providers: [
    SavedQueryService,
    SearchProvider,
    LoggregationService,
    LoggregationParamsService,
  ],
})
export class LoggregationModule {}
