import { Component, ViewChild } from '@angular/core';
import { AlertsEditorContainerComponent } from '@app/alerts/alerts-editor/containers/alerts-editor-container.component';
import { AlertsEditorMode } from '@app/alerts/alerts-editor/models/alerts-editor-mode.enum';
import { AlertType } from '@app/insights/shared/models/enums';
import { SaveAlertFromQueryConfig } from '@app/logs/logs-save-menu/models/logs-save-menu-config.model';
import { AlertEditFromQueryModel } from '@app/alerts/alerts-editor/models/alert-edit-from-query.model';

@Component({
  selector: 'sh-logs-container',
  templateUrl: './logs-container.component.html',
  styleUrls: ['./logs-container.component.scss'],
})
export class LogsContainerComponent {
  @ViewChild('alertEditor', { static: true })
  public alertEditor: AlertsEditorContainerComponent;

  public onSaveAlert(config: SaveAlertFromQueryConfig): void {
    console.log(config);
    const metadataClone = { ...config.metadata };
    delete metadataClone.applicationName;
    delete metadataClone.subsystemName;
    delete metadataClone.severity;

    for (const key in metadataClone) {
      if (metadataClone.hasOwnProperty(key)) {
        Object.defineProperty(
          metadataClone,
          `coralogix.metadata.${key}`,
          Object.getOwnPropertyDescriptor(metadataClone, key),
        );
        delete metadataClone[key];
      }
    }

    const params = {
      ...config.jsonObject,
      ...metadataClone,
    };

    const alert = new AlertEditFromQueryModel({
      text: config.text,
      queryParamsJson: params,
      hasConditions: true,
      userAlertTypeId: AlertType.ByText,
      applicationName:
        config.metadata && config.metadata.applicationName && config.metadata.applicationName.length > 0
          ? config.metadata.applicationName
          : null,
      subsystemName:
        config.metadata && config.metadata.subsystemName && config.metadata.subsystemName.length > 0
          ? config.metadata.subsystemName
          : null,
      logSeverity:
        config.metadata && config.metadata.severity && config.metadata.severity.length > 0
          ? config.metadata.severity
          : null,
    });

    this.alertEditor.openAlertsEditor(AlertsEditorMode.fromQuery, alert);
  }
}
