import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[ellipsisDetector]',
})
export class EllipsisDetectorDirective {
  @Output()
  public isEllipsis: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    const element = this.elementRef.nativeElement;

    if (element.offsetWidth < element.scrollWidth) {
      this.isEllipsis.emit(true);
    } else {
      this.isEllipsis.emit(false);
    }
  }
}
