import { type } from '../../../../shared/services/utils';
import { Action } from '@ngrx/store';

export namespace ExportActions {
  export const ActionTypes = {
    EXPORT_LOGS: type('[Export] export logs'),
    UPDATE_LOG_PAGES: type('[Export] update the current log pages'),
    UPDATE_DATE_RANGE: type('[Export] update the current logs min/max date'),
  };

  export class ExportLogs implements Action {
    public type = ActionTypes.EXPORT_LOGS;

    constructor(public payload: any) {}
  }

  export class UpdateLogPages implements Action {
    public type = ActionTypes.UPDATE_LOG_PAGES;

    constructor(public payload: Number[]) {}
  }

  export class UpdateDateRange implements Action {
    public type = ActionTypes.UPDATE_DATE_RANGE;

    constructor(public payload: Map<string, Date>) {}
  }

  export type Actions = ExportLogs | UpdateLogPages | UpdateDateRange;
}
