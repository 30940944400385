import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyStateContainerComponent } from './empty-state-container.component';
import { MatButtonModule } from '@angular/material/button';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [EmptyStateContainerComponent],
  imports: [CommonModule, MatButtonModule, AngularSvgIconModule, FlexLayoutModule],
  exports: [EmptyStateContainerComponent],
})
export class EmptyStateContainerModule {}
