import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Metadata } from '../shared/models/query-metadata';
import { MetadataService } from '../shared/services/metadata.service';
import * as _ from 'lodash';
import { zip } from 'rxjs/observable/zip';

@Injectable()
export class MetadataProvider {
  public options: {
    categoryMetadata$?: Observable<string[]>;
    classMetadata$?: Observable<{ id: string; name: string }[]>;
    methodMetadata$?: Observable<{ id: string; name: string }[]>;
    applicationMetadata$?: Observable<string[]>;
    subsystemMetadata$?: Observable<string[]>;
    computerNameMetadata$?: Observable<{ id: string; name: string }[]>;
    ipAddressMetadata$?: Observable<{ id: string; name: string }[]>;
  } = {};

  constructor(private metadataService: MetadataService) {}

  /////// get metadata function ///////
  // will be called for every change(onHide,onClear)
  public getAllRelatedMetadata(filter: Metadata) {
    return zip(
      this.setMetadataObservable('categoryMetadata$', 'category', filter),
      this.setMetadataObservable('classMetadata$', 'className', filter),
      this.setMetadataObservable('methodMetadata$', 'methodName', filter),
      this.setMetadataObservable('applicationMetadata$', 'applicationName', filter),
      this.setMetadataObservable('subsystemMetadata$', 'subsystemName', filter),
    );
  }

  public updateAllMetadata(filter: Metadata): Observable<Metadata> {
    return zip(this.getAllRelatedMetadata(filter), (relatedRes, independentRes) => [...relatedRes])
      .map((resArr: Array<any>) => ({
        category: resArr[0],
        className: resArr[1],
        methodName: resArr[2],
        applicationName: resArr[3],
        subsystemName: resArr[4],
        computerName: resArr[5],
        IPAddress: resArr[6],
        severity: filter.severity,
        threadId: filter.threadId,
      }))
      .map((data) => new Metadata(data));
  }

  public getMetaData(field: string, filter: Metadata): Observable<string[]> {
    return this.metadataService.getMetaData(field, filter).catch((err) => {
      console.log(err);
      return Observable.from([[]]);
    });
  }

  private setMetadataObservable(optionName, fieldName: string, filter: Metadata) {
    if (filter) {
      const filterMetadata = _.cloneDeep<Metadata>(filter);
      filterMetadata[fieldName] = [];
      filter = filterMetadata;
    }
    this.options[optionName] = this.getMetaData(fieldName, filter);
    return this.options[optionName];
  }
}
