<div class="preview-grid-container">
  <div class="title"></div>
  <div class="logs-container">
    <div *ngIf="isLoading$ | async" class="loader-container">
      <div class="ui active inline loader"></div>
    </div>
    <div *ngIf="!(hasLogsForPreview$ | async) && !(isLoading$ | async)">
      No logs to preview
    </div>
    <div class="grid-container" *ngIf="!(isLoading$ | async) && (hasLogsForPreview$ | async)">
      <sh-logs-new-grid
        [showJsonFormatterMenu]="false"
        [colsDefs$]="columnDefs$"
        [rowsData$]="logs$"
        [withInfoPanel]="false"
      ></sh-logs-new-grid>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="paginator-container">
      <mat-paginator class="paginator" [length]="(logs$ | async).length" [pageSize]="1000"></mat-paginator>
    </div>
    <div>
      <button class="coralogix button dialog-cancel-button" (click)="onNoClick()">{{data.noButtonText}}</button>
      <button class="coralogix button dialog-delete-button" (click)="getTsvs()">Download TSV</button>
    </div>
  </div>
</div>
