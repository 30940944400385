import { Component, EventEmitter, forwardRef, Input, NgZone, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ShDialogService } from '@shared/services/dialogService';
import { RegexCheatSheetComponent } from '@shared/tutorials/regex-cheat-sheet/regex-cheat-sheet.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { PrestoCheatSheetComponent } from '@shared/tutorials/presto-cheat-sheet/presto-cheat-sheet.component';

@Component({
  selector: 'sh-search-query',
  templateUrl: './search-query.component.html',
  styleUrls: ['./search-query.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchQueryComponent),
      multi: true,
    },
  ],
})
export class SearchQueryComponent implements OnInit, ControlValueAccessor {
  @Output() public changeInvalidQueryErr: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public queryTitle: string = 'Search query';
  @Input() public hasTitleIcon: boolean = true;
  @Input() public showRegexLegend: boolean = true;
  @Input() public placeholderText: string = 'Enter a text string, use the Elasticsearch Query Syntax, or leave blank to match any text';
  @Input() public placeholderAsInitialValue: boolean = false;
  @Input() public description: string = null;
  @Input() public tooltipContent: string = null;
  @Input() public showPrestoGuide: boolean = false;
  @Input() public isEditable: boolean = true;
  @Input() public isPromQLQuery = false;

  public placeholder: string;
  public _value: any;
  public useStandardTooltip: boolean;
  private _onChangeFn: (val: any) => any;
  private _onTouchedFn: () => any;

  constructor(private _ngZone: NgZone, private shDialogService: ShDialogService) {}

  public ngOnInit(): void {
    this.useStandardTooltip = this.tooltipContent === null;
    this.placeholder = this.placeholderText;
    if (this.placeholderAsInitialValue) {
      this.value = this.placeholderText;
    }
  }

  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
    if (this._onChangeFn) this._onChangeFn(value);
  }

  public registerOnChange(fn: (val: any) => any): void {
    this._onChangeFn = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this._onTouchedFn = fn;
  }

  public onFocus(): void {
    this.placeholder = '';
    if (this.placeholderAsInitialValue && (this.value == null || this.value === '')) {
      this.value = this.placeholderText;
    }
  }

  public onBlur(): void {
    this.placeholder = this.placeholderText;
    this._onTouchedFn();
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public openCheatSheet(): void {
    const config: MatDialogConfig = {
      width: '96%',
      maxWidth: '96%',
    };
    this.shDialogService.openCustomDialog<RegexCheatSheetComponent>(RegexCheatSheetComponent, config);
  }

  public openPrestoCheatSheet(): void {
    this.shDialogService.openCustomDialog<PrestoCheatSheetComponent>(PrestoCheatSheetComponent);
  }

  public openPrestoGuide(): void {
    window.open('https://prestodb.io/docs/0.217/functions.html', 'popup', 'width=600,height=600');
  }
}
