import { Injectable } from '@angular/core';
import { MappingStatsService } from '@app/settings/shared/services/mapping-stats.service';
import { ShDialogService } from '@app/shared/services/dialogService';
import { MappingStats } from '@app/user/shared/models/mapping-stats.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { GetMappingStats } from './mapping-stats.action';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { dialogServiceIconClasses } from '@app/shared/models/dialog-service.models';

export interface IMappingStatsModel {
    mappingStats: MappingStats;
    lastQueried: Date;
    loading: boolean;
}

const initialState = (): IMappingStatsModel => {
    return {
        mappingStats: null,
        lastQueried: null,
        loading: false
    };
};

@State<IMappingStatsModel>({
    name: 'mappingStats',
    defaults: initialState()
})
@Injectable()
export class MappingStatsState {
    private readonly TIME_TO_CACHE_LAST_RESULT_MINUTES: number = 15;

    constructor(
        private dialogService: ShDialogService,
        private mappingStatsService: MappingStatsService
    ) {
    }

    @Selector()
    public static mappingStats(state: IMappingStatsModel): MappingStats {
        return state.mappingStats;
    }

    @Selector()
    public static loading(state: IMappingStatsModel): boolean {
        return state.loading;
    }
    // Optimization for not quering many times, only each 15b minutes
    public shouldQueryServerAgain(lastQueried: Date): boolean {
        return differenceInMinutes(Date.now(), lastQueried) > this.TIME_TO_CACHE_LAST_RESULT_MINUTES;
    }

    @Action(GetMappingStats)
    public GetMappingStats(ctx: StateContext<IMappingStatsModel>): Observable<IMappingStatsModel> {
        const { lastQueried } = ctx.getState();
        if (!lastQueried || this.shouldQueryServerAgain(lastQueried)) {
            ctx.patchState({ loading: true });
            return this.mappingStatsService.getMappingStats().pipe(
                map((newMappingStats) => {
                    const newLastQueried = new Date();
                    return ctx.patchState({ mappingStats: newMappingStats, lastQueried: newLastQueried });
                }),
                catchError((err) => {
                    this.dialogService.showCoralogixMessage('Failed to retrieve Mapping Statistics', null, dialogServiceIconClasses.failed);
                    return of(null);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
        }
        return of(null);
    }
}
