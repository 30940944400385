import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { TeamService } from '../../shared/srvices/teams.service';
import { TeamActions } from './teams.actions';
import { AuthService } from '../../../auth/shared/services/auth.service';
import { ShDialogService } from '../../../shared/services/dialogService';
import * as RouterActions from './team-navigation.actions';
import { Constants } from '@app/constants';
import { Router } from '@angular/router';
import { State } from '@app/app.reducers';

@Injectable()
export class TeamsEffects {
  @Effect()
  public loginToTeam$: Observable<Action> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.LOGIN_TO_TEAM),
    switchMap((action) => {
      const autoLoginTeamName = this.teamService.getTeamNameFromUrl();
      // we have a team name
      if (autoLoginTeamName) {
        return of(new TeamActions.LoginWithTeamName(autoLoginTeamName));
      } else {
        // we don't have team name - going to regular login
        return of(new TeamActions.LoginWithTeamNameFailed(null));
      }
    }),
  );

  @Effect()
  public loginWithTeamName$: Observable<Action> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.LOGIN_WITH_TEAM_NAME),
    switchMap((action: any) => {
      return this.teamService
        .getTeams()
        .map(
          (teams) =>
            new TeamActions.LoginWithTeamNameGetTeamsCompleted({
              teamName: action.payload,
              teams,
            }),
        )
        .catch((err) => {
          return of(new TeamActions.LoginWithTeamNameFailed(err));
        });
    }),
  );

  @Effect()
  public loginWithTeamNameGetTeamsCompleted$: Observable<
    Action
  > = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.LOGIN_WITH_TEAM_NAME_GET_TEAMS_COMPLETED),
    switchMap((action: any) => {
      if (action.payload.teamName && action.payload.teams) {
        const teamIndex = action.payload.teams
          .map((t) => t.team_url.toLowerCase())
          .indexOf(action.payload.teamName.toLowerCase());
        if (teamIndex !== -1) {
          return of(
            new TeamActions.SelectTeam({
              teamId: action.payload.teams[teamIndex].id,
              isNew: false,
            }),
          );
        } else {
          // If there is an informative error we might use it in the message
          this.dialogService.showSnackBar(
            'Could not find team name, please select team from list.',
            null,
            5000,
          );
        }
      }
      return of(new TeamActions.LoginWithTeamNameFailed(null));
    }),
  );

  @Effect()
  public loginWithTeamNameFailed$: Observable<Action> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.LOGIN_WITH_TEAM_NAME_FAILED),
    map((err) => {
      return new RouterActions.Go({ path: ['/login/teams'] });
    }),
  );

  @Effect()
  public createTeam$: Observable<Action> = this.actions$.pipe(
    ofType(
      TeamActions.ActionTypes.CREATE_TEAM,
      TeamActions.ActionTypes.CREATE_FIRST_TEAM,
    ),
    switchMap((action: any) => {

      return this.teamService
        .createTeam(
          action.payload,
          action.type === TeamActions.ActionTypes.CREATE_FIRST_TEAM,
        )
        .map((team) => new TeamActions.CreateTeamCompleted(team))
        .catch((err) => {
          console.log('caught exception when creating team err: ' + err);
          let message =
            err.status === 409
              ? 'Team already exist. Please choose a different name'
              : 'Unable to create team';
          message =
            err.status === 406
              ? 'Team name is not valid, name must contain letters, numbers and dashes only'
              : message;
          this.dialogService.showSnackBar(message, null, 8000);
          return of(new TeamActions.CreateTeamFailed(err));
        });
    }),
  );

  @Effect()
  public changeTeamURL$: Observable<Action> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.CHANGE_TEAM_URL),
    switchMap((action: any) => {
      return this.teamService
        .changeURL(action.payload.team, action.payload.newURL)
        .map(
          (team) =>
            new TeamActions.ChangeTeamURLCompleted({
              team: action.payload.team,
              newURL: action.payload.newURL,
            }),
        )
        .catch((err) => {
          const message =
            err.status === 409
              ? 'Team name already exist. Please choose a different name'
              : 'Unable to rename team';
          this.dialogService.showSnackBar(message, null, 8000);
          return of(new TeamActions.ChangeTeamURLFailed(err));
        });
    }),
  );

  @Effect()
  public changeTeamURLCompleted$: Observable<Action> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.CHANGE_TEAM_URL_COMPLETED),
    switchMap((action: any) => {
      this.authService.selectTeam(action.payload.team);
      return of(
        new TeamActions.SelectTeam({
          teamId: action.payload.team.id,
          isNew: false,
        }),
      );
    }),
  );

  @Effect()
  public createTeamCompleted$: Observable<Action> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.CREATE_TEAM_COMPLETED),
    switchMap((action: any) => {
      this.authService.selectTeam(action.payload);
      return of(
        new TeamActions.SelectTeam({ teamId: action.payload.id, isNew: true }),
      );
    }),
  );

  @Effect()
  public getTeams$: Observable<Action> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.GET_TEAMS),
    switchMap((action) => {
      return this.teamService
        .getTeams()
        .map((teams) => {
          return new TeamActions.GetTeamsCompleted(teams);
        })
        .catch((err) => of(new TeamActions.GetTeamsFailed(err)));
    }),
  );

  @Effect()
  public getSelectdTeamId$: Observable<Action> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.GET_SELECTED_TEAM),
    switchMap((a) => {
      const slectedTeamId = this.authService.getSelectedTeamId();
      return of(new TeamActions.GetSelectedTeamCompleted(slectedTeamId));
    }),
  );

  @Effect({ dispatch: false })
  public teamSelected$: Observable<any> = this.actions$.pipe(
    ofType(TeamActions.ActionTypes.SELECT_TEAM),
    switchMap((action: any) => {
      this.store.dispatch(new TeamActions.SelectTeamCompleted(action.payload));
      return this.authService.getTempTokenNRedirectData(action.payload.teamId, action.payload.isNew).pipe(
        map(({ teamId, token, isNewTeamRoute, userName, teamUrl }) => {
           setTimeout(() => {
               const url = `${Constants.getTeamRedirectUrl.replace('%s', teamUrl)}${token}/${teamId}/${userName}/${isNewTeamRoute}`;
               window.location.href = url;
           }, 100);
          this.router.navigate(['/dashboard']).then();
        })
      ).catch(() => {
        return this.router.navigate(['/login/user']).then(() => {
          this.dialogService.showSnackBar('Failed to redirect to team', null, 3000);
        });
      });
    }),
  );

  constructor(
    private actions$: Actions,
    private teamService: TeamService,
    private dialogService: ShDialogService,
    private authService: AuthService,
    private router: Router,
    private store: Store<State>,
  ) {}
}
