<div
  class="timeline-block"
  (click)="insightClicked()"
  [class.selected]="selected"
  (mouseover)="mouseHover()"
  (mouseleave)="mouseLeave($event)"
>
  <div
    [class.squareMarker]="
      [insightTypeId.Alert, insightTypeId.AlertGroupByFields, insightTypeId.AlertRelative, insightTypeId.MetricAlert].includes(
        insight.typeId
      )
    "
    [class.marker]="
      ![insightTypeId.Alert, insightTypeId.AlertGroupByFields, insightTypeId.AlertRelative, insightTypeId.MetricAlert].includes(
        insight.typeId
      )
    "
  ></div>
  <div class="content" [class.muted]="!insight.isActive">
    <div class="header">
      <div>
        <div class="title">
          <span *ngIf="!resolvedTitle.length" class="insight-name">
            {{ this.insightsService.getInsightName(insight) }}
          </span>
          <p *ngIf="resolvedTitle.length" class="insight-name">
            <span class="resolved-title">RESOLVED</span>
            {{ resolvedTitle }}
          </p>
          <span *ngIf="!insight.isActive" class="span-title">(Muted)</span>
          <span
            #editTooltip="matTooltip"
            (mouseleave)="editTooltip.hide(0)"
            class="tuning-btn"
            matTooltip="Edit anomaly"
            matTooltipClass="coralogix-tooltip"
            position="above"
            cgxTooltip="Edit anomaly"
            *ngIf="
              (isReadOnly$ | async) === false &&
              insight.typeId == insightTypeId.Anomaly &&
              insight.subTypeId !== anomalySubTypeId.VolumeAnomaly
            "
            (click)="anomalyTuningClicked($event)"
          >
            <i class="write icon inverted"></i>
          </span>
          <span *ngIf="isSnoozed(snoozeData$ | async)" class="snooze-state"></span>
        </div>
        <div class="sub-title" [ngSwitch]="insight.typeId">
          <span class="text-cgx-{{insightSeverity[insight.severity].toLowerCase()}}" style="font-weight: 400">
            {{ insightSeverity[insight.severity] }}
          </span>
          <span *ngSwitchCase="insightTypeId.AlertGroupByFields">
            {{ ' ' + metricAlertSubtitle(insight.subTypeId) + 'alert grouped by field,' }}
          </span>
          <span *ngSwitchCase="insightTypeId.AlertRelative">
            ratio alert,
          </span>
          <span *ngSwitchCase="insightTypeId.MetricAlert">
            metric alert,
          </span>
          <span *ngSwitchDefault>
            {{ ' ' + insightTypeId[insight.typeId].toLowerCase() + ', ' }}
          </span>
          <span
            #timeTooltip="matTooltip"
            matTooltipClass="coralogix-tooltip"
            (mouseleave)="timeTooltip.hide(0)"
            [matTooltip]="insight.timestamp | dateFormatByUserSettings: null:hoursFormat.standard"
            matTooltipPosition="above"
          >
            {{ insight.timestamp | fromDate }}
          </span>
        </div>
      </div>
      <div
        *ngIf="isRoot"
        class="root-menu sub-title basic-btn button-color"
        (click)="occurrencesButtonClicked()"
        #occurrencesTooltip="matTooltip"
        (mouseleave)="occurrencesTooltip.hide(0)"
        matTooltipClass="coralogix-tooltip"
        [matTooltip]="occurrences + ' More insights of this type ' + groupBy + '. From ' + uniqueCompNum + ' unique computer(s)'"
        matTooltipPosition="above"
      >
        <div class="occurences-wrapper">
          {{ occurrences }}
          <i class="angle right icon"></i>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="insight-snooze-row">
      <div class="insight-properties">
        {{ getInsightPropertiesText() }}
      </div>
      <div *ngIf="showSnooze">
        <sh-snooze-button class="snooze-button" [alert]="insight"></sh-snooze-button>
      </div>
    </div>
  </div>
</div>
