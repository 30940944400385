import { Component } from '@angular/core';
import { Constants } from '@app/constants';
import { Router } from '@angular/router';
import { SamlService } from '@app/settings-common/shared/services/saml.service';
import { ShDialogService } from '@shared/services/dialogService';
import { first } from 'rxjs/operators';
@Component({
  selector: 'sh-saml-base',
  template: '',
})
export abstract class SamlBaseContainerComponent {
  isLoading: boolean;
  samlInfo: any;
  team_url: string;
  enableLoginWithSso: boolean;
  protected constructor(protected samlService: SamlService, protected dialogService: ShDialogService, protected router: Router) {}

  protected isHostContainsTeamName(hostname: string[]): boolean {
    return hostname.length > 1 && hostname[0] !== 'dashboard' && window.location.host !== Constants.getDefaultHost;
  }

  protected checkSaml(teamUrl?: string): void {
    this.samlService
      .getCompanySamlInfo(teamUrl)
      .pipe(first())
      .subscribe(
        res => {
          this.samlInfo = res;
          this.isLoading = false;
          if (!this.samlInfo.has_saml) {
            const startOfQueryParams = this.router.url.indexOf('?');
            const queryParams = startOfQueryParams !== -1 ? this.router.url.substring(startOfQueryParams) : '';
            window.location.href = Constants.getUserLoginRedirectUrl.replace('%s', teamUrl) + queryParams;
          } else {
            this.enableLoginWithSso = true;
          }
        },
        err => {
          this.isLoading = false;

          let errMsg = 'Unexpected error occured';
          if (err && err.status === 404) {
            errMsg = "We couldn't find your team in our system";
          }

          this.dialogService.showSnackBar(`Error: ${errMsg}`, null, 5000);
        },
      );
  }
}
