<div
  class="overlay"
  *ngIf="showSideBar"
  [@fadeAnimation]
  (click)="backDropClick()"
>
</div>
<div
  class="coralogix-sidebar"
  *ngIf="showSideBar"
  [@slideAnimation]
>
  <div (click)="close.emit()">
    <button
            class="close-button"
    >
      <span>×</span>
    </button>
  </div>
  <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
</div>
