import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'sh-alert-view-layout',
  templateUrl: './alert-view-layout.component.html',
  styleUrls: ['./alert-view-layout.component.scss']
})
export class AlertViewLayoutComponent {
  @Input() public alertType: string = '';

  @Input() public title: string = '';

  @Input() public subTitle: string = '';

  @Input() public tags: string[] = [];

  @Input() public alertId: string = '';

  public url: string;

  public host: string = window.location.host;
  constructor(private router: Router) {
    this.url = router.url;
  }

  public editAlert(): void {
    this.router.navigate([`/alerts/${this.alertId}`]);
  }

}
