import { Injectable } from '@angular/core';
import { AlertHelper } from '@app/alerts/shared/services/alert-helper';
import { LogsSeverityOptionsFactoryService } from '@app/logs/shared/factories/logs-severity-options-factory.service';
import { AlertsEditorSelectOptions } from '@app/alerts/alerts-editor/models/alerts-select-options.model';
import { MetadataProvider } from '@app/logs/logs-metadata-query/metadata.provider';
import { getIntegrations, getMetrics, getNonAllLogsFilterGridColumns, State } from '../../../app.reducers';
import { Observable } from 'rxjs';
import { LogsFilterGridColumnsActions } from '@app/logs/shared/state/filters/logs-filter-grid-columns/logs-filter-grid-columns.actions';
import { Store } from '@ngrx/store';
import { IntegrationsActions } from '@app/settings/shared/state/integrations/integrations.actions';
import { Metadata } from '@app/logs/shared/models/query-metadata';
import { filter, take } from 'rxjs/operators';
import { MetricsActions } from '@app/settings/metrices-settings/state/actions/metrics.actions';
import { Metric } from '@app/settings/metrices-settings/models/metric.model';
import { UserAlertViewTypes } from '../models/alert-editor-view.models';
import { Entity } from '@app/shared/models/entity.model';
import { Select } from '@ngxs/store';
import { NewMetricsState } from '@app/ngxs-store/new-metrics/new-metrics.state';
import { GetMetricFields, GetMetricLabels } from '@app/ngxs-store/new-metrics/new-metrics.action';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Injectable()
export class AlertsEditorSelectOptionsService {
  private get groupByOptions(): Observable<string[]> {
    const groupByOptions = this.store
      .select(getNonAllLogsFilterGridColumns)
      .filter(gridColumns => gridColumns?.filter && gridColumns.filter.available && gridColumns.filter.selected)
      .switchMap(gridColumns => {
        const allFields = [];
        const ignoredFields = ['coralogix.metadata.threadId', 'coralogix.metadata.companyId'];

        const fieldsArrays = [gridColumns.filter.available, gridColumns.filter.selected];
        fieldsArrays.forEach(fieldsArr => {
          fieldsArr.forEach(col => {
            if (!ignoredFields.includes(col.colId)) {
              allFields.push(col.colId);
            }
          });
        });

        return Observable.of(allFields.sort());
      });
    this.store.dispatch(new LogsFilterGridColumnsActions.LogsFilterGridTemplateColumnsInit());

    return groupByOptions;
  }

  private get metrics(): Observable<Metric[]> {
    this.store.dispatch(new MetricsActions.GetMetricsAction(null));
    return this.store.select(getMetrics);
  }

  private get newMetricsMetricFields(): Observable<string[]> {
    this.getNewMetricsMetricFields();
    return this.newMetricsMetricFields$;
  }

  private get newMetricsMetricLabels(): Observable<string[]> {
    this.getNewMetricsMetricLabels();
    return this.newMetricsMetricLabels$;
  }

  private get integrationsList(): Observable<any[]> {
    this.store.dispatch(new IntegrationsActions.GetIntegrationsAction());
    return this.store.select(getIntegrations).pipe(
      filter(integrations => integrations !== null),
      take(1),
    );
  }
  @Select(NewMetricsState.metricFields) public newMetricsMetricFields$: Observable<string[]>;
  @Select(NewMetricsState.metricLabels) public newMetricsMetricLabels$: Observable<string[]>;

  constructor(
    private alertHelper: AlertHelper,
    private logsSeverityOptionsFactoryService: LogsSeverityOptionsFactoryService,
    private metadataProvider: MetadataProvider,
    private store: Store<State>,
  ) {}

  public getInitialSelectOptions(filterObj: Metadata, alertType: UserAlertViewTypes): AlertsEditorSelectOptions {
    const metadataOptions = this.getMetadataOptions(filterObj);
    const options = new AlertsEditorSelectOptions({
      alertSeverityOptions: this.alertHelper.alertSeverityOptions,
      logSeverityOptions: this.logsSeverityOptionsFactoryService.create(),
      conditionsOperatorOptions: this.alertHelper.conditionsOperatorOptions,
      conditionTimeframeOptions: this.getConditionTimeframeOptions(alertType),
      relativeTimeframeOptions: this.alertHelper.relativeTimeframeOptions,
      relativeTimeframeOptionIdMapping: this.alertHelper.relativeTimeframeOptionIdMapping,
      relativeTimeframeSilenceDisplay: this.alertHelper.relativeTimeframeSilenceDisplay,
      conditionMetricArithmeticOptions: this.alertHelper.conditionMetricArithmeticOptions,
      metrics: this.metrics,
      newMetricsMetricFields: this.newMetricsMetricFields,
      newMetricsMetricLabels: this.newMetricsMetricLabels,
      weekDayOptions: this.alertHelper.alertWeekdayShortNameOptions,
      groupByOptions: this.groupByOptions,
      integrations: this.integrationsList,
      cardinalityTimeframeOptions: this.alertHelper.cardinalityTimeframeOptions,
    });

    return { ...options, ...metadataOptions };
  }

  public getMetadataOptions(filterObj: Metadata): Partial<AlertsEditorSelectOptions> {
    this.metadataProvider.updateAllMetadata(filterObj);

    const options = new AlertsEditorSelectOptions({
      applicationOptions: this.metadataProvider.options.applicationMetadata$,
      subsystemOptions: this.metadataProvider.options.subsystemMetadata$,
    });
    return options;
  }

  @Dispatch() public getNewMetricsMetricFields = () => new GetMetricFields();
  @Dispatch() public getNewMetricsMetricLabels = () => new GetMetricLabels();

  private getConditionTimeframeOptions(alertType: UserAlertViewTypes): Entity[] {
    switch (alertType) {
      case UserAlertViewTypes.NewValueAlert:
        return this.alertHelper.conditionTimeFrameNewValueOptions;
      case UserAlertViewTypes.MetricAlert:
        return this.alertHelper.conditionTimeframeMetricAlertOptions;
      default:
        return this.alertHelper.conditionTimeframeOptions;
    }
  }
}
